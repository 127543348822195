import {quesTypeTypes} from '../settingsTypes';

const initialState = {
    allQuestionTypes: [],
    loading: false,
    error: '',
    text: '',
    type:'',
    totalTypesCount: 0,
    allTypes:[],
    errortypedelete:false,
    loadingtypedelete:false,
    successtypeupdate: false,
    loadingtypeupdate: false,
    errortypeupdate:false,
    successtypedelete:false,
    errortypecreate: '',
    successtypecreate: false,
}

export function typeReducer(state = initialState, action){
    switch (action.type){
        case quesTypeTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allQuestionTypes: [],
                loading: false,
                error: '',
                text: '',
                type:'',
                totalTypesCount:0,
                allTypes:[],
                errortypedelete:false,
                loadingtypedelete:false,
                successtypeupdate: false,
                loadingtypeupdate: false,
                errortypeupdate:false,
                successtypedelete:false,
                errortypecreate: '',
                successtypecreate: false,
            }
        //Type retrieve request sending
        case quesTypeTypes.GET_ALL_QUESTION_TYPES:
            return {
                ...state,
                loading: true,
                text: 'Get all the question types'
            }
        case quesTypeTypes.GET_ALL_QUESTION_TYPES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case quesTypeTypes.GET_ALL_QUESTION_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionTypes: action.types.types,
                totalTypesCount: action.types.total_count
            }
        case quesTypeTypes.GET_ALL_QUESTION_TYPES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Type creation request sending
        case quesTypeTypes.CREATE_TYPE_REQUEST:
            return {
                ...state,
                errortypecreate: '',
                successtypecreate: false,
                loading: true
            }
        case quesTypeTypes.CREATE_TYPE_SUCCESS:
            var temp = state.allTypes;
            temp.push(action.qtypes)
            return {
                ...state,
                allTypes: temp,
                type: action.types.type_id,
                successtypecreate: true,
                loading: false
            }
        case quesTypeTypes.CREATE_TYPE_FAILURE:
            return {
                ...state,
                errortypecreate: action.error,
                loading: false
            }  
        //Type deletion request sending   
        case quesTypeTypes.DELETE_TYPE_REQUEST:
            return {
            ...state,
            errortypedelete: '',
            successtypedelete: false,
            loading: true,
            };
        case quesTypeTypes.DELETE_TYPE_SUCCESS:
            return {
            ...state,
            successtypedelete: true,
            loading: false,
            };
        case quesTypeTypes.DELETE_TYPE_FAILURE:
            return {
            ...state,
            errortypedelete: action.error,
            loading: false,
            };
        //Type updation request sending
        case quesTypeTypes.UPDATE_TYPE_REQUEST:
            return {
            ...state,
            errortypeupdate:'',
            successtypeupdate: false,
            loading: true,
            };
        case quesTypeTypes.UPDATE_TYPE_SUCCESS:
            return {
            ...state,
            successtypeupdate: true,
            loading: false,
            };
        case quesTypeTypes.UPDATE_TYPE_FAILURE:
            return {
            ...state,
            errortypeupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
