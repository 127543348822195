import React, { Component } from "react";
import Card from "../../../../components/common/Card.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardBody from "../../../../components/common/CardBody.js";
import CardTitle from "../../../../components/common/CardTitle.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import MaterialCustomTablePagination from "../../../../components/common/MaterialCustomTablePagination.js";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "../../../../components/admin/css/common.css";
import { sweetStatusAlert } from "../../../../components/common/SweetAlert.js";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { TransactionService } from "../../../../service/manage/subscription/transaction.service.js";
import TimeZoneHandler from "../../../../handlers/TimeZoneHandler.js";
import debounce from "lodash.debounce";

export default class Transactions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: true,
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      transactions: "",
      showModal: false,
      selectedTransaction: null,
      transactionsPrivilege: null,
      currentPage: 0,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      totalTransactions: 10,
      search_term: "",
    };
    this.debouncedHandleSearchAll = debounce(this.getAllTransactions, 2000);
    this.debouncedHandleSearchUser = debounce(this.getAllTransactionsByUser, 2000);
  }

  componentDidMount() {
    const { breadcrumb2, nodeTitle, flg_list_active } = this.props.props
    const userType = sessionStorage.getItem("userType");

    this.setState({
      breadcrumb2: breadcrumb2, nodeTitle, flg_list_active,
      transactionsPrivilege: this.props.transactionsPrivilege
    }, () => {
      const { transactionsPrivilege } = this.state
      if (transactionsPrivilege.isAdmin) {
        this.getAllTransactions();
      } else if (transactionsPrivilege.isStudent) {
        this.getAllTransactionsByUser();
      }
    })
  }

  getAllTransactions() {
    const mid = 100;
    const { limit, offset, search_term } = this.state
    TransactionService.getAllTransactions(mid, limit, offset, search_term).then((res) => {
      if (res.success) {
        this.setState({ transactions: res.data.transactions, totalTransactions: parseInt(res.data.count) });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Transactions. Please refresh the screen!",
          "error"
        );
      }
    }).finally(() => {
      this.setState({
        isTableDataLoading: false,
      });
    })
  }

  getAllTransactionsByUser() {
    const mid = 100;
    const { limit, offset, search_term } = this.state
    const user_id = sessionStorage.getItem("manage_user_id");
    TransactionService.getAllTransactionsByUser(mid, user_id, limit, offset, search_term).then((res) => {
      if (res.success) {
        this.setState({ transactions: res.data.transactions, totalTransactions: parseInt(res.data.count) });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Transactions. Please refresh the screen!",
          "error"
        );
      }
    }).finally(() => {
      this.setState({
        isTableDataLoading: false,
      });
    })
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  handleRowClick = (transaction) => {
    this.setState({
      showModal: true,
      selectedTransaction: transaction.transaction,
    });
  };

  handleModalClose = () => {
    this.setState({
      showModal: false,
      selectedTransaction: null,
    });
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        const { transactionsPrivilege } = this.state
        if (transactionsPrivilege.isAdmin) {
          this.getAllTransactions()
        }
        else {
          this.getAllTransactionsByUser()
        }
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        const { transactionsPrivilege } = this.state
        if (transactionsPrivilege.isAdmin) {
          this.getAllTransactions()
        }
        else {
          this.getAllTransactionsByUser()
        }
      }
    );
  };

  // handle the data fetching as per the data per page limit gets changes
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        const { transactionsPrivilege } = this.state
        if (transactionsPrivilege.isAdmin) {
          this.getAllTransactions()
        }
        else {
          this.getAllTransactionsByUser()
        }
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();

    this.setState(
      {
        search_term,
        isTableDataLoading: true,
        offset: 0,
        currentPage: 0,
      },
      () => {
        const { transactionsPrivilege } = this.state
        if (transactionsPrivilege.isAdmin) {
          this.debouncedHandleSearchAll();
        }
        else {
          this.debouncedHandleSearchUser()
        }
      }
    );
  };

  render() {
    const {
      isTableDataLoading,
      transactions,
      showModal,
      selectedTransaction,
      transactionsPrivilege,
      currentPage,
      limit,
      totalTransactions
    } = this.state;

    if (!transactionsPrivilege) {
      return (
        <div>
          <h4>Privilege not found for this section</h4>
        </div>
      )
    }
    return (
      <>
        <Card>
          <CardHeader>
            <div>
              {/* <CardTitle cardLabel={history.location.state.form_display_title} /> */}
              <CardTitle cardLabel={"Transactions"} />
              {/* <h3 style={{ color: 'gray' }}>{(breadcrumb2 || nodeTitle) ? `(${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle})` : undefined}</h3> */}
            </div>
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-6 d-flex align-items-end">
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="col-md-8 mb-2 px-0"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    className="search-bar"
                  />
                </Form.Group>
              </div>
            </div>
            {isTableDataLoading ? (
              <div className="loading-container">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div style={{ overflow: 'Auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>{transactionsPrivilege.isAdmin ? "Transaction Id" : "Confirmation No"}</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>Student</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>Type</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>No Of Credits</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>Expiration Date</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>Amount</TableCell>
                      {transactionsPrivilege.isAdmin && <TableCell className="thead" style={{ textAlign: 'center' }}>Currency Type</TableCell>}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {transactions.length > 0 && transactions.map((transaction) => (
                      <TableRow key={transaction.transaction_id} onClick={() => this.handleRowClick(transaction)} style={{ cursor: 'pointer' }}>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {transaction.transaction_id}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {transaction.studentFirstName} {transaction.studentLastName}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {transaction.type}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {transaction.credits}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {TimeZoneHandler.convertTime(transaction.expiration_date)}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {transaction.amount}
                        </TableCell>
                        {transactionsPrivilege.isAdmin && <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {transaction.currencyType}
                        </TableCell>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <MaterialCustomTablePagination
                  totalCount={totalTransactions}
                  currentPage={currentPage}
                  limit={limit}
                  handlePageChange={this.handlePageChange}
                  handleRowsPerPageChange={this.handleRowsPerPageChange}
                  handlePageOffsetChange={this.handlePageOffsetChange}
                  rowsPerPageOptions={[5, 10, 20]}
                  offset={this.state.offset}
                />
              </div>
            )}
          </CardBody>
        </Card>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transaction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedTransaction && (
              <Form>
                {Object.entries(selectedTransaction).map(([key, value]) => (
                  <Row className="my-3" key={key}>
                    <Form.Label as={Col} md="3">{key}</Form.Label>
                    <Form.Label as={Col} md="9">{key == "Exp. Date" || key == "Purchase Date" ? TimeZoneHandler.convertTime(value) : value}</Form.Label>
                  </Row>
                ))}
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </>
    );
  }
}
