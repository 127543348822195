import React from 'react';

export default function CardFooter ({ children }) {
    return (

        <div className="card-footer">
            {children}
        </div>

    )
}