import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const TransactionService = {
    getAllTransactions,
    getAllTransactionsByUser,
    manualPayment
};

async function getAllTransactions(node_id, limit, offset, search_term) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/transactions/getAllTransactions/${node_id}/${limit}/${offset}?search_term=${search_term}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllTransactionsByUser(node_id, user_id, limit, offset, search_term) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    let user_id_string = user_id;
    if (Array.isArray(user_id)) {
        user_id_string = `[${user_id.join(",")}]`; // Convert array to comma-separated string with square brackets
    }
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/transactions/getAllTransactionsByUser/${node_id}/${user_id_string}/${limit}/${offset}?search_term=${search_term}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function manualPayment(payment_obj) {
    const requestOptions = {
        method: "POST",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: payment_obj,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/transactions/manual-payment`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}