import React from "react"
import "../dashboard.css"
import config from "../../../../../helper/config.js"
import { customerConstants } from "../../../../../helper/constants.js"
import PaidIcon from "@mui/icons-material/Paid"
import { Dropdown } from "react-bootstrap"
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded"
import AreaChart from "../../../charts/AreaChart.js"
import GroupedBarChart from "../../../charts/GroupedBarChart.js"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}

const Income = (props) => {
  return (
    <div className="row d-flex">
      <div className="col-md-12 mb-3 pl-0 px-0 px-sm-0">
        <div className="dashboard__container">
          <div className="row d-flex justify-content-between align-items-center my-3">
            <h3 className="dashboard__container-title m-0">
              <SavingsRoundedIcon sx={{ fontSize: 22, color: "#5c5c5c" }} />{" "}
              Chargeable Fees
            </h3>
            <div className="">
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #E4E6EF",
                    color: "var(--secondary-text-color)",
                    fontWeight: "400",
                    fontSize: "10px",
                  }}
                >
                  May
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  <Dropdown.Item>
                    <div>January</div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div>February</div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div>March</div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div>April</div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="row d-flex dashboard__count-cards-orange align-items-center py-3">
            <div className="col-md-2 col-2 p-0 revenue__icon d-flex align-items-center">
              <SavingsRoundedIcon
                sx={{
                  fontSize: 15,
                  color: "#fff",
                }}
              />
            </div>
            <div className="col-md-10 col-10">
              <p className="revenue-text">Current Month Revenue</p>
              <h3 className="revenue-amount m-0">LKR 100,000</h3>
            </div>
          </div>
          <div className="row my-5">
            <div className="col-md-12 px-0">
              <div className="row d-flex justify-content-between receivables-row">
                <h5 className="receivables-title m-0">Old Receivables</h5>
                <p className="receivables-amount">10,000</p>
              </div>
              <div className="row d-flex justify-content-between receivables-row">
                <h5 className="receivables-title m-0">Current Receivables</h5>
                <p className="receivables-amount">90,000</p>
              </div>
              <div className="row d-flex justify-content-between receivables-row">
                <h5 className="receivables-title m-0">Total Receivables</h5>
                <p className="receivables-amount">90,000</p>
              </div>
              <h3 className="dashboard-subheading m-0 mt-8 pt-5">
                Monthly Revenue
              </h3>
              <div className="row">
                <AreaChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Income
