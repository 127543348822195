import React, { Component, createRef } from "react";
import Modal from "react-bootstrap/Modal";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import sweetAlert, {
  sweetStatusAlert,
} from "../../../../../components/common/SweetAlert";
import { Form } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import { StudentService } from "../../../../../service/manage/registration/student.service";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CGClogo from "../../../../../assets/images/cgc-logo.png";
import SimpleReactValidator from "simple-react-validator";

const lightTheme = createTheme({ palette: { mode: "light" } });

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  lineHeight: "40px",
  padding: theme.spacing(2), // Add padding
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(3),
}));

class BlockLetterInput extends Component {
  constructor(props) {
    super(props);
    this.textRefs = Array.from({ length: this.props.values.length }, () =>
      createRef()
    );
  }

  handleChange = (e, index) => {
    const { values, setValues } = this.props;
    const newValues = [...values];
    newValues[index] = e.target.value.toUpperCase().slice(-1);
    setValues(newValues);

    if (index < this.textRefs.length - 1 && newValues[index]) {
      this.textRefs[index + 1].current.focus();
    }
  };

  handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !this.props.values[index]) {
      const { values, setValues } = this.props;
      const newValues = [...values];
      newValues[index - 1] = "";
      setValues(newValues);
      this.textRefs[index - 1].current.focus();
    }
  };

  render() {
    const { values, label, refIndex, validationMessage } = this.props;
    const lines = [];
    for (let i = 0; i < values.length; i += 20) {
      lines.push(values.slice(i, i + 20));
    }

    return (
      <Box sx={{ p: 2 }}>
        <Grid container alignItems="center" spacing={0.7}>
          <Grid item xs={2}>
            <Item
              elevation={0}
              sx={{
                fontWeight: "bold",
                height: "100%",
                backgroundColor: "#E0E8ED",
              }}
            >
              {label}
            </Item>
          </Grid>
          <Grid item xs={10}>
            {lines.map((line, lineIndex) => (
              <Box
                key={lineIndex}
                sx={{
                  borderRadius: 0,
                  display: "flex",
                  gap: 0.2,
                  marginBottom: 0.5,
                  height: "100%",
                  flexDirection: "column", // Adjusted to have validation message below the blocks
                }}
              >
                <Box sx={{ display: "flex", gap: 0.2 }}>
                  {line.map((value, index) => (
                    <TextField
                      key={index + lineIndex * 20}
                      value={value}
                      onChange={(e) =>
                        this.handleChange(e, index + lineIndex * 20)
                      }
                      onKeyDown={(e) =>
                        this.handleKeyDown(e, index + lineIndex * 20)
                      }
                      inputRef={
                        index + lineIndex * 20 === refIndex
                          ? this.props.forwardedRef
                          : this.textRefs[index + lineIndex * 20]
                      }
                      inputProps={{
                        maxLength: 1,
                        style: {
                          borderRadius: 0,
                          textTransform: "uppercase",
                          width: "4.8ch",
                          height:"5.5ch",
                          textAlign: "center",
                          backgroundColor: "#E0E8ED",
                          padding: "0px"
                        },
                      }}
                      sx={{
                        borderRadius: 0,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 0, // Remove rounded corners
                        },
                      }}
                    />
                  ))}
                </Box>
                {lineIndex === lines.length - 1 && (
                  <Typography
                    variant="body2"
                    sx={{ color: "red", textAlign: "left", mt: 0.5 }}
                  >
                    {validationMessage}
                  </Typography>
                )}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default class CgcUpdateStudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isModified: false,
      showInsertModal: false,
      full_name: Array(40).fill(""),
      first_name: Array(20).fill(""),
      last_name: Array(20).fill(""),
      user_code: "Generating...",
      gender: "",
      name_of_certificate: "",
      dob: "",
      nationality: "",
      nic: "",
      mobile: "",
      email: "",
      home: "",
      office: "",
      address_for_correspondence: "",
      permanent_address: "",
      ol_year: "",
      ol_school: "",
      al_year: "",
      al_school: "",

      ol_subject1: "",
      ol_grade1: "",
      ol_subject2: "",
      ol_grade2: "",
      ol_subject3: "",
      ol_grade3: "",
      ol_subject4: "",
      ol_grade4: "",
      ol_subject5: "",
      ol_grade5: "",
      ol_subject6: "",
      ol_grade6: "",
      ol_subject7: "",
      ol_grade7: "",
      ol_subject8: "",
      ol_grade8: "",
      ol_subject9: "",
      ol_grade9: "",
      ol_subject10: "",
      ol_grade10: "",

      al_subject1: "",
      al_grade1: "",
      al_subject2: "",
      al_grade2: "",
      al_subject3: "",
      al_grade3: "",
      al_subject4: "",
      al_grade4: "",
      al_subject5: "",
      al_grade5: "",

      qualification: "",
      institution: "",
      academic_year: "",
      other_details: "",
      other_qualification: "",

      work_experience: "",

      ol: 0,
      al: 0,
      english_ability: 0,
      min_entry_qualification: "",
      name_designation: "",
      status: 0,
      overseas_nic_passport: 0,
      overseas_educational_doc: 0,
      overseas_color_photo: 0,
      overseas_work_experience_doc: 0,

      olQualifications: [],
      alQualifications: [],

      ol_subjects: [
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
      ],

      al_subjects: [
        { id: "", al_subject1: "", al_grade1: "", type: "al" },
        { id: "", al_subject2: "", al_grade2: "", type: "al" },
        { id: "", al_subject3: "", al_grade3: "", type: "al" },
        { id: "", al_subject4: "", al_grade4: "", type: "al" },
        { id: "", al_subject5: "", al_grade5: "", type: "al" },
      ],
    };

    this.createValidationsInstance();

    this.formInitialState = {
      isModified: false,
      full_name: Array(40).fill(""),
      first_name: Array(20).fill(""),
      last_name: Array(20).fill(""),
      user_code: "Generating...",
      gender: "",
      name_of_certificate: "",
      dob: null,
      nationality: "",
      nic: "",
      mobile: "",
      email: "",
      home: "",
      office: "",
      address_for_correspondence: "",
      permanent_address: "",
      ol_year: "",
      ol_school: "",
      al_year: "",
      al_school: "",

      ol_subject1: "",
      ol_grade1: "",
      ol_subject2: "",
      ol_grade2: "",
      ol_subject3: "",
      ol_grade3: "",
      ol_subject4: "",
      ol_grade4: "",
      ol_subject5: "",
      ol_grade5: "",
      ol_subject6: "",
      ol_grade6: "",
      ol_subject7: "",
      ol_grade7: "",
      ol_subject8: "",
      ol_grade8: "",
      ol_subject9: "",
      ol_grade9: "",
      ol_subject10: "",
      ol_grade10: "",

      al_subject1: "",
      al_grade1: "",
      al_subject2: "",
      al_grade2: "",
      al_subject3: "",
      al_grade3: "",
      al_subject4: "",
      al_grade4: "",
      al_subject5: "",
      al_grade5: "",

      qualification: "",
      institution: "",
      academic_year: "",
      other_details: "",
      other_qualification: "",

      work_experience: "",

      ol: 0,
      al: 0,
      english_ability: 0,
      min_entry_qualification: "",
      name_designation: "",
      status: 0,
      overseas_nic_passport: 0,
      overseas_educational_doc: 0,
      overseas_color_photo: 0,
      overseas_work_experience_doc: 0,

      olQualifications: [],
      alQualifications: [],

      ol_subjects: [
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
        { id: "", subject: "", grade: "", type: "ol" },
      ],

      al_subjects: [
        { id: "", al_subject1: "", al_grade1: "", type: "al" },
        { id: "", al_subject2: "", al_grade2: "", type: "al" },
        { id: "", al_subject3: "", al_grade3: "", type: "al" },
        { id: "", al_subject4: "", al_grade4: "", type: "al" },
        { id: "", al_subject5: "", al_grade5: "", type: "al" },
      ],
    };
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        address: {
          message: "The :attribute must be a valid address",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/:0-9,\s]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
        },
        dateBefore: {
          message: "The :attribute must be before current date",
          rule: (val, params, validator) => {
            const selectedDate = new Date(val);
            const currentDate = new Date();
            return selectedDate < currentDate;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        phone_number: {
          message: "The :attribute must be a valid phone number",
          rule: (val, params, validator) => {
            const isTenDigitsStartingWithZero =
              val.length === 10 && val.startsWith("0");
            const isNineDigits = val.length === 9 && !val.startsWith("0");
            return isTenDigitsStartingWithZero || isNineDigits;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
      },
    });
    this.updateValidator = new SimpleReactValidator();
  }

  componentDidMount() {
    const studentItem = this.props.updateStudentItem;
    const olQualifications = this.props.olQualifications;
    const alQualifications = this.props.alQualifications;
    const otherQualifications = this.props.otherQualifications;

    if (studentItem) {
      this.setState({
        full_name: this.padArray(studentItem.full_name?.split("") || [], 40),
        first_name: this.padArray(studentItem.first_name?.split("") || [], 20),
        last_name: this.padArray(studentItem.last_name?.split("") || [], 20),

        user_code: studentItem.user_code ? studentItem.user_code : "",
        name_of_certificate: studentItem.name_of_certificate
          ? studentItem.name_of_certificate
          : "",
        gender: studentItem.gender ? studentItem.gender : "",
        dob: studentItem.dob ? studentItem.dob : "",
        nationality: studentItem.nationality ? studentItem.nationality : "",
        nic: studentItem.nic ? studentItem.nic : "",
        mobile: studentItem.mobile ? studentItem.mobile : "",
        email: studentItem.email ? studentItem.email : "",
        home: studentItem.home ? studentItem.home : "",
        office: studentItem.office ? studentItem.office : "",
        address_for_correspondence: studentItem.address_for_correspondence
          ? studentItem.address_for_correspondence
          : "",
        permanent_address: studentItem.permanent_address
          ? studentItem.permanent_address
          : "",
        ol_year: studentItem.ol_year ? studentItem.ol_year : "",
        ol_school: studentItem.ol_school ? studentItem.ol_school : "",
        al_year: studentItem.al_year ? studentItem.al_year : "",
        al_school: studentItem.al_school ? studentItem.al_school : "",

        work_experience: studentItem.work_experience
          ? studentItem.work_experience
          : "",

        ol: studentItem.ol ? studentItem.ol : 0,
        al: studentItem.al ? studentItem.al : 0,
        english_ability: studentItem.english_ability
          ? studentItem.english_ability
          : 0,
        min_entry_qualification: studentItem.min_entry_qualification
          ? studentItem.min_entry_qualification
          : "",
        name_designation: studentItem.name_designation
          ? studentItem.name_designation
          : "",
        status: studentItem.status ? studentItem.status : 0,
        overseas_nic_passport: studentItem.overseas_nic_passport
          ? studentItem.overseas_nic_passport
          : 0,
        overseas_educational_doc: studentItem.overseas_educational_doc
          ? studentItem.overseas_educational_doc
          : 0,
        overseas_color_photo: studentItem.overseas_color_photo
          ? studentItem.overseas_color_photo
          : 0,
        overseas_work_experience_doc: studentItem.overseas_work_experience_doc
          ? studentItem.overseas_work_experience_doc
          : 0,
      });
    }

    if (olQualifications.length > 0) {
      const { ol_subjects } = this.state;

      for (let i = 0; i < olQualifications.length; i++) {
        ol_subjects[i]["id"] = olQualifications[i].id;
        ol_subjects[i]["subject"] = olQualifications[i].subjectValue || "";
        ol_subjects[i]["grade"] = olQualifications[i].gradeValue || "";
      }

      this.setState({
        ol_subjects,
      });

      // this.setState({
      //     ol_subject1: olQualifications[0]?.subjectValue?olQualifications[0].subjectValue:'',
      //     ol_grade1: olQualifications[0]?.gradeValue?olQualifications[0].gradeValue:'',
      //     ol_subject2: olQualifications[1]?.subjectValue?olQualifications[1].subjectValue:'',
      //     ol_grade2: olQualifications[1]?.gradeValue?olQualifications[1].gradeValue:'',
      //     ol_subject3: olQualifications[2]?.subjectValue?olQualifications[2].subjectValue:'',
      //     ol_grade3: olQualifications[2]?.gradeValue?olQualifications[2].gradeValue:'',
      //     ol_subject4: olQualifications[3]?.subjectValue?olQualifications[3].subjectValue:'',
      //     ol_grade4: olQualifications[3]?.gradeValue?olQualifications[3].gradeValue:'',
      //     ol_subject5: olQualifications[4]?.subjectValue?olQualifications[4].subjectValue:'',
      //     ol_grade5: olQualifications[4]?.gradeValue?olQualifications[4].gradeValue:'',
      //     ol_subject6: olQualifications[5]?.subjectValue?olQualifications[5].subjectValue:'',
      //     ol_grade6: olQualifications[5]?.gradeValue?olQualifications[5].gradeValue:'',
      //     ol_subject7: olQualifications[6]?.subjectValue?olQualifications[6].subjectValue:'',
      //     ol_grade7: olQualifications[6]?.gradeValue?olQualifications[6].gradeValue:'',
      //     ol_subject8: olQualifications[7]?.subjectValue?olQualifications[7].subjectValue:'',
      //     ol_grade8: olQualifications[7]?.gradeValue?olQualifications[7].gradeValue:'',
      //     ol_subject9: olQualifications[8]?.subjectValue?olQualifications[8].subjectValue:'',
      //     ol_grade9: olQualifications[8]?.gradeValue?olQualifications[8].gradeValue:'',
      //     ol_subject10: olQualifications[9]?.subjectValue?olQualifications[9].subjectValue:'',
      //     ol_grade10: olQualifications[9]?.gradeValue?olQualifications[9].gradeValue:'',

      // })
    }

    if (alQualifications.length > 0) {
      const { al_subjects } = this.state;

      for (let i = 0; i < alQualifications.length; i++) {
        al_subjects[i]["id"] = alQualifications[i].id;
        al_subjects[i]["subject"] = alQualifications[i].subjectValue || "";
        al_subjects[i]["grade"] = alQualifications[i].gradeValue || "";
      }

      this.setState({
        al_subjects,
      });

      // this.setState({
      //     al_subject1: alQualifications[0]?.subjectValue?alQualifications[0].subjectValue:'',
      //     al_grade1: alQualifications[0]?.gradeValue?alQualifications[0].gradeValue:'',
      //     al_subject2: alQualifications[1]?.subjectValue?alQualifications[1].subjectValue:'',
      //     al_grade2: alQualifications[1]?.gradeValue?alQualifications[1].gradeValue:'',
      //     al_subject3: alQualifications[2]?.subjectValue?alQualifications[2].subjectValue:'',
      //     al_grade3: alQualifications[2]?.gradeValue?alQualifications[2].gradeValue:'',
      //     al_subject4: alQualifications[3]?.subjectValue?alQualifications[3].subjectValue:'',
      //     al_grade4: alQualifications[3]?.gradeValue?alQualifications[3].gradeValue:'',
      //     al_subject5: alQualifications[4]?.subjectValue?alQualifications[4].subjectValue:'',
      //     al_grade5: alQualifications[4]?.gradeValue?alQualifications[4].gradeValue:'',
      // })
    }

    if (otherQualifications) {
      this.setState({
        qualification: otherQualifications[0]?.qualification
          ? otherQualifications[0].qualification
          : "",
        institution: otherQualifications[0]?.institution
          ? otherQualifications[0].institution
          : "",
        academic_year: otherQualifications[0]?.academic_year
          ? otherQualifications[0].academic_year
          : "",
        other_details: otherQualifications[0]?.other_details
          ? otherQualifications[0].other_details
          : "",
        other_qualification: otherQualifications[0]?.other_qualification
          ? otherQualifications[0].other_qualification
          : "",
      });
    }
  }

  padArray = (array, length) => {
    while (array.length < length) {
      array.push("");
    }
    return array;
  };

  getNewStudentId = async () => {
    const response = await StudentService.getCgcStudentId();

    if (response.success) {
      const { new_id } = response.data;
      if (new_id) {
        this.setState({ user_code: new_id });
      }
    } else {
      this.setState({ user_code: "Student Id Error" });
    }
  };

  toggleAddStudentModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        first_name: "",
        last_name: "",
        nic: "",
        dob: "",
        email: "",
        phone_number: "",
        gender_id: "",
        status_id: "",
        created_on: "",
        created_by: "",
        studentAddresses: [],
        grade: null,
        school: "",
      }),
      () => {
        // State has changed, perform your logic here based on the state change
        if (this.state.showInsertModal) {
          //   this.getAllGrades();
          //   this.getAllStudentAddressTypes();
        }
      }
    );
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.setState(this.formInitialState);
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
        this.toggleAddStudentModal();
        toggleFunction();
      });
    } else {
      this.setState(this.formInitialState);
      this.toggleAddStudentModal();
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    this.setState({ [name]: newValue, isModified: true });
  };

  handleBlockInputChangeFullName = (newValues) => {
    this.setState({ full_name: newValues });
    const fullNameString = this.convertArrayToString(newValues);
  };

  handleBlockInputChangeFirstName = (newValues) => {
    this.setState({ first_name: newValues });
    const firstNameString = this.convertArrayToString(newValues);
  };

  handleBlockInputChangeLastName = (newValues) => {
    this.setState({ last_name: newValues });
    const lastNameString = this.convertArrayToString(newValues);
  };

  convertArrayToString = (arr) => {
    if (Array.isArray(arr)) {
      return arr.join("").trim();
    }
    return "";
  };

  handleOlSubjectChange = (event, index) => {
    const { name, value } = event.target;

    this.setState((prevState) => {
      const ol_subjects = [...prevState.ol_subjects];
      ol_subjects[index][name] = value;
      return { ol_subjects };
    });
  };

  handleAlSubjectChange = (event, index) => {
    const { name, value } = event.target;

    this.setState((prevState) => {
      const al_subjects = [...prevState.al_subjects];
      al_subjects[index][name] = value;
      return { al_subjects };
    });
  };

  handleUpdateSubmit = async (event) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      // event.preventDefault();
      this.setState({ isLoading: true });
      const { dob } = this.state;
      if (!dob || dob === "") {
        sweetStatusAlert("Warning!", "Date of birth is required", "info");
        this.setState({ isLoading: false });
      } else {
        try {
          const {
            full_name,
            first_name,
            last_name,
            user_code,
            name_of_certificate,
            gender,
            dob,
            nationality,
            nic,
            mobile,
            email,
            home,
            office,
            address_for_correspondence,
            permanent_address,
            ol_year,
            ol_school,
            al_year,
            al_school,

            ol_subject1,
            ol_grade1,
            ol_subject2,
            ol_grade2,
            ol_subject3,
            ol_grade3,
            ol_subject4,
            ol_grade4,
            ol_subject5,
            ol_grade5,
            ol_subject6,
            ol_grade6,
            ol_subject7,
            ol_grade7,
            ol_subject8,
            ol_grade8,
            ol_subject9,
            ol_grade9,
            ol_subject10,
            ol_grade10,

            al_subject1,
            al_grade1,
            al_subject2,
            al_grade2,
            al_subject3,
            al_grade3,
            al_subject4,
            al_grade4,
            al_subject5,
            al_grade5,

            qualification,
            institution,
            academic_year,
            other_details,
            other_qualification,

            work_experience,

            ol,
            al,
            english_ability,
            min_entry_qualification,
            name_designation,
            status,
            overseas_nic_passport,
            overseas_educational_doc,
            overseas_color_photo,
            overseas_work_experience_doc,

            ol_subjects,
            al_subjects,
          } = this.state;

          const updateCgcStudent = {
            full_name: this.convertArrayToString(full_name),
            first_name: this.convertArrayToString(first_name),
            last_name: this.convertArrayToString(last_name),
            user_code: user_code,
            name_of_certificate: name_of_certificate,
            gender: gender,
            dob: dob,
            nationality: nationality,
            nic: nic,
            mobile: mobile,
            email: email,
            home: home,
            office: office,
            address_for_correspondence: address_for_correspondence,
            permanent_address: permanent_address,
            ol_year: ol_year,
            ol_school: ol_school,
            al_year: al_year,
            al_school: al_school,
            ol_subjects: ol_subjects,
            al_subjects: al_subjects,
            qualification: qualification,
            institution: institution,
            academic_year: academic_year,
            other_details: other_details,
            other_qualification: other_qualification,
            work_experience: work_experience,
            ol: ol,
            al: al,
            english_ability: english_ability,
            min_entry_qualification: min_entry_qualification,
            name_designation: name_designation,
            status: status,
            overseas_nic_passport: overseas_nic_passport,
            overseas_educational_doc: overseas_educational_doc,
            overseas_color_photo: overseas_color_photo,
            overseas_work_experience_doc: overseas_work_experience_doc,
          };

          const studentResponse = await StudentService.updateCgcStudent(
            JSON.stringify(updateCgcStudent),
            this.props.updateStudentItem?.user_id
          );

          if (studentResponse.success) {
            this.setState({ isLoading: false });
            sweetStatusAlert(
              "Success!",
              "Student updated successfully!",
              "success"
            );
            this.props.toggleAddStudentModal();
            this.setState(this.formInitialState);
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Failed!", "Failed to update student", "error");
          }
        } catch (e) {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", "Failed to update student", "error");
        }
      }
    }
  };

  render() {
    const {
      isLoading,

      full_name,
      first_name,
      last_name,
      user_code,
      name_of_certificate,
      gender,
      dob,
      nationality,
      nic,
      mobile,
      email,
      home,
      office,
      address_for_correspondence,
      permanent_address,
      ol_year,
      ol_school,
      al_year,
      al_school,

      ol_subject1,
      ol_grade1,
      ol_subject2,
      ol_grade2,
      ol_subject3,
      ol_grade3,
      ol_subject4,
      ol_grade4,
      ol_subject5,
      ol_grade5,
      ol_subject6,
      ol_grade6,
      ol_subject7,
      ol_grade7,
      ol_subject8,
      ol_grade8,
      ol_subject9,
      ol_grade9,
      ol_subject10,
      ol_grade10,

      al_subject1,
      al_grade1,
      al_subject2,
      al_grade2,
      al_subject3,
      al_grade3,
      al_subject4,
      al_grade4,
      al_subject5,
      al_grade5,

      qualification,
      institution,
      academic_year,
      other_details,
      other_qualification,

      work_experience,

      ol,
      al,
      english_ability,
      min_entry_qualification,
      name_designation,
      status,
      overseas_nic_passport,
      overseas_educational_doc,
      overseas_color_photo,
      overseas_work_experience_doc,

      olState,
      alState,
    } = this.state;

    const {
      isAddStudentModalOpen,
      toggleAddStudentModal,
      updateStudentItem,
      isStudent,
      isAdmin,
      olQualifications,
      alQualifications,
      otherQualifications,
    } = this.props;

    return (
      <>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={isAddStudentModalOpen}
          onHide={() =>
            this.handleSweetAlert(toggleAddStudentModal, "showInsertModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title centered={true}>Colombo Graduate Campus</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Form>
              <Grid container spacing={5} rowSpacing={5}>
                {[lightTheme].map((theme, index) => (
                  <ThemeProvider theme={theme} key={index}>
                    <Grid item xs={12}>
                      <Item elevation={8}>
                        <Grid
                          alignItems="center"
                          container
                          spacing={2}
                          sx={{ p: 2 }}
                        >
                          <Grid
                            item
                            xs={3}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Item>
                              <img
                                alt="Logo"
                                className="logo"
                                src={CGClogo}
                                style={{ width: "60px", height: "auto" }}
                              />
                            </Item>
                          </Grid>
                          <Grid item xs={6}>
                            <Item elevation={0}>
                              <Grid container spacing={2} sx={{ p: 2 }}>
                                <Grid item xs={12}>
                                  <Item sx={{ backgroundColor: "#E0E8ED" }}>
                                    Programme
                                  </Item>
                                </Grid>
                                <Grid item xs={12}>
                                  <Item sx={{ backgroundColor: "#E0E8ED" }}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{ fontWeight: "bold" }}
                                    >
                                      Student ID
                                    </Typography>

                                    {updateStudentItem
                                      ? updateStudentItem.user_code
                                      : user_code}
                                  </Item>
                                </Grid>
                              </Grid>
                            </Item>
                          </Grid>
                          <Grid item xs={3}>
                            <Item sx={{ backgroundColor: "#E0E8ED" }}>
                              Applicant photo
                            </Item>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>
                    <Grid item xs={12}>
                      <Item elevation={8}>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          sx={{ p: 2 }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              Application of Admission
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 160,
                                bgcolor: "background.paper",
                              }}
                              subheader={
                                <ListSubheader sx={{ fontWeight: "bold" }}>
                                  1. Name of the Student
                                </ListSubheader>
                              }
                            ></List>

                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <BlockLetterInput
                                length={40}
                                values={full_name}
                                setValues={this.handleBlockInputChangeFullName}
                                label="Full Name"
                                validationMessage={this.validator.message(
                                  "full_name",
                                  this.convertArrayToString(full_name),
                                  "required|alpha_space",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required:
                                        "The student full name is required.",
                                    },
                                  }
                                )}
                              />

                              <BlockLetterInput
                                length={20}
                                values={first_name}
                                setValues={this.handleBlockInputChangeFirstName}
                                label="First Name"
                                validationMessage={this.validator.message(
                                  "first_name",
                                  this.convertArrayToString(first_name),
                                  "required|alpha_space",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required:
                                        "The student first name is required.",
                                    },
                                  }
                                )}
                              />

                              <BlockLetterInput
                                length={20}
                                values={last_name}
                                setValues={this.handleBlockInputChangeLastName}
                                label="Last Name"
                                validationMessage={this.validator.message(
                                  "last_name",
                                  this.convertArrayToString(last_name),
                                  "required|alpha_space",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required:
                                        "The student last name is required.",
                                    },
                                  }
                                )}
                              />

                              <TextField
                                sx={{ bgcolor: "#E0E8ED" }}
                                onChange={this.handleChange}
                                name="name_of_certificate"
                                value={name_of_certificate}
                                fullWidth
                                id="outlined-basic"
                                label="Name of certificate"
                                variant="outlined"
                              />
                              <div style={{ textAlign: "left" }}>
                                {this.validator.message(
                                  "name_of_certificate",
                                  name_of_certificate,
                                  "required|alpha_space",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required:
                                        "The student Name of certificate is required.",
                                    },
                                  }
                                )}
                              </div>

                              <Typography variant="subtitle1" gutterBottom>
                                Note: Name appears as First name followed by
                                Surname in the Certificate. Please write your
                                names in this order.
                              </Typography>
                            </Item>

                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 200,
                                bgcolor: "background.paper",
                              }}
                              subheader={
                                <ListSubheader sx={{ fontWeight: "bold" }}>
                                  2. Personal & Contact Detailst
                                </ListSubheader>
                              }
                            ></List>

                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                sx={{ pl: 5, pr: 5 }}
                              >
                                <Grid item xs={6}>
                                  <Grid
                                    container
                                    spacing={0.5}
                                    alignItems="center"
                                  >
                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        Date Of Birth
                                      </Item>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        Gender
                                      </Item>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          id="dob"
                                          type="date"
                                          value={dob}
                                          name="dob"
                                          onChange={this.handleChange}
                                          size="small"
                                          sx={{
                                            maxHeight: "20px",
                                            width: "100%",
                                          }}
                                        />
                                        <br></br>
                                        <br />
                                        <div style={{ textAlign: "left" }}>
                                          {this.validator.message(
                                            "dob",
                                            dob,
                                            "required|dateBefore",
                                            {
                                              className: "text-danger",
                                              messages: {
                                                required:
                                                  "The date of birth is required.",
                                              },
                                            }
                                          )}
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <FormControl sx={{ height: "100%" }}>
                                          <RadioGroup
                                            row
                                            name="gender"
                                            value={gender}
                                            onChange={this.handleChange}
                                          >
                                            <FormControlLabel
                                              sx={{ height: "20px" }}
                                              value="female"
                                              label="Female"
                                              control={
                                                <Radio
                                                  sx={{ height: "10px" }}
                                                />
                                              }
                                            />
                                            <FormControlLabel
                                              sx={{ height: "20px" }}
                                              value="male"
                                              label="Male"
                                              control={
                                                <Radio
                                                  sx={{ height: "10px" }}
                                                />
                                              }
                                            />
                                          </RadioGroup>
                                        </FormControl>
                                        <div style={{ textAlign: "left" }}>
                                          {this.validator.message(
                                            "gender",
                                            gender,
                                            "required",
                                            {
                                              className: "text-danger",
                                            }
                                          )}
                                        </div>
                                      </Item>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                  <Grid
                                    container
                                    spacing={0.5}
                                    alignItems="center"
                                  >
                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        Nationality
                                      </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        NIC / Passport No
                                      </Item>
                                    </Grid>

                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={nationality}
                                          onChange={this.handleChange}
                                          size="small"
                                          name="nationality"
                                          id="outlined-basic"
                                          label="Nationality"
                                          variant="outlined"
                                        />
                                        <div style={{ textAlign: "left" }}>
                                          {this.validator.message(
                                            "nationality",
                                            nationality,
                                            "required",
                                            {
                                              className: "text-danger",
                                            }
                                          )}
                                        </div>
                                      </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={nic}
                                          onChange={this.handleChange}
                                          size="small"
                                          name="nic"
                                          id="outlined-basic"
                                          label="NIC / Passport No"
                                          variant="outlined"
                                        />
                                        <div style={{ textAlign: "left" }}>
                                          {this.validator.message(
                                            "nic",
                                            nic,
                                            "required",
                                            {
                                              className: "text-danger",
                                              messages: {
                                                required:
                                                  "The student nic is required.",
                                              },
                                            }
                                          )}
                                        </div>
                                      </Item>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                  <Item
                                    elevation={0}
                                    sx={{
                                      lineHeight: "20px",
                                      padding: "4px 0",
                                      fontWeight: "bold",
                                      backgroundColor: "#E0E8ED",
                                    }}
                                  >
                                    <TextField
                                      onChange={this.handleChange}
                                      name="mobile"
                                      value={mobile}
                                      fullWidth
                                      id="outlined-basic"
                                      label="Mobile number"
                                      variant="outlined"
                                    />
                                    <div style={{ textAlign: "left" }}>
                                      {this.validator.message(
                                        "mobile",
                                        mobile,
                                        "required|numeric|between:9,10|phone_number",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required:
                                              "The mobile number is required.",
                                          },
                                        }
                                      )}
                                    </div>
                                  </Item>
                                </Grid>

                                <Grid item xs={6}>
                                  <Item
                                    elevation={0}
                                    sx={{
                                      lineHeight: "20px",
                                      padding: "4px 0",
                                      fontWeight: "bold",
                                      backgroundColor: "#E0E8ED",
                                    }}
                                  >
                                    <TextField
                                      disabled
                                      onChange={this.handleChange}
                                      name="email"
                                      value={email}
                                      fullWidth
                                      id="outlined-basic"
                                      label="Email"
                                      variant="outlined"
                                    />
                                    <div style={{ textAlign: "left" }}>
                                      {this.validator.message(
                                        "email",
                                        email,
                                        "required|email",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required: "The email is required.",
                                          },
                                        }
                                      )}
                                    </div>
                                  </Item>
                                </Grid>

                                <Grid item xs={6}>
                                  <Item
                                    elevation={0}
                                    sx={{
                                      lineHeight: "20px",
                                      padding: "4px 0",
                                      fontWeight: "bold",
                                      backgroundColor: "#E0E8ED",
                                    }}
                                  >
                                    <TextField
                                      onChange={this.handleChange}
                                      name="home"
                                      value={home}
                                      fullWidth
                                      id="outlined-basic"
                                      label="Home contact number"
                                      variant="outlined"
                                    />
                                    <div style={{ textAlign: "left" }}>
                                      {this.validator.message(
                                        "home",
                                        home,
                                        "required|numeric|between:9,10|phone_number",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required:
                                              "Home contact number is required.",
                                          },
                                        }
                                      )}
                                    </div>
                                  </Item>
                                </Grid>

                                <Grid item xs={6}>
                                  <Item
                                    elevation={0}
                                    sx={{
                                      lineHeight: "20px",
                                      padding: "4px 0",
                                      fontWeight: "bold",
                                      backgroundColor: "#E0E8ED",
                                    }}
                                  >
                                    <TextField
                                      onChange={this.handleChange}
                                      name="office"
                                      value={office}
                                      fullWidth
                                      id="outlined-basic"
                                      label="Office contact number"
                                      variant="outlined"
                                    />
                                    
                                  </Item>
                                </Grid>

                                <Grid item xs={6}>
                                  <Item
                                    elevation={0}
                                    sx={{
                                      lineHeight: "20px",
                                      padding: "4px 0",
                                      fontWeight: "bold",
                                      backgroundColor: "#E0E8ED",
                                    }}
                                  >
                                    <TextField
                                      onChange={this.handleChange}
                                      name="address_for_correspondence"
                                      value={address_for_correspondence}
                                      fullWidth
                                      id="outlined-basic"
                                      label="Address for Correspondence"
                                      variant="outlined"
                                    />
                                    <div style={{ textAlign: "left" }}>
                                      {this.validator.message(
                                        "address_for_correspondence",
                                        address_for_correspondence,
                                        "required",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required:
                                              "Address for Correspondence is required.",
                                          },
                                        }
                                      )}
                                    </div>
                                  </Item>
                                </Grid>

                                <Grid item xs={6}>
                                  <Item
                                    elevation={0}
                                    sx={{
                                      lineHeight: "20px",
                                      padding: "4px 0",
                                      fontWeight: "bold",
                                      backgroundColor: "#E0E8ED",
                                    }}
                                  >
                                    <TextField
                                      onChange={this.handleChange}
                                      name="permanent_address"
                                      value={permanent_address}
                                      fullWidth
                                      id="outlined-basic"
                                      label="Permanent Address"
                                      variant="outlined"
                                    />
                                    <div style={{ textAlign: "left" }}>
                                      {this.validator.message(
                                        "permanent_address",
                                        permanent_address,
                                        "required",
                                        {
                                          className: "text-danger",
                                          messages: {
                                            required:
                                              "Permanent address is required.",
                                          },
                                        }
                                      )}
                                    </div>
                                  </Item>
                                </Grid>
                              </Grid>
                            </Item>

                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 200,
                                bgcolor: "background.paper",
                              }}
                              subheader={
                                <ListSubheader sx={{ fontWeight: "bold" }}>
                                  3. Educational Qualifications
                                </ListSubheader>
                              }
                            ></List>

                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                sx={{ pl: 5, pr: 5 }}
                              >
                                <Grid item xs={6}>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Grid item xs={4}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        G.C.E O/L
                                      </Item>
                                    </Grid>

                                    <Grid item xs={8}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={ol_year}
                                          onChange={this.handleChange}
                                          name="ol_year"
                                          fullWidth
                                          id="outlined-basic"
                                          label="Year"
                                          variant="outlined"
                                        />
                                        <div style={{ textAlign: "left" }}>
                                          {this.validator.message(
                                            "ol_year",
                                            ol_year,
                                            "required",
                                            {
                                              className: "text-danger",
                                              messages: {
                                                required:
                                                  "G.C.E O/L Year is required.",
                                              },
                                            }
                                          )}
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={ol_school}
                                          onChange={this.handleChange}
                                          name="ol_school"
                                          fullWidth
                                          id="outlined-basic"
                                          label="School"
                                          variant="outlined"
                                        />
                                        <div style={{ textAlign: "left" }}>
                                          {this.validator.message(
                                            "ol_school",
                                            ol_school,
                                            "required",
                                            {
                                              className: "text-danger",
                                              messages: {
                                                required:
                                                  "G.C.E O/L School is required.",
                                              },
                                            }
                                          )}
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={3}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        Subject
                                      </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        Grade
                                      </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        Subject
                                      </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        Grade
                                      </Item>
                                    </Grid>

                                    {this.state.ol_subjects.map(
                                      (subject, index) => (
                                        <React.Fragment key={index}>
                                          <Grid item xs={3}>
                                            <Item
                                              elevation={0}
                                              sx={{
                                                lineHeight: "20px",
                                                padding: "4px 0",
                                                fontWeight: "bold",
                                                backgroundColor: "#E0E8ED",
                                              }}
                                            >
                                              <TextField
                                                value={subject["subject"]}
                                                onChange={(e) =>
                                                  this.handleOlSubjectChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                                name="subject"
                                                fullWidth
                                                id={`outlined-subject${
                                                  index + 1
                                                }`}
                                                label={`subject ${index + 1}`}
                                                variant="outlined"
                                              />
                                              {index < 6 && (
                                                <div
                                                  style={{ textAlign: "left" }}
                                                >
                                                  {this.validator.message(
                                                    `ol_subject${index + 1}`,
                                                    subject["subject"],
                                                    "required",
                                                    {
                                                      className: "text-danger",
                                                      messages: {
                                                        required: "required.",
                                                      },
                                                    }
                                                  )}
                                                </div>
                                              )}
                                            </Item>
                                          </Grid>
                                          <Grid item xs={3}>
                                            <Item
                                              elevation={0}
                                              sx={{
                                                lineHeight: "20px",
                                                padding: "4px 0",
                                                fontWeight: "bold",
                                                backgroundColor: "#E0E8ED",
                                              }}
                                            >
                                              <TextField
                                                value={subject["grade"]}
                                                onChange={(e) =>
                                                  this.handleOlSubjectChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                                name="grade"
                                                fullWidth
                                                id={`outlined-grade${
                                                  index + 1
                                                }`}
                                                label="grade"
                                                variant="outlined"
                                              />
                                              {index < 6 && (
                                                <div
                                                  style={{ textAlign: "left" }}
                                                >
                                                  {this.validator.message(
                                                    `ol_grade${index + 1}`,
                                                    subject["grade"],
                                                    "required",
                                                    {
                                                      className: "text-danger",
                                                      messages: {
                                                        required: "required.",
                                                      },
                                                    }
                                                  )}
                                                </div>
                                              )}
                                            </Item>
                                          </Grid>
                                        </React.Fragment>
                                      )
                                    )}
                                  </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Grid item xs={4}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        G.C.E A/L
                                      </Item>
                                    </Grid>

                                    <Grid item xs={8}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={al_year}
                                          onChange={this.handleChange}
                                          name="al_year"
                                          fullWidth
                                          id="outlined-basic"
                                          label="Year"
                                          variant="outlined"
                                        />
                                        <div style={{ textAlign: "left" }}>
                                          {" "}
                                          {this.validator.message(
                                            "al_year",
                                            al_year,
                                            "required",
                                            {
                                              className: "text-danger",
                                              messages: {
                                                required:
                                                  "G.C.E A/L Year is required.",
                                              },
                                            }
                                          )}
                                        </div>
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={al_school}
                                          onChange={this.handleChange}
                                          name="al_school"
                                          fullWidth
                                          id="outlined-basic"
                                          label="School"
                                          variant="outlined"
                                        />
                                        <div style={{ textAlign: "left" }}>
                                          {this.validator.message(
                                            "al_school",
                                            al_school,
                                            "required",
                                            {
                                              className: "text-danger",
                                              messages: {
                                                required:
                                                  "G.C.E A/L School is required.",
                                              },
                                            }
                                          )}
                                        </div>
                                      </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        Subject
                                      </Item>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        Grade
                                      </Item>
                                    </Grid>

                                    {this.state.al_subjects.map(
                                      (subject, index) => (
                                        <React.Fragment key={index}>
                                          <Grid item xs={6}>
                                            <Item
                                              elevation={0}
                                              sx={{
                                                lineHeight: "20px",
                                                padding: "4px 0",
                                                fontWeight: "bold",
                                                backgroundColor: "#E0E8ED",
                                              }}
                                            >
                                              <TextField
                                                value={subject["subject"]}
                                                onChange={(e) =>
                                                  this.handleAlSubjectChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                                name="subject"
                                                fullWidth
                                                id={`outlined-al_subject${
                                                  index + 1
                                                }`}
                                                label={`subject ${index + 1}`}
                                                variant="outlined"
                                              />
                                              {index < 5 && (
                                                <div
                                                  style={{ textAlign: "left" }}
                                                >
                                                  {this.validator.message(
                                                    `al_subject${index + 1}`,
                                                    subject["subject"],
                                                    "required",
                                                    {
                                                      className: "text-danger",
                                                      messages: {
                                                        required: "required.",
                                                      },
                                                    }
                                                  )}
                                                </div>
                                              )}
                                            </Item>
                                          </Grid>
                                          <Grid item xs={6}>
                                            <Item
                                              elevation={0}
                                              sx={{
                                                lineHeight: "20px",
                                                padding: "4px 0",
                                                fontWeight: "bold",
                                                backgroundColor: "#E0E8ED",
                                              }}
                                            >
                                              <TextField
                                                value={subject["grade"]}
                                                onChange={(e) =>
                                                  this.handleAlSubjectChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                                name="grade"
                                                fullWidth
                                                id={`outlined-al_grade${
                                                  index + 1
                                                }`}
                                                label="grade"
                                                variant="outlined"
                                              />
                                              {index < 5 && (
                                                <div
                                                  style={{ textAlign: "left" }}
                                                >
                                                  {this.validator.message(
                                                    `al_grade${index + 1}`,
                                                    subject["grade"],
                                                    "required",
                                                    {
                                                      className: "text-danger",
                                                      messages: {
                                                        required: "required.",
                                                      },
                                                    }
                                                  )}
                                                </div>
                                              )}
                                            </Item>
                                          </Grid>
                                        </React.Fragment>
                                      )
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Item>

                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 300,
                                bgcolor: "background.paper",
                              }}
                              subheader={
                                <ListSubheader sx={{ fontWeight: "bold" }}>
                                  4. Academic / Professional Qualifications
                                </ListSubheader>
                              }
                            ></List>

                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                sx={{ pl: 5, pr: 5 }}
                              >
                                <Grid item xs={6}>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    sx={{ pl: 5, pr: 5 }}
                                  >
                                    <Grid item xs={12}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={qualification}
                                          onChange={this.handleChange}
                                          name="qualification"
                                          fullWidth
                                          id="outlined-basic"
                                          label="Qualification"
                                          variant="outlined"
                                        />
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={institution}
                                          onChange={this.handleChange}
                                          name="institution"
                                          fullWidth
                                          id="outlined-basic"
                                          label="Institution"
                                          variant="outlined"
                                        />
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={academic_year}
                                          onChange={this.handleChange}
                                          name="academic_year"
                                          fullWidth
                                          id="outlined-basic"
                                          label="Year"
                                          variant="outlined"
                                        />
                                      </Item>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Item
                                        elevation={0}
                                        sx={{
                                          lineHeight: "20px",
                                          padding: "4px 0",
                                          fontWeight: "bold",
                                          backgroundColor: "#E0E8ED",
                                        }}
                                      >
                                        <TextField
                                          value={other_details}
                                          onChange={this.handleChange}
                                          name="other_details"
                                          fullWidth
                                          id="outlined-basic"
                                          label="Other Details"
                                          variant="outlined"
                                        />
                                      </Item>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                  <Item
                                    elevation={0}
                                    sx={{
                                      lineHeight: "30vh",
                                      padding: "4px 0",
                                      fontWeight: "bold",
                                      backgroundColor: "#E0E8ED",
                                    }}
                                  >
                                    <TextField
                                      value={other_qualification}
                                      onChange={this.handleChange}
                                      name="other_qualification"
                                      multiline
                                      rows={10}
                                      fullWidth
                                      id="outlined-basic"
                                      label="Other Qualifications"
                                      variant="outlined"
                                    />
                                  </Item>
                                </Grid>
                              </Grid>
                            </Item>

                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 200,
                                bgcolor: "background.paper",
                              }}
                              subheader={
                                <ListSubheader sx={{ fontWeight: "bold" }}>
                                  5. Work Experience
                                </ListSubheader>
                              }
                            ></List>

                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                sx={{ pl: 5, pr: 5 }}
                              >
                                <Grid item xs={12}>
                                  <Item
                                    elevation={0}
                                    sx={{
                                      height: "250px",
                                      lineHeight: "20px",
                                      padding: "4px 0",
                                      fontWeight: "bold",
                                      backgroundColor: "#E0E8ED",
                                    }}
                                  >
                                    <TextField
                                      value={work_experience}
                                      onChange={this.handleChange}
                                      name="work_experience"
                                      multiline
                                      rows={11}
                                      fullWidth
                                      id="outlined-textarea"
                                      label="Work experience"
                                      variant="outlined"
                                    />
                                  </Item>
                                </Grid>
                              </Grid>
                            </Item>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>

                    <Grid item xs={12}>
                      <Item elevation={8}>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          sx={{ p: 2 }}
                        >
                          <Grid item xs={12}>
                            <List
                              sx={{
                                width: "100%",
                                maxWidth: 200,
                                bgcolor: "background.paper",
                              }}
                              subheader={
                                <ListSubheader sx={{ fontWeight: "bold" }}>
                                  6. Terms and Condtions
                                </ListSubheader>
                              }
                            ></List>

                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <Typography variant="subtitle1" gutterBottom>
                                6.1 Course fees paid are not refundable under
                                any circumstances.
                              </Typography>

                              <Typography variant="subtitle1" gutterBottom>
                                6.2 Course fee may be transferred, under special
                                circumstances from one course to another in
                                favour of the same student
                              </Typography>

                              <Typography variant="subtitle1" gutterBottom>
                                6.3 The Management reserves the right to alter
                                the time table at any time after the
                                commencement of the course
                              </Typography>

                              <Typography variant="subtitle1" gutterBottom>
                                6.4 Students must be abide by the Student
                                Charter, regulations, rules and dress code by
                                CGC
                              </Typography>

                              <Typography variant="subtitle1" gutterBottom>
                                6.5 Student exam admission and/or results may be
                                withheld for non-payment of the course fee
                                instalment on due date
                              </Typography>

                              <Typography variant="subtitle1" gutterBottom>
                                6.6 The qualifcation can only be awarded after
                                all assessment requirements have been met and
                                all fees have been paid to CGC
                              </Typography>

                              <Typography variant="subtitle2" gutterBottom>
                                I confrm that the information given in this form
                                is correct and complete. I have read and
                                understood the terms and conditions and agreed
                                to abide by the terms and conditions set
                                outabide, which I accept as conditions of this
                                application.
                              </Typography>

                              <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                              >
                                <Grid item xs={4}>
                                  <Item sx={{ backgroundColor: "#E0E8ED" }}>
                                    Upload signature here
                                  </Item>
                                  <Typography variant="h6" gutterBottom>
                                    Signature of the Applicant
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Item>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>

                    <Grid item xs={12}>
                      <Item elevation={8}>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          sx={{ p: 2 }}
                        >
                          <Grid item xs={12}>
                            <Item sx={{ backgroundColor: "#E0E8ED" }}>
                              OFFICE USE ONLY
                            </Item>
                          </Grid>
                          <Grid item xs={12}>
                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <FormControlLabel
                                value={ol}
                                control={
                                  <Checkbox
                                    name="ol"
                                    checked={ol === 1}
                                    onChange={this.handleChange}
                                    disabled={isStudent}
                                  />
                                }
                                label="G.C.E (O/L)"
                                labelPlacement="start"
                              />
                              <FormControlLabel
                                value={al}
                                control={
                                  <Checkbox
                                    name="al"
                                    checked={al === 1}
                                    onChange={this.handleChange}
                                    disabled={isStudent}
                                  />
                                }
                                label="G.C.E (A/L)"
                                labelPlacement="start"
                              />
                              <FormControlLabel
                                value={english_ability}
                                control={
                                  <Checkbox
                                    name="english_ability"
                                    checked={english_ability === 1}
                                    onChange={this.handleChange}
                                    disabled={isStudent}
                                  />
                                }
                                label="ENGLISH ABILITY"
                                labelPlacement="start"
                              />
                            </Item>
                          </Grid>

                          <Grid item xs={12}>
                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <TextField
                                disabled={isStudent}
                                value={min_entry_qualification}
                                onChange={this.handleChange}
                                multiline
                                rows={5}
                                name="min_entry_qualification"
                                fullWidth
                                id="outlined-textarea"
                                label="Minimum Entry Qualification"
                                variant="outlined"
                              />
                            </Item>
                          </Grid>

                          <Grid item xs={12}>
                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <TextField
                                disabled={isStudent}
                                value={name_designation}
                                onChange={this.handleChange}
                                name="name_designation"
                                fullWidth
                                id="outlined-textarea"
                                label="Name / Designation"
                                variant="outlined"
                              />
                            </Item>
                          </Grid>

                          <Grid item xs={12}>
                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <FormControl sx={{ height: "100%" }}>
                                <RadioGroup
                                  row
                                  name="status"
                                  value={status}
                                  onChange={this.handleChange}
                                  disabled={isStudent}
                                >
                                  <FormControlLabel
                                    sx={{ height: "20px" }}
                                    value={1}
                                    label="APPROVED"
                                    control={
                                      <Radio
                                        disabled={isStudent}
                                        sx={{ height: "10px" }}
                                      />
                                    }
                                  />
                                  <FormControlLabel
                                    sx={{ height: "20px" }}
                                    value={0}
                                    label="PENDING"
                                    control={
                                      <Radio
                                        disabled={isStudent}
                                        sx={{ height: "10px" }}
                                      />
                                    }
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Item>
                          </Grid>

                          <Grid item xs={12}>
                            <Item
                              elevation={0}
                              sx={{ pl: 5, pr: 5, backgroundColor: "#E0E8ED" }}
                            >
                              <List
                                sx={{ width: "100%", maxWidth: 200 }}
                                subheader={
                                  <ListSubheader sx={{ fontWeight: "bold" }}>
                                    Overseas Centre
                                  </ListSubheader>
                                }
                              ></List>

                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                sx={{ p: 2 }}
                              >
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    value={overseas_nic_passport}
                                    control={
                                      <Checkbox
                                        name="overseas_nic_passport"
                                        disabled={isStudent}
                                        checked={overseas_nic_passport === 1}
                                        onChange={this.handleChange}
                                      />
                                    }
                                    label="Copy of NIC / Passport"
                                    labelPlacement="end"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    value={overseas_educational_doc}
                                    control={
                                      <Checkbox
                                        name="overseas_educational_doc"
                                        disabled={isStudent}
                                        checked={overseas_educational_doc === 1}
                                        onChange={this.handleChange}
                                      />
                                    }
                                    label="Educational Documents"
                                    labelPlacement="end"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    value={overseas_color_photo}
                                    control={
                                      <Checkbox
                                        name="overseas_color_photo"
                                        disabled={isStudent}
                                        checked={overseas_color_photo === 1}
                                        onChange={this.handleChange}
                                      />
                                    }
                                    label="Colour Photograph"
                                    labelPlacement="end"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    value={overseas_work_experience_doc}
                                    control={
                                      <Checkbox
                                        disabled={isStudent}
                                        name="overseas_work_experience_doc"
                                        checked={
                                          overseas_work_experience_doc === 1
                                        }
                                        onChange={this.handleChange}
                                      />
                                    }
                                    label="Work Experience Documents"
                                    labelPlacement="end"
                                  />
                                </Grid>
                              </Grid>
                            </Item>
                          </Grid>

                          <Grid item xs={12}>
                            <Item elevation={0} sx={{ pl: 5, pr: 5 }}>
                              <Typography variant="subtitle1" gutterBottom>
                                No. 78 1/1, Havelock Road, Colombo 05.
                              </Typography>
                              <Typography variant="subtitle1" gutterBottom>
                                T: +9477 7914857 | +9411 349 7588 | E:
                                Info@cgcedu.lk | W: http://cgcedu.lk
                              </Typography>
                            </Item>
                          </Grid>
                        </Grid>
                      </Item>
                    </Grid>

                    <Grid item xs={12}>
                      <Item elevation={0}>
                        <Button
                          outlined
                          onClick={() => this.handleUpdateSubmit()}
                        >
                          Update
                        </Button>
                      </Item>
                    </Grid>
                  </ThemeProvider>
                ))}
              </Grid>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
