import { libraryService } from '../../service/assessment/libraryService';
import { assessmentTypes, libraryTypes } from '../../reducers/types';
import {store} from "../../helper/redux_store";
import moment from "moment";

export const libraryActions = {
    listManagedAssessments,
    getAllMyAssessments,
    listAssessedAssessments,
    getAllSharedAssessments,
    listAdministratedAssessments,
    viewAssessment,
    viewAssessmentData,
    getAssessors,
    getCandidates,
    getCandidate,
    getSchedule,
    clearAssessmentView,
    getAllPublicAssessments
};

function listManagedAssessments(obj) {
    return dispatch => {
        dispatch(list_assessment());
        dispatch(list_assessment_request());

        libraryService.listManagedAssessments(obj).then(
            data => {
                dispatch(list_assessment_success(data.data))
            },
            error => {
                dispatch(list_assessment_failure(error))
            }
        )
    }

    function list_assessment() {
        return { type: libraryTypes.LIST_MANAGED_ASSESSMENT }
    }

    function list_assessment_request() {
        return { type: libraryTypes.LIST_MANAGED_ASSESSMENT_REQUEST }
    }

    function list_assessment_success(data) {
        return { type: libraryTypes.LIST_MANAGED_ASSESSMENT_SUCCESS, data }
    }

    function list_assessment_failure(error) {
        return { type: libraryTypes.LIST_MANAGED_ASSESSMENT_FAILURE, error }
    }

}

function getAllMyAssessments(obj, limit, offset, sort_column, sort_direction, search_term, selectCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses) {
    return async dispatch => {
        dispatch(get_all_my_assessments());
        dispatch(get_all_my_assessments_request());

        await libraryService.getAllMyAssessments(obj, limit, offset, sort_column, sort_direction, search_term, selectCategories, selectedAuthors, selectedChapters,
            selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses).then(
                data => {
                    dispatch(get_all_my_assessments_success(data.data))
                },
                error => {
                    dispatch(get_all_my_assessments_failure(error))
                }
            )
    }

    function get_all_my_assessments() {
        return { type: libraryTypes.GET_ALL_MY_ASSESSMENTS }
    }

    function get_all_my_assessments_request() {
        return { type: libraryTypes.GET_ALL_MY_ASSESSMENTS_REQUEST }
    }

    function get_all_my_assessments_success(data) {
        return { type: libraryTypes.GET_ALL_MY_ASSESSMENTS_SUCCESS, data }
    }

    function get_all_my_assessments_failure(error) {
        return { type: libraryTypes.GET_ALL_MY_ASSESSMENTS_FAILURE, error }
    }

}

function listAssessedAssessments(obj) {
    return dispatch => {
        dispatch(list_assessment());
        dispatch(list_assessment_request());

        libraryService.listAssessoredAssessments(obj).then(
            data => {
                dispatch(list_assessment_success(data.data))
            },
            error => {
                dispatch(list_assessment_failure(error))
            }
        )
    }

    function list_assessment() {
        return { type: libraryTypes.LIST_ASSESSED_ASSESSMENT }
    }

    function list_assessment_request() {
        return { type: libraryTypes.LIST_ASSESSED_ASSESSMENT_REQUEST }
    }

    function list_assessment_success(data) {
        return { type: libraryTypes.LIST_ASSESSED_ASSESSMENT_SUCCESS, data }
    }

    function list_assessment_failure(error) {
        return { type: libraryTypes.LIST_ASSESSED_ASSESSMENT_FAILURE, error }
    }

}

function getAllSharedAssessments(obj, limit, offset, sort_column, sort_direction, search_term, selectCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses) {
    return async dispatch => {
        dispatch(get_all_shared_assessments());
        dispatch(get_all_shared_assessments_request());

        await libraryService.getAllSharedAssessments(obj, limit, offset, sort_column, sort_direction, search_term, selectCategories, selectedAuthors, selectedChapters,
            selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses).then(
                data => {
                    dispatch(get_all_shared_assessments_success(data.data))
                },
                error => {
                    dispatch(get_all_shared_assessments_failure(error))
                }
            )
    }

    function get_all_shared_assessments() {
        return { type: libraryTypes.GET_ALL_SHARED_ASSESSMENTS }
    }

    function get_all_shared_assessments_request() {
        return { type: libraryTypes.GET_ALL_SHARED_ASSESSMENTS_REQUEST }
    }

    function get_all_shared_assessments_success(data) {
        return { type: libraryTypes.GET_ALL_SHARED_ASSESSMENTS_SUCCESS, data }
    }

    function get_all_shared_assessments_failure(error) {
        return { type: libraryTypes.GET_ALL_SHARED_ASSESSMENTS_FAILURE, error }
    }

}

function getAllPublicAssessments(limit, offset, sort_column, sort_direction, search_term, selectCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses) {
    return async dispatch => {
        dispatch(get_all_public_assessments());
        dispatch(get_all_public_assessments_request());

        await libraryService.getAllPublicAssessments(limit, offset, sort_column, sort_direction, search_term, selectCategories, selectedAuthors, selectedChapters,
            selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses).then(
                data => {
                    dispatch(get_all_public_assessments_success(data.data))
                },
                error => {
                    dispatch(get_all_public_assessments_failure(error))
                }
            )
    }

    function get_all_public_assessments() {
        return { type: libraryTypes.GET_ALL_PUBLIC_ASSESSMENTS }
    }

    function get_all_public_assessments_request() {
        return { type: libraryTypes.GET_ALL_PUBLIC_ASSESSMENTS_REQUEST }
    }

    function get_all_public_assessments_success(data) {
        return { type: libraryTypes.GET_ALL_PUBLIC_ASSESSMENTS_SUCCESS, data }
    }

    function get_all_public_assessments_failure(error) {
        return { type: libraryTypes.GET_ALL_PUBLIC_ASSESSMENTS_FAILURE, error }
    }

}

function listAdministratedAssessments(obj) {
    return dispatch => {
        dispatch(list_assessment());
        dispatch(list_assessment_request());

        libraryService.listAdministratedAssessments(obj).then(
            data => {
                dispatch(list_assessment_success(data.data))
            },
            error => {
                dispatch(list_assessment_failure(error))
            }
        )
    }

    function list_assessment() {
        return { type: libraryTypes.LIST_ADMIN_ASSESSMENT }
    }

    function list_assessment_request() {
        return { type: libraryTypes.LIST_ADMIN_ASSESSMENT_REQUEST }
    }

    function list_assessment_success(data) {
        return { type: libraryTypes.LIST_ADMIN_ASSESSMENT_SUCCESS, data }
    }

    function list_assessment_failure(error) {
        return { type: libraryTypes.LIST_ADMIN_ASSESSMENT_FAILURE, error }
    }

}

function viewAssessment(id) {
    return async dispatch => {
        try {
            dispatch(view_request(id));
            let data = await libraryService.viewAssessment(id);
            dispatch(view_success(data.data))
        } catch (error) {
            dispatch(view_failure(error))
        }
    }

    function view_request(id) {
        return { type: libraryTypes.VIEW_ASSESSMENT_REQUEST, id }
    }

    function view_success(data) {
        let users = store.getState()['userReducer']['userMap'];
        let details = data.assessment;

        let user = users[details['assessment_admin']]
        if (user) {
            details['assessment_admin_name'] = user['user_first_name'] + " " + user['user_last_name'];
            details['admin_id'] = user['user_id'];
        }

        return { type: libraryTypes.VIEW_ASSESSMENT_SUCCESS, details }
    }

    function view_failure(error) {
        return { type: libraryTypes.VIEW_ASSESSMENT_FAILURE, error }
    }

}

function viewAssessmentData(id) {
    return async dispatch => {
        try {
            dispatch(view_assessment_data_request(id));
            let data = await libraryService.viewAssessmentData(id);
            dispatch(view_assessment_data_success(data.data))
        } catch (error) {
            dispatch(view_assessment_data_failure(error))
        }
    }
    
    function view_assessment_data_request(id) {
        return { type: libraryTypes.VIEW_ASSESSMENT_DATA_REQUEST, id }
    }
    
    function view_assessment_data_success(data) {
        let staffusers = store.getState()['staffUserReducer']['staffUserMap'];
        let assessor, staffuser, details;
        let assessor_list = []
        let assessors = data.view_assessment_data.find(item => 'assessors' in item)?.assessors;
        
        for (assessor of assessors) {
            staffuser = staffusers[assessor['user_id']]
            if (staffuser) {
                details = assessor;
                details['staff_id'] = staffuser['staff_id'];
                details['id'] = staffuser['user_id'].toString();
                assessor_list.push(details);
            }
        }

        assessors = assessor_list;

        let schedule_list = [];
        let schedule, schedule_details;
        let schedules =  data.view_assessment_data.find(item => 'schedule' in item)?.schedule;
        
        for (schedule of schedules) {
            if (schedule) {
                schedule_details = schedule;

                var _time = new Date(schedule['start_time'].concat("Z"))
                schedule['start_time'] = _time
                
                let timeStamp = schedule['start_time'];
                let examTimeInterval = schedule['exam_time_interval'];
                let timeUnit = schedule['exam_time_interval_unit_id']
                let bufferTime = schedule['buffer_time'];
                let bufferTimeUnit = schedule['buffer_time_unit_id'];
                let setTimeLimit = schedule['is_set_time_limit'];

                let d = new Date(timeStamp)
                let startDate = d.toDateString();
                let startTime = d.toLocaleTimeString('en-SL');

                schedule_details['startTime'] = startTime;
                schedule_details['startDate'] = startDate;
                schedule_details['examTimeInterval'] = examTimeInterval;
                schedule_details['timeUnit'] = timeUnit;
                schedule_details['bufferTime'] = bufferTime;
                schedule_details['bufferTimeUnit'] = bufferTimeUnit;
                schedule_details['setTimeLimit'] = setTimeLimit;
                schedule_details['local'] = moment(timeStamp).local().format('YYYY-MM-DDTHH:mm')

                schedule_list.push(schedule_details);
            }
        }

        schedules = schedule_list

        return { type: libraryTypes.VIEW_ASSESSMENT_DATA_SUCCESS, data }
    }

    function view_assessment_data_failure(error) {
        return { type: libraryTypes.VIEW_ASSESSMENT_DATA_FAILURE, error }
    }

}

function getAssessors(id) {

    return async dispatch => {
        dispatch(get_assessors(id));
        dispatch(get_assessors_request());

        try {
            let assessors = await libraryService.getAssessors(id);
            dispatch(getAssessorsDetails(assessors.data))
        } catch (error) {
            dispatch(get_assessors_failure(error))
        }
    }

    function getAssessorsDetails(assessors) {

        let staffusers = store.getState()['staffUserReducer']['staffUserMap'];
        let assessor, staffuser, details;
        let assessor_list = []

        for (assessor of assessors.assessmentAssessor) {
            staffuser = staffusers[assessor['user_id']]
            if (staffuser) {
                details = assessor;
                details['staff_id'] = staffuser['staff_id'];
                details['id'] = staffuser['user_id'].toString();
                details['name'] = "Assessor One";
                assessor_list.push(details);
            }
        }
        return { type: assessmentTypes.GET_ASSESSMENT_ASSESSORS_SUCCESS, assessor_list }
    }

    function get_assessors(assessment) {
        return { type: assessmentTypes.GET_ASSESSMENT_ASSESSORS, assessment }
    }

    function get_assessors_request() {
        return { type: assessmentTypes.GET_ASSESSMENT_ASSESSORS_REQUEST }
    }

    function get_assessors_success(assessors) {
        return { type: assessmentTypes.GET_ASSESSMENT_ASSESSORS_SUCCESS, assessors }
    }

    function get_assessors_failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_ASSESSORS_FAILURE, error }
    }

}

function getCandidates(id) {

    return async dispatch => {
        try {
            dispatch(get_candidates(id));
            dispatch(get_candidates_request(id));
            let candidates = await libraryService.getCandidates(id);
            dispatch(getCandidateDetails(candidates.data));
        } catch (error) {
            dispatch(get_candidates_failure(error))
        }
    }

    function get_candidates(id) {
        return { type: assessmentTypes.GET_CANDIDATES, id }
    }

    function get_candidates_request(id) {
        return { type: assessmentTypes.GET_CANDIDATES_REQUEST, id }
    }

    function getCandidateDetails(candidates_list) {

        let users = store.getState()['userReducer']['userMap'];
        let candidate, user, details;
        let candidates = []

        for (candidate of candidates_list.assessmentCandidate) {
            user = users[candidate['user_id']]

            if (user) {
                details = candidate;
                details['display_name'] = user['user_first_name'] + " " + user['user_last_name'];
                details['candidate_id'] = user['user_id'];
                details['candidate_email'] = user['user_email'];

                candidates.push(details);
            }
        }

        return { type: assessmentTypes.GET_CANDIDATES_SUCCESS, candidates }
    }

    function get_candidates_success(candidates) {
        return { type: assessmentTypes.GET_CANDIDATES_SUCCESS, candidates }
    }

    function get_candidates_failure(error) {
        return { type: assessmentTypes.GET_CANDIDATES_FAILURE, error }
    }

}

function getCandidate(id) {

    return async dispatch => {
        try {
            dispatch(get_candidate(id));
            dispatch(get_candidate_request(id));
            let candidate = await libraryService.getCandidate(id);
            dispatch(get_candidate_success(candidate.data));
        } catch (error) {
            dispatch(get_candidate_failure(error))
        }
    }

    function get_candidate(id) {
        return { type: assessmentTypes.GET_CANDIDATE, id }
    }

    function get_candidate_request(id) {
        return { type: assessmentTypes.GET_CANDIDATE_REQUEST, id }
    }

    function get_candidate_success(candidate) {
        return { type: assessmentTypes.GET_CANDIDATE_SUCCESS, candidate }
    }

    function get_candidate_failure(error) {
        return { type: assessmentTypes.GET_CANDIDATE_FAILURE, error }
    }

}

function getSchedule(id) {

    return async dispatch => {
        try {
            dispatch(get_schedule(id));
            dispatch(get_schedule_request(id));
            let schedules = await libraryService.getSchedule(id);
            dispatch(getScheduleDetails(schedules.data));
        } catch (error) {
            dispatch(get_schedule_failure(error));
        }
    }

    function get_schedule(id) {
        return { type: assessmentTypes.GET_SCHEDULE, id }
    }

    function get_schedule_request(id) {
        return { type: assessmentTypes.GET_SCHEDULE_REQUEST, id }
    }

    function getScheduleDetails(data) {
        let schedule = [];
        let details = data.assessmentSchedule;
        var _time = new Date(details.start_time.concat("Z"))
        details.start_time = _time
        let timeStamp = details['start_time'];
        let examTimeInterval = details['exam_time_interval'];
        let bufferTime = details['buffer_time'];
        let timeUnit = schedule['exam_time_interval_unit_id']
        let bufferTimeUnit = schedule['buffer_time_unit_id'];
        let setTimeLimit = schedule['is_set_time_limit'];

        let d = new Date(timeStamp)
        let startDate = d.toDateString();
        let startTime = d.toLocaleTimeString('en-SL');

        schedule[0] = startDate;
        schedule[1] = startTime;
        schedule[2] = examTimeInterval;
        schedule[3] = bufferTime;
        schedule[4] = moment(timeStamp).local().format('YYYY-MM-DDTHH:mm');
        schedule[5] = timeUnit;
        schedule[6] = bufferTimeUnit;
        schedule[7] = setTimeLimit;

        return { type: assessmentTypes.GET_SCHEDULE_SUCCESS, schedule }
    }

    function get_schedule_success(schedule) {
        return { type: assessmentTypes.GET_CANDIDATES_SUCCESS, schedule }
    }

    function get_schedule_failure(error) {
        return { type: assessmentTypes.GET_SCHEDULE_FAILURE, error }
    }
}

function clearAssessmentView() {
    return dispatch => {
        dispatch(clearAssessmentView());
    }

    function clearAssessmentView() {
        return { type: libraryTypes.VIEW_ASSESSMENT_REFRESH }
    }
}
