import {assessAttemptTypes} from '../../../reducers/settings/settingsTypes'
import {assessAttemptService} from '../../../service/settings/assessment/attemptService'


export const assessAttemptActions = {
    getAllAssessmentAttempts,
    createAttempt,
    updateAttempt,
    deleteAttempt
}

function getAllAssessmentAttempts(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let attempts = await assessAttemptService.getAllAssessmentAttempts(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(attempts.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: assessAttemptTypes.GET_ALL_ASSESSMENT_ATTEMPTS}
    }

    function request() {
        return {type: assessAttemptTypes.GET_ALL_ASSESSMENT_ATTEMPTS_REQUEST}
    }

    function success(attempts) {
        return {type: assessAttemptTypes.GET_ALL_ASSESSMENT_ATTEMPTS_SUCCESS, attempts}
    }

    function failure(error) {
        return {type: assessAttemptTypes.GET_ALL_ASSESSMENT_ATTEMPTS_FAILURE, error}
    }
}


function createAttempt(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await assessAttemptService.createAttempt(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: assessAttemptTypes.CREATE_ATTEMPT_REQUEST, data}
    }

    function success(attempts) {
        return {type: assessAttemptTypes.CREATE_ATTEMPT_SUCCESS, attempts}
    }

    function failure(error) {
        return {type: assessAttemptTypes.CREATE_ATTEMPT_FAILURE, error}
    }
}


function deleteAttempt(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await assessAttemptService.deleteAttempt(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(attempt) {
        return {type: assessAttemptTypes.DELETE_ATTEMPT_REQUEST, attempt}
    }

    function success(success) {
        return {type: assessAttemptTypes.DELETE_ATTEMPT_SUCCESS, success}
    }

    function failure(error) {
        return {type: assessAttemptTypes.DELETE_ATTEMPT_FAILURE, error}
    }
}

function updateAttempt(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await assessAttemptService.updateAttempt(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: assessAttemptTypes.UPDATE_ATTEMPT_REQUEST}
    }

    function success(attempt) {
        return {type: assessAttemptTypes.UPDATE_ATTEMPT_SUCCESS, attempt}
    }

    function failure(error) {
        return {type: assessAttemptTypes.UPDATE_ATTEMPT_FAILURE, error}
    }
}
