import React, { Component } from "react";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader"
import CardBody from "../../../components/common/CardBody";
import CardTitle from "../../../components/common/CardTitle";
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import "../../../components/admin/css/common.css";
import sweetAlert, {
    sweetConfirmAlert,
    sweetStatusAlert,
    sweetAlertInfo,
} from "../../../components/common/SweetAlert";
import SimpleReactValidator from "simple-react-validator";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { LevelService } from "../../../service/manage/subscription/level.service";
import { LevelGradeService } from "../../../service/manage/subscription/level_grade.service";
import { GradeService } from "../../../service/settings/manage/grade.service.js";
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class LevelGrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      showModal: false,
      isLoading: false,
      isTableDataLoading: true,
      isModified: false,
      selectedStudentId: "",
      selectedStudentTable: "",
      selectedStudent: null,
      search_id: "",
      isStudentsLoading: false,
      isSubmitting: false,
      registration_id: "",
      editVisibility: false,
      addVisibility: false,
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      levels: "",
      grades: "",
      assessment_grade_id: "",
      level_id: "",
      name: "",
      definition: "",
      description: "",
      level_grades: "",
      level_grade_id: "",
      userType: "",
      levelGradePrivilege: null,
      form_list_id: "",
    };

    this.createValidationsInstance();
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const {
      privilege_add,
      privilege_edit,
      breadcrumb2,
      nodeTitle,
      flg_list_active,
      form_list_id,
    } = this.props.props;
    this.getVisibilityCheck(privilege_add, privilege_edit);
    this.getAllLevelGrades();
    this.getAllLevels();
    this.getAllAssessmentGrades();
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle,
      flg_list_active,
      userType: sessionStorage.getItem("userType"),
      levelGradePrivilege: this.props.levelGradePrivilege,
      form_list_id: form_list_id,
    });
  }

  getAllLevelGrades() {
    this.setState({
      isTableDataLoading: true,
    });
    const mid = 100;
    LevelGradeService.getAllLevelGrades(mid)
      .then((res) => {
        if (res.success) {
          this.setState({ level_grades: res.data.level_grades });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Level Grades. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getAllLevels() {
    this.setState({
      isTableDataLoading: true,
    });
    const mid = 100;
    LevelService.getAllLevels(mid)
      .then((res) => {
        if (res.success) {
          this.setState({ levels: res.data.levels });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Level Grades. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getAllAssessmentGrades() {
    this.setState({
      isTableDataLoading: true,
    });
    GradeService
      .getGrades()
      .then((res) => {
        if (res.data) {
          this.setState({ grades: res.data });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Level Grades. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getVisibilityCheck(privilege_add, privilege_edit) {
    if (privilege_add == "1") {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_edit == "1") {
      this.setState({
        editVisibility: true,
      });
    }
  }

  submitLevel = () => {
    if (this.validator.allValid()) {
      this.setState({ showInsertModal: false, isLoading: true });
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");

      const { level_id, assessment_grade_id, form_list_id } = this.state;

      let obj = {
        node_mid: mid,
        level_id: level_id,
        assessment_grade_id: assessment_grade_id,
        user_id: session_id,
        user_table_id: table_log_id,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
        form_list_id: form_list_id,
      };
      LevelGradeService.createLevelGrade(JSON.stringify(obj))
        .then((res) => {
          if (res.success) {
            this.setState({ showInsertModal: false });
            this.getAllLevelGrades();
            sweetStatusAlert("Success!", res.message, "success");
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert(
            "Failed !",
            "Unable to add the Level Grade!",
            "error"
          );
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  updateLevel = () => {
    if (this.validator.allValid()) {
      this.setState({ showModal: false, isLoading: true });
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");
      const { level_grade_id, assessment_grade_id, level_id } = this.state;

      let obj = {
        node_mid: mid,
        level_id: level_id,
        assessment_grade_id: assessment_grade_id,
        user_id: session_id,
        user_table_id: table_log_id,
      };
      LevelGradeService.updateLevelGrade(JSON.stringify(obj), level_grade_id)
        .then((res) => {
          if (res.success) {
            this.setState({ showModal: false });
            this.getAllLevelGrades();
            sweetStatusAlert("Success!", res.message, "success");
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert(
            "Failed !",
            "Unable to add the Level Grade!",
            "error"
          );
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  deleteLevel = (level_grade_id) => {
    sweetConfirmAlert(
      "Are you sure?",
      "Do you want to delete this Level Grade?",
      "warning"
    ).then(() => {
      this.setState({ isLoading: true });
      LevelGradeService.deleteLevelGrade(
        level_grade_id,
        this.state.form_list_id
      )
        .then((res) => {
          if (res.status === 200) {
            this.setState({ showModal: false });
            this.getAllLevelGrades();
            sweetStatusAlert("Success!", res.message, "success");
          } else if(res.status === 400){
            sweetAlertInfo(
              "",
              "Cannot delete level grade. This level grade already in use.",
            );
          }
          else{
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert(
            "Failed !",
            "Unable to add the Level Grade!",
            "error"
          );
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    });
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  toggleAddLevelModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        level_id: "",
        assessment_grade_id: "",
      }),
      () => {}
    );
  };

  toggleUpdateLevelModal = (level_grade) => {
    this.setState(
      (prevState) => ({
        showModal: !prevState.showModal,
        level_id: "",
        assessment_grade_id: "",
      }),
      () => {
        if (this.state.showModal) {
          this.setState({
            level_grade_id: level_grade.level_grade_id,
            level_id: level_grade.level_id,
            assessment_grade_id: level_grade.assessment_grade_id,
          });
        }
      }
    );
  };

  render() {
    const {
      isLoading,
      isTableDataLoading,
      editVisibility,
      levels,
      level_id,
      grades,
      assessment_grade_id,
      level_grades,
      levelGradePrivilege,
    } = this.state;
    this.validator.purgeFields();

    if (!levelGradePrivilege) {
      return (
        <div>
          <h4>Privilege not found for this section</h4>
        </div>
      );
    }
    return (
      <>
        <Card>
          <CardHeader>
            <div>
              {/* <CardTitle cardLabel={history.location.state.form_display_title} /> */}
              <CardTitle cardLabel={"Level Grades"} />
              {/* <h3 style={{ color: 'gray' }}>{(breadcrumb2 || nodeTitle) ? `(${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle})` : undefined}</h3> */}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {levelGradePrivilege.addVisibility && (
                <Button
                  className="insert-btn"
                  variant="primary"
                  onClick={this.toggleAddLevelModal}
                >
                  Add Level Grade
                </Button>
              )}
            </div>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div className="loading-container">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div style={{ overflow: "Auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Level Grade Id
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Level
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Assessment Grade
                      </TableCell>
                      {(levelGradePrivilege.addVisibility ||
                        levelGradePrivilege.editVisibility ||
                        levelGradePrivilege.deleteVibility) && (
                        <TableCell
                          className="thead"
                          style={{ textAlign: "center" }}
                        >
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {level_grades &&
                      level_grades.length > 0 &&
                      level_grades.map((level_grade) => (
                        <TableRow key={level_grade.level_grade_id}>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {level_grade.level_grade_id}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {level_grade.level}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {grades &&
                              grades.length > 0 &&
                              grades.map((grade) =>
                                level_grade.assessment_grade_id ===
                                grade.grade_id ? (
                                  <span key={grade.grade_id}>{grade.grade}</span>
                                ) : null
                              )}
                          </TableCell>
                          {(levelGradePrivilege.addVisibility ||
                            levelGradePrivilege.editVisibility ||
                            levelGradePrivilege.deleteVibility) && (
                            <TableCell
                              className="tData"
                              style={{ textAlign: "center" }}
                            >
                              <div className="btn-group">
                                {/* {levelGradePrivilege.editVisibility && (
                                                                <Button
                                                                    className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                                                    title="Edit Details"
                                                                    onClick={() =>
                                                                        this.toggleUpdateLevelModal(level_grade)
                                                                    }
                                                                />
                                                            )} */}
                                {levelGradePrivilege.deleteVibility && (
                                  <Button
                                    className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                    title="Delete Details"
                                    onClick={() =>
                                      this.deleteLevel(
                                        level_grade.level_grade_id
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() => this.setState({ showInsertModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new Level Grade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>level*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="level_id"
                    onChange={this.handleChange}
                    value={level_id}
                  >
                    <option value="">Select</option>
                    {levels.length > 0 &&
                      levels.map((level) => (
                        <option value={level.level_id} key={level.level_id}>
                          {level.name}
                        </option>
                      ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("level", level_id, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Grade*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="assessment_grade_id"
                    onChange={this.handleChange}
                    value={assessment_grade_id}
                  >
                    <option value="">Select</option>
                    {grades.length > 0 &&
                      grades.map((grade) => (
                        <option value={grade.grade_id} key={grade.grade_id}>
                          {grade.grade}
                        </option>
                      ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "grade",
                      assessment_grade_id,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.setState({ showInsertModal: false })}
                >
                  Close
                </Button>
                {editVisibility && (
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.submitLevel}
                  >
                    Save changes
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Level Grade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>level*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="level_id"
                    onChange={this.handleChange}
                    value={level_id}
                    disabled
                  >
                    <option value="">Select</option>
                    {levels.length > 0 &&
                      levels.map((level) => (
                        <option value={level.level_id} key={level.level_id}>
                          {level.name}
                        </option>
                      ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("level", level_id, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Grade*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="assessment_grade_id"
                    onChange={this.handleChange}
                    value={assessment_grade_id}
                    disabled
                  >
                    <option value="">Select</option>
                    {grades.length > 0 &&
                      grades.map((grade) => (
                        <option value={grade.grade_id} key={grade.grade_id}>
                          {grade.grade}
                        </option>
                      ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "grade",
                      assessment_grade_id,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.setState({ showModal: false })}
                >
                  Close
                </Button>
                {editVisibility && (
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.updateLevel}
                  >
                    Save changes
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
