import React, { Component } from "react";
import ContentWrapper from "../../../../components/common/ContentWrapper.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../../../../components/admin/css/common.css";
import { history } from "../../../../helper/history.js";
import Levels from "../Levels.js";
import LevelGrade from "../LevelGrades.js";
import Type from "../Type.js";
import Subscription from "../LevelPrice.js";
import Transactions from "./Transactions.js";
import Credits from "./Credits.js";
import CreditPackages from "./CreditPackages.js"
import { TreeMainService } from "../../../../service/manage/h_tree/tree_main.service.js";
import Spinner from "react-bootstrap/Spinner";
import config from "../../../../helper/config.js";
import { customerConstants } from "../../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let tabTextColor;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    tabTextColor = "var(--maincolor)";
    break;
  case customerConstants.EXO_CUSTOMER2:
    tabTextColor = "var(--maincolor-cmc)";
    break;
  default:
    tabTextColor = "var(--maincolor)";
    break;
}

const privileges = {
  tabVisibility: false,
  addVisibility: false,
  editVisibility: false,
  deleteVibility: false,
  viewVisibility: false,
  isAdmin: false,
  isStudent: false,
  isParent: false,
};

export default class ManageSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      showInsertModal: false,
      showModal: false,
      isLoading: false,
      isTableDataLoading: true,
      isModified: false,
      selectedStudentId: "",
      selectedStudentTable: "",
      selectedStudent: null,
      search_id: "",
      isStudentsLoading: false,
      isSubmitting: false,
      registration_id: "",
      editVisibility: false,
      addVisibility: false,
      packages: null,
      type: "",
      credits: "",
      amount: "",
      currency: "",
      period: "",
      selectedStudent: null,
      selectedPackage: null,
      userType: "",
      levelPrivilege: privileges,
      levelGradePrivilege: privileges,
      typesPrivilege: privileges,
      levelPricePrivilege: privileges,
      creditsPrivilege: privileges,
      transactionsPrivilege: privileges,
      target_tab_id:0
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  componentDidMount() {
    const privilege_add = this.props.state?.privilege_add 
    const privilege_edit = this.props.state?.privilege_edit
    const privilege_view = this.props.state?.privilege_view 
    const privilege_delete = this.props.state?.privilege_delete
    const tab_param= this.props.state?.tab_param?this.props.state.tab_param : 0

    this.setState({activeTab:tab_param})

    //state pass through history.push is unable to accessible when we redirect through the browser back button,
    //So its good to gain it from the session storage
    const lg_user_table_id = sessionStorage.getItem("table_log_id")

    TreeMainService.getUserType(lg_user_table_id).then((response) => {
      this.setState(
        {
          userType: response.data?.userType,
        },
        () => {
          this.getVisibilityCheck(privilege_add, privilege_edit);
          this.setRoleBasedPrivilege(
            privilege_add,
            privilege_edit,
            privilege_view,
            privilege_delete
          );
        }
      );
    });
  }

  getVisibilityCheck(privilege_add, privilege_edit) {
    if (privilege_add == "1") {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_edit == "1") {
      this.setState({
        editVisibility: true,
      });
    }
  }

  setRoleBasedPrivilege(
    privilege_add,
    privilege_edit,
    privilege_view,
    privilege_delete
  ) {
    const { userType } = this.state;

    const levelPrivilege = {
      tabVisibility: true,
      addVisibility: privilege_add && userType === "admin",
      editVisibility: privilege_edit && userType === "admin",
      deleteVibility: privilege_delete && userType === "admin",
      viewVisibility:
        (privilege_view && userType === "admin") ||
        (privilege_view && userType === "student") ||
        (privilege_view && userType === "parent"),
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isParent: userType === "parent",
    };

    const levelGradePrivilege = {
      tabVisibility: true,
      addVisibility: privilege_add && userType === "admin",
      editVisibility: privilege_edit && userType === "admin",
      deleteVibility: privilege_delete && userType === "admin",
      viewVisibility:
        (privilege_view && userType === "admin") ||
        (privilege_view && userType === "student"),
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isParent: userType === "parent",
    };

    const typesPrivilege = {
      tabVisibility: userType === "admin",
      addVisibility: privilege_add && userType === "admin",
      editVisibility: privilege_edit && userType === "admin",
      deleteVibility: privilege_delete && userType === "admin",
      viewVisibility:
        (privilege_view && userType === "admin") ||
        (privilege_view && userType === "student") ||
        (privilege_view && userType === "parent"),
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isParent: userType === "parent",
    };

    const levelPricePrivilege = {
      tabVisibility: userType === "admin",
      addVisibility: privilege_add && userType === "admin",
      editVisibility: privilege_edit && userType === "admin",
      deleteVibility: privilege_delete && userType === "admin",
      viewVisibility:
        (privilege_view && userType === "admin") ||
        (privilege_view && userType === "student") ||
        (privilege_view && userType === "parent"),
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isParent: userType === "parent",
    };

    const creditsPrivilege = {
      tabVisibility:
        userType === "admin" || userType === "student" || userType === "parent",
      addVisibility:
        (privilege_add && userType === "student") ||
        (privilege_add && userType === "parent") ||
        (privilege_add && userType === 'admin'),
      editVisibility:
        (privilege_edit && userType === "student") ||
        (privilege_edit && userType === "parent"),
      deleteVibility:
        (privilege_delete && userType === "student") ||
        (privilege_delete && userType === "parent"),
      viewVisibility:
        (privilege_view && userType === "student") ||
        (privilege_view && userType === "parent"),
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isParent: userType === "parent",
    };

    const transactionsPrivilege = {
      tabVisibility:
        userType === "admin" || userType === "student" || userType === "parent",
      addVisibility: privilege_add && userType === "admin",
      editVisibility: privilege_edit && userType === "admin",
      deleteVibility: privilege_delete && userType === "admin",
      viewVisibility:
        (privilege_view && userType === "admin") ||
        (privilege_view && userType === "student") ||
        (privilege_view && userType === "parent"),
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isParent: userType === "parent",
    };

    this.setState({
      levelPrivilege: levelPrivilege,
      levelGradePrivilege: levelGradePrivilege,
      typesPrivilege: typesPrivilege,
      levelPricePrivilege: levelPricePrivilege,
      creditsPrivilege: creditsPrivilege,
      transactionsPrivilege: transactionsPrivilege,
      activeTab:
        userType === "admin"
          ? 5
          : userType === "student"
            ? 0
            : userType === "parent"
              ? 0
              : 0,
    });
  }

  render() {
    const path = history.location.pathname;
    const {
      levelPrivilege,
      levelGradePrivilege,
      typesPrivilege,
      levelPricePrivilege,
      creditsPrivilege,
      transactionsPrivilege,
      userType,
    } = this.state;
    return (
      <div style={{ height: "90vh", overflow: "auto" }}>
        <ContentWrapper disabled={path === "/manage-search"}>
          {!userType ? (
            <div className="loading-container">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div>
              {userType && userType === "admin" ? (
                <div>
                  <Tabs
                    value={this.state.activeTab}
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      "& .MuiTab-root": {
                        fontFamily: "Poppins, sans-serif",
                        textTransform: "unset",
                        letterSpacing: "-0.4px",
                        fontWeight: "600",
                      },
                      "& .Mui-selected": {
                        color: tabTextColor + " !important",
                      },
                      borderRight: 1,
                      borderColor: "divider",
                      backgroundColor: "#fff",
                    }}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: tabTextColor,
                      },
                    }}
                  >
                    {levelPrivilege.tabVisibility && <Tab label="Levels" />}
                    {levelGradePrivilege.tabVisibility && (
                      <Tab label="Level Grades" />
                    )}
                    {typesPrivilege.tabVisibility && <Tab label="Types" />}
                    {levelPricePrivilege.tabVisibility && (
                      <Tab label="Level Prices" />
                    )}
                    {creditsPrivilege.tabVisibility && <Tab label="Credits" />}
                    {transactionsPrivilege.tabVisibility && (
                      <Tab label="Transactions" />
                    )}
                  </Tabs>
                  {this.state.activeTab === 0 && levelPrivilege && (
                    <Levels
                      props={this.props.state}
                      levelPrivilege={levelPrivilege}
                    ></Levels>
                  )}

                  {this.state.activeTab === 1 && levelGradePrivilege && (
                    <LevelGrade
                      props={this.props.state}
                      levelGradePrivilege={levelGradePrivilege}
                    ></LevelGrade>
                  )}

                  {this.state.activeTab === 2 && typesPrivilege && (
                    <Type
                      props={this.props.state}
                      typesPrivilege={typesPrivilege}
                    ></Type>
                  )}

                  {this.state.activeTab === 3 && levelPricePrivilege && (
                    <Subscription
                      props={this.props.state}
                      levelPricePrivilege={levelPricePrivilege}
                    ></Subscription>
                  )}

                  {this.state.activeTab === 4 && creditsPrivilege && (
                    <Credits
                      props={this.props.state}
                      creditsPrivilege={creditsPrivilege}
                    ></Credits>
                  )}

                  {this.state.activeTab === 5 && transactionsPrivilege && (
                    <Transactions
                      props={this.props.state}
                      transactionsPrivilege={transactionsPrivilege}
                    ></Transactions>
                  )}
                </div>
              ) : (
                <div>
                  <Tabs
                    value={this.state.activeTab}
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      "& .MuiTab-root": {
                        fontFamily: "Poppins, sans-serif",
                        textTransform: "unset",
                        letterSpacing: "-0.4px",
                        fontWeight: "600",
                      },
                      "& .Mui-selected": {
                        color: tabTextColor + " !important",
                      },
                      borderRight: 1,
                      borderColor: "divider",
                      backgroundColor: "#fff",
                    }}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: tabTextColor,
                      },
                    }}
                  >
                    {creditsPrivilege.tabVisibility && <Tab label="Credit Packages"/>}
                    {creditsPrivilege.tabVisibility && <Tab label={userType === "student"?"My Credits":"Credits"}/>}
                    {transactionsPrivilege.tabVisibility && (<Tab label={userType === "student"?"My Transactions":"Transactions"} />)}
                    
                  </Tabs>
                  {this.state.activeTab === 0 && creditsPrivilege && (
                    <CreditPackages
                      props={this.props.state}
                      creditsPrivilege={creditsPrivilege}
                    ></CreditPackages>
                  )}
                  {this.state.activeTab === 1 && creditsPrivilege && (
                    <Credits
                      props={this.props.state}
                      creditsPrivilege={creditsPrivilege}
                    ></Credits>
                  )}
                  {this.state.activeTab === 2 && transactionsPrivilege && (
                    <Transactions
                      props={this.props.state}
                      transactionsPrivilege={transactionsPrivilege}
                    ></Transactions>
                  )}
                  
                </div>
              )}
            </div>
          )}
        </ContentWrapper>
      </div>
    );
  }
}
