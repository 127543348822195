import { assessmentTypes } from "../../helper/constants";
import { testConstants } from "../../helper/constants";

const initialState = {
  assessmentID: "",
  candidate: "",
  candidateID: "",
  candidateAnswers: [],
  candidateAnswerMap: {},
  candidateAnswerContents: [],
  candidateAnswerContentMap: {},
  loading: false,
  error: "",
  success: "",
  text: "",
  assessorRemarks: "",
  getRemarksError: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case assessmentTypes.GET_CANDIDATE_ANSWERS:
      return {
        ...state,
        assessmentID: "",
        candidateID: "",
        candidate: action.candidate,
        candidateAnswers: [],
        candidateAnswerContents: [],
        loading: false,
        error: "",
        success: "",
        text: "",
        assessorRemarks: "",
        getRemarksError: "",
      };
    case assessmentTypes.GET_CANDIDATE_ANSWERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case assessmentTypes.GET_CANDIDATE_ANSWERS_SUCCESS:
      var answers = action.content.answers,
        i,
        answer;
      var answerMap = {};
      try {
        for (i in action.content.answers) {
          answer = action.content.answers[i];
          answerMap[answer.question_id] = answer;
        }
      } catch (err) {
        answers = [];
        answerMap = {};
      }
      return {
        ...state,
        loading: false,
        success: "Obtained assessment questions successfully!",
        candidateAnswers: answers,
        candidateAnswerMap: answerMap,
      };
    case assessmentTypes.GET_CANDIDATE_ANSWERS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        success: "",
      };
    case assessmentTypes.GET_ALL_CANDIDATE_ANSWER_CONTENTS:
      return {
        ...state,
        text: "Obtain questions!",
        candidateAnswerContents: [],
        loading: true,
      };
    case assessmentTypes.GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST:
      return {
        ...state,
        text: action.id,
        loading: true,
      };
    case assessmentTypes.GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS:
      var answers = action.answers.answer,
        i,
        j,
        answer;
      
      var answerContentMap = {};
      try {
        for (i in answers) {
          answer = answers[i];
          answerContentMap[action.answers.candidate_answers[i].question_id] =
            answer;
        }

        // this is my_part
        // for (i in answers) {
        //   for (j in action.answers.candidate_answers) {
        //     if (
        //       answers[i].answer_id ==
        //       action.answers.candidate_answers[j].answer_id
        //     )
        //       answer = answers[i]["content"];
        //     answerContentMap[action.answers.candidate_answers[i].question_id] =
        //       answer;
        //   }
        // }
      } catch (err) {
        answers = [];
        answerContentMap = {};
      }
      return {
        ...state,
        loading: false,
        success: "Obtained candidate answers Successfully!",
        candidateAnswerContents: action.answers.answer,
        candidateAnswerContentMap: answerContentMap,
      };
    case assessmentTypes.GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.SET_MARKS:
      return {
        ...state,
        candidateAnswerMap: action.answers,
      };
    case testConstants.SET_SELF_MARKS:
      return {
        ...state,
        candidateAnswerMap: action.answers,
      };
    case assessmentTypes.SAVE_MARKS_REQUEST:
      return {
        ...state,
        error: "",
        text: "Grading!",
        loading: true,
      };
    case assessmentTypes.SAVE_MARKS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        success: "Graded successfully!",
      };
    case assessmentTypes.SAVE_MARKS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case testConstants.SAVE_SELF_MARKS_REQUEST:
      return {
        ...state,
        error: "",
        text: "Self Grading!",
        loading: true,
      };
    case testConstants.SAVE_SELF_MARKS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        success: "Self Graded successfully!",
      };
    case testConstants.SAVE_SELF_MARKS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.FINALIZE_GRADE:
      return {
        ...state,
        error: "",
        loading: true,
      };
    case assessmentTypes.FINALIZE_GRADE_REQUEST:
      return {
        ...state,
        error: action.error,
        loading: true,
      };
    case assessmentTypes.FINALIZE_GRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.candidate.message,
        candidate: action.candidate.message,
      };
    case assessmentTypes.FINALIZE_GRADE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case testConstants.FINALIZE_SELF_GRADE:
      return {
        ...state,
        error: "",
        loading: true,
      };
    case testConstants.FINALIZE_SELF_GRADE_REQUEST:
      return {
        ...state,
        error: action.error,
        loading: true,
      };
    case testConstants.FINALIZE_SELF_GRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.candidate.message,
        candidate: action.candidate.message,
      };
    case testConstants.FINALIZE_SELF_GRADE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.FINALIZE_ALL:
      return {
        ...state,
        error: "",
        loading: true,
      };
    case assessmentTypes.FINALIZE_ALL_REQUEST:
      return {
        ...state,
        error: action.error,
        loading: true,
      };
    case assessmentTypes.FINALIZE_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: action.message,
      };
    case assessmentTypes.FINALIZE_ALL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case assessmentTypes.GET_ASSESSOR_REMARKS_REQUEST:
      return {
        ...state,
        loading: true,
        assessorRemarks: "",
      };
    case assessmentTypes.GET_ASSESSOR_REMARKS_SUCCESS:
      return {
        ...state,
        loading: false,
        assessorRemarks: action.remarks,
      };
    case assessmentTypes.GET_ASSESSOR_REMARKS_FAILURE:
      return {
        ...state,
        getRemarksError: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
