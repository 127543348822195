import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const LimitService = {
    addLimit,
    getLimits,
    updateLimit,
    updateStudentRegLimit,
    getGroupLimits,
    addGroupLimit
  };

async function addLimit(limit) {
    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(limit),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/registration/limits/add`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getLimits() {
    const requestOptions = {
      method: "GET",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/registration/limits/getAllLimits`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function updateLimit(updateLimit , id){

    const requestOptions = {
        method: "PUT",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(updateLimit),
      };
      const response = await fetch(
        `${config.oat_api_url}/manage/registration/limits/update/${id}`,
        requestOptions
      );
      return APIHandler.handleResponse(response);
}

async function updateStudentRegLimit(updateLimit, id) {
    const requestOptions = {
      method: "PUT",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(updateLimit),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/registration/limits/update-student-limit/${id}`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function addGroupLimit(limit) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(limit),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/limits/add-group-limit`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getGroupLimits() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/limits/getAllGroupLimits`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}