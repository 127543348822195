import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import CardTitle from "../../../components/common/CardTitle";
import { Form, Row, Col, Button } from "react-bootstrap";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import { history } from "../../../helper/history";
import { ProvinceService } from "../../../service/settings/manage/province.service";
import { CityService } from "../../../service/settings/manage/city.service";
import { CountryService } from "../../../service/settings/manage/country.service";
import sweetAlert, {
  sweetStatusAlert,
  sweetSuccessAlert
} from "../../../components/common/SweetAlert";
import { OrganizationService } from "../../../service/manage/attribute/organization.service";
import SimpleReactValidator from "simple-react-validator";

class ManageOrganizationAttribute extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
      organization_id: null,
      countries: [],
      provinces: [],
      cities: [],
      name: "",
      email: "",
      phone_number: "",
      postalcode: "",
      address1: "",
      address2: "",
      country_id: "",
      province_id: "",
      city_id: "",
      isLoading: true,
      editVisibility: false,
      addVisibility: false,
      breadcrumb2:null,
      nodeTitle:null
    };
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        address: {
          message: "The :attribute must be a valid address",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/:,0-9\s]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        institueName: {
          message: "The :attribute must have with only 5 numbers",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /(?<!\d)\d{5}(?!\d)|^$/);
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        name: {
          message: "The :attribute must have valid characters",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/\s.,0-9]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        phone_number: {
          message: "The :attribute must be a valid phone number",
          rule: (val, params, validator) => {
            const isTenDigitsStartingWithZero =
              val.length === 10 && val.startsWith("0");
            const isNineDigits = val.length === 9 && !val.startsWith("0");
            return isTenDigitsStartingWithZero || isNineDigits;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
      },
    });
  }

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.resetFormFields();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      this.resetFormFields();
      toggleFunction();
    }
  };

  componentDidMount() {
    if (this.props.state) {
      const { mid, privilege_edit,breadcrumb2, nodeTitle} = this.props.state;
      this.setState({
        breadcrumb2:breadcrumb2, nodeTitle
      })
      this.getVisibilityCheck(privilege_edit);

      OrganizationService.getOrganizationByNodeMid(mid).then((data) => {
        this.setState({ isLoading: false });
        this.setState({
          organization_id: data.data["organization_id"],
          name: data.data["name"],
          email: data.data["email"],
          phone_number: data.data["phone_number"],
          postalcode: data.data["postalcode"],
          address_1: data.data["address_1"],
          address_2: data.data["address_2"],
          country_id: data.data["country_id"],
          province_id: data.data["province_id"],
          city_id: data.data["city_id"],
        });
        this.getProvinceForCountry(data.data["country_id"]);
        this.getCitiesForProvince(data.data["province_id"]);
      });
    }
    CountryService.getAll().then((res) => {
      this.setState({ countries: res.data });
    });
  }

  getVisibilityCheck(privilege_edit) {
    if (privilege_edit === 1) {
      this.setState({
        editVisibility: true,
      });
    }
  }

  getProvinceForCountry = (country_id) => {
    if (country_id) {
      ProvinceService.getProvinceForCountry(country_id).then((response) => {
        if (response.success) {
          this.setState({ provinces: response.data });
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      });
    }
  };

  getCitiesForProvince = (province_id) => {
    if (province_id) {
      CityService.getCitiesForProvince(province_id).then((response) => {
        if (response.success) {
          this.setState({ cities: response.data });
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  organizationSubmit = async (event) => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      const {
        name,
        email,
        phone_number,
        postalcode,
        address_1,
        address_2,
        country_id,
        province_id,
        city_id,
        organization_id,
      } = this.state;

      const organization_id_int = parseInt(organization_id);

      const organization = {
        organization_id,
        node_mid: this.props.state ? this.props.state.mid : null,
        name,
        email,
        phone_number,
        postalcode,
        address_1,
        address_2,
        country_id,
        province_id,
        city_id,
      };

      this.setState({ isLoading: true });

      if(organization_id_int){
        await OrganizationService.update(organization, organization_id_int)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.success) {
            sweetSuccessAlert(
              "Success",
              "Organization attribute has been updated successfully!",
              "success"
            );
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed!",
            "Unable to insert the organization information",
            "error"
          );
        });
      }else{
        await OrganizationService.add(organization)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.success) {
            sweetSuccessAlert(
              "Success",
              "Organization attribute has updated.",
              "success"
            );
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed!",
            "Unable to insert the organization information",
            "error"
          );
        });
      }

    }
  };

  render() {
    const {
      countries,
      provinces,
      cities,
      name,
      email,
      phone_number,
      postalcode,
      address_1,
      address_2,
      country_id,
      province_id,
      city_id,
      isLoading,
      addVisibility,
      editVisibility,
      breadcrumb2,
      nodeTitle
    } = this.state;
    this.validator.purgeFields();
    const formattedBreadcrumb = breadcrumb2? breadcrumb2.replaceAll(">", " > ") : ""
    return (
      <div style={{ height: "90vh", overflow: "auto" }}>
          <ContentWrapper>
            <div>
              <div className={editVisibility ? "p-0" : "p-0 hide-card-header"}>
                <CardHeader>
                  <div className="row d-flex px-0">
                    <div className="col-md-6 px-0">
                      {(this.props.state.path !== 'node' ) && 
                      <p style={{ fontSize: "10.5625px", color: "#00000099" }}>
                      {(formattedBreadcrumb) ? `${formattedBreadcrumb }`: ""}
                      </p>}
                    </div>
                    <div className="col-md-6 px-0 d-flex justify-content-end">
                      {editVisibility && <Button
                        className="insert-btn"
                        variant="primary"
                        onClick={this.organizationSubmit}
                        style={{ marginLeft: "auto" }}
                      >
                        Save
                      </Button>}
                    </div>
                  </div>
                </CardHeader>
              </div>

              <CardBody>
                {isLoading && (
                  <PageSpinner id="show" text="Loading, Please wait!" />
                )}
                <Form>
                  <Row>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="pt-3">Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Organization Name"
                        value={name}
                        onChange={this.handleChange}
                        disabled={!editVisibility}
                        className={editVisibility ? "enabled-select" : "disabled-select"}
                      />
                      {this.validator.message(
                        "name",
                        name,
                        "min:2|required|name|max:100",
                        {
                          className: "text-danger",
                          max: "Organization name should not exceed 100 characters",
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label className="pt-3">Email*</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="example@gmail.com"
                        value={email}
                        onChange={this.handleChange}
                        disabled={!editVisibility}
                        className={editVisibility ? "enabled-select" : "disabled-select"}
                      />
                      {this.validator.message(
                        "email",
                        email,
                        "required|email",
                        {
                          className: "text-danger",
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label className="pt-3">Phone Number*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone Number"
                        name="phone_number"
                        value={phone_number}
                        onChange={this.handleChange}
                        disabled={!editVisibility}
                        className={editVisibility ? "enabled-select" : "disabled-select"}
                      />
                      {this.validator.message(
                        "phoneNumber",
                        phone_number,
                        "required|numeric|between:9,10|phone_number",
                        { className: "text-danger" }
                      )}
                    </Form.Group>
                    
                  </Row>
                  <hr className="mt-8 mb-5" style={{ height: '0.1px', backgroundColor: 'rgba(0, 0, 0, 0.1)' }} />
                  <Row>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="pt-3">Address Line 1*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address 1"
                        name="address_1"
                        value={address_1}
                        onChange={this.handleChange}
                        disabled={!editVisibility}
                        className={editVisibility ? "enabled-select" : "disabled-select"}
                      />
                      {this.validator.message(
                        "address1",
                        address_1,
                        "required|address",
                        {
                          className: "text-danger",
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label className="pt-3">Address Line 2*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Address 2"
                        name="address_2"
                        value={address_2}
                        onChange={this.handleChange}
                        disabled={!editVisibility}
                        className={editVisibility ? "enabled-select" : "disabled-select"}
                      />
                      {this.validator.message(
                        "address2",
                        address_2,
                        "required|address",
                        {
                          className: "text-danger",
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row>
                  <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label className="pt-3">City*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={city_id}
                        onChange={(e) =>
                          this.setState({ city_id: e.target.value })
                        }
                        disabled={!editVisibility}
                        className={editVisibility ? "enabled-select" : "disabled-select"}
                      >
                        <option value="">Select</option>
                        {cities.map((city) => (
                          <option key={city.city_id} value={city.city_id}>
                            {city.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message("city", city_id, "required", {
                        className: "text-danger",
                      })}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="pt-3">Postal Code*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Postal Code"
                        name="postalcode"
                        value={postalcode}
                        onChange={this.handleChange}
                        disabled={!editVisibility}
                        className={editVisibility ? "enabled-select" : "disabled-select"}
                      />
                      {this.validator.message(
                        "postalCode",
                        postalcode,
                        "required|numeric",
                        { className: "text-danger" }
                      )}
                    </Form.Group>
                   
                  </Row>
                  <Row>
                  <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label className="pt-3">Province/District*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={province_id}
                        onChange={(e) => {
                          this.setState({
                            province_id: e.target.value,
                            cities: [],
                            city_id: "",
                          });
                          this.getCitiesForProvince(e.target.value);
                        }}
                        disabled={!editVisibility}
                        className={editVisibility ? "enabled-select" : "disabled-select"}
                      >
                        <option value="">Select</option>
                        {provinces.map((province) => (
                          <option
                            key={province.province_id}
                            value={province.province_id}
                          >
                            {province.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "province",
                        province_id,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                    </Form.Group>
                  <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label className="pt-3">Country*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={country_id}
                        onChange={(e) => {
                          this.setState({
                            country_id: e.target.value,
                            cities: [],
                            provinces: [],
                            city_id: "",
                            province_id: "",
                          });
                          this.getProvinceForCountry(e.target.value);
                        }}
                        disabled={!editVisibility}
                        className={editVisibility ? "enabled-select" : "disabled-select"}
                      >
                        <option value="">Select</option>
                        {countries.map((country) => (
                          <option
                            key={country.country_id}
                            value={country.country_id}
                          >
                            {country.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "country",
                        country_id,
                        "required",
                        {
                          className: "text-danger",
                        }
                      )}
                    </Form.Group>
                  </Row>
                </Form>
              </CardBody>
            </div>
          </ContentWrapper>
      </div>
    );
  }
}

export default ManageOrganizationAttribute;
