import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const StatusTypesService = {
  create,
  update,
  getAll,
  getStatusTypeByPagination
};

async function create(status_type) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(status_type),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/status-types/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(status_type, status_type_id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(status_type),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/status-types/update/${status_type_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAll() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/status-types/getAllStatusType`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getStatusTypeByPagination(limit, offset, search_term) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/status-types/getAll/${limit}/${offset}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
