import { userTypes } from '../types';

const initialState = {
    loading: false,
    error: '',
    teacher: '',
    text: '',
    teacherDetails: '',
    teacherDetailsForStudent: '',
    studentDetails: '',
    allTeachers: '',
    allStudents: ''
}

export function manageUserReducer(state = initialState, action) {
    switch (action.type) {
        case userTypes.GET_TEACHER_ME:
            return {
                ...state,
                teacher: '',
                loading: true,
                text: 'Obtaining the manage teacher'
            }
        case userTypes.GET_TEACHER_ME_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Obtaining the manage teacher'
            }
        case userTypes.GET_TEACHER_ME_SUCCESS:
            return {
                ...state,
                teacher: action.teacher,
                loading: false,
                success: 'Obtained the manage teacher successfully'
            }
        case userTypes.GET_TEACHER_ME_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case userTypes.GET_TEACHER_DETAILS:
            return {
                ...state,
                teacherDetails: '',
                loading: true,
                text: 'Obtaining the manage teacher details'
            }
        case userTypes.GET_TEACHER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Obtaining the manage teacher details'
            }
        case userTypes.GET_TEACHER_DETAILS_SUCCESS:
            return {
                ...state,
                teacherDetails: action.teacherDetails,
                loading: false,
                success: 'Obtained the manage teacher successfully'
            }
        case userTypes.GET_TEACHER_DETAILS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case userTypes.GET_TEACHER_DETAILS_FOR_STUDENT:
            return {
                ...state,
                teacherDetailsForStudent: '',
                loading: true,
                text: 'Obtaining the manage teacher details for student self grade'
            }
        case userTypes.GET_TEACHER_DETAILS_FOR_STUDENT_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Obtaining the manage teacher details for student self grade'
            }
        case userTypes.GET_TEACHER_DETAILS_FOR_STUDENT_SUCCESS:
            return {
                ...state,
                teacherDetailsForStudent: action.teacherDetailsForStudent,
                loading: false,
                success: 'Obtained the manage teacher details for student self grade successfully'
            }
        case userTypes.GET_TEACHER_DETAILS_FOR_STUDENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case userTypes.GET_ALL_TEACHERS:
            return {
                ...state,
                allTeachers: '',
                loading: true,
                text: 'Obtaining all teachers details'
            }
        case userTypes.GET_ALL_TEACHERS_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Obtaining all teachers details'

            }
        case userTypes.GET_ALL_TEACHERS_SUCCESS:
            return {
                ...state,
                allTeachers: action.allTeachers,
                error: action.error,
                loading: false,
                success: 'Obtained all teachers successfully'
            }
        case userTypes.GET_ALL_TEACHERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case userTypes.GET_ALL_STUDENTS:
            return {
                ...state,
                allStudents: '',
                loading: true,
                text: 'Obtaining all students details'
            }
        case userTypes.GET_ALL_STUDENTS_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Obtaining all students details'

            }
        case userTypes.GET_ALL_STUDENTS_SUCCESS:
            return {
                ...state,
                allStudents: action.allStudents,
                error: action.error,
                loading: false,
                success: 'Obtained all students successfully'
            }
        case userTypes.GET_ALL_STUDENTS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case userTypes.GET_STUDENT_DETAILS:
            return {
                ...state,
                studentDetails: '',
                loading: true,
                text: 'Obtaining the manage student details'
            }
        case userTypes.GET_STUDENT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Obtaining the manage student details'
            }
        case userTypes.GET_STUDENT_DETAILS_SUCCESS:
            return {
                ...state,
                studentDetails: action.studentDetails,
                loading: false,
                success: 'Obtained the manage student successfully'
            }
        case userTypes.GET_STUDENT_DETAILS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}
