import _ from 'underscore';
import { assessmentTypes } from '../../reducers/types';
import {store} from "../../helper/redux_store";
import { candidateService } from "../../service/candidate/candidateService";

export const candidateActions = {
    deleteCandidate,
    addCandidates,
    editCandidates,
    viewAddCandidates,
    getCandidateReports,
    refreshForCandidates
};

export function addCandidates(data) {
    return async dispatch => {
        try {
            let candidates = data.candidate_emails;
            let arr = [], content, candidate, selected_user, invalid_emails = [], new_roles = [];
            let users = store.getState()['userReducer']['users'];
            let user_roles = store.getState()['userReducer']['userRoleMap'];

            for (candidate of candidates) {
                if (candidate !== "") {
                    selected_user = _.findWhere(users, { 'user_email': candidate })
                    if (selected_user) {
                        content = {
                            'assessment_id': data.assessment_id,
                            'user_id': selected_user.user_id,
                            'added_by': data.userID,
                            'candidate_status': 'Assigned'
                        }
                        arr.push(content)
                        if (!user_roles['CNDT' + selected_user.user_id]) {
                            new_roles.push({
                                'user_id': selected_user.user_id,
                                'role_code': 'CNDT',
                                'assigned_by': data.userID
                            })
                        }
                    } else {
                        invalid_emails.push(candidate)
                    }
                }
            }

            let cndt_list = {
                'candidate_list': arr,
                'new_roles': new_roles
            };

            if (arr.length) {
                dispatch(request_add_candidates(invalid_emails));
                await candidateService.addCandidates(cndt_list)
                let message = "Candidates Added Successfully!";
                if (invalid_emails.length) {
                    message = "<b>" + "Invalid user emails: " + "</b>" + invalid_emails.join() + "<br/>" + "Other Candidates Added Successfully!";
                }
                dispatch(success_add_candidates(message))
            } else {
                let error = "<b>" + "Invalid user emails: " + "</b>" + invalid_emails.join() + "<br/>" + "Adding Candidate Unsuccessful!";
                dispatch(failure_add_candidates(error))
            }

        } catch (error) {
            dispatch(failure_add_candidates(error));
        }

    };

    function request_add_candidates(invalid_emails) {
        return { type: assessmentTypes.ADD_CANDIDATES_ASSESSMENT_REQUEST, invalid_emails }
    }

    function success_add_candidates(message) {
        return { type: assessmentTypes.ADD_CANDIDATES_ASSESSMENT_SUCCESS, message }
    }

    function failure_add_candidates(error) {
        return { type: assessmentTypes.ADD_CANDIDATES_ASSESSMENT_FAILURE, error }
    }

}

export function deleteCandidate(data) {

    return async dispatch => {
        try {
            dispatch(delete_candidate(data));
            dispatch(delete_candidate_request());
            var message = await candidateService.deleteCandidate(data)
            var messageData = message.data
            dispatch(delete_candidate_success(messageData))
        } catch (error) {
            dispatch(delete_candidate_failure(error))
        }
    }

    function delete_candidate(data) {
        return { type: assessmentTypes.DELETE_CANDIDATE, data}
    }

    function delete_candidate_request() {
        return { type: assessmentTypes.DELETE_CANDIDATE_REQUEST}
    }


    function delete_candidate_success(message) {
        return { type: assessmentTypes.DELETE_CANDIDATE_SUCCESS, message}
    }

    function delete_candidate_failure(error) {
        return { type: assessmentTypes.DELETE_CANDIDATE_FAILURE, error }
    }

}

export function viewAddCandidates(assessment) {
    return dispatch => {
        dispatch(start_add_candidates(assessment));
    }

    function start_add_candidates(assessment) {
        return { type: assessmentTypes.ADD_CANDIDATES_ASSESSMENT, assessment }
    }
}

export function getCandidateReports(assessment) {

    return async dispatch => {
        try {
            dispatch(get_candidate_reports(assessment));
            dispatch(get_candidate_reports_request());
            var reports = await candidateService.getCandidateReports(assessment.assessment_id)
            dispatch(get_candidate_reports_success(reports.data))
        } catch (error) {
            dispatch(get_candidate_reports_failure(error))
        }
    }

    function get_candidate_reports(assessment) {
        return { type: assessmentTypes.GET_CANDIDATE_REPORTS, assessment }
    }

    function get_candidate_reports_request() {
        return { type: assessmentTypes.GET_CANDIDATE_REPORTS_REQUEST }
    }


    function get_candidate_reports_success(candidates_list) {

        setTimeout(() => {}, 30000);

        let candidateReports = candidates_list.candidateReports

        return { type: assessmentTypes.GET_CANDIDATE_REPORTS_SUCCESS, candidateReports }
    }

    function get_candidate_reports_failure(error) {
        return { type: assessmentTypes.GET_CANDIDATE_REPORTS_FAILURE, error }
    }

}

export function refreshForCandidates(assessment) {
    return dispatch => {
        dispatch(refreshForAdding(assessment));
    }

    function refreshForAdding(assessment) {
        return { type: assessmentTypes.ADD_CANDIDATES_ASSESSMENT_REFRESH, assessment }
    }
}

export function editCandidates(data) {
    return async dispatch => {
        try {
            let candidates = data.editCandidateEmails;
            let arr = [], content, candidate, selected_user, invalid_emails = [], new_roles = [];
            let users = store.getState()['userReducer']['users'];
            let user_roles = store.getState()['userReducer']['userRoleMap'];

            for (candidate of candidates) {
                if (candidate !== "") {
                    selected_user = _.findWhere(users, { 'user_email': candidate })
                    if (selected_user) {
                        content = {
                            'assessment_id': data.editAssessmentId,
                            'user_id': selected_user.user_id,
                            'added_by': data.userID,
                            'candidate_status': 'Assigned'
                        }
                        arr.push(content)
                        if (!user_roles['CNDT' + selected_user.userID]) {
                            new_roles.push({
                                'user_id': selected_user.user_id,
                                'role_code': 'CNDT',
                                'assigned_by': data.userID
                            })
                        }
                    } else {
                        invalid_emails.push(candidate)
                    }
                }
            }

            let cndt_list = {
                'candidate_list': arr,
                'new_roles': new_roles
            };

            if (arr.length) {
                dispatch(request_add_candidates(invalid_emails));
                await candidateService.editCandidates(cndt_list)
                let message = "Candidates Added Successfully!";
                if (invalid_emails.length) {
                    message = "<b>" + "Invalid user emails: " + "</b>" + invalid_emails.join() + "<br/>" + "Other Candidates Added Successfully!"
                }
                dispatch(success_add_candidates(message))
            } else {
                let error = "<b>" + "Invalid user emails: " + "</b>" + invalid_emails.join() + "<br/>" + "Adding Candidate Unsuccessful!";
                dispatch(failure_add_candidates(error))
            }

        } catch (error) {
            dispatch(failure_add_candidates(error));
        }

    };

    function request_add_candidates(invalid_emails) {
        return { type: assessmentTypes.EDIT_CANDIDATES_ASSESSMENT_REQUEST, invalid_emails}
    }

    function success_add_candidates(message) {
        return { type: assessmentTypes.EDIT_CANDIDATES_ASSESSMENT_SUCCESS, message }
    }

    function failure_add_candidates(error) {
        return { type: assessmentTypes.EDIT_CANDIDATES_ASSESSMENT_FAILURE, error }
    }

}
