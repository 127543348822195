import { createTypes } from '../types';

const initialState = {
    allQuestionTypes: [],
    qtype: '',
    allDifficulty: [],
    difficulty: '',
    allLanguages: [],
    language: '',
    allSources: [],
    source: '',
    allSubjects: [],
    subject: '',
    allAuthors: [],
    author: '',
    allYears: [],
    year: '',
    allTerms: [],
    term: '',
    allCategories: [],
    allChapters: [],
    allGrades: [],
    allPrivileges: [],
    allQuestionMetaData: [],
    category: '',
    loading: false,
    error: '',
    totalCategoriesCount: 0
}

export function questionsCreateReducer(state = initialState, action) {
    switch (action.type) {
        case createTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allQuestionTypes: [],
                qtype: '',
                allDifficulty: [],
                question: [],
                difficulty: '',
                allLanguages: [],
                language: '',
                allSources: [],
                source: '',
                allSubjects: [],
                subject: '',
                allAuthors: [],
                author: '',
                allYears: [],
                year: '',
                allTerms: [],
                term: '',
                allCategories: [],
                allChapters: [],
                allGrades: [],
                allPrivileges: [],
                allQuestionCategories: [],
                category: '',
                loading: false,
                error: '',
                text: ''
            }
        case createTypes.GET_QUESTION_TYPES:
            return {
                ...state,
                loading: true,
                text: 'Get all the question types'
            }
        case createTypes.GET_QUESTION_TYPES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_TYPES_SUCCESS:
            return {
                ...state,
                allQuestionTypes: action.types.types,
                loading: false
            }
        case createTypes.GET_QUESTION_TYPES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_BY_ID:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_BY_ID_SUCCESS:
            return {
                ...state,
                question: action.types.question,
                loading: false
            }
        case createTypes.GET_QUESTION_BY_ID_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_GRADE:
            return {
                ...state,
                loading: true,
                text: 'Get all the question grades'
            }
        case createTypes.GET_QUESTION_GRADE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_GRADE_SUCCESS:
            return {
                ...state,
                allGrades: action.types.grades,
                loading: false
            }
        case createTypes.GET_QUESTION_GRADE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_PRIVILEGE:
            return {
                ...state,
                loading: true,
                text: 'Get all the question privileges'
            }
        case createTypes.GET_QUESTION_PRIVILEGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_PRIVILEGE_SUCCESS:
            return {
                ...state,
                allPrivileges: action.types.privileges,
                loading: false
            }
        case createTypes.GET_QUESTION_PRIVILEGE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_CHAPTER:
            return {
                ...state,
                loading: true,
                text: 'Get all the question chapters'
            }
        case createTypes.GET_QUESTION_CHAPTER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_CHAPTER_SUCCESS:
            return {
                ...state,
                allChapters: action.types.chapters,
                loading: false
            }
        case createTypes.GET_QUESTION_CHAPTER_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_DIFFICULTY:
            return {
                ...state,
                loading: true,
                text: 'Get all the question difficulties'
            }
        case createTypes.GET_QUESTION_DIFFICULTY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_DIFFICULTY_SUCCESS:
            return {
                ...state,
                allDifficulty: action.types.difficulty_types,
                loading: false
            }
        case createTypes.GET_QUESTION_DIFFICULTY_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_LANGUAGE:
            return {
                ...state,
                loading: true,
                text: 'Get all the languages'
            }
        case createTypes.GET_QUESTION_LANGUAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_LANGUAGE_SUCCESS:
            return {
                ...state,
                allLanguages: action.types.languages,
                loading: false
            }
        case createTypes.GET_QUESTION_LANGUAGE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_SOURCE:
            return {
                ...state,
                loading: true,
                text: 'Get all the sources'
            }
        case createTypes.GET_QUESTION_SOURCE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_SOURCE_SUCCESS:
            return {
                ...state,
                allSources: action.types.sources,
                loading: false
            }
        case createTypes.GET_QUESTION_SOURCE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_SUBJECT:
            return {
                ...state,
                loading: true,
                text: 'Get all the subjects'
            }
        case createTypes.GET_QUESTION_SUBJECT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_SUBJECT_SUCCESS:
            return {
                ...state,
                allSubjects: action.types.subjects,
                loading: false
            }
        case createTypes.GET_QUESTION_SUBJECT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_AUTHOR:
            return {
                ...state,
                loading: true,
                text: 'Get all the authors'
            }
        case createTypes.GET_QUESTION_AUTHOR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_AUTHOR_SUCCESS:
            return {
                ...state,
                allAuthors: action.types.authors,
                loading: false
            }
        case createTypes.GET_QUESTION_AUTHOR_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_YEAR:
            return {
                ...state,
                loading: true,
                text: 'Get all the years'
            }
        case createTypes.GET_QUESTION_YEAR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_YEAR_SUCCESS:
            return {
                ...state,
                allYears: action.types.years,
                loading: false
            }
        case createTypes.GET_QUESTION_YEAR_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_TERM:
            return {
                ...state,
                loading: true,
                text: 'Get all the terms'
            }
        case createTypes.GET_QUESTION_TERM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_TERM_SUCCESS:
            return {
                ...state,
                allTerms: action.types.terms,
                loading: false
            }
        case createTypes.GET_QUESTION_TERM_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_CATEGORIES:
            return {
                ...state,
                loading: true,
                text: 'Get all the question categories'
            }
        case createTypes.GET_QUESTION_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                allCategories: action.categories.categories
            }
        case createTypes.GET_QUESTION_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_ALL_QUESTION_CATEGORIES:
            return {
                ...state,
                loading: true,
                text: 'Get all the question categories'
            }
        case createTypes.GET_ALL_QUESTION_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_ALL_QUESTION_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionCategories: action.categories.categories,
                totalCategoriesCount: action.categories.total_count
            }
        case createTypes.GET_ALL_QUESTION_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.SELECT_QUESTION_CATEGORY:
            return {
                ...state,
                category: action.data
            }
        case createTypes.SELECT_QUESTION_TYPE:
            return {
                ...state,
                qtype: action.data
            }
        case createTypes.SELECT_QUESTION_LANGUAGE:
            return {
                ...state,
                language: action.data
            }
        case createTypes.CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                success: '',
                error: ''
            }
        case createTypes.CREATE_CATEGORY_SUCCESS:
            var temp = state.allCategories;
            temp.push(action.types)
            return {
                ...state,
                allCategories: temp,
                category: action.types.category_id,
                loading: false
            }
        case createTypes.CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case createTypes.GET_QUESTION_META_DATA:
            return {
                ...state,
                loading: true,
                text: 'Get all the question meta data'
            }
        case createTypes.GET_QUESTION_META_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case createTypes.GET_QUESTION_META_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionMetaData: action.data
            }
        case createTypes.GET_QUESTION_META_DATA_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }

        default:
            return state
    }
}
