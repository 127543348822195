import {quesTypeTypes} from '../../../reducers/settings/settingsTypes'
import {questionTypeService} from '../../../service/settings/question/typesService'


export const quesTypeActions = {
    getAllQuestionTypes,
    createType,
    updateType,
    deleteType
}

function getAllQuestionTypes(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let types = await questionTypeService.getAllQuestionTypes(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(types.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: quesTypeTypes.GET_ALL_QUESTION_TYPES}
    }

    function request() {
        return {type: quesTypeTypes.GET_ALL_QUESTION_TYPES_REQUEST}
    }

    function success(types) {
        return {type: quesTypeTypes.GET_ALL_QUESTION_TYPES_SUCCESS, types}
    }

    function failure(error) {
        return {type: quesTypeTypes.GET_ALL_QUESTION_TYPES_FAILURE, error}
    }
}


function createType(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await questionTypeService.createType(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: quesTypeTypes.CREATE_TYPE_REQUEST, data}
    }

    function success(types) {
        return {type: quesTypeTypes.CREATE_TYPE_SUCCESS, types}
    }

    function failure(error) {
        return {type: quesTypeTypes.CREATE_TYPE_FAILURE, error}
    }
}


function deleteType(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await questionTypeService.deleteType(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(type) {
        return {type: quesTypeTypes.DELETE_TYPE_REQUEST, type}
    }

    function success(success) {
        return {type: quesTypeTypes.DELETE_TYPE_SUCCESS, success}
    }

    function failure(error) {
        return {type: quesTypeTypes.DELETE_TYPE_FAILURE, error}
    }
}

function updateType(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await questionTypeService.updateType(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: quesTypeTypes.UPDATE_TYPE_REQUEST}
    }

    function success(qtype) {
        return {type: quesTypeTypes.UPDATE_TYPE_SUCCESS, qtype}
    }

    function failure(error) {
        return {type: quesTypeTypes.UPDATE_TYPE_FAILURE, error}
    }
}
