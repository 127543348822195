import React from "react";

export default function TableHead({ children }) {
    return (

        <thead>

            {children}
            
        </thead>

    )
}