import React from "react"
import ContentWrapper from "../../components/common/AssessmentContentWrapper.js"
import AdminTemplate from "../../components/admin/layouts/Template.js"
import PropTypes from "prop-types"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { connect } from "react-redux"
import QuestionCategory from "./question/Category.js"
import QuestionAuthor from "./question/Author.js"
import QuestionDifficulty from "./question/Difficulty.js"
import QuestionLanguage from "./question/Language.js"
import QuestionSource from "./question/Source.js"
import QuestionSubject from "./question/Subject.js"
import QuestionTerm from "./question/Term.js"
import QuestionTypes from "./question/Types.js"
import QuestionYear from "./question/Year.js"
import QuestionChapter from "./question/Chapter.js"
import AssessmentAttempt from "./assessment/Attempt.js"
import AssessmentCategory from "./assessment/Category.js"
import ManageGrade from "./manage/ManageGrade.js";
import ManageSchedule from "./candidate/ManageSchedule.js"
import ManageAttempt from "./candidate/ManageAttempt.js"
import ManagePaperTerms from "./manage/ManagePaperTerms.js"
import ManagePapers from "./manage/ManagePapers.js"
import ManageQuestions from "./manage/ManageQuestions.js"
import ManageGradeScale from "./candidate/ManageGradeScale.js"

import { Modal } from "react-bootstrap"
import "./Settings.css"
import MenuIcon from "@mui/icons-material/Menu"
import config from '../../helper/config.js';
import {customerConstants} from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let tabTextColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    tabTextColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    tabTextColor = "var(--maincolor-cmc)"
    break
  default:
    tabTextColor = "var(--maincolor)"
    break
}

class AssessmentSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      screenWidth: window.innerWidth,
      isTabSectionVisible: false,
    }

    this.handleResize = this.handleResize.bind(this)
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue, isTabSectionVisible: false })
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  handleResize() {
    this.setState({
      screenWidth: window.innerWidth,
    })
  }

  toggleTabSection = () => {
    this.setState((prevState) => ({
      isTabSectionVisible: !prevState.isTabSectionVisible,
    }))
  }

  render() {
    const { value, screenWidth, isTabSectionVisible } = this.state

    return (
      <AdminTemplate>
        <ContentWrapper>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "100%",
            }}
          >
            <div style={{ width: screenWidth < 1246 ? "75px" : "" }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={this.handleChange}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                  "& .MuiTab-root": {
                    fontFamily: "Poppins, sans-serif",
                    textTransform: "unset",
                    letterSpacing: "-0.4px",
                    fontWeight: "600",
                    alignItems: "start"
                  },
                  "& .Mui-selected, & .Mui-selected.MuiTab-root": {
                    color: tabTextColor + " !important",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: tabTextColor
                  },
                }}
                style={{
                  display: screenWidth < 1246 ? "none" : "",
                }}
              >
                <Tab label="Question-Category" {...a11yProps(0)} />
                <Tab label="Question-Source" {...a11yProps(1)} />
                <Tab label="Manage-All-Question-Details" {...a11yProps(2)} />
                <Tab label="Assessment-Category" {...a11yProps(3)} />
                <Tab label="Assessment-Attempt" {...a11yProps(4)} />
                {/* <Tab label="Question-Types" {...a11yProps(12)} /> */}
                <Tab label="Manage-Medium/Language" {...a11yProps(5)} />
                <Tab label="Manage-Author" {...a11yProps(6)} />
                <Tab label="Manage-Difficulty" {...a11yProps(7)} />
                <Tab label="Manage-Grade" {...a11yProps(8)} />
                <Tab label="Manage-Subject" {...a11yProps(9)} />
                <Tab label="Manage-Chapter/Module/Unit" {...a11yProps(10)} />
                <Tab label="Manage-Term/Semester" {...a11yProps(11)} />
                <Tab label="Manage-Year" {...a11yProps(12)} />
                <Tab label="Manage-Student-Paper-Schedule" {...a11yProps(13)} />
                <Tab label="Manage-Student-Paper-Attempt" {...a11yProps(14)} />
                <Tab label="Manage-Paper-Terms-and-Conditions" {...a11yProps(15)} />
                <Tab label="Manage-All-Paper-Details" {...a11yProps(16)} />
                {/* <Tab label="Manage-Marks-Grade-Scale" {...a11yProps(17)} /> */}
              </Tabs>
            </div>
            <button
              className="settings-menu"
              style={{
                display: screenWidth < 1246 ? "" : "none",
              }}
              onClick={this.toggleTabSection}
            >
              <MenuIcon style={{ marginLeft: "25px", fontSize: "24px" }} />
            </button>
            <TabPanel value={value} index={0}>
              <QuestionCategory />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <QuestionSource />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ManageQuestions />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <AssessmentCategory />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <AssessmentAttempt />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <QuestionLanguage />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <QuestionAuthor />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <QuestionDifficulty />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <ManageGrade />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <QuestionSubject />
            </TabPanel>
            <TabPanel value={value} index={10}>
              <QuestionChapter />
            </TabPanel>
            <TabPanel value={value} index={11}>
              <QuestionTerm />
            </TabPanel>
            {/* <TabPanel value={value} index={12}>
                        <QuestionTypes/>
                    </TabPanel> */}
            <TabPanel value={value} index={12}>
              <QuestionYear />
            </TabPanel>
            <TabPanel value={value} index={13}>
              <ManageSchedule />
            </TabPanel>
            <TabPanel value={value} index={14}>
              <ManageAttempt />
            </TabPanel>
            <TabPanel value={value} index={15}>
              <ManagePaperTerms />
            </TabPanel>
            <TabPanel value={value} index={16}>
              <ManagePapers />
            </TabPanel>
            {/* <TabPanel value={value} index={17}>
              <ManageGradeScale />
            </TabPanel> */}
          </Box>
        </ContentWrapper>

        {/* Tab Section Modal */}
        <Modal
          size="lg"
          centered
          onHide={() => this.setState({ isTabSectionVisible: false })}
          show={isTabSectionVisible && screenWidth < 1246}
        >
          <Modal.Header
            className="d-flex justify-content-end"
            closeButton
          ></Modal.Header>
          <Modal.Body>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              sx={{
                "& .MuiTab-root": {
                  fontFamily: "Poppins, sans-serif",
                  textTransform: "unset",
                  letterSpacing: "-0.4px",
                  fontWeight: "600"
                },
                "& .Mui-selected": {
                  color: tabTextColor
                },
                borderRight: 1,
                borderColor: "divider"
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: tabTextColor
                },
              }}
              className="collapsible-tab"
            >
              <Tab label="Question-Category" {...a11yProps(0)} />
              <Tab label="Question-Source" {...a11yProps(1)} />
              <Tab label="Manage-All-Question-Details" {...a11yProps(2)} />
              <Tab label="Assessment-Category" {...a11yProps(3)} />
              <Tab label="Assessment-Attempt" {...a11yProps(4)} />
              <Tab label="Manage-Medium/Language" {...a11yProps(5)} />
              <Tab label="Manage-Author" {...a11yProps(6)} />
              <Tab label="Manage-Difficulty" {...a11yProps(7)} />
              <Tab label="Manage-Grade" {...a11yProps(8)} />
              <Tab label="Manage-Subject" {...a11yProps(9)} />
              <Tab label="Manage-Chapter/Module/Unit" {...a11yProps(10)} />
              <Tab label="Manage-Term/Semester" {...a11yProps(11)} />
              {/* <Tab label="Question-Types" {...a11yProps(12)} /> */}
              <Tab label="Manage-Year" {...a11yProps(12)} />
              <Tab label="Manage-Student-Paper-Schedule" {...a11yProps(13)} />
              <Tab label="Manage-Student-Paper-Attempt" {...a11yProps(14)} />
              <Tab label="Manage-Paper-Terms-and-Conditions" {...a11yProps(15)} />
              <Tab label="Manage-All-Paper-Details" {...a11yProps(16)} />
              {/* <Tab label="Manage-Marks-Grade-Scale" {...a11yProps(17)} /> */}
            </Tabs>
          </Modal.Body>
        </Modal>
      </AdminTemplate>
    )
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

export default AssessmentSettings
