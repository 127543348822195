import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const roleService = {
  getById,
  getAll
};

async function getById(role_code) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(
      config.azure_ad_config.apis.OAT.name
    ),
  };
  const response = await fetch(`${config.oat_api_url}/user/roles/${role_code}`, requestOptions);
  return APIHandler.handleResponse(response);
}

async function getAll() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(
      config.azure_ad_config.apis.OAT.name
    ),
  };
  return await fetch(`${config.oat_api_url}/user/roles/`, requestOptions).then(
    APIHandler.handleResponse
  );
}


