import React, { Component } from "react";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import CardTitle from "../../../components/common/CardTitle";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import "../../../components/admin/css/common.css";
import sweetAlert, {
  sweetConfirmAlert,
  sweetStatusAlert,
  sweetAlertInfo,
} from "../../../components/common/SweetAlert";
import SimpleReactValidator from "simple-react-validator";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { LevelService } from "../../../service/manage/subscription/level.service";
import { LevelPriceService } from "../../../service/manage/subscription/level_price.service";
import TimeZoneHandler from "../../../handlers/TimeZoneHandler";
import moment from "moment-timezone";
import config from '../../../helper/config';
import { customerConstants } from "../../../helper/constants.js";
import CheckBoxInline from "../../../components/common/form/CheckBoxInline.js";
import CheckBox from "../../../components/common/form/CheckBox.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class LevelPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      combinedLevels: "",
      isButtonLoading: true,
      showInsertModal: false,
      initialStateValue: "",
      showModal: false,
      isLoading: false,
      isTableDataLoading: true,
      isModified: false,
      selectedStudentId: "",
      selectedStudentTable: "",
      selectedStudent: null,
      search_id: "",
      isStudentsLoading: false,
      isSubmitting: false,
      registration_id: "",
      editVisibility: false,
      addVisibility: false,
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      levelPrices: null,
      levels: "",
      level_id: "",
      name: "",
      credits: "1",
      value: "",
      currency_type: "",
      status: "",
      start_date: "",
      end_date: "2099-12-31 00:00:00",
      userType: "",
      statuses: [
        {
          id: "Active",
          name: "Active",
        },
        {
          id: "Deactive",
          name: "Deactive",
        },
      ],
      currency_types: [
        {
          id: "LKR",
          name: "LKR",
        },
        {
          id: "USD",
          name: "USD",
        },
        {
          id: "EUR",
          name: "EUR",
        },
        {
          id: "CAD",
          name: "CAD",
        },
        {
          id: "AUD",
          name: "AUD",
        },
        {
          id: "GBP",
          name: "GBP",
        },
      ],
      levelPricePrivilege: null,
      form_list_id: "",
      noEndDate: true,
    };

    this.createValidationsInstance();
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        isFloat: {
          message: "The credits must be a valid number.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[+-]?\d+(\.\d+)?$/);
          },
        },
        isDecimal: {
          message:
            "Value must be a valid decimal number with up to 2 decimal places.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^\d+(\.\d{1,2})?$/);
          },
        },
      },
    });
  }

  componentDidMount() {
    if (customerConstants.EXO_CUSTOMER2 !== getCustomerFeatureFlag()) {
      const currency_types = [
        {
          id: "LKR",
          name: "LKR",
        },
      ];
      this.setState({ currency_types: currency_types });
    }
    const {
      privilege_add,
      privilege_edit,
      breadcrumb2,
      nodeTitle,
      flg_list_active,
      form_list_id,
    } = this.props.props;
    this.getVisibilityCheck(privilege_add, privilege_edit);
    this.getAllLevelPrices();
    this.getAllLevels();
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle,
      flg_list_active,
      userType: sessionStorage.getItem("userType"),
      levelPricePrivilege: this.props.levelPricePrivilege,
      form_list_id: form_list_id,
    });
  }

  formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  getAllLevels() {
    this.setState({ isTableDataLoading: true });
    const mid = 100;
    const levelPrices = this.state;
    LevelService.getAllLevels(mid)
      .then((res) => {
        if (res.success) {
          this.setState({ levels: res.data.levels });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Level Grades. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getAllLevelPrices() {
    this.setState({ isTableDataLoading: true });
    const mid = 100;
    LevelPriceService.getAllLevelPrices(mid)
      .then((res) => {
        if (res.success) {
          this.getAllLevels();

          LevelService.getAllLevels(mid)
            .then((resLevels) => {
              if (resLevels.success) {

                //get the levels that are not in level prices
                const levelPricesIds = res.data.level_prices.map(lp => lp.level_id);
                const levelsNotInPrices = resLevels.data.levels.filter(level => !levelPricesIds.includes(level.level_id));

                // Get the levels that are in level prices but marked as "Deactive"
                const deactiveLevels = resLevels.data.levels.filter(level => {
                  const levelPrice = res.data.level_prices.find(lp => lp.level_id === level.level_id);
                  return levelPrice && levelPrice.status === "Deactive";
                });

                // Combine levelsNotInPrices and deactiveLevels and remove duplicates
                const combinedLevels = [...new Set([...levelsNotInPrices, ...deactiveLevels])];
                this.setState({ levels: resLevels.data.levels, combinedLevels: combinedLevels });

              } else {
                sweetStatusAlert(
                  "Failed!",
                  "Error fetching Level Grades. Please refresh the screen!",
                  "error"
                );
              }
            })
            .finally(() => {
              this.setState({
                isTableDataLoading: false,
              });
            });
          this.setState({ levelPrices: res.data.level_prices });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Member levelPrices. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
          isButtonLoading: false
        });
      });
  }

  getVisibilityCheck(privilege_add, privilege_edit) {
    if (privilege_add == "1") {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_edit == "1") {
      this.setState({
        editVisibility: true,
      });
    }
  }
  getLevelNameById = (levelId) => {
    const { levels } = this.state;
    const levelsArray = Object.values(levels);
    const level = levelsArray.find((level) => level.level_id === levelId);
    return level ? level.name : "";
  };

  submitLevelPrice = () => {
    const initialStateValue = this.state;
    if (
      this.validator.allValid() &&
      this.validator.fieldValid("credits") &&
      this.validator.fieldValid("value")
    ) {
      this.setState({ showInsertModal: false, isLoading: true });
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");

      const {
        name,
        credits,
        value,
        level_id,
        currency_type,
        status,
        start_date,
        end_date,
        form_list_id,
      } = this.state;

      let obj = {
        node_mid: mid,
        name: name,
        credits: parseInt(credits),
        value: value,
        level_id: parseInt(level_id),
        currency_type: currency_type,
        status: status,
        start_date: start_date,
        end_date: end_date ? end_date : "2099-12-31 00:00:00",
        user_id: session_id,
        user_table_id: table_log_id,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
        form_list_id: form_list_id,
      };

      if (status === "Deactive") {
        sweetConfirmAlert(
          "Submit Confirmation",
          "Do you want to add this level price as Deactive one?",
          "warning"
        ).then((userConfirmed) => {
          if (userConfirmed) {
            LevelPriceService.createLevelPrice(JSON.stringify(obj))
              .then((res) => {
                if (res.status === 201) {
                  this.setState({ showInsertModal: false });
                  sweetAlertInfo("", "Success!");
                  this.getAllLevelPrices();
                } else {
                  sweetAlertInfo("", "Failed!");
                }
              })
              .catch(() => {
                sweetAlertInfo(
                  "Failed!",
                  "Unable to add the Member levelPrices!"
                );
              })
              .finally(() => {
                // Hide loading indicator
                this.setState({ isLoading: false });
              });
          } else {
            // User clicked "No"
            sweetAlertInfo("", "Action cancelled!");
            this.setState({ isLoading: false }); 
          }
        }).catch(() => {
          // Handle any error from sweetConfirmAlert
          this.setState({ isLoading: false });
        });
      } else {
        LevelPriceService.createLevelPrice(JSON.stringify(obj))
          .then((res) => {
            if (res.status === 201) {
              this.setState({ showInsertModal: false });
              sweetAlertInfo("", "Success!");
              this.getAllLevelPrices();
            } else {
              sweetAlertInfo("", "Failed!");
            }
          })
          .catch(() => {
            sweetAlertInfo(
              "Failed!",
              "Unable to add the Member levelPrices!"
            );
          })
          .finally(() => {
            // Hide loading indicator
            this.setState({ isLoading: false });
          });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  updateLevelPrice = () => {
    const initialStateValue = this.state;
    if (
      this.validator.allValid() &&
      this.validator.fieldValid("credits") &&
      this.validator.fieldValid("value")
    ) {
      this.setState({ showModal: false, isLoading: true });
      const {
        name,
        credits,
        value,
        level_id,
        currency_type,
        status,
        start_date,
        end_date,
        level_price_id,
        form_list_id,
      } = this.state;
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");

      let obj = {
        node_mid: mid,
        name: name,
        credits: credits,
        value: value,
        level_id: level_id,
        currency_type: currency_type,
        status: status,
        start_date: start_date,
        end_date: end_date ? end_date : "2099-12-31 00:00:00",
        user_id: session_id,
        user_table_id: table_log_id,
        form_list_id: form_list_id,
      };
      if (initialStateValue !== "Active" && status === "Deactive") {
        sweetConfirmAlert(
          "Update Confirmation",
          "Do you want to update this level price to Deactive?",
          "warning"
        ).then((userConfirmed) => {
          if (userConfirmed) {
            LevelPriceService.updateLevelPrice(JSON.stringify(obj), level_price_id)
              .then((res) => {
                if (res.status === 200) {
                  this.setState({ showModal: false });
                  this.getAllLevelPrices();
                  sweetAlertInfo("", "Success!");
                } else {
                  sweetAlertInfo("", "Failed!");
                }
              })
              .catch(() => {
                sweetAlertInfo(
                  "Failed!",
                  "Unable to add the Member Level!",
                );
              })
              .finally(() => {
                // Hide loading indicator
                this.setState({ isLoading: false });
              });
          } else {
            // User clicked "No"
            sweetAlertInfo("", "Action cancelled!");
            this.setState({ isLoading: false }); 
          }
        }).catch(() => {
          this.setState({ isLoading: false });
        });
      } else {
        LevelPriceService.updateLevelPrice(JSON.stringify(obj), level_price_id)
          .then((res) => {
            if (res.status === 200) {
              this.setState({ showModal: false });
              this.getAllLevelPrices();
              sweetAlertInfo("", "Success!");
            } else {
              sweetAlertInfo("", "Failed!");
            }
          })
          .catch(() => {
            sweetAlertInfo(
              "Failed!",
              "Unable to add the Member Level!"
            );
          })
          .finally(() => {
            this.setState({ isLoading: false });
          });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  deleteLevelPrice = (level_price_id) => {
    sweetConfirmAlert(
      "Are you sure?",
      "Do you want to delete this LevelPrice?",
      "warning"
    ).then(() => {
      this.setState({ isLoading: true });
      LevelPriceService.deleteLevelPrice(
        level_price_id,
        this.state.form_list_id
      )
        .then((res) => {
          if (res.status === 200) {
            this.setState({ showModal: false });
            this.getAllLevelPrices();
            sweetStatusAlert("Success!", res.message, "success");
          } else if (res.status === 400) {
            sweetAlertInfo(
              "",
              "Cannot delete level price. This level price already in use.",
            );
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert(
            "Failed !",
            "Unable to add the Member Level!",
            "error"
          );
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    });
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));

    if (name === "start_date") {
      const endDateInput = document.querySelector('[name="end_date"]');
      if (endDateInput) {
        endDateInput.min = value;
      }
    }
  };

  toggleAddLevelPriceModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        name: "",
        level_id: "",
        credits: "1",
        value: "",
        currency_type: "",
        status: "",
        start_date: "",
        end_date: "",
      }),
      () => { }
    );
  };

  toggleUpdateLevelPriceModal = (LevelPrice) => {
    this.setState({ initialStateValue: LevelPrice.status });
    this.setState(
      (prevState) => ({
        showModal: !prevState.showModal,
        name: "",
        level_id: "",
        credits: "1",
        value: "",
        currency_type: "",
        status: "",
        start_date: "",
        end_date: "",
      }),
      () => {
        if (this.state.showModal) {
          this.setState({
            level_price_id: LevelPrice.level_price_id,
            name: LevelPrice.name,
            level_id: LevelPrice.level_id,
            credits: LevelPrice.credits,
            value: LevelPrice.value,
            currency_type: LevelPrice.currency_type,
            status: LevelPrice.status,
            start_date: LevelPrice.start_date,
            end_date: LevelPrice.end_date,
            noEndDate: LevelPrice.end_date !== "2099-12-31 00:00:00",
          });
        }
      }
    );
  };

  convertTimeLevel(utcDateString) {
    const userTimeZone = moment.tz.guess();
    const convertedDate = moment(utcDateString).tz(userTimeZone);

    const formattedDate = convertedDate.format("MMMM D, YYYY h:mm:ss a");

    return formattedDate;
  }

  noEndDateSelected = (e, status) => {
    if (status) {
      this.setState({ noEndDate: status, end_date: "" });
    } else {
      this.setState({ noEndDate: status, end_date: "2099-12-31 00:00:00" });
    }
  };

  render() {
    const {
      isLoading,
      isTableDataLoading,
      isButtonLoading,
      editVisibility,
      levelPrices,
      name,
      level_id,
      credits,
      value,
      initialStateValue,
      levels,
      currency_type,
      status,
      start_date,
      end_date,
      statuses,
      currency_types,
      levelPricePrivilege,
      noEndDate,
      combinedLevels,
    } = this.state;
    this.validator.purgeFields();

    if (!levelPricePrivilege) {
      return (
        <div>
          <h4>Privilege not found for this section</h4>
        </div>
      );
    }
    return (
      <>
        <Card>
          <CardHeader>
            <div>
              {/* <CardTitle cardLabel={history.location.state.form_display_title} /> */}
              <CardTitle cardLabel={"Level Prices"} />
              {/* <h3 style={{ color: 'gray' }}>{(breadcrumb2 || nodeTitle) ? `(${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle})` : undefined}</h3> */}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <>
                {levelPricePrivilege.addVisibility && (
                  <Button
                    className="insert-btn"
                    variant="primary"
                    onClick={this.toggleAddLevelPriceModal}
                    disabled={combinedLevels.length <= 0}
                  >
                    Add Level Price
                  </Button>
                )}
              </>
            </div>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div className="loading-container">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div style={{ overflow: "Auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Level Price Id
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Level
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Credit
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Value
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Currency Type
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Start Date
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        End Date
                      </TableCell>
                      {(levelPricePrivilege.addVisibility ||
                        levelPricePrivilege.editVisibility ||
                        levelPricePrivilege.deleteVibility) && (
                          <TableCell
                            className="thead"
                            style={{ textAlign: "center" }}
                          >
                            Actions
                          </TableCell>
                        )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {levelPrices &&
                      levelPrices.length > 0 &&
                      levelPrices.map((levelPrice) => (
                        <TableRow key={levelPrice.level_price_id}>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {levelPrice.level_price_id}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {levelPrice.name}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {/* {levelPrice.level} */}
                            {this.getLevelNameById(levelPrice.level_id)}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {levelPrice.credits}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {levelPrice.value}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {levelPrice.currency_type}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {levelPrice.status}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {new Date(
                              this.convertTimeLevel(levelPrice.start_date)
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            })}
                          </TableCell>

                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {new Date(
                              this.convertTimeLevel(levelPrice.end_date)
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            })}
                          </TableCell>

                          {(levelPricePrivilege.addVisibility ||
                            levelPricePrivilege.editVisibility ||
                            levelPricePrivilege.deleteVibility) && (
                              <TableCell
                                className="tData"
                                style={{ textAlign: "center" }}
                              >
                                <div className="btn-group">
                                  {levelPricePrivilege.editVisibility && (
                                    <Button
                                      className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                      title="Edit Details"
                                      onClick={() =>
                                        this.toggleUpdateLevelPriceModal(
                                          levelPrice
                                        )
                                      }
                                    />
                                  )}
                                  {levelPricePrivilege.deleteVibility && (
                                    <Button
                                      className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                      title="Delete Details"
                                      onClick={() =>
                                        this.deleteLevelPrice(
                                          levelPrice.level_price_id
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </TableCell>
                            )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() => this.setState({ showInsertModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new Level Price</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={name}
                    placeholder="Name"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("name", name, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>level*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="level_id"
                    onChange={this.handleChange}
                    value={level_id}
                  >
                    <option value="">Select</option>
                    {combinedLevels.length > 0 &&
                      combinedLevels.map((level) => (
                        <option value={level.level_id} key={level.level_id}>
                          {level.name}
                        </option>
                      ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("level", level_id, "required")}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Credits*</Form.Label>
                  <Form.Control
                    type="text"
                    name="credits"
                    defaultValue="1"
                    placeholder="Credits"
                    readOnly
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {/* {this.validator.message("credits", credits, [
                      "required",
                      "isFloat",
                    ])} */}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Value*</Form.Label>
                  <Form.Control
                    type="text"
                    name="value"
                    defaultValue={value}
                    placeholder="Value"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("value", value, [
                      "required",
                      "isDecimal",
                    ])}
                  </div>
                </Form.Group>
              </Row>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Currency Type*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="currency_type"
                    onChange={this.handleChange}
                    value={currency_type}
                  >
                    <option value="">Select</option>
                    {currency_types.map((singleStatus) => (
                      <option key={singleStatus.id} value={singleStatus.name}>
                        {singleStatus.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "currency_type",
                      currency_type,
                      "required"
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Status*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="status"
                    onChange={this.handleChange}
                    value={status}
                  >
                    <option value="">Select</option>
                    {statuses.map((singleStatus) => (
                      <option key={singleStatus.id} value={singleStatus.name}>
                        {singleStatus.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("status", status, "required")}
                  </div>
                </Form.Group>
              </Row>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>start Date*</Form.Label>
                  <Form.Control
                    type="date"
                    name="start_date"
                    defaultValue={start_date}
                    placeholder="start date"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "start_date",
                      start_date,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group as={Col} md="6" controlId="noEndDate">
                  <Form.Label>Do you want to have an end date?</Form.Label>
                  <CheckBoxInline style={{ alignItems: "center" }}>
                    <Form.Label
                      className="mb-0 mr-1"
                      style={{ fontWeight: "500" }}
                    >
                      Yes
                    </Form.Label>
                    <CheckBox
                      onChange={(e) => {
                        this.noEndDateSelected(e, true);
                      }}
                      checked={noEndDate}
                    />
                    <Form.Label
                      className="mb-0 mr-1"
                      style={{ fontWeight: "500" }}
                    >
                      No
                    </Form.Label>
                    <CheckBox
                      onChange={(e) => {
                        this.noEndDateSelected(e, false);
                      }}
                      checked={!noEndDate}
                    />
                  </CheckBoxInline>
                </Form.Group>

                {noEndDate && (
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>End Date*</Form.Label>
                    <Form.Control
                      type="date"
                      name="end_date"
                      min={start_date}
                      defaultValue={end_date}
                      placeholder="end date"
                      onChange={this.handleChange}
                    />
                    <div style={{ color: "red" }}>
                      {this.validator.message(
                        "end_date",
                        end_date && noEndDate,
                        "required"
                      )}
                    </div>
                  </Form.Group>
                )}
              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.setState({ showInsertModal: false })}
                >
                  Close
                </Button>
                {editVisibility && (
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.submitLevelPrice}
                  >
                    Save changes
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Level Price</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={name}
                    placeholder="Name"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("name", name, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlSelect1"
                >
                  <Form.Label>Level*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="level_id"
                    onChange={this.handleChange}
                    value={level_id}
                    disabled
                  >
                    <option value="">Select</option>
                    {combinedLevels.length > 0 &&
                      combinedLevels.map((level) => (
                        <option value={level.level_id} key={level.level_id}>
                          {level.name}
                        </option>
                      ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("level", level_id, "required")}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Credits*</Form.Label>
                  <Form.Control
                    type="text"
                    name="credits"
                    defaultValue={credits}
                    placeholder="Credits"
                    onChange={this.handleChange}
                    disabled
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("credits", credits, [
                      "required",
                      "isFloat",
                    ])}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Value*</Form.Label>
                  <Form.Control
                    type="text"
                    name="value"
                    defaultValue={value}
                    placeholder="Value"
                    onChange={this.handleChange}
                    disabled
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("value", value, [
                      "required",
                      "isDecimal",
                    ])}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Currency Type*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="currency_type"
                    onChange={this.handleChange}
                    value={currency_type}
                    disabled
                  >
                    <option value="">Select</option>
                    {currency_types.map((singleStatus) => (
                      <option key={singleStatus.id} value={singleStatus.name}>
                        {singleStatus.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "currency_type",
                      currency_type,
                      "required"
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Status*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="status"
                    onChange={this.handleChange}
                    value={status}
                    disabled={initialStateValue !== "Active" && status === "Deactive"}
                  >
                    <option value="">Select</option>
                    {statuses.map((singleStatus) => (
                      <option key={singleStatus.id} value={singleStatus.name}>
                        {singleStatus.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("status", status, "required")}
                  </div>
                  {(initialStateValue !== "Active" && status === "Deactive") && (
                    <div style={{ marginTop: '10px', fontSize: '10px' }}>
                      Can't activate a deactivated price level. Please create a new record if you need same price for same level.
                    </div>
                  )}
                </Form.Group>
              </Row>

              <Row>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Start Date*</Form.Label>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={
                      start_date
                        ? this.formatDateForInput(new Date(start_date))
                        : ""
                    }
                    placeholder="start date"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "start_date",
                      start_date,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group as={Col} md="6" controlId="noEndDate">
                  <Form.Label>Do you want to have an end date?</Form.Label>
                  <CheckBoxInline style={{ alignItems: "center" }}>
                    <Form.Label
                      className="mb-0 mr-1"
                      style={{ fontWeight: "500" }}
                    >
                      Yes
                    </Form.Label>
                    <CheckBox
                      onChange={(e) => {
                        this.noEndDateSelected(e, true);
                      }}
                      checked={noEndDate}
                    />
                    <Form.Label
                      className="mb-0 mr-1"
                      style={{ fontWeight: "500" }}
                    >
                      No
                    </Form.Label>
                    <CheckBox
                      onChange={(e) => {
                        this.noEndDateSelected(e, false);
                      }}
                      checked={!noEndDate}
                    />
                  </CheckBoxInline>
                </Form.Group>

                {noEndDate && (
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>End Date*</Form.Label>
                    <Form.Control
                      type="date"
                      name="end_date"
                      min={start_date}
                      value={
                        end_date
                          ? this.formatDateForInput(new Date(end_date))
                          : ""
                      }
                      placeholder="end date"
                      onChange={this.handleChange}
                    />
                    <div style={{ color: "red" }}>
                      {this.validator.message(
                        "end_date",
                        end_date && noEndDate,
                        "required"
                      )}
                    </div>
                  </Form.Group>
                )}
              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.setState({ showModal: false })}
                >
                  Close
                </Button>
                {editVisibility && (
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.updateLevelPrice}
                  >
                    Save changes
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
