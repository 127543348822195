import React,{useState} from "react";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, AtomicBlockUtils, convertToRaw, Modifier} from "draft-js";
import { namespace } from "../../../helper/namespace";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    // cancelClass = "cmc-btn-default"
    // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

export default function CustomEditor ({ editorState, value, onEditorStateChange,customOptions}) {
  
   //Creating a custom table component
   const CustomTableOption = ({ addTable }) => {
     return (
       <div
         onClick={addTable}
         style={{
           cursor: "pointer",
           display: "flex",
           alignItems: "center",
           marginBottom: "5px",
         }}
         className="rdw-option-wrapper"
       >
         <i className="fa fa-table" />
       </div>
     )
   }

    //Handle add table
    const addTable = () => {;
        const numRows = prompt("Enter the number of rows:");
        const numCols = prompt("Enter the number of columns:");

        const tableData = [];
        for (let i = 0; i < numRows; i++) {
          const row = [];
          for (let j = 0; j < numCols; j++) {
            row.push("");
          }
          tableData.push(row);
        }

        const contentStateWithEntity = editorState.getCurrentContent().createEntity('TABLE', 'IMMUTABLE', {
          tableData,
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
        onEditorStateChange(newEditorState);
        const entityMap = {
                [3]: {
                  type: 'table',
                  mutability: 'IMMUTABLE',
                  tableData
                }
              };
        const updatedContentState = newEditorState.getCurrentContent();
        const rawContentState = convertToRaw(updatedContentState);
        rawContentState.entityMap = entityMap;
    };

    //Fuction to check uploaded file type
    function checkFileType(file) {
      const fileType = file.type;
      if (fileType.startsWith('audio/')) {
        return 'audio';
      } else if (fileType.startsWith('video/')) {
        return 'video';
      } else if (fileType.startsWith('image/')) {
        return 'image';
      }
      return 'unknown';
    }

    //Function to read and convert the uploaded file into Base64 data
    function convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    }

    //Handling file upload functionality
    const handleFileUpload = (file) => {
        return new Promise(async (resolve, reject) => {
              
          try{
            
            //Checking the file type
            const fileType = checkFileType(file);

            //Checking the file size
            const fileSizeLimit = 10 * 1024 * 1024; // 10MB limit (adjust the value as needed)
            if (file.size > fileSizeLimit) {
              reject(new Error('File size exceeds the limit.'));
              window.Swal.fire({
                title: namespace.FAILED,
                text: namespace.FILE_SIZE_IS_EXCEEDED,
                icon: 'error',
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                      modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
              }
              });
              return;
            }
             
            //Handling image file upload
            if(fileType=='image'){
              
              const base64Data = await convertFileToBase64(file);
              resolve({ data: { link: base64Data } });   

            }
            
            //Handling audio file upload
            else if (fileType == 'audio'){
              
              const base64Data = await convertFileToBase64(file);
              const contentStateWithEntity = editorState
                .getCurrentContent()
                .createEntity('AUDIO', 'IMMUTABLE', { src: base64Data });
              const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
              const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                editorState,
                entityKey,
                ' '
              );
              onEditorStateChange(newEditorState);
              resolve({ data: { link: base64Data } });
              window.Swal.fire({
                title: 'Success!',
                text: namespace.AUDIO_ADD_SUCCESS ,
                icon: 'success',
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                      modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
              }
              });

            }

             //Handling audio file upload
            else if (fileType == 'video'){

              const base64Data = await convertFileToBase64(file);
              const contentStateWithEntity = editorState
                .getCurrentContent()
                .createEntity('VIDEO', 'IMMUTABLE', { src: base64Data });
              const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
              const newEditorState = AtomicBlockUtils.insertAtomicBlock(
                editorState,
                entityKey,
                ' '
              );
              onEditorStateChange(newEditorState);
              resolve({ data: { link: base64Data } });
              window.Swal.fire({
                title: 'Success!',
                text: namespace.VIDEO_ADD_SUCCESS ,
                icon: 'success',
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                      modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
              }
              });

            }

            }

            catch(error) {reject(error);}

            });
               
            }
    
   //Rendering table block
    function TableBlock(props) {
      const { block, contentState} = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { tableData } = entity.getData();
      const entityKey = block.getEntityAt(0);

      const handleEditCell = (e, rowIndex, cellIndex) => {
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex][cellIndex] = e.target.value;
        const newContentState = contentState.mergeEntityData(entityKey, { tableData: updatedTableData });
        onEditorStateChange(EditorState.push(editorState, newContentState));
      };

      return (
        <table>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cellData, cellIndex) => (
                  <td 
                  key={cellIndex} 
                  style={{ border: "1px solid black", padding: "8px" }}
                  >
                  <input
                  type="text"
                  value={cellData}
                  onChange={(e) => handleEditCell(e, rowIndex, cellIndex)}
                  style={{ outline: "none", border: "none" }}
                  />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }
    
    //Creating the eraser component
    const eraser = () => {
      return (
        <div
          onClick={(e) => {
            e.preventDefault()
            onEditorStateChange(EditorState.createEmpty())
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
          className="rdw-option-wrapper"
        >
          <i className="fa fa-eraser" />
        </div>
      )
    }
  
    //Creating custom block component to render image
    const ImageBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src } = entity.getData();
      return <img src={src} alt="Image" />;
    }
    
    //Creating custom block component to render audio
    const AudioBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src } = entity.getData();
      return <audio src={src} controls />;
    };

    //Creating custom block component to render video
    const VideoBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src } = entity.getData();
      return <video src={src} controls />;
    };

    //Function to render the respective blocks
    const BlockRenderer = (block) => {
    if (block.getType() === "atomic") {
      const contentState = editorState.getCurrentContent();
      const entity = contentState.getEntity(block.getEntityAt(0));
      const entityType = entity.getType();

      if (entityType === "AUDIO") {
        const { src } = entity.getData();
        return {
          component: AudioBlock,
          editable: false,
          props: {
            src,
          },
        };
      }

      if (entityType === "VIDEO") {
        const { src } = entity.getData();
        return {
          component: VideoBlock,
          editable: false,
          props: {
            src,
          },
        };
      }

      if (entityType === "IMAGE") {
        const { src } = entity.getData();
        return {
          component: ImageBlock,
          editable: false,
          props: {
            src,
          },
        };
      }

      if (entityType === "TABLE") {
        const { tableData } = entity.getData();
        return {
          component: TableBlock,
          editable: false,
          props: {
            block,
            contentState,
            tableData
          },
        };
      }

    }
    return null;
  }

  return (
      <div>
        <Editor
            editorState= {editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            value={{key:value , state: editorState}}
            onEditorStateChange={onEditorStateChange}
            blockRendererFn={BlockRenderer}
            toolbar={{
                options: customOptions?customOptions:['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: handleFileUpload,
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg,audio/mpeg,video/mp4',
                  //alt: { present: true, mandatory: true },
                  defaultSize: {
                      height: 'auto',
                      width: 'auto',
                    },
                },
                fontFamily: {
                  options: [
                    "Arial",
                    "Georgia",
                    "Impact",
                    "Tahoma",
                    "Times New Roman",
                    "Verdana",
                    "Kushpoo",
                    "Baamini",
                    "FMAbhaya"
                  ],
                },
                remove: {component:eraser},
               }}
               toolbarCustomButtons={[<CustomTableOption addTable={addTable}/>]}
        />
        </div>
    )
}
