import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const RequestService = {
  submitRequest,
  submitRegistration,
  getRequestsByPagination,
  getRegistration,
  approveRequest,
  approveRegistration,
  registerRequestDetils,
  rejectRequest,
  checkEmail,
  checkParentEmail,
  checkStudentEmail,
  getAllByPagination,
  getAllStudentsByEntryId,
  getAllRequestsForStudentsByEmail,
  getAllRequestsForParentsByEmail
};

async function submitRequest(reqdata) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: reqdata,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/add`,
    requestOptions
  );
  return await response.json();
}

async function submitRegistration(reqdata) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: reqdata,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/register`,
    requestOptions
  );
  return await response.json();
}

async function registerRequestDetils(reqdata) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: reqdata,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registrations/add`,
    requestOptions
  );
  return await response.json();
}

async function getRequestsByPagination(
  limit,
  offset,
  search_term,
  sort_direction,
  sort_column,
  filter_by
) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/getAll/${limit}/${offset}/${sort_column}/${sort_direction}/${filter_by}?search_term=${search_term}`,
    requestOptions
  );
  const data = await response.json();
  return data;
  // return APIHandler.handleResponse(data)
}
async function getAllByPagination(
  limit,
  offset,
  search_term,
  sort_direction,
  sort_column,
  filter_by
) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/getAllStud/${limit}/${offset}/${sort_column}/${sort_direction}/${filter_by}?search_term=${search_term}`,
    requestOptions
  );
  const data = await response.json();
  return data;
  // return APIHandler.handleResponse(data)
}
async function getRegistration(child_email) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/getInvitation/${child_email}`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

async function approveRequest(invitation_id, handler_id, handler_table_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registrations/verification/${invitation_id}?h_id=${handler_id}&h_t_id=${handler_table_id}`,
    requestOptions
  );
  return await response.json();
}

async function approveRegistration(invitation_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registrations/verification_child/${invitation_id}`,
    requestOptions
  );
  return await response.json();
}

async function rejectRequest(invitation_id, handler_id, handler_table_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registrations/reject/${invitation_id}?h_id=${handler_id}&h_t_id=${handler_table_id}`,
    requestOptions
  );
  return await response.json();
}

async function checkEmail(email, registration_type) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/check-mail-exist/${email}/${registration_type}`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

async function checkParentEmail(email) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/identify-parent-email/${email}`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

async function checkStudentEmail(email) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/identify-student-email/${email}`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

async function getAllStudentsByEntryId(
  entry_id,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/getAllStuRegWithRefPaginationAndSorting/${entry_id}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
    requestOptions
  );
  const data = await response.json();
  return data;
  // return APIHandler.handleResponse(data)
}

async function getAllRequestsForStudentsByEmail(
  email,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/get-all-requests-for-student/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}&email=${email}`,
    requestOptions
  );
  const data = await response.json();
  return data;
  // return APIHandler.handleResponse(data)
}

async function getAllRequestsForParentsByEmail(
  email,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/invitations/get-all-parent-requests/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}&email=${email}`,
    requestOptions
  );
  const data = await response.json();
  return data;
  // return APIHandler.handleResponse(data)
}
