import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import Spinner from "react-bootstrap/Spinner";
import debounce from "lodash.debounce";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../../components/admin/css/common.css";
import sweetAlert, {
  sweetStatusAlert,
} from "../../../components/common/SweetAlert";
import { CountryService } from "../../../service/settings/manage/country.service.js";
import SimpleReactValidator from "simple-react-validator";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import ContentWrapper from "../../../components/common/ContentWrapper";
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

export default class ManageCountry extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
      showModal: false,
      countryId: "",
      countryName: "",
      countryDescription: "",
      countries: [],
      isModified: false,
      modalType: false,
      currentPage: 0,
      totalCountries: 10,
      isLoading: false,
      search_term: "",
      limit: 10,
      offset: 0,
      isTableDataLoading: true,
      editVisibility: true,
      addVisibility: true,
      deleteVisibility: false,
    };
    this.debouncedHandleSearch = debounce(this.fetchCountryData, 2000);
  }

  resetFormFields = () => {
    this.setState({
      countryName: "",
      countryDescription: "",
    });
  };

  componentDidMount() {
    // const staff_id = sessionStorage.getItem("staff_id");
    // this.getVisibilityCheck(staff_id);
    this.fetchCountryData();
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  //fetching country data with limit
  fetchCountryData = () => {
    const { limit, offset, search_term } = this.state;
    CountryService.getAllCountriesLimit(limit, offset, search_term)
      .then((response) => {
        if (response.success) {
          this.setState({
            countries: response.data.countries,
            totalCountries: parseInt(response.data.count),
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Countries. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        sweetStatusAlert(
          "Failed!",
          "Error fetching Countries. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isLoading: false, isTableDataLoading: false });
      });
  };

  //table pagination handle
  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchCountryData();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.fetchCountryData();
      }
    );
  };

  //table rows handle
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage, 
        isTableDataLoading: true,
        currentPage: newPage, 
      },
      () => {
        this.fetchCountryData();
      }
    );
  };

  //handle country add modal
  toggleAddCountryModal = (modalType) => {
    this.modalType = modalType;
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
  };

  //handle country update modal
  toggleUpdateCountryModal = (
    countryId,
    countryName,
    countryDescription,
    modalType
  ) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      countryId: countryId,
      countryName: countryName,
      countryDescription: countryDescription,
      modalType: modalType,
    }));
  };

  //handle sweet alert
  handleSweetAlert = (modalType, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.modalType = false;
        this.validator.hideMessages();
        this.validator.purgeFields();
        this.resetFormFields();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.hideMessages();
      this.validator.purgeFields();
      this.resetFormFields();
      if (modalType) this.toggleAddCountryModal();
      else this.toggleUpdateCountryModal();
    }
  };

  //handle state values
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  //handle add country
  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const { countryName, countryDescription } = this.state;
      const newCountry = { name: countryName, description: countryDescription };

      CountryService.add(newCountry)
        .then((response) => {
          if (response.success) {
            sweetStatusAlert(
              "Success!",
              "New country added successfully..!",
              "success"
            );
            this.setState({ isLoading: false });
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.toggleAddCountryModal(false);
            this.fetchCountryData();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", "Unable to create new country", "error");
        });
    }
  };

  //handle edit country
  handleEditSubmit = (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const { countryName, countryDescription } = this.state;
      const updateCountry = {
        name: countryName,
        description: countryDescription,
      };
      const country_id = this.state.countryId;

      CountryService.update(updateCountry, country_id)
        .then((response) => {
          if (response.success) {
            this.setState({ showModal: false });
            sweetStatusAlert("Success!", response.message, "success");
            this.setState({ isLoading: false });
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.fetchCountryData();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed!",
            "Unable to update the country informations",
            "error"
          );
        });
    }
  };

  //handle the search with pagination
  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  render() {
    const {
      currentPage,
      limit,
      totalCountries,
      isTableDataLoading,
      editVisibility,
      addVisibility,
      isLoading,
      deleteVisibility,
    } = this.state;
    return (
      <>
            <ContentWrapper>              
              <div className="row justify-content-between align-items-center py-5 mb-3">
                <div className="col-md-6 col-6"><h3 className='text-nowrap'>Manage Country</h3></div>
                <div>
                  {addVisibility && (
                    <Button
                      className="insert-btn"
                      variant="primary"
                      onClick={() => this.toggleAddCountryModal(true)}
                    >
                      Add Country
                    </Button>
                  )}
                </div>
              </div>
              <div>
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    // style={{ width: "18%" }}
                    className="col-md-3 col-12"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                {isLoading && (
                  <PageSpinner id="show" text="Loading, Please wait!" />
                )}
                {isTableDataLoading ? (
                  <div
                    className="loading-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "75vw",
                      height: "90vh"
                    }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : this.state.countries.length > 0 ? (
                  <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" className="thead">
                            No
                          </TableCell>
                          <TableCell align="left" className="thead">
                            Country Name
                          </TableCell>
                          <TableCell align="left" className="thead">
                            Country Description
                          </TableCell>
                          {(editVisibility || deleteVisibility) && (
                            <TableCell className="thead d-flex justify-content-end">Actions</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.countries.map((countryItem) => (
                          <TableRow
                            key={countryItem.country_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left" className="tData">
                              {countryItem.country_id
                                ? countryItem.country_id
                                : "null"}
                            </TableCell>
                            <TableCell align="left" className="tData">
                              {countryItem.name ? countryItem.name : "null"}
                            </TableCell>
                            <TableCell align="left" className="tData">
                              {countryItem.description
                                ? countryItem.description
                                : "No description"}
                            </TableCell>
                            {(editVisibility || deleteVisibility) && (
                              <TableCell align="right">
                                <div className="btn-group">
                                  {editVisibility && (
                                    <Button
                                      onClick={() =>
                                        this.toggleUpdateCountryModal(
                                          countryItem.country_id
                                            ? countryItem.country_id
                                            : "",
                                          countryItem.name
                                            ? countryItem.name
                                            : "",
                                          countryItem.description
                                            ? countryItem.description
                                            : "",
                                          false
                                        )
                                      }
                                      className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                      title="Edit Details"
                                    />
                                  )}
                                  {deleteVisibility && (
                                    <Button
                                      className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                                      title="Delete"
                                    />
                                  )}
                                </div>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <MaterialCustomTablePagination
                      totalCount = {Number(totalCountries)}
                      currentPage = {Number(currentPage)}
                      limit = {Number(limit)}
                      handlePageChange = {this.handlePageChange}
                      handleRowsPerPageChange = {this.handleRowsPerPageChange}
                      handlePageOffsetChange = {this.handlePageOffsetChange}
                      offset = {Number(this.state.offset)}
                      rowsPerPageOptions={[5, 10, 20]}
                    />
                  </div>
                ) : (
                  <div className="text-center mb-5 mt-10" style={{ height: "90vh" }}>
                    <label>No Countries Available!</label>
                  </div>
                )}
              </div>
            </ContentWrapper>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.showModal}
            onHide={() => this.handleSweetAlert(this.modalType, "showModal")}
          >
            {this.modalType ? (
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>Create New Country </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Country Name *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Country Name"
                          name="countryName"
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "countryName",
                          this.state.countryName,
                          "required|alpha_space|max:30",
                          {
                            className: "text-danger",
                            messages: {
                              max: "The country name should not be greater than 30 characters.",
                              alpha_space:
                                "The country name must have valid characters.",
                            },
                          }
                        )}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Description"
                          name="countryDescription"
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "countryDescription",
                          this.state.countryDescription,
                          "alpha_space|max:100",
                          {
                            className: "text-danger",
                            messages: {
                              max: "The country description should not be greater than 100 characters.",
                              alpha_space:
                                "The country description must have valid characters.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" style={ addBtnStyle } onClick={this.handleSubmit}>
                    Save changes
                  </Button>
                  <Button
                    id="modal-close-button"
                    style={ closeBtnStyle }
                    onClick={() =>
                      this.handleSweetAlert(this.modalType, "showModal")
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </div>
            ) : (
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>Update Country Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Country Name *</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Country Name"
                          defaultValue={this.state.countryName}
                          onChange={(e) =>
                            this.setState({
                              countryName: e.target.value,
                              isModified: true,
                            })
                          }
                        />
                        {this.validator.message(
                          "countryName",
                          this.state.countryName,
                          "required|alpha_space|max:30",
                          {
                            className: "text-danger",
                            messages: {
                              max: "The country name should not be greater than 30 characters.",
                              alpha_space:
                                "The country name must have valid characters.",
                            },
                          }
                        )}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Description"
                          defaultValue={this.state.countryDescription}
                          onChange={(e) =>
                            this.setState({
                              countryDescription: e.target.value,
                              isModified: true,
                            })
                          }
                        />
                        {this.validator.message(
                          "countryDescription",
                          this.state.countryDescription,
                          "alpha_space|max:100",
                          {
                            className: "text-danger",
                            messages: {
                              max: "The country description should not be greater than 100 characters.",
                              alpha_space:
                                "The country description must have valid characters.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={ addBtnStyle }
                    onClick={this.handleEditSubmit}
                  >
                    Save changes
                  </Button>
                  <Button
                    className="modal-btn"
                    id="modal-close-button"
                    style={ closeBtnStyle }
                    onClick={() =>
                      this.handleSweetAlert(this.modalType, "showModal")
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Modal>
      </>
    );
  }
}
