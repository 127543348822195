import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const DocumentService = {
  addDocument,
  updateDocument,
  getDocumentsByContainerId,
  deleteDocument,
};

export const StudentDocumentService = {
  addStudentDocument,
  updateStudentDocument,
  getStudentDocumentsByContainerId,
  deleteStudentDocument,
  getDocsByContainerId
}

async function addDocument(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/documents/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateDocument(data, document_id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/documents/update/${document_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getDocumentsByContainerId(container_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Time-Zone":Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/documents/get-by-container-id/${container_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteDocument(document_id) {

  const requestOptions = {
    method: "DELETE",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/documents/delete/${document_id}`,
    requestOptions
  );

  return APIHandler.handleResponse(response);
}

async function addStudentDocument(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-documents/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateStudentDocument(data, document_id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-documents/update/${document_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getStudentDocumentsByContainerId(container_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Time-Zone":Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-documents/get-by-container-id/${container_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
async function getDocsByContainerId(container_id,user_id,user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Time-Zone":Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-documents/get-by-container/${container_id}?cr_u_id=${user_id}&cr_u_t_id=${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteStudentDocument(document_id) {

  const requestOptions = {
    method: "DELETE",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/student-documents/delete/${document_id}`,
    requestOptions
  );

  return APIHandler.handleResponse(response);
}
