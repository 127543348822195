import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const NodeGroupService = {
    addNodeGroup,
    getNodeGroups,
    deleteNodeGroup,
    updateNodeGroup
  };

async function addNodeGroup(newNodeGroup) {
    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(newNodeGroup),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/node-groups/add`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getNodeGroups() {
    const requestOptions = {
      method: "GET",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/node-groups/getAll`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function deleteNodeGroup(id){

    const configOptions = {
        method: "DELETE",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        };

        const response = await fetch(
        `${config.oat_api_url}/manage/node-groups/delete-node-group/${id}`,
        configOptions
        );
        return APIHandler.handleResponse(response);

}

async function updateNodeGroup(updateNodeGroup , id){

    const requestOptions = {
        method: "PUT",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(updateNodeGroup),
      };
      const response = await fetch(
        `${config.oat_api_url}/manage/node-groups/update/${id}`,
        requestOptions
      );
      return APIHandler.handleResponse(response);
}
