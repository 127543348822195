import React, { Component } from "react";
import { connect } from "react-redux";
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import CardBody from '../../../components/common/CardBody';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import TabList from '../../../components/common/tabs/TabList';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { quesTermActions } from "../../../actions/settings/question/termAction.js"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config.js";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class QuestionTerm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newTerm:null,
        termRowsPerPage:5,
        currentTermPage: 0,
        termData: [],
        term_sort_column: "term_id",
        term_sort_direction: "ASC",
        selectTerm: '',
        editTerm: false,
        term: '',
        limit: 10,
        term_search_term: "",
        term_offset: 0,
        searchError: false,
        term_limit:5,
        termId: '',
        termOptionsImport: [],
        selectedTerm: []
      };
      
      this.onNewTermInput = this.onNewTermInput.bind(this);
      this.onAddNewTerm = this.onAddNewTerm.bind(this);
      this.generateTermOptions = this.generateTermOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllQuestionTerms();
    }
    
    onNewTermInput(e) {
        this.setState({
            newTerm: e.target.value
        });
    }
    
    async onAddNewTerm(e) {
        if (this.state.newTerm && this.state.newTerm.trim()) {
            await this.props.createTerm({ 'term': this.state.newTerm })
            if (this.props.successtermcreate) {
                this.setState(
                    {
                        term_offset: 0,
                        currentTermPage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllQuestionTerms();
                        this.generateTermOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new term/semester!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-category').modal('hide')
                                window.$("#question-category").val('default');
                                window.$('#question-category').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errortermupdate) {
                window.Swal.fire({
                    title: 'Failed to add a new term/semester!',
                    text: this.props.errortermupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty term/semester!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newTerm: ''
        });
    }

    handleTermSearch = (event) => {
        const term_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (term_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (term_search_term === "" || regex.test(term_search_term)) {
            this.setState(
                {
                    term_search_term,
                    term_offset: 0,
                    currentTermPage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllQuestionTerms();
                }
            );
        } else {
            this.setState({ term_search_term, searchError: true });
        }
    };
    
    fetchAllQuestionTerms = async () => {
        const {
            term_limit,
            term_offset,
            term_sort_column,
            term_sort_direction,
            term_search_term
        } = this.state;
        await this.props.getAllQuestionTerms(term_limit, term_offset, term_sort_column, term_sort_direction, term_search_term);
        this.setState({ term_limit: this.state.termRowsPerPage });
        this.setState({ termData: this.props.allQuestionTerms })
    };

    handleTermPageChange = (event, newTermPage) => {
        const {termRowsPerPage} = this.state;
        const newTermOffset = newTermPage * termRowsPerPage;
        this.setState(
        {
            term_limit: termRowsPerPage,
            term_offset: newTermOffset,
            currentTermPage: newTermPage
        },
        () => {
            this.fetchAllQuestionTerms();
        }
        );
    };
     
    handlePageOffsetChange = (newOffset, newPage) => {
        const {termRowsPerPage} = this.state;
        this.setState(
        {
            term_limit: termRowsPerPage,
            term_offset: newOffset,
            currentTermPage: newPage
        },
        () => {
            this.fetchAllQuestionTerms();
        }
        );
    };
    
    //handle the data fetching as per the data per page limit gets changes
    handleTermRowsPerPageChange = (event) => {
        const newTermLimit = parseInt(event.target.value, 10);
        this.setState({termRowsPerPage: newTermLimit});
        const newTermPage = Math.floor(this.state.term_offset / newTermLimit)
        this.setState(
        {
            term_limit: newTermLimit,
            term_offset: newTermLimit * newTermPage,
            currentTermPage: newTermPage
        },
        () => {
            this.fetchAllQuestionTerms();
        }
        );
    };

    handleSortTermChange = (term_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { term_sort_column: currentTermSortColumn, term_sort_direction } = this.state;
        const newTermSortDirection =
        currentTermSortColumn === term_sort_column && term_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            term_sort_column,
            term_sort_direction: newTermSortDirection,
            term_offset: 0,
            currentTermPage: 0,
        },
        () => {
            this.fetchAllQuestionTerms();
        }
        );
    }

    getQuestionTerm(term_id) {
        let termname
        this.props.allQuestionTerms.map(term => {
            if (term.term_id === term_id) {
                return termname = term.term
            }
        })
        return termname
    }
    
    onEditTerm(e, el) {
        this.setState({
            termId: el.term_id,
            term: e.target.value
        })
    }
    
    async onUpdateTerm(e) {
        e.preventDefault()
        var obj = {
            term_id: this.state.termId,
            term: this.state.term
        }
        this.setState({ selectTerm: '' })
        if (!this.state.term.trim()) {
            window.Swal.fire({
                title: 'Failed to update term/semester!',
                text:'Term/Semester cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateTerm(obj);
            if (this.props.successtermupdate) {
                // await this.props.getAllQuestionTerms(term_limit, term_offset, term_sort_column, term_sort_direction, term_search_term);
                this.fetchAllQuestionTerms()
                this.generateTermOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated term/semester!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });
            }
            if (this.props.errortermupdate) {
                window.Swal.fire({
                    title: 'Failed to update term/semester!',
                    text: this.props.errortermupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        }
    }

    generateTermOptions() {
        let termOptionsImport = []
        this.props.allQuestionTerms.map((el, i) => {
            termOptionsImport.push(<Option key={el.term_id} optionName={el.term} value={el.term_id} />)
        });

        this.setState({
            termOptionsImport: termOptionsImport,
            selectedTerm: termOptionsImport[0].props.value
        });

        if (termOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditTerm(el, i, e) {
        e.preventDefault()
        this.setState({
            editTerm: true,
            selectTerm: i,
            termId: el.term_id,
            term: el.term
        })
    }

    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the term/semester?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteTerm(el);
                if (this.props.successtermdelete) {
                    // await this.props.getAllQuestionTerms(term_limit, term_offset, term_sort_column, term_sort_direction, term_search_term);
                    this.fetchAllQuestionTerms()
                    this.generateTermOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted term/semester!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });
                }
                if (this.props.errortermdelete) {
                    window.Swal.fire({
                        title: 'Failed to delete term/semester!',
                        text: this.props.errortermdelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-term').modal('hide')
                            window.$("#question-term").val('default');
                            window.$('#question-term').selectpicker('refresh');
                        });

                }
            }
        })
    }
    
    syncTableTerm() {
        return (
            <>
                {this.state.termData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortTermChange("term_id")}
                                >
                                    No{" "}{this.state.term_sort_column === "term_id" && (
                                    <span>{this.state.term_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortTermChange("term")}
                                >
                                    Term/Semester Name{" "}
                                    {this.state.term_sort_column === "term" && (
                                    <span>{this.state.term_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.termData.map((el, i) => {
                                if (i === this.state.selectTerm) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editTerm ? 
                                                this.getQuestionTerm(el.term_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.term}
                                                    onChange={(e) => this.onEditTerm(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateTerm(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Term" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getQuestionTerm(el.term_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditTerm(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Term" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"  
                                                    title="Delete Term" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Terms Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3>Manage Terms/Semesters</h3>
              </div>

              <CardBody>
                <div className="row justify-content-end">
                  <div
                    className="table-wrapper-scroll-y my-custom-scrollbar"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <TabList>
                      <QuestionAccordion
                        labelName="Create New Term/Semester"
                        onChange={this.onNewTermInput}
                        onClick={this.onAddNewTerm}
                        val={this.state.newTerm}
                      />
                    </TabList>
                    <div className="row justify-content-end col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleTermSearch}
                          className="col-md-18 col-18"
                        />
                      </Form.Group>
                    </div>
                    <TabContent>
                      <TabPane
                        className="tab-pane fade show active"
                        id="manage-1"
                        ariaLabelledby="manage-tab-1"
                      >
                        {/* begin: Datatable */}
                        {this.props.cat_loading ? (
                          <div style={{ width: "75vw", overflowX: "scroll" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Term/Semester Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                            <div className="loading-container mb-5 mt-10">
                              <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </ReactSpinner>
                            </div>
                          </div>
                        ) : this.state.termData.length > 0 ? (
                          this.syncTableTerm()
                        ) : (
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Term/Semester Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {/* end: Datatable */}
                            </Table>
                            <div className="text-center mb-5 mt-10">
                              <label>No Terms Available!</label>
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                            totalCount = {this.props.totalTermsCount}
                            currentPage = {this.state.currentTermPage}
                            limit = {this.state.termRowsPerPage}
                            handlePageChange = {this.handleTermPageChange}
                            handleRowsPerPageChange = {this.handleTermRowsPerPageChange}
                            handlePageOffsetChange = {this.handlePageOffsetChange}
                            offset = {this.state.term_offset}
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalTermsCount: state.termReducer.totalTermsCount,//
        cat_loading:state.termReducer.loading,
        error: state.termReducer.error,//
        // totalCount: state.questionsLibraryReducer.totalCount,//
        
        errortermdelete: state.termReducer.errortermdelete,
        successtermdelete: state.termReducer.successtermdelete,

        errortermupdate: state.termReducer.errortermupdate,
        successtermupdate: state.termReducer.successtermupdate,//
        
        errortermcreate: state.termReducer.errortermcreate,
        successtermcreate: state.termReducer.successtermcreate,//

        allQuestionTerms: state.termReducer.allQuestionTerms,//
        loading: state.termReducer.loading,//
    }
}

    const mapActionsToProps = {
        createTerm: quesTermActions.createTerm,
        getAllQuestionTerms: quesTermActions.getAllQuestionTerms,
        updateTerm: quesTermActions.updateTerm,
        deleteTerm: quesTermActions.deleteTerm,
    }

    export default connect(mapStateToProps, mapActionsToProps)(QuestionTerm);

