import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const LevelGradeService = {
    createLevelGrade,
    getAllLevelGrades,
    updateLevelGrade,
    deleteLevelGrade
};

async function createLevelGrade(level_grade) {
    const requestOptions = {
        method: "POST",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: level_grade,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-grades/add`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllLevelGrades(node_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-grades/getAllLevelGrades/${node_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function updateLevelGrade(level_grade, level_grade_id) {
    const requestOptions = {
        method: "PUT",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: level_grade,
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-grades/update/${level_grade_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function deleteLevelGrade(level_grade_id, form_list_id) {
    const configOptions = {
        method: "DELETE",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-grades/delete/${level_grade_id}/${form_list_id}`,
        configOptions
    );
    return APIHandler.handleResponse(response);
}