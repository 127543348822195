import {userTypes} from '../types';

const initialState = {
    allStaffUsers: [],
    staffUserMap: {},
    staffUserRoleMap: {},
    loading: false,
    error: '',
    assessors: [],
    text: ''
}

export function staffUserReducer(state = initialState, action){
    switch (action.type){
        case userTypes.CREATE_STAFFUSER_MAP:
            return {
                ...state,
                staffUserMap: action.staffuser_data.map,
                staffUsers: action.staffuser_data.staffusers,
                loading: true
            }
        case userTypes.GET_ALL_STAFFUSERS:
            return {
                ...state,
                allStaffUsers: [],
                loading: true,
                text: 'Obtaining all the staffusers'
            }
        case userTypes.GET_ALL_STAFFUSERS_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Obtaining all the staffusers'
            }
        case userTypes.GET_ALL_STAFFUSERS_SUCCESS:
            return {
                ...state,
                allStaffUsers: action.staffusers,
                loading: false,
                success: 'Obtained staffusers successfully'
            }
        case userTypes.GET_ALL_STAFFUSERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case userTypes.CREATE_STAFF_USER_ROLES_MAP:
            return {
                ...state,
                staffUserRoleMap: action.map,
                loading: true
            }
        case userTypes.GET_STAFF_USER_ROLES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case userTypes.GET_STAFF_USER_ROLES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case userTypes.GET_STAFF_USER_ROLES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}
