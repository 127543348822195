import React, { Component } from "react"
import config from "../../../helper/config"
import { customerConstants } from "../../../helper/constants.js"
import Modal from "react-bootstrap/Modal"
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates"
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop"
import PostAddIcon from "@mui/icons-material/PostAdd"
import step1img1 from "../../../components/cmc/assets/images/steps/1_step 1.png"
import step1img2 from "../../../components/cmc/assets/images/steps/1_step 2 & 3.JPG"
import step2img1 from "../../../components/cmc/assets/images/steps/2_step 1.JPG"
import step2img2 from "../../../components/cmc/assets/images/steps/2_Step 2 & 3.JPG"
import step2img4 from "../../../components/cmc/assets/images/steps/2_step5.png"
import {
  estep1img1,
  estep1img2,
  estep1img3,
  estep2img1,
  estep2img2,
  estep2img3,
  estep2img4,
  estep2img5,
  estep2img6,
  estep2img7,
  estep2img8,
  estep2img9,
  estep2img10,
  estep2img11,
  estep2img12,

  esubImg1,
  esubImg2,
  esubImg3,
  esubImg4,

  esearchImg4,
} from "../../../components/edsider/assets/images/Guidelines/guidelines.js"
import Quick_Guide_Paper_Search from "../../../components/edsider/assets/images/Guidelines/EdSider_Quick Guide (Paper Search)_062024_V2 1.pdf"
import Quick_Guide_Student from "../../../components/edsider/assets/images/Guidelines/EdSider_Quick Guide (Student)_062024_V2.pdf"
import Quick_Guide_Parent from "../../../components/edsider/assets/images/Guidelines/EdSider_Quick Guide (Parent)_062024_V2 1.pdf"



const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let isCMC,
  isEdSider,
  iconColor,
  subscriptionModal,
  searchPaperModal,
  stepsLabel,
  stepsBtn

const customer = getCustomerFeatureFlag()

switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    isCMC = false
    isEdSider = true
    stepsLabel = {
      padding: "5px 10px",
      backgroundColor: "#f5f5ff",
      color: iconColor,
      fontWeight: "600",
      borderRadius: "5px",
      border: "1px solid #c8c9f9",
    }
    stepsBtn = {
      backgroundColor: iconColor,
      padding: "5px 10px",
      borderRadius: "3px",
      color: "#fff",
    }
    subscriptionModal = {
      subImg1: esubImg1,
      subImg2: esubImg2,
      subImg3: esubImg3,
      subImg4: esubImg4,
    }
    searchPaperModal = {
      step2img1: estep2img1,
      step2img2: estep2img2,
      step2img3: estep2img3,
      step2img4: estep2img4,
      step2img5: estep2img5,
      step2img6: estep2img6,
      step2img7: estep2img7,
      step2img8: estep2img8,
      step2img9: estep2img9,
      step2img10: estep2img10,
      step2img11: estep2img11,
      step2img12: estep2img12,

      searchImg4: esearchImg4,
    }
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    isCMC = true
    isEdSider = false
    stepsLabel = {
      padding: "5px 10px",
      backgroundColor: "#eafaff",
      color: iconColor,
      fontWeight: "600",
      borderRadius: "5px",
      border: "1px solid #b3edff",
    }
    stepsBtn = {
      backgroundColor: iconColor,
      padding: "5px 10px",
      borderRadius: "3px",
      color: "#fff",
    }
    subscriptionModal = {
      step1img1: step1img1,
      step1img2: step1img2,
    }
    searchPaperModal = {
      step2img1: step2img1,
      step2img2: step2img2,
      step2img4: step2img4,
    }
    break
  default:
    iconColor = "var(--maincolor)"
    isCMC = false
    isEdSider = false
    stepsLabel = {
      padding: "5px 10px",
      backgroundColor: "#f5f5ff",
      color: iconColor,
      fontWeight: "600",
      borderRadius: "5px",
      border: "1px solid #c8c9f9",
    }
    stepsBtn = {
      backgroundColor: iconColor,
      padding: "5px 10px",
      borderRadius: "3px",
      color: "#fff",
    }
    subscriptionModal = {
      step1img1: estep1img1,
      step1img2: estep1img2,
      step1img3: estep1img3,
    }
    searchPaperModal = {
      step2img1: estep2img1,
      step2img2: estep2img2,
      step2img3: estep2img3,
      step2img4: estep2img4,
      step2img5: estep2img5,
      step2img6: estep2img6,
      step2img7: estep2img7,
      step2img8: estep2img8,
      step2img9: estep2img9,
      step2img10: estep2img10,
      step2img11: estep2img11,
      step2img12: estep2img12,
    }
    break
}

class QuickGuides extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearchPaperModalVisible: false,
      isBuyPaperModalVisible: false,
      featureFlag: getCustomerFeatureFlag(),
      user_type: '',
    }
  }

  componentDidMount() {
    const userType = sessionStorage.getItem("userType");
    this.setState({ user_type: userType })
  }

  toggleSearchPaperModal = () => {
    this.setState((prevState) => ({
      isSearchPaperModalVisible: !prevState.isSearchPaperModalVisible,
    }))
  }

  toggleBuyPaperModal = () => {
    this.setState((prevState) => ({
      isBuyPaperModalVisible: !prevState.isBuyPaperModalVisible,
    }))
  }

  render() {
    const { isBuyPaperModalVisible, isSearchPaperModalVisible, user_type } = this.state

    return (
      <>

        {user_type === "admin" ? (
          <div>
            <div className="col-md-12 colPadding">
              <div className="row d-flex align-items-center">
                <div className="d-flex align-items-center colPadding">
                  <TipsAndUpdatesIcon sx={{ fontSize: "25px", color: "#ffbc04" }} />
                  <h1 className="quickguide-heading m-0 text-nowrap">Quick Guides &nbsp;</h1>
                </div>
                <div
                  className=" d-flex align-items-center colPadding"
                  onClick={this.toggleSearchPaperModal}
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}>
                  <div className="quickguide-content">
                    {/* <ScreenSearchDesktopIcon
                sx={{ fontSize: "20px", color: iconColor }}
              /> */}
                    <p className="quickguide-text">How to Search Papers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12 colPadding">
            <div className="row">
              <div className="col-md-12 d-flex align-items-center mb-5 colPadding">
                <TipsAndUpdatesIcon sx={{ fontSize: "25px", color: "#ffbc04" }} />
                <h1 className="quickguide-heading m-0">Quick Guides</h1>
              </div>
              {/* studnt */}
              {this.state.user_type === "student" ? (
                <div
                  className="col-lg-6 col-md-6 col-12 d-flex align-items-center colPadding"
                  onClick={this.toggleBuyPaperModal}
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}>
                  <div className="quickguide-content">
                    {/* <PostAddIcon sx={{ fontSize: "20px", color: iconColor }} /> */}
                    <p className="quickguide-text">How to do the Subscription</p>
                  </div>
                </div>
              ) : (
                (this.state.user_type === "parent" &&
                  <div
                    className="col-lg-6 col-md-6 col-12 d-flex align-items-center colPadding"
                    onClick={this.toggleBuyPaperModal}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}>
                    <div className="quickguide-content">
                      {/* <PostAddIcon sx={{ fontSize: "20px", color: iconColor }} /> */}
                      <p className="quickguide-text">How to do the Subscription</p>
                    </div>
                  </div>
                )
              )}

              <div
                className="col-lg-6 col-md-6 col-12 d-flex align-items-center colPadding"
                onClick={this.toggleSearchPaperModal}
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}>
                <div className="quickguide-content">
                  {/* <ScreenSearchDesktopIcon
                sx={{ fontSize: "20px", color: iconColor }}
              /> */}

                  <p className="quickguide-text">How to Search Papers</p>
                </div>
              </div>
            </div>
          </div>
        )}


        {/* Quick Guide - How to buy a paper modal */}
        <Modal
          size="lg"
          centered
          onHide={() => this.setState({ isBuyPaperModalVisible: false })}
          show={isBuyPaperModalVisible}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <PostAddIcon /> How to Buy Credits?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <p className="steps-content-text">
                To start, you need to buy a credit package in order to enroll papers.
              </p>

              <p
                className="steps-text col-md-12 steps-label"
                style={stepsLabel}
              >
                Step 01: Go to the ‘Subscription’ Menu
              </p>
              <img
                src={subscriptionModal.subImg1}
                alt="step-1"
                className="pb-5"
              />

              <p
                className="steps-text col-md-12 steps-label"
                style={stepsLabel}
              >
                Step 02: Select the ‘Grade’ & the ‘Package’ you prefer.
              </p>

              <p
                className="steps-text col-md-12 steps-label"
                style={stepsLabel}
              >
                Step 03: Click the ‘Pay’ Button, after checking the package details
              </p>
              <p className="steps-content-text">
                You will be directed to the Payment Gateway {">"} Make the payment
              </p>
              <img
                src={subscriptionModal.subImg3}
                alt="step-2&3"
                className="pb-5"
              />

              {isEdSider && (
                <>
                  <p
                    className="steps-text col-md-12 steps-label"
                    style={stepsLabel}
                  >
                    Step 04: You will receive a payment confirmation mail
                  </p>
                  <img
                    src={subscriptionModal.subImg4}
                    alt="step-4"
                    className="pb-5"
                    style={{ width: "350px", height: "auto" }}
                  />
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>

        {/* Quick Guide - How to search paper modal */}
        <Modal
          size="lg"
          centered
          onHide={() => this.setState({ isSearchPaperModalVisible: false })}
          show={isSearchPaperModalVisible}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <ScreenSearchDesktopIcon /> How to Search Papers?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {/* Step 01 */}
              <p
                className="steps-text col-md-12 steps-label"
                style={stepsLabel}
              >
                Step 01
              </p>
              <p className="steps-content-text">
                <b>Papers</b> menu item {">"} <b>Paper list</b> tab {">"} Select
                a paper and click the{" "}
                <span className="steps-btn mx-1" style={stepsBtn}>
                  Enroll
                </span>{" "}
                Button
              </p>
              <img
                src={searchPaperModal.step2img1}
                alt="step-1"
                className="pb-5"
              />

              {/* Step 02 */}
              {isCMC ? (
                <>
                  <p
                    className="steps-text col-md-12 steps-label"
                    style={stepsLabel}
                  >
                    Step 02
                  </p>
                  <p className="steps-content-text">
                    Select an <b>Available Credit Package</b> & Click the{" "}
                    <span className="steps-btn mx-1" style={stepsBtn}>
                      Enroll
                    </span>{" "}
                    button & Confirm
                  </p>
                  <img
                    src={searchPaperModal.step2img2}
                    alt="step-2&3"
                    className="pb-5"
                    style={{ width: "550px", height: "auto" }}
                  />
                </>
              ) : (
                <>
                  <p
                    className="steps-text col-md-12 steps-label"
                    style={stepsLabel}
                  >
                    Step 02
                  </p>
                  <p className="steps-content-text col-md-12">
                    Select an <b>Available Credit Package</b> & Click the{" "}
                    <span className="steps-btn mx-1" style={stepsBtn}>
                      Enroll
                    </span>{" "}
                    button & Confirm
                  </p>
                  <img
                    src={searchPaperModal.step2img2}
                    alt="step-3"
                    className="pb-5"
                    style={{ width: "350px", height: "auto" }}
                  />
                  <img
                    src={searchPaperModal.step2img3}
                    alt="step-3"
                    className="pb-5"
                    style={{ width: "350px", height: "auto" }}
                  />
                </>
              )}

              {/* Step 03 */}
              {isCMC && (
                <>
                  <p
                    className="steps-text col-md-12 steps-label"
                    style={stepsLabel}
                  >
                    Step 03
                  </p>
                  <p className="steps-content-text col-md-12">
                    <b>Enrolled Papers</b> tab {">"} Track the Status of the
                    Enrolled Papers & Take the Exam
                  </p>
                  <img
                    src={searchPaperModal.step2img4}
                    alt="step-2&3"
                    className="pb-5"
                  />
                </>
              )}

              {/* Step 04 */}
              <p
                className="steps-text col-md-12 steps-label"
                style={stepsLabel}
              >
                Step 03: (If a parent enrolls a student in a paper) The student will receive a confirmation email
                regarding the enrollment.
              </p>
              <img
                src={searchPaperModal.searchImg4}
                alt="step-1"
                className="pb-5"
              />

            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default QuickGuides
