import {alertTypes} from '../../reducers/types';

export const alertActions = {
    success,
    error,
    clear
};

function success(message='success') {
    return {type: alertTypes.SUCCESS, message};
}

function error(message='error') {
    return {type: alertTypes.ERROR, message};
}

function clear() {
    return {type: alertTypes.CLEAR};
}