import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const PromoService = {
  getAll,
  add,
  changePromoStatus,
  validatePromoCode
};

async function add(newPromo) {
    const configOptions = {
      method: "POST",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPromo),
    };
  
    const response = await fetch(
      `${config.oat_api_url}/manage/promo_code/add`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }
      
async function getAll(limit,offset,search_term,sortColumn,sortDirection) {
    const configOptions = {
      method: "GET",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
  
    const response = await fetch(
      `${config.oat_api_url}/manage/promo_code/getAll/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
      configOptions
    );
    return APIHandler.handleResponse(response);
};

async function changePromoStatus(id,status) {
  const requestOptions = {
    method: "PUT",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(status),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/promo_code/change_status/${id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function validatePromoCode(promo_code,user_id,user_table_id,current_amount){

    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(current_amount)
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/promo_code/validate_promo_code/${promo_code}/${user_id}/${user_table_id}`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}