import React, {useState, useRef, Fragment} from "react";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, AtomicBlockUtils, convertToRaw, Modifier} from "draft-js";
import { namespace } from "../../../helper/namespace.js";
import config from '../../../helper/config.js';
import { customerConstants } from "../../../helper/constants.js";
import CanvasSketch from "./CanvasSketch.js";
import Fraction from './Fraction.js';
import './DragDrop.css';
import DragDrop from "./DragDrop.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    // cancelClass = "cmc-btn-default"
    // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

export default function CustomEditor ({ editorState, value, onEditorStateChange}) {
  
    //Creating a custom audio component
    const CustomAudioOption = ({ addAudio }) => {
      return (
        <div
          onClick={addAudio}
          style={{ cursor: "pointer", marginRight: "10px" }}
          className="rdw-option-wrapper"
          title="Audio"
        >
          <i className="fa fa-microphone" />
        </div>
      )
    }

    // Custom function to get audio file
    const getAudioFile = () => {
      return new Promise((resolve, reject) => {
        // Create an input element
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'audio/*';
        input.onchange = (event) => {
          const file = event.target.files[0];
          if (file) {
            resolve(file);
          } else {
            reject(new Error('No audio file selected'));
          }
        };
        // Trigger a click on the input element
        input.click();
      });
    };

    // Handle add audio
    const addAudio = async() => {
      try {
        // Your logic to upload and get base64Data for the audio file
        const audioFile = await getAudioFile();
        const base64Data = await convertFileToBase64(audioFile);

        // Create an entity with audio data
        const contentStateWithEntity = editorState.getCurrentContent().createEntity(
          'AUDIO',
          'IMMUTABLE',
          { src: base64Data }
        );

        // Get the last created entity key
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Insert the audio block into the editor
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
          editorState,
          entityKey,
          ' '
        );

        // Update the editor state
        onEditorStateChange(newEditorState);
      } catch (error) {
        console.error('Error adding audio:', error);
      }
    };

    // Custom component for adding video
    const CustomVideoOption = ({ addVideo }) => {
      return (
        <div 
          onClick={addVideo} 
          style={{ cursor: 'pointer', marginRight: '10px' }}
          title="Video"
        >
          <i className="fa fa-video" />
        </div>
      );
    };

    // Custom function to get audio file
    const getVideoFile = () => {
      return new Promise((resolve, reject) => {
        // Create an input element
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'video/*';
        input.onchange = (event) => {
          const file = event.target.files[0];
          if (file) {
            resolve(file);
          } else {
            reject(new Error('No video file selected'));
          }
        };
        // Trigger a click on the input element
        input.click();
      });
    };

    // Handle add audio
    const addVideo = async() => {
      try {
        // Your logic to upload and get base64Data for the audio file
        const videoFile = await getVideoFile();
        const base64Data = await convertFileToBase64(videoFile);

        // Create an entity with audio data
        const contentStateWithEntity = editorState.getCurrentContent().createEntity(
          'VIDEO',
          'IMMUTABLE',
          { src: base64Data }
        );

        // Get the last created entity key
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Insert the audio block into the editor
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
          editorState,
          entityKey,
          ' '
        );

        // Update the editor state
        onEditorStateChange(newEditorState);
      } catch (error) {
        console.error('Error adding video:', error);
      }
    };

    const [isMathModalOpen, setMathModalOpen] = useState(false);

    const handleToggleMath = () => {
      setMathModalOpen(!isMathModalOpen);
    };

    const MathSelectModal = ({ isOpen, onClose, onSelectMath }) => {
      const mathExpressions = [
        'x^2', 'y^2', 'a^2', 'b^2', 'x²', 'y²', 
        'a²', 'b²', '∫', '∑', '√', 'α', 'β', 'γ',
        'π', 'θ', 'μ', '∞', '≠', '≈', '≥', '≤',
        '∂', '∆', '∇', '∏', '√2', '∛', '∜', '∝',
        '∞', '∟', '∠', '∡', '∢', '∣', '∤', '∥',
        '∦', '∧', '∨', '∩', '∪', '∫', '∬', '∭',
        '∮', '∯', '∰', '∱', '∲', '∳', '∴', '∵',
        'y³', 'x³', 'a³', 'b³', '+', '-'
      ];

      const handleSelectMath = (expression) => {
        onSelectMath(expression);
        onClose();
      };

      // Define the number of columns
      const columns = 15;

      // Calculate the number of rows
      const rows = Math.ceil(mathExpressions.length / columns);

      return (
        <div style={{ display: isOpen ? 'block' : 'none' }}>
          {/* <p>Select a mathematical expression:</p> */}
          <table
            style={{
              borderCollapse: 'collapse',
              width: '100%',
              margin: '10px 0',
            }}
          >
            <tbody>
              {Array.from({ length: rows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Array.from({ length: columns }).map((_, colIndex) => {
                    const index = rowIndex * columns + colIndex;
                    const expression = mathExpressions[index];
                    return (
                      <td
                        key={expression}
                        onClick={() => handleSelectMath(expression)}
                        style={{
                          border: '1px solid #ccc',
                          padding: '8px',
                          cursor: 'pointer',
                        }}
                      >
                        {expression}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={onClose}>Cancel</button>
        </div>
      );
    };

    const CustomMathOption = ({ onToggleMath }) => {
      return (
        <div
          onClick={onToggleMath}
          style={{ cursor: "pointer" }}
          className="rdw-option-wrapper"
          title="Math"
        >
          <i className="fa fa-superscript" />
        </div>
      )
    }

    const handleSelectMath = (mathExpression) => {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();

      // Get the current block and its text
      const currentBlock = contentState.getBlockForKey(selection.getStartKey());
      const currentText = currentBlock.getText();

      // Get the start and end offsets of the selection
      const startOffset = selection.getStartOffset();
      const endOffset = selection.getEndOffset();

      // // Combine the existing text with the selected expression
      const newText = mathExpression + currentText.substring(endOffset);

      // Replace the current content with the new text
      const newContentState = Modifier.replaceText(
        contentState,
        selection.merge({
          anchorOffset: startOffset,
          focusOffset: startOffset + mathExpression.length,
        }),
        newText
      );

      // Update the editor state
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'replace-text'
      );

      onEditorStateChange(newEditorState);
    };

    //Drawing 
    const CustomDrawOption = ({ handleToggleDrawing, isDrawing }) => {
        return (
            <div
                onClick={handleToggleDrawing}
                style={{ cursor: "pointer", marginRight: "10px" }}
                className={`rdw-option-wrapper ${isDrawing ? 'active' : ''}`}
                title="Draw"
            >
              <i className="fas fa-paint-brush" />
            </div>
        );
    };

    const [isDrawing, setIsDrawing] = useState(false);
    const canvasSketchRef = useRef(null);

    // Function to toggle drawing mode
    const handleToggleDrawing = (e) => {
      e.preventDefault();
      setIsDrawing(!isDrawing);
    };

    const clearDrawing = (e) => {
      e.preventDefault();
      if (canvasSketchRef.current) {
        canvasSketchRef.current.clearCanvas();
      }
    };

    const insertDrawingBlock = (e) => {
      e.preventDefault();      
      try {
        const width = prompt("Enter the width of inserted drawing:");
        const height = prompt("Enter the height of the inserted drawing:");

        if (!width || !height) {
          throw new Error("Width and height are required.");
        }

        const dataURL = canvasSketchRef.current.getCanvasDataURL();

        if (!dataURL) {
          throw new Error("Failed to get canvas data URL.");
        }

        const contentState = editorState.getCurrentContent();

        const contentStateWithEntity = contentState.createEntity(
          "IMAGE",
          "IMMUTABLE",
          { src: dataURL, width: width, height: height }
        );

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
          editorState,
          entityKey,
          " "
        );

        onEditorStateChange(newEditorState);

        setIsDrawing(false);

      } catch (error) {
        console.error("Error handling draw complete:", error);
      }
    };

    //Function to read and convert the uploaded file into Base64 data
    function convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    }

    // Handling file upload functionality
    const handleFileUpload = (file) => {
      return new Promise(async (resolve, reject) => {
        try{
          const fileSizeLimit = 10 * 1024 * 1024; // 10MB limit (adjust the value as needed)
          if (file.size > fileSizeLimit) {
            reject(new Error('File size exceeds the limit.'));
            window.Swal.fire({
              title: namespace.FAILED,
              text: namespace.FILE_SIZE_IS_EXCEEDED,
              icon: 'error',
              customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                      modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
              }
            });
            return;
          }
          const base64Data = await convertFileToBase64(file);
          resolve({ data: { link: base64Data } });   
        }
        catch(error) {
          reject(error);
        }
      });         
    }

    //Creating a custom table component
    const CustomTableOption = ({ addTable }) => {
      return (
        <div
          onClick={addTable}
          style={{
            cursor: "pointer",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
          className="rdw-option-wrapper"
          title="Table"
        >
          <i className="fa fa-table" />
        </div>
      )
    }

    //Handle add table
    const addTable = () => {;
        const numRows = prompt("Enter the number of rows:");
        const numCols = prompt("Enter the number of columns:");

        const tableData = [];
        for (let i = 0; i < numRows; i++) {
          const row = [];
          for (let j = 0; j < numCols; j++) {
            row.push("");
          }
          tableData.push(row);
        }

        const contentStateWithEntity = editorState.getCurrentContent().createEntity('TABLE', 'IMMUTABLE', {
          tableData,
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
        onEditorStateChange(newEditorState);
        const entityMap = {
                [3]: {
                  type: 'table',
                  mutability: 'IMMUTABLE',
                  tableData
                }
              };
        const updatedContentState = newEditorState.getCurrentContent();
        const rawContentState = convertToRaw(updatedContentState);
        rawContentState.entityMap = entityMap;
    };

    
    //Creating the custom eraser component
    const eraser = () => {
      return (
        <div
          onClick={(e) => {
            e.preventDefault()
            onEditorStateChange(EditorState.createEmpty())
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
          className="rdw-option-wrapper"
          title="Eraser"
        >
          <i className="fa fa-eraser" />
        </div>
      )
    }

    //Drag and Drop
    const CustomDragDropOption = ({ addDragDrop }) => {
        return (
            <div 
              onClick={addDragDrop} 
              style={{ cursor: 'pointer', marginRight: '10px' }}
              title="Drag and Drop"
            >
              <i className="fa fa-arrows-alt" />
            </div>
        );
    };

    // Handle add drag and drop
    const addDragDrop = () => {
      const initialWords = ["quick", "brown", "fox", "lazy", "dog"];
      const initialSentence = "The _____ _____ jumps over the _____ _____.";
      const contentStateWithEntity = editorState.getCurrentContent().createEntity(
        'DRAG_DROP',
        'IMMUTABLE',
        { initialWords, initialSentence }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' '
      );
      onEditorStateChange(newEditorState);
    };

    //Fraction
    const CustomFractionOption = ({ addFraction }) => {
      return (
        <div
          onClick={addFraction}
          style={{ cursor: "pointer", marginRight: "10px" }}
          className="rdw-option-wrapper"
          title="Fraction"
        >
          <span>&frac12;</span> 
        </div>
      );
    };

    const addFraction = () => {
      const numerator = prompt("Enter the numerator:");
      const denominator = prompt("Enter the denominator:");
      const contentStateWithEntity = editorState.getCurrentContent().createEntity(
          'FRACTION',
          'IMMUTABLE',
          { numerator: numerator, denominator: denominator }
        );

      // Get the last created entity key
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      // Insert the fraction block into the editor
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        ' '
      );

      onEditorStateChange(newEditorState);

    };

    //Fill in the blanks
    const addFillBlank = () => {
      const blankTemplate = Array(1).fill("__________").join(" ");
      const newContentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        blankTemplate
      );
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-characters"
      );
      onEditorStateChange(newEditorState);
    };

    const handleBlankTextChange = (text, blockKey) => {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const start = text.indexOf("__________");
      const end = start + "__________".length;
      const newContentState = Modifier.replaceText(
        contentState,
        selectionState.merge({
          anchorOffset: start,
          focusOffset: end
        }),
        text,
        null,
        blockKey
      );
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-characters"
      );
      onEditorStateChange(newEditorState);
    };

    const BlankBlock = ({ block, contentState, onBlankTextChange }) => {
      const [blankText, setBlankText] = useState("");

      const handleInputChange = (e) => {
        const newText = e.target.value;
        setBlankText(newText);
        onBlankTextChange(newText, block.getKey());
      };

      return (
        <span>
          {block.getText().split("__________").map((part, index) => (
            <Fragment key={index}>
              {part}
              {index < block.getLength() - 1 && (
                <input
                  type="text"
                  value={blankText}
                  onChange={handleInputChange}
                  style={{
                    width: "50px",
                    outline: "none",
                    border: "1px solid black"
                  }}
                />
              )}
            </Fragment>
          ))}
        </span>
      );
    };
  
    const CustomFillBlankButton = ({ onClick }) => (
      <div
        onClick={addFillBlank}
        style={{ cursor: "pointer", marginRight: "10px" }}
        className="rdw-option-wrapper"
        title="Fill in the blank"
      >
        <i className="fas fa-circle" style={{ fontSize: '5px' }} />
        <i className="fas fa-minus" />
        <i className="fas fa-circle" style={{ fontSize: '5px' }} />{/* Icon for the placeholder button */}
      </div>
    );
    
    //Creating custom block component to render image
    const ImageBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src, width, height } = entity.getData();
      return <img src={src} alt="Image" width={width} height={height} />;
    }
    
    //Creating custom block component to render audio
    const AudioBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src } = entity.getData();
      return <audio src={src} controls />;
    };

    //Creating custom block component to render video
    const VideoBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src } = entity.getData();
      return <video src={src} controls />;
    };

    const DragDropBlock = (props) => {
      const { initialWords, initialSentence } = props;
      return <DragDrop initialWords={initialWords} initialSentence={initialSentence} isEditable={false}/>;
    };

    const MathBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { expression } = entity.getData();
      return (
        <span>
          {expression}
        </span>
      );
    };

    const FractionBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { numerator, denominator } = entity.getData();
      return <Fraction numerator={numerator} denominator={denominator} />;
    };

    // Rendering table block
    function TableBlock(props) {
      const { block, contentState} = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { tableData } = entity.getData();
      const entityKey = block.getEntityAt(0);

      const handleEditCell = (e, rowIndex, cellIndex) => {
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex][cellIndex] = e.target.value;
        const newContentState = contentState.mergeEntityData(entityKey, { tableData: updatedTableData });
        onEditorStateChange(EditorState.push(editorState, newContentState));
      };

      return (
        <table>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cellData, cellIndex) => (
                  <td 
                  key={cellIndex} 
                  style={{ border: "1px solid black", padding: "8px" }}
                  >
                  <input
                  type="text"
                  value={cellData}
                  onChange={(e) => handleEditCell(e, rowIndex, cellIndex)}
                  style={{ outline: "none", border: "none" }}
                  />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    //Function to render the respective blocks
    const BlockRenderer = (block) => {
    if (block.getType() === "atomic") {
      const contentState = editorState.getCurrentContent();
      const entity = contentState.getEntity(block.getEntityAt(0));
      const entityType = entity.getType();

      if (entityType === "AUDIO") {
        const { src } = entity.getData();
        return {
          component: AudioBlock,
          editable: false,
          props: {
            block,
            contentState,
            src
          },
        };
      }

      if (entityType === "VIDEO") {
        const { src } = entity.getData();
        return {
          component: VideoBlock,
          editable: false,
          props: {
            block,
            contentState,
            src
          },
        };
      }

      if (entityType === "DRAG_DROP") {
        const { initialWords, initialSentence } = entity.getData();
        return {
          component: DragDropBlock,
          editable: false,
          props: {
            initialWords,
            initialSentence
          },
        };
      }

      if (entityType === "HTML") {
          const { html } = entity.getData();
          return {
              component: () => <div dangerouslySetInnerHTML={{ __html: html }} />,
              editable: false,
          };
      }

      if (entityType === "IMAGE") {
        const { src, width, height } = entity.getData();
        return {
          component: ImageBlock,
          editable: false,
          props: {
            src,
            width,
            height
          },
        };
      }

      if (entityType === "TABLE") {
        const { tableData } = entity.getData();
        return {
          component: TableBlock,
          editable: false,
          props: {
            block,
            contentState,
            tableData
          },
        };
      }

      if (entity.getType() === 'MATH') {
        return {
          component: MathBlock,
          editable: false,
        };
      }

      if (entityType === 'FRACTION') {
        const { numerator, denominator } = entity.getData();
        return {
          component: FractionBlock,
          editable: false,
          props: {
            numerator,
            denominator
          },
        };
      }

      const text = block.getText();
      if (text.includes("__________")) {
        return {
          component: BlankBlock,
          editable: true,
          props: {
            text,
            onTextChanged: handleBlankTextChange
          }
        };
      }

    }
    return null;
  }

  return (
    <div>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        value={{ key: value, state: editorState }}
        onEditorStateChange={onEditorStateChange}
        blockRendererFn={BlockRenderer}
        toolbarHidden={true}
        readOnly={true}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
            "remove",
            "history",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Times New Roman",
              "Verdana",
              "Kushpoo",
              "Baamini",
              "FMAbhaya"
            ],
          },
          image: {
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: handleFileUpload,
            previewImage: true,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            //alt: { present: true, mandatory: true },
            defaultSize: {
              height: "",
              width: "",
            },
          },
          remove: { component: eraser },
        }}
        toolbarCustomButtons={[
          //<CustomTableOption key="table" addTable={addTable} />,
          <CustomAudioOption key="audio" addAudio={addAudio} />,
          //<CustomVideoOption key="video" addVideo={addVideo} />,
          <CustomMathOption key="math" onToggleMath={handleToggleMath} />,
          <CustomDrawOption key="draw" handleToggleDrawing={handleToggleDrawing} isDrawing={isDrawing} />,
          // <CustomFillBlankButton key="fill" onClick={addFillBlank} />,
          // <CustomFractionOption key="fraction" addFraction={addFraction}/>,
          // <CustomDragDropOption key="dragdrop" addDragDrop={addDragDrop} />
        ]}
      />
      <MathSelectModal
        isOpen={isMathModalOpen}
        onClose={() => setMathModalOpen(false)}
        onSelectMath={handleSelectMath}
      />
      {isDrawing && (
      <div>
        <CanvasSketch
          ref={canvasSketchRef}
          width="500px"
          height="300px"
          lineColor="black"
          lineWidth={2}
        />
        <button onClick={insertDrawingBlock} style={{ flex: 1, marginRight: "2px" }}>Insert Drawing</button>
        <button onClick={clearDrawing}>Clear</button>
      </div>
      )}
    </div>
  )
}
