import React from "react"
import "../dashboard.css"
import config from "../../../../../helper/config.js"
import { customerConstants } from "../../../../../helper/constants.js"
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
} from "@mui/material"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}

const StudentExams = (props) => {
  return (
    <div className="row" style={{ overflow: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="tHead">Due Date & Time</TableCell>
            <TableCell className="tHead">Paper Title</TableCell>
            <TableCell className="tHead">Paper Duration</TableCell>
            <TableCell className="tHead">Course</TableCell>
            <TableCell className="tHead">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="tData">
              2023-05-04 <br /> 11:00AM
            </TableCell>
            <TableCell className="tData">Test MCQ</TableCell>
            <TableCell className="tData">30 mins</TableCell>
            <TableCell className="tData">
              Institute › Faculties › Faculty of Management ›
            </TableCell>
            <TableCell className="tData">
              <span className="stu-variant-class label label-lg label-light-warning label-inline">
                Not Started
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tData">
              2024-05-12 <br /> 08:00AM
            </TableCell>
            <TableCell className="tData">Maths Assessment</TableCell>
            <TableCell className="tData">60 mins</TableCell>
            <TableCell className="tData">
              Institute › Faculties › English Language Teaching Unit ›
            </TableCell>
            <TableCell className="tData">
              <span className="stu-variant-class label label-lg label-light label-inline">
                Upcoming
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tData">
              2024-05-12 <br /> 08:00AM
            </TableCell>
            <TableCell className="tData">Maths Assessment</TableCell>
            <TableCell className="tData">60 mins</TableCell>
            <TableCell className="tData">
              Institute › Faculties › English Language Teaching Unit ›
            </TableCell>
            <TableCell className="tData">
              <span className="stu-variant-class label label-lg label-light-success label-inline">
                Ready
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tData">
              2024-05-12 <br /> 08:00AM
            </TableCell>
            <TableCell className="tData">Maths Assessment</TableCell>
            <TableCell className="tData">60 mins</TableCell>
            <TableCell className="tData">
              Institute › Faculties › English Language Teaching Unit ›
            </TableCell>
            <TableCell className="tData">
              <span className="stu-variant-class label label-lg label-success label-inline">
                Completed
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default StudentExams
