import React from "react"
import PropTypes from "prop-types"

const ProgressCircle = ({ val, index }) => {
  const strokeWidth = 12
  const radius = 70
  const circumference = 2 * Math.PI * radius
  const progress = (circumference * (100 - val.chart_y_value)) / 100

  return (
    <div style={{ textAlign: "center" }}>
      <h3 style={{ fontSize: "12px", fontWeight: "600" }}>{val.chart_title}</h3>
      <h4 style={{ fontSize: "10px", fontWeight: "400" }}>{val.breadcrumb}</h4>
      <svg width="200" height="200" style={{ marginTop: "-40px" }}>
        <circle
          cx="100"
          cy="100"
          r={radius}
          fill="transparent"
          stroke="#e0e0e0"
          strokeWidth={strokeWidth}
        />
        <circle
          cx="100"
          cy="100"
          r={radius}
          fill="transparent"
          stroke="#36A2EB"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progress}
        />
        <text x="50%" y="50%" fontSize="24" textAnchor="middle">
          {val.chart_y_value}%
        </text>
      </svg>
    </div>
  )
}

export default ProgressCircle
