import {quesAuthorTypes} from '../../../reducers/settings/settingsTypes'
import {questionAuthorService} from '../../../service/settings/question/authorService'


export const quesAuthorActions = {
    getAllQuestionAuthors,
    createAuthor,
    updateAuthor,
    deleteAuthor
}

function getAllQuestionAuthors(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let authors = await questionAuthorService.getAllQuestionAuthors(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(authors.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: quesAuthorTypes.GET_ALL_QUESTION_AUTHORS}
    }

    function request() {
        return {type: quesAuthorTypes.GET_ALL_QUESTION_AUTHORS_REQUEST}
    }

    function success(authors) {
        return {type: quesAuthorTypes.GET_ALL_QUESTION_AUTHORS_SUCCESS, authors}
    }

    function failure(error) {
        return {type: quesAuthorTypes.GET_ALL_QUESTION_AUTHORS_FAILURE, error}
    }
}


function createAuthor(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await questionAuthorService.createAuthor(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: quesAuthorTypes.CREATE_AUTHOR_REQUEST, data}
    }

    function success(authors) {
        return {type: quesAuthorTypes.CREATE_AUTHOR_SUCCESS, authors}
    }

    function failure(error) {
        return {type: quesAuthorTypes.CREATE_AUTHOR_FAILURE, error}
    }
}


function deleteAuthor(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await questionAuthorService.deleteAuthor(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(author) {
        return {type: quesAuthorTypes.DELETE_AUTHOR_REQUEST, author}
    }

    function success(success) {
        return {type: quesAuthorTypes.DELETE_AUTHOR_SUCCESS, success}
    }

    function failure(error) {
        return {type: quesAuthorTypes.DELETE_AUTHOR_FAILURE, error}
    }
}

function updateAuthor(obj) {
    return async dispatch => {

        try {
            dispatch(request());
            let res = await questionAuthorService.updateAuthor(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: quesAuthorTypes.UPDATE_AUTHOR_REQUEST}
    }

    function success(author) {
        return {type: quesAuthorTypes.UPDATE_AUTHOR_SUCCESS, author}
    }

    function failure(error) {
        return {type: quesAuthorTypes.UPDATE_AUTHOR_FAILURE, error}
    }
}
