import React, { Component } from "react";
import { connect } from "react-redux";
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import CardBody from '../../../components/common/CardBody';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { quesCatActions } from "../../../actions/settings/question/quesCatAction.js"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config.js";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class QuestionCategory extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newCategory:null,
        categoryRowsPerPage:5,
        currentCategoryPage: 0,
        categoryData: [],
        category_sort_column: "category_id",
        category_sort_direction: "ASC",
        selectCategory: '',
        editCategory: false,
        category: '',
        limit: 10,
        category_search_term: "",
        category_offset: 0,
        searchError: false,
        category_limit:5,
        categoryId: '',
        categoryOptionsImport: [],
        selectedCategory: []
      };
      
      this.onNewCategoryInput = this.onNewCategoryInput.bind(this);
      this.onAddNewCategory = this.onAddNewCategory.bind(this);
      this.generateCategoryOptions = this.generateCategoryOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllQuestionCategories();
    }
    
    onNewCategoryInput(e) {
        this.setState({
            newCategory: e.target.value
        });
    }
    
    async onAddNewCategory(e) {

        if (this.state.newCategory && this.state.newCategory.trim()) {
            await this.props.createCategory({ 'name': this.state.newCategory })
            if (this.props.successcategorycreate) {
                this.setState(
                    {
                        category_offset: 0,
                        currentCategoryPage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllQuestionCategories();
                        this.generateCategoryOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new category!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-category').modal('hide')
                                window.$("#question-category").val('default');
                                window.$('#question-category').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errorcategorycreate) {
                window.Swal.fire({
                    title: 'Failed to add a new category!',
                    text: this.props.errorcategorycreate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Please enter a valid category name!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newCategory: ''
        });
    }

    handleCategorySearch = (event) => {
        const category_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
    
        if (category_search_term === "" || regex.test(category_search_term)) {
            this.setState(
                {
                    category_search_term,
                    category_offset: 0,
                    currentCategoryPage: 0,
                    searchError: false,
                },
                () => {
                    this.fetchAllQuestionCategories();
                }
            );
        } else {
            this.setState({ category_search_term, searchError: true });
        }
    };
    
    fetchAllQuestionCategories = async () => {
        const {
            category_limit,
            category_offset,
            category_sort_column,
            category_sort_direction,
            category_search_term
        } = this.state;
        await this.props.getAllQuestionCategories(category_limit, category_offset, category_sort_column, category_sort_direction, category_search_term);
        this.setState({ category_limit: this.state.categoryRowsPerPage });
        this.setState({ categoryData: this.props.allQuestionCategories })
    };
    
    handleCategoryPageChange = (event, newCategoryPage) => {
        const {categoryRowsPerPage} = this.state;
        const newCategoryOffset = newCategoryPage * categoryRowsPerPage;
        this.setState(
        {
            category_limit: categoryRowsPerPage,
            category_offset: newCategoryOffset,
            currentCategoryPage: newCategoryPage
        },
        () => {
            this.fetchAllQuestionCategories();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {categoryRowsPerPage} = this.state;
        this.setState(
        {
            category_limit: categoryRowsPerPage,
            category_offset: newOffset,
            currentCategoryPage: newPage
        },
        () => {
            this.fetchAllQuestionCategories();
        }
        );
    };

    //handle the data fetching as per the data per page limit gets changes
    handleCategoryRowsPerPageChange = (event) => {
        const newCategoryLimit = parseInt(event.target.value, 10);
        this.setState({categoryRowsPerPage: newCategoryLimit});
        const newCategoryPage = Math.floor(this.state.category_offset / newCategoryLimit)
        this.setState(
        {
            category_limit: newCategoryLimit,
            category_offset: newCategoryLimit * newCategoryPage,
            currentCategoryPage: newCategoryPage
        },
        () => {
            this.fetchAllQuestionCategories();
        }
        );
    };

    handleSortCategoryChange = (category_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { category_sort_column: currentCategorySortColumn, category_sort_direction } = this.state;
        const newCategorySortDirection =
        currentCategorySortColumn === category_sort_column && category_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            category_sort_column,
            category_sort_direction: newCategorySortDirection,
            category_offset: 0,
            currentCategoryPage: 0,
        },
        () => {
            this.fetchAllQuestionCategories();
        }
        );
    }

    getQuestionCategory(category_id) {
        let categoryname
        this.props.allQuestionCategories.map(category => {
            if (category.category_id === category_id) {
                return categoryname = category.name
            }
        })
        return categoryname
    }
    
    onEditCategory(e, el) {
        this.setState({
            categoryId: el.category_id,
            category: e.target.value
        })
    }
    
    async onUpdateCategory(e) {
        e.preventDefault()

        var obj = {
            category_id: this.state.categoryId,
            name: this.state.category
        }
        this.setState({ selectCategory: '' })
        if (!this.state.category.trim()) {
            window.Swal.fire({
                title: 'Failed to update category!',
                text: 'Please enter a valid category name!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateCategory(obj);
            if (this.props.successcategoryupdate) {
                // await this.props.getAllQuestionCategories(category_limit, category_offset, category_sort_column, category_sort_direction, category_search_term);
                this.fetchAllQuestionCategories()
                this.generateCategoryOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated category!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });
            }
            if (this.props.errorcategoryupdate) {
                window.Swal.fire({
                    title: 'Failed to update category!',
                    text: this.props.errorcategoryupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        }
    }

    generateCategoryOptions() {
        let categoryOptionsImport = []
        this.props.allQuestionCategories.map((el, i) => {
            categoryOptionsImport.push(<Option key={el.category_id} optionName={el.name} value={el.category_id} />)
        });

        this.setState({
            categoryOptionsImport: categoryOptionsImport,
            selectedCategory: categoryOptionsImport[0].props.value
        });

        if (categoryOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditCategory(el, i, e) {
        e.preventDefault()
        this.setState({
            editCategory: true,
            selectCategory: i,
            categoryId: el.category_id,
            category: el.name
        })
    }

    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the category?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteCategory(el);
                if (this.props.successcategorydelete) {
                    // await this.props.getAllQuestionCategories(category_limit, category_offset, category_sort_column, category_sort_direction, category_search_term);
                    this.fetchAllQuestionCategories()
                    this.generateCategoryOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted category!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });
                }
                if (this.props.errorcategorydelete) {
                    window.Swal.fire({
                        title: 'Failed to delete category!',
                        text: this.props.errorcategorydelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });

                }
            }
        })
    }
    
    syncTableCategory() {
        return (
            <>
                {this.state.categoryData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortCategoryChange("category_id")}
                                >
                                    No{" "}
                                    {this.state.category_sort_column === "category_id" && (
                                    <span>{this.state.category_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortCategoryChange("name")}
                                >
                                    Category Name{" "}
                                    {this.state.category_sort_column === "name" && (
                                    <span>{this.state.category_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.categoryData.map((el, i) => {
                                if (i === this.state.selectCategory) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editCategory ? 
                                                this.getQuestionCategory(el.category_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.category}
                                                    onChange={(e) => this.onEditCategory(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateCategory(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Category" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getQuestionCategory(el.category_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditCategory(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Category" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete" 
                                                    title="Delete Category" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Categories Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3 className='text-nowrap'>Manage Question Categories</h3>
              </div>

              <CardBody>
                <div>
                  <div className="row">
                    <div className="col-md-9">
                      <QuestionAccordion
                        labelName="Create New Category"
                        onChange={this.onNewCategoryInput}
                        onClick={this.onAddNewCategory}
                        val={this.state.newCategory}
                      />
                    </div>
                    <div className="col-md-3">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleCategorySearch}
                          className="col-md-12 col-12"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <TabContent>
                    <TabPane
                      className="tab-pane fade show active"
                      id="manage-1"
                      ariaLabelledby="manage-tab-1"
                    >
                      {/* begin: Datatable */}
                      {this.props.cat_loading ? (
                        <div style={{ width: "75vw", overflowX: "scroll" }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="thead">No</TableCell>
                                <TableCell className="thead">
                                  Category Name
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                          <div className="loading-container mb-5 mt-10">
                            <ReactSpinner
                              animation="border"
                              role="status"
                              style={{ display: "block", margin: "auto" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </ReactSpinner>
                          </div>
                        </div>
                      ) : this.state.categoryData.length > 0 ? (
                        this.syncTableCategory()
                      ) : (
                        <div>
                          <Table sx={{ width: "75vw" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="thead">No</TableCell>
                                <TableCell className="thead">
                                  Category Name
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            {/* end: Datatable */}
                          </Table>
                          <div className="text-center mb-5 mt-10">
                            <label>No Categories Available!</label>
                          </div>
                        </div>
                      )}
                    </TabPane>
                  </TabContent>
                  <div className="d-flex justify-content-end">
                    <MaterialCustomTablePagination
                        totalCount = {this.props.totalCategoriesCount}
                        currentPage = {this.state.currentCategoryPage}
                        limit = {this.state.categoryRowsPerPage}
                        handlePageChange = {this.handleCategoryPageChange}
                        handleRowsPerPageChange = {this.handleCategoryRowsPerPageChange}
                        handlePageOffsetChange = {this.handlePageOffsetChange}
                        offset = {this.state.category_offset}
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    />
                  </div>
                </div>
                {/* </div> */}
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalCategoriesCount: state.quesCatReducer.totalCategoriesCount,//
        cat_loading:state.quesCatReducer.loading,
        error: state.quesCatReducer.error,//
        // totalCount: state.questionsLibraryReducer.totalCount,//
        
        errorcategorydelete: state.quesCatReducer.errorcategorydelete,
        successcategorydelete: state.quesCatReducer.successcategorydelete,

        errorcategoryupdate: state.quesCatReducer.errorcategoryupdate,
        successcategoryupdate: state.quesCatReducer.successcategoryupdate,//
        
        errorcategorycreate: state.quesCatReducer.errorcategorycreate,
        successcategorycreate: state.quesCatReducer.successcategorycreate,//

        allQuestionCategories: state.quesCatReducer.allQuestionCategories,//
        loading: state.quesCatReducer.loading,//
    }
}

    const mapActionsToProps = {
        createCategory: quesCatActions.createCategory,
        getAllQuestionCategories: quesCatActions.getAllQuestionCategories,
        updateCategory: quesCatActions.updateCategory,
        deleteCategory: quesCatActions.deleteCategory,
    }

    export default connect(mapStateToProps, mapActionsToProps)(QuestionCategory);

