import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const candidateService = {
    getAllCandidateSchedules,
    getAllCandidateAttempts,
    getAllStudentsForExam,
    getAllExamsForStudent,
    getAllAttemptsForStudentExam,
    updateCandidateSchedule,
    updateBulkCandidateSchedule,
    updateCandidateAttempt,
    updateBulkCandidateAttempt,
    getAllGradeScales,
    getAllCandidateGradeScales,
    createCandidateGradeScale,
    updateCandidateGradeScale,
    deleteCandidateGradeScale,
    updateAllPapers
};

async function getAllCandidateSchedules(
    limit, 
    offset, 
    sort_column, 
    sort_direction, 
    search_term, 
    selectedAssessment, 
    selectedStudent, 
    selectedAttempt,
    selectedCategories,
    selectedAuthors,
    selectedChapters,
    selectedGrades,
    selectedDifficulties,
    selectedLanguages,
    selectedTerms,
    selectedYears,
    selectedsubjects,
    selectedCandidateStatuses
) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/candidates/getAllSchedules/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedAssessment=${selectedAssessment}&selectedStudent=${selectedStudent}&selectedAttempt=${selectedAttempt}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedCandidateStatuses=${selectedCandidateStatuses}`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllCandidateAttempts(
    limit, 
    offset, 
    sort_column, 
    sort_direction, 
    search_term, 
    selectedAssessment, 
    selectedStudent,
    selectedCategories,
    selectedAuthors,
    selectedChapters,
    selectedGrades,
    selectedDifficulties,
    selectedLanguages,
    selectedTerms,
    selectedYears,
    selectedsubjects,
    selectedCandidateStatuses
) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };
    return await fetch(`${config.oat_api_url}/candidates/getAllAttempts/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedAssessment=${selectedAssessment}&selectedStudent=${selectedStudent}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedCandidateStatuses=${selectedCandidateStatuses}`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllStudentsForExam(assessment_id, selectedCandidateStatuses) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };
    return await fetch(`${config.oat_api_url}/candidates/getAllStudentsForExam/${assessment_id}?selectedCandidateStatuses=${selectedCandidateStatuses}`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllExamsForStudent(student_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };
    return await fetch(`${config.oat_api_url}/candidates/getAllExamsForStudent/${student_id}`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllAttemptsForStudentExam(student_id, assessment_id, selectedCandidateStatuses) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };
    return await fetch(`${config.oat_api_url}/candidates/getAllAttemptsForExam/${student_id}/${assessment_id}?selectedCandidateStatuses=${selectedCandidateStatuses}`, requestOptions).then(APIHandler.handleResponse);
}

async function updateCandidateSchedule(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };
    return await fetch(`${config.oat_api_url}/candidates/updateByCandidateId/${obj.candidate_id}`, requestOptions).then(APIHandler.handleResponse);
}

async function updateBulkCandidateSchedule(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };
    return await fetch(`${config.oat_api_url}/candidates/updateBulkSchedule/`, requestOptions).then(APIHandler.handleResponse);
}

async function updateCandidateAttempt(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };
    return await fetch(`${config.oat_api_url}/candidates/updateAttemptByIds/${obj.student_id}/${obj.assessment_id}`, requestOptions).then(APIHandler.handleResponse);
}

async function updateBulkCandidateAttempt(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };
    return await fetch(`${config.oat_api_url}/candidates/updateBulkAttempt/`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllGradeScales() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/candidate/grade/scales/getAll/`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllCandidateGradeScales(limit, offset, sort_column, sort_direction, search_term) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/candidate/grade/scales/getAllCandidateGradeScales/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}`, requestOptions).then(APIHandler.handleResponse);
}

async function createCandidateGradeScale(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/candidate/grade/scales/add/`, requestOptions).then(APIHandler.handleResponse);
}

async function updateCandidateGradeScale(obj) {
    try {
        const requestOptions = {
            method: 'PUT',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(obj)
        };

        return await fetch(`${config.oat_api_url}/candidate/grade/scales/update/${obj.scale_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function deleteCandidateGradeScale(obj) {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(obj)
        };
        return await fetch(`${config.oat_api_url}/candidate/grade/scales/delete/${obj.scale_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function updateAllPapers(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };
    return await fetch(`${config.oat_api_url}/assessments/updateAllAssessments/`, requestOptions).then(APIHandler.handleResponse);
}
