import React from 'react';
import { Link } from 'react-router-dom';

export default function SelfGradeCardToolBar({ cardLabel, cardBottomLable, children }) {
    return (

        <div className="card-toolbar">
            {children}
        </div>

    )
}