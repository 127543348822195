import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputAdornment from "@mui/material/InputAdornment";
import SimpleReactValidator from "simple-react-validator";
import { LimitService } from "../../../service/settings/manage/limit.service.js";
import {
  reportSubjectService,
  reportTitleService,
} from "../../../service/manage/mark_sheet/marksheetreport.service.js";
import { Spinner } from "react-bootstrap";
import {
  sweetErrorAlert,
  sweetStatusAlert,
  sweetSuccessAlert,
} from "../../../components/common/SweetAlert";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

class ManageReportSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: false,
      isLoading: false,
      addSubjectModal: false,
      updateSubjectModal: false,
      subject: null,
      subject_code:null,
      updateSubject: null,
      updateSubjectCode: null,
      updatedCredit:null,
      selectedSubject: null,
      subjects: [],
      credit: null
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getAllSubject();
  }

  getAllSubject = () => {
    this.setState({ isTableDataLoading: true });
    const { mid} =
      this.props.props;
    reportSubjectService.getAllReportSubjects(mid).then((response) => {
      if (response.success) {
        this.setState({
          subjects: response.data.subjects,
          isTableDataLoading: false,
        });
      } else {
        this.setState({ isTableDataLoading: false });
      }
    });
  };

  toggleupdateSubjectModal = (subject) => {
    const { updateSubjectModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessages();
    if (!updateSubjectModal) {
      this.setState({
        updateSubject: subject.subject_name,
        updateSubjectCode: subject.subject_code,
        updatedCredit:subject.credits,
        selectedSubject: subject.id,
        updateSubjectModal: true,
      });
    } else {
      this.setState({
        updateSubject:  "",
        updateSubjectCode:  "",
        updatedCredit:  "",
        selectedSubject: null,
        updateSubjectModal: false,
      });
    }
  };

  handleEditSubmit = () => {
    const { updateSubject, selectedSubject, updateSubjectCode,updatedCredit } = this.state;
    if (!updateSubject || !updateSubjectCode || updatedCredit < 0)  {
      this.validator.showMessageFor("updateSubject");
      this.validator.showMessageFor("updateSubjectCode");
      this.validator.showMessageFor("updatedCredit");
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      this.setState({ updateSubjectModal: false });
      const newupdateSubject = {
        subject_name: updateSubject,
        subject_code: updateSubjectCode,
        credits:updatedCredit,
      };
      reportSubjectService
        .updateReportSubject(newupdateSubject, selectedSubject)
        .then((response) => {
          if (response.success) {
            sweetSuccessAlert(
              "Success",
              "Subject updated successfully",
              "success"
            );
            this.setState({ isLoading: false });
            this.setState({ updateSubjectModal: false });
            this.getAllSubject();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Oops", response.message.error, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while updating Subject", "error");
        });
    }
  };

  handleDeleteSubmit = (selectedSubject) => {
    this.setState({ isLoading: true });
    reportSubjectService
      .deleteReportSubject(selectedSubject)
      .then((response) => {
        if (response.success) {
          sweetSuccessAlert(
            "Success",
            "Subject deleted successfully",
            "success"
          );
          this.setState({ isLoading: false });
          this.getAllSubject();
        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Oops", response.message.error, "info");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        sweetErrorAlert("Error", "Error while deleting Subject", "error");
      });
  };
  handleSubjectChange = (e) => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  handleSubjectCodeChange = (e) => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  handleSubjectCreditChange = (e) => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  toggleaddSubjectModal = () => {
    
    const { addSubjectModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessageFor("subject");
    this.validator.hideMessageFor("subject_code")
    this.validator.hideMessageFor("credit")
    this.setState({
      addSubjectModal: !addSubjectModal,
      subject: "",
      subject_code:"",
      credit:""
    });
  };

  handleSubmit = () => {
    const { subject,subject_code,credit } = this.state;
    const { mid} = this.props.props;

    if (!subject || !subject_code || credit < 0) {
      this.validator.showMessageFor("subject");
      this.validator.showMessageFor("subject_code");
      this.validator.showMessageFor("credit");
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      this.setState({ addSubjectModal: false });
      const newsubject = {
        node_id:mid,
        subject_name: subject,
        subject_code:subject_code,
        credits:credit
      };
      reportSubjectService
        .addReportSubject(newsubject)
        .then((response) => {
          if (response.success) {
            this.setState({ isLoading: false });
            this.toggleaddSubjectModal();
            sweetSuccessAlert(
              "Success",
              "A new Report Subject added successfully",
              "success"
            );
            this.setState({ addSubjectModal: false });
            this.getAllSubject();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Info", response.message.error, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while adding Subject", "error");
        });
    }
  };

  render() {
    const {
      addSubjectModal,
      subject,
      updateSubjectModal,
      isTableDataLoading,
      isLoading,
      subjects,
      updateSubject,
      updateSubjectCode,
      subject_code,
      updatedCredit,
      credit
    } = this.state;

    return (
      <>
        <div className="row justify-content-between align-items-center py-5 mb-3">
          <div className="col-md-6 col-6">
            <h3 className="text-nowrap">Manage Report Module</h3>
          </div>

          <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleaddSubjectModal}
          >
            Add New Module
          </Button>
        </div>
        {isLoading && <PageSpinner id="show" text="Loading, Please wait!" />}
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead">Module Name</TableCell>
                  <TableCell className="thead">Module Code</TableCell>
                  <TableCell className="thead">Module Credits</TableCell>
                  <TableCell className="thead" align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {subjects.length > 0 ? (
                  subjects.map((subject) => (
                    <TableRow key={subject.id}>
                      <TableCell className="tData">
                        {subject.subject_name}
                      </TableCell>
                      <TableCell className="tData">
                        {subject.subject_code}
                      </TableCell>
                      <TableCell className="tData">
                        {subject.credits}
                      </TableCell>
                      <TableCell className="tData" align="right">
                        <div>
                          <Button
                            onClick={() =>
                              this.toggleupdateSubjectModal(subject)
                            }
                            className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                            title="Edit Details"
                          />
                          <Button
                            className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                            title="Delete Details"
                            sx={{ color: "red" }}
                            onClick={() => this.handleDeleteSubmit(subject.id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>No Report Module found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}
        <Modal
          size="md"
          aria-labelledby="contained-modal-subject-vcenter"
          centered
          show={updateSubjectModal}
          onHide={this.toggleupdateSubjectModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Module</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : ( */}
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Module Name*</Form.Label>
                  <Form.Control
                    id="outlined-start-adornment"
                    sx={{ width: "25ch" }}
                    name="updateSubject"
                    value={updateSubject ? updateSubject : ""}
                    onChange={this.handleSubjectChange}
                  />
                  {this.validator.message(
                    "updateSubject",
                    updateSubject,
                    "required",
                    {
                      className: "text-danger",
                    }
                  )}
                  <Form.Label>Module Code*</Form.Label>
                  <Form.Control
                    id="outlined-start-adornment"
                    sx={{ width: "25ch" }}
                    name="updateSubjectCode"
                    value={updateSubjectCode ? updateSubjectCode : ""}
                    onChange={this.handleSubjectCodeChange}
                  />
                  {this.validator.message(
                    "updateSubjectCode",
                    updateSubjectCode,
                    "required",
                    {
                      className: "text-danger",
                    }
                  )}
                  <Form.Label>Module Credit*</Form.Label>
                  <Form.Control
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      name="updatedCredit"
                      type="number"
                      value={updatedCredit ? updatedCredit : ""}
                      onChange={this.handleSubjectCreditChange}
                      min={0}
                    />
                    {this.validator.message("updatedCredit", updatedCredit, "required|min:1,number", {
                      className: "text-danger",
                    })}
                </Form.Group>
              </Row>
            </Form>
            {/* )} */}
            <Modal.Footer>
              <Button
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
                sx={{ mr: "20px" }}
                onClick={this.toggleupdateSubjectModal}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={() => this.handleEditSubmit()}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          aria-labelledby="contained-modal-subject-vcenter"
          centered
          show={addSubjectModal}
          onHide={this.toggleaddSubjectModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Report Module</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Module Name*</Form.Label>
                    <Form.Control
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      name="subject"
                      type="text"
                      defaultValue={subject}
                      onChange={this.handleSubjectChange}
                    />
                    {this.validator.message("subject", subject, "required", {
                      className: "text-danger",
                    })}

               <Form.Label>Module Code*</Form.Label>
                  <Form.Control
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      name="subject_code"
                      type="text"
                      defaultValue={subject_code}
                      onChange={this.handleSubjectCodeChange}
                    />
                    {this.validator.message("subject_code", subject_code, "required", {
                      className: "text-danger",
                    })}

               <Form.Label>Module Credit*</Form.Label>
                  <Form.Control
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      name="credit"
                      type="number"
                      defaultValue={credit}
                      min={0}
                      onChange={this.handleSubjectCreditChange}
                    />
                    {this.validator.message("credit", credit, "required|min:1,num", {
                      className: "text-danger",
                    })}
                  </Form.Group>
                </Row>
              </Form>
            <Modal.Footer>
              <Button
                sx={{ mr: "20px" }}
                onClick={this.toggleaddSubjectModal}
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ManageReportSubject;
