import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const StripeService = {
    create_checkout_session
};

async function create_checkout_session(stripe_obj) {
    const requestOptions = {
        method: "POST",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: stripe_obj,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/stripe-pg/create-checkout-session`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}