import React, { Component } from "react";
import { connect } from "react-redux";
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import CardBody from '../../../components/common/CardBody';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import TabList from '../../../components/common/tabs/TabList';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { quesAuthorActions } from "../../../actions/settings/question/authorAction.js"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config.js";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class QuestionAuthor extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newAuthor:null,
        authorRowsPerPage:5,
        currentAuthorPage: 0,
        authorData: [],
        author_sort_column: "author_id",
        author_sort_direction: "ASC",
        selectAuthor: '',
        editAuthor: false,
        author: '',
        limit: 10,
        author_search_term: "",
        author_offset: 0,
        searchError: false,
        author_limit:5,
        authorId: '',
        authorOptionsImport: [],
        selectedAuthor: []
      };
      
      this.onNewAuthorInput = this.onNewAuthorInput.bind(this);
      this.onAddNewAuthor = this.onAddNewAuthor.bind(this);
      this.generateAuthorOptions = this.generateAuthorOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllQuestionAuthors();
    }
    
    onNewAuthorInput(e) {
        this.setState({
            newAuthor: e.target.value
        });
    }
    
    async onAddNewAuthor(e) {
        if (this.state.newAuthor && this.state.newAuthor.trim()) {
            await this.props.createAuthor({ 'author': this.state.newAuthor })
            if (this.props.successauthorcreate) {
                this.setState(
                    {
                        author_offset: 0,
                        currentAuthorPage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllQuestionAuthors();
                        this.generateAuthorOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new author!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-category').modal('hide')
                                window.$("#question-category").val('default');
                                window.$('#question-category').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errorauthorcreate) {
                window.Swal.fire({
                    title: 'Failed to add a new author!',
                    text: this.props.errorauthorcreate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty author!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newAuthor: ''
        });
    }

    handleAuthorSearch = (event) => {
        const author_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (author_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (author_search_term === "" || regex.test(author_search_term)) {
            this.setState(
                {
                    author_search_term,
                    author_offset: 0,
                    currentAuthorPage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllQuestionAuthors();
                }
            );
        } else {
            this.setState({ author_search_term, searchError: true });
        }
    };
    
    fetchAllQuestionAuthors = async () => {
        const {
            author_limit,
            author_offset,
            author_sort_column,
            author_sort_direction,
            author_search_term
        } = this.state;
        await this.props.getAllQuestionAuthors(author_limit, author_offset, author_sort_column, author_sort_direction, author_search_term);
        this.setState({ author_limit: this.state.authorRowsPerPage });
        this.setState({ authorData: this.props.allQuestionAuthors })
    };
    
    handleAuthorPageChange = (event, newAuthorPage) => {
        const {authorRowsPerPage} = this.state;
        const newAuthorOffset = newAuthorPage * authorRowsPerPage;
        this.setState(
        {
            author_limit: authorRowsPerPage,
            author_offset: newAuthorOffset,
            currentAuthorPage: newAuthorPage
        },
        () => {
            this.fetchAllQuestionAuthors();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {authorRowsPerPage} = this.state;
        this.setState(
        {
            author_limit: authorRowsPerPage,
            author_offset: newOffset,
            currentAuthorPage: newPage
        },
        () => {
            this.fetchAllQuestionAuthors();
        }
        );
    };

    //handle the data fetching as per the data per page limit gets changes
    handleAuthorRowsPerPageChange = (event) => {
        const newAuthorLimit = parseInt(event.target.value, 10);
        this.setState({authorRowsPerPage: newAuthorLimit});
        const newAuthorPage = Math.floor(this.state.author_offset / newAuthorLimit)
        this.setState(
        {
            author_limit: newAuthorLimit,
            author_offset: newAuthorLimit * newAuthorPage,
            currentAuthorPage: newAuthorPage
        },
        () => {
            this.fetchAllQuestionAuthors();
        }
        );
    };

    handleSortAuthorChange = (author_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { author_sort_column: currentAuthorSortColumn, author_sort_direction } = this.state;
        const newAuthorSortDirection =
        currentAuthorSortColumn === author_sort_column && author_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            author_sort_column,
            author_sort_direction: newAuthorSortDirection,
            author_offset: 0,
            currentAuthorPage: 0,
        },
        () => {
            this.fetchAllQuestionAuthors();
        }
        );
    }

    
    getQuestionAuthor(author_id) {
        let authorname
        this.props.allQuestionAuthors.map(author => {
            if (author.author_id === author_id) {
                return authorname = author.author
            }
        })
        return authorname
    }
    

    onEditAuthor(e, el) {
        this.setState({
            authorId: el.author_id,
            author: e.target.value
        })
    }
    
    async onUpdateAuthor(e) {
        e.preventDefault()
        var obj = {
            author_id: this.state.authorId,
            author: this.state.author
        }
        this.setState({ selectAuthor: '' })
        if (!this.state.author.trim()) {
            window.Swal.fire({
                title: 'Failed to update author!',
                text: 'Author cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateAuthor(obj);
            if (this.props.successauthorupdate) {
                // await this.props.getAllQuestionAuthors(author_limit, author_offset, author_sort_column, author_sort_direction, author_search_term);
                this.fetchAllQuestionAuthors()
                this.generateAuthorOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated author!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });
            }
            if (this.props.errorauthorupdate) {
                window.Swal.fire({
                    title: "Failed to update author!",
                    text: this.props.errorauthorupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        }
    }

    generateAuthorOptions() {
        let authorOptionsImport = []
        this.props.allQuestionAuthors.map((el, i) => {
            authorOptionsImport.push(<Option key={el.author_id} optionName={el.author} value={el.author_id} />)
        });

        this.setState({
            authorOptionsImport: authorOptionsImport,
            selectedAuthor: authorOptionsImport[0].props.value
        });

        if (authorOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditAuthor(el, i, e) {
        e.preventDefault()
        this.setState({
            editAuthor: true,
            selectAuthor: i,
            authorId: el.author_id,
            author: el.author
        })
    }

    
    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the author?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteAuthor(el);
                if (this.props.successauthordelete) {
                    // await this.props.getAllQuestionAuthors(author_limit, author_offset, author_sort_column, author_sort_direction, author_search_term);
                    this.fetchAllQuestionAuthors()
                    this.generateAuthorOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted author!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });
                }
                if (this.props.errorauthordelete) {
                    window.Swal.fire({
                        title: 'Failed to delete author!',
                        text: this.props.errorauthordelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-author').modal('hide')
                            window.$("#question-author").val('default');
                            window.$('#question-author').selectpicker('refresh');
                        });

                }
            }
        })
    }
    
    syncTableAuthor() {
        return (
            <>
                {this.state.authorData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortAuthorChange("author_id")}
                                >
                                    No{" "}
                                    {this.state.author_sort_column === "author_id" && (
                                    <span>{this.state.author_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortAuthorChange("author")}
                                >
                                    Author Name{" "}
                                    {this.state.author_sort_column === "author" && (
                                    <span>{this.state.author_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.authorData.map((el, i) => {
                                if (i === this.state.selectAuthor) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editAuthor ? 
                                                this.getQuestionAuthor(el.author_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.author}
                                                    onChange={(e) => this.onEditAuthor(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateAuthor(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Author" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getQuestionAuthor(el.author_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditAuthor(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Author" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                                                    title="Delete Author" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Authors Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3>Manage Authors</h3>
              </div>

              <CardBody>
                <div className="row justify-content-end">
                  <div
                    className="table-wrapper-scroll-y my-custom-scrollbar"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <TabList>
                      <QuestionAccordion
                        labelName="Create New Author"
                        onChange={this.onNewAuthorInput}
                        onClick={this.onAddNewAuthor}
                        val={this.state.newAuthor}
                      />
                    </TabList>
                    <div className="row justify-content-end col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleAuthorSearch}
                          className="col-md-18 col-18"
                        />
                      </Form.Group>
                    </div>
                    <TabContent>
                      <TabPane
                        className="tab-pane fade show active"
                        id="manage-1"
                        ariaLabelledby="manage-tab-1"
                      >
                        {/* begin: Datatable */}
                        {this.props.cat_loading ? (
                          <div style={{ width: "75vw", overflowX: "scroll" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Author Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                            <div className="loading-container mb-5 mt-10">
                              <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </ReactSpinner>
                            </div>
                          </div>
                        ) : this.state.authorData.length > 0 ? (
                          this.syncTableAuthor()
                        ) : (
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Author Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {/* end: Datatable */}
                            </Table>
                            <div className="text-center mb-5 mt-10">
                              <label>No Authors Available!</label>
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                            totalCount = {this.props.totalAuthorsCount}
                            currentPage = {this.state.currentAuthorPage}
                            limit = {this.state.authorRowsPerPage}
                            handlePageChange = {this.handleAuthorPageChange}
                            handleRowsPerPageChange = {this.handleAuthorRowsPerPageChange}
                            handlePageOffsetChange = {this.handlePageOffsetChange}
                            offset = {this.state.author_offset}
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalAuthorsCount: state.authorReducer.totalAuthorsCount,//
        cat_loading:state.authorReducer.loading,
        error: state.authorReducer.error,//
        // totalCount: state.questionsLibraryReducer.totalCount,//
        
        errorauthordelete: state.authorReducer.errorauthordelete,
        successauthordelete: state.authorReducer.successauthordelete,

        errorauthorupdate: state.authorReducer.errorauthorupdate,
        successauthorupdate: state.authorReducer.successauthorupdate,//
        
        errorauthorcreate: state.authorReducer.errorauthorcreate,
        successauthorcreate: state.authorReducer.successauthorcreate,//

        allQuestionAuthors: state.authorReducer.allQuestionAuthors,//
        loading: state.authorReducer.loading,//
    }
}

    const mapActionsToProps = {
        createAuthor: quesAuthorActions.createAuthor,
        getAllQuestionAuthors: quesAuthorActions.getAllQuestionAuthors,
        updateAuthor: quesAuthorActions.updateAuthor,
        deleteAuthor: quesAuthorActions.deleteAuthor,
    }

    export default connect(mapStateToProps, mapActionsToProps)(QuestionAuthor);

