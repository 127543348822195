
import Modal from "react-bootstrap/Modal";
import React, { Component } from "react";
import HirearchyCard from "../HirearchyCard.js";
import AddCard from "../AddCard.js";

export default class HierarchyCardModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const {
        } = this.state;
        const {
            breadcrumbData,
            redirect,
            toggleUpdateModal,
            toggleModal,
            user_id,
            highlightCard,
            user_table_id,
            setNodeLoading,
            toggleAboutModal,
            toggleChatModal,
            toggleProgressModal,
            setHierarchyCardInsertion,
            setToInvisible,
            isCardSectionVisible,
            titles,
            getNodesAndFormsInfo,
            nodeFormsDisplayData
        } = this.props
        return (
            <div>
            <Modal
                size="lg"
                centered
                onHide={() => setToInvisible()}
                show={isCardSectionVisible}
            >
                <Modal.Header className="d-flex justify-content-end" closeButton></Modal.Header>
                <Modal.Body>
                <>
                    {nodeFormsDisplayData?.nodes?.length > 0 &&
                    nodeFormsDisplayData?.nodes?.map(
                        (cardData, index) => (
                        <HirearchyCard
                            singleNodeParentParent = {nodeFormsDisplayData?.nodes?.length === 1 ? breadcrumbData[breadcrumbData.length-1]: undefined}
                            key={index}
                            cardData={cardData}
                            redirect = {()=>redirect(cardData.pid, cardData.mid)}
                            redirectParent={redirect}   
                            toggleUpdateModal={toggleUpdateModal}
                            toggleInsertModal = {this.toggleModal}
                            user_id={user_id}
                            highlightCard={highlightCard}
                            user_table_id={user_table_id}
                            setNodeLoading={setNodeLoading}
                            toggleAboutModal={toggleAboutModal}
                            toggleChatModal={toggleChatModal}
                            toggleProgressModal= {toggleProgressModal}
                            setHierarchyCardInsertion = {setHierarchyCardInsertion}
                            titles={titles}
                            getNodesAndFormsInfo={getNodesAndFormsInfo}
                        />
                        )
                                    )}
                    {!!breadcrumbData[
                    breadcrumbData.length - 1
                    ] &&
                    !!breadcrumbData[
                        breadcrumbData.length - 1
                    ].flg_add_node_sibling && (
                        <AddCard toggleModal={toggleModal} />
                    )}
                </>
                </Modal.Body>
                </Modal>
            </div>
        )
    }
}



