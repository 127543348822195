import React, { Component } from "react";
import moment from "moment";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import CardHeader from "../../../components/common/CardHeader.js";
import CardBody from "../../../components/common/CardBody.js";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { CalendarService } from "../../../service/manage/calendar/calendar.service.js";
import SweetAlert from "react-bootstrap-sweetalert";
import swal from 'sweetalert';
import config from '../../../helper/config.js';
import {customerConstants} from "../../../helper/constants.js";
import DatePicker from "react-datepicker";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';
import CustomToolBar from './CustomToolBar.js' 
import {
  sweetConfirmAlert,
  sweetStatusAlert,
  sweetSuccessAlert,
} from "../../../components/common/SweetAlert.js";
import { CalendarEventTypeService } from "../../../service/settings/manage/calendar_events.service.js";

import Checkbox from '@mui/material/Checkbox';

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  Table,
  TableRow,
  TableBody,
  TextField,
  TableCell,
} from "@mui/material";
import EditNoteIcon from '@mui/icons-material/EditNote';
import IconButton from "@mui/material/IconButton"
import Autocomplete from "@mui/material/Autocomplete";
import { DeleteForever } from "@mui/icons-material";
const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, dropdownStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    dropdownStyle = {
      width: "170px",
      display: "flex",
      justifyContent: "space=between",
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

const localizer = momentLocalizer(moment);

export default class ManageCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment(),
      events: [],
      eventText: "",
      selectedMonth: moment().month(),
      selectedYear: moment().year(),
      showEventsPopup: false,
      selectedEvents: [],
      showSuccessAlert: false,
      showUpdateSuccessAlert: false,
      editingEvent: null,
      editedEventText: "",
      node_mid: null,
      selectedDateEvents: [],
      firstDayOfMonth: moment().startOf("month"),
      showEventPopup: false,
      deleteConfirmation: null,
      selectedEvent: null,
      showAllEvents: false,
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      selectedEventCategoryDropdown: undefined,
      selectedEventCategoryRadio: "All Events",
      showDropdown: false,
      showButtons:true,
      selectedEventCategories: [
        "Holiday",
        "Assignments",
        "Lab sessions",
        "Special Events",
        "Lecture Notice",
      ],
      showModal: false,
      showPersonalCalendar: false,
      breadcrumb2:null,
      nodeTitle:null,
      calEventTypes:[],
      selectedEventType:undefined,
      selectedSearchEventTypes:[],
      lg_user_id:undefined,
      lg_user_table_id: undefined,
      slotSelectedDate: undefined,
      editingEventText: "",
      node_mid_actual:null
    };
  }

  componentDidMount() {
    this.loadInitialData(this.loadEventsForSelectedMonth);
  }

  fetchAllEventTypes = () => {
    CalendarEventTypeService.getAllCalEventTypes().then(res => {
      this.setState({
        calEventTypes: res.data
      })
    })
  }
  

  loadInitialData(func) {
    this.fetchAllEventTypes()
    let privilege_edit = !!this.props.state.privilege_edit;
    let privilege_add = !!this.props.state.privilege_add;
    let privilege_delete = !!this.props.state.privilege_delete;
    let owner_restriction = !!this.props.state.owner_restriction;

    let node_mid_actual = this.props.state.node_mid_actual !== null ? this.props.state.node_mid_actual : this.props.state.mid;

    this.setState({
      addVisibility: privilege_add,
      editVisibility: privilege_edit,
      deleteVisibility: privilege_delete,
      showPersonalCalendar: owner_restriction,
      breadcrumb2: this.props.state.breadcrumb2,
      nodeTitle:this.props.state.nodeTitle,
      node_mid_actual: node_mid_actual
      // lg_user_id: this.props.state.lg_user_id,
      // lg_user_table_id: this.props.state.lg_user_table_id
      // user_list_table_id:sessionStorage.getItem("table_log_id"),
      // session_stu_id: sessionStorage.getItem("manage_user_id")
    }, () => func());
  }
  // Handle changes for the checkboxes
  handleCheckboxPersonalChange = (event) => {
    const {checked} = event.target
    this.setState({
      lg_user_id: checked ? this.props.state.lg_user_id : undefined,
      lg_user_table_id: checked ? this.props.state.lg_user_table_id: undefined
    },()=>{
      this.loadEventsForSelectedMonth();
    })
  };

  shouldShowEditDeleteButtons(event) {
    return (
      event &&
      this.state.selectedEventCategories.includes("Personal Calendar") ||
      this.state.editVisibility ||
      this.state.deleteVisibility
    );
  }
  
  formattedDateString = (value) =>{
    const dateObject = value ? new Date(value): undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject &&  ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString =  `${day}-${month}-${year}`
    return fromattedString;
  }

  handleSelectEventType = (event) =>{
    this.setState({
      selectedEventType:event,
      selectedEventCategoryDropdown: event.name
    })
  }


  handleDropdownDateChange = (date) => {
    const newFirstDayOfMonth = moment(date).startOf('month');
    this.setState({
      selectedDate: moment(date),
      firstDayOfMonth: newFirstDayOfMonth,
      selectedMonth: newFirstDayOfMonth.month(),
      selectedYear: newFirstDayOfMonth.year(),
    }, () => {
      this.loadEventsForSelectedMonth();
    });
  };
  

  handleDeleteEvent = (event) => {
    sweetConfirmAlert().then(() => {
      this.setState({
        deleteConfirmation: {
          event,
          // show: true,
        },
        showEventPopup: false,
      }, () => {
        this.confirmDeleteEvent()
      });      
    });    
  };

  // Add this method to confirm the deletion of an event
  confirmDeleteEvent = async () => {
    const { deleteConfirmation } = this.state;
    if (deleteConfirmation && deleteConfirmation.event) {
      try {
        const response = await CalendarService.deleteEvent(
          deleteConfirmation.event.entry_id
        );

        if (response.success) {
          const updatedEvents = this.state.events.filter(
            (event) => event.entry_id !== deleteConfirmation.event.entry_id
          );

          this.setState({
            events: updatedEvents,
            deleteConfirmation: null,
            showEventPopup: false,
          }, () =>{            
            sweetSuccessAlert('Success', 'Event deleted successfully', 'success')
          });
        }
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };

  updateDropdownText = (date) => {
    const formattedDate = date.format("MMMM DD, YYYY");
    const dropdownButton = document.getElementById("selectDateButton");

    if (dropdownButton) {
      dropdownButton.innerHTML = formattedDate;
      dropdownButton.title = date.format("YYYY-MM-DD");
    }
  };
  

  handleDatePickerChange = (value) => {
    const dateObject = value ? new Date(value): undefined;
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = ("0" + dateObject.getDate()).slice(-2);

    const formattedDateString = `${year}-${month}-${day}`;
    this.setState((prev) => ({ selectedDate: moment(formattedDateString)}));
  };

  handleInputChange = (e) => {
    this.setState({ eventText: e.target.value });
  };
  onFilterInput = (e,v,r) =>{
    this.setState({
      // On autofill we get a the stringified value.
      selectedSearchEventTypes:v
    }, () => {
      this.loadEventsForSelectedMonth()
    })
  }


  handleSaveEvent = async () => {
    const { eventText, selectedDate } = this.state;
  
    if (eventText.length > 100) {
      swal({
        title: "Error",
        text: "Enter event field should have maximum 100 characters.",
        icon: "error",
        button: "OK",
      });
      return;
    }

    const newEvent = {
      event_title: this.state.eventText,
      event_description: `${'None'}`,
      event_start_date: this.state.selectedDate.format("YYYY-MM-DD"),
      event_end_date: this.state.selectedDate.format("YYYY-MM-DD"),
      node_mid: !!this.state.node_mid_actual
        ? this.state.node_mid_actual
        : null,
      event_start_time: "08:00:00",
      event_end_time: "09:00:00",
      create_user_id: !!this.props.state.lg_user_id
        ? this.props.state.lg_user_id
        : null,
      create_user_table_id: !!this.props.state.lg_user_table_id
        ? this.props.state.lg_user_table_id
        : null,
      cal_event_type_id: this.state.selectedEventType.cal_event_type_id
    };

    try {
      this.handleCloseModal()
      const response = await CalendarService.addEvent(newEvent);

      if (response.success) {
        const savedEvent = response.data;        
        this.setState(
          (prevState) => ({
            events: [...prevState.events, savedEvent],
            eventText: "",
            // showSuccessAlert: true,
            selectedEventType: undefined,
            selectedMonth: moment(savedEvent.event_start_date).month(),
          selectedYear: moment(savedEvent.event_start_date).year(),
          }),
          () => {
            this.loadEventsForSelectedMonth();
            sweetSuccessAlert('Success', 'Event added successfully', 'success')
          }
        );
      }
    } catch (error) {
      sweetStatusAlert("Failed",error.message,"error")
    } finally{
      this.state.showEventPopup && this.closeEventPopup()
    }
  };

  checkingDateIsAfterTodayDate = (date) => {
    const today = moment().startOf('day'); 
    return date.isSameOrAfter(today) 
  }

  handleEditEvent = (event) => { 
    this.setState({
      selectedEvent: event,
      editingEventText: event?.title
    });

    const selectedEvent = this.state.calEventTypes?.find(event_ => event_.cal_event_type_id === event?.cal_event_type_id)
    if(selectedEvent){
      this.handleSelectEventType(selectedEvent)
    }
    if(!event){
      this.closeEventPopup()
    }
  };

  // Handle changes to the edited event title
  handleEditingEventInputChange = (e) => {
    this.setState({ editingEventText: e.target.value });
  };

  handleSaveEditedEvent = async () => {
    const { selectedEvent, editingEventText } = this.state;
    if (!selectedEvent) {
      return;
    }
    const updatedEvent = {
      entry_id: selectedEvent.entry_id,
      event_title: editingEventText,
      event_start_date: selectedEvent.start.toISOString(),
      cal_event_type_id: this.state.selectedEventType.cal_event_type_id
    };
    try {
      const response = await CalendarService.updateEvent(
        selectedEvent.entry_id,
        updatedEvent
      );

      if (response.success) {
        // Update the event in the calendar
        const updatedEvents = this.state.events.map((event) =>
          event.entry_id === selectedEvent.entry_id
            ? { ...event, title: editingEventText }
            : event
        );

        this.setState(
          {
            events: updatedEvents,
          },
          () => {
            
            sweetSuccessAlert('Success', 'Event updated successfully', 'success')
            this.loadEventsForSelectedMonth()
          }
        );
      }
    } catch (error) {
      console.error("Error saving edited event:", error);
    } finally{
      this.setState(
        {
          editingEvent: null,
          editingEventText: "",
          showEventPopup: false,
          cal_event_type_id: undefined
        })
    }
  };

  handleMonthChange = (month) => {
    this.setState({ selectedMonth: month }, () => {
      // Update the firstDayOfMonth to the selected month and year
      const newFirstDayOfMonth = this.state.firstDayOfMonth
        .clone()
        .month(month);
      this.setState({ firstDayOfMonth: newFirstDayOfMonth }, () => {
        this.loadEventsForSelectedMonth();
      });
    });
  };


  loadEventsForSelectedMonth = async () => {
    const firstDayOfMonth = this.state.firstDayOfMonth;
  
    const selectedMonth = firstDayOfMonth.month();
    const selectedYear = firstDayOfMonth.year();
  
    const lg_user_id  = this.state.lg_user_id ? this.state.lg_user_id : '';
    const lg_user_table_id  = this.state.lg_user_table_id ? this.state.lg_user_table_id : '';
  
    try {
      let response;
  
      // Ensure that node_mid is defined before making the API call
      const validNodeMid = this.state.node_mid_actual;
      let selectedEventCategories = this.state.selectedSearchEventTypes.length ? this.state.selectedSearchEventTypes.map(event => event.cal_event_type_id) : []
  
        // Fetch events based on the selected categories
      response = await CalendarService.getEventTitlesForMonth(
        selectedYear,
        selectedMonth + 1,
        selectedEventCategories,
        lg_user_id,
        lg_user_table_id,
        validNodeMid
      );
  
      if (response.success) {
        const calendarEvents = response.data.map((event) => ({
          title: event.event_title,
          start: moment(event.event_start_date).toDate(),
          end: moment(event.event_start_date).toDate(),
          entry_id: event.entry_id,
          calendar_event_type: event.calendar_event_type,
          cal_event_type_id: event.cal_event_type_id
        }));
  
        this.setState({ events: calendarEvents });
      }
    } catch (error) {
      console.error("Error loading events for the month:", error);
    }
  };
  

  handleAddEventClick = () => {
    this.setState({
      showModal: true
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedEventCategoryDropdown: undefined,
      selectedDate: moment(),
    });
  };

  handleDateClick = (slotInfo) => {
    const selectedDate = moment(slotInfo.start);
    const selectedEvents = this.state.events.filter((event) => {
      return moment(event.start).isSame(selectedDate, "day");
    });
    this.setState({
      showEventPopup: true,
      slotSelectedDate: selectedDate,
      selectedDateEvents: selectedEvents,
    });
  };

  closeEventPopup = () => {
    this.setState({
      showEventPopup: false,
      selectedDateEvents: [],
      selectedEvent: null,
      slotSelectedDate: undefined,
      selectedEventType: undefined
    });
  };

  render() {
    const {
      events,
      showSuccessAlert,
      showUpdateSuccessAlert,
      selectedDate,
      selectedMonth,
      selectedYear,
      // showAllEvents,
      deleteConfirmation,
      editingEvent,
      editedEventText,
      firstDayOfMonth,
      showEventsPopup,
      selectedEvents,
      editVisibility,
      addVisibility,
      deleteVisibility,
      selectedEventCategoryDropdown,
      selectedEventCategoryRadio,
      showModal,
      eventText,
      showDropdown,
      showButtons,
      breadcrumb2,
      nodeTitle
    } = this.state;
    const formattedBreadcrumb = breadcrumb2? breadcrumb2.replaceAll(">", " > ") : ""

    return (    

      <>
      <div className="sweet-alert-container">

        {showUpdateSuccessAlert && (
          <SweetAlert
            success
            title="Success!"
            onConfirm={() =>
              this.setState({ showUpdateSuccessAlert: false })
            }
          >
            Event updated successfully.
          </SweetAlert>
        )}
        {showSuccessAlert && (
          <SweetAlert
            success
            title="Success!"
            onConfirm={() => {
              this.setState({ showSuccessAlert: false })
              this.handleCloseModal()
            }}
          >
            Event added successfully.
          </SweetAlert>
        )}
      </div>
      
      <ContentWrapper>
        <div className="col-md-12 px-0">
          <div className="px-0" style={{ overflow: "auto" }}>
            <div>
              <CardHeader>
                {this.props.state.path !== "node" && (
                  <p style={{ fontSize: "10.5625px", color: "#00000099" }}>
                    {formattedBreadcrumb ? `${formattedBreadcrumb && formattedBreadcrumb}` : ""}
                  </p>
                )}
                <div className="row">
                  <div className="col-md-12 px-0">
                    <div className="row d-flex py-3">
                      <div
                        class="d-flex justify-content-start px-0 mt-5 pb-5 col-6 "
                        style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                      >
                        <Autocomplete
                          multiple
                          size="small"
                          id="checkboxes-filter"
                          options={this.state.calEventTypes}
                          disableCloseOnSelect
                          getOptionLabel={(option) => (
                            <li style={{ fontFamily: 'Poppins, sans-serif', listStyle: 'none' }}>{option.name}</li>
                          )}
                          onChange={(e, v, r) => this.onFilterInput(e, v, r)}
                          sx={{
                            // width: 300,
                            fontFamily: "Poppins, sans-serif"
                          }}
                          renderOption={(props, option, { selected }) => {
                            return (
                            <li {...props} style={{ fontFamily: 'Poppins' }}>
                              <Checkbox
                                style={{ marginRight: 8, color: "#5458AF" }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          )}}
                          className="col-lg-12 col-md-12 col-12 px-1 py-2"
                          renderInput={(params) => (
                            <TextField {...params} label="Select Filter" className="col-md-12" />
                          )}
                        />
                        </div>
                      {!!addVisibility && <div className="col-6 col-md-6 d-flex justify-content-end align-items-center px-0">
                        {/* <div className="col-md-4"> */}
                        <div>
                          <Button
                            variant="primary"
                            onClick={this.handleAddEventClick}
                          >
                            Add Event
                          </Button>
                        </div>
                        {/* </div>  */}
                      </div>}
                      </div>
                      <div
                  >
                    <Form.Group className="d-flex col-md-12 justify-content-between px-0">
                      
                      
                      <div className="calendar-checkbox">
                        <Form.Check
                          type="checkbox"
                          label="My Events"
                          checked={!!this.state.lg_user_id && !!this.state.lg_user_table_id}
                          onChange={
                            this.handleCheckboxPersonalChange
                          }
                          style={{ whiteSpace: "nowrap" }}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  </div>
                </div>
              </CardHeader>

              <CardBody>
                <div className="row">
                  <div className="col-md-6">
                    {showDropdown && (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="primary"
                          id="selectMonthYearButton"
                          style={{
                            width: "170px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {firstDayOfMonth.format("MMMM YYYY")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {moment.months().map((month, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => this.handleMonthChange(index)}
                            >
                              {month}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
                <div style={{ height: 400 }}>
                  <BigCalendar
                    localizer={localizer}
                    events={events}
                    views={["month"]}
                    defaultView={"month"}
                    date={selectedDate.toDate()}
                    onNavigate={(newDate, view, action) =>
                      {
                        this.handleDropdownDateChange(moment(newDate))
                        if(action === 'DATE'){
                          this.handleDateClick({'start':newDate})
                        }
                      }
                    }
                    selectable={true}
                    onSelectSlot={this.handleDateClick}
                    onSelectEvent = {this.handleDateClick}
                    components={{ toolbar: CustomToolBar}}
                    // components={{ toolbar: CustomToolBar, eventWrapper: this.CustomEventWrapper }}
                    // components={{ toolbar: CustomToolBar, eventWrapper: this.CustomEventWrapper, dateCellWrapper: this.Book }}
                  />
                </div>               

                <br />
                <br />
                {this.state.showEventPopup && (
                  <Modal
                    show={this.state.showEventPopup}
                    onHide={this.closeEventPopup}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Events of the Day {`: ${this.formattedDateString(this.state.slotSelectedDate)}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {this.checkingDateIsAfterTodayDate(this.state.slotSelectedDate) && <div className="d-flex justify-content-end">

                        <Button
                          variant="primary"
                          onClick={() => {
                            this.setState({
                              selectedDate: this.state.slotSelectedDate
                            }, ()=>{
                              this.handleAddEventClick()
                            })
                          }}
                        >
                        Add Event
                      </Button>
                      </div>}
                      
                    {this.state.selectedDateEvents?.length ? <Table>
                        <TableBody>
                          {this.state.selectedDateEvents.map((event, index) => {
                            return (
                            <TableRow key={event.entry_id}>
                                <TableCell className="tData">
                                {event.title}
                                </TableCell>
                                <TableCell className="tData">
                                  {event.calendar_event_type}
                                </TableCell>
                                <TableCell>
                                  <div className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn">
                                    <IconButton
                                        onClick={() => this.handleEditEvent(event)}
                                    >
                                      <EditNoteIcon
                                        style={{
                                          color: "#A2A5B5",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                  
                                  <div className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn">
                                    <IconButton
                                        onClick={() => this.handleDeleteEvent(event)}
                                    >
                                      <DeleteForever
                                        style={{
                                          color: "#A2A5B5",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )})
                            }
                          </TableBody>
                        </Table> : <div>No events to show on this Date</div>}
                      {/* <ul className="p-0">
                        {this.state.selectedDateEvents.map((event, index) => (
                          <li
                            key={event.entry_id}
                            style={{
                              marginBottom: "10px",
                              paddingBottom: "10px",
                              borderBottom:
                                index ===
                                this.state.selectedDateEvents.length - 1
                                  ? "none"
                                  : "1px solid #E4E6EF",
                            }}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {" "}
                            {event.title}
                            {showButtons &&
                              this.shouldShowEditDeleteButtons(event) && (
                                <div className="">
                                  <Button
                                    className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit"
                                    title="Edit"
                                    variant="primary"
                                    onClick={() => this.handleEditEvent(event)}
                                  />
                                  <Button
                                    className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                    title="Delete"
                                    variant="primary"
                                    onClick={() =>
                                      this.handleDeleteEvent(event)
                                    }
                                  />
                                  
                                </div>
                              )}
                          </li>
                        ))}
                      </ul> */}
                    </Modal.Body>
                    <Modal.Footer>
                      {/* <Button
                            id="modal-close-button"
                            onClick={this.closeEventPopup}
                          >
                            Close
                          </Button> */}
                    </Modal.Footer>
                  </Modal>
                )}
                {this.state.selectedEvent && (
                  <Modal show={true} onHide={() => this.handleEditEvent(null)}>
                    <Modal.Header closeButton>
                      <Modal.Title>Edit Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <Row className="my-5">
                    <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                      <Form.Control
                        type="text"
                        placeholder="Edit event title"
                        value={this.state.editingEventText}
                        onChange={(e) => this.handleEditingEventInputChange(e)}
                      />
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                      
                      <Dropdown>
                        <Dropdown.Toggle
                          // variant="primary"
                          disabled={!this.state.calEventTypes.length}
                          id="eventCategoryDropdown"
                          style={{
                            dropdownStyle,
                            width: "170px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            border: "1px solid #E4E6EF",
                            color: "var(--secondary-text-color)",
                            fontWeight: "400"
                          }}
                        >
                          {selectedEventCategoryDropdown ? selectedEventCategoryDropdown :'No Event Selected' }
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ width: "170px" }}>
                          {addVisibility && (
                            <>
                              {this.state.calEventTypes.length && this.state.calEventTypes.map(event => (
                                <Dropdown.Item
                                  onClick={() => this.handleSelectEventType(event)}
                                >
                                  {event.name}
                                </Dropdown.Item>
                              ))}
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                    </Row>
                      
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={() => {
                          this.handleSaveEditedEvent()
                          this.handleEditEvent(null)
                        }}
                      >
                        Save
                      </Button>
                    </Modal.Footer>
                  </Modal>
                )}
              </CardBody>
            </div>
          </div>
        </div>

        {showModal && (
          <Modal show={showModal} onHide={this.handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Add Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-6 col-6">
                  <DatePicker
                    className="py-2 form-control" // Add Bootstrap form control class
                    selected={selectedDate ? new Date(selectedDate):undefined}
                    name="selectedDate"
                    onChange={this.handleDatePickerChange}
                    dateFormat="dd/MM/yyyy" // Set custom date format
                    placeholderText="Event Date"
                    minDate={new Date()}
                  />                   
                </div>

                {/* <div className="col-md-4">
                      <div className="row"> */}
                <div className="col-md-6 col-6 d-flex justify-content-end">
                  {/* {addVisibility && ( */}
                  <Dropdown>
                    <Dropdown.Toggle
                      // variant="primary"
                      disabled={!this.state.calEventTypes.length}
                      id="eventCategoryDropdown"
                      style={{
                        dropdownStyle,
                        width: "170px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        border: "1px solid #E4E6EF",
                        color: "var(--secondary-text-color)",
                        fontWeight: "400"
                      }}
                    >
                      {selectedEventCategoryDropdown ? selectedEventCategoryDropdown :'No Event Selected' }
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ width: "170px" }}>
                      {addVisibility && (
                        <>
                          {this.state.calEventTypes.length && this.state.calEventTypes.map(event => (
                            <Dropdown.Item
                              onClick={() => this.handleSelectEventType(event)}
                            >
                              {event.name}
                            </Dropdown.Item>
                          ))}
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* )} */}
                </div>
              </div>
              <div className="my-3"></div>
              <div className="row">
                <div className="col-md-12">
                  <Form.Control
                    type="text"
                    placeholder="Enter Details"
                    value={this.state.eventText}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  onClick={this.handleSaveEvent}
                  style={addBtnStyle}
                  disabled={!eventText || !selectedEventCategoryDropdown}
                >
                  Save Event
                </Button>
                <span className="ml-2"></span>
                <Button
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={this.handleCloseModal}
                >
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </ContentWrapper>
      </>
    )
  }
}
