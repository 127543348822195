import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import './assets/css/index.css';
import {App} from './App';
import {Provider} from 'react-redux';
import {render} from 'react-dom';

import {store} from './helper/redux_store';
import ChatProvider from './components/context/ChatProvider';

render(
    <Provider store={store}>
        <ChatProvider>
        <App/>
        </ChatProvider>
    </Provider>,
    document.getElementById('root')
);