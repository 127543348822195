
import Modal from "react-bootstrap/Modal";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import CardBody from "../../../components/common/CardBody";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { sweetStatusAlert } from "../../../components/common/SweetAlert";
import SimpleReactValidator from "simple-react-validator";
import Spinner from "react-bootstrap/Spinner";
import { DiscountService } from "../../../service/manage/payments/discount.service";

export default class DiscountModel extends Component {
    constructor(props) {
        super(props);
        this.createValidationsInstance();
        this.state = {
            description: this.props.selectedStudent ? this.props.selectedStudent.reason : undefined,
            discount: this.props.selectedStudent ? this.props.selectedStudent.discount : undefined,
            isLoading: false
        }
    }

    //create validation object
    createValidationsInstance() {
      this.validator = new SimpleReactValidator();
    }

    
    handleDiscountChange = (e) => {
        let newValue = e.target.value;
        if (newValue < 0) {
            newValue = 0;
        }
        this.setState({ discount: newValue });
    };

    //handle properties and states
    handleChange = (event) => {
      const { name, value } = event.target;
      this.setState(() => ({ [name]: value, isModified: true }));
    };

    componentDidUpdate(prevProps, prevState){
        if(this.props.selectedStudent?.discount !== prevProps.selectedStudent?.discount ){
            this.setState({
                discount: this.props.selectedStudent?.discount
            })
        }
        if(this.props.selectedStudent?.reason !== prevProps.selectedStudent?.reason ){
            this.setState({
                description: this.props.selectedStudent?.reason,
            })
        }
    }

    onToggleDiscount = (isUndefined) => {
        this.validator.hideMessages();
        this.validator.purgeFields();
        this.props.toggleDiscountModal(isUndefined);
    }
        
    //handle the create discount
    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        } else {
        this.setState({ isLoading: true });
        const { description, discount } = this.state;
        const newDiscount = {
            discount: parseInt(discount),
            reason: description,
            user_id: this.props.selectedStudent.user_id,
            user_table_id: this.props.selectedStudent.user_table_id,
            node_mid:this.props.state.mid
        };

        DiscountService.add(newDiscount)
            .then((response) => {
            if (response.success) {
                sweetStatusAlert("Success!", response.message.success, "success");
                this.props.getAllEnrollments()
            } else {
                sweetStatusAlert("Failed!", response.message, "error");
            }
            })
            .catch((err) => {
                sweetStatusAlert("Failed!", "Unable to create new discount", "error");
            })
            .finally(() =>{
                this.setState({ isLoading: false });
                this.onToggleDiscount(undefined)
            });
        }
    };

    //handle the update discount
    handleEditSubmit = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
        } else {
        this.setState({ isLoading: true });
        const { description, discount } = this.state;
        const discountDetail = {
            discount: parseInt(discount),
            reason: description,
        };

        DiscountService.update(discountDetail, this.props.selectedStudent?.discount_id)
            .then((response) => {
                if (response.success) {
                    sweetStatusAlert("Success!", response.message.success, "success");
                    this.props.getAllEnrollments()
                } else {
                    sweetStatusAlert("Failed!", response.message, "error");
                }
            })
            .catch((err) => {
                sweetStatusAlert("Failed!", "Unable to update the discount", "error");
            })
            .finally(() =>{
                this.setState({ isLoading: false });
                this.onToggleDiscount(undefined)
            });
        }
    };

    render() {
        this.validator.purgeFields();
        return (
            <div>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.showDiscountModal}
                    onHide={() => this.onToggleDiscount(undefined)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title> <h3>{this.props.selectedStudent?.first_name + ' ' + this.props.selectedStudent?.last_name}</h3> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="col-md-12">
                                <div className="announcement-container">
                                    <div className="pt-4 pb-3 pl-3">
                                        <p className="parent-section-title">
                                            {" "}
                                            <InsertChartIcon sx={{ fontSize: 28 }} /> Apply Discount
                                        </p>
                                    </div>
                                    {this.state.isLoading ? 
                                        <div className="loading-container">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                        </div>  :
                                    <CardBody >
                                    <Form>
                                        <Row className="my-5">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleForm.ControlInput2"
                                        >
                                            <Form.Label>Discount</Form.Label>
                                            <Form.Control
                                                min={0}
                                                type="number"
                                                name="discount"
                                                placeholder="Discount"
                                                onChange={this.handleDiscountChange}
                                                value={this.state.discount}
                                                disabled={!this.props.editVisibility}
                                            />
                                            {this.validator.message(
                                            "discount",
                                            this.state.discount,
                                            "required",
                                            {
                                                className: "text-danger",
                                                messages: {
                                                required: "The discount field is required",
                                                },
                                            }
                                            )}
                                        </Form.Group>
                                        </Row>
                                        <Row className="my-5">
                                        <Form.Group
                                            as={Col}
                                            md="6"
                                            controlId="exampleForm.ControlInput1"
                                        >
                                            <Form.Label>Description</Form.Label>
                                            <textarea
                                                class="form-control"
                                                id="exampleFormControlTextarea1"            
                                                placeholder="Enter Description"
                                                rows="3"
                                                name="description"
                                                value={this.state.description}
                                                onChange={this.handleChange}
                                                disabled={!this.props.editVisibility}
                                            ></textarea>
                                            {this.validator.message(
                                            "description",
                                            this.state.description,
                                            "required",
                                            {
                                                className: "text-danger",
                                                messages: {
                                                required: "The description field is required",
                                                },
                                            }
                                            )}
                                            
                                        </Form.Group>
                                        
                                        </Row>
                                    </Form>
                                    </CardBody>}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.props.editVisibility && <Button variant="primary" style={ this.props.addBtnStyle } onClick={this.props.selectedStudent?.created_at? this.handleEditSubmit: this.handleSubmit}>
                            Save changes
                        </Button> }                       
                        <Button
                            id="modal-close-button"
                            style={this.props.closeBtnStyle}
                            onClick={() =>
                                this.onToggleDiscount(undefined)
                            }
                        >
                            Close
                        </Button>
                </Modal.Footer>
                </Modal>
            </div>
        )
    }
}



