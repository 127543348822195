import React, { Component } from "react"

export default class CustomToolBar extends Component {
  constructor(props) {
    super(props)
  }

  navigate = (action) => {
    this.props.onNavigate(action)
  }
  render() {
    let navigateConst = {
      PREVIOUS: "PREV",
      NEXT: "NEXT",
      TODAY: "TODAY",
      DATE: "DATE",
    }

    let {
      localizer: { messages },
      label,
    } = this.props
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={() => this.navigate(navigateConst.PREVIOUS)}
            style={{ 
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px"
            }}
          >
            Prev
          </button>
          <button
            type="button"
            onClick={() => this.navigate(navigateConst.NEXT)}
            style={{ 
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px"
            }}
          >
            Next
          </button>
        </span>
        <span className="rbc-toolbar-label">{label}</span>
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={() => this.navigate(navigateConst.TODAY)}
            style={{ 
                fontFamily: "Poppins, sans-serif",
                fontSize: "12px"
            }}
          >
            Today
          </button>
        </span>
      </div>
    )
  }
}
