import React, { useEffect } from 'react';

export default function Wizard ({children}) {
    return (
        <div className="wizard wizard-6 d-flex flex-column flex-lg-row flex-column-fluid create-assessment-wizard" id="kt_wizard">
            {/* begin::Container */}
            <div className="wizard-content d-flex flex-column mx-auto w-100">
                {children}
            </div>
            {/* end::Container */}
        </div>
    )
}


