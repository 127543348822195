import {submissionTypes} from '../../reducers/types';
import { descriptiveService } from '../../service/question/descriptiveService';

export const submissionActions = {
    refreshPage,
    createQuestion,
    saveAnswer,
    refreshAddAnswer
};


function createQuestion(obj) {
    return async dispatch => {

        try {
            dispatch(clearExisting());
            dispatch(request());
            let data = await descriptiveService.saveQuestion(obj);
            dispatch(success(data.data));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function clearExisting() {
        return {type: submissionTypes.CREATE_SUBMISSION_QUESTION}
    }

    function request() {
        return {type: submissionTypes.CREATE_SUBMISSION_QUESTION_REQUEST}
    }

    function success(question) {
        return {type: submissionTypes.CREATE_SUBMISSION_QUESTION_SUCCESS, question}
    }

    function failure(error) {
        return {type: submissionTypes.CREATE_SUBMISSION_QUESTION_FAILURE, error}
    }
}


function saveAnswer(obj) {
    return async dispatch => {

        dispatch(request());

        await descriptiveService.saveAnswer(obj)
            .then(
                question => {
                    dispatch(success(question.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() {
        return {type: submissionTypes.ADD_ANSWER_REQUEST}
    }

    function success(question) {
        return {type: submissionTypes.ADD_ANSWER_SUCCESS, question}
    }

    function failure(error) {
        return {type: submissionTypes.ADD_ANSWER_FAILURE, error}
    }
}

function refreshAddAnswer() {
    return dispatch => {
        dispatch(clearExisting());
    };

    function clearExisting() {
        return {type: submissionTypes.ADD_ANSWER}
    }
}

function refreshPage() {
    return dispatch => {
        dispatch(clearExisting());
    };

    function clearExisting() {
        return {type: submissionTypes.SUBMISSION_PAGE_REFRESH}
    }
}