import {assessTermTypes} from '../settingsTypes';

const initialState = {
    allAssessmentTerms: [],
    loading: false,
    error: '',
    text: '',
    term:'',
    totalTermsCount: 0,
    allTerms:[],
    errortermdelete:false,
    loadingtermdelete:false,
    successtermupdate: false,
    loadingtermupdate: false,
    errortermupdate:false,
    successtermdelete:false,
    errortermcreate: '',
    successtermcreate: false,
}

export function termReducer(state = initialState, action){
    switch (action.type){
        case assessTermTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allAssessmentTerms: [],
                loading: false,
                error: '',
                text: '',
                term:'',
                totalTermsCount:0,
                allTerms:[],
                errortermdelete:false,
                loadingtermdelete:false,
                successtermupdate: false,
                loadingtermupdate: false,
                errortermupdate:false,
                successtermdelete:false,
                errortermcreate: '',
                successtermcreate: false,
            }
        //Term retrieve request sending
        case assessTermTypes.GET_ALL_ASSESSMENT_TERMS:
            return {
                ...state,
                loading: true,
                text: 'Get all the assessment terms'
            }
        case assessTermTypes.GET_ALL_ASSESSMENT_TERMS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessTermTypes.GET_ALL_ASSESSMENT_TERMS_SUCCESS:
            return {
                ...state,
                loading: false,
                allAssessmentTerms: action.terms.terms,
                totalTermsCount: action.terms.total_count
            }
        case assessTermTypes.GET_ALL_ASSESSMENT_TERMS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Term creation request sending
        case assessTermTypes.CREATE_TERM_REQUEST:
            return {
                ...state,
                errortermcreate: '',
                successtermcreate: false,
                loading: true
            }
        case assessTermTypes.CREATE_TERM_SUCCESS:
            var temp = state.allTerms;
            temp.push(action.terms)
            return {
                ...state,
                allTerms: temp,
                term: action.terms.term_id,
                successtermcreate: true,
                loading: false
            }
        case assessTermTypes.CREATE_TERM_FAILURE:
            return {
                ...state,
                errortermcreate: action.error,
                loading: false
            }  
        //Term deletion request sending   
        case assessTermTypes.DELETE_TERM_REQUEST:
            return {
            ...state,
            errortermdelete: '',
            successtermdelete: false,
            loading: true,
            };
        case assessTermTypes.DELETE_TERM_SUCCESS:
            return {
            ...state,
            successtermdelete: true,
            loading: false,
            };
        case assessTermTypes.DELETE_TERM_FAILURE:
            return {
            ...state,
            errortermdelete: action.error,
            loading: false,
            };
        //Term updation request sending
        case assessTermTypes.UPDATE_TERM_REQUEST:
            return {
            ...state,
            errortermupdate:'',
            successtermupdate: false,
            loading: true,
            };
        case assessTermTypes.UPDATE_TERM_SUCCESS:
            return {
            ...state,
            successtermupdate: true,
            loading: false,
            };
        case assessTermTypes.UPDATE_TERM_FAILURE:
            return {
            ...state,
            errortermupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
