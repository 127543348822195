import { store } from "../helper/redux_store";
import config from "../helper/config";
import { history } from "../helper/history";
import {
  alertConstants,
  authenticationConstants,
  federationConstants,
  loginLoaderConstants,
  TermsAgreementConstants,
} from "../helper/constants";
import { userActions } from "../actions/user_management/userActions";
import { studentUserService } from "../service/user_management/student_user.service";
import { StaffService } from "../service/user_management/staff.service";
import { StaffsRoleService } from "../service/user_management/staff_role.service";
import { StudentsRoleService } from "../service/user_management/student_role.service";
import { sweetStatusAlert } from "../components/common/SweetAlert";
import { TreeMainService } from "../service/manage/h_tree/tree_main.service";
import { ParentService } from "../service/manage/registration/parent.service";
import DataHandler from "../handlers/DataHandler";
import { routes } from "../helper/routes";
import { customerConstants } from "../helper/constants";
import { StudentService } from "../service/manage/registration/student.service";
import { AzureStorage } from "../helper/azureStorage";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

const customer = getCustomerFeatureFlag();
let isEdFoci;
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    isEdFoci = false
    break
  case customerConstants.EXO_CUSTOMER2:
    isEdFoci = false
    break
  default:
    isEdFoci = true
    break
}

class UMAuthAgent {
  constructor() {
    this.UMUserStaff = null;
    this.UMUserStudent = null;
    this.agreementStatus = "unknown";
    this.userType = null;
  }
  /*
   * Clears the UMAuthAgent props to null
   */
  clearUser() {
    this.UMUserStaff = null;
    this.UMUserStudent = null;
    this.agreementStatus = "unknown";
  }

  /*
   * Returns (bool) agreement status of currently logged in user
   */
  getAgreementStatus() {
    return this.agreementStatus;
  }
  setSessionUser = async (user, userType) => {
    try {
      if (userType === "student") {
        const userDetails = await TreeMainService.getRegistrationInfo(
          user.student_id,
          user.table_log_id
        );
        await AzureStorage.getSASToken();
        sessionStorage.setItem("userType", userDetails.data[0].table_name.split('.')[1]);
        sessionStorage.setItem("username", userDetails.data[0].first_name + " " + userDetails.data[0].last_name);
        sessionStorage.setItem("user_id", user.user_id);
        sessionStorage.setItem("manage_user_id", user.student_id);
        sessionStorage.setItem("email", user.user_email);
        sessionStorage.setItem("table_log_id", user.table_log_id);
        const parentDetails = await ParentService.getParentGroupByParentId(
          user.student_id,
        );
        if(parentDetails.success){
          sessionStorage.setItem("parent_group_id", parentDetails.data.user_id);
          sessionStorage.setItem("parent_group_user_table_id", parentDetails.data.user_table_id);
        }
        await StudentService.getStudentbyEmail(user.user_email).then((response) => {
          sessionStorage.setItem("studentGrade", response.data.grade);
        })
      } else if (userType === "admin" || userType === "teacher") {
        const userDetails = await TreeMainService.getRegistrationInfo(
          user.staff_id,
          user.table_log_id
        );
        await AzureStorage.getSASToken();
        sessionStorage.setItem("userType", userDetails.data[0].table_name.split('.')[1]);
        sessionStorage.setItem("username", userDetails.data[0].first_name + " " + userDetails.data[0].last_name );
        sessionStorage.setItem("user_id", user.user_id);
        sessionStorage.setItem("manage_user_id", user.staff_id);
        sessionStorage.setItem("email", user.user_email);
        sessionStorage.setItem("table_log_id", user.table_log_id);
      }
    } catch (error) {
      throw error;
    }
  };

  async getUMUser() {
    const userTypeSelected = sessionStorage.getItem("user_role_selection");
    const method = store.getState().authLoginReducer.method;
    const { email } = store.getState().myProfileReducer.me;

    if (userTypeSelected === "student") {
      if (this.UMUserStudent !== null) {
        return this.UMUserStudent;
      }
      if (
        method === federationConstants.MICROSOFT ||
        method === federationConstants.GOOGLE
      ) {
        try {
          const studentResponseUM =
            await studentUserService.searchStudentByEmail(email);
          this.UMUserStudent = studentResponseUM.data[0];
          const agreeStatus =
            String(studentResponseUM.data[0].user_agreement_status).toLowerCase() ===
            "true";
          this.agreementStatus = agreeStatus;
          if (agreeStatus) {
            store.dispatch({
              type: TermsAgreementConstants.AGREEMENT_SUCCESS,
            });
          }
          try {
            const studentRoleRes =
              await StudentsRoleService.searchStudentRoleByUserId(
                studentResponseUM.data[0].user_id
              );
            await this.setSessionUser(
              studentResponseUM.data[0],
              userTypeSelected,
              studentRoleRes.data[0].role_code
            );
            if (
              studentRoleRes.data[0].role_code === "CNDT" ||
              studentRoleRes.data[0].role_code === "STUD" ||
              studentRoleRes.data[0].role_code === "STUC"
            ) {
              this.userType = "student";
              this.loggedIn();
            } else {
              sweetStatusAlert(
                "Failed",
                "We coudnt able to decide your role !!! Please contact System Administrator",
                "error"
              ).then(() => {
                userActions.staffLogout();
                window.location.reload(true);
              });
              this.unabledLoggedIn();
            }
          } catch (error) {
            sweetStatusAlert(
              "Failed",
              "We coudnt able to decide your role !!! Please contact System Administrator",
              "error"
            ).then(() => {
              userActions.staffLogout();
              window.location.reload(true);
            });
            this.unabledLoggedIn(error);
          }
        } catch (error) {
          sweetStatusAlert(
            "Failed",
            "You are not a User !!! Please contact System Administrator",
            "error"
          ).then(() => {
            userActions.staffLogout();
            window.location.reload(true);
          });
          this.unabledLoggedIn(error);
        }
      }
    } else {
      if (this.UMUserStaff !== null) {
        return this.UMUserStaff;
      }
      if (
        method === federationConstants.MICROSOFT ||
        method === federationConstants.GOOGLE
      ) {
        try {
          const staffResponse = await StaffService.searchStaffByEmail(email);
          if(staffResponse.data.some(role => role.role_code === 'ASAD')){
            DataHandler.setToSession("isASAD", staffResponse.data.some(role => role.role_code === 'ASAD'));
          }

          const { user_id } = staffResponse.data[0];
          this.UMUserStaff = staffResponse.data[0];
          try {
            const staffRoles = await StaffsRoleService.searchStaffRoleByUserId(
              user_id
            );
            const role = staffRoles.data[0];
            await this.setSessionUser(
              staffResponse.data[0],
              userTypeSelected,
              role.role_code
            );
            const agreeStatus =
              String(staffResponse.data[0].user_agreement_status).toLowerCase() ===
              "true";
            this.agreementStatus = agreeStatus;
            if (agreeStatus) {
              store.dispatch({
                type: TermsAgreementConstants.AGREEMENT_SUCCESS,
              });
            }
            if (role.role_code === "SYST" && userTypeSelected === "admin") {
              this.userType = "admin";
              this.loggedIn();
              //window.location.href = '/manage-student';
            } else if (
              (role.role_code === "ASAD" ||
              role.role_code === "ASLE" ||
              role.role_code === "ASSR" ||
              role.role_code === "CASS" ||
              role.role_code === "CPRC" ||
              role.role_code === "MNGS" ||
              role.role_code === "PRAD" ||
              role.role_code === "PRCR" ||
              role.role_code === "PRMN") && userTypeSelected === "teacher"
            ) {
              this.userType = "teacher";
              this.loggedIn();
            } else {
              sweetStatusAlert(
                "Failed",
                "We coudnt able to decide your role !!! Please contact System Administrator",
                "error"
              ).then(() => {
                userActions.staffLogout();
                window.location.reload(true);
              });
              this.unabledLoggedIn();
            }
          } catch (error) {
            sweetStatusAlert(
              "Failed",
              "We coudnt able to decide your role !!! Please contact System Administrator",
              "error"
            ).then(() => {
              userActions.staffLogout();
              window.location.reload(true);
            });
            this.unabledLoggedIn(error);
          }
        } catch (error) {
          sweetStatusAlert(
            "Failed",
            "You are not a User !!! Please contact System Administrator",
            "error"
          ).then(() => {
            userActions.staffLogout();
            window.location.reload(true);
          });
          this.unabledLoggedIn(error);
        }
      }
    }
  }

  /*
   * Sets the user's agreement_status to 'true'
   */
  static AgreeToTerms = async (userType) => {
    try {
      let id = sessionStorage.getItem("user_id");
      if (userType === "student") {
        let responseSuccessful = await studentUserService.statusChangeTerms(id);
        return responseSuccessful;
      } else if (userType === "teacher" || userType === "admin") {
        let responseSuccessful = await StaffService.statusChangeTerms(id);
        return responseSuccessful;
      }
    } catch (error) {
      const userTypeSelected = sessionStorage.getItem("user_role_selection");
      if(userTypeSelected === "admin"){
        if(!isEdFoci){
          history.push(routes.admin_url);
        }
        else{
          history.push(routes.base_url);
        }
      }
      else if(userTypeSelected === "teacher"){
        if(!isEdFoci){
          history.push(routes.teacher_url);
        }
        else{
          history.push(routes.base_url);
        }
      }
      else{
        history.push(routes.base_url);
      }
      store.dispatch({
        type: TermsAgreementConstants.AGREEMENT_FAILURE,
        error,
      });
      return error;
    }
  };

  async silentRegister(newUser) {
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_first_name: newUser.givenName,
        user_last_name: newUser.surname,
        user_designation: newUser.jobTitle,
        user_email: newUser.userPrincipalName,
      }),
    };

    try {
      let response = await fetch(
        `${config.oat_api_url}/student/`,
        requestOptions
      );
      this.UMUserStudent = this.handleResponse(response);
      this.loggedIn();
    } catch (error) {
      history.push("/login");
      store.dispatch({
        type: authenticationConstants.LOGIN_FAILURE,
        error: error,
      });
      store.dispatch({
        type: alertConstants.ERROR,
        message: error,
      });
      return error;
    }
  }

  unabledLoggedIn(error) {
    const userTypeSelected = sessionStorage.getItem("user_role_selection");
    if(userTypeSelected === "admin"){
      if(!isEdFoci){
        history.push(routes.admin_url);
      }
      else{
        history.push(routes.base_url);
      }
    }
    else if(userTypeSelected === "teacher"){
      if(!isEdFoci){
        history.push(routes.teacher_url);
      }
      else{
        history.push(routes.base_url);
      }
    }
    else{
      history.push("/");
    }
    store.dispatch({
      type: authenticationConstants.LOGIN_FAILURE,
      error: error,
    });
    store.dispatch({
      type: alertConstants.ERROR,
      message: error,
    });
    return error;
  }

  loggedIn() {
    store.dispatch({
      type: loginLoaderConstants.SUCCESS,
    });
  }

  handleResponse(response) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        const error = (data && data.error) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  }
}

export { UMAuthAgent };
