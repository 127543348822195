import React from "react"
import {Dropdown} from "react-bootstrap";
import {TablePagination as Pagination} from "@mui/material";

export default function MaterialCustomTablePagination(props) {
    const {
        totalCount, 
        currentPage, 
        limit, 
        handlePageChange, 
        handleRowsPerPageChange, 
        handlePageOffsetChange, 
        offset,
        rowsPerPageOptions
    } = props
    return (
        <div 
            style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            whiteSpace: "no-wrap"
            }}
        >
            <Pagination
                component="div"
                count={totalCount}
                page={currentPage}
                rowsPerPage={limit}
                rowsPerPageOptions={rowsPerPageOptions}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
            <Dropdown data-bs-theme="dark">
            <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="light"
                style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontFamily: "Poppins, sans-serif",
                backgroundColor: "#fff",
                color: "#000000DE",
                fontSize: "10.5px",
                }}
            >
                {`Page ${offset / limit + 1} of ${Math.ceil(
                totalCount / limit
                )}`}
            </Dropdown.Toggle>

            <Dropdown.Menu
                style={{
                maxHeight: "190px",
                overflowY: "auto",
                width: "100%",
                color: "#000000DE",
                fontSize: "10.5px",
                }}
            >
                {Array.from(
                { length: Math.ceil(totalCount / limit) },
                (_, index) => (
                    <Dropdown.Item
                    key={index}
                    onClick={() =>
                        handlePageOffsetChange(
                        index * limit,
                        index
                        )
                    }
                    >
                    Page {index + 1}
                    </Dropdown.Item>
                )
                )}
            </Dropdown.Menu>
            </Dropdown>

        </div>
    )
}
