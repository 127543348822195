import React from "react";
import "../dashboard.css";
import config from "../../../../../helper/config.js";
import { customerConstants } from "../../../../../helper/constants.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  CircularProgress,
} from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import SavingsRoundedIcon from "@mui/icons-material/AccountBalanceWallet";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let iconColor;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)";
    break;
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)";
    break;
  default:
    iconColor = "var(--maincolor)";
    break;
}

const TotalOutStanding = ({ suitableArray, dashboardDataLoading }) => {
  return (
    <div className="col-md-12 dashboard__container mb-3">
      <h3 className="dashboard__container-title m-0 mt-3">
        <SavingsRoundedIcon sx={{ fontSize: 22, color: "#5c5c5c" }} /> Total
        Outstanding
      </h3>
      {dashboardDataLoading ? (
        <div className="d-flex justify-content-center my-3 py-3">
          <CircularProgress />
        </div>
      ) : (
        <div className="row d-flex dashboard__count-cards-purple align-items-center my-3 py-3">
          <div className="col-md-2 col-2 p-0 revenue__icon d-flex align-items-center">
            <SavingsRoundedIcon
              sx={{
                fontSize: 15,
                color: "#fff",
              }}
            />
          </div>
          <div className="col-md-10 col-10">
            <p className="revenue-text">{suitableArray[0]?.array[0].chart_x_value}</p>
            <h3 className="revenue-amount m-0">LKR {suitableArray[0]?.array[0].chart_y_value}</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default TotalOutStanding;
