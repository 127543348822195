import React, { useState } from "react";
import "./edsider/dashboard.css";
import Avatar from "@mui/material/Avatar";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton'; 
import CloseIcon from '@mui/icons-material/Close';
import TimeZoneHandler from "../../../handlers/TimeZoneHandler";
import Speaker from "../../../assets/images/loudspeaker.png";

// Modal styling
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #d4c11c',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
  padding: '16px',
  display: 'flex',
  justifyContent: 'center',
};

const TeacherAnnouncementCard = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function formatTimeDifference(dateString) {
    const currentDate = new Date();
    const providedDate = new Date(TimeZoneHandler.convertTimeByUserLocation(dateString));
    const timeDifferenceInMilliseconds = currentDate - providedDate;
    const secondsDifference = Math.floor(timeDifferenceInMilliseconds / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const weeksDifference = Math.floor(daysDifference / 7);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(daysDifference / 365);

    if (yearsDifference > 0) {
      return `${yearsDifference} ${yearsDifference === 1 ? "year" : "years"} ago`;
    } else if (monthsDifference > 0) {
      return `${monthsDifference} ${monthsDifference === 1 ? "month" : "months"} ago`;
    } else if (weeksDifference > 0) {
      return `${weeksDifference} ${weeksDifference === 1 ? "week" : "weeks"} ago`;
    } else if (daysDifference > 0) {
      return `${daysDifference} ${daysDifference === 1 ? "day" : "days"} ago`;
    } else if (hoursDifference > 0) {
      return `${hoursDifference} ${hoursDifference === 1 ? "hour" : "hours"} ago`;
    } else if (minutesDifference > 0) {
      return `${minutesDifference} ${minutesDifference === 1 ? "minute" : "minutes"} ago`;
    } else {
      return "Just now";
    }
  }

  return (
    <div>
      <div className="row announcement-card" onClick={handleOpen}>
        <div className="col-md-2 col-2 d-flex align-items-center">
          <Avatar
            sx={{ bgcolor: "#D9DEEF" }}
            alt={props.announcements?.announcement_title}
            src={props.announcements?.announcement_title}
          />
        </div>
        <div className="col-md-10 col-10 d-flex align-items-center">
          <div className="row d-flex col-md-12">
            <div className="col-md-12 announcement-title pb-2">
              {props.announcements?.announcement_title}
            </div>
            <div className="col-md-12">
              <div className="row d-flex justify-content-between px-0">
                <div className="col-md-6 col-6 px-0 announcement-content">
                  Posted by{" "}
                  <b>
                    {props.announcements?.create_user_table_id === "1" && "Admin"}
                    {props.announcements?.create_user_table_id === "3" && "Parent"}
                    {props.announcements?.create_user_table_id === "10" && "Teacher"}
                  </b>
                </div>
                <div className="col-md-6 col-6 px-0 announcement-content d-flex justify-content-end">
                  {formatTimeDifference(props.announcements?.created_at)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          {/* Close button positioned at the top right */}
          <IconButton 
            aria-label="close" 
            onClick={handleClose} 
            sx={{ 
              position: 'absolute', 
              top: 8, 
              right: 8 
            }}
          >
            <CloseIcon />
          </IconButton>
          
          <div className="col-lg-10 col-md-11 col-10 d-flex align-items-center">
            <div className="row d-flex w-100 justify-content-center">
              <div>
                <img src={Speaker} style={{width:'70px', marginBottom:'8px'}}/>
              </div>
              {props.announcements?.announcement_title && (                
                <div className="col-md-12 announcement-title pb-2 d-flex justify-content-center">
                   Title - {props.announcements.announcement_title}
                </div>                
              )}
              {props.announcements?.name && (
                <div className="col-md-12 annTopic d-flex justify-content-center">
                  Topic name - {props.announcements.name}
                </div>
              )}
              {props.announcements?.announcement_body && (
                <div className="col-md-12 d-flex justify-content-center anncont">
                  Content - {props.announcements.announcement_body}
                </div>
              )}
              <div className="col-md-12">
                <div className="row d-flex justify-content-between px-0">
                  <div className="col-md-7 px-0 announcement-content">
                    {props.announcements?.create_user_table_id && (
                      <>
                        Posted by{" "}
                        <b>
                          {props.announcements.create_user_table_id === "1" && "Admin"}
                          {props.announcements.create_user_table_id === "3" && "Parent"}
                          {props.announcements.create_user_table_id === "10" && "Teacher"}
                        </b>
                      </>
                    )}
                  </div>
                  <div className="col-md-5 px-0 announcement-content d-flex justify-content-end">
                    {props.announcements?.created_at && formatTimeDifference(props.announcements.created_at)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TeacherAnnouncementCard;
