import {mcqTypes} from '../types';

const initialState = {
    question_id: '',
    loading: false,
    success: '',
    error: '',
    text: ''
}

export function mcqReducer(state = initialState, action){
    switch (action.type){
        case mcqTypes.MCQ_PAGE_REFRESH:
            return {
                ...state,
                question_id: '',
                loading: false,
                success: '',
                error: '',
                text: ''
            }
        case mcqTypes.CREATE_MCQ_QUESTION:
            return {
                ...state,
                loading: true,
                text: 'Creating MCQ Question'
            }
        case mcqTypes.CREATE_MCQ_QUESTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case mcqTypes.CREATE_MCQ_QUESTION_SUCCESS:
            return {
                ...state,
                question_id: action.question.question_id,
                loading: false,
                success: 'Question and answer added successfully'
            }
        case mcqTypes.CREATE_MCQ_QUESTION_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case mcqTypes.UPDATE_MCQ_QUESTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case mcqTypes.UPDATE_MCQ_QUESTION_SUCCESS:
            return {
                ...state,
                question_id: action.question.question_id,
                loading: false,
                success: 'Question and answer updated successfully'
            }
        case mcqTypes.UPDATE_MCQ_QUESTION_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}
