import React from 'react';

export default function LoginContainer ({children}) {

    return (

        <div className="login-container order-2 order-lg-1 d-flex flex-center flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white">
            {/* begin::Wrapper */}
            <div className="login-content student-login d-flex flex-column pt-lg-0 pt-12">
                {/* begin::Logo */}
                <a href="https://www.edsider.com/" className="login-logo pb-xl-10 pb-8 text-center">
                    <img 
                        src="../assets/media/logos/edsiderlogo.png" 
                        className="max-h-100px" 
                        alt=""
                    />
                </a>
                {/* end::Logo */}

                {children}

            </div>
            {/* end::Wrapper */}
        </div>

    )
}