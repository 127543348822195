import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const questionLanguageService = {
    getAllQuestionLanguages,
    createLanguage,
    deleteLanguage,
    updateLanguage
};

async function getAllQuestionLanguages(limit, offset, sort_column, sort_direction, search_term) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/languages/getAllLanguages/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}`, requestOptions)
        .then(APIHandler.handleResponse);
}

async function createLanguage(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/manage/languages/add/`, requestOptions).then(APIHandler.handleResponse);
}

async function deleteLanguage(obj) {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(obj)
        };
        return await fetch(`${config.oat_api_url}/manage/languages/delete/${obj.language_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function updateLanguage(obj) {
    try {
        let requestBody = {
            language: obj.language.toString()
        }
        const requestOptions = {
            method: 'PUT',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(requestBody)
        };

        let data = await fetch(`${config.oat_api_url}/manage/languages/update/${obj.language_id}`, requestOptions);

        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}
