import { testConstants } from "../../helper/constants";

const initialState = {
  loading: false,
  error: "",
  success: "",
  text: "",
  questions: [],
  assessmentQuestions: [],
  question: "",
  questionContent: "",
  candidate: "",
  candidateAnswers: [],
  candidateAnswerMap: {},
  candidateAnswerContents: [],
  answerContents: [],
  candidateAnswerContentMap: {},
  auto_shuffle: false,
  // questionContents: []
};

// function to shuffle questions
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default function (state = initialState, action) {
  switch (action.type) {
    case testConstants.LOAD_EXAM_QUESTIONS_REFRESH:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
        text: "",
      };
    case testConstants.LOAD_EXAM_QUESTIONS:
      return {
        ...state,
        text: "Loading exam questions!",
        loading: true,
      };
    case testConstants.LOAD_EXAM_QUESTIONS_REQUEST:
      return {
        ...state,
        text: "test",
        loading: true,
      };
    case testConstants.LOAD_EXAM_QUESTIONS_SUCCESS:
      let all_questions;
      try {
        all_questions = JSON.parse(action.data.examQuestions).questions;
      } catch (err) {
        all_questions = null;
      }
      return {
        ...state,
        text: "Questions loaded successfully!",
        success: action.data,
        loading: false,
        questions: all_questions,
      };
    case testConstants.LOAD_EXAM_QUESTIONS_FAILURE:
      return {
        ...state,
        text: "candidate validation failed",
        error: action.error,
        loading: false,
      };
    case testConstants.LOAD_ASSESSMENT_QUESTIONS:
      return {
        ...state,
        text: "Loading assessment questions!",
        loading: true,
      };
    case testConstants.LOAD_ASSESSMENT_QUESTIONS_REQUEST:
      return {
        ...state,
        text: "test",
        loading: true,
      };
    case testConstants.LOAD_ASSESSMENT_QUESTIONS_SUCCESS:
      let all_assessment_questions;
      try {
        all_assessment_questions =  JSON.parse(action.assessmentQuestions.examQuestions).questions;
      } catch (err) {
        all_assessment_questions = null;
      }
      return {
        ...state,
        text: "Questions loaded successfully!",
        success: action.assessmentQuestions,
        loading: false,
        assessmentQuestions: all_assessment_questions,
      };
    case testConstants.LOAD_ASSESSMENT_QUESTIONS_FAILURE:
      return {
        ...state,
        text: "candidate validation failed",
        error: action.error,
        loading: false,
      };
    case testConstants.VIEW_TEST:
      return {
        ...state,
        loading: true,
      };
    case testConstants.VIEW_TEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case testConstants.VIEW_TEST_SUCCESS:
      return {
        ...state,
        assessment: action.data.assessment,
        loading: false,
        success: action.data,
      };
    case testConstants.VIEW_TEST_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case testConstants.VIEW_TEST_REFRESH:
      return {
        ...state,
        assessment: action.assessment,
        loading: false,
      };
    case testConstants.GET_TEST_QUESTION_CONTENT:
      return {
        ...state,
        question: "",
        text: "Refresh!",
        loading: false,
        error: "",
        success: "",
        questionContent: "",
      };
    case testConstants.GET_TEST_QUESTION_CONTENT_REQUEST:
      return {
        ...state,
        question: action.question,
        text: "Obtain question!",
        loading: true,
      };
    case testConstants.GET_TEST_QUESTION_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Obtained question Successfully!",
        questionContent: action.data,
      };
    case testConstants.GET_TEST_QUESTION_CONTENT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case testConstants.GET_TEST_QUESTION_ANSWER_CONTENTS:
      return {
        ...state,
        text: "Obtain test question answers!",
        answerContents: [],
        loading: true,
      };
    case testConstants.GET_TEST_QUESTION_ANSWER_CONTENTS_REQUEST:
      return {
        ...state,
        text: action.id,
        loading: true,
      };
    case testConstants.GET_TEST_QUESTION_ANSWER_CONTENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Obtained answer contents successfully!",
        answerContents: action.answers,
      };
    case testConstants.GET_TEST_QUESTION_ANSWER_CONTENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case testConstants.GET_CANDIDATE_ANSWERS:
      return {
        ...state,
        candidateAnswers: [],
        candidateAnswerContents: [],
        candidate: action.candidate,
        loading: false,
        error: "",
        success: "",
        text: "",
      };
    case testConstants.GET_AUTO_SHUFFLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case testConstants.GET_AUTO_SHUFFLE_SUCCESS:
      let auto_shuffle_flag;
      try {
        auto_shuffle_flag = action.content.auto_shuffle; //getting auto_shuffle flag

      } catch (err) {
        auto_shuffle_flag = false;
      }
      return {
        ...state,
        loading: false,
        success: "Obtained auto shuffle successfully!",
        auto_shuffle: auto_shuffle_flag,
      };

    case testConstants.GET_AUTO_SHUFFLE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        success: "",
      };
    case testConstants.GET_CANDIDATE_ANSWERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case testConstants.GET_CANDIDATE_ANSWERS_SUCCESS:
      var answers = action.content.answers, i, answer;
      var answerMap = {};
      try {
        for (i in action.content.answers) {
          answer = action.content.answers[i];
          answerMap[answer.question_id] = answer;
        }
      } catch (err) {
        answers = [];
        answerMap = {};
      }
      return {
        ...state,
        loading: false,
        success: "Obtained candidate answers successfully!",
        candidateAnswers: answers,
        candidateAnswerMap: answerMap
      };
    case testConstants.GET_CANDIDATE_ANSWERS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        success: "",
      };
    case testConstants.CLEAR_CANDIDATE_ANSWERS:
      return {
        ...state,
        loading: false,
        answerContents: [],
        candidateAnswers: [],
        candidateAnswerContents: [],
        candidateAnswerContentMap:[],
      };
    case testConstants.GET_ALL_CANDIDATE_ANSWER_CONTENTS:
      return {
        ...state,
        text: "Obtain answers!",
        candidateAnswerContents: [],
        loading: true,
      };
    case testConstants.GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST:
      return {
        ...state,
        text: action.id,
        loading: true,
      };
    case testConstants.GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS:
      var answers = action.answers.answer,
        i,
        answer;
      var answerContentMap = {};
      try {
        for (i in answers) {
          answer = answers[i];
          answerContentMap[action.answers.candidate_answers[i].question_id] =
            answer;
        }
      } catch (err) {
        answers = [];
        answerContentMap = {};
      }
      return {
        ...state,
        loading: false,
        success: "Obtained candidate answers Successfully!",
        candidateAnswerContents: action.answers.answer,
        candidateAnswerContentMap: answerContentMap,
      };
    case testConstants.GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    // case testConstants.SUBMIT_ANSWER:
    //     return {
    //         ...state,
    //         loading: true
    //     }
    // case testConstants.SUBMIT_ANSWER_REQUEST:
    //     return {
    //         ...state,
    //         loading: true
    //     }
    // case testConstants.SUBMIT_ANSWER_SUCCESS:
    //     return {
    //         ...state,
    //         loading: false,
    //         success: 'Answer submitted successfully!'
    //     }
    // case testConstants.SUBMIT_ANSWER_FAILURE:
    //     return {
    //         ...state,
    //         error: action.error,
    //         loading: false
    //     }
    // case testConstants.SUBMIT_ANSWERS_FOR_PAGE:
    //     return {
    //         ...state,
    //         loading: true
    //     }
    // case testConstants.SUBMIT_ANSWERS_FOR_PAGE_REQUEST:
    //     return {
    //         ...state,
    //         loading: true
    //     }
    // case testConstants.SUBMIT_ANSWERS_FOR_PAGE_SUCCESS:
    //     return {
    //         ...state,
    //         loading: false,
    //         success: 'Answer submitted successfully!'
    //     }
    // case testConstants.SUBMIT_ANSWERS_FOR_PAGE_FAILURE:
    //     return {
    //         ...state,
    //         error: action.error,
    //         loading: false
    //     }
    // case testConstants.FINISH_TEST:
    //     return {
    //         ...state,
    //         text: 'Finishing Test',
    //         loading: true
    //     }
    // case testConstants.FINISH_TEST_REQUEST:
    //     return {
    //         ...state,
    //         loading: true
    //     }
    // case testConstants.FINISH_TEST_SUCCESS:
    //     return {
    //         ...state,
    //         loading: false,
    //         success: 'Test submitted Successfully!'
    //     }
    // case testConstants.FINISH_TEST_FAILURE:
    //     return {
    //         ...state,
    //         error: action.error,
    //         loading: false
    //     }
    default:
      return state;
  }
}
