import {quesLanguageTypes} from '../../../reducers/settings/settingsTypes'
import {questionLanguageService} from '../../../service/settings/question/languageService'


export const quesLanguageActions = {
    getAllQuestionLanguages,
    createLanguage,
    updateLanguage,
    deleteLanguage
}

function getAllQuestionLanguages(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let languages = await questionLanguageService.getAllQuestionLanguages(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(languages.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: quesLanguageTypes.GET_ALL_QUESTION_LANGUAGES}
    }

    function request() {
        return {type: quesLanguageTypes.GET_ALL_QUESTION_LANGUAGES_REQUEST}
    }

    function success(languages) {
        return {type: quesLanguageTypes.GET_ALL_QUESTION_LANGUAGES_SUCCESS, languages}
    }

    function failure(error) {
        return {type: quesLanguageTypes.GET_ALL_QUESTION_LANGUAGES_FAILURE, error}
    }
}


function createLanguage(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await questionLanguageService.createLanguage(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: quesLanguageTypes.CREATE_LANGUAGE_REQUEST, data}
    }

    function success(languages) {
        return {type: quesLanguageTypes.CREATE_LANGUAGE_SUCCESS, languages}
    }

    function failure(error) {
        return {type: quesLanguageTypes.CREATE_LANGUAGE_FAILURE, error}
    }
}


function deleteLanguage(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await questionLanguageService.deleteLanguage(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(subject) {
        return {type: quesLanguageTypes.DELETE_LANGUAGE_REQUEST, subject}
    }

    function success(success) {
        return {type: quesLanguageTypes.DELETE_LANGUAGE_SUCCESS, success}
    }

    function failure(error) {
        return {type: quesLanguageTypes.DELETE_LANGUAGE_FAILURE, error}
    }
}

function updateLanguage(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await questionLanguageService.updateLanguage(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: quesLanguageTypes.UPDATE_LANGUAGE_REQUEST}
    }

    function success(subject) {
        return {type: quesLanguageTypes.UPDATE_LANGUAGE_SUCCESS, subject}
    }

    function failure(error) {
        return {type: quesLanguageTypes.UPDATE_LANGUAGE_FAILURE, error}
    }
}
