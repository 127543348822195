import {combineReducers} from 'redux';
import {authLoginReducer} from './user_management/authLoginReducer';
import {myProfileReducer} from "./user_management/myProfileReducer";
import { alert } from './user_management/alertReducer';
import {userAgreementReducer} from "./user_management/userAgreementReducer";
import examReducer from './candidate/examReducer';
import testReducer from './candidate/testReducer';
import validationReducer from './candidate/validationReducer';
import submissionReducer from './candidate/submissionReducer';
import { submissionQuestionReducer } from './question/submissionQuestionReducer';
import testPageReducer from './candidate/testPageReducer';
import gradeReducer from './candidate/gradeReducer';
import { questionsCreateReducer } from './question/createReducer';
import questionReducer from './assessment/questionReducer';
import { manageUserReducer } from './user_management/manageUserReducer';
import {questionsLibraryReducer} from './question/libraryReducer';
import {descriptiveReducer} from './question/descriptiveReducer';
import {mcqReducer} from './question/mcqReducer';
import {shareReducer} from './question/shareReducer';
import {userReducer} from './user_management/userReducer';
import { staffUserReducer } from './user_management/staffUserReducer';
import { studentUserReducer } from './user_management/studentUserReducer';
import assessmentReducer from './assessment/assessmentReducer';
import {assessmentLibraryReducer} from './assessment/libraryReducer';
import assessmentCreateReducer from './assessment/createReducer';
import assessmentScheduleReducer from './assessment/assessmentScheduleReducer';
import candidateReducer from './candidate/candidateReducer';
import {editDetailsReducer,editScheduleReducer,editAssessorsReducer,editCandidatesReducer} from './assessment/editReducer';
import assessorReducer from './assessment/assessorReducer';

import {quesCatReducer} from "../reducers/settings/question/quesCatReducer"
import {authorReducer} from "../reducers/settings/question/authorReducer"
import {difficultyReducer} from "../reducers/settings/question/difficultyReducer"
import {languageReducer} from "../reducers/settings/question/languageReducer"
import {sourceReducer} from "../reducers/settings/question/sourceReducer"
import {subjectReducer} from "../reducers/settings/question/subjectReducer"
import {termReducer} from "../reducers/settings/question/termReducer"
import {typeReducer} from "../reducers/settings/question/typeReducer"
import {yearReducer} from "../reducers/settings/question/yearReducer"
import {chapterReducer as quesChapterReducer} from "../reducers/settings/question/chapterReducer"
import {gradeReducer as quesGradeReducer} from "../reducers/settings/question/gradeReducer"

import {categoryReducer as assesCategoryReducer} from "../reducers/settings/assessment/categoryReducer"
import {attemptReducer as assessAttemptReducer} from "../reducers/settings/assessment/attemptReducer"
import {authorReducer as assessAuthorReducer} from "../reducers/settings/assessment/authorReducer"
import {chapterReducer as assessChapterReducer} from "../reducers/settings/assessment/chapterReducer"
import {difficultyReducer as assessDifficultyReducer} from "../reducers/settings/assessment/difficultyReducer"
import {gradeReducer as assessGradeReducer} from "../reducers/settings/assessment/gradeReducer"
import {languageReducer as assessLanguageReducer} from "../reducers/settings/assessment/languageReducer"
import {subjectReducer as assessSubjectReducer} from "../reducers/settings/assessment/subjectReducer"
import {termReducer  as assessTermReducer} from "../reducers/settings/assessment/termReducer"
import {yearReducer  as assessYearReducer} from "../reducers/settings/assessment/yearReducer"

const rootReducer = combineReducers({
    authLoginReducer,
    myProfileReducer,
    alert,
    userAgreementReducer,
    examReducer,
    testReducer,
    validationReducer,
    submissionReducer,
    submissionQuestionReducer,
    testPageReducer,
    gradeReducer,
    questionsCreateReducer,
    questionReducer,
    manageUserReducer,
    descriptiveReducer,
    mcqReducer,
    shareReducer,
    questionsLibraryReducer,
    userReducer,
    staffUserReducer,
    studentUserReducer,
    assessmentReducer,
    assessmentLibraryReducer,
    assessmentCreateReducer,
    assessmentScheduleReducer,
    
    candidateReducer,
    editDetailsReducer,
    editScheduleReducer,
    editAssessorsReducer,
    editCandidatesReducer,
    assessorReducer,

    quesCatReducer,
    authorReducer,
    difficultyReducer,
    languageReducer,
    sourceReducer,
    subjectReducer,
    termReducer,
    typeReducer,
    yearReducer,
    quesGradeReducer,
    quesChapterReducer,

    assesCategoryReducer,
    assessAttemptReducer,
    assessAuthorReducer,
    assessChapterReducer,
    assessDifficultyReducer,
    assessGradeReducer,
    assessLanguageReducer,
    assessSubjectReducer,
    assessTermReducer,
    assessYearReducer
});

export default rootReducer;