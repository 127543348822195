import React, { Component } from "react";
import ContentWrapper from "../../../../components/common/ContentWrapper.js";
import AdminTemplate from "../../../../components/admin/layouts/Template.js";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../../components/common/spinner/PageSpinner.js";
import Card from "../../../../components/common/Card.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardBody from "../../../../components/common/CardBody.js";
import CardTitle from "../../../../components/common/CardTitle.js";
import debounce from "lodash.debounce";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Button2 from "@mui/material/Button";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  TablePagination as Pagination,
} from "@mui/material";
import "../../../../components/admin/css/manage-table-title.css";
import "../../../../components/admin/css/common.css";
import { RequestService } from "../../../../service/manage/registration/request_form.service.js";
import {
  sweetApproveAlert,
  sweetConfirmAlert,
  sweetStatusAlert,
} from "../../../../components/common/SweetAlert.js";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import config from "../../../../helper/config.js";
import { customerConstants } from "../../../../helper/constants.js";
import { GradeService } from "../../../../service/settings/manage/grade.service.js";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { toInteger } from "lodash";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

class StudentRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: false,
      isLoading: false,
      totalRequests: 10,
      currentPage: 0,
      limit: 100, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      requests: [],
      showViewModal: false,
      invitation_id: "",
      first_name: "",
      last_name: "",
      lname: "",
      grade: "",
      child_email: "",
      school: "",
      parent_fname: "",
      parent_lname: "",
      phone_number: "",
      parent_email: "",
      invitation_status: "",
      lg_user_table_id: "",
      lg_user_id: "",
      sortColumn: "invitation_id",
      sortDirection: "desc",
      filter_by: null,
      tabValue: 0,
      grades:[],
      isGradeLoading:false,
    };
    // Debounced version of handleSearch function
    this.debouncedHandleSearch = debounce(this.fetchRequestData, 2000);
  }

  componentDidMount() {
    this.setState(
      {
        isTableDataLoading: true,
        lg_user_table_id: sessionStorage.getItem("table_log_id"),
        lg_user_id: sessionStorage.getItem("manage_user_id"),
      },
      () => {
        this.fetchRequestData();
        this.getAllGrades();
      }
    );
  }

  fetchRequestData = async () => {
    this.setState({ isTableDataLoading: true });
    const { limit, offset, search_term, sortColumn, sortDirection, filter_by } =
      this.state;
    await RequestService.getAllByPagination(
      limit,
      offset,
      search_term,
      sortDirection,
      sortColumn,
      filter_by
    )
      .then((response) => {
        this.setState({
          requests: response.data.student_invitations,
          totalRequests: parseInt(response.data.count),
        });
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  getAllGrades=()=>{
    this.setState({ isGradeLoading: true });
    GradeService.getGrades()
        .then((response)=>{
            if(response.success){
                this.setState({grades:response.data, isGradeLoading:false})
            }
            else{
                this.setState({ isGradeLoading: false });
            }
            
        })
  }

  handleSortChange = (sortColumn) => {
    // If the same column is clicked, toggle the sort direction
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.fetchRequestData();
      }
    );
  };

  handleRequest = async (invitation_id) => {
    let alertText = "";
    alertText =
      "Invitation will approve and send verification emails to Student and Parent!";

    sweetApproveAlert("Are you sure?", alertText, "warning").then(async () => {
      this.setState({ isLoading: true });
      await RequestService.approveRequest(
        invitation_id,
        this.state.lg_user_id,
        this.state.lg_user_table_id
      )
        .then((response) => {
          sweetStatusAlert("Success!", "Approved", "success");
          this.fetchRequestData();
          this.setState({
            isLoading: false,
            showViewModal: !this.state.showViewModal,
          });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed !", "Unable to approve request", "error");
        });
    });
  };

  handleRejectRequest = async (invitation_id) => {
    const alertText =
      "Invitation will reject and send emails to both Student and Parent";
    sweetApproveAlert("Are you sure?", alertText, "warning").then(async () => {
      this.setState({ isLoading: true });
      await RequestService.rejectRequest(
        invitation_id,
        this.state.lg_user_id,
        this.state.lg_user_table_id
      )
        .then((response) => {
          sweetStatusAlert("Success!", "Rejected", "success");
          this.fetchRequestData();
          this.setState({
            isLoading: false,
            showViewModal: !this.state.showViewModal,
          });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed !", "Unable to reject request", "error");
        });
    });
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchRequestData();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchRequestData();
      }
    );
  };


  // handle search text as per the regex validation and search_term , and fetch data as per the debounce
  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  toggleViewInvitationModal = (requestsDetails) => {
    this.setState(
      (prevState) => ({
        showViewModal: !prevState.showViewModal,
        invitation_id: "",
        first_name: "",
        last_name: "",
        grade: "",
        child_email: "",
        school: "",
        parent_fname: "",
        parent_lname: "",
        phone_number: "",
        parent_email: "",
        invitation_status: "",
      }),
      async () => {
        if (this.state.showViewModal) {
          this.setState({
            invitation_id: requestsDetails.invitation_id
              ? requestsDetails.invitation_id
              : "",
            first_name: requestsDetails.first_name
              ? requestsDetails.first_name
              : "",
            last_name: requestsDetails.last_name
              ? requestsDetails.last_name
              : "",
            grade: requestsDetails.grade ? requestsDetails.grade : "",
            child_email: requestsDetails.child_email
              ? requestsDetails.child_email
              : "",
            school: requestsDetails.school ? requestsDetails.school : "",
            parent_fname: requestsDetails.parent_fname
              ? requestsDetails.parent_fname
              : "",
            parent_lname: requestsDetails.parent_lname
              ? requestsDetails.parent_lname
              : "",
            phone_number: requestsDetails.phone_number
              ? requestsDetails.phone_number
              : "",
            parent_email: requestsDetails.parent_email
              ? requestsDetails.parent_email
              : "",
            invitation_status: requestsDetails.invitation_status.toString()
              ? requestsDetails.invitation_status
              : "",
          });
        }
      }
    );
  };

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue }, () => {
      const { tabValue } = this.state;

      let newValue = null;
      if (tabValue === 1) {
        newValue = 0;
      } else if (tabValue === 2) {
        newValue = 1;
      } else if (tabValue === 3) {
        newValue = 2;
      } else if (tabValue === 4) {
        newValue = 3;
      }

      this.setState({ filter_by: newValue }, () => {
        this.fetchRequestData();
      });
    });
  };

  
  findGrade = (grade_id) =>{

    const {grades}  =this.state
    if(grades && grades.length > 0){

      const studentGrade = grades.find((grade) => grade.grade_id === toInteger(grade_id));

      return studentGrade? String(studentGrade.grade) : "N/A"
    }
    else{
      return "N/A"
    }
  }

  renderInvitations = () => {
    const {
      totalRequests,
      currentPage,
      limit,
      isLoading,
      isTableDataLoading,
      requests,
    } = this.state;
    return (
      <>
        {isLoading && <PageSpinner id="show" text="Loading, Please wait!" />}
        {isTableDataLoading ? (
          <div className="loading-container">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : requests?.length > 0 ? (
          <div style={{ overflowX: "auto", height: "90vh"}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="thead"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleSortChange("invitation_id")}
                  >
                    No{" "}
                    {this.state.sortColumn === "invitation_id" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("first_name")}
                  >
                    Student First Name{" "}
                    {/* {this.state.sortColumn === "first_name" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                  </TableCell>
                  <TableCell
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("last_name")}
                  >
                    Student Last Name{" "}
                    {/* {this.state.sortColumn === "last_name" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                  </TableCell>

                  <TableCell
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("grade")}
                  >
                    Student Grade{" "}
                    {/* {this.state.sortColumn === "grade" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                  </TableCell>
                  <TableCell
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("child_email")}
                  >
                    Student Email{" "}
                    {/* {this.state.sortColumn === "child_email" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                  </TableCell>
                  <TableCell
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("school")}
                  >
                    School{" "}
                    {/* {this.state.sortColumn === "school" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                  </TableCell>
                  <TableCell 
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("student_status")}
                  >
                    Students Status{" "}
                    {/* {this.state.sortColumn === "student_status" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                      
                    </TableCell>
                  <TableCell
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("parent_fname")}
                  >
                    Parent First Name{" "}
                    {/* {this.state.sortColumn === "parent_fname" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                  </TableCell>
                  <TableCell
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("parent_lname")}
                  >
                    Parent Last Name{" "}
                    {/* {this.state.sortColumn === "parent_lname" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                  </TableCell>
                  <TableCell
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("parent_email")}
                  >
                    Parent Email{" "}
                    {/* {this.state.sortColumn === "parent_email" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                  </TableCell>
                  <TableCell
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("phone_number")}
                  >
                    Parent Number{" "}
                    {/* {this.state.sortColumn === "phone_number" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                  </TableCell>
                  {/* <TableCell className="thead">Status</TableCell>
                          <TableCell className="thead">Actions</TableCell> */}
                  <TableCell 
                    className="thead"
                    // style={{ cursor: "pointer" }}
                    // onClick={() => this.handleSortChange("parent_status")}
                  >
                    Parent Status{" "}
                    {/* {this.state.sortColumn === "parent_status" && (
                      <span>
                        {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )} */}
                        
                    </TableCell>
                  <TableCell
                      className="thead"
                      // style={{ cursor: "pointer" }}
                      // onClick={() => this.handleSortChange("process_status")}
                    >
                      Process Status{" "}
                      {/* {this.state.sortColumn === "process_status" && (
                        <span>
                          {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                        </span>
                      )} */}
                    
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {requests?.map((requestsDetails, index) => (
                  <TableRow key={index}>
                    <TableCell className="tData">
                      {requestsDetails?.main_invitation_id}
                    </TableCell>
                    <TableCell className="tData">
                      {requestsDetails?.main_first_name?requestsDetails.main_first_name : requestsDetails.reference_first_name?requestsDetails.reference_first_name:"N/A" || "N/A"}
                    </TableCell>
                    <TableCell className="tData">
                      {requestsDetails?.main_last_name?requestsDetails.main_last_name : requestsDetails.reference_last_name?requestsDetails.reference_last_name:"N/A" || "N/A"}
                    </TableCell>
                    <TableCell className="tData">
                      {this.findGrade(requestsDetails?.main_grade?requestsDetails.main_grade : requestsDetails.reference_grade?requestsDetails.reference_grade :"N/A" ) || "N/A"}
                    </TableCell>
                    <TableCell className="tData">
                      {requestsDetails?.main_child_email?requestsDetails.main_child_email : requestsDetails.reference_child_email?requestsDetails.reference_child_email:"N/A" || "N/A"}
                    </TableCell>
                    <TableCell className="tData">
                      {requestsDetails?.main_school?requestsDetails.main_school : requestsDetails.reference_school?requestsDetails.reference_school:"N/A" || "N/A"}
                    </TableCell>
                    <TableCell className="tData">
                      <Typography>
                      {requestsDetails.main_record_type === "student" ? (
                        requestsDetails.main_request_status === "Pending" ? (
                          <Chip label="Pending" color="primary" />
                        ) : requestsDetails.main_request_status === "Approved" ? (
                          <Chip label="Approved" color="success" />
                        ) : requestsDetails.main_request_status === "Rejected" ? (
                          <Chip label="Rejected" color="error" variant="outlined" />
                        ) : "N/A"
                      ):(
                        
                          (requestsDetails.reference_record_type === "student" ? (
                            requestsDetails.reference_request_status === "Pending" ? (
                              <Chip label="Pending" color="primary" />
                            ) : requestsDetails.reference_request_status === "Approved" ? (
                              <Chip label="Approved" color="success" />
                            ) : requestsDetails.reference_request_status === "Rejected" ? (
                              <Chip label="Rejected" color="error" variant="outlined" />
                            ) : 
                            (
                            "N/A"
                            )
                          ):"N/A"
                        )
                      )}
                      </Typography>
                    </TableCell>

                    <TableCell className="tData">
                      {requestsDetails?.main_parent_fname?requestsDetails.main_parent_fname : requestsDetails.reference_parent_fname?requestsDetails.reference_parent_fname:"N/A" || "N/A"}
                    </TableCell>
                    <TableCell className="tData">
                      {requestsDetails?.main_parent_lname?requestsDetails.main_parent_lname : requestsDetails.reference_parent_lname?requestsDetails.reference_parent_lname:"N/A" || "N/A"}
                    </TableCell>
                    <TableCell className="tData">
                      {requestsDetails?.main_parent_email?requestsDetails.main_parent_email : requestsDetails.reference_parent_email?requestsDetails.reference_parent_email:"N/A" || "N/A"}
                    </TableCell>
                    <TableCell className="tData">
                      {requestsDetails?.main_phone_number?requestsDetails.main_phone_number : requestsDetails.reference_phone_number?requestsDetails.reference_phone_number:"N/A" || "N/A"}
                    </TableCell>
                    <TableCell className="tData">
                      <Typography>
                      {requestsDetails.main_record_type === "parent" ? (
                        requestsDetails.main_request_status === "Pending" ? (
                          <Chip label="Pending" color="primary" />
                        ) : requestsDetails.main_request_status === "Approved" ? (
                          <Chip label="Approved" color="success" />
                        ) : requestsDetails.main_request_status === "Rejected" ? (
                          <Chip label="Rejected" color="error" variant="outlined" />
                        ) : "N/A"
                      ):(
                        
                          (requestsDetails.reference_record_type === "parent" ? (
                            requestsDetails.reference_request_status === "Pending" ? (
                              <Chip label="Pending" color="primary" />
                            ) : requestsDetails.reference_request_status === "Approved" ? (
                              <Chip label="Approved" color="success" />
                            ) : requestsDetails.reference_request_status === "Rejected" ? (
                              <Chip label="Rejected" color="error" variant="outlined" />
                            ) : 
                            (
                            "N/A"
                            )
                          ):"N/A"
                        )
                      )}
                      </Typography>
                    </TableCell>
                    <TableCell className="tData">
                                {requestsDetails?.main_record_status === "Expired" && requestsDetails?.reference_record_status === "Expired" ? (
                                    <Chip label="Expired" color="error" />
                                ) : (
                                    !requestsDetails?.reference_record_status && requestsDetails?.main_record_status === "Expired"?
                                    (
                                        <Chip label="Expired" color="error" />
                                    )
                                    :
                                    (
                                        <>
                                            {/* <Tooltip
                                                PopperProps={{
                                                disablePortal: true,
                                                }}
                                                onClose={this.handleTooltipClose}
                                                open={typeof selectedIndex === "number" && selectedIndex === index}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Pending Process"
                                                arrow
                                                placement="right"
                                            > */}
                                            <Chip label="Pending" color="warning" />
                                            {/* </Tooltip> */}
                                        </>
                                    )
                                    
                                )}
                            </TableCell>
                    {/* 
                            <TableCell>
                              <div className="btn-group">
                                <Button
                                  onClick={() =>
                                    this.toggleViewInvitationModal(
                                      requestsDetails
                                    )
                                  }
                                >
                                  View
                                </Button>
                              </div>
                            </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Pagination
              component="div"
              count={totalRequests}
              page={currentPage}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              onPageChange={this.handlePageChange}
              onRowsPerPageChange={this.handleRowsPerPageChange}
            />
          </div>
        ) : (
          <div className="text-center mb-5 mt-10" style={{ height: "90vh" }}>
            <label>No Requests Available!</label>
          </div>
        )}
      </>
    );
  };


  render() {
    const {
      totalRequests,
      currentPage,
      limit,
      isLoading,
      isTableDataLoading,
      requests,
      showViewModal,
      first_name,
      last_name,
      grade,
      child_email,
      school,
      parent_fname,
      parent_lname,
      phone_number,
      parent_email,
      invitation_status,
      invitation_id,
      tabValue,
    } = this.state;
    return (
      <>
        <AdminTemplate>
          <ContentWrapper>
            <Card>
              <CardHeader>
                <CardTitle cardLabel="Manage Requests " />
              </CardHeader>
              <CardBody>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TextField
                    className="mb-2"
                    label="Search"
                    onChange={this.handleSearch}
                    error={this.state.searchError}
                    helperText={this.state.searchError ? "Invalid input" : ""}
                  />
                  <div
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <Tabs
                      value={tabValue}
                      onChange={this.handleTabChange}
                      aria-label="basic tabs example"
                      sx={{
                        "& .MuiTab-root": {
                          fontFamily: "Poppins, sans-serif",
                          textTransform: "unset",
                          letterSpacing: "-0.4px",
                          fontWeight: "600",
                        },
                        "& .Mui-selected": {
                          color: "var(--maincolor) !important",
                        },
                      }}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: "var(--maincolor)",
                        },
                      }}
                    >
                      {/* <Tab label="All" {...a11yProps(0)} /> */}
                      {/* <Tab label="Pending" {...a11yProps(1)} />
                      <Tab label="Approved" {...a11yProps(2)} />
                      <Tab label="Rejected" {...a11yProps(3)} />
                      <Tab label="Registered" {...a11yProps(3)} /> */}
                    </Tabs>
                  </div>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                  {this.renderInvitations()}
                </CustomTabPanel>
                {/* <CustomTabPanel value={tabValue} index={1}>
                  {this.renderInvitations()}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                  {this.renderInvitations()}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={3}>
                  {this.renderInvitations()}
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={4}>
                  {this.renderInvitations()}
                </CustomTabPanel> */}
              </CardBody>
            </Card>
          </ContentWrapper>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showViewModal}
            onHide={() => this.toggleViewInvitationModal()}
          >
            <Modal.Header closeButton>
              <Modal.Title>Student Invitation Details</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6}>
                  <Typography>
                    <strong>Student First Name:</strong>
                  </Typography>
                  <Typography>{first_name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Student Last Name:</strong>
                  </Typography>
                  <Typography>{last_name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Student Grade:</strong>
                  </Typography>
                  <Typography>{grade}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Student Email:</strong>
                  </Typography>
                  <Typography>{child_email}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>School:</strong>
                  </Typography>
                  <Typography>{school}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Parent First Name:</strong>
                  </Typography>
                  <Typography>{parent_fname}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Parent Last Name:</strong>
                  </Typography>
                  <Typography>{parent_lname}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Parent Number:</strong>
                  </Typography>
                  <Typography>{phone_number}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Parent Email:</strong>
                  </Typography>
                  <Typography>{parent_email}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <strong>Invitation Status</strong>
                  </Typography>
                  <Typography>
                    {invitation_status === 0 ? (
                      <Chip label="Pending" color="primary" />
                    ) : invitation_status === 1 ? (
                      <Chip label="Approved" color="success" />
                    ) : invitation_status === 2 ? (
                      <Chip label="Rejected" color="error" variant="outlined" />
                    ) : (
                      <Chip
                        label="Registered"
                        color="success"
                        variant="outlined"
                      />
                    )}
                  </Typography>
                </Grid>
              </Grid>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.toggleViewInvitationModal()}
                >
                  Close
                </Button>
                {/* {invitation_status === 0 && (
                  <div>
                    <Button2
                      variant="contained"
                      color="error"
                      onClick={() => this.handleRejectRequest(invitation_id)}
                      style={{ marginRight: "10px" }}
                    >
                      Reject
                    </Button2>
                    <Button2
                      variant="contained"
                      color="success"
                      onClick={() => this.handleRequest(invitation_id)}
                    >
                      Approve
                    </Button2>
                  </div>
                )} */}
              </Modal.Footer>
            </Modal.Body>
          </Modal>
        </AdminTemplate>
      </>
    );
  }
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default StudentRequest;
