import React, { useEffect } from 'react';

export default function FinalizeRightWrap ({ children }) {
    return (
        
        <div className="col-md-12 course-detail-wrap">
            {children}
        </div>
        

    )
}


