// import React, { useEffect } from 'react';

// import Button from '../form/Button';

// export default function FinalizeLeftWrap ({ children }) {
//     return (
        
//         <div className="col-4">
//             <div className="card card-custom card-fit card-border p-5">

//                 <div className="row">
//                     <div className="col-md-12 text-left mb-5">
//                         <Button className="btn btn-primary" btnName="Apply Position change" />
//                     </div>
//                 </div>

//                 <div data-scroll="true" data-height="655">
//                     <ul className="nav flex-column nav-pills">
//                         {children}
//                     </ul>
//                 </div>

//             </div>
//         </div>

//     )
// }

import React, { useEffect } from 'react';

import Button from '../../../components/common/form/Button';

export default function FinalizeLeftWrap ({ children, onClick, finalizeView, disabled }) {
    return (
        
        <div className="col-4 course-title-wrap">
            <div className="card card-custom card-fit card-border p-5">

                { finalizeView && <div className="row">
                    <div className="col-md-12 text-left mb-5">
                        <Button className="btn btn-primary" btnName="Apply Position change" onClick={onClick} />
                    </div>
                </div>}

                {/* <div data-scroll="true" data-height="655"> */}
                    <div className="scroll-wrap">
                        <ul className="nav flex-column nav-pills">
                            {children}
                        </ul>
                    </div>
                {/* </div> */}

            </div>
        </div>

    )
}



