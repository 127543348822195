import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const CombankService = {
    getSessionID,
    retrieveOrder
};

async function getSessionID(combank_obj) {
    const requestOptions = {
        method: "POST",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: combank_obj,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/combank-pg/getSessionId`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function retrieveOrder(session_id) {
    const requestOptions = {
        method: "GET",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/combank-pg/retrieve-order/${session_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}