import { AzureStorage } from "../../helper/azureStorage";
import DataHandler from "../../handlers/DataHandler";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";
import { questionTypeConstants } from "../../helper/constants";

export const questionService = {
  getQuestions: getQuestions,
  getAllAddQuestions: getAllAddQuestions,
  getQuestionContent: getQuestionContent,
  getQuestionGuidanceContent: getQuestionGuidanceContent,
  getAllQuestionContents: getAllQuestionContents,
  addQuestions: addQuestions,
  getAssessmentQuestions: getAssessmentQuestions,
  getAllAssessmentQuestions: getAllAssessmentQuestions,
  getSetQuestion: getSetQuestion,
  getQuestionsAddedByMe: getQuestionsAddedByMe,
  removeQuestions: removeQuestions,
  saveUpdates: saveUpdates,
  getCandidateAnswers: getCandidateAnswers,
  getAllAnswerContents: getAllAnswerContents,
  getAllCandidateAnswerContents: getAllCandidateAnswerContents,
  getAllQuestionContentsByType: getAllQuestionContentsByType,
  saveMarks: saveMarks,
  getQuestionTypes: getQuestionTypes,
  getQuestionCategories: getQuestionCategories,
  getQuestionDifficulty: getQuestionDifficulty,
  getQuestionPrivilege, getQuestionPrivilege
};

async function getQuestions() {
  var user_id = sessionStorage.getItem("user_id");

  const requestOptions = {
    // mode: 'no-cors',
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/questions/getAll/CreatedAndShared/${user_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getAllAddQuestions(limit, offset, sort_column, sort_direction, search_term, category_id) {
  var user_id = sessionStorage.getItem("user_id");

  const requestOptions = {
    // mode: 'no-cors',
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/questions/getAllQuestions/CreatedAndShared/${user_id}?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&category_id=${category_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getQuestionsAddedByMe(assessment) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      assessment_id: assessment.assessment_id,
      user_id: assessment.user_role_id,
    }),
  };

  const response = await fetch(
    `${config.oat_api_url}/assessment/questions/getMyQuestions/`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function removeQuestions(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };

  return await fetch(
    `${config.oat_api_url}/assessment/questions/remove/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function saveUpdates(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };

  return await fetch(
    `${config.oat_api_url}/assessment/questions/saveUpdates/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function saveMarks(obj) {

  obj.assessor_remarks = JSON.stringify(obj.assessor_remarks);
  
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/answers/saveMarks/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function addQuestions(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };

  return await fetch(
    `${config.oat_api_url}/assessment/questions/addAll/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getAssessmentQuestions(assessment_id) {
  var user_id = localStorage.getItem("user_id");
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/assessment/questions/getAllQuestions/${assessment_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getAllAssessmentQuestions(assessment_id, limit, offset, sort_column, sort_direction, search_term, selectedCategories, selectedAuthors, selectedChapters,
  selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources, selectedTeachers) {
  var user_id = localStorage.getItem("user_id");
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/assessment/questions/getAllAssessmentQuestions/${assessment_id}?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTypes=${selectedTypes}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedSources=${selectedSources}&selectedTeachers=${selectedTeachers}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getSetQuestion(question_id) {
  var user_id = localStorage.getItem("user_id");
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/assessment/questions/SetQuestion/${question_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

function getCandidateAnswers(candidate_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return fetch(
    `${config.oat_api_url}/candidate/answers/getAnswers/${candidate_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getQuestionContent(obj) {
  if(obj.is_content_blob === "true"){
    const user_id = obj.created_by;
    const questionContainerName= config.storageBlobConfigs.containerName;
    const questionBlobName = "".concat(user_id, "-q-", obj.content);
    return await AzureStorage.getBlobContent(questionContainerName, questionBlobName);
  }
  else{
    return obj.content;
  }
}

async function getQuestionGuidanceContent(obj) {
  return await obj.guidence;
}

async function getAllQuestionContents(obj) {
  var promises = obj["questions"].map(async function (question) {
    if(question.is_content_blob === "true"){
      const user_id = question.created_by;
      const questionContainerName= config.storageBlobConfigs.containerName;
      const questionBlobName = "".concat(user_id, "-q-", question.content);
      return await AzureStorage.getBlobContent(questionContainerName, questionBlobName);
    }
    else{
      return question.content;
    }
  });

  return await Promise.all(promises).then(getContent);
}

async function getAllAnswerContents(obj) {
  var promises = obj["questions"].map(async function (question) {
    if (question.question_type === questionTypeConstants.MCQ.name) {
      return question.answer;
    } else if (question.question_type === questionTypeConstants.DESCRIPTIVE.name) {
      if(question.is_answer_blob === "true"){
        const user_id = question.created_by;
        const answerContainerName= config.storageBlobConfigs.containerName;
        const answerBlobName = "".concat(user_id, "-a-", question.answer);
        return await AzureStorage.getBlobContent(answerContainerName, answerBlobName);
      }
      else{
        return question.answer;
      }
    } else if (question.question_type === questionTypeConstants.SUBQUESTION.name && question.is_subquestion === "true") {
      if(question.is_answer_blob === "true"){
        const user_id = question.created_by;
        const answerContainerName = config.storageBlobConfigs.containerName;
        const answerBlobName = "".concat(user_id, "-a-", question.answer);
        return await AzureStorage.getBlobContent(answerContainerName, answerBlobName);
      }
      else{
        return question.answer;
      }
    }
    else if (question.question_type === questionTypeConstants.SUBMISSION.name) {
      if(question.is_answer_blob === "true"){
        const user_id = question.created_by;
        const answerContainerName = config.storageBlobConfigs.containerName;
        const answerBlobName = "".concat(user_id, "-a-", question.answer);
        return await AzureStorage.getBlobContent(answerContainerName, answerBlobName);
      }
      else{
        return question.answer;
      }
    }
  });

  return await Promise.all(promises).then(getContent);
}

async function getAllCandidateAnswerContents(obj) {
  var promises = obj["answers"].map(async function (answer) {
    if(answer.is_answer_content_blob === "true"){
      const student_user_id = DataHandler.getFromSession('studentUserID');
      const candidateAnswerContainerName= config.storageBlobConfigs.containerName;
      const candidateAnswerBlobName = "".concat(student_user_id, "-ca-", answer.answer_content);
      return await AzureStorage.getBlobContent(candidateAnswerContainerName, candidateAnswerBlobName);
    }
    else{
      return answer.answer_content;
    }
  });
  //   var promises = obj["answers"];
  return await Promise.all(promises).then(getContent);
}

//getting questions contents by the type
async function getAllQuestionContentsByType(obj) {
  // Create an empty object to store questions by type
  var questionsByType = {};

  // Iterate through each question in the object
  obj["questions"].forEach(function (question) {
    // If the question's type has not been added to the questionsByType object yet,
    // create an empty array for that type
    if (!(question.question_type in questionsByType)) {
      questionsByType[question.question_type] = [];
    }
    // Add the question's content to the appropriate type's array
    questionsByType[question.question_type].push(question);
  });

  // Create an empty object to store promises by type
  var promisesByType = {};

  // Iterate through each type of question
  for (var type in questionsByType) {
    // Get the contents (i.e. IDs) of all questions of this type
    var questions = questionsByType[type];

    // Create an array of promises to fetch the contents of each question
    promisesByType[type] = Promise.all(
      questions.map(async function (question) {
        if(question.is_content_blob === "true"){
          const user_id = question.created_by;
          const questionContainerName= config.storageBlobConfigs.containerName;
          const questionBlobName = "".concat(user_id, "-q-", question.content);
          return await AzureStorage.getBlobContent(questionContainerName, questionBlobName);
        }
        else{
          return question.content;
        }
      })
      // Once all promises have resolved, pass the array of contents to the getContent function
    ).then(getContent);
  }

  // Create an empty object to store all contents by type
  var allContentsByType = {};

  // Iterate through each type of question
  for (var type in promisesByType) {
    // Wait for the promise to resolve and add the results to the allContentsByType object
    allContentsByType[type] = await promisesByType[type];
  }

  // Return the object containing all contents by type
  return allContentsByType;
}

function getContent(snapshots) {
  try {
    var contents = [];
    snapshots.forEach(function (snapshot) {
      try {
        contents.push(snapshot.val());
      } catch (error) {
        contents.push(snapshot);
      }
    });
    return contents;
  } catch (error) {
    return Promise.reject(error);
  }
}

function getQuestionTypes() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT .name),
  };

  return fetch(`${config.oat_api_url}/question/types/getAll/`, requestOptions).then(
    APIHandler.handleResponse
  );
}

function getQuestionDifficulty() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return fetch(
    `${config.oat_api_url}/manage/difficulties/getAll/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

function getQuestionPrivilege() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return fetch(
    `${config.oat_api_url}/manage/privileges/getAll/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getQuestionCategories() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/question/categories/getAll/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}
