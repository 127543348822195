import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const StudentService = {
  create,
  update,
  getById,
  getByEncryptedId,
  searchStudentById,
  getAllStuRegWithPaginationAndSorting,
  getAllStudentsForParent,
  getCreateUserDetail,
  getreference,
  getAllStudentsByReferance,
  checkStudentStatus,
  getStudentbyEmail,
  create_cgc_student,
  getCgcStudentId,
  getAllCgcStudents,
  updateCgcStudent
};

async function create(registration) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: registration,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(student, studentId) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: student,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/update/${studentId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getById(registrationId) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/get/${registrationId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getByEncryptedId(registrationId) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/get-grade/${registrationId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function searchStudentById(id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/search-student/${id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllStudentsByReferance(
  ref_id,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/getAllStuRegWithRefPaginationAndSorting/${ref_id}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
  );
  return APIHandler.handleResponse(response);
}

async function getAllStudentsForParent(
  node_mid,
  form_list_id,
  login_user_id,
  login_user_table_id,
  userType
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/getAllStudentsForParent/${node_mid}/${form_list_id}?l_u_id=${login_user_id}&l_u_t_id=${login_user_table_id}&userType=${userType}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getCreateUserDetail(stu_id, stu_table_id, userType) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/getCreateUserDetail/${stu_id}/${stu_table_id}?type=${userType}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getreference(user_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/get-reference/${user_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


async function getAllStuRegWithPaginationAndSorting(
  stu_id,
  user_table_id,
  create_user_id,
  create_user_table_id,
  node_mid,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const login_user_id = sessionStorage.getItem("manage_user_id");
  const login_user_table_id = sessionStorage.getItem("table_log_id")
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/getAllStuRegWithPaginationAndSorting/${node_mid}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}&s_id=${stu_id}&u_t_id=${user_table_id}&cr_u_id=${create_user_id}&cr_u_t_id=${create_user_table_id}&lg_u_id=${login_user_id}&lg_u_t_id=${login_user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getStudentbyEmail(email) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/get-student-by-email/${email}`,
    requestOptions
  );
  const data = await response.json()
  return data;
}

async function checkStudentStatus(email) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/check-student-status/${email}`,
    requestOptions
  );
  const data = await response.json()
  return data;
}

async function create_cgc_student(registration) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: registration,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/cgc-add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getCgcStudentId() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/cgc-generate-new-student-id`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllCgcStudents(
  stu_id,
  user_table_id,
  create_user_id,
  create_user_table_id,
  node_mid,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const login_user_id = sessionStorage.getItem("manage_user_id");
  const login_user_table_id = sessionStorage.getItem("table_log_id")
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/getAllCgcStudents/${node_mid}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}&s_id=${stu_id}&u_t_id=${user_table_id}&cr_u_id=${create_user_id}&cr_u_t_id=${create_user_table_id}&lg_u_id=${login_user_id}&lg_u_t_id=${login_user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateCgcStudent(student, studentId) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: student,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/students/update-cgc-student/${studentId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

