import {quesSourceTypes} from '../settingsTypes';

const initialState = {
    allQuestionSources: [],
    loading: false,
    error: '',
    text: '',
    source:'',
    totalSourcesCount: 0,
    allSources:[],
    errorsourcedelete:false,
    loadingsourcedelete:false,
    successsourceupdate: false,
    loadingsourceupdate: false,
    errorsourceupdate:false,
    successsourcedelete:false,
    errorsourcecreate: '',
    successsourcecreate: false,
}

export function sourceReducer(state = initialState, action){
    switch (action.type){
        case quesSourceTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allQuestionSources: [],
                loading: false,
                error: '',
                text: '',
                source:'',
                totalSourcesCount:0,
                allSources:[],
                errorsourcedelete:false,
                loadingsourcedelete:false,
                successsourceupdate: false,
                loadingsourceupdate: false,
                errorsourceupdate:false,
                successsourcedelete:false,
                errorsourcecreate: '',
                successsourcecreate: false,
            }
        //Source retrieve request sending
        case quesSourceTypes.GET_ALL_QUESTION_SOURCES:
            return {
                ...state,
                loading: true,
                text: 'Get all the question sources'
            }
        case quesSourceTypes.GET_ALL_QUESTION_SOURCES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case quesSourceTypes.GET_ALL_QUESTION_SOURCES_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionSources: action.sources.sources,
                totalSourcesCount: action.sources.total_count
            }
        case quesSourceTypes.GET_ALL_QUESTION_SOURCES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Source creation request sending
        case quesSourceTypes.CREATE_SOURCE_REQUEST:
            return {
                ...state,
                errorsourcecreate: '',
                successsourcecreate: false,
                loading: true
            }
        case quesSourceTypes.CREATE_SOURCE_SUCCESS:
            var temp = state.allSources;
            temp.push(action.sources)
            return {
                ...state,
                allSources: temp,
                source: action.sources.source_id,
                successsourcecreate: true,
                loading: false
            }
        case quesSourceTypes.CREATE_SOURCE_FAILURE:
            return {
                ...state,
                errorsourcecreate: action.error,
                loading: false
            }  
        //Source deletion request sending   
        case quesSourceTypes.DELETE_SOURCE_REQUEST:
            return {
            ...state,
            errorsourcedelete: '',
            successsourcedelete: false,
            loading: true,
            };
        case quesSourceTypes.DELETE_SOURCE_SUCCESS:
            return {
            ...state,
            successsourcedelete: true,
            loading: false,
            };
        case quesSourceTypes.DELETE_SOURCE_FAILURE:
            return {
            ...state,
            errorsourcedelete: action.error,
            loading: false,
            };
        //Source updation request sending
        case quesSourceTypes.UPDATE_SOURCE_REQUEST:
            return {
            ...state,
            errorsourceupdate:'',
            successsourceupdate: false,
            loading: true,
            };
        case quesSourceTypes.UPDATE_SOURCE_SUCCESS:
            return {
            ...state,
            successsourceupdate: true,
            loading: false,
            };
        case quesSourceTypes.UPDATE_SOURCE_FAILURE:
            return {
            ...state,
            errorsourceupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
