import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {
    sweetConfirmAlert,
    sweetErrorAlert,
   sweetSuccessAlert
} from "../../../components/common/SweetAlert";
import Spinner from "react-bootstrap/Spinner";
import "../../../components/admin/css/common.css";
import SimpleReactValidator from "simple-react-validator";
import { history } from "../../../helper/history"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { AttendanceService } from "../../../service/manage/attendance/attendance.service";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListSubheader from '@mui/material/ListSubheader';
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";
import DatePicker from "react-datepicker";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

export default class extends Component {
    constructor(props) {
      super(props);
      this.createValidationsInstance();
      this.state = {
        mid:this.props.state?this.props.state.mid:null,
        form_display_title:this.props.state?this.props.state.form_display_title:null,
        privilege_view:this.props.state?this.props.state.privilege_view:null,
        privilege_add:this.props.state?this.props.state.privilege_add:null,
        breadcrumb:this.props.state?this.props.state.breadcrumb:null,
        showModal:false,
        attendance: [],
        students:[],
        attendanceSubmit:[],
        atten_description:"",
        search_term: "",
        checked:false,
        totalAttendance: 10,
        isTableDataLoading: true,
        currentPage: 0,
        isLoading: false,
        limit: 10, // Set the desired limit
        offset: 0, // Set the desired offset
        breadcrumb2:null,
        nodeTitle:null,
        atten_date:null,
        flg_list_active:null,
        select_all:false,
        sortColumn:undefined,
        sortDirection: "desc",
        addVisibility: false,
        editVisibility: false,
        user_list_restriction:false
      };
  
    }

    componentDidMount() {
        this.fetchAllAttendance();
        this.fetchStudentData();
        const {breadcrumb2, nodeTitle, flg_list_active} = this.props.state
        this.setState({
          breadcrumb2:breadcrumb2,
          nodeTitle,
          flg_list_active
        })

      }

    createValidationsInstance() {
        this.validator = new SimpleReactValidator();
    }

    fetchAllAttendance = () => {
        this.setState({ isTableDataLoading: true });
        const { limit, offset, search_term,
          sortColumn,
          sortDirection, } =this.state;

        const session_stu_id = sessionStorage.getItem("manage_user_id");
        
        const {mid, owner_restriction, lg_user_id, lg_user_table_id,  user_list_table_id, user_list_restriction} = this.props.state

        let create_user_id = (!!owner_restriction && lg_user_id) ? lg_user_id :null
        let create_user_table_id = (!!owner_restriction && lg_user_table_id) ? lg_user_table_id : null
        let user_table_id = (!!user_list_restriction && user_list_table_id) ? user_list_table_id : null
        let stu_id = (!!user_list_restriction&& session_stu_id) ? session_stu_id : null

      AttendanceService.getAllAttendanceLimit(mid,limit,offset, search_term,create_user_id,create_user_table_id,user_table_id,stu_id,sortColumn,sortDirection)
      .then((response)=>{
        if (response.success) {
            this.setState({
                attendance: response.data.attendance,
                totalAttendance: parseInt(response.data.count), // Update totalStudents with the correct count
              });
        }
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
    }

    fetchStudentData=()=>{
      const {mid, lg_user_table_id,form_list_id} = this.props.state
      const lg_user_id = sessionStorage.getItem("manage_user_id")
      AttendanceService.getAllEnrolledStudents(mid,form_list_id,lg_user_id,lg_user_table_id)
        .then((result)=>{
          if(result.success){
            const updatedStudentsArray = result.data.students.map(student => ({
              ...student,
              "status": false
            }));
            this.setState({students:updatedStudentsArray})
          }
          
        })  
    }

    resetStudentStatus = () => {
      const { students } = this.state;
      const updatedStudents = students.map(student => ({ ...student, status: false }));
      this.setState({ students: updatedStudents });
    };

    handlePageChange = (event, newPage) => {
        const { limit } = this.state;
        const newOffset = newPage * limit;
        this.setState(
          {
            offset: newOffset,
            isTableDataLoading: true,
            currentPage: newPage,
          },
          () => {
            this.fetchAllAttendance();
          }
        );
    };

     
    handlePageOffsetChange = (newOffset, newPage) => {
      this.setState(
        {
          offset: newOffset,
          isTableDataLoading: true,
          currentPage: newPage
        },
        () => {
          this.fetchAllAttendance();
        }
      );
    };
    
    // handle the data fetching as per the data per page limit gets changes
    handleRowsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        const newPage = Math.floor(this.state.offset / newLimit);
        this.setState(
          {
            limit: newLimit,
            offset: newLimit * newPage,
            isTableDataLoading: true,
            currentPage: newPage,
          },
          () => {
            this.fetchAllAttendance();
          }
        );
    };

    handleToggle = (index,check) => {
        const studentsCopy = this.state.students
        studentsCopy[index].status = !check;
        if(!check === false){
          this.setState({ select_all: false});
        }
        this.setState({ students: studentsCopy });
    };

    handleSortChange = (sortColumn) => {
      // If the same column is clicked, toggle the sort direction
      const { sortColumn: currentSortColumn, sortDirection } = this.state;
      const newSortDirection =
        currentSortColumn === sortColumn && sortDirection === "asc"
          ? "desc"
          : "asc";
  
      this.setState(
        {
          sortColumn,
          sortDirection: newSortDirection,
          offset: 0, // Reset offset when changing sorting
          currentPage: 0,
          isTableDataLoading: true,
        },
        () => {
          this.fetchAllAttendance();
        }
      );
    };
  

    
    handleAllSelect = (event) => {
      if(event.target.checked){
        this.setState({ students: this.state.students.map(student => ({ ...student, status: true }))  , select_all: true });        
      }else{
        this.setState({ students: this.state.students.map(student => ({ ...student, status: false }))  , select_all: false });      
      }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(() => ({ [name]: value }));
    };
    

    handleDatePickerChange = (value) => {
      const dateObject = value ? new Date(value): undefined;
      const year = dateObject && dateObject.getFullYear();
      const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
      const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
      
      const hours = dateObject && dateObject.getHours(); // Get the hours (0-23)
      const minutes = dateObject && dateObject.getMinutes(); // Get the minutes (0-59)
      const seconds = dateObject && dateObject.getSeconds(); // Get the seconds (0-59)
  
      const formattedDateString = dateObject && `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      this.setState(() => ({ atten_date: formattedDateString, isModified: true}));
    };

    toggleModal = (showModal)=>{
        this.validator.hideMessages();
        this.validator.purgeFields();
        this.resetStudentStatus();
        this.setState((prevState) => ({
            showModal: !prevState.showModal,
            atten_description:"",
            atten_date:null
          }));
    }
    

    handleSubmit=()=>{
      if (this.state.atten_description.length > 128) {
        sweetErrorAlert("Error", "Description text is too long. Please check it.", "Error");
        return;
      }
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
          } else {
            sweetConfirmAlert()
                .then(()=>{
                  const {mid, lg_user_id, lg_user_table_id} = this.props.state
                    this.setState({isLoading:true})

                    const updatedAttendanceSubmit = [...this.state.attendanceSubmit];
                    
                    for (let i = 0; i < this.state.students.length; i++) {
                    
                      const updatedStudent = {
                        atten_description: this.state.atten_description,
                        atten_date : this.state.atten_date,
                        node_mid: mid,
                        create_user_id: lg_user_id ? lg_user_id : sessionStorage.getItem("manage_user_id"),
                        create_user_table_id: lg_user_table_id ? lg_user_table_id : null,
                        user_table_id: this.state.students[i].user_table_id,
                        stu_id: this.state.students[i].user_id,
                        status: this.state.students[i].status,
                      };
                    
                      updatedAttendanceSubmit[i] = updatedStudent;
                    }
                    this.setState({
                      attendanceSubmit: updatedAttendanceSubmit,
                    });
                    
                    AttendanceService.add(this.state.attendanceSubmit)
                        .then((result)=>{
                            if(result.success){
                              this.setState({atten_description:"",atten_date:null})
                                this.fetchAllAttendance();
                                sweetSuccessAlert("Success","Attendance added successfully","success")
                                this.fetchStudentData();
                            }
                            else{
                                sweetErrorAlert("Error","Failed to add attendance")
                            }
                            this.resetStudentStatus();
                            this.setState({isLoading:false})
                            this.toggleModal();
                        })
                })
          }
        
    }

    handleSearch = (event) => {
        const search_term = event.target.value.trim();

          this.setState(
            {
              search_term,
              isTableDataLoading: true,
              offset: 0,
              currentPage: 0,
            },
            () => {
              this.fetchAllAttendance();
            }
          );
        
    
    };

    handleFilterAttendaceByDate = (value)=>{
      const dateObject = value ? new Date(value): undefined;
      const year = dateObject.getFullYear();
      const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
      const day = ("0" + dateObject.getDate()).slice(-2);
      const formattedDateString = `${year}-${month}-${day}`;
      if(dateObject){
        this.setState({ search_term: formattedDateString }, () => {
          this.fetchAllAttendance();
        });
      }
      else{
        this.setState({ search_term:"" }, () => {
          this.fetchAllAttendance();
        });
      }
      
    }

    resetDatePicker = () =>  {
      // Reset the date picker
      this.setState({ search_term: "" }, ()=>{
        this.fetchAllAttendance();
      });
    }
    
  formattedDateString = (value) =>{
    const dateObject = value ? new Date(value): undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject &&  ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString =  `${day}-${month}-${year}`
    return fromattedString;
  }
  formattedTimeString = (value) =>{
    const time = new Date(value);    
    const formattedTime = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    return formattedTime
  }

    render() {

        const {
            limit,
            attendance,
            totalAttendance,
            isTableDataLoading,
            currentPage,
            isLoading,
            students,
            form_display_title,
            privilege_add,
            breadcrumb2,
            nodeTitle,
            atten_date,
            flg_list_active,
            offset
          } = this.state
            const path = history.location.pathname
            const formattedBreadcrumb = breadcrumb2? breadcrumb2.replaceAll(">", " > ") : ""

        const CustomInput = ({ value, onClick }) => (
          <input
            className="py-2 form-control"
            onClick={onClick}
            value={value}
            readOnly
            placeholder="Date"
          />
        );

        return (
          <>
          <div style={{ height: "90vh", overflow: "auto" }}>
            <ContentWrapper disabled={path === "/manage-search"}>
              <div>
                {(privilege_add === 1 || this.props.state.path !== "node") && <CardHeader>
                  <div className="col-md-12 px-0 d-flex justify-content-end">
                    {this.props.state.path !== "node" && (
                      <p className="py-4" style={{ fontSize: "10.5625px", color: "#00000099" }}>
                        {formattedBreadcrumb ? `${formattedBreadcrumb && formattedBreadcrumb}` : ""}
                      </p>
                    )}

                    <div className="col-md-6 px-0 d-flex justify-content-end">
                      {privilege_add === 1 && (
                        <Button
                          className="insert-btn"
                          variant="primary"
                          onClick={() => this.toggleModal(this.showModal)}
                        >
                          Add Attendance
                        </Button>
                      )}
                    </div>
                  </div>
                </CardHeader>}
                <CardBody>
                  <div>
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-end">
                        <Form.Group
                          controlId="exampleForm.ControlInput1"
                          className="col-md-8 mb-2 px-0"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            onChange={this.handleSearch}
                            isInvalid={this.state.searchError}
                            className="search-bar"
                          />
                          {this.state.searchError && (
                            <Form.Control.Feedback type="invalid">
                              Invalid input
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-6 d-flex justify-content-end">
                        <div className="col-md-9 px-0">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label style={{ fontWeight: "bold", marginRight: "5px" }}>
                              Filter attendance
                            </Form.Label>
                            <DatePicker
                              name="atten_date"
                              selected={this.state.search_term ? new Date(this.state.search_term):undefined}
                              onChange={(e) =>
                                this.handleFilterAttendaceByDate(e)
                              }
                              dateFormat="dd/MM/yyyy" // Set custom date format
                              placeholderText="Date"
                              customInput = {<CustomInput/>}
                            />
                            <Button
                              className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                              title="Delete Feedback"
                              onClick={this.resetDatePicker}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isLoading && (
                    <PageSpinner id="show" text="Loading, Please wait!" />
                  )}
                  {isTableDataLoading ? (
                    <div className="loading-container">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : attendance.length > 0 ? (
                    <div>
                      <Table>
                        <TableHead>
                          <TableRow>
                          {!this.props.state?.user_list_restriction && 
                          <>
                            <TableCell
                              className="thead"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleSortChange("user_code")}
                            >
                              User Code{" "}
                              {this.state.sortColumn === "user_code" && (
                                <span>
                                  {this.state.sortDirection === "asc"
                                    ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                    : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                                </span>
                              )}
                            </TableCell>                            
                              <TableCell
                                className="thead"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.handleSortChange("full_name")}
                              >
                                Name{" "}
                                {this.state.sortColumn === "full_name" && (
                                  <span>
                                    {this.state.sortDirection === "asc"
                                      ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                      : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                                  </span>
                                )}
                              </TableCell>
                            </>
                            }                            
                            <TableCell
                              className="thead"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleSortChange("atten_date")}
                            >
                              Date & Time{" "}
                              {this.state.sortColumn === "atten_date" && (
                                <span>
                                  {this.state.sortDirection === "asc"
                                    ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                    : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                                </span>
                              )}
                            </TableCell>                            
                            <TableCell
                              className="thead"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleSortChange("atten_description")}
                            >
                              Description{" "}
                              {this.state.sortColumn === "atten_description" && (
                                <span>
                                  {this.state.sortDirection === "asc"
                                    ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                    : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                                </span>
                              )}
                            </TableCell>                            
                            <TableCell
                              className="thead"
                              style={{ cursor: "pointer" }}
                              onClick={() => this.handleSortChange("status")}
                            >
                              Status{" "}
                              {this.state.sortColumn === "status" && (
                                <span>
                                  {this.state.sortDirection === "asc"
                                    ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                    : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                                </span>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {attendance.map((attendanceItem) => (
                            <TableRow key={attendanceItem.id}>
                             {!this.props.state?.user_list_restriction && <TableCell className="tData">
                                {attendanceItem.user_code}
                              </TableCell>}
                             {!this.props.state?.user_list_restriction && <TableCell className="tData">
                                {attendanceItem.first_name +
                                  " " +
                                  attendanceItem.last_name}
                              </TableCell>}
                              <TableCell className="tData">
                                <div>{this.formattedDateString(attendanceItem.atten_date) ? this.formattedDateString(attendanceItem.atten_date): undefined}</div>
                                <div>{this.formattedTimeString(attendanceItem.atten_date) ? this.formattedTimeString(attendanceItem.atten_date): undefined}</div>
                              </TableCell>
                              <TableCell className="tData">
                                {attendanceItem.atten_description}
                              </TableCell>
                              <TableCell className="tData">
                                <span
                                  className={`stu-variant-class label label-lg label-light-${
                                    attendanceItem.status ? "success" : "danger"
                                  } label-inline`}
                                >
                                  {attendanceItem.status ? "Present" : "Absent"}
                                </span>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <MaterialCustomTablePagination
                        totalCount = {totalAttendance}
                        currentPage = {currentPage}
                        limit = {limit}
                        handlePageChange = {this.handlePageChange}
                        handleRowsPerPageChange = {this.handleRowsPerPageChange}
                        handlePageOffsetChange = {this.handlePageOffsetChange}
                        rowsPerPageOptions={[5, 10, 20]}
                        offset = {offset}
                      />
                    </div>
                  ) : (
                    <div className="text-center mb-5 mt-10">
                      <label>No Attendance Available!</label>
                    </div>
                  )}
                </CardBody>
              </div>
            </ContentWrapper>

            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.showModal}
              onHide={() => this.toggleModal(this.showModal)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Create New Attendance</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Description*</Form.Label>
                      <Form.Control
                        type="text"
                        name="atten_description"
                        placeholder="Enter Description"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "atten_description",
                        this.state.atten_description,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The description field is required",
                          },
                        }
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Date*</Form.Label>                      
                      <DatePicker
                        className="py-2 form-control" // Add Bootstrap form control class
                        selected={this.state.atten_date ? new Date(this.state.atten_date):undefined}
                        name="atten_date"
                        onChange={this.handleDatePickerChange}
                        dateFormat="dd-MM-yyyy h:mm aa" // Set custom date format
                        placeholderText="Date"
                        minDate={new Date()}                               showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        wrapperClassName="react-datePicker"
                      />
                      {this.validator.message(
                        "atten_date",
                        this.state.atten_date,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The date field is required",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>

                  <List
                    dense
                    sx={{
                      width: "100%",
                      // maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                  >
                    <ListSubheader className="py-3 px-2">
                      <FormGroup aria-label="position" row style={{ display: 'flex' }} >
                        <div className="col-md-6 col-6 px-0 stu-variant-class label label-lg label-light label-inline d-flex justify-content-between">
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox style ={{
                                  color: "#5458AF",
                                }}
                                  checked
                                  disabled
                              />
                            }
                            className="m-0"
                            // control={<Checkbox checked disabled/>}
                            label="Present"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="end"
                            control={
                              <Checkbox
                                style ={{
                                  color: "#5458AF",
                                }}
                                checked ={false}
                                disabled
                              />
                            }
                            className="m-0 pr-2"
                            // control={<Checkbox disabled/>}
                            label="Absent"
                            labelPlacement="end"
                          />
                        </div>
                        
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              style ={{
                                color: "#5458AF",
                              }}
                            />
                          }
                          className="col-md-6 col-6 m-0 px-0"
                          onChange={this.handleAllSelect}
                          label="Select All"
                          labelPlacement="start"
                          style={{ marginLeft: '10px' }}
                          checked={this.state.select_all}
                        />
                      </FormGroup>
                    </ListSubheader>

                    {!!students.length && flg_list_active ? (
                      students.map((value, index) => {
                        const dataConcatArray = value.data_concat //.split('/');

                        // const registrationCode = dataConcatArray[0].split(':')[1];
                        // const firstName = dataConcatArray[1].split(':')[1];
                        // const lastName = dataConcatArray[2] && dataConcatArray[2].split(':')[1];
                        // const enrollmentCode = dataConcatArray[3] && dataConcatArray[3].split(':')[1];

                        return (
                          <ListItem
                            key={value.user_id}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                onChange={() =>
                                  this.handleToggle(index, value.status)
                                }
                                checked={value.status}
                                inputProps={{
                                  "aria-labelledby": value.user_id,
                                }}
                                style ={{
                                  color: "#5458AF",
                                }}
                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemAvatar>
                                <Avatar
                                  alt={value.first_name}
                                  src={`/static/images/avatar/${
                                    value.first_name + 1
                                  }.jpg`}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                id={value.user_id}
                                primary={dataConcatArray}
                              />
                            </ListItemButton>
                          </ListItem>
                        )
                      })
                    ) : (
                      <div className="text-center mb-5">
                        <label>No User to Select </label>
                      </div>
                    )}
                  </List>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button style={addBtnStyle} onClick={this.handleSubmit}>
                  Save changes
                </Button>
                <Button
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.toggleModal(this.showModal)}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          </>
        )
    }
}