import React from 'react';
import Button from './form/Button';

export default function CardToolbar({ fnViewAllQuestion, isViewAllQuestions }) {
    return (

        <div className="card-toolbar singlePgview">
            <ul class="nav nav-pills nav-pills-sm">

                {/* <li class="nav-item">
                    <div className="time-remaining position-relative">
                        <span className="font-size-h2 font-weight-bolder">1</span>
                        <span className="font-size-h2 font-weight-bolder pl-2 pr-2">:</span>
                        <span className="font-size-h2 font-weight-bolder">48</span>
                        <span className="font-size-h2 font-weight-bolder pl-2 pr-2">:</span>
                        <span className="font-size-h2 font-weight-bolder">16</span>
                        <span className="font-size-sm position-absolute text-muted text-center bottom-text">Time Remaining</span>
                    </div>
                </li> */}

                {/* <li class="nav-item ml-10 mr-10 pl-4 pr-4 border border-primary rounded">
                    <CheckBoxInline>
                        <CheckBox checkBoxName="Make for Review" />
                    </CheckBoxInline>
                </li> */}

                <li className="nav-item AllQcheckBox">
                    <div className='form-check' style={{ display: 'flex', alignItems: 'center' }} onClick={fnViewAllQuestion}>
                        <input type="checkbox" id="view" name="view" value="pprView"
                            className={`form-check-input btn btn-${isViewAllQuestions ? 'primary' : 'view-questions'} btn-sm font-weight-bolder`}
                        />
                        <label className="view" for="view">Single page view</label>
                    </div>
                </li>
            </ul>
        </div>

    )
}