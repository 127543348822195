import React, { Component } from "react";
import Card from "../../../../components/common/Card.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardBody from "../../../../components/common/CardBody.js";
import CardTitle from "../../../../components/common/CardTitle.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../../components/common/spinner/PageSpinner.js";
import "../../../../components/admin/css/common.css";
import sweetAlert, {
  sweetStatusAlert
} from "../../../../components/common/SweetAlert.js";
import SimpleReactValidator from "simple-react-validator";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import TimeZoneHandler from "../../../../handlers/TimeZoneHandler.js";
import config from "../../../../helper/config.js";
import { customerConstants } from "../../../../helper/constants.js";
import { LevelPriceService } from "../../../../service/manage/subscription/level_price.service.js";
import { CreditService } from "../../../../service/manage/subscription/credit.service.js";
import { TypeService } from "../../../../service/manage/subscription/type.service.js";
import { StripeService } from "../../../../service/manage/subscription/stripe_pg.service.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

export default class Credits extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      showModal: false,
      isLoading: false,
      isTableDataLoading: true,
      isModified: false,
      selectedStudentId: "",
      selectedStudentTable: "",
      selectedStudent: null,
      search_id: "",
      isStudentsLoading: false,
      isSubmitting: false,
      registration_id: "",
      editVisibility: true,
      addVisibility: true,
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      credits: "",
      types: "",
      type_id: "",
      type_name: "",
      type_credits: "",
      discount_rate: "",
      level_prices: "",
      level_price_id: "",
      level_price_name: "",
      level_name: "",
      level_definition: "",
      level_price_credits: "",
      level_price_value: "",
      level_price_currency_type: "",
      level_price_start_date: "",
      level_price_end_date: "",
      credit_id: "",
      name: "",
      definition: "",
      description: "",
      amount: "",
      userType: "",
      total_credits: "",
      isPayEnabled: false,
      showTransactionModal: false,
      selectedTransaction: null,
      creditsPrivilege: null,
      form_list_id: "",
    };

    this.createValidationsInstance();

  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }
  toggleFileUploadModal = () => {
    this.setState((prevState) => ({
      showFileUploadModal: !prevState.showFileUploadModal,
    }));
  };

  handleRowClick = (transaction) => {
    this.setState({
      showTransactionModal: true,
      selectedTransaction: transaction,
    });
  };

  handleModalClose = () => {
    this.setState({
      showTransactionModal: false,
      selectedTransaction: null,
    });
  };

  componentDidMount() {
    const { breadcrumb2, nodeTitle, flg_list_active, form_list_id } = this.props.props;
    const userType = sessionStorage.getItem("userType");

    this.setState({
      breadcrumb2: breadcrumb2, nodeTitle, flg_list_active,
      form_list_id: form_list_id,
      userType: userType,
      creditsPrivilege: this.props.creditsPrivilege
    }, () => {
      const { creditsPrivilege } = this.state
      if (creditsPrivilege.isAdmin) {
        this.getAllCredits();
      } else if (creditsPrivilege.isStudent) {
        this.getAllCreditsByUser();
      }
    });
  }

  getAllCredits() {
    const mid = 100;
    CreditService.getAllCredits(mid).then((res) => {
      if (res.success) {
        this.setState({ credits: res.data.credits });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Credits. Please refresh the screen!",
          "error"
        );
      }
    }).finally(() => {
      this.setState({
        isTableDataLoading: false,
      });
    })
  }

  getAllCreditsByUser() {
    const mid = 100;
    const user_id = sessionStorage.getItem("manage_user_id");
    CreditService.getAllCreditsByUser(mid, user_id).then((res) => {
      if (res.success) {
        this.setState({ credits: res.data.credits });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Credits. Please refresh the screen!",
          "error"
        );
      }
    }).finally(() => {
      this.setState({
        isTableDataLoading: false,
      });
    })
  }

  getAllActiveLevelPrices() {
    const mid = 100;
    LevelPriceService.getAllActiveLevelPrices(mid).then((res) => {
      if (res.success) {
        this.setState({ level_prices: res.data.level_prices });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Member Levels. Please refresh the screen!",
          "error"
        );
      }
    }).finally(() => {
      this.setState({
        isTableDataLoading: false,
      });
    })
  }

  getAllTypes() {
    const mid = 100;
    TypeService.getAllTypes(mid).then((res) => {
      if (res.success) {
        this.setState({ types: res.data.types });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Member types. Please refresh the screen!",
          "error"
        );
      }
    }).finally(() => {
      this.setState({
        isTableDataLoading: false,
      });
    })
  }

  submitCredit = () => {
    if (this.validator.allValid()) {
      this.setState({ showInsertModal: false, isLoading: true });
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");
      const userType = sessionStorage.getItem("userType");

      const {
        type_id,
        type_name,
        type_credits,
        level_price_id,
        level_price_credits,
        level_price_start_date,
        level_price_end_date,
        amount,
        level_price_currency_type,
        form_list_id,
      } = this.state;

      // Parse the date strings
      const startDate = new Date(level_price_start_date.split('/').reverse().join('-'));
      const endDate = new Date(level_price_end_date.split('/').reverse().join('-'));

      // Calculate the difference in days
      const timeDifference = endDate.getTime() - startDate.getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      // Add the difference to the start date to get the expiration date
      const expirationDate = new Date(startDate.getTime() + daysDifference * (1000 * 3600 * 24));

      const stripe_obj = {
        stu_id: session_id,
        email: sessionStorage.getItem("email"),
        unit_amount: amount * 100,
        amount: amount,
        credits: parseInt(type_credits) * parseInt(level_price_credits),
        currency_type: level_price_currency_type,
        name: type_name,
        node_mid: mid,
        type_id: parseInt(type_id),
        level_price_id: parseInt(level_price_id),
        expiration_date: expirationDate,
        total_credits: parseInt(type_credits) * parseInt(level_price_credits),
        available_credits: parseInt(type_credits) * parseInt(level_price_credits),
        used_credits: 0,
        user_id: session_id,
        user_table_id: table_log_id,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
        form_list_id: userType === "admin" ? form_list_id : 29,
      }
      StripeService.create_checkout_session(JSON.stringify(stripe_obj)).then((res) => {
        if (res.success) {
          window.location.replace(res.data);
        } else {
          sweetStatusAlert("Failed!", res.message, "error");
        }
      }).finally(() => {
        // Hide loading indicator
        this.setState({ isLoading: false });
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }

  }

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      toggleFunction();
    }
  };

  calculateAmount() {
    const {
      type_credits,
      discount_rate,
      level_price_credits,
      level_price_value,
    } = this.state;

    if (type_credits && discount_rate && level_price_credits && level_price_value) {
      const amountToPay = parseFloat(type_credits) * parseFloat(level_price_value) * ((100 - parseFloat(discount_rate)) / 100);
      const roundedAmount = parseFloat(amountToPay.toFixed(2));
      this.setState({ amount: roundedAmount, total_credits: parseInt(type_credits) * parseInt(level_price_credits), isPayEnabled: true });
    }

  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "type_id") {
      if (value) {
        const type = this.state.types.find(item => item.type_id === parseInt(value));
        this.setState(
          () => ({
            type_name: type.name,
            type_credits: type.credits,
            discount_rate: type.discount_rate,
          }),
          () => {
            this.calculateAmount();
          }
        );
      } else {
        this.setState({
          type_name: "",
          type_credits: "",
          discount_rate: "",
          amount: "",
          total_credits: "",
          isPayEnabled: false,
        });
      }
    }
    if (name == "level_price_id") {
      if (value) {
        const level_price = this.state.level_prices.find(item => item.level_price_id === parseInt(value));
        this.setState(
          () => ({
            level_price_name: level_price.name,
            level_price_credits: level_price.credits,
            level_name: level_price.level,
            level_definition: level_price.definition,
            level_price_value: level_price.value,
            level_price_currency_type: level_price.currency_type,
            level_price_start_date: level_price.start_date,
            level_price_end_date: level_price.end_date,
          }),
          () => {
            this.calculateAmount();
          }
        );
      } else {
        this.setState({
          level_price_name: "",
          level_price_credits: "",
          level_name: "",
          level_definition: "",
          level_price_value: "",
          level_price_currency_type: "",
          level_price_start_date: "",
          level_price_end_date: "",
          amount: "",
          total_credits: "",
          isPayEnabled: false,
        });
      }
    }
    this.setState(() => ({ [name]: value, isModified: true }));
  };


  toggleAddCreditModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        level_price_id: "",
        level_price_name: "",
        level_price_credits: "",
        level_name: "",
        level_definition: "",
        level_price_value: "",
        level_price_currency_type: "",
        level_price_start_date: "",
        level_price_end_date: "",
        amount: "",
        total_credits: "",
        type_id: "",
        type_name: "",
        type_credits: "",
        discount_rate: "",
        isPayEnabled: false,
      }),
      () => {
        this.getAllActiveLevelPrices();
        this.getAllTypes();
      }
    );
  };

  render() {
    const {
      isLoading,
      isTableDataLoading,
      credits,
      types,
      type_id,
      type_credits,
      discount_rate,
      level_prices,
      level_price_id,
      level_price_credits,
      level_name,
      level_definition,
      level_price_value,
      level_price_currency_type,
      amount,
      total_credits,
      isPayEnabled,
      showTransactionModal,
      selectedTransaction,
      creditsPrivilege,
    } = this.state;
    this.validator.purgeFields();

    if (!creditsPrivilege) {
      return (
        <div>
          <h4>Privilege not found for this section</h4>
        </div>
      )
    }
    return (
      <>
        <Card>
          <CardHeader>
            <div>
              {/* <CardTitle cardLabel={history.location.state.form_display_title} /> */}
              <CardTitle cardLabel={"Credits"} />
              {/* <h3 style={{ color: 'gray' }}>{(breadcrumb2 || nodeTitle) ? `(${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle})` : undefined}</h3> */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {creditsPrivilege.addVisibility && (
                <Button
                  className="insert-btn"
                  variant="primary"
                  onClick={this.toggleAddCreditModal}
                >
                  Add Credits
                </Button>
              )}
            </div>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div className="loading-container">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div style={{ overflow: 'Auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>Purchased Date</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>Total Credits</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>Used Credits</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>Available Credits</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>Expiration Date</TableCell>
                      <TableCell className="thead" style={{ textAlign: 'center' }}>{creditsPrivilege.isAdmin ? "Transaction Id" : "Confirmation No"}</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {credits.length > 0 && credits.map((credit) => (
                      <TableRow key={credit.credit_id}>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {TimeZoneHandler.convertTime(credit.created_at)}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {credit.total_credits}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {credit.used_credits}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {credit.available_credits}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center' }}>
                          {TimeZoneHandler.convertTime(credit.expiration_date)}
                        </TableCell>
                        <TableCell className="tData" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => this.handleRowClick(credit.transaction)}>
                          {credit.transaction_id}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() =>
            this.setState({ showInsertModal: false })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Credits</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Level Price*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="level_price_id"
                    onChange={this.handleChange}
                    defaultValue={level_price_id}
                  >
                    <option value="">Select</option>
                    {level_prices.length > 0 && level_prices.map((level_price) => (
                      <option
                        value={level_price.level_price_id}
                        key={level_price.level_price_id}
                      >
                        {level_price.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("level price", level_price_id, "required")}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Level</Form.Label>
                  <Form.Control
                    type="text"
                    name="level_name"
                    value={level_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Available Grades</Form.Label>
                  <Form.Control
                    type="text"
                    name="level_definition"
                    value={level_definition}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Level Price Credits</Form.Label>
                  <Form.Control
                    type="text"
                    name="level_price_credits"
                    defaultValue={level_price_credits}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Value</Form.Label>
                  <Form.Control
                    type="text"
                    name="level_price_value"
                    value={`${level_price_value} ${level_price_currency_type}`}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Type*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="type_id"
                    onChange={this.handleChange}
                    defaultValue={type_id}
                  >
                    <option value="">Select</option>
                    {types.length > 0 && types.map((type) => (
                      <option
                        value={type.type_id}
                        key={type.type_id}
                      >
                        {type.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("type", type_id, "required")}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Type Credits</Form.Label>
                  <Form.Control
                    type="text"
                    name="type_credits"
                    defaultValue={type_credits}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Discount Rate</Form.Label>
                  <Form.Control
                    type="text"
                    name="discount_rate"
                    defaultValue={discount_rate}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Total Credits</Form.Label>
                  <Form.Control
                    type="text"
                    name="total_credits"
                    value={total_credits}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Total Amount To Pay</Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    value={`${amount} ${level_price_currency_type}`}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() =>
                    this.setState({ showInsertModal: false })
                  }
                >
                  Close
                </Button>
                {/* {editVisibility && ( */}
                <Button
                  disabled={!isPayEnabled}
                  className="modal-btn"
                  variant="primary"
                  style={addBtnStyle}
                  onClick={this.submitCredit}
                >
                  Pay
                </Button>
                {/* )} */}
              </Modal.Footer>

            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showTransactionModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transaction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedTransaction && (
              <Form>
                {Object.entries(selectedTransaction).map(([key, value]) => (
                  <Row className="my-3" key={key}>
                    <Form.Label as={Col} md="3">{key}</Form.Label>
                    <Form.Label as={Col} md="9">{key == "Exp. Date" || key == "Purchase Date" ? TimeZoneHandler.convertTime(value) : value}</Form.Label>
                  </Row>
                ))}
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

      </>
    );
  }
}