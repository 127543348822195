import React, { useRef, useEffect, useImperativeHandle, forwardRef } from 'react';

const CanvasSketch = forwardRef(({ width, height, lineColor, lineWidth }, ref) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    let isDrawing = false;
    let lastX = 0;
    let lastY = 0;

    const handleMouseDown = (event) => {
      isDrawing = true;
      [lastX, lastY] = [event.offsetX, event.offsetY];
    };

    const handleMouseMove = (event) => {
      if (!isDrawing) return;

      const x = event.offsetX;
      const y = event.offsetY;

      context.strokeStyle = lineColor;
      context.lineWidth = lineWidth;
      context.lineCap = 'round';

      context.beginPath();
      context.moveTo(lastX, lastY);
      context.lineTo(x, y);
      context.stroke();

      [lastX, lastY] = [x, y];
    };

    const handleMouseUp = () => {
      isDrawing = false;
    };

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);
    };
  }, [lineColor, lineWidth]);

  const getCanvasDataURL = () => {
    if (canvasRef.current) {
      return canvasRef.current.toDataURL();
    } else {
      return null;
    }
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  useImperativeHandle(ref, () => ({
    getCanvasDataURL: getCanvasDataURL,
    clearCanvas: clearCanvas
  }));

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{ border: '1px solid black' }}
    />
  );
});

export default CanvasSketch;
