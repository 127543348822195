import {assessLanguageTypes} from '../settingsTypes';

const initialState = {
    allAssessmentLanguages: [],
    loading: false,
    error: '',
    text: '',
    language:'',
    totalLanguagesCount: 0,
    allLanguages:[],
    errorlanguagedelete:false,
    loadinglanguagedelete:false,
    successlanguageupdate: false,
    loadinglanguageupdate: false,
    errorlanguageupdate:false,
    successlanguagedelete:false,
    errorlanguagecreate: '',
    successlanguagecreate: false,
}

export function languageReducer(state = initialState, action){
    switch (action.type){
        case assessLanguageTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allAssessmentLanguages: [],
                loading: false,
                error: '',
                text: '',
                language:'',
                totalLanguagesCount:0,
                allLanguages:[],
                errorlanguagedelete:false,
                loadinglanguagedelete:false,
                successlanguageupdate: false,
                loadinglanguageupdate: false,
                errorlanguageupdate:false,
                successlanguagedelete:false,
                errorlanguagecreate: '',
                successlanguagecreate: false,
            }
        //Language retrieve request sending
        case assessLanguageTypes.GET_ALL_ASSESSMENT_LANGUAGES:
            return {
                ...state,
                loading: true,
                text: 'Get all the assessment languages'
            }
        case assessLanguageTypes.GET_ALL_ASSESSMENT_LANGUAGES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessLanguageTypes.GET_ALL_ASSESSMENT_LANGUAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                allAssessmentLanguages: action.languages.languages,
                totalLanguagesCount: action.languages.total_count
            }
        case assessLanguageTypes.GET_ALL_ASSESSMENT_LANGUAGES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Language creation request sending
        case assessLanguageTypes.CREATE_LANGUAGE_REQUEST:
            return {
                ...state,
                errorlanguagecreate: '',
                successlanguagecreate: false,
                loading: true
            }
        case assessLanguageTypes.CREATE_LANGUAGE_SUCCESS:
            var temp = state.allLanguages;
            temp.push(action.languages)
            return {
                ...state,
                allLanguages: temp,
                language: action.languages.language_id,
                successlanguagecreate: true,
                loading: false
            }
        case assessLanguageTypes.CREATE_LANGUAGE_FAILURE:
            return {
                ...state,
                errorlanguagecreate: action.error,
                loading: false
            }  
        //Language deletion request sending   
        case assessLanguageTypes.DELETE_LANGUAGE_REQUEST:
            return {
            ...state,
            errorlanguagedelete: '',
            successlanguagedelete: false,
            loading: true,
            };
        case assessLanguageTypes.DELETE_LANGUAGE_SUCCESS:
            return {
            ...state,
            successlanguagedelete: true,
            loading: false,
            };
        case assessLanguageTypes.DELETE_LANGUAGE_FAILURE:
            return {
            ...state,
            errorlanguagedelete: action.error,
            loading: false,
            };
        //Language updation request sending
        case assessLanguageTypes.UPDATE_LANGUAGE_REQUEST:
            return {
            ...state,
            errorlanguageupdate:'',
            successlanguageupdate: false,
            loading: true,
            };
        case assessLanguageTypes.UPDATE_LANGUAGE_SUCCESS:
            return {
            ...state,
            successlanguageupdate: true,
            loading: false,
            };
        case assessLanguageTypes.UPDATE_LANGUAGE_FAILURE:
            return {
            ...state,
            errorlanguageupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
