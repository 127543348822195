import React, { useState, useEffect } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { MicrosoftLoginAgent } from '../../authentication/MicrosoftLoginAgent';
import jwt_decode from "jwt-decode";
import DataHandler from '../../handlers/DataHandler';
import config from "../../helper/config";
import { UserManagementService } from '../../service/user_management/user_management.service';
import { routes } from '../../helper/routes';
import Spinner from "../common/Spinner"

function PrivateRoute({ component: Component, ...rest }) {

    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [idToken, setIdToken] = useState(DataHandler.getFromSession(DataHandler.SESSION_DATA.IdToken))
    // useEffect(() => {
    //     setIdToken(DataHandler.getFromSession(DataHandler.SESSION_DATA.IdToken));
    // }, [])
    useEffect(() => {
        if (idToken) {
            let decodedToken = jwt_decode(idToken);
            let dateNow = new Date();

            if (decodedToken.exp * 1000 < dateNow.getTime()) {
                setIsAuthenticated(false)

                MicrosoftLoginAgent.acquireToken(config.azure_ad_config.login_scopes)
                    .then((res) => {
                        DataHandler.setToSession(DataHandler.SESSION_DATA.IdToken, res.accessToken);
                        setIsAuthenticated(true)
                    }).catch(() => {
                        UserManagementService.logout();
                    });
            } else {
                setIsAuthenticated(true)
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [idToken])

    return (
        <>
            {
                isAuthenticated === null ? <Spinner id="show" text="Please Wait!" /> : (
                    < Route {...rest} render={(props) => {
                        if (isAuthenticated) {
                            return <Component {...props} />
                        } else {
                            if (props?.location?.pathname === routes.enrolled_paper_list) {
                                const urlParams = new URLSearchParams(props?.location?.search);
                                const nodeMid = urlParams.get('node-mid');
                                const candidateId = urlParams.get('candidate-id');
                                sessionStorage.setItem("user_role_selection", "student")
                                sessionStorage.setItem("fromURL", props.location.pathname);
                                sessionStorage.setItem("entrolledPaper_nodeMid", nodeMid);
                                sessionStorage.setItem("entrolledPaper_candidateId", candidateId)
                                return <Redirect to={{ pathname: routes.auth_response, state: { from: props.location } }} />
                            }
                            return <Redirect to={{ pathname: routes.base_url, state: { from: props.location } }} />
                        }
                    }} />
                )
            }
        </>
    )
}

export default withRouter(PrivateRoute)