import React from "react"
import "../dashboard.css"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import TimeZoneHandler from "../../../../../handlers/TimeZoneHandler.js"
import config from "../../../../../helper/config.js"
import { customerConstants } from "../../../../../helper/constants.js"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}

const AnnouncementCard = (props) => {
  
  
  const formattedDateString = (value) =>{
    const dateObject = value ? new Date(value): undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject &&  ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString =  `${day}-${month}-${year}`
    return fromattedString;
  }
  
  const formattedTimeString = (value) =>{
    const time = new Date(value);    
    const formattedTime = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    return formattedTime
  }
  function convertUTCtoIST(utcDateString) {
    // Parse the UTC date string
    const utcTime = new Date(utcDateString)

    // Convert to IST (Indian Standard Time, UTC+5:30)
    const istOffset = 5.5 * 60 * 60 * 1000 // 5.5 hours in milliseconds
    const istTime = new Date(utcTime.getTime() + istOffset)

    // Format the date for display
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "Asia/Kolkata",
    }

    const istTimeString = istTime.toLocaleString("en-US", options)

    return istTimeString
  }

  function formatTimeDifference(dateString) {
    const currentDate = new Date()
    const providedDate = new Date(
      TimeZoneHandler.convertTimeByUserLocation(dateString)
    )

    const timeDifferenceInMilliseconds = currentDate - providedDate
    const secondsDifference = Math.floor(timeDifferenceInMilliseconds / 1000)
    const minutesDifference = Math.floor(secondsDifference / 60)
    const hoursDifference = Math.floor(minutesDifference / 60)
    const daysDifference = Math.floor(hoursDifference / 24)
    const weeksDifference = Math.floor(daysDifference / 7)
    const monthsDifference = Math.floor(daysDifference / 30)
    const yearsDifference = Math.floor(daysDifference / 365)

    if (yearsDifference > 0) {
      return `${yearsDifference} ${
        yearsDifference === 1 ? "year" : "years"
      } ago`
    } else if (monthsDifference > 0) {
      return `${monthsDifference} ${
        monthsDifference === 1 ? "month" : "months"
      } ago`
    } else if (weeksDifference > 0) {
      return `${weeksDifference} ${
        weeksDifference === 1 ? "week" : "weeks"
      } ago`
    } else if (daysDifference > 0) {
      return `${daysDifference} ${daysDifference === 1 ? "day" : "days"} ago`
    } else if (hoursDifference > 0) {
      return `${hoursDifference} ${
        hoursDifference === 1 ? "hour" : "hours"
      } ago`
    } else if (minutesDifference > 0) {
      return `${minutesDifference} ${
        minutesDifference === 1 ? "minute" : "minutes"
      } ago`
    } else {
      return "Just now"
    }
  }

  return (
    <div className="row announcement__card">
      <div className="col-md-1 col-1 d-flex align-items-center p-0">
        <AccountCircleIcon sx={{ fontSize: 30, color: "#D9DEEF" }} />
      </div>
      <div className="col-md-11 col-11 pl-5">
        <h3 className="announcement__title m-0">
          {props.announcement.announcement_title}
          {/* Announcement Title */}
        </h3>
        <span className="stu-variant-class label label-lg label-light-success label-inline announcement__breadcrumb">
          
        {props.announcement.breadcrumb + props.announcement.title}
          {/* Institute › Faculties › Faculty of Management › */}
        </span>
        <div className="d-flex justify-content-between pt-2 announcement__content">
          <p>
            {/* Posted By:{" "} */}
            <b>
              {/* Person:  */}
              {props.announcement?.create_user_table_id === "1" && "Admin"}
                {props.announcement?.create_user_table_id === "3" && "Parent"}
                {props.announcement?.create_user_table_id === "10" && "Teacher"}
            </b>
          </p>
          
                {/* {`${formatTimeDifference(props.paper?.created_at)} Time zone (IST)`} */ }                
                <div>{formattedDateString(TimeZoneHandler.convertTime(props.announcement?.created_at))} &nbsp;  &nbsp; {formattedTimeString(TimeZoneHandler.convertTime(props.announcement?.created_at))}</div>  
              
          {/* <p>{props.announcement?.created_at} */}
            {/* {`${formatTimeDifference(props.announcement?.created_at)}`} */}
            {/* Created time */}
          {/* </p> */}
        </div>
      </div>
    </div>
  )
}

export default AnnouncementCard
