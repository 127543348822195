import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  CircularProgress
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import Spinner from "../../../components/Spinner2";
import { AssessmentService } from "../../../service/candidate/assessmentService";
import { sweetStatusAlert } from "../../../components/common/SweetAlert";
import { QuizService } from "../../../service/manage/quiz/quiz_service";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default class QuizMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AssessmentList: [],
      selectedRows: new Set(),
      isLoading: true,
      node_mid: null,
      addedPapers: [],
      limit: 10,
      offset: 0,
      search_term: "",
      totalAssessments: 0,
      currentPage: 0,
      sortColumn: "title",
      sortDirection: "desc",
      addVisibility: false,
      deleteVisibility: false,
      addingExamsLoading: false,
      deletingPapersLoading: false,
      breadcrumb2: null,
      nodeTitle: null,
    };
  }

  componentDidMount() {
    this.getAllAssessments();
    this.getPaperData();
    this.loadInitialData();
  }

  loadInitialData() {
    const {
      privilege_view,
      privilege_add,
      privilege_delete,
      breadcrumb2,
      nodeTitle,
    } = this.props.state;
    this.getVisibilityCheck(privilege_view, privilege_add, privilege_delete);
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle,
    });
  }

  getVisibilityCheck(privilege_view, privilege_add, privilege_delete) {
    let addVisibility = false;
    let deleteVisibility = false;

    if (privilege_view === 1 && privilege_add === 1) {
      if (privilege_delete === 1) {
        addVisibility = true;
        deleteVisibility = true;
      } else {
        addVisibility = true;
      }
    } else if (privilege_view === 1 && privilege_add === null) {
      if (privilege_delete === 1) {
        addVisibility = true;
        deleteVisibility = true;
      } else {
        addVisibility = true;
      }
    }

    this.setState({
      addVisibility,
      deleteVisibility,
    });
  }

  async getPaperData() {
    try {
      const node_mid = this.props.state.mid;
      const paperData = await QuizService.getById(node_mid);
      this.setState({ paperData });

      if (paperData.data && paperData.data.length > 0) {
        const addedPapers = paperData.data.map((paper) => paper.assessment_id);
        this.setState({ addedPapers });
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleCheckboxChange = (assessment_id) => {
    this.setState((prevState) => {
      const selectedRows = new Set(prevState.selectedRows);

      if (selectedRows.has(assessment_id)) {
        selectedRows.delete(assessment_id);
      } else {
        selectedRows.add(assessment_id);
      }

      return { selectedRows };
    });
  };

  handleAddAllExams = async (event) => {
    event.preventDefault();
    let lg_user_id = sessionStorage.getItem("manage_user_id");
    let lg_user_table_id = sessionStorage.getItem("table_log_id");

    this.setState({ addingExamsLoading: true });

    try {
      const node_mid = this.props.state.mid;

      const examList = [...this.state.selectedRows].map((assessment_id) => ({
        node_mid,
        create_user_id: lg_user_id,
        create_user_table_id: lg_user_table_id,
        assessment_id,
        assess_name: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).title,
        attempt_no: this.state.AssessmentList.find(
          (assessment) => assessment.assessment_id === assessment_id
        ).no_of_attempts,
      }));

      await QuizService.addAll(JSON.stringify(examList)).then((response) => {
        if (response.success) {
          sweetStatusAlert("Success!", response.message, "success");
          this.getPaperData();
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      });

      this.setState({ selectedRows: new Set() });
      this.setState({ addingExamsLoading: false });
    } catch (error) {
      console.error(error);
      this.setState({ addingExamsLoading: false });
    }
  };

  async deletePapers(paperIds, nodeMid) {
    this.setState({ deletingPapersLoading: true });
    try {
      const response = await QuizService.deleteAll(paperIds, nodeMid);

      if (response.success) {
        sweetStatusAlert("Success!", response.message, "success");

        const updatedAddedPapers = this.state.addedPapers.filter(
          (paperId) => !paperIds.includes(paperId)
        );

        this.setState({
          selectedRows: new Set(),
          deletingPapersLoading: false,
          addedPapers: updatedAddedPapers,
        });
      } else {
        sweetStatusAlert("Failed!", response.message, "error");
        this.setState({ deletingPapersLoading: false });
      }
    } catch (error) {
      console.error(error);
      this.setState({ deletingPapersLoading: false });
    }
  }

  getAllAssessments() {
    const { limit, offset, sortColumn, sortDirection, search_term } =
      this.state;

    AssessmentService.getAllAssesmentsWithPaginationAndSorting(
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term
    )
      .then((res) => {
        this.setState({
          AssessmentList: res.data.assessments,
          totalAssessments : res.data["total count"],
          isLoading: false,
        });
        
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Quizzes. Please refresh the screen!",
          "error"
        );
        this.setState({ isLoading: false });
      });
  }

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isLoading: true,
        currentPage: newPage
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.getAllAssessments();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleSortChange = (sortColumn) => {
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0,
        currentPage: 0,
        isLoading: true,
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  areAllAddedPapersSelected() {
    // Check if all "Added ✔" papers are selected
    return this.state.AssessmentList.every((assessment) =>
      this.state.addedPapers.includes(assessment.assessment_id)
    );
  }

  handleSelectAllAddedPapers() {
    // Select or deselect all "Added ✔" papers
    const selectedRows = new Set(this.state.selectedRows);
    if (this.areAllAddedPapersSelected()) {
      // Deselect all
      this.state.AssessmentList.filter((assessment) =>
        this.state.addedPapers.includes(assessment.assessment_id)
      ).forEach((assessment) => selectedRows.delete(assessment.assessment_id));
    } else {
      // Select all
      this.state.AssessmentList.filter((assessment) =>
        this.state.addedPapers.includes(assessment.assessment_id)
      ).forEach((assessment) => selectedRows.add(assessment.assessment_id));
    }

    this.setState({ selectedRows });
  }

  formattedDateString = (value) =>{
    const dateObject = value ? new Date(value): undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject &&  ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString =  `${day}-${month}-${year}`
    return fromattedString;
  }

  formattedTimeString = (value) =>{
    const time = new Date(value);    
    const formattedTime = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    return formattedTime
  }

  render() {
    const {
      AssessmentList,
      isLoading,
      addedPapers,
      currentPage,
      totalAssessments,
      limit,
      addVisibility,
      addingExamsLoading,
      offset
    } = this.state;

    return (
      <>
        <div style={{ overflow: "auto", height: "90vh" }}>
          <Card>
            <CardHeader>
              <div>
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    style={{ width: "300px" }}
                    className="search-bar"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>
              <div>
                {addVisibility && this.state.selectedRows.size > 0 && (
                  <Button
                    className="insert-btn btn-primary"
                    variant="contained"
                    onClick={this.handleAddAllExams}
                  >
                    {addingExamsLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Add Selected Papers"
                    )}
                  </Button>
                )}
              </div>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ cursor: "pointer" }}
                            className="thead"
                            onClick={() => this.handleSortChange("title")}
                          >
                            Paper Title
                            {this.state.sortColumn === "title" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </TableCell>
                          {/* <TableCell className="thead">Paper Status</TableCell> */}
                          {addVisibility && (
                            <TableCell className="thead">
                              Select to Add Papers
                            </TableCell>
                          )}
                          {/* {deleteVisibility && (
                            <TableCell className="thead">
                              Select Added papers to delete
                            </TableCell>
                          )} */}
                        </TableRow>
                      </TableHead>
                      <TableBody>{AssessmentList.map((assessment) => (
                        <TableRow key={assessment.assessment_id}>
                          <TableCell className="tData">
                            {assessment.title}
                          </TableCell>
                          {addVisibility && (
                            <TableCell className="tData">
                              {addedPapers.includes(
                                assessment.assessment_id
                              ) ? (
                                <span className="stu-variant-class label label-lg label-light-success label-inline">
                                  Added ✔
                                </span>
                              ) : ( 
                                <Checkbox
                                  checked={this.state.selectedRows.has(
                                    assessment.assessment_id
                                  )}
                                  onChange={() =>
                                    this.handleCheckboxChange(
                                      assessment.assessment_id
                                    )
                                  }
                                  style ={{
                                    color: "#5458AF",
                                  }}
                                />
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                      </TableBody>
                    </Table>
                  </div>
                  <MaterialCustomTablePagination
                    totalCount = {parseInt(totalAssessments)}
                    currentPage = {currentPage}
                    limit = {limit}
                    handlePageChange = {this.handlePageChange}
                    handleRowsPerPageChange = {this.handleRowsPerPageChange}
                    handlePageOffsetChange = {this.handlePageOffsetChange}
                    rowsPerPageOptions={[5, 10, 20]}
                    offset = {offset}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}
