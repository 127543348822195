import React, { useEffect } from 'react';

export default function NavItem({ className, id, dataToggle, href, tabName, isHidden, onClick }) {
    if(isHidden){
        return null;
    }
    return (
                    
        <li className="nav-item" onClick={() => onClick()}>
            <a className={className} id={id} data-toggle={dataToggle} href={href}>
                <span className="nav-text"><h4>{tabName}</h4></span>
            </a>
        </li>
        
    )
}


