import React, { useEffect } from 'react';

export default function WizardSteps ({children}) {
    return (
        <div className="d-flex flex-column-auto flex-column">
            {/* begin: Wizard Nav */}
            <div className="wizard-nav d-flex flex-column align-items-center align-items-md-start">
                <div className="wizard-steps d-flex flex-column flex-md-row">

                    {children}
                    
                </div>
            </div>
        </div>
    )
}


