import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import Card from "../../../components/common/Card.js";
import CardHeader from "../../../components/common/CardHeader.js";
import CardBody from "../../../components/common/CardBody.js";
import CardTitle from "../../../components/common/CardTitle.js";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EditNoteIcon from "@mui/icons-material/EditNote";
import IconButton from "@mui/material/IconButton";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  InputLabel,
  TextField,
  Select,
  TableCell,
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { EnrollmentService } from "../../../service/manage/enrollment/enrollment.service.js";
import { PaymentService } from "../../../service/manage/payments/payment.service.js";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import "../../../components/admin/css/common.css";
import sweetAlert, {
  sweetStatusAlert,
} from "../../../components/common/SweetAlert.js";
import ReactSelect from "react-select";
import SimpleReactValidator from "simple-react-validator";
import { history } from "../../../helper/history.js";
import config from "../../../helper/config.js";
import { customerConstants } from "../../../helper/constants.js";
import "./Payment.css";
import TimeZoneHandler from "../../../handlers/TimeZoneHandler.js";
import { OwnerRestrictedViewPayment } from "./OwnerRestrictedViewPayment.js";
import { PaymentMethodService } from "../../../service/settings/manage/payment_method.service.js";
import Dropdown from "react-bootstrap/Dropdown";
import DiscountModal from "./DiscountModal.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      showModal: false,
      enrollments: [],
      isLoading: false,
      isTableDataLoading: true,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      totalEnrollments: 10,
      currentPage: 0,
      isModified: false,
      students: [],
      selectedStudentId: "",
      selectedStudentTable: "",
      selectedStudent: null,
      search_id: "",
      isStudentsLoading: false,
      isSubmitting: false,
      registration_id: "",
      editVisibility: false,
      addVisibility: false,
      sortColumn: "user_code",
      sortDirection: "desc",
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      amount: null,
      payments: null,
      paymentLoading: true,
      enrollmentLoading: true,
      amountLoading: true,
      amountValue: "",
      paymentValue: "",
      paymentDescription: "",
      mannualReceptCode: "",
      otherEnrollments: [],
      form_list_id: undefined,
      user_list_restriction: null,
      paymentMethods: [],
      selectedPaymentMethod: undefined,
      showDiscountModal: false,
      studentDiscount: undefined,
    };

    this.debouncedHandleSearch = debounce(this.getAllEnrollments, 2000);

    this.createValidationsInstance();
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const {
      privilege_add,
      privilege_edit,
      breadcrumb2,
      nodeTitle,
      flg_list_active,
      form_list_id,
      user_list_restriction,
    } = this.props.state;
    this.getVisibilityCheck(privilege_add, privilege_edit);
    this.getAllEnrollments();
    this.fetchAllPaymentMethods();
    this.getAmount();
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle,
      flg_list_active,
      form_list_id,
      user_list_restriction,
    });
  }

  getVisibilityCheck(privilege_add, privilege_edit) {
    if (privilege_add == "1") {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_edit == "1") {
      this.setState({
        editVisibility: true,
      });
    }
  }

  getAmount() {
    const { mid } = this.props.state;
    PaymentService.getAmountByNode(mid)
      .then((res) => {
        if (res.success) {
          this.setState({
            amount: res.data.amounts[0],
            amountLoading: false,
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Amount Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          amountLoading: false,
        });
      });
  }

  getPayment(s_id, mid) {
    PaymentService.getPaymentByStudent(parseInt(mid), s_id)
      .then((res) => {
        if (res.success) {
          this.setState({
            payments: res.data.payments,
            studentDiscount: res.data.discount,
            paymentLoading: false,
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Amount Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          paymentLoading: false,
        });
      });
  }

  getAllEnrollments() {
    const { mid, form_list_id } = this.props.state;
    const { limit, offset, sortColumn, sortDirection, search_term } =
      this.state;
    const session_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");
    PaymentService.getAllEnrolledStudents(
      mid,
      form_list_id,
      session_id,
      table_log_id,
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term
    )
      .then((res) => {
        if (res.success) {
          this.setState({
            enrollments: res.data.students,
            totalEnrollments: res.data.count,
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Enrollments Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  }

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllEnrollments();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllEnrollments();
      }
    );
  };

  fetchAllPaymentMethods = () => {
    PaymentMethodService.getAllPaymentMethods().then((res) => {
      this.setState({
        paymentMethods: res.data,
      });
    });
  };

  getCurrentEnrollments = () => {
    const s_id = this.state.selectedStudentId;
    const s_t_id = this.state.selectedStudentTable;
    PaymentService.getEnrollments(s_id, s_t_id, "enrollment", "owner")
      .then((res) => {
        if (res.success) {
          this.setState({
            otherEnrollments: res.data,
            enrollmentLoading: false,
          });
        } else {
          this.setState({
            otherEnrollments: [],
          });
        }
      })
      .finally(() => {
        this.setState({
          enrollmentLoading: false,
        });
      });
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllEnrollments();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  togglePaymentsModal = (enrollment) => {
    this.setState(
      {
        showInsertModal: true,
        selectedStudentId: enrollment?.user_id,
        selectedStudentTable: enrollment?.user_table_id,
        selectedStudent: enrollment,
        search_id: "",
        selectedPaymentMethod: undefined,
      },
      () => {
        this.getPayment(this.state.selectedStudentId, this.props.state.mid);
        this.getCurrentEnrollments();
      }
    );
  };

  toggleDiscountModal = (enrollment) => {
    this.setState({
      showDiscountModal: enrollment ? true : false,
      selectedStudentId: enrollment?.user_id,
      selectedStudentTable: enrollment?.user_table_id,
      selectedStudent: enrollment,
    });
  };

  handlePaymentMethodSelect = (meth) => {
    this.setState({
      selectedPaymentMethod: meth,
    });
  };

  calOutstandingAmount = (amount, totalAmount, discount) => {
    let outStandingAmount = amount;
    if (discount) {
      outStandingAmount =
        outStandingAmount - (outStandingAmount / 100) * parseInt(discount);
    }
    if (totalAmount) {
      outStandingAmount = outStandingAmount - totalAmount;
    }
    return outStandingAmount;
  };

  amountToLRK = (amount) => {
    return amount?.toLocaleString("en-LK", {
      style: "currency",
      currency: "LKR",
    });
  };

  submitAmount = () => {
    const { mid } = this.props.state;
    const session_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");
    let val = parseInt(this.state.amountValue);
    let obj = [
      {
        node_mid: mid,
        amount: val,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
      },
    ];
    let updateObj = {
      node_mid: mid,
      amount: val,
      create_user_id: session_id,
      create_user_table_id: table_log_id,
    };
    this.setState({ amountLoading: true });
    if (this.state.amount == undefined) {
      PaymentService.createAmount(JSON.stringify(obj))
        .then((res) => {
          if (res.success) {
            sweetStatusAlert("Success!", res.message, "success");
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch((error) => {
          sweetStatusAlert("Failed !", "Unable to add Amount!", "error");
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ amountValue: "" });
          this.setState({ amountLoading: true });
          this.getAmount();
        });
    } else {
      PaymentService.updatePayment(
        JSON.stringify(updateObj),
        this.state.amount?.id
      )
        .then((res) => {
          if (res.success) {
            this.setState({
              amount: res.data,
              amountLoading: false,
            });
            sweetStatusAlert("Success!", res.message, "success");
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch((error) => {
          sweetStatusAlert("Failed !", "Unable to add Amount!", "error");
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ amountValue: "" });
        });
    }
  };

  totalPaid = () => {
    let payments = this.state.payments;
    let total = 0;
    for (let i = 0; i <= payments.length - 1; i++) {
      total = total + payments[i]?.payment;
    }
    return total;
  };

  submitPayment = () => {
    const { mid, user_list_restriction } = this.props.state;
    const session_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");
    let val_payment = parseInt(this.state.paymentValue);
    let val_stu_id = user_list_restriction
      ? session_id
      : this.state.selectedStudentId;
    let val_table_id = user_list_restriction
      ? table_log_id
      : this.state.selectedStudentTable;
    let checkAmountValidity =
      this.calOutstandingAmount(
        this.state.amount?.amount,
        this.totalPaid() + val_payment,
        this.state.selectedStudent?.discount
      ) >= 0;

    if (!checkAmountValidity) {
      sweetStatusAlert(
        "Failed!",
        "Provided payment is exceeding the alocated amount",
        "error"
      );
      return;
    }
    if (!this.state.selectedPaymentMethod) {
      sweetStatusAlert("Failed!", "Please select a payment method", "error");
      return;
    }

    let obj = [
      {
        node_mid: mid,
        stu_id: val_stu_id,
        payment: val_payment,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
        user_table_id: val_table_id,
        payment_method_id: this.state.selectedPaymentMethod.payment_method_id,
        description: this.state.paymentDescription,
        manual_recipt_code: this.state.mannualReceptCode,
        customer: customer,
      },
    ];
    this.setState({ paymentLoading: true });
    PaymentService.createPayment(JSON.stringify(obj))
      .then((res) => {
        if (res.success) {
          sweetStatusAlert("Success!", res.message.success, "success");
        } else {
          sweetStatusAlert("Failed!", res.message, "error");
        }
      })
      .catch((error) => {
        sweetStatusAlert("Failed !", "Unable to add Amount!", "error");
      })
      .finally(() => {
        // Hide loading indicator
        this.setState(
          {
            paymentLoading: false,
            paymentValue: "",
            paymentDescription: "",
            mannualReceptCode: "",
          },
          () => {
            this.getPayment(val_stu_id, this.props.state.mid);
            this.getAllEnrollments();
          }
        );
      });
  };

  handleSortChange = (sortColumn) => {
    // If the same column is clicked, toggle the sort direction
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.getAllEnrollments();
      }
    );
  };

  handleAmountChange = (e) => {
    let newValue = e.target.value;
    if (newValue < 0) {
      newValue = 0;
    }
    this.setState({ amountValue: newValue });
  };

  handlePaymentChange = (e) => {
    let newValue = e.target.value;
    if (newValue < 0) {
      newValue = 0;
    }
    this.setState({ paymentValue: newValue });
  };

  handlePaymentDescriptionChange = (e) => {
    this.setState({ paymentDescription: e.target.value });
  };

  handleManualReceptChange = (e) => {
    this.setState({ mannualReceptCode: e.target.value });
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  formattedDateString = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString = `${day}-${month}-${year}`;
    return fromattedString;
  };

  formattedTimeString = (value) => {
    const time = new Date(value);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  render() {
    const {
      enrollments,
      isTableDataLoading,
      limit,
      currentPage,
      totalEnrollments,
      breadcrumb2,
      nodeTitle,
      amountLoading,
      paymentLoading,
      enrollmentLoading,
      editVisibility,
      addVisibility,
      deleteVisibility,
      payments,
      otherEnrollments,
      user_list_restriction,
      offset,
    } = this.state;
    this.validator.purgeFields();
    const formattedBreadcrumb = breadcrumb2
      ? breadcrumb2.replaceAll(">", " > ")
      : "";
    return (
      <div style={{ height: "90vh", overflow: "auto" }}>
        <ContentWrapper>
          <div>
            {this.props.state.path !== "node" && (
              <CardHeader>
                <p
                  className="pb-4"
                  style={{ fontSize: "10.5625px", color: "#00000099" }}
                >
                  {formattedBreadcrumb
                    ? `${formattedBreadcrumb && formattedBreadcrumb}`
                    : ""}
                </p>
                <div>
                  <CardTitle cardLabel={"Payments"} />
                </div>
              </CardHeader>
            )}
            {user_list_restriction ? (
              <OwnerRestrictedViewPayment
                addVisibility={addVisibility}
                editVisibility={editVisibility}
                deleteVisibility={deleteVisibility}
                amount={this.state.amount?.amount}
                paymentValue={this.state.paymentValue}
                paymentDescription={this.state.paymentDescription}
                mannualReceptCode={this.state.mannualReceptCode}
                payments={this.state.payments}
                studentDiscount={this.state.studentDiscount}
                paymentLoading={this.state.paymentLoading}
                handlePaymentChange={this.handlePaymentChange}
                submitPayment={this.submitPayment}
                formattedDateString={this.formattedDateString}
                formattedTimeString={this.formattedTimeString}
                amountToLRK={this.amountToLRK}
                totalPaid={this.totalPaid}
                calOutstandingAmount={this.calOutstandingAmount}
                otherEnrollments={otherEnrollments}
                getPayment={(s_id, mid) => this.getPayment(s_id, mid)}
                state={this.props.state}
                handlePaymentDescriptionChange={(e) =>
                  this.handlePaymentDescriptionChange(e)
                }
                handlePaymentMethodSelect={(meth) => {
                  this.handlePaymentMethodSelect(meth);
                }}
                selectedPaymentMethod={this.state.selectedPaymentMethod}
                paymentMethods={this.state.paymentMethods}
              />
            ) : (
              <CardBody>
                {amountLoading ? (
                  <div class="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <div className="col-md-12 px-0 d-flex justify-content-between">
                    <div className="row" style={{ width: "100%" }}>
                      {editVisibility && (
                        <h3 className="main-payment-total col-12 col-md-8 m-0">
                          {" "}
                          Amount: {this.amountToLRK(this.state.amount?.amount)}
                        </h3>
                      )}
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2 d-flex justify-content-end col-md-4 col-12 px-0"
                      >
                        {editVisibility && (
                          <div className="d-flex">
                            <div className="no-arrow">
                              <TextField
                                type="number"
                                size="small"
                                value={this.state.amountValue}
                                placeholder="Fee"
                                onChange={(e) => this.handleAmountChange(e)}
                                variant="outlined"
                                inputProps={{ min: 0 }}
                              />
                            </div>
                            <div className="d-flex align-items-center">
                              <Button
                                className="insert-btn"
                                variant="primary"
                                disabled={this.state.amountValue?.length < 1}
                                onClick={this.submitAmount}
                                style={{ height: "36px", marginLeft: "12px" }}
                              >
                                {this.state.amount?.amount !== undefined
                                  ? "Update"
                                  : "Set Amount"}
                              </Button>
                            </div>
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                )}
                {!user_list_restriction && <br />}
                {!user_list_restriction && <br />}
                {/* {isLoading && (
                  <PageSpinner id="show" text="Loading, Please wait!" />
                )} */}

                {!amountLoading && !user_list_restriction && (
                  <Form.Group
                    controlId="exampleForm.ControlInput1"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      onChange={this.handleSearch}
                      isInvalid={this.state.searchError}
                      // style={{ width: "18%" }}
                      value={this.state.search_term}
                      className="col-md-3 col-12"
                    />
                    {this.state.searchError && (
                      <Form.Control.Feedback type="invalid">
                        Invalid input
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )}
                {isTableDataLoading ? (
                  <div className="loading-container">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : !!enrollments.length ? (
                  <>
                    <div>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {!this.props.state?.user_list_restriction && (
                              <>
                                <TableCell className="thead">
                                  User Code
                                </TableCell>
                                <TableCell
                                  className="thead"
                                  style={{ cursor: "pointer" }}
                                >
                                  Name{" "}
                                </TableCell>
                              </>
                            )}
                            <TableCell className="thead">
                              Payment Details
                            </TableCell>

                            <TableCell className="thead">
                              Applied Discount
                            </TableCell>
                            <TableCell className="thead">
                              Outstanding Amount
                            </TableCell>
                            <TableCell className="thead">
                              Discount Detail
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {enrollments?.map((enrollment) => (
                            <TableRow key={enrollment.id}>
                              {!this.props.state?.user_list_restriction && (
                                <>
                                  <TableCell className="tData">
                                    {enrollment.user_code}
                                  </TableCell>
                                  <TableCell className="tData">
                                    {enrollment.first_name +
                                      " " +
                                      enrollment.last_name}
                                  </TableCell>
                                </>
                              )}
                              <TableCell>
                                <div className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn">
                                  <IconButton
                                    onClick={() =>
                                      this.togglePaymentsModal(enrollment)
                                    }
                                  >
                                    <AccountBalanceWalletIcon
                                      style={{
                                        color: "#A2A5B5",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </div>
                              </TableCell>
                              <TableCell className="tData">
                                {enrollment?.discount
                                  ? `${enrollment?.discount} %`
                                  : "None"}
                              </TableCell>
                              <TableCell className="tData">
                                {this.amountToLRK(
                                  this.calOutstandingAmount(
                                    this.state.amount?.amount,
                                    enrollment.total_amount,
                                    enrollment?.discount
                                  )
                                )}
                              </TableCell>
                              <TableCell>
                                <div className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn">
                                  <IconButton
                                    onClick={() =>
                                      this.toggleDiscountModal(enrollment)
                                    }
                                  >
                                    <EditNoteIcon
                                      style={{
                                        color: "#A2A5B5",
                                        fontSize: "20px",
                                      }}
                                    />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {!user_list_restriction && (
                        <MaterialCustomTablePagination
                          totalCount={parseInt(totalEnrollments)}
                          currentPage={currentPage}
                          limit={limit}
                          handlePageChange={this.handlePageChange}
                          handleRowsPerPageChange={this.handleRowsPerPageChange}
                          handlePageOffsetChange={this.handlePageOffsetChange}
                          rowsPerPageOptions={[5, 10, 20]}
                          offset={offset}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <div className="text-center mb-5 mt-10">
                    <label>No User to Select </label>
                  </div>
                )}
              </CardBody>
            )}
          </div>
        </ContentWrapper>
        <Modal
          //   size="md"
          dialogClassName="modal-90w"
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
          show={this.state.showInsertModal}
          onHide={() =>
            this.setState({
              showInsertModal: false,
              payments: null,
              paymentLoading: true,
              enrollmentLoading: false,
              selectedStudent: undefined,
              paymentValue: undefined,
              paymentDescription: undefined,
              mannualReceptCode: undefined,
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Payment Info</Modal.Title>
          </Modal.Header>
          {paymentLoading || enrollmentLoading ? (
            <div className="loading-container">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <Modal.Body>
              <>
                <h2 className="payment-total">
                  <div className=" d-flex justify-content-between">
                    <p style={{ fontSize: "15px" }}>Amount</p>
                    <p>{this.amountToLRK(this.state.amount?.amount)}</p>
                  </div>
                  <div className=" d-flex justify-content-between">
                    <p style={{ fontSize: "12px" }}>Applied Discount</p>
                    <p style={{ fontSize: "12px" }}>{`${
                      this.state.selectedStudent?.discount
                        ? this.state.selectedStudent?.discount
                        : 0
                    } %`}</p>
                  </div>
                  <div className=" d-flex justify-content-between">
                    <p style={{ fontSize: "12px" }}>Discounted Amount</p>
                    <p style={{ fontSize: "12px" }}>
                      {this.amountToLRK(
                        (this.state.amount?.amount / 100) *
                          (this.state.selectedStudent?.discount
                            ? this.state.selectedStudent?.discount
                            : 0)
                      )}
                    </p>
                  </div>
                  <div className=" d-flex justify-content-between">
                    <p style={{ fontSize: "12px" }}>
                      Amount after discount applied
                    </p>
                    <p style={{ fontSize: "12px" }}>
                      {this.amountToLRK(
                        this.state.amount?.amount -
                          (this.state.amount?.amount / 100) *
                            (this.state.selectedStudent?.discount
                              ? this.state.selectedStudent?.discount
                              : 0)
                      )}
                    </p>
                  </div>
                </h2>
                {addVisibility && (
                  <div className="row d-flex justify-content-between">
                    <div className="col-md-4 px-0 no-arrow">
                      <Dropdown data-bs-theme="dark">
                        <Dropdown.Toggle
                          id="dropdown-button-dark-example1"
                          variant="secondary"
                          style={{
                            width: "95%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontFamily: "Poppins, sans-serif",
                            backgroundColor: "#fff",
                          }}
                        >
                          {!!this.state.selectedPaymentMethod
                            ? this.state.selectedPaymentMethod.name
                            : "Payment Method"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          style={{
                            maxHeight: "190px",
                            overflowY: "auto",
                            width: "100%",
                          }}
                        >
                          {this.state.paymentMethods?.map((meth) => (
                            <Dropdown.Item
                              key={meth.payment_method_id}
                              onClick={() =>
                                this.handlePaymentMethodSelect(meth)
                              }
                            >
                              <div className="col-6">{meth.name}</div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-md-8 col-12 px-0 no-arrow">
                      <TextField
                        size="small"
                        type="number"
                        placeholder="Enter Payment"
                        onChange={(e) => this.handlePaymentChange(e)}
                        variant="outlined"
                        value={this.state.paymentValue}
                        inputProps={{ min: 0 }}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <br />
                    <div className="col-md-12 col-12 my-2 px-0 no-arrow">
                      <TextField
                        size="small"
                        type="text"
                        placeholder="Payment Description"
                        onChange={(e) => this.handlePaymentDescriptionChange(e)}
                        variant="outlined"
                        value={this.state.paymentDescription}
                        style={{ width: "100%" }}
                      />
                    </div>
                    {customer === 'CGC' && (
                      <div className="col-md-12 col-12 my-2 px-0 no-arrow">
                        <TextField
                          size="small"
                          type="text"
                          placeholder="Manual Receipt Code"
                          onChange={(e) => this.handleManualReceptChange(e)}
                          variant="outlined"
                          value={this.state.mannualReceptCode}
                          style={{ width: "100%" }}
                        />
                      </div>
                    )}

                    <div className="w-100 d-flex justify-content-end">
                      <Button
                        className="insert-btn"
                        variant="primary"
                        disabled={this.state.paymentValue?.length < 1}
                        onClick={this.submitPayment}
                        style={{ height: "36px" }}
                      >
                        Add Payment
                      </Button>
                    </div>
                  </div>
                )}
              </>
              <h3>Payment History</h3>
              {payments?.length > 0 ? (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="thead">Date & Time</TableCell>
                        <TableCell className="thead ">Receipt Code</TableCell>
                        <TableCell className="thead ">Type</TableCell>
                        {customer === 'CGC' && (
                        <TableCell className="thead ">Manual Receipt Code</TableCell>
                        )}
                        <TableCell className="thead ">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments?.map((payment) => {
                        return (
                          <TableRow key={payment.id}>
                            <TableCell>
                              <div>
                                {this.formattedDateString(payment.created_at)
                                  ? this.formattedDateString(
                                      TimeZoneHandler.convertTime(
                                        payment.created_at
                                      )
                                    )
                                  : undefined}
                              </div>
                              <div>
                                {this.formattedTimeString(payment.created_at)
                                  ? this.formattedTimeString(
                                      TimeZoneHandler.convertTime(
                                        payment.created_at
                                      )
                                    )
                                  : undefined}
                              </div>
                            </TableCell>
                            <TableCell className="">
                              {payment?.recipt_code || "-"}
                            </TableCell>
                            <TableCell className="">
                              {payment?.payment_method}
                            </TableCell>
                            {customer === 'CGC' && (
                            <TableCell className="">
                              {payment?.manual_recipt_code || "-"}
                            </TableCell>
                            )}
                            <TableCell className="">
                              {this.amountToLRK(payment?.payment)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <div className="row d-flex justify-content-between py-3 px-5 payment-total">
                    <p>Total</p>
                    <p>
                      <b>{`${this.amountToLRK(this.totalPaid())}`}</b>
                    </p>
                  </div>
                  <div className="row d-flex justify-content-between py-3 px-5 outstanding-total">
                    <p>Outstanding Amount</p>
                    <p>
                      <b>
                        {this.amountToLRK(
                          this.calOutstandingAmount(
                            this.state.amount?.amount,
                            this.totalPaid(),
                            this.state.selectedStudent?.discount
                          )
                        )}
                      </b>
                    </p>
                  </div>
                </>
              ) : (
                <div className="text-center mb-5 mt-10">
                  <label>No Payments Yet </label>
                </div>
              )}
              <h3>Other Enrollments</h3>
              {otherEnrollments.length > 0 ? (
                otherEnrollments.map((enrollment) => (
                  <p>
                    {enrollment.breadcrumb}
                    {enrollment.tree_main_title}
                  </p>
                ))
              ) : (
                <div className="text-center mb-5 mt-10">
                  <label>No Other Enrollments </label>
                </div>
              )}
            </Modal.Body>
          )}
          <Modal.Footer>
            <Button
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={() =>
                this.setState({
                  showInsertModal: false,
                  payments: null,
                  paymentLoading: true,
                  enrollmentLoading: false,
                  selectedStudent: undefined,
                  paymentValue: undefined,
                  paymentDescription: undefined,
                })
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {
          <DiscountModal
            editVisibility={editVisibility}
            showDiscountModal={this.state.showDiscountModal}
            toggleDiscountModal={this.toggleDiscountModal}
            selectedStudent={this.state.selectedStudent}
            closeBtnStyle={closeBtnStyle}
            state={this.props.state}
            getAllEnrollments={() => this.getAllEnrollments()}
          />
        }
      </div>
    );
  }
}
