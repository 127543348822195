import {assessmentTypes} from '../types';

const initialState = {
    text: '',
    loading: false,
    error: '',
    success: '',
    assessment: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case assessmentTypes.CREATE_ASSESSMENT:
            return {
                ...state,
                text: 'Creating Assessment!',
                loading: true
            }
        case assessmentTypes.CREATE_ASSESSMENT_REFRESH:
            return {
                ...state,
                text: '',
                loading: false,
                error: '',
                success: '',
                assessment: ''
            }
        case assessmentTypes.CREATE_ASSESSMENT_REQUEST:
            return {
                ...state,
                text: action.content,
                loading: true
            }
        case assessmentTypes.CREATE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                text: action.assessment,
                loading: false,
                success: 'Assessment Created Successfully!',
                assessment: action.assessment
            }
        case assessmentTypes.CREATE_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }

        case assessmentTypes.SCHEDULE_ASSESSMENT_REFRESH:
            return {
                ...state,
                text: '',
                loading: false,
                error: '',
                success: '',
                assessment: action.assessment
            }
        case assessmentTypes.SCHEDULE_ASSESSMENT:
            return {
                ...state,
                text: 'Scheduling Assessment!',
                loading: true
            }
        case assessmentTypes.SCHEDULE_ASSESSMENT_REQUEST:
            return {
                ...state,
                text: action.content,
                loading: true
            }
        case assessmentTypes.SCHEDULE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                text: action.assessment,
                loading: false,
                success: 'Assessment scheduled successfully!'
            }
        case assessmentTypes.SCHEDULE_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.ASSIGN_ASSESSORS_ASSESSMENT_REFRESH:
            return {
                ...state,
                text: '',
                loading: false,
                error: '',
                success: '',
                assessment: action.assessment
            }
        case assessmentTypes.ASSIGN_ASSESSORS_ASSESSMENT:
            return {
                ...state,
                text: 'Assigning Assessors for Assessment!',
                loading: true
            }
        case assessmentTypes.ASSIGN_ASSESSORS_ASSESSMENT_REQUEST:
            return {
                ...state,
                text: action.content,
                loading: true
            }
        case assessmentTypes.ASSIGN_ASSESSORS_ASSESSMENT_SUCCESS:
            return {
                ...state,
                text: action.assessment,
                loading: false,
                success: 'Assessors were added to the appAssessment successfully!'
            }
        case assessmentTypes.ASSIGN_ASSESSORS_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }

        default:
            return state
    }
}