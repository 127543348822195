import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const TermService = {
  LanguageGetAll,
  TermsGetAll,
  TermsById,
  addTerms,
  updateTerms
};

async function LanguageGetAll() {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    }
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/languages/getAll/`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function TermsGetAll() {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    }
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/user/accept-terms/getAll/`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function TermsById(id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/user/accept-terms/get/${id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function addTerms(obj) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),    
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/user/accept-terms/add/`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateTerms(obj) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),    
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/user/accept-terms/update/${obj.term_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
