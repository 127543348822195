import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../../../components/admin/css/common.css";
import { history } from "../../../helper/history.js";
import ReportCard from "./ReportCard.js";
import MarkEntrySheet from "./MarkEntrySheet.js";
import { TreeMainService } from "../../../service/manage/h_tree/tree_main.service.js";
import Spinner from "react-bootstrap/Spinner";
import config from "../../../helper/config.js";
import { customerConstants } from "../../../helper/constants.js";
import ManageReportTitle from "./ManageReportTitle.js";
import ManageReportSubject from "./ManageReportSubject.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let tabTextColor;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    tabTextColor = "var(--maincolor)";
    break;
  case customerConstants.EXO_CUSTOMER2:
    tabTextColor = "var(--maincolor-cmc)";
    break;
  default:
    tabTextColor = "var(--maincolor)";
    break;
}

const privileges = {
  tabVisibility: false,
  addVisibility: false,
  editVisibility: false,
  deleteVibility: false,
  viewVisibility: false,
  isAdmin: false,
  isStudent: false,
  isTeacher: false,
};

export default class MainManageMarkSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      showInsertModal: false,
      showModal: false,
      isLoading: false,
      isTableDataLoading: true,
      isModified: false,
      selectedStudentId: "",
      selectedStudentTable: "",
      selectedStudent: null,
      search_id: "",
      isStudentsLoading: false,
      isSubmitting: false,
      registration_id: "",
      editVisibility: false,
      addVisibility: false,
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      selectedStudent: null,
      selectedPackage: null,
      userType: "",
      reportCardPrivilege: privileges,
      markEntrysheetPrivilage: privileges,
      manageReportTitlePrivilage: privileges,
      manageReportSubjectPrivilage: privileges,
      privilageLoading: true,
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  componentDidMount() {
    const {
      privilege_add,
      privilege_edit,
      breadcrumb2,
      nodeTitle,
      flg_list_active,
      privilege_view,
      privilege_delete,
      lg_user_id,
      lg_user_table_id,
    } = this.props.state;

    const userType = sessionStorage.getItem("userType");

    TreeMainService.getUserType(lg_user_table_id).then((response) => {
      this.setState(
        {
          breadcrumb2: breadcrumb2,
          nodeTitle,
          flg_list_active,
          userType: userType,
        },
        () => {
          this.getVisibilityCheck(privilege_add, privilege_edit);
          this.setRoleBasedPrivilege(
            privilege_add,
            privilege_edit,
            privilege_view,
            privilege_delete
          );
        }
      );
    });
  }

  getVisibilityCheck(privilege_add, privilege_edit) {
    if (privilege_add == "1") {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_edit == "1") {
      this.setState({
        editVisibility: true,
      });
    }
  }

  setRoleBasedPrivilege(
    privilege_add,
    privilege_edit,
    privilege_view,
    privilege_delete
  ) {
    const { userType } = this.state;
    const reportCardPrivilege = {
      tabVisibility: privilege_view,
      addVisibility: privilege_add,
      editVisibility: privilege_edit,
      deleteVibility: privilege_delete,
      viewVisibility: privilege_view,
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isTeacher: userType === "teacher",
    };

    const manageReportTitlePrivilage = {
      tabVisibility: privilege_edit || privilege_delete,
      addVisibility: privilege_add,
      editVisibility: privilege_edit,
      deleteVibility: privilege_delete,
      viewVisibility: privilege_view,
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isTeacher: userType === "teacher",
    };

    const markEntrysheetPrivilage = {
      tabVisibility: privilege_add || privilege_edit || privilege_delete,
      addVisibility: privilege_add,
      editVisibility: privilege_edit,
      deleteVibility: privilege_delete,
      viewVisibility: privilege_view,
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isTeacher: userType === "teacher",
    };

    const manageReportSubjectPrivilage = {
      tabVisibility:privilege_edit || privilege_delete,
      addVisibility: privilege_add,
      editVisibility: privilege_edit,
      deleteVibility: privilege_delete,
      viewVisibility:privilege_view,
      isAdmin: userType === "admin",
      isStudent: userType === "student",
      isTeacher: userType === "teacher",
    };

    this.setState({
      reportCardPrivilege: reportCardPrivilege,
      markEntrysheetPrivilage: markEntrysheetPrivilage,
      manageReportTitlePrivilage: manageReportTitlePrivilage,
      manageReportSubjectPrivilage: manageReportSubjectPrivilage,
      privilageLoading: false,
      activeTab:
        userType === "admin"
          ? 0
          : userType === "student"
          ? 3
          : userType === "teacher"
          ? 3
          : 2,
    });
  }

  render() {
    const path = history.location.pathname;


   

    const {
      reportCardPrivilege,
      markEntrysheetPrivilage,
      privilageLoading,
      manageReportTitlePrivilage,
      manageReportSubjectPrivilage,
      userType,
    } = this.state;
    return (
      <div style={{ height: "90vh", overflow: "auto" }}>
        <ContentWrapper disabled={path === "/manage-search"}>
          {!userType ? (
            <div className="loading-container">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div>
              {userType && userType === "admin" ? (
                <div>
                  <Tabs
                    value={this.state.activeTab}
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      "& .MuiTab-root": {
                        fontFamily: "Poppins, sans-serif",
                        textTransform: "unset",
                        letterSpacing: "-0.4px",
                        fontWeight: "600",
                      },
                      "& .Mui-selected": {
                        color: tabTextColor + " !important",
                      },
                      borderRight: 1,
                      borderColor: "divider",
                      backgroundColor: "#fff",
                    }}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: tabTextColor,
                      },
                    }}
                  >
                    {manageReportSubjectPrivilage.tabVisibility && (
                      <Tab label="Manage Report Module" />
                    )}
                    {manageReportTitlePrivilage.tabVisibility && (
                      <Tab label="Manage Report Title" />
                    )}
                    {markEntrysheetPrivilage.tabVisibility && (
                      <Tab label="Marks Entry Sheet" />
                    )}
                    {reportCardPrivilege.tabVisibility && (
                      <Tab label="Report Card" />
                    )}
                  </Tabs>

                  {!privilageLoading &&
                    this.state.activeTab === 0 &&
                    manageReportSubjectPrivilage && (
                      <ManageReportSubject
                        props={this.props.state}
                        manageReportSubjectPrivilage={
                          manageReportSubjectPrivilage
                        }
                      ></ManageReportSubject>
                    )}

                  {!privilageLoading &&
                    this.state.activeTab === 1 &&
                    manageReportTitlePrivilage && (
                      <ManageReportTitle
                        props={this.props.state}
                        manageReportTitlePrivilage={manageReportTitlePrivilage}
                      ></ManageReportTitle>
                    )}
                  {!privilageLoading &&
                    this.state.activeTab === 2 &&
                    markEntrysheetPrivilage && (
                      <MarkEntrySheet
                        props={this.props.state}
                        markEntrysheetPrivilage={markEntrysheetPrivilage}
                      ></MarkEntrySheet>
                    )}
                  {!privilageLoading &&
                    this.state.activeTab === 3 &&
                    reportCardPrivilege && (
                      <ReportCard
                        props={this.props.state}
                        reportCardPrivilege={reportCardPrivilege}
                      ></ReportCard>
                    )}
                </div>
              ) : (
                <div>
                  <Tabs
                    value={this.state.activeTab}
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      "& .MuiTab-root": {
                        fontFamily: "Poppins, sans-serif",
                        textTransform: "unset",
                        letterSpacing: "-0.4px",
                        fontWeight: "600",
                      },
                      "& .Mui-selected": {
                        color: tabTextColor + " !important",
                      },
                      borderRight: 1,
                      borderColor: "divider",
                      backgroundColor: "#fff",
                    }}
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: tabTextColor,
                      },
                    }}
                  >
                  {manageReportSubjectPrivilage.tabVisibility && (
                    <Tab label="Manage Report Module" value={0} />
                  )}
                  {manageReportTitlePrivilage.tabVisibility && (
                    <Tab label="Manage Report Title" value={1} />
                  )}
                  {markEntrysheetPrivilage.tabVisibility && (
                    <Tab label="Marks Entry Sheet" value={2} />
                  )}
                  {reportCardPrivilege.tabVisibility && (
                    <Tab label="Report Card" value={3} />
                  )}
                  </Tabs>

                  {!privilageLoading &&
                    this.state.activeTab === 0 &&
                    manageReportSubjectPrivilage && (
                      <ManageReportSubject
                        props={this.props.state}
                        manageReportSubjectPrivilage={
                          manageReportSubjectPrivilage
                        }
                      ></ManageReportSubject>
                    )}

                  {!privilageLoading &&
                    this.state.activeTab === 1 &&
                    manageReportTitlePrivilage && (
                      <ManageReportTitle
                        props={this.props.state}
                        manageReportTitlePrivilage={manageReportTitlePrivilage}
                      ></ManageReportTitle>
                    )}

                  {!privilageLoading &&
                    this.state.activeTab === 2 &&
                    markEntrysheetPrivilage && (
                      <MarkEntrySheet
                        props={this.props.state}
                        markEntrysheetPrivilage={markEntrysheetPrivilage}
                      ></MarkEntrySheet>
                    )}
                  {!privilageLoading &&
                    this.state.activeTab === 3 &&
                    reportCardPrivilege && (
                      <ReportCard
                        props={this.props.state}
                        reportCardPrivilege={reportCardPrivilege}
                      ></ReportCard>
                    )}
                </div>
              )}
            </div>
          )}
        </ContentWrapper>
      </div>
    );
  }
}
