import React from "react";

export default function Select({ref, children, selectId, name, defaultValue, value, selected, multiple, dataPlaceHolder, dataActionsBox, dataSize, dataLiveSearch, onChange, className, selectWrap, disabled, spinner, style }) {


    return (
        <div className={selectWrap}>
            <div className="spinner spinner-track spinner-primary spinner-right" hidden={!spinner} />
            <select className={className}
            ref={ref}
                id={selectId}
                name={name}
                defaultValue={defaultValue}
                value={value}
                selected={selected}
                onChange={onChange}
                multiple={multiple}
                data-placeholder={dataPlaceHolder}
                data-actions-box={dataActionsBox}
                data-size={dataSize}
                data-live-search={dataLiveSearch}
                style={style}
                disabled={disabled}>
                {children}
            </select>
        </div>
    )
}
