import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const StudentAddressTypeService = {
  create,
  update,
  getAllWithoutPagination,
  getAll,
  deleteType,
  saveDisplayOrder
};

async function create(name, description) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: name,
      description: description,
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-address-types/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(studentAddressTypeId, typeName, typeDescription, status) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: typeName,
      description: typeDescription,
      status: status
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-address-types/update/${studentAddressTypeId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllWithoutPagination() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-address-types/getAll`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAll(limit, offset, search_term) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-address-types/getAll/${limit}/${offset}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteType(studentAddressTypeId) {
  const requestOptions = {
    method: "DELETE",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-address-types/delete/${studentAddressTypeId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function saveDisplayOrder(studentAddressTypes) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(studentAddressTypes),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-address-types/save-display-order`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}