import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const EmailTemplateService = {
    addTemplate,
    getTemplate,
    deleteTemplate,
    updateTemplate,
    getAllTemplates
};

async function addTemplate(newEmail) {
    const requestOptions = {
        method: "POST",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(newEmail),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/email-templates/add-template`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getTemplate(id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/email-templates/get-template/${id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllTemplates() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/email-templates/get-all-templates`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function deleteTemplate(templateName) {
    const configOptions = {
        method: "DELETE",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/email-templates/delete-template/${templateName}`,
        configOptions
    );
    return APIHandler.handleResponse(response);
}

async function updateTemplate(updateTemplate, template_name) {
    const requestOptions = {
        method: "PUT",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(updateTemplate),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/email-templates/update-template/${template_name}`, 
        requestOptions
    );
    return APIHandler.handleResponse(response);
}
