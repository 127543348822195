import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const FeedbackService = {
  addFeedback,
  getAllFeedback,
  getFeedback,
  deleteFeedback,
  getAllFeedbackWithSorting,
  getAllFeedbackWithPaginationAndSorting
};

// Add new feedback
async function addFeedback(newFeedback) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newFeedback),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/feedbacks/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

// Get all feedback
async function getAllFeedback() {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/feedbacks/get-all`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllFeedbackWithSorting(sortColumn, sortDirection) {
    const configOptions = {
      method: "GET",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
  
    const response = await fetch(
      `${config.oat_api_url}/manage/feedbacks/get-all?sort_column=${sortColumn}&sort_direction=${sortDirection}`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }

  async function getAllFeedbackWithPaginationAndSorting(nodeId, limit, offset, sort_column, sort_direction, search_term, create_user_id, create_user_table_id, user_table_id, stu_id) {
    const configOptions = {
      method: "GET",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
  
    const response = await fetch(
      `${config.oat_api_url}/manage/feedbacks/getAll/${nodeId}?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&create_user_id=${create_user_id}&create_user_table_id=${create_user_table_id}&user_table_id=${user_table_id}&stu_id=${stu_id}`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }

// Get feedback by ID
async function getFeedback(feedbackId) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/feedbacks/get/${feedbackId}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

// Delete feedback by ID
async function deleteFeedback(feedbackId) {
  const configOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/feedbacks/delete/${feedbackId}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}
