
import React, { Component } from 'react';

class GradeNavItem extends Component {
 

  render() {
    const { children, added_by, className, id, value, dataToggle, href, onChange } = this.props;

    return (
      <li className="nav-item mb-4 mr-2 ml-2">      
        <a className={className} id={id} data-toggle={dataToggle} href={href} style={{ border: '1px solid #3699ff' }}>
          <span className="label label-inline position-absolute top-0 right-0">Added by: {added_by}</span>
          <div className="row nav-text">{children}</div>
        </a>
      </li>
    );
  }
}

export default GradeNavItem;




