import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import _, { entries } from "lodash";
import debounce from "lodash.debounce";
import Spinner from "../../components/Spinner3.js";
import SelfGradeModal from "../../components/common/modal/SelfGradeModal.js";
import ModalBody from "../../components/common/modal/ModalBody.js";
import SelfGradeModalFooter from "../../components/common/modal/SelfGradeModalFooter.js";
import ContentWrapper from "../../components/common/ContentWrapper.js";
import Card from "../../components/common/Card.js";
import CardHeader from "../../components/common/CardHeader.js";
import CardBody from "../../components/common/CardBody.js";
import SelfGradeCardTitle from "../../components/common/SelfGradeCardTitle.js";
import SelfGradeCardToolBar from "../../components/common/SelfGradeCardToolBar.js";
import SelfGradeButton from "../../components/common/form/SelfGradeButton.js";
import SelfGradeOption from "../../components/common/form/SelfGradeOption.js";
import SelfGradeCheckBox from "../../components/common/form/SelfGradeCheckBox.js";
import CheckBoxInline from "../../components/common/form/CheckBoxInline.js";
import RadioInline from '../../components/common/form/RadioInline.js';
import Radio from '../../components/common/form/Radio.js';
import GradeNavItem from "../../components/common/tabs/GradeNavItem.js";
import FinalizeTab from "../../components/common/tabs/FinalizeTab.js";
import FinalizeLeftWrap from "../../components/common/tabs/FinalizeLeftWrap.js";
import FinalizeQuestionTitle from "../../components/common/tabs/FinalizeQuestionTitle.js";
import FinalizeNavItemActions from "../../components/common/tabs/FinalizeNavItemActions.js";
import CustomEditor from "../../components/common/editor/CustomEditor.js";
import FormGroup from "../../components/common/form/FormGroup.js";
import Marks from "../../components/common/tabs/Marks.js";
import FinalizeRightWrap from "../../components/common/tabs/FinalizeRightWrap.js";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromRaw, EditorState } from "draft-js";
import { questionActions } from "../../actions/assessment/questionActions.js";
import { questionMCQAnswerTypeConstants, questionTypeConstants } from "../../helper/constants.js";
import DataHandler from "../../handlers/DataHandler.js";
import { AzureStorage } from "../../helper/azureStorage.js";
import { onSelfSetMarks } from "../../actions/candidate/testActions.js";
import {
  getAllQuestionContents,
  getAllAnswerContents,
  getAllCandidateAnswerContents,
  getCandidateAnswers,
} from "../../actions/candidate/testActions.js";
import { createActions } from "../../actions/assessment/createActions.js";
import { staffUserActions } from "../../actions/user_management/staffUserActions.js";
import { manageUserActions } from "../../actions/user_management/manageUserActions.js";
import { loadAssessmentQuestions } from "../../actions/candidate/testActions.js";
import ViewEditor from "../../components/common/ViewEditor.js";
import { candidateService } from "../../service/settings/candidate/candidateService.js";
import config from "../../helper/config.js";
import { customerConstants } from "../../helper/constants.js";
import { testService } from "../../service/candidate/testService.js";
import { sweetStatusAlert } from "../../components/common/SweetAlert.js";
import ReactSpinner from "react-bootstrap/Spinner";
import AdminTemplate from "../../components/admin/layouts/Template.js";
import { quesGradeService } from "../../service/candidate/gradeService.js";
import CheckBox from "../../components/common/form/CheckBox.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, cancelClass, confirmClass;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default";
    confirmClass = "btn-primary";
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
      boxShadow: "none",
    };
    break;
  default:
    cancelClass = "btn-default";
    confirmClass = "btn-primary";
    break;
}

class TeacherGrade extends React.Component {
  constructor(props) {
    super(props);

    this.ref_tagify = React.createRef();
    this.state = {
      assessmentQuestions: [],
      total: "",
      sum: "",
      errors: {},
      changes: false,
      setTotal: true,
      questions: "",
      changeQuestions: true,
      searchValue: "",
      selectedCategory: 0,
      selectedType: 0,
      selectedDifficulty: 0,
      chkbox: false,
      filteredQuestions: [],
      remarksEditorState: EditorState.createEmpty(),
      loadRemarks: true,
      prevMark: "",
      questionMap: {},
      loadingTeacherGradePage: false,
      selfGradingState: false,
      spinnerLoading: true,
      teacherDetailsMap: {},
      disabled: false,
      subQuestions: [],
      subQuestionsIndexes: [],
      mainQuestionIds: [],
      candidateAnswers: [],
      answerLoading: false,
      newAnswerMap: [],
      gradeLoading: false,
      ReportFinalGrade: [],
      candidateGradeScales: []
    };

    this.onSave = this.onSave.bind(this);
    this.getEditability = this.getEditability.bind(this);
    this.finalizeSelfGrade = this.finalizeSelfGrade.bind(this);
    this.addRemarks = this.addRemarks.bind(this);
    this.onChangeMarks = this.onChangeMarks.bind(this);
    this.updateMarks = debounce(this.updateMarks.bind(this), 1500);
    this.handleChangeQuestionCategory =
      this.handleChangeQuestionCategory.bind(this);
    this.handleChangeQuestionDifficulty =
      this.handleChangeQuestionDifficulty.bind(this);
    this.handleChangeQuestionType = this.handleChangeQuestionType.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.onRemarksEditorChange = this.onRemarksEditorChange.bind(this);
    this.onDownloadAttachment = this.onDownloadAttachment.bind(this);
    //this.getQuestionCreator = this.getQuestionCreator.bind(this);
  }

  onUnload = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  async componentDidMount() {
    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
    if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
    if (window.KTApp.initComponents) window.KTApp.initComponents();
    if (window.$) window.$(".selectpicker").selectpicker();
    if (typeof window.KTLayoutStickyCard !== "undefined") {
      window.KTLayoutStickyCard.update();
    } else {
      if (window.KTLayoutStickyCard)
        window.KTLayoutStickyCard.init("kt_page_sticky_card");
    }

    await this.props.loadAssessmentQuestions(
      this.props.grade_assessment?.assessment_id
    );

    await this.props.getAllQuestionContents({
      questions: this.props.assessmentQuestions,
    });
    await this.props.getAllAnswerContents({
      questions: this.props.assessmentQuestions,
    });
    await this.getReport();
    // await this.props.getCandidateAnswers(this.props.grade_assessment);
    await this.getCandidatesAnswersForTeacherGrade(); // need to change  (get candidate answers for teacher graded_by candidate_id)
    

    await this.props.getAllCandidateAnswerContents({
      // need to change
      answers: this.state.candidateAnswers,
    });

    var sum = 0;
    for (let k = 0; k <= this.props.assessmentQuestions.length - 1; k++) {
      sum = sum + this.props.assessmentQuestions[k].mark_allocation;
    }

    this.setState({ selfGradingState: true, sum: sum });

    await this.props.getStaffUsers();
    if (_.isEqual({}, this.props.staffUserMap)) {
      await this.props.createStaffUserMap();
    }
    if (
      this.props.assessmentQuestions &&
      this.props.assessmentQuestions.length &&
      !this.props.assessmentQuestions.length
    ) {
      this.setState({ assessmentQuestions: this.props.assessmentQuestions });
    }

    let subQuestionsObject = {};
    this.props.assessmentQuestions.forEach((item, index) => {
      if (item.is_subquestion === "true") {
        if (!subQuestionsObject[index]) {
          subQuestionsObject[index] = item;
        }
      }
    });
    this.setState({
      subQuestions: subQuestionsObject,
    });

    let subQuestionsIndexes = this.props.assessmentQuestions
      .map((item, index) => (item.is_subquestion === "true" ? index : null))
      .filter((index) => index !== null);
    this.setState({
      subQuestionsIndexes: subQuestionsIndexes,
    });

    let mainQuestionIds = this.props.assessmentQuestions
      .filter(
        (item) =>
          item.question_type === questionTypeConstants.SUBQUESTION.name &&
          item.is_subquestion !== "true"
      )
      .map((item) => item.question_id);
    this.setState({
      mainQuestionIds: mainQuestionIds,
    });

    if (this.props.allStaffUsers.length > 0) {
      this.setState({ loadingTeacherGradePage: true });
      var staffuser, teacher;
      await this.props.getAllTeachers();
      for (staffuser of this.props.allStaffUsers) {
        for (teacher of this.props.allTeachers)
          if (
            staffuser.staff_id === teacher.user_id &&
            staffuser.role_code === "ASSR"
          ) {
            let map = this.state.teacherDetailsMap;
            let teacherName = teacher.first_name.concat(" ", teacher.last_name);
            map[staffuser.staff_id] = teacherName;
            this.setState({ teacherDetailsMap: map });
          }
      }
      this.setState({ loadingTeacherGradePage: false });
    }

    if (this.state.candidateAnswers.length && this.state.setTotal) {
      var i,
        total = 0;
      let disabled;
      for (i = 0; i < this.state.candidateAnswers.length; i++) {
        if (this.state.candidateAnswers[i].mark_obtained) {
          total = total + this.state.candidateAnswers[i].mark_obtained;
        }
        if (
          this.state.candidateAnswers[i].mark_obtained === null ||
          this.state.candidateAnswers[i].mark_obtained === ""
        ) {
          disabled = true;
        }
      }

      this.setState({
        total: total,
        setTotal: false,
        disabled: disabled,
      });
    }

    window.onpopstate = function (event) {
      this.props.hideSelfGrade();
    };
    window.addEventListener("beforeunload", this.onUnload);

    await this.getAllCandidateGradeScales();

    this.setState({ spinnerLoading: false });
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !!Object.entries(this.state.newAnswerMap).length &&
      this.state.changeQuestions
    ) {
      var data = new Object();
      Object.assign(data, this.state.newAnswerMap);

      this.setState({
        questions: data,
        changeQuestions: false,
      });
    }

    if (
      this.props.assessmentQuestions &&
      this.props.assessmentQuestions.length &&
      !this.props.assessmentQuestions.length
    ) {
      this.setState({ assessmentQuestions: this.props.assessmentQuestions });
    }

    if (this.state.candidateAnswers.length && this.state.setTotal) {
      var i,
        total = 0;
      var disabled;
      var answers = this.answerMap;
      for (i = 0; i < this.state.candidateAnswers.length; i++) {
        var id = this.state.candidateAnswers[i].question_id;
        if (
          this.state.candidateAnswers[i].mark_obtained === null ||
          this.state.candidateAnswers[i].mark_obtained === ""
        ) {
          disabled = true;
        }
        if (
          this.state.candidateAnswers[i].mark_obtained ||
          this.state.candidateAnswers[i].mark_obtained == 0
        ) {
          total = total + this.state.candidateAnswers[i].mark_obtained;
        } else {
          this.state.candidateAnswers[i].mark_obtained = "";
        }
      }
      this.setState({
        total: total,
        setTotal: false,
        disabled: disabled,
      });
    }

    if (this.props.assessorRemarks && this.state.loadRemarks) {
      let state = JSON.parse(this.props.assessorRemarks);
      let tempQuestionState = EditorState.createWithContent(
        convertFromRaw(state)
      );
      this.setState({
        remarksEditorState: tempQuestionState,
        loadRemarks: false,
      });
    }

    window.$(".selectPicker").selectpicker("refresh");
    window.$("#question-difficulty").selectpicker("refresh");
    window.$("#question-type").selectpicker("refresh");
    window.$("#question-category").selectpicker("refresh");
  }

  async componentWillUnmount() {
    if (this.state.changes) {
      await window.Swal.fire({
        text: "You have unsaved changes in previous page!",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: !1,
        confirmButtonText: "Save changes",
        cancelButtonText: "Discard",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
          cancelButton: `btn font-weight-bold ${cancelClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement
              .querySelector(".swal2-confirm")
              .setAttribute("id", "confirmButtonId");
            modalElement
              .querySelector(".swal2-cancel")
              .setAttribute("id", "cancelButtonId");
          }
        },
      }).then(async (action) => {
        if (action.isConfirmed) {
          this.setState({
            changes: false,
          });
          const assessment_id = this.props.grade_assessment.assessment_id;
          const report_id = this.props.grade_assessment.report_id;
          const userID = sessionStorage.getItem("user_id");
          const user_role_id = "ASSR" + userID;
          const questions = this.state.candidateAnswers;
          const candidate_id = this.props.grade_assessment?.candidate_id;
          const attempt_no = this.props.grade_assessment?.attempt_no;
          const assessor_remarks = convertToRaw(
            this.state.remarksEditorState.getCurrentContent()
          );

          const obj = {
            report_id: report_id,
            assessment_id: assessment_id,
            attempt_no: attempt_no,
            candidate_id: candidate_id,
            questions: questions,
            assessor_remarks: null,
          };
      
          var arr = [];
          for (const [, value] of Object.entries(obj.questions)) {
            arr.push(value);
          }
          obj.questions = arr;
          this.setState({ gradeLoading: true });
      
          
          await quesGradeService.saveTeacherMarks(obj);
        }
      });
    }
  }

  async getCandidatesAnswersForTeacherGrade() {
    const candidate_id = this.props.grade_assessment?.candidate_id;
    const graded_by = sessionStorage.getItem("manage_user_id");

    this.setState({ answerLoading: true });

    try {
      const res = await testService.getCandidatesAnswersForTeacherGrade(
        graded_by,
        candidate_id
      );

      if (res.success) {
        const candidateAnswers = res.data.answers;
        const answerMap = {};

        candidateAnswers.forEach((answer) => {
          answerMap[answer.question_id] = answer;
        });

        this.setState({
          candidateAnswers,
          newAnswerMap: answerMap,
          answerLoading: false,
        });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Enrollments Please refresh the screen!",
          "error"
        );

        this.setState({ answerLoading: false });
      }
    } catch (error) {
      console.error("Error fetching candidate answers:", error);
      sweetStatusAlert(
        "Failed!",
        "Error fetching Enrollments Please refresh the screen!",
        "error"
      );

      this.setState({ answerLoading: false });
    }
  }

  async getReport() {
    const report_id = this.props.grade_assessment.report_id;
    try {
      const res = await quesGradeService.getAllTeachersReportsByReportId(
        report_id
      );

      if (res.success) {
        this.setState({
          ReportFinalGrade: res.data.grade_report,
        });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Reports. Please refresh the screen!",
          "error"
        );
      }
    } catch (error) {
      sweetStatusAlert(
        "Failed!",
        "Error fetching Reports. Please refresh the screen!",
        "error"
      );
    }
  }

  handleChangeChk = (e) => {
    let checked = !this.state.chkbox;
    this.setState({ chkbox: checked });
  };

  handleChangeQuestionType(e) {
    let type = e.target.value;
    this.setState({ selectedType: type });
  }

  handleChangeQuestionDifficulty(e) {
    let difficulty = e.target.value;
    this.setState({ selectedDifficulty: difficulty });
  }

  handleChangeQuestionCategory(e) {
    let category = e.target.value;
    this.setState({
      selectedCategory: category,
    });
  }

  onRemarksEditorChange(editorState) {
    this.setState({
      remarksEditorState: editorState,
      changes: true,
    });
  }

  getQuestionTypeName(qType) {
    switch (qType) {
      case questionTypeConstants.MCQ.id:
        return questionTypeConstants.MCQ.name;
      case questionTypeConstants.DESCRIPTIVE.id:
        return questionTypeConstants.DESCRIPTIVE.name;
      case questionTypeConstants.SUBMISSION.id:
        return questionTypeConstants.SUBMISSION.name;
      default:
        return "Invalid Type";
    }
  }
  getColor(val) {
    switch (val) {
      case "-1":
        return "label label-lg label-light-danger label-inline";
      case "1":
        return "label label-lg label-light-success label-inline";
      default:
        return "";
    }
  }

  async onDownloadAttachment(e, container, attach) {
    e.preventDefault();
    let fileReader;
    async function handleFileRead() {
      const content = fileReader.result;
    }

    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    let content = await AzureStorage.downloadBlob(container, attach);
    fileReader.readAsArrayBuffer(content);
  }

  getAnswerInEditor(el, i) {
    let quesIndex = this.props.assessmentQuestions.findIndex(item => item.question_id === el.question_id)
    var question_type = this.props.assessmentQuestions[quesIndex].question_type;
    var answer_type = this.props.assessmentQuestions[quesIndex].answer_type_id;
    var answer =
      this.props.contentMap[this.props.assessmentQuestions[quesIndex].question_id];
    let states = JSON.parse(this.props.questionContents[quesIndex]);
    var no_ans = states.length - 1;
    var tempAnswerStates = [];
    if (question_type == questionTypeConstants.DESCRIPTIVE.name) {
      if (answer) {
        var answerState = JSON.parse(answer);
        return (
          <p className="mb-5 overflow-hidden">
            {answerState?.blocks?.length > 0 ? (
              <p className="answer-sheet-text">
                <ViewEditor
                  editorState={EditorState.createWithContent(
                    convertFromRaw(answerState)
                  )}
                />
              </p>
            ) : (
              <p className="mt-5 stu-variant-class label label-lg label-danger label-inline my-2 answer-sheet-text">
                Not Answered
              </p>
            )}
          </p>
        );
      }
    } else if (
      question_type == questionTypeConstants.SUBQUESTION.name &&
      this.props.assessmentQuestions[quesIndex].is_subquestion === "true"
    ) {
      if (answer) {
        var answerState = JSON.parse(answer);
        return (
          <p className="mb-5 overflow-hidden answer-sheet-text">
            {answerState?.blocks?.length > 0 ? (
              <div>
                <ViewEditor
                  editorState={EditorState.createWithContent(
                    convertFromRaw(answerState)
                  )}
                />
              </div>
            ) : (
              <p className="mt-5 stu-variant-class label label-lg label-danger label-inline my-2 answer-sheet-text">
                Not Answered
              </p>
            )}
          </p>
        );
      }
    } else if (question_type == questionTypeConstants.MCQ.name) {
      if (answer) {
        answer = JSON.parse(answer);
        var anyBoxesChecked = [],
          ans;
          for (var n = 0; n < no_ans; n++) {
          anyBoxesChecked.push(false);
        }
        for (ans in answer) {
          anyBoxesChecked[answer[ans]] = true;
        }
        states.shift();
        states.forEach((state) => {
          tempAnswerStates.push(
            EditorState.createWithContent(convertFromRaw(state))
          );
        });
        let filteredIndexes = anyBoxesChecked
        .map((val, m) => val === true ? m : -1)
          .filter((index) => index !== -1);
        let result = filteredIndexes.map((index) => tempAnswerStates[index]);
        return (
          <div>
            {result?.length > 0 ? (
              <div
                className={`RichEditor-root p-0 ${
                  tempAnswerStates.some(
                    (answer) =>
                      answer.getCurrentContent().getPlainText().length > 20
                  )
                    ? "single-column"
                    : ""
                }`}
              >
                {result.map((answer, j) => {
                  return (
                    <div className="row">
                      <div className="col-md-1">
                        {answer_type = questionMCQAnswerTypeConstants.One.id ?
                          <RadioInline className="">
                            <Radio
                              key={j}
                              checked={true}
                              style={{
                                color: "#5458AF",
                              }}
                            />
                          </RadioInline>
                          :
                          <CheckBoxInline className="">
                            <CheckBox
                              key={j}
                              checked={true}
                              style={{
                                color: "#5458AF",
                              }}
                            />
                          </CheckBoxInline>
                        }
                      </div>
                      <p className="col-md-11 answer-sheet-text">
                        <ViewEditor
                          editorState={answer}
                        />
                      </p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="stu-variant-class label label-lg label-danger label-inline mb-2 mt-4 answer-sheet-text">
                Not Answered
              </p>
            )}
            {/* <div class="separator separator-dashed separator-border-2 mt-5 mb-5"></div> */}
          </div>
        );
      }
    } else if (question_type == questionTypeConstants.SUBMISSION.name) {
      let attachmentName = answer
        .replace(/^\[(.+)\]$/, "$1")
        .replace(/"\"$/, "$1")
        .split(",");
      const container = "".concat(
        this.props.grade_assessment?.candidate_id,
        "-",
        this.props.assessmentQuestions[quesIndex].question_id
      );
      const container_name = config.storageBlobConfigs.containerName;

      if (attachmentName[0] !== "[]") {
        let itemList = [];
        {
          attachmentName.map((attach) => {
            if (attach.replace(/"/g, "") != "[]") {
              itemList.push(
                <div>
                  <SelfGradeButton
                    className="btn mr-2 px-0 pt-3 submission-document"
                    btnIconStyle="flaticon-interface-3"
                    // inlineStyle={{ innerWidth: "400px" }}
                    btnName={attach.replace(/"/g, "")}
                    onClick={(e) => {
                      this.onDownloadAttachment(
                        e,
                        container_name,
                        container + "-" + attach.replace(/"/g, "")
                      );
                    }}
                  />
                  <br></br>
                </div>
              );
            }
          });
        }
        return <div className="RichEditor-root col-md-8 p-0">{itemList}</div>;
      } else {
        return (
          <p className="mt-4 stu-variant-class label label-lg label-danger label-inline my-2 answer-sheet-text">
            No answer uploaded!
          </p>
        );
      }
    }
  }

  getContentInEditor(el, i) {
    let quesIndex = this.props.assessmentQuestions.findIndex(item => item.question_id === el.question_id)
    var question_type = this.props.assessmentQuestions[quesIndex].question_type;
    let states = JSON.parse(this.props.questionContents[quesIndex]);
    let tempAnswerStates = [];
    let tempQuestionState = EditorState.createWithContent(
      convertFromRaw(states[0])
    );
    if (question_type == questionTypeConstants.DESCRIPTIVE.name) {
      return (
        <p className="mb-5 overflow-hidden answer-sheet-text">
          <div>
            <ViewEditor editorState={tempQuestionState} />
          </div>
        </p>
      );
    }
    if (question_type == questionTypeConstants.SUBQUESTION.name) {
      return (
        <p className="mb-5 overflow-hidden answer-sheet-text">
          <div>
            <ViewEditor editorState={tempQuestionState} />
          </div>
        </p>
      );
    } else if (question_type == questionTypeConstants.MCQ.name) {
      states.shift();
      states.forEach((state) => {
        tempAnswerStates.push(
          EditorState.createWithContent(convertFromRaw(state))
        );
      });
      return (
        <div>
          <p className="mb-5 overflow-hidden answer-sheet-text">
            <div>
              <ViewEditor editorState={tempQuestionState} />
            </div>
          </p>
          <div>
            <div
              className={`RichEditor-root px-0 py-0 parent-class ${
                tempAnswerStates.some(
                  (answer) =>
                    answer.getCurrentContent().getPlainText().length > 20
                )
                  ? "single-column"
                  : ""
              }`}
            >
              {tempAnswerStates.map((answer, j) => {
                return (
                  <div className="d-flex align-items-center">
                    <div className="col-md-1">
                      <RadioInline className="">
                        <Radio
                          key={j}
                          checked={false}
                          style={{
                            color: "#5458AF",
                          }}
                        />
                      </RadioInline>
                    </div>
                    <p className="col-md-11 answer-sheet-text">
                      <Editor
                        editorState={answer}
                        toolbarHidden={true}
                        toolbarClassName="hide-toolbar"
                        readOnly={true}
                      />
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div class="separator separator-dashed separator-border-2 mt-5 mb-5"></div> */}
        </div>
      );
    } else if (question_type == questionTypeConstants.SUBMISSION.name) {
      return (
        <div>
          <p className="mb-5 overflow-hidden answer-sheet-text">
            <ViewEditor editorState={tempQuestionState} />
          </p>
          {/* <div class="separator separator-dashed separator-border-2 mt-5 mb-5"></div> */}
        </div>
      );
    }
  }

  getAssessorAnswerInEditor(el, i) {
    let quesIndex = this.props.assessmentQuestions.findIndex(item => item.question_id === el.question_id)
    var question_type = this.props.assessmentQuestions[quesIndex].question_type,
      answerState;
    // let container = "".concat(
    //   this.props.assessmentQuestions[quesIndex].created_by,
    //   "-sa-",
    //   this.props.assessmentQuestions[quesIndex].answer
    // );
    let container = config.storageBlobConfigs.containerName;
    let states = JSON.parse(this.props.questionContents[quesIndex]);
    var tempAnswerStates = [];
    if (question_type == questionTypeConstants.DESCRIPTIVE.name) {
      var answer = JSON.parse(this.props.answerContents[quesIndex]);
      return (
        <p className="mb-5 overflow-hidden">
          <p className="answer-sheet-text">
            <ViewEditor
              editorState={EditorState.createWithContent(
                convertFromRaw(answer[0])
              )}
            />
          </p>
        </p>
      );
    } else if (question_type == questionTypeConstants.SUBQUESTION.name) {
      var ans = this.props.answerContents[quesIndex];
      if (ans) {
        var answer = JSON.parse(this.props.answerContents[quesIndex]);
        return (
          <p className="mb-5 overflow-hidden answer-sheet-text">
            <div>
              <ViewEditor
                editorState={EditorState.createWithContent(
                  convertFromRaw(answer[0])
                )}
              />
            </div>
          </p>
        );
      } else {
        return (
          <p className="mt-4 answer-sheet-text">This is the main question</p>
        );
      }
    } else if (question_type == questionTypeConstants.MCQ.name) {
      var res = this.props.answerContents[quesIndex]?.split(",");
      states.shift();
      states.forEach((state) => {
        tempAnswerStates.push(
          EditorState.createWithContent(convertFromRaw(state))
        );
      });
      let filteredIndexes = res
        .map((val, i) => (val > 0 ? i : -1))
        .filter((index) => index !== -1);
      let result = filteredIndexes.map((index) => tempAnswerStates[index]);
      return (
        <div>
          <div className="RichEditor-root px-0 py-0">
            {result.map((answer, j) => {
              let val = "0";
              if (parseFloat(res[j]) > 0) {
                val = "1";
              } else if (parseFloat(res[j]) < 0) {
                val = "-1";
              }
              return (
                <div className="row mt-1">
                  <div className="col-md-1">
                    <RadioInline className="">
                      <Radio
                        key={j}
                        checked={true}
                        style={{
                          color: "#5458AF",
                        }}
                      />
                    </RadioInline>
                  </div>
                  <p className="col-md-11 answer-sheet-text">
                    <ViewEditor
                      editorState={answer}
                    />
                  </p>
                  {/* <div className={this.getColor("1")}>
                    {parseFloat(res[j]) || ""}
                  </div> */}
                </div>
              );
            })}
          </div>
          {/* <div class="separator separator-dashed separator-border-2 mt-5 mb-5"></div> */}
        </div>
      );
    } else if (question_type == questionTypeConstants.SUBMISSION.name) {
      let attachmentName = this.props.answerContents[quesIndex]
        .replace(/^\[(.+)\]$/, "$1")
        .replace(/"\"$/, "$1")
        .split(",");

      if (attachmentName.length) {
        let itemList = [];
        {
          attachmentName.map((attach) => {
            if (attach.replace(/"/g, "") != "[]") {
              itemList.push(
                <div>
                  <SelfGradeButton
                    className="btn mr-2 px-0 pt-3 submission-document"
                    btnIconStyle="flaticon-interface-3"
                    // inlineStyle={{ innerWidth: "400px" }}
                    btnName={attach.replace(/"/g, "")}
                    onClick={(e) => {
                      this.onDownloadAttachment(
                        e,
                        container,
                        attach.replace(/"/g, "")
                      );
                    }}
                  />
                  <br></br>
                </div>
              );
            }
          });
        }
        return <div className="RichEditor-root col-md-8 p-0">{itemList}</div>;
      } else {
        return (
          <p className="stu-variant-class label label-lg label-danger label-inline my-2 answer-sheet-text">
            No answer uploaded!
          </p>
        );
      }
    }
  }

  getAssessorAnswerGuidanceInEditor(question, i) {
    let quesIndex = this.props.assessmentQuestions.findIndex(item => item.question_id === question.question_id)
    var guidance = this.props.assessmentQuestions[quesIndex].guidence

    var guidanceContent = JSON.parse(guidance);

    let guidanceEditorContent = EditorState.createWithContent(
      convertFromRaw(guidanceContent[0])
    );

    var question_type = this.props.assessmentQuestions[i].question_type;

    if (question_type === questionTypeConstants.MCQ.name && guidance) {
      return (
        <div>
          <div className="row">
            {/* <div className="col-md-2 col-2 d-flex align-items-center">
              <h4 style={{ color: "#5458AF" }}>Marking Guidance:</h4>
            </div> */}
            <div className="col-md-12 col-12 px-0">
              <div className="">
                <p className="">
                  {guidance !== null && guidance !== "" ? (
                    <p className="model-guidance-text">
                      <ViewEditor editorState={guidanceEditorContent} />
                    </p>
                  ) : (
                    <div>
                      <p>No marking guidance given for this question</p>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      question_type == questionTypeConstants.DESCRIPTIVE.name &&
      guidance
    ) {
      return (
        <div>
          <div className="row">
            {/* <div className="col-md-2 col-2 d-flex align-items-center">
              <h4 style={{ color: "#5458AF" }}>Marking Guidance:</h4>
            </div> */}
            <div className="col-md-12 col-12 px-0">
              <div className="">
                <p className="">
                  {guidance !== null && guidance !== "" ? (
                    <p className="model-guidance-text">
                      <ViewEditor editorState={guidanceEditorContent} />
                    </p>
                  ) : (
                    <div>
                      <p>No marking guidance given for this question</p>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      question_type == questionTypeConstants.SUBMISSION.name &&
      guidance
    ) {
      return (
        <div>
          <div className="row">
            {/* <div className="col-md-2 col-2 d-flex align-items-center">
              <h4 style={{ color: "#5458AF" }}>Marking Guidance:</h4>
            </div> */}
            <div className="col-md-12 col-12 px-0">
              <div className="">
                <p className="">
                  {guidance !== null && guidance !== "" ? (
                    <p className="model-guidance-text">
                      <ViewEditor editorState={guidanceEditorContent} />
                    </p>
                  ) : (
                    <div>
                      <p>No marking guidance given for this question</p>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      question_type == questionTypeConstants.SUBQUESTION.name &&
      this.props.assessmentQuestions[quesIndex].is_subquestion === "true" &&
      guidance
    ) {
      return (
        <div>
          <div className="row">
            {/* <div className="col-md-2 col-2 d-flex align-items-center">
              <h4 style={{ color: "#5458AF" }}>Marking Guidance:</h4>
            </div> */}
            <div className="col-md-12 col-12 px-0">
              <div className="">
                <p className="">
                  {guidance !== null && guidance !== "" ? (
                    <p className="model-guidance-text">
                      <ViewEditor editorState={guidanceEditorContent} />
                    </p>
                  ) : (
                    <div>
                      <p>No marking guidance given for this question</p>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  async updateMarks(value, i, el) {
    if (!isNaN(parseFloat(value))) {
      var marks = parseFloat(value);
      if (el.mark_allocation >= marks && el.mark_allocation >= 0) {
        const assessor_id = this.props.grade_assessment.user_role_id;
        var i,
          total = 0;
        var id = el.question_id;
        await this.onsetTeacherMarks(id, marks);
        for (i = 0; i < this.state.candidateAnswers.length; i++) {
          if (this.state.candidateAnswers[i].mark_obtained) {
            total = total + this.state.candidateAnswers[i].mark_obtained;
          }
        }
        this.setState({
          total: total,
          setTotal: false,
          changes: true,
        });
      } else {
        window.Swal.fire({
          title: "Warning!",
          text: "Value should be positive and less than or equal to allocated value!",
          icon: "warning",
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement
                .querySelector(".swal2-confirm")
                .setAttribute("id", "confirmButtonId");
            }
          },
        });
        var values = this.state.questions;
        values[el.question_id].mark_obtained = "";
        var total = 0;
        for (i = 0; i < this.state.candidateAnswers.length; i++) {
          if (this.state.candidateAnswers[i].mark_obtained) {
            total = total + this.state.candidateAnswers[i].mark_obtained;
          }
        }
        this.setState({
          total: total,
          questions: values,
          setTotal: false,
          changes: true,
        });
      }
    } else {
      window.Swal.fire({
        title: "Warning!",
        text: "Not a number!",
        icon: "warning",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement
              .querySelector(".swal2-confirm")
              .setAttribute("id", "confirmButtonId");
          }
        },
      });
      var values = this.state.questions;
      values[el.question_id].mark_obtained = "";

      var total = 0;
      var id = el.question_id;
      await this.onsetTeacherMarks(id, marks="");
      console.log('this.props.newAnswerMap :', this.state.newAnswerMap)
      for (i = 0; i < this.state.candidateAnswers.length; i++) {
        if (this.state.candidateAnswers[i].mark_obtained) {
          total = total + this.state.candidateAnswers[i].mark_obtained;
        }
      }
      this.setState({
        total: total,
        questions: values,
        setTotal: false,
        changes: true,
      });
    }
  }

  async onsetTeacherMarks(id, marks) {
    var candidate_answerMap = {...this.state.newAnswerMap};

    candidate_answerMap[id]["mark_obtained"] = marks;

    this.setState({newAnswerMap:candidate_answerMap  });
  }
  
  async updateMainQuestionMarks() {
    const assessor_id = this.props.grade_assessment.user_role_id;
    const marks = 0;
    for (let i = 0; i < this.state.mainQuestionIds.length; i++) {
      var id = parseInt(this.state.mainQuestionIds[i]);
      await this.onsetTeacherMarks(id, marks);
    }
  }

  async onChangeMarks(e, i, el) {
    var values ={ ...this.state.questions};
    this.setState({
      prevMark: values[el.question_id].mark_obtained,
    });
    values[el.question_id].mark_obtained = e.target.value;
    this.setState({
      questions: values,
    });
    var value = e.target.value;
    this.updateMarks(value, i, el);
  }

  async onSetMainQuestionMarks(e, i, el) {
    var values = this.state.questions;
    this.setState({
      prevMark: values[el.question_id].mark_obtained,
    });
    values[el.question_id].mark_obtained = 0;
    this.setState({
      questions: values,
    });
    var value = 0;
    this.updateMarks(value, i, el);
  }

  getAllCandidateGradeScales = async() => {
    this.setState({ isTableDataLoading: true });
    await candidateService.getAllGradeScales()
        .then((response)=>{
            if(response.success){
                this.setState({ 
                    candidateGradeScales: response.data.candidate_grade_scales,
                    isTableDataLoading: false 
                });
            }
            else{
                this.setState({ isTableDataLoading: false });
            }
            
        })
        .catch(()=>{
          this.setState({ isTableDataLoading: false });
        })
  }

  calculateTotalAllocatedMarks() {
    var question_list = this.props.assessmentQuestions, i, sum = 0 ;
    for (i = 0; i < this.props?.assessmentQuestions.length; i++) {
      sum = sum + question_list[i]["mark_allocation"]
    }
    return sum;
  }

  calculateTotalReceivedMarks() {
    var i, total = 0;
    for (i = 0; i < this.props?.assessmentQuestions?.length; i++) {
      var candidate_answers = this.state.newAnswerMap;
      if (candidate_answers[this.props?.assessmentQuestions[i].question_id]["mark_obtained"]) {
        total = total + candidate_answers[this.props?.assessmentQuestions[i].question_id]["mark_obtained"];
      }
    }
    return total;
  }

  getGrade(percentage) {
    for (const scale of this.state.candidateGradeScales) {
          if (percentage >= scale.min_marks && percentage <= scale.max_marks) {
              return scale.grade;
          }
      }
    return "Invalid grade percentage"; 
  }

  async onSave() {
    console.log('this.props.newAnswerMap :', this.state.newAnswerMap)
    var unMarked = "",
      i = 1;
    const filteredMainSub = Object.values(this.state.newAnswerMap).filter(answ => {
      let relavantQues = this.props.assessmentQuestions.find(ques => ques.question_id === answ.question_id)
      if(!(!relavantQues.is_subquestion &&  relavantQues.question_type === questionTypeConstants.SUBQUESTION.name)){
        return true
      }
    })
    for (const value of filteredMainSub) {      
      if (!(!!value.mark_obtained || value.mark_obtained === 0)) {
        unMarked = unMarked + "Question " + i + " ";
      }
      i = i + 1;
    }
    if(unMarked !== ""){
      window.Swal.fire({
       title: 'Warning!',
       text: "Cannot save! Make sure all the answers has been graded!",
       icon: 'warning',
       customClass: {
         confirmButton: `btn font-weight-bold ${confirmClass}`,
       },
       onOpen: (modalElement) => {
         if (customer === customerConstants.EXO_CUSTOMER2) {
           modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
         }
       }
     });
    }else{
      const assessment_id = this.props.grade_assessment.assessment_id;
      const report_id = this.props.grade_assessment.report_id;
      const userID = sessionStorage.getItem("user_id");
      const user_role_id = "ASSR" + userID;
      const questions = this.state.candidateAnswers;
      const candidate_id = this.props.grade_assessment?.candidate_id;
      const attempt_no = this.props.grade_assessment?.attempt_no;
      const assessor_remarks = convertToRaw(
        this.state.remarksEditorState.getCurrentContent()
      );
  
  
      if (this.state.mainQuestionIds && this.state.mainQuestionIds.length > 0) {
        await this.updateMainQuestionMarks();
      }
  
      const obj = {
        report_id: report_id,
        assessment_id: assessment_id,
        attempt_no: attempt_no,
        candidate_id: candidate_id,
        questions: questions,
        assessor_remarks: null,
      };
  
      var arr = [];
      for (const [, value] of Object.entries(obj.questions)) {
        arr.push(value);
      }
      obj.questions = arr;
      this.setState({ gradeLoading: true });
  
      try {
        const res = await quesGradeService.saveTeacherMarks(obj);
  
        if (res.success) {
          sweetStatusAlert("Success!", res.message.success, "success");
        } else {
          sweetStatusAlert("Failed!", res.message, "error");
        }
      } catch (error) {
        sweetStatusAlert("Failed!", "Error saving teacher marks!", "error");
      } finally {
        // Hide loading indicator
        this.setState({ gradeLoading: false });
      }
      this.setState({ gradeLoading: false });
    }
    

    let disabled;
    for (let i = 0; i < this.state.candidateAnswers.length; i++) {
      if (
        this.state.candidateAnswers[i].mark_obtained === null ||
        this.state.candidateAnswers[i].mark_obtained === ""
      ) {
        disabled = true;
      }
    }
    this.setState({ disabled: disabled });
  }

  async finalizeSelfGrade() {
    var unMarked = "",
    i = 1;
    const filteredMainSub = Object.values(this.state.newAnswerMap).filter(answ => {
      let relavantQues = this.props.assessmentQuestions.find(ques => ques.question_id === answ.question_id)
      if(!(!relavantQues.is_subquestion &&  relavantQues.question_type === questionTypeConstants.SUBQUESTION.name)){
        return true
      }
    })
    for (const value of filteredMainSub) {
      if (!(value.mark_obtained || value.mark_obtained == 0)) {
        unMarked = unMarked + "Question " + i + " ";
      }
      i = i + 1;
    }

    if (unMarked == "") {
      const totalMarksAllocation = this.calculateTotalAllocatedMarks();
      const finalGrade = this.calculateTotalReceivedMarks();
      const percentage = (finalGrade / totalMarksAllocation) * 100;
      const grade = this.getGrade(percentage)
      const assessment_id = this.props.grade_assessment?.assessment_id;
      const report_id = this.props.grade_assessment?.report_id;
      const userID = sessionStorage.getItem("user_id");
      const graded_by = this.props.grade_assessment?.graded_by;
      const final_grade = this.state.ReportFinalGrade?.final_grade;
      const user_role_id = "ASSR" + userID;
      const questions = this.state.candidateAnswers;
      const candidate_id = this.props.grade_assessment?.candidate_id;
      const total_mark_allocation = totalMarksAllocation;
      const attempt_no = this.props.grade_assessment?.attempt_no;
      const assessor_remarks = convertToRaw(
        this.state.remarksEditorState.getCurrentContent()
      );
      const obj = {
        report_id: report_id,
        assessment_id: assessment_id,
        attempt_no: attempt_no,
        candidate_id: candidate_id,
        questions: questions,
        assessor_remarks: null,
      };

      var arr = [];
      for (const [, value] of Object.entries(obj.questions)) {
        arr.push(value);
      }
      obj.questions = arr;
      this.setState({ gradeLoading: true });

      try {
        const res = await quesGradeService.saveTeacherMarks(obj);

        if (res.success) {
          const finalizeobj = {
            report_id: report_id,
            final_grade: final_grade,
            attempt_no: attempt_no,
            user_role_id: user_role_id,
            candidate_id: candidate_id,
            graded_by: graded_by,
            total_mark_allocation: total_mark_allocation,
            grade: grade
          };

          const finalizeres = await quesGradeService.finalizeTeacherMarks(
            finalizeobj
          );

          if (finalizeres.success) {
            await this.getReport();
            if (this.getEditability()) {
              window.removeEventListener("beforeunload", this.onUnload);
              window.Swal.fire({
                title: "Success!",
                text: "Finalized successfully!",
                icon: "success",
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement
                      .querySelector(".swal2-confirm")
                      .setAttribute("id", "confirmButtonId");
                  }
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  this.setState({
                    changes: false,
                  });
                }
              });
            } else if (!this.getEditability()) {
              window.removeEventListener("beforeunload", this.onUnload);
              window.Swal.fire({
                title: "Success!",
                text: "Unpublished successfully!",
                icon: "success",
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement
                      .querySelector(".swal2-confirm")
                      .setAttribute("id", "confirmButtonId");
                  }
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  this.setState({
                    changes: false,
                  });
                //   this.changeRoute();
                }
              });
            }
          } else {
            sweetStatusAlert(
              "Failed!",
              "Cannot finalize the grade! Make sure all the answers have been graded!",
              "error"
            );
          }
        } else {
          sweetStatusAlert("Failed!", res.message, "error");
        }
      } catch (error) {
        sweetStatusAlert("Failed!", "Error finalizing marks!", "error");
      } finally {
        // Hide loading indicator
        this.setState({ gradeLoading: false });
      }

      this.setState({ gradeLoading: false });
    }
  }

  autoGrade() {
    window.Swal.fire({
      text: "Do you want to auto grade questions? (The questions that has already been graded manually will not be auto graded)",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: !1,
      confirmButtonText: "Proceed",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: `btn font-weight-bold ${confirmClass}`,
        cancelButton: `btn font-weight-bold ${cancelClass}`,
      },
    }).then(async (action) => {
      if (action.isConfirmed) {
        window.Swal.showLoading();
        var i;
        for (i = 0; i < this.props.assessmentQuestions.length; i++) {
          if (
            this.props.assessmentQuestions[i].question_type ==
            questionTypeConstants.MCQ.name
          ) {
            var candidate_answer =
              this.props.contentMap[
                this.props.assessmentQuestions[i].question_id
              ];
            candidate_answer = JSON.parse(candidate_answer);
            var res = this.props.answerContents[i].split(",");
            let numOfCandidateAnswers = candidate_answer.length;
            let numOfAnswers = 0;
            for (var m in res) {
              if (res[m] != "Nan" && res[m] != 0) {
                numOfAnswers = numOfAnswers + 1;
              }
            }
            if (numOfCandidateAnswers <= numOfAnswers) {
              var marks = 0,
                j,
                ans_index;
              for (j in candidate_answer) {
                ans_index = candidate_answer[j];
                marks =
                  marks +
                  (res[ans_index] *
                    this.props.assessmentQuestions[i].mark_allocation) /
                    100;
              }
              const assessor_id = this.props.grade_assessment.user_role_id;
              var id = this.props.assessmentQuestions[i].question_id;
              await this.onsetTeacherMarks(id, marks);
            } else {
              var marks = 0;
              const assessor_id = this.props.grade_assessment.user_role_id;
              var id = this.props.assessmentQuestions[i].question_id;
              await this.onsetTeacherMarks(id, marks);
            }
          }
        }

        var total = 0;
        for (i = 0; i < this.props.assessmentQuestions.length; i++) {
          var candidate_answer =
            this.props.contentMap[
              this.props.assessmentQuestions[i].question_id
            ];
          if (candidate_answer.mark_obtained) {
            total = total + candidate_answer.mark_obtained;
          }
        }
        this.onSave();
        this.setState({
          total: total,
          setTotal: true,
          questions: this.state.newAnswerMap,
        });
      }
    });
  }

  addRemarks() {
    window.Swal.fire({
      title: "Success!",
      text: "Remarks added successfully!",
      icon: "success",
      customClass: {
        confirmButton: `btn font-weight-bold ${confirmClass}`,
      },
      onOpen: (modalElement) => {
        if (customer === customerConstants.EXO_CUSTOMER2) {
          modalElement
            .querySelector(".swal2-confirm")
            .setAttribute("id", "confirmButtonId");
        }
      },
    });
  }
  searchChange(e) {
    let val = e.target.value;
    this.setState({
      searchValue: val,
    });
  }

  getEditability() {
    return !(this.state.ReportFinalGrade?.final_grade == null);
  }

  questionsFilter(questions, type, difficulty, category) {
    let filteredQuestions = [];
    var userId = DataHandler.getFromSession("user_id");
    var questionLibrary = this.props.questions;
    var qType;
    var qDifficulty;
    var qCategory;
    if (this.state.chkbox) {
      questions.map((question) => {
        if (!question.added_by.includes(userId)) {
          questionLibrary.map((q) => {
            if (q.question_id === question.question_id) {
              this.props.allCategories.map((el, i) => {
                if (el.category_id === q.category_id) {
                  qCategory = el.name;
                }
              });
              this.props.allQuestionTypes.map((el, i) => {
                if (el.type_id === q.qtype) {
                  qType = el.qtype;
                }
              });
              this.props.allDifficulty.map((el, i) => {
                if (el.difficulty_id === q.difficulty_id) {
                  qDifficulty = el.difficulty_type;
                }
              });
            }
          });
          if (category == 0 && type == 0 && difficulty == 0) {
            filteredQuestions.push(question);
          } else if (category == qCategory && type == 0 && difficulty == 0) {
            filteredQuestions.push(question);
          } else if (category == 0 && type == qType && difficulty == 0) {
            filteredQuestions.push(question);
          } else if (category == 0 && type == 0 && difficulty == qDifficulty) {
            filteredQuestions.push(question);
          } else if (
            category == qCategory &&
            type == qType &&
            difficulty == 0
          ) {
            filteredQuestions.push(question);
          } else if (
            category == qCategory &&
            type == 0 &&
            difficulty == qDifficulty
          ) {
            filteredQuestions.push(question);
          } else if (
            category == 0 &&
            type == qType &&
            difficulty == qDifficulty
          ) {
            filteredQuestions.push(question);
          } else if (
            category == qCategory &&
            type == qType &&
            difficulty == qDifficulty
          ) {
            filteredQuestions.push(question);
          }
        }
      });
    } else {
      questions.map((question) => {
        this.props.assessmentQuestions.map((q) => {
          if (q.question_id === question.question_id) {
            this.props.allCategories.map((el, i) => {
              if (el.category_id === q.question_category) {
                qCategory = el.name;
              }
            });
            this.props.allQuestionTypes.map((el, i) => {
              if (el.qtype === q.question_type) {
                qType = el.qtype;
              }
            });
            this.props.allDifficulty.map((el, i) => {
              if (el.difficulty_id === q.question_difficulty) {
                qDifficulty = el.difficulty_type;
              }
            });
          }
        });

        if (category == 0 && type == 0 && difficulty == 0) {
          filteredQuestions.push(question);
        } else if (category == qCategory && type == 0 && difficulty == 0) {
          filteredQuestions.push(question);
        } else if (category == 0 && type == qType && difficulty == 0) {
          filteredQuestions.push(question);
        } else if (category == 0 && type == 0 && difficulty == qDifficulty) {
          filteredQuestions.push(question);
        } else if (category == qCategory && type == qType && difficulty == 0) {
          filteredQuestions.push(question);
        } else if (
          category == qCategory &&
          type == 0 &&
          difficulty == qDifficulty
        ) {
          filteredQuestions.push(question);
        } else if (
          category == 0 &&
          type == qType &&
          difficulty == qDifficulty
        ) {
          filteredQuestions.push(question);
        } else if (
          category == qCategory &&
          type == qType &&
          difficulty == qDifficulty
        ) {
          filteredQuestions.push(question);
        }
      });
    }
    return filteredQuestions;
  }
  questionTable(showingQuestionsList) {
    return (
      this.props.assessmentQuestions &&
      this.props.assessmentQuestions.length &&
      this.state.candidateAnswers &&
      this.state.candidateAnswers.length &&
      this.state.questions && (
        <FinalizeLeftWrap finalizeView={false}>
          <span className="label label-lg label-light-primary label-inline font-weight-bold m-2">
            Question Count: {showingQuestionsList.length}
          </span>
          {showingQuestionsList.map((el, i) => (
            <GradeNavItem
              class="question"
              key={i}
              added_by={this.state.questionMap[el.question_id]?.creatorName}
              className={
                (this.state.questions[el.question_id]?.graded_by &&
                  "nav-link position-relative pt-10 pl-5 pr-5 pb-5") ||
                "nav-link position-relative pt-10 pl-5 pr-5 pb-5 non-graded"
              }
              id={"home-tab-".concat(String(i + 1))}
              dataToggle="tab"
              href={"#home-".concat(String(i + 1))}
            >
              <FinalizeQuestionTitle Question={el.title} />
              <FinalizeNavItemActions>
                <Marks
                  viewAllocated={true}
                  label={el.mark_allocation}
                  value={this.state.questions[el.question_id].mark_obtained}
                  onChangeMarks={(e) => this.onChangeMarks(e, i, el)}
                  mark_button_visible={true}
                  readOnly={this.getEditability()}
                  question={i}
                />
              </FinalizeNavItemActions>
            </GradeNavItem>
          ))}
        </FinalizeLeftWrap>
      )
    );
  }

  changeRoute() {
    this.props.hideSelfGrade();
  }

  render() {
    var questionOp = this.props.assessmentQuestions;
    var qTypeNames = [];
    var qCategoryNames = [];
    var qDiffNames = [];
    let sub = 0;

    questionOp.map((q) => {
      if (!qTypeNames.includes(q.question_type)) {
        qTypeNames.push(q.question_type);
      }

      this.props.allCategories.map((el, i) => {
        if (el.category_id === q.question_category) {
          if (!qCategoryNames.includes(el.name)) {
            qCategoryNames.push(el.name);
          }
        }
      });

      this.props.allDifficulty.map((el, i) => {
        if (el.difficulty_id === q.question_difficulty) {
          if (!qDiffNames.includes(el.difficulty_type)) {
            qDiffNames.push(el.difficulty_type);
          }
        }
      });
    });

    var categoryOptions = [];
    var typeOptions = [];
    var difficultyOptions = [];
    categoryOptions.push(
      <SelfGradeOption
        key={0}
        optionName={"None"}
        value={0}
        selected="selected"
      />
    );
    typeOptions.push(
      <SelfGradeOption
        key={0}
        optionName={"None"}
        value={0}
        selected="selected"
      />
    );
    difficultyOptions.push(
      <SelfGradeOption
        key={0}
        optionName={"None"}
        value={0}
        selected="selected"
      />
    );

    qCategoryNames.map((el, i) => {
      categoryOptions.push(
        <SelfGradeOption key={i + 1} optionName={el} value={el} />
      );
    });

    qTypeNames.map((el, i) => {
      typeOptions.push(
        <SelfGradeOption key={i + 1} optionName={el} value={el} />
      );
    });

    qDiffNames.map((el, i) => {
      difficultyOptions.push(
        <SelfGradeOption key={i + 1} optionName={el} value={el} />
      );
    });

    const filteredQuestionss = this.props.assessmentQuestions.filter(
      (question, i) => {
        return (
          question.title
            .toLowerCase()
            .indexOf(this.state.searchValue.toLowerCase()) !== -1
        );
      }
    );

    return (
      <>
        <AdminTemplate>
          <ContentWrapper>
            {/* begin::Card */}
            {this.state.spinnerLoading && (
              <Spinner id="show" text="Please wait.." />
            )}
            {this.state.answerLoading && (
              <Spinner id="show" text="Please wait.." />
            )}
            {/* {this.props.contentLoading && (
            <Spinner id="show" text="Please wait.." />
          )} */}
            {this.state.gradeLoading && (
              <Spinner id="show" text="Please wait.." />
            )}
            {this.state.loadingTeacherGradePage && (
              <Spinner id="show" text="Please wait.." />
            )}
            {this.props.dropdownDataLoading && (
              <Spinner id="show" text="Please wait.." />
            )}
            {this.props.grade_assessment?.title != undefined ? (
              <div style={{ overflowY: "auto" }}>
                {/* {this.state.spinnerLoading ? (
                        <Spinner id="show" text="Please wait.." />
                      ) :( */}
                <Card>
                  <CardHeader>
                    <SelfGradeCardTitle
                      cardLabel={this.props.grade_assessment.title}
                    />
                    <SelfGradeCardToolBar>
                      <div className="mr-2 label label-lg label-light-success label-inline border border-primary rounded-md p-3 m-total-marks text-center">
                        <h5 className="font-weight-bold d-block">
                          Total Marks: {this.state.total}/{this.state.sum}
                        </h5>
                      </div>
                      <SelfGradeButton
                        className="btn btn-primary mr-2 finalize-btn"
                        btnName="Save Changes"
                        onClick={this.onSave}
                        disabled={this.getEditability()}
                      />
                      {!this.getEditability() && (
                        <SelfGradeButton
                          className="btn btn-primary mr-2 finalize-btn"
                          btnName="Finalize"
                          btnIconStyle="flaticon2-correct"
                          onClick={this.finalizeSelfGrade}
                          disabled={this.state.disabled}
                        />
                      )}
                      {this.getEditability() && (
                        <SelfGradeButton
                          className="btn btn-primary mr-2 finalize-btn"
                          btnName="Unpublish grade"
                          btnIconStyle="flaticon2-correct"
                          onClick={this.finalizeSelfGrade}
                        />
                      )}
                      <SelfGradeButton
                        onClick={() => this.autoGrade()}
                        btnName="Auto Grade"
                        className="btn btn-primary mr-2 finalize-btn"
                        disabled={this.getEditability()}
                      />
                    </SelfGradeCardToolBar>
                  </CardHeader>

                  <CardBody>
                    {this.props.assessmentQuestions.length > 0 ? (
                      <FinalizeTab>
                        <FinalizeRightWrap>
                          <div className="row">
                            <div className="col-md-6"></div>
                          </div>
                          <br />
                          {this.props.assessmentQuestions &&
                            this.props.assessmentQuestions.length &&
                            this.props.questionContents &&
                            this.props.questionContents.length &&
                            this.questionsFilter(
                              filteredQuestionss,
                              this.state.selectedType,
                              this.state.selectedDifficulty,
                              this.state.selectedCategory
                            ).length > 0 && (
                              <>
                                {this.props.answerContents.length &&
                                this.props.questionContents.length &&
                                this.state.candidateAnswers.length &&
                                this.props.candidateAnswerContents.length ? (
                                  <div className="">
                                    {this.props.assessmentQuestions
                                      .filter(
                                        (item) => item.is_subquestion !== "true"
                                      )
                                      .map((question, i) => {
                                        let subQuestions =
                                          this.state.subQuestions;
                                        let arr = [];
                                        let k = sub;

                                        // Collect sub-questions corresponding to the main question
                                        for (let val in subQuestions) {
                                          if (
                                            subQuestions[val].title ===
                                              question.title &&
                                            question.question_type ===
                                              questionTypeConstants.SUBQUESTION
                                                .name &&
                                            question.is_subquestion !== "true"
                                          ) {
                                            arr.push(val);
                                            sub++;
                                          }
                                        }

                                        // Create an object for the sub-questions
                                        let sub_ques = {};
                                        arr.forEach((index) => {
                                          if (
                                            subQuestions.hasOwnProperty(index)
                                          ) {
                                            sub_ques[index] =
                                              subQuestions[index];
                                          }
                                        });

                                        return (
                                          <div
                                            className="pb-5 question-container mx-0"
                                            key={i}
                                          >
                                            <div className="row">
                                              <div className="col-md-12 d-flex justify-content-between">
                                                <h6 className="d-flex align-items-center">
                                                  Question {i + 1}:
                                                </h6>
                                                {question.question_type !==
                                                  questionTypeConstants
                                                    .SUBQUESTION.name && (
                                                  <Marks
                                                    viewAllocated={true}
                                                    label={
                                                      question.mark_allocation
                                                    }
                                                    value={
                                                      this.state.questions[
                                                        question.question_id
                                                      ]?.mark_obtained
                                                    }
                                                    onChangeMarks={(e) =>
                                                      this.onChangeMarks(
                                                        e,
                                                        i,
                                                        question
                                                      )
                                                    }
                                                    mark_button_visible={true}
                                                    readOnly={this.getEditability()}
                                                    question={i}
                                                  />
                                                )}
                                              </div>
                                              <div className="col-md-12 separator separator-dashed separator-border-2 my-5"></div>
                                            </div>
                                            <p className="d-flex m-0">
                                              <p className="col-md-2 d-flex align-items-start pt-3">
                                                <b>Question: </b>
                                              </p>
                                              {question.question_type !==
                                              questionTypeConstants.SUBQUESTION
                                                .name ? (
                                                <div className="col-md-10 px-0">
                                                  {this.getContentInEditor(
                                                    question,
                                                    i
                                                  )}
                                                </div>
                                              ) : (
                                                <div className="col-md-10 px-0">
                                                  {this.getContentInEditor(
                                                    question,
                                                    i + k
                                                  )}
                                                </div>
                                              )}
                                            </p>
                                            {question.question_type ===
                                              questionTypeConstants.SUBQUESTION
                                                .name && (
                                              <div className="col-md-12 separator separator-dashed separator-border-2 my-5"></div>
                                            )}
                                            {question.question_type !==
                                              questionTypeConstants.SUBQUESTION
                                                .name && (
                                              <div>
                                                <p className="d-flex m-0">
                                                  <p className="col-md-2 d-flex align-items-start pt-4">
                                                    <b>Student Answer: </b>
                                                  </p>
                                                  <div className="col-md-10 px-0 answer-sheet-text">
                                                    {this.getAnswerInEditor(
                                                      question,
                                                      i
                                                    )}
                                                  </div>
                                                </p>
                                                <p className="d-flex m-0">
                                                  <p className="col-md-2 d-flex align-items-start pt-4">
                                                    <b>Model Answer: </b>
                                                  </p>
                                                  <div className="col-md-10 px-0">
                                                    {this.getAssessorAnswerInEditor(
                                                      question,
                                                      i
                                                    )}
                                                  </div>
                                                </p>
                                                <p className="d-flex m-0 pb-1">
                                                  <p className="col-md-2 d-flex align-items-start">
                                                    <b>Guidelines: </b>
                                                  </p>
                                                  <div className="col-md-10 px-0">
                                                    {this.getAssessorAnswerGuidanceInEditor(
                                                      question,
                                                      i
                                                    )}
                                                  </div>
                                                </p>
                                              </div>
                                            )}

                                            {/* Render sub-questions for the current main question */}
                                            {Object.keys(sub_ques).length >
                                              0 && (
                                              <div className="ml-5">
                                                {Object.keys(sub_ques).map(
                                                  (index, j) => (
                                                    <div key={j}>
                                                      <div className="row">
                                                        <div className="col-md-12 d-flex justify-content-between">
                                                          <h6 className="d-flex align-items-center">
                                                            SubQuestion {j + 1}:
                                                          </h6>
                                                          <Marks
                                                            viewAllocated={true}
                                                            label={
                                                              sub_ques[index]
                                                                .mark_allocation
                                                            }
                                                            value={
                                                              this.state
                                                                .questions[
                                                                sub_ques[index]
                                                                  .question_id
                                                              ]?.mark_obtained
                                                            }
                                                            onChangeMarks={(
                                                              e
                                                            ) =>
                                                              this.onChangeMarks(
                                                                e,
                                                                j,
                                                                sub_ques[index]
                                                              )
                                                            }
                                                            mark_button_visible={
                                                              true
                                                            }
                                                            readOnly={this.getEditability()}
                                                            question={j}
                                                          />
                                                        </div>
                                                        <div className="col-md-12 separator separator-dashed separator-border-2 my-5"></div>
                                                      </div>
                                                      <p className="d-flex m-0">
                                                        <p className="col-md-2 d-flex align-items-start pt-3">
                                                          <b>Question: </b>
                                                        </p>
                                                        <div className="col-md-10 px-0">
                                                          {this.getContentInEditor(
                                                            sub_ques[index],
                                                            index
                                                          )}
                                                        </div>
                                                      </p>
                                                      <p className="d-flex m-0">
                                                        <p className="col-md-2 d-flex align-items-start pt-4">
                                                          <b>
                                                            Student Answer:{" "}
                                                          </b>
                                                        </p>
                                                        <div className="col-md-10 px-0 answer-sheet-text">
                                                          {this.getAnswerInEditor(
                                                            sub_ques[index],
                                                            index
                                                          )}
                                                        </div>
                                                      </p>
                                                      <p className="d-flex m-0">
                                                        <p className="col-md-2 d-flex align-items-start pt-4">
                                                          <b>Model Answer: </b>
                                                        </p>
                                                        <div className="col-md-10 px-0">
                                                          {this.getAssessorAnswerInEditor(
                                                            sub_ques[index],
                                                            index
                                                          )}
                                                        </div>
                                                      </p>
                                                      <p className="d-flex m-0 pb-1">
                                                        <p className="col-md-2 d-flex align-items-start">
                                                          <b>Guidelines: </b>
                                                        </p>
                                                        <div className="col-md-10 px-0">
                                                          {this.getAssessorAnswerGuidanceInEditor(
                                                            sub_ques[index],
                                                            index
                                                          )}
                                                        </div>
                                                      </p>
                                                      <div className="col-md-12 separator separator-dashed separator-border-2 my-5"></div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                ) : (
                                  <div className="loading-container">
                                    <Spinner animation="border" role="status">
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </Spinner>
                                  </div>
                                )}
                              </>
                            )}
                        </FinalizeRightWrap>
                      </FinalizeTab>
                    ) : (
                      <span className="label label-lg label-light-warning label-inline">
                        No Questions Added!
                      </span>
                    )}
                  </CardBody>
                </Card>
                {/* )} */}
              </div>
            ) : (
              <div>
                {" "}
                <Redirect to={"/teacher_grade_report"} />
              </div>
            )}
            {/* end::Card */}

            <SelfGradeModal modalTitle="Add Remarks" modalId="add-remarks">
              <ModalBody>
                <div
                  className="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <FormGroup
                    labelName="Add remarks here: "
                    className="col-lg-12"
                  >
                    <div className="col-lg-9">
                      <div className="RichEditor-root-createquestion">
                        <CustomEditor
                          value={this.state.remarksEditorState}
                          editorState={this.state.remarksEditorState}
                          onEditorStateChange={this.onRemarksEditorChange}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </ModalBody>
              <SelfGradeModalFooter>
                <SelfGradeButton
                  className="btn btn-light-primary font-weight-bold"
                  dataDismiss="modal"
                  btnName="Add remark"
                  onClick={this.addRemarks}
                />
                <SelfGradeButton
                  className="btn btn-light-primary font-weight-bold"
                  dataDismiss="modal"
                  btnName="Close"
                />
              </SelfGradeModalFooter>
            </SelfGradeModal>
          </ContentWrapper>
        </AdminTemplate>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    allStaffUsers: state.staffUserReducer.allStaffUsers,
    staffUserMap: state.staffUserReducer.staffUserMap,
    allTeachers: state.manageUserReducer.allTeachers,

    assessment: state.examReducer.assessment,
    questionContents: state.questionReducer.questionContents,
    contentLoading: state.testPageReducer.loading,
    dropdownDataLoading: state.questionsCreateReducer.loading,
    answerContents: state.questionReducer.answerContents,
    questions: state.testReducer.questions,
    assessmentQuestions: state.testReducer.assessmentQuestions,

    candidateAnswers: state.testReducer.candidateAnswers,
    candidate: state.testReducer.candidate,
    candidateAnswerContents: state.testReducer.candidateAnswerContents,
    answerMap: state.testReducer.candidateAnswerMap,
    contentMap: state.testReducer.candidateAnswerContentMap,
    assessorRemarks: state.gradeReducer.assessorRemarks,
    gradeError: state.gradeReducer.error,
    gradeLoading: state.gradeReducer.loading,

    allCategories: state.questionsCreateReducer.allCategories,
    allQuestionTypes: state.questionsCreateReducer.allQuestionTypes,
    allDifficulty: state.questionsCreateReducer.allDifficulty,

    report: state.examReducer.report,
  };
}

const mapActionsToProps = {
  createStaffUserMap: staffUserActions.createStaffUserMap,
  getStaffUsers: staffUserActions.getAllStaffUsers,
  getAllTeachers: manageUserActions.getAllTeachers,

  refreshPage: questionActions.refreshPage,
  getAllQuestions: questionActions.getAllQuestions,
  loadAssessmentQuestions: loadAssessmentQuestions,
  getAllQuestionContents: questionActions.getAllQuestionContents,

  removeMyQuestions: questionActions.removeMyQuestions,
  getAllAnswerContents: questionActions.getAllAnswerContents,
  getAllCandidateAnswerContents: getAllCandidateAnswerContents,
  onSelfSetMarks: onSelfSetMarks,
  getQuestionTypes: createActions.getQuestionTypes,
  getQuestionCategories: createActions.getQuestionCategories,
  getQuestionDifficulty: createActions.getQuestionDifficulty,
  getCandidateAnswers: getCandidateAnswers,
};

export default connect(mapStateToProps, mapActionsToProps)(TeacherGrade);
