import * as msal from "@azure/msal-browser";
import config from "../helper/config";
import { federationConstants } from "../helper/constants";
import {
  onAccessTokenFailure,
  onAccessTokenRequest,
  onAccessTokenSuccess,
  onInitializationFailure,
  onLoginFailure,
  onLoginRequest,
  onLoginSuccess,
  onGetUserSuccess,
  onGetUserFailure,
} from "./MicrosoftLoginAgent.actions";
import DataHandler from "../handlers/DataHandler";
class MicrosoftLoginAgent {
  constructor() {
    this.username = "";
    this.account = "";
    this.msalInstance = this.init();
  }

  init() {
    try {
      return new msal.PublicClientApplication(
        config.azure_ad_config.msalConfig
      );
    } catch (error) {
      throw onInitializationFailure(error);
    }
  }

  async login() {
    try {
      let request = {
        scopes: config.azure_ad_config.login_scopes,
      };

      onLoginRequest();
      await this.msalInstance.loginRedirect(request);
    } catch (error) {
      throw onLoginFailure(error);
    }
  }

  async accessTokens() {
    onAccessTokenRequest();

    for (const [, value] of Object.entries(
      config.azure_ad_config.api_scopes
    )) {
      await this.acquireToken(value);
    }
  }

  async acquireToken(scope) {
    let request = {
      scopes: scope,
      account: this.account,
    };
    try {
      let response = await this.msalInstance.acquireTokenSilent(request);
      let accessToken = this.getAccessToken(response);

      return onAccessTokenSuccess(accessToken);
    } catch (error) {
      if (error.name === "InteractionRequiredAuthError") {
        let request = {
          scopes: scope,
        };
        try {
          let response = await this.msalInstance.acquireTokenPopup(request);
          let accessToken = this.getAccessToken(response);
          onAccessTokenSuccess(accessToken);
        } catch (error) {
          throw onAccessTokenFailure(error);
        }
      } else {
        throw onAccessTokenFailure(error);
      }
    }
  }

  getAccessToken(response) {
    if (!response.scopes) return;
    let scope_parts = response.scopes[0].split("https://");
    scope_parts = scope_parts[1].split("/");
    let app_id = scope_parts[1];

    switch (app_id) {
      case config.azure_ad_config.apis.OAT.api:
        DataHandler.setToSession(
          config.azure_ad_config.apis.OAT.name,
          response.accessToken
        );
        return {OAT: response.accessToken };
      default:
    }
  }

  async logout() {
    await this.msalInstance.logoutRedirect();
  }

  getUserAccount() {
    this.account = this.msalInstance.getAccountByUsername(
      this.account.username
    );
    return this.account;
  }

  async getUserDispatch(userDetails) {
    try {
      DataHandler.setToSession(
        DataHandler.SESSION_DATA.UserEmail,
        userDetails.email
      );
      onGetUserSuccess(userDetails);
    } catch (error) {
      throw onGetUserFailure(error);
    }
  }

  async GetTokenFromRedirect() {
    onAccessTokenRequest();

    try {
      let tokenResponse = await this.msalInstance.handleRedirectPromise();
      if (tokenResponse) {
        let accessToken = this.getAccessToken(tokenResponse);
        onAccessTokenSuccess(accessToken);
      }
      return tokenResponse;
    } catch (error) {
      throw onAccessTokenFailure(error);
    }
  }

  async GetDetailsFromToken(token) {
    try {
      this.account = token.account;
      await this.getUserDispatch(token.idTokenClaims);
      await this.accessTokens();
      const userFederation = getFederationFromIdP(token.idTokenClaims.idp);

      DataHandler.setToSession(
        DataHandler.SESSION_DATA.FederationService,
        userFederation
      );
      DataHandler.setToSession(DataHandler.SESSION_DATA.IdToken, token.idToken);

      onLoginSuccess(token, userFederation);
    } catch (error) {
      throw onLoginFailure(error);
    }

    function getFederationFromIdP(identityProvider) {
      if (String(identityProvider).includes("microsoft"))
        return federationConstants.MICROSOFT;
      if (String(identityProvider).includes("google"))
        return federationConstants.GOOGLE;
    }
  }
}

const MicrosoftLogin = new MicrosoftLoginAgent();
export { MicrosoftLogin as MicrosoftLoginAgent };
