import React, { Component } from "react"
import cmclogo from "../../../assets/images/mid.png"
import "../../../components/cmc/assets/css/cmc-landing.css"
import vectortwo from "../../../components/cmc/assets/images/vectortwo.svg"
import { routes } from "../../../helper/routes"
import { history } from "../../../helper/history"

export default class AdminLandingPage extends Component {
  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role)
    history.push(routes.auth_response)
  }

  render() {
    const currentYear = new Date().getFullYear()

    return (
      <>
        <div className="col-md-12 px-0 cmc-landing">
          <div className="cmc-nav col-md-12 px-10 py-5">
            <div className="row">
              <div className="col-md-6 col-6 pl-5 py-0">
                <img
                  src={cmclogo}
                  alt="logo"
                  style={{ height: "auto", width: "300px" }}
                  className="cmc-logo"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 px-0">
            <div className="row">
              <div className="col-lg-2 col-md-2 px-0 d-flex align-items-end">
                <img
                  src={vectortwo}
                  alt="logo"
                  style={{
                    height: "auto",
                    width: "250px",
                    marginBottom: "-53px",
                  }}
                  className="vector-two"
                />
              </div>
              <div className="col-lg-8 col-md-8 d-flex justify-content-center">
                <div className="row signin-container">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 pt-10">
                        <h1 className="text-center signin-heading">Join Us!</h1>
                      </div>
                      <div className="col-md-12">
                        <p className="text-center signin-para">
                          Manage Online Exams From Wherever You Are
                        </p>
                      </div>
                      <div className="col-md-12 d-flex justify-content-center pt-3">
                        <div className="gradient-line"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 py-10">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-5">
                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center">
                            <div className="admin-img"></div>
                          </div>
                          <div className="col-md-12 d-flex justify-content-center">
                            <div className="d-flex justify-content-center button-container">
                              <button
                                onClick={() => this.handleRoleSelect("admin")}
                                className="signin-btn-container"
                              >
                                I'M AN ADMIN
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 px-0 d-flex justify-content-end"></div>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-center pt-8 pb-2 pl-1">
            &#169; {currentYear} All Rights Reserved. Powered by EdSider (Pvt) Ltd.
            Supported by Infinity Innovators (Pvt) Ltd
          </div>
        </div>
      </>
    )
  }
}
