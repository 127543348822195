import React, { Component } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
  } from "@mui/material";
  import IconButton from '@mui/material/IconButton';
  import DeleteIcon from '@mui/icons-material/Delete';
  import AddIcon from '@mui/icons-material/Add';
  import Button from "react-bootstrap/Button";
  import Modal from "react-bootstrap/Modal";
  import Col from "react-bootstrap/Col";
  import Form from "react-bootstrap/Form";
  import Row from "react-bootstrap/Row";
  import SimpleReactValidator from "simple-react-validator";
  import EditIcon from '@mui/icons-material/Edit';
  import { Spinner } from 'react-bootstrap';
  import { sweetConfirmAlert, sweetErrorAlert, sweetStatusAlert, sweetSuccessAlert } from '../../../components/common/SweetAlert';
import { NodeGroupService } from '../../../service/settings/manage/node_group_title.service.js';
import ContentWrapper from '../../../components/common/ContentWrapper';
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

class ManageNodeGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isTableDataLoading:false,
        isLoading:false,
        addTitleModal: false,
        updateTitleModal:false,
        group_title:"",
        updateTitle:"",
        selectedTitleId:null,
        titles:[]
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount(){
    this.getAllTitleGroups()
  }

  getAllTitleGroups=()=>{
    this.setState({ isTableDataLoading: true });
    NodeGroupService.getNodeGroups()
        .then((response)=>{
            if(response.success){
                this.setState({titles:response.data, isTableDataLoading:false})
            }
            else{
                this.setState({ isTableDataLoading: false });
            }
            
        })
        .catch(()=>{
          this.setState({ isTableDataLoading: false });
        })
  }

  toggleAddTitleModal = () => {
    const { addTitleModal } = this.state;
    this.validator.purgeFields()
    this.validator.hideMessageFor("group_title")
    this.setState({
        addTitleModal: !addTitleModal,
        group_title: "",
    });
  };

  toggleUpdateTitleModal = (title) => {
    const { updateTitleModal } = this.state;
    this.validator.purgeFields()
    this.validator.hideMessageFor("updateTitle")
    if(!updateTitleModal){
        this.setState({updateTitle:title ,selectedTitleId:title.group_id, updateTitleModal:true})
    }
    else{
        this.setState({updateTitle:null ,selectedTitleId:null, updateTitleModal:false})
    }
  };

  handleSubmit = () => {
    const { group_title } = this.state;
    if (!this.validator.fieldValid("group_title")) {
      this.validator.showMessageFor("group_title")
      this.forceUpdate();
    } else {
    this.setState({isLoading:true})
      const newNodeGroup = {
        group_title:group_title
      }
      NodeGroupService.addNodeGroup(newNodeGroup)
        .then((response)=>{
            if(response.success){
                sweetSuccessAlert("Success","Node Group added successfully","success")
                this.setState({isLoading:false,titles:[...this.state.titles,response.data]})
                this.toggleAddTitleModal()
            }
            else{
                this.setState({isLoading:false})
                sweetStatusAlert("Info",response.message,"info")
            }
        })
        .catch((error)=>{
            this.setState({isLoading:false})
            sweetErrorAlert("Error","Error while adding node group","error")
        })
    }
  };

  handleEditSubmit = ()=>{
    const {updateTitle,selectedTitleId} = this.state;
    if(!this.validator.fieldValid("updateTitle")){
        this.validator.showMessageFor("updateTitle")
        this.forceUpdate();
    }
    else{
        this.setState({isLoading:true})
        const newUpdateTitle = {
            group_title:updateTitle
        }
        NodeGroupService.updateNodeGroup(newUpdateTitle,selectedTitleId)
            .then((response)=>{
                if(response.success){
                    sweetSuccessAlert("Success","Node title group updated successfully","success")
                    this.setState({isLoading:false})
                    this.toggleUpdateTitleModal()
                    this.getAllTitleGroups()
                }
                else{
                    this.setState({isLoading:false})
                    sweetStatusAlert("Oops",response.message,"info")
                }
                
            })
            .catch((error)=>{
                this.setState({isLoading:false})
                sweetErrorAlert("Error","Error while updating node title group","error")
            })
    }
  }

  handleDeleteGrade = (id)=>{
    sweetConfirmAlert()
        .then(()=>{
            this.setState({isTableDataLoading:true})
            NodeGroupService.deleteNodeGroup(id)
                .then((response)=>{
                  if(response.success){
                    sweetSuccessAlert("Success","Node group title deleted successfully","success")
                    this.getAllTitleGroups()
                  }
                  else{
                    sweetStatusAlert("Warning",response.message,"info")
                    this.setState({isTableDataLoading:false})
                  }
                    
                })
                .catch((error)=>{
                    sweetStatusAlert("Oops","Error while deleting node title group","info")
                    this.setState({isTableDataLoading:false})
                })
        })
  };

  handletitleChange = (e) => {
    const { name,value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  render() {
    const { 
        addTitleModal, 
        group_title, 
        updateTitleModal,
        isTableDataLoading,
        isLoading,
        titles,
        updateTitle
        } = this.state;
    return (
      <ContentWrapper>
        <div className="row justify-content-between align-items-center py-5 mb-3">
          <div className='col-md-6 col-6'>
            <h3 className='text-nowrap'>Manage Node Group</h3>
          </div>
          <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleAddTitleModal}
          >
            Add Node Group Title
          </Button>
        </div>
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "75vw",
              height: "90vh"
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead">Node Groups</TableCell>
                  <TableCell className="thead d-flex justify-content-end">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {titles.length > 0 ? (
                  titles.map((title) => (
                    <TableRow key={title.group_id}>
                      <TableCell className="tData">
                        {title.group_title}
                      </TableCell>
                      <TableCell className="tData" align="right">
                        <Button
                          className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                          title="Edit Details"
                          color="primary"
                          onClick={() => this.toggleUpdateTitleModal(title)}
                        />

                        <Button
                          className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                          title="Delete"
                          onClick={() => this.handleDeleteGrade(title.group_id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <div style={{ height: "90vh" }}>No Node Group Titles available</div>
                )}
              </TableBody>
            </Table>
          </div>
        )}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={addTitleModal}
          onHide={this.toggleAddTitleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Node Group Title</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Group title name</Form.Label>
                    <Form.Control
                      type="text"
                      name="group_title"
                      defaultValue={group_title}
                      placeholder="Enter title name"
                      onChange={this.handletitleChange}
                    />
                    {this.validator.message(
                      "group_title",
                      group_title,
                      "required|min:2|max:30",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}
            <Modal.Footer>
              <Button variant="primary" style={ addBtnStyle } onClick={this.handleSubmit}>
                Save
              </Button>
              <Button
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleAddTitleModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateTitleModal}
          onHide={this.toggleUpdateTitleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Node Title Group</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Group title name</Form.Label>
                    <Form.Control
                      type="text"
                      name="updateTitle"
                      value={updateTitle?.group_title}
                      placeholder="Enter title name"
                      onChange={this.handletitleChange}
                    />
                    {this.validator.message(
                      "updateTitle",
                      updateTitle,
                      "required|min:2|max:30",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}
            <Modal.Footer>
              <Button variant="primary" style={ addBtnStyle } onClick={this.handleEditSubmit}>
                Save
              </Button>
              <Button
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleUpdateTitleModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </ContentWrapper>
    )
  }
}

export default ManageNodeGroup;
