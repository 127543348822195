import React, { Component } from "react";
import ContentWrapper from "../../components/common/ContentWrapper.js";
import AdminTemplate from "../../components/admin/layouts/Template.js";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ManageGrade from "./manage/ManageGrade.js";
import ManageNodeGroup from "./manage/ManageNodeGroup.js";
import ManageEmail from "./manage/ManageEmail.js";
import ManageAddressType from "./manage/ManageAddressType.js";
import ManageCity from "./manage/ManageCity.js";
import ManageCountry from "./manage/ManageCountry.js";
import ManageProvince from "./manage/ManageProvince.js";
import ManageGender from "./manage/ManageGender.js";
//import ManageStatus from "./ManageStatus";
//import ManageStatusType from "./ManageStatusType";
import ManageTitle from "./manage/ManageTitle.js";
import ManageLimit from "./manage/ManageRegistrationLimit.js";
import ManageExpiration from "./manage/ManageRequestExpiration.js";
import ManageAnnouncementTopic from "./manage/ManageAnnouncementTopic.js";
import ManageUserAcceptTerms from "./manage/ManageUserAcceptTerms.js";
import { Modal } from "react-bootstrap";
import "./Settings.css"
import MenuIcon from "@mui/icons-material/Menu";
import config from "../../helper/config.js";
import { customerConstants } from "../../helper/constants.js";
import ManagePromoCodes from "./manage/ManagePromoCodes.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let tabTextColor;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    tabTextColor = "var(--maincolor)";
    break;
  case customerConstants.EXO_CUSTOMER2:
    tabTextColor = "var(--maincolor-cmc)";
    break;
  default:
    tabTextColor = "var(--maincolor)";
    break;
}

class EdSiderManageSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      screenWidth: window.innerWidth,
      isTabSectionVisible: false,
    };

    this.handleResize = this.handleResize.bind(this);
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue, isTabSectionVisible: false });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize() {
    this.setState({
      screenWidth: window.innerWidth,
    });
  }

  toggleTabSection = () => {
    this.setState((prevState) => ({
      isTabSectionVisible: !prevState.isTabSectionVisible,
    }));
  };

  render() {
    const { value, screenWidth, isTabSectionVisible } = this.state;

    return (
      <AdminTemplate>
        <ContentWrapper>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "100%",
            }}
          >
            <div style={{ width: screenWidth < 1000 ? "75px" : "" }}>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={this.handleChange}
                aria-label="Vertical tabs example"
                sx={{
                  "& .MuiTab-root": {
                    fontFamily: "Poppins, sans-serif",
                    textTransform: "unset",
                    letterSpacing: "-0.4px",
                    fontWeight: "600",
                  },
                  "& .Mui-selected, & .Mui-selected.MuiTab-root": {
                    color: tabTextColor + " !important",
                  },
                  borderRight: 1,
                  borderColor: "divider",
                }}
                style={{ display: screenWidth < 1000 ? "none" : "" }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: tabTextColor,
                  },
                }}
              >
                <Tab label="Manage Registration Settings" {...a11yProps(0)} />
                <Tab
                  label="Manage Request Expiration Settings"
                  {...a11yProps(1)}
                />
                <Tab label="Manage Grade" {...a11yProps(2)} />
                <Tab label="Manage Node Group" {...a11yProps(3)} />
                <Tab label="Manage Email" {...a11yProps(4)} />
                <Tab label="Manage Address Type" {...a11yProps(5)} />
                <Tab label="Manage Country" {...a11yProps(6)} />
                <Tab label="Manage Province" {...a11yProps(7)} />
                <Tab label="Manage City" {...a11yProps(8)} />
                <Tab label="Manage Gender" {...a11yProps(9)} />
                {/* <Tab label="Manage Status Type" {...a11yProps(8)} />
                        <Tab label="Manage Status" {...a11yProps(9)} /> */}
                <Tab label="Manage title" {...a11yProps(10)} />
                <Tab label="Manage Announcement Topic" {...a11yProps(11)} />
                <Tab label="Manage Promo Codes" {...a11yProps(12)} />
                <Tab label="Manage User Privacy Policy & Terms and Conditions" {...a11yProps(13)} />
              </Tabs>
            </div>
            <button
              className="settings-menu"
              style={{ display: screenWidth < 1000 ? "" : "none" }}
              onClick={this.toggleTabSection}
            >
              <MenuIcon
                style={{
                  marginTop: "15px",
                  fontSize: "24px",
                  marginLeft: "10px"
                }}
              />
            </button>
            <TabPanel value={value} index={0}>
              <ManageLimit />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ManageExpiration />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ManageGrade />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ManageNodeGroup />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <ManageEmail />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <ManageAddressType />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <ManageCountry />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <ManageProvince />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <ManageCity />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <ManageGender />
            </TabPanel>

            {/* <TabPanel value={value} index={8}>
                        <ManageStatusType/>
                    </TabPanel>
                    <TabPanel value={value} index={9}>
                        <ManageStatus/>
                    </TabPanel> */}
            <TabPanel value={value} index={10}>
              <ManageTitle />
            </TabPanel>
            <TabPanel value={value} index={11}>
              <ManageAnnouncementTopic />
            </TabPanel>
            <TabPanel value={value} index={12}>
              <ManagePromoCodes />
            </TabPanel>
            <TabPanel value={value} index={13}>
              <ManageUserAcceptTerms />
            </TabPanel>
          </Box>
        </ContentWrapper>

        {/* Tab Section */}
        <Modal
          size="sm"
          centered
          onHide={() => this.setState({ isTabSectionVisible: false })}
          show={isTabSectionVisible && screenWidth < 1000}
        >
          <Modal.Header
            className="d-flex justify-content-end"
            closeButton
          ></Modal.Header>
          <Modal.Body>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              sx={{
                "& .MuiTab-root": {
                  fontFamily: "Poppins, sans-serif",
                  textTransform: "unset",
                  letterSpacing: "-0.4px",
                  fontWeight: "600",
                  alignItems: "start",
                  whiteSpace: "pre"
                },
                "& .Mui-selected": {
                  color: tabTextColor,
                },
                borderRight: 1,
                borderColor: "divider",
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: tabTextColor,
                },
              }}
            >
              <Tab label="Manage Registration Settings" {...a11yProps(0)} />
              <Tab
                label="Manage Request Expiration Settings"
                {...a11yProps(1)}
              />
              <Tab label="Manage Grade" {...a11yProps(2)} />
              <Tab label="Manage Node Group" {...a11yProps(3)} />
              <Tab label="Manage Email" {...a11yProps(4)} />
              <Tab label="Manage Address Type" {...a11yProps(5)} />
              <Tab label="Manage Country" {...a11yProps(6)} />
              <Tab label="Manage Province" {...a11yProps(7)} />
              <Tab label="Manage City" {...a11yProps(8)} />
              <Tab label="Manage Gender" {...a11yProps(9)} />
              {/* <Tab label="Manage Status Type" {...a11yProps(8)} />
                        <Tab label="Manage Status" {...a11yProps(9)} /> */}
              <Tab label="Manage title" {...a11yProps(10)} />
              <Tab label="Manage Announcement Topic" {...a11yProps(11)} />
              <Tab label="Manage Promo Codes" {...a11yProps(12)} />
              <Tab label="Manage User Privacy Policy & Terms and Conditions" {...a11yProps(13)} />
            </Tabs>
          </Modal.Body>
        </Modal>
      </AdminTemplate>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default EdSiderManageSettings;
