import config from '../../helper/config'
import APIHandler from '../../handlers/APIHandler';

export const StudentsRoleService = {
    searchStudentRoleByUserId
};

async function searchStudentRoleByUserId(user_id){
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    const response = await fetch(`${config.oat_api_url}/user/student/roles/${user_id}`, requestOptions);
    return APIHandler.handleResponse(response);
}
