
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const assessmentService = {
    createAssessment: createAssessment,
    deleteAssessment: deleteAssessment,
    editDetails: editDetails,
    scheduleAssessment: scheduleAssessment,
    editScheduleAssessment: editScheduleAssessment,
    assignAssessors: assignAssessors,
    editAssessors: editAssessors,
    editCandidates: editCandidates,
    finalizeAssessment: finalizeAssessment,
    getAssessmentLanguage: getAssessmentLanguage,
    getAssessmentCategories: getAssessmentCategories,
    getAssessmentDifficulty: getAssessmentDifficulty,
    getAssessmentGrade: getAssessmentGrade,
    getAssessmentSubject: getAssessmentSubject,
    getAssessmentAuthor: getAssessmentAuthor,
    getAssessmentYear: getAssessmentYear,
    getAssessmentTerm: getAssessmentTerm,
    getAssessmentChapter: getAssessmentChapter,
    getAssessmentAttempt: getAssessmentAttempt,
    getAssessmentPrivilege: getAssessmentPrivilege,
    getCounts: getCounts,
    getLatestAssessments: getLatestAssessments,
    getAllAssessmentsCount: getAllAssessmentsCount,
    getTotalSharedAssessmentsCount: getTotalSharedAssessmentsCount,
    getTotalPublicAssessmentsCount: getTotalPublicAssessmentsCount,
    getAllAssessments: getAllAssessments,
    getAllAssessmentMetaData: getAllAssessmentMetaData
};


async function getAllAssessments(count) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/assessments/getAllAssessments?count=${count}`, requestOptions).then(APIHandler.handleResponse);
}

async function createAssessment(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/assessments/add/`, requestOptions).then(APIHandler.handleResponse);
}

async function editDetails(obj) {

    let requestBody = {
        created_by: obj.userID,
        assessment_id: obj.editAssessmentId,
        title: obj.editTitle,
        assessment_admin: obj.editAssessmentAdminId,
        proctor_requirement: obj.editProctorRequirement,
        assessment_state: obj.assessment_state,
        language_id: obj.language_id,
        category_id: obj.category_id,
        difficulty_id: obj.difficulty_id,
        terms_content: obj.terms_content,
        attempt_no_id: parseInt(obj.attempt_no_id),
        grade_id: obj.grade_id,
        author_id: obj.author_id,
        chapter_id: obj.chapter_id,
        subject_id: obj.subject_id,
        term_id: obj.term_id,
        privilege_id: parseInt(obj.editPrivilege),
        status_id: parseInt(obj.status_id),
        year_id: obj.year_id,
    }
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(requestBody)
    };

    const response = await fetch(`${config.oat_api_url}/assessments/update/${obj.editAssessmentId}`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function scheduleAssessment(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    const response = await fetch(`${config.oat_api_url}/assessment/schedules/add/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getAssessmentLanguage() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/languages/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getAssessmentPrivilege() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/privileges/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getAssessmentCategories() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(
        `${config.oat_api_url}/assessment/categories/getAll/`,
        requestOptions
    ).then(APIHandler.handleResponse);
}

async function getAssessmentDifficulty() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/difficulties/getAll/`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAssessmentGrade() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/grades/getAll/`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAssessmentSubject() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subjects/getAll/`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAssessmentAuthor() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/authors/getAll/`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAssessmentYear() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/years/getAll/`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAssessmentTerm() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/terms/getAll/`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAssessmentChapter() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/chapters/getAll/`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAssessmentAttempt() {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(
        `${config.oat_api_url}/assessment/attempts/getAll/`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}


async function editScheduleAssessment(obj) {
    let requestBody = {
        user_id: obj.userID,
        assessment_id: obj.editAssessmentId,
        start_time: obj.editStartTime,
        exam_time_interval: obj.editTestDuration,
        buffer_time: obj.editAccessBuffer,
        exam_time_interval_unit_id: obj.editTimeUnit,
        buffer_time_unit_id: obj.editBufferTimeUnit,
        is_set_time_limit: obj.editSetTimeLimit
    }
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(requestBody)
    };

    const response = await fetch(`${config.oat_api_url}/assessment/schedules/update/${obj.editAssessmentId}`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function editCandidates(obj) {
    let requestBody = {
        user_id: obj.userID,
        candidates: obj.editSelectedCandidates,
        candidate_id: obj.edit_candidate_id

    }
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(requestBody)
    };


    const response = await fetch(`${config.oat_api_url}/candidates/update/${obj.edit_assessment_id}`, requestOptions);
    return APIHandler.handleResponse(response);
}


async function assignAssessors(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/assessment/assessors/add/`, requestOptions).then(APIHandler.handleResponse);
}

async function editAssessors(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    const response = await fetch(`${config.oat_api_url}/assessment/assessors/edit/`, requestOptions);
    return APIHandler.handleResponse(response);
}


async function deleteAssessment(obj) {
    let requestBody = {
        user_id: obj.userID,
        assessment_id: obj.deleteAssessmentId
    }

    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(requestBody)
    };

    const response = await fetch(`${config.oat_api_url}/assessments/delete/${obj.deleteAssessmentId}`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function finalizeAssessment(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/assessments/finalizeAssessment/`, requestOptions).then(APIHandler.handleResponse);
}

async function getCounts() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/assessments/getCounts/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getLatestAssessments() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/assessments/get-latest-assesments`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getAllAssessmentsCount(user_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/assessments/getAllAssessmentsCount/${user_id}`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getTotalSharedAssessmentsCount(user_id, search_term = "") {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/assessments/getTotalSharedAssessmentsCount/${user_id}?search_term=${search_term}`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getTotalPublicAssessmentsCount() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/assessments/getTotalPublicAssessmentsCount/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getAllAssessmentMetaData() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/assessments/get-all-meta-data/`, requestOptions);
    return APIHandler.handleResponse(response);
}