import {assessmentTypes} from '../types';

const initialState = {
    assessors: '',
    assessment: '',
    loading: false,
    error: '',
    success: '',
    text: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case assessmentTypes.GET_ASSESSMENT_ASSESSORS:
            return {
                ...state,
                text: 'Obtaining assessors!',
                assessment: action.assessment,
                loading: false,
                error: '',
                success: '',
                assessors: ''
            }
        case assessmentTypes.GET_ASSESSMENT_ASSESSORS_REQUEST:
            return {
                ...state,
                text: action.content,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_ASSESSORS_SUCCESS:
            return {
                ...state,
                loading: false,
                text: '',
                success: 'assessors are Obtained Successfully!',
                assessors: action.assessor_list
            }
        case assessmentTypes.GET_ASSESSMENT_ASSESSORS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                success: ''
            }
        default:
            return state
    }
}