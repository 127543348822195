import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const MarksheetService = {
  getAllEnrolledStudents,
  getAllMarksType,
  addTypeTable,
  addMarksTable,
  getAllStudentMarks,
  getAllMarksByType,
  updateMarks,
  getReportForStudent,
  getReportForAdminAndTeachers,
  getReportOverView,
  getReportNode,
  updateMarksTable
};

async function getAllEnrolledStudents(
  node_mid,
  form_id,
  lg_user_id,
  lg_user_table_id,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/marks/getAllUsers/${node_mid}/${form_id}/${lg_user_id}/${lg_user_table_id}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllMarksType() {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/marks-types/getAll`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//Add Marks
async function addMarksTable(newMarks) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newMarks),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/marks/updateAdd/all`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateMarksTable(newMarks) {
  const configOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newMarks),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/marks/update/all`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function addTypeTable(newMarks) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newMarks),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/marks-types/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllStudentMarks(node_mid, stu_id, mark_type_id) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/marks/getById/${node_mid}/${stu_id}/${mark_type_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllMarksByType(node_mid, mark_type_id) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/marks/getById/${node_mid}/${mark_type_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateMarks(newMarks) {
  const configOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newMarks),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/marks/update`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getReportForStudent(
  node_mid,
  lg_user_id,
  lg_user_table_id,
  title_id,
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/marks/getreport/${node_mid}/${lg_user_id}/${lg_user_table_id}/${title_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getReportForAdminAndTeachers(
  node_mid,
  lg_user_id,
  lg_user_table_id,
  title_id,
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/marks/getReportByAdminAndTeacher/${node_mid}/${lg_user_id}/${lg_user_table_id}/${title_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getReportOverView(
  node_mid,
  title_id,
  subject_code,
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/marks/overview/${node_mid}/${title_id}/${subject_code}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getReportNode(
  node_mid,
  title_id,
  subject_code,
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/marks/getAllReportNode/${node_mid}/${title_id}/${subject_code}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}