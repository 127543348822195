import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const OrganizationService = {
  add,
  update,
  getOrganizationByNodeMid
};

async function add(organization) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(organization),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/organizations/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(organization, organization_id) {
  const requestOptions = {
    method: "PUT",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(organization),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/organizations/update/${organization_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getOrganizationByNodeMid(nodeMid) {
    const requestOptions = {
      method: "GET",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/organizations/get_organization_by_node_mid/${nodeMid}`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}
