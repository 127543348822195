import {quesSubjectTypes} from '../../../reducers/settings/settingsTypes'
import {questionSubjectService} from '../../../service/settings/question/subjectService'


export const quesSubjectActions = {
    getAllQuestionSubjects,
    createSubject,
    updateSubject,
    deleteSubject
}

function getAllQuestionSubjects(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let subjects = await questionSubjectService.getAllQuestionSubjects(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(subjects.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: quesSubjectTypes.GET_ALL_QUESTION_SUBJECTS}
    }

    function request() {
        return {type: quesSubjectTypes.GET_ALL_QUESTION_SUBJECTS_REQUEST}
    }

    function success(subjects) {
        return {type: quesSubjectTypes.GET_ALL_QUESTION_SUBJECTS_SUCCESS, subjects}
    }

    function failure(error) {
        return {type: quesSubjectTypes.GET_ALL_QUESTION_SUBJECTS_FAILURE, error}
    }
}


function createSubject(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await questionSubjectService.createSubject(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: quesSubjectTypes.CREATE_SUBJECT_REQUEST, data}
    }

    function success(subjects) {
        return {type: quesSubjectTypes.CREATE_SUBJECT_SUCCESS, subjects}
    }

    function failure(error) {
        return {type: quesSubjectTypes.CREATE_SUBJECT_FAILURE, error}
    }
}


function deleteSubject(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await questionSubjectService.deleteSubject(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(subject) {
        return {type: quesSubjectTypes.DELETE_SUBJECT_REQUEST, subject}
    }

    function success(success) {
        return {type: quesSubjectTypes.DELETE_SUBJECT_SUCCESS, success}
    }

    function failure(error) {
        return {type: quesSubjectTypes.DELETE_SUBJECT_FAILURE, error}
    }
}

function updateSubject(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await questionSubjectService.updateSubject(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: quesSubjectTypes.UPDATE_SUBJECT_REQUEST}
    }

    function success(subject) {
        return {type: quesSubjectTypes.UPDATE_SUBJECT_SUCCESS, subject}
    }

    function failure(error) {
        return {type: quesSubjectTypes.UPDATE_SUBJECT_FAILURE, error}
    }
}
