import React from "react";
import "../dashboard.css";
import config from "../../../../../helper/config.js";
import { customerConstants } from "../../../../../helper/constants.js";
import {
  CircularProgress,
} from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let iconColor;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)";
    break;
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)";
    break;
  default:
    iconColor = "var(--maincolor)";
    break;
}

const PaymentOverdue = ({ suitableArray, dashboardDataLoading }) => {
  return (
    <div>
      {dashboardDataLoading ? (
        <div className="d-flex justify-content-center my-3 py-3">
          <CircularProgress />
        </div>
      ) : (
        suitableArray[1]?.array.map((arrayItem, index) => (
          <div key={index} className="row payment-overdue-container py-3 my-3 px-3">
            <div className="col-md-12 mb-3">
              <p className="dashboard__container-title">{arrayItem.chart_title}</p>
            </div>
            <div className="col-md-12 d-flex justify-content-between">
              <p className="m-0">Payment Details</p>
              <p><AccountBalanceWalletIcon sx={{ color: "#A2A5B5" }}/></p>
            </div>
            <div className="col-md-12 d-flex justify-content-between dashboard__count-cards-blue my-3">
              <p className="revenue-text m-0">Outstanding</p>
              <p className="revenue-amount">{arrayItem.chart_y_value}</p>
            </div>
            <div className="col-md-12">
              <p className="reminder-text m-0">
                <WatchLaterIcon /> {arrayItem.chart_legend_x_title}
              </p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default PaymentOverdue;
