import React, { Component } from 'react'
import SimpleReactValidator from "simple-react-validator";
import debounce from "lodash.debounce";
import ContentWrapper from "../../../components/common/ContentWrapper";
import Button from "react-bootstrap/Button";
import config from '../../../helper/config.js';
import {customerConstants} from "../../../helper/constants.js";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Switch,
  CircularProgress,
} from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import sweetAlert, {
  sweetStatusAlert,
  sweetConfirmAlert
} from "../../../components/common/SweetAlert.js";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import { PromoService } from '../../../service/settings/manage/promo_code.service.js';
import { GradeService } from '../../../service/settings/manage/grade.service.js';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './styles/promocode.css'


const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

export default class ManagePromoCodes extends Component {

    constructor(props) {
        super(props);
        this.state = {
          addVisibility: false,
          editVisibility: false,
          deleteVisibility: false,
          showInsertModal: false,
          isLoading: false,
          isTableDataLoading: false,
          promo_codes: [],
          limit: 10, // Set the desired limit
          offset: 0, // Set the desired offset
          currentPage: 0,
          sortColumn:'id',
          sortDirection:'desc',
          search_term: "",
          totalPromoCodes: 10,
          isModified: false,
          isAddLoading:false,

          grades:[],
          checked:[],

          start_date:'',
          end_date:'',
          description:'',
          promo_code:'',
          promo_type:'percentage',
          promo_value:0.0,
          status:'active',
          usage_per_person:'',
          overall_usage_limit:'',

          loadingRow:null,

          allChecked: false

        };
        this.debouncedHandleSearch = debounce(this.fetchAllPromoCodes, 2000);

        this.createValidationsInstance();
      };

    componentDidMount(){
      this.fetchAllPromoCodes()
      this.fetchAllGrades()
    }

    createValidationsInstance() {
        this.validator = new SimpleReactValidator({
          validators: {
            greaterThanZero: {
              message: 'The :attribute must be greater than 0.',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val, /^\d*\.?\d+$/) && parseFloat(val) > 0;
              },
              required: true,
            },
            atLeastOneGrade: {
              message: 'At least one grade must be selected.',
              rule: (val) => {
                return val.length > 0;
              },
              required: true,
            },
            validDateRange: {
              message: 'The end date cannot be before the start date.',
              rule: (val, params, validator, state) => {
                const { start_date, end_date } = this.state;
                return (
                  new Date(end_date) >= new Date(start_date) // Ensures end_date is after or same as start_date
                );
              },
              required: true,
            },
      
          },
        });
        this.updateValidator = new SimpleReactValidator();
    }

    fetchAllPromoCodes = async() =>{

      const { 
        limit, 
        offset, 
        search_term,
        sortColumn,
        sortDirection, 
      } =this.state;

      try {
        this.setState({isLoading:true})
        await PromoService.getAll(
          limit, 
          offset, 
          search_term,
          sortColumn,
          sortDirection)
          .then((response)=>{

            if(response.success){
              this.setState({promo_codes:response.data.ser_promo_code,totalPromoCodes:response.data.total_count, isLoading:false})              
            }
            this.setState({isLoading:false})
          })
      } catch (error) {
        console.error("Failed to get promo codes");
        this.setState({isLoading:false})
        
      }
    }

    fetchAllGrades = async() =>{
      
      try {

        await GradeService.getGradesWithLimit()
          .then((response)=>{

            if(response.success){
              this.setState({grades:response.data})
            }
            
          })
      } catch (error) {
        console.error("Failed to get grade list");
        
      }
    };

    toggleAddPromoModal = () => {

      this.setState({
        checked:[],
        start_date:'',
        end_date:'',
        description:'',
        promo_code:'',
        promo_type:'percentage',
        promo_value:0.0,
        status:'active',
        usage_per_person:'',
        overall_usage_limit:'',
      })

      this.setState((prevState) => ({
        showInsertModal: !prevState.showInsertModal,
      }));
    };

    handlePageChange = (event, newPage) => {
      const { limit } = this.state;
      const newOffset = newPage * limit;
      this.setState(
        {
          offset: newOffset,
          isLoading: true,
          currentPage: newPage,
        },
        () => {
          this.fetchAllPromoCodes();
        }
      );
    };
  
    handlePageOffsetChange = (newOffset, newPage) => {
      this.setState(
        {
          offset: newOffset,
          isLoading: true,
          currentPage: newPage
        },
        () => {
          this.fetchAllPromoCodes();
        }
      );
    };
  
    // handle the data fetching as per the data per page limit gets changess
    handleRowsPerPageChange = (event) => {
      const newLimit = parseInt(event.target.value, 10);
      const newPage = Math.floor(this.state.offset / newLimit);
      this.setState(
        {
          limit: newLimit,
          offset: newLimit * newPage,
          isLoading: true,
          currentPage: newPage,
        },
        () => {
          this.fetchAllPromoCodes();
        }
      );
    };

    handleSearch = (event) => {
      const search_term = event.target.value.trim();

        this.setState(
          {
            search_term,
            offset: 0,
            currentPage: 0,
          },
          () => {
            this.debouncedHandleSearch();
          }
        );
  };

    handleSweetAlert = (toggleFunction, modalVisibility) => {
      if (this.state.isModified) {
        sweetAlert().then((response) => {
          this.validator.hideMessages();
          this.validator.purgeFields();
          this.setState(() => ({
            [modalVisibility]: response,
            isModified: false,
          }));
        });
      } else {
        this.validator.hideMessages();
        this.validator.purgeFields();
        toggleFunction();
      }
    };

    handleChange = (event) => {
      const { name, value } = event.target;
      if(name === 'promo_type') {
        this.setState({ promo_type: value, promo_value: 0, isModified: true });
      } else if (name === 'promo_code') {
        this.setState({ [name]: value.toUpperCase(), isModified: true });
      } else if (name === 'promo_value') {
        if (this.state.promo_type === 'percentage' && value > 100) {
          this.setState({ promo_value: 100, isModified: true });
        } else {
          this.setState({ promo_value: value, isModified: true });
        }
      } else {
        this.setState({ [name]: value, isModified: true });
      }
    };

    handleSubmit = async (event) => {
      
      event.preventDefault();
  
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate();
      } else {
        this.setState({ isAddLoading: true });

        const { 
          start_date,
          end_date,
          promo_code,
          promo_type,
          promo_value,
          status,
          usage_per_person,
          overall_usage_limit,
          checked,
          promo_codes,
        } = this.state;

        const create_user_id = sessionStorage.getItem('manage_user_id')
        const create_user_table_id = sessionStorage.getItem('table_log_id')
        const user_table_id = 1
        const node_mid = 100

        const newPromo = { 
          start_date,
          end_date,
          promo_code,
          promo_type,
          promo_value: parseFloat(promo_value),
          status,
          usage_per_person: parseInt(usage_per_person),
          overall_usage_limit: parseInt(overall_usage_limit),
          checked,
          create_user_id,
          create_user_table_id,
          user_table_id,
          node_mid };

        await PromoService.add(newPromo)
          .then((response) => {
            this.setState({ isAddLoading: false });
            if (response.success) {
              this.setState({ showInsertModal: false });
              sweetStatusAlert("Success!", response.message, "success");

              const newPromo = response?.data
            
              this.setState((prevState) => ({
                promo_codes: [newPromo,...prevState.promo_codes],
              }));

              this.validator.hideMessages();
              this.validator.purgeFields();
            } else {
              sweetStatusAlert("Failed!", response.message, "error");
            }
          })
          .catch(() => {
            this.setState({ isAddLoading: false });
            sweetStatusAlert("Failed !", "Unable to create new title", "error");
          });
      }
    };

    handleToggle = (value) => {
      const {checked} = this.state;
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      this.setState({checked:newChecked})

    };

    handlePromoStatusChange = (id) => {

      const { promo_codes } = this.state;

        sweetConfirmAlert().then((response) => {

          if(response){

            const promo = promo_codes.find((p) => p.id === id);

            this.setState({ loadingRow: id }); 

            const newStatus = {
              status : promo?.status === 'active' ? 'inactive' : 'active'
            }
          
            setTimeout(async() => {

              try {
              
                await PromoService.changePromoStatus(id,newStatus)
                  .then((response)=>{

                    if(response.success){

                      const updatedStatus = response?.data?.status

                      const updatedPromoCodes = promo_codes.map((p) => {
                        if (p.id === id) {
                          return { ...p, status: updatedStatus };
                        }
                        return p;
                      });
            
                      this.setState({ promo_codes: updatedPromoCodes, loadingRow: null });

                      sweetStatusAlert("Success","Promo code status updated!","success")
            
                    }
                    else{
                      sweetStatusAlert("Failed","Failed to update promo code","error")
                      this.setState({ loadingRow: null });
                    }
                  })
              } catch (error) {
                sweetStatusAlert("Failed","Failed to update promo code","error")
                this.setState({ loadingRow: null });
              }
              this.setState({ loadingRow: null });
            }, 2000); 
          }
        });
    };

    handleSelectAll =()=> {
      const { grades, allChecked } = this.state;
      if (allChecked) {
        this.setState({
          checked: [],
          allChecked: false,
        });
      } else {
        this.setState({checked:[]},()=>{
          const allGradeIds = grades?.map((grade) => grade.grade_id);
          this.setState({
            checked: allGradeIds,
            allChecked: true,
          });
        })
        
      }
    }

    render() {

    const {
      addVisibility,
      editVisibility,
      deleteVisibility,
      isLoading,
      isTableDataLoading,
      totalPromoCodes,
      currentPage,
      limit,
      offset,
      promo_codes,
      showInsertModal,
      grades,
      checked,
      isAddLoading,

      start_date,
      end_date,
      description,
      promo_code,
      promo_type,
      promo_value,
      status,
      usage_per_person,
      overall_usage_limit,

      loadingRow,
      allChecked

        } = this.state;

        return (
          <>
            <ContentWrapper>
                <div className="row justify-content-between align-items-center py-5 mb-3">
                  <div className="col-md-6 col-6">
                    <h3 className="text-nowrap">Manage Promo Codes</h3>
                  </div>
                  <div>
                    {!addVisibility && (
                      <Button
                        className="insert-btn"
                        variant="primary"
                        onClick={() => this.toggleAddPromoModal(true)}
                      >
                        Add New Promo Code
                      </Button>
                    )}
                  </div>
                </div>

                <div>
                <Form.Group controlId="exampleForm.ControlInput1" className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    // style={{ width: "18%" }}
                    className="col-md-3 col-12"
                  />
                  
                </Form.Group>
                {isLoading && (
                  <PageSpinner id="show" text="Loading, Please wait!" />
                )}
                {isTableDataLoading ? (
                    <div
                      className="loading-container"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "75vw",
                        height: "90vh",
                      }}
                    >
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ): promo_codes?.length > 0 ? (
                    <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className="thead">Promo Code</TableCell>
                              <TableCell className="thead">Start Date</TableCell>
                              <TableCell className="thead">End Date</TableCell>
                              <TableCell className="thead">Promo Code Type</TableCell>
                              <TableCell className="thead">Promo Code Value</TableCell>
                              <TableCell className="thead">Usage Per Person</TableCell>
                              <TableCell className="thead">Overall Usage Limit</TableCell>
                              <TableCell className="thead">Available Grades</TableCell>
                              {(!editVisibility || !deleteVisibility) && (
                                <TableCell className="thead">Status</TableCell>
                              )}
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {promo_codes?.map((promo) => (
                              <TableRow key={promo.id}>
                                <TableCell className="tData">
                                  <b>{promo.promo_code}</b>
                                </TableCell>
                                <TableCell className="tData">
                                  {new Date(promo.start_date).toLocaleDateString('en-CA')}
                                </TableCell>
                                <TableCell className="tData">
                                  {new Date(promo.end_date).toLocaleDateString('en-CA')}
                                </TableCell>
                                <TableCell className="tData">
                                  {promo.promo_type}
                                </TableCell>
                                <TableCell className="tData">
                                  {promo.promo_value}
                                </TableCell>
                                <TableCell className="tData">
                                  {promo.usage_per_person}
                                </TableCell>
                                <TableCell className="tData">
                                  {promo.overall_usage_limit}
                                </TableCell>
                                <TableCell className="tData">
                                <CustomWidthTooltip
                                  arrow
                                  placement="left"
                                  title={
                                    promo?.grades && promo.grades.length > 0 ? (
                                      promo.grades.map((grade, index) => (
                                        <div style={{fontSize:'10px',padding:'5px'}} key={index}>{`Grade: ${grade}`}</div>
                                      ))
                                    ) : (
                                      <div>No Grades</div>
                                    )
                                  }
                                >
                                  <span>
                                    <IconButton>
                                      <VisibilityIcon/>
                                    </IconButton>
                                  </span>
                                </CustomWidthTooltip>
                                </TableCell>
                                {(!editVisibility || !deleteVisibility) && (
                                  <TableCell>
                                    <div>
                                      {loadingRow === promo.id ? (
                                        <CircularProgress size={24} /> // Show loader when the row is loading
                                      ) : (
                                        <Switch
                                          checked={promo?.status === 'active'}
                                          onChange={() => this.handlePromoStatusChange(promo?.id)}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                          disabled={loadingRow!==null}
                                        />
                                      )}
                                    </div>
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <MaterialCustomTablePagination
                          totalCount = {totalPromoCodes}
                          currentPage = {currentPage}
                          limit = {limit}
                          handlePageChange = {this.handlePageChange}
                          handleRowsPerPageChange = {this.handleRowsPerPageChange}
                          handlePageOffsetChange = {this.handlePageOffsetChange}
                          offset = {this.state.offset}
                          rowsPerPageOptions={[5, 10, 20]}
                        />
                      </div>
                      ) : (
                        <div
                          className="text-center mb-5 mt-10"
                          style={{ height: "90vh" }}
                        >
                          <label>No Promo Codes Available!</label>
                        </div>
                      )}
                </div>
            </ContentWrapper>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.state.showInsertModal}
                onHide={() =>
                  this.handleSweetAlert(this.toggleAddPromoModal, "showInsertModal")
                }
              >
                <Modal.Header closeButton>
                  <Modal.Title>Create Promo Code </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                  {isAddLoading ?(
                    <div
                    className="loading-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                  ):(
                  <Form>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Start Date"
                          name="start_date"
                          onChange={this.handleChange}
                          value={start_date}
                        />
                        {this.validator.message(
                          "start_date",
                          this.state.start_date,
                          "required",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The start date required",
                            },
                          }
                        )}
                        
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="End Date"
                          name="end_date"
                          value={end_date}
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "end_date",
                          this.state.end_date,
                          "required|validDateRange",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The end date required",
                            },
                          }
                        )}
                        
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Promo Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Promo Code"
                          name="promo_code"
                          value={promo_code}
                          onChange={this.handleChange}
                          style={{ textTransform: 'uppercase' }}
                        />
                        {this.validator.message(
                          "promo_code",
                          this.state.promo_code,
                          "required",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The promo code required",
                            },
                          }
                        )}
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Applicable Grades</Form.Label>
                          <div className="grade-item">
                              <Checkbox
                                edge="start"
                                checked={allChecked}
                                onChange={this.handleSelectAll}
                                tabIndex={-1}
                                disableRipple
                              />
                              <span>Select All</span>
                            </div>
                        <div className="grade-list">
                            
                          {grades.length > 0 ? (
                            grades.map((value) => {
                              const labelId = `checkbox-list-label-${value?.grade_id}`;

                              return (
                                <div key={value?.grade_id} className="grade-item">
                                  <Checkbox
                                    edge="start"
                                    checked={checked.includes(value?.grade_id)}
                                    onChange={() => this.handleToggle(value?.grade_id)}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                                  <span id={labelId}>{value?.grade}</span>
                                </div>
                              );
                            })
                            
                          ) : (
                            <>No grades</>
                          )}
                        </div>
                        {this.validator.message(
                            "checked", 
                            this.state.checked, 
                            "atLeastOneGrade",
                            {
                              className: "text-danger",
                            }
                          )}
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                        style={{marginTop:'10px'}}
                      >
                        <Form.Label>Discount Type</Form.Label>

                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          value={promo_type}
                          name="promo_type"
                          onChange={this.handleChange}
                        >
                          <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
                          <FormControlLabel value="amount" control={<Radio />} label="Amount" />
                  
                        </RadioGroup>
                        
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                        style={{marginTop:'10px'}}
                      >
                        <Form.Label>Status</Form.Label>

                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          value={status}
                          name="status"
                          onChange={this.handleChange}
                        >
                          <FormControlLabel value="active" control={<Radio />} label="Active" />
                          <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                  
                        </RadioGroup>
                        
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Discount Value {promo_type === 'percentage'? '( 0% - 100% )' : '( LKR )'}</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="promo value"
                          name="promo_value"
                          value={promo_value}
                          onChange={this.handleChange}
                          onKeyDown={(e) => {
                            if (e.key === '-' || e.key === '+') {
                              e.preventDefault(); 
                            }
                          }}
                        />
                        {this.validator.message(
                          "promo_value",
                          this.state.promo_value,
                          "required|greaterThanZero|numeric",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The discount value required",
                              numeric:"The discount value must be a number",
                              greaterThanZero:"The discount value must be a greater than 0."
                            },
                          }
                        )}
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Usage per person</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Usage per person"
                          name="usage_per_person"
                          value={usage_per_person}
                          onChange={this.handleChange}
                          onKeyDown={(e) => {
                            if (e.key === '-' || e.key === '+') {
                              e.preventDefault();
                            }
                          }}
                        />
                        {this.validator.message(
                          "usage_per_person",
                          this.state.usage_per_person,
                          "required|greaterThanZero|numeric",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The usage per person required",
                              numeric:"The usage per person must be a number",
                              greaterThanZero:"The usage per person must be greater than 0"
                            },
                          }
                        )}
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Overall usage limit</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Overall usage limit"
                          name="overall_usage_limit"
                          value={overall_usage_limit}
                          onChange={this.handleChange}
                          onKeyDown={(e) => {
                            if (e.key === '-' || e.key === '+') {
                              e.preventDefault();
                            }
                          }}
                        />
                        {this.validator.message(
                          "overall_usage_limit",
                          this.state.overall_usage_limit,
                          "required|greaterThanZero|numeric",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The overall usage limit required",
                              numeric:"The overall usage limit must be a number",
                              greaterThanZero:"The overall usage limit must be greater than 0"
                            },
                          }
                        )}
                      </Form.Group>

                    </Row>
                  </Form>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.handleSubmit}
                    disabled={isAddLoading}
                  >
                    Save changes
                  </Button>
                  <Button
                    id="modal-close-button"
                    style={closeBtnStyle}
                    disabled={isAddLoading}
                    onClick={() =>
                      this.handleSweetAlert(
                        this.toggleAddPromoModal,
                        "showInsertModal"
                      )
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
            </Modal>
          </>
            
        )
    }
}
