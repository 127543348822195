import React from 'react';
import {history} from "../../helper/history";
import Form from '../common/form/Form';
import Spinner from "../common/Spinner"
import { routes } from '../../helper/routes';

class LoginForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clickLogin: false,
            loading: false,
        };
    }

    handleRoleSelect = (role) => {
        sessionStorage.setItem('user_role_selection', role);
        history.push(routes.auth_response)
    }

    render() {

        return (
            <div className="login-form">
                {this.state.loading && <Spinner id="show" text="Logging In..." />}
                {/* begin::Form */}
                <Form className="form" id="kt_login_singin_form">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="form-group">
                                <a onClick={() => this.handleRoleSelect('teacher')}
                                    className="btn btn-facebook btn-block mb-4">
                                    LOGIN
                                </a>
                            </div>
                        </div>
                    </div>
                </Form>
                {/* end::Form */}
            </div>
        )
    }
}

export default LoginForm