import config from '../../../helper/config'
import APIHandler from '../../../handlers/APIHandler';

export const ParentDashboardService = {
    getAllForAnnonuncements,
    getAllStudentsForParent,
    getAllParents
};

async function getAllForAnnonuncements(
    parent_id
  ) {
    const requestOptions = {
      method: "GET",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };
    return fetch(
      `${config.oat_api_url}/assessments/getPapers/parent-announcements/${parent_id}`,
      requestOptions
    ).then(APIHandler.handleResponse);
    
  }

  async function getAllStudentsForParent(parent_id) {
    const requestOptions = {
      method: "GET",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };
    return fetch(
      `${config.oat_api_url}/manage/registration/parents/get-child-details-by-parent-id/${parent_id}`,
      requestOptions
    ).then(APIHandler.handleResponse);
    
  }

  async function getAllParents(student_id) {
    const requestOptions = {
      method: "GET",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    return fetch(
      `${config.oat_api_url}/manage/registration/parents/get-all-parents/${student_id}`,
      requestOptions
    ).then(APIHandler.handleResponse);
  }


