import React, { forwardRef } from 'react';


export default function SwitchCheck({ className, type, name, onChange, checked, id }) {

    return (
        <div className={className}>
            <span className="switch switch-icon">
                <label>
                    <input type={type} name={name} onChange={onChange} checked={checked} id={id} />

                    <span></span>
                </label>
            </span>
        </div>
    )
}