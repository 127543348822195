import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import debounce from "lodash.debounce";
import { routes } from "../../helper/routes";
import { history } from "../../helper/history";
import Spinner from "../../components/common/Spinner";
import ContentWrapper from "../../components/common/AssessmentContentWrapper";
import Modal from "../../components/common/modal/SelfGradeModal";
import ModalBody from "../../components/common/modal/ModalBody";
import ModalFooter from "../../components/common/modal/ModalFooter";
import Card from "../../components/common/Card";
import CardHeader from "../../components/common/CardHeader";
import CardBody from "../../components/common/CardBody";
import CardTitle from "../../components/common/AssessmentCardTitle";
import CardToolbar from "../../components/common/QuestionCardToolBar";
import Breadcrumb from "../../components/common/Breadcrumb";
import BreadcrumbItem from "../../components/common/BreadcrumbItem";
import Button from "../../components/common/form/Button";
import SearchFormGroup from "../../components/common/table/SearchFormGroup";
import Select from "../../components/common/form/Select";
import Option from "../../components/common/form/Option";
import CheckBox from "../../components/common/form/CheckBox";
import CheckBoxInline from "../../components/common/form/CheckBoxInline";
import TabList from "../../components/common/tabs/TabList";
import NavItem from "../../components/common/tabs/NavItem";
import TabContent from "../../components/common/tabs/TabContent";
import TabPane from "../../components/common/tabs/TabPane";
import GradeNavItem from "../../components/common/tabs/GradeNavItem";
import TableFilter from "../../components/common/table/TableFilter";
import SearchInputWrap from "../../components/common/table/SearchInputWrap";
import SearchInput from "../../components/common/table/SearchInput";
import FinalizeTab from "../../components/common/tabs/FinalizeTab";
import FinalizeLeftWrap from "../../components/common/tabs/FinalizeLeftWrap";
import FinalizeQuestionTitle from "../../components/common/tabs/FinalizeQuestionTitle";
import FinalizeNavItemActions from "../../components/common/tabs/FinalizeNavItemActions";
import FinalizeTabContent from "../../components/common/tabs/FinalizeTabContent";
import CustomEditor from "../../components/common/editor/CustomEditor";
import FormGroup from "../../components/common/form/FormGroup";
import Marks from "../../components/common/tabs/Marks";
import FinalizeRightWrap from "../../components/common/tabs/FinalizeRightWrap";
import FinalizeTabPane from "../../components/common/tabs/FinalizeTabPane";
import { Editor } from "react-draft-wysiwyg";
import ViewEditor from "../../components/common/editor/ViewEditor";
import {convertToRaw, convertFromRaw, EditorState} from "draft-js";
import { assessorActions } from "../../actions/assessment/assessorActions";
import { questionActions } from "../../actions/assessment/questionActions";
import {questionTypeConstants} from "../../helper/constants";
import DataHandler from "../../handlers/DataHandler";
import { AzureStorage } from "../../helper/azureStorage";
import { staffUserActions } from "../../actions/user_management/staffUserActions.js";
import { manageUserActions } from "../../actions/user_management/manageUserActions.js";
import { createActions } from "../../actions/assessment/createActions";
import config from '../../helper/config.js';
import {customerConstants} from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let guidanceColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    guidanceColor = {
      color: "var(--maincolor-cmc)"
    }
    // cancelClass = "cmc-btn-default"
    // confirmClass = "cmc-btn-primary"
    break
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class Grade extends React.Component {
  constructor(props) {
    super(props);

    this.ref_tagify = React.createRef();
    this.state = {
      assessmentQuestions: [],
      total: "",
      errors: {},
      changes: false,
      setTotal: true,
      questions: "",
      changeQuestions: true,
      searchValue: "",
      selectedCategory: 0,
      selectedType: 0,
      selectedDifficulty: 0,
      chkbox: false,
      filteredQuestions: [],
      remarksEditorState: EditorState.createEmpty(),
      loadRemarks: true,
      prevMark: "",
      questionMap: {},
      teacherDetailsMap: {},
      loadingGradePage: false
    };

    this.onSave = this.onSave.bind(this);
    this.getEditability = this.getEditability.bind(this);
    this.finalizeGrade = this.finalizeGrade.bind(this);
    this.addRemarks = this.addRemarks.bind(this);
    this.onChangeMarks = this.onChangeMarks.bind(this);
    this.updateMarks = debounce(this.updateMarks.bind(this), 1500);
    this.handleChangeQuestionCategory = this.handleChangeQuestionCategory.bind(this);
    this.handleChangeQuestionDifficulty = this.handleChangeQuestionDifficulty.bind(this);
    this.handleChangeQuestionType = this.handleChangeQuestionType.bind(this);
    this.searchChange = this.searchChange.bind(this);
    this.onRemarksEditorChange = this.onRemarksEditorChange.bind(this);
    this.onDownloadAttachment = this.onDownloadAttachment.bind(this);
    this.getQuestionCreator = this.getQuestionCreator.bind(this);
  }

  onUnload = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = "";
  };

  async componentDidMount() {
    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
    if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
    if (window.KTApp.initComponents) window.KTApp.initComponents();
    if (window.$) window.$(".selectpicker").selectpicker();
    if (typeof window.KTLayoutStickyCard !== "undefined") {
      window.KTLayoutStickyCard.update();
    } else {
      if (window.KTLayoutStickyCard)
        window.KTLayoutStickyCard.init("kt_page_sticky_card");
    }
    await this.props.getAllQuestions();
    await this.props.getStaffUsers();
    if (_.isEqual({}, this.props.staffUserMap)) {
      await this.props.createStaffUserMap();
    }

    if (
      this.props.assessmentQuestions &&
      this.props.assessmentQuestions.length &&
      !this.props.assessmentQuestions.length
    ) {
      this.setState({ assessmentQuestions: this.props.assessmentQuestions });
    }

    if (this.props.allStaffUsers.length > 0) {
      this.setState({loadingGradePage: true})
      var staffuser, teacher;
      await this.props.getAllTeachers();
      for (staffuser of this.props.allStaffUsers) {
        for (teacher of this.props.allTeachers)
          if (staffuser.staff_id === teacher.user_id && staffuser.role_code === 'ASSR') {
            let map = this.state.teacherDetailsMap;
            let teacherName = teacher.first_name.concat(" ", teacher.last_name)
            map[staffuser.staff_id] = teacherName
            this.setState({ teacherDetailsMap: map })
        }
      }
      this.setState({loadingGradePage: false})
    }

    await Promise.all(
       await this.props.assessmentQuestions.map(async(question) => {
        this.setState({loadingGradePage: true})
        let map = this.state.questionMap;
        map[question["question_id"]] = question;
        let staff_id = this.getQuestionCreator(question.added_by);
        let questionCreatorName = this.state.teacherDetailsMap[staff_id];
        map[question["question_id"]]["creatorName"] = questionCreatorName;
        this.setState({questionMap: map})
        this.setState({loadingGradePage: false})
    })
    );

    if (this.props.candidateAnswers.length && this.state.setTotal) {
      var i,
        total = 0;
      for (i = 0; i < this.props.candidateAnswers.length; i++) {
        if (this.props.candidateAnswers[i].mark_obtained) {
          total = total + this.props.candidateAnswers[i].mark_obtained;
        }
      }
      this.setState({
        total: total,
        setTotal: false,
      });
    }

    await this.props.getQuestionTypes();
    await this.props.getQuestionCategories();
    await this.props.getQuestionDifficulty();
    
    window.onpopstate = function (event) {
      history.push(routes.grade_report);
      window.location.reload();
    };
    window.addEventListener("beforeunload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  componentDidUpdate() {
    if (this.props.answerMap && this.state.changeQuestions) {
      var data = new Object();
      Object.assign(data, this.props.answerMap);
      this.setState({
        questions: data,
        changeQuestions: false,
      });
    }

    if (
      this.props.assessmentQuestions &&
      this.props.assessmentQuestions.length &&
      !this.props.assessmentQuestions.length
    ) {
      this.setState({ assessmentQuestions: this.props.assessmentQuestions });
    }

    if (this.props.candidateAnswers.length && this.state.setTotal) {
      var i,
        total = 0;
      var answers = this.answerMap;
      for (i = 0; i < this.props.candidateAnswers.length; i++) {
        var id = this.props.candidateAnswers[i].question_id;
        if (
          this.props.candidateAnswers[i].mark_obtained ||
          this.props.candidateAnswers[i].mark_obtained == 0
        ) {
          total = total + this.props.candidateAnswers[i].mark_obtained;
        } else {
          this.props.candidateAnswers[i].mark_obtained = "";
        }
      }
      this.setState({
        total: total,
        setTotal: false,
      });
    }

    if (this.props.assessorRemarks && this.state.loadRemarks) {
      let state = JSON.parse(this.props.assessorRemarks);
      let tempQuestionState = EditorState.createWithContent(
        convertFromRaw(state)
      );
      this.setState({
        remarksEditorState: tempQuestionState,
        loadRemarks: false,
      });
    }

    window.$(".selectPicker").selectpicker("refresh");
    window.$("#question-difficulty").selectpicker("refresh");
    window.$("#question-type").selectpicker("refresh");
    window.$("#question-category").selectpicker("refresh");
  }

  async componentWillUnmount() {
    if (this.state.changes) {
      await window.Swal.fire({
        text: "You have unsaved changes in previous page!",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: !1,
        confirmButtonText: "Save changes",
        cancelButtonText: "Discard",
        customClass: {
          confirmButton: `btn font-weight-bold ${cancelClass}`,
          cancelButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
          }
        }
      }).then(async (action) => {
        if (action.isConfirmed) {
          this.setState({
            changes: false,
          });
          const assessment_id = this.props.assessment.assessment_id;
          const user_role_id = this.props.assessment.user_role_id;
          const questions = this.props.candidateAnswers;
          const candidate_id = this.props.candidate.candidate_id;
          const attempt_no = this.props.candidate.attempt_no;
          // const attempt_no = this.props.
          const assessor_remarks = convertToRaw(
            this.state.remarksEditorState.getCurrentContent()
          );
          await this.props.saveMarks({
            assessment_id,
            questions,
            user_role_id,
            assessor_remarks,
            candidate_id,
            attempt_no,
          });
        }
      });
    }
  }

  handleChangeChk = (e) => {
    let checked = !this.state.chkbox;
    this.setState({ chkbox: checked });
  };

  handleChangeQuestionType(e) {
    let type = e.target.value;
    this.setState({ selectedType: type });
  }

  handleChangeQuestionDifficulty(e) {
    let difficulty = e.target.value;
    this.setState({ selectedDifficulty: difficulty });
  }

  handleChangeQuestionCategory(e) {
    let category = e.target.value;
    this.setState({
      selectedCategory: category,
    });
  }

  onRemarksEditorChange(editorState) {
    this.setState({
      remarksEditorState: editorState,
      changes: true,
    });
  }

  getQuestionTypeName(qType) {
    switch (qType) {
      case questionTypeConstants.MCQ.id:
        return questionTypeConstants.MCQ.name;
      case questionTypeConstants.DESCRIPTIVE.id:
        return questionTypeConstants.DESCRIPTIVE.name;
      case questionTypeConstants.SUBMISSION.id:
        return questionTypeConstants.SUBMISSION.name;
      default:
        return "Invalid Type";
    }
  }

  getQuestionCreator(user_id) {
    var id;
    if (user_id.startsWith("ASSR")) {
      id = user_id.replace(/^ASSR/, "");
    } else {
      id = user_id.replace(/^CASS/, "");
    }
    var staff_id = this.props.staffUserMap[id]["staff_id"];
    return staff_id;
  }

  getColor(val) {
    switch (val) {
      case "-1":
        return "label label-lg label-light-danger label-inline";
      case "1":
        return "label label-lg label-light-success label-inline";
      default:
        return "";
    }
  }

  async onDownloadAttachment(e, container, attach) {
    e.preventDefault();
    let fileReader;
    async function handleFileRead() {
      const content = fileReader.result;
    }

    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    let content = await AzureStorage.downloadBlob(container, attach);
    fileReader.readAsArrayBuffer(content);
  }

  getAnswerInEditor(el, i) {
    var question_type = this.props.assessmentQuestions[i].question_type;
    var answer =
      this.props.contentMap[this.props.assessmentQuestions[i].question_id];
    let states = JSON.parse(this.props.questionContents[i]);
    var no_ans = states.length - 1;
    var tempAnswerStates = [];
    if (question_type == questionTypeConstants.DESCRIPTIVE.name) {
      if (answer) {
        var answerState = JSON.parse(answer);
        return (
          <h3 className="mb-5 overflow-hidden">
            <div>
              <Editor
                editorState={EditorState.createWithContent(
                  convertFromRaw(answerState)
                )}
                toolbarClassName="hide-toolbar"
                toolbarHidden={true}
                readOnly={true}
              />
            </div>
          </h3>
        );
      }
    } else if (question_type == questionTypeConstants.MCQ.name) {
      if (answer) {
        answer = JSON.parse(answer);
        var anyBoxesChecked = [],
          ans;
        for (var i = 0; i < no_ans; i++) {
          anyBoxesChecked.push(false);
        }
        for (ans in answer) {
          anyBoxesChecked[answer[ans]] = true;
        }
        states.shift();
        states.forEach((state) => {
          tempAnswerStates.push(
            EditorState.createWithContent(convertFromRaw(state))
          );
        });
        return (
          <div>
            <div className="RichEditor-root">
              {tempAnswerStates.map((answer, j) => {
                return (
                  <div className="row">
                    <div className="col-md-1">
                      <CheckBoxInline className="col-md-1">
                        <CheckBox
                          key={j} checked={anyBoxesChecked[j]}
                          style ={{
                            color: "#5458AF",
                          }}
                        />
                      </CheckBoxInline>
                    </div>
                    <div className="col-md-11">
                      <Editor
                        editorState={answer}
                        toolbarHidden={true}
                        toolbarClassName="hide-toolbar"
                        readOnly={true}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div class="separator separator-dashed separator-border-2 mt-10 mb-10"></div>
          </div>
        );
      }
    } else if (question_type == questionTypeConstants.SUBMISSION.name) {
      let attachmentName = answer
        .replace(/^\[(.+)\]$/, "$1")
        .replace(/"\"$/, "$1")
        .split(",");
      const container = "".concat(
        this.props.candidate.candidate_id,
        "-",
        this.props.assessmentQuestions[i].question_id
      );
      if (attachmentName.length) {
        let itemList = [];
        {
          attachmentName.map((attach) => {
            if (attach.replace(/"/g, "") != "[]") {
              itemList.push(
                <div>
                  <Button
                    className="btn btn-outline-primary primary-button mr-2"
                    btnIconStyle="flaticon-interface-3"
                    inlineStyle={{ innerWidth: "400px" }}
                    btnName={attach.replace(/"/g, "")}
                    onClick={(e) => {
                      this.onDownloadAttachment(
                        e,
                        container,
                        attach.replace(/"/g, "")
                      );
                    }}
                  />
                  <br></br>
                </div>
              );
            }
          });
        }
        return <div className="RichEditor-root col-lg-8">{itemList}</div>;
      } else {
        return <div>No answer uploaded.</div>;
      }
    }
  }

  getContentInEditor(el, i) {
    var question_type = this.props.assessmentQuestions[i].question_type;
    let states = JSON.parse(this.props.questionContents[i]);
    let tempAnswerStates = [];
    let tempQuestionState = EditorState.createWithContent(
      convertFromRaw(states[0])
    );
    if (question_type == questionTypeConstants.DESCRIPTIVE.name) {
      return (
        <h3 className="mb-5 overflow-hidden">
          <div>
            <ViewEditor
              editorState={tempQuestionState}
            />
          </div>
        </h3>
      );
    } else if (question_type == questionTypeConstants.MCQ.name) {
      states.shift();
      states.forEach((state) => {
        tempAnswerStates.push(
          EditorState.createWithContent(convertFromRaw(state))
        );
      });
      return (
        <div>
          <h3 className="mb-5 overflow-hidden">
            <div>
              <ViewEditor
                editorState={tempQuestionState}
              />
            </div>
          </h3>
          <div>
            <div className="RichEditor-root">
              {tempAnswerStates.map((answer, j) => {
                return (
                  <div className="row">
                    <div className="col-md-1">
                      <CheckBoxInline className="col-md-1">
                        <CheckBox
                          key={j}
                          checked={false}
                          style ={{
                            color: "#5458AF",
                          }}
                        />
                      </CheckBoxInline>
                    </div>
                    <div className="col-md-11">
                      <Editor
                        editorState={answer}
                        toolbarHidden={true}
                        toolbarClassName="hide-toolbar"
                        readOnly={true}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div class="separator separator-dashed separator-border-2 mt-10 mb-10"></div>
        </div>
      );
    } else if (question_type == questionTypeConstants.SUBMISSION.name) {
      return (
        <div>
          <h3 className="mb-5 overflow-hidden">
            <ViewEditor
              editorState={tempQuestionState}
            />
          </h3>
          <div class="separator separator-dashed separator-border-2 mt-10 mb-10"></div>
        </div>
      );
    }
  }

  getAssessorAnswerInEditor(el, i) {
    var question_type = this.props.assessmentQuestions[i].question_type,
      answerState;
    // let container = "".concat(
    //   this.props.assessmentQuestions[i].created_by,
    //   "-sa-",
    //   this.props.assessmentQuestions[i].answer
    // );
    let container = config.storageBlobConfigs.containerName;
    let states = JSON.parse(this.props.questionContents[i]);
    var tempAnswerStates = [];
    if (question_type == questionTypeConstants.DESCRIPTIVE.name) {
      var answer = JSON.parse(this.props.answerContents[i]);
      return (
        <h3 className="mb-5 overflow-hidden">
          <div>
            <Editor
              editorState={EditorState.createWithContent(
                convertFromRaw(answer[0])
              )}
              toolbarClassName="hide-toolbar"
              toolbarHidden={true}
              readOnly={true}
            />
          </div>
        </h3>
      );
    } else if (question_type == questionTypeConstants.MCQ.name) {
      var res = this.props.answerContents[i]?.split(",");
      states.shift();
      states.forEach((state) => {
        tempAnswerStates.push(
          EditorState.createWithContent(convertFromRaw(state))
        );
      });
      return (
        <div>
          <div className="RichEditor-root">
            {tempAnswerStates.map((answer, j) => {
              let val = "0";
              if (parseFloat(res[j]) > 0) {
                val = "1";
              } else if (parseFloat(res[j]) < 0) {
                val = "-1";
              }
              return (
                <div className="row">
                  <div className="col-md-1">
                    <CheckBoxInline className="col-md-1">
                      <CheckBox
                        key={j}
                        checked={parseFloat(res[j]) > 0}
                        style ={{
                          color: "#5458AF",
                        }}
                      />
                    </CheckBoxInline>
                  </div>
                  <div className="col-md-10">
                    <Editor
                      editorState={answer}
                      toolbarHidden={true}
                      toolbarClassName="hide-toolbar"
                      readOnly={true}
                    />
                  </div>
                  <div className={this.getColor(val)}>
                    {parseFloat(res[j]) || ""}
                  </div>
                </div>
              );
            })}
          </div>
          <div class="separator separator-dashed separator-border-2 mt-10 mb-10"></div>
        </div>
      );
    } else if (question_type == questionTypeConstants.SUBMISSION.name) {
      let attachmentName = this.props.answerContents[i]
        .replace(/^\[(.+)\]$/, "$1")
        .replace(/"\"$/, "$1")
        .split(",");

      if (attachmentName.length) {
        let itemList = [];
        {
          attachmentName.map((attach) => {
            if (attach.replace(/"/g, "") != "[]") {
              itemList.push(
                <div>
                  <Button
                    className="btn btn-outline-primary primary-button mr-2"
                    btnIconStyle="flaticon-interface-3"
                    inlineStyle={{ innerWidth: "400px" }}
                    btnName={attach.replace(/"/g, "")}
                    onClick={(e) => {
                      this.onDownloadAttachment(
                        e,
                        container,
                        attach.replace(/"/g, "")
                      );
                    }}
                  />
                  <br></br>
                </div>
              );
            }
          });
        }
        return <div className="RichEditor-root col-lg-8">{itemList}</div>;
      } else {
        return <div>No answer uploaded.</div>;
      }
    }
  }

   getAssessorAnswerGuidanceInEditor(question, i) {
    var guidance = this.props.assessmentQuestions[i].guidence

    var guidanceContent = JSON.parse(guidance);

    let guidanceEditorContent = EditorState.createWithContent(
      convertFromRaw(guidanceContent[0])
    )

    var question_type = this.props.assessmentQuestions[i].question_type;

    if (question_type === questionTypeConstants.MCQ.name && guidance) {
      return (
        <div>
          <div className="row">
            <div className="col-md-2 col-2 d-flex align-items-center">
              <h4 style={guidanceColor}>Marking Guidance:</h4>
            </div>
            <div className="col-md-10 col-10">
              <div className="card p-3">
                <h3 className="">
                  {guidance !== null && guidance !== ""  ? (
                    <ViewEditor editorState={guidanceEditorContent} />
                  ) : (
                    <div>
                      <p>No marking guidance given for this question</p>
                    </div>
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="separator border border-primary mt-4 mb-4"></div>
        </div>
      );
    } else if (question_type == questionTypeConstants.DESCRIPTIVE.name && guidance) {
      return (
        <div>
          <div className="row">
            <div className="col-md-2 col-2 d-flex align-items-center">
              <h4 style={guidanceColor}>Marking Guidance:</h4>
            </div>
            <div className="col-md-10 col-10">
              <div className="card p-3">
                <h3 className="">
                  {guidance !== null && guidance !== "" ? (
                    <ViewEditor editorState={guidanceEditorContent} />
                  ) : (
                    <div>
                      <p>No marking guidance given for this question</p>
                    </div>
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="separator border border-primary mt-4 mb-4"></div>
        </div>
      );
    } else if (question_type == questionTypeConstants.SUBMISSION.name && guidance) {
      return (
        <div>
          <div className="row">
            <div className="col-md-2 col-2 d-flex align-items-center">
              <h4 style={guidanceColor}>Marking Guidance:</h4>
            </div>
            <div className="col-md-10 col-10">
              <div className="card p-3">
                <h3 className="">
                  {guidance !== null && guidance !== "" ? (
                    <ViewEditor editorState={guidanceEditorContent} />
                  ) : (
                    <div>
                      <p>No marking guidance given for this question</p>
                    </div>
                  )}
                </h3>
              </div>
            </div>
          </div>
          <div className="separator border border-primary mt-4 mb-4"></div>
        </div>
      );
    }
  }

  async updateMarks(value, i, el) {
    if (!isNaN(parseFloat(value))) {
      var marks = parseFloat(value);
      if (el.mark_allocation >= marks && el.mark_allocation >= 0) {
        const assessor_id = this.props.assessment.user_role_id;
        var i,
          total = 0;
        var id = el.question_id;
        await this.props.onSetMarks({ id, marks, assessor_id });
        for (i = 0; i < this.props.candidateAnswers.length; i++) {
          if (this.props.candidateAnswers[i].mark_obtained) {
            total = total + this.props.candidateAnswers[i].mark_obtained;
          }
        }
        this.setState({
          total: total,
          setTotal: false,
          changes: true,
        });
      } else {
        window.Swal.fire({
          title: "Warning!",
          text: "Value should be positive and less than or equal to allocated value!",
          icon: "warning",
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
        });
        var values = this.state.questions;
        values[el.question_id].mark_obtained = "";
        var total = 0;
        for (i = 0; i < this.props.candidateAnswers.length; i++) {
          if (this.props.candidateAnswers[i].mark_obtained) {
            total = total + this.props.candidateAnswers[i].mark_obtained;
          }
        }
        this.setState({
          total: total,
          questions: values,
          setTotal: false,
          changes: true,
        });
      }
    } else {
      window.Swal.fire({
        title: "Warning!",
        text: "Not a number!",
        icon: "warning",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
      },
      onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
      }
      });
      var values = this.state.questions;
      values[el.question_id].mark_obtained = "";
      var total = 0;
      for (i = 0; i < this.props.candidateAnswers.length; i++) {
        if (this.props.candidateAnswers[i].mark_obtained) {
          total = total + this.props.candidateAnswers[i].mark_obtained;
        }
      }
      this.setState({
        total: total,
        questions: values,
        setTotal: false,
        changes: true,
      });
    }
  }

  async onChangeMarks(e, i, el) {
    var values = this.state.questions;
    this.setState({
      prevMark: values[el.question_id].mark_obtained,
    });
    values[el.question_id].mark_obtained = e.target.value;
    this.setState({
      questions: values,
    });
    var value = e.target.value;
    this.updateMarks(value, i, el);
  }

  async onSave() {
    const assessment_id = this.props.assessment.assessment_id;
    const user_role_id = this.props.assessment.user_role_id;
    const questions = this.props.candidateAnswers;
    const candidate_id = this.props.candidate.candidate_id;
    const attempt_no = this.props.candidate.attempt_no;
    const assessor_remarks = convertToRaw(
      this.state.remarksEditorState.getCurrentContent()
    );
    window.Swal.showLoading();
    await this.props.saveMarks({
      assessment_id,
      questions,
      user_role_id,
      assessor_remarks,
      candidate_id,
      attempt_no,
    });
    if (!this.props.gradeLoading && this.props.gradeError) {
      window.Swal.fire({
        title: "Failed!",
        text: "Failed to grade!",
        icon: "error",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
      },
      onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
      }
      });
    } else {
      window.Swal.fire({
        title: "Success!",
        text: "Saved successfully!",
        icon: "success",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
      },
      onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
      }
      });
    }
  }

  async finalizeGrade() {
    var unMarked = "",
      i = 1;
    for (const [key, value] of Object.entries(this.props.answerMap)) {
      if (!(value.mark_obtained || value.mark_obtained == 0)) {
        unMarked = unMarked + "Question " + i + " ";
      }
      i = i + 1;
    }

    if (unMarked == "") {
      const assessment_id = this.props.assessment.assessment_id;
      const user_role_id = this.props.assessment.user_role_id;
      const questions = this.props.candidateAnswers;
      const candidate_id = this.props.candidate.candidate_id;
      const final_grade = this.props.candidate.final_grade;
      const attempt_no = this.props.candidate.attempt_no;
      const assessor_remarks = convertToRaw(
        this.state.remarksEditorState.getCurrentContent()
      );
      await this.props.saveMarks({
        assessment_id,
        questions,
        user_role_id,
        assessor_remarks,
        candidate_id,
        attempt_no,
      });
      await this.props.finalizeGrade({
        candidate_id,
        final_grade,
        user_role_id,
        attempt_no,
      });
      if (!this.props.gradeLoading && this.props.gradeError) {
        window.Swal.fire({
          title: "Warning!",
          text: "Cannot finalize the grade! Make sure all the answers has been graded!",
          icon: "warning",
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
        });
      } else if (this.getEditability()) {
        window.removeEventListener("beforeunload", this.onUnload);
        window.Swal.fire({
          title: "Success!",
          text: "Finalized successfully!",
          icon: "success",
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
          }).then(
          (result) => {
            if (result.isConfirmed) {
              this.changeRoute();
            }
          }
        );
      } else if (!this.getEditability()) {
        window.removeEventListener("beforeunload", this.onUnload);
        window.Swal.fire({
          title: "Success!",
          text: "Unpublished successfully!",
          icon: "success",
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
            }
        }
        })
        // .then((result) => {
        //   if (result.isConfirmed) {
        //     this.changeRoute();
        //   }
        // });
      }
    } else {
      window.Swal.fire({
        title: "Warning!",
        text: "Cannot finalize the grade! Make sure all the answers has been graded!",
        icon: "warning",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
      },
      onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
      }
      });
    }
  }

  autoGrade() {
    window.Swal.fire({
      text: "Do you want to auto grade questions? (The questions that has already been graded manually will not be auto graded)",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: !1,
      confirmButtonText: "Proceed",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: `btn font-weight-bold ${confirmClass}`,
        cancelButton: `btn font-weight-bold ${cancelClass}`,
      },
    }).then(async (action) => {
      if (action.isConfirmed) {
        window.Swal.showLoading();
        var i;
        for (i = 0; i < this.props.assessmentQuestions.length; i++) {
          if (this.props.assessmentQuestions[i].question_type == questionTypeConstants.MCQ.name) {
            var candidate_answer =
              this.props.contentMap[
                this.props.assessmentQuestions[i].question_id
              ];
            candidate_answer = JSON.parse(candidate_answer);
            var res = this.props.answerContents[i].split(",");
            let numOfCandidateAnswers = candidate_answer.length;
            let numOfAnswers = 0;
            for (var m in res) {
              if (res[m] != "Nan" && res[m] != 0) {
                numOfAnswers = numOfAnswers + 1;
              }
            }
            if (numOfCandidateAnswers <= numOfAnswers) {
              var marks = 0,
                j,
                ans_index;
              for (j in candidate_answer) {
                ans_index = candidate_answer[j];
                marks =
                  marks +
                  (res[ans_index] *
                    this.props.assessmentQuestions[i].mark_allocation) /
                    100;
              }
              const assessor_id = this.props.assessment.user_role_id;
              var id = this.props.assessmentQuestions[i].question_id;
              await this.props.onSetMarks({ id, marks, assessor_id });
            } else {
              var marks = 0;
              const assessor_id = this.props.assessment.user_role_id;
              var id = this.props.assessmentQuestions[i].question_id;
              await this.props.onSetMarks({ id, marks, assessor_id });
            }
          }
        }

        var total = 0;
        for (i = 0; i < this.props.assessmentQuestions.length; i++) {
          var candidate_answer =
            this.props.contentMap[
              this.props.assessmentQuestions[i].question_id
            ];
          if (candidate_answer.mark_obtained) {
            total = total + candidate_answer.mark_obtained;
          }
        }
        this.onSave();
        this.setState({
          total: total,
          setTotal: true,
          questions: this.props.answerMap,
        });
      }
    });
  }

  addRemarks() {
    window.Swal.fire({
      title: "Success!",
      text: "Remarks added successfully!",
      icon: "success",
      customClass: {
        confirmButton: `btn font-weight-bold ${confirmClass}`,
    },
    onOpen: (modalElement) => {
        if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
        }
    }
    });
  }
  searchChange(e) {
    let val = e.target.value;
    this.setState({
      searchValue: val,
    });
  }

  getEditability() {
    return !(this.props.candidate?.final_grade == null);
  }

  questionsFilter(questions, type, difficulty, category) {
    let filteredQuestions = [];
    var userId = DataHandler.getFromSession("user_id");
    var questionLibrary = this.props.questions;
    var qType;
    var qDifficulty;
    var qCategory;
    if (this.state.chkbox) {
      questions.map((question) => {
        if (question.added_by.includes(userId)) {
          questionLibrary.map((q) => {
            if (q.question_id === question.question_id) {
              this.props.allCategories.map((el, i) => {
                if (el.category_id === q.category_id) {
                  qCategory = el.name;
                }
              });
              this.props.allQuestionTypes.map((el, i) => {
                if (el.type_id === q.qtype) {
                  qType = el.qtype;
                }
              });
              this.props.allDifficulty.map((el, i) => {
                if (el.difficulty_id === q.difficulty_id) {
                  qDifficulty = el.difficulty_type;
                }
              });
            }
          });
          if (category == 0 && type == 0 && difficulty == 0) {
            filteredQuestions.push(question);
          } else if (category == qCategory && type == 0 && difficulty == 0) {
            filteredQuestions.push(question);
          } else if (category == 0 && type == qType && difficulty == 0) {
            filteredQuestions.push(question);
          } else if (category == 0 && type == 0 && difficulty == qDifficulty) {
            filteredQuestions.push(question);
          } else if (
            category == qCategory &&
            type == qType &&
            difficulty == 0
          ) {
            filteredQuestions.push(question);
          } else if (
            category == qCategory &&
            type == 0 &&
            difficulty == qDifficulty
          ) {
            filteredQuestions.push(question);
          } else if (
            category == 0 &&
            type == qType &&
            difficulty == qDifficulty
          ) {
            filteredQuestions.push(question);
          } else if (
            category == qCategory &&
            type == qType &&
            difficulty == qDifficulty
          ) {
            filteredQuestions.push(question);
          }
        }
      });
    } else {
      questions.map((question) => {
        this.props.assessmentQuestions.map((q) => {
          if (q.question_id === question.question_id) {
            this.props.allCategories.map((el, i) => {
              if (el.category_id === q.question_category) {
                qCategory = el.name;
              }
            });
            this.props.allQuestionTypes.map((el, i) => {
              if (el.qtype === q.question_type) {
                qType = el.qtype;
              }
            });
            this.props.allDifficulty.map((el, i) => {
              if (el.difficulty_id === q.question_difficulty) {
                qDifficulty = el.difficulty_type;
              }
            });
          }
        });

        if (category == 0 && type == 0 && difficulty == 0) {
          filteredQuestions.push(question);
        } else if (category == qCategory && type == 0 && difficulty == 0) {
          filteredQuestions.push(question);
        } else if (category == 0 && type == qType && difficulty == 0) {
          filteredQuestions.push(question);
        } else if (category == 0 && type == 0 && difficulty == qDifficulty) {
          filteredQuestions.push(question);
        } else if (category == qCategory && type == qType && difficulty == 0) {
          filteredQuestions.push(question);
        } else if (
          category == qCategory &&
          type == 0 &&
          difficulty == qDifficulty
        ) {
          filteredQuestions.push(question);
        } else if (
          category == 0 &&
          type == qType &&
          difficulty == qDifficulty
        ) {
          filteredQuestions.push(question);
        } else if (
          category == qCategory &&
          type == qType &&
          difficulty == qDifficulty
        ) {
          filteredQuestions.push(question);
        }
      });
    }
    return filteredQuestions;
  }
  questionTable(showingQuestionsList) {
    return (
      this.props.assessmentQuestions &&
      this.props.assessmentQuestions.length &&
      this.props.candidateAnswers &&
      this.props.candidateAnswers.length &&
      this.state.questions && (
        <FinalizeLeftWrap finalizeView={false}>
          <span className="label label-lg label-light-primary label-inline font-weight-bold m-2">
            Question Count: {showingQuestionsList.length}
          </span>
          {showingQuestionsList.map((el, i) => (
            <GradeNavItem
              class="question"
              key={i}
              added_by={this.state.questionMap[el.question_id]?.creatorName}
              className={
                (this.state.questions[el.question_id]?.graded_by &&
                  "nav-link position-relative pt-10 pl-5 pr-5 pb-5") ||
                "nav-link position-relative pt-10 pl-5 pr-5 pb-5 non-graded"
              }
              id={"home-tab-".concat(String(i + 1))}
              dataToggle="tab"
              href={"#home-".concat(String(i + 1))}
            >
              <FinalizeQuestionTitle Question={el.title} />
              <FinalizeNavItemActions>
                <Marks
                  viewAllocated={true}
                  label={el.mark_allocation}
                  value={this.state.questions[el.question_id].mark_obtained}
                  onChangeMarks={(e) => this.onChangeMarks(e, i, el)}
                  mark_button_visible={true}
                  readOnly={this.getEditability()}
                  question={i}
                />
              </FinalizeNavItemActions>
            </GradeNavItem>
          ))}
        </FinalizeLeftWrap>
      )
    );
  }

  changeRoute() {
    window.location = routes.view_assessment;
  }

  render() {
    if (this.state.add_question) {
      return <Redirect to={routes.finalize_new_question} />;
    }
    var questionOp = this.props.assessmentQuestions;
    var qTypeNames = [];
    var qCategoryNames = [];
    var qDiffNames = [];
    questionOp.map((q) => {
      if (!qTypeNames.includes(q.question_type)) {
        qTypeNames.push(q.question_type);
      }

      this.props.allCategories.map((el, i) => {
        if (el.category_id === q.question_category) {
          if (!qCategoryNames.includes(el.name)) {
            qCategoryNames.push(el.name);
          }
        }
      });

      this.props.allDifficulty.map((el, i) => {
        if (el.difficulty_id === q.question_difficulty) {
          if (!qDiffNames.includes(el.difficulty_type)) {
            qDiffNames.push(el.difficulty_type);
          }
        }
      });
    });

    var categoryOptions = [];
    var typeOptions = [];
    var difficultyOptions = [];
    categoryOptions.push(
      <Option key={0} optionName={"None"} value={0} selected="selected" />
    );
    typeOptions.push(
      <Option key={0} optionName={"None"} value={0} selected="selected" />
    );
    difficultyOptions.push(
      <Option key={0} optionName={"None"} value={0} selected="selected" />
    );

    qCategoryNames.map((el, i) => {
      categoryOptions.push(<Option key={i + 1} optionName={el} value={el} />);
    });

    qTypeNames.map((el, i) => {
      typeOptions.push(<Option key={i + 1} optionName={el} value={el} />);
    });

    qDiffNames.map((el, i) => {
      difficultyOptions.push(<Option key={i + 1} optionName={el} value={el} />);
    });

    const filteredQuestionss = this.props.assessmentQuestions.filter(
      (question, i) => {
        return (
          question.title
            .toLowerCase()
            .indexOf(this.state.searchValue.toLowerCase()) !== -1
        );
      }
    );

    return (
      <ContentWrapper>
        {/* begin::Card */}
        {this.state.loadingGradePage && <Spinner id="show" text="Please wait.." />}
        {this.props.gradeLoading && <Spinner id="show" text="Please wait.." />}
        {this.props.dropdownDataLoading && <Spinner id="show" text="Please wait.." />}
        {this.props.assessment.title != undefined ? (
          <Card>
            <CardHeader>
              <CardTitle cardLabel={this.props.assessment.title}>
                <Breadcrumb>
                  <BreadcrumbItem
                    to={routes.view_assessment}
                    breadcrumbItemName="Assessments Library"
                  />
                  <BreadcrumbItem
                    to={routes.grade_report}
                    breadcrumbItemName="Grade Report"
                  />
                  {/* <BreadcrumbItem
                    to="grade"
                    active="active"
                    breadcrumbItemName="Grade"
                  /> */}
                </Breadcrumb>
              </CardTitle>
              <CardToolbar>
                <Button
                  className="btn btn-primary mr-2 finalize-btn"
                  btnName="Save Changes"
                  onClick={this.onSave}
                  disabled={this.getEditability()}
                />
                {!this.getEditability() && (
                  <Button
                    className="btn btn-primary mr-2 finalize-btn"
                    btnName="Finalize"
                    btnIconStyle="flaticon2-correct"
                    onClick={this.finalizeGrade}
                  />
                )}
                {this.getEditability() && (
                  <Button
                    className="btn btn-primary mr-2 finalize-btn"
                    btnName="Unpublish grade"
                    btnIconStyle="flaticon2-correct"
                    onClick={this.finalizeGrade}
                  />
                )}
                <Button
                  onClick={() => this.autoGrade()}
                  btnName="Auto Grade"
                  className="btn btn-primary mr-2 finalize-btn"
                  disabled={this.getEditability()}
                />
                <Button
                  className="btn btn-primary finalize-btn"
                  btnName="Add Remarks"
                  dataToggle="modal"
                  dataTarget="#add-remarks"
                  title="Add Remarks"
                />
              </CardToolbar>
            </CardHeader>

            <CardBody>
              {/* begin::Search */}
              <TableFilter>
                <SearchFormGroup
                  labelName="My Questions"
                  className="col-md-3 my-questions"
                  labelColStyle="col-lg-8 col-5 col-form-label pr-0"
                  formColStyle="col-lg-4"
                >
                  <CheckBoxInline>
                    <CheckBox
                      checked={this.state.chkbox}
                      onChange={this.handleChangeChk}
                      style ={{
                        color: "#5458AF",
                      }}
                    />
                  </CheckBoxInline>
                </SearchFormGroup>

                <SearchFormGroup
                  labelName="Category"
                  className="col-md-3 category"
                  labelColStyle="col-lg-12 col-form-label pr-0"
                >
                  {categoryOptions.length && (
                    <Select
                      selectWrap="col-lg-12"
                      selectId="question-category"
                      id="question-category"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={categoryOptions[0]}
                      multiple={false}
                      spinner={
                        this.props.dropdownDataLoading && categoryOptions.length <= 0
                      }
                      disabled={
                        this.props.dropdownDataLoading && categoryOptions.length <= 0
                      }
                      children={categoryOptions}
                      onChange={this.handleChangeQuestionCategory}
                    ></Select>
                  )}
                </SearchFormGroup>

                <SearchFormGroup
                  labelName="Type"
                  className="col-md-3 type"
                  labelColStyle="col-lg-12 col-form-label pr-0"
                >
                  {typeOptions.length && (
                    <Select
                      selectWrap="col-lg-12"
                      selectId="question-type"
                      id="question-type"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={typeOptions[0]}
                      multiple={false}
                      spinner={this.props.dropdownDataLoading && typeOptions.length <= 0}
                      disabled={this.props.dropdownDataLoading && typeOptions.length <= 0}
                      children={typeOptions}
                      onChange={this.handleChangeQuestionType}
                    ></Select>
                  )}
                </SearchFormGroup>

                <SearchFormGroup
                  labelName="Difficulty"
                  className="col-md-3 difficulty"
                  labelColStyle="col-lg-12 col-form-label pr-0"
                >
                  {difficultyOptions.length && (
                    <Select
                      selectWrap="col-lg-12"
                      selectId="question-difficulty"
                      id="question-difficulty"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      defaultValue={difficultyOptions[0]}
                      multiple={false}
                      spinner={
                        this.props.dropdownDataLoading && difficultyOptions.length <= 0
                      }
                      disabled={
                        this.props.dropdownDataLoading && difficultyOptions.length <= 0
                      }
                      children={difficultyOptions}
                      onChange={this.handleChangeQuestionDifficulty}
                    ></Select>
                  )}
                </SearchFormGroup>
              </TableFilter>
              {/* end::Search */}
              {this.props.assessmentQuestions.length > 0 ? (
                <FinalizeTab>
                  {this.questionTable(
                    this.questionsFilter(
                      filteredQuestionss,
                      this.state.selectedType,
                      this.state.selectedDifficulty,
                      this.state.selectedCategory
                    )
                  )}
                  <FinalizeRightWrap>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="bg-gray-200 rounded-lg p-2 m-total-marks text-center">
                          <h5 className="text-dark font-weight-bold d-block font-size-h5">
                            Total Marks
                          </h5>
                          <h3 className="text-dark font-weight-bold font-size-h5 m-0">
                            {this.state.total}
                          </h3>
                        </div>
                      </div>
                      <SearchInputWrap className="col-md-6 float-right">
                        <SearchInput
                          label="Search Questions"
                          value={this.state.searchValue}
                          onChange={this.searchChange}
                        />
                      </SearchInputWrap>
                    </div>
                    <br />
                    {this.props.assessmentQuestions &&
                      this.props.assessmentQuestions.length &&
                      this.props.questionContents &&
                      this.props.questionContents.length &&
                      this.questionsFilter(
                        filteredQuestionss,
                        this.state.selectedType,
                        this.state.selectedDifficulty,
                        this.state.selectedCategory
                      ).length > 0 && (
                        <FinalizeTabContent>
                          <FinalizeTabPane
                            className="tab-pane fade show active"
                            id="home-0"
                            role="tabpanel"
                            ariaLabelledby={"home-tab-0"}
                            question="Select question to view content"
                          />
                          {this.questionsFilter(
                            filteredQuestionss,
                            this.state.selectedType,
                            this.state.selectedDifficulty,
                            this.state.selectedCategory
                          ).map((el, i) => {
                            let num;
                            this.props.assessmentQuestions.map((q, j) => {
                              if (q.question_id === el.question_id) {
                                num = j;
                              }
                            });
                            return (
                              <FinalizeTabPane
                                className="tab-pane fade"
                                id={"home-".concat(String(i + 1))}
                                role="tabpanel"
                                ariaLabelledby={"home-tab-".concat(
                                  String(i + 1)
                                )}
                                question={
                                  <div>
                                    <TabList>
                                      <NavItem
                                        className="nav-link active"
                                        id={"answer-tab-".concat(String(i + 1))}
                                        href={"#answer-".concat(String(i + 1))}
                                        dataToggle="tab"
                                        iconName="flaticon2-chat-1"
                                        tabName="Answer"
                                      />
                                      <NavItem
                                        className="nav-link"
                                        id={"question-tab-".concat(
                                          String(i + 1)
                                        )}
                                        href={"#question-".concat(
                                          String(i + 1)
                                        )}
                                        dataToggle="tab"
                                        iconName="flaticon-users-1"
                                        tabName="Question"
                                      />
                                      <NavItem
                                        className="nav-link"
                                        id={"assessor-answer-tab-".concat(
                                          String(i + 1)
                                        )}
                                        href={"#assessor-answer-".concat(
                                          String(i + 1)
                                        )}
                                        dataToggle="tab"
                                        iconName="flaticon2-rocket-1"
                                        tabName="Assessor Answer"
                                      />
                                      <NavItem
                                          className="nav-link"
                                          id={"assessor-guidance".concat(
                                            String(i + 1)
                                          )}
                                          href={"#assessor-guidance-tab-".concat(
                                            String(i + 1)
                                          )}
                                          dataToggle="tab"
                                          iconName="flaticon2-rocket-1"
                                          tabName="Assessor Guidance"
                                        />
                                    </TabList>
                                    <TabContent>
                                      <TabPane
                                        className="tab-pane fade show active"
                                        id={"answer-".concat(String(i + 1))}
                                        ariaLabelledby={"answer-tab-".concat(
                                          String(i + 1)
                                        )}
                                      >
                                        {this.getAnswerInEditor(el, num)}
                                      </TabPane>
                                      <TabPane
                                        className="tab-pane fade"
                                        id={"question-".concat(String(i + 1))}
                                        ariaLabelledby={"question-tab-".concat(
                                          String(i + 1)
                                        )}
                                      >
                                        {this.getContentInEditor(el, num)}
                                      </TabPane>
                                      <TabPane
                                        className="tab-pane fade"
                                        id={"assessor-answer-".concat(
                                          String(i + 1)
                                        )}
                                        ariaLabelledby={"assessor-answeror-answer-tab-".concat(
                                          String(i + 1)
                                        )}
                                      >
                                        {this.getAssessorAnswerInEditor(
                                          el,
                                          num
                                        )}
                                      </TabPane>
                                      <TabPane
                                          className="tab-pane fade"
                                          id={"assessor-guidance-tab-".concat(
                                            String(i + 1)
                                          )}
                                          ariaLabelledby={"assessor-marking-guidance-tab-".concat(
                                            String(i + 1)
                                          )}
                                        >
                                          {this.getAssessorAnswerGuidanceInEditor(
                                              el,
                                              num
                                            )}
                                        </TabPane>
                                    </TabContent>
                                  </div>
                                }
                              />
                            );
                          })}
                        </FinalizeTabContent>
                      )}
                  </FinalizeRightWrap>
                </FinalizeTab>
              ) : (
                <span className="label label-lg label-light-warning label-inline">
                  No Questions Added!
                </span>
              )}
            </CardBody>
          </Card>
        ) : (
          <div>
            {" "}
            <Redirect to={routes.view_assessment} />
          </div>
        )}
        {/* end::Card */}

        <Modal modalTitle="Add Remarks" modalId="add-remarks">
          <ModalBody>
            <div
              className="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <FormGroup labelName="Add remarks here: " className="col-lg-12">
                <div className="col-lg-9">
                  <div className="RichEditor-root-createquestion">
                    <CustomEditor
                      value={this.state.remarksEditorState}
                      editorState={this.state.remarksEditorState}
                      onEditorStateChange={this.onRemarksEditorChange}
                    />
                  </div>
                </div>
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-light-primary font-weight-bold"
              dataDismiss="modal"
              btnName="Add remark"
              onClick={this.addRemarks}
            />
            <Button
              className="btn btn-light-primary font-weight-bold"
              dataDismiss="modal"
              btnName="Close"
            />
          </ModalFooter>
        </Modal>
      </ContentWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    allStaffUsers: state.staffUserReducer.allStaffUsers,
    staffUserMap: state.staffUserReducer.staffUserMap,
    allTeachers: state.manageUserReducer.allTeachers,

    assessment: state.candidateReducer.assessment,
    question: state.questionReducer.question,
    questionContent: state.questionReducer.questionContent,
    questionContents: state.questionReducer.questionContents,
    dropdownDataLoading: state.questionsCreateReducer.loading,
    answerContents: state.questionReducer.answerContents,
    questions: state.questionReducer.questions,
    assessmentQuestions: state.questionReducer.assessmentQuestions,

    candidateAnswers: state.gradeReducer.candidateAnswers,
    candidate: state.gradeReducer.candidate,
    candidateAnswerContents: state.gradeReducer.candidateAnswerContents,
    answerMap: state.gradeReducer.candidateAnswerMap,
    contentMap: state.gradeReducer.candidateAnswerContentMap,
    assessorRemarks: state.gradeReducer.assessorRemarks,
    gradeError: state.gradeReducer.error,
    gradeLoading: state.gradeReducer.loading,

    allCategories: state.questionsCreateReducer.allCategories,
    allQuestionTypes: state.questionsCreateReducer.allQuestionTypes,
    allDifficulty: state.questionsCreateReducer.allDifficulty,
  };
}

const mapActionsToProps = {
  createStaffUserMap: staffUserActions.createStaffUserMap,
  getAllTeachers: manageUserActions.getAllTeachers,
  refreshPage: questionActions.refreshPage,
  getStaffUsers: staffUserActions.getAllStaffUsers,
  getAllQuestions: questionActions.getAllQuestions,
  getQuestionContent: questionActions.getQuestionContent,
  refreshforAddingQuestions: questionActions.refreshforAddingQuestions,
  getAssessmentQuestions: questionActions.getAssessmentQuestions,
  getAllQuestionContents: questionActions.getAllQuestionContents,
  removeMyQuestions: questionActions.removeMyQuestions,
  saveMarks: assessorActions.saveMarks,
  finalizeGrade: assessorActions.finalizeGrade,
  getAllAnswerContents: questionActions.getAllAnswerContents,
  getAllCandidateAnswerContents: assessorActions.getAllCandidateAnswerContents,
  onSetMarks: assessorActions.onSetMarks,

  getQuestionTypes: questionActions.getQuestionTypes,
  getQuestionCategories: questionActions.getQuestionCategories,
  getQuestionDifficulty: questionActions.getQuestionDifficulty,
};

export default connect(mapStateToProps, mapActionsToProps)(Grade);
