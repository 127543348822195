import {quesChapterTypes} from '../../../reducers/settings/settingsTypes'
import {quesChapterService} from '../../../service/settings/question/chapterService'


export const quesChapterActions = {
    getAllQuestionChapters,
    createChapter,
    updateChapter,
    deleteChapter
}

function getAllQuestionChapters(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let chapters = await quesChapterService.getAllQuestionChapters(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(chapters.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: quesChapterTypes.GET_ALL_QUESTION_CHAPTERS}
    }

    function request() {
        return {type: quesChapterTypes.GET_ALL_QUESTION_CHAPTERS_REQUEST}
    }

    function success(chapters) {
        return {type: quesChapterTypes.GET_ALL_QUESTION_CHAPTERS_SUCCESS, chapters}
    }

    function failure(error) {
        return {type: quesChapterTypes.GET_ALL_QUESTION_CHAPTERS_FAILURE, error}
    }
}


function createChapter(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await quesChapterService.createChapter(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: quesChapterTypes.CREATE_CHAPTER_REQUEST, data}
    }

    function success(chapters) {
        return {type: quesChapterTypes.CREATE_CHAPTER_SUCCESS, chapters}
    }

    function failure(error) {
        return {type: quesChapterTypes.CREATE_CHAPTER_FAILURE, error}
    }
}


function deleteChapter(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await quesChapterService.deleteChapter(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(chapter) {
        return {type: quesChapterTypes.DELETE_CHAPTER_REQUEST, chapter}
    }

    function success(success) {
        return {type: quesChapterTypes.DELETE_CHAPTER_SUCCESS, success}
    }

    function failure(error) {
        return {type: quesChapterTypes.DELETE_CHAPTER_FAILURE, error}
    }
}

function updateChapter(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await quesChapterService.updateChapter(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: quesChapterTypes.UPDATE_CHAPTER_REQUEST}
    }

    function success(chapter) {
        return {type: quesChapterTypes.UPDATE_CHAPTER_SUCCESS, chapter}
    }

    function failure(error) {
        return {type: quesChapterTypes.UPDATE_CHAPTER_FAILURE, error}
    }
}
