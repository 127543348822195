import {quesTermTypes} from '../../../reducers/settings/settingsTypes'
import {questionTermService} from '../../../service/settings/question/termService'


export const quesTermActions = {
    getAllQuestionTerms,
    createTerm,
    updateTerm,
    deleteTerm
}

function getAllQuestionTerms(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let terms = await questionTermService.getAllQuestionTerms(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(terms.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: quesTermTypes.GET_ALL_QUESTION_TERMS}
    }

    function request() {
        return {type: quesTermTypes.GET_ALL_QUESTION_TERMS_REQUEST}
    }

    function success(terms) {
        return {type: quesTermTypes.GET_ALL_QUESTION_TERMS_SUCCESS, terms}
    }

    function failure(error) {
        return {type: quesTermTypes.GET_ALL_QUESTION_TERMS_FAILURE, error}
    }
}


function createTerm(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await questionTermService.createTerm(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: quesTermTypes.CREATE_TERM_REQUEST, data}
    }

    function success(terms) {
        return {type: quesTermTypes.CREATE_TERM_SUCCESS, terms}
    }

    function failure(error) {
        return {type: quesTermTypes.CREATE_TERM_FAILURE, error}
    }
}


function deleteTerm(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await questionTermService.deleteTerm(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(term) {
        return {type: quesTermTypes.DELETE_TERM_REQUEST, term}
    }

    function success(success) {
        return {type: quesTermTypes.DELETE_TERM_SUCCESS, success}
    }

    function failure(error) {
        return {type: quesTermTypes.DELETE_TERM_FAILURE, error}
    }
}

function updateTerm(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await questionTermService.updateTerm(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: quesTermTypes.UPDATE_TERM_REQUEST}
    }

    function success(term) {
        return {type: quesTermTypes.UPDATE_TERM_SUCCESS, term}
    }

    function failure(error) {
        return {type: quesTermTypes.UPDATE_TERM_FAILURE, error}
    }
}
