import React, { Component } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "../../../../components/Spinner2";
import "./RegistrationForm.css";
import SimpleReactValidator from "simple-react-validator";
import { Col, Row } from 'react-bootstrap';
import NavBar from "../../../../components/edsider/components/NavBar";
import { GradeService } from '../../../../service/settings/manage/grade.service.js';
import { CountryService } from '../../../../service/settings/manage/country.service.js';
import config from '../../../../helper/config';
import {customerConstants} from "../../../../helper/constants.js";
import { routes } from '../../../../helper/routes.js';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};
class CmcRequestFrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name:"",
            grade: "",
            student_email:"",
            school:"",
            parent_fname:"",
            parent_lname:"",
            parent_number:"",
            parent_email: "",
            isLoading: false,
            grades:[],
            countries: [],
            country: ""
          };

        this.validator = new SimpleReactValidator({
            validators: {
                phone_number: {
                  message: "The parent contact number must be a valid phone number",
                  rule: (val, params, validator) => {
                    const isTenDigitsStartingWithZero =
                      val.length === 10 && val.startsWith("0");
                    const isNineDigits = val.length === 9 && !val.startsWith("0");
                    return isTenDigitsStartingWithZero || isNineDigits;
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: true,
                },
                child_first_name: {
                  message: "The student first name must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s.]+$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
                child_last_name: {
                  message: "The student last name must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s.]+$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
                school: {
                  message: "The :attribute must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s./]*\d{0,5}[a-zA-Z-\s./]*$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
                parent_first_name: {
                  message: "The parent first name must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s.]+$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
                parent_last_name: {
                  message: "The parent last name must have valid characters",
                  rule: (val, params, validator) => {
                    return (
                      validator.helpers.testRegex(val, /^[a-zA-Z-\s.]+$/i) &&
                      params.indexOf(val) === -1
                    );
                  },
                  messageReplace: (message, params) =>
                    message.replace(":values", this.helpers.toSentence(params)), // optional
                  required: false,
                },
              },
        });

    }

    resetForm=()=>{
        this.setState({
            name: "",
            grade: "",
            student_email:"",
            school:"",
            parent_name:"",
            parent_number:"",
            parent_email: "",
            country: ""
        });
    }

    validateForm = (data) => {
        for (let key in data) {
          if (!data[key]) {
            return false;
          }
        }
        return true;
      };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      };

    handleSubmit = async (event) => {
        event.preventDefault();

        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
        }
        else{
            this.setState({ isLoading: true });

            const data = {
                first_name: this.state.first_name,
                last_name:this.state.last_name,
                grade: "Grade "+ this.state.grade,
                child_email: this.state.student_email,
                school: this.state.school,
                parent_fname: "CMC",
                parent_lname: "Admin",
                phone_number: "0775626357",
                parent_email: "cmcadmin@edsider.com",
                country_id: parseInt(this.state.country)
              };

            if(this.validateForm(data)){

                data["invitation_status"] = 0;

                this.props.history.push({
                  pathname: routes.terms_and_conditions_view_from_registration_form,
                  state: {
                          "success":true,
                          "data":data
                        },
                  });
            }
            this.setState({ isLoading: false });
        }
    }
    
    componentDidMount() {
      window.scrollTo(0, 0);
    
      const datas = this.props.location.state ? this.props.location.state : null;
      this.getAllGrades()
      this.getAllCountries()
      if (datas && datas.data) {
        this.setState({
          first_name: datas.data.first_name,
          last_name: datas.data.last_name,
          grade: datas.data.grade.substring('Grade '.length),
          student_email: datas.data.child_email,
          school: datas.data.school,
          parent_fname: "Super",
          parent_lname: "Admin",
          phone_number: "0775626357",
          parent_email: "sysadmin@edsider.com",
          country: datas.data.country_id
        });
      }
    }

    getAllGrades=()=>{
      GradeService.getGrades()
          .then((response)=>{
              if(response.success){
                  this.setState({grades:response.data})
              }
              
          })
    }

    async getAllCountries() {
      await CountryService.getAll().then((response) => {
        this.setState({ countries: response.data });
      });
    }

    render() {
        const { isLoading, grades, countries } = this.state;
        this.validator.purgeFields();
        const currentYear = new Date().getFullYear()
        const customer = getCustomerFeatureFlag();
        let footerText, copyRightText, cardTitle, bgImageClassName;
        switch (customer) {
          case customerConstants.EXO_CUSTOMER1:
            copyRightText = "© 2024 EdSider";
            footerText = "All Right Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd";
            cardTitle = "Request Form";
            bgImageClassName = "col-md-12 login-edsider-background";
            break;
          case customerConstants.EXO_CUSTOMER2:
            copyRightText = "© 2024"
            footerText = "All Rights Reserved. Powered by EdSider (Pvt) Ltd. Supported by Infinity Innovators (Pvt) Ltd";
            cardTitle = "Registration Form";
            bgImageClassName = "col-md-12 login-cmc-background";
            break;
          default:
            copyRightText = "© 2024 EdFoci"
            footerText = "All Rights Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
            cardTitle = "Registration Form";
            bgImageClassName = "col-md-12 login-edsider-background";
            break;
        }
        return (
            <>
        <NavBar className="navigation-bg" />
        <div className={bgImageClassName}>
          <div className="row">
            <div className="row py-5">
              <div className="col-lg-12 col-xs-12">
                <div className="card login-card col-lg-6 col-md-8 col-sm-12  mt-3">
                  <h1 className="login-card-title mt-5 mb-4">{cardTitle}</h1>
                  {(customer === customerConstants.EXO_CUSTOMER1) && (<>
                    <p className="request-form-para">
                      "Welcome to our specialized online platform, EdSider, 
                      exclusively tailored to support your Grade 5 scholarship 
                      exam preparation. As a trusted resource for model papers, 
                      we're thrilled to provide a unique opportunity, available 
                      only to a select group of individuals – individuals like you, 
                      who are committed to excelling in their exams.
                    </p>
                    <p className="request-form-para">
                      Our platform isn't just about practice; it's about strategic 
                      preparation. By engaging with our application form, you are 
                      applied for the selection procedure to gain access to a valuable 
                      collection of papers that empowers you to  approach the exams 
                      with confidence. Join our community of dedicated learners and 
                      unlock a path to success that's designed specifically for you." 
                    </p>
                  </>)}
                  {(customer === customerConstants.EXO_CUSTOMER2) && (<>
                    <p className="request-form-para">
                      Embark on your journey to mathematical excellence with
                      Chithambara Maths Challenge! Register today and unlock a
                      world of learning. With just a few simple steps, you can
                      create your account, ensuring a personalized and secure
                      experience. Dive into our resources, practice diligently,
                      and get ready to shine in the competition.
                    </p>
                  </>)}
                  <div className="card-body login-card-body">
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      <Form onSubmit={this.handleSubmit}>
                        <Form.Group
                          controlId="name"
                          className="form-group"
                        >
                          <Row>
                          <Col className="req-form-name mr-2">
                          <Form.Label>Student's First Name:</Form.Label>
                          <Form.Control
                            type="text"
                            name="first_name"
                            value={this.state.first_name}
                            onChange={this.handleChange}
                          />
                          <span className="form-error-message">
                          {this.validator.message(
                              "first_name",
                              this.state.first_name,
                              "required|Student_first_name|min:2|max:100",
                              {
                                messages: {
                                  required: "The student first name is required.",
                                  min:  "The student first name should have minimum 2 characters.",
                                  max:  "The student first name should have maximum 128 characters."
                                },
                              }
                            )}
                          </span>
                          </Col>
                          <Col className="req-form-name ml-2">
                          <Form.Label>Student's Last Name:</Form.Label>
                          <Form.Control
                            type="text"
                            name="last_name"
                            value={this.state.last_name}
                            onChange={this.handleChange}
                          />
                          <span className="form-error-message">
                          {this.validator.message(
                              "last_name",
                              this.state.last_name,
                              "required|Student_last_name|min:2|max:100",
                              {
                                messages: {
                                  required: "The student last name is required.",
                                  min:  "The student last name should have minimum 2 characters.",
                                  max:  "The student last name should have maximum 128 characters."
                                },
                              }
                            )}
                          </span>
                          </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group controlId="student_email" className="form-group">
                          <Form.Label>Student's Email:</Form.Label>
                          <Form.Control
                            type="email"
                            name="student_email"
                            value={this.state.student_email}
                            onChange={this.handleChange}
                          />
                          <span className="form-error-message">
                          {this.validator.message(
                              "student_email",
                              this.state.student_email,
                              "required|email"
                            )}
                          </span>
                        </Form.Group>
                        <p style={{ fontSize: "10px", fontWeight: "600", color: "#5E5873" }}>
                          To register, you must have a Microsoft or Google email
                          account. Let's conquer math, together!
                        </p>
                        <Form.Group controlId="grade" className="form-group">
                        <Form.Label>Student’s Year:</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="grade"
                            value={this.state.grade}
                            onChange={this.handleChange}
                          >
                            <option value="">Select</option>
                            {grades.length>0 ? (grades.map((grade)=>(
                              <option key={grade.grade_id} value={grade.grade}>
                                {grade.grade}
                              </option>
                            ))):(
                              <option value="">No grades available</option>
                            )}
                          </Form.Select>
                          <span className="form-error-message">
                          {this.validator.message(
                            "grade",
                            this.state.grade,
                            "required"
                          )}
                          </span>
                        </Form.Group>
                        <Form.Group controlId="country" className="form-group">
                          <Form.Label>Student’s Country:</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="country"
                              value={this.state.country}
                              onChange={this.handleChange}
                            >
                              <option value="">Select</option>
                              {countries.length>0 ? (countries.map((country)=>(
                                <option key={country.country_id} value={country.country_id}>
                                  {country.name}
                                </option>
                              ))):(
                                <option value="">No countries available</option>
                              )}
                            </Form.Select>
                            <span className="form-error-message">
                            {this.validator.message(
                              "country",
                              this.state.country,
                              "required"
                            )}
                            </span>
                        </Form.Group>
                        <Form.Group controlId="School" className="form-group">
                          <Form.Label>School:</Form.Label>
                          <Form.Control
                            type="text"
                            name="school"
                            value={this.state.school}
                            onChange={this.handleChange}
                          />
                          <span className="form-error-message">
                          {this.validator.message(
                              "school",
                              this.state.school,
                              "required|school|max:60"
                            )}
                          </span>
                        </Form.Group>
                        {/* <Form.Group controlId="parent_name" className="form-group">
                          <Row>
                            <Col className="req-form-name mr-2">
                            <Form.Label>Parent's First Name:</Form.Label>
                            <Form.Control
                              type="text"
                              name="parent_fname"
                              value={this.state.parent_fname}
                              onChange={this.handleChange}
                            />
                            <span className="form-error-message">
                            {this.validator.message(
                                "parent_fname",
                                this.state.parent_fname,
                                "required|parent_first_name|min:2|max:100",
                                {
                                  messages: {
                                    required: "The parent first name is required.",
                                    min:  "The parent first name should have minimum 2 characters.",
                                    max:  "The parent first name should have maximum 100 characters."
                                  },
                                }
                              )}
                            </span>
                            </Col>
                            <Col className="req-form-name ml-2">
                            <Form.Label>Parent's Last Name:</Form.Label>
                            <Form.Control
                              type="text"
                              name="parent_lname"
                              value={this.state.parent_lname}
                              onChange={this.handleChange}
                            />
                            <span className="form-error-message">
                            {this.validator.message(
                                "parent_lname",
                                this.state.parent_lname,
                                "required|parent_last_name|min:2|max:100",
                                {
                                  messages: {
                                    required: "The parent last name is required.",
                                    min:  "The parent last name should have minimum 2 characters.",
                                    max:  "The parent last name should have maximum 100 characters."
                                  },
                                }
                              )}
                            </span>
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group controlId="parent_email" className="form-group">
                          <Form.Label>Parent's Email:</Form.Label>
                          <Form.Control
                            type="email"
                            name="parent_email"
                            value={this.state.parent_email}
                            onChange={this.handleChange}
                          />
                          <span className="form-error-message">
                          {this.validator.message(
                              "parent_email",
                              this.state.parent_email,
                              "required|email"
                            )}
                          </span>
                        </Form.Group>
                        <Form.Group controlId="parent_number" className="form-group">
                          <Form.Label>Parent's Contact Number:</Form.Label>
                          <Form.Control
                            type="text"
                            name="parent_number"
                            value={this.state.parent_number}
                            onChange={this.handleChange}
                          />
                          <span className="form-error-message">
                          {this.validator.message(
                              "parent_number",
                              this.state.parent_number,
                              "required|numeric|between:9,10|phone_number",
                              {
                                messages: {
                                  required: "The parent contact number is required.",
                                  numeric:  "The parent contact number must be a number.",
                                  between:  "The parent contact number must be between 9 and 10 characters"
                                },
                              }
                            )}
                          </span>
                        </Form.Group> */}
                        <div className="d-flex justify-content-center">
                          <Button variant="primary" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-between footer-container">
          <div className="col-md-2 col-sm-3 copyright-items">
            {copyRightText}
          </div>
          <div className="col-md-6 col-sm-3 copyright-items d-flex justify-content-center">
            {footerText}
          </div>
          <div className="col-md-4 col-sm-3 d-flex justify-content-end policy-content">
          <span className="text-dark-75">
                <a
                  href={routes.terms_and_conditions_view}
                  target="_blank"
                  className="text-dark-75 text-hover-primary"
                >
                  Privacy Policy and Terms & Conditions
                </a>
              </span>
          </div>
        </div>
      </>
        );
    }
}


export default CmcRequestFrom;