import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ContentWrapper from "../../../../components/common/ContentWrapper";
import Backdrop from '@mui/material/Backdrop';
import Modal from "react-bootstrap/Modal";
import CircularProgress from '@mui/material/CircularProgress';
import { history } from "../../../../helper/history";
import Card from "../../../../components/common/Card";
import CardHeader from "../../../../components/common/CardHeader";
import CardBody from "../../../../components/common/CardBody";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import debounce from "lodash.debounce";
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    TablePagination as Pagination,
  } from "@mui/material";
import Spinner from "react-bootstrap/Spinner";
import "../../../../components/admin/css/common.css";
import { RequestService } from '../../../../service/manage/registration/request_form.service';
import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { StudentService } from '../../../../service/manage/registration/student.service';
import { sweetStatusAlert } from '../../../../components/common/SweetAlert';
import { routes } from '../../../../helper/routes';
import { Col, Row } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import TimeZoneHandler from '../../../../handlers/TimeZoneHandler';

export default class MyRequests extends Component {

    constructor(props) {
        super(props);
        this.tableRowRef = React.createRef();
        this.state = {
            isPageLoading:false,
            openDialog:false,
            breadcrumb2: null,
            nodeTitle: null,
            main_table_id: null,
            isTableDataLoading:false,
            search_term:'',
            limit: 10,
            offset: 0,
            sortColumn:"invitation_id",
            sortDirection:"DESC",
            currentPage: 0,
            totalRequests:0,
            requests:[],
            pending_request:null,
            timeDifference:null,
            highlight: false,
            selectedIndex:null,
            selectedRow:null,
        };

        this.debouncedHandleSearch = debounce(this.fetchAllRequests, 2000);
    
        
      }

    componentDidMount() {
        this.loadInitialData();

        document.body.addEventListener('click', this.handleOutsideClick);

        this.interval = setInterval(() => {
            this.setState(prevState => ({
            highlight: !prevState.highlight,
            timeDifference: this.getTimeDifference(
              this.state.pending_request?.main_entry_date? TimeZoneHandler.convertTimeByUserLocation(this.state.pending_request.main_entry_date):new Date(),
              new Date()
            )
            }));
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        document.body.removeEventListener('click', this.handleOutsideClick);
    }

    loadInitialData() {
    let privilege_edit = !!this.props.state?.privilege_edit;
    let privilege_add = !!this.props.state?.privilege_add;
    let privilege_delete = !!this.props.state?.privilege_delete;
    this.setState(
        {
        addVisibility: privilege_add,
        editVisibility: privilege_edit,
        deleteVisibility: privilege_delete,
        user_list_table_id: sessionStorage.getItem("table_log_id"),
        session_adm_id: sessionStorage.getItem("manage_user_id"),
        session_user_email: sessionStorage.getItem("UserEmail"),
        breadcrumb2: this.props.state?.breadcrumb2,
        nodeTitle: this.props.state?.nodeTitle,
        main_table_id: this.props.state?.main_table_id,
        },
        () => {
        this.fetchAllRequests();
        }
    );
    } 

    fetchAllRequests = async () => {
        this.setState({isTableDataLoading:true})
        const {
            limit,
            offset,
            sortColumn,
            sortDirection,
            search_term,
            session_user_email,
          } = this.state;

          await RequestService.getAllRequestsForStudentsByEmail(session_user_email,limit,offset,sortColumn,sortDirection,search_term)
            .then((response)=>{

                const pending_request = response?.data?.requests?.map((request, index) => {
                    if (
                        (request?.main_record_status !== "Expired" && request?.main_record_status !== "Expired") ||
                        (!request?.reference_record_status && request?.main_record_status !== "Expired")
                    ) {
                        return { ...request, index };
                    } else {
                        return null;
                    }
                }).filter(request => request !== null)[0];

                
                this.setState({
                    requests: response.data.requests,
                    totalRequests: response.data.total_count,
                    isTableDataLoading:false,
                    pending_request:pending_request,
                  });
            })
            .finally(()=>{
                this.setState({isTableDataLoading:false})
            })
    };
    
    toRegisterPage = () => {
        const { mid, owner_restriction, user_list_restriction } =
          this.props.state || {};
    
        this.setState({ isPageLoading: true });
    
        StudentService.checkStudentStatus(sessionStorage.getItem("UserEmail"))
          .then((response) => {
            if (response.success) {
              sweetStatusAlert(
                "Warning",
                `${typeof response.message === "string"?response.message:"Parent count exceeded or you have pending process with parent"}`,
                "info"
              );
            } else {
              StudentService.getStudentbyEmail(sessionStorage.getItem("UserEmail"))
                .then((response) => {
                  const data = {
                    student: response.data,
                  };
                  const restriction = {
                    mid: mid,
                    owner_restriction: owner_restriction,
                    user_list_restriction: user_list_restriction,
                  };
    
                  this.props.history.push({
                    pathname: routes.registration_form_view,
                    claim_parent: {
                      success: true,
                      data: data,
                      owner_restriction: restriction,
                    },
                  });
                  this.setState({ isPageLoading: false });
                })
                .finally(() => {
                  this.setState({ isPageLoading: false });
                });
            }
          })
          .finally(() => {
            this.setState({ isPageLoading: false });
          });
    };

    handlePageChange = (event, newPage) => {
        const { limit } = this.state;
        const newOffset = newPage * limit;
        this.setState(
          {
            offset: newOffset,
            isTableDataLoading: true,
            currentPage: newPage,
          },
          () => {
            this.fetchAllRequests();
          }
        );
    };
    
    handleRowsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        const newPage = Math.floor(this.state.offset / newLimit);
        this.setState(
          {
            limit: newLimit,
            offset: newLimit * newPage,
            isTableDataLoading: true,
            currentPage: newPage,
          },
          () => {
            this.fetchAllRequests();
          }
        );
    };

    toggleDialog = () => {
    this.setState((prevState) => ({
        openDialog: !prevState.openDialog,
    }),()=>{
        if(!this.state.openDialog){
            this.setState({selectedRow:null})
        }
    });
    };

    getTimeDifference(startDate, endDate) {
        // Convert the dates to milliseconds
        const startDateTime = new Date(startDate).getTime();
        const endDateTime = new Date(endDate).getTime();

        // Calculate the difference in milliseconds
        let difference = Math.abs(endDateTime - startDateTime);

        // Calculate the number of milliseconds in a day, hour, minute, and second
        const oneDay = 24 * 60 * 60 * 1000;
        const oneHour = 60 * 60 * 1000;
        const oneMinute = 60 * 1000;

        // Calculate the number of days
        const days = Math.floor(difference / oneDay);
        difference = difference % oneDay;

        // Calculate the number of hours
        const hours = Math.floor(difference / oneHour);
        difference = difference % oneHour;

        // Calculate the number of minutes
        const minutes = Math.floor(difference / oneMinute);
        difference = difference % oneMinute;

        // Calculate the number of seconds
        const seconds = Math.floor(difference / 1000);

        // Construct the time difference string
        let timeDifference = '';
        if (days > 0) timeDifference += `${days} days, `;
        if (hours > 0) timeDifference += `${hours} hours, `;
        if (minutes > 0) timeDifference += `${minutes} minutes, `;
        timeDifference += `${seconds} seconds`;

        return timeDifference;
    }

    handleOutsideClick = (event) => {
        const tooltip = document.querySelector('.MuiTooltip-tooltip');
        if (tooltip && !tooltip.contains(event.target)) {
            this.handleTooltipClose();
        }
    }

    handleTooltipClose = () => {
        this.setState({selectedIndex:null})
    }

    handleSearch = (event) => {
        const search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
    
        if (search_term === "" || regex.test(search_term)) {
          this.setState(
            {
              search_term,
              isTableDataLoading: true,
              offset: 0,
              currentPage: 0,
              searchError: false,
            },
            () => {
              // Call the debounced version of fetchStudentData
              this.debouncedHandleSearch();
            }
          );
        } else {
          this.setState({ search_term, searchError: true });
        }
    };

  render() {

    const {
        isPageLoading,
        openDialog,
        breadcrumb2,
        nodeTitle,
        isTableDataLoading,
        currentPage,
        limit,
        totalRequests,
        requests,
        pending_request,
        timeDifference,
        highlight,
        selectedIndex,
        selectedRow,
      } = this.state;

    const path = history.location.pathname;

    return (
        <>
            <div style={{ height: "90vh", overflow: "auto" }}>
                <ContentWrapper disabled={path === "/my-requests"}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isPageLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Card> 
                        <CardHeader>
                            <div>
                                {this.props.state?.path !== "node" && (
                                    <h3 style={{ color: "gray" }}>
                                        {breadcrumb2 || nodeTitle
                                            ? `${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle}`
                                            : undefined}
                                    </h3>
                                )}
                                <Form.Group controlId="exampleForm.ControlInput1" className="mb-2">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search"
                                        onChange={this.handleSearch}
                                        isInvalid={this.state.searchError}
                                        style={{ width: "300px" }}
                                    />
                                    {this.state.searchError && (
                                        <Form.Control.Feedback type="invalid">
                                            Invalid input
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </div>
                            <div>
                                <Button
                                    className="insert-btn"
                                    variant="primary"
                                    onClick={this.toRegisterPage}
                                >
                                    Claim or Invite Parent
                                </Button>
                            </div>
                        </CardHeader>

                        <CardBody>

                        {isTableDataLoading ? (
                            <div className="loading-container">
                                <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                            ) : (
                                <div style={{ overflowX: "auto" }}>
                                    <div style={{display:"flex", alignItems:"center",justifyContent:"end"}}>
                                    {pending_request && 
                                        <div>
                                            <Tooltip
                                                title="Click to see"
                                                arrow
                                                placement="top"
                                            >
                                            <Chip
                                                onClick={()=>{
                                                    this.setState({selectedIndex:pending_request.index});
                                                    if (this.tableRowRef.current) {
                                                        this.tableRowRef.current.focus();
                                                    }
                                                }}
                                                color="warning"
                                                label={
                                                    <>
                                                        Request pending since :  {" "}
                                                        <span style={{ opacity: highlight ? 1 : 0, transition: 'opacity 1s ease-in-out' }}>
                                                            {timeDifference ? timeDifference : pending_request.main_entry_date}
                                                        </span>
                                                    </>
                                                }
                                            />
                                            </Tooltip>
                                            <br></br><br></br>
                                        </div>
                                        
                                    }
                                    </div>

                                    <Table>
                                    <TableHead>
                                    <TableRow>
                                    <TableCell
                                        className="thead"
                                    >
                                    Parent First Name
                                    </TableCell>
                                    <TableCell
                                        className="thead"
                                    >
                                    Parent Last Name
                                    </TableCell>
                                    <TableCell
                                        className="thead"
                                    >
                                    Parent Email
                                    </TableCell>
                                    <TableCell
                                        className="thead"
                                    >
                                    Parent Phone Number
                                    </TableCell>
                                    <TableCell
                                        className="thead"
                                    >
                                    Parent Action
                                    </TableCell>
                                    <TableCell
                                        className="thead"
                                    >
                                    Your Action
                                    </TableCell>
                                    <TableCell
                                        className="thead"
                                    >
                                    Process Status
                                    </TableCell>
                                    <TableCell
                                        className="thead"
                                    >
                                    Actions
                                    </TableCell>

                                    </TableRow>
                                    </TableHead>

                                    <TableBody>
                                    {requests.length > 0 ?
                                        (requests?.map((requestsItem,index) => (
                                            <TableRow 
                                                ref={this.tableRowRef} 
                                                key={requestsItem.main_invitation_id} 
                                                selected={index === selectedIndex}
                                                >
                                                <TableCell className="tData">
                                                {requestsItem?.reference_parent_fname?requestsItem.reference_parent_fname:"N/A"}
                                                </TableCell>
                                                <TableCell className="tData">
                                                {requestsItem?.reference_parent_lname?requestsItem.reference_parent_lname:"N/A"}
                                                </TableCell>
                                                <TableCell className="tData">
                                                {requestsItem?.reference_parent_email?requestsItem.reference_parent_email:"N/A"}
                                                </TableCell>
                                                <TableCell className="tData">
                                                {requestsItem?.reference_phone_number?requestsItem.reference_phone_number:"N/A"}
                                                </TableCell>
                                                <TableCell className="tData">
                                                    {requestsItem?.reference_request_status ? (
                                                        requestsItem?.reference_request_status === "Pending" ? (
                                                            <Chip label={requestsItem?.reference_request_status} color="warning" />
                                                        ) : requestsItem?.reference_request_status === "Approved" ? (
                                                            <Chip label={requestsItem?.reference_request_status} color="success" />
                                                        ) : requestsItem?.reference_request_status === "Rejected" ? (
                                                            <Chip label={requestsItem?.reference_request_status} color="error" />
                                                        ) : "N/A"
                                                    ) : "N/A"}
                                                </TableCell>
                                                <TableCell className="tData">
                                                    {requestsItem?.main_request_status ? (
                                                        requestsItem?.main_request_status === "Pending" ? (
                                                            <Chip label={requestsItem?.main_request_status} color="warning" />
                                                        ) : requestsItem?.main_request_status === "Approved" ? (
                                                            <Chip label={requestsItem?.main_request_status} color="success" />
                                                        ) : requestsItem?.main_request_status === "Rejected" ? (
                                                            <Chip label={requestsItem?.main_request_status} color="error" />
                                                        ) : "N/A"
                                                    ) : "N/A"}
                                                </TableCell>
                                                <TableCell className="tData">
                                                    {requestsItem?.main_record_status === "Expired" && requestsItem?.reference_record_status === "Expired" ? (
                                                        <Chip label="Expired" color="error" />
                                                    ) : (
                                                        !requestsItem?.reference_record_status && requestsItem?.main_record_status === "Expired"?
                                                        (
                                                            <Chip label="Expired" color="error" />
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <Tooltip
                                                                    PopperProps={{
                                                                    disablePortal: true,
                                                                    }}
                                                                    onClose={this.handleTooltipClose}
                                                                    open={typeof selectedIndex === "number" && selectedIndex === index}
                                                                    disableFocusListener
                                                                    disableHoverListener
                                                                    disableTouchListener
                                                                    title="Pending Process"
                                                                    arrow
                                                                    placement="right"
                                                                >
                                                                <Chip label="Pending" color="warning" />
                                                                </Tooltip>
                                                            </>
                                                        )
                                                        
                                                    )}
                                                </TableCell>
                                                <TableCell className='tData'>
                                                    <button 
                                                        className="child-view-icon"
                                                        onClick={()=>{
                                                          this.setState({selectedRow:requestsItem});
                                                          this.toggleDialog();
                                                        }}>
                                                        <VisibilityIcon
                                                          sx={{ fontSize: 16, color: "#fff" }}
                                                          style={{ cursor: "pointer" }}
                                                        />
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        )))
                                    :
                                        <div style={{display:"flex", alignItems:"center",justifyContent:"center"}}>No data</div>
                                    }

                                    </TableBody>
                                    </Table>

                                    <Pagination
                                        component="div"
                                        count={parseInt(totalRequests)}
                                        page={currentPage}
                                        rowsPerPage={limit}
                                        rowsPerPageOptions={[5, 10, 20]}
                                        onPageChange={this.handlePageChange}
                                        onRowsPerPageChange={this.handleRowsPerPageChange}
                                    />
                                </div>
                            )}
                            
                        </CardBody>
                    </Card>
                </ContentWrapper>
            </div>

            <Modal show={openDialog} onHide={this.toggleDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Request details</Modal.Title>
                </Modal.Header>
            <Modal.Body>

            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Parent First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="parent_fname"
                    placeholder="First Name"
                    defaultValue={selectedRow?.reference_parent_fname}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Parent Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="parent_lname"
                    placeholder="Last Name"
                    disabled
                    defaultValue={selectedRow?.reference_parent_lname}
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Parent Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Parent Email"
                    disabled
                    defaultValue={selectedRow?.reference_parent_email}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Parent Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    name="phone_number"
                    placeholder="Parent Phone"
                    disabled
                    defaultValue={selectedRow?.reference_phone_number}
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                  style={{display:selectedRow?.reference_record_status?"block":"none"}}
                >
                  <Form.Label>Parent Action</Form.Label>
                  {selectedRow?.reference_request_status ? (
                    selectedRow?.reference_request_status === "Pending" ? (
                        <Chip label={selectedRow?.reference_request_status} color="warning" />
                    ) : selectedRow?.reference_request_status === "Approved" ? (
                        <Chip label={selectedRow?.reference_request_status} color="success" />
                    ) : selectedRow?.reference_request_status === "Rejected" ? (
                        <Chip label={selectedRow?.reference_request_status} color="error" />
                    ) : "N/A"
                ) : "N/A"}
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Your Action</Form.Label>
                  {selectedRow?.main_request_status ? (
                    selectedRow?.main_request_status === "Pending" ? (
                        <Chip label={selectedRow?.main_request_status} color="warning" />
                    ) : selectedRow?.main_request_status === "Approved" ? (
                        <Chip label={selectedRow?.main_request_status} color="success" />
                    ) : selectedRow?.main_request_status === "Rejected" ? (
                        <Chip label={selectedRow?.main_request_status} color="error" />
                    ) : "N/A"
                ) : "N/A"}
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Process Status</Form.Label>
                  {selectedRow?.main_record_status === "Expired" && selectedRow?.reference_record_status === "Expired" ? (
                    <Chip label="Expired" color="error" />
                ) : (
                    !selectedRow?.reference_record_status && selectedRow?.main_record_status === "Expired"?
                    (
                        <Chip label="Expired" color="error" />
                    )
                    :
                    (
                        <Chip label="Pending" color="warning" />
                    )
                    
                )}
                </Form.Group>

              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  onClick={this.toggleDialog}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>

            </Modal.Body>
            </Modal>
        </>
    )
  }
}
