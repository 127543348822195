import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const questionYearService = {
    getAllQuestionYears,
    createYear,
    deleteYear,
    updateYear
};

async function getAllQuestionYears(limit, offset, sort_column, sort_direction, search_term) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/years/getAllYears/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}`, requestOptions)
        .then(APIHandler.handleResponse);
}

async function createYear(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/manage/years/add/`, requestOptions).then(APIHandler.handleResponse);
}

async function deleteYear(obj) {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(obj)
        };
        return await fetch(`${config.oat_api_url}/manage/years/delete/${obj.year_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function updateYear(obj) {
    try {
        let requestBody = {
            year: obj.year.toString()
        }
        const requestOptions = {
            method: 'PUT',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(requestBody)
        };

        let data = await fetch(`${config.oat_api_url}/manage/years/update/${obj.year_id}`, requestOptions);

        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}
