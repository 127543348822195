import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import "../../../components/cmc/assets/css/cmc-landing.css"
import Navigation from "../../cmc/components/NavBar"
import Hero from "../../cmc/components/Hero"
import { routes } from "../../../helper/routes"

class StudentParentLandingPage extends Component {
  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role)
    this.props.history.push(routes.auth_response)
  }

  render () {
    return (
      <>
        <div className="col-md-12 px-0 cmc-landing">
          <Navigation />
          <Hero />
        </div>
      </>
    )
  }
}

export default withRouter(StudentParentLandingPage)
