import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const PaperService = {
  getAll,
  addAll,
  getById,
  getAllCount,
  deleteAll,
  getPaper,
  getfilterdAllAssesments,
  getAllRegisteredTeachers
};

async function getAllCount() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/papers/getAll/count`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAll(
  node_mid,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/papers/getAll/${node_mid}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getfilterdAllAssesments(
  node_mid,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term,
  selectedCategories,
  selectedAuthors,
  selectedChapters,
  selectedGrades,
  selectedDifficulties,
  selectedLanguages,
  selectedTerms,
  selectedYears,
  selectedsubjects
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/papers/filter/getAll/${node_mid}?limit=${limit}&offset=${offset}&sort_column=${sortColumn}&sort_direction=${sortDirection}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getById(node_mid) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/papers/getByNode/${node_mid}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function addAll(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: data,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/papers/add/all`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteAll(paperIds, nodeMid) {
  const payload = {
    paper_ids: paperIds,
    node_mid: nodeMid
  };

  const requestOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload), // Convert the payload to a JSON string
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/papers/delete/all`,
    requestOptions
  );

  return APIHandler.handleResponse(response);
}

async function getPaper(node_mid, asses_ids) {
  const asses_ids_str = Array.isArray(asses_ids) ? `[${asses_ids.join(',')}]` : `[${asses_ids}]`;

  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/enrolled-papers/getById/${node_mid}/${asses_ids_str}`,
    requestOptions
  );

  return APIHandler.handleResponse(response);
}

async function getAllRegisteredTeachers(
  node_mid,
  form_id,
  lg_user_id,
  lg_user_table_id,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/papers/getAllRegisteredTeachersByNode/${node_mid}/${form_id}/${lg_user_id}/${lg_user_table_id}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}


