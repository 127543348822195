import React, { Component } from "react";
import { Form, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
} from "@mui/material";
import ReactSelect from "react-select";
import { CreditService } from "../../../service/manage/subscription/credit.service";
import {
  sweetErrorAlert,
  sweetStatusAlert,
  sweetSuccessAlert,
} from "../../../components/common/SweetAlert";
import { MarksheetService } from "../../../service/manage/mark_sheet/marksheet.service";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import {
  reportSubjectService,
  reportTitleService,
} from "../../../service/manage/mark_sheet/marksheetreport.service.js";
import SimpleReactValidator from "simple-react-validator";
import debounce from "lodash.debounce";

export default class MarkEntrySheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentlist: [],
      selectedOption: null,
      isStudentListLoading: false,
      selectedStudent: "",
      reportTitle: [],
      selectedTitle: "",
      isTitleListLoading: false,
      titleSelected: false,
      reportCardPrivilege: null,
      flg_list_active: null,
      isDropHidden: false,
      isModalOpen: false,
      modalDataLoading: false,
      limit: 10,
      offset: 0,
      sortColumn: "user_code",
      sortDirection: "desc",
      studentSearchTerm: "",
      currentPage: 0,
      totalStudents: 0,
      selectedSubject: "",
      subjects: [],
      loading: false,
      isLoading: false,
      modalTableData: [],
      Report: [],
      isMarksValid: true,
      invalidMarks: [],
    };

    this.validator = new SimpleReactValidator();
    this.debouncedHandleSearch = debounce(this.fetchallstudent, 2000);
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  componentDidMount() {
    const { flg_list_active } = this.props.props;
    const userType = sessionStorage.getItem("userType");

    this.setState(
      {
        userType: userType,
        markEntrysheetPrivilage: this.props.markEntrysheetPrivilage,
        flg_list_active,
      },
      () => {
        const { markEntrysheetPrivilage } = this.state;

        this.fetchallstudent();
        this.fetchallTitles();
        this.fetchSubjects();
      }
    );
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  fetchallstudent = async () => {
    const { mid, lg_user_id, lg_user_table_id, form_list_id } =
      this.props.props;
    const { studentSearchTerm, limit, offset, sortColumn, sortDirection } =
      this.state;

    this.setState({ isStudentListLoading: true });

    await MarksheetService.getAllEnrolledStudents(
      mid,
      form_list_id,
      lg_user_id,
      lg_user_table_id,
      limit,
      offset,
      sortColumn,
      sortDirection,
      studentSearchTerm
    )
      .then((result) => {
        if (result.success) {
          const updatedStudentsArray = result.data.users.map((student) => ({
            ...student,
            status: false,
          }));
          this.setState({
            studentlist: updatedStudentsArray,
            totalStudents: parseInt(result.data.count),
          });
          this.setState({ loading: false });
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching students. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isStudentListLoading: false });
      });
  };

  fetchallTitles = () => {
    this.setState({ isTitleListLoading: true });
    const { mid } = this.props.props;
    reportTitleService
      .getAllReportTitle(mid)
      .then((response) => {
        if (response.success) {
          this.setState({ reportTitle: response.data.report_title });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Titles. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch(() => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Titles. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTitleListLoading: false });
      });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchallstudentAndReport();
      }
    );
  };

  handleTitleChange = async (event) => {
    const { selectedSubject, studentlist } = this.state;

    const updatedStudentList = studentlist.map((student) => ({
      ...student,
      marks: student.marks === "" ? "" : student.marks,
      comment: student.comment === "" ? "" : student.comment,
    }));

    this.setState({
      selectedTitle: event.target.value,
      titleSelected: true,
      studentlist: updatedStudentList,
    });

    if (selectedSubject) {
      await this.fetchOverViewData(event.target.value, selectedSubject);
      await this.fetchReport(event.target.value, selectedSubject);
    }
  };

  handleSubjectChange = async (event) => {
    const { selectedTitle, studentlist } = this.state;
    const selectedSubject = event.target.value;

    const updatedStudentList = studentlist.map((student) => ({
      ...student,
      marks: student.marks === "" ? "" : student.marks,
      comment: student.comment === "" ? "" : student.comment,
    }));

    this.setState({ selectedSubject, studentlist: updatedStudentList });

    await this.fetchOverViewData(selectedTitle, selectedSubject);
    await this.fetchReport(selectedTitle, selectedSubject);
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchallstudentAndReport();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchallstudentAndReport();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.fetchallstudentAndReport();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleSortChange = (sortColumn) => {
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0,
        currentPage: 0,
        isLoading: true,
      },
      () => {
        this.fetchallstudentAndReport();
      }
    );
  };

  fetchSubjects = () => {
    const { mid } = this.props.props;
    reportSubjectService.getAllReportSubjects(mid).then((response) => {
      if (response.success) {
        const subjectsArray = response.data.subjects;
        this.setState({ subjects: subjectsArray });
      }
    });
  };

  fetchOverViewData = async (selectedTitle, selectedSubject) => {
    const { mid } = this.props.props;

    this.setState({ modalDataLoading: true });

    await MarksheetService.getReportOverView(
      mid,
      selectedTitle,
      selectedSubject
    )
      .then((response) => {
        if (response.success) {
          this.setState({ modalTableData: response.data.overview });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching OverView. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch(() => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching OverView. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ modalDataLoading: false });
      });
  };

  fetchReport = async (selectedTitle, selectedSubject) => {
    const { mid } = this.props.props;

    this.setState({ modalDataLoading: true });

    await MarksheetService.getReportNode(mid, selectedTitle, selectedSubject)
      .then((response) => {
        if (response.success) {
          this.setState({ Report: response.data.Report });

          const { studentlist } = this.state;

          const mergedStudentData = studentlist.map((student) => {
            const modalData = this.state.Report.find(
              (data) => data.user_id === student.user_id
            );

            return {
              ...student,
              marks: modalData ? modalData.marks : "",
              comment: modalData ? modalData.comment : "",
              subject_id: modalData ? modalData.subject_id : "",
              title_id: modalData ? modalData.title_id : "",
              id: modalData ? modalData.id : "",
            };
          });

          this.setState({ studentlist: mergedStudentData });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Report. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch(() => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Report. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ modalDataLoading: false });
      });
  };

  handleSubmit = () => {
    this.setState({ loading: true });

    const { mid, lg_user_id, lg_user_table_id } = this.props.props;

    const AddedMarksSubmit = [];
    for (let i = 0; i < this.state.studentlist.length; i++) {
      const comment = this.state.studentlist[i].comment || "";
      const marks = this.state.studentlist[i].marks || "";
      const updatedMarks = {
        comment: comment,
        node_mid: mid,
        create_user_id: lg_user_id,
        create_user_table_id: lg_user_table_id,
        marks: parseInt(marks),
        subject_id: this.state.selectedSubject,
        title_id: this.state.selectedTitle,
        user_id: this.state.studentlist[i].user_id,
        user_table_id: this.state.studentlist[i].user_table_id,
      };
      AddedMarksSubmit.push(updatedMarks);
    }

    const invalidMarks = this.state.studentlist.filter(
      (student) => student.marks < 0
    );
    if (invalidMarks.length > 0) {
      this.setState({ isMarksValid: false });
      this.setState({ invalidMarks: invalidMarks });
      this.setState({ loading: false });
      return; // Prevent further execution of the function
    }

    if (AddedMarksSubmit.length > 0) {
      MarksheetService.addMarksTable(AddedMarksSubmit).then((result) => {
        if (result.success) {
          sweetSuccessAlert(
            "Success",
            "Mark Sheet Saved successfully",
            "success"
          );

          // Clear marks and comments after submission
          const updatedStudentList = this.state.studentlist.map((student) => ({
            ...student,
            marks: "",
            comment: "",
          }));

          this.setState({
            studentlist: updatedStudentList,
          });
          this.fetchReport(
            this.state.selectedTitle,
            this.state.selectedSubject
          );
          this.fetchOverViewData(
            this.state.selectedTitle,
            this.state.selectedSubject
          );
        } else {
          sweetErrorAlert("Error", "Failed to add Mark Sheet");
        }
        this.setState({ loading: false });
      });
    }
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ studentSearchTerm: search_term, searchError: true });
    }
  };

  handleCommentChange = (studentId, e) => {
    const { studentlist } = this.state;
    const updatedStudentList = studentlist.map((student) => {
      if (student.user_id === studentId) {
        return { ...student, comment: e.target.value };
      }
      return student;
    });

    this.setState({ studentlist: updatedStudentList });
  };

  handleMarksChange = (studentId, e) => {
    const { studentlist } = this.state;
    const updatedStudentList = studentlist.map((student) => {
      if (student.user_id === studentId) {
        return { ...student, marks: String(e.target.value) };
      }
      return student;
    });

    const invalidMarks = [];

    updatedStudentList.map((updatedStudent) => {
      if (updatedStudent.marks < 0) {
        this.setState({ isMarksValid: false });
        invalidMarks.push(updatedStudent);
      }
    });

    this.setState({ invalidMarks: invalidMarks });
    this.setState({ studentlist: updatedStudentList });
  };

  fetchallstudentAndReport = async () => {
    await this.fetchallstudent();
    const { selectedTitle, selectedSubject } = this.state;
    await this.fetchReport(selectedTitle, selectedSubject);
  };

  render() {
    const {
      studentlist,
      isStudentListLoading,
      reportTitle,
      titleSelected,
      selectedTitle,
      isModalOpen,
      modalDataLoading,
      flg_list_active,
      limit,
      offset,
      totalStudents,
      currentPage,
      selectedSubject,
      subjects,
      modalTableData,
      isTitleListLoading,
      studentSearchTerm,
      loading,
      invalidMarks,
    } = this.state;
    return (
      <>
        <div className="row py-4">
          {selectedSubject && (
            <div className="col-md-12 d-flex justify-content-end py-3">
              <Button
                className="insert-btn"
                variant="primary"
                onClick={this.toggleModal}
              >
                View Overall
              </Button>
              <Button
                className="insert-btn ml-2"
                variant="primary"
                onClick={this.handleSubmit}
              >
                Save Marks
              </Button>
            </div>
          )}
          <div className="col-md-6">
            <InputLabel htmlFor="markTypeDropdown">Select the Title</InputLabel>
            <Select
              id="markTypeDropdown"
              value={selectedTitle}
              onChange={this.handleTitleChange}
              style={{
                fontFamily: "Poppins",
              }}
              size="small"
              className="col-md-12"
            >
              <MenuItem value="" disabled style={{ fontFamily: "Poppins" }}>
                Select
              </MenuItem>
              {reportTitle.map((reportTitl) => (
                <MenuItem
                  key={reportTitl.id}
                  value={reportTitl.id}
                  style={{ fontFamily: "Poppins" }}
                >
                  {reportTitl.title}
                </MenuItem>
              ))}
            </Select>
          </div>

          {titleSelected && (
            <div className="col-md-6">
              <InputLabel htmlFor="subjectDropdown">Select Module</InputLabel>

              <Select
                label="Select Subject"
                id="subjectDropdown"
                value={selectedSubject}
                onChange={this.handleSubjectChange}
                style={{
                  fontFamily: "Poppins",
                }}
                size="small"
                className="col-md-12"
              >
                <MenuItem value="" disabled style={{ fontFamily: "Poppins" }}>
                  Select
                </MenuItem>
                {subjects.map((subject) => (
                  <MenuItem
                    key={subject.id}
                    value={subject.id}
                    style={{ fontFamily: "Poppins" }}
                  >
                    {subject.subject_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
        {/* {selectedSubject && (
                  <>
                   <div className="col-md-3">
                      <Form.Control
                        type="text"
                        placeholder="Search Code"
                        value={this.state.search_term}
                        onChange={this.handleSearch}
                        isInvalid={this.state.searchError}
                        // className="col-md-3 col-12"
                      />
                      {this.state.searchError && (
                        <Form.Control.Feedback type="invalid">
                          Invalid input
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </>
                )} */}
        {isTitleListLoading && (
          <PageSpinner id="show" text="Loading, Please wait!" />
        )}
        {/* {modalDataLoading && (
          <PageSpinner id="show" text="Loading, Please wait!" />
        )} */}
        {loading && <PageSpinner id="show" text="Loading, Please wait!" />}
        <div style={{ overflow: "Auto" }}>
          {selectedSubject && (
            <>
              {(isStudentListLoading || modalDataLoading) && (
                <div
                  className="loading-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
              {!isStudentListLoading && !modalDataLoading && (
                <>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {!this.props.props?.user_list_restriction && (
                          <>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              className="thead"
                              onClick={() => this.handleSortChange("user_code")}
                            >
                              User Code
                              {/* {this.state.sortColumn === "user_code" && (
                      <span>
                        {this.state.sortDirection === "asc" ? (
                          <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                        ) : (
                          <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                        )}
                      </span>
                    )} */}
                            </TableCell>
                            {/* <TableCell>Student Detail</TableCell> */}
                            <TableCell className="thead text-nowrap">
                              Name
                            </TableCell>
                          </>
                        )}
                        <TableCell className="thead">Marks</TableCell>
                        <TableCell className="thead">Comment</TableCell>
                      </TableRow>
                    </TableHead>
                    {!!flg_list_active ? (
                      <TableBody>
                        {studentlist.map((student) => {
                          let fullName = student.data_concat
                            ? student.data_concat.split("] ")[1]
                            : "";

                          return (
                            <TableRow key={student.user_id}>
                              {!this.props.state?.user_list_restriction && (
                                <>
                                  <TableCell>{student.user_code}</TableCell>
                                  <TableCell>{fullName}</TableCell>
                                </>
                              )}
                              <TableCell>
                                <TextField
                                  type="number"
                                  value={
                                    student.marks === null ? "" : student.marks
                                  }
                                  onChange={(e) => {
                                    const value = e.target.value.trim(); // Trim to handle spaces
                                    if (
                                      value === "" ||
                                      parseFloat(value) <= 100
                                    ) {
                                      this.handleMarksChange(
                                        student.user_id,
                                        e
                                      );
                                    }
                                  }}
                                  variant="outlined"
                                  inputProps={{ min: 0 }}
                                />

                                {invalidMarks.some(
                                  (invalidStudent) =>
                                    invalidStudent.user_id === student.user_id
                                ) && (
                                  <div className="text-danger">
                                    <p>
                                      Error: Marks must be greater than or equal
                                      to 0
                                    </p>
                                  </div>
                                )}
                              </TableCell>

                              <TableCell>
                                <TextField
                                  value={student.comment || ""}
                                  onChange={(e) =>
                                    this.handleCommentChange(student.user_id, e)
                                  }
                                  variant="outlined"
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <div className="text-center mb-5 mt-10">
                        <label>No User to Show </label>
                      </div>
                    )}
                  </Table>
                  <MaterialCustomTablePagination
                    totalCount={parseInt(totalStudents)}
                    currentPage={currentPage}
                    limit={limit}
                    handlePageChange={this.handlePageChange}
                    handleRowsPerPageChange={this.handleRowsPerPageChange}
                    handlePageOffsetChange={this.handlePageOffsetChange}
                    rowsPerPageOptions={[5, 10, 20]}
                    offset={offset}
                  />
                </>
              )}
            </>
          )}

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isModalOpen}
            onHide={this.toggleModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>Student Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {modalDataLoading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                <Form>
                  <div className="mb-3">
                    <Form.Label>Students Average</Form.Label>
                    <Form.Control
                      type="text"
                      value={modalTableData && modalTableData[0]?.sub_avg_marks}
                      disabled={true}
                    />

                    <Form.Label>Total no. of students in the group</Form.Label>
                    <Form.Control
                      type="text"
                      value={modalTableData && modalTableData[0]?.stu_count}
                      disabled={true}
                    />
                  </div>
                </Form>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}
