import { libraryTypes } from "../types";

const initialState = {
  myQuestions: [],
  sharedQuestions: [],
  publicQuestions: [],
  question: "",
  questionContent: "",
  guidanceContent: "",
  loading: false,
  success: "",
  successupdate: "",
  loadingupdate: "",
  errorupdate: "",
  error: "",
  text: "",
  totalCount: "",
  totalSharedCount: "",
  totalPublicCount: "",
  teachers: []
};

export function questionsLibraryReducer(state = initialState, action) {
  switch (action.type) {
    case libraryTypes.LIBRARY_PAGE_REFRESH:
      return {
        ...state,
        myQuestions: [],
        question: "",
        loading: false,
        success: "",
        error: "",
        text: "",
      };
    case libraryTypes.SELECT_QUESTION:
      return {
        ...state,
        question: action.question,
        loading: false,
        success: "",
        error: "",
        text: "",
      };
    case libraryTypes.GET_MY_QUESTIONS:
      return {
        ...state,
        question: "",
        questionContent: "",
        myQuestions: [],
        loading: true,
        text: "Getting all the appQuestions created by you!",
      };
    case libraryTypes.GET_MY_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
      };
    case libraryTypes.GET_MY_QUESTIONS_SUCCESS:

      return {
        ...state,
        myQuestions: action.data.questions,
        loading: false,
        success: "success",
      };
    case libraryTypes.GET_MY_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        success: "",
      };
    case libraryTypes.GET_ALL_MY_QUESTIONS:
      return {
        ...state,
        question: "",
        questionContent: "",
        myQuestions: [],
        loading: true,
        text: "Getting all the appQuestions created by you!",
      };
    case libraryTypes.GET_ALL_MY_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
      };
    case libraryTypes.GET_ALL_MY_QUESTIONS_SUCCESS:

      return {
        ...state,
        myQuestions: action.data.questions,
        totalCount: action.data.total_count,
        loading: false,
        success: "success",
      };
    case libraryTypes.GET_ALL_MY_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        success: "",
      };
    case libraryTypes.GET_ALL_PUBLIC_QUESTIONS:
      return {
        ...state,
        question: "",
        questionContent: "",
        loading: true,
        text: "Getting all the appQuestions",
      };
    case libraryTypes.GET_ALL_PUBLIC_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        success: "",
      };
    case libraryTypes.GET_ALL_PUBLIC_QUESTIONS_SUCCESS:
      return {
        ...state,
        publicQuestions: action.data.questions,
        totalPublicCount: action.data.total_count,
        teachers: action.data.teachers,
        loading: false,
        success: "success",
      };
    case libraryTypes.GET_ALL_PUBLIC_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        success: "",
      };
    case libraryTypes.GET_QUESTION_CONTENT:
      return {
        ...state,
        question: action.question,
        loading: true,
        text: "Getting question content created by you!",
      };
    case libraryTypes.GET_QUESTION_CONTENT_REQUEST:
      return {
        ...state,
        question: action.question,
        loading: true,
        text: "Getting question content created by you!",
      };
    case libraryTypes.GET_QUESTION_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        questionContent: action.question,
      };
    case libraryTypes.CLEAR_QUESTION_CONTENT:
      return {
        ...state,
        questionContent: "",
      };
    case libraryTypes.GET_QUESTION_CONTENT_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
     case libraryTypes.GET_QUESTION_GUIDANCE_CONTENT:
      return {
        ...state,
        loading: true,
        text: "Getting question marking guidance!",
      };
    case libraryTypes.GET_QUESTION_GUIDANCE_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
        text: "Getting question marking guidance!",
      };
    case libraryTypes.GET_QUESTION_GUIDANCE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        guidanceContent: action.guidance,
      };
    case libraryTypes.GET_ANSWER_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case libraryTypes.GET_ANSWER_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        answerContent: action.content,
      };
    case libraryTypes.GET_ANSWER_CONTENT_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case libraryTypes.GET_QUESTIONS_SHARED_WITH_ME:
      return {
        ...state,
        questionContent: "",
        question: "",
        sharedQuestions: [],
        success: "",
        erroe: "",
        loading: true,
        text: "Getting all the appQuestions shared with you!",
      };
    case libraryTypes.GET_QUESTIONS_SHARED_WITH_ME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case libraryTypes.GET_QUESTIONS_SHARED_WITH_ME_SUCCESS:
      return {
        ...state,
        sharedQuestions: action.data.questions,
        success: "Obtained appQuestions",
        loading: false,
      };
    case libraryTypes.GET_QUESTIONS_SHARED_WITH_ME_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case libraryTypes.GET_ALL_QUESTIONS_SHARED_WITH_ME:
      return {
        ...state,
        questionContent: "",
        question: "",
        sharedQuestions: [],
        success: "",
        erroe: "",
        loading: true,
        text: "Getting all the appQuestions shared with you!",
      };
    case libraryTypes.GET_ALL_QUESTIONS_SHARED_WITH_ME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case libraryTypes.GET_ALL_QUESTIONS_SHARED_WITH_ME_SUCCESS:
      return {
        ...state,
        sharedQuestions: action.data.questions,
        totalSharedCount: action.data.total_count,
        success: "Obtained appQuestions",
        loading: false,
      };
    case libraryTypes.GET_ALL_QUESTIONS_SHARED_WITH_ME_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case libraryTypes.UPDATE_QUESTION_REQUEST:
      return {
        ...state,
        successupdate: false,
        loadingupdate: true,
      };
    case libraryTypes.UPDATE_QUESTION_SUCCESS:
      return {
        ...state,
        question: action.question,
        successupdate: true,
        loadingupdate: false,
      };
    case libraryTypes.UPDATE_QUESTION_FAILURE:
      return {
        ...state,
        errorupdate: action.error,
        loadingupdate: false,
      };
    case libraryTypes.DELETE_QUESTION_REQUEST:
      return {
        ...state,
        successdelete: false,
        loadingdelete: true,
      };
    case libraryTypes.DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        successdelete: true,
        loadingdelete: false,
      };
    case libraryTypes.DELETE_QUESTION_FAILURE:
      return {
        ...state,
        errordelete: action.error,
        loadingdelete: false,
      };
    case libraryTypes.DELETE_CATEGORY_REQUEST:
      return {
        ...state,
        successcategorydelete: false,
        loadingcategorydelete: true,
      };
    case libraryTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        successcategorydelete: true,
        loadingcategorydelete: false,
      };
    case libraryTypes.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        errorcategorydelete: action.error,
        loadingcategorydelete: false,
      };
    case libraryTypes.UPDATE_CATEGORY_REQUEST:
      return {
        ...state,
        successcategoryupdate: false,
        loadingcategoryupdate: true,
      };
    case libraryTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        successcategoryupdate: true,
        loadingcategoryupdate: false,
      };
    case libraryTypes.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        errorcategoryupdate: action.error,
        loadingcategoryupdate: false,
      };

    default:
      return state;
  }
}
