import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import Card from "../../../components/common/Card.js";
import CardHeader from "../../../components/common/CardHeader.js";
import CardBody from "../../../components/common/CardBody.js";
import { Button, Modal, Form, Dropdown } from "react-bootstrap";
import { AnnouncementService } from "../../../service/manage/announcement/announcement.service.js";
import {
  sweetErrorAlert,
  sweetStatusAlert,
  sweetSuccessAlert,
} from "../../../components/common/SweetAlert.js";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import Spinner from "react-bootstrap/Spinner";
import { history } from "../../../helper/history.js";
import { NotificationService } from "../../../service/manage/announcement/notification.service.js";
import debounce from "lodash.debounce";
import config from "../../../helper/config.js";
import { customerConstants } from "../../../helper/constants.js";
import TimeZoneHandler from "../../../handlers/TimeZoneHandler.js";
import "react-datepicker/dist/react-datepicker.css";
import Tooltip from "@mui/material/Tooltip";
import { AnnouncementTopicService } from "../../../service/settings/manage/announcement_topic.service.js";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

class ManageAnnouncement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodeTitle: null,
      breadcrumb2: null,
      announcements: [],
      isLoading: false,
      isTableLoading: false,
      showModal: false,
      totalAnnoucement: 10,
      currentPage: 0,
      search_term: "",
      newAnnouncement: {
        title: "",
        body: "",
        topic: "",
        titleError: "",
        bodyError: "",
        topicError: "",
        sheduledDate: undefined,
      },
      topics: [],
      selectedAnnouncementTopic: undefined,
      limit: 10,
      offset: 0,
      sort_column: "announcement_id",
      sort_direction: "desc",
      details: [],
      showDetailsModal: false,
      node_mid_actual: null,
    };

    this.debouncedHandleSearch = debounce(this.fetchAnnouncements, 2000);

    this.fetchAnnouncements = this.fetchAnnouncements.bind(this);
    this.handleAddAnnouncement = this.handleAddAnnouncement.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const {
      privilege_view,
      privilege_add,
      privilege_edit,
      privilege_delete,
      breadcrumb2,
      nodeTitle,
    } = this.props?.state;

    let node_mid_actual =
      this.props.state.node_mid_actual !== null
        ? this.props.state.node_mid_actual
        : this.props.state.mid;

    this.getVisibilityCheck(
      privilege_view,
      privilege_add,
      privilege_delete,
      privilege_edit
    );
    let userType = sessionStorage.getItem("userType");
    this.fetchAllAnnouncementTopics();
    this.setState(
      { mid: node_mid_actual, userType, breadcrumb2: breadcrumb2, nodeTitle },
      () => {
        this.fetchAnnouncements();
      }
    );
  }

  getVisibilityCheck(
    privilege_view,
    privilege_add,
    privilege_delete,
    privilege_edit
  ) {
    if (privilege_add === 1) {
      this.setState({
        addVisibility: true,
      });
    } else if (privilege_edit === 1) {
      this.setState({
        editVisibility: true,
      });
    } else if (privilege_view === 1) {
      this.setState({
        viewVisibility: true,
      });
    } else if (privilege_delete === 1) {
      this.setState({
        deleteVisibility: true,
      });
    }
  }

  truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  validateForm = () => {
    const { newAnnouncement, selectedAnnouncementTopic } = this.state;
    let isValid = true;

    // Validate Title
    if (!newAnnouncement.title) {
      isValid = false;
      this.setState({ titleError: "Title is required" });
    } else if (newAnnouncement.title.length - 1 >= 128) {
      isValid = false;
      this.setState({ titleError: "Title should less than 128 characters" });
    } else {
      this.setState({ titleError: "" });
    }

    // Validate Type
    if (!newAnnouncement.body) {
      isValid = false;
      this.setState({ bodyError: "Announcement is required" });
    } else if (newAnnouncement.body.length - 1 >= 1000) {
      isValid = false;
      this.setState({
        bodyError: "Announcement text should less than 1000 characters",
      });
    } else {
      this.setState({ bodyError: "" });
    }

    // Validate Topic
    if (!selectedAnnouncementTopic) {
      isValid = false;
      this.setState({ topicError: "Topic is required" });
    } else {
      this.setState({ topicError: "" });
    }

    return isValid;
  };

  fetchAllAnnouncementTopics = () => {
    AnnouncementTopicService.getAllAnnouncementTopics().then((res) => {
      this.setState({
        topics: res.data,
      });
    });
  };

  fetchAnnouncements() {
    const { limit, offset, sort_column, sort_direction, search_term, mid } =
      this.state;
    this.setState({ isTableLoading: true });

    AnnouncementService.getAllAnnouncementsByNode(
      mid,
      limit,
      offset,
      search_term,
      sort_column,
      sort_direction
    )
      .then((response) => {
        if (response.success) {
          this.setState({
            announcements: response.data.announcements,
            totalAnnoucement: parseInt(response.data.count),
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching announcements. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching announcements. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableLoading: false });
      });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      newAnnouncement: {
        ...prevState.newAnnouncement,
        [name]: value,
      },
      [`${name}Error`]: "",
    }));
  }

  fetchAnnouncementById = (id) => {
    return this.state.topics?.find(
      (topic) => topic.announcement_topic_id === parseInt(id)
    );
  };

  handleAnnouncementTopicSelect = (event) => {
    this.setState({
      selectedAnnouncementTopic: this.fetchAnnouncementById(event.target.value),
    });
  };

  handleDatePickerChange = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = ("0" + dateObject.getDate()).slice(-2);

    const formattedDateString = `${year}-${month}-${day}`;
    this.setState((prev) => ({
      newAnnouncement: {
        ...prev.newAnnouncement,
        sheduledDate: formattedDateString,
      },
      isModified: true,
    }));
  };

  handleAddAnnouncement() {
    const { newAnnouncement, selectedAnnouncementTopic } = this.state;
    const lg_user_table_id = sessionStorage.getItem("table_log_id");
    const lg_user_id = sessionStorage.getItem("manage_user_id");
    const { mid } = this.state;

    if (!this.validateForm()) {
      return;
    } else {
      this.setState({ isLoading: true, showModal: false });
      const newAnnouncementData = {
        announcement_title: newAnnouncement.title,
        announcement_body: newAnnouncement.body,
        scheduled_on: newAnnouncement.sheduledDate,
        node_mid: mid,
        create_user_id: lg_user_id,
        create_user_table_id: lg_user_table_id,
        user_id: lg_user_id,
        user_table_id: lg_user_table_id,
        user_table_id: lg_user_table_id,
        announcement_topic_id: selectedAnnouncementTopic.announcement_topic_id,
      };

      AnnouncementService.addAnnouncementTable([newAnnouncementData])
        .then((result) => {
          if (result.success) {
            sweetSuccessAlert(
              "Success",
              "Announcement added successfully",
              "success"
            );
            this.fetchAnnouncements();
            this.setState((prevState) => ({
              announcements: [...prevState.announcements, newAnnouncementData],
              newAnnouncement: { title: "", type: "", sheduledDate: undefined },
            }));
          } else {
            sweetErrorAlert("Error", "Failed to add Announcement");
            this.setState({
              newAnnouncement: { title: "", type: "", sheduledDate: undefined },
            });
          }
        })
        .catch((error) => {
          sweetErrorAlert("Error", "Failed to add Announcement");
        })
        .finally(() => {
          this.setState({
            isLoading: false,
            newAnnouncement: { title: "", type: "", sheduledDate: undefined },
            selectedAnnouncementTopic: undefined,
          });
        });
    }
  }

  handleDeleteAnnouncement(id) {
    this.setState({ isLoading: true });
    AnnouncementService.deleteAnnouncementById(id)
      .then((response) => {
        if (response.success) {
          sweetSuccessAlert(
            "Success!",
            "Announcement deleted successfully!",
            "success"
          );
          this.fetchAnnouncements();
        } else {
          sweetStatusAlert("Failed!", "Failed to delete announcement", "error");
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error deleting announcement. Please try again later.",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  sendPushNotification(id, title, body, topic) {
    this.setState({ isLoading: true });
    const notification = {
      to: `/topics/${topic}`,
      notification: {
        title: title,
        body: body,
      },
    };
    AnnouncementService.sendPushNotification(notification)
      .then(async (response) => {
        if (response) {
          const user_id = sessionStorage.getItem("manage_user_id");
          const user_list_table_id = sessionStorage.getItem("table_log_id");

          const newNotification = {
            send_user_id: user_id,
            send_user_table_id: user_list_table_id,
            announcement_id: id,
          };

          const detailsResponse = await NotificationService.addNewNotification(
            newNotification
          );

          if (detailsResponse.success) {
            sweetSuccessAlert(
              "Success!",
              "Push notification has sent to everyone!",
              "success"
            );
          }
        } else {
          sweetStatusAlert(
            "Failed!",
            "Failed to send the push notification!",
            "error"
          );
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Failed to send the push notification!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  notificationDetails(id) {
    this.setState({ isLoading: true });

    NotificationService.getAllNotificationsByAnnoucementId(id)
      .then(async (response) => {
        if (response.success) {
          if(response.data.notifications.length === 0){
            sweetStatusAlert(
              "Failed!",
              "No notification history found for this announcement. Please send a notification to see the history here.",
              "error"
            );
          }else{
            this.setState({
              details: response.data.notifications,
              showDetailsModal: true,
            });
          }
        } else {
          sweetStatusAlert(
            "Failed!",
            response.message.error,
            "error"
          );
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Unable to get the sent notifications",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  formattedDateString = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString = `${day}-${month}-${year}`;
    return fromattedString;
  };

  formattedTimeString = (value) => {
    const time = new Date(value);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  toggleAddAnnouncement = () => {
    const { showModal } = this.state;

    if (!showModal) {
      this.setState({ showModal: true });
    } else {
      this.setState({
        showModal: false,
        newAnnouncement: {
          title: "",
          body: "",
          topic: "",
          titleError: "",
          bodyError: "",
          topicError: "",
          sheduledDate: undefined,
        },
        titleError: "",
        bodyError: "",
        topicError: "",
        selectedAnnouncementTopic: undefined,
      });
    }
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAnnouncements();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAnnouncements();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAnnouncements();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();

    this.setState(
      {
        search_term,
        isTableLoading: true,
        offset: 0,
        currentPage: 0,
      },
      () => {
        this.debouncedHandleSearch();
      }
    );
  };

  handleSortChange = (sort_column) => {
    const { sort_column: currentSortColumn, sort_direction } = this.state;
    const newSortDirection =
      currentSortColumn === sort_column && sort_direction === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sort_column,
        sort_direction: newSortDirection,
        offset: 0,
        currentPage: 0,
        isTableLoading: true,
      },
      () => {
        this.fetchAnnouncements();
      }
    );
  };
  render() {
    const {
      announcements,
      isLoading,
      isTableLoading,
      showModal,
      newAnnouncement,
      topics,
      details,
      showDetailsModal,
      breadcrumb2,
      totalAnnoucement,
      currentPage,
      limit,
      offset,
    } = this.state;
    const path = history.location.pathname;
    const formattedBreadcrumb = breadcrumb2
      ? breadcrumb2.replaceAll(">", " > ")
      : "";

    return (
      <div style={{ height: "90vh", overflow: "auto" }}>
        <ContentWrapper disabled={path === "/manage-search"}>
          <Card>
            <CardHeader>
              {this.props.state.path !== "node" && (
                <p
                  className="col-md-12 px-0 pb-4"
                  style={{ fontSize: "10.5625px", color: "#00000099" }}
                >
                  {formattedBreadcrumb ? `${formattedBreadcrumb}` : ""}
                </p>
              )}
              <div>
                {/* <CardTitle cardLabel="Announcement" /> */}
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    style={{ width: "300px" }}
                    className="search-bar"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </div>
              <div>
                {this.state.addVisibility && (
                  <Button
                    className="insert-btn"
                    variant="primary"
                    onClick={() => this.setState({ showModal: true })}
                  >
                    Add Announcement
                  </Button>
                )}
              </div>
            </CardHeader>
            <CardBody>
              {isLoading && (
                <PageSpinner id="show" text="Loading, Please wait!" />
              )}
              {isTableLoading ? (
                <div className="loading-container">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ cursor: "pointer", width: "10%" }}
                          className="thead"
                          onClick={() => this.handleSortChange("created_at")}
                        >
                          Created On
                          {this.state.sort_column === "created_at" && (
                            <span>
                              {this.state.sort_direction === "asc" ? (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: "pointer", width: "25%" }}
                          className="thead"
                          onClick={() =>
                            this.handleSortChange("announcement_title")
                          }
                        >
                          Title
                          {this.state.sort_column === "announcement_title" && (
                            <span>
                              {this.state.sort_direction === "asc" ? (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: "pointer", width: "10%" }}
                          className="thead"
                          onClick={() =>
                            this.handleSortChange("announcement_topic_id")
                          }
                        >
                          Topic
                          {this.state.sort_column ===
                            "announcement_topic_id" && (
                            <span>
                              {this.state.sort_direction === "asc" ? (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>

                        <TableCell
                          style={{ cursor: "pointer", width: "45%" }}
                          className="thead"
                          onClick={() =>
                            this.handleSortChange("announcement_body")
                          }
                        >
                          Announcement
                          {this.state.sort_column === "announcement_body" && (
                            <span>
                              {this.state.sort_direction === "asc" ? (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        {/* <TableCell className="thead text-nowrap">Scheduled on</TableCell> */}
                        {/* <TableCell className="thead">Scheduled On</TableCell> */}
                        {(this.state.addVisibility ||
                          this.state.editVisibility ||
                          this.state.deleteVisibility) && (
                          <TableCell className="thead" style={{ width: "10%" }}>
                            Actions
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {announcements.map((announcement, announcement_id) => {
                        return (
                          <TableRow key={announcement_id}>
                            <TableCell
                              className="tData text-nowrap"
                              style={{ width: "10%" }}
                            >
                              <div>
                                {this.formattedDateString(
                                  TimeZoneHandler.convertTime(
                                    announcement.created_at
                                  )
                                )}
                              </div>
                              <div>
                                {this.formattedTimeString(
                                  TimeZoneHandler.convertTime(
                                    announcement.created_at
                                  )
                                )}
                              </div>
                            </TableCell>
                            <TableCell
                              className="tData"
                              style={{ width: "25%" }}
                            >
                              {announcement.announcement_title}
                            </TableCell>
                            <TableCell style={{ width: "10%" }}>
                              {announcement.announcement_topic}
                            </TableCell>
                            {announcement.announcement_body ===
                            this.truncateString(
                              announcement.announcement_body,
                              60
                            ) ? (
                              <TableCell style={{ width: "45%" }}>
                                {announcement.announcement_body}
                              </TableCell>
                            ) : (
                              <TableCell>
                                <Tooltip title={announcement.announcement_body}>
                                  <span>
                                    {" "}
                                    {this.truncateString(
                                      announcement.announcement_body,
                                      60
                                    )}
                                  </span>
                                </Tooltip>
                              </TableCell>
                            )}
                            {/* <TableCell className="tData">
                              {this.formattedDateString(announcement.scheduled_on)
                                ? this.formattedDateString(announcement.scheduled_on)
                                : "Not Scheduled"}
                            </TableCell> */}
                            {(this.state.addVisibility ||
                              this.state.editVisibility ||
                              this.state.deleteVisibility) && (
                              <TableCell
                                className="text-nowrap"
                                style={{ width: "10%" }}
                              >
                                <Button
                                  onClick={() =>
                                    this.notificationDetails(
                                      announcement.announcement_id
                                    )
                                  }
                                  className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-eye-icon"
                                  title="View"
                                />
                                {this.state.deleteVisibility && (
                                  <Button
                                    onClick={() =>
                                      this.handleDeleteAnnouncement(
                                        announcement.announcement_id
                                      )
                                    }
                                    className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                    title="Delete"
                                  />
                                )}
                                {this.state.addVisibility && (
                                  <Button
                                    onClick={() =>
                                      this.sendPushNotification(
                                        announcement.announcement_id,
                                        announcement.announcement_title,
                                        announcement.announcement_body,
                                        announcement.announcement_topic
                                      )
                                    }
                                    style={{
                                      backgroundColor: "#F6F8FA",
                                      color: "#A2A5B5",
                                      border: "none",
                                    }}
                                    className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon2-send-1"
                                    title="Send notification"
                                  />
                                )}
                                {/* <Button
                            variant="danger"
                            className="me-2 mr-5"
                            onClick={() =>
                              this.handleDeleteAnnouncement(
                                announcement.announcement_id
                              )
                            }
                          >
                            Delete
                          </Button>
                          <Button variant="primary" 
                            onClick={() =>
                              this.sendPushNotification(
                                announcement.announcement_id, announcement.announcement_title, announcement.announcement_body, announcement.announcement_topic
                              )
                            }
                          >
                            Send notification
                          </Button>
                          <Button
                            variant="primary"
                            className="me-2 ml-5"
                            onClick={() =>
                              this.notificationDetails(
                                announcement.announcement_id
                              )
                            }
                          >
                              Details
                            </Button> */}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  <MaterialCustomTablePagination
                    totalCount={totalAnnoucement}
                    currentPage={currentPage}
                    limit={limit}
                    handlePageChange={this.handlePageChange}
                    handleRowsPerPageChange={this.handleRowsPerPageChange}
                    handlePageOffsetChange={this.handlePageOffsetChange}
                    rowsPerPageOptions={[5, 10, 20]}
                    offset={offset}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </ContentWrapper>

        <Modal show={showModal} onHide={this.toggleAddAnnouncement} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Announcement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formTitle">
                <Form.Label>Title*</Form.Label>
                <Form.Control
                  type="text"
                  className="mb-3"
                  placeholder="Enter Title"
                  name="title"
                  value={newAnnouncement.title}
                  onChange={this.handleInputChange}
                />
                <Form.Text className="text-danger">
                  {this.state.titleError}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="formType">
                <Form.Label>Topic*</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="topic"
                  value={
                    this.state.selectedAnnouncementTopic?.announcement_topic_id
                  }
                  onChange={this.handleAnnouncementTopicSelect}
                >
                  <option value="">Select</option>
                  {topics.map((topic) => (
                    <option
                      key={topic.announcement_topic_id}
                      value={topic.announcement_topic_id}
                    >
                      {topic.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {this.state.topicError}
                </Form.Text>
              </Form.Group>

              {/* <Form.Group controlId="formTitle">
              <Form.Label>Body</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Body"
                name="body"
                value={newAnnouncement.body}
                onChange={this.handleInputChange}
              />
              <Form.Text className="text-danger">
                {this.state.bodyError}
              </Form.Text>
            </Form.Group> */}
              <Form.Group md="6" className="pt-2" controlId="ScheduledOn">
                <div class="mb-3">
                  <label for="exampleFormControlTextarea1" class="form-label">
                    Announcement*
                  </label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    placeholder="Enter Announcement"
                    rows="3"
                    name="body"
                    value={newAnnouncement.body}
                    onChange={this.handleInputChange}
                  ></textarea>
                </div>
                <Form.Text className="text-danger">
                  {this.state.bodyError}
                </Form.Text>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button style={addBtnStyle} onClick={this.handleAddAnnouncement}>
              Add
            </Button>
            <Button
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={this.toggleAddAnnouncement}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Details Modal */}
        <Modal
          show={showDetailsModal}
          onHide={() => this.setState({ showDetailsModal: false })}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {details.map((detail, index) => (
              <div key={index}>
                <p>
                  <strong>Notification ID:</strong> {detail.notification_id}
                </p>
                <p className="mb-10">
                  <strong>Sent At:</strong>{" "}
                  {this.formattedDateString(
                    TimeZoneHandler.convertTime(detail.created_at)
                  )}{" "}
                  {this.formattedTimeString(
                    TimeZoneHandler.convertTime(detail.created_at)
                  )}
                </p>
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={() => this.setState({ showDetailsModal: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ManageAnnouncement;
