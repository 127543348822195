import React, { useEffect } from 'react';

export default function FinalizeRightWrap ({ children }) {
    return (
        
        <div className="card card-custom card-fit card-border p-5">
            <div className="tab-content pb-20" id="myTabContent5">
                
                {children}

            </div>
        </div>

    )
}


