import React from "react"
import "../dashboard.css"
import config from "../../../../../helper/config.js"
import { customerConstants } from "../../../../../helper/constants.js"
import MoneyIcon from "@mui/icons-material/Money"
import { Dropdown } from "react-bootstrap"
import ReactSelect from "react-select"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}

const TeacherPayments = ({dashboardDataLoading,loaderSection,renderDoghnutCharts}) => {
  return (
    <div className="col-md-12 mb-3 pl-0 px-0 px-sm-0">
      <div className="dashboard__container row d-flex">
        <div className="col-lg-12 col-md-12 px-0 d-flex justify-content-between align-items-center my-3">
          <h3 className="dashboard__container-title m-0">
            <MoneyIcon sx={{ fontSize: 22, color: "#5c5c5c" }} /> Teacher
            Payments
          </h3>
          <div className="">
            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  border: "1px solid #E4E6EF",
                  color: "var(--secondary-text-color)",
                  fontWeight: "400",
                  fontSize: "10px",
                }}
              >
                May
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                <Dropdown.Item>
                  <div>January</div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div>February</div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div>March</div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div>April</div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-12 px-0">
          <div className="py-5" style={{ width: "100%" }}>
            <ReactSelect
              isClearable={true}
              placeholder="Select Teacher"
              //   menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={[
                { value: "teacher1", label: "Teacher 1" },
                { value: "teacher2", label: "Teacher 2" },
                { value: "teacher3", label: "Teacher 3" },
              ]}
              //   value={}
              //   isLoading={}
              //   onChange={}
              //   onInputChange={}
            />
          </div>
          <div className="row d-flex justify-content-between receivables-row">
            <h5 className="receivables-title m-0">Teacher Fee</h5>
            <p className="receivables-amount">40,000</p>
          </div>
          <div className="row d-flex justify-content-between receivables-row">
            <h5 className="receivables-title m-0">Total Outstanding</h5>
            <p className="receivables-amount">45,000</p>
          </div>
          <div className="pl-8 row d-flex justify-content-between receivables-row">
            <h5 className="receivables-title m-0">Current Outstanding</h5>
            <p className="receivables-amount">30,000</p>
          </div>
          <div className="pl-8 row d-flex justify-content-between receivables-row">
            <h5 className="receivables-title m-0">Old Outstanding</h5>
            <p className="receivables-amount">15,000</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center align-items-center">
          {dashboardDataLoading ? loaderSection() : renderDoghnutCharts()}
        </div>
      </div>
    </div>
  )
}

export default TeacherPayments
