import React from "react";

export default function FormGroup ({ children, labelName, className }) {
    return (
        <div className="form-group">
            <div className={className}>
                <div className="row">
                    <label className="col-lg-3 col-form-label">{labelName}</label>
                    {children}
                </div>
            </div>
        </div>
    )
}