import React, { forwardRef } from 'react';

export default function QuestionAccordion ({ labelName = 'Create New Category', type, name, onChange, onClick, val }) {
    return (
        <div className="accordion accordion-light accordion-toggle-arrow col-lg-8 pl-0" id="accordionExample2">
            <div className="card">
                <div id="headingOne2">
                    <div className="btn btn-primary btn-sm font-weight-bold mb-3" data-toggle="collapse" data-target="#collapseOne2">
                        <i className="flaticon2-plus icon-sm mr-2"/>{labelName}
                    </div>
                </div>
                <div id="collapseOne2" className="collapse" data-parent="#accordionExample2">
                    <div className="card-body row">
                        <div className="col-8 pl-0 mt-2 mb-2">
                            <input className="form-control" type="text" value={val} onChange={onChange}/>
                        </div>
                        <div className="col-4 mt-2 mb-2 text-nowrap">
                            <a className="btn btn-primary btn-sm font-weight-bold mr-2" data-toggle="collapse" data-target="#collapseOne2" onClick={onClick}>
                                <i className="flaticon2-plus icon-sm mr-2"/>
                                Add
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
