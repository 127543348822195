import React, { useEffect } from 'react';

export default function FinalizeTab ({ children, disabled}) {
    return (

        <div className="row">
            {children}
        </div>
        
    )
}


