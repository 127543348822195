import React, { Component } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import SimpleReactValidator from "simple-react-validator";
import { termsConditionsService } from '../../../service/assessment/termsConditionsService.js';
import { assessmentService } from '../../../service/assessment/assessmentService.js';
import { Spinner } from 'react-bootstrap';
import { sweetErrorAlert, sweetStatusAlert, sweetSuccessAlert } from '../../../components/common/SweetAlert';
import ContentWrapper from '../../../components/common/ContentWrapper';
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

class ManagePaperTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: false,
      isLoading: false,
      addPaperTermsModal: false,
      updatePaperTermsModal: false,
      term_condition_id: "",
      content: "",
      language_id: 1,
      update_term_condition_id: "",
      update_terms_and_conditions_content: "",
      update_language_id: "",
      paperTerms: [],
      allLanguages: [],
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getAllAssessmentLanguages();
    this.getAllPaperTerms();
  }

  getAllAssessmentLanguages = async () => {
    this.setState({ isTableDataLoading: true });
    try {
      const response = await assessmentService.getAssessmentLanguage();
      this.setState({ allLanguages: response.data.languages});
    } catch (error) {
      this.setState({ isTableDataLoading: false });
    }
  };

  getAssessmentLanguage(language_id) {
    if (language_id !== null) {
        const language = this.state.allLanguages?.find(
            (language) => language.language_id == language_id
        );
        return language?.language;
    }
    else{
        return "N/A";
    }
  }

  getAllPaperTerms = async () => {
    this.setState({ isTableDataLoading: true });
    await termsConditionsService.getAllAssessmentTermsConditions()
      .then((response) => {
        if (response.success) {
          this.setState({ paperTerms: response.data.assessmentTermsConditions, isTableDataLoading: false });
        } else {
          this.setState({ isTableDataLoading: false });
        }
      })
      .catch((error) => {
        console.error("Error while fetching paper terms and conditions!", error);
        this.setState({ isTableDataLoading: false });
      });
  }

  toggleAddPaperTermsModal = () => {
    const { addPaperTermsModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessageFor("content");
    this.validator.hideMessageFor("language");
  
    if (addPaperTermsModal) {
      this.setState({
        addPaperTermsModal: !addPaperTermsModal
      });
    } else {
      this.setState({
        addPaperTermsModal: !addPaperTermsModal,
      });
    }
  };
  
  toggleUpdatePaperTermsModal = (paperTerm) => {
    const { updatePaperTermsModal} = this.state;
  
    if (!updatePaperTermsModal) {
      this.setState({
        update_term_condition_id: paperTerm.term_condition_id,
        update_terms_and_conditions_content: paperTerm.content || "",
        update_language_id: paperTerm.language_id || "",
        updatePaperTermsModal: true,
      });
    } else {
      this.setState({
        update_term_condition_id: "",
        update_terms_and_conditions_content: "",
        update_language_id: "",
        updatePaperTermsModal: false,
      });
    }
  };

handleSubmit = () => {
  this.setState({ isLoading: true });

  const { content, language_id } = this.state; 

    const newUserAcceptTerms = {
      content: content,
      language_id: parseInt(language_id)
    };

    termsConditionsService.createAssessmentTermsConditions(newUserAcceptTerms)
      .then((response) => {
        if (response.success) {
          sweetSuccessAlert("Success", "Paper Terms and Conditions added successfully!", "success");
          this.setState({ term_condition_id: "", content: "", language_id: "", isLoading: false });
          this.toggleAddPaperTermsModal();
          this.getAllPaperTerms();
        } else {
          this.setState({ term_condition_id: "", content: "", language_id: "", isLoading: false });
          sweetStatusAlert("Failed to add paper terms and conditions!", response.message.error, "error");
        }
      })
      .catch((error) => {
        this.setState({ term_condition_id: "", content: "", language_id: "", isLoading: false });
        sweetErrorAlert("Error", "Error while adding paper terms and conditions!", "error");
      });
};

handleEditSubmit = async() => {
  const { update_term_condition_id, update_terms_and_conditions_content, update_language_id } = this.state;

  this.setState({ isLoading: true });

  const editedUserAcceptTerms = {
    term_condition_id: update_term_condition_id,
    content: update_terms_and_conditions_content,
    language_id: parseInt(update_language_id),
  };

  await termsConditionsService.updateAssessmentTermsConditions(editedUserAcceptTerms)
  
    .then((response) => {
      console.log(response)
      if (response.success === true) {

        sweetSuccessAlert("Success", "Paper terms and conditions updated successfully!", "success");

        this.setState({
          update_term_condition_id: "",
          update_terms_and_conditions_content: "",
          update_language_id: "",
          isLoading: false,
        });

        this.toggleUpdatePaperTermsModal();
        this.getAllPaperTerms();
      } else {
        this.setState({
            update_term_condition_id: "",
            update_terms_and_conditions_content: "",
            update_language_id: "",
            isLoading: false,
        });
        sweetStatusAlert("Failed to update paper terms and conditions and conditions!", response.message.error, "error");
      }
    })
    .catch((error) => {
        this.setState({
            update_term_condition_id: "",
            update_terms_and_conditions_content: "",
            update_language_id: "",
            isLoading: false,
        });
        sweetErrorAlert("Error", "Error while updating paper terms and conditions and conditions!", "error");
    });
};

handleChangeContent = (event) => {
    event.preventDefault();
    this.setState({ content: event.target.value });
};

handleEditChangeContent = (event) => {
    event.preventDefault();
    this.setState({ update_terms_and_conditions_content: event.target.value });
};

handleChangeLanguage = (event) => {
    event.preventDefault();
    this.setState({ language_id: event.target.value });
};
  
render() {
    const {
      addPaperTermsModal,
      updatePaperTermsModal,
      isTableDataLoading,
      paperTerms
    } = this.state;

    return (
      <ContentWrapper>
        <div className="row justify-content-between align-items-center py-5 mb-3">
          <div className="col-md-6 col-6">
            <h3>Manage Paper Terms and Conditions</h3>
          </div>
          {/* <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleAddPaperTermsModal}
          >
            Add
          </Button> */}
        </div>
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "75vw",
              height: "90vh"
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead">Language</TableCell>
                  <TableCell className="thead">Paper Terms and Conditions Content</TableCell>
                  <TableCell className="thead">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {paperTerms.length > 0 ? (
                  paperTerms.map((paperTerm) => (
                    <TableRow key={paperTerm.term_condition_id}>
                      <TableCell className="tData">{this.getAssessmentLanguage(paperTerm.language_id)}</TableCell>
                      <TableCell className="tData">
                        {paperTerm.content}
                      </TableCell>
                      <TableCell className="tData" align="right">
                        <Button
                          className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                          title="Edit Details"
                          onClick={() => this.toggleUpdatePaperTermsModal(paperTerm)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <div style={{ height: "90vh" }}>No paper terms and conditions available!</div>
                )}
              </TableBody>
            </Table>
          </div>
        )}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={addPaperTermsModal}
          onHide={this.toggleAddPaperTermsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Paper Terms And Conditions</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isTableDataLoading ? (
              <div className="loading-container" style={{ height: "100px"}}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Select Language</Form.Label>
                    <Form.Select
                          id="language"
                          value={this.state.language_id}
                          onChange={this.handleChangeLanguage}
                          className="form-control"
                        >
                          {this.state.allLanguages.map((option, index) => (
                            <option key={index} value={option.language_id}>
                              {option.language}
                            </option>
                          ))}
                    </Form.Select>
                    {this.validator.message(
                      "language",
                       this.state.language_id,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "90ch" }}
                      type="text"
                      name="content"
                      value={this.state.content}
                      onChange={this.handleChangeContent}
                      multiline
                      rows={25}
                    />
                    {this.validator.message(
                      "content",
                      this.state.content,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button variant="primary" style={ addBtnStyle } onClick={this.handleSubmit}>
                Save
              </Button>
              <Button
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleAddPaperTermsModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updatePaperTermsModal}
          onHide={this.toggleUpdatePaperTermsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Terms and Conditions For All {this.getAssessmentLanguage(this.state.update_language_id)} Papers</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.state.isLoading ? (
              <div className="loading-container" style={{ height: "100px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "90ch" }}
                      type="text"
                      name="content"
                      value={this.state.update_terms_and_conditions_content}
                      onChange={this.handleEditChangeContent}
                      multiline
                      rows={25}
                    />
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button
                className="modal-btn"
                variant="primary"
                style={ addBtnStyle }
                onClick={() => this.handleEditSubmit()}
              >
                Save
              </Button>
              <Button
                className="modal-btn"
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleUpdatePaperTermsModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </ContentWrapper>
    )
  }
}

export default ManagePaperTerms;
