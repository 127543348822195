import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const CreditService = {
  getAllCredits,
  getAllCreditsByUser,
  getAllCreditsForPaperEnrollment,
  getAllStudentsForParent,
  getAllParentCreditsForPaperEnrollment
};

async function getAllCredits(node_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/subscription/credits/getAllCredits/${node_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllCreditsByUser(node_id, user_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  let user_id_string = user_id;
  if (Array.isArray(user_id)) {
    user_id_string = `[${user_id.join(",")}]`; // Convert array to comma-separated string with square brackets
  }

  const response = await fetch(
    `${config.oat_api_url}/manage/subscription/credits/getAllCreditsByUser/${node_id}/${user_id_string}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllCreditsForPaperEnrollment(node_id, user_id, grade_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/subscription/credits/getAllEnrollmentCredits/${node_id}/${user_id}/${grade_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllParentCreditsForPaperEnrollment(
  node_id,
  user_id,
  grade_id
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/subscription/credits/getAllEnrollmentCreditsForParent/${node_id}/${user_id}/${grade_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
async function getAllStudentsForParent(
  node_mid,
  form_list_id,
  login_user_id,
  login_user_table_id,
  search_term,
  userType
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/subscription/credits/getAllStudentsForParent/${node_mid}/${form_list_id}?l_u_id=${login_user_id}&l_u_t_id=${login_user_table_id}&search_term=${search_term}&userType=${userType}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
