import React, { useState, useEffect } from "react";
import { Link , useLocation} from "react-router-dom";
import "./sidebar.css";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CoPresentRoundedIcon from "@mui/icons-material/CoPresentRounded";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import SchoolIcon from "@material-ui/icons/School";
import EventIcon from "@material-ui/icons/Event";
import InfoIcon from "@material-ui/icons/Info";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Tooltip from "@mui/material/Tooltip";
import PeopleIcon from "@mui/icons-material/People";
import { routes } from "../../../helper/routes";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Payment, SubscriptionsSharp } from "@mui/icons-material";
import DataHandler from "../../../handlers/DataHandler";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

const Sidebar = (props) => {
  const [isExpanded, setExpanded] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setExpanded(!isExpanded);
  };

  const closeSidebar = () => {
    setExpanded(false);
  };

  const handleOutsideClick = (event) => {
    const sidebar = document.querySelector(".sidebar");
    if (sidebar && !sidebar.contains(event.target)) {
      closeSidebar();
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const renderMenuItem = (to, icon, label, additionalState = {}) => {
    const isActive = location.pathname === to;
    const iconElement = isExpanded ? (
      icon
    ) : (
      <Tooltip title={label} placement="right" arrow>
        {icon}
      </Tooltip>
    );

    return (
      <li key={to} className={isActive ? "active" : ""}>
        <Link to={{ pathname: to, state: { ...additionalState } }}>
          {isExpanded ? (
            <>
              {iconElement}
              <span>{label}</span>
            </>
          ) : (
            iconElement
          )}
        </Link>
      </li>
    );
  };

  const customer = getCustomerFeatureFlag();
  let customerPaperText, isCMC, isEdFoci;
  switch (customer) {
    case customerConstants.EXO_CUSTOMER1:
      customerPaperText = "Papers";
      isCMC = false;
      isEdFoci = false;
      break;
    case customerConstants.EXO_CUSTOMER2:
      customerPaperText = "Past Papers";
      isCMC = true;
      isEdFoci = false;
      break;
    case customerConstants.EDF_CUSTOMER3:
      customerPaperText = "Courses";
      isCMC = false;
      isEdFoci = true;
      break;
    default:
      customerPaperText = "Search";
      isCMC = false;
      isEdFoci = true;
      break;
  }

  let isASAD = DataHandler.getFromSession("isASAD");

  return (
    <nav
      className={`sidebar ${isExpanded ? "expanded" : ""}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="toggle-container">
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          {isExpanded ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
        </button>
      </div>
      <ul className="list-unstyled components">
        {renderMenuItem("/manage-dashboard", <DashboardIcon />, "Dashboard")}
        {renderMenuItem("/manage-search", <AssignmentIcon />, customerPaperText)}
        {!!props.isAdmin &&
          !isEdFoci &&
          renderMenuItem(
            "/manage-invitations",
            <LocalLibraryIcon />,
            "Requests"
          )}
        {!!props.isAdmin &&
          !isEdFoci &&
          renderMenuItem(
            "/manage-teacher",
            <CoPresentRoundedIcon />,
            "Teachers",
            {
              privilege_edit: 1,
              privilege_add: 1,
              privilege_delete: 1,
              breadcrumb2: "teachers",
              nodeTitle: "",
              mid: 100,
              main_table_id: 10,
              owner_restriction: null,
              lg_user_id: sessionStorage.getItem("table_log_id"),
              lg_user_table_id: sessionStorage.getItem("manage_user_id"),
              user_list_restriction: null,
            }
          )}
        {!!props.isAdmin &&
          !isCMC &&
          !isEdFoci &&
          renderMenuItem("/manage-parent", <PeopleIcon />, "Parents", {
            privilege_edit: 1,
            privilege_add: 1,
            privilege_delete: 1,
            breadcrumb2: "parents",
            nodeTitle: "",
            mid: 100,
            main_table_id: 3,
            owner_restriction: null,
            lg_user_id: sessionStorage.getItem("table_log_id"),
            lg_user_table_id: sessionStorage.getItem("manage_user_id"),
            user_list_restriction: null,
          })}
        {(!!props.isTeacher || !!props.isStudent) &&
          !isEdFoci &&
          renderMenuItem("/announcements", <CampaignIcon />, "Announcement", {
            privilege_edit: props.isTeacher ? 1 : null,
            privilege_add: props.isTeacher ? 1 : null,
            privilege_delete: null,
            privilege_view: 1,
            breadcrumb2: "announcement",
            nodeTitle: "",
            mid: 100,
            main_table_id: props.isTeacher ? 10 : 2,
            owner_restriction: null,
            lg_user_id: sessionStorage.getItem("table_log_id"),
            lg_user_table_id: sessionStorage.getItem("manage_user_id"),
            user_list_restriction: null,
            flg_list_active: null,
            node_mid_actual: null,
          })}
          {(!!props.isParent) &&
          !isEdFoci &&
          renderMenuItem("/announcements", <CampaignIcon />, "Announcement", {
            privilege_edit: props.isTeacher ? 1 : null,
            privilege_add: props.isTeacher ? 1 : null,
            privilege_delete: null,
            privilege_view: 1,
            breadcrumb2: "announcement",
            nodeTitle: "",
            mid: 100,
            main_table_id: props.isTeacher ? 10 : 2,
            owner_restriction: null,
            lg_user_id: sessionStorage.getItem("table_log_id"),
            lg_user_table_id: sessionStorage.getItem("manage_user_id"),
            user_list_restriction: null,
            flg_list_active: null,
            node_mid_actual: null,
          })}
        {!!props.isStudent &&
          !isEdFoci &&
          renderMenuItem("/subscriptions", <Payment />, "Subscriptions", {
            privilege_edit: 1,
            privilege_add: 1,
            privilege_delete: 1,
            nodeTitle: "Subscriptions",
            privilege_view: 1,
            mid: 100,
            main_table_id: 10,
            owner_restriction: null,
            lg_user_id: sessionStorage.getItem("manage_user_id"),
            lg_user_table_id: sessionStorage.getItem("table_log_id"),
            user_list_restriction: null,
          })}
        {!!props.isParent &&
          !isEdFoci &&
          sessionStorage.getItem("parent_group_id") &&
          sessionStorage.getItem("parent_group_user_table_id") &&
          renderMenuItem("/subscriptions", <Payment />, "Subscriptions", {
            privilege_edit: 1,
            privilege_add: 1,
            privilege_delete: 1,
            privilege_view: 1,
            nodeTitle: "Subscriptions",
            mid: 100,
            main_table_id: 10,
            owner_restriction: null,
            lg_user_id: sessionStorage.getItem("manage_user_id"),
            lg_user_table_id: sessionStorage.getItem("table_log_id"),
            user_list_restriction: null,
            flg_list_active: 1,
            form_list_id: 29,
          })}
        {!!props.isParent &&
          renderMenuItem(
            "/register-student",
            <CoPresentRoundedIcon />,
            "Students",
            {
              privilege_edit: 1,
              privilege_add: 1,
              privilege_delete: 1,
              breadcrumb2: "Student Registration",
              nodeTitle: "",
              mid: 100,
              main_table_id: 10,
              owner_restriction: null,
              lg_user_id: sessionStorage.getItem("table_log_id"),
              lg_user_table_id: sessionStorage.getItem("manage_user_id"),
              user_list_restriction: null,
            }
          )}
        {!!props.isStudent &&
          !isEdFoci &&
          renderMenuItem(
            "/my-requests",
            <CoPresentRoundedIcon />,
            "Parent Requests",
            {
              privilege_edit: 1,
              privilege_add: 1,
              privilege_delete: 1,
              breadcrumb2: "Parent Requests",
              nodeTitle: "",
              privilege_view: 1,
              mid: 100,
              main_table_id: 10,
              owner_restriction: null,
              lg_user_id: sessionStorage.getItem("manage_user_id"),
              lg_user_table_id: sessionStorage.getItem("table_log_id"),
              user_list_restriction: null,
            }
          )}
        {!!props.isAdmin &&
          renderMenuItem("/manage-settings", <SettingsIcon />, "Settings")}
        {!!props.isTeacher &&
          isASAD &&
          renderMenuItem(
            routes.questions_library,
            <HelpOutlineIcon />,
            "Questions"
          )}
        {!!props.isTeacher &&
          isASAD &&
          renderMenuItem(
            routes.view_assessment,
            <AssignmentIcon />,
            "Assessments"
          )}
        {!!props.isTeacher &&
          isASAD &&
          renderMenuItem(routes.settings_view, <SettingsIcon />, "Settings")}

        {isEdFoci &&
          renderMenuItem("/manage-sitemap", <AccountTreeIcon />, "Site Map")}

        {isEdFoci &&
          renderMenuItem("/main-calendar", <CalendarMonthIcon />, "Calendar")}

        {/* <li>
          <Link to="/main-calendar">
            {isExpanded ? (
              <>
                <InfoIcon />
                <span>About</span>
              </>
            ) : (
              <InfoIcon />
            )}
          </Link>
        </li> */}
        {/* Add more menu items as needed */}
      </ul>
    </nav>
  );
};

export default Sidebar;

