import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const TitleService = {
  create,
  update,
  getTitlesByPagination,
  getAllTitles,
  deleteTitle
};

async function getAllTitles() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/titles/getAllTitles`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function create(title) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(title),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/titles/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(title, titleId) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(title),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/titles/update/${titleId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getTitlesByPagination(limit, offset, search_term) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/titles/getAll/${limit}/${offset}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteTitle(id) {
  const configOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/titles/delete/${id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}