import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const LevelPriceService = {
    createLevelPrice,
    getAllLevelPrices,
    updateLevelPrice,
    deleteLevelPrice,
    getAllActiveLevelPrices,
    getAllActiveLevelPricesByGrade,
    getAllCreditPackagesByGrade,
    getAllActiveCreditPackagesByGradeAndID
};

async function createLevelPrice(levelPrice) {
    const requestOptions = {
        method: "POST",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: levelPrice,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-prices/add`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllLevelPrices(node_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-prices/getAllLevelPrice/${node_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllActiveLevelPrices(node_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-prices/getAllActiveLevelPrices/${node_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllActiveLevelPricesByGrade(node_id, grade_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-prices/getAllActiveLevelPricesByGrade/${node_id}/${grade_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllCreditPackagesByGrade(node_id, grade_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-prices/getAllActiveCreditPackagesByGrade/${node_id}/${grade_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllActiveCreditPackagesByGradeAndID(node_id, grade_id, user_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-prices/getAllActiveCreditPackagesByGradeAndID/${node_id}/${grade_id}/${user_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function updateLevelPrice(levelPrice, level_price_id) {
    const requestOptions = {
        method: "PUT",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: levelPrice,
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-prices/update/${level_price_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function deleteLevelPrice(level_price_id, form_list_id) {
    const configOptions = {
        method: "DELETE",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/level-prices/delete/${level_price_id}/${form_list_id}`,
        configOptions
    );
    return APIHandler.handleResponse(response);
}
