import React, {useState} from "react";
import Tooltip from '@mui/material/Tooltip';

const ProgressBar = ({ label, width, barClass, toolTip }) => (
  <div>
    <p>{label}</p>
    <Tooltip title={toolTip} placement="top-end" arrow>
      <div className="progress">
        <div
          className={`progress-bar ${barClass} progress-bar-striped progress-bar-animated`}
          style={{ width: `${width}%` }}
        >
          {width}%
        </div>
      </div>
    </Tooltip>
  </div>
);

const ProgressBars = ({ packageDetail }) => {
  const [userType, setUserType] = useState(sessionStorage.getItem("userType"))
  return (
  <div className={`mt-3 subPackageReserve ${userType === 'parent'?' studentPopup' : ''}`} >
    <h6>{packageDetail.chart_title}</h6>
    {
      packageDetail?.array?.map((bar, i) => {
        let colorArray = ["bg-success", "bg-warning"]
        return (
            <ProgressBar className="progressBar" label={bar?.chart_x_value} width={parseInt(bar?.chart_y_value)} barClass={colorArray[i]} toolTip={bar?.chart_legend_x_title}/>
        )
      })
    }
  </div>
)};

export default ProgressBars;
