import React from 'react';

export default function Spinner ({ id ,text}) {
    return (

        <div class="spinner-wrap" id={id}>
            <div class="spinner-load">
                <div class="blockui">
                    <span>{text}</span>
                    <span class="spinner spinner-track spinner-primary"></span>
                </div>
            </div>
        </div>
    )
}