import React, { forwardRef } from 'react';

export default function Input({ className, type, name, inputWrap, id, placeholder, dataToggle, dataTarget, value, onChange, disabled, min, maxLength }) {
    return (
        <div className={inputWrap}>
            <input type={type} name={name} className={className} id={id} placeholder={placeholder}
                data-toggle={dataToggle} data-target={dataTarget} value={value} onChange={onChange} disabled={disabled}
                min={min} onSelect={onChange} maxLength={maxLength} />
        </div>
    )
}
