
import DataHandler from "../../../handlers/DataHandler";
import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const AdminService = {
  create,
  update,
  getByEmail,
  getAll
};

async function create(registration) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: registration,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/admins/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


async function getAll(
  admin_id,
  user_table_id,
  create_user_id,
  create_user_table_id,
  node_mid,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const login_user_id = sessionStorage.getItem("manage_user_id");
  const login_user_table_id = sessionStorage.getItem("table_log_id")
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/admins/getAll/${node_mid}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}&a_id=${admin_id}&u_t_id=${user_table_id}&cr_u_id=${create_user_id}&cr_u_t_id=${create_user_table_id}&lg_u_id=${login_user_id}&lg_u_t_id=${login_user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(admin, adminId) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(admin),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/admins/update/${adminId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getByEmail(email) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/admins/getByEmail/${email}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}




