import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import layerIcon from "../../../assets/images/addlayer.png"
import {styled} from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import {customerConstants} from "../../../helper/constants.js";
import config from "../../../helper/config";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  // const backgroundColor = theme.palette.background.default;
  return {
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontFamily: "Poppins, sans-serif",
    cursor: "pointer",
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: "#fff",
  };
});

export default function BasicBreadcrumbs(props) {
  const fetchedData = props.breadcrumbData;
  let data = fetchedData
  const customer = getCustomerFeatureFlag();
  let isAdmin = sessionStorage.getItem("userType") === "admin";
  if(customer === customerConstants.EXO_CUSTOMER2 && !isAdmin  ){
    data = data.slice(1)
  }
  return (
    <div role="presentation">
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {data.length &&
          data?.map((item, index) => {
            return (
            <StyledBreadcrumb
              onClick={
                () =>
                props.redirect(
                  item.nodes[0].mid,
                )
              }
              component="a"
              label={item.nodes[0].title}
              key={index}
            />
          )})}
        {/* <StyledBreadcrumb
          label="Accessories"
        /> */}
        {fetchedData.length && fetchedData[fetchedData.length -1].nodes[0].flg_add_node_child &&  <div
          style={{ cursor: "pointer", width: "34px", height: "34px" }}
          onClick={() => props.toggleModal(true)}
        >
          <img style={{ width: "34px", height: "34px" }} src={layerIcon} />
        </div>}
      </Breadcrumbs>
    </div>
  );
}
