import React, { Component } from "react";
import { connect } from "react-redux";
import ContentWrapper from '../../../components/common/AssessmentContentWrapper.js';
import CardBody from '../../../components/common/CardBody.js';
import Input from '../../../components/common/form/Input.js';
import Button from '../../../components/common/form/Button.js';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion.js';
import TabList from '../../../components/common/tabs/TabList.js';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent.js';
import TabPane from '../../../components/common/tabs/TabPane.js';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option.js';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { assessCatActions } from "../../../actions/settings/assessment/categoryAction"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class AssessmentCategory extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newCategory:null,
        categoryRowsPerPage:5,
        currentCategoryPage: 0,
        categoryData: [],
        category_sort_column: "category_id",
        category_sort_direction: "ASC",
        selectCategory: '',
        editCategory: false,
        category: '',
        limit: 10,
        category_search_term: "",
        category_offset: 0,
        searchError: false,
        category_limit:5,
        categoryId: '',
        categoryOptionsImport: [],
        selectedCategory: []
      };
      
      this.onNewCategoryInput = this.onNewCategoryInput.bind(this);
      this.onAddNewCategory = this.onAddNewCategory.bind(this);
      this.generateCategoryOptions = this.generateCategoryOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllAssessmentCategories();
    }
    
    onNewCategoryInput(e) {
        this.setState({
            newCategory: e.target.value
        });
    }
    
    async onAddNewCategory(e) {
        if (this.state.newCategory && this.state.newCategory.trim()) {
            await this.props.createCategory({ 'name': this.state.newCategory })
            if (this.props.successcategorycreate) {
                this.setState(
                    {
                        category_offset: 0,
                        currentCategoryPage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllAssessmentCategories();
                        this.generateCategoryOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new category!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-category').modal('hide')
                                window.$("#question-category").val('default');
                                window.$('#question-category').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errorcategorycreate) {
                window.Swal.fire({
                    title: 'Failed to add a new category!',
                    text: this.props.errorcategorycreate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty category!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newCategory: ''
        });
    }

    handleCategorySearch = (event) => {
        const category_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (category_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (category_search_term === "" || regex.test(category_search_term)) {
            this.setState(
                {
                    category_search_term,
                    category_offset: 0,
                    currentCategoryPage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllAssessmentCategories();
                }
            );
        } else {
            this.setState({ category_search_term, searchError: true });
        }
    };
    
    fetchAllAssessmentCategories = async () => {
        const {
            category_limit,
            category_offset,
            category_sort_column,
            category_sort_direction,
            category_search_term
        } = this.state;
        await this.props.getAllAssessmentCategories(category_limit, category_offset, category_sort_column, category_sort_direction, category_search_term);
        this.setState({ category_limit: this.state.categoryRowsPerPage });
        this.setState({ categoryData: this.props.allAssessmentCategories })
    };

    handleCategoryPageChange = (event, newCategoryPage) => {
        const {categoryRowsPerPage} = this.state;
        const newCategoryOffset = newCategoryPage * categoryRowsPerPage;
        this.setState(
        {
            category_limit: categoryRowsPerPage,
            category_offset: newCategoryOffset,
            currentCategoryPage: newCategoryPage
        },
        () => {
            this.fetchAllAssessmentCategories();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {categoryRowsPerPage} = this.state;
        this.setState(
        {
            category_limit: categoryRowsPerPage,
            category_offset: newOffset,
            currentCategoryPage: newPage
        },
        () => {
            this.fetchAllAssessmentCategories();
        }
        );
    };

    //handle the data fetching as per the data per page limit gets changes
    handleCategoryRowsPerPageChange = (event) => {
        const newCategoryLimit = parseInt(event.target.value, 10);
        this.setState({categoryRowsPerPage: newCategoryLimit});
        const newCategoryPage = Math.floor(this.state.category_offset / newCategoryLimit)
        this.setState(
        {
            category_limit: newCategoryLimit,
            category_offset: newCategoryLimit * newCategoryPage,
            currentCategoryPage: newCategoryPage
        },
        () => {
            this.fetchAllAssessmentCategories();
        }
        );
    };

    handleSortCategoryChange = (category_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { category_sort_column: currentCategorySortColumn, category_sort_direction } = this.state;
        const newCategorySortDirection =
        currentCategorySortColumn === category_sort_column && category_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            category_sort_column,
            category_sort_direction: newCategorySortDirection,
            category_offset: 0,
            currentCategoryPage: 0,
        },
        () => {
            this.fetchAllAssessmentCategories();
        }
        );
    }

    getAssessmentCategory(category_id) {
        let categoryname
        this.props.allAssessmentCategories.map(category => {
            if (category.category_id === category_id) {
                return categoryname = category.name
            }
        })
        return categoryname
    }
    
    onEditCategory(e, el) {
        this.setState({
            categoryId: el.category_id,
            category: e.target.value
        })
    }
    
    async onUpdateCategory(e) {
        e.preventDefault()
        var obj = {
            category_id: this.state.categoryId,
            name: this.state.category
        }
        this.setState({ selectCategory: '' })
        if (!this.state.category.trim()) {
            window.Swal.fire({
                title: 'Failed to update category!',
                text: 'Category cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateCategory(obj);
            if (this.props.successcategoryupdate) {
                // await this.props.getAllAssessmentCategories(category_limit, category_offset, category_sort_column, category_sort_direction, category_search_term);
                this.fetchAllAssessmentCategories()
                this.generateCategoryOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated category!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });
            } 
            if(this.props.errorcategoryupdate){
                window.Swal.fire({
                    title: 'Failed to update category!',
                    text: this.props.errorcategoryupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        }
    }

    generateCategoryOptions() {
        let categoryOptionsImport = []
        this.props.allAssessmentCategories.map((el, i) => {
            categoryOptionsImport.push(<Option key={el.category_id} optionName={el.name} value={el.category_id} />)
        });

        this.setState({
            categoryOptionsImport: categoryOptionsImport,
            selectedCategory: categoryOptionsImport[0].props.value
        });

        if (categoryOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditCategory(el, i, e) {
        e.preventDefault()
        this.setState({
            editCategory: true,
            selectCategory: i,
            categoryId: el.category_id,
            category: el.name
        })
    }
    
    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the category?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteCategory(el);
                if (this.props.successcategorydelete) {
                    // await this.props.getAllAssessmentCategories(category_limit, category_offset, category_sort_column, category_sort_direction, category_search_term);
                    this.fetchAllAssessmentCategories()
                    this.generateCategoryOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted category!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });
                }
                if (this.props.errorcategorydelete) {
                    window.Swal.fire({
                        title: 'Failed to delete category!',
                        text: this.props.errorcategorydelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });

                }
            }
        })
    }  
    
    syncTableCategory() {
        return (
            <>
                {this.state.categoryData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortCategoryChange("category_id")}
                                >
                                    No{" "}
                                    {this.state.category_sort_column === "category_id" && (
                                    <span>{this.state.category_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortCategoryChange("name")}
                                >
                                    Category Name{" "}
                                    {this.state.category_sort_column === "name" && (
                                    <span>{this.state.category_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.categoryData.map((el, i) => {
                                if (i === this.state.selectCategory) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editCategory ? 
                                                this.getAssessmentCategory(el.category_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.category}
                                                    onChange={(e) => this.onEditCategory(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateCategory(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Category" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getAssessmentCategory(el.category_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditCategory(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Category" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete" 
                                                    title="Delete Category" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Categories Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3>Manage Assessment Categories</h3>
              </div>

              <CardBody>
                <div className="row justify-content-end">
                  <div
                    className="table-wrapper-scroll-y my-custom-scrollbar"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <TabList>
                      <QuestionAccordion
                        labelName="Create New Category"
                        onChange={this.onNewCategoryInput}
                        onClick={this.onAddNewCategory}
                        val={this.state.newCategory}
                      />
                    </TabList>
                    <div className="row justify-content-end col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleCategorySearch}
                          className="col-md-18 col-18"
                        />
                      </Form.Group>
                    </div>
                    <TabContent>
                      <TabPane
                        className="tab-pane fade show active"
                        id="manage-1"
                        ariaLabelledby="manage-tab-1"
                      >
                        {/* begin: Datatable */}
                        {this.props.cat_loading ? (
                          <div style={{ width: "75vw", overflowX: "scroll" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Category Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                            <div className="loading-container mb-5 mt-10">
                              <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </ReactSpinner>
                            </div>
                          </div>
                        ) : this.state.categoryData.length > 0 ? (
                          this.syncTableCategory()
                        ) : (
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Category Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {/* end: Datatable */}
                            </Table>
                            <div className="text-center mb-5 mt-10">
                              <label>No Categories Available!</label>
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                            totalCount = {this.props.totalCategoriesCount}
                            currentPage = {this.state.currentCategoryPage}
                            limit = {this.state.categoryRowsPerPage}
                            handlePageChange = {this.handleCategoryPageChange}
                            handleRowsPerPageChange = {this.handleCategoryRowsPerPageChange}
                            handlePageOffsetChange = {this.handlePageOffsetChange}
                            offset = {this.state.category_offset}
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalCategoriesCount: state.assesCategoryReducer.totalCategoriesCount,//
        cat_loading:state.assesCategoryReducer.loading,
        error: state.assesCategoryReducer.error,//
        // totalCount: state.assessmentsLibraryReducer.totalCount,//
        
        errorcategorydelete: state.assesCategoryReducer.errorcategorydelete,
        successcategorydelete: state.assesCategoryReducer.successcategorydelete,

        errorcategoryupdate: state.assesCategoryReducer.errorcategoryupdate,
        successcategoryupdate: state.assesCategoryReducer.successcategoryupdate,//
        
        errorcategorycreate: state.assesCategoryReducer.errorcategorycreate,
        successcategorycreate: state.assesCategoryReducer.successcategorycreate,//

        //assessmentCategories: state.assessmentsCreateReducer.allCategories,//
        allAssessmentCategories: state.assesCategoryReducer.allAssessmentCategories,//
        loading: state.assesCategoryReducer.loading,//
    }
}

    const mapActionsToProps = {
        createCategory: assessCatActions.createCategory,
        getAllAssessmentCategories: assessCatActions.getAllAssessmentCategories,
        updateCategory: assessCatActions.updateCategory,
        deleteCategory: assessCatActions.deleteCategory,
    }

    export default connect(mapStateToProps, mapActionsToProps)(AssessmentCategory);

