import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const libraryService = {
    listAssessments: listAssessments,
    listManagedAssessments: listManagedAssessments,
    getAllMyAssessments: getAllMyAssessments,
    listAdministratedAssessments: listAdministratedAssessments,
    listAssessoredAssessments: listAssessoredAssessments,
    getAllSharedAssessments: getAllSharedAssessments,
    viewAssessment: viewAssessment,
    getAssessors: getAssessors,
    getCandidates: getCandidates,
    getSchedule: getSchedule,
    getAllPublicAssessments: getAllPublicAssessments,
    getAllPapers: getAllPapers,
    viewAssessmentData: viewAssessmentData,
    getCandidate: getCandidate,
};

async function listAssessments() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/assessments/getAll/`, requestOptions).then(APIHandler.handleResponse);
}


async function listManagedAssessments(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/assessments/getAll/managed`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllMyAssessments(obj, limit, offset, sort_column, sort_direction, search_term, selectedCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/assessments/getAllFilteredMyAssessments/managed?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedStatuses=${selectedStatuses}`, requestOptions).then(APIHandler.handleResponse);
}

async function listAdministratedAssessments(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/assessments/getAll/admin`, requestOptions).then(APIHandler.handleResponse);
}


async function listAssessoredAssessments(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/assessments/getAll/assessor`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllSharedAssessments(obj, limit, offset, sort_column, sort_direction, search_term, selectedCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/assessments/getAllFilteredSharedAssessments/assessor?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedStatuses=${selectedStatuses}`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllPublicAssessments(limit, offset, sort_column, sort_direction, search_term, selectedCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/assessments/getAllFilteredPublicAssessments/managed?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedStatuses=${selectedStatuses}`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllPapers(limit, offset, sort_column, sort_direction, search_term, selectedCategories, selectedAuthors, selectedChapters,
    selectedGrades, selectedDifficulties, selectedLanguages, selectedTerms, selectedYears, selectedsubjects, selectedStatuses, selectedTeachers) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/assessments/getAllFilteredAssessments/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedStatuses=${selectedStatuses}&selectedTeachers=${selectedTeachers}`, requestOptions).then(APIHandler.handleResponse);
}

async function viewAssessment(assessment_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/assessments/get/${assessment_id}`, requestOptions).then(APIHandler.handleResponse);
}

async function viewAssessmentData(assessment_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/assessments/get-view-details/${assessment_id}`, requestOptions).then(APIHandler.handleResponse);
}

async function getAssessors(assessment_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    const response = await fetch(`${config.oat_api_url}/assessment/assessors/getAllAssessors/${assessment_id}`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getCandidates(assessment_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/candidates/getCandidates/${assessment_id}`, requestOptions).then(APIHandler.handleResponse);
}

async function getCandidate(candidate_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/candidates/getCandidate/${candidate_id}`, requestOptions).then(APIHandler.handleResponse);
}

async function getSchedule(assessment_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/assessment/schedules/getSchedule/${assessment_id}`, requestOptions).then(APIHandler.handleResponse);
}