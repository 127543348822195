import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const StudentEnrollmentService = {
  create,
  update,
  updateEnrollment,
  getSortedWithCount,
  getEmailsForOfferedCourse,
  getUpdateInfo,
  getSortedWithNodeCount,
  save,
  removeEnrollment
};

export const TeacherEnrollmentService = {
  Teachercreate,
  Teachersave,
  Teacherupdate,
  updateTeacherEnrollment,
  getTeacherSortedWithCount,
  getTeacherSortedWithNodeCount,
  getTeacherEmailsForOfferedCourse,
  getTeacherUpdateInfo,
  removeTeacherEnrollment,
  
};


async function create(enrollment) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrollment,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/student-enrollments/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function save(enrollment) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrollment,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/student-enrollments/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(enrollment, enrollment_id) {
  const enrollmentJson = JSON.stringify(enrollment);
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrollmentJson,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-enrollments/update/${enrollment_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateEnrollment(enrollment, enrollment_id) {
  const enrollmentJson = JSON.stringify(enrollment);
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrollmentJson,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-enrollments/update/${enrollment_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getSortedWithCount(
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-enrollments/getSortedWithCount/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getSortedWithNodeCount(
  nodeId,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term,
  registration_id,
  user_table_id,
  create_user_id,
  owner_restriction,
  user_list_restriction,
  create_user_table_id,
  form_list_id
) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-enrollments/getAllEnrollWithPaginationAndSorting/${nodeId}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}&r_id=${registration_id}&u_t_id=${user_table_id}&cr_u_id=${create_user_id}&cr_u_t_id=${create_user_table_id}&o_rest=${owner_restriction}&l_rest=${user_list_restriction}&f_id=${form_list_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getEmailsForOfferedCourse(offered_course_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-enrollments/getEmailsForOfferedCourse/${offered_course_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getUpdateInfo(student_id, program_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/student-enrollments/updateFetch/${student_id}/${program_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function removeEnrollment(id){

  const requestOptions = {
      method: "DELETE",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/student-enrollments/delete/${id}`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}



async function Teachercreate(enrollment) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrollment,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/teacher-enrollments/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function Teachersave(enrollment) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrollment,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/teacher-enrollments/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function Teacherupdate(enrollment, enrollment_id) {
  const enrollmentJson = JSON.stringify(enrollment);
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrollmentJson,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/teacher-enrollments/update/${enrollment_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateTeacherEnrollment(enrollment, enrollment_id) {
  const enrollmentJson = JSON.stringify(enrollment);
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrollmentJson,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/teacher-enrollments/update/${enrollment_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getTeacherSortedWithCount(
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/teacher-enrollments/getSortedWithCount/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


async function getTeacherSortedWithNodeCount(
    nodeId,
    limit,
    offset,
    sortColumn,
    sortDirection,
    search_term,
    registration_id,
    user_table_id,
    create_user_id,
    owner_restriction,
    user_list_restriction,
    create_user_table_id,
    form_list_id
  ) {
    const requestOptions = {
      method: "GET",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/teacher-enrollments/getAllEnrollWithPaginationAndSorting/${nodeId}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}&r_id=${registration_id}&u_t_id=${user_table_id}&cr_u_id=${create_user_id}&cr_u_t_id=${create_user_table_id}&o_rest=${owner_restriction}&l_rest=${user_list_restriction}&f_id=${form_list_id}`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
  }

async function getTeacherEmailsForOfferedCourse(offered_course_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/teacher-enrollments/getEmailsForOfferedCourse/${offered_course_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getTeacherUpdateInfo(student_id, program_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/teacher-enrollments/updateFetch/${student_id}/${program_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function removeTeacherEnrollment(id){

  const requestOptions = {
      method: "DELETE",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/teacher-enrollments/delete/${id}`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}