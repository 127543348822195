import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const CityService = {
  add,
  update,
  getAllCitiesLimit,
  getCitiesForProvince
};

//getting cities with limit
async function getAllCitiesLimit(limit, offset, search_term) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/cities/getAllCitiesLimit/${limit}/${offset}?search_term=${search_term}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//add a new city
async function add(newcity) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newcity),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/cities/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//update previous city
async function update(cityDetails, cityId) {
  const configOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(cityDetails),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/cities/update/${cityId}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//get city list for relevent province
async function getCitiesForProvince(province_id) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/cities/province/${province_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}
