import { Redirect } from "react-router-dom";
import React from "react";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";
import DataHandler from "../../handlers/DataHandler";
import { MicrosoftLoginAgent } from "../../authentication/MicrosoftLoginAgent";
import { history } from "../../helper/history";
import { customerConstants } from "../../helper/constants.js";
import { routes } from "../../helper/routes.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

const customer = getCustomerFeatureFlag();
let isEdFoci;
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    isEdFoci = false
    break
  case customerConstants.EXO_CUSTOMER2:
    isEdFoci = false
    break
  default:
    isEdFoci = true
    break
}

export const UserManagementService = {
  createStudent,
  createAllStudentRoles,
  createStudentRole,
  updateByStuId,
  createStaff,
  updateStaffuser,
  createStaffRole,
  getStuIds,
  getStuId,
  deleteRoleCode,
  logout,
  staffLogout,
  getAllUsers,
  getUserRoles,
  userlogout
};

//Student user
async function createStudent(studentUserData) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: studentUserData,
  };
  const response = await fetch(
    `${config.oat_api_url}/user/students/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function createAllStudentRoles(studentUserData) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: studentUserData,
  };
  const response = await fetch(
    `${config.oat_api_url}/user/student/roles/add/all`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function createStudentRole(studentUserRoleData) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: studentUserRoleData,
  };
  const response = await fetch(
    `${config.oat_api_url}/user/student/roles/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateByStuId(studentUserItem, studentId, table_log_id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: studentUserItem,
  };
  const response = await fetch(
    `${config.oat_api_url}/user/students/updateByStuId/${studentId}/${table_log_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

//Staff user
async function createStaff(staffUserdata) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: staffUserdata,
  };
  const response = await fetch(`${config.oat_api_url}/user/staffs/add`, requestOptions);
  return APIHandler.handleResponse(response);
}

async function updateStaffuser(staffUserdata, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: staffUserdata,
  };
  const response = await fetch(
    `${config.oat_api_url}/user/staffs/updateByStaffId/${id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function createStaffRole(staffRoleData) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: staffRoleData,
  };
  const response = await fetch(
    `${config.oat_api_url}/user/staff/roles/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getStuIds(studentIds) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/user/students/getStuIds/${studentIds}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getStuId(studentId, tableLogId) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/user/students/getStuId/${studentId}/${tableLogId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteRoleCode(role_code, user_id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/user/staff/roles/delete/${role_code}/${user_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function logout() {
  try {
    DataHandler.clearSession(); // should be apply before the microsoft logout
    await MicrosoftLoginAgent.logout();
  } catch (e) {
    DataHandler.clearSession();
    history.push("/");
    window.location.reload();
  }
}

async function staffLogout() {
  const userType = sessionStorage.getItem("userType");
  try {
    DataHandler.clearSession();
    //await MicrosoftLoginAgent.logout();

    // Conditionally redirect based on userType
    let redirectPath;
    
    if(!isEdFoci){
      switch (userType) {
        case "admin":
          redirectPath = routes.admin_url;
          break;
        case "teacher":
          redirectPath = routes.teacher_url;
          break;
        default:
          redirectPath = routes.base_url;
          break;
      }
    }
    else{
      redirectPath = routes.base_url;
    }
    
    history.push(redirectPath);
    window.location.reload();
  } catch (e) {
    DataHandler.clearSession();
    history.push("/");
    window.location.reload();
  }
}

async function getAllUsers() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/user/staffs/`, requestOptions).then(APIHandler.handleResponse);
}

async function getUserRoles() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    const response = await fetch(`${config.oat_api_url}/user/staff/roles/`, requestOptions);
    return APIHandler.handleResponse(response);
}

function userlogout() {
    try {
        DataHandler.clearSession();
        return <Redirect to={routes.login} />
    } catch (e) {
        return e
    }
}