import React, { Component } from "react";
import { Spinner, Row, Col, Button } from "react-bootstrap";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Switch,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import Card from "../../../components/common/Card.js";
import CardHeader from "../../../components/common/CardHeader.js";
import CardBody from "../../../components/common/CardBody.js";
import CardTitle from "../../../components/common/CardTitle.js";
import sweetAlert, {
  sweetConfirmAlert,
  sweetStatusAlert,
} from "../../../components/common/SweetAlert.js";
import { NodeFormMapService } from "../../../service/manage/h_tree/node_form_map.service.js";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SimpleReactValidator from "simple-react-validator";
import { FormListService } from "../../../service/manage/h_tree/form_list.service.js";
import Dropdown from "react-bootstrap/Dropdown";
import ManageForm from "./ManageForm.js";
import config from "../../../helper/config.js";
import {
  customerConstants,
  fn_form_attachment_addalter_delete_outputs,
} from "../../../helper/constants.js";
import ManageLinkForm from "./ManageLinkForm.js";
import LinkIcon from "@mui/icons-material/Link";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

class FormAttachment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formPrivilagesList: [],
      loading: true,
      showInsertModal: false,
      showUpdateModal: false,
      showLinkBreadcomeModal: false,
      form_list_id: "",
      form_code: "",
      form_type: "",
      form_title: "",
      note: "",
      formList: [],
      all_form_list: [],
      selectedForm: "",
      pid: "",
      mid: "",
      lg_user_id: "",
      lg_user_table_id: "",
      showDropdownManage: false,
      formUserList: [],
      selectedDropDownForm: null,
      attachedDropDownForm: null,
      dropDownExist: false,
      dropDownForms: [],
      toggledFormforDropDown: null,
      userModalLoading: false,
      nodeTitle: null,
      breadcrumb2: null,
      manageFormVisibility: false,
      selectedManageFrom: null,
      limit: 5000,
      offset: 0,
      sortColumn: "user_code",
      sortDirection: "desc",
      studentSearchTerm: "",
      manageDropDownShowAll: false,
      showManageLinkForm: false,
    };

    this.showManageLinkForm = this.showManageLinkForm.bind(this);
    this.hideManageLinkForm = this.hideManageLinkForm.bind(this);

    this.createValidationsInstance();
    this.formInitialState = {
      form_list_id: "",
      note: "",
    };
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const lg_user_id = this.props.state.lg_user_id;
    const lg_user_table_id = this.props.state.lg_user_table_id;
    const { breadcrumb2, nodeTitle, pid } = this.props.state;

    const mid =
      this.props.state.node_mid_actual !== null
        ? this.props.state.node_mid_actual
        : this.props.state.mid;

    this.setState({
      pid: pid,
      mid: mid,
      lg_user_id: lg_user_id,
      lg_user_table_id: lg_user_table_id,
      breadcrumb2,
      nodeTitle,
    });
    this.getFormAttachmentList(mid, lg_user_id, lg_user_table_id);

    FormListService.getAllList(mid).then((data) => {
      this.setState({
        formList: !!data.data
          ? data.data.sort((a, b) => {
              if (a.form_type.toLowerCase() < b.form_type.toLowerCase())
                return -1;
              if (a.form_type.toLowerCase() > b.form_type.toLowerCase())
                return 1;
              if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
              if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
              return 0;
            })
          : [],
      });
    });

    FormListService.getAll().then((data) => {
      this.setState({
        all_form_list: !!data.data
          ? data.data.sort((a, b) => {
              if (a.form_type.toLowerCase() < b.form_type.toLowerCase())
                return -1;
              if (a.form_type.toLowerCase() > b.form_type.toLowerCase())
                return 1;
              if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
              if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
              return 0;
            })
          : [],
      });
    });
  }

  getFormAttachmentList = (mid, lg_user_id, lg_user_table_id) => {
    NodeFormMapService.getFormAttachmentList(
      mid,
      lg_user_id,
      lg_user_table_id
    ).then((data) => {
      this.setState({
        formPrivilagesList: data.data,
        loading: false,
      });
    });
  };

  toggleAddModal = () => {
    if (this.state.showInsertModal) {
      this.setState({
        showInsertModal: false,
        selectedForm: [],
        form_list_id: null,
        note: null,
      });
    } else {
      this.setState({
        showInsertModal: true,
      });
    }
  };

  toggleUpdateModal = (selectedGainedform) => {
    if (this.state.showUpdateModal) {
      this.setState({
        showUpdateModal: false,
        form_list_id: null,
        note: null,
        selectedForm: null,
      });
    } else {
      const selectedForm = this.state.all_form_list.find(
        (form) => form.form_list_id === selectedGainedform.form_list_id
      );
      this.setState({
        showUpdateModal: true,
        form_list_id: selectedForm.form_list_id,
        note: selectedGainedform.note,
        selectedForm: selectedForm,
      });
    }
  };

  toggleshowLinkBreadcomeModal = (selectedGainedform) => {
    if (this.state.showLinkBreadcomeModal) {
      this.setState({
        showLinkBreadcomeModal: false,
        form_list_id: null,
        note: null,
        selectedForm: null,
      });
    } else {
      const selectedForm = this.state.all_form_list.find(
        (form) => form.form_list_id === selectedGainedform.form_list_id
      );

      if (selectedForm) {
        const updatedForm = {
          ...selectedForm,
          flg_dropdown: selectedGainedform.flg_dropdown,
          linked_form: selectedGainedform.linked_form,
        };
        this.setState({
          showLinkBreadcomeModal: true,
          form_list_id: updatedForm.form_list_id,
          note: selectedGainedform.note,
          selectedForm: updatedForm,
        });
      }
    }
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(this.formInitialState);
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      this.setState(this.formInitialState);
      toggleFunction();
    }
  };

  manageFormSetup(form) {
    this.setState({
      selectedManageFrom: form,
    });
  }

  showManageLinkForm() {
    this.setState({ showManageLinkForm: true });
  }

  hideManageLinkForm() {
    this.setState({ showManageLinkForm: false });
  }

  deleteForm(form) {
    const { mid, lg_user_id, lg_user_table_id } = this.state;
    sweetConfirmAlert().then(() => {
      this.setState({
        loading: true,
      });
      NodeFormMapService.deleteFormAttachment(
        lg_user_id,
        lg_user_table_id,
        mid,
        parseInt(form.form_list_id),
        "null"
      ).then((data) => {
        const obj = fn_form_attachment_addalter_delete_outputs.find(
          (obj) => obj.code === data.data[0].fn_form_attachment_addalter_delete
        );
        obj && sweetStatusAlert(obj.title, obj.errorMessage, obj.iconInfo);
        if (obj.code > 0) {
          this.props.nodeProps.selectNode();
        }
        this.setState({
          loading: false,
        });
      });
    });
  }

  updateForm(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const { mid, lg_user_id, lg_user_table_id, form_list_id, note } =
      this.state;
    this.toggleUpdateModal();
    NodeFormMapService.updateFormAttachment(
      lg_user_id,
      lg_user_table_id,
      mid,
      parseInt(form_list_id),
      note
    ).then((data) => {
      const obj = fn_form_attachment_addalter_delete_outputs.find(
        (obj) => obj.code === data.data[0].fn_form_attachment_addalter_delete
      );
      obj && sweetStatusAlert(obj.title, obj.errorMessage, obj.iconInfo);
      if (obj.code > 0) {
        this.getFormAttachmentList(mid, lg_user_id, lg_user_table_id);
      }
      this.setState({
        loading: false,
      });
    });
  }

  async submitForm(event) {
    event.preventDefault();

    const {
      mid,
      lg_user_id,
      lg_user_table_id,
      form_list_id,
      note,
      selectedForm,
    } = this.state;

    // Validate if Form Template is selected
    if (!selectedForm) {
      window.Swal.fire("Warning!", "Please select a Form Template.", "warning");
      return;
    }

    this.setState({
      loading: true,
    });
    this.toggleAddModal();
    NodeFormMapService.insertFormAttachment(
      lg_user_id,
      lg_user_table_id,
      mid,
      parseInt(form_list_id),
      note
    ).then((data) => {
      const obj = fn_form_attachment_addalter_delete_outputs.find(
        (obj) => obj.code === data.data[0].fn_form_attachment_addalter_delete
      );
      obj && sweetStatusAlert(obj.title, obj.errorMessage, obj.iconInfo);
      if (obj.code > 0) {
        this.props.nodeProps.selectNode();
      }
      this.setState({
        loading: false,
      });
    });
  }

  handleFormSelect = (id) => {
    const selectedForm = this.state.formList.find(
      (form) => form.form_list_id === parseInt(id)
    );

    this.setState({
      selectedForm: selectedForm,
      form_list_id: id,
    });
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "flg_node_hide") {
      value = event.target.checked ? 1 : 0;
    }
    this.setState({ [name]: value });
  };

  handleDropDownFormSelect = (e) => {
    const {
      lg_user_id,
      lg_user_table_id,
      studentSearchTerm,
      limit,
      offset,
      sortColumn,
      sortDirection,
    } = this.state;

    const selectedDropDownForm = this.state.dropDownForms.find(
      (form) => form.id === parseInt(e.target.value)
    );
    this.setState({
      selectedDropDownForm: selectedDropDownForm,
      userModalLoading: true,
    });
    !!selectedDropDownForm &&
      NodeFormMapService.getMembersListOfForm(
        selectedDropDownForm.node_mid,
        selectedDropDownForm.form_list_id,
        lg_user_id,
        lg_user_table_id,
        limit,
        offset,
        sortColumn,
        sortDirection,
        studentSearchTerm
      ).then((data) => {
        this.setState({
          formUserList: data.data.members,
          userModalLoading: false,
        });
      });
  };
  handleManageDropDownShowAll = (e) => {
    this.setState(
      {
        manageDropDownShowAll: e.target.checked,
      },
      () => {
        if (this.state.manageDropDownShowAll) {
        } else {
        }
      }
    );
  };

  toggleDropdownManage = (selectedForm) => {
    const {
      lg_user_id,
      lg_user_table_id,
      mid,
      limit,
      offset,
      sortColumn,
      sortDirection,
      studentSearchTerm,
    } = this.state;
    if (!selectedForm) {
      this.setState({
        showDropdownManage: false,
        toggledFormforDropDown: null,
        selectedDropDownForm: null,
        attachedDropDownForm: null,
        dropDownExist: false,
        formUserList: [],
      });
    } else {
      this.setState(
        {
          toggledFormforDropDown: selectedForm,
          showDropdownManage: true,
        },
        () => {
          NodeFormMapService.getDropdownFormList(
            mid,
            lg_user_id,
            lg_user_table_id
          ).then((data) => {
            this.setState(
              {
                dropDownForms: data.data.map((item, index) => ({
                  ...item,
                  id: index,
                })),
              },
              () => {
                if (
                  !!selectedForm.dropdown_form_id &&
                  !!selectedForm.dropdown_form_mid
                ) {
                  this.setState({
                    userModalLoading: true,
                  });
                  NodeFormMapService.getMembersListOfForm(
                    selectedForm.dropdown_form_mid,
                    selectedForm.dropdown_form_id,
                    lg_user_id,
                    lg_user_table_id,
                    limit,
                    offset,
                    sortColumn,
                    sortDirection,
                    studentSearchTerm
                  ).then((data) => {
                    const selectedDropDownForm = this.state.dropDownForms.find(
                      (form) =>
                        form.node_mid === selectedForm.dropdown_form_mid &&
                        form.form_list_id === selectedForm.dropdown_form_id
                    );
                    this.setState({
                      formUserList: data.data.members,
                      userModalLoading: false,
                    });

                    if (!!selectedDropDownForm) {
                      this.setState({
                        dropDownExist: true,
                        selectedDropDownForm: selectedDropDownForm,
                        attachedDropDownForm: selectedDropDownForm,
                      });
                    } else {
                      this.setState({
                        dropDownExist: false,
                        selectedDropDownForm: null,
                        attachedDropDownForm: null,
                      });
                    }
                  });
                } else {
                  this.setState({
                    userModalLoading: false,
                  });
                }
              }
            );
          });
        }
      );
    }
  };

  actionDropDownForm(type) {
    const {
      mid,
      lg_user_id,
      lg_user_table_id,
      selectedDropDownForm,
      toggledFormforDropDown,
    } = this.state;
    sweetConfirmAlert().then(() => {
      if (type === "remove") {
        NodeFormMapService.removeDropdownForm(
          mid,
          lg_user_id,
          lg_user_table_id,
          toggledFormforDropDown.form_list_id
        ).then((_) => {
          sweetStatusAlert(
            "Success!",
            "Dropdown successfully removed from the form",
            "success"
          );
          this.toggleDropdownManage();
          this.getFormAttachmentList(mid, lg_user_id, lg_user_table_id);
        });
      } else if (type === "update") {
        NodeFormMapService.updateDropdownForm(
          mid,
          lg_user_id,
          lg_user_table_id,
          toggledFormforDropDown.form_list_id,
          selectedDropDownForm.node_mid,
          selectedDropDownForm.form_list_id
        ).then((_) => {
          sweetStatusAlert(
            "Success!",
            "Dropdown successfully updated to the form",
            "success"
          );
          this.toggleDropdownManage();
          this.getFormAttachmentList(mid, lg_user_id, lg_user_table_id);
        });
      } else if (type === "attach") {
        NodeFormMapService.attachDropdownForm(
          mid,
          lg_user_id,
          lg_user_table_id,
          toggledFormforDropDown.form_list_id,
          selectedDropDownForm.node_mid,
          selectedDropDownForm.form_list_id
        ).then((_) => {
          sweetStatusAlert(
            "Success!",
            "Dropdown successfully attached to the form",
            "success"
          );
          this.toggleDropdownManage();
          this.getFormAttachmentList(mid, lg_user_id, lg_user_table_id);
        });
      }
      this.props.nodeProps.selectNode();
    });
  }

  render() {
    const {
      formPrivilagesList,
      loading,
      form_code,
      form_type,
      form_title,
      formList,
      note,
      selectedForm,
      formUserList,
      selectedDropDownForm,
      attachedDropDownForm,
      dropDownExist,
      dropDownForms,
      userModalLoading,
      form_list_id,
      breadcrumb2,
      nodeTitle,
      selectedManageFrom,
    } = this.state;
    this.validator.purgeFields();
    const formattedBreadcrumb = breadcrumb2
      ? breadcrumb2.replaceAll(">", " > ")
      : "";
    return (
      <>
        {/* <ContentWrapper> */}
        {selectedManageFrom ? (
          <ManageForm
            pid={this.props.state.pid}
            mid={
              this.props.state.node_mid_actual !== null
                ? this.props.state.node_mid_actual
                : this.props.state.mid
            }
            formDetail={selectedManageFrom}
            nodeState={this.props.state}
            manageFormSetup={() => this.manageFormSetup(undefined)}
            nodeProps={this.props.nodeProps}
          />
        ) : (
          <Row
            style={{
              backgroundColor: "#fff",
              height: "90vh",
              overflow: "auto",
            }}
          >
            <Col md={12} className="px-0">
              <div style={{ overflow: "auto" }}>
                <CardHeader>
                  {this.props.state.path !== "node" && (
                    <p
                      className="pb-4"
                      style={{ fontSize: "10.5625px", color: "#00000099" }}
                    >
                      {formattedBreadcrumb
                        ? `${formattedBreadcrumb && formattedBreadcrumb}`
                        : ""}
                    </p>
                  )}
                  <div className="row d-flex justify-content-between">
                    <div>
                      <CardTitle cardLabel="Manage Form Access Privileges" />
                    </div>
                    <div>
                      <Button
                        className="insert-btn"
                        variant="primary"
                        onClick={this.toggleAddModal}
                      >
                        Add new
                      </Button>

                      <Button
                        className="insert-btn"
                        variant="primary"
                        onClick={this.showManageLinkForm}
                      >
                        Add Form Link
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                {this.state.showManageLinkForm && (
                  <ManageLinkForm
                    pid={this.props.state.pid}
                    mid={
                      this.props.state.node_mid_actual !== null
                        ? this.props.state.node_mid_actual
                        : this.props.state.mid
                    }
                    formDetail={selectedManageFrom}
                    nodeState={this.props.state}
                    nodeProps={this.props.nodeProps}
                    showModal={this.state.showManageLinkForm}
                    hideModal={this.hideManageLinkForm}
                    getFormAttachmentList={this.getFormAttachmentList}
                    setLoading={() => this.setState({ loading: true })}
                  />
                )}
                <CardBody>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "#007bff" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : formPrivilagesList.length > 0 ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="thead">Code</TableCell>
                          <TableCell className="thead">Type</TableCell>
                          <TableCell className="thead">Template</TableCell>
                          <TableCell className="thead">
                            Privilege Types
                          </TableCell>
                          {/* <TableCell className="thead">Note</TableCell> */}
                          <TableCell className="thead">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formPrivilagesList.map((form) => (
                          <TableRow key={form.form_list_id}>
                            <TableCell className="tData">
                              {form.form_code}
                            </TableCell>
                            <TableCell className="tData">
                              {form.form_type}
                            </TableCell>
                            <TableCell>
                              {form.node_mid_actual !== null ? (
                                <>
                                  <span className="stu-variant-class label label-lg label-light label-inline">
                                    <LinkIcon sx={{ marginRight: 1 }} />
                                    {form.form_title}
                                  </span>
                                </>
                              ) : (
                                form.form_title
                              )}
                            </TableCell>

                            <TableCell className="thead row text-nowrap">
                              <Button
                                variant="primary"
                                onClick={(event) => this.manageFormSetup(form)}
                                style={{
                                  width: "160px",
                                  width: "140px",
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                  marginRight: "8px",
                                }}
                              >
                                Privilege Setup
                              </Button>

                              <Button
                                variant="primary"
                                onClick={(event) =>
                                  this.toggleDropdownManage(form)
                                }
                                style={{
                                  color: "var(--maincolor)",
                                  backgroundColor: "#edeef7",
                                  width: "140px",
                                  fontSize: "12px",
                                  whiteSpace: "nowrap",
                                  border: "none",
                                }}
                              >
                                Manage Dropdown
                              </Button>
                            </TableCell>
                            {/* <TableCell className="tData">{form.note}</TableCell> */}
                            <TableCell className="tData">
                              {form.note ? (
                                <Button
                                  className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-eye-icon"
                                  title="View Note"
                                  onClick={() => this.toggleUpdateModal(form)}
                                />
                              ) : (
                                <Button
                                  className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon2-notepad"
                                  title="Add Note"
                                  onClick={() => this.toggleUpdateModal(form)}
                                />
                              )}
                              <Button
                                className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                title="Delete Details"
                                sx={{ color: "red" }}
                                onClick={() => this.deleteForm(form)}
                              />
                              {form.node_mid_actual !== null && (
                                <div className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn">
                                  <IconButton
                                    onClick={() => {
                                      this.toggleshowLinkBreadcomeModal(form);
                                    }}
                                    title="Linked Details"
                                  >
                                    <LinkIcon
                                      style={{
                                        color: "#A2A5B5",
                                        fontSize: "16px",
                                      }}
                                    />
                                  </IconButton>
                                </div>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  ) : (
                    <p style={{ textAlign: "center", marginTop: "20px" }}>
                      No data available.
                    </p>
                  )}
                </CardBody>
              </div>
            </Col>
          </Row>
        )}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() =>
            this.handleSweetAlert(this.toggleAddModal, "showInsertModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Form</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    className="mb-4"
                    controlId="statusId"
                  >
                    <Form.Label>Form Template*</Form.Label>
                    <Dropdown data-bs-theme="dark">
                      <Dropdown.Toggle
                        id="dropdown-button-dark-example1"
                        variant="secondary"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {!!selectedForm
                          ? selectedForm.title
                          : "Select Template"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{
                          maxHeight: "190px",
                          overflowY: "auto",
                          width: "100%",
                        }}
                      >
                        {formList?.map((form) => (
                          <Dropdown.Item
                            key={form.form_list_id}
                            onClick={() =>
                              this.handleFormSelect(form.form_list_id)
                            }
                          >
                            <div className="col-6">{form.form_type}</div>
                            <div className="col-6">{form.title}</div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Form Code*</Form.Label>
                    <Form.Control
                      type="text"
                      name="form_code"
                      value={selectedForm ? selectedForm.form_code : ""}
                      placeholder="Form Code"
                      disabled
                    />
                  </Form.Group>
                </Row>

                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                    className="mb-4"
                  >
                    <Form.Label>Form Type*</Form.Label>
                    <Form.Control
                      type="text"
                      name="form_type"
                      value={selectedForm ? selectedForm.form_type : ""}
                      placeholder="Form Type"
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Note</Form.Label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Note"
                      rows="3"
                      name="note"
                      value={note}
                      onChange={this.handleChange}
                    ></textarea>
                  </Form.Group>
                </Row>

                <Modal.Footer>
                  <Button
                    className="mb-4"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={(event) => this.submitForm(event)}
                  >
                    Save changes
                  </Button>
                  <Button
                    className="mb-4"
                    id="modal-close-button"
                    style={closeBtnStyle}
                    onClick={() =>
                      this.handleSweetAlert(
                        this.toggleAddModal,
                        "showInsertModal"
                      )
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </div>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showUpdateModal}
          onHide={() =>
            this.handleSweetAlert(this.toggleUpdateModal, "showUpdateModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Form Note</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Form Title*</Form.Label>
                    <Form.Control
                      type="text"
                      name="form_title"
                      value={!!selectedForm ? selectedForm.title : null}
                      placeholder="Form Title"
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Form Code*</Form.Label>
                    <Form.Control
                      type="text"
                      name="form_code"
                      value={!!selectedForm ? selectedForm.form_code : ""}
                      placeholder="Form Code"
                      disabled
                    />
                  </Form.Group>
                </Row>

                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Form Type*</Form.Label>
                    <Form.Control
                      type="text"
                      name="form_type"
                      value={!!selectedForm ? selectedForm.form_type : ""}
                      placeholder="Form Type"
                      disabled
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Note</Form.Label>
                    <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder="Note"
                      rows="3"
                      name="note"
                      value={note}
                      onChange={this.handleChange}
                    ></textarea>
                  </Form.Group>
                </Row>

                <Modal.Footer>
                  <Button
                    className="mb-4"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={(event) => this.updateForm(event)}
                  >
                    Save changes
                  </Button>
                  <Button
                    className="mb-4"
                    id="modal-close-button"
                    style={closeBtnStyle}
                    onClick={() =>
                      this.handleSweetAlert(
                        this.toggleUpdateModal,
                        "showUpdateModal"
                      )
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </div>
        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showDropdownManage}
          onHide={() => this.toggleDropdownManage()}
        >
          <Modal.Header closeButton>
            <Modal.Title>User List </Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              <Card>
                <CardHeader>
                  <CardTitle
                    cardLabel={`Dropdown Manage ${
                      this.state.toggledFormforDropDown &&
                      "from " +
                        this.state.toggledFormforDropDown?.form_title +
                        " [" +
                        this.state.toggledFormforDropDown?.form_code +
                        "]"
                    }`}
                  />
                </CardHeader>
                <CardBody>
                  <Row>
                    <Form.Label>
                      <span style={{ fontWeight: "bold", paddingLeft: "11px" }}>
                        Location :&nbsp;&nbsp;
                      </span>
                      {!!selectedDropDownForm &&
                        selectedDropDownForm.breadcrumb}
                    </Form.Label>
                  </Row>
                  <Row className="col-12 px-0 ml-0 pl-0">
                    <Form.Group as={Col} md="12" controlId="statusId">
                      <div className="row justify-content-between">
                        <Form.Label>User Groups​</Form.Label>
                      </div>
                      <div className="row justify-content-between">
                        <div class="col-8 px-0 ml-0 pl-0">
                          <Form.Select
                            aria-label="Default select example"
                            defaultValue={
                              !!selectedDropDownForm
                                ? selectedDropDownForm.id
                                : ""
                            }
                            value={
                              !!selectedDropDownForm
                                ? selectedDropDownForm.id
                                : ""
                            }
                            onChange={this.handleDropDownFormSelect}
                            className="mb-2"
                          >
                            <option value="">Select</option>
                            {dropDownForms.map((form) => {
                            const cleanTitle = (title) =>
                              title.replace(/\[.*?\]\s*/, "");

                            return (
                              <option key={form.id} value={form.id}>
                                {(!!form.breadcrumb
                                  ? `${form.breadcrumb}  `
                                  : "") + cleanTitle(form.form_display_title)}
                              </option>
                            );
                              }
                            )}
                          </Form.Select>
                        </div>
                        <div class="col-4">
                          {!!selectedDropDownForm && (
                            <div className="row">
                              {!dropDownExist &&
                                this.state.toggledFormforDropDown
                                  .flg_dropdown === 1 && (
                                  <Button
                                    className="insert-btn"
                                    variant="primary"
                                    style={addBtnStyle}
                                    onClick={() =>
                                      this.actionDropDownForm("attach")
                                    }
                                  >
                                    Save
                                  </Button>
                                )}
                              {dropDownExist && (
                                <Button
                                  className="insert-btn"
                                  variant="primary"
                                  style={addBtnStyle}
                                  onClick={() =>
                                    this.actionDropDownForm("update")
                                  }
                                  disabled={
                                    this.state.toggledFormforDropDown
                                      .flg_dropdown === 0
                                  }
                                >
                                  Update
                                </Button>
                              )}
                              {dropDownExist && (
                                <Button
                                  className="insert-btn"
                                  variant="danger"
                                  onClick={() =>
                                    this.actionDropDownForm("remove")
                                  }
                                  disabled={
                                    this.state.toggledFormforDropDown
                                      .flg_dropdown === 0
                                  }
                                >
                                  Remove
                                </Button>
                              )}

                              {/* <Form.Control
                              type="text"
                              disabled
                              value={!!attachedDropDownForm ? attachedDropDownForm.form_display_title :"None Attached"}
                            /> */}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <Form.Label>
                        Please select the FORM the list of users need to be
                        taken from (i.e. USER-GROUP*)​​
                      </Form.Label> */}
                    </Form.Group>
                  </Row>
                  {!!selectedDropDownForm && (
                    <Row className="col-12 px-0 ml-0 pl-0">
                      <Form.Group
                        as={Col}
                        controlId="statusId"
                        className="mb-4"
                      >
                        <div className="row space-between !important ">
                          <Form.Label>
                            <span style={{ fontWeight: "bold" }}>
                              Selected User Group :&nbsp;&nbsp;
                            </span>
                          </Form.Label>

                          <span>
                            <div
                              className="form-control"
                              style={{
                                appearance: "none",
                                border: "none",
                                padding: "0",
                              }}
                            >
                              {!!attachedDropDownForm
                                ? attachedDropDownForm.form_display_title
                                : "None Attached"}
                            </div>
                          </span>
                        </div>
                      </Form.Group>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={this.state.manageDropDownShowAll}
                              onChange={this.handleManageDropDownShowAll}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Show users"
                        />
                      </FormGroup>
                    </Row>
                  )}
                  {selectedDropDownForm && this.state.manageDropDownShowAll && (
                    <h4>{selectedDropDownForm.form_display_title}</h4>
                  )}
                  {userModalLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "#007bff" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : formUserList.length > 0 ? (
                    this.state.manageDropDownShowAll && (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="thead">User Code</TableCell>
                            <TableCell className="thead">User Detail</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {formUserList.map((user) => (
                            <TableRow
                              key={`${user.user_id}${user.user_table_id}`}
                            >
                              <TableCell className="tData">
                                {user.user_code}
                              </TableCell>
                              <TableCell className="tData">
                                {user.data_concat}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )
                  ) : (
                    this.state.manageDropDownShowAll && (
                      <p
                        style={{
                          textAlign: "center",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                        }}
                      >
                        No data available.
                      </p>
                    )
                  )}
                </CardBody>
              </Card>
            </Modal.Body>
          </div>
        </Modal>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showLinkBreadcomeModal}
          onHide={() => this.toggleshowLinkBreadcomeModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Link Form Details</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Linked Locations:</Form.Label>
                    <div>
                      {this.state.selectedForm
                        ? this.state.selectedForm.linked_form
                        : ""}
                    </div>
                  </Form.Group>
                </Row>
                <Modal.Footer>
                  <Button
                    className="mb-4"
                    id="modal-close-button"
                    style={closeBtnStyle}
                    onClick={() => this.toggleshowLinkBreadcomeModal()}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          </div>
        </Modal>
      </>
    );
  }
}

export default FormAttachment;
