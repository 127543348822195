import config from "../../../helper/config";
import axios from 'axios';
import APIHandler from "../../../handlers/APIHandler";

export const ChatService = {
    add,
    getChat,
    getChats,
    getMessagesForChat,
    sendMessage,
    removeUsers,
    addUsers,
    renameChat,
    syncUserNameInChatDb,
    getMembersListOfNode,
    getChatInfo,
    addChatInfo
  };

async function add(chat) {
    const configOptions = {
        headers: {
            "Content-Type": "application/json",
        },
      };
    const { data } = await axios.post(
      `${config.chat_api_url}chat/group`,
      chat,
      configOptions
    );
  return data;
}

async function getChat(chatId) {
    const configOptions = {
        headers: {
            "Content-Type": "application/json",
        },
      };
      const { data } = await axios.get(
        `${config.chat_api_url}chat/getChat/${chatId}`,
        configOptions
      );
  return data;
}

async function getChats(chatList,userDetails) {
  const configOptions = {
      headers: {
          "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${config.chat_api_url}chat/getChats`,
      {
        chatList,
        userDetails,
      },
      configOptions
    );
  return data;
}

async function getMessagesForChat(chatId) {
  const configOptions = {
      headers: {
          "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `${config.chat_api_url}message/${chatId}`,
      configOptions
    );
  return data;
}

async function sendMessage(message) {
  const configOptions = {
      headers: {
          "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${config.chat_api_url}message`,
      message,
      configOptions
    );
  return data;
}

async function removeUsers(userId,chatId) {
  const configOptions = {
      headers: {
          "Content-Type": "application/json",
      },
    };
    const response = await axios.put(
      `${config.chat_api_url}chat/groupremove`,
      {
        userId,
        chatId
      },
      configOptions
    );
return response;
}

async function addUsers(userId,chatId) {
  const configOptions = {
      headers: {
          "Content-Type": "application/json",
      },
    };
    const response = await axios.put(
      `${config.chat_api_url}chat/groupadd`,
      {
        userId,
        chatId
      },
      configOptions
    );
  return response;
}

async function renameChat(chatName,chatId) {
    const configOptions = {
      headers: {
          "Content-Type": "application/json",
      },
    };
    const response = await axios.put(
      `${config.chat_api_url}chat/rename`,
      {
        chatName,
        chatId
      },
      configOptions
    );
  return response;
}

async function syncUserNameInChatDb(userId, tableId, newName){
  const configOptions = {
    headers: {
        "Content-Type": "application/json",
    },
  };
  const response = await axios.put(
    `${config.chat_api_url}chat/sync-userName`,
    {
      userId,
      tableId,
      newName
    },
    configOptions
  );
  return response;
}

async function getChatInfo(node_mid, lg_user_id, lg_user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/chats/getAllByNodeUser/${node_mid}/${lg_user_id}/${lg_user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function addChatInfo(chat) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(chat),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/chats/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getMembersListOfNode(node_mid, form_list_id, lg_user_id, lg_user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/chats/getAllMembers/${node_mid}/${form_list_id}/${lg_user_id}/${lg_user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response)
}
