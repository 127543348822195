import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { routes } from '../../helper/routes.js';
import _ from "lodash";
import { history } from '../../helper/history.js';
import ContentWrapper from '../../components/common/AssessmentContentWrapper.js';
import AdminTemplate from '../../components/admin/layouts/Template.js';
import Spinner from '../../components/common/Spinner.js'
import Card from '../../components/common/Card.js';
import CardHeader from '../../components/common/CardHeader.js';
import CardBody from '../../components/common/CardBody.js';
import CardTitle from '../../components/common/AssessmentCardTitle.js';
import Breadcrumb from '../../components/common/Breadcrumb.js';
import BreadcrumbItem from '../../components/common/BreadcrumbItem.js';
import Button from '../../components/common/form/Button.js';
import Table from '../../components/common/table/Table.js';
import TableHead from '../../components/common/table/TableHead.js';
import TableBody from '../../components/common/table/TableBody.js';
import TableRow from '../../components/common/table/TableRow.js';
import TableHeadColumn from '../../components/common/table/TableHeadCol.js';
import TableBodyCol from '../../components/common/table/TableBodyCol.js';
import { questionActions } from '../../actions/assessment/questionActions.js';
import { assessorActions } from '../../actions/assessment/assessorActions.js';
import { candidateActions } from '../../actions/candidate/candidateActions.js';
import DataHandler from "../../handlers/DataHandler";
import { messageTypes } from '../../reducers/types.js';
import { manageUserActions } from '../../actions/user_management/manageUserActions.js';
import { studentUserActions } from '../../actions/user_management/studentUserActions.js';
import config from '../../helper/config.js';
import { customerConstants } from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    // cancelClass = "cmc-btn-default"
    // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class GradeReport extends React.Component {

    constructor(props) {
        super(props);

        this.ref_tagify = React.createRef()
        this.state = {
            gradeStudent: false,
            selectedCandidates: [],
            candidateMap: [],
            studentDetailsMap: {},
            loadingCandidateReport: false,
            loadingGradePage: false
        }

        this.onSelectCandidate = this.onSelectCandidate.bind(this);
        this.finalizeAll = this.finalizeAll.bind(this);
        
    }

    onUnload = e => { // the method that will be used for both add and remove event
        e.preventDefault();
        e.returnValue = '';
     }

    async componentDidMount() {
        if(window.KTSelect2) window.KTSelect2.init();
        if(window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
        if (window.KTSweetAlert2Demo) window.KTSweetAlert2Demo.init();
        if(window.KTIONRangeSlider) window.KTIONRangeSlider.init();
        if(window.KTApp.initComponents) window.KTApp.initComponents();
        if (window.$) window.$('.selectpicker').selectpicker();
        if (typeof window.KTLayoutStickyCard !== 'undefined') {
            window.KTLayoutStickyCard.update();
        }else{
            if(window.KTLayoutStickyCard) window.KTLayoutStickyCard.init('kt_page_sticky_card'); 
        }

        this.onDatatableActionEvent()

        window.onpopstate = function(event) {
            history.push(routes.view_assessment)
            window.location.reload()
        };
       
        await this.props.getStudentUsers();
        if (_.isEqual({}, this.props.studentUserMap)) {
            await this.props.createStudentUserMap();
        }

        if (this.props.allStudentUsers.length > 0) {
            this.setState({loadingCandidateReport: true})
            var studentuser, student;
            await this.props.getAllStudents();
            for (studentuser of this.props.allStudentUsers) {
                for (student of this.props.allStudents)
                if (studentuser.student_id === student.stu_id) {
                    let map = this.state.studentDetailsMap;
                    let studentName = student.first_name.concat(" ", student.last_name)
                    map[studentuser.student_id] = studentName
                    this.setState({ studentDetailsMap: map })
                }
            }
            this.setState({loadingCandidateReport: false})
        }

        await this.props.candidateReports.map(async(candidate) => {
                this.setState({loadingCandidateReport: true})
                let map = this.state.candidateMap;
                map[candidate["user_id"]] = candidate;
                let student_id = this.props?.studentUserMap[candidate.user_id]?.student_id;
                let studentName = this.state.studentDetailsMap[student_id];
                map[candidate["user_id"]]["candidateName"] = studentName;
                this.setState({candidateMap: map})
                this.setState({loadingCandidateReport: false})
        });

        window.addEventListener("beforeunload", this.onUnload);
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    }

    onDatatableActionEvent(){
        window.$(document).on('click', '.grade-student',  (e)=> {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.onGradeStudent( this.props.candidateReports[idx])
        })

        window.$(document).on('click', '.delete-student',  (e)=> {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.onDeleteStudent( this.props.candidateReports[idx])
         
        })
    }

    async onGradeStudent(element) {
        DataHandler.setToSession("studentUserID", element?.user_id);
        this.setState({loadingGradePage: true})
        await this.props.getAllQuestionContents({questions: this.props.assessmentQuestions});
        await this.props.getAllAnswerContents({questions: this.props.assessmentQuestions});
        await this.props.getCandidateAnswers(element);
        await this.props.getAllCandidateAnswerContents({answers: this.props.candidateAnswers});
        if (element?.assessor_remarks) {
            await this.props.getRemarks(element.assessor_remarks)
        }
        this.setState({loadingGradePage: false})
        this.setState({
            gradeStudent: true
        })
    }

    async onDeleteStudent(element) {
        window.Swal.fire({
            text: "Do you want to delete the candidate?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: !1,
            confirmButtonText: "Yes, Delete",
            cancelButtonText: "No, cancel",
            customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
                cancelButton: `btn font-weight-bold ${cancelClass}`
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
                }
              }
        }).then((async (action) => {
            if (action.isConfirmed) {
                var candidate_id = element.candidate_id
                var assessment_id = this.props.assessment.assessment_id
                var user_id = DataHandler.getFromSession("user_id");
                window.Swal.showLoading()
                await this.props.deleteCandidate({candidate_id, assessment_id, user_id});
                if (this.props.candidateSuccess) {
                    window.Swal.fire({
                        title: messageTypes.SUCCESS_WARNING,
                        text: this.props.candidateSuccess,
                        icon: messageTypes.SUCCESS,
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    });
                    await this.props.getCandidateReports(this.props.assessment)
                } else {
                    window.Swal.fire({
                        title: messageTypes.FAILED_WARNING,
                        text: this.props.candidateError,
                        icon: messageTypes.ERROR,
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    });
                }
            }
        }))
    }

    getColor(state) {
        switch (state) {
            case "Assigned":
                return "label label-lg label-light-danger label-inline";
            case "Invited":
                return "label label-lg label-light-danger label-inline";
            case "Finished":
                return "label label-lg label-light-success label-inline";
            case "Onprogress":
                return "label label-lg label-light-warning label-inline";
            default:
                return "label label-lg label-light-info label-inline";
        }
    }

    onSelectCandidate(e, el) {
        var candidates = this.state.selectedCandidates
        if (e.target.checked) {
            if (el.candidate_status == 'Finished') {
                candidates.push(e.target.value)  
            } else {
                window.Swal.fire({
                    title: 'Warning!',
                    text: 'Candidate has not finished the test!',
                    icon: 'warning',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                });
                e.target.checked = false
            }          
        } else {
            var index = candidates.indexOf(e.target.value);
            if (index !== -1) {
                candidates.splice(index, 1);
            }
        }

        this.setState({
            selectedCandidates: candidates
        })
    }

    finalizeAll () {
        const assessment_id = this.props.assessment.assessment_id;
        const user_role_id = this.props.assessment.user_role_id;
        const candidates = this.state.selectedCandidates;
        this.props.finalizeAll({ assessment_id, candidates, user_role_id });
    }

    getStartTime(date){
        let startedTime = new Date(date + 'Z')
        return startedTime.toLocaleString();
    }

    render () {
        if (this.state.gradeStudent) {
            return <Redirect to='/grade' />;
        }

        return (
        <AdminTemplate>
            <div style={{ height: "90vh" }}>
                <ContentWrapper>
                    {this.props.candidateLoading && this.props.loading && <Spinner id="show" text="Please wait.."/> }
                    {this.state.loadingCandidateReport && <Spinner id="show" text="Please wait.." />}
                    {this.state.loadingGradePage && <Spinner id="show" text="Please wait.." />}
                    {/* begin::Card */}
                    {this.props.assessment.title != undefined ?
                    <Card>
                        {/* {this.state.addFail && <Alert className="alert alert-custom alert-danger fade show" alertIcon="flaticon-warning" alertText="A simple primary alert—check it out!" />} */}
                        <CardHeader>
                            <CardTitle cardLabel={this.props.assessment.title + " - Candidate Report"}>
                                <Breadcrumb>
                                    <BreadcrumbItem to={routes.view_assessment} breadcrumbItemName="Assessments Library"/>
                                    {/* <BreadcrumbItem to="grade-report" active="active" breadcrumbItemName="Grade Report"/> */}
                                </Breadcrumb>
                            </CardTitle>
                            {/* <CardToolbar>
                                <Button className="btn btn-primary mr-2" btnName="Finalize All" onClick={this.finalizeAll} />
                            </CardToolbar> */}

                        </CardHeader>
        
                        <CardBody>

                            <Table dataTableID="kt_datatable_5" >
                                <TableHead>
                                    <TableRow>
                                        {/* <TableHeadColumn tableHeadLable="Select" /> */}
                                        {/* <TableHeadColumn tableHeadLable="" /> */}
                                        <TableHeadColumn tableHeadLable="No" />
                                        <TableHeadColumn tableHeadLable="Candidate Name" />
                                        <TableHeadColumn tableHeadLable="Candidate Status" />
                                        <TableHeadColumn tableHeadLable="Attempt Start Time" />
                                        <TableHeadColumn tableHeadLable="Attempt Finish Time" />
                                        <TableHeadColumn tableHeadLable="Final Grade" />
                                        <TableHeadColumn tableHeadLable="Assessor Remarks" />
                                        <TableHeadColumn tableHeadLable="Attempt No" />
                                        {/* <TableHeadColumn tableHeadLable="Proctor Remarks" /> */}
                                        <TableHeadColumn tableHeadLable="" />
                                    </TableRow>
                                </TableHead>

                                {this.props.candidateReports && this.props.candidateReports.length &&
                                    <TableBody>
                                        {this.props.candidateReports.map((el, i) => (
                                            <TableRow key={i} checkBoxID={i}>
                                                {/* <TableBodyCol tableBodyLable={<input type="checkbox" value={el.candidate_id} onChange={(e) => this.onSelectCandidate(e, el)} />} /> */}
                                                <TableBodyCol tableBodyLable={i + 1} />
                                                <TableBodyCol tableBodyLable={<div>{this.state.candidateMap[el.user_id]?.candidateName}</div>} />
                                                <TableBodyCol tableBodyLable={<span className={this.getColor(el.candidate_status)}>{el.candidate_status}</span>} />
                                                <TableBodyCol tableBodyLable={<div>{el.attempt_start_time!=null && this.getStartTime(el.attempt_start_time) || "-"}</div>} />
                                                <TableBodyCol tableBodyLable={<div>{el.attempt_end_time!=null && this.getStartTime(el.attempt_end_time) || "-"}</div>} />
                                                {/* <TableBodyCol tableBodyLable={<div>{el.timestamp != null  && el.timestamp.split('T').slice(0, 10).join(' ').slice(0,19) || "-"}</div>} /> */}
                                                <TableBodyCol tableBodyLable={<div>{el.final_grade || "-"}</div>} />
                                                <TableBodyCol tableBodyLable={<div>{ (el.assessor_remarks && "Available" ) || "-"}</div>} />
                                                <TableBodyCol tableBodyLable={<div>{el.attempt_no || "-"}</div>} />
                                                {/* <TableBodyCol tableBodyLable={<div>{el.proctor_remarks || "-"}</div>} /> */}
                                                <TableBodyCol tableBodyLable={
                                                    <div>
                                                        {/* <Button onClick={(e) => this.onGradeStudent(el)} 
                                                        dataIndex={i}
                                                        className="btn btn-icon action-btn btn-sm m-1 grade-student flaticon2-correct-icon" 
                                                        title="Grade Answers" 
                                                        disabled={el.candidate_status !== 'Finished'} /> */}

                                                        {/* <Button onClick={(e) => this.onDeleteStudent(el)} 
                                                        className="btn btn-icon btn-primary btn-sm m-1 delete-student flaticon2-delete-icon" 
                                                        dataIndex={i} 
                                                        title="Delete Candidate" 
                                                        disabled={el.candidate_status == 'Finished' || el.candidate_status == 'Onprogress'} /> */}
                                                    </div>}
                                                />
                                            </TableRow>
                    
                                        ))}
                                    </TableBody>
                                }
                            </Table>
                        </CardBody>
                    </Card>:<div><Redirect to={routes.view_assessment}/></div>}
                    {/* end::Card */}
                </ContentWrapper>
            </div>
        </AdminTemplate>
        )

    }
}

function mapStateToProps(state) {
    return {
        allStudentUsers: state.studentUserReducer.allStudentUsers,
        studentUserMap: state.studentUserReducer.studentUserMap,
        allStudents: state.manageUserReducer.allStudents,

        question: state.questionReducer.question,
        questionContent: state.questionReducer.questionContent,
        questions: state.questionReducer.questions,
        assessmentQuestions: state.questionReducer.assessmentQuestions,
        error: state.questionReducer.errorAddQuestions,

        assessment: state.candidateReducer.assessment,
        candidateSuccess: state.candidateReducer.success,
        candidateError: state.candidateReducer.error,
        candidateLoading: state.candidateReducer.loading,
        candidateReports: state.candidateReducer.candidateReports,

        candidateAnswers: state.gradeReducer.candidateAnswers,
        loading:state.gradeReducer.loading

    };}

const mapActionsToProps = {
    getQuestionContent: questionActions.getQuestionContent,
    addQuestions: questionActions.addQuestions,
    viewAddQuestions: questionActions.viewAddQuestions,
    getAssessmentQuestions: questionActions.getAssessmentQuestions,
    getAllQuestionContents: questionActions.getAllQuestionContents,
    getAllAnswerContents: questionActions.getAllAnswerContents,
    getAllQuestions: questionActions.getAllQuestions,
    refreshforAddingQuestions: questionActions.refreshforAddingQuestions,
    getCandidateAnswers: assessorActions.getCandidateAnswers,
    getAllCandidateAnswerContents: assessorActions.getAllCandidateAnswerContents,
    getRemarks: assessorActions.getRemarks,
    finalizeAll: assessorActions.finalizeAll,
    deleteCandidate: candidateActions.deleteCandidate,
    getCandidateReports: candidateActions.getCandidateReports,
    createStudentUserMap: studentUserActions.createStudentUserMap,
    getStudentUsers: studentUserActions.getAllStudentUsers,
    getAllStudents: manageUserActions.getAllStudents
}

export default connect(mapStateToProps, mapActionsToProps)(GradeReport);