import React, { Component } from "react";
import { history } from "../../../helper/history.js";
import { AzureStorageManage } from "../../../helper/azureStorageManage.js";
import config from "../../../helper/config.js";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import CardBody from "../../../components/common/CardBody.js";
import { Row, Spinner, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { Button } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import Col from "react-bootstrap/Col";
import { EditorState } from "draft-js";
import { namespace } from "../../../helper/namespace.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { StudentDocumentService } from "../../../service/manage/lms/document_service.js";
import { DeadlineService } from "../../../service/manage/lms/deadline_service.js";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import { customerConstants } from "../../../helper/constants.js";
import TimeZoneHandler from "../../../handlers/TimeZoneHandler.js";
import CheckBox from "../../../components/common/form/CheckBox.js";
import CheckBoxInline from "../../../components/common/form/CheckBoxInline.js";
import {
  sweetStatusAlert,
  sweetSuccessAlert,
} from "../../../components/common/SweetAlert.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default";
    confirmClass = "btn-primary";
    break;
  case customerConstants.EXO_CUSTOMER2:
  // cancelClass = "cmc-btn-default"
  // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default";
    confirmClass = "btn-primary";
    break;
}

class ManageStudentDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      uploadedFile: null,
      errors: {},
      getContent: true,
      questions: "",
      assessmentQuestions: "",
      currentQuestion: 0,
      viewAllQuestions: false,
      ans: EditorState.createEmpty(),
      needToRevise: 0,
      answeredNum: 0,
      submissionState: false,
      loadSubmissions: true,
      finishState: false,
      timeUp: false,
      submit: false,
      setAnswers: false,
      fileUpload: false,
      filesUploaded: [],
      answeredQuestions: [],
      revisedQuestions: [],
      isConnected: window.navigator.onLine,
      auto_shuffle: false,
      node_id: "",
      manage_user_id: "",
      user_table_id: "",
      documents: [],
      loading: false,
      userType: null,
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      viewVisibility: false,
      breadcrumb2: null,
      nodeTitle: null,
      selectedFiles: [],
      editEndDateModal: false,
      addEndDateModal: false,
      updated_end_date: "",
      end_date: {},
      loadingModal: false,
      deadline_id: null,
    };
  }

  componentDidMount() {
    this.loadInitialData();

    const {
      flg_list_active,
      privilege_view,
      privilege_add,
      privilege_edit,
      privilege_delete,
    } = this.props.state;

    this.getVisibilityCheck(
      privilege_view,
      privilege_add,
      privilege_delete,
      privilege_edit
    );
    let userType = sessionStorage.getItem("userType");
    this.setState({
      userType,
      breadcrumb2: this.props.state.breadcrumb2,
      nodeTitle: this.props.state.breadcrumb2,
    });
  }

  getVisibilityCheck(
    privilege_view,
    privilege_add,
    privilege_delete,
    privilege_edit
  ) {
    if (privilege_add == 1) {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_edit == 1) {
      this.setState({
        editVisibility: true,
      });
    }
    if (privilege_view == 1) {
      this.setState({
        viewVisibility: true,
      });
    }
    if (privilege_delete == 1) {
      this.setState({
        deleteVisibility: true,
      });
    }
  }

  loadInitialData() {
    this.setState({ loading: true });

    this.getDocumentsByContainerId();

    let node_mid_actual =
      this.props.state.node_mid_actual !== null
        ? this.props.state.node_mid_actual
        : this.props.state.mid;

    this.setState({
      node_id: node_mid_actual,
      manage_user_id: sessionStorage.getItem("manage_user_id"),
      user_table_id: sessionStorage.getItem("table_log_id"),
    });
  }

  getDocumentsByContainerId() {
    const {
      owner_restriction,
      lg_user_id,
      lg_user_table_id,
      user_list_restriction,
    } = this.props.state;

    let create_user_id =
      !!user_list_restriction && lg_user_id ? lg_user_id : "null";
    let create_user_table_id =
      !!user_list_restriction && lg_user_table_id ? lg_user_table_id : "null";
    const NodeId =
      this.props.state.node_mid_actual !== null
        ? this.props.state.node_mid_actual
        : this.props.state.mid;

    const form_list = this.props.state.form_list_id;
    const containerId = `${NodeId}_${form_list}`
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "-")
      .replace(/^-+|-+$/g, "");

    this.setState({ loading: true });

    StudentDocumentService.getDocsByContainerId(
      containerId,
      create_user_id,
      create_user_table_id
    )
      .then((docs) => {
        this.setState({ documents: docs.data });
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
      });

    DeadlineService.getDeadlineByContainerId(containerId)
      .then((res) => {
        this.setState({
          end_date: res.data,
          deadline_id: res.data?.deadline_id,
        });
      })
      .catch((error) => {
        console.error("Error getting deadlines:", error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  onFileChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  handleSelectAllFiles = (e) => {
    const { documents } = this.state;
    const allSelected = e.target.checked;
    const selectedFiles = allSelected
      ? documents.map((doc) => ({
          container_id: doc.container_id,
          document_name: doc.document_name,
        }))
      : [];
    this.setState({ allSelected, selectedFiles });
  };

  handleFileCheckboxChange = (e, document) => {
    const { selectedFiles } = this.state;
    const isChecked = e.target.checked;
    const updatedSelectedFiles = isChecked
      ? [
          ...selectedFiles,
          {
            container_id: document.container_id,
            document_name: document.document_name,
          },
        ]
      : selectedFiles.filter(
          (file) => file.document_name !== document.document_name
        );
    this.setState({ selectedFiles: updatedSelectedFiles });
  };

  toggleeditEndDateModal = () => {
    const { editEndDateModal, end_date } = this.state;
    this.setState({
      editEndDateModal: !editEndDateModal,
      updated_end_date: end_date?.end_date || "",
    });
  };

  toggleaddEndDateModal = () => {
    const { addEndDateModal, end_date } = this.state;

    // Check if end_date exists and is not an empty object
    const updatedEndDate =
      end_date && Object.keys(end_date).length !== 0
        ? end_date.end_date
        : new Date();

    this.setState({
      addEndDateModal: !addEndDateModal,
      updated_end_date: updatedEndDate,
    });
  };

  handleUpdate = async () => {
    try {
      this.setState({ loadingModal: true });
      const { updated_end_date, node_id, deadline_id } = this.state;

      const form_list = this.props.state.form_list_id;
      let containerId = `${node_id}_${form_list}`
        .toLowerCase()
        .replace(/[^a-z0-9-]/g, "-")
        .replace(/^-+|-+$/g, "");

      if (!updated_end_date) {
        sweetStatusAlert("Failed", "Please select a deadline date", "error");
        this.setState({ loadingModal: false });
        return;
      }
      const newDeadline = {
        end_date: updated_end_date,
        node_id: containerId,
      };

      const response = await DeadlineService.updateDeadline(
        newDeadline,
        deadline_id
      );
      if (response.success) {
        this.setState({ loadingModal: false });
        sweetSuccessAlert("Success", response.message.success, "success");
        this.getDocumentsByContainerId();
        this.toggleeditEndDateModal();
      } else {
        this.setState({ loadingModal: false });
        sweetSuccessAlert("Failed", response.message.error, "error");
      }
    } catch (error) {
      this.setState({ loadingModal: false });
      sweetStatusAlert("Failed", "Error updating deadline", "error");
    }
  };

  handleAdd = async () => {
    try {
      this.setState({ loadingModal: true });
      const { updated_end_date, node_id } = this.state;

      const form_list = this.props.state.form_list_id;
      let containerId = `${node_id}_${form_list}`
        .toLowerCase()
        .replace(/[^a-z0-9-]/g, "-")
        .replace(/^-+|-+$/g, "");

      if (!updated_end_date) {
        sweetStatusAlert("Failed", "Please select a deadline date", "error");
        this.setState({ loadingModal: false });
        return;
      }

      const newDeadline = {
        end_date: updated_end_date,
        node_id: containerId,
      };

      const response = await DeadlineService.addDeadline(newDeadline);
      if (response.success) {
        this.setState({ loadingModal: false });
        sweetSuccessAlert("Success", response.message.success, "success");
        this.getDocumentsByContainerId();
        this.toggleaddEndDateModal();
      } else {
        sweetSuccessAlert("Failed", response.message.error, "error");
        this.setState({ loadingModal: false });
      }
    } catch (error) {
      this.setState({ loadingModal: false });
      sweetStatusAlert(
        "Failed",
        error.message || "Error updating deadline",
        "error"
      );
    }
  };
  downloadSelectedFiles = async () => {
    this.setState({ loading: true });
    try {
      const { selectedFiles, documents } = this.state;
      await AzureStorageManage.downloadMultipleBlobs(
        selectedFiles[0].container_id,
        selectedFiles
      );
    } catch (error) {
      console.error("Error downloading files:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  async retrieveFile(containerName, blobName) {
    try {
      await AzureStorageManage.downloadBlob(containerName, blobName);
      // Use blobContent as needed (e.g., display/download the file)
    } catch (error) {
      console.error("Error retrieving file:", error);
    }
  }

  async updateFile(containerName, blobName, newBlobData, newBlobDataLength) {
    try {
      const updateResponse = await AzureStorageManage.updateBlobContent(
        containerName,
        blobName,
        newBlobData,
        newBlobDataLength
      );
      // Handle the update response as needed
    } catch (error) {
      console.error("Error updating file:", error);
    }
  }

  async deleteFile(containerName, blobName, document_id) {
    this.setState({ loading: true });
    try {
      await AzureStorageManage.deleteblob(containerName, blobName);
      await StudentDocumentService.deleteStudentDocument(document_id);
      window.Swal.fire({
        title: "Success!",
        text: `File ${blobName} deleted successfully!!!`,
        icon: "success",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement
              .querySelector(".swal2-confirm")
              .setAttribute("id", "confirmButtonId");
          }
        },
      });
      this.getDocumentsByContainerId();
      // File deleted successfully
    } catch (error) {
      console.error("Error deleting file:", error);
    } finally {
      this.setState({ loading: false });
    }
  }

  onFileUpload = async () => {
    const { selectedFile, node_id, manage_user_id, user_table_id } = this.state;

    // Check if selectedFile is undefined
    if (!selectedFile) {
      window.Swal.fire({
        title: namespace.FAILED,
        text: "No file selected!",
        icon: "error",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement
              .querySelector(".swal2-confirm")
              .setAttribute("id", "confirmButtonId");
          }
        },
      });
      return;
    }

    this.setState({ loading: true });

    const questionIndex = 200; // Replace with the actual question index

    const form_list = this.props.state.form_list_id;
    let container_id = `${node_id}_${form_list}`
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "-")
      .replace(/^-+|-+$/g, "");

    const name = selectedFile.name;
    const size = selectedFile.size;
    const self = this;

    if (size > config.fileUploadConfigs.maxFileSize) {
      window.Swal.fire({
        titl: namespace.FAILED,
        text: "File cannot be uploaded. Check the size of the file!",
        icon: "error",
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement
              .querySelector(".swal2-confirm")
              .setAttribute("id", "confirmButtonId");
          }
        },
      });
    } else {
      let filesUploaded = [...(self.state.filesUploaded || [])];
      let answers = [...(self.state.answers || [])];

      const currentFiles = [...(answers[questionIndex] || [])];

      if (!currentFiles.includes(name)) {
        currentFiles.push(name);
        filesUploaded.push(name);
        self.setState({ filesUploaded: filesUploaded });

        try {
          const text = await this.readFile(selectedFile);

          await AzureStorageManage.uploadBlob(container_id, name, text).then(
            () => {}
          );
          await StudentDocumentService.addStudentDocument({
            document_name: name,
            node_id: node_id,
            container_id: container_id,
            user_id: manage_user_id,
            user_table_id: user_table_id,
            create_user_id: manage_user_id,
            create_user_table_id: user_table_id,
          });
          window.Swal.fire({
            title: "Success!",
            text: `File ${name} uploaded successfully!!!`,
            icon: "success",
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement
                  .querySelector(".swal2-confirm")
                  .setAttribute("id", "confirmButtonId");
              }
            },
          });
          this.getDocumentsByContainerId();
        } catch (e) {
          currentFiles.pop();
          filesUploaded.pop();
        } finally {
          this.setState({ loading: false, selectedFile: null });
        }
      } else {
        window.SWal.fire({
          title: namespace.FAILED,
          text: "File names are similar. Delete the previous upload to add a new file with the same name!",
          icon: "error",
          customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
          },
          onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
              modalElement
                .querySelector(".swal2-confirm")
                .setAttribute("id", "confirmButtonId");
            }
          },
        });
      }
    }
  };

  // Helper function to read file as text
  readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event.target.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  formattedDateString = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString = `${day}-${month}-${year}`;
    return fromattedString;
  };

  handleDatePickerChange = (value, key) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);

    const hours = dateObject && dateObject.getHours(); // Get the hours (0-23)
    const minutes = dateObject && dateObject.getMinutes(); // Get the minutes (0-59)
    const seconds = dateObject && dateObject.getSeconds(); // Get the seconds (0-59)

    const formattedDateString = dateObject
      ? `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
      : "";

    this.setState(() => ({ [key]: formattedDateString }));
  };

  formattedTimeString = (value) => {
    const time = new Date(value);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  isUploadDisabled = () => {
    const { end_date } = this.state;

    if (end_date && end_date.end_date) {
      const endDate = new Date(end_date.end_date);
      const today = new Date();
      return endDate < today;
    }

    return false;
  };

  resetDatePicker = () => {
    // Reset the date picker
    this.setState({ updated_end_date: "" });
  };

  truncateFileName = (fileName) => {
    const maxLength = 20;
    if (fileName.length > maxLength) {
      return fileName.substring(0, maxLength) + "...";
    }
    return fileName;
  };

  render() {
    const {
      documents,
      loading,
      viewVisibility,
      addVisibility,
      editVisibility,
      deleteVisibility,
      selectedFile,
      breadcrumb2,
      nodeTitle,
      allSelected,
      selectedFiles,
      editEndDateModal,
      addEndDateModal,
      end_date,
    } = this.state;
    const path = history.location.pathname;
    const formattedBreadcrumb = breadcrumb2
      ? breadcrumb2.replaceAll(">", " > ")
      : "";

    const CustomInput = ({ value, onClick }) => (
      <input
        className="py-2 form-control"
        onClick={onClick}
        value={value}
        readOnly
        placeholder="Select date"
      />
    );
    return (
      <>
        <div
          style={{ overflow: "auto", height: "90vh", backgroundColor: "#fff" }}
        >
          <ContentWrapper disabled={path === "/manage-search"}>
            {this.props.state.path !== "node" && (
              <p
                className="pt-4 pl-4"
                style={{ fontSize: "10.5625px", color: "#00000099" }}
              >
                {formattedBreadcrumb
                  ? `${formattedBreadcrumb && formattedBreadcrumb}`
                  : ""}
              </p>
            )}
            <>
              <CardBody>
                {loading && (
                  <PageSpinner id="show" text="Loading, Please wait!" />
                )}

                <div className="row" style={{ marginBottom: "20px" }}>
                  {addVisibility && (
                    <>
                      <h3 className="col-md-12 px-0 card-title">Upload File</h3>
                      <div className="d-flex">
                        <div>
                          <div className="card">
                            <div className="file-upload-section">
                              <label
                                htmlFor="fileInput"
                                className="custom-file-label"
                              >
                                {selectedFile
                                  ? this.truncateFileName(selectedFile.name)
                                  : "Select a file"}
                              </label>

                              <div className="file-input-container">
                                <input
                                  type="file"
                                  id="fileInput"
                                  onChange={this.onFileChange}
                                  className="custom-file-input"
                                  style={{ width: "350px" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-2">
                          {!this.isUploadDisabled() ? (
                            <button
                              onClick={this.onFileUpload}
                              className="custom-upload-btn btn btn-primary"
                              // style={{ marginTop: "35px", marginLeft: "15px" }}
                            >
                              Upload
                            </button>
                          ) : (
                            <span
                              className="deadline-over-message"
                              style={{ color: "red", fontWeight: "bold" }}
                            >
                              The deadline for uploading files has passed.{" "}
                              <br /> Please contact support for further
                              assistance.
                            </span>
                          )}
                        </div>
                      </div>
                      &nbsp; &nbsp;
                      <div className="d-flex align-items-center mt-3">
                        <div className="mr-3">
                          <strong>Deadline:</strong>
                        </div>
                        {!this.isUploadDisabled() ? (
                          <div className="mr-3">
                            {end_date && JSON.stringify(end_date) !== "{}" ? (
                              <>
                                <span className="stu-variant-class label label-lg label-success label-inline">
                                  {this.formattedDateString(end_date.end_date)}
                                </span>{" "}
                                &nbsp;
                                <span className="stu-variant-class label label-lg label-success label-inline">
                                  {this.formattedTimeString(end_date.end_date)}
                                </span>
                              </>
                            ) : (
                              <span>No Deadline Set</span>
                            )}
                          </div>
                        ) : (
                          <div className="mr-3">
                            {end_date && JSON.stringify(end_date) !== "{}" ? (
                              <>
                                <span className="stu-variant-class label label-lg label-danger label-inline">
                                  {this.formattedDateString(end_date.end_date)}
                                </span>{" "}
                                &nbsp;
                                <span className="stu-variant-class label label-lg label-danger label-inline">
                                  {this.formattedTimeString(end_date.end_date)}
                                </span>
                              </>
                            ) : (
                              <span>No Deadline Set</span>
                            )}
                          </div>
                        )}

                        {(editVisibility || deleteVisibility) && (
                          <div className="mr-3">
                            {end_date && JSON.stringify(end_date) !== "{}" ? (
                              <Button
                                onClick={this.toggleeditEndDateModal}
                                className="btn btn-icon btn-primary btn-sm edit-assessment-btn flaticon-edit-icon"
                                title="Edit Deadline"
                              />
                            ) : (
                              <Button
                                onClick={this.toggleaddEndDateModal}
                                className="flaticon2-plus icon-sm mr-2"
                                title="Add Deadline"
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {/* <div className="row"> */}
                <h3>Uploaded Documents</h3>

                <button
                  onClick={this.downloadSelectedFiles}
                  className="btn btn-primary mb-3"
                  disabled={selectedFiles.length === 0}
                >
                  Download Selected Files
                </button>

                <Table style={{ overflowX: "auto" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="thead">
                        <CheckBoxInline>
                          <CheckBox
                            checked={allSelected}
                            onChange={this.handleSelectAllFiles}
                          />
                        </CheckBoxInline>
                      </TableCell>
                      <TableCell className="thead">Date & Time</TableCell>
                      <TableCell className="thead">File Name</TableCell>
                      <TableCell className="thead">Uploaded By</TableCell>
                      {addVisibility && (
                        <TableCell className="thead">Actions</TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {documents.map((document, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell className="tData">
                            <CheckBoxInline>
                              <CheckBox
                                checked={selectedFiles.some(
                                  (file) =>
                                    file.document_name ===
                                    document.document_name
                                )}
                                onChange={(e) =>
                                  this.handleFileCheckboxChange(e, document)
                                }
                              />
                            </CheckBoxInline>
                          </TableCell>
                          <TableCell className="tData">
                            <div>
                              {this.formattedDateString(document.created_at)
                                ? this.formattedDateString(
                                    TimeZoneHandler.convertTime(
                                      document.created_at
                                    )
                                  )
                                : undefined}
                            </div>
                            <div>
                              {this.formattedTimeString(document.created_at)
                                ? this.formattedTimeString(
                                    TimeZoneHandler.convertTime(
                                      document.created_at
                                    )
                                  )
                                : undefined}
                            </div>
                          </TableCell>

                          <TableCell className="tData">
                            {document.document_name}
                          </TableCell>

                          <TableCell className="tData">
                            {document.first_name +
                              " " +
                              document.last_name +
                              " [" +
                              document.user_code +
                              "]"}
                          </TableCell>
                          <TableCell className="tData">
                            <div className="btn-group">
                              {/* <Button
                                className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-download"
                                title="Download Details"
                                onClick={() =>
                                  this.retrieveFile(
                                    document.container_id,
                                    document.document_name
                                  )
                                }
                                style={{
                                  backgroundColor: "#F6F8FA",
                                  color: "#A2A5B5",
                                  border: "none",
                                }}
                              /> */}
                              {addVisibility && (
                                <Button
                                  className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                  title="Delete Details"
                                  onClick={() =>
                                    this.deleteFile(
                                      document.container_id,
                                      document.document_name,
                                      document.document_id
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#F6F8FA",
                                    color: "#A2A5B5",
                                    border: "none",
                                  }}
                                />
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Modal
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={editEndDateModal}
                  onHide={this.toggleeditEndDateModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Deadline</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    {this.state.loadingModal ? (
                      <Row className="justify-content-center align-items-center">
                        <Col xs={12} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </Col>
                      </Row>
                    ) : (
                      <Form>
                        <Row className="my-5">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>End Date</Form.Label>
                            {/* <div className="col-md-12 px-0"> */}

                            <DatePicker
                              name="updated_end_date"
                              selected={
                                this.state.updated_end_date
                                  ? new Date(this.state.updated_end_date)
                                  : undefined
                              }
                              onChange={(date) =>
                                this.handleDatePickerChange(
                                  date,
                                  "updated_end_date"
                                )
                              }
                              dateFormat="dd-MM-yyyy h:mm aa" // Set custom date format
                              placeholderText="Deadline"
                              wrapperClassName="react-datePicker"
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              // maxDate={(new Date(progress_details[progress_details.length - 1]?.end_date) > new Date()) ? new Date() :new Date(progress_details[progress_details.length - 1]?.end_date) }
                              minDate={
                                this.state.updated_end_date
                                  ? new Date(this.state.updated_end_date)
                                  : null
                              }
                              customInput={<CustomInput />}
                            />
                            <Button
                              className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                              title="Delete Feedback"
                              onClick={this.resetDatePicker}
                            />
                            {/* </div> */}
                          </Form.Group>
                        </Row>
                      </Form>
                    )}

                    <Modal.Footer>
                      <Button
                        sx={{ mr: "20px" }}
                        onClick={this.toggleeditEndDateModal}
                        className="modal-btn"
                        id="modal-close-button"
                      >
                        Close
                      </Button>
                      <Button
                        className="modal-btn"
                        variant="primary"
                        onClick={this.handleUpdate}
                      >
                        Save
                      </Button>
                    </Modal.Footer>
                  </Modal.Body>
                </Modal>
                <Modal
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={addEndDateModal}
                  onHide={this.toggleaddEndDateModal}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Add a Deadline</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    {this.state.loadingModal ? (
                      <Row className="justify-content-center align-items-center">
                        <Col xs={12} className="text-center">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </Col>
                      </Row>
                    ) : (
                      <Form>
                        <Row className="my-5">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>End Date</Form.Label>
                            {/* <div className="col-md-12 px-0"> */}
                            <DatePicker
                              className="py-2 form-control w-100 col-md-12"
                              name="updated_end_date"
                              selected={
                                this.state.updated_end_date
                                  ? new Date(this.state.updated_end_date)
                                  : undefined
                              }
                              onChange={(date) =>
                                this.handleDatePickerChange(
                                  date,
                                  "updated_end_date"
                                )
                              }
                              dateFormat="dd-MM-yyyy h:mm aa" // Set custom date format
                              placeholderText="Deadline"
                              wrapperClassName="react-datePicker"
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              // maxDate={(new Date(progress_details[progress_details.length - 1]?.end_date) > new Date()) ? new Date() :new Date(progress_details[progress_details.length - 1]?.end_date) }
                              minDate={
                                this.state.updated_end_date
                                  ? new Date(this.state.updated_end_date)
                                  : null
                              }
                              customInput={<CustomInput />}
                            />
                            <Button
                              className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                              title="Delete Feedback"
                              onClick={this.resetDatePicker}
                            />
                            {/* </div> */}
                          </Form.Group>
                        </Row>
                      </Form>
                    )}

                    <Modal.Footer>
                      <Button
                        sx={{ mr: "20px" }}
                        onClick={this.toggleaddEndDateModal}
                        className="modal-btn"
                        id="modal-close-button"
                      >
                        Close
                      </Button>
                      <Button
                        className="modal-btn"
                        variant="primary"
                        onClick={this.handleAdd}
                      >
                        Save
                      </Button>
                    </Modal.Footer>
                  </Modal.Body>
                </Modal>
                {/* </div> */}
              </CardBody>
            </>
          </ContentWrapper>
        </div>
      </>
    );
  }
}

export default ManageStudentDocument;
