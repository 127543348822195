import { createTypes } from '../../reducers/types';
import { createService } from '../../service/question/createService';
import { questionService } from '../../service/assessment/questionService';

export const createActions = {
    getQuestionTypes,
    getQuestionCategories,
    getAllQuestionCategories,
    getQuestionDifficulty,
    getQuestionLanguage,
    getQuestionSource,
    getQuestionSubject,
    getQuestionAuthor,
    getQuestionYear,
    getQuestionTerm,
    refreshPage,
    createCategory,
    selectCategory,
    selectType,
    selectDifficulty,
    selectLanguage,
    selectSource,
    selectSubject,
    selectAuthor,
    selectYear,
    selectTerm,
    getQuestionGrades,
    getQuestionChapters,
    getQuestionPrivilege,
    getQuestionById,
    getQuestionMetaData
};

function getQuestionDifficulty() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let difficulty = await createService.getQuestionDifficulty();
            dispatch(success(difficulty.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_DIFFICULTY }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_DIFFICULTY_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_DIFFICULTY_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_DIFFICULTY_FAILURE, error }
    }
}

function getQuestionLanguage() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let language = await createService.getQuestionLanguage();
            dispatch(success(language.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_LANGUAGE }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_LANGUAGE_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_LANGUAGE_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_LANGUAGE_FAILURE, error }
    }
}

function getQuestionById(id) {
    return async dispatch => {
        try {
            dispatch(request());
            let question = await createService.getQuestionById(id);
            dispatch(success(question.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request() {
        return { type: createTypes.GET_QUESTION_BY_ID }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_BY_ID_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_BY_ID_FAILURE, error }
    }
}

function getQuestionGrades() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let grade = await createService.getQuestionGrade();
            dispatch(success(grade.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_GRADE }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_GRADE_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_GRADE_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_GRADE_FAILURE, error }
    }
}

function getQuestionPrivilege() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let privilege = await questionService.getQuestionPrivilege();
            dispatch(success(privilege.data))
        } catch (error) {
            dispatch(failure(error.toString()))
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_PRIVILEGE }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_PRIVILEGE_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_PRIVILEGE_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_PRIVILEGE_FAILURE, error }
    }
}


function getQuestionChapters() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let chapters = await createService.getQuestionChapter();
            dispatch(success(chapters.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_CHAPTER }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_CHAPTER_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_CHAPTER_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_CHAPTER_FAILURE, error }
    }
}

function getQuestionTypes() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let qTypes = await createService.getQuestionTypes();
            dispatch(success(qTypes.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_TYPES }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_TYPES_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_TYPES_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_TYPES_FAILURE, error }
    }
}

function getQuestionSource() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let source = await createService.getQuestionSource();
            dispatch(success(source.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_SOURCE }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_SOURCE_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_SOURCE_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_SOURCE_FAILURE, error }
    }
}

function getQuestionSubject() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let subject = await createService.getQuestionSubject();
            dispatch(success(subject.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_SUBJECT }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_SUBJECT_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_SUBJECT_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_SUBJECT_FAILURE, error }
    }
}

function getQuestionAuthor() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let author = await createService.getQuestionAuthor();
            dispatch(success(author.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_AUTHOR }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_AUTHOR_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_AUTHOR_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_AUTHOR_FAILURE, error }
    }
}

function getQuestionYear() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let year = await createService.getQuestionYear();
            dispatch(success(year.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_YEAR }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_YEAR_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_YEAR_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_YEAR_FAILURE, error }
    }
}

function getQuestionTerm() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let term = await createService.getQuestionTerm();
            dispatch(success(term.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_TERM }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_TERM_REQUEST }
    }

    function success(types) {
        return { type: createTypes.GET_QUESTION_TERM_SUCCESS, types }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_TERM_FAILURE, error }
    }
}

function createCategory(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await createService.createCategory(obj);
            dispatch(success(res.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return { type: createTypes.CREATE_CATEGORY_REQUEST, data }
    }

    function success(categories) {
        return { type: createTypes.CREATE_CATEGORY_SUCCESS, categories }
    }

    function failure(error) {
        return { type: createTypes.CREATE_CATEGORY_FAILURE, error }
    }
}

function getQuestionCategories() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let categories = await createService.getQuestionCategories();
            dispatch(success(categories.data))
        } catch (error) {
            dispatch(failure(error.toString()))
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_QUESTION_CATEGORIES }
    }

    function request() {
        return { type: createTypes.GET_QUESTION_CATEGORIES_REQUEST }
    }

    function success(categories) {
        return { type: createTypes.GET_QUESTION_CATEGORIES_SUCCESS, categories }
    }

    function failure(error) {
        return { type: createTypes.GET_QUESTION_CATEGORIES_FAILURE, error }
    }
}

function getAllQuestionCategories(limit, offset, sort_column, sort_direction, search_term) {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let categories = await createService.getAllQuestionCategories(limit, offset, sort_column, sort_direction, search_term);
            dispatch(success(categories.data))
        } catch (error) {
            dispatch(failure(error.toString()))
        }
    };

    function clearExisting() {
        return { type: createTypes.GET_ALL_QUESTION_CATEGORIES }
    }

    function request() {
        return { type: createTypes.GET_ALL_QUESTION_CATEGORIES_REQUEST }
    }

    function success(categories) {
        return { type: createTypes.GET_ALL_QUESTION_CATEGORIES_SUCCESS, categories }
    }

    function failure(error) {
        return { type: createTypes.GET_ALL_QUESTION_CATEGORIES_FAILURE, error }
    }
}

function selectCategory(id) {
    return dispatch => {
        dispatch(category_select(id));
    };

    function category_select(data) {
        return { type: createTypes.SELECT_QUESTION_CATEGORY, data }
    }
}

function selectType(id) {
    return dispatch => {
        dispatch(type_select(id));
    };

    function type_select(data) {
        return { type: createTypes.SELECT_QUESTION_TYPE, data }
    }
}

function selectDifficulty(id) {
    return dispatch => {
        dispatch(difficulty_select(id));
    };

    function difficulty_select(data) {
        return { type: createTypes.SELECT_QUESTION_DIFFICULTY, data }
    }
}

function selectLanguage(id) {
    return dispatch => {
        dispatch(language_select(id));
    };

    function language_select(data) {
        return { type: createTypes.SELECT_QUESTION_LANGUAGE, data }
    }
}

function selectSource(id) {
    return dispatch => {
        dispatch(source_select(id));
    };

    function source_select(data) {
        return { type: createTypes.SELECT_QUESTION_SOURCE, data }
    }
}

function selectSubject(id) {
    return dispatch => {
        dispatch(subject_select(id));
    };

    function subject_select(data) {
        return { type: createTypes.SELECT_QUESTION_SUBJECT, data }
    }
}

function selectAuthor(id) {
    return dispatch => {
        dispatch(author_select(id));
    };

    function author_select(data) {
        return { type: createTypes.SELECT_QUESTION_AUTHOR, data }
    }
}

function selectYear(id) {
    return dispatch => {
        dispatch(year_select(id));
    };

    function year_select(data) {
        return { type: createTypes.SELECT_QUESTION_YEAR, data }
    }
}

function selectTerm(id) {
    return dispatch => {
        dispatch(term_select(id));
    };

    function term_select(data) {
        return { type: createTypes.SELECT_QUESTION_TERM, data }
    }
}

function refreshPage() {
    return dispatch => {
        dispatch(clearExisting());
    };

    function clearExisting() {
        return { type: createTypes.CREATE_PAGE_REFRESH }
    }

}

function getQuestionMetaData(obj) {
    return async dispatch => {
        try {
            dispatch(get_question_meta_data());
            dispatch(get_question_meta_data_request());
            var data = await createService.getAllQuestionMetaData(obj);
            dispatch(get_question_meta_data_success(data.data))
        } catch (error) {
            dispatch(get_question_meta_data_failure(error))
        }
    }

    function get_question_meta_data() {
        return { type: createTypes.GET_QUESTION_META_DATA }
    }

    function get_question_meta_data_request() {
        return { type: createTypes.GET_QUESTION_META_DATA_REQUEST}
    }

    function get_question_meta_data_success(data) {
        return { type: createTypes.GET_QUESTION_META_DATA_SUCCESS, data }
    }

    function get_question_meta_data_failure(error) {
        return { type: createTypes.GET_QUESTION_META_DATA_FAILURE, error }
    }
}