import React, { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Dropdown } from "react-bootstrap";
import "./drillchart.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const GroupedBarChart = (props) => {

  const [monthBasedData, setMonthBasedData] = useState(undefined);
  const [selectedData, setSelectedData] = useState([]);
  useEffect(() => {
    fetchSuitableReceivable()
  }, [props.val])

  const fetchSuitableReceivable = () => {
    const groupedData = props.val.reduce((acc, obj) => {
      const key = `${obj.flg_chart_legend_x_filter}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    if (groupedData) {
      const filteredGroupedData = Object.values(groupedData)
      setMonthBasedData(filteredGroupedData)
      setSelectedData(filteredGroupedData[0])
    }
  }

  const groupedData = selectedData?.reduce((acc, obj) => {
    const key = `${obj.chart_x_value}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
  const arrays = Object.values(groupedData);
  const colorArray = [[75, 192, 192], [153, 102, 255], [102, 253, 255], [95, 192, 212], [133, 82, 235]]
  const datasets = arrays?.map((data, i) => {

    return {
      label: data[0].chart_x_value,
      data: data.map(val => val.chart_y_value),
      backgroundColor: `rgba(${colorArray[i][0]}, ${colorArray[i][1]}, ${colorArray[i][2]}, 0.2)`,
      borderColor: `rgba(${colorArray[i][0]}, ${colorArray[i][1]}, ${colorArray[i][1]}, 1)`,
      borderWidth: 1,
    }
  })
  const labels = selectedData?.map(val => val.chart_legend_y_title)
  const uniqueLabels = [...new Set(labels)]
  const data = {
    labels: uniqueLabels,
    datasets: datasets
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: props.val[0].chart_title,
      },
    },
  }

  return (
    <div className="w-100 groupedBarchart">

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Dropdown>
          <Dropdown.Toggle
            className="dropdownToggle"
            variant="primary"
            style={{
              width: "50vw",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#fff",
              border: "1px solid #E4E6EF",
              color: "var(--secondary-text-color)",
              fontWeight: "400",
              fontSize: "10px"
            }}

          >
            {selectedData && selectedData[0]?.flg_chart_legend_x_filter}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100">
            {monthBasedData && monthBasedData.map(obj => (
              <Dropdown.Item
                onClick={() => { setSelectedData(obj) }}
              >
                {obj[0].flg_chart_legend_x_filter}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div style={{ height: "80vh", marginBottom: "35px", width: '70vw' }}>
        <Bar data={data} options={options} className="multiBar" />
      </div>
    </div>
  )
}

export default GroupedBarChart

