import React, { useEffect } from 'react';

export default function TabPane ({ children, id, ariaLabelledby, className}) {

    return (
                    
        <div className={className} id={id} aria-labelledby={ariaLabelledby}>
            {children}
        </div>
        
    )
}


