import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { StudentAddressTypeService } from "../../../service/settings/manage/student_address_type.service.js";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import "../../../components/admin/css/common.css";
import sweetAlert, {
  sweetStatusAlert,
} from "../../../components/common/SweetAlert";
import SimpleReactValidator from "simple-react-validator";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import ContentWrapper from "../../../components/common/ContentWrapper";
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class ManageAddressType extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
      showInsertModal: false,
      showModal: false,
      studentAddressTypeId: "",
      typeName: "",
      typeDescription: "",
      studentAddressTypes: [],
      isLoading: false,
      isTableDataLoading: true,
      isModified: false,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      totalAddressTypes: 10,
      currentPage: 0,
      editVisibility: true,
      addVisibility: true,
      deleteVisibility: true,
      status: "",
      statuses: [
        {
          id: "Active",
          name: "Active",
        },
        {
          id: "Deactive",
          name: "Deactive",
        },
      ],
    };

    // Debounced version of handleSearch function
    this.debouncedHandleSearch = debounce(this.callAfterDebounce, 2000);
  }

  resetFormFields = () => {
    this.setState({
      typeName: "",
      typeDescription: "",
    });
  };

  callAfterDebounce() {
    this.fetchAddressTypesData();
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        address: {
          message: "The :attribute must be a valid address",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/:0-9\s]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        name: {
          message: "The :attribute must have valid character",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/\s.,0-9]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
      },
    });
  }

  fetchAddressTypesData() {
    const { limit, offset, search_term } = this.state;
    StudentAddressTypeService.getAll(limit, offset, search_term)
      .then((response) => {
        if (response.success) {
          this.setState({
            totalAddressTypes: parseInt(response.data.total_count),
            studentAddressTypes: response.data.student_address_types,
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Address Types. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        sweetStatusAlert(
          "Failed!",
          "Error fetching Address Types. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  }

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAddressTypesData();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.fetchAddressTypesData();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAddressTypesData();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.hideMessages();
        this.validator.purgeFields();
        this.resetFormFields();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.hideMessages();
      this.validator.purgeFields();
      this.resetFormFields();
      toggleFunction();
    }
  };

  handleSubmit = async (event) => {
    if (!this.validator.fieldValid('typeDescription') && !this.validator.fieldValid('typeName')) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });

      const { typeName, typeDescription } = this.state;
      StudentAddressTypeService.create(typeName, typeDescription)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.success) {
            this.setState({ showInsertModal: false });
            this.fetchAddressTypesData();
            sweetStatusAlert("Success!", response.message, "success");
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed !",
            "Unable to create new student type ",
            "error"
          );
        });
    }
  };

  handleEditSubmit = (event) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });

      const { studentAddressTypeId, typeName, typeDescription, status } =
        this.state;
      StudentAddressTypeService.update(
        studentAddressTypeId,
        typeName,
        typeDescription,
        status
      )
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.success) {
            this.setState({ isLoading: false, showModal: false });
            this.fetchAddressTypesData();
            sweetStatusAlert("Success!", response.message, "success");
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed !",
            "Unable to update the student address type",
            "error"
          );
        });
    }
  };

  handleDelete = (studentAddressTypeId) => {
    sweetAlert(
      "Are you sure",
      "Do you want to delete this address type?",
      "warning"
    ).then((value) => {
      if (!value) {
        this.setState({ isLoading: true });
        StudentAddressTypeService.deleteType(studentAddressTypeId)
          .then((response) => {
            this.setState({ isLoading: false });
            if (response.success) {
              this.fetchAddressTypesData();
              sweetStatusAlert("Success!", response.message, "success");
            } else {
              sweetStatusAlert("Failed!", response.message, "error");
            }
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            sweetStatusAlert(
              "Failed !",
              "Unable to delete the student address type",
              "error"
            );
          });
      }
    });
  };

  componentDidMount() {
    this.fetchAddressTypesData();
  }

  toggleAddAddressTypeModal = () => {
    this.setState((prevState) => ({
      showInsertModal: !prevState.showInsertModal,
    }));
  };

  toggleUpdateAddressTypeModal = (
    studentAddressTypeId,
    typeName,
    typeDescription,
    status
  ) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      studentAddressTypeId: studentAddressTypeId,
      typeName: typeName,
      typeDescription: typeDescription,
      status: status,
    }));
  };

  toggleDeleteAddressTypeModal = (studentAddressTypeId) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      studentAddressTypeId: studentAddressTypeId,
    }));
  };

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { studentAddressTypes } = this.state;
    const reorderedAddressTypes = Array.from(studentAddressTypes);

    // Remove the dragged item from the array
    const [removed] = reorderedAddressTypes.splice(result.source.index, 1);

    // Insert the dragged item at the new index
    reorderedAddressTypes.splice(result.destination.index, 0, removed);

    // Update the display_order property based on the new index
    reorderedAddressTypes.forEach((type, index) => {
      type.display_order = index + 1; // Assuming display order starts from 1
    });

    // Update state with the new order
    this.setState({ studentAddressTypes: reorderedAddressTypes });

    StudentAddressTypeService.saveDisplayOrder(reorderedAddressTypes);
  };

  render() {
    const {
      limit,
      studentAddressTypes,
      totalAddressTypes,
      isLoading,
      isTableDataLoading,
      currentPage,
      editVisibility,
      addVisibility,
      deleteVisibility,
      status,
      statuses,
    } = this.state;

    return (
      <>
        <ContentWrapper>
          <div className="row justify-content-between align-items-center py-5 mb-3">
            <div className="col-md-6">
              <h3 className="text-nowrap">Manage Address Type</h3>
            </div>
            {addVisibility && (
              <Button
                className="insert-btn"
                variant="primary"
                onClick={this.toggleAddAddressTypeModal}
              >
                Add Type
              </Button>
            )}
          </div>
          <div>
            <Form.Group controlId="exampleForm.ControlInput1" className="mb-2">
              <Form.Control
                type="text"
                placeholder="Search"
                onChange={this.handleSearch}
                isInvalid={this.state.searchError}
                // style={{ width: "18%" }}
                className="col-md-4 col-12"
              />
              {this.state.searchError && (
                <Form.Control.Feedback type="invalid">
                  Invalid input
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div
                className="loading-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "75vw",
                  height: "90vh",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : studentAddressTypes.length > 0 ? (
              <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="addressTypes">
                    {(provided) => (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className="thead">
                              Address Type Name
                            </TableCell>
                            <TableCell className="thead">Description</TableCell>
                            <TableCell className="thead">Status</TableCell>
                            {(deleteVisibility || editVisibility) && (
                              <TableCell className="thead">Actions</TableCell>
                            )}
                          </TableRow>
                        </TableHead>

                        <TableBody
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {studentAddressTypes.map((type, index) => (
                            <Draggable
                              key={type.student_address_type_id}
                              draggableId={type.student_address_type_id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <TableRow
                                  key={type.student_address_type_id}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TableCell className="tData">
                                    {type.name}
                                  </TableCell>
                                  <TableCell className="tData">
                                    {type.description}
                                  </TableCell>
                                  <TableCell className="tData">
                                    {type.status}
                                  </TableCell>
                                  {(deleteVisibility || editVisibility) && (
                                    <TableCell className="btn-group">
                                      <div className="btn-group">
                                        {editVisibility && (
                                          <Button
                                            onClick={() =>
                                              this.toggleUpdateAddressTypeModal(
                                                type.student_address_type_id,
                                                type.name,
                                                type.description,
                                                type.status
                                              )
                                            }
                                            className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                            title="Edit Details"
                                          />
                                        )}
                                        {deleteVisibility && (
                                          <Button
                                            onClick={() =>
                                              this.handleDelete(
                                                type.student_address_type_id
                                              )
                                            }
                                            className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                                            title="Delete"
                                          />
                                        )}
                                      </div>
                                    </TableCell>
                                  )}
                                </TableRow>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </TableBody>
                      </Table>
                    )}
                  </Droppable>
                </DragDropContext>
                <MaterialCustomTablePagination
                  totalCount = {parseInt(totalAddressTypes)}
                  currentPage = {currentPage}
                  limit = {limit}
                  handlePageChange = {this.handlePageChange}
                  handleRowsPerPageChange = {this.handleRowsPerPageChange}
                  handlePageOffsetChange = {this.handlePageOffsetChange}
                  rowsPerPageOptions={[5, 10, 20]}
                  offset = {this.state.offset}
                />
              </div>
            ) : (
              <div
                className="text-center mb-5 mt-10"
                style={{ height: "90vh" }}
              >
                <label>No Address Types Available!</label>
              </div>
            )}
          </div>
        </ContentWrapper>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() =>
            this.handleSweetAlert(
              this.toggleAddAddressTypeModal,
              "showInsertModal"
            )
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Address Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address Type Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type Name"
                    name="typeName"
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "typeName",
                    this.state.typeName,
                    "required|alpha_space|max:30",
                    {
                      className: "text-danger",
                      messages: {
                        max: "The address type should not be greater than 30 characters",
                        alpha_space:
                          "The address type must have valid characters.",
                        required: "The address type field is required",
                      },
                    }
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address Type Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type Description"
                    name="typeDescription"
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "typeDescription",
                    this.state.typeDescription,
                    "alpha_space|max:100",
                    {
                      className: "text-danger",
                      messages: {
                        max: "The address type description should not be greater than 100 characters",
                        alpha_space:
                          "The address type description must have valid characters.",
                      },
                    }
                  )}
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              style={addBtnStyle}
              onClick={this.handleSubmit}
            >
              Save changes
            </Button>
            <Button
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={() =>
                this.handleSweetAlert(
                  this.toggleAddAddressTypeModal,
                  "showInsertModal"
                )
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={() =>
            this.handleSweetAlert(
              this.toggleUpdateAddressTypeModal,
              "showModal"
            )
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Address Type Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address Type Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type Name"
                    defaultValue={this.state.typeName}
                    name="typeName"
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "typeName",
                    this.state.typeName,
                    "required|alpha_space|max:30",
                    {
                      className: "text-danger",
                      messages: {
                        max: "The address type should not be greater than 30 characters",
                        alpha_space:
                          "The address type must have valid characters.",
                        required: "The address type field is required",
                      },
                    }
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address Type Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type Description"
                    defaultValue={this.state.typeDescription}
                    name="typeDescription"
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "typeDescription",
                    this.state.typeDescription,
                    "alpha_space|max:100",
                    {
                      className: "text-danger",
                      messages: {
                        max: "The address type description should not be greater than 100 characters",
                        alpha_space:
                          "The address type description must have valid characters.",
                      },
                    }
                  )}
                </Form.Group>
              </Row>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Status*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="status"
                    onChange={this.handleChange}
                    value={status}
                  >
                    <option value="">Select</option>
                    {statuses.map((singleStatus) => (
                      <option key={singleStatus.id} value={singleStatus.name}>
                        {singleStatus.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("status", status, "required")}
                  </div>
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="modal-btn"
              variant="primary"
              style={addBtnStyle}
              onClick={this.handleEditSubmit}
            >
              Save changes
            </Button>
            <Button
              className="modal-btn"
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={() =>
                this.handleSweetAlert(
                  this.toggleUpdateAddressTypeModal,
                  "showModal"
                )
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

