import React, { Component } from 'react'
import edsiderlogowhite from '../../../assets/images/edsiderlogowhite.png';
import admin from '../../../assets/images/admin-card-img.png';
import {history} from "../../../helper/history";
import { routes } from '../../../helper/routes';

export default class AdminLandingPage extends Component {
  handleRoleSelect = (role) => {
    sessionStorage.setItem('user_role_selection', role);
    history.push(routes.auth_response)
  }
  render() {
    
    return (
      <>
        <div className='landing-bg'>
          <div className='container pb-5'>
            <div className='row py-5'>
                <img className='logo-img' src={edsiderlogowhite} alt="Logo" />
            </div>
            <div className='row pt-4 d-flex justify-content-center'>
                <center>
                  <h1 className='landing-topic'>JOIN WITH US</h1>
                  <p className='landing-para mt-5'>Manage Online Exams From Wherever You Are</p>
                </center>
            </div>
            <div className='row py-5 d-flex justify-content-center'>
              <div className='col-lg-4 col-sm-6 col-xs-12'>
                <center>
                  <div className="card landing-card mt-3">
                    <img class="card-img-top" src={admin} alt="student-img" /> 
                    <div className="card-body">
                      <center>
                        <button type="button" className="btn btn-outline-dark landing-btn my-2" onClick={() => this.handleRoleSelect('admin')}>LOGIN</button>
                      </center>
                    </div>
                  </div>
                </center>
              </div>
            </div>  
          </div>
        </div>
      </>
    )
  }
}

