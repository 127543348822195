import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const GradeService = {
    addGrade,
    getGrades,
    deleteGrade,
    updateGrade,
    getGradesWithLimit
  };

async function addGrade(grade) {
    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(grade),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/grades/add`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getGrades() {
    const requestOptions = {
      method: "GET",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/grades/getAll/`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getGradesWithLimit() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/grades/getAll-with-limit/`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteGrade(id){

    const configOptions = {
        method: "DELETE",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        };

        const response = await fetch(
        `${config.oat_api_url}/manage/grades/delete-grade/${id}`,
        configOptions
        );
        return APIHandler.handleResponse(response);

}

async function updateGrade(updateGrade , id){

    const requestOptions = {
        method: "PUT",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(updateGrade),
      };
      const response = await fetch(
        `${config.oat_api_url}/manage/grades/update/${id}`,
        requestOptions
      );
      return APIHandler.handleResponse(response);
}

