import React from "react";

export default function ModalBody({ children }) {

    return (
        
        <div className="modal-body">
            {children}
        </div>

    )
}