import React from 'react';

export default function Card({ children }) {
    return (

        // <div className="card card-custom card-sticky" id="kt_page_sticky_card">
        <div className="card card-custom" id="">
            {children}
        </div>

    )
}