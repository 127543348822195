import React, { Component } from "react"
import logo from "../../../assets/images/cgc-logo.png"
import admin from "../../../assets/images/admin-card-img.png"
import teacher from "../../../assets/images/teacher-card-img.png"
import student from "../../../assets/images/student-card-img.png"
import "../edfoci/LandingPage.css"
import "./LandingPage.css"
import { history } from "../../../helper/history"
import { routes } from "../../../helper/routes"
export default class LandingPage extends Component {
  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role)
    history.push(routes.auth_response)
  }
  render() {
    return (
      <>
        <div className="">
          <div className="royal-landing-bg pb-5">
            <div className="row py-5 ml-10">
              <img className="royal-logo-img" src={logo} alt="Logo" />
            </div>
            <div className="row pt-4 ml-0 mr-0 justify-content-center">
              <h1 className="col-md-12 d-flex justify-content-center landing-topic">
                Welcome!
              </h1>
              <p className="col-md-12 d-flex justify-content-center landing-para">
                Sign In to continue to your account
              </p>
              <span className="d-flex justify-content-center underline-container">
                <div className="landing-para-underline"></div>
              </span>
            </div>
            <div className="row py-5 pt-4">
              <div className="col-md-4 d-flex justify-content-center">
                <div className="card landing-card mt-3">
                  <img className="card-img-top landing-card-img" src={admin} alt="admin-img" />
                  <div className="landing-card-body">
                    <center>
                      <button
                        type="button"
                        className="btn landing-btn my-2"
                        onClick={() => this.handleRoleSelect("admin")}
                      >
                        As an Administrator
                      </button>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center">
                <div className="card landing-card mt-3">
                  <img
                    className="card-img-top landing-card-img"
                    src={teacher}
                    alt="teacher-img"
                  />
                  <div className="landing-card-body">
                    <center>
                      <button
                        type="button"
                        className="btn landing-btn my-2"
                        onClick={() => this.handleRoleSelect("teacher")}
                      >
                        As a Teacher
                      </button>
                    </center>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center">
                <div className="card landing-card mt-3">
                  <img
                    className="card-img-top landing-card-img"
                    src={student}
                    alt="student-img"
                  />
                  <div className="landing-card-body">
                    <center>
                      <button
                        type="button"
                        className="btn landing-btn my-2"
                        onClick={() => this.handleRoleSelect("student")}
                      >
                        As a Student
                      </button>
                    </center>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5 d-flex justify-content-center">
              <p className="text-white text-center">
                © 2024 All Rights Reserved. Designed and Developed by Infinity
                Innovators (Pvt) Ltd
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
