import { act } from "react-test-renderer";
import { libraryConstants } from "../../helper/constants";

const initialState = {
  loading: false,
  error: "",
  success: "",
  text: "",
  assessment_list: [],
  child_assessment_list: [],
  node_assessment_list: [],
  enrolled_node_exams_list: [],
  enrolled_child_node_exams_list: [],
  enrolled_node_exams_list_for_user: [],
  assessment: "",
  report: "",
  report_loading: false,
  assessment_loading: false,
  assessmentDetail: "",
  status: 0,
  totalEnrolledPapers: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case libraryConstants.LIST_ASSESSMENTS:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.LIST_ASSESSMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.LIST_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Assessments Loaded Successfully!",
        assessment_list: action.data.exam_list,
      };
    case libraryConstants.LIST_ASSESSMENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case libraryConstants.LIST_CHILD_ASSESSMENTS:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.LIST_CHILD_ASSESSMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.LIST_CHILD_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Child Assessments Loaded Successfully!",
        child_assessment_list: action.data.child_exam_list,
      };
    case libraryConstants.LIST_CHILD_ASSESSMENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case libraryConstants.LIST_NODE_ASSESSMENTS:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.LIST_NODE_ASSESSMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.LIST_NODE_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Node Assessments Loaded Successfully!",
        node_assessment_list: action.node_assessments.data,
      };
    case libraryConstants.LIST_NODE_ASSESSMENTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case libraryConstants.GET_ENROLLED_NODE_EXAMS:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.GET_ENROLLED_NODE_EXAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.GET_ENROLLED_NODE_EXAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Enrolled Node Exams Loaded Successfully!",
        enrolled_node_exams_list: action.data.enrolled_node_exams_list,
        totalEnrolledPapers: action.data.total_count,
      };
    case libraryConstants.GET_ENROLLED_NODE_EXAMS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case libraryConstants.GET_CHILD_ENROLLED_NODE_EXAMS:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.GET_CHILD_ENROLLED_NODE_EXAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.GET_CHILD_ENROLLED_NODE_EXAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: "Enrolled Child Node Exams Loaded Successfully!",
        enrolled_child_node_exams_list:
          action.data.enrolled_node_exams_list,
        status: action.data.status_code,
        totalEnrolledPapers: action.data.total_count,
      };
    case libraryConstants.GET_CHILD_ENROLLED_NODE_EXAMS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case libraryConstants.GET_ENROLLED_NODE_EXAMS_FOR_USER:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.GET_ENROLLED_NODE_EXAMS_REQUEST_FOR_USER:
      return {
        ...state,
        loading: true,
      };
    case libraryConstants.GET_ENROLLED_NODE_EXAMS_SUCCESS_FOR_USER:
      return {
        ...state,
        loading: false,
        success: "Enrolled Node exams for user Loaded Successfully!",
        enrolled_node_exams_list_for_user:
          action.data.enrolled_node_exams_list,
        status: action.data.status_code,
        totalEnrolledPapers: action.data.total_count,
      };  
    case libraryConstants.GET_ENROLLED_NODE_EXAMS_FAILURE_FOR_USER:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case libraryConstants.OPEN_ASSESSMENT:
      return {
        ...state,
        assessment: action.assessment,
        success: "",
        error: "",
      };
    case libraryConstants.GET_ASSESSMENT:
      return {
        ...state,
        assessment_loading: true,
      };
    case libraryConstants.GET_ASSESSMENT_REQUEST:
      return {
        ...state,
        assessment_loading: true,
      };
    case libraryConstants.GET_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessment_loading: false,
        success: "Assessment Loaded Successfully!",
        assessmentDetail: action.data.assessment,
      };
    case libraryConstants.GET_ASSESSMENT_FAILURE:
      return {
        ...state,
        error: action.error,
        assessment_loading: false,
      };
    case libraryConstants.OPEN_ASSESSMENT:
      return {
        ...state,
        assessment: action.assessment,
        success: "",
        error: "",
      };
    case libraryConstants.UPDATE_CANDIDATE_STATUS_REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case libraryConstants.UPDATE_CANDIDATE_STATUS_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case libraryConstants.UPDATE_CANDIDATE_STATUS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case libraryConstants.GET_REPORT:
      return {
        ...state,
        assessment: {...state.assessment, ...action.assessment},
        report: "",
        error: "",
        success: "",
        report_loading: false,
      };
    case libraryConstants.GET_REPORT_REQUEST:
      return {
        ...state,
        report_loading: true,
      };
    case libraryConstants.GET_REPORT_SUCCESS:
      return {
        ...state,
        report_loading: false,
        success: "Assessment report loaded Successfully!",
        report: action.data.assessment,
      };
    case libraryConstants.GET_REPORT_FAILURE:
      return {
        ...state,
        error: action.error,
        report_loading: false,
      };
    default:
      return state;
  }
}
