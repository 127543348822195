import {descriptiveTypes} from '../../reducers/types';
import {descriptiveService} from '../../service/question/descriptiveService';

export const descriptiveActions = {
    refreshPage,
    createQuestion,
    createSubQuestion,
    createMainQuestion,
    saveAnswer,
    refreshAddAnswer
};


function createQuestion(obj) {
    return async dispatch => {
        
        dispatch(clearExisting());
        dispatch(request());

        await descriptiveService.saveQuestion(obj)
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function clearExisting() {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION}
    }

    function request() {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_REQUEST}
    }

    function success(question) {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_SUCCESS, question}
    }

    function failure(error) {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_FAILURE, error}
    }
}

function createSubQuestion(obj) {
    return async dispatch => {
        
        dispatch(clearExisting());
        dispatch(request());

        await descriptiveService.saveSubQuestion(obj)
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function clearExisting() {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION}
    }

    function request() {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_REQUEST}
    }

    function success(question) {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_SUCCESS, question}
    }

    function failure(error) {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_FAILURE, error}
    }
}

function createMainQuestion(obj) {
    return async dispatch => {
        
        dispatch(clearExisting());
        dispatch(request());

        await descriptiveService.saveMainQuestion(obj)
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function clearExisting() {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION}
    }

    function request() {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_REQUEST}
    }

    function success(question) {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_SUCCESS, question}
    }

    function failure(error) {
        return {type: descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_FAILURE, error}
    }
}


function saveAnswer(obj) {
    return async dispatch => {
        
        dispatch(request());

        await descriptiveService.saveAnswer(obj)
            .then(
                question => {
                    dispatch(success(question.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() {
        return {type: descriptiveTypes.ADD_ANSWER_REQUEST}
    }

    function success(question) {
        return {type: descriptiveTypes.ADD_ANSWER_SUCCESS, question}
    }

    function failure(error) {
        return {type: descriptiveTypes.ADD_ANSWER_FAILURE, error}
    }
}

function refreshAddAnswer() {
    return dispatch => {
        dispatch(clearExisting());
    };

    function clearExisting() {
        return {type: descriptiveTypes.ADD_ANSWER}
    }

}

function refreshPage() {
    return dispatch => {
        dispatch(clearExisting());
    };

    function clearExisting() {
        return {type: descriptiveTypes.DESCRIPTIVE_PAGE_REFRESH}
    }

}