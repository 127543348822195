import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const AdminDashboardService = {
    getStudentAndRegCount,
    getLatestAddedAssessment,
    deleteProgress
};

async function getStudentAndRegCount() {
    const configOptions = {
        method: "GET",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/registration/invitations/get-new-students-reg-count`,
        configOptions
    );
    return APIHandler.handleResponse(response);
}

async function getLatestAddedAssessment() {
    const configOptions = {
        method: "GET",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };
    const response = await fetch(
        `${config.oat_api_url}/assessments/get-latest-assesments`,
        configOptions
    );
    return APIHandler.handleResponse(response);
}


async function deleteProgress(id) {
    const configOptions = {
        method: "DELETE",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(
        `${config.oat_api_url}progress/delete/${id}`,
        configOptions
    );
    return APIHandler.handleResponse(response);
}

