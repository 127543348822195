import React, { useEffect , forwardRef} from "react"
import Card from "../../../components/common/Card"
import { useState } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined"
import AddBoxIcon from "@mui/icons-material/AddBox"
import "./hirearchy.css"
import { TreeMainService } from "../../../service/manage/h_tree/tree_main.service"
import { sweetStatusAlert } from "../../../components/common/SweetAlert"
import swal from "sweetalert"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

const HirearchyCard = forwardRef((props, ref) => {
  const {
    mcode,
    group_title,
    title,
    mid,
    pid,
    flg_view_node,
    flg_edit_node,
    flg_delete_node,
    cnt_child_node,
    flg_add_node_child
  } = props.cardData
  const formArray = props.cardData.children
  const [isCollapsed, setIsCollapsed] = useState(
    !!title && formArray && formArray.length > 0
  )
  const [titles, setTitles] = useState(props.titles)
  const [anchorEl, setAnchorEl] = useState(null)

  const shouldShowExpandIcons = !!title

  // const handleHeaderClick = () => {
  //   if (formArray && formArray.length > 0) {
  //     setIsCollapsed(!isCollapsed)
  //   }
  //   if(props.highlightCard !== mid){
  //     props.redirect()    
  //   }
  // }

  const deleteHierarchyNode = async (child_id, parent_id) => {
    const isConfirmed = await swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete the node?",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    })

    if (!isConfirmed) {
      return
    }

    props.setNodeLoading(true)
    await TreeMainService.deleteHierarchyNode(child_id).then((res) => {
      if (res.data[0].fn_tree_delete_node === 99) {
        sweetStatusAlert(
          "Failed!",
          "You can't delete a intermediate node with sibiling",
          "error"
        )
      } else if (!res.data[0].fn_tree_delete_node) {
        sweetStatusAlert("Failed!", "Unable to delete the node", "error")
      } else {
        sweetStatusAlert("Success!", "Node deleted successfully", "success")
      }
    })
    if(props.singleNodeParentParent){
      await props.getNodesAndFormsInfo(
        props.singleNodeParentParent.pid,
        1,
        props.user_id,
        props.user_table_id,
        props.singleNodeParentParent.mid
      )
    }else{
      await props.getNodesAndFormsInfo(
        parent_id,
        1,
        props.user_id,
        props.user_table_id
      )
    }
  }

  const getGroupTitleById = (group_id) => {
    const foundTitle = titles?.find((title) => title.group_id === group_id)
    return foundTitle ? foundTitle.group_title : null
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [anchorChildNodesEl, setAnchorChildNodesEl] = React.useState(null);
  const open = Boolean(anchorChildNodesEl);
  const handleChildNodesClick = (event) => {
    setAnchorChildNodesEl(event.currentTarget);
  };
  const handleChildNodesClose = () => {
    setAnchorChildNodesEl(null);
  };

  return (
    <div className="hirearchy-card" ref={ref} >
      <Card>
        <div
          onClick={() => {
            props.redirect()
          }}
          className={`row card-custom hcard-header ${
            props.highlightCard === mid ? "selected-card" : ""
          } ${(!!cnt_child_node && !!formArray?.length) || !!flg_add_node_child ? "hoverable" : ""}`} style={{height:'8vh', padding: '2px 12px', display:'flex', alignItems:'center'}}
          >
          {!!flg_view_node && (
            <div
              className="col-md-9 col-10 px-0"
              style={{
                cursor:
                (!!cnt_child_node && !!formArray?.length) || !!flg_add_node_child
                ? "pointer"
                    : "default",
              }}
            >
              <div className="row hcard-title">
                <div className="col-md-12 col-11 px-0">
                  <p>{title}</p>
                </div>
                {/* <div className="col-md-12 col-11 px-0 hcard-subtitle">
                  <p>
                    {mcode} / {getGroupTitleById(parseInt(group_title))}
                  </p>
                </div> */}
              </div>
            </div>
          )}

          <div className="col-md-2 col-1 px-0 d-flex align-items-center justify-content-end expand-icons">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleChildNodesClick}
            >
              {shouldShowExpandIcons &&
              !!cnt_child_node && !!formArray?.length &&
              (open ? (
                <ExpandLessIcon
                  sx={{ fontSize: 20 }}
                  style={{ cursor: "pointer" , color: "var(--iconcolor)" }}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ fontSize: 20 }}
                  style={{ cursor: "pointer" , color: "var(--iconcolor)" }}
                />
              ))}
            </IconButton>
          </div>

          {(!!flg_edit_node || !!flg_delete_node || !!flg_add_node_child) && (
            <div className="col-md-1 col-1 px-0 d-flex align-items-center justify-content-center expand-icons">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon
                  sx={{ fontSize: 14, color: "var(--iconcolor)" }}
                />
              </IconButton>
            </div>
          )}
        </div>
      </Card>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!!flg_add_node_child && (
          <MenuItem
            onClick={() => {
              props.toggleInsertModal(false, mid, true)
              handleClose()
            }}
          >
            <div className="col-md-4 d-flex justify-content-center action-icons-delete">
              <AddBoxIcon
                sx={{ fontSize: 14, color: "#A2A5B5" }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <p>Add</p>
          </MenuItem>
        )}
        {!!flg_edit_node && (
          <MenuItem
            onClick={() => {
              props.toggleUpdateModal(props.cardData)
              handleClose()
            }}
          >
            <div className="col-md-4 d-flex justify-content-center action-icons-edit">
              <BorderColorOutlinedIcon
                sx={{ fontSize: 14, color: "#A2A5B5" }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <p>Edit</p>
          </MenuItem>
        )}
        {!!flg_delete_node && (
          <MenuItem
            onClick={() => {
              deleteHierarchyNode(mid, pid)
              handleClose()
            }}
          >
            <div className="col-md-4 d-flex justify-content-center action-icons-delete">
              <DeleteForeverIcon
                sx={{ fontSize: 16, color: "#A2A5B5" }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <p>Delete</p>
          </MenuItem>
        )}
      </Menu>      
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorChildNodesEl}
        open={open}
        onClose={handleChildNodesClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: 48 * 4.5,
              // width: '20ch',
              // overflow:'auto',
            },
          },
        }}
      >
        {formArray?.map((form, index) => (
          <MenuItem key={index} onClick={() => {
            props.redirectParent(
              form.pid,
              form.mid
            )
          }}>
            {form.title}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
})

export default HirearchyCard
