import {quesDifficultyTypes} from '../../../reducers/settings/settingsTypes'
import {questionDifficultyService} from '../../../service/settings/question/difficultyService'


export const quesDifficultyActions = {
    getAllQuestionDifficulties,
    createDifficulty,
    updateDifficulty,
    deleteDifficulty
}

function getAllQuestionDifficulties(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let difficulties = await questionDifficultyService.getAllQuestionDifficulties(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(difficulties.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: quesDifficultyTypes.GET_ALL_QUESTION_DIFFICULTIES}
    }

    function request() {
        return {type: quesDifficultyTypes.GET_ALL_QUESTION_DIFFICULTIES_REQUEST}
    }

    function success(difficulties) {
        return {type: quesDifficultyTypes.GET_ALL_QUESTION_DIFFICULTIES_SUCCESS, difficulties}
    }

    function failure(error) {
        return {type: quesDifficultyTypes.GET_ALL_QUESTION_DIFFICULTIES_FAILURE, error}
    }
}


function createDifficulty(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await questionDifficultyService.createDifficulty(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: quesDifficultyTypes.CREATE_DIFFICULTY_REQUEST, data}
    }

    function success(difficulties) {
        return {type: quesDifficultyTypes.CREATE_DIFFICULTY_SUCCESS, difficulties}
    }

    function failure(error) {
        return {type: quesDifficultyTypes.CREATE_DIFFICULTY_FAILURE, error}
    }
}


function deleteDifficulty(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await questionDifficultyService.deleteDifficulty(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(difficulty) {
        return {type: quesDifficultyTypes.DELETE_DIFFICULTY_REQUEST, difficulty}
    }

    function success(success) {
        return {type: quesDifficultyTypes.DELETE_DIFFICULTY_SUCCESS, success}
    }

    function failure(error) {
        return {type: quesDifficultyTypes.DELETE_DIFFICULTY_FAILURE, error}
    }
}

function updateDifficulty(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await questionDifficultyService.updateDifficulty(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: quesDifficultyTypes.UPDATE_DIFFICULTY_REQUEST}
    }

    function success(difficulty) {
        return {type: quesDifficultyTypes.UPDATE_DIFFICULTY_SUCCESS, difficulty}
    }

    function failure(error) {
        return {type: quesDifficultyTypes.UPDATE_DIFFICULTY_FAILURE, error}
    }
}
