import React, {useEffect, useState} from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutCharts = ({doughnutData}) => {
    const [doughnutInfo, setDougnutInfo] = useState([])
    useEffect(() => {
        const dougnutData = doughnutData.filter(data => data.chart_type === "Statistics-donut")
        setDougnutInfo(dougnutData)
    }, [doughnutData])
    const handleChartData = (data) => {
        const donutValues = data?.array.map(item => {
            return item.chart_y_value
        })
        const labels = data?.array.map(item => {
            return item.chart_x_value
        })
        const tooltips = data?.array.map(item => {
            return item.chart_legend_x_title
        })
        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Grades Distribution',
                    data: donutValues,
                    backgroundColor: [
                        '#00A8E8', 
                        '#FF5A5F', 
                        '#FFC107', 
                        '#8E44AD',
                        '#4CAF50',
                        '#F39C12', 
                        '#B085D5',
                        '#E74C3C',
                        '#1ABC9C',
                        '#9B59B6'
                    ],
                    hoverOffset: 4,
                    tooltips:tooltips
                }
            ]
        };
        return chartData
    }

    const options = {
        cutout: '55%',  // Adjust the cutout for a smaller center circle
        plugins: {
            legend: {
                display: false, // Hide individual chart legend
            },
            tooltip: {
                enabled: true, // Disable tooltips
                callbacks: {
                    label: function(context) {
                    const index = context?.dataIndex
                    const tooltips = context?.dataset?.tooltips
                    const item = tooltips[index]
                    return item
                    },
                },
            },
        }
    };



    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginBottom: '20px' }}>
                <ul style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    padding: 0,
                    margin: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    {handleChartData(doughnutInfo[0])?.labels?.length && handleChartData(doughnutInfo[0]).labels.map((label, index) => (
                        <li key={index} style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '5px 10px',
                            width: '120px', // Adjust this value if needed to fit your layout
                            justifyContent: 'center',
                        }}>
                            <span style={{
                                display: 'block',
                                width: '12px',
                                height: '12px',
                                backgroundColor: handleChartData(doughnutInfo[0])?.datasets[0].backgroundColor[index],
                                marginRight: '5px'
                            }}></span>
                            <span style={{ fontSize: '12px' }}>{label}</span>
                        </li>
                    ))}
                </ul>
            </div>

            {!!doughnutInfo?.length && doughnutInfo.map((data, i) => (
                <div key={i} style={{ position: 'relative', marginBottom: '20px', width: '150px', height: '150px' }}>
                    <Doughnut data={handleChartData(data)} options={options} />
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        fontSize: '8px',
                        fontWeight: 'bold',
                        color: '#333',
                        textAlign: 'center',
                    }}>
                        {`${data?.chart_title}`}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DonutCharts;
