import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const CalendarEventTypeService = {
  create,
  update,
  getCalendarEventTypeByPagination,
  getAllCalEventTypes,
  deleteCalendarEventType
};

async function create(calendarEvent) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(calendarEvent),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/cal-event-types/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(calendarEvent, calendarEventId) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(calendarEvent),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/cal-event-types/update/${calendarEventId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllCalEventTypes() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/cal-event-types/getAllCalEventTypes`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


async function getCalendarEventTypeByPagination(limit, offset, search_term) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/cal-event-types/getAll/${limit}/${offset}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteCalendarEventType(id) {
  const configOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/cal-event-types/delete/${id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}