export const namespace = {
    USER_ID: 'user_id',
    STUDENT_ID: 'student_id',
    AGREEMENT_LINE: 'Student declares to adhere to the following online paper terms & conditions:',
    AGREEMENT_LINE1: 'You should have a stable network connection for doing the exam paper.',
    AGREEMENT_LINE2: 'You should carefully read and follow all the instructions in the question paper.',
    AGREEMENT_LINE3: 'You should select the most appropriate answer for an MCQ question.',
    AGREEMENT_LINE4: 'You should not select more than one appropriate answer for a question.',
    AGREEMENT_LINE5: 'You can resume the paper in case of any disconnections within the paper duration.',
    AGREEMENT_LINE6: 'You can reach our support team in case of any inquiries.',
    // AGREEMENT_LINE7: 'There are no other people allowed in your room or exam area and you should not leave the room during the examination unnecessarily.',
    // AGREEMENT_LINE8: 'Unless permitted (Except for open book exams), you should accept to have all study materials removed from the visible area.',
    // AGREEMENT_LINE9: 'There should be adequate lighting ("daylight" quality), for you to take the exam with a better mentality.',
    // AGREEMENT_LINE10: 'You should have a better grasp of the subject as well as the scope of the question paper. Further, you must respond to examination questions in accordance with the directions provided by the examination paper & mindful of the timings when answering and choosing to submit.',
    // AGREEMENT_LINE11: 'You should have a general & sufficient understanding of and familiarity with UI arrangements, indicators, and feature usage in the system to complete an assessment.',
    // AGREEMENT_LINE12: 'During the examination, it is prohibited to consult unapproved study or technical materials or to communicate the contents of the examination paper with other Candidates or any other unpermitted parties.',
    // AGREEMENT_LINE13: 'In the exam room, Smartphones, Bluetooth and other virtual machines, or extraneous devices are not permitted & should not be used to store answers, for surfing on the web or receiving information. (Including, but not limited to the following: Copy-pasting responses, Screen sharing or Mirroring, Online Browsing, Use of External devices, Bluetooth earphones or earbuds, Task Switching are not permitted)',
    // AGREEMENT_LINE14: 'You should have a clear understanding & good handling of the procedure & actions to take in the event of a connection or technical issue.',
    // AGREEMENT_LINE15: 'You can resume a started exam with the last saved answers even after the given access buffer time from the scheduled start time in case of disconnections. If you are unable to complete the assessment within the time limit, the current progress will be automatically submitted.',
    // AGREEMENT_LINE16: 'If you are unable to handle the situation on your own, you must prove the error and immediately contact relevant or given parties at the institute. As a result, you must accept any decision made by the institute.',
    // AGREEMENT_LINE17: 'Any material or content, or a component, file, or information on the Platform, may not be copied, printed, reproduced, republished, broken apart, disassembled, downloaded, sent, distributed, transmitted, or made available to external unless permission.',
    // AGREEMENT_LINE18: 'If you have any queries regarding accessing, support requirements, assessment instructions or if you believe there is an error on the paper, these should be directed to the exam administrator &/or relevant parties of the institute.',
    // AGREEMENT_LINE19: 'You should be responsible for not acting in any inappropriate behaviors or cheating throughout the examination and you should accept any reasonable conclusion made by the institute or examination observers regarding exam misbehavior, cheating, or violation including but not limited to any of the above-mentioned rules.',
    // AGREEMENT_LINE20: 'The institute has exclusive authority to grade the assessments based on your compliance with the above-mentioned terms and conditions.',
    // AGREEMENT_LINE21: 'For further information on the technical/connection problems, any submission issues, or any other support requirements regarding the exams, please contact: (emergency mail/ hotline).',
    AGREEMENT_CONFIRMATION: 'I acknowledge that I have read and fully grasped the above online paper terms & conditions before starting the paper.',
    CANDIDATE_AGREEMENT: 'Read and accept to proceed!',
    ANSWER_SAVED_SUCCESSFULL: 'Answer Saved Successfully!',
    ANSWER_SAVED_FAIL: 'Answer Saving Failed!',
    FAILED: 'Failed!',
    SUCCESS: 'Success!',
    START_MESSAGE: 'Do you want to start?',
    RESUME_MESSAGE: 'Do you want to resume?',
    YES: 'Yes',
    NO: 'No',
    WELCOME_MESSAGE: 'Hi,',
    VALIDATION_START: 'Are you sure?',
    LEAVE_EXAM_WINDOW: 'Are you sure?',
    WARNING_MESSAGE: 'This will redirect you to the paper terms and conditions page!',
    LEAVE_WARNING_MESSAGE: 'Do you want to leave the exam without saving your progress?. You have to resume again!',
    WARNING: '',
    QUESTION_UPDATED_SUCCESSFULL:'Question Saved Successfully!',
    QUESTION_SAVED_FAIL:'Question Saving Failed!',
    FAILED:'Failed!',
    SUCCESS:'Success!',
    SUCCESS:'Warning!',
    QUESTION_UPDATED_SUCCESSFULL:'Question Updated Successfully!',
    QUESTION_UPDATED_FAIL:'Question update Failed!',
    INVALID_TYPE:'Invalid Type',
    DEFAULT_LANGUAGE: 'English',
    QUESTION_OR_TITLE_NOT_GIVEN:'Question or Title Not Given',
    ANSWER_NOT_SELECTED:'Answer Not Selected',
    ANSWER_NOT_GIVEN:'Answer Not Given',
    ANSWER_NOT_COMPLETED:'Complete the Optional Answers',
    FILE_UPLOAD_SUCCESS:'File Uploaded Successfully!',
    IMAGE_ADD_SUCCESS:'Image Added Successfully!',
    AUDIO_ADD_SUCCESS:'Audio Added Successfully!',
    VIDEO_ADD_SUCCESS:'Video Added Successfully!',
    FILE_SIZE_IS_EXCEEDED: 'File size exceeded the maximum limit!',
    NOT_REGISTERED_USER: 'User is not registered',
    WRONG_FORMAT:'Wrong Content Format',
    CANT_REMOVE_SHARED_QUESTION:`Can't remove shared question`,
    CANT_REMOVE_SET_QUESTION:`Can't remove questions added to assessments`,
    QUESTION_DELETED_SUCCESSFULL:'Question Removed Successfully!',
    ASSESSMENT_DELETED_SUCCESSFUL: 'Assessment Deleted Successfully',
    CANT_DELETE_PUBLISHED_ASSESSMENT: `Can't delete published assessment`,
    CANT_DELETE_READY_ASSESSMENT: `Can't delete ready assessment`,
    CANT_DELETE_REVIEW_ASSESSMENT: `Can't delete review assessment`,
    CANT_DELETE_RETIRED_ASSESSMENT: `Can't delete retired assessment`,
    CANT_DELETE_FINISHED_ASSESSMENT: `Can't delete finished assessment`,
    EXAMSTATE:'examState',
    TESTSTATE: 'testState'
}

export const dashChartTypesEdsider = {
    EDSIDER_ADMIN_SUBJECT_PAPER_COUNT_ENGLISH:{type:"edadmin-sub-pepcnt-eng", chart_id: 8000},
    EDSIDER_ADMIN_SUBJECT_PAPER_COUNT_TAMIL:{type:"edadmin-sub-pepcnt-tam", chart_id: 8100},
    EDSIDER_ADMIN_SUBJECT_PAPER_COUNT_SINHALA:{type:"edadmin-sub-pepcnt-sin", chart_id: 8200},
    EDSIDER_ADMIN_GRADE_PAPER_COUNT_ENGLISH:{type:"edadmin-grd-pepcnt-eng", chart_id: 8300},
    EDSIDER_ADMIN_GRADE_PAPER_COUNT_TAMIL:{type:"edadmin-grd-pepcnt-tam", chart_id: 8400},
    EDSIDER_ADMIN_GRADE_PAPER_COUNT_SINHALA:{type:"edadmin-grd-pepcnt-sin", chart_id: 8500},
    REGISTRATION_BY_GRADE_ENGLISH:{type:"Statistics-donut", chart_id: 9100},
    REGISTRATION_BY_GRADE_TAMIL:{type:"Statistics-donut", chart_id: 9200},
    REGISTRATION_BY_GRADE_SINHALA:{type:"Statistics-donut", chart_id: 9300},

    
    EDSIDER_TEACHER_SUBJECT_PAPER_COUNT_ENGLISH:{type:"edtea-sub-pepcnt-eng", chart_id: 11000},
    EDSIDER_TEACHER_SUBJECT_PAPER_COUNT_TAMIL:{type:"edtea-sub-pepcnt-tam", chart_id: 11100},
    EDSIDER_TEACHER_GRADE_PAPER_COUNT_ENGLISH:{type:"edtea-grd-pepcnt-eng", chart_id: 11200},
    EDSIDER_TEACHER_GRADE_PAPER_COUNT_SINHALA:{type:"edtea-grd-pepcnt-sin", chart_id: 11300},
}