import { AzureStorage } from "../../helper/azureStorage";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";
import DataHandler from "../../handlers/DataHandler";

export const candidateService = {
    addCandidates,
    deleteAssessment,
    getCandidateReports,
    deleteCandidate,
    editCandidates,
    finalizeGrade,
    finalizeAllGrades,
    getRemarks,
    getTeacherReports
};


async function addCandidates(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/candidates/addAll/`, requestOptions).then(APIHandler.handleResponse);
}

async function editCandidates(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };
    return await fetch(`${config.oat_api_url}/candidates/edit/`, requestOptions).then(APIHandler.handleResponse);
}

async function getCandidateReports(assessment_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/candidates/getReports/${assessment_id}`, requestOptions).then(APIHandler.handleResponse);
}
async function getTeacherReports(graded_by,assessment_id,limit,offset,sort_column,sort_direction,search_term) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/candidate/teacher-grade/reports/get/${graded_by}/${assessment_id}/${limit}/${offset}/${sort_column}/${sort_direction}?search_term=${search_term}&candidate_id=`, requestOptions).then(APIHandler.handleResponse);
}
async function deleteCandidate(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/candidates/delete`, requestOptions).then(APIHandler.handleResponse);
}

async function finalizeGrade(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };
    return await fetch(`${config.oat_api_url}/candidate/grade/reports/finalize/`, requestOptions).then(APIHandler.handleResponse);
}

async function finalizeAllGrades(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };
    return await fetch(`${config.oat_api_url}/candidate/grade/reports/finalizeAll/`, requestOptions).then(APIHandler.handleResponse);
}

async function deleteAssessment(id) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify({user_ID: DataHandler.getFromSession("user_id")})
    };

    const response = await fetch(`${config.oat_api_url}/assessments/delete/${id}`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getRemarks (key) {
  return key;
}
