import React, { Component } from "react";
import { connect } from "react-redux";
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import CardBody from '../../../components/common/CardBody';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import TabList from '../../../components/common/tabs/TabList';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { quesYearActions } from "../../../actions/settings/question/yearAction.js"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config.js";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class QuestionYear extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newYear:null,
        yearRowsPerPage:5,
        currentYearPage: 0,
        yearData: [],
        year_sort_column: "year_id",
        year_sort_direction: "ASC",
        selectYear: '',
        editYear: false,
        year: '',
        limit: 10,
        year_search_term: "",
        year_offset: 0,
        searchError: false,
        year_limit:5,
        yearId: '',
        yearOptionsImport: [],
        selectedYear: []
      };
      
      this.onNewYearInput = this.onNewYearInput.bind(this);
      this.onAddNewYear = this.onAddNewYear.bind(this);
      this.generateYearOptions = this.generateYearOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllQuestionYears();
    }
    
    onNewYearInput(e) {
        this.setState({
            newYear: e.target.value
        });
    }
    
    async onAddNewYear(e) {
        if (this.state.newYear && this.state.newYear.trim()) {
            await this.props.createYear({ 'year': this.state.newYear })
            if (this.props.successyearcreate) {
                this.setState(
                    {
                        year_offset: 0,
                        currentYearPage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllQuestionYears();
                        this.generateYearOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new year!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-category').modal('hide')
                                window.$("#question-category").val('default');
                                window.$('#question-category').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.erroryearupdate) {
                window.Swal.fire({
                    title: 'Failed to add a new year!',
                    text: this.props.erroryearupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty year!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newYear: ''
        });
    }

    handleYearSearch = (event) => {
        const year_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (year_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (year_search_term === "" || regex.test(year_search_term)) {
            this.setState(
                {
                    year_search_term,
                    year_offset: 0,
                    currentYearPage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllQuestionYears();
                }
            );
        } else {
            this.setState({ year_search_term, searchError: true });
        }
    };
    
    fetchAllQuestionYears = async () => {
        const {
            year_limit,
            year_offset,
            year_sort_column,
            year_sort_direction,
            year_search_term
        } = this.state;
        await this.props.getAllQuestionYears(year_limit, year_offset, year_sort_column, year_sort_direction, year_search_term);
        this.setState({ year_limit: this.state.yearRowsPerPage });
        this.setState({ yearData: this.props.allQuestionYears })
    };

    handleYearPageChange = (event, newYearPage) => {
        const {yearRowsPerPage} = this.state;
        const newYearOffset = newYearPage * yearRowsPerPage;
        this.setState(
        {
            year_limit: yearRowsPerPage,
            year_offset: newYearOffset,
            currentYearPage: newYearPage
        },
        () => {
            this.fetchAllQuestionYears();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {yearRowsPerPage} = this.state;
        this.setState(
        {
            year_limit: yearRowsPerPage,
            year_offset: newOffset,
            currentYearPage: newPage
        },
        () => {
            this.fetchAllQuestionYears();
        }
        );
    };
    
    //handle the data fetching as per the data per page limit gets changes
    handleYearRowsPerPageChange = (event) => {
        const newYearLimit = parseInt(event.target.value, 10);
        this.setState({yearRowsPerPage: newYearLimit});
        const newYearPage = Math.floor(this.state.year_offset / newYearLimit)
        this.setState(
        {
            year_limit: newYearLimit,
            year_offset: newYearLimit * newYearPage,
            currentYearPage: newYearPage
        },
        () => {
            this.fetchAllQuestionYears();
        }
        );
    };

    handleSortYearChange = (year_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { year_sort_column: currentYearSortColumn, year_sort_direction } = this.state;
        const newYearSortDirection =
        currentYearSortColumn === year_sort_column && year_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            year_sort_column,
            year_sort_direction: newYearSortDirection,
            year_offset: 0,
            currentYearPage: 0,
        },
        () => {
            this.fetchAllQuestionYears();
        }
        );
    }

    getQuestionYear(year_id) {
        let yearname
        this.props.allQuestionYears.map(year => {
            if (year.year_id === year_id) {
                return yearname = year.year
            }
        })
        return yearname
    }
    
    onEditYear(e, el) {
        this.setState({
            yearId: el.year_id,
            year: e.target.value
        })
    }
    
    async onUpdateYear(e) {
        e.preventDefault()
        var obj = {
            year_id: this.state.yearId,
            year: this.state.year
        }
        this.setState({ selectYear: '' })
        if (!this.state.year.trim()) {
            window.Swal.fire({
                title: 'Failed to update year!',
                text: 'Year cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateYear(obj);
            if (this.props.successyearupdate) {
                // await this.props.getAllQuestionYears(year_limit, year_offset, year_sort_column, year_sort_direction, year_search_term);
                this.fetchAllQuestionYears()
                this.generateYearOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated year!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });
            }
            if (this.props.erroryearupdate) {
                window.Swal.fire({
                    title: 'Failed to update year!',
                    text: this.props.erroryearupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        }
    }

    generateYearOptions() {
        let yearOptionsImport = []
        this.props.allQuestionYears.map((el, i) => {
            yearOptionsImport.push(<Option key={el.year_id} optionName={el.year} value={el.year_id} />)
        });

        this.setState({
            yearOptionsImport: yearOptionsImport,
            selectedYear: yearOptionsImport[0].props.value
        });

        if (yearOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditYear(el, i, e) {
        e.preventDefault()
        this.setState({
            editYear: true,
            selectYear: i,
            yearId: el.year_id,
            year: el.year
        })
    }

    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the year?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteYear(el);
                if (this.props.successyeardelete) {
                    // await this.props.getAllQuestionYears(year_limit, year_offset, year_sort_column, year_sort_direction, year_search_term);
                    this.fetchAllQuestionYears()
                    this.generateYearOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted year!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });
                }
                if (this.props.erroryeardelete) {
                    window.Swal.fire({
                        title: 'Failed to delete year!',
                        text: this.props.erroryeardelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-year').modal('hide')
                            window.$("#question-year").val('default');
                            window.$('#question-year').selectpicker('refresh');
                        });

                }
            }
        })
    }
    
    syncTableYear() {
        return (
            <>
                {this.state.yearData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortYearChange("year_id")}
                                >
                                    No{" "}
                                    {this.state.year_sort_column === "year_id" && (
                                    <span>{this.state.year_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortYearChange("year")}
                                >
                                    Year Name{" "}
                                    {this.state.year_sort_column === "year" && (
                                    <span>{this.state.year_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.yearData.map((el, i) => {
                                if (i === this.state.selectYear) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editYear ? 
                                                this.getQuestionYear(el.year_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.year}
                                                    onChange={(e) => this.onEditYear(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateYear(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Year" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getQuestionYear(el.year_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditYear(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Year" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                                                    title="Delete Year" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Years Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3>Manage Years</h3>
              </div>

              <CardBody>
                <div className="row justify-content-end">
                  <div
                    className="table-wrapper-scroll-y my-custom-scrollbar"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <TabList>
                      <QuestionAccordion
                        labelName="Create New Year"
                        onChange={this.onNewYearInput}
                        onClick={this.onAddNewYear}
                        val={this.state.newYear}
                      />
                    </TabList>
                    <div className="row justify-content-end col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleYearSearch}
                          className="col-md-18 col-18"
                        />
                      </Form.Group>
                    </div>
                    <TabContent>
                      <TabPane
                        className="tab-pane fade show active"
                        id="manage-1"
                        ariaLabelledby="manage-tab-1"
                      >
                        {/* begin: Datatable */}
                        {this.props.cat_loading ? (
                          <div style={{ width: "75vw", overflowX: "scroll" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Year Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                            <div className="loading-container mb-5 mt-10">
                              <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </ReactSpinner>
                            </div>
                          </div>
                        ) : this.state.yearData.length > 0 ? (
                          this.syncTableYear()
                        ) : (
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Year Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {/* end: Datatable */}
                            </Table>
                            <div className="text-center mb-5 mt-10">
                              <label>No Years Available!</label>
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                            totalCount = {this.props.totalYearsCount}
                            currentPage = {this.state.currentYearPage}
                            limit = {this.state.yearRowsPerPage}
                            handlePageChange = {this.handleYearPageChange}
                            handleRowsPerPageChange = {this.handleYearRowsPerPageChange}
                            handlePageOffsetChange = {this.handlePageOffsetChange}
                            offset = {this.state.year_offset}
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalYearsCount: state.yearReducer.totalYearsCount,//
        cat_loading:state.yearReducer.loading,
        error: state.yearReducer.error,//
        // totalCount: state.questionsLibraryReducer.totalCount,//
        
        erroryeardelete: state.yearReducer.erroryeardelete,
        successyeardelete: state.yearReducer.successyeardelete,

        erroryearupdate: state.yearReducer.erroryearupdate,
        successyearupdate: state.yearReducer.successyearupdate,//
        
        erroryearcreate: state.yearReducer.erroryearcreate,
        successyearcreate: state.yearReducer.successyearcreate,//

        allQuestionYears: state.yearReducer.allQuestionYears,//
        loading: state.yearReducer.loading,//
    }
}

    const mapActionsToProps = {
        createYear: quesYearActions.createYear,
        getAllQuestionYears: quesYearActions.getAllQuestionYears,
        updateYear: quesYearActions.updateYear,
        deleteYear: quesYearActions.deleteYear,
    }

    export default connect(mapStateToProps, mapActionsToProps)(QuestionYear);

