import {shareTypes} from '../types';

const initialState = {
    question: '',
    share_id: '',
    sharedUsers: [],
    loading: false,
    success: '',
    error: '',
    text: ''
}

export function shareReducer(state = initialState, action){
    switch (action.type){
        case shareTypes.SHARE_PAGE_REFRESH:
            return {
                ...state,
                question: '',
                share_id: '',
                sharedUsers: [],
                loading: false,
                success: '',
                error: '',
                text: ''
            }
        case shareTypes.SHARE_QUESTION:
            return {
                ...state,
                question: action.question,
                share_id: '',
                sharedUsers: [],
                loading: false,
                success: '',
                error: '',
                text: 'Load share page'
            }
        case shareTypes.SHARE_QUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                success: null,
                error: null,
                text: 'Sharing the question'
            }
        case shareTypes.SHARE_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Question shared with the selected user'
            }
        case shareTypes.SHARE_QUESTION_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case shareTypes.GET_SHARED_USERS:
            return {
                ...state,
                sharedUsers: [],
                loading: true,
                text: ''
            }
        case shareTypes.GET_SHARED_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Getting all the users that the question is already shared!'
            }
        case shareTypes.GET_SHARED_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                sharedUsers: action.users.shared_users,
                success: 'Obtained shared users successfullly'
            }
        case shareTypes.GET_SHARED_USERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case shareTypes.STOP_SHARING_QUESTION:
            return {
                ...state,
                loading: true,
                share_id: action.share_id
            }
        case shareTypes.STOP_SHARING_QUESTION_REQUEST:
            return {
                ...state,
                loading: true,
                success: null,
                error: null,
                text: 'Removing specified question permissions!'
            }
        case shareTypes.STOP_SHARING_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Successfully unshared the question!'
            }
        case shareTypes.STOP_SHARING_QUESTION_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case shareTypes.UNSHARE_FROM_ME:
            return {
                ...state,
                loading: true,
                share_id: action.share_id,
                text: 'Removing question from your library!'
            }
        case shareTypes.UNSHARE_FROM_ME_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Removing question from your library!'
            }
        case shareTypes.UNSHARE_FROM_ME_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Removed from your shared list'
            }
        case shareTypes.UNSHARE_FROM_ME_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}
