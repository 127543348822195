import React from "react";

export default function ModalFooter({children}) {

    return (
        
        <div className="modal-footer">
            <div class="col-lg-3"></div>

            <div class="col-lg-9">
                {children}
            </div>
        </div>

    )
}