import React, { Component } from "react";
import { Tab, Nav } from "react-bootstrap";
import tab1 from "../assets/images/tab1.png";
import tab2 from "../assets/images/tab2.png";
import parents1 from "../assets/images/parents1.svg";
import parents2 from "../assets/images/parents2.svg";
import students1 from "../assets/images/students1.svg";
import students2 from "../assets/images/students2.svg";

class Tabs extends Component {
  componentDidMount() {
    // Intersection Observer setup
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
        }
      });
    }, { threshold: 0.1 });

    // Target elements for animation
    const elements = document.querySelectorAll('.up-to-down, .down-to-up');
    elements.forEach((el) => observer.observe(el));
  }

  render() {
    return (
      <>
        <div id="tabs" className="mt-4 container section-container">
          <div className="tabs">
            <div className="row pt-3">
              <div className="col-md-6 sm-12 xs-12 tabs-container up-to-down">
                <h1 className="tab-row-title d-flex justify-content-center">Student</h1>
                <div className="d-flex flex-row mb-3 tab-content-row">
                  <div className="row d-flex flex-row align-items-center">
                    <img
                      className="col-md-2 tab-img-container"
                      src={students1}
                      alt="email-icon"
                    />
                    <div className="col-md-9">
                      <h1 className="tab-row-title">Convenient Online Practice</h1>
                      <p className="tab-row-content">
                        Students can take model papers online from the comfort of their
                        homes, enabling flexible exam preparation
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row mb-3 tab-content-row">
                  <div className="row d-flex flex-row align-items-center">
                    <img
                      className="col-md-2 tab-img-container"
                      src={students2}
                      alt="email-icon"
                    />
                    <div className="col-md-9">
                      <h1 className="tab-row-title">Track Progress</h1>
                      <p className="tab-row-content">
                        Our system provides detailed reports and performance analysis,
                        helping students identify their strengths and weaknesses
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 sm-12 xs-12 tabs-container up-to-down">
                <h1 className="tab-row-title d-flex justify-content-center">Parent</h1>
                <div className="d-flex flex-row mb-3 tab-content-row">
                  <div className="row d-flex flex-row align-items-center">
                    <img
                      className="col-md-2 tab-img-container"
                      src={parents1}
                      alt="email-icon"
                    />
                    <div className="col-md-9">
                      <h1 className="tab-row-title">Engage in their child's learning</h1>
                      <p className="tab-row-content">
                        Parents can actively participate in their child's exam
                        preparation by accessing their performance reports and providing
                        guidance and support
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row tab-content-row">
                  <div className="row d-flex align-items-center">
                    <img
                      className="col-md-2 tab-img-container"
                      src={parents2}
                      alt="phone-icon"
                    />
                    <div className="col-md-9">
                      <h1 className="tab-row-title">Monitor Progress</h1>
                      <p className="tab-row-content">
                        Stay updated on your child's progress, track improvement over
                        time, and make informed decisions to enhance their learning
                        experience
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Tabs;
