import React from 'react';
import { Link } from 'react-router-dom';

export default function Card({ cardLabel, cardBottomLable, children }) {
    return (
        <div className="card-title">
            <h3 className="card-label">{cardLabel}</h3>
            {children}
        </div>
    )
}