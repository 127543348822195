import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const questionTermService = {
    getAllQuestionTerms,
    createTerm,
    deleteTerm,
    updateTerm
};

async function getAllQuestionTerms(limit, offset, sort_column, sort_direction, search_term) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/terms/getAllTerms/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}`, requestOptions)
        .then(APIHandler.handleResponse);
}

async function createTerm(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/manage/terms/add/`, requestOptions).then(APIHandler.handleResponse);
}

async function deleteTerm(obj) {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(obj)
        };
        return await fetch(`${config.oat_api_url}/manage/terms/delete/${obj.term_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function updateTerm(obj) {
    try {
        let requestBody = {
            term: obj.term.toString()
        }
        const requestOptions = {
            method: 'PUT',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(requestBody)
        };

        let data = await fetch(`${config.oat_api_url}/manage/terms/update/${obj.term_id}`, requestOptions);

        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}
