import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const PaymentService = {
    createAmount,
    getAmountByNode,
    getPaymentByStudent,
    createPayment,
    getEnrollments,
    updatePayment,
    getSessionID,
    getAllEnrolledStudents
};


async function getAllEnrolledStudents(
    node_mid,
    form_id,
    lg_user_id,
    lg_user_table_id,
    limit,
    offset,
    sortColumn,
    sortDirection,
    search_term
  ) {
    const configOptions = {
      method: "GET",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/payments/getAllPaidStudents/${node_mid}/${form_id}/${lg_user_id}/${lg_user_table_id}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }

async function createAmount(amount) {
    const requestOptions = {
        method: "POST",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: amount,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/amounts/add`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function createPayment(payment) {
    const requestOptions = {
        method: "POST",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: payment,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/payments/add`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function updatePayment(payment, id) {
    const requestOptions = {
        method: "PUT",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: payment,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/amounts/update/${id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getEnrollments(user_id, user_table_id, table, user_type) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-enrollment-information/${user_id}/${user_table_id}/${table}/${user_type}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAmountByNode(
    nodeId,
) {
    const requestOptions = {
        method: "GET",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/amounts/getAmount/${nodeId}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}
async function getPaymentByStudent(
    nodeId,
    stu_id
) {
    const requestOptions = {
        method: "GET",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
            "Time-Zone":Intl.DateTimeFormat().resolvedOptions().timeZone
        },
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/payments/getAllPayments/${nodeId}?stu_id=${stu_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getSessionID() {
    const configOptions = {
      method: "GET",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/payments/getSessionId`,
      configOptions
    );
    return response;
  }

