import swal from "sweetalert";
import "./SweetAlert.css";
import enroll from "../../assets/images/enroll.png";

const sweetAlert = (
  title = "Are you sure?",
  text = "You want to close before saving!",
  icon = "warning"
) => {
  return new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: ["No", "Yes"],
      dangerMode: false,
    })
      .then((willDelete) => {
        if (willDelete) {
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sweetConfirmAlert = (
  title = "Are you sure?",
  text = "You won't be able to revert this!",
  icon = "warning"
) => {
  return new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: ["No", "Yes"],
      dangerMode: false,
    })
      .then((willDelete) => {
        if (willDelete) {
          resolve(true);
        }
        else{
          resolve(false)
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sweetApproveAlert = (title,text,icon) => {
  return new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: ["No", "Yes"],
      dangerMode: false,
    })
      .then((willDelete) => {
        if (willDelete) {
          resolve(false);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sweetStatusAlert = (title, text, iconOf) => {
  const icon = iconOf?iconOf:'error';
  return swal({
    title,
    text,
    icon,
    dangerMode: false,
  });
};

const sweetSuccessAlert = (title, text, iconOf) => {
  const icon = iconOf?iconOf:'success';
  swal({
    title,
    text,
    icon,
    dangerMode: false,
  });
};

const registersuccess = (title, text, icon, callback) => {
  swal({
    title: title,
    text: text,
    icon: icon,
    dangerMode: false,
  }).then((result) => {
    if (callback && typeof callback === "function") {
      callback(result);
    }
  });
};

const sweetErrorAlert = (title, text) => {
  swal({
    icon: "error",
    title,
    text,
    footer: "Fill all the fields before submit",
  });
};

const sweetAlertEnroll = (
  title = "Are you sure?",
  text = "Are you sure you want to enroll to this exam ?",
  icon = enroll,
) => {
  return new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: ["No", "Yes"], // Changed the order of buttons
      dangerMode: false,
    })
      .then((willEnroll) => {
        if (willEnroll) {
          resolve(true); // Resolve with true if user clicks "Yes"
        } else {
          resolve(false); // Resolve with false if user clicks "No"
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sweetAlertTryagain = (
  title = "Are you sure?",
  text = "Are you sure you want to try again for this exam ?",
  icon = enroll,
) => {
  return new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: ["No", "Yes"], // Changed the order of buttons
      dangerMode: false,
    })
      .then((willEnroll) => {
        if (willEnroll) {
          resolve(true); // Resolve with true if user clicks "Yes"
        } else {
          resolve(false); // Resolve with false if user clicks "No"
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const sweetStudentRemoveAlert = (
  title = "Are you sure?",
  text = "This student will prevent access to this chat!",
  icon = "warning"
) => {
  return new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: ["No", "Yes"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          resolve(true);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sweetStudentAddAlert = (
  title = "Are you sure?",
  text = "This student will add to the chat!",
  icon = "info"
) => {
  return new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: ["No", "Yes"],
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          resolve(true);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sweetChatRenameAlert = (
  title = "Are you sure?",
  text = "Do you want to change chat name?",
  icon = "info"
) => {
  return new Promise((resolve, reject) => {
    swal({
      title,
      text,
      icon,
      buttons: ["No", "Yes"],
      dangerMode: false,
    })
      .then((willDelete) => {
        if (willDelete) {
          resolve(true);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const sweetAlertInfo = (title, text, icon) => {
  return new Promise((resolve, reject) => {
    swal({
      title,
      text,
      button: "Okay",
      dangerMode: false,
    })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export default sweetAlert;
export {
  sweetStatusAlert,
  sweetSuccessAlert,
  sweetErrorAlert,
  sweetAlertEnroll,
  registersuccess,
  sweetConfirmAlert,
  sweetApproveAlert,
  sweetStudentRemoveAlert,
  sweetStudentAddAlert,
  sweetChatRenameAlert,
  sweetAlertTryagain,
  sweetAlertInfo
};
