import React, { Component } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputAdornment from '@mui/material/InputAdornment';
import SimpleReactValidator from "simple-react-validator";
import { GradeService } from '../../../service/settings/manage/grade.service.js';
import { Spinner } from 'react-bootstrap';
import { sweetConfirmAlert, sweetErrorAlert, sweetStatusAlert, sweetSuccessAlert } from '../../../components/common/SweetAlert';
import ContentWrapper from '../../../components/common/ContentWrapper';
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

class ManageGrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading:false,
      isLoading:false,
      addGradeModal: false,
      updateGradeModal:false,
      grade: null,
      updateGrade:null,
      selectedGrade:null,
      grades:[],
      limit:null
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount(){
    this.getAllGrades()
  }

  getAllGrades=()=>{
    this.setState({ isTableDataLoading: true });
    GradeService.getGrades()
        .then((response)=>{
            if(response.success){
                this.setState({grades:response.data, isTableDataLoading:false})
            }
            else{
                this.setState({ isTableDataLoading: false });
            }
            
        })
  }

  toggleAddGradeModal = () => {
    const { addGradeModal } = this.state;
    this.validator.purgeFields()
    this.validator.hideMessageFor("grade")
    this.setState({
      addGradeModal: !addGradeModal,
      grade: "",
      limit:null,
    });
  };

  toggleUpdateGradeModal = (grade) => {
    const { updateGradeModal } = this.state;
    this.validator.purgeFields()
    this.validator.hideMessages()
    if(!updateGradeModal){
        this.setState({updateGrade:grade.grade , selectedGrade:grade.grade_id , updateGradeModal:true})
    }
    else{
        this.setState({updateGrade:null, selectedGrade:null , updateGradeModal:false})
    }
  };

  handleSubmit = () => {
    const { grade, limit } = this.state;
    if (!this.validator.fieldValid("grade") || !this.validator.fieldValid("limit")) {
      this.validator.showMessageFor("grade")
      this.validator.showMessageFor("limit")
      this.forceUpdate();
    } else {
    this.setState({isLoading:true})
      const newGrade = {
        grade:grade,
        limit:limit
      }
      GradeService.addGrade(newGrade)
        .then((response)=>{
            if(response.success){
                this.setState({isLoading:false,grade:'',limit:null})
                this.toggleAddGradeModal()
                sweetSuccessAlert("Success","Grade added successfully","success")
                this.getAllGrades()
            }
            else{
                this.setState({isLoading:false})
                sweetStatusAlert("Info",response?.message,"info")
            }
        })
        .catch((error)=>{
            this.setState({isLoading:false})
            sweetErrorAlert("Error","Error while adding grade","error")
        })
    }
  };

  handleEditSubmit = ()=>{
    const {updateGrade,selectedGrade} = this.state;
    if(!this.validator.fieldValid("updateGrade")){
        this.validator.showMessageFor("updateGrade")
        this.forceUpdate();
    }
    else{
        this.setState({isLoading:true})
        const newUpdateGrade = {
            grade:updateGrade
        }
        GradeService.updateGrade(newUpdateGrade, selectedGrade)
            .then((response)=>{
                if(response.success){
                    sweetSuccessAlert("Success","Grade updated successfully","success")
                    this.setState({isLoading:false})
                    this.toggleUpdateGradeModal()
                    this.getAllGrades()
                }
                else{
                    this.setState({isLoading:false})
                    sweetStatusAlert("Oops",response.message,"info")
                }
                
            })
            .catch((error)=>{
                this.setState({isLoading:false})
                sweetErrorAlert("Error","Error while updating grade","error")
            })
    }
  }

  handleDeleteGrade = (id)=>{
    sweetConfirmAlert()
        .then(()=>{
            this.setState({isTableDataLoading:true})
            GradeService.deleteGrade(id)
                .then((response)=>{
                  if(response.success){
                    sweetSuccessAlert("Success","Grade deleted successfully","success")
                    this.getAllGrades()
                  }
                  else{
                    sweetSuccessAlert("Failed to delete grade!",response.message,"error")
                    this.getAllGrades()
                  }
                })
                .catch((error)=>{
                    sweetStatusAlert("Oops","Error while deleting grade","info")
                    this.setState({isTableDataLoading:false})
                })
        })
  }

  handleGradeChange = (e) => {
    const { name,value } = e.target;
    this.setState(() => ({ [name]: value }));
  };


  render() {
    const { 
        addGradeModal, 
        grade, 
        updateGradeModal,
        isTableDataLoading,
        isLoading,
        grades,
        updateGrade,
        limit
        } = this.state;

    return (
      <ContentWrapper>
        <div className="row justify-content-between align-items-center py-5 mb-3">
          <div className="col-md-6 col-6">
            <h3 className='text-nowrap'>Manage Grade</h3>
          </div>
          <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleAddGradeModal}
          >
            Add Grade
          </Button>
        </div>
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "75vw",
              height: "90vh"
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ width: "80vw", height: "90vh", overflowX: "auto", overflowY: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead">Grade</TableCell>
                  <TableCell className="thead d-flex justify-content-end">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {grades.length > 0 ? (
                  grades.map((grade) => (
                    <TableRow key={grade.grade_id}>
                      <TableCell className="tData">{grade.grade}</TableCell>
                      <TableCell className="tData" align="right">
                        {/* <Button
                          onClick={() => this.toggleUpdateGradeModal(grade)}
                          className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                          title="Edit Details"
                        /> */}

                        <Button
                          onClick={() => this.handleDeleteGrade(grade.grade_id)}
                          className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                          title="Delete"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <div style={{ height: "90vh" }}>No grades available</div>
                )}
              </TableBody>
            </Table>
          </div>
        )}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={addGradeModal}
          onHide={this.toggleAddGradeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Grade</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Enter grade</Form.Label>
                    <TextField
                      id="outlined-start-adornment"
                      type="number"
                      name="grade"
                      defaultValue={grade}
                      onChange={this.handleGradeChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Grade
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.validator.message(
                      "grade",
                      grade,
                      "required|numeric|min:1,num|max:13,num",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="8"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Student registration limit of the grade</Form.Label>
                    <TextField
                      id="outlined-start-adornment"
                      type="number"
                      name="limit"
                      defaultValue={limit}
                      onChange={this.handleGradeChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Set Limit
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.validator.message(
                      "limit",
                      limit,
                      "required|numeric|min:1,num",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button
                className="modal-btn"
                variant="primary"
                style={ addBtnStyle }
                onClick={this.handleSubmit}
              >
                Save
              </Button>
              <Button
                sx={{ mr: "20px" }}
                onClick={this.toggleAddGradeModal}
                className="modal-btn"
                id="modal-close-button"
                style={ closeBtnStyle }
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateGradeModal}
          onHide={this.toggleUpdateGradeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Grade</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      type="number"
                      name="updateGrade"
                      value={updateGrade ? updateGrade : ""}
                      onChange={this.handleGradeChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Grade
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.validator.message(
                      "updateGrade",
                      updateGrade,
                      "required|min:1,num|max:13,num",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button
                className="modal-btn"
                variant="primary"
                style={ addBtnStyle }
                onClick={() => this.handleEditSubmit()}
              >
                Save
              </Button>
              <Button
                className="modal-btn"
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleUpdateGradeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </ContentWrapper>
    )
  }
}

export default ManageGrade;
