export const isSameSenderMargin = (messages, m, i, userId, userTableId) => {
    if (
      i < messages.length - 1 &&
      messages[i + 1].sender.lg_user_id === m.sender.lg_user_id && messages[i + 1].sender.lg_user_table_id === m.sender.lg_user_table_id &&
      messages[i].sender.lg_user_id !== userId && messages[i].sender.lg_user_table_id !== userTableId
    )
      return 33;
    else if (
      (i < messages.length - 1 &&
        messages[i + 1].sender.lg_user_id !== m.sender.lg_user_id &&
        messages[i].sender.lg_user_id !== userId && messages[i].sender.lg_user_table_id !== userTableId) ||
      (i === messages.length - 1 && messages[i].sender.lg_user_id !== userId)
    )
      return 0;
    else return "auto";
  };

  export const isSameSender = (message,chat)=>{
    return(
      message.sender.lg_user_id === String(chat.lg_user_id) && message.sender.lg_user_table_id === String(chat.lg_user_table_id)
    );
  }

  export const getBackground = (message,chat)=>{

    return(
      message.sender.lg_user_id === String(chat.lg_user_id) && message.sender.lg_user_table_id === String(chat.lg_user_table_id)
    )
  }

  export const senderMargins=(message,chat)=>{
      return(
        message.sender.lg_user_id === String(chat.lg_user_id) && message.sender.lg_user_table_id === String(chat.lg_user_table_id)
      )
  }
