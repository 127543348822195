import React, { useEffect, useRef } from 'react';

export default function Table({ children, dataTableID }) {

    const TABLE_REF = useRef()

    useEffect(() => {
        if (window.KTDatatablesBasicBasic) window.KTDatatablesBasicBasic.init();
        init()
    }, []);

    function init() {
        var $ = window.$;
        
        if ($) {
            // begin first table
            var table = $(TABLE_REF.current);
            table.DataTable({
                responsive: true,

                // DOM Layout settings
                dom: `<"top d-flex justify-content-end"<f>><'row'<'col-sm-12'tr>>
                        <'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>`,

                lengthMenu: [5, 10, 25, 50],
                pageLength: 10,
                language: {
                    'lengthMenu': 'Display _MENU_',
                },

                // Order settings
                order: [[1, 'desc']],

                // headerCallback: function (thead, data, start, end, display) {
                //     thead.getElementsByTagName('th')[0].innerHTML = `
                // <label class="checkbox checkbox-single">
                //     <input type="checkbox" value="" class="group-checkable"/>
                //     <span></span>
                // </label>`;
                // },

                columnDefs: [
                    // {
                    //     targets: 0,
                    //     width: '30px',
                    //     className: 'dt-left',
                    //     orderable: false,
                    //     render: function (data, type, full, meta) {
                    //         return `
                    //         <label class="checkbox checkbox-single">
                    //             <input type="checkbox" value="" class="checkable"/>
                    //             <span></span>
                    //         </label>`;
                    //     },
                    // },
                    {
                        targets: -1,
                        orderable: false,
                        width: '125px',
                    },

                ],
            });

            table.on('change', '.group-checkable', function () {
                var set = $(this).closest('table').find('td:first-child .checkable');
                var checked = $(this).is(':checked');

                $(set).each(function () {
                    if (checked) {
                        $(this).prop('checked', true);
                        $(this).closest('tr').addClass('active');
                    }
                    else {
                        $(this).prop('checked', false);
                        $(this).closest('tr').removeClass('active');
                    }
                });
            });

            table.on('change', 'tbody tr .checkbox', function () {
                $(this).parents('tr').toggleClass('active');
            });


            // this code block is to adjust datatable resposive where it inside tab container.
            // ref: https://stackoverflow.com/questions/55460888/datatable-responsive-in-bootstrap-4-tab
            $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
                var table = $.fn.dataTable.fnTables(true);
                if (table.length > 0) {
                    $(table).dataTable().fnAdjustColumnSizing();
                }
            });

            
        }

    };



    return (

        <table className="table table-separate table-head-custom table-checkable" ref={TABLE_REF} id={dataTableID}>

            {children}

        </table>

    )
}
