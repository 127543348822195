import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const shareService = {
    shareQuestion: shareQuestion,
    stopSharing: stopSharing,
    getSharedUsers: getSharedUsers,
    unshareFromMe: unshareFromMe
};

async function getSharedUsers(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    let data = await fetch(`${config.oat_api_url}/question/shares/getSharedUsers/${obj.question_id}`, requestOptions);
    return APIHandler.handleResponse(data);
}


async function shareQuestion(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/question/shares/add/`, requestOptions).then(APIHandler.handleResponse);
}


async function stopSharing(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/question/shares/stopSharing/${obj.share_id}`, requestOptions).then(APIHandler.handleResponse);
}


async function unshareFromMe(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/question/shares/removeFromLibrary/`, requestOptions).then(APIHandler.handleResponse);
}