import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import Grid from "@mui/material/Grid";
import { ChatService } from "../../../service/manage/chat/chat.service.js";
import { Row, Col, Spinner, Button, Form } from "react-bootstrap";
import {
  sweetSuccessAlert,
  sweetStatusAlert,
  sweetConfirmAlert,
} from "../../../components/common/SweetAlert";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ErrorIcon from "@mui/icons-material/Error";
import SingleChat from "./components/SingleChat.js";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ChatListView from "./components/ChatListView.js";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import Card from "../../../components/common/Card.js";
import StickyCardBody from "../../../../src/components/common/StickyCardBody.js";

export default class ManageChat extends Component {
  constructor(props) {
    super(props);
    // this.createValidationsInstance();
    this.state = {
      showChatModal: false, //main chat modal
      chat: null, //which are passing through state of db function
      chat_details: null, //chat details of main db
      showGroupChatAdd: false, // chat create modal
      showChatInterface: false,
      studentList: [], // student list of node (enrolled)
      chatName: "",
      createChatLoading: false,
      chat_server_details: null,
      chat_backDrop: false,
      checked: [],
      memberListLoading: false,
      chatList: [],
      chat_server_list: [],
      selected_chat: null,
      selected_chat_index: null,
      socketState: 0,
      chatLastMessages: undefined,
      searchInput: "",
    };
  }

  componentDidMount() {
    const chat = this.props ? this.props.state : null;
    this.setState({ chat: chat, chat_backDrop: true }, () => {
      this.getChatDetails(chat.mid, chat.lg_user_id, chat.lg_user_table_id);
    });
  }

  handleChange = (event) => {
    let { name, value } = event.target;

    this.setState({ [name]: value });
  };

  getChatDetails = async (node_mid, lg_user_id, lg_user_table_id) => {
    this.setState({ chat_backDrop: true });
    ChatService.getChatInfo(node_mid, lg_user_id, lg_user_table_id)
      .then((response) => {
        if (response.data.chatIds.length > 0) {
          const chatIds = response.data?.chatIds?.map(
            (chatObj) => chatObj.chatId
          );
          this.setState(
            (prevState) => ({
              chat_details: response.data,
              chatList: chatIds,
            }),
            () => {
              if (this.state.chatList.length > 0) {
                const { chat_details } = this.state;
                //need to get chat details from mongo by passing chatid from response.data
                const userDetails = {
                  lg_user_id: String(chat_details.lg_user_id),
                  lg_user_table_id: String(chat_details.lg_user_table_id),
                };
                ChatService.getChats(this.state.chatList, userDetails)
                  .then((response) => {
                    if (response.code === 1) {
                      this.setState({
                        chat_server_list: response.results,
                        chat_backDrop: false,
                        showGroupChatAdd: false,
                        showChatInterface: true,
                        chatLastMessages: response.results
                          ? response.results.map((res) =>
                              res.latestMessage
                                ? res.latestMessage.content
                                : undefined
                            )
                          : undefined,
                      });
                    } else {
                      sweetStatusAlert(
                        "Oops",
                        "You are not added to any chat groups. please contact administrator!",
                        "info"
                      );
                      this.setState({
                        chat_server_list: [],
                        chat_backDrop: false,
                        showGroupChatAdd: false,
                        showChatInterface: true,
                      });
                    }
                  })
                  .catch((error) => {
                    this.setState({ chat_backDrop: false });
                    sweetStatusAlert(
                      "Error",
                      "Error getting chat details",
                      "error"
                    );
                  });
              } else {
                if (!!this.state.chat.privilege_add) {
                  this.setState({ chat_backDrop: false });
                  this.toggleCreateGroupModal();
                } else {
                  this.setState({ chat_backDrop: false });
                  sweetStatusAlert("Oops", "Chat is not created yet!", "info");
                }
              }
            }
          );
        } else {
          if (
            response.data &&
            response.data.lg_user_id &&
            response.data.lg_user_table_id
          ) {
            this.setState({ chat_details: response.data }, () => {
              if (!!this.state.chat.privilege_add) {
                this.setState({ chat_backDrop: false });
                this.toggleCreateGroupModal();
              } else {
                this.setState({ chat_backDrop: false });
                sweetStatusAlert("Oops", "Chat is not created yet!", "info");
              }
            });
          } else {
            this.setState({ chat_backDrop: false });
            sweetStatusAlert(
              "Oops",
              "Error getting details refresh screen!",
              "info"
            );
          }
        }
      })
      .catch((error) => {
        this.setState({ chat_backDrop: false });
        sweetStatusAlert("Failed", "Failed to get chat details", "error");
      });
  };

  toggleCreateGroupModal = () => {
    this.setState(
      (prevState) => {
        return {
          showChatModal: false,
          showGroupChatAdd: !prevState.showGroupChatAdd,
        };
      },
      () => {
        if (this.state.showGroupChatAdd) {
          this.setState({
            selected_chat: null,
            selected_chat_index: null,
            showChatInterface: false,
          });
          this.getNodeUsersDetailsForCreateChat();
        } else {
          this.setState({
            studentList: [],
            checked: [],
            createChatLoading: false,
            showChatInterface: true,
          });
        }
      }
    );
  };

  getNodeUsersDetailsForCreateChat = async () => {
    this.setState({ memberListLoading: true });
    ChatService.getMembersListOfNode(
      this.state.chat.mid,
      this.state.chat.form_list_id,
      this.state.chat.lg_user_id,
      this.state.chat.lg_user_table_id
    )
      .then((response) => {
        const members = JSON.parse(response.data.members);

        const filteredData = members.map((item) => {
          let lg_user_id = String(item.user_id);
          let lg_user_table_id = String(item.user_table_id);
          let name = item.first_name + " " + item.last_name;
          let user_code = String(item.user_code);

          // const match = item.data_concat.match(
          // /First Name:(.*?)\/Last Name:(.*?)$/
          // )
          // if (match) {
          // name = match[1] + " " + match[2]
          // }

          return {
            lg_user_id,
            lg_user_table_id,
            name,
            user_code
          };
        });
        this.setState(
          (prevState) => ({
            studentList: filteredData,
            checked: filteredData,
          }),
          () => {
            this.setState({ memberListLoading: false });
          }
        );
      })
      .catch((error) => {
        this.setState({ memberListLoading: false });
        sweetStatusAlert("Error", "Error getting members list", "error");
      });
  };

  createNewChat = async () => {
    this.setState({ createChatLoading: true });
    const { chatName } = this.state;
    const groupAdmin = {
      name: "Admin",
      lg_user_id: String(this.state.chat_details.lg_user_id),
      lg_user_table_id: String(this.state.chat_details.lg_user_table_id),
    };
    const users = this.state.checked;
    const { mid } = this.state.chat;

    if (!chatName) {
      this.setState({ createChatLoading: false });
      sweetStatusAlert("Error", "Please provide chat name", "info");
    } else {
      const newChat = {
        chatName: chatName,
        users: users,
        groupAdmin: groupAdmin,
      };

      ChatService.add(newChat)
        .then((response) => {
          if (response._id) {
            const addChat = {
              node_mid: mid,
              create_user_id: this.state.chat_details.lg_user_id,
              create_user_table_id: this.state.chat_details.lg_user_table_id,
              chatId: response._id,
            };

            ChatService.addChatInfo(addChat)
              .then((response) => {
                if (response.success) {
                  this.setState({
                    createChatLoading: false,
                    showGroupChatAdd: false,
                    showChatInterface: true,
                  });
                  this.getChatDetails(
                    this.state.chat.mid,
                    this.state.chat.lg_user_id,
                    this.state.chat.lg_user_table_id
                  );
                  sweetSuccessAlert(
                    "Success",
                    "Chat created successfully",
                    "success"
                  );
                }
              })
              .catch((error) => {
                this.setState({ createChatLoading: false });
                sweetStatusAlert(
                  "Oops",
                  "Error while adding chat to main DB",
                  "error"
                );
              });
          } else {
            this.setState({ createChatLoading: false });
            sweetStatusAlert("Error", "Error while creating group", "error");
          }
        })
        .catch((error) => {
          this.setState({ createChatLoading: false });
          sweetStatusAlert("Error", "Error while creating group", "error");
        });
    }
  };

  handleToggle = (value) => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checked: newChecked });
  };

  handleToggleAll = (items) => () => {
    const { checked } = this.state;
    if (checked.length === 0) {
      this.setState({ checked: items });
    } else if (items.length === checked.length) {
      this.setState({ checked: [] });
    } else {
      const uncheckedItems = items.filter((item) => !checked.includes(item));
      this.setState({
        checked: [...checked, ...uncheckedItems],
      });
    }
  };

  updateChatState = (newValue, key) => {
    const { selected_chat_index, chat_server_list } = this.state;

    const updatedChatServerList = [...chat_server_list];

    updatedChatServerList[selected_chat_index] = newValue;

    this.setState({
      selected_chat: newValue,
      chat_server_list: updatedChatServerList,
    });
  };

  toggleChatModal = (chat) => {
    const { showChatModal } = this.state;
    if (!showChatModal) {
      this.setState({ selectedFormDetail: null }, () => {
        this.setState({ chat: chat, chat_backDrop: true });
        this.getChatDetails(chat.mid, chat.lg_user_id, chat.lg_user_table_id);
      });
    } else {
      this.setState((prevState) => ({
        showChatModal: !prevState.showChatModal,
      }));
    }
  };

  setChatLastMessages = (message) => {
    this.setState((prev) => {
      let modChatLastMesssages = prev.chatLastMessages;
      modChatLastMesssages[this.state.selected_chat_index] = message;
      return { chatLastMessages: modChatLastMesssages };
    });
  };

  setSelectedChat = (chat, index) => {
    if (
      this.state.socketState === 1 &&
      this.state.chat_details &&
      this.state.selected_chat?._id != chat._id
    ) {
      this.setState({ socketState: 2 }, () => {
        this.setState(
          { selected_chat: chat, selected_chat_index: index },
          () => {
            if (this.state.selected_chat) {
              this.setState({ showChatModal: true });
            }
          }
        );
      });
    } else {
      this.setState({ selected_chat: chat, selected_chat_index: index }, () => {
        if (this.state.selected_chat) {
          this.setState({ showChatModal: true });
        }
      });
    }
  };

  setSocketState = (socket) => {
    this.setState({ socketState: socket });
  };

  handleSearchChange = (event) => {
    this.setState({ searchInput: event.target.value });
  };

  render() {
    const {
      isLoading,
      loadingNode,
      hierarchyGridTopData,
      hierarchyGridBottomData,
      groupTitle,
      mcode,
      title,
      displayOrder,
      showAboutUpdateModal,
      about,
      isAboutLoading,
      about_details,
      showChatModal,
      chat,
      chat_details,
      createChatLoading,
      chat_server_details,
      chat_backDrop,
      checked,
      studentList,
      memberListLoading,
      showGroupChatAdd,
      chat_server_list,
      selected_chat,
      showChatInterface,
      socketState,
      chatLastMessages,
      searchInput,
    } = this.state;
    const { breadcrumb2 } = this.props.state;

    const filteredStudents = studentList.filter((student) => {
      return (
        student.name.toLowerCase().includes(searchInput.toLowerCase()) ||
        student.user_code.toLowerCase().includes(searchInput.toLowerCase()) 
      );
    });

    return (
      <>
        <div style={{ overflow: "auto", height: "90vh" }}>
          <ContentWrapper>
            {/* <Card> */}

            {chat && (
              <div>
                <CardHeader>
                  <div className="p-0 d-flex justify-content-end">
                    {chat.privilege_add &&
                    showGroupChatAdd &&
                    !showChatInterface ? (
                      <Button
                        onClick={this.toggleCreateGroupModal}
                        style={{
                          display: chat.privilege_add ? "block" : "none",
                        }}
                      >
                        View Chats
                      </Button>
                    ) : (
                      <Button
                        onClick={this.toggleCreateGroupModal}
                        style={{
                          display: chat.privilege_add ? "block" : "none",
                        }}
                      >
                        Add Chat Group
                      </Button>
                    )}
                  </div>
                </CardHeader>

                <CardBody>
                  {showGroupChatAdd &&
                    !showChatInterface &&
                    (createChatLoading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Spinner />
                      </div>
                    ) : (
                      <Form>
                        <Row className="my-5">
                          <Form.Group
                            as={Col}
                            md="8"
                            sm="12"
                            controlId="exampleForm.ControlInput1"
                            className="d-flex align-items-end"
                          >
                            <Form.Label className="text-nowrap mr-3">
                              Group Name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="chatName"
                              placeholder="Group Name"
                              onChange={this.handleChange}
                            />
                          </Form.Group>

                          {checked.length > 1 ? (
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId="exampleForm.ControlInput1"
                              className="px-0"
                            >
                              <div>
                                {/* <Form.Label>Create Group</Form.Label> */}
                                <Button
                                  variant="primary"
                                  onClick={() => this.createNewChat()}
                                >
                                  Create
                                </Button>
                              </div>
                            </Form.Group>
                          ) : (
                            <i
                              className="col-md-12"
                              style={{ color: "red", fontSize: "10px" }}
                            >
                              Please select atleast 2 users to create chat
                            </i>
                          )}
                        </Row>

                        <StickyCardBody>
                          <List
                            as={Col}
                            md="12"
                            subheader={
                              <StickyCardBody
                                component="div"
                                id="nested-list-subheader"
                              >
                                <Row className="my-5">
                                  <h6 className="px-0 add-remove-members">
                                    <b>Group Chat Members</b>
                                  </h6>
                                </Row>
                                <Row className="my-5">
                                  <TextField
                                    id="standard-search"
                                    label="Search users"
                                    type="search"
                                    variant="standard"
                                    value={searchInput}
                                    onChange={this.handleSearchChange}
                                    className="w-100"
                                  />
                                </Row>
                              </StickyCardBody>
                            }
                            sx={{
                              width: "100%",
                              // maxWidth: 360,
                              bgcolor: "background.paper",
                              position: "relative",
                              overflow: "auto",
                              // maxHeight: 500,
                              "& ul": { padding: 0, fontFamily: "Poppins" },
                            }}
                          >
                            <div id="nested-list-subheader">
                              <Checkbox
                                onClick={this.handleToggleAll(studentList)}
                                checked={checked.length > 0}
                                disabled={studentList.length === 0}
                                inputProps={{
                                  "aria-label": "all items selected",
                                }}
                                style={{
                                  color: "#5458AF",
                                }}
                              />
                              {checked.length +
                                "/" +
                                studentList.length +
                                " " +
                                "selected"}
                            </div>
                            <ListSubheader
                              component="div"
                              id="nested-list-subheader"
                              disablePadding
                            ></ListSubheader>
                            {!memberListLoading ? (
                              filteredStudents.length > 0 ? (
                                filteredStudents.map((item) => {
                                  return (
                                    <ListItem
                                      key={item.lg_user_id}
                                      secondaryAction={
                                        <Checkbox
                                          edge="end"
                                          onChange={this.handleToggle(item)}
                                          checked={checked.indexOf(item) !== -1}
                                          inputProps={{
                                            "aria-labelledby": item,
                                          }}
                                          style={{
                                            color: "#5458AF",
                                          }}
                                        />
                                      }
                                      disablePadding
                                    >
                                      <ListItemButton>
                                        <ListItemAvatar>
                                          <Avatar
                                            alt={item.name ? item.name : ""}
                                            src={item.name ? item.name : ""}
                                          />
                                        </ListItemAvatar>
                                        <ListItemText
                                          id={
                                            item.lg_user_id
                                              ? item.lg_user_id
                                              : null
                                          }
                                          primary={
                                            item.name ? item.name : "Unknown"
                                          }
                                          secondary={
                                            item.user_code
                                              ? item.user_code
                                              : "unknown"
                                          }
                                        />
                                      </ListItemButton>
                                    </ListItem>
                                  );
                                })
                              ) : (
                                <div>
                                  <ListItemButton component="a">
                                    <ListItemIcon sx={{ fontSize: 20 }}>
                                      <ErrorIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ my: 0 }}
                                      primary="No members found"
                                      primaryTypographyProps={{
                                        fontSize: 20,
                                        fontWeight: "medium",
                                        letterSpacing: 0,
                                      }}
                                    />
                                  </ListItemButton>
                                </div>
                              )
                            ) : (
                              <Box sx={{ width: 300 }}>
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                              </Box>
                            )}
                          </List>
                        </StickyCardBody>
                      </Form>
                    ))}
                  {!chat_backDrop && showChatInterface && !showGroupChatAdd && (
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item md={5} xs={12}>
                          <Item>
                            {chat_server_list.length > 0 ? (
                              <ChatListView
                                chatLastMessages={chatLastMessages}
                                setSelectedChat={this.setSelectedChat}
                                chats={chat_server_list}
                                selected_chat={selected_chat}
                              />
                            ) : (
                              <div>
                                <span className="stu-variant-class label label-lg label-light-primary label-inline text-left">
                                  <InfoIcon
                                    sx={{
                                      fontSize: 20,
                                      color: "var(--maincolor)",
                                      marginRight: "8px",
                                    }}
                                  />{" "}
                                  You are not added to any chat groups. please
                                  contact the administrator!
                                </span>
                              </div>
                            )}
                          </Item>
                        </Grid>
                        <Grid item md={7} xs={12}>
                          <Item>
                            {showChatModal && selected_chat ? (
                              <SingleChat
                                setChatLastMessages={this.setChatLastMessages}
                                showChatModal={showChatModal}
                                chat={chat}
                                chat_details={chat_details}
                                chat_server_details={selected_chat}
                                updateState={this.updateChatState}
                                setSocketState={this.setSocketState}
                                socketState={socketState}
                              />
                            ) : (
                              <div>
                                <span className="stu-variant-class label label-lg label-light label-inline">
                                  No Chat Selected
                                </span>
                              </div>
                            )}
                          </Item>
                        </Grid>
                        {/* <Grid item xs={3}>
                      <Item>xs=3</Item>
                    </Grid> */}
                      </Grid>
                      <p
                        className="mt-5 stu-variant-class label label-lg label-light-warning label-inline"
                        style={{ fontWeight: "normal" }}
                      >
                        Alert: This chat app is currently in test mode! Kindly
                        note, as it is based open-source platform, we're not
                        fully responsible for privacy & efficiency. Thank you
                        for your understanding!
                      </p>
                    </Box>
                  )}
                </CardBody>
              </div>
            )}
            {/* </Card> */}
          </ContentWrapper>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={chat_backDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </>
    );
  }
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
