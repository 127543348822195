import { studentUserService } from "../../service/user_management/student_user.service";
import {store} from "../../helper/redux_store";
import { userTypes } from "../../reducers/types";

export const studentUserActions = {
    getAllStudentUsers,
    createStudentUserMap,
    createStudentUserRoleMap
};

function getAllStudentUsers() {
    return async dispatch => {
        try {
            dispatch(get_studentusers());
            dispatch(get_studentusers_request());
            let studentusers = await studentUserService.getAllStudentUsers();
            dispatch(get_studentusers_success(studentusers.data));
        } catch (error) {
            dispatch(get_studentusers_failure(error));
        }
    };

    function get_studentusers() {
        return {type: userTypes.GET_ALL_STUDENTUSERS}
    }

    function get_studentusers_request() {
        return {type: userTypes.GET_ALL_STUDENTUSERS_REQUEST}
    }

    function get_studentusers_success(data) {
        return {type: userTypes.GET_ALL_STUDENTUSERS_SUCCESS, studentusers: data}
    }

    function get_studentusers_failure(error) {
        return {type: userTypes.GET_ALL_STUDENTUSERS_FAILURE, error:error}
    }
}

function createStudentUserMap() {
    return async dispatch => {
        try {
            dispatch(get_studentusers_request());
            let data = await studentUserService.getAllStudentUsers();
            dispatch(get_studentusers_success(data.data));
            await create_studentuser_map(data.data);
        } catch(error) {
            dispatch(get_studentusers_failure(error));
        }
    }

    async function create_studentuser_map(studentusers) {
        let map = {}
        let studentuser;
        for (studentuser of studentusers) {
            map[studentuser['user_id']] = studentuser
        }

        let studentuser_data = {map, studentusers}
        store.dispatch({type: userTypes.CREATE_STUDENTUSER_MAP, studentuser_data});
    }

    function get_studentusers_request() {
        return {type: userTypes.GET_ALL_STUDENTUSERS_SUCCESS}
    }

    function get_studentusers_success(data) {
        return {type: userTypes.GET_ALL_STUDENTUSERS_SUCCESS, studentusers: data}
    }

    function get_studentusers_failure(error) {
        return {type: userTypes.GET_ALL_STUDENTUSERS_FAILURE, error}
    }
}

function createStudentUserRoleMap() {
    return async dispatch => {

        try {
            dispatch(get_student_user_roles_request());
            let student_user_roles = await studentUserService.getStudentUserRoles();
            dispatch(get_student_user_roles_success(student_user_roles.data));
            dispatch(create_student_user_roles_map(student_user_roles.data));
        } catch (error) {
            dispatch(get_student_user_roles_failure(error));
        }
    }

    function get_student_user_roles_request() {
        return {type: userTypes.GET_ALL_STUDENT_USER_ROLES_REQUEST}
    }

    function get_student_user_roles_success(data) {
        return {type: userTypes.GET_ALL_STUDENT_USER_ROLES_SUCCESS, data}
    }

    function create_student_user_roles_map(student_user_roles) {
        let map = {}
        let student_user_role;
        for (student_user_role of student_user_roles) {
            map[student_user_role['user_role_id']] = student_user_role
        }
        return {type: userTypes.CREATE_STUDENT_USER_ROLES_MAP, map}
    }

    function get_student_user_roles_failure(error) {
        return {type: userTypes.GET_ALL_STUDENT_USER_ROLES_FAILURE, error}
    }
}
