import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const ProgressService = {
    add,
    getProgressDetails,
    deleteProgress,
    updateEndDate
};

async function getProgressDetails(node_mid) {
    const configOptions = {
      method: "GET",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/progress-catchups/getAllByNode/${node_mid}`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }

  async function add(newProgress) {
    const configOptions = {
      method: "POST",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newProgress),
    };
  
    const response = await fetch(
      `${config.oat_api_url}/manage/progress-catchups/add`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }

  async function deleteProgress(id) {
    const configOptions = {
      method: "DELETE",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
  
    const response = await fetch(
      `${config.oat_api_url}/manage/progress-catchups/delete/${id}`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }
async function updateEndDate(date,node_mid) {
    const requestOptions = {
        method: "PUT",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: date,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/progress-catchups/update/${node_mid}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}