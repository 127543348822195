import React, { Component } from "react";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import CardTitle from "../../../components/common/CardTitle";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import "../../../components/admin/css/common.css";
import sweetAlert, {
  sweetConfirmAlert,
  sweetStatusAlert,
  sweetAlertInfo
} from "../../../components/common/SweetAlert";
import SimpleReactValidator from "simple-react-validator";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { LevelService } from "../../../service/manage/subscription/level.service";
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class Levels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      showModal: false,
      isLoading: false,
      isTableDataLoading: true,
      isModified: false,
      selectedStudentId: "",
      selectedStudentTable: "",
      selectedStudent: null,
      search_id: "",
      isStudentsLoading: false,
      isSubmitting: false,
      registration_id: "",
      editVisibility: false,
      addVisibility: false,
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      levels: null,
      level_id: "",
      name: "",
      definition: "",
      description: "",
      userType: "",
      levelPrivilege: null,
      form_list_id: "",
    };

    this.createValidationsInstance();
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const {
      privilege_add,
      privilege_edit,
      breadcrumb2,
      nodeTitle,
      flg_list_active,
      form_list_id,
    } = this.props.props;
    this.getVisibilityCheck(privilege_add, privilege_edit);
    this.getAllLevels();
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle,
      flg_list_active,
      userType: sessionStorage.getItem("userType"),
      levelPrivilege: this.props.levelPrivilege,
      form_list_id: form_list_id,
    });
  }

  getAllLevels() {
    const mid = 100;
    LevelService.getAllLevels(mid)
      .then((res) => {
        if (res.success) {
          this.setState({ levels: res.data.levels });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Levels. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getVisibilityCheck(privilege_add, privilege_edit) {
    if (privilege_add == "1") {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_edit == "1") {
      this.setState({
        editVisibility: true,
      });
    }
  }

  submitLevel = () => {
    if (this.validator.allValid()) {
      this.setState({ showInsertModal: false, isLoading: true });
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");

      const { name, definition, description, form_list_id } = this.state;

      let obj = {
        node_mid: mid,
        name: name,
        definition: definition,
        description: description,
        user_id: session_id,
        user_table_id: table_log_id,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
        form_list_id: form_list_id,
      };
      LevelService.createLevel(JSON.stringify(obj))
        .then((res) => {
          if (res.success) {
            this.setState({ showInsertModal: false });
            this.getAllLevels();
            sweetStatusAlert("Success!", res.message, "success");
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert("Failed !", "Unable to add the Level!", "error");
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  updateLevel = () => {
    if (this.validator.allValid()) {
      this.setState({ showModal: false, isLoading: true });
      const { name, definition, description, level_id, form_list_id } =
        this.state;
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");

      let obj = {
        node_mid: mid,
        name: name,
        definition: definition,
        description: description,
        user_id: session_id,
        user_table_id: table_log_id,
        form_list_id: form_list_id,
      };
      LevelService.updateLevel(JSON.stringify(obj), level_id)
        .then((res) => {
          if (res.success) {
            this.setState({ showModal: false });
            this.getAllLevels();
            sweetStatusAlert("Success!", res.message, "success");
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert("Failed !", "Unable to add the Level!", "error");
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  deleteLevel = (level_id) => {
    sweetConfirmAlert(
      "Are you sure?",
      "Do you want to delete this Level?",
      "warning"
    ).then(() => {
      this.setState({ isLoading: true });
      LevelService.deleteLevel(level_id, this.state.form_list_id)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ showModal: false });
            this.getAllLevels();
            sweetStatusAlert("Success!", res.message, "success");
          } else if(res.status === 400){
            sweetAlertInfo(
              "",
              "Cannot delete level. This level already in use.",
            );
          }
          else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert("Failed !", "Unable to add the Level!", "error");
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    });
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  toggleAddLevelModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        name: "",
        definition: "",
        description: "",
      }),
      () => {}
    );
  };

  toggleUpdateLevelModal = (level) => {
    this.setState(
      (prevState) => ({
        showModal: !prevState.showModal,
        name: "",
        definition: "",
        description: "",
      }),
      () => {
        if (this.state.showModal) {
          this.setState({
            level_id: level.level_id,
            name: level.name,
            definition: level.definition,
            description: level.description,
          });
        }
      }
    );
  };

  render() {
    const {
      isLoading,
      isTableDataLoading,
      editVisibility,
      levels,
      name,
      definition,
      description,
      levelPrivilege,
    } = this.state;
    this.validator.purgeFields();

    if (!levelPrivilege) {
      return (
        <div>
          <h4>Privilege not found for this section</h4>
        </div>
      );
    }
    return (
      <>
        <Card>
          <CardHeader>
            <div>
              {/* <CardTitle cardLabel={history.location.state.form_display_title} /> */}
              <CardTitle cardLabel={"Levels"} />
              {/* <h3 style={{ color: 'gray' }}>{(breadcrumb2 || nodeTitle) ? `(${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle})` : undefined}</h3> */}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {levelPrivilege.addVisibility && (
                <Button
                  className="insert-btn"
                  variant="primary"
                  onClick={this.toggleAddLevelModal}
                >
                  Add Level
                </Button>
              )}
            </div>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div className="loading-container">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div style={{ overflow: "Auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Level Id
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Definition
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Description
                      </TableCell>
                      {(levelPrivilege.addVisibility ||
                        levelPrivilege.editVisibility ||
                        levelPrivilege.deleteVibility) && (
                        <TableCell
                          className="thead"
                          style={{ textAlign: "center" }}
                        >
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {levels.length > 0 &&
                      levels.map((level) => (
                        <TableRow key={level.level_id}>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {level.level_id}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {level.name}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {level.definition}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {level.description}
                          </TableCell>
                          {(levelPrivilege.addVisibility ||
                            levelPrivilege.editVisibility ||
                            levelPrivilege.deleteVibility) && (
                            <TableCell
                              className="tData"
                              style={{ textAlign: "center" }}
                            >
                              <div className="btn-group">
                                {levelPrivilege.editVisibility && (
                                  <Button
                                    className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                    title="Edit Details"
                                    onClick={() =>
                                      this.toggleUpdateLevelModal(level)
                                    }
                                  />
                                )}
                                {levelPrivilege.deleteVibility && (
                                  <Button
                                    className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                    title="Delete Details"
                                    onClick={() =>
                                      this.deleteLevel(level.level_id)
                                    }
                                  />
                                )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() => this.setState({ showInsertModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new Level</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={name}
                    placeholder="Name"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("name", name, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Definition*</Form.Label>
                  <Form.Control
                    type="text"
                    name="definition"
                    defaultValue={definition}
                    placeholder="Definition"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "definition",
                      definition,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Description*</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    defaultValue={description}
                    placeholder="Description"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "description",
                      description,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.setState({ showInsertModal: false })}
                >
                  Close
                </Button>
                {editVisibility && (
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.submitLevel}
                  >
                    Save changes
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Level</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={name}
                    placeholder="Name"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("name", name, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Definition*</Form.Label>
                  <Form.Control
                    type="text"
                    name="definition"
                    defaultValue={definition}
                    placeholder="Definition"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "definition",
                      definition,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Description*</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    defaultValue={description}
                    placeholder="Description"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "description",
                      description,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.setState({ showModal: false })}
                >
                  Close
                </Button>
                {editVisibility && (
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.updateLevel}
                  >
                    Save changes
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
