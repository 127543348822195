import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const ManageDashboardService = {
  getContent,
  getStatistics,
  getStatisticsBottom
};

async function getContent(user_id, user_table_id, table, user_type) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-dashboard-content/${user_id}/${user_table_id}/${table}/${user_type}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getStatistics(node_mid, user_id, user_table_id, v_selection, d_type) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-dashboard-statistics/${node_mid}/${user_id}/${user_table_id}/${v_selection}/${d_type}`,
    requestOptions
  ).then(APIHandler.handleResponse);
  // return APIHandler.handleResponse(response);
}

async function getStatisticsBottom(node_mid, user_id, user_table_id, v_selection) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-dashboard-statistics-bottom/${node_mid}/${user_id}/${user_table_id}/${v_selection}`,
    requestOptions
  ).then(APIHandler.handleResponse);
  // return APIHandler.handleResponse(response);
}

