import { libraryConstants, testConstants } from "../../helper/constants";
import { examService } from "../../service/candidate/examService";
import { EnrolledPaperService } from "../../service/manage/paper/enrolled_paper.service";
import { AssessExamService } from "../../service/assessment/assessExamService";

export function listNodeAssessments(node_id) {
  return async (dispatch) => {
    try {
      dispatch(list_node_assessments());
      dispatch(list_node_assessments_request(node_id));

      let node_assessments =
        await EnrolledPaperService.getAllEnrolledPaperForNode(node_id);
      dispatch(list_node_assessments_success(node_assessments.data));
    } catch (error) {
      dispatch(list_node_assessments_failure(error));
    }
  };

  function list_node_assessments() {
    return { type: libraryConstants.LIST_NODE_ASSESSMENTS };
  }

  function list_node_assessments_request(node_id) {
    return { type: libraryConstants.LIST_NODE_ASSESSMENTS_REQUEST, node_id };
  }

  function list_node_assessments_success(node_assessments) {
    return {
      type: libraryConstants.LIST_NODE_ASSESSMENTS_SUCCESS,
      node_assessments,
    };
  }

  function list_node_assessments_failure(error) {
    return { type: libraryConstants.LIST_NODE_ASSESSMENTS_FAILURE, error };
  }
}

export function listAssessments(user_id) {
  return async (dispatch) => {
    try {
      dispatch(list_assessments());
      dispatch(list_assessments_request(user_id));

      let assessments = await examService.listAssessments(user_id);
      dispatch(list_assessments_success(assessments.data));
    } catch (error) {
      dispatch(list_assessments_failure(error));
    }
  };

  function list_assessments() {
    return { type: libraryConstants.LIST_ASSESSMENTS };
  }

  function list_assessments_request(user_id) {
    return { type: libraryConstants.LIST_ASSESSMENTS_REQUEST, user_id };
  }

  function list_assessments_success(data) {
    return { type: libraryConstants.LIST_ASSESSMENTS_SUCCESS, data };
  }

  function list_assessments_failure(error) {
    return { type: libraryConstants.LIST_ASSESSMENTS_FAILURE, error };
  }
}

export function listChildAssessments(parent_user_id) {
  return async (dispatch) => {
    try {
      dispatch(list_child_assessments());
      dispatch(list_child_assessments_request(parent_user_id));

      let child_assessments = await examService.listChildAssessments(
        parent_user_id
      );
      dispatch(list_child_assessments_success(child_assessments.data));
    } catch (error) {
      dispatch(list_child_assessments_failure(error));
    }
  };

  function list_child_assessments() {
    return { type: libraryConstants.LIST_CHILD_ASSESSMENTS };
  }

  function list_child_assessments_request(parent_user_id) {
    return {
      type: libraryConstants.LIST_CHILD_ASSESSMENTS_REQUEST,
      parent_user_id,
    };
  }

  function list_child_assessments_success(data) {
    return { type: libraryConstants.LIST_CHILD_ASSESSMENTS_SUCCESS, data };
  }

  function list_child_assessments_failure(error) {
    return { type: libraryConstants.LIST_CHILD_ASSESSMENTS_FAILURE, error };
  }
}

export function getNodeEnrolledPapers(
  user_id,
  node_mid,
  limit,
  offset,
  sort_column,
  sort_direction,
  search_term,
  selectedGrades,
  selectedChapters,
  selectedsubjects,
  selectedAuthors,
  selectedYears,
  selectedCategories,
  selectedTerms,
  selectedDifficulties,
  selectedLanguages,
  selecCandStatus
) {
  return async (dispatch) => {
    try {
      dispatch(get_node_enrolled_papers());
      dispatch(
        get_node_enrolled_papers_request(
          user_id,
          node_mid,
          limit,
          offset,
          sort_column,
          sort_direction,
          search_term,
          selectedGrades,
          selectedChapters,
          selectedsubjects,
          selectedAuthors,
          selectedYears,
          selectedCategories,
          selectedTerms,
          selectedDifficulties,
          selectedLanguages,
          selecCandStatus
        )
      );

      let node_enrolled_papers = await AssessExamService.getEnrolledNodeExams(
        user_id,
        node_mid,
        limit,
        offset,
        sort_column,
        sort_direction,
        search_term,
        selectedGrades,
        selectedChapters,
        selectedsubjects,
        selectedAuthors,
        selectedYears,
        selectedCategories,
        selectedTerms,
        selectedDifficulties,
        selectedLanguages,
        selecCandStatus
      );
      dispatch(get_node_enrolled_papers_success(node_enrolled_papers.data));
    } catch (error) {
      dispatch(get_node_enrolled_papers_failure(error));
    }
  };

  function get_node_enrolled_papers() {
    return { type: libraryConstants.GET_ENROLLED_NODE_EXAMS };
  }

  function get_node_enrolled_papers_request(
    user_id,
    node_mid,
    limit,
    offset,
    sort_column,
    sort_direction,
    search_term,
    selectedGrades,
    selectedChapters,
    selectedsubjects,
    selectedAuthors,
    selectedYears,
    selectedCategories,
    selectedTerms,
    selectedDifficulties,
    selectedLanguages,
    selecCandStatus
  ) {
    return {
      type: libraryConstants.GET_ENROLLED_NODE_EXAMS_REQUEST,
      user_id,
      node_mid,
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      selecCandStatus
    };
  }

  function get_node_enrolled_papers_success(data) {
    return { type: libraryConstants.GET_ENROLLED_NODE_EXAMS_SUCCESS, data };
  }

  function get_node_enrolled_papers_failure(error) {
    return { type: libraryConstants.GET_ENROLLED_NODE_EXAMS_FAILURE, error };
  }
}

export function getNodeEnrolledPapersForUser(
  user_id,
  node_mid,
  limit,
  offset,
  sort_column,
  sort_direction,
  search_term,
  selectedGrades,
  selectedChapters,
  selectedsubjects,
  selectedAuthors,
  selectedYears,
  selectedCategories,
  selectedTerms,
  selectedDifficulties,
  selectedLanguages,
  added_by,
  selecCandStatus
) {
  return async (dispatch) => {
    try {
      dispatch(get_node_enrolled_papers_for_user());
      dispatch(
        get_node_enrolled_papers_for_user_request(
          user_id,
          node_mid,
          limit,
          offset,
          sort_column,
          sort_direction,
          search_term,
          selectedGrades,
          selectedChapters,
          selectedsubjects,
          selectedAuthors,
          selectedYears,
          selectedCategories,
          selectedTerms,
          selectedDifficulties,
          selectedLanguages,
          added_by,
          selecCandStatus
        )
      );

      let node_enrolled_papers_for_user =
        await AssessExamService.getEnrolledNodeExamsForUser(
          user_id,
          node_mid,
          limit,
          offset,
          sort_column,
          sort_direction,
          search_term,
          selectedGrades,
          selectedChapters,
          selectedsubjects,
          selectedAuthors,
          selectedYears,
          selectedCategories,
          selectedTerms,
          selectedDifficulties,
          selectedLanguages,
          added_by,
          selecCandStatus
        );
      dispatch(
        get_node_enrolled_papers_for_user_success(node_enrolled_papers_for_user.data)
      );
    } catch (error) {
      dispatch(get_node_enrolled_papers_for_user_failure(error));
    }
  };

  function get_node_enrolled_papers_for_user() {
    return { type: libraryConstants.GET_ENROLLED_NODE_EXAMS_FOR_USER };
  }

  function get_node_enrolled_papers_for_user_request(
    user_id,
    node_mid,
    limit,
    offset,
    sort_column,
    sort_direction,
    search_term,
    selectedGrades,
    selectedChapters,
    selectedsubjects,
    selectedAuthors,
    selectedYears,
    selectedCategories,
    selectedTerms,
    selectedDifficulties,
    selectedLanguages,
    added_by,
    selecCandStatus
  ) {
    return {
      type: libraryConstants.GET_CHILD_ENROLLED_NODE_EXAMS_REQUEST,
      user_id,
      node_mid,
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      added_by,
      selecCandStatus
    };
  }

  function get_node_enrolled_papers_for_user_success(data) {
    return {
      type: libraryConstants.GET_ENROLLED_NODE_EXAMS_SUCCESS_FOR_USER,
      data,
    };
  }

  function get_node_enrolled_papers_for_user_failure(error) {
    return {
      type: libraryConstants.GET_ENROLLED_NODE_EXAMS_FAILURE_FOR_USER,
      error,
    };
  }
}

export function getNodeEnrolledChildPapers(
  parent_user_id,
  child_user_id,
  node_mid,
  limit,
  offset,
  sort_column,
  sort_direction,
  search_term,
  selectedGrades,
  selectedChapters,
  selectedsubjects,
  selectedAuthors,
  selectedYears,
  selectedCategories,
  selectedTerms,
  selectedDifficulties,
  selectedLanguages,
  selecCandStatus
) {
  return async (dispatch) => {
    try {
      dispatch(get_node_enrolled_child_papers());
      dispatch(
        get_node_enrolled_child_papers_request(
          parent_user_id,
          child_user_id,
          node_mid,
          limit,
          offset,
          sort_column,
          sort_direction,
          search_term,
          selectedGrades,
          selectedChapters,
          selectedsubjects,
          selectedAuthors,
          selectedYears,
          selectedCategories,
          selectedTerms,
          selectedDifficulties,
          selectedLanguages,
          selecCandStatus
        )
      );

      let node_enrolled_child_papers =
        await AssessExamService.getChildEnrolledNodeExams(
          parent_user_id,
          child_user_id,
          node_mid,
          limit,
          offset,
          sort_column,
          sort_direction,
          search_term,
          selectedGrades,
          selectedChapters,
          selectedsubjects,
          selectedAuthors,
          selectedYears,
          selectedCategories,
          selectedTerms,
          selectedDifficulties,
          selectedLanguages,
          selecCandStatus
        );
      dispatch(
        get_node_enrolled_child_papers_success(node_enrolled_child_papers.data)
      );
    } catch (error) {
      dispatch(get_node_enrolled_child_papers_failure(error));
    }
  };

  function get_node_enrolled_child_papers() {
    return { type: libraryConstants.GET_CHILD_ENROLLED_NODE_EXAMS };
  }

  function get_node_enrolled_child_papers_request(
    parent_user_id,
    child_user_id,
    node_mid,
    limit,
    offset,
    sort_column,
    sort_direction,
    search_term,
    selectedGrades,
    selectedChapters,
    selectedsubjects,
    selectedAuthors,
    selectedYears,
    selectedCategories,
    selectedTerms,
    selectedDifficulties,
    selectedLanguages,
    selecCandStatus
  ) {
    return {
      type: libraryConstants.GET_CHILD_ENROLLED_NODE_EXAMS_REQUEST,
      parent_user_id,
      child_user_id,
      node_mid,
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      selecCandStatus
    };
  }

  function get_node_enrolled_child_papers_success(data) {
    return {
      type: libraryConstants.GET_CHILD_ENROLLED_NODE_EXAMS_SUCCESS,
      data,
    };
  }

  function get_node_enrolled_child_papers_failure(error) {
    return {
      type: libraryConstants.GET_CHILD_ENROLLED_NODE_EXAMS_FAILURE,
      error,
    };
  }
}

export function getAssessment(assessment_id) {
  return async (dispatch) => {
    try {
      dispatch(get_assessment());
      dispatch(get_assessment_request(assessment_id));

      let data = await examService.getAssessment(assessment_id);
      dispatch(get_assessment_success(data.data));
    } catch (error) {
      dispatch(get_assessment_failure(error));
    }
  };

  function get_assessment() {
    return { type: libraryConstants.GET_ASSESSMENT };
  }

  function get_assessment_request(assessment_id) {
    return { type: libraryConstants.GET_ASSESSMENT_REQUEST, assessment_id };
  }

  function get_assessment_success(data) {
    return { type: libraryConstants.GET_ASSESSMENT_SUCCESS, data };
  }

  function get_assessment_failure(error) {
    return { type: libraryConstants.GET_ASSESSMENT_FAILURE, error };
  }
}

export function getReport(obj) {
  return async (dispatch) => {
    try {
      dispatch(get_report(obj));
      dispatch(get_report_request(obj.candidate_id));

      let report = await examService.getReport(
        obj.candidate_id,
        obj.attempt_no
      );
      
      report.data.assessment["remark_content"] = null;
      if (report.data.assessment.assessor_remarks) {
        let remarks = await examService.getRemarks(
          report.data.assessment.assessor_remarks
        );
        report.data.assessment.remark_content = remarks;
      }
      dispatch(get_report_success(report.data));
    } catch (error) {
      dispatch(get_report_failure(error));
    }
  };

  function get_report(assessment) {
    return { type: libraryConstants.GET_REPORT, assessment };
  }

  function get_report_request(candidate_id) {
    return { type: libraryConstants.GET_REPORT_REQUEST, candidate_id };
  }

  function get_report_success(data) {
    return { type: libraryConstants.GET_REPORT_SUCCESS, data };
  }

  function get_report_failure(error) {
    return { type: libraryConstants.GET_REPORT_FAILURE, error };
  }
}

export function openAssessment(assessment) {
  return (dispatch) => {
    dispatch(open_assessment(assessment));
  };

  function open_assessment(assessment) {
    return { type: libraryConstants.OPEN_ASSESSMENT, assessment };
  }
}

export function updateCandidateStatus(obj) {
  return async (dispatch) => {
    dispatch(request());

    try {
      let data = await examService.updateCandidateStatus(obj);
      dispatch(success(data.data));
    } catch (error) {
      dispatch(failure(error.toString()));
    }
  };

  function request() {
    return { type: testConstants.UPDATE_CANDIDATE_STATUS_REQUEST };
  }

  function success(message) {
    return { type: testConstants.UPDATE_CANDIDATE_STATUS_SUCCESS, message };
  }

  function failure(error) {
    return { type: testConstants.UPDATE_CANDIDATE_STATUS_FAILURE, error };
  }
}
