import {assessmentTypes} from '../types';

const initialState = {
    schedule: '',
    assessment: '',
    loading: false,
    error: '',
    success: '',
    text: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case assessmentTypes.ADD_SCHEDULE_ASSESSMENT_REFRESH:
            return {
                ...state,
                text: '',
                loading: false,
                error: '',
                success: '',
                assessment: action.assessment
            }
        case assessmentTypes.ADD_SCHEDULE_ASSESSMENT:
            return {
                ...state,
                text: 'Adding schedule!',
                assessment: action.assessment,
                loading: false
            }
        case assessmentTypes.ADD_SCHEDULE_ASSESSMENT_REQUEST:
            return {
                ...state,
                text: action.content,
                loading: true
            }
        case assessmentTypes.ADD_SCHEDULE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                text: 'Schedule is added Successfully!',
                success: action.message
            }
        case assessmentTypes.ADD_SCHEDULE_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                success: '',
                schedule: ''
            }
        case assessmentTypes.GET_SCHEDULE:
            return {
                ...state,
                assessment: action.id,
                text: 'Obtain schedule!',
                loading: true,
                schedule: ''
            }
        case assessmentTypes.GET_SCHEDULE_REQUEST:
            return {
                ...state,
                text: action.id,
                loading: true
            }
        case assessmentTypes.GET_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Obtained Schedule Successfully!',
                schedule: action.schedule
            }
        case assessmentTypes.GET_SCHEDULE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}