import { BlobServiceClient } from "@azure/storage-blob";
import config from "./config";
import appUrls from "./appUrls";

import { store } from "./redux_store";
import { testConstants } from "./constants";
import APIHandler from "../handlers/APIHandler";
import DataHandler from "../handlers/DataHandler";

let SAS_TOKEN_MANAGE = "";
let SAS_URL = "";

class AzureStorageManage {
  async getBlobServiceClient() {
    let blobServiceClient;
    SAS_TOKEN_MANAGE = DataHandler.getFromSession("SAS_TOKEN_MANAGE");
    SAS_URL = appUrls.MANAGE_STORAGE_ACCOUNT_URI + "?" + SAS_TOKEN_MANAGE;
    if (SAS_TOKEN_MANAGE) {
      try {
        blobServiceClient = await new BlobServiceClient(SAS_URL);
      } catch (e) {
        SAS_TOKEN_MANAGE = await this.getSASToken();
        blobServiceClient = await new BlobServiceClient(SAS_URL);
      }
    } else {
      SAS_TOKEN_MANAGE = await this.getSASToken();

      SAS_URL = appUrls.MANAGE_STORAGE_ACCOUNT_URI + "?" + SAS_TOKEN_MANAGE;
      blobServiceClient = await new BlobServiceClient(SAS_URL);
    }

    return blobServiceClient;
  }

  async getSASToken() {
    await store.dispatch({ type: testConstants.GET_SAS_TOKEN_REQUEST_MANAGE });
    let requestOptions = {
      method: "GET",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    SAS_TOKEN_MANAGE = await fetch(
      `${config.oat_api_url}/manage/azure-tokens/get-manage-sas-token`,
      requestOptions
    ).then(APIHandler.handleResponse);
    await store.dispatch({
      type: testConstants.GET_SAS_TOKEN_SUCCESS_MANAGE,
      token: SAS_TOKEN_MANAGE.data,
    });
    DataHandler.setToSession("SAS_TOKEN_MANAGE", SAS_TOKEN_MANAGE.data);
    return SAS_TOKEN_MANAGE;
  }

  async getContainer(containerName) {
    let blobServiceClient = await this.getBlobServiceClient();
    try {
      let containerClient = await blobServiceClient.getContainerClient(
        containerName
      );
      let isExists = await containerClient.exists();
      if (isExists) {
        return containerClient;
      } else {
        await this.createContainer(containerName);
        return await blobServiceClient.getContainerClient(containerName);
      }
    } catch (e) {
      await this.createContainer(containerName);
      return blobServiceClient.getContainerClient(containerName);
    }
  }

  async createContainer(containerName) {
    try {
      let blobServiceClient = await this.getBlobServiceClient();
      let containerClient = await blobServiceClient.getContainerClient(
        containerName
      );
      let containerCreateResponse = await containerClient.createIfNotExists();
    } catch (e) {
      throw "Azure Blob Container Create Failed";
    }
  }

  // delete container immediately on blobServiceClient
  async deleteContainerImmediately(containerName) {
    let blobServiceClient = await this.getBlobServiceClient();
    const response = await blobServiceClient.deleteContainer(containerName);
    if (!response.errorCode) {
    }
  }

  async uploadBlob(containerName, blobName, blobData) {
    const maxRetries = 3;
    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
        let containerClient = await this.getContainer(containerName);
        let blockBlobClient = await containerClient.getBlockBlobClient(blobName);
        let uploadBlobResponse = await blockBlobClient.uploadData(blobData);
        return uploadBlobResponse.requestId;
      } catch (e) {
        if (attempt < maxRetries) {
          console.warn(`Upload attempt ${attempt} failed. Retrying...`);
          await this.delay(1000); // Wait for 1 second before retrying
        } else {
          throw new Error("Azure Blob Upload Failed after multiple attempts");
        }
      }
    }
  }
  
  // Utility function to introduce a delay
  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  

  async storeBlobContent(containerName, blobName, blobData) {
    try {
      let containerClient = await this.getContainer(containerName);
      let blockBlobClient = await containerClient.getBlockBlobClient(blobName);
      let storeBlobResponse = await blockBlobClient.uploadData(blobData);
      return storeBlobResponse.requestId;
    } catch (e) {
      throw "Azure Blob Content Store Failed";
    }
  }

  async updateBlobContent(
    containerName,
    blobName,
    newBlobData,
    newBlobDataLength
  ) {
    try {
      let containerClient = await this.getContainer(containerName);
      let blockBlobClient = await containerClient.getBlockBlobClient(blobName);
      let updateBlobResponse = await blockBlobClient.upload(
        newBlobData,
        newBlobDataLength
      );
      return updateBlobResponse.requestId;
    } catch (e) {
      throw "Azure Blob Update Failed";
    }
  }

  async downloadBlob(containerName, blobName) {
    try {
      let containerClient = await this.getContainer(containerName);
      let blockBlobClient = await containerClient.getBlockBlobClient(
        blobName.replace(/['"]+/g, "")
      );
      let downloadBlobResponse = await blockBlobClient.download(0);
      return await downloadBlobResponse.blobBody;
    } catch (e) {
      throw "Azure Blob Download Failed";
    }
  }

  async downloadMultipleBlobs(containerName, blobNames) {
    const maxRetries = 3;
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
        let containerClient = await this.getContainer(containerName);
  
        // Create an array of promises for downloading each blob
        let downloadPromises = blobNames.map(async (blob) => {
          let blockBlobClient = await containerClient.getBlockBlobClient(
            blob.document_name.replace(/['"]+/g, "")
          );
          let downloadBlobResponse = await blockBlobClient.download(0);
          let blobBody = await downloadBlobResponse.blobBody;
          let blobUrl = URL.createObjectURL(blobBody);
  
          // Create an anchor element and click it to start download
          let a = document.createElement("a");
          a.href = blobUrl;
          a.download = blob.document_name;
          document.body.appendChild(a);
          a.click();
  
          // Cleanup
          document.body.removeChild(a);
          URL.revokeObjectURL(blobUrl);
  
          return blobBody;
        });
  
        // Wait for all downloads to complete
        let downloadedBlobs = await Promise.all(downloadPromises);
        return downloadedBlobs;
      } catch (e) {
        if (attempt < maxRetries) {
          console.warn(`Download attempt ${attempt} failed. Retrying...`);
          await delay(1000); // Wait for 1 second before retrying
        } else {
          throw new Error("Azure Blob Download Failed after multiple attempts");
        }
      }
    }
  }
  
  async listBlobs(container, candidate) {
    try {
      store.dispatch({ type: testConstants.GET_MEDIA_REQUEST_MANAGE });
      let containerClient = await this.getContainer(container);
      let iter = containerClient.listBlobsByHierarchy("*", {
        prefix: "video/" + candidate,
      });
      let entity = await iter.next();
      let blobList = [];
      while (!entity.done) {
        let item = entity.value;
        if (item.kind === "prefix") {
        } else {
          blobList.push({ name: item.name });
        }
        entity = await iter.next();
      }
      store.dispatch({
        type: testConstants.GET_MEDIA_SUCCESS_MANAGE,
        content: blobList,
      });
      return blobList;
    } catch (e) {
      store.dispatch({
        type: testConstants.GET_MEDIA_FAILURE_MANAGE,
        error: e.toString(),
      });
    }
  }

  async downloadBlob(containerName, blobName) {
    let blobServiceClient = await this.getBlobServiceClient();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
    let downloadBlockBlobResponse = await blobClient.download();
    window.open(downloadBlockBlobResponse._response.request.url);
    return await downloadBlockBlobResponse.blobBody;
  }

  async getBlobContent(containerName, blobName) {
    let blobServiceClient = await this.getBlobServiceClient();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blobClient = containerClient.getBlobClient(blobName);
    let downloadBlockBlobResponse = await blobClient.download();
    const downloadedBlobContent = await this.blobToString(
      await downloadBlockBlobResponse.blobBody
    );
    return await downloadedBlobContent;
  }

  async downloadBlobs(containerName, blobsList) {
    let blobServiceClient = await this.getBlobServiceClient();
    const containerClient = blobServiceClient.getContainerClient(containerName);

    let blobs = [];

    for (let blob of blobsList) {
      let blobClient = await containerClient.getBlobClient(blob.name);
      let response = await blobClient.download();
      let blobBody = await response.blobBody;
      blobs.push(blobBody);
    }

    let recordedBlob = new Blob(blobs, { type: "application/octet-stream" });
    return recordedBlob;
  }

  async deleteblob(containerName, blobName) {
    let blobServiceClient = await this.getBlobServiceClient();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    await containerClient.deleteBlob(blobName);
  }

  async removeBlobContent(containerName, blobName) {
    let blobServiceClient = await this.getBlobServiceClient();
    const containerClient = blobServiceClient.getContainerClient(containerName);
    await containerClient.deleteBlob(blobName);
    await blobServiceClient.deleteContainer(containerName);
  }

  async streamToString(readableStream) {
    return new Promise((resolve, reject) => {
      const chunks = [];
      readableStream.on("data", (data) => {
        chunks.push(data.toString());
      });
      readableStream.on("end", () => {
        resolve(chunks.join(""));
      });
      readableStream.on("error", reject);
    });
  }

  async blobToString(blob) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        if (fileReader.result) {
          resolve(fileReader.result);
        } else {
          reject(new Error("Failed to read the blob as a string."));
        }
      };

      fileReader.onerror = () => {
        reject(new Error("Error reading the blob as a string."));
      };

      fileReader.readAsText(blob);
    });
  }
}

const AzureBlobStorage = new AzureStorageManage();
export { AzureBlobStorage as AzureStorageManage };
