import React from 'react';

import LoginColum from '../../teacher/LoginColumn';
import LoginContainer from '../../teacher/LoginContainer';
import LoginForm from '../../teacher/LoginForm';

export default function TeacherLandingPage () {
    return (
        <LoginColum>
            <LoginContainer>
                <LoginForm />
            </LoginContainer>
        </LoginColum>
    );
}