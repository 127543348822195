import React from "react";
import { UserManagementService } from "../../../service/user_management/user_management.service";
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { history } from "../../../helper/history";
import { ManageDashboardService } from "../../../service/manage/dashboard/manage_dashboard.service";
import { formFixture } from "../../../pages/manage/hierarchy/FormFixture";
import PageSpinner from "../../../components/common/spinner/PageSpinner";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: sessionStorage.getItem("username"),
      email: sessionStorage.getItem("email"),
      session_id:sessionStorage.getItem("manage_user_id"),
      table_log_id:sessionStorage.getItem("table_log_id"),
      isLoading:false
    };
  }
  handleStorageChange = (event) => {
    if (event.storageArea === sessionStorage) {
      this.setState(() => ({
        [event.key]: event.newValue,
      }));
    }
  };
  handleViewProfileClick = () => {
    const closeBtn = document.getElementById("kt_quick_user_close");
    if (closeBtn) {
      closeBtn.click();
    }
    this.setState({
      isLoading:true
    })
    ManageDashboardService.getContent(
      this.state.session_id,
      this.state.table_log_id,
      "registration",
      "owner"
    )
      .then((res) => {
        let formData = res.data.length > 0 && res.data[0]
        let state = {
          mid: formData.node_mid,
          lg_user_id: formData.lg_user_id,
          lg_user_table_id: formData.lg_user_table_id,
          flg_list_active: formData.flg_list_active,
          privilege_view: formData.privilege_view,
          privilege_edit: formData.privilege_edit,
          privilege_add: 0,
          privilege_delete: 0,
          owner_restriction: 0,
          user_list_restriction: 1,
          form_display_title: formData.form_display_title,
          form_display_order: formData.form_display_order,
          form_list_id: formData.form_list_id,
          breadcrumb: formData.breadcrumb,
          main_table_id: formData.main_table_id,
          breadcrumb2: formData.breadcrumb,
          nodeTitle: formData.tree_main_title,
          node_mid_actual:formData.node_mid_actual,
          path: "profile",
        };
        const componentDetail = formFixture(state).find(
          (item) => formData.form_list_id === item.formListId
        );
        if (componentDetail && componentDetail.url) {
          history.push({
            pathname: componentDetail.url,
            state: { ...state },
          });
        }
        this.setState({
          isLoading:false
        })
      })
      .catch((error) => {
        this.setState({
          isLoading:false
        })
      });
    
  };
  componentDidMount() {
    // Add event listener when the component mounts
    window.addEventListener("storage", this.handleStorageChange);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("storage", this.handleStorageChange);
  }

  render() {
    const { email, designation } = this.state;
    const username = !!this.props.user_name
      ? this.props.user_name
      : this.state.username;

    return (
    <div>
          {this.state.isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            
      <div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
        {/* begin::Header */}
        <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 className="font-weight-bold m-0">
            User Profile
            <small className="text-muted font-size-sm ml-2"></small>
          </h3>
          <p
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </p>
        </div>
        {/* end::Header */}
        {/* begin::Content */}
        <div className="offcanvas-content pr-5 mr-n5">
          {/* begin::Header */}
          <div className="mt-10">

            {/* <div className="symbol symbol-100 mr-5">


              <div
                className="symbol-label custom-symbol-label"
                style={{
                  backgroundImage: "url('../assets/media/users/user.png')",
                }}
              ></div>


              <i className="symbol-badge bg-success"></i>
            </div> */}

<div className="d-flex justify-content-center mb-5">


<div className="symbol symbol-100">
  <div
    className="symbol-label custom-symbol-label"
    style={{
      backgroundImage: "url('../assets/media/users/useravatar.png')",
    }}
  ></div>

</div>
</div>

<div className="row d-flex justify-content-center">

    <div className="col-md-12 d-flex justify-content-center font-weight-bold font-size-h5 text-dark-75"> 
      {username}
    </div>

    <div className="col-md-12 text-muted mt-1">{designation}</div>

    <div className="col-md-12 d-flex justify-content-center navi mt-2">
      <div className="navi-item">
        <span className="navi-link p-0 pb-2">
          <span className="navi-icon mr-1">
              {/* ... (rest of your SVG code) */}
               <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                    fill="#000000"
                  />
                  <circle
                    fill="#000000"
                    opacity="0.3"
                    cx="19.5"
                    cy="17.5"
                    r="2.5"
                  />
                </g>
              </svg>
            </span>
          <span className="">
            {email}
          </span>
        </span>
      </div>
    </div>

</div>






          </div>
          {/* end::Header */}
          {/* begin::Separator */}
          <div className="separator separator-dashed mt-3 mb-5"></div>
          <div className="d-flex justify-content-center">
          <div
            className="d-flex navi-text text-muted text-hover-primary mt-1"
            onClick={this.handleViewProfileClick}
            style={{ cursor: 'pointer' }}
          >
            <PersonOutlineRoundedIcon />
            <p className="ml-5">View Profile</p>
          </div>
      </div>
      
          <div className="separator separator-dashed mt-5 mb-5"></div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5"
              onClick={UserManagementService.staffLogout}
            >
              Sign Out
            </button>
          </div>
          {/* end::Separator */}
        </div>
        {/* end::Content */}
      </div>


                </div>
      
    );
  }
}

export default UserProfile;
