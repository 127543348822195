import React from "react";
import ContentWrapper from "../../components/common/ContentWrapper.js";
import Card from "../../components/common/Card.js";
import CardHeader from "../../components/common/CardHeader.js";
import CardBody from "../../components/common/CardBody.js";
import CardTitle from "../../components/common/QuestionCardTitle.js";
import moment from "moment";
import Spinner from "../../components/Spinner3.js";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState } from "draft-js";
import { getReport } from "../../actions/candidate/examActions.js";
import { Button } from "@mui/material";
import {
  getAllQuestionContents,
  getAllAnswerContents,
  getAllCandidateAnswerContents,
  getCandidateAnswers,
  loadAssessmentQuestions
} from "../../actions/candidate/testActions.js";
import { libraryActions } from "../../actions/assessment/libraryActions";
import { manageUserActions } from "../../actions/user_management/manageUserActions.js";
import PaperReview from "./PaperReview.js";
import SelfGrade from "./SelfGrade.js";
import config from '../../helper/config.js';
import { customerConstants } from "../../helper/constants.js";
import "./Questions.css";
import ReactSpinner from "react-bootstrap/Spinner";
import { questionTypeConstants } from "../../helper/constants.js";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import { Row, Form, Modal, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { quesGradeService } from "../../service/candidate/gradeService.js";
import ReactSelect from "react-select";
import debounce from "lodash.debounce";
import { TextRotateUp } from "@mui/icons-material";
import { sweetStatusAlert } from "../../components/common/SweetAlert.js";
import { unitConstants } from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, disabledBtnStyle, gradeReportbtnStyle ,requestbtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnStyle = {
      color: "var(--maincolor)",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    }
    gradeReportbtnStyle = {
      color: "var(--maincolor)",
      width: "100%",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px",
      margin: "0px 5px",
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    }
    disabledBtnStyle = {
      color: "#fff",
      backgroundColor: "#7E8299",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    }
    requestbtnStyle = {
      color: "#EDEEF7" ,
      backgroundColor: "#1ead42",
    }
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    gradeReportbtnStyle = {
      color: "var(--maincolor)",
      width: "100%",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px",
      margin: "0px 5px",
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
      boxShadow: "none"
    }
    disabledBtnStyle = {
      color: "#fff",
      backgroundColor: "#7E8299",
      borderColor: "transparent",
      boxShadow: "none"
    }
    break
  default:
    closeBtnStyle = {
      color: "var(--maincolor)",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    }
    gradeReportbtnStyle = {
      color: "var(--maincolor)",
      width: "100%",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px",
      margin: "0px 5px",
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    }
    disabledBtnStyle = {
      color: "#fff",
      backgroundColor: "#7E8299",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px"
    }
    requestbtnStyle = {
      color: "#EDEEF7" ,
      backgroundColor: "#1ead42",
    }
    break
}

class PaperGradeReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userType: null,
      showGradebutton: false,
      selfGradingState: false,
      isReportLoading: false,
      isAllMCQs: true,
      showInsertModal: false,
      teachers:[],
      selectedTeacher:"",
      teacherSearchTerm:"",
      isTeacherLoading: false
    };
    this.debouncedHandleStudentSearch = debounce(this.getAllTeachers, 1000);
  }

  hidePaperReview = () => {
    this.setState({
      showPaperReview: false
    })
  }
  hideSelfGrade = () => {
    this.setState({
      showSelfGrade: false
    })
  }

  onUnload = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = "";
  };

  async componentDidMount() {
    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
    window.addEventListener("beforeunload", this.onUnload);

    this.setState({ isReportLoading: true });

    const final_grade = this.props.report.final_grade
    sessionStorage.setItem("final_grade", final_grade);

    let userType = sessionStorage.getItem("userType");
    this.setState({ userType });

    if (userType === "student") {
      this.setState({ showGradebutton: true });
    }

    await this.props.loadAssessmentQuestions(this.props.assessment?.assessment_id);
    for (var i = 0; i < this.props.assessmentQuestions?.length; i++) {
      if (this.props?.assessmentQuestions[i]["question_type"] !== questionTypeConstants.MCQ.name) {
        this.setState({ isAllMCQs: false });
      }
    }

    if (this.state.isAllMCQs) {
      this.setState({ showGradebutton: false });
    }

    await this.props.getReport(this.props.assessment);
    await this.props.getCandidate(this.props.assessment?.candidate_id);
    await this.props.getStudentDetails(parseInt(this.props.candidateDetails?.student_id));
    await this.getAllTeachers();
    await this.props.getAllQuestionContents({
      questions: this.props.assessmentQuestions,
    });
    await this.props.getAllAnswerContents({
      questions: this.props.assessmentQuestions,
    });
    await this.props.getCandidateAnswers(this.props.assessment);
    await this.props.getAllCandidateAnswerContents({
      answers: this.props.candidateAnswers,
    });
    this.setState({ isReportLoading: false });
  }

  getAllTeachers = async () => {
    this.setState({
      isTeacherLoading: true,
    });
    const {teacherSearchTerm} = this.state
    quesGradeService.getAllTeachers(teacherSearchTerm)
      .then((res) => {
        this.setState({
          teachers: res.data,
        });
      })
      .catch((error) => {
        console.error("Error getting deadlines:", error);
      })
      .finally((error) => {
        this.setState({
          isTeacherLoading: false,
        });
      })
  }
  toggleAddModal = () => {
    if (this.state.showInsertModal) {
      this.setState({
        showInsertModal: false,
      });
    } else {
      this.setState({
        showInsertModal: true,
        selectedTeacher:""
      });
    }
  };

  handleTeacherSelect = (id) => {
    
    const selectedTeacher = this.state.teachers.find(
      (form) => form.user_id === id.user_id
    );


    this.setState({
      selectedTeacher: selectedTeacher,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  handleTeacherSearch = (event) => {
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
    if (event === "" || regex.test(event)) {
      let num = event;
      this.setState(
        {
          teacherSearchTerm: num,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleStudentSearch();
        }
      );
    } else {
      this.setState({ teacherSearchTerm: event, searchError: true });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
      this.setState({ isLoading: true, showInsertModal: false });
      const { selectedTeacher } =
        this.state;
        const requestDataArray = [];

        if (this.props?.answers) {
          const answersArray = Object.values(this.props.answers);
        
          for (let i = 0; i < answersArray.length; i++) {
            const Request_data = {
              candidate_id: this.props.assessment.candidate_id,
              assessment_id: this.props.assessment.assessment_id,
              question_id: answersArray[i]?.question_id,
              attempt_no: this.props.assessment.attempt_no,
              answer_id: answersArray[i]?.answer_id,
              graded_by: String(selectedTeacher.value) 
            };
        
            requestDataArray.push(Request_data);
          }
        }

      quesGradeService.createTeacherGrade(requestDataArray)
        .then((res) => {
          if (res.success) {
            this.props.getReport(this.props.assessment);
            sweetStatusAlert("Success!", res.message.success, "success");
          } else {
            sweetStatusAlert("Warning!", res.message.error, "warning");
          }
        })
        .catch((error) => {
          sweetStatusAlert(
            "Failed !",
            "Unable to make the request!",
            "error"
          );
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    
  };

  getScheduleUnit(unit_id, value) {
    if(value !== null){
      if (unit_id !== null) {
          const unit = this.props.allUnits?.find(
              (unit) => unit.unit_id == unit_id
          );
          return unit?.unit;
      }
      else{
          return "N/A";
      }
    }
    else{
      return "";
    }
  }

  convertScheduleInSelectedUnit(value, unit) {
    if(value !== null){
      let valueInSelectedUnit;
      let selectedUnit = this.getScheduleUnit(unit, value);

      switch (selectedUnit) {
        case unitConstants.MONTHS.name:
          valueInSelectedUnit = value / 43200;
          break;
        case unitConstants.HOURS.name:
          valueInSelectedUnit = value / 60;
          break;
        case unitConstants.DAYS.name:
          valueInSelectedUnit = value / 1440;
          break;
        case unitConstants.MINUTES.name:
        default:
          valueInSelectedUnit = value;
      }
      
      return valueInSelectedUnit;
    }
    else{
      return 'Unlimited';
    }
  }

  getTextFromJson(jsonString) {
    const parsedData = JSON.parse(jsonString);
    const textValue = parsedData.blocks[0]?.text || "";
    return textValue;
  }
  
  render() {
    var remarks_view = <h3 className="m-0">Not Available</h3>;

    if (
      this.props.report.remark_content &&
      this.props.report.final_grade !== null
    ) {
      let contentState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(this.props.report.remark_content))
      );

      if(this.state.isAllMCQs){
        remarks_view = this.getTextFromJson(this.props?.report?.remark_content)
      }
      else{
        remarks_view = (
          <div className="remarks-view">
            <Editor
              editorState={contentState}
              toolbarClassName="hide-toolbar-show-border"
              readOnly={true}
              toolbar={{}}
              toolbarHidden={true}
            />
          </div>
        );
      }
    }
    if (this.state.showPaperReview) {
      return <PaperReview hidePaperReview={() => this.hidePaperReview()} assessment={this.props.assessment} candidate={this.props.candidate} />
    } else if (this.state.showSelfGrade) {
      return <SelfGrade hideSelfGrade={() => this.hideSelfGrade()} assessment={this.props.assessment} />
    }

    return (
      <div style={{ height: "90vh" }}>
         {this.state.isLoading && (
              <Spinner id="show" text="Please wait.." />
            )}
        {this.state.isReportLoading ? (
          <Spinner id="show" text="Loading Grade Report..." />
        ) :
        (<ContentWrapper>
          {/* begin::Card */}
          {this.props.assessment?.title ? (
            <Card>
              <CardHeader>
                <CardTitle
                  cardLabel={`Grade Report of ${this.props.assessment.title}`}
                />

                <div className="d-flex justify-content-end">
                  {/* <Button
                    variant="primary"
                    style={
                      this.props.report.final_grade !== null
                        ? addBtnStyle
                        : disabledBtnStyle
                    }
                    onClick={() => {
                      this.setState({
                        showPaperReview: true,
                      })
                    }}
                    disabled={
                      this.props.report.final_grade !== null ? false : true
                    }
                  >
                    View Answer Sheet
                  </Button> */}
                  <Button
                    variant="contained"
                    style={addBtnStyle}
                    className="ml-1"
                    onClick={() => this.props.offViewReport()}
                  >
                    Back
                  </Button>
                </div>
              </CardHeader>
              {this.state.isReportLoading ? (
                <div>
                  <div className="loading-container mb-5 mt-10">
                    <ReactSpinner
                      animation="border"
                      role="status"
                      style={{ display: "block", margin: "auto" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </ReactSpinner>
                  </div>
                </div>
              ) :
                (
                  // <CardBody>
                  //   <div className="row d-flex mb-4 align-items-center">
                  //     <div className="col-lg-6 col-md-8 col-sm-8 d-flex justify-content-end">
                  //     </div>
                  //   </div>
                  //   <div className="row exam-details">
                  //     <div className="col col-lg-12">
                  //       <div className="row">
                  //         <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                  //           <div className="row w-100">
                  //             <div className="col-md-12 d-flex justify-content-start px-0">
                  //               <span className="grade-report-text font-size-sm">
                  //                 User Code
                  //               </span>
                  //             </div>
                  //             <div className="col-md-12 d-flex justify-content-start grade-report-box">
                  //               <h3 className="m-0">
                  //                 {this.props.studentDetails?.user_code}
                  //               </h3>
                  //             </div>
                  //           </div>
                  //         </div>
                  //         <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                  //           <div className="row w-100">
                  //             <div className="col-md-12 d-flex justify-content-start px-0">
                  //               <span className="grade-report-text font-size-sm">
                  //                 Name
                  //               </span>
                  //             </div>
                  //             <div className="col-md-12 d-flex justify-content-start grade-report-box">
                  //               <h3 className="m-0">
                  //                 {this.props.studentDetails?.first_name + " " + this.props.studentDetails?.last_name}
                  //               </h3>
                  //             </div>
                  //           </div>
                  //         </div>
                  //         <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                  //           <div className="row w-100">
                  //             <div className="col-md-12 d-flex justify-content-start px-0">
                  //               <span className="grade-report-text font-size-sm">
                  //                 Exam Title
                  //               </span>
                  //             </div>
                  //             <div className="col-md-12 d-flex justify-content-start grade-report-box">
                  //               <h3 className="m-0">
                  //                 {this.props.assessment.title}
                  //               </h3>
                  //             </div>
                  //           </div>
                  //         </div>
                  //         <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                  //           <div className="row w-100">
                  //             <div className="col-md-12 d-flex justify-content-start px-0">
                  //               <span className="grade-report-text font-size-sm">
                  //                 Attempted Date
                  //               </span>
                  //             </div>
                  //             <div className="col-md-12 d-flex justify-content-start grade-report-box">
                  //               <h3 className="m-0">
                  //                 {moment(
                  //                   this.props.report.attempt_start_time
                  //                 ).format("MMMM Do YYYY")}
                  //               </h3>
                  //             </div>
                  //           </div>
                  //         </div>
                  //         <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                  //           <div className="row w-100">
                  //             <div className="col-md-12 d-flex justify-content-start px-0">
                  //               <span className="grade-report-text font-size-sm">
                  //                 Final Marks
                  //               </span>
                  //             </div>
                  //             <div className="col-md-12 d-flex justify-content-start grade-report-box">
                  //               <h3 className="m-0">
                  //                 {this.props.report.final_grade == null || this.props.report.final_grade == undefined
                  //                   ? "Complete your self-grading to finalize your marks."
                  //                   : this.props.report.final_grade}
                  //               </h3>
                  //             </div>
                  //           </div>
                  //         </div>
                  //         <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                  //           <div className="row w-100">
                  //             <div className="col-md-12 d-flex justify-content-start px-0">
                  //               <span className="grade-report-text font-size-sm">
                  //                 {/* Assessor Remarks: */}
                  //                 Attempt Number
                  //               </span>
                  //             </div>
                  //             <div className="col-md-12 d-flex justify-content-start grade-report-box">
                  //               <h3 className="m-0">
                  //                 {/* {remarks_view || "Not Available"} */}
                  //                 {!!this.props.assessment.attempt_no ? this.props.assessment.attempt_no : 'Unknown'}
                  //               </h3>
                  //             </div>
                  //           </div>
                  //         </div>
                  //         <div className="col-md-12 d-flex justify-content-end pr-4">
                  //           {this.state.showGradebutton && (
                  //             <Button
                  //               // variant="primary"
                  //               style={closeBtnStyle}
                  //               onClick={() => {
                  //                 // this.props.history.push({
                  //                 //   pathname: "/self-grade",
                  //                 // });
                  //                 this.setState({
                  //                   showSelfGrade: true,
                  //                 })
                  //               }}
                  //               // disabled={
                  //               //   this.props.report.final_grade !== null ? false : true
                  //               // }
                  //             >
                  //               Self Grading
                  //             </Button>
                  //           )}
                  //         </div>
                  //         {/* <div className="col col-lg-12 col-md-12 col-12 mb-5">
                  //           <span className="d-block text-muted font-size-sm">Proctor Remarks:</span>
                  //           <h3 className="m-0">{this.props.report.proctor_remarks || "Not Available"}</h3>
                  //         </div> */}
                  //       </div>
                  //     </div>
                  //   </div>
                  // </CardBody>

                  <div className="w3-container ">
                    <div className="row lineGrade">
                      <div className="col-md-6 colPadding">
                        <div className="col-md-12 colPadding">
                          <div className="row">
                            <div className="col-md-4 colPadding">
                              <h2 className="text-white subData lineItems">Marks :&nbsp;</h2>
                            </div>
                            <div className="col-md-8 colPadding text-white theData">

                              {this.props.report.final_grade == null || this.props.report.final_grade == undefined
                                    ?
                                    <Tooltip title="Complete your self grading to finalize your marks" placement="top" arrow>
                                      <ErrorOutlineIcon />
                                    </Tooltip>
                                    : 
                                    this.props.report.final_grade + '/' + this.props.report.total_mark_allocation
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 colPadding">
                          <div className="row">
                            <div className="col-md-8 colPadding">
                              <h2 className="text-white subData lineItems">Attempt Number :&emsp;</h2>
                            </div>
                            <div className="col-md-4 colPadding text-white theData">
                              <div className="row">
                                <div className="theData">
                                  {!!this.props.assessment.attempt_no ? this.props.assessment.attempt_no : 'Unknown'}
                                </div>
                                <div className="theData">
                                  /
                                {!!this.props.assessment.no_of_attempts ? this.props.assessment.no_of_attempts : 'Unknown'}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 colPadding lineItems">
                        <div className="row">
                          <div className="col-md-7 colPadding">
                            <h2 className="text-white subData lineItems">Paper duration :&nbsp;</h2>
                          </div>
                          <div className="col-md-5 colPadding text-white subData">
                            {this.convertScheduleInSelectedUnit(this.props?.candidateAssessment?.exam_time_interval, this.props?.candidateAssessment?.exam_time_interval_unit_id) + ' ' + this.getScheduleUnit(this.props?.candidateAssessment?.exam_time_interval_unit_id, this.props?.candidateAssessment?.exam_time_interval)} 
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 colPadding lineItems">
                        <h2 className="text-white subData lineItems">Attempted Date :&emsp;
                          {moment(
                            this.props.report.attempt_start_time
                          ).format("MMMM Do YYYY")}
                        </h2>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-12 colPadding lineItems">
                        <h2 className="text-white subData lineItems">Final Grade :&emsp;
                          <b>{this.props?.report?.grade}</b>
                        </h2>
                      </div>
                    </div> */}
                    {/* {this.state.isAllMCQs && 
                      <div className="row">
                        <div className="col-md-12 colPadding lineItems">
                          <h2 className="text-white subData lineItems">Remarks :&emsp;
                            {remarks_view}
                          </h2>
                        </div>
                      </div>
                    } */}
                    {/* <div className="row">
                      <div className="col-md-3 colPadding lineItems">
                        <div className="col-md-12 colPadding">
                          <h2 className="text-white subData lineItems">Answered</h2>
                        </div>
                        <div className="col-md-12 colPadding text-white subData">
                          40
                        </div>
                      </div>
                      <div className="col-md-3 colPadding">
                        <div className="col-md-12 colPadding">
                          <h2 className="text-white subData lineItems">Unanswered</h2>
                        </div>
                        <div className="col-md-12 colPadding text-white theData">
                          <div className="row">

                            <div className="subData">
                              15
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 colPadding lineItems">
                        <div className="col-md-12 colPadding">
                          <h2 className="text-white subData lineItems">Correct</h2>
                        </div>
                        <div className="col-md-12 colPadding text-white subData">
                          35
                        </div>
                      </div>
                      <div className="col-md-3 colPadding lineItems">
                        <div className="col-md-12 colPadding">
                          <h2 className="text-white subData lineItems">Incorrect</h2>
                        </div>
                        <div className="col-md-12 colPadding text-white subData">
                          10
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-12 colPadding grbtn">
                      <div className="col-md-4 d-flex justify-content-end colPadding">
                        {this.state.showGradebutton && (
                          <Button
                            // variant="primary"
                            style={gradeReportbtnStyle}
                            onClick={() => {
                              // this.props.history.push({
                              //   pathname: "/self-grade",
                              // });
                              this.setState({
                                showSelfGrade: true,
                              })
                            }}
                          // disabled={
                          //   this.props.report.final_grade !== null ? false : true
                          // }
                          >
                            Self Grading
                          </Button>
                        )}
                      </div>
                      <div className="col-md-4 d-flex justify-content-end colPadding">
                      {this.state.showGradebutton && (
                        <Button
                          style={gradeReportbtnStyle}
                          onClick={this.toggleAddModal}
                        >
                          Teacher Grading
                        </Button>
                      )}
                      </div>
                      <div className="col-md-4 colPadding">
                        <Button style={gradeReportbtnStyle}
                          onClick={() => {
                            this.setState({
                              showPaperReview: true,
                            })
                          }}
                          disabled={
                            this.props.report.final_grade !== null ? false : true
                          }
                        >View Result Sheet
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              <Modal
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.showInsertModal}
              onHide={this.toggleAddModal}
            >
              <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: '8px' }}>Request a teacher to grade</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row className="my-5">
                    <Form.Group as={Col} md="12" className="mb-4" controlId="statusId">
                      <Form.Label>Select a Teacher*</Form.Label>
                      <ReactSelect
                        options={this.state.teachers.map((teacher) => ({
                          value: teacher.user_id,
                          label: `${teacher.first_name} ${teacher.last_name}`
                        }))}
                        value={this.state.selectedTeacher}
                        isLoading={this.state.isTeacherLoading}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption?.value;
                          this.setState({
                            selectedTeacherId: selectedValue,
                            selectedTeacher: selectedOption,
                          });
                        }}
                        onInputChange={(inputValue) => {
                          if (inputValue) {
                            this.handleTeacherSearch(inputValue);
                          }
                        }}
                        onMenuClose={() => {
                          if (this.state.teacherSearchTerm) {
                            this.setState(
                              {
                                teacherSearchTerm: "",
                              },
                              () => {
                                this.getAllTeachers();
                              }
                            );
                          }
                        }}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minHeight: '40px', // Make dropdown larger
                          }),
                          menu: (base) => ({
                            ...base,
                            fontSize: '12px', // Make font size smaller
                          }),
                        }}
                      />
                    </Form.Group>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="label label-lg label-light-danger label-inline"
                  variant="secondary"
                  onClick={this.toggleAddModal}
                >
                  Close
                </Button>
                {this.state.selectedTeacher && (
                  <Button
                    className="label label-lg label-light-success label-inline"
                    onClick={this.handleSubmit}
                  >
                    Request
                  </Button>
                )}
              </Modal.Footer>
            </Modal>


                </Card>
            
          ) : (
            this.props.offViewReport()
          )}
          {/* end::Card */}
        </ContentWrapper>
        )}
      </div>
    )
  }
}

const mapStateToActions = {
  getReport: getReport,
  loadAssessmentQuestions: loadAssessmentQuestions,
  getAllQuestionContents: getAllQuestionContents,
  getAllAnswerContents: getAllAnswerContents,
  getAllCandidateAnswerContents: getAllCandidateAnswerContents,
  getCandidateAnswers: getCandidateAnswers,
  getCandidate: libraryActions.getCandidate,
  getStudentDetails: manageUserActions.getStudentDetails
};

function mapStateToProps(state) {
  return {
    report: state.examReducer.report,
    assessment: state.examReducer.assessment,
    assessmentQuestions: state.testReducer.assessmentQuestions,
    report_loading: state.examReducer.report_loading,
    candidateAssessment: state.examReducer.assessment,
    candidateDetails: state.candidateReducer.candidateDetails,
    studentDetails: state.manageUserReducer.studentDetails,
    answers: state.testReducer.candidateAnswerMap,
    candidateAnswers: state.testReducer.candidateAnswers,
  };
}

export default connect(mapStateToProps, mapStateToActions)(PaperGradeReport);
