import {shareTypes} from '../../reducers/types';
import {shareService} from '../../service/question/shareService';

export const shareActions = {
    refreshPage,
    loadSharePage,
    shareQuestion,
    getAlreadySharedUsers,
    stopSharing,
    unshareFromMe,
    stopSharingConsent,
    unshareConsent
};

function loadSharePage(question) {
    return dispatch => {
        dispatch(setQuestion(question));
    };

    function setQuestion(question) {
        return {type: shareTypes.SHARE_QUESTION, question}
    }
}

function shareQuestion(obj) {
    return async dispatch => {

        dispatch(clearExisting());
        dispatch(request());

        try {
            let data = await shareService.shareQuestion(obj);
            dispatch(share_success());
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function clearExisting() {
        return {type: shareTypes.SHARE_QUESTION}
    }

    function request() {
        return {type: shareTypes.SHARE_QUESTION_REQUEST}
    }

    function share_success() {
        return {type: shareTypes.SHARE_QUESTION_SUCCESS}
    }

    function failure(error) {
        return {type: shareTypes.SHARE_QUESTION_FAILURE, error}
    }
}

function getAlreadySharedUsers(obj) {
    return async dispatch => {

        dispatch(clearExisting());
        dispatch(request());

        await shareService.getSharedUsers(obj)
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function clearExisting() {
        return {type: shareTypes.GET_SHARED_USERS}
    }

    function request() {
        return {type: shareTypes.GET_SHARED_USERS_REQUEST}
    }

    function success(users) {
        return {type: shareTypes.GET_SHARED_USERS_SUCCESS, users}
    }

    function failure(error) {
        return {type: shareTypes.GET_SHARED_USERS_FAILURE, error}
    }
}

function stopSharingConsent(share_id) {

    return dispatch => {
        dispatch(consent(share_id));

        function consent(share_id) {
            return {type: shareTypes.STOP_SHARING_QUESTION, share_id}
        }
    }
}

function stopSharing(obj) {
    return async dispatch => {
        dispatch(request(obj));
        try {
            let data = await shareService.stopSharing(obj);
            dispatch(unshared());
        } catch (error) {
            dispatch(failure(error.toString()));
        }

    };

    function request(question) {
        return {type: shareTypes.STOP_SHARING_QUESTION_REQUEST, question}
    }

    function unshared() {
        return {type: shareTypes.STOP_SHARING_QUESTION_SUCCESS}
    }

    function failure(error) {
        return {type: shareTypes.STOP_SHARING_QUESTION_FAILURE, error}
    }
}


function unshareConsent(share_id) {
    return dispatch => {
        dispatch(consent(share_id));

        function consent(share_id) {
            return {type: shareTypes.UNSHARE_FROM_ME, share_id}
        }
    }
}

function unshareFromMe(obj) {
    return dispatch => {

        dispatch(request(obj));

        shareService.unshareFromMe(obj)
            .then(
                data => {
                    dispatch(success(data.data))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request(question) {
        return {type: shareTypes.UNSHARE_FROM_ME_REQUEST, question}
    }

    function success(success) {
        return {type: shareTypes.UNSHARE_FROM_ME_SUCCESS, success}
    }

    function failure(error) {
        return {type: shareTypes.UNSHARE_FROM_ME_FAILURE, error}
    }
}


function refreshPage() {
    return dispatch => {
        dispatch(clearExisting());
    };

    function clearExisting() {
        return {type: shareTypes.SHARE_PAGE_REFRESH}
    }
}