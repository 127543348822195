import React, { useState } from 'react';
import "./drillchart.css"

const FigureChart = ({ val }) => {
    return (
        <div>
            {val.map((val) => (
                <div className="row d-flex align-items-center mb-5 figure-chart-container">
                    <div className="col-md-9 col-9">
                        <p className="figure-chart-text">{val?.breadcrumb ? val?.breadcrumb : ""}{val?.chart_title ? " > " + val?.chart_title : ""}</p>
                    </div>
                    <div className="col-md-3 col-3 justify-content-start">
                        <p className="label label-lg label-light-success label-inline figure-chart-text">{val?.chart_y_value}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FigureChart;