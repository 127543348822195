
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Modal
} from "react-bootstrap";

export default class NodeUpdationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const {
        } = this.state;
        const {
            showUpdateModal,
            toggleUpdateModal,
            handleChange,
            validator,
            mcode,
            title,
            groupTitle,
            displayOrder,
            addBtnStyle,
            closeBtnStyle,
            flg_node_hide,
            handleUpdateSubmit,
            titles
        } = this.props
        return (
            <div>
            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={showUpdateModal}
              onHide={() => toggleUpdateModal()}
            >
              <Modal.Header closeButton>
                <Modal.Title>You are going to update the item</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="mcode"
                        placeholder="ID"
                        value={mcode}
                        onChange={handleChange}
                      />
                      {validator.message(
                        "mcode",
                        mcode,
                        "required|alpha_num",
                        {
                          className: "text-danger",
                          messages: {
                            required: "Id is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Main Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        placeholder="Main Title"
                        value={title}
                        onChange={handleChange}
                      />
                      {validator.message(
                        "title",
                        title,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "Main title is required.",
                          },
                        }
                      )}
                    </Form.Group>
  
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                      className="my-3"
                    >
                      <Form.Label>Group Title</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="groupTitle"
                        value={groupTitle}
                        onChange={handleChange}
                      >
                        <option value="">Select</option>
                        {titles.length>0?(titles.map((title)=>(
                            <option key={title.group_id} value={title.group_id}>
                              {title.group_title}
                            </option>
                          ))):(
                          <option value="">No titles available</option>
                        )}
                      </Form.Select>
                      {validator.message(
                        'groupTitle',
                        groupTitle,
                        'required|max:20', {
                          className: "text-danger",
                          messages: {
                            required: "Group title is required.",
                          },
                        })}
                    </Form.Group>
  
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                      className="mt-3"
                    >
                      <Form.Label>Display Order</Form.Label>
                      <Form.Control
                        type="number"
                        name="displayOrder"
                        step={1}
                        min={0}
                        max={100}
                        placeholder="Display Order"
                        value={displayOrder}
                        onChange={handleChange}
                        className="mb-3"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                    >
                      <Row className="px-0 d-flex justify-content-between">
                        <Form.Label className="col-auto px-0">
                          Hide this item
                        </Form.Label>
                        <div className="col-auto px-0">
                          <Form.Check
                            type="checkbox"
                            name="flg_node_hide"
                            onChange={handleChange}
                            checked={flg_node_hide}
                          />
                        </div>
                      </Row>
                    </Form.Group>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  style={ addBtnStyle }
                  onClick={(event) => handleUpdateSubmit(event)}
                >
                  Update
                </Button>
                <Button
                  id="modal-close-button"
                  style={ closeBtnStyle }
                  onClick={() => toggleUpdateModal()}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            </div>
        )
    }
}



