import {testConstants} from '../../helper/constants';

const initialState = {
    assessment: '',
    candidate_id: '',
    rules_n_regs: false,
    start_time: '',
    exam_duration: '',
    candidate_exam_start_time: '',
    candidate_exam_duration: '',
    candidate_exam_buffer_time: '',
    loading: false,
    error:'',
    success: '',
    text: ''
}

export default function(state = initialState, action){
    switch (action.type){
        case testConstants.VALIDATE_CANDIDATE_REFRESH:
            return {
                ...state,
                assessment: '',
                candidate_id: '',
                rules_n_regs: false,
                start_time: '',
                exam_duration: '',
                candidate_exam_start_time: '',
                candidate_exam_duration: '',
                candidate_exam_buffer_time: '',
                loading: false,
                error: '',
                success: '',
                text: '',
                questions: []
            }
        case testConstants.VALIDATE_CANDIDATE:
            return {
                ...state,
                text: 'Candidate credential validation started!',
                loading: true,
            }
        case testConstants.VALIDATE_CANDIDATE_REQUEST:
            return {
                ...state,
                text: action.data,
                loading: true
            }
        case testConstants.VALIDATE_CANDIDATE_SUCCESS:
            return {
                ...state,
                assessment: action.data.assessment,
                candidate_id: action.data.validation.candidate_id,
                exam_duration: action.data.exam_duration,
                candidate_exam_start_time: action.data.validation.start_time,
                candidate_exam_duration: action.data.validation.exam_time_interval,
                candidate_exam_buffer_time: action.data.validation.buffer_time,
                candidate_exam_time_limit: action.data.validation.is_set_time_limit,
                text: 'candidate validated successfully',
                success: action.data,
                loading: false
            }
        case testConstants.VALIDATE_CANDIDATE_FAILURE:
            return {
                ...state,
                text: 'candidate validation failed',
                error: action.error,
                loading: false
            }
        case testConstants.START_EXAM:
            return {
                ...state,
                start_time: '',
                loading: true
            }
        case testConstants.START_EXAM_REQUEST:
            return {
                ...state,
                start_time: action.obj.start_time,
                loading: true
            }
        case testConstants.START_EXAM_SUCCESS:
            return {
                ...state,
                start_time: action.data.attempt_start_time,
                loading: false
            }
        case testConstants.START_EXAM_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}