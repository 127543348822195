import React from "react";
import { routes } from "../../helper/routes.js";
import _ from "lodash";
import ContentWrapper from "../../components/common/AssessmentContentWrapper.js";
import AdminTemplate from "../../components/admin/layouts/Template.js";
import Spinner from "../../components/common/Spinner.js";
import Card from "../../components/common/Card.js";
import CardHeader from "../../components/common/CardHeader.js";
import CardBody from "../../components/common/CardBody.js";
import CardTitle from "../../components/common/AssessmentCardTitle.js";
import Breadcrumb from "../../components/common/Breadcrumb.js";
import BreadcrumbItem from "../../components/common/BreadcrumbItem.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import config from "../../helper/config.js";
import { customerConstants } from "../../helper/constants.js";
import debounce from "lodash.debounce";
import { candidateService } from "../../service/candidate/candidateService.js";
import { sweetStatusAlert } from "../../components/common/SweetAlert.js";
import { ArrowDownward } from "@mui/icons-material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import MaterialCustomTablePagination from "../../components/common/MaterialCustomTablePagination.js";
import { Button } from "@mui/material";
import TeacherGrade from "./TeacherGrade.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default";
    confirmClass = "btn-primary";
    break;
  case customerConstants.EXO_CUSTOMER2:
  // cancelClass = "cmc-btn-default"
  // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default";
    confirmClass = "btn-primary";
    break;
}

class TeacherGradeReport extends React.Component {
  constructor(props) {
    super(props);

    this.ref_tagify = React.createRef();
    this.state = {
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      sortColumn: "report_id",
      sortDirection: "desc",
      TeacherGrades: [],
      isTableDataLoading: true,
      totalTeacherGrades: 0,
      currentPage: 0,
      showTeacherGrade: false,
      selectedTeacherGrade: null,
    };
    this.debouncedHandleSearch = debounce(this.getAllTeacherGrades, 2000);
  }

  async componentDidMount() {
    this.getAllTeacherGrades();
  }

  getAllTeacherGrades() {
    const { limit, offset, sortColumn, sortDirection, search_term } =
      this.state;

    const assessment_id = this.props.location.state.assessment.assessment_id;
    const graded_by = sessionStorage.getItem("manage_user_id");

    candidateService
      .getTeacherReports(
        graded_by,
        assessment_id,
        limit,
        offset,
        sortColumn,
        sortDirection,
        search_term
      )
      .then((res) => {
        if (res.success) {
          this.setState({
            TeacherGrades: res.data.assessment,
            totalTeacherGrades: res.data.total_count,
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Enrollments Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  }

  getColor(state) {
    switch (state) {
      case "Assigned":
        return "label label-lg label-light-danger label-inline";
      case "Invited":
        return "label label-lg label-light-danger label-inline";
      case "Finished":
        return "label label-lg label-light-success label-inline";
      case "Onprogress":
        return "label label-lg label-light-warning label-inline";
      default:
        return "label label-lg label-light-info label-inline";
    }
  }
  getStartTime(date) {
    let startedTime = new Date(date + "Z");
    return startedTime.toLocaleString();
  }

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleSortChange = (sortColumn) => {
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0,
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.getAllTeacherGrades();
      }
    );
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllTeacherGrades();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllTeacherGrades();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllAssessments();
      }
    );
  };

  hideTeacherGrade = () => {
    this.setState({
      showTeacherGrade: false,
      selectedTeacherGrade: null,
    });
  };

  render() {
    const { TeacherGrades, limit, offset, currentPage, totalTeacherGrades } =
      this.state;

    if (this.state.showTeacherGrade) {
      return (
        <TeacherGrade
          hideTeacherGrade={() => this.hideTeacherGrade()}
          grade_assessment={this.state.selectedTeacherGrade}
        />
      );
    }

    return (
      <AdminTemplate>
        <div style={{ height: "90vh" }}>
          <ContentWrapper>
            {/* <Spinner id="show" text="Please wait.." /> */}
            {this.state.isTableDataLoading && (
              <Spinner id="show" text="Please wait.." />
            )}
            <Card>
              <CardHeader>
                <CardTitle
                  cardLabel={`${this.props.location.state.assessment?.title} - Student's Reports`}
                >
                  <Breadcrumb>
                    <BreadcrumbItem
                      to={routes.view_assessment}
                      breadcrumbItemName="Assessments Library"
                    />
                  </Breadcrumb>
                </CardTitle>
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  style={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    value={this.state.search_term}
                    className="col-md-6"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </CardHeader>
              <CardBody>
              {TeacherGrades.length > 0 ? (
                  <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="thead">No</TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("report_id")}
                        >
                          Student Name
                          {this.state.sortColumn === "report_id" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowDownward sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell className="thead">
                          Student Status
                        </TableCell>
                        <TableCell className="thead">
                          Attempt Start Time
                        </TableCell>
                        <TableCell className="thead">
                          Attempt Finish Time
                        </TableCell>
                        <TableCell className="thead">Final Grade</TableCell>
                        {/* <TableCell className="thead">
                          Assessor Remarks
                        </TableCell> */}
                        <TableCell className="thead">Attempt No</TableCell>
                        <TableCell className="thead">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {TeacherGrades.map((el, i) => (
                        <TableRow key={i}>
                          <TableCell className="tData">{i + 1}</TableCell>
                          <TableCell className="tData">{`${el.first_name} ${el.last_name}`}</TableCell>
                          <TableCell className="tData">
                            <span
                              className={this.getColor(el.candidate_status)}
                            >
                              {el.candidate_status}
                            </span>
                          </TableCell>
                          <TableCell className="tData">
                            {el.attempt_start_time
                              ? this.getStartTime(el.attempt_start_time)
                              : "-"}
                          </TableCell>
                          <TableCell className="tData">
                            {el.attempt_end_time
                              ? this.getStartTime(el.attempt_end_time)
                              : "-"}
                          </TableCell>
                          <TableCell className="tData">
                            {el.final_grade || "-"}
                          </TableCell>
                          {/* <TableCell className="tData">
                            {el.assessor_remarks ? "Available" : "-"}
                          </TableCell> */}
                          <TableCell className="tData">
                            {el.attempt_no || "-"}
                          </TableCell>
                          <TableCell className="tData">
                            <Button
                              className="btn btn-icon action-btn btn-sm m-1 grade-public-assement-btn flaticon2-correct-icon"
                              title="Grade Answers"
                              onClick={() => {
                                this.setState({
                                  showTeacherGrade: true,
                                  selectedTeacherGrade: el,
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                <MaterialCustomTablePagination
                  totalCount={parseInt(totalTeacherGrades)}
                  currentPage={currentPage}
                  limit={limit}
                  handlePageChange={this.handlePageChange}
                  handleRowsPerPageChange={this.handleRowsPerPageChange}
                  handlePageOffsetChange={this.handlePageOffsetChange}
                  rowsPerPageOptions={[5, 10, 20]}
                  offset={offset}
                />
                </div>
                ) : null}
              </CardBody>
            </Card>
          </ContentWrapper>
        </div>
      </AdminTemplate>
    );
  }
}

export default TeacherGradeReport;
