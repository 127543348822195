import { AzureStorage } from "../../helper/azureStorage";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const gradeService = {
  saveMarks: saveMarks,
  finalizeGrade: finalizeGrade,
  finalizeAutoGrade: finalizeAutoGrade,
  saveSelfMarks: saveSelfMarks,
  selfFinalizeGrade: selfFinalizeGrade,
  getAllGrades: getAllGrades,
  getAllAssesmentGrades: getAllAssesmentGrades,
};

export const quesGradeService = {
  getAllManageGrades,
  createGrade,
  deleteGrade,
  updateGrade,
  getAllTeachers,
  createTeacherGrade,
  saveTeacherMarks,
  finalizeTeacherMarks,
  getAllTeachersReportsByReportId,
  getAllTeacherGrades
};

async function saveMarks(obj) {
    
  obj.assessor_remarks = JSON.stringify(obj.assessor_remarks);

  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/answers/saveMarks/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function finalizeGrade(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };
  return await fetch(
    `${config.oat_api_url}/candidate/grade/reports/finalize/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function finalizeAutoGrade(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };
  return await fetch(
    `${config.oat_api_url}/candidate/grade/reports/finalizeAutoGrade/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function selfFinalizeGrade(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };
  return await fetch(
    `${config.oat_api_url}/candidate/grade/reports/selfFinalize/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}
async function getAllManageGrades(
  limit,
  offset,
  sort_column,
  sort_direction,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/manage/grades/getAllGrades/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getAllGrades() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/manage/grades/getAll/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getAllAssesmentGrades() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/manage/grades/getAll/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}
async function createGrade(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };

  return await fetch(
    `${config.oat_api_url}/manage/grades/add/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function deleteGrade(obj) {
  try {
    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(obj),
    };
    return await fetch(
      `${config.oat_api_url}/manage/grades/delete/${obj.grade_id}`,
      requestOptions
    ).then(APIHandler.handleResponse);
  } catch (error) {
    return { error: error };
  }
}

async function updateGrade(obj) {
  try {
    let requestBody = {
      grade: obj.grade.toString(),
    };
    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(requestBody),
    };
    return await fetch(
      `${config.oat_api_url}/manage/grades/update/${obj.grade_id}`,
      requestOptions
    ).then(APIHandler.handleResponse);
  } catch (error) {
    return { error: error };
  }
}

async function saveSelfMarks(obj) {
  obj.assessor_remarks = JSON.stringify(obj.assessor_remarks);
  
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };

  await fetch(
    `${config.oat_api_url}/candidate/answers/saveSelfMarks/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function saveTeacherMarks(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/teacher-grade/saveTeacherMarks/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function finalizeTeacherMarks(obj) {

  var data = new Object();
  Object.assign(data, obj);

  let content = {
    final_grade: "",
    attempt_no: data.attempt_no,
    user_role_id: data.user_role_id,
    report_id: data.report_id,
    candidate_id: data.candidate_id,
    graded_by: data.graded_by,
    total_mark_allocation: data.total_mark_allocation,
    grade: data.grade
  };

  if (data.final_grade == null) {
    content["final_grade"] = "grade";
    data["final_grade"] = null;
  }

  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(content),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/teacher-grade/reports/teacherFinalize/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}
async function getAllTeachersReportsByReportId(report_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/teacher-grade/reports/get-report/${report_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getAllTeachers(search_term) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/manage/registration/teachers/getTeachersForPaperGrade?search_term=${search_term}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function createTeacherGrade(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(obj),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/teacher-grade/addAll/`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getAllTeacherGrades(
graded_by
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/teacher-grade/reports/getAll/${graded_by}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}
