import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const DiscountService = {
  add,
  update
};

//add a discount city
async function add(newDiscount) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newDiscount),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/discounts/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//update previous city
async function update(discountDetails, discoutId) {
  const configOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(discountDetails),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/discounts/update/${discoutId}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}
