import { userTypes } from "../types"

const initialState = {
    allStudentUsers: [],
    studentUserMap: {},
    studentUserRoleMap: {},
    loading: false,
    error: '',
    candidates: [],
    text: ''
}

export function studentUserReducer(state = initialState, action){
    switch (action.type){
        case userTypes.CREATE_STUDENTUSER_MAP:
            return {
                ...state,
                studentUserMap: action.studentuser_data.map,
                studentUsers: action.studentuser_data.studentusers,
                loading: true
            }
        case userTypes.GET_ALL_STUDENTUSERS:
            return {
                ...state,
                allStudentUsers: [],
                loading: true,
                text: 'Obtaining all the studentusers'
            }
        case userTypes.GET_ALL_STUDENTUSERS_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Obtaining all the studentusers'
            }
        case userTypes.GET_ALL_STUDENTUSERS_SUCCESS:
            return {
                ...state,
                allStudentUsers: action.studentusers,
                loading: false,
                success: 'Obtained studentusers successfully'
            }
        case userTypes.GET_ALL_STUDENTUSERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case userTypes.CREATE_STUDENT_USER_ROLES_MAP:
            return {
                ...state,
                studentUserRoleMap: action.map,
                loading: true
            }
        case userTypes.GET_ALL_STUDENT_USER_ROLES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case userTypes.GET_ALL_STUDENT_USER_ROLES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case userTypes.GET_ALL_STUDENT_USER_ROLES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}
