import React from "react";
import ContentWrapper from "../../components/common/ContentWrapper.js";
import Card from "../../components/common/Card.js";
import CardHeader from "../../components/common/CardHeader.js";
import CardBody from "../../components/common/CardBody.js";
import CardTitle from "../../components/common/QuestionCardTitle.js";
import moment from "moment";
import { connect } from "react-redux";
import { libraryActions } from "../../actions/assessment/libraryActions";
import { getReport } from '../../actions/candidate/examActions.js';
import { manageUserActions } from "../../actions/user_management/manageUserActions.js";
import { Button } from "@mui/material";
import PaperReview from "./PaperReview.js";
import config from '../../helper/config.js';
import {customerConstants} from "../../helper/constants.js";
import Spinner from "../../components/Spinner3.js";
import ReactSpinner from "react-bootstrap/Spinner";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import { unitConstants } from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, disabledBtnStyle,gradeReportbtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnStyle = {
      color: "var(--maincolor)",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins"
    }
    gradeReportbtnStyle = {
      color: "var(--maincolor)",
      width: "100%",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px",
      margin: "0px 5px",
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset",
      fontFamily: "Poppins"
    }
    disabledBtnStyle = {
      color: "#fff",
      backgroundColor: "#7E8299",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset",
      fontFamily: "Poppins"
    }
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    gradeReportbtnStyle = {
      color: "var(--maincolor)",
      width: "100%",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px",
      margin: "0px 5px",
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    disabledBtnStyle = {
      color: "#fff",
      backgroundColor: "#7E8299",
      borderColor: "transparent",
      boxShadow: "none"
    }
    break
  default:
    closeBtnStyle = {
      color: "var(--maincolor)",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins"
    }
    gradeReportbtnStyle = {
      color: "var(--maincolor)",
      width: "100%",
      backgroundColor: "#EDEEF7",
      borderColor: "transparent",
      textTransform: "unset",
      fontFamily: "Poppins",
      letterSpacing: "-0.2px",
      margin: "0px 5px",
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor)",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset",
      fontFamily: "Poppins"
    }
    disabledBtnStyle = {
      color: "#fff",
      backgroundColor: "#7E8299",
      borderColor: "transparent",
      boxShadow: "none",
      textTransform: "unset",
      fontFamily: "Poppins"
    }
    break
}

class PaperAutoGradeReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPaperReview: false,
      isReportLoading: false
    }
  }

  hidePaperReview = () => {
    this.setState({
      showPaperReview:false
    })
  }

  async componentDidMount() {


    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
    this.setState({isReportLoading: true});
    let candidate_id = parseInt(sessionStorage.getItem("candidate_id"));
    const attempt_no = parseInt(sessionStorage.getItem("attempt_no"));
    await this.props.getReport({ candidate_id, attempt_no });
    await this.props.getCandidate(this.props.report?.candidate_id);
    await this.props.getStudentDetails(parseInt(this.props.candidateDetails?.student_id));
    this.setState({isReportLoading: false});
  }

  getScheduleUnit(unit_id, value) {
    if(value !== null){
      if (unit_id !== null) {
          const unit = this.props.allUnits?.find(
              (unit) => unit.unit_id == unit_id
          );
          return unit?.unit;
      }
      else{
          return "N/A";
      }
    }
    else{
      return "";
    }
  }

  convertScheduleInSelectedUnit(value, unit) {
    if(value !== null){
      let valueInSelectedUnit;
      let selectedUnit = this.getScheduleUnit(unit, value);

      switch (selectedUnit) {
        case unitConstants.MONTHS.name:
          valueInSelectedUnit = value / 43200;
          break;
        case unitConstants.HOURS.name:
          valueInSelectedUnit = value / 60;
          break;
        case unitConstants.DAYS.name:
          valueInSelectedUnit = value / 1440;
          break;
        case unitConstants.MINUTES.name:
        default:
          valueInSelectedUnit = value;
      }
      
      return valueInSelectedUnit;
    }
    else{
      return 'Unlimited';
    }
  }

  getTextFromJson(jsonString) {
    const parsedData = JSON.parse(jsonString);
    const textValue = parsedData.blocks[0]?.text || "";
    return textValue;
  }

  render() {
    var remarks_view = <h3 className="m-0">Not Available</h3>;

    if (this.props.report.remark_content && this.props.report.final_grade !== null) {
      remarks_view = this.getTextFromJson(this.props?.report?.remark_content)
    }

    if(this.state.showPaperReview){
      return <PaperReview hidePaperReview = {() =>this.hidePaperReview()}  assessment={this.props.assessment} candidate={this.props.candidate} title={this.props.title}/>
    }

    return (
      <div style={{ height: "90vh" }}>
        {this.state.isReportLoading ? (
          <Spinner id="show" text="Loading Grade Report..." />
        ) : (
        <ContentWrapper>
          {/* begin::Card */}
          <Card>
            <CardHeader>
              <CardTitle
                cardLabel="Grade Report"
              />
              <div className="d-flex justify-content-end">
                {/* <Button
                  variant="contained"
                  style={
                    this.props.report?.final_grade !== null
                      ? addBtnStyle
                      : disabledBtnStyle
                  }
                  onClick={() => {
                    this.setState({
                      showPaperReview: true,
                    })
                  }}
                  disabled={
                    this.props.report?.final_grade !== null ? false : true
                  }
                >
                  View Answer Sheet
                </Button> */}
                <Button
                  className="ml-2"
                  variant="contained"
                  style={addBtnStyle}
                  onClick={() => {
                    this.props.offStartExam()
                  }}
                >
                  Back
                </Button>
              </div>
            </CardHeader>
            {/* <CardBody>
              <div className="row d-flex mb-4 align-items-center">
                <div className="col-lg-6 col-md-8 col-sm-8 d-flex justify-content-end">
                </div>
              </div>
              <div className="row exam-details">
                <div className="col col-lg-12">
                  <div className="row">
                    <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                      <div className="row w-100">
                        <div className="col-md-12 d-flex justify-content-start px-0">
                          <span className="grade-report-text font-size-sm">
                            User Code
                          </span>
                        </div>
                        <div className="col-md-12 d-flex justify-content-start grade-report-box">
                          <h3 className="m-0">{this.props.studentDetails?.user_code}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                      <div className="row w-100">
                        <div className="col-md-12 d-flex justify-content-start px-0">
                          <span className="grade-report-text font-size-sm">
                            Name
                          </span>
                        </div>
                        <div className="col-md-12 d-flex justify-content-start grade-report-box">
                          <h3 className="m-0">{this.props.studentDetails?.first_name + " " + this.props.studentDetails?.last_name}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                      <div className="row w-100">
                        <div className="col-md-12 d-flex justify-content-start px-0">
                          <span className="grade-report-text font-size-sm">
                            Exam Title:
                          </span>
                        </div>
                        <div className="col-md-12 d-flex justify-content-start grade-report-box">
                          <h3 className="m-0">
                            {this.props.assessment?.title}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                      <div className="row w-100">
                        <div className="col-md-12 d-flex justify-content-start px-0">
                          <span className="grade-report-text font-size-sm">
                            Attempted Date:
                          </span>
                        </div>
                        <div className="col-md-12 d-flex justify-content-start grade-report-box">
                          <h3 className="m-0">
                            {moment(
                              this.props.report?.attempt_start_time
                            ).format("MMMM Do YYYY")}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-6 col-md-6 col-12 mb-5 d-flex justify-content-start">
                      <div className="row w-100">
                        <div className="col-md-12 d-flex justify-content-start px-0">
                          <span className="grade-report-text font-size-sm">
                            Final Marks:
                          </span>
                        </div>
                        <div className="col-md-12 d-flex justify-content-start grade-report-box">
                          <h3 className="m-0">
                            {this.props.report?.final_grade !== null
                              ? this.props.report?.final_grade
                              : "Complete your self-grading to finalize your marks."}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
              </div>
            </CardBody> */}
            {this.state.isReportLoading ? (
                <div>
                  <div className="loading-container mb-5 mt-10">
                    <ReactSpinner
                      animation="border"
                      role="status"
                      style={{ display: "block", margin: "auto" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </ReactSpinner>
                  </div>
                </div>
              ) :
                (
                  <div className="w3-container ">
                  <div className="row lineGrade">
                    <div className="col-md-6 colPadding">
                      <div className="col-md-12 colPadding">
                        <div className="row">
                          <div className="col-md-4 colPadding">
                            <h2 className="text-white subData lineItems">Marks :&nbsp;</h2>
                          </div>
                          <div className="col-md-8 colPadding text-white theData">

                            {this.props.report.final_grade == null || this.props.report.final_grade == undefined
                                  ?
                                  <Tooltip title="Complete your self grading to finalize your marks" placement="top" arrow>
                                    <ErrorOutlineIcon />
                                  </Tooltip>
                                  : 
                                  this.props.report.final_grade + '/' + this.props.report.total_mark_allocation
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 colPadding">
                        <div className="row">
                          <div className="col-md-8 colPadding">
                            <h2 className="text-white subData lineItems">Attempt Number :&emsp;</h2>
                          </div>
                          <div className="col-md-4 colPadding text-white theData">
                            <div className="row">
                              <div className="theData">
                                {!!this.props.candidate.attempt_no ? this.props.candidate.attempt_no : 'Unknown'}
                              </div>
                              <div className="theData">
                                /
                              {!!this.props.candidate.no_of_attempts ? this.props.candidate.no_of_attempts : 'Unknown'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 colPadding lineItems">
                      <div className="row">
                        <div className="col-md-7 colPadding">
                          <h2 className="text-white subData lineItems">Paper duration :&nbsp;</h2>
                        </div>
                        <div className="col-md-5 colPadding text-white subData">
                          {this.convertScheduleInSelectedUnit(this.props?.candidate?.exam_time_interval, this.props?.candidate?.exam_time_interval_unit_id) + ' ' + this.getScheduleUnit(this.props?.candidate?.exam_time_interval_unit_id, this.props?.candidate?.exam_time_interval)} 
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 colPadding lineItems">
                      <h2 className="text-white subData lineItems">Attempted Date :&emsp;
                        {moment(
                          this.props.report.attempt_start_time
                        ).format("MMMM Do YYYY")}
                      </h2>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12 colPadding lineItems">
                      <h2 className="text-white subData lineItems">Final Grade :&emsp;
                        <b>{this.props?.report?.grade}</b>
                      </h2>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="col-md-12 colPadding lineItems">
                      <h2 className="text-white subData lineItems">Remarks :&emsp;
                        {remarks_view}
                      </h2>
                    </div>
                  </div> */}
                  {/* <div className="row">
                    <div className="col-md-3 colPadding lineItems">
                      <div className="col-md-12 colPadding">
                        <h2 className="text-white subData lineItems">Answered</h2>
                      </div>
                      <div className="col-md-12 colPadding text-white subData">
                        40
                      </div>
                    </div>
                    <div className="col-md-3 colPadding">
                      <div className="col-md-12 colPadding">
                        <h2 className="text-white subData lineItems">Unanswered</h2>
                      </div>
                      <div className="col-md-12 colPadding text-white theData">
                        <div className="row">

                          <div className="subData">
                            15
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 colPadding lineItems">
                      <div className="col-md-12 colPadding">
                        <h2 className="text-white subData lineItems">Correct</h2>
                      </div>
                      <div className="col-md-12 colPadding text-white subData">
                        35
                      </div>
                    </div>
                    <div className="col-md-3 colPadding lineItems">
                      <div className="col-md-12 colPadding">
                        <h2 className="text-white subData lineItems">Incorrect</h2>
                      </div>
                      <div className="col-md-12 colPadding text-white subData">
                        10
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-12 colPadding grbtn">
                    <div className="col-md-4 d-flex justify-content-end colPadding">
                      {this.state.showGradebutton && (
                        <Button
                          // variant="primary"
                          style={gradeReportbtnStyle}
                          onClick={() => {
                            // this.props.history.push({
                            //   pathname: "/self-grade",
                            // });
                            this.setState({
                              showSelfGrade: true,
                            })
                          }}
                        // disabled={
                        //   this.props.report.final_grade !== null ? false : true
                        // }
                        >
                          Self Grading
                        </Button>
                      )}
                    </div>
                    <div className="col-md-4 d-flex justify-content-end colPadding">
                    {this.state.showGradebutton && (
                      <Button
                        style={gradeReportbtnStyle}
                        onClick={this.toggleAddModal}
                      >
                        Teacher Grading
                      </Button>
                    )}
                    </div>
                    <div className="col-md-4 colPadding">
                      <Button style={gradeReportbtnStyle}
                        onClick={() => {
                          this.setState({
                            showPaperReview: true,
                          })
                        }}
                        disabled={
                          this.props.report.final_grade !== null ? false : true
                        }
                      >View Result Sheet
                      </Button>
                    </div>
                  </div>
                </div>
              )}
          </Card>
        </ContentWrapper>
        )}
      </div>
    )
  }
}

const mapStateToActions = {
  getReport: getReport,
  getCandidate: libraryActions.getCandidate,
  getStudentDetails: manageUserActions.getStudentDetails
};

function mapStateToProps(state) {
  return {
    candidateDetails: state.candidateReducer.candidateDetails,
    studentDetails: state.manageUserReducer.studentDetails,
    report: state.examReducer.report
  };
}

export default connect(mapStateToProps, mapStateToActions)(PaperAutoGradeReport);
