import {quesYearTypes} from '../settingsTypes';

const initialState = {
    allQuestionYears: [],
    loading: false,
    error: '',
    text: '',
    year:'',
    totalYearsCount: 0,
    allYears:[],
    erroryeardelete:false,
    loadingyeardelete:false,
    successyearupdate: false,
    loadingyearupdate: false,
    erroryearupdate:false,
    successyeardelete:false,
    erroryearcreate: '',
    successyearcreate: false,
}

export function yearReducer(state = initialState, action){
    switch (action.type){
        case quesYearTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allQuestionYears: [],
                loading: false,
                error: '',
                text: '',
                year:'',
                totalYearsCount:0,
                allYears:[],
                erroryeardelete:false,
                loadingyeardelete:false,
                successyearupdate: false,
                loadingyearupdate: false,
                erroryearupdate:false,
                successyeardelete:false,
                erroryearcreate: '',
                successyearcreate: false,
            }
        //Year retrieve request sending
        case quesYearTypes.GET_ALL_QUESTION_YEARS:
            return {
                ...state,
                loading: true,
                text: 'Get all the question years'
            }
        case quesYearTypes.GET_ALL_QUESTION_YEARS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case quesYearTypes.GET_ALL_QUESTION_YEARS_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionYears: action.years.years,
                totalYearsCount: action.years.total_count
            }
        case quesYearTypes.GET_ALL_QUESTION_YEARS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Year creation request sending
        case quesYearTypes.CREATE_YEAR_REQUEST:
            return {
                ...state,
                erroryearcreate: '',
                successyearcreate: false,
                loading: true
            }
        case quesYearTypes.CREATE_YEAR_SUCCESS:
            var temp = state.allYears;
            temp.push(action.years)
            return {
                ...state,
                allYears: temp,
                year: action.years.year_id,
                successyearcreate: true,
                loading: false
            }
        case quesYearTypes.CREATE_YEAR_FAILURE:
            return {
                ...state,
                erroryearcreate: action.error,
                loading: false
            }  
        //Year deletion request sending   
        case quesYearTypes.DELETE_YEAR_REQUEST:
            return {
            ...state,
            erroryeardelete: '',
            successyeardelete: false,
            loading: true,
            };
        case quesYearTypes.DELETE_YEAR_SUCCESS:
            return {
            ...state,
            successyeardelete: true,
            loading: false,
            };
        case quesYearTypes.DELETE_YEAR_FAILURE:
            return {
            ...state,
            erroryeardelete: action.error,
            loading: false,
            };
        //Year updation request sending
        case quesYearTypes.UPDATE_YEAR_REQUEST:
            return {
            ...state,
            erroryearupdate:'',
            successyearupdate: false,
            loading: true,
            };
        case quesYearTypes.UPDATE_YEAR_SUCCESS:
            return {
            ...state,
            successyearupdate: true,
            loading: false,
            };
        case quesYearTypes.UPDATE_YEAR_FAILURE:
            return {
            ...state,
            erroryearupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
