import React, { useEffect } from 'react';

export default function TabContent ({ children }) {

    return (
                    
        <div className="tab-content mt-5 pb-5" id="myTabContent1">
            {children}
        </div>
        
    )
}


