import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Stack,
  Button
} from "@mui/material";
import TextField from "@mui/material/TextField";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputAdornment from "@mui/material/InputAdornment";
import SimpleReactValidator from "simple-react-validator";
import { LimitService } from "../../../service/settings/manage/limit.service.js";
import { Spinner } from "react-bootstrap";
import {
  sweetErrorAlert,
  sweetStatusAlert,
  sweetSuccessAlert
} from "../../../components/common/SweetAlert";
import ContentWrapper from "../../../components/common/ContentWrapper";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

class CustomTabPanel extends Component {
  render() {
    const { children, value, index, ...other } = this.props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
};

class ManageLimit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: false,
      isGroupLimitDataLoading: false,
      isLoading: false,
      addLimitModal: false,
      updateLimitModal: false,
      limit: null,
      updateLimit: null,
      selectedLimit: null,
      limits: [],
      group_limits: null,
      overall_student_limit:null,
      updateStudentRegLimit:'',
      updateStudentRegLimitModal:false,
      tabValue: 0,
      group_parent_limit: null,
      group_student_limit: null,
      isParentGroupBtnLoading:false,
      isStudentGroupBtnLoading:false,
    };
    this.validator = new SimpleReactValidator();
  };

  componentDidMount() {
    this.getAllLimit();
    this.getAllGroupLimit()
  };

  getAllLimit = () => {
    this.setState({ isTableDataLoading: true });
    LimitService.getLimits().then((response) => {
      if (response.success) {
        this.setState({ limits: response.data?.grade_limits, overall_student_limit:response.data?.student_limit, isTableDataLoading: false });
      } else {
        this.setState({ isTableDataLoading: false });
      }
    });
  };

  getAllGroupLimit = () => {
    this.setState({ isGroupLimitDataLoading: true });
    LimitService.getGroupLimits().then((response) => {
      if (response.success) {
        console.log(response.data);
        
        this.setState({ 
          group_limits: response.data,
          group_parent_limit:response.data.parent_limit?.group_limit? String(response.data.parent_limit.group_limit):null,
          group_student_limit: response.data.student_limit?.group_limit? String(response.data.student_limit.group_limit):null,
          isGroupLimitDataLoading: false 
        });
        
      } else {
        this.setState({ isGroupLimitDataLoading: false });
      }
    });
  };

  toggleUpdateLimitModal = (limit) => {
    const { updateLimitModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessages();
    if (!updateLimitModal) {
      this.setState({
        updateLimit: limit.limit,
        selectedLimit: limit.limit_id,
        updateLimitModal: true,
      });
    } else {
      this.setState({
        updateLimit: null,
        selectedLimit: null,
        updateLimitModal: false,
      });
    }
  };

  handleEditSubmit = () => {
    const { updateLimit, selectedLimit } = this.state;
    if (!this.validator.fieldValid("updateLimit")) {
      this.validator.showMessageFor("updateLimit");
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const newUpdateLimit = {
        limit: updateLimit,
      };
      LimitService.updateLimit(newUpdateLimit, selectedLimit)
        .then((response) => {

          if (response.success) {

            if(response.data?.code === 1){

              this.setState({ isLoading: false });
              sweetStatusAlert(
                "Warning",
                `${response.message}`,
                "info"
              );
            }
            else{

              sweetSuccessAlert(
                "Success",
                "Limit updated successfully",
                "success"
              );

              this.setState({ isLoading: false });
              this.setState({ updateLimitModal: false });
              this.getAllLimit();
            }
          } 
          else {

            this.setState({ isLoading: false });
            sweetStatusAlert("Oops", response.message, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while updating Limit", "error");
        });
    }
  };

  handleLimitChange = (e) => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  toggleAddLimitModal = () => {
    const { addLimitModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessageFor("limit");
    this.setState({
      addLimitModal: !addLimitModal,
      grade: "",
    });
  };

  handleSubmit = () => {

    const { limit } = this.state;

    if (!this.validator.fieldValid("limit")) {

      this.validator.showMessageFor("limit");
      this.forceUpdate();
    } 
    else {
      this.setState({ isLoading: true });
      const newLimit = {
        limit: limit,
        type:'student'
      };
      LimitService.addLimit(newLimit)
        .then((response) => {
          if (response.success) {
            this.setState({ isLoading: false });
            this.toggleAddLimitModal();
            sweetSuccessAlert(
              "Success",
              "A new student Limit added successfully",
              "success"
            );
            this.getAllLimit();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Info", response.message, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while adding Limit", "error");
        });
    }
  };

  StudentLimit = () => {

    const {
      overall_student_limit,
      isTableDataLoading
    } = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Total Student Registration Limit
          </Typography>
          </Col>
          <Col>
          <Typography component="h2" variant="h6" color="secondary" gutterBottom>
              Active Registrations Count
            </Typography>
          </Col>
          </Row>
          {!isTableDataLoading && !overall_student_limit ? (
            <div>
              <Button onClick={this.toggleAddLimitModal}>Setup Registration Limit</Button>
            </div>
          ):(
            <>
            <Row>
            <Col>
              <Typography component="p" variant="h4" color="error">
                {overall_student_limit?.limit}
              </Typography>
            </Col>
            <Col>
            <Typography component="p" variant="h4" color="success">
              {overall_student_limit?.limit_usage}
            </Typography>
            </Col>
            </Row>
            <Row>
              <Col>
              <Typography color="text.secondary" sx={{ flex: 1 }}>
                Last update : {overall_student_limit?.updated_at}
              </Typography>
              </Col>
            </Row>
          <br/>
          <Row>
            <Col>
            <div>
            <Button variant="contained" onClick={this.toggleUpdateStudentRegLimitModal}>Update</Button>
            </div>
            </Col>
          </Row>
            </>
          )
        }
      </React.Fragment>
    );
  };

  toggleUpdateStudentRegLimitModal = () => {
    const { updateStudentRegLimitModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessageFor("updateStudentRegLimit");
    this.setState({
      updateStudentRegLimitModal: !updateStudentRegLimitModal,
    },()=>{
      if(this.state.updateStudentRegLimitModal){
        const currLimit = this.state.overall_student_limit?.limit
        this.setState({updateStudentRegLimit:currLimit})
      }
      else{
        this.setState({updateStudentRegLimit:""})
      }
    });
  };

  handleStudentRegEditSubmit = () => {
    const { updateStudentRegLimit,overall_student_limit,updateStudentRegLimitModal } = this.state;
    if (!this.validator.fieldValid("updateStudentRegLimit")) {
      this.validator.showMessageFor("updateStudentRegLimit");
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const newUpdateLimit = {
        limit: updateStudentRegLimit,
      };
      LimitService.updateStudentRegLimit(newUpdateLimit, overall_student_limit.limit_id)
        .then((response) => {

          if (response.success) {

            if(response.data?.code === 1){

              this.setState({ isLoading: false });
              sweetStatusAlert(
                "Warning",
                `${response.message}`,
                "info"
              );
            }
            else{

              sweetSuccessAlert(
                "Success",
                "Limit updated successfully",
                "success"
              );

              this.setState({ isLoading: false });
              this.setState({ updateStudentRegLimitModal:false, updateStudentRegLimit:'',  });
              this.getAllLimit();
            }
          } 
          else {

            this.setState({ isLoading: false });
            sweetStatusAlert("Oops", response.message, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while updating Limit", "error");
        });
    }
  };

  handleTabChange = (event, newValue) =>{

    this.setState({tabValue:newValue},
      ()=>{
        if(this.state.tabValue === 1){
          this.setState({isParentGroupBtnLoading:false,isStudentGroupBtnLoading:false})
        }
      }
    )

  };

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  handleGroupLimitSubmit = (type) => {

    const { group_student_limit,group_parent_limit } = this.state;

    if(type === "parent"){

      if(!this.validator.fieldValid("group_parent_limit")){
        this.validator.showMessageFor("group_parent_limit");
        this.forceUpdate();
      }
      else{

        this.setState({ isParentGroupBtnLoading: true });
        const newGroupLimit = {
          group_limit: group_parent_limit,
          type:'parent'
        };

        LimitService.addGroupLimit(newGroupLimit)
        .then((response) => {
          if (response.success) {
            this.setState({ isParentGroupBtnLoading: false });
            sweetSuccessAlert(
              "Success",
              "A new parent limit added successfully",
              "success"
            );
          } else {
            this.setState({ isParentGroupBtnLoading: false });
            sweetStatusAlert("Info", response.message, "info");
          }
        })
        .catch((error) => {
          this.setState({ isParentGroupBtnLoading: false });
          sweetErrorAlert("Error", "Error while adding Limit", "error");
        });

      }

    }
    else if(type === "student"){
      if(!this.validator.fieldValid("group_student_limit")){
        this.validator.showMessageFor("group_student_limit");
        this.forceUpdate();
      }
      else{

        this.setState({ isStudentGroupBtnLoading: true });
        const newGroupLimit = {
          group_limit: group_student_limit,
          type:'student'
        };

        LimitService.addGroupLimit(newGroupLimit)
        .then((response) => {
          if (response.success) {
            this.setState({ isStudentGroupBtnLoading: false });
            sweetSuccessAlert(
              "Success",
              "A new student limit added successfully",
              "success"
            );
          } else {
            this.setState({ isStudentGroupBtnLoading: false });
            sweetStatusAlert("Info", response.message, "info");
          }
        })
        .catch((error) => {
          this.setState({ isStudentGroupBtnLoading: false });
          sweetErrorAlert("Error", "Error while adding Limit", "error");
        });

      }
    }
  };


  render() {
    const {
      addLimitModal,
      limit,
      updateLimitModal,
      isTableDataLoading,
      isLoading,
      limits,
      updateLimit,
      updateStudentRegLimitModal,
      overall_student_limit,
      updateStudentRegLimit,
      tabValue,
      group_parent_limit,
      group_student_limit,
      isParentGroupBtnLoading,
      isStudentGroupBtnLoading,
      isGroupLimitDataLoading,
    } = this.state;

    const buttonSxParent = {
      ...(!isParentGroupBtnLoading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const buttonSxStudent = {
      ...(!isStudentGroupBtnLoading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    return (
      <ContentWrapper>
        <div className="row justify-content-between align-items-center pt-5">
          <div className="col-md-12 col-12">
            <h3 className="text-nowrap">Manage Registration Limit</h3>
          </div>
          <br/>
          <br/>
          <br/>
          <div className="col-md-6 col-6 ">
          {isTableDataLoading ? (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
        ):(
          (this.StudentLimit())
        )}
          </div>
          {/* <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleAddLimitModal}
          >
            Add New Limit
          </Button> */}

        </div>
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "75vw",
              height: "90vh",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (

            <Tabs value={tabValue} onChange={this.handleTabChange} aria-label="disabled tabs example" variant="fullWidth">

              <Tab label="Grade Wise Student Registration Limit" {...this.a11yProps(0)}>

              </Tab>

              <Tab label="Limit Number of Users Student and Parent Relation" variant="fullWidth" {...this.a11yProps(1)}>
              </Tab>

            </Tabs>

        )}

        <CustomTabPanel value={tabValue} index={0}>

          <div style={{ width: "80vw", height: "90vh", overflow: "auto", marginTop:'20px' }}>
                  {/* <Typography component="h2" variant="h6" color="secondary" gutterBottom>
                    Grade Wise Student Registration Limit
                  </Typography> */}
                  <Table>
                    <TableHead>
                      <TableRow>
                      <TableCell className="thead">
                          Grade
                        </TableCell>
                        <TableCell className="thead">
                          Student registration limit
                        </TableCell>
                        <TableCell className="thead">
                          Limit usage
                        </TableCell>
                        <TableCell className="thead" align="right">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {limits.length > 0 ? (
                        limits.map((Limit) => (
                          <TableRow key={Limit.limit_id}>
                            <TableCell className="tData">{Limit.grade}</TableCell>
                            <TableCell className="tData">{Limit.limit}</TableCell>
                            <TableCell className="tData">{Limit.grade_limit_usage}</TableCell>
                            <TableCell className="tData" align="right">
                              <Button
                                onClick={() => this.toggleUpdateLimitModal(Limit)}
                                className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                title="Edit Details"
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={2}>No limits found</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
          </div>

        </CustomTabPanel>

        <CustomTabPanel value={tabValue} index={1}>

          {/* <div style={{ width: "80vw", height: "90vh", overflow: "auto", marginTop:'20px' }}> */}
            <Grid container style={{ width: "80vw", height: "90vh", overflow: "auto", marginTop:'20px' }}>
              <Grid item xs={12} md={12}>
                {/* <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                  Avatar with text and icon
                </Typography> */}

                {isGroupLimitDataLoading ? (
                  <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                ):(
                  <List dense={true} sx={{mt:5}}>
                      
                      <ListItem
                        secondaryAction={

                          <Stack spacing={{ xs: 1, sm: 2 }}
                          direction="row"
                          useFlexGap
                          sx={{ flexWrap: 'wrap' }}>

                            <Stack>
                              <TextField 
                                name="group_parent_limit" 
                                id="filled-basic" 
                                label={`${this.state.group_limits?.parent_limit?.group_limit ? 'Parent limit for student' : 'Set-up Limit'}`} 
                                variant="standard" 
                                sx={{width:'20vw'}}
                                type="number"
                                value={group_parent_limit}
                                onChange={this.handleLimitChange}
                              />
                              {this.validator.message(
                                "group_parent_limit",
                                group_parent_limit,
                                "required|min:1,num",
                                {
                                  className: "text-danger",
                                  messages:{
                                    required: "The group parent limit required.",
                                  }
                                }
                              )}
                            </Stack>

                            <Box sx={{ position: 'relative' }}>

                              <Button  sx={buttonSxParent} disabled={isParentGroupBtnLoading} variant="contained" size="large" onClick={()=>this.handleGroupLimitSubmit('parent')}>
                                Save
                              </Button>

                              {isParentGroupBtnLoading && (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                  }}
                                />
                              )}
                            </Box>

                          </Stack>
                          
                        }
                        
                    >
                        <ListItemAvatar>
                          <Avatar>
                            <EscalatorWarningIcon />
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary="Number of Parents per Student"
                          // secondary={'Secondary text'}
                        />
                      </ListItem>

                      <ListItem
                        secondaryAction={
                          <Stack spacing={2} direction="row">
                          
                            <Stack>
                              <TextField 
                                name="group_student_limit" 
                                id="filled-basic" 
                                label={`${this.state.group_limits?.student_limit?.group_limit ? 'Stuent limit for parent' : 'Set-up Limit'}`} 
                                variant="standard" 
                                sx={{width:'20vw'}}
                                type="number"
                                value={group_student_limit}
                                onChange={this.handleLimitChange}
                              />
                              {this.validator.message(
                                "group_student_limit",
                                group_student_limit,
                                "required|min:1,num",
                                {
                                  className: "text-danger",
                                  messages: {
                                    required: "The group student limit required.",
                                  },
                                }
                              )}
                            </Stack>

                          <Box sx={{ position: 'relative' }}>

                            <Button 
                              sx={buttonSxStudent} 
                              disabled={isStudentGroupBtnLoading} 
                              variant="contained" 
                              size="large" 
                              onClick={()=>this.handleGroupLimitSubmit('student')}
                            >
                              Save
                            </Button>
                            
                            {isStudentGroupBtnLoading && (
                                <CircularProgress
                                  size={24}
                                  sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                  }}
                                />
                              )}

                          </Box>
                          </Stack>
                        }
                        sx={{padding:2,mt:3}}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <FamilyRestroomIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary="Number of Students per Parent"
                          // secondary={'Secondary text'}
                        />
                      </ListItem>,
                    
                  </List>
                )}

                

                
            
              </Grid>
            </Grid>
          {/* </div> */}

        </CustomTabPanel>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateLimitModal}
          onHide={this.toggleUpdateLimitModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Limit</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      type="number"
                      name="updateLimit"
                      value={updateLimit ? updateLimit : ""}
                      onChange={this.handleLimitChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Limit
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.validator.message(
                      "updateLimit",
                      updateLimit,
                      "required|min:1,num",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}
            <Modal.Footer>
              <Button
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
                sx={{ mr: "20px" }}
                onClick={this.toggleUpdateLimitModal}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={() => this.handleEditSubmit()}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={addLimitModal}
          onHide={this.toggleAddLimitModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Set Student Registration Limit</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      type="number"
                      name="limit"
                      defaultValue={limit}
                      onChange={this.handleLimitChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Limit
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.validator.message(
                      "limit",
                      limit,
                      "required|min:1,num",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button
                sx={{ mr: "20px" }}
                onClick={this.toggleAddLimitModal}
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        
        </Modal>
        
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateStudentRegLimitModal}
          onHide={this.toggleUpdateStudentRegLimitModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Student Registration Limit</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      type="number"
                      name="updateStudentRegLimit"
                      value={updateStudentRegLimit ? updateStudentRegLimit : ""}
                      onChange={this.handleLimitChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Limit
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.validator.message(
                      "updateStudentRegLimit",
                      updateStudentRegLimit,
                      "required|min:1,num",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}
            <Modal.Footer>
              <Button
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
                sx={{ mr: "20px" }}
                onClick={this.toggleUpdateStudentRegLimitModal}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={() => this.handleStudentRegEditSubmit()}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

      </ContentWrapper>
    );
  }
}

export default ManageLimit;
