import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const assessSubjectService = {
    getAllAssessmentSubjects,
    getAllSubjects,
    createSubject,
    deleteSubject,
    updateSubject
};

async function getAllAssessmentSubjects(limit, offset, sort_column, sort_direction, search_term) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/subjects/getAllSubjects/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}`, requestOptions)
        .then(APIHandler.handleResponse);
}

async function getAllSubjects() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/subjects/getAll/`, requestOptions)
        .then(APIHandler.handleResponse);
}

async function createSubject(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/manage/subjects/add/`, requestOptions).then(APIHandler.handleResponse);
}

async function deleteSubject(obj) {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(obj)
        };
        return await fetch(`${config.oat_api_url}/manage/subjects/delete/${obj.subject_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function updateSubject(obj) {
    try {
        let requestBody = {
            name: obj.name.toString()
        }
        const requestOptions = {
            method: 'PUT',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(requestBody)
        };

        let data = await fetch(`${config.oat_api_url}/manage/subjects/update/${obj.subject_id}`, requestOptions);

        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}
