import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

export default function Spinner({ active, text }) {
  // Convert the 'active' prop to a boolean
  const isActive = !!active;

  return (
    <Dimmer active={isActive} inverted>
      <Loader
        className="Loader"
        inline="centered"
        active={isActive}
        size="medium"
      >
        <h4 style={{ color: "grey" }}>{text}</h4>
      </Loader>
    </Dimmer>
  );
}
