import moment from "moment-timezone";

class TimeZoneHandler {
static convertTime  (utcDateString) {
  const userTimeZone = moment.tz.guess();
  const convertedDate = moment.utc(utcDateString).tz(userTimeZone);

  const formattedDate = convertedDate.format("MMMM D, YYYY h:mm:ss a");

  return formattedDate;
  }

  static convertTimeByUserLocation(utcDateString) {
    
    const userTimeZone = moment.tz.guess();
  
    const utcDateTime = moment.utc(utcDateString, "YYYY-MM-DD HH:mm:ss.SSSSSS");

    const convertedDate = utcDateTime.clone().tz(userTimeZone);

    const formattedDate = convertedDate.format("YYYY-MM-DD HH:mm:ss.SSSSSS");

    return formattedDate;
  }
}

export default TimeZoneHandler;
