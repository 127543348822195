import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const termsConditionsService = {
    getAllAssessmentTermsConditions,
    getAssessmentTermsConditionsForLanguage,
    createAssessmentTermsConditions,
    updateAssessmentTermsConditions
};

async function getAllAssessmentTermsConditions() {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    }
  };

  const response = await fetch(
    `${config.oat_api_url}/assessment/terms-and-conditions/getAll/`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAssessmentTermsConditionsForLanguage(language_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    }
  };

  const response = await fetch(
    `${config.oat_api_url}/assessment/terms-and-conditions/get/${language_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function createAssessmentTermsConditions(obj) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),    
  };

  const response = await fetch(
    `${config.oat_api_url}/assessment/terms-and-conditions/add/`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateAssessmentTermsConditions(obj) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(obj),    
  };

  const response = await fetch(
    `${config.oat_api_url}/assessment/terms-and-conditions/update/${obj.term_condition_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


