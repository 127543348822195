export default class DataHandler {

    static SESSION_DATA = {
        IdToken: 'IdToken',
        FederationService: 'FederationService',
        userID: 'userID',
        UserEmail: 'UserEmail',
        TimeZone: 'TimeZone',
        FirstName: 'FirstName',
        LastName: 'LastName',
        AssessmentID: 'AssessmentID'
    }


    static setToSession(name, value) {
        sessionStorage.setItem(name, value);
    }

    static getFromSession(name) {
        return sessionStorage.getItem(name);
    }

    static clearSession() {
        sessionStorage.clear();
    }
}