import React, { useEffect, useState } from "react"
import "../dashboard.css"
import config from "../../../../../helper/config.js"
import { customerConstants } from "../../../../../helper/constants.js"
import PaidIcon from "@mui/icons-material/Paid"
import { Dropdown } from "react-bootstrap"
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}

const Revenue = ({dashboardDataLoading,loaderSection,renderDoghnutCharts, suitableArray}) => {
  const [monthBasedRevenues, setMonthBasedRevenues] = useState(undefined);
  const [selectedRevenues, setSelectedRevenues] = useState(null);
  const [monthBasedTop5Revenues, setMonthBasedTop5Revenues] = useState(undefined);
  const [selectedTop5Revenues, setSelectedTop5Revenues] = useState(null);
  useEffect( () => {
    fetchSuitableRevenue('Revenue-info1', 1100, setMonthBasedRevenues,setSelectedRevenues)
    fetchSuitableRevenue('Revenue-info3', 1100, setMonthBasedTop5Revenues,setSelectedTop5Revenues)
  },[suitableArray])
  
  const fetchSuitableRevenue = (chart_type, chart_id, setAllFunc, setSelectFunc) => {
    const suitableArray_ = suitableArray.find(item => item.chart_type === chart_type && item.chart_id === chart_id)
    const groupedData =suitableArray_?.array.reduce((acc, obj) => {
      const key = `${obj.flg_chart_legend_x_filter}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    if(groupedData){
      const filteredGroupedData = Object.values(groupedData)
      setAllFunc(filteredGroupedData)
      setSelectFunc(filteredGroupedData[0])
    }
  }

  const selectDropdown = (obj) => {
    setSelectedRevenues(obj)
    const selectedTop5Revenues = monthBasedTop5Revenues.find(obj_ => {
      return  obj_[0].flg_chart_legend_x_filter === obj[0].flg_chart_legend_x_filter
    })
    setSelectedTop5Revenues(() => {
      return selectedTop5Revenues
    })
  }
  return (
    <div className="row d-flex">
      <div className="col-md-12 mb-3 pl-0 px-0 px-sm-0">
        <div className="dashboard__container">
          <div className="row d-flex justify-content-between align-items-center my-3">
            <h3 className="dashboard__container-title m-0">
              <PaidIcon sx={{ fontSize: 22, color: "#5c5c5c" }} /> Revenue
            </h3>
            <div className="">
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #E4E6EF",
                    color: "var(--secondary-text-color)",
                    fontWeight: "400",
                    fontSize: "10px",
                  }}
                >
                  {selectedRevenues&&selectedRevenues[0]?.flg_chart_legend_x_filter}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {monthBasedRevenues && monthBasedRevenues.map(obj => (
                    <Dropdown.Item
                      onClick={() => {selectDropdown(obj)}}
                    >
                      {obj[0].flg_chart_legend_x_filter}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="row d-flex dashboard__count-cards-orange align-items-center py-3">
            <div className="col-md-2 col-2 p-0 revenue__icon d-flex align-items-center">
              <SavingsRoundedIcon
                sx={{
                  fontSize: 15,
                  color: "#fff",
                }}
              />
            </div>
            <div className="col-md-10 col-10">
              <p className="revenue-text">{selectedRevenues && selectedRevenues[0].chart_x_value}</p>
              <h3 className="revenue-amount m-0">LKR {selectedRevenues && selectedRevenues[0].chart_y_value}</h3>
            </div>
          </div>
          <div className="row my-5 d-flex justify-content-center">
            {/* <h3 className="dashboard-subheading m-0">Course Wise Revenue</h3> */}
            {dashboardDataLoading ? loaderSection() : (selectedRevenues && renderDoghnutCharts(1100, selectedRevenues[0]?.flg_chart_legend_x_filter))}
          </div>
          <div className="row my-5">
            <h3 className="dashboard-subheading m-0 my-5">
              Top 5 courses with highest revenue
            </h3>
            <div className="col-md-12 px-0">
              <div className="row d-flex justify-content-between receivables-row py-5">
                <h5 className="receivables-title m-0">Course Name</h5>
                <p className="receivables-title">Revenue (LKR)</p>
              </div>
              {
                selectedTop5Revenues && selectedTop5Revenues?.map(item => (
                  <div className="row d-flex justify-content-between receivables-row">
                    <p className="m-0 revenue-row-text">{item.chart_x_value}</p>
                    <h5 className="m-0">{item.chart_y_value}</h5>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Revenue
