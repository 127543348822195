import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const AnnouncementTopicService = {
  create,
  update,
  getAnnouncementTopicByPagination,
  getAllAnnouncementTopics,
  deleteAnnouncementTopic
};

async function create(calendarEvent) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(calendarEvent),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/announcement-topics/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(calendarEvent, calendarEventId) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(calendarEvent),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/announcement-topics/update/${calendarEventId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllAnnouncementTopics() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/announcement-topics/getAllAnnouncementTopics`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


async function getAnnouncementTopicByPagination(limit, offset, search_term) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/announcement-topics/getAll/${limit}/${offset}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteAnnouncementTopic(id) {
  const configOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/announcement-topics/delete/${id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}