import {quesChapterTypes} from '../settingsTypes';

const initialState = {
    allQuestionChapters: [],
    loading: false,
    error: '',
    text: '',
    chapter:'',
    totalChaptersCount: 0,
    allChapters:[],
    errorchapterdelete:false,
    loadingchapterdelete:false,
    successchapterupdate: false,
    loadingchapterupdate: false,
    errorchapterupdate:false,
    successchapterdelete:false,
    errorchaptercreate: '',
    successchaptercreate: false,
}

export function chapterReducer(state = initialState, action){
    switch (action.type){
        case quesChapterTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allQuestionChapters: [],
                loading: false,
                error: '',
                text: '',
                chapter:'',
                totalChaptersCount:0,
                allChapters:[],
                errorchapterdelete:false,
                loadingchapterdelete:false,
                successchapterupdate: false,
                loadingchapterupdate: false,
                errorchapterupdate:false,
                successchapterdelete:false,
                errorchaptercreate: '',
                successchaptercreate: false,
            }
        //Chapter retrieve request sending
        case quesChapterTypes.GET_ALL_QUESTION_CHAPTERS:
            return {
                ...state,
                loading: true,
                text: 'Get all the question chapters'
            }
        case quesChapterTypes.GET_ALL_QUESTION_CHAPTERS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case quesChapterTypes.GET_ALL_QUESTION_CHAPTERS_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionChapters: action.chapters.chapters,
                totalChaptersCount: action.chapters.total_count
            }
        case quesChapterTypes.GET_ALL_QUESTION_CHAPTERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Chapter creation request sending
        case quesChapterTypes.CREATE_CHAPTER_REQUEST:
            return {
                ...state,
                errorchaptercreate: '',
                successchaptercreate: false,
                loading: true
            }
        case quesChapterTypes.CREATE_CHAPTER_SUCCESS:
            var temp = state.allChapters;
            temp.push(action.chapters)
            return {
                ...state,
                allChapters: temp,
                chapter: action.chapters.chapter_id,
                successchaptercreate: true,
                loading: false
            }
        case quesChapterTypes.CREATE_CHAPTER_FAILURE:
            return {
                ...state,
                errorchaptercreate: action.error,
                loading: false
            }  
        //Chapter deletion request sending   
        case quesChapterTypes.DELETE_CHAPTER_REQUEST:
            return {
            ...state,
            errorchapterdelete: '',
            successchapterdelete: false,
            loading: true,
            };
        case quesChapterTypes.DELETE_CHAPTER_SUCCESS:
            return {
            ...state,
            successchapterdelete: true,
            loading: false,
            };
        case quesChapterTypes.DELETE_CHAPTER_FAILURE:
            return {
            ...state,
            errorchapterdelete: action.error,
            loading: false,
            };
        //Chapter updation request sending
        case quesChapterTypes.UPDATE_CHAPTER_REQUEST:
            return {
            ...state,
            errorchapterupdate:'',
            successchapterupdate: false,
            loading: true,
            };
        case quesChapterTypes.UPDATE_CHAPTER_SUCCESS:
            return {
            ...state,
            successchapterupdate: true,
            loading: false,
            };
        case quesChapterTypes.UPDATE_CHAPTER_FAILURE:
            return {
            ...state,
            errorchapterupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
