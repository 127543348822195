    
    
import React, { Component } from "react"; 
import CardBody from "../../../components/common/CardBody.js";
import TimeZoneHandler from "../../../handlers/TimeZoneHandler";
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TextField,
    TableCell
} from "@mui/material";
import Button from "react-bootstrap/Button";
import Dropdown from 'react-bootstrap/Dropdown';
import config from "../../../helper/config.js";
import { customerConstants } from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}
export class OwnerRestrictedViewPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInsertModal: false,
            showModal: false
        }        
    }

    componentDidMount(){
        this.props.getPayment(this.props.state.lg_user_id,this.props.state.mid)
    }

    render (){
        const {
            addVisibility,
            editVisibility,
            deleteVisibility,
            amount,
            paymentValue,
            payments,
            studentDiscount,
            paymentLoading,
            handlePaymentChange,
            submitPayment,
            formattedDateString,
            formattedTimeString,
            amountToLRK,
            totalPaid,
            calOutstandingAmount,
            otherEnrollments,
            handlePaymentDescriptionChange,
            paymentDescription,
            handlePaymentMethodSelect,
            selectedPaymentMethod,
            paymentMethods 
        } = this.props
        return (
            
            <CardBody>
                <>
                <h3 className="payment-total">
                <div className=" d-flex justify-content-between">
                  <p style={{ fontSize: "13px" }}>Amount</p>
                  <p>{amountToLRK(amount)}</p>
                </div>
                  {payments && <div className=" d-flex justify-content-between">
                  <p style={{ fontSize: "12px" }}>Applied Discount</p>
                  <p style={{ fontSize: "12px" }}>{`${studentDiscount?.discount ? studentDiscount?.discount : 0} %`}</p>
                  </div>}
                  {payments && <div className=" d-flex justify-content-between">
                  <p style={{ fontSize: "12px" }}>Discounted Amount</p>
                  <p style={{ fontSize: "12px" }}>{amountToLRK((amount/100)*(studentDiscount?.discount?studentDiscount?.discount:0))}</p>
                  </div>}
                  {payments && <div className=" d-flex justify-content-between">
                  <p style={{ fontSize: "12px" }}>Amount After Discount Applied</p>
                  <p style={{ fontSize: "12px" }}>{amountToLRK(amount - (amount/100)*(studentDiscount?.discount?studentDiscount?.discount:0))}</p>
                  </div>}
                </h3>
                {addVisibility && (
                    <div className="row d-flex justify-content-between">
                    <div className="col-md-4 px-0 no-arrow">
                      <Dropdown data-bs-theme="dark">
                        <Dropdown.Toggle
                          id="dropdown-button-dark-example1"
                          variant="secondary"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          {!!selectedPaymentMethod
                            ? selectedPaymentMethod.name
                            : "Select"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          style={{
                            maxHeight: "190px",
                            overflowY: "auto",
                            width: "100%",
                          }}
                        >
                          {paymentMethods?.map((meth) => (
                            <Dropdown.Item
                              key={meth.payment_method_id}
                              onClick={() => handlePaymentMethodSelect(meth)}
                            >
                              <div className="col-6">{meth.name}</div>
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <div className="col-md-8 col-8 px-0 no-arrow">
                        <TextField
                            size="small"
                            type="number"
                            placeholder="Enter Payment"
                            onChange={(e) => handlePaymentChange(e)}
                            variant="outlined"
                            value={paymentValue}
                            inputProps={{ min: 0 }}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className="col-md-12 col-12 my-2 px-0 no-arrow">
                      <TextField
                        size="small"
                        type="text"
                        placeholder="Payment Description"
                        onChange={(e) => handlePaymentDescriptionChange(e)}
                        variant="outlined"
                        value={paymentDescription}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="w-100 d-flex justify-content-end">
                        <Button
                        className="insert-btn"
                        variant="primary"
                        disabled={paymentValue.length < 1}
                        onClick={submitPayment}
                        style={{ height: "36px" }}
                        >
                        Add Payment
                        </Button>
                    </div>
                    </div>
                )}
                </>
                <h3>Payment History</h3>
                {payments?.length > 0 ? (
                <>
                    <Table>
                    <TableHead>
                        <TableCell className="thead">Payment Date</TableCell>
                        <TableCell className="thead payment-amount">
                          Payment Type
                        </TableCell>
                        <TableCell className="thead payment-amount">
                          Payment
                        </TableCell>
                        <TableCell className="thead payment-amount">Manual Receipt Code</TableCell>
                        {customer === 'CGC' && (
                        <TableCell className="thead payment-amount">Manual Receipt Code</TableCell>
                        )}
                    </TableHead>
                    <TableBody>
                        {payments.map((payment) => {
                        return (
                        <TableRow key={payment.id}>
                            <TableCell>
                            <div>
                                {formattedDateString(payment.created_at)
                                ? formattedDateString(
                                    TimeZoneHandler.convertTime(payment.created_at)
                                    )
                                : undefined}
                            </div>
                            <div>
                                {formattedTimeString(payment.created_at)
                                ? formattedTimeString(
                                    TimeZoneHandler.convertTime(payment.created_at)
                                    )
                                : undefined}
                            </div>
                            </TableCell>
                            <TableCell className="payment-amount">
                              {payment?.payment_method}
                            </TableCell>
                            <TableCell className="payment-amount">
                              {amountToLRK(payment?.payment)}
                            </TableCell>
                            {customer === 'CGC' && (
                            <TableCell className="payment-amount">
                              {payment?.manual_recipt_code || "-"}
                            </TableCell>
                            )}
                            <TableCell className="payment-amount">
                              {payment?.recipt_code || "-"}
                            </TableCell>
                        </TableRow>
                        )})}
                    </TableBody>
                    </Table>
                    <div className="row d-flex justify-content-between py-3 px-5 payment-total">
                    <p>
                        <b>Total</b>
                    </p>
                    <p>
                        <b>{`${amountToLRK(totalPaid())}`}</b>
                    </p>
                    </div>
                    <div className="row d-flex justify-content-between py-3 px-5 outstanding-total">
                    <p>
                        <b>Outstanding Amount</b>
                    </p>
                    <p>
                        <b>{amountToLRK(calOutstandingAmount(amount,totalPaid(),studentDiscount?.discount ))}</b>
                    </p>
                    </div>
                </>
                ) : (
                <div className="text-center mb-5 mt-10">
                    <label>No Payments Yet </label>
                </div>
                )}
                <h3>Other Enrollments</h3>
                {otherEnrollments.length > 0 ? (
                otherEnrollments.map((enrollment) => (
                    <p>
                    {enrollment.breadcrumb}
                    {enrollment.tree_main_title}
                    </p>
                ))
                ) : (
                <div className="text-center mb-5 mt-10">
                    <label>No Other Enrollments </label>
                </div>
                )}
            </CardBody>
        )
    }

}

    