import { staffUserService } from "../../service/user_management/staff.user.service";
import { userTypes } from "../../reducers/types";
import {store} from "../../helper/redux_store";

export const staffUserActions = {
    getAllStaffUsers,
    createStaffUserMap,
    createStaffUserRoleMap
};

function getAllStaffUsers() {
    return async dispatch => {
        try {
            dispatch(get_staffusers());
            dispatch(get_staffusers_request());
            let staffusers = await staffUserService.getAllStaffUsers();
            dispatch(get_staffusers_success(staffusers.data));
        } catch (error) {
            dispatch(get_staffusers_failure(error));
        }
    };

    function get_staffusers() {
        return {type: userTypes.GET_ALL_STAFFUSERS}
    }

    function get_staffusers_request() {
        return {type: userTypes.GET_ALL_STAFFUSERS_REQUEST}
    }

    function get_staffusers_success(data) {
        return {type: userTypes.GET_ALL_STAFFUSERS_SUCCESS, staffusers: data}
    }

    function get_staffusers_failure(error) {
        return {type: userTypes.GET_ALL_STAFFUSERS_FAILURE, error:error}
    }
}

function createStaffUserMap() {
    return async dispatch => {
        try {
            dispatch(get_staffusers_request());
            let data = await staffUserService.getAllStaffUsers();
            dispatch(get_staffusers_success(data.data));
            await create_staffuser_map(data.data);
        } catch(error) {
            dispatch(get_staffusers_failure(error));
        }
    }

    async function create_staffuser_map(staffusers) {
        let map = {}
        let staffuser;
        for (staffuser of staffusers) {
            map[staffuser['user_id']] = staffuser
        }

        let staffuser_data = {map, staffusers}
        store.dispatch({type: userTypes.CREATE_STAFFUSER_MAP, staffuser_data});
    }

    function get_staffusers_request() {
        return {type: userTypes.GET_ALL_STAFFUSERS_REQUEST}
    }

    function get_staffusers_success(data) {
        return {type: userTypes.GET_ALL_STAFFUSERS_SUCCESS, staffusers: data}
    }

    function get_staffusers_failure(error) {
        return {type: userTypes.GET_ALL_STAFFUSERS_FAILURE, error}
    }
}

function createStaffUserRoleMap() {
    return async dispatch => {

        try {
            dispatch(get_staff_user_roles_request());
            let staff_user_roles = await staffUserService.getStaffUserRoles();
            dispatch(get_staff_user_roles_success(staff_user_roles.data));
            dispatch(create_staff_user_roles_map(staff_user_roles));
        } catch (error) {
            dispatch(get_staff_user_roles_failure(error));
        }
    }

    function get_staff_user_roles_request() {
        return {type: userTypes.GET_STAFF_USER_ROLES_REQUEST}
    }

    function get_staff_user_roles_success(data) {
        return {type: userTypes.GET_STAFF_USER_ROLES_SUCCESS, data}
    }

    function create_staff_user_roles_map(staff_user_roles) {
        let map = {}
        let staff_user_role;
        for (staff_user_role of staff_user_roles) {
            map[staff_user_role['user_role_id']] = staff_user_role
        }
        return {type: userTypes.CREATE_STAFF_USER_ROLES_MAP, map}
    }

    function get_staff_user_roles_failure(error) {
        return {type: userTypes.GET_STAFF_USER_ROLES_FAILURE, error}
    }
}
