import React from "react";
import ManageNode from "../privilege/ManageNode.js";
import FormAttachment from "../privilege/ManageFormAttachment.js";
import ManageAdmin from "../admin/ManageAdmin.js";
import ManageStudent from "../student/Student.js";
import CGCManageStudent from "../student/CgcStudent.js";
import ManageParent from "../parent/Parent.js";
import EnrollmentForm from "../enrollment/ManageEnrollment.js";
import TeacherEnrollmentForm from "../enrollment/cgc/ManageTeacherEnrollment.js"
import ManageAttendance from "../attendance/ManageAttendance.js";
import EdFociPaperList from "../paper/edfoci/EdFociPaperList.js";
import EdSiderPaperList from "../paper/edsider/EdSiderPaperList.js";
import CMCPaperList from "../paper/cmc/CMCPaperList.js";
import PaperMapping from "../paper/PaperMapping.js";
import EnrolledPaperList from "../../candidate/EnrolledPaperList.js";
import OrganizationAttributes from "../attribute/ManageOrganizationAttribute.js";
import CalendarApp from "../calendar/ManageCalendar.js";
import MainManageMarkSheet from "../mark-sheet/MainManageMarkSheet.js";
import Announcement from "../announcement/ManageAnnouncement.js";
import FeedbackForm from "../feedback/ManageFeedback.js";
import QuizMapping from "../quiz/QuizMapping.js";
import ManageDocuments from "../lms/ManageDocument.js";
import ManageStudentDocument from "../lms/ManageStudentDocument.js";
import ManageTeacher from "../teacher/ManageTeacher.js";
import Payments from "../payment/ManagePayment.js";
import SamplePaymentForm from "../payment/ManageSamplePayment.js";
import EdSiderSubscriptionMain from "../subscription/edsider/ManageSubscription.js";
import CMCSubscriptionMain from "../subscription/cmc/ManageSubscription.js";

import ManageAbout from "../about/ManageAbout.js";
import config from "../../../helper/config.js";
import { customerConstants } from "../../../helper/constants.js";
import {
  IconAbout,
  IconAnnouncements,
  IconAssessments,
  IconAttendance,
  IconCalendar,
  IconChat,
  IconContactInfo,
  IconEnrollemnt,
  IconFeedback,
  IconManageForms,
  IconManageNodes,
  IconMarkSheet,
  IconMediaStore,
  IconPayments,
  IconProgressCatchup,
  IconQuizMapping,
  IconRegistration,
  IconSubscription,
  IconPaperMapping,
  IconEnrolledPapers
} from './FormIcons'
import ManageProgress from "../progress/ManageProgress.js";
import ManageChat from "../chat/ManageChat.js";
import { routes } from "../../../helper/routes.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

const customer = getCustomerFeatureFlag();
let CustomPaperList;
let SubscriptionMain;
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    CustomPaperList = EdSiderPaperList
    SubscriptionMain = EdSiderSubscriptionMain
    break;
  case customerConstants.EXO_CUSTOMER2:
    CustomPaperList = CMCPaperList
    SubscriptionMain = CMCSubscriptionMain
    break;
  default:
    CustomPaperList = EdFociPaperList
    SubscriptionMain = EdSiderSubscriptionMain
    break;
}

export const formFixture = (state = undefined) => [
  { formListId: 1, url: routes.manage_node, component: (props) => <ManageNode state={state} nodeProps={props} />, icon: <IconManageNodes /> },
  { formListId: 2, url: routes.form_attachment, component: (props) => <FormAttachment state={state} nodeProps={props} />, icon: <IconManageForms /> },
  { formListId: 3, url: routes.manage_admin, component: (props) => <ManageAdmin state={state} />, icon: <IconRegistration /> },
  { formListId: 4, url: routes.manage_student, component: (props) => <ManageStudent state={state} />, icon: <IconRegistration /> },
  { formListId: 5, url: routes.manage_parent, component: (props) => <ManageParent state={state} />, icon: <IconRegistration /> },
  { formListId: 6, url: routes.manage_enrollment, component: (props) => <EnrollmentForm state={state} />, icon: <IconEnrollemnt /> },
  { formListId: 7, url: routes.manage_attendance, component: (props) => <ManageAttendance state={state} />, icon: <IconAttendance /> },
  { formListId: 8, url: routes.paper_list, component: (props) => <CustomPaperList state={state} />, icon: <IconAssessments /> },
  { formListId: 9, url: routes.manage_search, component: (props) => undefined, icon: undefined },
  { formListId: 10, url: routes.paper_mapping, component: (props) => <PaperMapping state={state} />, icon: <IconPaperMapping /> },
  { formListId: 11, url: routes.enrolled_paper_list, component: (props) => <EnrolledPaperList state={state}  nodeProps={props}/>, icon: <IconEnrolledPapers /> },
  { formListId: 12, url: undefined, component: (props) => undefined, icon: undefined },
  { formListId: 13, url: routes.manage_organization_attribute, component: (props) => <OrganizationAttributes state={state} />, icon: <IconContactInfo /> },
  { formListId: 14, url: routes.calendar_app, component: (props) => <CalendarApp state={state} />, icon: <IconCalendar /> },
  { formListId: 15, url: routes.manage_about, component: (props) => <ManageAbout state={state} />, icon: <IconAbout /> },// About
  { formListId: 16, url: routes.payment_gateway, component: (props) => <SamplePaymentForm state={state} />, icon: <IconPayments /> },
  { formListId: 17, url: routes.manage_marksheet, component: (props) => <MainManageMarkSheet state={state} />, icon: <IconMarkSheet /> },
  { formListId: 18, url: undefined, component: (props) => undefined, icon: undefined },
  { formListId: 19, url: routes.enrolled_paper_list, component: (props) => <EnrolledPaperList state={state} nodeProps={props}/>, icon: <IconEnrolledPapers /> },
  { formListId: 20, url: undefined, component: (props) => undefined, icon: undefined },
  { formListId: 21, url: routes.manage_chat, component: (props) => <ManageChat state={state} />, icon: <IconChat /> }, // chat 
  { formListId: 22, url: routes.quiz_mapping, component: (props) => <QuizMapping state={state} />, icon: <IconQuizMapping /> },
  { formListId: 23, url: routes.manage_documents, component: (props) => <ManageDocuments state={state} />, icon: <IconMediaStore /> },
  { formListId: 24, url: routes.manage_teacher, component: (props) => <ManageTeacher state={state} />, icon: <IconRegistration /> },
  { formListId: 25, url: routes.manage_announcement, component: (props) => <Announcement state={state} />, icon: <IconAnnouncements /> },
  { formListId: 26, url: routes.manage_progress, component: (props) => <ManageProgress state={state} />, icon: <IconProgressCatchup /> }, // Progress
  { formListId: 27, url: routes.feedback_form, component: (props) => <FeedbackForm state={state} />, icon: <IconFeedback /> },
  { formListId: 28, url: routes.manage_payment, component: (props) => <Payments state={state} />, icon: <IconPayments /> },
  { formListId: 29, url: routes.manage_subscription_main, component: (props) => <SubscriptionMain state={state} />, icon: <IconSubscription /> },
  { formListId: 31, url: routes.manage_teacher_enrollment, component: (props) => <TeacherEnrollmentForm state={state} />, icon: <IconEnrollemnt /> },
  { formListId: 32, url: routes.manage_cgc_student, component: (props) => <CGCManageStudent state={state} />, icon: <IconRegistration /> },
  { formListId: 33, url: routes.manage_documents, component: (props) => <ManageStudentDocument state={state} />, icon: <IconMediaStore /> },
]
