import {assessAttemptTypes} from '../settingsTypes';

const initialState = {
    allAssessmentAttempts: [],
    loading: false,
    error: '',
    text: '',
    attempt:'',
    totalAttemptsCount: 0,
    allAttempts:[],
    errorattemptdelete:false,
    loadingattemptdelete:false,
    successattemptupdate: false,
    loadingattemptupdate: false,
    errorattemptupdate:false,
    successattemptdelete:false,
    errorattemptcreate: '',
    successattemptcreate: false,
}

export function attemptReducer(state = initialState, action){
    switch (action.type){
        case assessAttemptTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allAssessmentAttempts: [],
                loading: false,
                error: '',
                text: '',
                attempt:'',
                totalAttemptsCount:0,
                allAttempts:[],
                errorattemptdelete:false,
                loadingattemptdelete:false,
                successattemptupdate: false,
                loadingattemptupdate: false,
                errorattemptupdate:false,
                successattemptdelete:false,
                errorattemptcreate: '',
                successattemptcreate: false,
            }
        //Attempt retrieve request sending
        case assessAttemptTypes.GET_ALL_ASSESSMENT_ATTEMPTS:
            return {
                ...state,
                loading: true,
                text: 'Get all the assessment attempts'
            }
        case assessAttemptTypes.GET_ALL_ASSESSMENT_ATTEMPTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessAttemptTypes.GET_ALL_ASSESSMENT_ATTEMPTS_SUCCESS:
            return {
                ...state,
                loading: false,
                allAssessmentAttempts: action.attempts.attempts,
                totalAttemptsCount: action.attempts.total_count
            }
        case assessAttemptTypes.GET_ALL_ASSESSMENT_ATTEMPTS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Attempt creation request sending
        case assessAttemptTypes.CREATE_ATTEMPT_REQUEST:
            return {
                ...state,
                errorattemptcreate: '',
                successattemptcreate: false,
                loading: true
            }
        case assessAttemptTypes.CREATE_ATTEMPT_SUCCESS:
            var temp = state.allAttempts;
            temp.push(action.attempts)
            return {
                ...state,
                allAttempts: temp,
                attempt: action.attempts.attempt_no_id,
                successattemptcreate: true,
                loading: false
            }
        case assessAttemptTypes.CREATE_ATTEMPT_FAILURE:
            return {
                ...state,
                errorattemptcreate: action.error,
                loading: false
            }  
        //Attempt deletion request sending   
        case assessAttemptTypes.DELETE_ATTEMPT_REQUEST:
            return {
            ...state,
            errorattemptdelete: '',
            successattemptdelete: false,
            loading: true,
            };
        case assessAttemptTypes.DELETE_ATTEMPT_SUCCESS:
            return {
            ...state,
            successattemptdelete: true,
            loading: false,
            };
        case assessAttemptTypes.DELETE_ATTEMPT_FAILURE:
            return {
            ...state,
            errorattemptdelete: action.error,
            loading: false,
            };
        //Attempt updation request sending
        case assessAttemptTypes.UPDATE_ATTEMPT_REQUEST:
            return {
            ...state,
            errorattemptupdate:'',
            successattemptupdate: false,
            loading: true,
            };
        case assessAttemptTypes.UPDATE_ATTEMPT_SUCCESS:
            return {
            ...state,
            successattemptupdate: true,
            loading: false,
            };
        case assessAttemptTypes.UPDATE_ATTEMPT_FAILURE:
            return {
            ...state,
            errorattemptupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
