import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { StudentAddressTypeService } from "../../../service/settings/manage/student_address_type.service.js";
import { StudentAddressService } from "../../../service/manage/registration/student_address.service";
import { CountryService } from "../../../service/settings/manage/country.service.js";
import { ProvinceService } from "../../../service/settings/manage/province.service.js";
import { CityService } from "../../../service/settings/manage/city.service.js";
import { GenderService } from "../../../service/settings/manage/gender.service.js";
import { StatusTypesService } from "../../../service/settings/manage/status_type.service.js";
import { StatusService } from "../../../service/settings/manage/status.service.js";
import Spinner from "react-bootstrap/Spinner";
import "../../../components/admin/css/common.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import { history } from "../../../helper/history";

import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination as Pagination,
} from "@mui/material";
import debounce from "lodash.debounce";
import sweetAlert, {
  sweetConfirmAlert,
  sweetStatusAlert,
} from "../../../components/common/SweetAlert";
import { StudentService } from "../../../service/manage/registration/student.service";
import { UserManagementService } from "../../../service/user_management/user_management.service";
import SimpleReactValidator from "simple-react-validator";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import Tooltip from "@mui/material/Tooltip";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChatService } from "../../../service/manage/chat/chat.service";
import { GradeService } from "../../../service/settings/manage/grade.service.js";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";
import DatePicker from "react-datepicker";
import { RequestService } from "../../../service/manage/registration/request_form.service.js";
import { assessmentService } from "../../../service/assessment/assessmentService.js";
import { toInteger } from "lodash";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

const customer = getCustomerFeatureFlag();
let isEdfoci, addBtnStyle, closeBtnStyle;
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    isEdfoci = false;
    break;
  case customerConstants.EXO_CUSTOMER2:
    isEdfoci = false;
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    isEdfoci = true;
    break;
}

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    '& .MuiSpeedDial-root': {
      marginLeft: '0% !important',
    },
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
    '& .MuiSpeedDial-root': {
      marginLeft: '0% !important',
    },
  },
}));

export default class AddStudentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      students: [],
      first_name: "",
      last_name: "",
      nic: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      genders: [],
      titles: [],
      countries: [],
      stu_statuses: [],
      statusTypes: [],
      StuStatustype: "Availability",
      showInsertModal: false,
      studentAddressTypes: [],
      studentAddresses: [],
      isLoading: false,
      isTableDataLoading: true,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      totalStudents: 10,
      currentPage: 0,
      isModified: false,
      isAddressesLoading: false,
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      sortColumn: "user_code",
      sortDirection: "desc",
      errorProvince: [],
      errorCity: [],
      addressValidity: [],
      hidden: false,
      studentAddressesAdd: [],
      node_mid: null,
      user_list_table_id: null,
      session_stu_id: null,
      grade: null,
      school: "",
      main_table_id: null,
      breadcrumb2: null,
      nodeTitle: null,
      user_code: "",
      grades: [],
      grades_with_limit: [],
      emailExistenceRes: "",
      mediums:[],
      medium:null,
    };

    //Initiating the ref object for each fields on the forms of both modals
    this.firsNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.phoneNumberRef = React.createRef();
    this.dobRef = React.createRef();
    this.dobWrapRef = React.createRef();
    this.gradeRef = React.createRef();
    this.schoolRef = React.createRef();
    this.genderIdRef = React.createRef();
    this.createdByRef = React.createRef();
    this.statusIdRef = React.createRef();
    this.addressesRefs = [];
    this.handleValidityFocus = this.handleValidityFocus.bind(this)

    // Debounced version of handleSearch function

    this.createValidationsInstance();
    this.formInitialState = {
      first_name: "",
      last_name: "",
      nic: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      postalcode: "",
    };
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        address: {
          message: "The :attribute must be a valid address",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/:0-9,\s]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
        },
        dateBefore: {
          message: "The :attribute must be before current date",
          rule: (val, params, validator) => {
            const selectedDate = new Date(val)
            const currentDate = new Date()
            return selectedDate < currentDate;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        phone_number: {
          message: "The :attribute must be a valid phone number",
          rule: (val, params, validator) => {
            const isTenDigitsStartingWithZero =
              val.length === 10 && val.startsWith("0");
            const isNineDigits = val.length === 9 && !val.startsWith("0");
            return isTenDigitsStartingWithZero || isNineDigits;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
      },
    });
    this.updateValidator = new SimpleReactValidator();
  }

  async getAllStudentAddressTypes() {
    this.setState({ isAddressesLoading: true });
    this.addressesRefs = [];
    const res = await StudentAddressTypeService.getAllWithoutPagination();
    const types = res.data;
    const addressValidity = [];
    types.forEach((type) => {
      addressValidity.push(false);
      this.addressesRefs.push({
        country_id: React.createRef(),
        province_id: React.createRef(),
        city_id: React.createRef(),
        address_1: React.createRef(),
        address_2: React.createRef(),
        postalcode: React.createRef(),
      });
    });

    const studentAddresses = this.state.studentAddresses;
    this.setState(
      (prevState) => ({
        studentAddressTypes: types,
        addressValidity: addressValidity,
        studentAddresses: types.map((type, index) => {
            let obj = {
              ...type,
              ...studentAddresses[index],
              visibility: true,
            }
            delete obj['display_order']
            return obj
        })
      }),
      () => {
        const { studentAddresses } = this.state;

        const studentAddressVisibility = studentAddresses.map((item, index) => {
          if (index > 0) {
            item.visibility = false;
          }
          return item;
        });
        this.setState({ studentAddressesAdd: studentAddressVisibility });

        const studentAddressUpdateVisibility = studentAddresses.map(
          (item, index) => {
            if (index > 0) {
              if (
                !item.country_id ||
                item.country_id === null ||
                item.country_id === undefined
              ) {
                item.visibility = false;
              } else {
                item.visibility = true;
              }
            }
            return item;
          }
        );
        this.setState({ studentAddresses: studentAddressUpdateVisibility });
      }
    );

    this.setState({ isAddressesLoading: false }); // Set isLoading to false after data is fetched
  }

  componentDidMount() {
    this.loadInitialData();
    this.genarateStudentIdPrefix();
  }

  genarateStudentIdPrefix() {
    let id_prefix;
    if (this.props.state && this.props.state.mcode) {
      id_prefix = this.props.state.mcode;
    } else {
      id_prefix = "ED";
    }
    const id_prefix_year = String(new Date().getFullYear() % 100);

    const user_base_prefix = id_prefix + id_prefix_year + "S";

    this.setState({ user_code: user_base_prefix });
  }

  loadInitialData() {
    this.setState(
      {
        user_list_table_id: sessionStorage.getItem("table_log_id"),
        session_stu_id: sessionStorage.getItem("manage_user_id"),
      },
      () => {
        this.getAllGenders();
        this.getAllStatusTypes();
        this.getAllContries();
        this.getAllStudentAddressTypes();
        this.getAllGrades();
        this.getAllGradesWithLimit();
        this.getMedium()
        this.getAllStudentAddressTypes();
      }
    );
  }

  getAllContries() {
    CountryService.getAll().then((res) => {
      this.setState({ countries: res.data });
    });
  }

  getAllGenders() {
    GenderService.getAllGenders().then((res) => {
      this.setState({ genders: res.data });
    });
  }

  getAllStatusTypes() {
    StatusTypesService.getAll().then((res) => {
      this.setState({ statusTypes: res.data }, () => {
        this.getAllStatuses();
      });
    });
  }

  getAllStatuses() {
    const stu_type_id = this.getRespectiveStatusTypeID(
      this.state.StuStatustype
    );
    StatusService.getByTypeId(stu_type_id).then((res) => {
      this.setState({ stu_statuses: res.data });
    });
  }

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(this.formInitialState);
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
        this.toggleAddStudentModal();
        toggleFunction();
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      this.setState(this.formInitialState);
      this.toggleAddStudentModal();
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };
  
  handleDatePickerChange = (value) => {
    const dateObject = value ? new Date(value): undefined;
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = ("0" + dateObject.getDate()).slice(-2);

    const formattedDateString = `${year}-${month}-${day}`;
    this.setState(() => ({ dob: formattedDateString, isModified: true}));
  };

  handleChangeAddress = async (index, event) => {
    const { name, value } = event.target;
    const { studentAddresses } = this.state;

    const updatedAddresses = [...studentAddresses];
    if (name.includes("country_id")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        country_id: value,
      };
    }
    if (name.includes("province_id")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        province_id: value,
      };
    }
    if (name.includes("city_id")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        city_id: value,
      };
    }
    if (name.includes("address_1")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        address_1: value,
      };
    }
    if (name.includes("address_2")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        address_2: value,
      };
    }
    if (name.includes("postalcode")) {
      updatedAddresses[index] = {
        ...updatedAddresses[index],
        postalcode: value,
      };
    }
    this.setState({ studentAddresses: updatedAddresses });

    if (name.includes("country_id")) {
      const country_id = value;
      updatedAddresses[index].provinces = [];
      updatedAddresses[index].cities = [];
      const provinces = await this.getProvincesForCountry(country_id);
      if (provinces) {
        updatedAddresses[index].provinces = provinces;
      } else {
        updatedAddresses[index].province_id = null;
        updatedAddresses[index].city_id = null;
      }
    }

    if (name.includes("province_id")) {
      const province_id = value;
      updatedAddresses[index].cities = [];
      const cities = await this.getCitiesForProvince(province_id);
      if (cities) {
        updatedAddresses[index].cities = cities;
      } else {
        updatedAddresses[index].city_id = null;
      }
    }

    this.setState({ studentAddresses: updatedAddresses }, () => {
      this.handleAddressFeildValidity();
    });
  };

  getProvincesForCountry = async (country_id, is_initial = false) => {
    if (country_id && country_id !== "Select") {
      const response = await ProvinceService.getProvinceForCountry(country_id);
      if (response.success) {
        return response.data;
      } else {
        if (
          Object.keys(response.data).length === 0 &&
          response.success === false &&
          response.message === "Provinces/District not found" &&
          is_initial === true
        ) {
          return undefined;
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      }
    } else {
      return null;
    }
  };

  getCitiesForProvince = async (province_id, is_initial = false) => {
    if (province_id && province_id !== "Select") {
      const response = await CityService.getCitiesForProvince(province_id);
      if (response.success) {
        return response.data;
      } else {
        if (
          Object.keys(response.data).length === 0 &&
          response.success === false &&
          response.message === "Cities not found" &&
          is_initial === true
        ) {
          return undefined;
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      }
    } else {
      return null;
    }
  };

  handleUpdatedAddress = async (index, name, value) => {
    const { studentAddresses } = this.state;
    const updatedAddresses = [...studentAddresses];

    if (name === "country_id") {
      const country_id = value;
      if (updatedAddresses[index]) {
        updatedAddresses[index].provinces = [];
        updatedAddresses[index].cities = [];
        try {
          const provinces = await this.getProvincesForCountry(country_id, true);
          if (provinces) {
            updatedAddresses[index].provinces = provinces;
          } else {
            updatedAddresses[index].province_id = null;
            updatedAddresses[index].city_id = null;
          }
        } catch (error) {
          this.setApiErrorArray(country_id, index, "province");
        }
        this.setState({ studentAddresses: updatedAddresses });
      }
    }
    if (name === "province_id") {
      const province_id = value;
      if (updatedAddresses[index]) {
        updatedAddresses[index].cities = [];
        try {
          const cities = await this.getCitiesForProvince(province_id, true);
          if (cities) {
            updatedAddresses[index].cities = cities;
          } else {
            updatedAddresses[index].city_id = null;
          }
        } catch (error) {
          this.setApiErrorArray(province_id, index, "city");
        }
        this.setState({ studentAddresses: updatedAddresses });
      }
    }
  };

  reFetchErrorApi = async () => {
    let countryPromises, provincePromise;
    if (this.state.errorProvince.length > 0) {
      countryPromises = this.state.errorProvince.map(
        async ({ country_id, index }) => {
          return await this.handleUpdatedAddress(
            index,
            "country_id",
            country_id
          );
        }
      );
    }
    if (this.state.errorCity.length > 0) {
      provincePromise = this.state.errorCity.map(
        async ({ province_id, index }) => {
          return await this.handleUpdatedAddress(
            index,
            "province_id",
            province_id
          );
        }
      );
    }
    await Promise.all([countryPromises, provincePromise]);
  };

  setApiErrorArray = (id, index, type) => {
    if (type === "province") {
      if (
        !this.state.errorProvince.includes({ country_id: id, index: index })
      ) {
        this.setState((prev) => ({
          errorProvince: [
            ...prev.errorProvince,
            { country_id: id, index: index },
          ],
        }));
      }
    } else if (type === "city") {
      if (!this.state.errorCity.includes({ province_id: id, index: index })) {
        this.setState((prev) => ({
          errorCity: [...prev.errorCity, { province_id: id, index: index }],
        }));
      }
    }
  };

  toggleAddStudentModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        first_name: "",
        last_name: "",
        nic: "",
        dob: "",
        email: "",
        phone_number: "",
        gender_id: "",
        status_id: "",
        created_on: "",
        created_by: "",
        studentAddresses: [],
        grade: null,
        school: "",
      }),
      () => {
        // State has changed, perform your logic here based on the state change
        if (this.state.showInsertModal) {
          this.getAllGrades();
          this.getAllGradesWithLimit();
          this.getMedium()
          this.getAllStudentAddressTypes();
        }
      }
    );
  };

  checkEmailExistence = async() => {
    const {email} = this.state;
    await RequestService.checkEmail(email, "student")
        .then((response)=>{
          this.setState({emailExistenceRes: response.data});
        }
      )
  }

  handleValidityFocus = (submission_type) => {
    if (!this.validator.fieldValid("first_name")) {
      this.firsNameRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("last_name")) {
      this.lastNameRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("email")) {
      this.emailRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("phone_number")) {
      this.phoneNumberRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("dob")) {
      this.dobRef.current.setFocus();
      this.dobWrapRef.current.scrollIntoView();
      return;
    } else if (!this.validator.fieldValid("gender_id")) {
      this.genderIdRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("grade") && !isEdfoci) {
      this.gradeRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("school") && !isEdfoci) {
      this.schoolRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("created_by")) {
      this.createdByRef.current.focus();
      return;
    } else if (!this.validator.fieldValid("status_id")) {
      this.statusIdRef.current.focus();
      return;
    }

    for (const [index, address] of this.addressesRefs.entries()) {
      if (
        !this.validator.fieldValid(`${submission_type}_country_id${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.country_id.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_province_id${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.province_id.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_city_id${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.city_id.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_address_1${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.address_1.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_address_2${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.address_2.current.focus();
        break;
      } else if (
        !this.validator.fieldValid(`${submission_type}_postalcode${index}`) &&
        this.state.addressValidity[index]
      ) {
        address.postalcode.current.focus();
        break;
      }
    }
  };

  handleAddressFeildValidity = () => {
    this.state.studentAddresses.forEach((address, index) => {
      if (
        address.country_id ||
        address.province_id ||
        address.city_id ||
        address.address_1 ||
        address.address_2 ||
        address.postalcode
      ) {
        this.setState((prev) => {
          const updatedAddressValidity = [...prev.addressValidity];
          updatedAddressValidity[index] = true;
          return {
            addressValidity: updatedAddressValidity,
          };
        });
      } else {
        this.setState((prev) => {
          const updatedAddressValidity = [...prev.addressValidity];
          updatedAddressValidity[index] = false;
          return {
            addressValidity: updatedAddressValidity,
          };
        });
      }
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.handleAddressFeildValidity();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.handleValidityFocus("add");
      this.forceUpdate();
    } 
    else {
      await this.checkEmailExistence();
      const {emailExistenceRes} = this.state;
      if(emailExistenceRes.code === 1){
        sweetStatusAlert(`${emailExistenceRes.title && typeof emailExistenceRes.title === "string" ? emailExistenceRes.title:"Email exist"}`,`${typeof emailExistenceRes.message === "string" ? emailExistenceRes.message:"Email already exist in system"}`,"info");
      }
      else{
        this.setState({ isLoading: true });
        try {
          const {
            first_name,
            last_name,
            nic,
            email,
            phone_number,
            dob,
            grade,
            medium,
            school,
            gender_id,
            status_id,
            studentAddresses,
            search_term,
            session_stu_id,
            user_list_table_id,
            main_table_id,
            user_code,
          } = this.state;

          const newStudent = {
            first_name: first_name,
            last_name: last_name,
            nic: nic,
            email: email,
            phone_number: parseInt(phone_number),
            dob: dob,
            gender_id: parseInt(gender_id),
            user_status_id: parseInt(status_id),
            node_mid:
              !!this.props.state && !!this.props.state.mid
                ? this.props.state.mid
                : 100,
            create_user_id: session_stu_id,
            create_user_table_id: user_list_table_id,
            user_table_id:
              !!this.props.state && this.props.state.main_table_id
                ? this.props.state.main_table_id
                : 2,
            grade: grade,
            medium:medium,
            school: school,
            user_code: user_code,
          };
          const studentResponse = await StudentService.create(
            JSON.stringify(newStudent)
          );
          if (studentResponse.success) {
            const studentId = studentResponse.data.stu_id;
            const studentEmail = studentResponse.data.email;
            const filteredAddressesWithStudentId = studentAddresses.map(
              (address) => {
                const {
                  name,
                  description,
                  cities,
                  provinces,
                  country_id,
                  province_id,
                  city_id,
                  visibility,
                  ...rest
                } = address; // Destructure the name and description fields from the address object
                return {
                  ...rest, // Spread the rest of the address fields
                  student_id: studentId, // Add the student_id field
                  country_id: parseInt(country_id), // Convert the country_id to an integer
                  province_id: parseInt(province_id),
                  city_id: parseInt(city_id),
                  status: "active",
                };
              }
            );

            if (filteredAddressesWithStudentId.length > 0) {
              await StudentAddressService.create(
                JSON.stringify(filteredAddressesWithStudentId)
              );
            }

            this.setState({ isLoading: false, showInsertModal: false });

            sweetStatusAlert(
              "Success!",
              "New student added successfully!",
              "success"
            );
            this.toggleAddStudentModal();
            this.props.toggleAddStudentModal(studentResponse.success);
            this.validator.purgeFields();
            this.validator.hideMessages();
            this.setState(this.formInitialState);
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Failed!", studentResponse.message, "error");
          }
        } catch (e) {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", "Failed to create new student", "error");
        }
      }
    }
  }
  
  getRespectiveStatusTypeID(name) {
    const type = this.state.statusTypes.find((item) => item.name === name);
    return type ? type.type_id : "";
  }

  clearAddress = (index) => {
    const { studentAddresses } = this.state;
    const keysToClear = [
      "country_id",
      "province_id",
      "city_id",
      "address_1",
      "address_2",
      "postalcode",
    ];

    if (index >= 0 && index < studentAddresses.length) {
      // Clear the values in the specified keys for the object at indexToClear
      keysToClear.forEach((key) => {
        studentAddresses[index][key] = null; // You can set it to null or any other desired value
      });
      this.setState({ studentAddresses: studentAddresses });
    }
  };

  toggleAddress = (id) => {
    const { studentAddressesAdd } = this.state;
    this.setState({ hidden: true });
    const updateStuAddress = studentAddressesAdd.map((item, index) => {
      if (index === id) {
        if (item.visibility) {
          sweetConfirmAlert().then(() => {
            item.visibility = !item.visibility;
            this.clearAddress(id);
            this.setState({ hidden: false });
          });
        } else {
          item.visibility = !item.visibility;
        }
      }
      if (!item.visibility) {
        this.setState({ hidden: false });
      }
      return item;
    });
    this.setState({ studentAddressesAdd: updateStuAddress });
  };

  toggleUpdateAddress = (id) => {
    const { studentAddresses } = this.state;
    this.setState({ hidden: true });
    const updateStuAddress = studentAddresses.map((item, index) => {
      if (index === id) {
        if (item.visibility) {
          sweetConfirmAlert().then(() => {
            item.visibility = !item.visibility;
            this.clearAddress(id);
            this.setState({ hidden: false });
          });
        } else {
          item.visibility = !item.visibility;
        }
      }
      if (!item.visibility) {
        this.setState({ hidden: false });
      }
      return item;
    });
    this.setState({ studentAddresses: updateStuAddress });
  };

  getAllGrades = () => {
    GradeService.getGrades().then((response) => {
      if (response.success) {
        this.setState({ grades: response.data });
      }
    });
  };

  getAllGradesWithLimit = async() => {
    await GradeService.getGradesWithLimit().then((response) => {
      if (response.success) {
        this.setState({ grades_with_limit: response.data });
      }
    });
  };

  getMedium=()=>{
    assessmentService.getAssessmentLanguage()
        .then((response)=>{
            if(response.success){
                this.setState({mediums:response.data.languages})
            }
            
        })
  }

  render() {
    const {
      studentAddresses,
      isLoading,
      countries,
      limit,
      students,
      totalStudents,
      isTableDataLoading,
      currentPage,
      first_name,
      last_name,
      email,
      nic,
      phone_number,
      dob,
      grade,
      school,
      gender_id,
      created_on,
      created_by,
      status_id,
      genders,
      stu_statuses,
      isAddressesLoading,
      editVisibility,
      addVisibility,
      deleteVisibility,
      hidden,
      studentAddressesAdd,
      breadcrumb2,
      nodeTitle,
      user_code,
      grades,
      grades_with_limit,
      mediums,
      medium,
    } = this.state;
    const { isAddStudentModalOpen, toggleAddStudentModal } = this.props;
    this.validator.purgeFields();
    let toggleUpdateClassName = `btn btn-icon btn-primary btn-sm m-1 edit-assement-btn ${!!editVisibility ? "flaticon-edit-icon" : "flaticon-eye-icon"
      }`;

    return (
      <>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={isAddStudentModalOpen}
          onHide={() =>
            this.handleSweetAlert(toggleAddStudentModal, "showInsertModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Student</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              {isLoading ? (
                <div
                  className="stu-card-title pt-2 col-md-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <i className="fas fa-spinner fa-spin"></i>
                </div>
              ) : (
                <Form>
                  <Row>
                    <Modal.Title className="pl-4 mb-4">
                      Personal Details
                    </Modal.Title>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        defaultValue={first_name}
                        placeholder="First Name"
                        onChange={this.handleChange}
                        ref={this.firsNameRef}
                      />
                      {this.validator.message(
                        "first_name",
                        first_name,
                        "required|alpha_space|min:2|max:128",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The student first name is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        defaultValue={last_name}
                        placeholder="Last Name"
                        onChange={this.handleChange}
                        ref={this.lastNameRef}
                      />
                      {this.validator.message(
                        "last_name",
                        last_name,
                        "required|alpha_space|min:2|max:128",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The student last name is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="my-5" ref={this.dobWrapRef}>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>NIC</Form.Label>
                      <Form.Control
                        type="text"
                        name="nic"
                        value={nic}
                        placeholder="NIC"
                        onChange={this.handleChange}
                      />
                      {/* {this.validator.message(
                          "nic",
                          nic,
                          "required|min:8",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The student nic is required.",
                            },
                          }
                        )} */}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>DOB*</Form.Label>
                      <div className="col-md-12 px-0">
                        <DatePicker
                          className="py-2 form-control w-100 col-md-12" // Add Bootstrap form control class
                          selected={this.state.dob ? new Date(this.state.dob):undefined}
                          name="dob"
                          onChange={this.handleDatePickerChange}
                          dateFormat="dd/MM/yyyy" // Set custom date format
                          placeholderText="Date of Birth"
                          ref={this.dobRef}
                          wrapperClassName="react-datePicker"
                        />
                      </div>
                      {this.validator.message(
                        "dob",
                        dob,
                        "required|dateBefore",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The date of birth is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Email*</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        defaultValue={email}
                        placeholder="example@gmail.com"
                        onChange={this.handleChange}
                        ref={this.emailRef}
                      />
                      {this.validator.message(
                        "email",
                        email,
                        "required|email",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The email is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Gender*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="gender_id"
                        value={gender_id}
                        onChange={this.handleChange}
                        ref={this.genderIdRef}
                        defaultValue={""}
                      >
                        <option hidden value="">Select</option>
                        {genders?.map((gender) => (
                          <option
                            key={gender.gender_id}
                            value={gender.gender_id}
                          >
                            {gender.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "gender_id",
                        gender_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The gender is required.",
                          },
                        }
                      )}
                    </Form.Group>

                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Phone Number*</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_number"
                        defaultValue={phone_number}
                        placeholder="Phone Number"
                        onChange={this.handleChange}
                        ref={this.phoneNumberRef}
                      />
                      {this.validator.message(
                        "phone_number",
                        phone_number,
                        "required|numeric|between:9,10|phone_number",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The phone number is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Status*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="status_id"
                        value={status_id}
                        onChange={this.handleChange}
                        ref={this.statusIdRef}
                        defaultValue={""}
                      >
                        <option hidden value="">Select</option>
                        {stu_statuses?.map((status) => (
                          <option
                            key={status.status_id}
                            value={status.status_id}
                          >
                            {status.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "status_id",
                        status_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The status is required.",
                          },
                        }
                      )}
                    </Form.Group>

                  </Row>
                  {!isEdfoci && (
                    <>
                      <Row className="my-5">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label>Grade*</Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="grade"
                            value={this.state.grade}
                            onChange={this.handleChange}
                          >
                            {/* <option value="">Select</option>
                            {grades.length > 0 ? (
                              grades.map((grade) => (
                                <option
                                  key={grade.grade_id}
                                  value={grade.grade}
                                >
                                  {grade.grade}
                                </option>
                              ))
                            ) : (
                              <option value="">No grades available</option>
                            )} */}
                            <option value="">Select</option>
                            {grades_with_limit.length>0 ? (grades_with_limit.map((grade)=>(
                              <option key={grade.grade_id} value={grade.grade_id} disabled={grade.limit_exceeded} style={{color:grade.limit_exceeded?'red':'black'}}>
                                {grade.limit_exceeded ? `${grade.grade} (Registration Limit exceeded)` : grade.grade}
                              </option>
                            ))):(
                              <option value="">No grades available</option>
                            )}
                          </Form.Select>
                          {this.validator.message("grade", grade, "required", {
                            className: "text-danger",
                            messages: {
                              required: "The grade is required.",
                            },
                          })}
                        </Form.Group>

                        <Form.Group 
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                        >
                        <Form.Label>Student’s Medium:</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            name="medium"
                            value={toInteger(this.state.medium)}
                            onChange={this.handleChange}
                          >
                            <option value="">Select</option>
                            {mediums.length>0?(mediums.map((medium)=>(
                              <option key={medium.language_id} value={medium.language_id}>
                                {medium.language}
                              </option>
                            ))):(
                              <option value="">No mediums available</option>
                            )}
                          </Form.Select>
                          <span className="form-error-message">
                          {this.validator.message(
                            "medium",
                            this.state.medium,
                            "required"
                          )}
                          </span>
                        </Form.Group>


                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>School*</Form.Label>
                          <Form.Control
                            className="py-2"
                            type="text"
                            name="school"
                            defaultValue={school}
                            placeholder="School"
                            onChange={this.handleChange}
                          />
                          {this.validator.message("school", school, ["required", "max:60"], {
                            className: "text-danger",
                            messages: {
                              required: "The school is required.",
                              max: "The school must not exceed 60 characters."
                            },
                          })}
                        </Form.Group>
                      </Row>
                    </>
                  )}

                  <Row className="my-5">


                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Student ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="user_code"
                        value={user_code ? user_code : ""}
                        placeholder="Student ID"
                        disabled
                      />
                      {this.validator.message(
                        "user_code",
                        user_code,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The student ID is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>


                  <Row className="pt-5">
                    <Modal.Title className="pl-4 mb-4">
                      Residential Details
                    </Modal.Title>
                  </Row>
                  {isAddressesLoading ? (
                    <div className="loading-container">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <div>
                      {studentAddressesAdd.length > 0 &&
                        studentAddressesAdd.map((type, index) => {
                          const add_country_id = `add_country_id${index}`;
                          const add_province_id = `add_province_id${index}`;
                          const add_city_id = `add_city_id${index}`;
                          const add_address_1 = `add_address_1${index}`;
                          const add_address_2 = `add_address_2${index}`;
                          const add_postalcode = `add_postalcode${index}`;

                          return type.visibility ? (
                            <div key={index}>
                              <Row>
                                <Col md="11">
                                  <Modal.Title className=" mt-4 h6">
                                    {type.name}
                                  </Modal.Title>
                                  {/* <div className="mt-5">
                                        &nbsp; (please fill all the fields)
                                    </div> */}
                                </Col>
                                <Col md="1">
                                  {index > 0 && (
                                    <Tooltip title={"Remove " + type.name}>
                                      <IconButton
                                        onClick={() =>
                                          this.toggleAddress(index)
                                        }
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Col>
                              </Row>
                              <Row className="my-5">
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Address Line 1</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name={add_address_1}
                                    placeholder="Address 1"
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.address_1 || ""
                                    }
                                    ref={this.addressesRefs[index].address_1}
                                  />
                                  {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_address_1,
                                      studentAddresses[index]?.address_1 || "",
                                      "address|required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required:
                                            "The Address 1 is required.",
                                          address:
                                            "The address 1 must be a valid address",
                                        },
                                      }
                                    )} */}
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput2"
                                >
                                  <Form.Label>Address Line 2</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name={add_address_2}
                                    placeholder="Address 2"
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.address_2 || ""
                                    }
                                    ref={this.addressesRefs[index].address_2}
                                  />
                                  {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_address_2,
                                      studentAddresses[index]?.address_2 || "",
                                      "address|required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required:
                                            "The Address 2 is required.",
                                          address:
                                            "The address 2 must be a valid address",
                                        },
                                      }
                                    )} */}
                                </Form.Group>
                              </Row>
                              <Row className="my-5">
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput2"
                                >
                                  <Form.Label>Country</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name={add_country_id}
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.country_id || ""
                                    }
                                    ref={this.addressesRefs[index].country_id}
                                  >
                                    <option value="">Select</option>
                                    {countries?.map((country) => (
                                      <option
                                        value={country.country_id}
                                        key={country.country_id}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_country_id,
                                      studentAddresses[index]?.country_id || "",
                                      "required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required: "The Country is required.",
                                        },
                                      }
                                    )} */}
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput3"
                                >
                                  <Form.Label>Province/District</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name={add_province_id}
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.province_id || ""
                                    }
                                    ref={this.addressesRefs[index].province_id}
                                  >
                                    <option value="">Select</option>
                                    {studentAddresses[index]?.provinces?.map(
                                      (province) => (
                                        <option
                                          value={province.province_id}
                                          key={province.province_id}
                                        >
                                          {province.name}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                  {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_province_id,
                                      studentAddresses[index]?.province_id ||
                                        "",
                                      "required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required: "The Province is required.",
                                        },
                                      }
                                    )} */}
                                </Form.Group>
                              </Row>
                              <Row className="my-5">
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput3"
                                >
                                  <Form.Label>City</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name={add_city_id}
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.city_id || ""
                                    }
                                    ref={this.addressesRefs[index].city_id}
                                  >
                                    <option value="">Select</option>
                                    {studentAddresses[index]?.cities?.map(
                                      (city) => (
                                        <option
                                          value={city.city_id}
                                          key={city.city_id}
                                        >
                                          {city.name}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                  {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_city_id,
                                      studentAddresses[index]?.city_id || "",
                                      "required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required: "The City is required.",
                                        },
                                      }
                                    )} */}
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="exampleForm.ControlInput3"
                                >
                                  <Form.Label>Postal Code</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="postalcode"
                                    placeholder="Postal Code"
                                    onChange={(event) =>
                                      this.handleChangeAddress(index, event)
                                    }
                                    value={
                                      studentAddresses[index]?.postalcode || ""
                                    }
                                    ref={this.addressesRefs[index].postalcode}
                                  />
                                  {/* {this.state.addressValidity[index] &&
                                    this.state.showInsertModal &&
                                    this.validator.message(
                                      add_postalcode,
                                      studentAddresses[index]?.postalcode || "",
                                      "numeric|required",
                                      {
                                        className: "text-danger",
                                        messages: {
                                          required:
                                            "The postal code is required.",
                                          numeric:
                                            "The postal code must be a number",
                                        },
                                      }
                                    )} */}
                                </Form.Group>
                              </Row>
                            </div>
                          ) : (
                            <></>
                          );
                        })}

                      {studentAddressesAdd.length > 0 && (
                        <Box
                          sx={{
                            position: "relative",
                            mt: 3,
                            height: 320,
                            ml: 10,
                          }}
                          style={{ margin: '0px' }}
                        >
                          <StyledSpeedDial
                            ariaLabel="SpeedDial playground example"
                            hidden={hidden}
                            icon={<SpeedDialIcon />}
                            direction={"down"}
                            FabProps={{
                              sx: {
                                bgcolor: '#5458AF',
                                '&:hover': {
                                  bgcolor: '#5458AF',
                                }
                              }
                            }}
                          >
                            {studentAddressesAdd?.map(
                              (action, index) =>
                                !action.visibility && (
                                  <SpeedDialAction
                                    key={action.student_address_type_id}
                                    icon={<AddCircleIcon />}
                                    tooltipTitle={<div style={{ whiteSpace: 'nowrap' }}>{action.name}</div>}
                                    onClick={() => this.toggleAddress(index)}
                                    tooltipOpen
                                    tooltipPlacement="right"
                                  />
                                )
                            )}
                          </StyledSpeedDial>

                        </Box>
                      )}
                    </div>
                  )}
                  {/* <Row>
                   <Modal.Title className="pl-4 mt-4 h6">
                   Shipping Address
                   </Modal.Title>
                   <Button
                style={{ marginLeft: '10px', backgroundColor: '#EDEEF7', padding: '1px', borderRadius: '10%', height:'20px', marginTop: '15px',border: 'none' }}
                onClick={() => {
                }}
              >
                <AddIcon style={{ color: '#5458AF' }} />
              </Button>
                   </Row>

                   <Row>
                   <div>
                   <FormControlLabel
        control={
          <Checkbox
            name="useSameAddress"
            color="primary"
            style={{ marginLeft: '13px'}}
          />
        }
        label={
          <Typography variant="body1">
            Use same as permanent address.
            </Typography>
        }
      />
    </div>
                   </Row>


                   <Row>
                   <Modal.Title className="pl-4 mt-4 h6">
                   Billing Address
                   </Modal.Title>
                   <Button
                style={{ marginLeft: '10px', backgroundColor: '#EDEEF7', padding: '1px', borderRadius: '10%', height:'20px', marginTop: '15px', border: 'none'}}
                onClick={() => {
                }}
              >
                <AddIcon style={{ color: '#5458AF' }} />
              </Button>
                  
                   </Row> 


                   <Row>
                   <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="useSameAddress"
                          color="primary"
                          style={{ marginLeft: '13px'}}
                        />
                      }
                      label={
                        <Typography variant="body1">
                          Use same as permanent address.
                          
                        </Typography>
                      }
                    />
                  </div>
                   </Row>*/}

                  <Modal.Footer>
                    <Button
                      className="mb-4"
                      variant="primary"
                      style={addBtnStyle}
                      onClick={this.handleSubmit}
                    >
                      Save changes
                    </Button>
                    <Button
                      className="mb-4"
                      id="modal-close-button"
                      style={closeBtnStyle}
                      onClick={() =>
                        this.handleSweetAlert(
                          toggleAddStudentModal,
                          "showInsertModal"
                        )
                      }
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Modal.Body>
          </div>
        </Modal>
      </>
    );
  }
}
