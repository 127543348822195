import {quesCatTypes} from '../../settings/settingsTypes';

const initialState = {
    allQuestionCategories: [],
    loading: false,
    error: '',
    text: '',
    category:'',
    totalCategoriesCount: 0,
    allCategories:[],
    errorcategorydelete:false,
    loadingcategorydelete:false,
    successcategoryupdate: false,
    loadingcategoryupdate: false,
    errorcategoryupdate:false,
    successcategorydelete:false,
    errorcategorycreate: '',
    successcategorycreate: false,
}

export function quesCatReducer(state = initialState, action){
    switch (action.type){
        case quesCatTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allQuestionCategories: [],
                loading: false,
                error: '',
                text: '',
                category:'',
                totalCategoriesCount:0,
                allCategories:[],
                errorcategorydelete:false,
                loadingcategorydelete:false,
                successcategoryupdate: false,
                loadingcategoryupdate: false,
                errorcategoryupdate:false,
                successcategorydelete:false,
                errorcategorycreate: '',
                successcategorycreate: false,
            }
        //Category retrieve request sending
        case quesCatTypes.GET_ALL_QUESTION_CATEGORIES:
            return {
                ...state,
                loading: true,
                text: 'Get all the question categories'
            }
        case quesCatTypes.GET_ALL_QUESTION_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case quesCatTypes.GET_ALL_QUESTION_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionCategories: action.categories.categories,
                totalCategoriesCount: action.categories.total_count
            }
        case quesCatTypes.GET_ALL_QUESTION_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Category creation request sending
        case quesCatTypes.CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                errorcategorycreate: '',
                successcategorycreate: false,
                loading: true
            }
        case quesCatTypes.CREATE_CATEGORY_SUCCESS:
            var temp = state.allCategories;
            temp.push(action.types)
            return {
                ...state,
                allCategories: temp,
                category: action.types.category_id,
                successcategorycreate: true,
                loading: false
            }
        case quesCatTypes.CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                errorcategorycreate: action.error,
                loading: false
            }  
        //Category deletion request sending   
        case quesCatTypes.DELETE_CATEGORY_REQUEST:
            return {
            ...state,
            errorcategorydelete: '',
            successcategorydelete: false,
            loading: true,
            };
        case quesCatTypes.DELETE_CATEGORY_SUCCESS:
            return {
            ...state,
            successcategorydelete: true,
            loading: false,
            };
        case quesCatTypes.DELETE_CATEGORY_FAILURE:
            return {
            ...state,
            errorcategorydelete: action.error,
            loading: false,
            };
        //Category updation request sending
        case quesCatTypes.UPDATE_CATEGORY_REQUEST:
            return {
            ...state,
            errorcategoryupdate:'',
            successcategoryupdate: false,
            loading: true,
            };
        case quesCatTypes.UPDATE_CATEGORY_SUCCESS:
            return {
            ...state,
            successcategoryupdate: true,
            loading: false,
            };
        case quesCatTypes.UPDATE_CATEGORY_FAILURE:
            return {
            ...state,
            errorcategoryupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
