import React, { Component } from "react";
import tab1 from '../assets/images/tab1.png';
import tab2 from '../assets/images/tab2.png';

class About extends Component {
  componentDidMount() {
    // Intersection Observer setup
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
        }
      });
    }, { threshold: 0.1 });

    // Target elements for animation
    const elements = document.querySelectorAll('.about-title, .about-para, .about-card, .aboutImgs');
    elements.forEach((el) => observer.observe(el));
  }

  render() {
    return (
      <>
        <div id="about" className="container">
          <div className="pt-5 pb-5 d-flex justify-content-center">
            <h1 className="about-title edsider-section-title">About Us</h1>
          </div>
          <p className="pb-5 d-flex justify-content-center text-center about-para">
            At EdSider, we are dedicated in empowering students, providing{" "}
            <br />
            educational materials for the academic excellence and unlocking full{" "}
            <br /> potential of every learner
          </p>
          <div className="row">
            <div className="col-md-6">
              <div className="card-container">
                <div className="about-card left-to-right">
                  <div className="about-card-body">
                    <h1 className="d-flex justify-content-center about-card-title">Mission</h1>
                    <p className="d-flex justify-content-center text-center about-card-text">
                      “Empowering Students for Success: Revolutionizing
                      Education through Comprehensive, Exceptional, and
                      Accessible Learning Resources”
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              <img src={tab1} className="aboutImgs" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex justify-content-center">
              <img src={tab2} className="aboutImgs" />
            </div>
            <div className="col-md-6">
              <div className="card-container">
                <div className="about-card right-to-left">
                  <div className="about-card-body">
                    <h1 className="d-flex justify-content-center about-card-title">Vision</h1>
                    <p className="d-flex justify-content-center text-center about-card-text">
                      “Unleashing Potential: Redefining Education with Engaging
                      and Accessible Learning Resources”
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}

export default About;
