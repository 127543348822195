import React from "react"
import { Doughnut } from "react-chartjs-2"
import config from "../../../helper/config"
import { customerConstants } from "../../../helper/constants.js"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let BGColor, hoverBGColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    BGColor = {
      backgroundColor: [
        "#238BCE",
        "#1A8FE3",
        "#D9DBF1",
        "#7E8299",
        "#ffc47c",
        "#61678c",
        "#8ca4f4",
        "#afbef4",
        "#c4c4bd",
        "#8cdcb4",
        "#6c85ec",
        "#dbdcd6",
        "#94a4f4",
        "#bcbcc4",
        "#fce45f",
      ],
    }
    hoverBGColor = {
      backgroundColor: [
        "#5458AF",
        "#1A8FE3",
        "#D9DBF1",
        "#7E8299",
        "#ffc47c",
        "#61678c",
        "#8ca4f4",
        "#afbef4",
        "#c4c4bd",
        "#8cdcb4",
        "#6c85ec",
        "#dbdcd6",
        "#94a4f4",
        "#bcbcc4",
        "#fce45f",
      ],
    }
    break
  case customerConstants.EXO_CUSTOMER2:
    BGColor = {
      backgroundColor: ["#216694", "#2980b9", "#5499c7", "#7fb3d5", "#a9cce3"],
    }
    hoverBGColor = {
      backgroundColor: ["#216694", "#2980b9", "#5499c7", "#7fb3d5", "#a9cce3"],
    }
    break
  default:
    BGColor = {
      backgroundColor: [
        "#238BCE",
        "#00AEE0",
        "#7558A8",
        "#F249A4",
        "#D53364",
        "#FFA31E",
        "#32B965",
        "#fce45f",
      ],
    }
    hoverBGColor = {
      backgroundColor: [
        "#238BCE",
        "#00AEE0",
        "#7558A8",
        "#F249A4",
        "#D53364",
        "#FFA31E",
        "#32B965",
        "#fce45f",
      ],
    }
    break
}

const DoughnutChart = ({ val }) => {
  const x_value = val.map((val) => {
    return val.chart_x_value
  })
  const y_value = val.map((val) => {
    return val.chart_y_value
  })
  const data = {
    labels: x_value,
    datasets: [
      {
        data: y_value,
        backgroundColor: BGColor.backgroundColor,
        hoverBackgroundColor: hoverBGColor.backgroundColor,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || ""
            let percentageValue = context.formattedValue || ""

            return `${label} : ${percentageValue}%`
          },
        },
        bodyFont: {
          family: "Poppins",
        },
        titleFont: {
          family: "Poppins",
        },
      },
    },
  }

  const chartContainerStyle = {
    // width: "50%",
    // height: "50%",
    margin: "auto",
    position: "relative",
    // paddingRight: '90px',
  }

  const labelContainerStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    textAlign: "right",
    marginTop: "20px",
    textAlign: "left",
  }

  return (
    <div style={chartContainerStyle}>
      <h3 style={{ fontSize: "12px", fontWeight: "600", textAlign: "center" }}>
        {val[0]?.chart_title}
      </h3>
      <div className="pb-5" style={{ textAlign: "center" }}>
        <h4 style={{ fontSize: "10px", fontWeight: "400" }}>
          {val[0]?.breadcrumb}
        </h4>
      </div>
      <div style={{ width: "270px", height: "270px" }}>
        <Doughnut data={data} options={options} />
      </div>
      {/* <div className="border border-danger" style={labelContainerStyle}>
        {data.labels.map((label, index) => (
          <div key={index}>
            <span
              style={{
                backgroundColor: data.datasets[0].backgroundColor[index],
                width: "10px",
                height: "10px",
                display: "inline-block",
                marginRight: "5px",
              }}
            ></span>
            {label}
          </div>
        ))}
      </div> */}
    </div>
  )
}

export default DoughnutChart
