
import {testConstants} from '../../helper/constants';
 
const initialState = {
    questionContents: [],
    text: '',
    loading: true,
    error: '',
    success: ''
}

export default function(state = initialState, action){
    switch (action.type){
        // case LOAD_TEST_PAGE_REFRESH:
        //     return {
        //         ...state,
        //         questions: [],
        //         questionContents: [],
        //         text: '',
        //         loading: false,
        //         error: '',
        //         success: ''
        //     }
        // case LOAD_TEST_PAGE:
        //     return {
        //         ...state,
        //         text: 'Candidate credential validation started!',
        //         loading: true,
        //     }
        // case LOAD_TEST_PAGE_REQUEST:
        //     return {
        //         ...state,
        //         text: action.data,
        //         loading: true
        //     }
        // case LOAD_TEST_PAGE_SUCCESS:
        //     let all_questions;
        //     try {
        //         all_questions = JSON.parse(action.data.examQuestions).questions
        //     } catch (err) {
        //         all_questions = null
        //     }
        //     return {
        //         ...state,
        //         text: 'Questions loaded successfully!',
        //         success: action.data,
        //         loading: false,
        //         questions: all_questions
        //     }
        // case LOAD_TEST_PAGE_FAILURE:
        //     return {
        //         ...state,
        //         text: 'candidate validation failed',
        //         error: action.error,
        //         loading: false
        //     }
        case testConstants.GET_PAGE_QUESTION_CONTENTS:
            return {
                ...state,
                text: 'Obtain question!',
                loading: true
            }
        case testConstants.GET_PAGE_QUESTION_CONTENTS_REQUEST:
            return {
                ...state,
                text: action.id,
                loading: true
            }
        case testConstants.GET_PAGE_QUESTION_CONTENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Obtained questions Successfully!',
                questionContents: action.data
            }
        case testConstants.GET_PAGE_QUESTION_CONTENTS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}