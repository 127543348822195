import React, { Component } from "react";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import CardTitle from "../../../components/common/CardTitle";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import "../../../components/admin/css/common.css";
import sweetAlert, {
  sweetConfirmAlert,
  sweetStatusAlert,
  sweetAlertInfo,
} from "../../../components/common/SweetAlert";
import SimpleReactValidator from "simple-react-validator";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { TypeService } from "../../../service/manage/subscription/type.service";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";
import moment from "moment-timezone";
import CheckBoxInline from "../../../components/common/form/CheckBoxInline.js";
import CheckBox from "../../../components/common/form/CheckBox.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class Type extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      showModal: false,
      isLoading: false,
      isTableDataLoading: true,
      isModified: false,
      selectedStudentId: "",
      selectedStudentTable: "",
      selectedStudent: null,
      search_id: "",
      isStudentsLoading: false,
      isSubmitting: false,
      registration_id: "",
      editVisibility: false,
      addVisibility: false,
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      types: "",
      type_id: "",
      name: "",
      description: "",
      initialDescription: "",
      credits: "",
      discount_rate: "",
      userType: "",
      typesPrivilege: null,
      isFreeCredits: false,
      discountRateError: "",
      status: "",
      start_date: "",
      end_date: "2099-12-31 00:00:00",
      statuses: [
        {
          id: "Active",
          name: "Active",
        },
        {
          id: "Deactive",
          name: "Deactive",
        },
      ],
      form_list_id: "",
      noEndDate: true,
    };

    this.createValidationsInstance();
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        isFloat: {
          message: "The :attribute must be a valid number.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[+-]?\d+(\.\d+)?$/);
          },
        },
        isDecimal: {
          message:
            "Value must be a valid decimal number with up to 2 decimal places.",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^\d+(\.\d{1,2})?$/);
          },
        },
      },
    });
  }

  componentDidMount() {
    const {
      privilege_add,
      privilege_edit,
      breadcrumb2,
      nodeTitle,
      flg_list_active,
      form_list_id,
    } = this.props.props;
    this.getVisibilityCheck(privilege_add, privilege_edit);
    this.getAllTypes();
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle,
      flg_list_active,
      userType: sessionStorage.getItem("userType"),
      typesPrivilege: this.props.typesPrivilege,
      form_list_id: form_list_id,
    });
  }

  getAllTypes() {
    const mid = 100;
    TypeService.getAllTypes(mid)
      .then((res) => {
        if (res.success) {
          this.setState({ types: res.data.types });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Member types. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getVisibilityCheck(privilege_add, privilege_edit) {
    if (privilege_add == "1") {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_edit == "1") {
      this.setState({
        editVisibility: true,
      });
    }
  }

  submitType = () => {
    if (
      this.validator.allValid() &&
      this.validator.fieldValid("credits") &&
      this.validator.fieldValid("discount_rate")
    ) {
      this.setState({ showInsertModal: false, isLoading: true });
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");

      const {
        name,
        credits,
        discount_rate,
        description,
        status,
        start_date,
        end_date,
        form_list_id,
      } = this.state;

      let obj = {
        node_mid: mid,
        name: name,
        credits: parseInt(credits),
        discount_rate: discount_rate,
        description: description,
        user_id: session_id,
        user_table_id: table_log_id,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
        status: status,
        start_date: start_date,
        end_date: end_date ? end_date : "2099-12-31 00:00:00",
        form_list_id: form_list_id,
      };
      TypeService.createType(JSON.stringify(obj))
        .then((res) => {
          if (res.success) {
            this.setState({ showInsertModal: false });
            this.getAllTypes();
            sweetStatusAlert("Success!", res.message, "success");
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert(
            "Failed !",
            "Unable to add the Member type!",
            "error"
          );
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  updateType = () => {
    if (
      this.validator.allValid() &&
      this.validator.fieldValid("credits") &&
      this.validator.fieldValid("discount_rate")
    ) {
      this.setState({ showModal: false, isLoading: true });
      const {
        name,
        credits,
        discount_rate,
        description,
        type_id,
        status,
        start_date,
        end_date,
        form_list_id,
      } = this.state;
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");

      let obj = {
        node_mid: mid,
        name: name,
        credits: credits,
        discount_rate: discount_rate,
        description: description,
        user_id: session_id,
        user_table_id: table_log_id,
        status: status,
        start_date: start_date,
        end_date: end_date ? end_date : "2099-12-31 00:00:00",
        form_list_id: form_list_id,
      };
      TypeService.updateType(JSON.stringify(obj), type_id)
        .then((res) => {
          if (res.success) {
            this.setState({ showModal: false });
            this.getAllTypes();
            sweetStatusAlert("Success!", res.message, "success");
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert("Failed !", "Unable to update the type!", "error");
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  deleteType = (type_id) => {
    sweetConfirmAlert(
      "Are you sure?",
      "Do you want to delete this Type?",
      "warning"
    ).then(() => {
      this.setState({ isLoading: true });
      TypeService.deleteType(type_id, this.state.form_list_id)
        .then((res) => {
          if (res.status === 200) {
            this.setState({ showModal: false });
            this.getAllTypes();
            sweetStatusAlert("Success!", res.message, "success");
          } else if(res.status === 400){
            sweetAlertInfo(
              "",
              "Cannot delete type. This type already in use.",
            );
          }else{
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch(() => {
          sweetStatusAlert(
            "Failed !",
            "Unable to add the Member type!",
            "error"
          );
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    });
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    let error = '';
  
    if (name === "discount_rate") {
      const discountValue = parseFloat(value);
      if (discountValue < 0 || discountValue > 100) {
        error = "Discount rate must be between 0 and 100.";
      }
    }
  
    this.setState(() => ({
      [name]: value,
      isModified: true,
      discountRateError: error, 
    }));
  
    if (name === "start_date") {
      const endDateInput = document.querySelector('[name="end_date"]');
      if (endDateInput) {
        endDateInput.min = value;
      }
    }
  };

  toggleAddTypelModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        name: "",
        credits: "",
        discount_rate: "",
        description: "",
        status: "",
        start_date: "",
        end_date: "",
        isFreeCredits: false,
      }),
      () => {}
    );
  };

  handleRadioChangeAdd = (event) => {
    const value = event.target.value;
    if (value === "free") {
      this.setState({
        description: "Free",
        isFreeCredits: true,
      });
    } else {
      this.setState({
        description: "",
        isFreeCredits: false,
      });
    }
  };

  handleRadioChangeUpdate = (event) => {
    const value = event.target.value;
    if (value === "free") {
      this.setState((prevState) => ({
        initialDescription: prevState.description,
        description: "Free",
        isFreeCredits: true,
      }));
    } else {
      this.setState((prevState) => ({
        description: prevState.initialDescription,
        isFreeCredits: false,
      }));
    }
  };

  toggleUpdateTypeModal = (type) => {
    this.setState(
      (prevState) => ({
        showModal: !prevState.showModal,
        name: "",
        credits: "",
        discount_rate: "",
        description: "",
        status: "",
        start_date: "",
        end_date: "",
        isFreeCredits: false,
      }),
      () => {
        if (this.state.showModal) {
          this.setState({
            type_id: type.type_id,
            name: type.name,
            credits: type.credits,
            discount_rate: type.discount_rate,
            description: type.description,
            status: type.status,
            start_date: type.start_date,
            end_date: type.end_date,
            noEndDate: type.end_date !== "2099-12-31 00:00:00",
          });
        }
      }
    );
  };

  convertTimeLevel(utcDateString) {
    const userTimeZone = moment.tz.guess();
    const convertedDate = moment(utcDateString).tz(userTimeZone);
    const formattedDate = convertedDate.format("MMMM D, YYYY h:mm:ss a");
    return formattedDate;
  }

  formatDateForInput = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  noEndDateSelected = (e, status) => {
    if (status) {
      this.setState({ noEndDate: status, end_date: "" });
    } else {
      this.setState({ noEndDate: status, end_date: "2099-12-31 00:00:00" });
    }
  };

  render() {
    const {
      isLoading,
      isTableDataLoading,
      breadcrumb2,
      nodeTitle,
      addVisibility,
      editVisibility,
      type_id,
      name,
      description,
      credits,
      discount_rate,
      types,
      isFreeCredits,
      userType,
      typesPrivilege,
      status,
      start_date,
      end_date,
      statuses,
      discountRateError,
      noEndDate,
    } = this.state;
    this.validator.purgeFields();
    if (!typesPrivilege) {
      return (
        <div>
          <h4>Privilege not found for this section</h4>
        </div>
      );
    }
    return (
      <>
        <Card>
          <CardHeader>
            <div>
              {/* <CardTitle cardLabel={history.location.state.form_display_title} /> */}
              <CardTitle cardLabel={"Types"} />
              {/* <h3 style={{ color: 'gray' }}>{(breadcrumb2 || nodeTitle) ? `(${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle})` : undefined}</h3> */}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {typesPrivilege.addVisibility && (
                <Button
                  className="insert-btn"
                  variant="primary"
                  onClick={this.toggleAddTypelModal}
                >
                  Add Type
                </Button>
              )}
            </div>
          </CardHeader>
          <CardBody>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div className="loading-container">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div style={{ height: "65vh", overflow: "Auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Type Id
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Credits
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Discount Rate
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        Start Date
                      </TableCell>
                      <TableCell
                        className="thead"
                        style={{ textAlign: "center" }}
                      >
                        End Date
                      </TableCell>
                      {(typesPrivilege.addVisibility ||
                        typesPrivilege.editVisibility ||
                        typesPrivilege.deleteVibility) && (
                        <TableCell
                          className="thead"
                          style={{ textAlign: "center" }}
                        >
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {types.length > 0 &&
                      types.map((type) => (
                        <TableRow key={type.type_id}>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {type.type_id}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {type.name}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {type.credits}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {type.discount_rate}%
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {type.description}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {type.status}
                          </TableCell>
                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {new Date(
                              this.convertTimeLevel(type.start_date)
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            })}
                          </TableCell>

                          <TableCell
                            className="tData"
                            style={{ textAlign: "center" }}
                          >
                            {new Date(
                              this.convertTimeLevel(type.end_date)
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            })}
                          </TableCell>

                          {(typesPrivilege.addVisibility ||
                            typesPrivilege.editVisibility ||
                            typesPrivilege.deleteVibility) && (
                            <TableCell
                              className="tData"
                              style={{ textAlign: "center" }}
                            >
                              <div className="btn-group">
                                {typesPrivilege.editVisibility && (
                                  <Button
                                    className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                    title="Edit Details"
                                    onClick={() =>
                                      this.toggleUpdateTypeModal(type)
                                    }
                                  />
                                )}
                                {typesPrivilege.deleteVibility && (
                                  <Button
                                    className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                    title="Delete Details"
                                    onClick={() =>
                                      this.deleteType(type.type_id)
                                    }
                                  />
                                )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() => this.setState({ showInsertModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add new Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={name}
                    placeholder="Name"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("name", name, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Credits*</Form.Label>
                  <Form.Control
                    type="text"
                    name="credits"
                    defaultValue={credits}
                    placeholder="Credits"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("credits", credits, [
                      "required",
                      "isFloat",
                    ])}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Discount Rate*</Form.Label>
                  <Form.Control
                    type="text"
                    name="discount_rate"
                    defaultValue={discount_rate}
                    placeholder="Discount Rate"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.state.discountRateError && <p>{this.state.discountRateError}</p>}
                    {this.validator.message("discount_rate", discount_rate, [
                      "required",
                      "isDecimal",
                      "min:0",
                      "max:100"
                    ])}
                  </div>

                </Form.Group>
                    <Form.Group 
                    as={Col}
                    md="6"
                    controlId="formDescription">
                      {/* Radio Buttons for Credits Type */}
                      <div>
                          <Form.Check
                            inline
                            type="radio"
                            label="Regular Credits"
                            value="regular"
                            name="creditsType"
                            onChange={this.handleRadioChangeAdd}
                            checked={!isFreeCredits}
                            className="form-check-inline"
                          />
                          <Form.Check
                            inline 
                            type="radio"
                            label="Free Credits"
                            value="free"
                            name="creditsType"
                            onChange={this.handleRadioChangeAdd}
                            checked={isFreeCredits}
                            className="form-check-inline" // Bootstrap inline class
                          />
                          
                        </div>
                    {/* Description Input */}
                    <Form.Label>Description*</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={description}
                      placeholder="Description"
                      onChange={this.handleChange}
                      readOnly={isFreeCredits}
                    />
                    <div style={{ color: "red" }}>
                      {this.validator.message(
                        "description",
                        description,
                        "required"
                      )}
                    </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Status*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="status"
                    onChange={this.handleChange}
                    value={status}
                  >
                    <option value="">Select</option>
                    {statuses.map((singleStatus) => (
                      <option key={singleStatus.id} value={singleStatus.name}>
                        {singleStatus.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("status", status, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>start Date*</Form.Label>
                  <Form.Control
                    type="date"
                    name="start_date"
                    defaultValue={start_date}
                    placeholder="start date"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "start_date",
                      start_date,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group as={Col} md="6" controlId="noEndDate">
                  <Form.Label>Do you want to have an end date?</Form.Label>
                  <CheckBoxInline style={{ alignItems: "center" }}>
                    <Form.Label
                      className="mb-0 mr-1"
                      style={{ fontWeight: "500" }}
                    >
                      Yes
                    </Form.Label>
                    <CheckBox
                      onChange={(e) => {
                        this.noEndDateSelected(e, true);
                      }}
                      checked={noEndDate}
                    />
                    <Form.Label
                      className="mb-0 mr-1"
                      style={{ fontWeight: "500" }}
                    >
                      No
                    </Form.Label>
                    <CheckBox
                      onChange={(e) => {
                        this.noEndDateSelected(e, false);
                      }}
                      checked={!noEndDate}
                    />
                  </CheckBoxInline>
                </Form.Group>

                {noEndDate && (
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>End Date*</Form.Label>
                    <Form.Control
                      type="date"
                      name="end_date"
                      min={start_date}
                      defaultValue={end_date}
                      placeholder="end date"
                      onChange={this.handleChange}
                    />
                    <div style={{ color: "red" }}>
                      {this.validator.message(
                        "end_date",
                        end_date && noEndDate,
                        "required"
                      )}
                    </div>
                  </Form.Group>
                )}
              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.setState({ showInsertModal: false })}
                >
                  Close
                </Button>
                {editVisibility && (
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.submitType}
                  >
                    Save changes
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Types</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    defaultValue={name}
                    placeholder="Name"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("name", name, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Credits*</Form.Label>
                  <Form.Control
                    type="text"
                    name="credits"
                    defaultValue={credits}
                    placeholder="Credits"
                    onChange={this.handleChange}
                    disabled
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("credits", credits, [
                      "required",
                      "isFloat",
                    ])}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Discount Rate*</Form.Label>
                  <Form.Control
                    type="text"
                    name="discount_rate"
                    defaultValue={discount_rate}
                    placeholder="Discount Rate"
                    onChange={this.handleChange}
                    disabled
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message("discount_rate", discount_rate, [
                      "required",
                      "isDecimal",
                    ])}
                  </div>
                </Form.Group>
                <Form.Group 
                    as={Col}
                    md="6"
                    controlId="formDescription">
                      {/* Radio Buttons for Credits Type */}
                      <div>
                          <Form.Check
                            inline
                            type="radio"
                            label="Regular Credits"
                            value="regular"
                            name="creditsType"
                            onChange={this.handleRadioChangeUpdate}
                            checked={description !== "Free"}
                            className="form-check-inline"
                            disabled
                          />
                          <Form.Check
                            inline 
                            type="radio"
                            label="Free Credits"
                            value="free"
                            name="creditsType"
                            onChange={this.handleRadioChangeUpdate}
                            checked={description === "Free"}
                            className="form-check-inline" // Bootstrap inline class
                            disabled
                          />
                          
                        </div>
                    {/* Description Input */}
                    <Form.Label>Description*</Form.Label>
                    <Form.Control
                      type="text"
                      name="description"
                      value={description}
                      placeholder="Description"
                      onChange={this.handleChange}
                      disabled={description === "Free"}
                    />
                    <div style={{ color: "red" }}>
                      {this.validator.message(
                        "description",
                        description,
                        "required|alpha_space|min:1|max:256"
                      )}
                    </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Status*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="status"
                    onChange={this.handleChange}
                    value={status}
                  >
                    <option value="">Select</option>
                    {statuses.map((singleStatus) => (
                      <option key={singleStatus.id} value={singleStatus.name}>
                        {singleStatus.name}
                      </option>
                    ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("status", status, "required")}
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Start Date*</Form.Label>
                  <Form.Control
                    type="date"
                    name="start_date"
                    value={
                      start_date
                        ? this.formatDateForInput(new Date(start_date))
                        : ""
                    }
                    placeholder="start date"
                    onChange={this.handleChange}
                  />
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "start_date",
                      start_date,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group as={Col} md="6" controlId="noEndDate">
                  <Form.Label>Do you want to have an end date?</Form.Label>
                  <CheckBoxInline style={{ alignItems: "center" }}>
                    <Form.Label
                      className="mb-0 mr-1"
                      style={{ fontWeight: "500" }}
                    >
                      Yes
                    </Form.Label>
                    <CheckBox
                      onChange={(e) => {
                        this.noEndDateSelected(e, true);
                      }}
                      checked={noEndDate}
                    />
                    <Form.Label
                      className="mb-0 mr-1"
                      style={{ fontWeight: "500" }}
                    >
                      No
                    </Form.Label>
                    <CheckBox
                      onChange={(e) => {
                        this.noEndDateSelected(e, false);
                      }}
                      checked={!noEndDate}
                    />
                  </CheckBoxInline>
                </Form.Group>

                {noEndDate && (
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>End Date*</Form.Label>
                    <Form.Control
                      type="date"
                      name="end_date"
                      min={start_date}
                      value={
                        end_date
                          ? this.formatDateForInput(new Date(end_date))
                          : ""
                      }
                      placeholder="end date"
                      onChange={this.handleChange}
                    />
                    <div style={{ color: "red" }}>
                      {this.validator.message(
                        "end_date",
                        end_date && noEndDate,
                        "required"
                      )}
                    </div>
                  </Form.Group>
                )}
              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.setState({ showModal: false })}
                >
                  Close
                </Button>
                {editVisibility && (
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={addBtnStyle}
                    onClick={this.updateType}
                  >
                    Save changes
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
