import DataHandler from "./DataHandler";
import { UserManagementService } from "../service/user_management/user_management.service";
import config from "../helper/config";

export default class APIHandler {
    static getHeader(api_name) {
        let token = DataHandler.getFromSession(api_name);
        let method = DataHandler.getFromSession('FederationService');
        let userType = DataHandler.getFromSession('user_role_selection');

        if (token) {
          return {'FederationService': method, 'Authorization': 'Bearer ' + token, 'UserType':userType}
        } 
        else {
          return {};
        }
    }

  static async handleResponse(response) {
    try {
      const text = await response.text();
      const data = text && JSON.parse(text);

      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          // auto logout if 401 or 403 response returned from the API
          UserManagementService.logout();
          window.location.reload(true);
        }
      }

      if (response.status === 500) {
        return {
          data: data.data,
          message: "An unexpected error occurred.",
          success: false,
          status: 500,
        };
      }

      return {
        data: data.data,
        message: data.message,
        success: data.success,
        status: data.status_code,
      };
    } catch (error) {
      return {
        data: null,
        message: "Something went wrong",
        success: false,
        status: 500,
      };
    }
  }
}