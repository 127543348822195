import React from "react"
import "../dashboard.css"
import ArticleIcon from "@mui/icons-material/Article"
import TimeZoneHandler from "../../../../../handlers/TimeZoneHandler.js"
import config from "../../../../../helper/config.js"
import { customerConstants } from "../../../../../helper/constants.js"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}

const DocumentCard = (props) => {
  return (
    <div className="row document__card mb-3 d-flex align-items-center">
      <div className="col-md-1 col-1 p-0 document__icon d-flex align-items-center">
        <ArticleIcon
          sx={{
            fontSize: 15,
            color: "#7779CF"
          }}
        />
      </div>
      <div className="col-md-11 col-11 pl-5">
        <h3 className="document__title m-0">
          {/* {props.annonucements?.annoucement_title} */}
          Document Title
        </h3>
        <span className="stu-variant-class label label-lg label-light-success label-inline document__breadcrumb">
          Institute › Faculties › Faculty of Management ›
        </span>
        <div className="d-flex justify-content-end pt-1 document__content">
          <p>04-04-2024 2:08 PM</p>
        </div>
      </div>
    </div>
  )
}

export default DocumentCard
