import React, { Component } from "react";
import edsider1 from "../assets/images/edsider1.svg";
import edsider2 from "../assets/images/edsider2.svg";
import edsider3 from "../assets/images/edsider3.svg";
import edsider4 from "../assets/images/edsider4.svg";

class ChooseEdsider extends Component {
  componentDidMount() {
    // Intersection Observer setup
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
          observer.unobserve(entry.target);  // Stop observing once animated
        }
      });
    }, { threshold: 0.1 });

    // Target elements for animation
    const elements = document.querySelectorAll('.edsider-card');
    elements.forEach((el, index) => {
      el.style.transitionDelay = `${index * 0.3}s`;
      observer.observe(el);
    });
  }

  render() {
    return (
      <>
        <div id="edsider" className="container section-container chooseEdsider">
          <div className="pt-5 pb-5 px-5">
            <h1 className="edsider-section-title white-section-title">Why choose EdSider?</h1>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-3">
              <div className="edsider-card">
                <div className="row d-flex justify-content-center edsider-img-container">
                  <img src={edsider1} alt="Image" className="edsiderImage" />
                </div>
                <div className="row d-flex justify-content-center text-center">
                  <h3 className="edsider-card-title">
                    Expertly Designed <br /> Papers
                  </h3>
                </div>
                <div className="row d-flex justify-content-center text-center">
                  <h3 className="edsider-card-para">
                    Our team of experienced teachers ensures that our model
                    papers cover all essential skills required for the exams
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="edsider-card">
                <div className="row d-flex justify-content-center edsider-img-container">
                  <img src={edsider4} alt="Image 2" className="edsiderImage" />
                </div>
                <div className="row d-flex justify-content-center text-center">
                  <h3 className="edsider-card-title">
                    Immediate <br /> Test Scores
                  </h3>
                </div>
                <div className="row d-flex justify-content-center text-center">
                  <h3 className="edsider-card-para">
                    Our online MCQ system provides instant feedback, allowing
                    students to track their progress and identify areas for
                    improvement
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="edsider-card">
                <div className="row d-flex justify-content-center edsider-img-container">
                  <img src={edsider3} alt="Image 2" className="edsiderImage" />
                </div>
                <div className="row d-flex justify-content-center text-center">
                  <h3 className="edsider-card-title">
                    User Friendly <br /> Platform
                  </h3>
                </div>
                <div className="row d-flex justify-content-center text-center">
                  <h3 className="edsider-card-para">
                    Edsider offers a seamless and intuitive platform that is
                    easy for students, parents, and teachers to navigate{" "}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="edsider-card">
                <div className="row d-flex justify-content-center edsider-img-container">
                  <img src={edsider2} alt="Image 2" className="edsiderImage" />
                </div>
                <div className="row d-flex justify-content-center text-center">
                  <h3 className="edsider-card-title">
                    Progress <br /> Tracking
                  </h3>
                </div>
                <div className="row d-flex justify-content-center text-center">
                  <h3 className="edsider-card-para">
                    Observe performance changes over time and compare results of various assessments.{" "}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ChooseEdsider;
