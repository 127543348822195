import React, { Component } from "react";
import { Spinner, Row, Col, Button, Dropdown } from "react-bootstrap";
import BootCard from "react-bootstrap/Card";
import ContentWrapper from "../../../components/common/ContentWrapper";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import CardTitle from "../../../components/common/CardTitle";
import {
  sweetConfirmAlert,
  sweetStatusAlert,
} from "../../../components/common/SweetAlert";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import { NodeFormMapService } from "../../../service/manage/h_tree/node_form_map.service";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SimpleReactValidator from "simple-react-validator";
import { GridColumn } from "semantic-ui-react";
import CheckBox from "../../../components/common/form/CheckBox";
import CheckBoxInline from "../../../components/common/form/CheckBoxInline";
import { history } from "../../../helper/history";
import Tooltip from "@mui/material/Tooltip";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";
import debounce from "lodash.debounce";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

class ManageNode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formPrivilagesList: [],
      dropDownForms: [],
      loading: true,
      showInsertModal: false,
      form_list_id: "",
      form_code: "",
      form_type: "",
      form_title: "",
      note: "",
      formList: [],
      selectedDropDownForm: null,
      selectedRecRest: null,
      selectedPrivRole: null,
      selectedPrivUsers: [],
      showViewUserList: false,
      node_id: "",
      lg_user_id: "",
      lg_user_table_id: "",
      formUserList: [],
      privGroupList: [],
      privRoleList: [],
      showPrivSetup: false,
      selectedPrivGroupDetali: [],
      selectFlgAddRemove: "1",
      nodeTitle: null,
      breadcrumb2: null,
      limit: 5000,
      offset: 0,
      sortColumn: "user_code",
      sortDirection: "desc",
      studentSearchTerm: "",
      memberListLoading: false,
      selectedPrivRole: 5,
    };

    this.debouncedHandleSearch = debounce(this.searchMembersListOfForm, 1000);
    this.createValidationsInstance();
    this.formInitialState = {
      form_list_id: "",
      note: "",
    };
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    // const node_id = this.props.state.mid;
    const { breadcrumb2, nodeTitle } = this.props.state;
    const node_id = this.props.state.node_mid_actual !== null ? this.props.state.node_mid_actual : this.props.state.mid;
    const user_id = sessionStorage.getItem("manage_user_id");
    const user_table_id = sessionStorage.getItem("table_log_id");

    this.setState({
      node_id: node_id,
      lg_user_id: user_id,
      lg_user_table_id: user_table_id,
      selectedDropDownForm: null,
      breadcrumb2,
      nodeTitle,
    });
    this.getPrivPerNode(node_id).then(() => {
      this.getDropdownFormList(node_id, user_id, user_table_id);
    });
  }

  convertToSriLankaTime = (utcTimestamp) => {
    let dotSplit = utcTimestamp.split(".");
    let splitDouble = utcTimestamp.split(":");
    let splitEqual = utcTimestamp.split("=");
    let checkLog = utcTimestamp.includes("log");
    if (dotSplit.length === 2 && splitDouble.length === 4) {
      let brackSplit = utcTimestamp.split("(");
      const utcDate = new Date(dotSplit[0].slice(-19));
      const sriLankaDate = new Date(utcDate.getTime() + (5 * 60 + 30) * 60000); //.toISOString()//.slice(0, 19).replace("T", " ");
      const formattedTime = sriLankaDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });

      const sriLankaTimeString =
        ("0" + sriLankaDate.getDate()).slice(-2) +
        "-" +
        ("0" + (sriLankaDate.getMonth() + 1)).slice(-2) +
        "-" +
        sriLankaDate.getFullYear() +
        "   " +
        formattedTime;
      // ('0' + sriLankaDate.getHours()).slice(-2) + ':' +
      // ('0' + sriLankaDate.getMinutes()).slice(-2) //+ ':' +
      // ('0' + sriLankaDate.getSeconds()).slice(-2);
      const neededString =
        brackSplit[0] + "(" + brackSplit[1] + "(" + sriLankaTimeString + ")";
      return sriLankaTimeString;
    }
    if (splitEqual.length === 2 && checkLog) {
      return splitEqual[1].slice(0, 10);
    }
    return undefined;
  };

  getPrivPerNode = async (node_id) => {
    return NodeFormMapService.getPrivPerNode(node_id).then((data) => {
      this.setState({
        privGroupList: data.data,
        selectedPrivGroupDetali: data.data,
        loading: false,
      });
    });
  };

  deletePrivGroup(group) {
    const { node_id, lg_user_id, lg_user_table_id } = this.state;
    sweetConfirmAlert().then(() => {
      this.setState({
        loading: true,
      });
      NodeFormMapService.deletePrivPerNode(
        lg_user_id,
        lg_user_table_id,
        node_id,
        group.form_list_id,
        group.user_access_node_mid,
        group.user_access_form_list_id,
        group.user_access_table_id,
        group.user_access_user_id,
        group.flg_add_remove,
        group.role_group_id,
        group.flg_form_display_priority,
        group.form_display_title,
        group.status
      ).then((data) => {
        sweetStatusAlert(
          "Success!",
          "Privilege group successfully deleted",
          "success"
        );
        this.props.nodeProps.getDashboardStats();
      });
    });
  }

  updatePrivGroup(group, index) {
    const { node_id, lg_user_id, lg_user_table_id, selectedPrivGroupDetali } =
      this.state;
    sweetConfirmAlert().then(() => {
      this.setState({
        loading: true,
      });
      NodeFormMapService.addUpdatePrivPerNode(
        lg_user_id,
        lg_user_table_id,
        node_id,
        group.form_list_id,
        group.user_access_node_mid,
        group.user_access_form_list_id,
        group.user_access_table_id,
        group.user_access_user_id,
        group.flg_add_remove,
        group.role_group_id,
        selectedPrivGroupDetali[index].flg_owner_restriction_onrecord,
        group.flg_owner_restriction_onlist,
        group.form_display_order,
        group.flg_form_display_priority,
        group.form_display_title,
        group.status
      ).then((data) => {
        sweetStatusAlert(
          "Success!",
          "Privilege group successfully updated",
          "success"
        );
        this.props.nodeProps.getDashboardStats();
      });
    });
  }

  addPriveGroup() {
    const {
      node_id,
      lg_user_id,
      lg_user_table_id,
      selectedDropDownForm,
      formUserList,
      selectedPrivRole,
      selectedRecRest,
      selectedPrivUsers,
      selectFlgAddRemove,
    } = this.state;
    const flg_add_remove = !!selectFlgAddRemove ? 1 : -1;
    let userId = null;
    const selectedUserIndex = selectedPrivUsers.findIndex(
      (priv) => priv === true
    );
    if (selectedUserIndex !== -1 && !!formUserList[selectedUserIndex].user_id) {
      userId = parseInt(formUserList[selectedUserIndex].user_id);
    }
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      sweetConfirmAlert().then(() => {
        this.setState({
          loading: true,
        });
        NodeFormMapService.addUpdatePrivPerNode(
          lg_user_id,
          lg_user_table_id,
          node_id,
          1,
          selectedDropDownForm.node_mid,
          selectedDropDownForm.form_list_id,
          selectedDropDownForm.form_list_table_id,
          userId,
          flg_add_remove,
          parseInt(selectedPrivRole),
          parseInt(selectedRecRest),
          null,
          null,
          null,
          null,
          null
        ).then((data) => {
          sweetStatusAlert(
            "Success!",
            "Privilege group successfully added",
            "success"
          );
          this.props.nodeProps.getDashboardStats();
        });
        this.toggleAddPrivGroup();
      });
    }
  }

  getDropdownFormList = (node_id, user_id, user_table_id) => {
    this.setState({
      loading: true,
    });
    NodeFormMapService.getDropdownFormList(
      node_id,
      user_id,
      user_table_id
    ).then((data) => {
      this.setState({
        dropDownForms: data.data.map((item, index) => ({ ...item, id: index })),
        loading: false,
      });
      this.getNecessaryLists();
    });
  };
  getNecessaryLists = () => {
    NodeFormMapService.getNodePrivRole().then((data) => {
      this.setState({
        privRoleList: data.data,
      });
    });
  };
  handleDropDownFormSelect = (e) => {
    const { lg_user_id, lg_user_table_id, node_id } = this.state;
    const selectedDropDownForm = this.state.dropDownForms.find(
      (form) => form.id === parseInt(e.target.value)
    );
    this.setState(
      {
        selectedDropDownForm: selectedDropDownForm,
        selectFlgAddRemove: "1",
        studentSearchTerm: "",
      },
      () => {
        !!selectedDropDownForm
          ? this.getMembersListOfForm(
              selectedDropDownForm.node_mid,
              selectedDropDownForm.form_list_id,
              lg_user_id,
              lg_user_table_id
            )
          : this.setState({ formUserList: [] });
      }
    );
  };

  findFormDetail = (group) => {
    return this.state.dropDownForms.find(
      (form) =>
        form.form_list_id === group.user_access_form_list_id &&
        form.node_mid === group.user_access_node_mid
    );
  };

  toggleAddPrivGroup = () => {
    if (this.state.showAddPrivGroup) {
      this.setState(() => ({
        showAddPrivGroup: false,
        selectedDropDownForm: null,
        selectedPrivRole: null,
        selectedRecRest: null,
        selectedPrivUsers: [],
        formUserList: [],
        studentSearchTerm: "",
        selectedPrivRole: 5,
      }));
    } else {
      this.setState(() => ({
        showAddPrivGroup: true,
      }));
    }
    this.validator.purgeFields();
    this.validator.hideMessages();
  };
  handleRecRestSelect = (e, index) => {
    const change = this.state.selectedPrivGroupDetali;
    change[index].flg_owner_restriction_onrecord = e.target.value;
    this.setState({
      selectedPrivGroupDetali: change,
    });
  };

  getMembersListOfForm = async (node_mid, form_list_id, user_id, table_id) => {
    const { studentSearchTerm, limit, offset, sortColumn, sortDirection } =
      this.state;

    this.setState({
      memberListLoading: true,
    });

    return NodeFormMapService.getMembersListOfForm(
      node_mid,
      form_list_id,
      user_id,
      table_id,
      limit,
      offset,
      sortColumn,
      sortDirection,
      studentSearchTerm
    ).then((data) => {
      const selectedUser = [];
      data.data.members.map((member) => {
        selectedUser.push(false);
      });
      this.setState({
        formUserList: data.data.members,
        loading: false,
        selectedPrivUsers: selectedUser,
        memberListLoading: false,
      });
    });
  };

  toggleUserList = (group) => {
    if (this.state.showViewUserList) {
      this.setState({
        showViewUserList: false,
        formUserList: [],
      });
    } else {
      this.setState(
        {
          showViewUserList: true,
          loading: true,
        },
        () => {
          this.getMembersListOfForm(
            group.user_access_node_mid,
            group.user_access_form_list_id,
            this.state.lg_user_id,
            this.state.lg_user_table_id
          ).then(() => {
            if (!!group.user_access_user_id) {
              if (group.flg_add_remove === 1 || group.flg_add_remove === -1) {
                this.setState((prev) => {
                  const user = prev.formUserList.find(
                    (item) =>
                      parseInt(item.user_table_id) ===
                        group.user_access_table_id &&
                      parseInt(item.user_id) === group.user_access_user_id
                  );
                  if (!!user) {
                    return {
                      formUserList: [user],
                    };
                  } else {
                    return {
                      formUserList: [],
                    };
                  }
                });
              }
            }
          });
        }
      );
    }
  };

  isUserSelected = () => {
    const selectedPrivUsers = this.state.selectedPrivUsers;
    const existingIndex = selectedPrivUsers.findIndex((priv) => priv === true);
    if (existingIndex === -1) {
      return false;
    } else {
      return true;
    }
  };

  onPrivCheckBoxChange = (e, index) => {
    const selectedPrivUsers = this.state.selectedPrivUsers;
    const existingIndex = selectedPrivUsers.findIndex((priv) => priv === true);
    selectedPrivUsers[existingIndex] = false;
    if (index !== existingIndex) {
      selectedPrivUsers[index] = true;
    }
    this.setState(
      {
        selectedPrivUsers: selectedPrivUsers,
      },
      () => {
        if (existingIndex === -1) {
          this.setState({
            selectFlgAddRemove: "1",
          });
        }
      }
    );
  };

  handleChange = (event) => {
    let { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handlePrivRole = (value) => {
    this.setState({ selectedPrivRole: value });
  };

  handleSearch = (event) => {
    const studentSearchTerm = event.target.value.trim();
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;

    if (studentSearchTerm === "" || regex.test(studentSearchTerm)) {
      this.setState(
        {
          studentSearchTerm,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ studentSearchTerm, searchError: true });
    }
  };

  searchMembersListOfForm = () => {
    const { lg_user_id, lg_user_table_id, selectedDropDownForm } = this.state;

    if (selectedDropDownForm) {
      this.getMembersListOfForm(
        selectedDropDownForm.node_mid,
        selectedDropDownForm.form_list_id,
        lg_user_id,
        lg_user_table_id
      );
    }
  };

  render() {
    const {
      dropDownForms,
      loading,
      selectedDropDownForm,
      formUserList,
      privRoleList,
      privGroupList,
      selectedPrivUsers,
      selectFlgAddRemove,
      breadcrumb2,
      nodeTitle,
      selectedPrivRole,
    } = this.state;
    const path = history.location.pathname;
    const selectedPrivRoleObj =
      privRoleList.length && selectedPrivRole
        ? privRoleList.find((row) => row.id === parseInt(selectedPrivRole))
        : undefined;

    this.validator.purgeFields();
    const userDetailLiteral = (group) => {
      const userLiteral =
        group.flg_add_remove === 1 || group.flg_add_remove === -1
          ? "User Detail"
          : "";
      return userLiteral;
    };
    const formattedBreadcrumb = breadcrumb2
      ? breadcrumb2.replaceAll(">", " > ")
      : "";
    return (
      <>
        <ContentWrapper disabled={path === "/manage-search"}>
          <Row
            style={{
              backgroundColor: "#FCFCFC",
              height: "90vh",
              overflow: "auto",
            }}
          >
            <Col md={12} className="px-0">
              <div style={{ overflow: "auto" }}>
                <CardHeader>
                  {this.props.state.path !== "node" && (
                    <p
                      className="pb-4"
                      style={{ fontSize: "10.5625px", color: "#00000099" }}
                    >
                      {formattedBreadcrumb
                        ? `${formattedBreadcrumb && formattedBreadcrumb}`
                        : ""}
                    </p>
                  )}
                  <div className="row d-flex justify-content-between">
                    <div>
                      <CardTitle cardLabel="Manage Node Access Privileges" />
                    </div>
                    <div>
                      <Button
                        className="insert-btn"
                        variant="primary"
                        onClick={this.toggleAddPrivGroup}
                      >
                        Add new
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                {this.state.loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "var(--maincolor)" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <CardBody>
                    <div>
                      {privGroupList.length > 0 ? (
                        <div>
                          {privGroupList.map((group, index) => (
                            <BootCard
                              className="mb-3"
                              style={{ padding: "1.125rem" }}
                              key={`${group.node_mid}${group.form_list_id}`}
                            >
                              {/* <BootCard.Body> */}
                              <Row className="mt-0">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="statusId"
                                  className="px-0"
                                >
                                  <div className="row user-list-row">
                                    <div class="col-12 col-md-8 px-0">
                                      <div className="row d-flex align-items-center">
                                        <Form.Label>
                                          <span style={{ fontWeight: "bold" }}>
                                            List of users :&nbsp;&nbsp;
                                          </span>
                                        </Form.Label>
                                        <span>
                                          <Form.Select
                                            aria-label="Default select example"
                                            value={
                                              this.findFormDetail(group) &&
                                              this.findFormDetail(group).id
                                            }
                                            onChange={
                                              this.handleDropDownFormSelect
                                            }
                                            className="mb-2"
                                            disabled
                                            style={{
                                              appearance: "none",
                                              border: "none",
                                              marginTop: "0",
                                              padding: "0",
                                            }}
                                          >
                                            <option value="">Select</option>
                                            {dropDownForms.map((form) => (
                                              <option
                                                key={form.id}
                                                value={form.id}
                                              >
                                                {form.form_display_title}
                                              </option>
                                            ))}
                                          </Form.Select>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="col-12 col-md-4 px-0">
                                      <div className="row justify-content-end px-0 pr-0 mr-0">
                                        {!!group.user_access_form_list_id &&
                                          !!group.user_access_node_mid && (
                                            <Button
                                              className="insert-btn"
                                              variant="primary"
                                              style={addBtnStyle}
                                              onClick={() =>
                                                this.toggleUserList(group)
                                              }
                                            >
                                              {!!group.user_access_user_id
                                                ? userDetailLiteral(group)
                                                : "User List"}
                                            </Button>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <Form.Label>
                                      Please select the FORM the list of users
                                      need to be taken from (i.e. USER-GROUP*)​​
                                    </Form.Label> */}
                                </Form.Group>
                              </Row>

                              <Row className="my-1">
                                <div className="row d-flex align-items-center">
                                  <Form.Label>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Location :
                                    </span>{" "}
                                    &nbsp;&nbsp;
                                    {!!this.findFormDetail(group) &&
                                      this.findFormDetail(group).breadcrumb &&
                                      `${
                                        this.findFormDetail(group).breadcrumb
                                      }`}
                                    ​
                                  </Form.Label>
                                </div>
                              </Row>

                              {/* <Row className="mt-0"> */}
                              {/* <GridColumn className="d-flex w-100"> */}
                              <Form.Group
                                as={Row}
                                // md="12"
                                controlId="statusId"
                                className="d-flex w-100 pr-0"
                              >
                                {" "}
                                <div className="col-md-12 col-12 d-flex align-items-center px-0">
                                  <Form.Label className="m-0">
                                    <span style={{ fontWeight: "bold" }}>
                                      Privilege Role :&nbsp;&nbsp;
                                    </span>
                                  </Form.Label>
                                  {group.flg_add_remove === 1 ? (
                                    <span className="stu-variant-class label label-lg label-success label-inline">
                                      Given
                                    </span>
                                  ) : (
                                    <span className="stu-variant-class label label-lg label-danger label-inline">
                                      Removed
                                    </span>
                                  )}
                                  <span>
                                    <Form.Select
                                      aria-label="Default select example"
                                      value={group.role_group_id}
                                      onChange={this.handleFormSelect}
                                      disabled
                                      style={{
                                        appearance: "none",
                                        border: "none",
                                        marginTop: "0",
                                        padding: "0",
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {privRoleList.map((row) => (
                                        <option key={row.id} value={row.id}>
                                          {row.text_str_ex}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </span>
                                </div>
                              </Form.Group>
                              {/* </GridColumn> */}
                              {/* </Row> */}

                              <Row className="mt-1">
                                <div className="col-md-8 col-12 d-flex align-items-center px-0">
                                  <Form.Label>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Last update :
                                    </span>{" "}
                                    &nbsp;&nbsp;
                                    {!!group.note &&
                                    this.convertToSriLankaTime(group.note)
                                      ? this.convertToSriLankaTime(group.note)
                                      : group.note}
                                    ​
                                  </Form.Label>
                                </div>
                                <div className="col-md-4 col-12 d-flex justify-content-end px-0">
                                  <div className="text-nowrap">
                                    {/* <Button
                                      className="btn btn-icon action-btn btn-sm m-1 edit-question-btn flaticon-edit-icon"
                                      variant="primary"
                                      onClick={() =>
                                        this.updatePrivGroup(group, index)
                                      }
                                      title="Update"
                                    /> */}
                                    <Button
                                      className="btn btn-icon btn-danger btn-sm m-1 delete-question-btn flaticon-delete"
                                      variant="danger"
                                      onClick={() =>
                                        this.deletePrivGroup(group)
                                      }
                                      title="Remove"
                                    />
                                  </div>
                                </div>
                              </Row>
                            </BootCard>
                          ))}
                        </div>
                      ) : (
                        <p style={{ textAlign: "center", marginTop: "20px" }}>
                          No privilege groups are available!
                        </p>
                      )}
                    </div>
                  </CardBody>
                )}
              </div>
            </Col>
          </Row>
        </ContentWrapper>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showViewUserList}
          onHide={() => this.toggleUserList()}
        >
          <Modal.Header closeButton>
            <Modal.Title>User List</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              {/* <CardHeader>
                  <CardTitle cardLabel="Manage Form Access Privileges" />
                </CardHeader> */}
              <div>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "#007bff" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : formUserList.length > 0 ? (
                  <Table striped bordered hover responsive>
                    <TableHead>
                      <TableRow>
                        <TableCell className="thead">User Code</TableCell>
                        <TableCell className="thead">Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!!formUserList.length &&
                        formUserList.map((user) => {
                          let splits = user.data_concat
                            ? user.data_concat.split(" ")
                            : [];

                          return (
                            <TableRow
                              key={`${user.user_id}${user.user_table_id}`}
                            >
                              <TableCell className="tData">
                                {user.user_code}
                              </TableCell>
                              <TableCell className="tData">
                                {splits.length && splits[1]}{" "}
                                {splits.length && splits[2]}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    No data available.
                  </p>
                )}
              </div>
            </Modal.Body>
          </div>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showAddPrivGroup}
          onHide={() => this.toggleAddPrivGroup()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New Privilege Group</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
              {/* <Card> */}
              {/* <CardHeader>
                  <CardTitle cardLabel="Privilege Detail" />
                 
                </CardHeader>
                <CardBody> */}
              <Row className="mt-5">
                <GridColumn className="col-md-6">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="statusId"
                    className="mb-4 pr-0"
                  >
                    <Form.Label>Privilege Role *</Form.Label>
                    <Dropdown onSelect={this.handlePrivRole}>
                      <Tooltip
                        title={
                          selectedPrivRoleObj
                            ? selectedPrivRoleObj.text_str_ex
                            : "Non Selected"
                        }
                        arrow
                      >
                        <Dropdown.Toggle
                          disabled={!privRoleList && !privRoleList.length}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            border: "1px solid #E4E6EF",
                            color: "var(--secondary-text-color)",
                            fontWeight: "400",
                          }}
                        >
                          {selectedPrivRoleObj
                            ? selectedPrivRoleObj.text_str_ex
                            : "Loading..."}
                        </Dropdown.Toggle>
                      </Tooltip>
                      <Dropdown.Menu style={{ width: "100%" }}>
                        {privRoleList.map((row) => (
                          <Tooltip title={row.text_str_ex} arrow key={row.id}>
                            <Dropdown.Item eventKey={row.id}>
                              <div>{row.text_str_ex}</div>
                            </Dropdown.Item>
                          </Tooltip>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <Form.Select
                                    aria-label="Default select example"
                                    onChange={this.handleChange}
                                    name="selectedPrivRole"
                                    >
                                    <option value="">Select</option>
                                    {privRoleList.map((row) => (
                                        <option
                                        key={row.id}
                                        value={row.id}
                                        >
                                        {row.text_str}
                                        </option>
                                    ))}
                                    </Form.Select>                                                   */}
                    {this.validator.message(
                      "selectedPrivRole",
                      selectedPrivRole,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                          required: "The privilege role is required.",
                        },
                      }
                    )}
                  </Form.Group>
                </GridColumn>
              </Row>
              <Row className="col-md-12 pr-0">
                <GridColumn className="col-md-12 mt-0 pl-0 pr-0">
                  <Form.Group as={Col} md="12" controlId="statusId">
                    <div className="row justify-content-between">
                      <Form.Label>
                        User Groups *&nbsp;&nbsp;
                        {/* {!!selectedDropDownForm &&
                            selectedDropDownForm.breadcrumb &&
                            `From: (${selectedDropDownForm.breadcrumb})`}
                          ​ */}
                      </Form.Label>
                    </div>
                    <div className="row justify-content-between">
                      <div class="col-12 pl-0">
                        <Form.Select
                          aria-label="Default select example"
                          value={
                            !!selectedDropDownForm
                              ? selectedDropDownForm.id
                              : ""
                          }
                          onChange={this.handleDropDownFormSelect}
                          className="mb-2"
                          name="user_group"
                        >
                          <option value="">Select</option>
                          {dropDownForms.map((form) => {
                            const cleanTitle = (title) =>
                              title.replace(/\[.*?\]\s*/, "");

                            return (
                              <option key={form.id} value={form.id}>
                                {(!!form.breadcrumb
                                  ? `${form.breadcrumb}  `
                                  : "") + cleanTitle(form.form_display_title)}
                              </option>
                            );
                          })}
                        </Form.Select>
                        {this.validator.message(
                          "user_group",
                          !!selectedDropDownForm
                            ? selectedDropDownForm.id
                            : undefined,
                          "required",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The user group is required.",
                            },
                          }
                        )}
                      </div>
                    </div>
                    {/* <Form.Label>
                          Please select the FORM the list of users
                          need to be taken from (i.e. USER-GROUP*)​​
                        </Form.Label> */}
                  </Form.Group>
                </GridColumn>

                {!!this.isUserSelected() && (
                  <GridColumn className="col-md-12">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="statusId"
                      className="mt-3 pl-0"
                    >
                      <Form.Label>User Selection​​</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={this.handleChange}
                        name="selectFlgAddRemove"
                        value={selectFlgAddRemove}
                      >
                        <option key={0} value="">
                          Remove
                        </option>
                        <option key={1} value="1">
                          Choose
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </GridColumn>
                )}
              </Row>
              {/* {selectedDropDownForm && (
                  <h4 style={{ marginLeft: "25px" }}>{selectedDropDownForm.form_display_title}</h4>
                )} */}
              {false ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px",
                  }}
                >
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: "var(--maincolor)" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div
                  style={{
                    paddingTop: "30px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                  }}
                >
                  {this.state.selectedDropDownForm && (
                    <div className="col-md-6 col-8 px-0">
                      {/* <CardTitle cardLabel={this.props.state.form_display_title} /> */}
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search User"
                          onChange={this.handleSearch}
                          value={this.state.studentSearchTerm}
                          isInvalid={this.state.searchError}
                          style={{ width: "300px" }}
                          className="search-bar"
                        />
                        {this.state.searchError && (
                          <Form.Control.Feedback type="invalid">
                            Invalid input
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </div>
                  )}
                  {this.state.memberListLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "var(--maincolor)" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : formUserList.length > 0 ? (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="thead">User Code</TableCell>
                          <TableCell className="thead">Name</TableCell>
                          <TableCell className="thead">Select</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {formUserList.map((user, index) => {
                          let splits = user.data_concat
                            ? user.data_concat.split(" ")
                            : [];
                          return (
                            <TableRow
                              key={`${user.user_id}${user.user_table_id}`}
                            >
                              <TableCell className="tData">
                                {user.user_code}
                              </TableCell>
                              <TableCell className="tData">
                                {splits.length > 1 && splits[1]}{" "}
                                {splits.length > 2 && splits[2]}
                              </TableCell>
                              <TableCell>
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="statusId"
                                >
                                  <CheckBoxInline>
                                    <CheckBox
                                      onChange={(e) => {
                                        this.onPrivCheckBoxChange(e, index);
                                      }}
                                      checked={selectedPrivUsers[index]}
                                      style={{ color: "#5458AF" }}
                                    />
                                  </CheckBoxInline>
                                </Form.Group>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        paddingTop: "20px",
                        paddingBottom: "20px",
                      }}
                    >
                      No data available.
                    </p>
                  )}
                </div>
              )}

              {/* </CardBody> */}
              <Modal.Footer>
                <Button
                  className="insert-btn"
                  variant="primary"
                  style={addBtnStyle}
                  onClick={() => this.addPriveGroup()}
                >
                  Add
                </Button>
                <Button
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={this.toggleAddPrivGroup}
                >
                  Close
                </Button>
              </Modal.Footer>
              {/* </Card> */}
            </Modal.Body>
          </div>
        </Modal>
      </>
    );
  }
}

export default ManageNode;

