import {authenticationConstants} from '../../helper/constants';

export function authLoginReducer(state = {}, action) {
    switch (action.type) {
        case authenticationConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                method: action.method,
            };
        case authenticationConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                method: action.method,
                token: action.token,
            };
        case authenticationConstants.LOGIN_FAILURE:
            return {
                error: action.error
            };
        case authenticationConstants.LOGOUT_REQUEST:
            return {
                ...state,
                loggingOut: true,
            };
        case authenticationConstants.LOGOUT_SUCCESS:
            return {};
        case authenticationConstants.LOGOUT_FAILURE:
            return {
                ...state
            };
        default:
            return state
    }
}