import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const ProvinceService = {
  getAll,
  add,
  update,
  getAllProvincesLimit,
  getProvinceForCountry
};

//getting all provinces
async function getAll() {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/provinces/getAll`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//getting provinces with limit
async function getAllProvincesLimit(limit, offset, search_term) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/provinces/getAllProvincesLimit/${limit}/${offset}?search_term=${search_term}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//add a new province
async function add(newProvince) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newProvince),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/provinces/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//update existing province
async function update(provinceItem, provinceId) {
  const configOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(provinceItem),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/provinces/update/${provinceId}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getProvinceForCountry(country_id) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/provinces/country/${country_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}
