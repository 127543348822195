import React, { Component } from "react";
import ContentWrapper from "../../../../components/common/ContentWrapper";
import Card from "../../../../components/common/Card";
import CardHeader from "../../../../components/common/CardHeader";
import CardBody from "../../../../components/common/CardBody";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "../../../../components/admin/css/common.css";
import { history } from "../../../../helper/history";
import debounce from "lodash.debounce";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination as Pagination,
} from "@mui/material";
import {
  sweetStatusAlert,
} from "../../../../components/common/SweetAlert";
import SimpleReactValidator from "simple-react-validator";
import { RequestService } from "../../../../service/manage/registration/request_form.service.js";
import { routes } from "../../../../helper/routes.js";
import { ParentService } from "../../../../service/manage/registration/parent.service.js";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Col, Row } from 'react-bootstrap';
import { GradeService } from "../../../../service/settings/manage/grade.service.js";
import { toInteger } from "lodash";

export default class RegisterStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      isLoading: false,
      isTableDataLoading: false,
      openDialog: false,
      email: "",
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      breadcrumb2: null,
      nodeTitle: null,
      main_table_id: null,
      limit: 10,
      offset: 0,
      search_term: "",
      totalStudents: 0,
      currentPage: 0,
      sortColumn: "user_code",
      sortDirection: "desc",
      isTableDataLoading: true,
      isLoading: false,
      isPageLoading:false,
      pending_request:null,
      selectedRow:null,
      openInfoDialog:false,
      grades:[],
    };

    // Debounced version of handleSearch function
    this.debouncedHandleSearch = debounce(this.fetchAllStudents, 2000);
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.loadInitialData();
  }

  loadInitialData() {
    let privilege_edit = !!this.props.state?.privilege_edit;
    let privilege_add = !!this.props.state?.privilege_add;
    let privilege_delete = !!this.props.state?.privilege_delete;
    this.setState(
      {
        addVisibility: privilege_add,
        editVisibility: privilege_edit,
        deleteVisibility: privilege_delete,
        user_list_table_id: sessionStorage.getItem("table_log_id"),
        session_adm_id: sessionStorage.getItem("manage_user_id"),
        session_user_email: sessionStorage.getItem("UserEmail"),
        breadcrumb2: this.props.state?.breadcrumb2,
        nodeTitle: this.props.state?.nodeTitle,
        main_table_id: this.props.state?.main_table_id,
      },
      () => {
        this.fetchAllStudents();
        this.getAllGrades();
      }
    );
  }

  fetchAllStudents = async () => {
    const {
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      session_user_email,
    } = this.state;

    await RequestService.getAllRequestsForParentsByEmail(
      session_user_email,
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term
    )
      .then((response) => {
        if (response.success) {

          const pending_request = response?.data?.requests?.map((request, index) => {
            if (
                (request?.main_record_status !== "Expired" && request?.main_record_status !== "Expired") ||
                (!request?.reference_record_status && request?.main_record_status !== "Expired")
            ) {
                return { ...request, index };
            } else {
                return null;
            }
        }).filter(request => request !== null)[0];

        this.setState({
          students: response.data.requests,
          totalStudents: response.data.total_count,
          isTableDataLoading: false,
          pending_request:pending_request,
        });

        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching requests! Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        sweetStatusAlert(
          "Failed!",
          "Error fetching requests! Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  getAllGrades=()=>{
    this.setState({ isGradeLoading: true });
    GradeService.getGrades()
        .then((response)=>{
            if(response.success){
                this.setState({grades:response.data, isGradeLoading:false})
            }
            else{
                this.setState({ isGradeLoading: false });
            }
            
        })
  }

  toggleDialog = () => {
    this.setState((prevState) => ({
      openDialog: !prevState.openDialog,
    }));
  };

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleSubmit = () => {
    const { registration_type, email } = this.state;

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });

      RequestService.checkStudentEmail(email).then((response) => {
        if (response.data) {
          if (response.data.code === 1) {
            this.setState({ email: "", isLoading: false, openDialog: false });
            sweetStatusAlert(
              "Email Exist",
              `${
                typeof response.data.message === "string"
                  ? response.data.message
                  : "Email already exist in system"
              }`,
              "info"
            );
          } else {
            const data = {
              email: email,
              registration_type: registration_type,
            };

            this.setState({ isLoading: false, openDialog: false });

            this.props.history.push({
              pathname: routes.registration_form_view,
              info: {
                success: true,
                data: data,
              },
            });
          }
        }
      });
    }
  };

  toRegisterPgae = ()=>{

    this.setState({isPageLoading:true})

    ParentService.checkParentStatus(sessionStorage.getItem("UserEmail"))
      .then((response)=>{
        if(response.success){
          sweetStatusAlert(
            "Warning",
            `${typeof response.message === "string"?response.message:"Student count exceeded or you have pending process with student"}`,
            "info"
          )
        }
        

        else{
          this.setState({isPageLoading:true})
          ParentService.getParentbyEmail(sessionStorage.getItem("UserEmail"))
            .then((response)=>{
              const data = {
                parent: response.data?.parent,
                parent_group: response.data?.parent_group ? response.data.parent_group : null
              };

            this.setState({ isLoading: false, openDialog: false });

            this.props.history.push({
              pathname: routes.registration_form_view,
              claim_student: JSON.stringify({
                success: true,
                data: data,
              }),
            });
              this.setState({isPageLoading:false})
            })
            .finally(()=>{
              this.setState({isPageLoading:false})
            })
        }
        
      })
      .finally(()=>{
        this.setState({isPageLoading:false})
      })
  }

  handleSortChange = (sortColumn) => {
    // If the same column is clicked, toggle the sort direction
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  toggleInfoDialog = () => {
    this.setState((prevState) => ({
      openInfoDialog: !prevState.openInfoDialog,
    }),()=>{
        if(!this.state.openInfoDialog){
            this.setState({selectedRow:null})
        }
    });
    };

  findGrade = (grade_id) =>{

    const {grades}  =this.state
    if(grades && grades.length > 0){

      const studentGrade = grades.find((grade) => grade.grade_id === toInteger(grade_id));

      return studentGrade? String(studentGrade.grade) : "N/A"
    }
    else{
      return "N/A"
    }
  }

  render() {
    const {
      students,
      isLoading,
      isTableDataLoading,
      openDialog,
      email,
      limit,
      totalStudents,
      currentPage,
      breadcrumb2,
      nodeTitle,
      isPageLoading,
      openInfoDialog,
      selectedRow
    } = this.state;

    const path = history.location.pathname;

    return (
      <>
        <div style={{ height: "90vh", overflow: "auto" }}>
          <ContentWrapper disabled={path === "/manage-search"}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isPageLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
            <Card> 
              <CardHeader>
                <div>
                  {this.props.state?.path !== "node" && (
                    <h3 style={{ color: "gray" }}>
                      {breadcrumb2 || nodeTitle
                        ? `${breadcrumb2 && breadcrumb2}${
                            nodeTitle && nodeTitle
                          }`
                        : undefined}
                    </h3>
                  )}
                  <Form.Group
                    controlId="exampleForm.ControlInput1"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      onChange={this.handleSearch}
                      isInvalid={this.state.searchError}
                      style={{ width: "300px" }}
                    />
                    {this.state.searchError && (
                      <Form.Control.Feedback type="invalid">
                        Invalid input
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div>
                  <Button
                    className="insert-btn"
                    variant="primary"
                    onClick={this.toRegisterPgae}
                  >
                    Claim or Invite Student
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                {isTableDataLoading ? (
                  <div className="loading-container">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <div style={{ overflowX: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="thead"
                          >
                            Student First Name
                          </TableCell>
                          <TableCell
                            className="thead"
                          >
                            Student Last Name
                          </TableCell>
                          <TableCell
                            className="thead"
                          >
                            Student Email
                          </TableCell>
                          <TableCell
                            className="thead"
                          >
                            Student Grade
                          </TableCell>
                          <TableCell
                              className="thead"
                          >
                            Student Action
                          </TableCell>
                          <TableCell
                              className="thead"
                          >
                            Your Action
                          </TableCell>
                          <TableCell
                              className="thead"
                          >
                            Process Status
                          </TableCell>
                          <TableCell
                              className="thead"
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {students?.map((studentItem) => (
                          <TableRow key={studentItem.user_id}>
                            <TableCell className="tData">
                              {studentItem.reference_first_name?studentItem.reference_first_name:"N/A"}
                            </TableCell>
                            <TableCell className="tData">
                              {studentItem.reference_last_name?studentItem.reference_last_name:"N/A"}
                            </TableCell>
                            <TableCell className="tData">
                              {studentItem.reference_child_email?studentItem.reference_child_email:"N/A"}
                            </TableCell>
                            <TableCell className="tData">
                              {studentItem.reference_grade?this.findGrade(studentItem.reference_grade):"N/A"}
                            </TableCell>
                            <TableCell className="tData">
                                {studentItem?.reference_request_status ? (
                                    studentItem?.reference_request_status === "Pending" ? (
                                        <Chip label={studentItem?.reference_request_status} color="warning" />
                                    ) : studentItem?.reference_request_status === "Approved" ? (
                                        <Chip label={studentItem?.reference_request_status} color="success" />
                                    ) : studentItem?.reference_request_status === "Rejected" ? (
                                        <Chip label={studentItem?.reference_request_status} color="error" />
                                    ) : "N/A"
                                ) : "N/A"}
                            </TableCell>
                            <TableCell className="tData">
                                {studentItem?.main_request_status ? (
                                    studentItem?.main_request_status === "Pending" ? (
                                        <Chip label={studentItem?.main_request_status} color="warning" />
                                    ) : studentItem?.main_request_status === "Approved" ? (
                                        <Chip label={studentItem?.main_request_status} color="success" />
                                    ) : studentItem?.main_request_status === "Rejected" ? (
                                        <Chip label={studentItem?.main_request_status} color="error" />
                                    ) : "N/A"
                                ) : "N/A"}
                            </TableCell>
                            <TableCell className="tData">
                                {studentItem?.main_record_status === "Expired" && studentItem?.reference_record_status === "Expired" ? (
                                    <Chip label="Expired" color="error" />
                                ) : (
                                    !studentItem?.reference_record_status && studentItem?.main_record_status === "Expired"?
                                    (
                                        <Chip label="Expired" color="error" />
                                    )
                                    :
                                    (
                                        <>
                                            {/* <Tooltip
                                                PopperProps={{
                                                disablePortal: true,
                                                }}
                                                onClose={this.handleTooltipClose}
                                                open={typeof selectedIndex === "number" && selectedIndex === index}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Pending Process"
                                                arrow
                                                placement="right"
                                            > */}
                                            <Chip label="Pending" color="warning" />
                                            {/* </Tooltip> */}
                                        </>
                                    )
                                    
                                )}
                            </TableCell>
                            <TableCell className='tData'>
                                <button 
                                    className="child-view-icon"
                                    onClick={()=>{
                                      this.setState({selectedRow:studentItem});
                                      this.toggleInfoDialog();
                                      }}
                                    >
                                    <VisibilityIcon
                                        sx={{ fontSize: 16, color: "#fff" }}
                                        style={{ cursor: "pointer" }}
                                    />
                                </button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      component="div"
                      count={parseInt(totalStudents)}
                      page={currentPage}
                      rowsPerPage={limit}
                      rowsPerPageOptions={[5, 10, 20]}
                      onPageChange={this.handlePageChange}
                      onRowsPerPageChange={this.handleRowsPerPageChange}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </ContentWrapper>
        </div>

        <Modal show={openInfoDialog} onHide={this.toggleInfoDialog} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Request details</Modal.Title>
                </Modal.Header>
            <Modal.Body>

            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Student First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="parent_fname"
                    placeholder="First Name"
                    defaultValue={selectedRow?.reference_first_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Student Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="parent_lname"
                    placeholder="Last Name"
                    disabled
                    defaultValue={selectedRow?.reference_last_name}
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Student Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Parent Email"
                    disabled
                    defaultValue={selectedRow?.reference_child_email}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Student Grade</Form.Label>
                  <Form.Control
                    type="text"
                    name="grade"
                    placeholder="Student Grade"
                    disabled
                    defaultValue={this.findGrade(selectedRow?.reference_grade)}
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                  style={{display:selectedRow?.reference_record_status?"block":"none"}}
                >
                  <Form.Label>Student Action</Form.Label>
                  {selectedRow?.reference_request_status ? (
                    selectedRow?.reference_request_status === "Pending" ? (
                        <Chip label={selectedRow?.reference_request_status} color="warning" />
                    ) : selectedRow?.reference_request_status === "Approved" ? (
                        <Chip label={selectedRow?.reference_request_status} color="success" />
                    ) : selectedRow?.reference_request_status === "Rejected" ? (
                        <Chip label={selectedRow?.reference_request_status} color="error" />
                    ) : "N/A"
                ) : "N/A"}
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Your Action</Form.Label>
                  {selectedRow?.main_request_status ? (
                    selectedRow?.main_request_status === "Pending" ? (
                        <Chip label={selectedRow?.main_request_status} color="warning" />
                    ) : selectedRow?.main_request_status === "Approved" ? (
                        <Chip label={selectedRow?.main_request_status} color="success" />
                    ) : selectedRow?.main_request_status === "Rejected" ? (
                        <Chip label={selectedRow?.main_request_status} color="error" />
                    ) : "N/A"
                ) : "N/A"}
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Process Status</Form.Label>
                  {selectedRow?.main_record_status === "Expired" && selectedRow?.reference_record_status === "Expired" ? (
                    <Chip label="Expired" color="error" />
                ) : (
                    !selectedRow?.reference_record_status && selectedRow?.main_record_status === "Expired"?
                    (
                        <Chip label="Expired" color="error" />
                    )
                    :
                    (
                        <Chip label="Pending" color="warning" />
                    )
                    
                )}
                </Form.Group>

              </Row>

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  onClick={this.toggleInfoDialog}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Form>

            </Modal.Body>
            </Modal>

        <Modal show={openDialog} onHide={this.toggleDialog} centered>
          <Modal.Header closeButton>
            <Modal.Title>Enter your email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleEmailChange}
                  placeholder="Enter email"
                />
                <span className="form-error-message">
                  {this.validator.message(
                    "email",
                    this.state.email,
                    "required|email"
                  )}
                </span>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleDialog}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.handleSubmit}
              className="insert-btn"
              style={{ backgroundColor: "#5458AF" }}
              disabled={isLoading}
            >
              {isLoading ? (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    minWidth: "50px",
                  }}
                >
                  <i className="fas fa-spinner fa-spin"></i>
                </div>
              ) : (
                "Submit"
              )}
            </Button>
          </Modal.Footer>
        </Modal>

      </>
    );
  }
}
