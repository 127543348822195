import React from "react";
import { Link } from "react-router-dom";
import edfocilogo from "../../../assets/images/logo.png";
import edsiderlogo from "../../../assets/images/edsiderlogo.png";
import teacherImg from "../../../assets/images/teacher.png";
import studentImg from "../../../assets/images/student.png";
import parentImg from "../../../assets/images/parent.png";
import adminImg from "../../../assets/images/admin.png";
import cmclogo from "../../../assets/images/mid.png";
import RAlogo from "../../../assets/images/royal-logo.png";
import CGClogo from "../../../assets/images/cgc-logo.png";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin:sessionStorage.getItem("userType") === "admin",
      isTeacher:sessionStorage.getItem("userType") === "teacher",
      isStudent:sessionStorage.getItem("userType") === "student",
      isParent:sessionStorage.getItem("userType") === "parent"
    };
  }

  //Creates the label for the button of aside menu
  headerLabelMaker = () => {
    const sessionUserName = sessionStorage.getItem("username");

    if (sessionUserName) {
      const names = sessionUserName.split(" ");
      const initials = names.map((name) => {
        if (name.length > 0) {
          return name[0].toUpperCase();
        } else {
          return ""; // Handle empty names if necessary
        }
      });
      const result = initials.join("");
      return result;
    } else {
      return ""; // Handle the case where sessionUserName is not available
    }
  };

  render() {
    const customer = getCustomerFeatureFlag();
    const {isAdmin, isTeacher, isParent, isStudent} = this.state
    let logo, logoStyle;
    switch (customer) {
      case customerConstants.EXO_CUSTOMER1:
        logo = edsiderlogo;
        logoStyle = { width: "140px", height: "auto" };
        break;
      case customerConstants.EXO_CUSTOMER2:
        logo = cmclogo;
        logoStyle = { width: "190px", height: "auto" };
        break;
      case customerConstants.EDF_CUSTOMER2:
        logo = RAlogo;
        logoStyle = { width: "40px", height: "auto" };
        break;
      case customerConstants.EDF_CUSTOMER3:
        logo = CGClogo;
        logoStyle = { width: "40px", height: "auto" };
        break;
      default:
        logo = edfocilogo;
        logoStyle = { width: "140px", height: "auto" };
        break;
    }
    return (
      <div id="kt_header" className="header header-fixed">
        {/* begin::Container */}
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          {/* begin::Header Menu Wrapper */}
          <div
            className="header-menu-wrapper header-menu-wrapper-left"
            id="kt_header_menu_wrapper"
          >
            {/* begin::Info */}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/* begin::Actions */}
              {/* <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div> */}
              {/* <span className="text-muted font-weight-bold mr-4"></span> */}
              <div className="brand-logo">
                {/* <img alt="Logo" className="logo" src="../../../../public/assets/media/logos/logo-light.png" /> */}
                <img
                  alt="Logo"
                  className="logo"
                  src={logo}
                  style={logoStyle}
                />
              </div>
              {/* end::Actions */}
              {isTeacher && <img
                  alt="Logo"
                  className="logo"
                  src={teacherImg}
                  style={logoStyle}
                />}
                {isStudent && <img
                  alt="Logo"
                  className="logo"
                  src={studentImg}
                  style={logoStyle}
                />}
                {isParent && <img
                  alt="Logo"
                  className="logo"
                  src={parentImg}
                  style={logoStyle}
                />}
                {isAdmin && <img
                  alt="Logo"
                  className="logo"
                  src={adminImg}
                  style={logoStyle}
                />}
            </div>
            {/* end::Info */}
          </div>

          {/* end::Header Menu Wrapper */}

          {/* begin::Topbar */}
          <div className="topbar">
            {/* begin::User */}
            <div className="topbar-item">
              <div
                className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                id="kt_quick_user_toggle"
              >
                <span className="symbol symbol-35 symbol-primary">
                  <span className="symbol-label font-size-h5 font-weight-bold">
                    {this.headerLabelMaker()}
                  </span>
                </span>
              </div>
            </div>
            {/* end::User */}
          </div>
          {/* end::Topbar */}
        </div>
        {/* end::Container */}
      </div>
    );
  }
}

export default Header;
