import React, { useEffect } from 'react';

export default function FinalizeQuestionTitle ({ Question}) {
    return (
        
        <div className="font-size-h3 col-md-12">{Question}</div>
                    
    )
}


