import React, { useEffect } from "react";
import HeaderMobile from "../../admin/layouts/HeaderMobile";
import Header from "../../admin/layouts/Header";
import TopMenu from "./TopMenu";
import Footer from "../../admin/layouts/Footer";
import Scrolltop from "../../admin/layouts/Scrolltop";
import UserProfile from "../../admin/layouts/UserProfile";
import { useState } from "react";
import { UserManagementService } from "../../../service/user_management/user_management.service";
import Spinner from "../../Spinner";
import Sidebar from "./Sidebar";
import "./layouts.css";

export default function Template(props) {
  useEffect(() => {
    if (window.KTLayoutAsideMenu)
      window.KTLayoutAsideMenu.init("kt_aside_menu");
    if (window.KTLayoutQuickUser)
      window.KTLayoutQuickUser.init("kt_quick_user");
    if (window.KTLayoutAsideToggle)
      window.KTLayoutAsideToggle.init("kt_aside_toggle");
    let isAdmin = sessionStorage.getItem("userType") === "admin";
    let isTeacher =  sessionStorage.getItem("userType") === "teacher";
    let isStudent = sessionStorage.getItem("userType") === "student";
    let isParent = sessionStorage.getItem("userType") === "parent";
    setIsAdmin(isAdmin);
    setIsTeacher(isTeacher);
    setIsStudent(isStudent);
    setIsParent(isParent)
  }, []);
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isParent, setIsParent] = useState(false);

  const onLogout = async () => {
    setLoading(true);
    await UserManagementService.logout();
    setLoading(false);
  };
  if (loading) {
    return <Spinner active={true} text="Logging Out..." />;
  } else {
    return (
      <>
        {/* Start:: Header Mobile */}
        <HeaderMobile />
        {/* End:: Header Mobile */}

        {/* begin::Main */}
        <div className="d-flex flex-column flex-root">
          {/* <div className="example"></div> */}
          {/* begin::Page */}
          <div className="d-flex flex-row flex-column-fluid page">
            {/* begin::Wrapper */}
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"

            >
              {/* begin:: Header */}
              <Header />
              {/* end:: Header */}

              {/* begin::TopMenu */}
              {/* <TopMenu isAdmin={isAdmin} /> */}
              {/* end:: TopMenu */}
               {/* begin::Sidebar */}
               <Sidebar isAdmin={isAdmin} isTeacher={isTeacher} isStudent={isStudent} isParent={isParent}/>
              {/* end:: Sidebar */}

              {/* begin::Content */}
              {/* <DashBoard /> */}
              {props.children}
              {/* end::Content */}

              {/* begin:: Footer */}
              <Footer />
              {/* end:: Footer */}
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Page */}
        </div>
        {/*  end::Main */}

        {/* begin::Scrolltop */}
        <Scrolltop />
        {/* end::Scrolltop */}

        {/* begin::User Panel */}
          <UserProfile onLogout={onLogout} user_name={props.user_name}/>
        {/* end::User Panel */}
      </>
    );
  }
}
