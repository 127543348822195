import React, { Component } from "react";
import Card from "../../../../components/common/Card.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardBody from "../../../../components/common/CardBody.js";
import CardTitle from "../../../../components/common/CardTitle.js";
import CircularProgress from '@mui/material/CircularProgress';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../../components/common/spinner/PageSpinner.js";
import "../../../../components/admin/css/common.css";
import sweetAlert, {
    sweetConfirmAlert,
    sweetStatusAlert,
    sweetAlertInfo,
} from "../../../../components/common/SweetAlert.js";
import SimpleReactValidator from "simple-react-validator";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import TimeZoneHandler from "../../../../handlers/TimeZoneHandler.js";
import config from "../../../../helper/config.js";
import { customerConstants } from "../../../../helper/constants.js";
import { CombankService } from "../../../../service/manage/subscription/combank_pg.service.js";
import { TransactionService } from "../../../../service/manage/subscription/transaction.service.js";
import { LevelPriceService } from "../../../../service/manage/subscription/level_price.service.js";
import { CreditService } from "../../../../service/manage/subscription/credit.service.js";
import { TypeService } from "../../../../service/manage/subscription/type.service.js";
import { StripeService } from "../../../../service/manage/subscription/stripe_pg.service.js";
import { StudentService } from "../../../../service/manage/registration/student.service.js";
import ReactSelect from "react-select";
import debounce from "lodash.debounce";
import { GradeService } from "../../../../service/settings/manage/grade.service.js";
import PackageCard from './PackageCard.js'; import Grid from '@mui/material/Grid';
import no_credit_packs from '../../../../assets/images/no_credit_packs.png';

const getCustomerFeatureFlag = () => {
    return config.customerConfigs.featureFlag;
};

const customer = getCustomerFeatureFlag()

export default class CreditPackages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showInsertModal: false,
            showModal: false,
            isLoading: false,
            isTableDataLoading: false,
            isStudentListLoading: false,
            isModified: false,
            search_id: "",
            isStudentsLoading: false,
            isSubmitting: false,
            registration_id: "",
            editVisibility: true,
            addVisibility: true,
            breadcrumb2: null,
            nodeTitle: null,
            flg_list_active: null,
            credits: "",
            types: "",
            type_id: "",
            type_name: "",
            type_credits: "",
            discount_rate: "",
            level_prices: "",
            credit_packages: "",
            level_price_id: "",
            level_price_name: "",
            level_name: "",
            level_definition: "",
            level_price_credits: "",
            level_price_value: "",
            level_price_currency_type: "",
            level_price_start_date: "",
            level_price_end_date: "",
            credit_id: "",
            name: "",
            definition: "",
            description: "",
            amount: "",
            userType: "",
            total_credits: "",
            isPayEnabled: false,
            showTransactionModal: false,
            selectedTransaction: null,
            creditsPrivilege: null,
            customer: "EdSider",
            sessionId: "",
            isDropHidden: false,
            studentlist: [],
            selectedStudent: "",
            payment_method: "",
            reference_id: "",
            selectedFile: null,
            studentSearchTerm: "",
            selectedOption: "",
            showStudentParentModal: false,
            grades: "",
            assessment_grade_id: "",
            level_type_loading: false,
            without_discount: "",
            discounted_amount: "",
            form_list_id: "",
            userIDs: "",
            selectedStudentGrade: "",
            gradeMapAvailable: false,
        };

        this.createValidationsInstance();
        this.debouncedHandleStudentSearch = debounce(this.fetchallstudent, 1000);
    }

    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
    };
    createValidationsInstance() {
        this.validator = new SimpleReactValidator();
    }

    handleRowClick = (transaction) => {
        this.setState({
            showTransactionModal: true,
            selectedTransaction: transaction,
        });
    };

    handleModalClose = () => {
        this.setState({
            showTransactionModal: false,
            selectedTransaction: null,
        });
    };

    getAllAssessmentGrades() {
        GradeService.getGrades().then((res) => {
            if (res.data) {
                this.setState({ grades: res.data });
            } else {
                sweetStatusAlert(
                    "Failed!",
                    "Error fetching Level Grades. Please refresh the screen!",
                    "error"
                );
            }
        });
    }

    componentDidMount() {
        const { breadcrumb2, nodeTitle, flg_list_active, form_list_id } =
            this.props.props;
        const userType = sessionStorage.getItem("userType");
        this.setState(
            {
                breadcrumb2: breadcrumb2,
                isLoading: true,
                nodeTitle,
                flg_list_active,
                userType: userType,
                creditsPrivilege: this.props.creditsPrivilege,
                form_list_id: form_list_id,
                customer: getCustomerFeatureFlag(),
            },
            () => {
                const { creditsPrivilege } = this.props;
                if (creditsPrivilege.isStudent) {
                    this.getAllAssessmentGrades();
                } else if (creditsPrivilege.isParent) {
                    if (flg_list_active === 1) {
                        this.setState({ isDropHidden: true });
                    }
                    this.fetchallstudent();
                    this.getAllAssessmentGrades();
                }
            }
        );
        if (sessionStorage.getItem("studentGrade")) {
            const mid = 100;
            StudentService.getByEncryptedId(
                sessionStorage.getItem("manage_user_id")
            ).then((response) => {
                LevelPriceService.getAllActiveCreditPackagesByGradeAndID(
                    mid,
                    response.data.grade_id,
                    sessionStorage.getItem("manage_user_id")
                )
                    .then((res) => {
                        if (res.success && res.data.credit_packages) {
                            this.setState(
                                () => ({
                                    credit_packages: res.data.credit_packages,
                                    assessment_grade_id: sessionStorage.getItem("studentGrade"),
                                    gradeMapAvailable: true,
                                })
                            );
                        } else {
                            this.setState(
                                {
                                    types: [],
                                    assessment_grade_id: "",
                                    gradeMapAvailable: false,
                                },
                                () => { }
                            );
                        }
                    })
                    .finally(() => {
                        this.setState({
                            level_type_loading: false,
                            isLoading: false,
                        });
                    });
            });
        }
    }

    componentWillUnmount() {
        if (this.state.customer !== customerConstants.EXO_CUSTOMER2) {
            // Cleanup: Remove the script when the component is unmounted
            const script = document.querySelector("script[src*='checkout.min.js']");
            if (script) {
                document.head.removeChild(script);
            }
        }
    }

    handlePaymentPageClick = async (payment_obj) => {
        this.setState({ isLoading: true });

        await this.getSessionID(payment_obj);

        this.loadCheckoutScript();
    };

    getSessionID = async (payment_obj) => {
        try {
            const res = await CombankService.getSessionID(
                JSON.stringify(payment_obj)
            );
            sessionStorage.setItem("checkout_session_id", res.data.session.id);
            this.setState({
                sessionId: res.data.session.id,
            });
        } catch (error) {
            sweetStatusAlert(
                "Error!",
                "An error occurred while getting session ID.",
                "error"
            );
        } finally {
            this.setState({
                isLoading: false,
            });
        }
    };

    loadCheckoutScript = () => {
        const script = document.createElement("script");
        script.src =
            "https://cbcmpgs.gateway.mastercard.com/static/checkout/checkout.min.js";
        document.head.appendChild(script);

        script.onload = () => {
            window.Checkout.configure({
                session: {
                    id: this.state.sessionId,
                },
            });

            // Set up callbacks after configuring
            window.Checkout.onComplete = this.successCallback;
            window.Checkout.onError = this.errorCallback;
            window.Checkout.onCancel = this.cancelCallback;

            // Ensure Checkout is configured before attempting to showPaymentPage
            if (window.Checkout) {
                window.Checkout.showPaymentPage();
            }
        };
    };

    fetchallstudent = () => {
        const { mid, lg_user_id, lg_user_table_id, form_list_id } =
            this.props.props;
        const { studentSearchTerm } = this.state;

        this.setState({ isStudentListLoading: true });

        let userType = sessionStorage.getItem("userType");

        let user_id;
        let user_table_id;

        if (userType === "parent") {
            user_id = sessionStorage.getItem("parent_group_id");
            user_table_id = sessionStorage.getItem("parent_group_user_table_id");
        } else {
            user_id = lg_user_id;
            user_table_id = lg_user_table_id;
        }
        CreditService.getAllStudentsForParent(
            mid,
            form_list_id,
            user_id,
            user_table_id,
            studentSearchTerm,
            userType
        )
            .then((response) => {
                if (response.success) {
                    const userIDs = response.data.students.map(
                        (student) => student.user_id
                    );
                    this.setState({
                        studentlist: response.data.students,
                        userIDs: userIDs,
                        isLoading: false,
                    });
                    // this.getAllCreditsByParent(userIDs);
                } else {
                    sweetStatusAlert(
                        "Failed!",
                        "Error fetching students. Please refresh the screen!",
                        "error"
                    );
                }
            })
            .catch(() => {
                sweetStatusAlert(
                    "Failed!",
                    "Error fetching students. Please refresh the screen!",
                    "error"
                );
            })
            .finally(() => {
                this.setState({ isStudentListLoading: false });
            });
    };

    submitCredit = async () => {
        if (this.validator.allValid()) {
            if (this.state.userType === "admin") {
                this.setState({ showInsertModal: false });
            } else {
                this.setState({ showStudentParentModal: false });
            }
            const mid = 100;
            const session_id = sessionStorage.getItem("manage_user_id");
            const table_log_id = sessionStorage.getItem("table_log_id");

            const {
                type_id,
                type_name,
                type_credits,
                level_price_id,
                level_price_credits,
                level_price_start_date,
                level_price_end_date,
                amount,
                level_price_currency_type,
                userType,
                selectedStudent,
                payment_method,
                reference_id,
                form_list_id,
            } = this.state;

            let student = this.state.studentlist.find(
                (student) => student.user_id === selectedStudent
            );

            // Parse the date strings
            const startDate = new Date(
                level_price_start_date.split("/").reverse().join("-")
            );
            const endDate = new Date(
                level_price_end_date.split("/").reverse().join("-")
            );

            // Calculate the difference in days
            const timeDifference = endDate.getTime() - startDate.getTime();
            const daysDifference = timeDifference / (1000 * 3600 * 24);

            // Add the difference to the start date to get the expiration date
            const expirationDate = new Date(
                startDate.getTime() + daysDifference * (1000 * 3600 * 24)
            );

            let paid_by = "";

            if (userType === "parent") {
                paid_by = sessionStorage.getItem("username");
            } else if (userType === "student") {
                paid_by = sessionStorage.getItem("username");
            } else if (userType === "admin") {
                paid_by = "Admin";
            }

            const payment_obj = {
                email: sessionStorage.getItem("email"),
                unit_amount: amount * 100,
                amount: amount,
                credits: parseInt(type_credits) * parseInt(level_price_credits),
                currency_type: level_price_currency_type,
                type_name: type_name,
                node_mid: mid,
                type_id: parseInt(type_id),
                level_price_id: parseInt(level_price_id),
                expiration_date: expirationDate,
                total_credits: parseInt(type_credits) * parseInt(level_price_credits),
                available_credits:
                    parseInt(type_credits) * parseInt(level_price_credits),
                used_credits: 0,
                user_id: userType === "student" ? session_id : selectedStudent,
                user_table_id:
                    userType === "student" ? table_log_id : student.user_table_id,
                create_user_id: session_id,
                create_user_table_id: table_log_id,
                paid_by: paid_by,
                payment_method: payment_method ? payment_method : "Online",
                reference_id: reference_id ? reference_id : "N/A",
                form_list_id: userType === "admin" ? form_list_id : 29,
            };

            if (this.state.customer === customerConstants.EXO_CUSTOMER2) {
                this.setState({ isLoading: true });
                StripeService.create_checkout_session(JSON.stringify(payment_obj))
                    .then((res) => {
                        if (res.success) {
                            window.location.replace(res.data);
                        } else {
                            sweetStatusAlert("Failed!", res.message, "error");
                        }
                    })
                    .finally(() => {
                        // Hide loading indicator
                        this.setState({ isLoading: false });
                    });
            } else {
                if (userType === "admin") {
                    await this.manualPayment(payment_obj);
                } else {
                    this.setState({ isLoading: true });
                    await this.handlePaymentPageClick(payment_obj);
                }
            }
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    };

    handleSweetAlert = (toggleFunction, modalVisibility) => {
        if (this.state.isModified) {
            sweetAlert().then((response) => {
                this.validator.purgeFields();
                this.validator.hideMessages();
                this.setState(() => ({
                    [modalVisibility]: response,
                    isModified: false,
                }));
            });
        } else {
            this.validator.purgeFields();
            this.validator.hideMessages();
            toggleFunction();
        }
    };

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(
            () => ({
                isLoading: true,
            }))
        if (name == "assessment_grade_id") {
            if (value) {
                this.setState({
                    level_type_loading: true,
                    isPayEnabled: false,
                    level_definition: "",
                    level_price_currency_type: "",
                    amount: "",
                });
                const mid = 100;
                LevelPriceService.getAllActiveCreditPackagesByGradeAndID(mid, value, sessionStorage.getItem("manage_user_id"))
                    .then((res) => {
                        if (res.success && res.data.credit_packages) {
                            this.setState(
                                () => ({
                                    credit_packages: res.data.credit_packages,
                                    assessment_grade_id: value,
                                    gradeMapAvailable: true,
                                }),
                            );
                        } else {
                            this.setState(
                                {
                                    credit_packages: 0,
                                    assessment_grade_id: "",
                                    gradeMapAvailable: false,
                                },
                                () => {
                                    sweetAlertInfo(
                                        "",
                                        "Credit packages for the selected grade are coming soon."
                                    );
                                }
                            );
                        }
                    })
                    .finally(() => {
                        this.setState(
                            () => ({
                                isLoading: false,
                            }))
                    });
            } else {
                this.setState({
                    level_price_id: "",
                    level_price_name: "",
                    level_price_credits: "",
                    level_name: "",
                    level_definition: "",
                    level_price_value: "",
                    level_price_currency_type: "",
                    level_price_start_date: "",
                    level_price_end_date: "",
                    amount: "",
                    total_credits: "",
                    isPayEnabled: false,
                    types: [],
                });
            }
        }
    };

    handleStudentSelect(event) {
        this.setState({
            isLoading: true,
        });
        if (!event.target.value) {
            this.setState({
                selectedStudent: "",
                types: [],
                assessment_grade_id: "",
                type_name: "",
                type_credits: "",
                discount_rate: "",
                amount: "",
                total_credits: "",
                level_price_name: "",
                level_definition: "",
            });
            return;
        }
        let student = this.state.studentlist.find(
            (student) => student.user_id === event.target.value
        );
        const selectedStudent = student.user_id;
        if (selectedStudent) {
            this.setState({
                selectedStudent: selectedStudent
            });
        }
        if (selectedStudent) {
            const mid = 100;
            let studentGrade;
            StudentService.getByEncryptedId(selectedStudent).then((response) => {
                this.setState({
                    selectedStudentGrade: response.data?.grade_id,
                    assessment_grade_id: response?.data?.grade_id
                });
                studentGrade = response.data.grade;
                LevelPriceService.getAllActiveCreditPackagesByGradeAndID(
                    mid,
                    response.data.grade_id,
                    selectedStudent
                )
                    .then((res) => {
                        if (res.success && res.data.credit_packages) {
                            this.setState(
                                () => ({
                                    credit_packages: res.data.credit_packages,
                                })
                            );
                        }
                    })

                    .finally(() => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            });
        }
    }

    onFileChange = (event) => {
        this.setState({
            selectedFile: event.target.files[2],
        });
    };

    handleStudentSearch = (event) => {
        const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
        if (event === "" || regex.test(event)) {
            let num = event;
            this.setState(
                {
                    studentSearchTerm: num,
                    searchError: false,
                    selectedOption: "",
                    studentlist: [],
                },
                () => {
                    // Call the debounced version of fetchStudentData
                    this.debouncedHandleStudentSearch();
                }
            );
        } else {
            this.setState({
                studentSearchTerm: event,
                searchError: true,
                selectedOption: "",
            });
        }
    };

    clearStudentFilter = () => {
        this.setState({
            selectedStudent: "",
            assessment_grade_id: "",
            level_definition: "",
        });
    };

    render() {
        const {
            isLoading,
            isTableDataLoading,
            credit_packages,
            credits,
            creditsPrivilege,
            isDropHidden,
            studentlist,
            selectedStudent,
            selectedStudentGrade,
            userType,
            grades,
            customer,
            assessment_grade_id,
            gradeMapAvailable,
        } = this.state;

        this.validator.purgeFields();

        if (!creditsPrivilege) {
            return (
                <div>
                    <h4>Privilege not found for this section</h4>
                </div>
            );
        }
        return (
            <>
                {isLoading ? (
                    <div className="spinner-container">
                        <CircularProgress />
                    </div>
                ) : (
                    <Card>
                        {userType === "parent" && (
                            <>
                                <CardHeader>
                                    {isDropHidden && !isLoading && !isTableDataLoading && (
                                        <div className="col-md-12 d-flex justify-content-start align-items-center px-0">
                                            {/* <div className="dropdown mb-2 px-0 col-md-4"> */}
                                            <div className="row d-flex align-items-end" style={{ width: '100%' }}>
                                                <div className="col-md-4 col-sm-12">
                                                    <Form.Group
                                                        as={Col}
                                                        md="12"
                                                        controlId="exampleForm.ControlInput3"
                                                        className="px-0"
                                                    >
                                                        <>
                                                            {!selectedStudent ? (
                                                                <h3 className="thead">
                                                                    Please select the student
                                                                </h3>
                                                            ) : (
                                                                <h3 className="thead">
                                                                    Student
                                                                </h3>
                                                            )}

                                                            <Form.Select
                                                                aria-label="Select the student"
                                                                name="student_id"
                                                                value={selectedStudent}
                                                                onChange={(event) =>
                                                                    this.handleStudentSelect(event)
                                                                }
                                                            >
                                                                <option
                                                                    value=""
                                                                    disabled
                                                                    style={{ fontFamily: "Poppins, sans-serif" }}
                                                                >
                                                                    All Students
                                                                </option>
                                                                {studentlist.map((student) => (
                                                                    <option
                                                                        key={student.user_id}
                                                                        value={student.user_id}
                                                                    >
                                                                        {student.data_concat}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-4 col-sm-12 mt-1">
                                                    <Form.Group
                                                        controlId="exampleForm.ControlInput1"
                                                    >
                                                        <Form.Label>Select the grade</Form.Label>
                                                        <Form.Select
                                                            aria-label="Default select example"
                                                            name="assessment_grade_id"
                                                            onChange={this.handleChange}
                                                            value={
                                                                assessment_grade_id
                                                                    ? assessment_grade_id
                                                                    : userType === "parent" && selectedStudent
                                                                        ? selectedStudentGrade
                                                                            ? selectedStudentGrade
                                                                            : ""
                                                                        : userType === "student" && gradeMapAvailable
                                                                            ? sessionStorage.getItem("studentGrade")
                                                                            : ""
                                                            }
                                                            disabled={userType === "parent" && !selectedStudent}
                                                        >
                                                            {grades.length === 0 ? (
                                                                <option value="" disabled>Loading...</option>
                                                            ) : (
                                                                <>
                                                                    <option value="" disabled>Select</option>
                                                                    {grades.map((grade) => (
                                                                        <option
                                                                            value={grade.grade_id}
                                                                            key={grade.grade_id}
                                                                        >
                                                                            {grade.grade}
                                                                        </option>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-md-2 col-sm-12">
                                                    {selectedStudent && (
                                                        <Button
                                                            variant="secondary"
                                                            onClick={this.clearStudentFilter}
                                                        >
                                                            Clear
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </CardHeader>
                                {selectedStudent && (
                                    <>
                                        {credit_packages.length > 0 ? (
                                            <>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className="row" style={{ width: '88vw' }}>
                                                        {credit_packages.map((pkg, index) => (
                                                            <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 px-5 py-2" key={index}>
                                                                <PackageCard pkg={pkg} selectedStudent={selectedStudent} studentlist={studentlist} customer={customer} />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="col-md-12 col-sm-12">
                                                <div style={{ paddingTop: '16px', borderRadius: '24px', boxShadow: '0px 0px 4px 4px #b9b9b9' }}>
                                                    <h4 style={{ display: 'flex', justifyContent: 'center' }}>Seems like there are still no any credit package available for this</h4>
                                                    <p style={{ display: 'flex', justifyContent: 'center' }}><img src={no_credit_packs} style={{ height: '50vh' }} /></p>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {userType === "student" && (
                            <><div className="col-md-4 col-sm-12 mt-1 mb-2">
                                <div style={{ marginLeft: '24px', marginRight: '24px', marginTop: '16px' }}>
                                    <Form.Label>Select the grade</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name="assessment_grade_id"
                                        onChange={this.handleChange}
                                        value={
                                            assessment_grade_id
                                                ? assessment_grade_id
                                                : userType === "parent" && selectedStudent
                                                    ? credits.length > 0
                                                        ? credits[0].grade
                                                        : ""
                                                    : userType === "student" && gradeMapAvailable
                                                        ? sessionStorage.getItem("studentGrade")
                                                        : ""
                                        }
                                        disabled={userType === "parent" && !selectedStudent}
                                    >
                                        {grades.length === 0 ? (
                                            <option value="" disabled>Loading...</option>
                                        ) : (
                                            <>
                                                <option value="" disabled>Select</option>
                                                {grades.map((grade) => (
                                                    <option
                                                        value={grade.grade_id}
                                                        key={grade.grade_id}
                                                    >
                                                        {grade.grade}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </Form.Select>
                                </div>
                            </div>
                                <div className="d-flex justify-content-center">
                                    <CardHeader>
                                        {credit_packages.length > 0 ? (
                                            <div className="row" style={{ width: '88vw' }}>
                                                {credit_packages.map((pkg, index) => (
                                                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12 px-5 py-2" key={index}>
                                                        <PackageCard pkg={pkg} selectedStudent={selectedStudent} studentlist={studentlist} customer={customer} />
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="col-md-12 col-sm-12">
                                                <div style={{ paddingTop: '16px', borderRadius: '24px', boxShadow: '0px 0px 4px 4px #b9b9b9' }}>
                                                    <h4 style={{ display: 'flex', justifyContent: 'center' }}>Seems like there are still no any credit package available for this</h4>
                                                    <p style={{ display: 'flex', justifyContent: 'center' }}><img src={no_credit_packs} style={{ height: '50vh' }} /></p>
                                                </div>
                                            </div>
                                        )}
                                    </CardHeader>
                                </div>
                            </>
                        )}
                    </Card>
                )}
            </>
        )
    }
}
