import _ from "underscore";
import { assessmentTypes } from "../../reducers/types";
import {store} from "../../helper/redux_store";
import { questionService } from "../../service/assessment/questionService";
import { candidateService } from "../../service/candidate/candidateService";

export const assessorActions = {
  getCandidateAnswers,
  getAllCandidateAnswerContents,
  onSetMarks,
  saveMarks,
  finalizeGrade,
  finalizeAll,
  getRemarks
};

function getCandidateAnswers(candidate) {
  return async (dispatch) => {
    try {
      dispatch(get_candidate_answers(candidate));
      dispatch(get_candidate_answers_request());
      var answers = await questionService.getCandidateAnswers(
        candidate.candidate_id
      );
      dispatch(get_candidate_answers_success(answers.data));
    } catch (error) {
      dispatch(get_candidate_answers_failure(error));
    }
  };

  function get_candidate_answers(candidate) {
    return { type: assessmentTypes.GET_CANDIDATE_ANSWERS, candidate };
  }

  function get_candidate_answers_request() {
    return { type: assessmentTypes.GET_CANDIDATE_ANSWERS_REQUEST };
  }

  function get_candidate_answers_success(content) {
    return { type: assessmentTypes.GET_CANDIDATE_ANSWERS_SUCCESS, content };
  }

  function get_candidate_answers_failure(error) {
    return { type: assessmentTypes.GET_CANDIDATE_ANSWERS_FAILURE, error };
  }
}

function finalizeGrade(assessment) {
  return async (dispatch) => {
    dispatch(finalize_grade());

    var data = new Object();
    Object.assign(data, assessment);

    let content = {
      candidate_id: data.candidate_id,
      user_role_id: data.user_role_id,
      final_grade: "",
      attempt_no:data.attempt_no,
    };

    if (data.final_grade == null) {
      content["final_grade"] = "grade";
      data["final_grade"] = null;
    }

    dispatch(request_finalize());

    try {
      var message = await candidateService.finalizeGrade(content);
      var messageData = message.data
      dispatch(success_finalize({ data, messageData }));
    } catch (error) {
      dispatch(failure_finalize(error));
    }
  };

  function finalize_grade() {
    return { type: assessmentTypes.FINALIZE_GRADE };
  }

  function request_finalize() {
    return { type: assessmentTypes.FINALIZE_GRADE_REQUEST };
  }

  function success_finalize(candidate) {
    return { type: assessmentTypes.FINALIZE_GRADE_SUCCESS, candidate };
  }

  function failure_finalize(error) {
    return { type: assessmentTypes.FINALIZE_GRADE_FAILURE, error };
  }
}

function finalizeAll(assessment) {
  return async (dispatch) => {
    dispatch(finalize_all());

    var data = new Object();
    Object.assign(data, assessment);

    let content = {
      candidates: data.candidates,
      assessment_id: data.assessment_id,
      user_role_id: data.user_role_id,
      final_grade: "grade",
    };

    dispatch(request_finalize_all());

    try {
      var message = await candidateService.finalizeAllGrades(content);
      var messageData = message.data
      dispatch(success_finalize_all(messageData));
    } catch (error) {
      dispatch(failure_finalize_all(error));
    }
  };

  function finalize_all() {
    return { type: assessmentTypes.FINALIZE_ALL };
  }

  function request_finalize_all() {
    return { type: assessmentTypes.FINALIZE_ALL_REQUEST };
  }

  function success_finalize_all(message) {
    return { type: assessmentTypes.FINALIZE_ALL_SUCCESS, message };
  }

  function failure_finalize_all(error) {
    return { type: assessmentTypes.FINALIZE_ALL_FAILURE, error };
  }
}

function getAllCandidateAnswerContents(obj) {
  return async (dispatch) => {
    dispatch(get_all_candidate_answer_contents());
    dispatch(get_all_candidate_answer_contents_request());

    try {
      let answer = await questionService.getAllCandidateAnswerContents(obj);
      var candidate_answers =
        store.getState()["gradeReducer"]["candidateAnswers"];
      dispatch(
        get_all_candidate_answer_contents_success({ answer, candidate_answers })
      );
    } catch (error) {
      dispatch(get_all_candidate_answer_contents_failure(error.toString()));
    }
  };

  function get_all_candidate_answer_contents() {
    return { type: assessmentTypes.GET_ALL_CANDIDATE_ANSWER_CONTENTS };
  }

  function get_all_candidate_answer_contents_request() {
    return { type: assessmentTypes.GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST };
  }

  function get_all_candidate_answer_contents_success(answers) {
    return {
      type: assessmentTypes.GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS,
      answers,
    };
  }

  function get_all_candidate_answer_contents_failure(error) {
    return {
      type: assessmentTypes.GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE,
      error,
    };
  }
}

function getRemarks(key) {
  return async (dispatch) => {
    dispatch(get_assessor_remarks_request());

    try {
      let remarks = await candidateService.getRemarks(key);
      dispatch(get_assessor_remarks_success(remarks));
    } catch (error) {
      dispatch(get_assessor_remarks_failure(error.toString()));
    }
  };

  function get_assessor_remarks_request() {
    return { type: assessmentTypes.GET_ASSESSOR_REMARKS_REQUEST };
  }

  function get_assessor_remarks_success(remarks) {
    return { type: assessmentTypes.GET_ASSESSOR_REMARKS_SUCCESS, remarks };
  }

  function get_assessor_remarks_failure(error) {
    return { type: assessmentTypes.GET_ASSESSOR_REMARKS_FAILURE, error };
  }
}

function onSetMarks(obj) {
  return async (dispatch) => {
    try {
      var candidate_answers =
        store.getState()["gradeReducer"]["candidateAnswerMap"];
      candidate_answers[obj.id]["mark_obtained"] = obj.marks;
      candidate_answers[obj.id]["graded_by"] = obj.assessor_id;
      dispatch(set_marks(candidate_answers));
    } catch (error) {
      // dispatch(get_all_candidate_answer_contents_failure(error.toString()));
    }
  };

  function set_marks(answers) {
    return { type: assessmentTypes.SET_MARKS, answers };
  }
}

function saveMarks(data) {
  return async (dispatch) => {
    dispatch(save_marks_request());

    try {
      var arr = [];
      for (const [key, value] of Object.entries(data.questions)) {
        arr.push(value);
      }
      data.questions = arr;
      var answers = await questionService.saveMarks(data);
      dispatch(save_marks_success(answers.data));
    } catch (error) {
      dispatch(save_marks_failure(error));
    }
  };

  function save_marks_request() {
    return { type: assessmentTypes.SAVE_MARKS_REQUEST };
  }

  function save_marks_success(data) {
    return { type: assessmentTypes.SAVE_MARKS_SUCCESS, data };
  }

  function save_marks_failure(error) {
    return { type: assessmentTypes.SAVE_MARKS_FAILURE, error };
  }
}
