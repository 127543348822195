import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { routes } from "../../helper/routes";
import _ from "lodash";
import SelfGradeModal from "../../components/common/modal/SelfGradeModal";
import ModalHeader from "../../components/common/modal/ModalHeader";
import ModalBody from "../../components/common/modal/ModalBody";
import { questionMCQAnswerTypeConstants, questionTypeConstants } from "../../helper/constants";
import { AzureStorage } from "../../helper/azureStorage";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";
import AdminTemplate from "../../components/admin/layouts/Template";
import ContentWrapper from "../../components/common/AssessmentContentWrapper";
import Spinner from "../../components/common/Spinner";
import Card from "../../components/common/Card";
import CardHeader from "../../components/common/CardHeader";
import CardBody from "../../components/common/CardBody";
import CardTitle from "../../components/common/AssessmentCardTitle";
import CardToolbar from '../../components/common/QuestionCardToolBar.js';
import Breadcrumb from "../../components/common/Breadcrumb";
import BreadcrumbItem from "../../components/common/BreadcrumbItem";
import Button from "../../components/common/form/Button";
import CheckBox from "../../components/common/form/CheckBox";
import CheckBoxInline from "../../components/common/form/CheckBoxInline";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Marks from "../../components/common/tabs/Marks";
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import ViewEditor from "../../components/common/editor/ViewEditor";
import { questionActions } from "../../actions/assessment/questionActions";
import { assessmentActions } from "../../actions/assessment/assessmentActions";
import { messageTypes } from "../../reducers/types";
import { staffUserActions } from "../../actions/user_management/staffUserActions.js";
import { manageUserActions } from "../../actions/user_management/manageUserActions.js";
import { libraryActions } from "../../actions/question/libraryActions";
import { createActions as questionCreateActions } from "../../actions/question/createActions";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { customerConstants } from "../../helper/constants.js";
import ReactSpinner from "react-bootstrap/Spinner";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '../../components/common/form/FormGroup.js';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Form from "react-bootstrap/Form";
import { ASSESSMENT_QUESTION_FILTERS } from '../../helper/constants.js';
import TabContent from '../../components/common/tabs/TabContent.js';
import TabPane from '../../components/common/tabs/TabPane.js';
import MaterialCustomTablePagination from "../../components/common/MaterialCustomTablePagination.js";
import { candidateService } from "../../service/settings/candidate/candidateService.js";
import RadioInline from '../../components/common/form/RadioInline.js';
import Radio from '../../components/common/form/Radio.js';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    // cancelClass = "cmc-btn-default"
    // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class Finalize extends React.Component {
    constructor(props) {
        super(props);

        this.ref_tagify = React.createRef();
        this.state = {
        assessment_title: "",
        auto_shuffle: false,
        assessmentQuestionsData: [],
        assessmentAllQuestionsData: [],
        questions_contents_by_type: {},
        print_questions: [],
        to_delete: [],
        total: "",
        errors: {},
        mark_allocation: {},
        position_list: {}, //state for getting positions
        changes: false,
        get_content: true,
        add_question: false,
        searchValue: "",
        selectedCategory: 0,
        selectedType: 0,
        selectedDifficulty: 0,
        allMarks: "",
        chkbox: false,
        deletion: false,
        filteredQuestions: [],
        selectedQuestions: [],
        question_added: false,
        teacherDetailsMap: {},
        disabled: false,
        isStudentViewLoading: false,
        attachment: '',
        isViewModalLoading: false,
        states: '',
        studentView: false,
        limit: 100,
        offset: 0,
        search_term: "",
        currentPage: 0,
        sort_column: "created_at",
        sort_direction: "DESC",
        rowsPerPage: 100,
        isTableDataLoading: true,
        count: 10,
        filters: [],
        selectedFilters: [],
        selectedGrades: null,
        selectedChapters: null,
        selectedsubjects: null,
        selectedAuthors: null,
        selectedYears: null,
        selectedCategories: null,
        activeTab: 1,
        selectedTerms: null,
        selectedSources: null,
        selectedTeachers: null,
        checked: false,
        selectedTypes: null,
        selectedDifficulties: null,
        selectedLanguages: null,
        assessment:"",
        teachers: []
    };

        this.onSave = this.onSave.bind(this);
        this.finalizeAssessment = this.finalizeAssessment.bind(this);
        this.setForReviewAssessment = this.setForReviewAssessment.bind(this);
        this.makeReadyAssessment = this.makeReadyAssessment.bind(this);
        this.publishAssessment = this.publishAssessment.bind(this);
        this.retireAssessment = this.retireAssessment.bind(this);
        this.makeEditableAssessment = this.makeEditableAssessment.bind(this);
        this.orderQuestions = this.orderQuestions.bind(this)
        this.handleSelectQuestion = this.handleSelectQuestion.bind(this);
        this.onViewFinalizeQuestion = this.onViewFinalizeQuestion.bind(this);
    }

    async componentDidMount() {
        if (window.KTSelect2) window.KTSelect2.init();
        if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
        if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
        if (window.KTApp.initComponents) window.KTApp.initComponents();
        if (window.$) window.$(".selectpicker").selectpicker();
        if (typeof window.KTLayoutStickyCard !== "undefined") {
        window.KTLayoutStickyCard.update();
        } else {
        if (window.KTLayoutStickyCard)
            window.KTLayoutStickyCard.init("kt_page_sticky_card");
        }
        
        const assessment = JSON.parse(sessionStorage.getItem("setup_assessment"));

        this.setState({ assessment : assessment }, async () => {
            await this.fetchAllAssessmentQuestions();
            this.setState({ assessmentAllQuestionsData: this.props?.assessmentFilteredQuestions });
        });

        await this.props.assessment;

        this.setState({ assessment_title: this.state.assessment.title });
        
        let disabled
        let ques = await this.props?.assessmentFilteredQuestions.filter(val => val?.sub_questions === null)
        for (let k = 0; k <= ques.length - 1; k++) {
        if (ques[k]?.mark_allocation === 0) {
            disabled = true
        }
        }

        this.setState({ assessmentQuestionsData: this.props?.assessmentFilteredQuestions, disabled: disabled });

        this.onDatatableActionEvent();
    }

    async componentDidUpdate() {
        if (
            this.state.assessmentQuestionsData &&
            this.state.assessmentQuestionsData.length &&
            !this.state.assessmentQuestionsData.length &&
            !this.state.deletion
        ) {
            var question_list = this.props?.assessmentFilteredQuestions, i;

            for (i = 0; i < question_list.length; i++) {
                question_list[i]["question_number"] = i + 1; //adding "question_number" as prperty for each questions
            }
            this.setState({ assessmentQuestionsData: question_list });
            this.setState({ print_questions: question_list });
        }

        if (
            this.props?.assessmentFilteredQuestions &&
            this.props?.assessmentFilteredQuestions.length > 0 &&
            !this.props.question
        ) {
            await this.props.getQuestionContent(this.props.sharedQuestions[0]);
        }

        if (
            this.props?.assessmentFilteredQuestions &&
            this.props?.assessmentFilteredQuestions.length &&
            this.state.get_content
        ) 
        {
            this.setState({ get_content: false });
            await this.props.getAllQuestionContents({
                questions: this.props?.assessmentFilteredQuestions,
            });
        }

        let disabled
        let ques = await this.props?.assessmentFilteredQuestions.filter(val => val?.sub_questions === null)
        for (let k = 0; k <= ques.length - 1; k++) {
            if (ques[k]?.mark_allocation === 0) {
                disabled = true
            }
        }

        if (
            this.state.assessmentQuestionsData &&
            this.state.assessmentQuestionsData.length &&
            _.isEqual({}, this.state.mark_allocation)
        ) 
        {
        
            var list = {},
            pos = {};
            var i,
            total = 0;

            for (i = 0; i < this.state.assessmentQuestionsData.length; i++) {
                var question_id = this.state.assessmentQuestionsData[i].question_id; //getting each question_id

                pos[question_id] = "";
                if (this.state.assessmentQuestionsData[i].mark_allocation) {
                list[question_id] = this.props?.assessmentFilteredQuestions[i].mark_allocation;
                total = total + this.props?.assessmentFilteredQuestions[i].mark_allocation;
                } else {
                list[question_id] = "";
                }
            }

            this.setState({
                mark_allocation: list,
                total: total,
                position_list: pos, //taking the positions list of above quesitons
                disabled: disabled
            });
        }
        window.$("#finalize-tab").children().prop("disabled", false);
        window.$(".selectPicker").selectpicker("refresh");
        window.$("#question-difficulty").selectpicker("refresh");
        window.$("#question-type").selectpicker("refresh");
        window.$("#question-category").selectpicker("refresh");
    }

    onDatatableActionEvent() {
        window.$(document).on('click', '.view-question-btn', (e) => {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.onViewFinalizeQuestion(this.state.assessmentQuestionsData[idx])
            window.$('#view-details').modal('show')
        })
    }

    fetchAllAssessmentQuestions = async () => {
        const {
            assessment,
            limit,
            offset,
            sort_column,
            sort_direction,
            search_term,
            selectedCategories,
            selectedAuthors,
            selectedChapters,
            selectedGrades,
            selectedDifficulties,
            selectedLanguages,
            selectedTypes,
            selectedTerms,
            selectedYears,
            selectedsubjects,
            selectedSources,
            selectedTeachers
        } = this.state;
        this.setState({ isTableDataLoading: true });
        await this.props.getAllAssessmentQuestions(assessment.assessment_id, limit, offset, sort_column, sort_direction, search_term, selectedCategories, selectedAuthors, selectedChapters, selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources, selectedTeachers);
        this.setState({ assessmentQuestionsData: this.props?.assessmentFilteredQuestions });
        this.setState({ teachers: this.props?.teachers });
        this.setState({ limit: this.state.rowsPerPage });
        this.orderQuestions();
        await this.props.getQuestionMetaData();
        await this.props.getAllQuestions();
        await this.props.getAllLibraryQuestions();
        await this.props.getAllQuestionContentsByType({
            questions: this.props?.assessmentFilteredQuestions,
        });
        this.setState({ isTableDataLoading: false });
        // this.setState({ isTableDataLoading: false });
    };

    // checking whether all questions are MCQS or not
    checkingForAllMcqs() {
        var AllMcqs = true;
        
        var question_list = this.state.assessmentQuestionsData,
            i;
        for (i = 0; i < this.state.assessmentQuestionsData.length; i++) {
            if (question_list[i]["question_type"] !== "MCQ") {
            AllMcqs = false;
            }
        }

        return AllMcqs;
    }

    async onViewFinalizeQuestion(element, e) {
        
        this.setState({
        editorQuestionState: '',
        editorAnswerStates: '',
        editorAnswerStateDescriptive: ''
        })

        let tempAnswerStates = [];
        let tempAnswer = [];
        this.setState({ isViewModalLoading: true });

        await this.props.getLibraryQuestionContent(element);

        if(this.props?.libraryQuestionContent){
        let states = await JSON.parse(this.props?.libraryQuestionContent);
        this.setState({states: states});
        let tempQuestionState = EditorState.createWithContent(convertFromRaw(states[0]));
        this.setState({ editorQuestionState: tempQuestionState });
        }

        if (element?.question_type === questionTypeConstants.DESCRIPTIVE.name) {

        await this.props.getLibraryAnswerContent(element);
        let states = await JSON.parse(this.props?.libraryAnswerContent);
        let Ans = EditorState.createWithContent(convertFromRaw(states[0]))
        this.setState({ editorAnswerStateDescriptive: Ans })

        } 
        
        else if (element?.question_type === questionTypeConstants.SUBQUESTION.name && element.is_subquestion === "true") {

        await this.props.getLibraryAnswerContent(element);
        let states = await JSON.parse(this.props?.libraryAnswerContent);
        let Ans = EditorState.createWithContent(convertFromRaw(states[0]))
        this.setState({ editorAnswerStateDescriptive: Ans })

        }

        else if (element?.question_type === questionTypeConstants.MCQ.name) {
            if(this.state.states){
                this.state.states.shift();
                this.state.states.forEach(state => {
                    tempAnswerStates.push(EditorState.createWithContent(convertFromRaw(state)))
                });
            }
            if(this.props.libraryQuestion.answer){
                this.props.libraryQuestion.answer.split(',').map((answer) => {
                    tempAnswer.push(parseInt(answer) > 0);
                });
                this.setState({
                    editorAnswerStates: tempAnswerStates,
                    selectedChoices: tempAnswer
                })
            }
        } 
        
        else if (element?.question_type === questionTypeConstants.SUBMISSION.name) {
        await this.props.getLibraryAnswerContent(element);
        let attachmentName = this.props?.libraryAnswerContent.replace(/^\[(.+)\]$/, "$1").replace(/"\"$/, "$1").split(',');
        this.setState({
            attachment: attachmentName
        });
        }

        this.setState({ isViewModalLoading: false });
    }

    orderQuestions() {
        var question_list = this.state.assessmentQuestionsData,
            i;
        for (i = 0; i < this.state.assessmentQuestionsData.length; i++) {
            question_list[i]["question_number"] = i + 1;
        }
        this.setState({
            assessmentQuestionsData: question_list,
        });
    }

    async onChangeMarks(e, el, i) {
        var marks = this.state.mark_allocation;
        marks[el.question_id] = e.target.value;
        this.setState({ mark_allocation: marks });
    }

    async handleSelectQuestion(e, el) {
        // select multiple questions from list of questions in the assessment
        var Squestions = this.state.selectedQuestions;
        if (e.target.checked) {
            Squestions.push(e.target.value);
            if (el?.sub_questions !== null) {
                let subs = el?.sub_questions.split(",")
                for (let i = 0; i <= subs?.length - 1; i++) {
                    Squestions.push(subs[i])
                }
            }
        } else {
            var index = Squestions.indexOf(e.target.value);
            if (index !== -1) {
                Squestions.splice(index, 1);
            }
            if (el?.sub_questions !== null) {
                let subs = el?.sub_questions.split(",")
                for (let i = 0; i <= subs?.length - 1; i++) {
                    var index = Squestions.indexOf(subs[i]);
                    if (index !== -1) {
                        Squestions.splice(index, 1);
                    }
                }
            }
        }
        this.setState({
            selectedQuestions: Squestions,
        });
    }

    handleSelectMyQuestions = (e) => {
        const isChecked = e.target.checked;
        this.setState(
          {
            checked: isChecked,  
            selectedTeachers: isChecked ? sessionStorage.getItem("user_id") : null  
          },
          () => {
            this.fetchAllAssessmentQuestions();  
          }
        );
      };

    async onDelete(e, el) {
        // Delete one selected question from an assessment
        this.setState({
            deletion: true,
        });

        await window.Swal.fire({
            text: "You will loose the current marks/position allocation. Do you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: !1,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
            confirmButton: `btn font-weight-bold ${cancelClass}`,
            cancelButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
            }
            }
        }).then(async (action) => {
            if (action.isConfirmed) {
                var assessmentQuestionsData = this.state.assessmentQuestionsData;
                var questions = this.state.to_delete;
                var marks = this.state.mark_allocation;
                var pos = this.state.position_list;
                if (el?.sub_questions !== null) {
                    var ind = assessmentQuestionsData.indexOf(el);

                    questions.push(el.question_id);

                    if (index !== -1) {
                        assessmentQuestionsData.splice(ind, 1);
                    }

                    delete pos[el.question_id];
                    delete marks[el.question_id];
                    let subs = el?.sub_questions.split(",")
                
                    for (let i = 0; i <= subs.length - 1; i++) {

                        var index = assessmentQuestionsData.findIndex((val) => (val?.question_id === parseInt(subs[i])))
                        var total = this.state.total - marks[parseInt(subs[i])];

                        questions.push(parseInt(subs[i]));

                        if (index !== -1) {
                            assessmentQuestionsData.splice(index, 1);
                        }

                        delete pos[parseInt(subs[i])];
                        delete marks[parseInt(subs[i])];
                        this.setState({ total: total })
                    }

                    this.props.getAllQuestionContents({ questions: assessmentQuestionsData });

                    this.setState({
                        to_delete: questions,
                        assessmentQuestionsData: assessmentQuestionsData,
                        mark_allocation: marks,
                        position_list: pos,
                        changes: true,
                        total: total,
                    });

                    this.orderQuestions();
                } 
                
                else {
                    var index = assessmentQuestionsData.indexOf(el);
                    var total = this.state.total - marks[el.question_id];

                    questions.push(el.question_id);

                    if (index !== -1) {
                        assessmentQuestionsData.splice(index, 1);
                    }

                    delete pos[el.question_id];
                    delete marks[el.question_id];

                    this.props.getAllQuestionContents({ questions: assessmentQuestionsData });

                    this.setState({
                        to_delete: questions,
                        assessmentQuestionsData: assessmentQuestionsData,
                        mark_allocation: marks,
                        position_list: pos,
                        changes: true,
                        total: total,
                    });

                    this.orderQuestions();
                }
            }
        });
    }

    async handleDeleteSelectedQuestions() {
        // Delete selected multiple questions
        if (this.state.selectedQuestions.length === 0) {
            await window.Swal.fire({
                text: "No questions selected.",
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
            return;
        }
        this.setState({
            deletion: true,
        });

        await window.Swal.fire({
            text: "You will lose the current marks/position allocation. Do you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: !1,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
            confirmButton: `btn font-weight-bold ${cancelClass}`,
            cancelButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
            }
            }
        }).then(async (action) => {
            if (action.isConfirmed) {
            var questions = this.state.selectedQuestions;
            this.setState({ to_delete: questions });
            var assessmentQuestionsData = this.state.assessmentQuestionsData.slice();

            var marks = { ...this.state.mark_allocation }; // create a copy of the object
            var pos = { ...this.state.position_list }; // create a copy of the object
            var total = this.state.total;
            questions.forEach((question_id) => {
                let index = -1;
                assessmentQuestionsData.forEach((question, i) => {
                if (question.question_id == question_id) {
                    index = i;
                    if (index !== -1) {
                    assessmentQuestionsData.splice(index, 1);
                    total -= marks[question_id];
                    delete marks[question_id];
                    delete pos[question_id];
                    }
                }
                });
            });

            this.setState({
                to_delete: questions,
                assessmentQuestionsData: assessmentQuestionsData,
                mark_allocation: marks,
                position_list: pos,
                changes: true,
                total: total,
                selectedQuestions: [],
            });
            this.orderQuestions();
            await this.onSave();
            }
        });
    }

    async onSave() {
        const assessment_id = this.state.assessment.assessment_id;
        const assessor_id = this.state.assessment.user_role_id;
        const user_id = sessionStorage.getItem("user_id");
        var delete_questions = this.state.to_delete;
        const user_role_id = this.state.assessment.user_role_id;

        var questions = this.state.assessmentQuestionsData;

        window.Swal.showLoading();
        if (delete_questions.length) {
        await this.props.removeMyQuestions({
            assessment_id,
            delete_questions,
            assessor_id,
            user_id,
        });
        if (this.props.updateError != "") {
            window.Swal.fire({
                title: messageTypes.FAILED_WARNING,
                text: this.props.updateError,
                icon: messageTypes.ERROR,
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        } else {
            await this.props.saveUpdates({
                assessment_id,
                questions,
                user_role_id,
            });

            if (this.props.updateError == "") {
            this.setState({
                to_delete: [],
            });
            window.Swal.fire({
                title: messageTypes.SUCCESS_WARNING,
                text: "Saved changes successfully!",
                icon: messageTypes.SUCCESS,
                customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.fetchAllAssessmentQuestions();
                }
            });
            } else {
            window.Swal.fire({
                itle: messageTypes.FAILED_WARNING,
                text: this.props.updateError,
                icon: messageTypes.ERROR,
                customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
            }
        }
        } else {
        await this.props.saveUpdates({ assessment_id, questions, user_role_id });
        if (this.props.updateError == "") {
            window.Swal.fire({
                title: messageTypes.SUCCESS_WARNING,
                text: "Saved changes successfully!",
                icon: messageTypes.SUCCESS,
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.fetchAllAssessmentQuestions();
                }
            });
            let disabled
            let ques = await this.props?.assessmentFilteredQuestions.filter(val => val?.sub_questions === null)
            for (let k = 0; k <= ques.length - 1; k++) {
            if (ques[k]?.mark_allocation === 0) {
                disabled = true
            }
            }
            this.setState({ disabled: disabled })
        } else {
            window.Swal.fire({
                title: messageTypes.FAILED_WARNING,
                text: this.props.updateError,
                icon: messageTypes.ERROR,
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        }
        this.setState({
            changes: false,
        });
    }

    async finalizeAssessment() {
        window.Swal.showLoading();
        await this.props.finalizeAssessment(this.state.assessment);
        if (this.props.finalizeError == "") {
            this.state.assessment["assessment_state"] = "Ready";
            sessionStorage.setItem("setup_assessment", JSON.stringify(this.state.assessment));
            window.Swal.fire({
                title: messageTypes.SUCCESS_WARNING,
                text: this.props.finalizeSuccess,
                icon: messageTypes.SUCCESS,
                customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    const assessment = JSON.parse(sessionStorage.getItem("setup_assessment"));
                    this.setState({ assessment : assessment });
                    this.fetchAllAssessmentQuestions();
                }
            });
        } 
        
        else {
            window.Swal.fire({
                title: messageTypes.FAILED_WARNING,
                text: this.props.finalizeError,
                icon: messageTypes.ERROR,
                customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
    }

    async setForReviewAssessment() {
        await window.Swal.fire({
            text: "Assessment will be set under review! Do you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: !1,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
            confirmButton: `btn font-weight-bold ${cancelClass}`,
            cancelButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
            }
            }
        }).then(async (action) => {
            if (action.isConfirmed) {
                window.Swal.showLoading();
                let user_id = sessionStorage.getItem("manage_user_id");
                await this.props.getTeacherMe(user_id);
                const assessmentItem = {
                    paper_list: [parseInt(this.state.assessment?.assessment_id)],
                    category_id: null,
                    grade_id: null,
                    language_id: null,
                    chapter_id: null,
                    subject_id: null,
                    difficulty_id: null,
                    author_id: null,
                    year_id: null,
                    term_id: null,
                    privilege_id: null,
                    status_id: 3,
                    assessment_state: "Review",
                    user_id: user_id,
                    first_name: this.props.teacher[0]?.first_name,
                    last_name: this.props.teacher[0]?.last_name
                };
                let response = await candidateService.updateAllPapers(assessmentItem);
                if (response.success) {
                    this.state.assessment["assessment_state"] = "Review";
                    sessionStorage.setItem("setup_assessment", JSON.stringify(this.state.assessment));
                    window.Swal.fire({
                        title: messageTypes.SUCCESS_WARNING,
                        text: "Assessment Set for Review Successfully!",
                        icon: messageTypes.SUCCESS,
                        customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const assessment = JSON.parse(sessionStorage.getItem("setup_assessment"));
                            this.setState({ assessment : assessment });
                            this.fetchAllAssessmentQuestions();
                        }
                    });
                } 
            
            else {
                window.Swal.fire({
                    title: messageTypes.FAILED_WARNING,
                    text: this.props.finalizeError,
                    icon: messageTypes.ERROR,
                    customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                });
            }
        }
    });
    }

    async makeReadyAssessment() {
        await window.Swal.fire({
            text: "Assessment will be reset to ready! Do you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: !1,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
            confirmButton: `btn font-weight-bold ${cancelClass}`,
            cancelButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
            }
            }
        }).then(async (action) => {
            if (action.isConfirmed) {
                window.Swal.showLoading();
                let user_id = sessionStorage.getItem("manage_user_id");
                await this.props.getTeacherMe(user_id);
                const assessmentItem = {
                    paper_list: [parseInt(this.state.assessment?.assessment_id)],
                    category_id: null,
                    grade_id: null,
                    language_id: null,
                    chapter_id: null,
                    subject_id: null,
                    difficulty_id: null,
                    author_id: null,
                    year_id: null,
                    term_id: null,
                    privilege_id: null,
                    status_id: 2,
                    assessment_state: "Ready",
                    user_id: user_id,
                    first_name: this.props.teacher[0]?.first_name,
                    last_name: this.props.teacher[0]?.last_name
                };
                let response = await candidateService.updateAllPapers(assessmentItem);
                if (response.success) {
                    this.state.assessment["assessment_state"] = "Ready";
                    sessionStorage.setItem("setup_assessment", JSON.stringify(this.state.assessment));
                    window.Swal.fire({
                        title: messageTypes.SUCCESS_WARNING,
                        text: "Assessment made ready successfully!",
                        icon: messageTypes.SUCCESS,
                        customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const assessment = JSON.parse(sessionStorage.getItem("setup_assessment"));
                            this.setState({ assessment : assessment });
                            this.fetchAllAssessmentQuestions();
                        }
                    });
                } 
            
            else {
                window.Swal.fire({
                    title: messageTypes.FAILED_WARNING,
                    text: this.props.finalizeError,
                    icon: messageTypes.ERROR,
                    customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                });
            }
        }
    });
    }

    async publishAssessment() {
        await window.Swal.fire({
            text: "Assessment will go live for students and You will not be able to make any modifications! Do you want to publish?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: !1,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
            confirmButton: `btn font-weight-bold ${cancelClass}`,
            cancelButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
            }
            }
        }).then(async (action) => {
            if (action.isConfirmed) {
            window.Swal.showLoading();
            let user_id = sessionStorage.getItem("manage_user_id");
            await this.props.getTeacherMe(user_id);
            const assessmentItem = {
                paper_list: [parseInt(this.state.assessment?.assessment_id)],
                category_id: null,
                grade_id: null,
                language_id: null,
                chapter_id: null,
                subject_id: null,
                difficulty_id: null,
                author_id: null,
                year_id: null,
                term_id: null,
                privilege_id: null,
                status_id: 4,
                assessment_state: "Published",
                user_id: user_id,
                first_name: this.props.teacher[0]?.first_name,
                last_name: this.props.teacher[0]?.last_name
            };
            let response = await candidateService.updateAllPapers(assessmentItem);
            if (response.success) {
                this.state.assessment["assessment_state"] = "Published";
                sessionStorage.setItem("setup_assessment", JSON.stringify(this.state.assessment));
                window.Swal.fire({
                    title: messageTypes.SUCCESS_WARNING,
                    text: "Assessment Published Successfully!",
                    icon: messageTypes.SUCCESS,
                    customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        const assessment = JSON.parse(sessionStorage.getItem("setup_assessment"));
                        this.setState({ assessment : assessment });
                        this.fetchAllAssessmentQuestions();
                    }
                });
            } 
            
            else {
                window.Swal.fire({
                    title: messageTypes.FAILED_WARNING,
                    text: this.props.finalizeError,
                    icon: messageTypes.ERROR,
                    customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                });
            }
        }
    });
    }

    async retireAssessment() {
        await window.Swal.fire({
            text: "Assessment will be removed from live acccess by students! Do you want to retire?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: !1,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
            confirmButton: `btn font-weight-bold ${cancelClass}`,
            cancelButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
            }
            }
        }).then(async (action) => {
            if (action.isConfirmed) {
                window.Swal.showLoading();
                let user_id = sessionStorage.getItem("manage_user_id");
                await this.props.getTeacherMe(user_id);
                const assessmentItem = {
                    paper_list: [parseInt(this.state.assessment?.assessment_id)],
                    category_id: null,
                    grade_id: null,
                    language_id: null,
                    chapter_id: null,
                    subject_id: null,
                    difficulty_id: null,
                    author_id: null,
                    year_id: null,
                    term_id: null,
                    privilege_id: null,
                    status_id: 5,
                    assessment_state: "Retired",
                    user_id: user_id,
                    first_name: this.props.teacher[0]?.first_name,
                    last_name: this.props.teacher[0]?.last_name
                };
                let response = await candidateService.updateAllPapers(assessmentItem);
                if (response.success) {
                    this.state.assessment["assessment_state"] = "Retired";
                    sessionStorage.setItem("setup_assessment", JSON.stringify(this.state.assessment));
                    window.Swal.fire({
                        title: messageTypes.SUCCESS_WARNING,
                        text: "Assessment Retired Successfully!",
                        icon: messageTypes.SUCCESS,
                        customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            const assessment = JSON.parse(sessionStorage.getItem("setup_assessment"));
                            this.setState({ assessment : assessment });
                            this.fetchAllAssessmentQuestions();
                        }
                    });
                } 
            
            else {
                window.Swal.fire({
                    title: messageTypes.FAILED_WARNING,
                    text: response.message,
                    icon: messageTypes.ERROR,
                    customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                });
            }
        }
    });
    }

    async makeEditableAssessment() {
        window.Swal.showLoading();
        await this.props.finalizeAssessment(this.state.assessment);
        if (this.props.finalizeError == "") {
            this.state.assessment["assessment_state"] = "Initial";
            sessionStorage.setItem("setup_assessment", JSON.stringify(this.state.assessment));
            window.Swal.fire({
                title: messageTypes.SUCCESS_WARNING,
                text: this.props.finalizeSuccess,
                icon: messageTypes.SUCCESS,
                customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    const assessment = JSON.parse(sessionStorage.getItem("setup_assessment"));
                    this.setState({ assessment : assessment });
                    this.fetchAllAssessmentQuestions();
                }
            });
        } 
        else {
            window.Swal.fire({
                title: messageTypes.FAILED_WARNING,
                text: this.props.finalizeError,
                icon: messageTypes.ERROR,
                customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
    }

    onPositionChange() {
        var assessmentQuestionsNew = this.props?.assessmentFilteredQuestions;
        var questions = this.state.assessmentQuestionsData;
        var questionContent = this.props.questionContents;
        var position_list = this.state.position_list;
        var new_assQ = new Array(this.props?.assessmentFilteredQuestions.length).fill("");
        var new_q = new Array(this.props?.assessmentFilteredQuestions.length).fill("");
        var new_qcnt = new Array(this.props?.assessmentFilteredQuestions.length).fill("");

        var tmp_assQ = [],
        tmp_q = [],
        tmp_qcnt = [];

        var i = 0,
        k = 0;

        for (i = 0; i < this.state.assessmentQuestionsData.length; i++) {
        var question_id = this.state.assessmentQuestionsData[i]["question_id"];
        if (position_list[question_id] != "" && position_list[question_id] > 0) {
            var newIndex = position_list[question_id] - 1;
            new_assQ[newIndex] = assessmentQuestionsNew[i];
            new_q[newIndex] = questions[i];
            new_qcnt[newIndex] = questionContent[i];
            position_list[question_id] = "";
        } else {
            tmp_assQ.push(assessmentQuestionsNew[i]);
            tmp_q.push(questions[i]);
            tmp_qcnt.push(questionContent[i]);
        }
        }

        for (i = 0; i < this.state.assessmentQuestionsData.length; i++) {
        if (new_assQ[i] == "") {
            new_assQ[i] = tmp_assQ[k];
            new_q[i] = tmp_q[k];
            new_qcnt[i] = tmp_qcnt[k];
            k = k + 1;
        }

        new_q[i]["question_number"] = i + 1;
        }

        this.setState({
            assessmentQuestionsData: new_q,
            questionContents: new_qcnt,
            position_list: position_list,
            changes: true,
        });

        this.props.applyPositionChangeLocal({ list: new_assQ, contents: new_qcnt });
    }

    //function for printing all available questions
    handlePrint = async () => {
        await this.props.getAllQuestionContentsByType({
        questions: this.state.assessmentQuestionsData,
        });

        this.setState({
        questions_contents_by_type: this.props.questionContentsByType,
        });

        let questionsByType = this.state.questions_contents_by_type
        let html = "";

        if (questionsByType["MCQ"]) {
        html += "<h3>Section 1: Multiple Choice Questions</h3>";
        html += "<p>Underline the suitable answer</p>";

        html += questionsByType["MCQ"]
            .map((q, i) => {
            const blocks = JSON.parse(q)[0].blocks;

            if (blocks && blocks.length > 0) {
                let questions = ""; // Move the initialization here
                let answers = ""; // Move the initialization here

                blocks.forEach((block) => {
                let text = block.text;
                let inlineStyleRanges = block.inlineStyleRanges || [];

                // Process inline style ranges for formatting
                if (inlineStyleRanges.length > 0) {
                    let formattedText = ""
                    let lastIndex = 0

                    inlineStyleRanges.forEach((range) => {
                    const { offset, length, style } = range
                    const substring = text.slice(offset, offset + length)

                    // Append the preceding unformatted text
                    formattedText += text.slice(lastIndex, offset)

                    // Apply the appropriate formatting to the substring
                    if (style === "BOLD") {
                        formattedText += `<strong>${substring}</strong>`
                    } else if (style === "ITALIC") {
                        formattedText += `<em>${substring}</em>`
                    } else if (style === "UNDERLINE") {
                        formattedText += `<u>${substring}</u>`
                    }

                    lastIndex = offset + length
                    })

                    // Append the remaining unformatted text
                    formattedText += text.slice(lastIndex)

                    text = formattedText
                }

                questions += text
                })

                answers = JSON.parse(q)
                .slice(1)
                .map((a, j) => {
                    let answerText = ""
                    let answerImage = ""

                    if (a.blocks && a.blocks.length > 0) {
                    answerText = a.blocks[0].text
                    let inlineStyleRanges = a.blocks[0].inlineStyleRanges || []

                    // Process inline style ranges for formatting
                    if (inlineStyleRanges.length > 0) {
                        let formattedText = ""
                        let lastIndex = 0

                        inlineStyleRanges.forEach((range) => {
                        const { offset, length, style } = range
                        const substring = answerText.slice(
                            offset,
                            offset + length
                        )

                        // Append the preceding unformatted text
                        formattedText += answerText.slice(lastIndex, offset)

                        // Apply the appropriate formatting to the substring
                        if (style === "BOLD") {
                            formattedText += `<strong>${substring}</strong>`
                        } else if (style === "ITALIC") {
                            formattedText += `<em>${substring}</em>`
                        } else if (style === "UNDERLINE") {
                            formattedText += `<u>${substring}</u>`
                        }

                        lastIndex = offset + length
                        })

                        // Append the remaining unformatted text
                        formattedText += answerText.slice(lastIndex)

                        answerText = formattedText
                    }
                    }

                    if (typeof a.entityMap === "object" && a.entityMap !== null) {
                    Object.values(a.entityMap).forEach((entityMap) => {
                        if (entityMap.type === "IMAGE") {
                        const imageUrl = entityMap.data.src
                        answerImage = `<img src="${imageUrl}" alt="Image" />`
                        }
                    })
                    }

                    return `<div>${String.fromCharCode(97 + j)}) ${answerImage ? answerImage : answerText
                    }</div>`
                })
                .join("")

                return `<div style="margin-left: 20px;">${i + 1
                }.${questions}<br><div style="margin-left: 35px;">${answers}</div></div>`
            }
            })
            .join("")
        }

        if (questionsByType["Descriptive"]) {
        html += "<h3>Section 2: Short Answer</h3>"
        html += "<p>Write a short answer</p>"

        html += questionsByType["Descriptive"]
            .map((q, i) => {
            const content = JSON.parse(q)[0]
            const blocks = content.blocks
            const entityMap = content.entityMap

            let question = ""
            let answers = ""

            if (entityMap && blocks && blocks.length > 0) {
                blocks.forEach((block) => {
                let text = block.text
                let inlineStyleRanges = block.inlineStyleRanges || []
                let entityRanges = block.entityRanges || []
                let formattedText = ""

                let currentIndex = 0
                inlineStyleRanges.forEach((range) => {
                    const nonFormattedSubstring = text.slice(
                    currentIndex,
                    range.offset
                    )
                    const formattedSubstring = text.slice(
                    range.offset,
                    range.offset + range.length
                    )
                    formattedText += nonFormattedSubstring

                    if (range.style === "BOLD") {
                    formattedText += `<strong>${formattedSubstring}</strong>`
                    } else if (range.style === "ITALIC") {
                    formattedText += `<em>${formattedSubstring}</em>`
                    } else if (range.style === "UNDERLINE") {
                    formattedText += `<u>${formattedSubstring}</u>`
                    }

                    currentIndex = range.offset + range.length
                })

                formattedText += text.slice(currentIndex)

                question += formattedText
                })
            }

            return `<div style="margin-left: 20px;font-family: Arial;">${i + 1
                }. ${question}<br></div>`
            })
            .join("")
        }

        if (questionsByType["Submission"]) {
        html += "<h3>Section 3: Essay</h3>"
        html += "<p>Write answer in additional paper and attach it</p>"

        html += questionsByType["Submission"]
            .map((q, i) => {
            const blocks = JSON.parse(q)[0].blocks

            let question = ""

            if (blocks && blocks.length > 0) {
                blocks.forEach((block) => {
                let text = block.text
                let inlineStyleRanges = block.inlineStyleRanges || []

                if (inlineStyleRanges.length > 0) {
                    let formattedText = ""
                    let lastIndex = 0

                    inlineStyleRanges.forEach((range) => {
                    const { offset, length, style } = range
                    const substring = text.slice(offset, offset + length)

                    formattedText += text.slice(lastIndex, offset)

                    if (style === "BOLD") {
                        formattedText += `<strong>${substring}</strong>`
                    } else if (style === "ITALIC") {
                        formattedText += `<em>${substring}</em>`
                    } else if (style === "UNDERLINE") {
                        formattedText += `<u>${substring}</u>`
                    }
                    // Add additional conditions for other formatting options
                    else if (style === "STRIKETHROUGH") {
                        formattedText += `<del>${substring}</del>`
                    } else if (style === "HIGHLIGHT") {
                        formattedText += `<span style="background-color: yellow;">${substring}</span>`
                    }
                    // Add more conditions for other formatting options as needed

                    lastIndex = offset + length
                    })

                    formattedText += text.slice(lastIndex)

                    text = formattedText
                }

                question += text
                })
            }

            return `<div style="margin-left: 20px;">${i + 1
                }. ${question}<br></div>`
            })
            .join("")
        }

        const fullHtml = `
        <html>
        <head>
            <title>Exam</title>
        </head>
        <body>
            <h4><u>Test Assessment</u></h4>
            <p>Instructions: Please read each question carefully and select the best answer.</p>
            ${html}
        </body>
        </html>
    `

        const popupWindow = window.open("", "_blank")
        popupWindow.document.write(fullHtml)
        popupWindow.print()
    }

    // function to get autoshuffle done for only for mcqs
    handleAutoShuffle = () => {
        this.setState(
        (prevState) => ({
            auto_shuffle: !prevState.auto_shuffle,
        }),
        () => {
            try {
            const requestOptions = {
                method: "POST",
                headers: APIHandler.getHeader(
                config.azure_ad_config.apis.ASSESSMENT.name
                ),
                body: JSON.stringify({
                auto_shuffle: this.state.auto_shuffle,
                }),
            }

            fetch(
                `${config.oat_api_url}/assessment/autoshuffle-mcqs/${this.state.assessment.assessment_id}`,
                requestOptions
            ).then(APIHandler.handleResponse)
            } catch (err) {
            return { error: "Some error occured. Please try again!" }
            }
        }
        )
    }

    onChangeAllMarks = (j) => {
        var list = this.state.mark_allocation
        const keys = Object.keys(list)
        for (let key of keys) {
        list[key] = j.target.value
        }
        this.setState({ mark_allocation: list, allMarks: j.target.value })
    }

    onSetAllMarks = () => {
        var total = this.state.total
        var question_list = this.state.assessmentAllQuestionsData?.filter(val => val?.sub_questions === null)
        if (this.state.allMarks > 0) {
        for (var i = 0; i <= question_list.length - 1; i++) {
            if (question_list[i]?.sub_questions === null) {
                question_list[i].mark_allocation = this.state.allMarks
                this.onSetMarks(question_list[i], i);
            }
        }
        total = this.state.allMarks * question_list.length 
        this.setState({
            total: total,
            changes: true
        })
        } else {
        window.Swal.fire({
            title: messageTypes.WARNING,
            text: "Invalid Mark Allocated.",
            icon: messageTypes.WARNING,
            customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
        })
        }
    }

    onSetMarks = (el, val) => {
        var list = this.state.mark_allocation
        var question_list = this.state.assessmentQuestionsData
        var total = this.state.total
        var question_id = el.question_id
        var index = _.findIndex(question_list, { question_id: question_id })
        if (
        !isNaN(parseFloat(list[question_id])) &&
        parseFloat(list[question_id]) != 0
        ) {
        if (parseFloat(question_list[index].mark_allocation)) {
            total = total - parseFloat(question_list[index].mark_allocation)
        }
        total = total + parseFloat(list[question_id])
        question_list[index].mark_allocation = parseFloat(list[question_id])
        this.setState({
            assessmentQuestionsData: question_list,
            total: total,
            changes: true,
        })
        } else if (list[question_id] == "") {
        this.setState({
            assessmentQuestionsData: question_list,
            total: total,
            changes: true,
        })
        list[question_id] =
            this.state.assessmentQuestionsData[index]["mark_allocation"]
        this.setState({
            mark_allocation: list,
        })
        window.Swal.fire({
            title: messageTypes.WARNING,
            text: "Not a Number. Mark allocated will be reverted to previous value.",
            icon: messageTypes.WARNING,
            customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
        })
        } else {
        list[question_id] =
            this.state.assessmentQuestionsData[index]["mark_allocation"]
        window.Swal.fire({
            title: messageTypes.WARNING,
            text: "Mark allocated is reverted to previous value.",
            icon: messageTypes.WARNING,
            customClass: {
            confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
            }
        })
        this.setState({
            mark_allocation: list,
        })
        }
    }

    async onDownloadAttachment(e, attach) {
        e.preventDefault()
        let fileReader;
        async function handleFileRead() {
        const content = fileReader.result;
        }
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        let containerName = config.storageBlobConfigs.containerName;
        let content = await AzureStorage.downloadBlob(containerName, attach);
        fileReader.readAsArrayBuffer(content);
    }

    addQuestion = () => {
        if (this.state.changes) {
        window.Swal.fire({
            text: "You have unsaved changes in this page. Save them before adding new questions",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: !1,
            confirmButtonText: "Discard and proceed",
            cancelButtonText: "Stay on page",
            customClass: {
            confirmButton: `btn font-weight-bold ${cancelClass}`,
            cancelButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
            }
            }
        }).then(async (action) => {
            if (action.isConfirmed) {
            this.setState({
                add_question: true,
                mark_allocation: {},
                assessmentQuestionsData: [],
            })
            }
        })
        } else {
        this.setState({
            add_question: true,
            mark_allocation: {},
            assessmentQuestionsData: [],
        })
        }
    }

    renderStudentView = async() => {
        this.setState({isStudentViewLoading: true});
        await this.fetchAllAssessmentQuestions();
        await this.props?.getAllQuestionContents({
            questions: this.props?.assessmentFilteredQuestions,
        });
        this.setState({isStudentViewLoading: false});
        this.setState({studentView: true});
    }

    resetAll = () => {
        if (this.state.changes) {
        window.Swal.fire({
            text: "All the unsaved changes will be reverted. Do you want to proceed?",
            icon: "warning",
            showCancelButton: true,
            buttonsStyling: !1,
            confirmButtonText: "Discard and reset",
            cancelButtonText: "Cancel",
            customClass: {
            confirmButton: `btn font-weight-bold ${cancelClass}`,
            cancelButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
            if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
            }
            }
        }).then(async (action) => {
            if (action.isConfirmed) {
                this.fetchAllAssessmentQuestions();
                this.setState({
                    deletion: false,
                    assessmentQuestionsData: [],
                    to_delete: [],
                    total: "",
                    errors: {},
                    mark_allocation: {},
                    position_list: {},
                    changes: false,
                    get_content: true,
                    add_question: false,
                    searchValue: "",
                    selectedCategory: 0,
                    selectedType: 0,
                    selectedDifficulty: 0,
                    chkbox: false,
                    filteredQuestions: [],
                    selectedQuestion: [],
                })
            }
        })
        } 
        else {
            this.fetchAllAssessmentQuestions();
            this.setState({
                deletion: false,
                assessmentQuestionsData: [],
                to_delete: [],
                total: "",
                errors: {},
                mark_allocation: {},
                position_list: {},
                changes: false,
                get_content: true,
                add_question: false,
                searchValue: "",
                selectedCategory: 0,
                selectedType: 0,
                selectedDifficulty: 0,
                chkbox: false,
                filteredQuestions: [],
            })
        }
    }

    getEditability(user_id) {
        var role_permission =
        this.state.assessment["role_code"] == "CASS" ||
        user_id.endsWith(sessionStorage.getItem("user_id"))
        var assessment_state =
        this.state.assessment.assessment_state == "Ready" ||
        this.state.assessment.assessment_state == "Finished"
        return role_permission && !assessment_state
    }

    getText() {
        if(this.state.assessment.assessment_state == "Initial"){
            return <p style={{ fontSize: "14px" }}>Make sure to click "<b>Save Changes</b>" after doing any modifications to this page!</p>;
        }
        else if(this.state.assessment.assessment_state == "Ready"){
            return <p style={{ fontSize: "14px" }}>Make sure to click "<b>Make Editable</b>" to do any modifications to this page! Click "<b>Set For Review</b>" to set this page under review!</p>;
        }
        else if(this.state.assessment.assessment_state == "Review"){
            return <p style={{ fontSize: "14px" }}>Make sure to click "<b>Publish</b>" after doing your review to this page! Once published it will go live for students!</p>;
        }
        else if(this.state.assessment.assessment_state == "Published"){
            return <p style={{ fontSize: "14px" }}>Assessment is published and live for students! You can not do any modifications to this page!</p>;
        }
        else if(this.state.assessment.assessment_state == "Retired"){
            return <p style={{ fontSize: "14px" }}>Assessment is retired! You can not do any modifications to this page! Please Contact Administrator!</p>;
        }
    }

    getEditabilityAssessment() {
        var assessment_state = this.state.assessment.assessment_state == "Ready" || this.state.assessment.assessment_state == "Finished"
        return !assessment_state
    }

    synceTableSetupAssessment(showingQuestionsList) {

        const handleDragEnd = async (e) => {
            if (!e.destination) return;

            let tempData = Array.from(this.state.assessmentQuestionsData);
            let [source_data] = tempData.splice(e.source.index, 1);
            tempData.splice(e.destination.index, 0, source_data);

            const newAssessmentQuestions = [...tempData];

            const position_list = {};

            // Update position_list based on the new order
            newAssessmentQuestions.forEach((el, index) => {
                position_list[el.question_id] = index + 1;
            });

            // Update the state asynchronously
            await this.setState({
                assessmentQuestionsData: newAssessmentQuestions,
                position_list: position_list,
            });

            // Trigger onPositionChange after the state is updated
            this.onPositionChange();
            //this.onSave();
        }

        return (
            <>
            {this.state.assessmentQuestionsData && this.state.assessmentQuestionsData.length > 0 ? (
                <>
                    <div className="col-md-15 d-flex align-items-center">
                        <div className="col-md-6">
                            <div className="bg-gray-200 rounded-lg p-2 m-total-marks text-center">
                                <h5 className="text-dark font-weight-bold d-block font-size-h5">
                                    Total Marks
                                </h5>
                                <h3 className="text-dark font-weight-bold font-size-h5 m-0">
                                    {this.state.total}
                                </h3>
                            </div>
                        </div>
                        {/* <div className="col-md-3">
                            <input
                                type="checkbox"
                                id="selectMyQuestions"
                                onChange={this.handleSelectMyQuestions}
                                checked={this.state.checked}
                                style={{ marginRight: '8px' }}  
                            />
                            <label htmlFor="selectMyQuestions" style={{ margin: 0, cursor: 'pointer' }}>
                                My Questions
                            </label>
                        </div> */}
                        <div className="col-md-6">
                            {this.state.assessment.assessment_state == "Initial" && this.state.rowsPerPage >= this.state.assessmentAllQuestionsData.length && (
                                <Marks
                                    viewAllocated={false}
                                    onChangeMarks={(e) => this.onChangeAllMarks(e)}
                                    onSetMarks={() => this.onSetAllMarks()}
                                    viewAllMarks={true}
                                />
                            )}
                        </div>
                    </div>

                    <div className="col-md-12" style={{ overflow: "auto", height: "50vh" }}>
                        <DragDropContext onDragEnd={handleDragEnd}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell className="thead"></TableCell>
                                <TableCell className="thead"></TableCell>
                                <TableCell className="thead"></TableCell>
                                <TableCell className="thead">Question Title</TableCell>
                                <TableCell className="thead">Added By</TableCell>
                                <TableCell className="thead">Marks</TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                            </TableHead>

                            <Droppable droppableId="droppable-1">
                            {(provider) => (
                                <TableBody
                                ref={provider.innerRef}
                                {...provider.droppableProps}
                                >
                                {showingQuestionsList.map((el, i) => (
                                    <Draggable
                                    key={el.question_id}
                                    draggableId={el.answer}
                                    index={i}
                                    >
                                    {(provider) => (
                                        <TableRow
                                        {...provider.draggableProps}
                                        ref={provider.innerRef}
                                        >
                                        <TableCell
                                            className="tData"
                                            {...provider.dragHandleProps}
                                        >
                                            <b>≡</b>
                                        </TableCell>
                                        <TableCell>
                                            {el.sub_questions !== null ? "Main Question" : el.is_subquestion === "true" ? "Sub Question" : el?.question_type}
                                        </TableCell>

                                        <TableCell className="tData">
                                            {el?.is_subquestion === null && (
                                            <div>
                                                <input
                                                type="checkbox"
                                                value={el.question_id}
                                                onChange={(e) => this.handleSelectQuestion(e, el)} />
                                            </div>
                                            )}
                                        </TableCell>
                                        <TableCell className="tData">
                                            {(i + 1) + ". " + el.title}
                                        </TableCell>
                                        <TableCell className="tData">
                                            {el.first_name.concat(" ", el.last_name)}
                                        </TableCell>
                                        <TableCell className="tData">
                                            {el.sub_questions === null && (
                                            <Marks
                                                viewAllocated={false}
                                                value={this.state.mark_allocation[el.question_id]}
                                                onChangeMarks={(e) => this.onChangeMarks(e, el, i)}
                                                onSetMarks={() => this.onSetMarks(el, i)}
                                                readOnly={!this.getEditability(el.added_by)}
                                                question={i} />
                                            )}
                                        </TableCell>
                                        <TableCell className="tData">
                                            <Button
                                                onClick={(e) => this.onViewFinalizeQuestion(el, e)}
                                                className="btn btn-icon action-btn btn-sm m-1 view-question-btn flaticon-eye-icon"
                                                dataIndex={i}
                                                dataToggle="modal"
                                                dataTarget="#view-details"
                                                dataBackDrop="static"
                                                title="View Details" 
                                            />
                                            {el.is_subquestion === null && (
                                            <Button
                                                className="btn btn-icon btn-danger btn-sm flaticon-delete"
                                                dataIndex={i}
                                                title="Delete"
                                                onClick={(e) => this.onDelete(e, el)}
                                            />)}
                                        </TableCell>
                                        </TableRow>

                                    )}
                                    </Draggable>
                                ))}
                                {provider.placeholder}
                                </TableBody>
                            )}
                            </Droppable>
                        </Table>
                        </DragDropContext>
                    </div>
                    </>
                    ) : (
                        <div className="text-center mb-5 my-10" style={{ height: "90vh" }}>
                        <label>No Questions Available!</label>
                        </div>
                    )}
                </>
            )
        }

    //Applying Filtering
    onFilterInput = (v, r) => {
        let categoryOptions
        let languageOptions
        let subjectOptions
        let sourceOptions
        let authorOptions
        let gradeOptions
        let yearOptions
        let termOptions
        let chapterOptions
        let typeOptions
        let difficultyOptions
        let questionAddedTeacherOptions
        let selectedFilters = []
        this.setState({
            filters: v,
            filterValues: []
        })

        if (r === "removeOption") {
            this.setState({
                selectedAuthors: null,
                selectedsubjects: null,
                selectedChapters: null,
                selectedTerms: null,
                selectedCategories: null,
                selectedYears: null,
                selectedLanguages: null,
                selectedDifficulties: null,
                selectedTypes: null,
                selectedSources: null,
                selectedGrades: null,
                selectedTeachers: null

            }, () => {
                this.fetchAllAssessmentQuestions();
            })

        }
        if (v.includes("Category")) {
            let questionCategories = this.props.allQuestionMetaData.find(item => 'categories' in item).categories;
            categoryOptions = questionCategories?.map((val) => {
                return {
                    filter: "Category",
                    name: val.name,
                    category_id: val.category_id
                }
            })
            selectedFilters = [...selectedFilters, ...categoryOptions]
        } if (v.includes("Language")) {
            let questionLanguages = this.props.allQuestionMetaData.find(item => 'languages' in item).languages;
            languageOptions = questionLanguages?.map((val) => {
                return {
                    filter: "Language",
                    name: val.language,
                    language_id: val.language_id
                }
            })
            selectedFilters = [...selectedFilters, ...languageOptions]
        } if (v.includes("Source")) {
            let questionSources = this.props.allQuestionMetaData.find(item => 'sources' in item).sources;
            sourceOptions = questionSources?.map((val) => {
                return {
                    filter: "Source",
                    name: val.source,
                    source_id: val.source_id
                }
            })
            selectedFilters = [...selectedFilters, ...sourceOptions]
        } if (v.includes("Subject")) {
            let questionSubjects = this.props.allQuestionMetaData.find(item => 'subjects' in item).subjects;
            subjectOptions = questionSubjects?.map((val) => {
                return {
                    filter: "Subject",
                    name: val.subject,
                    subject_id: val.subject_id
                }
            })
            selectedFilters = [...selectedFilters, ...subjectOptions]
        } if (v.includes("Type")) {
            let questionTypes = this.props.allQuestionMetaData.find(item => 'types' in item).types;
            typeOptions = questionTypes?.map((val) => {
                return {
                    filter: "Type",
                    name: val.qtype,
                    type_id: val.type_id
                }
            })
            selectedFilters = [...selectedFilters, ...typeOptions]
        } if (v.includes("Author")) {
            let questionAuthors = this.props.allQuestionMetaData.find(item => 'authors' in item).authors;
            authorOptions = questionAuthors?.map((val) => {
                return {
                    filter: "Author",
                    name: val.author,
                    author_id: val.author_id
                }
            })
            selectedFilters = [...selectedFilters, ...authorOptions]
        } if (v.includes("Year")) {
            let questionYears = this.props.allQuestionMetaData.find(item => 'years' in item).years;
            yearOptions = questionYears?.map((val) => {
                return {
                    filter: "Year",
                    name: val.year,
                    year_id: val.year_id
                }
            })
            selectedFilters = [...selectedFilters, ...yearOptions]
        } if (v.includes("Difficulty")) {
            let questionDifficulties = this.props.allQuestionMetaData.find(item => 'difficulty_types' in item).difficulty_types;
            difficultyOptions = questionDifficulties?.map((val) => {
                return {
                    filter: "Difficulty",
                    name: val.difficulty_type,
                    difficulty_id: val.difficulty_id
                }
            })
            selectedFilters = [...selectedFilters, ...difficultyOptions]
        } if (v.includes("Grade")) {
            let questionGrades = this.props.allQuestionMetaData.find(item => 'grades' in item).grades;
            gradeOptions = questionGrades?.map((val) => {
                return {
                    filter: "Grade",
                    name: val.grade,
                    grade_id: val.grade_id
                }
            })
            selectedFilters = [...selectedFilters, ...gradeOptions]
        } if (v.includes("Term")) {
            let questionTerms = this.props.allQuestionMetaData.find(item => 'terms' in item).terms;
            termOptions = questionTerms?.map((val) => {
                return {
                    filter: "Term",
                    name: val.term,
                    term_id: val.term_id
                }
            })
            selectedFilters = [...selectedFilters, ...termOptions]
        } if (v.includes("Chapter")) {
            let questionChapters = this.props.allQuestionMetaData.find(item => 'chapters' in item).chapters;
            chapterOptions = questionChapters?.map((val) => {
                return {
                    filter: "Chapter",
                    name: val.chapter,
                    chapter_id: val.chapter_id
                }
            })
            selectedFilters = [...selectedFilters, ...chapterOptions]
        }
        if (v.includes("Added By")) {
            let questionAddedTeachers = this.state.teachers;
            questionAddedTeacherOptions = questionAddedTeachers?.map((val) => {
                return {
                    filter: "Added By",
                    name: val.first_name + ' ' + val.last_name,
                    teacher_user_id: parseInt(val.user_id)
                }
            })
            selectedFilters = [...selectedFilters, ...questionAddedTeacherOptions]
        }
        this.setState({
            selectedFilters: selectedFilters
        })

    }

    onSelectFilter = (e) => {
        let selectedGrades = []
        let selectedChapters = []
        let selectedsubjects = []
        let selectedAuthors = []
        let selectedYears = []
        let selectedCategories = []
        let selectedTerms = []
        let selectedSources = []
        let selectedTypes = []
        let selectedDifficulties = []
        let selectedLanguages = []
        let selectedTeachers = []

        e.map((val) => {
            if (val.filter === "Category") {
                selectedCategories.push(val?.category_id)
            } if (val.filter === "Type") {
                selectedTypes.push(val?.type_id)
            } if (val.filter === "Difficulty") {
                selectedDifficulties.push(val?.difficulty_id)
            } if (val.filter === "Language") {
                selectedLanguages.push(val?.language_id)
            } if (val.filter === "Source") {
                selectedSources.push(val?.source_id)
            } if (val.filter === "Subject") {
                selectedsubjects.push(val?.subject_id)
            } if (val.filter === "Author") {
                selectedAuthors.push(val?.author_id)
            } if (val.filter === "Year") {
                selectedYears.push(val?.year_id)
            } if (val.filter === "Term") {
                selectedTerms.push(val?.term_id)
            } if (val.filter === "Chapter") {
                selectedChapters.push(val?.chapter_id)
            } if (val.filter === "Grade") {
                selectedGrades.push(val?.grade_id)
            } if (val.filter === "Added By") {
                selectedTeachers.push(val?.teacher_user_id)
            }
        })

        this.setState({
            filterValues: e,
            selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
            selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
            selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
            selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
            selectedCategories: selectedCategories.length > 0 ? selectedCategories : null,
            selectedYears: selectedYears.length > 0 ? selectedYears : null,
            selectedLanguages: selectedLanguages.length > 0 ? selectedLanguages : null,
            selectedDifficulties: selectedDifficulties.length > 0 ? selectedDifficulties : null,
            selectedTypes: selectedTypes.length > 0 ? selectedTypes : null,
            selectedSources: selectedSources.length > 0 ? selectedSources : null,
            selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,
            selectedTeachers: selectedTeachers.length > 0 ? selectedTeachers : null

        }, () => {
            this.fetchAllAssessmentQuestions();
        })

    }

    handlePageChange = (event, newPage) => {
        const { rowsPerPage } = this.state;
        const newOffset = newPage * rowsPerPage;
        this.setState(
            {
                limit: rowsPerPage,
                offset: newOffset,
                isTableDataLoading: true,
                currentPage: newPage,
            },
            () => {
                this.fetchAllAssessmentQuestions();
            }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        this.setState(
            {
                offset: newOffset,
                isTableDataLoading: true,
                currentPage: newPage
            },
            () => {
                this.fetchAllAssessmentQuestions();
            }
        );
    };

    //handle the data fetching as per the data per page limit gets changes
    handleRowsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        const {mark_allocation, changes} = this.state;
        this.setState({ rowsPerPage: newLimit });
        const newPage = Math.floor(this.state.offset / newLimit);
        this.setState(
            {
                limit: newLimit,
                offset: newLimit * newPage,
                isTableDataLoading: true,
                currentPage: newPage,
                mark_allocation: mark_allocation,
                changes: changes
            },
            () => {
                this.fetchAllAssessmentQuestions();
            }
        );
    };

    handleSortChange = (sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { sort_column: currentSortColumn, sort_direction } = this.state;
        const newSortDirection =
            currentSortColumn === sort_column && sort_direction === "ASC"
                ? "DESC"
                : "ASC";

        this.setState(
            {
                sort_column,
                sort_direction: newSortDirection,
                offset: 0, // Reset offset when changing sorting
                currentPage: 0,
                isTableDataLoading: true,
            },
            () => {
                this.fetchAllAssessmentQuestions();
            }
        );
    };

    handleSearch = (event) => {
        const limit = this.state.limit;
        const offset = this.state.offset;
        const search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

        if (search_term !== "") {
            this.setState({ limit: this.props.totalFilteredCount });
        }

        if (search_term === "" || regex.test(search_term)) {
            this.setState(
                {
                    limit,
                    offset,
                    search_term,
                    isTableDataLoading: true,
                    offset: 0,
                    currentPage: 0,
                    searchError: false,
                },
                () => {
                    // Call the debounced version of fetchStudentData
                    // this.debouncedHandleSearch();
                    this.fetchAllAssessmentQuestions();
                }
            );
        } 
        else {
            this.setState({ search_term, searchError: true });
        }
    };

    render() {
        if (this.state.add_question) {
            return <Redirect to={routes.finalize_new_question} />
        }
        if (this.state.studentView) {
            return <Redirect to={routes.student_view} />;
        }

        return (
            <AdminTemplate>
                <ContentWrapper>
                    {this.state.isStudentViewLoading && <Spinner id="show" text="Loading Student View.." />}
                    {this.state.assessment.title != undefined ? (
                        <Card>
                            <CardHeader>
                            <CardTitle cardLabel={this.state.assessment.title}>
                                <Breadcrumb>
                                <BreadcrumbItem
                                    to={routes.view_assessment}
                                    breadcrumbItemName="Go Back to Assessments Library"
                                />
                                {/* <BreadcrumbItem
                                    to={routes.setup_assessment}
                                    active="active"
                                    breadcrumbItemName="Finalize"
                                /> */}
                                </Breadcrumb>
                            </CardTitle>

                            <CardToolbar>
                                <Button
                                    onClick={() => this.addQuestion()}
                                    btnName="Add new question"
                                    className="btn btn-primary mr-2 finalize-btn"
                                    title="Add new question"
                                    to="finalize-new-question"
                                    disabled={
                                        this.state.assessment.assessment_state == "Ready" ||
                                        this.state.assessment.assessment_state == "Review" ||
                                        this.state.assessment.assessment_state == "Published" ||
                                        this.state.assessment.assessment_state == "Retired"
                                    }
                                />
                                <Button
                                    className="btn btn-danger btn-mr font-weight-bold mr-2 remove"
                                    btnName="Delete"
                                    // btnIconStyle="flaticon-trash"
                                    onClick={() => this.handleDeleteSelectedQuestions()}
                                    disabled={
                                        this.state.assessment.assessment_state == "Ready" ||
                                        this.state.assessment.assessment_state == "Review" ||
                                        this.state.assessment.assessment_state == "Published" ||
                                        this.state.assessment.assessment_state == "Retired"
                                    }
                                />
                                <Button
                                    onClick={() => this.resetAll()}
                                    className="btn btn-primary finalize-btn mr-2"
                                    btnName="Reset"
                                    disabled={
                                        this.state.assessment.assessment_state == "Ready" ||
                                        this.state.assessment.assessment_state == "Review" ||
                                        this.state.assessment.assessment_state == "Published" ||
                                        this.state.assessment.assessment_state == "Retired"
                                    }
                                />
                                <Button
                                    onClick={() => this.renderStudentView()}
                                    className="btn btn-primary finalize-btn mr-2"
                                    btnName="Student View"
                                />
                                <Button
                                    className="btn btn-primary mr-2 finalize-btn"
                                    btnName="Save Changes"
                                    onClick={this.onSave}
                                    disabled={
                                        this.state.assessment.assessment_state == "Ready" ||
                                        this.state.assessment.assessment_state == "Review" ||
                                        this.state.assessment.assessment_state == "Published" ||
                                        this.state.assessment.assessment_state == "Retired"
                                    }
                                />
                                {this.state.assessment["role_code"] == "CASS" && this.state.assessment["assessment_state"] == "Initial" && (
                                    <Button
                                        className="btn btn-primary mr-2 finalize-btn"
                                        btnName="Finalize"
                                        btnIconStyle="flaticon2-correct"
                                        onClick={this.finalizeAssessment}
                                        disabled={this.state.disabled}
                                    />
                                )}
                                {this.state.assessment["role_code"] == "CASS" && this.state.assessment["assessment_state"] == "Review" && (
                                    <Button
                                        className="btn btn-primary mr-2 finalize-btn"
                                        btnName="Publish"
                                        btnIconStyle="flaticon2-correct"
                                        onClick={this.publishAssessment}
                                        disabled={this.state.disabled}
                                    />
                                )}
                                {this.state.assessment["role_code"] == "CASS" && this.state.assessment["assessment_state"] == "Published" && (
                                    <Button
                                        className="btn btn-primary mr-2 finalize-btn"
                                        btnName="Retire"
                                        btnIconStyle="flaticon2-correct"
                                        onClick={this.retireAssessment}
                                        disabled={this.state.disabled}
                                    />
                                )}
                                {this.state.assessment["role_code"] == "CASS" && this.state.assessment["assessment_state"] == "Ready" && (
                                    <Button
                                        className="btn btn-primary mr-2 finalize-btn"
                                        btnName="Make editable"
                                        btnIconStyle="flaticon2-correct"
                                        onClick={this.makeEditableAssessment}
                                        disabled={this.state.disabled}
                                    />
                                )}
                                {this.state.assessment["role_code"] == "CASS" && this.state.assessment["assessment_state"] == "Ready" && (
                                    <Button
                                        className="btn btn-primary mr-2 finalize-btn"
                                        btnName="Set for review"
                                        btnIconStyle="flaticon2-correct"
                                        onClick={this.setForReviewAssessment}
                                        disabled={this.state.disabled}
                                    />
                                )}
                                {this.state.assessment["role_code"] == "CASS" && this.state.assessment["assessment_state"] == "Review" && (
                                    <Button
                                        className="btn btn-primary mr-2 finalize-btn"
                                        btnName="Make ready"
                                        btnIconStyle="flaticon2-correct"
                                        onClick={this.makeReadyAssessment}
                                        disabled={this.state.disabled}
                                    />
                                )}
                                {/* to activate print button */}
                                {/* {this.state.assessment["assessment_state"] == "Ready" && (
                                <Button
                                    className="btn btn-primary mr-2 finalize-btn"
                                    btnName="Print"
                                    onClick={() => this.handlePrint()}
                                />
                                )} */}

                                <div style={{ width: "10px" }}></div>
                                {/* to autoshuffle checkBox */}
                                {this.state.assessment["assessment_state"] == "Initial" && this.checkingForAllMcqs() && (
                                    <CheckBoxInline>
                                        <CheckBox
                                            checkBoxName="Autoshuffle MCQ"
                                            onChange={() => this.handleAutoShuffle()}
                                        />
                                    </CheckBoxInline>
                                )}
                            </CardToolbar>
                        </CardHeader>

                        <div
                            className="row mx-3 my-5 d-flex justify-content-center align-items-center"
                            style={{
                                border: "1px solid #ffbc04",
                                borderRadius: "10px",
                                backgroundColor: "#fcf4dc",
                                borderLeft: "8px solid #ffbc04"
                            }}
                        >
                            <div className="py-5 col-md-3 col-3 d-flex justify-content-end align-items-center">
                                <TipsAndUpdatesIcon sx={{ fontSize: "40px", color: "#ffbc04" }} />
                            </div>
                            <div className="py-5 col-md-9 col-9 d-flex align-items-center">
                                {this.getText()}
                            </div>
                        </div>

                        <CardBody>
                            <div className="row py-5 d-flex align-items-center">
                                <div className="col-md-9">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FormGroup labelName="Filter By">
                                                <Autocomplete
                                                    multiple
                                                    id="checkboxes-filter"
                                                    options={ASSESSMENT_QUESTION_FILTERS}
                                                    disableCloseOnSelect
                                                    getOptionLabel={(option) => option}
                                                    onChange={(e, v, r) => this.onFilterInput(v, r)}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <Checkbox
                                                                style={{ marginRight: 8, color: "#5458AF" }}
                                                                checked={selected}
                                                            />
                                                            {option}
                                                        </li>
                                                    )}
                                                    className="col-lg-8 col-md-8 p-0"
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Select Filter" />
                                                    )}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6">
                                            {this.state.filters.length > 0 && (
                                                <FormGroup labelName="">
                                                    <Autocomplete
                                                        multiple
                                                        id="grouped-demo"
                                                        className="col-lg-8 col-md-8 p-0"
                                                        options={this.state.selectedFilters}
                                                        groupBy={(option) => option.filter}
                                                        onChange={(e, v) => this.onSelectFilter(v)}
                                                        getOptionLabel={(option) => option.name}
                                                        sx={{ width: 300 }}
                                                        value={this.state.filterValues}
                                                        renderInput={(params) => <TextField {...params} label="Filters" />}
                                                    />
                                                </FormGroup>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-3 mb-2">
                                    <Form.Group
                                        controlId="exampleForm.ControlInput1"
                                        className="mb-2"
                                    >
                                        <Form.Control
                                            type="text"
                                            placeholder="Search"
                                            onChange={this.handleSearch}
                                            className="col-md-12 col-12"
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <TabContent>
                                <TabPane className="tab-pane fade show active" id="questions-library-1"
                                    ariaLabelledby="questions-library-tab-1">
                                    {(this.state.isTableDataLoading) ? (
                                        <div>
                                            <div className="loading-container mb-5 mt-10">
                                                <ReactSpinner animation="border" role="status" style={{ display: 'block', margin: 'auto' }}>
                                                    <span className="sr-only">Loading...</span>
                                                </ReactSpinner>
                                            </div>
                                        </div>
                                    ) : (
                                        this.synceTableSetupAssessment(this.state.assessmentQuestionsData)
                                    )
                                    }
                                    <div className="d-flex justify-content-end">
                                        <MaterialCustomTablePagination
                                            totalCount = {this.props.totalFilteredCount}
                                            currentPage = {this.state.currentPage}
                                            limit = {this.state.rowsPerPage}
                                            handlePageChange = {this.handlePageChange}
                                            handleRowsPerPageChange = {this.handleRowsPerPageChange}
                                            handlePageOffsetChange = {this.handlePageOffsetChange}
                                            offset = {this.state.offset}
                                            rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                                        />
                                    </div>
                                </TabPane>
                            </TabContent>

                            {/* View Question Modal */}
                            <SelfGradeModal modalId="view-details" dataBackDrop="static" dataKeyboard="false">
                                <ModalHeader>
                                <h4 className="modal-title text-muted">{this.props.libraryQuestion?.title}</h4>
                                <Button className="btn btn-light-primary font-weight-bold" dataDismiss="modal" btnName="Close" />
                                </ModalHeader>
                                {this.state.isViewModalLoading ? (
                                <div>
                                    <div className="loading-container mb-5 mt-10">
                                    <ReactSpinner
                                        animation="border"
                                        role="status"
                                        style={{ display: "block", margin: "auto" }}
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </ReactSpinner>
                                    </div>
                                </div>
                                ) : (
                                <ModalBody>
                                <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                    {/* begin::Section */}
                                    <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-3">Question</h6>
                                    <div className="text-dark-50 line-height-lg col-md-9">
                                        <div>
                                        <ViewEditor
                                            editorState={this.state.editorQuestionState}
                                        />
                                        </div>
                                    </div>
                                    </div>

                                    {/* end::Section */}
                                    {/* begin::Section */}
                                    {this.props.libraryQuestion?.sub_questions === null && (
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-lg-3 col-md-12">Model Answer</h6>
                                        <div className="text-dark-50 line-height-lg col-lg-9 col-md-12">
                                        <div>
                                            {this.props.libraryQuestion?.question_type === questionTypeConstants.DESCRIPTIVE.name && this.props?.libraryAnswerContent &&
                                            <div>
                                                <ViewEditor
                                                    editorState={this.state.editorAnswerStateDescriptive}
                                                />
                                            </div>

                                            }
                                            {this.props.libraryQuestion?.question_type === questionTypeConstants.SUBQUESTION.name && this.props?.libraryAnswerContent && this.props.libraryQuestion?.is_subquestion === "true" &&
                                            <div>
                                                <ViewEditor
                                                    editorState={this.state.editorAnswerStateDescriptive}
                                                />
                                            </div>

                                            }
                                            {this.props.libraryQuestion?.question_type === questionTypeConstants.MCQ.name && this.props?.libraryQuestionContent &&
                                            <div>

                                                {this.state.editorAnswerStates?.length > 0 &&
                                                this.state.editorAnswerStates?.map((editorState, i) => (
                                                    <div className="view-questions-answer d-flex">


                                                    {this.props.libraryQuestion?.answer_type_id === questionMCQAnswerTypeConstants.One.id ? 
                                                        <RadioInline className="col-md-1">
                                                            <Radio checked={this.state.selectedChoices[i]} />
                                                        </RadioInline> 
                                                        :
                                                        <CheckBoxInline className="col-md-1">
                                                            <CheckBox checked={this.state.selectedChoices[i]} />
                                                        </CheckBoxInline> 
                                                    }
                                                    <div className="RichEditor-root">
                                                        <ViewEditor
                                                            editorState={this.state.editorAnswerStates[i]}
                                                        />
                                                    </div>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                            }
                                            {this.props.libraryQuestion?.question_type === questionTypeConstants.SUBMISSION.name && this.state.attachment &&
                                            <div className="RichEditor-root col-lg-8">
                                                {this.state.attachment.map(attach => {
                                                if (attach.replace(/"/g, '') != "[]") {
                                                    return <div>
                                                    <Button className="btn btn-outline-primary primary-button mr-2" btnIconStyle="flaticon-interface-3" inlineStyle={{ innerWidth: '400px' }}
                                                        btnName={attach.replace(/"/g, '')} onClick={(e) => {
                                                        this.onDownloadAttachment(e, attach.replace(/"/g, ''))
                                                        }} />
                                                    <br></br></div>
                                                }
                                                else {
                                                    return <div>
                                                    </div>
                                                }
                                                })
                                                }
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>

                            {this.props?.libraryQuestion?.category_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Category :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'categories' in item).categories 
                                        .find(category => category.category_id === this.props?.libraryQuestion?.category_id)?.name
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.qtype && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Type :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'types' in item).types
                                        .find(type => type.type_id === this.props?.libraryQuestion?.qtype)?.qtype
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.language_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Medium/Language :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'languages' in item).languages
                                        .find(language => language.language_id === this.props?.libraryQuestion?.language_id)?.language
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.difficulty_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Difficulty :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'difficulty_types' in item).difficulty_types
                                        .find(difficulty => difficulty.difficulty_id === this.props?.libraryQuestion?.difficulty_id)?.difficulty_type
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.grade_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Grade :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'grades' in item).grades
                                        .find(grade => grade.grade_id === this.props?.libraryQuestion?.grade_id)?.grade
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.subject_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Subject :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props.allQuestionMetaData.find(item => 'subjects' in item).subjects
                                        .find(subject => subject.subject_id === this.props?.libraryQuestion?.subject_id)?.subject
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.year_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Year :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'years' in item).years
                                        .find(year => year.year_id === this.props?.libraryQuestion?.year_id)?.year
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.term_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Term/Semester :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'terms' in item).terms
                                        .find(term => term.term_id === this.props?.libraryQuestion?.term_id)?.term
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.chapter_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Chapter/Module/Unit :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'chapters' in item).chapters
                                        .find(chapter => chapter.chapter_id === this.props?.libraryQuestion?.chapter_id)?.chapter
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.source_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Source :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'sources' in item).sources
                                        .find(source => source.source_id === this.props?.libraryQuestion?.source_id)?.source
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}

                            {this.props?.libraryQuestion?.author_id && (
                                <>
                                <div className="separator separator-dashed my-5"></div>
                                <div className="row">
                                    <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Author :
                                    </h6>
                                    <div className="text-dark-50 line-height-lg col-md-5">
                                    <div className="inline-flex full-width align-center">
                                        {
                                        this.props?.allQuestionMetaData.find(item => 'authors' in item).authors
                                        .find(author => author.author_id === this.props?.libraryQuestion?.author_id)?.author
                                        }
                                    </div>
                                    </div>
                                </div>
                                </>
                            )}
                                </ModalBody>
                                )}
                            </SelfGradeModal>
                        </CardBody>
                    </Card>
                ) : (
                    <Card>
                        <div>
                            <div className="loading-container mb-5 mt-10" style={{height: "90vh" }}>
                                <ReactSpinner animation="border" role="status" style={{ display: 'block', margin: 'auto' }}>
                                    <span className="sr-only">Loading...</span>
                                </ReactSpinner>
                            </div>
                        </div>
                    </Card>
                )}
                {/* end::Card */}
            </ContentWrapper>
        </AdminTemplate>
    )}
}

function mapStateToProps(state) {
    return {
        allTeachers: state.manageUserReducer.allTeachers,
        teacher: state.manageUserReducer.teacher,
        staffUserRoleMap: state.staffUserReducer.staffUserRoleMap,
        staffUserMap: state.staffUserReducer.staffUserMap,
        allStaffUsers: state.staffUserReducer.allStaffUsers,
        assessment: state.questionReducer.assessment,
        question: state.questionReducer.question,
        libraryQuestion: state.questionsLibraryReducer.question,
        questionContent: state.questionReducer.questionContent,
        libraryQuestionContent: state.questionsLibraryReducer.questionContent,
        libraryAnswerContent: state.questionsLibraryReducer.answerContent,
        questionContents: state.questionReducer.questionContents,
        questionContentsByType: state.questionReducer.questionContentsByType,
        questions: state.questionReducer.questions,
        assessmentQuestions: state.questionReducer.assessmentQuestions,
        assessmentFilteredQuestions: state.questionReducer.assessmentFilteredQuestions,
        totalFilteredCount: state.questionReducer.totalAssessmentFilteredQuestionsCount,
        teachers: state.questionReducer.teachers,
        loading: state.questionReducer.loading,
        finalizeSuccess: state.assessmentReducer.success,
        finalizeError: state.assessmentReducer.error,
        updateSuccess: state.questionReducer.success,
        updateError: state.questionReducer.error,
        allQuestionMetaData: state.questionsCreateReducer.allQuestionMetaData,
    };
}

const mapActionsToProps = {
    createStaffUserMap: staffUserActions.createStaffUserMap,
    refreshPage: questionActions.refreshPage,
    getStaffUsers: staffUserActions.getAllStaffUsers,
    getAllTeachers: manageUserActions.getAllTeachers,
    getAllQuestions: questionActions.getAllQuestions,
    getAllLibraryQuestions: libraryActions.getAllQuestions,
    getQuestionContent: questionActions.getQuestionContent,
    getLibraryQuestionContent: libraryActions.getQuestionContent,
    getLibraryAnswerContent: libraryActions.getAnswerContent,
    refreshforAddingQuestions: questionActions.refreshforAddingQuestions,
    getAssessmentQuestions: questionActions.getAssessmentQuestions,
    getAllAssessmentQuestions: questionActions.getAllAssessmentQuestions,
    getAllQuestionContentsByType: questionActions.getAllQuestionContentsByType,
    getAllQuestionContents: questionActions.getAllQuestionContents,
    removeMyQuestions: questionActions.removeMyQuestions,
    saveUpdates: questionActions.saveUpdates,
    finalizeAssessment: assessmentActions.finalizeAssessment,
    applyPositionChangeLocal: questionActions.applyPositionChangeLocal,
    getQuestionMetaData: questionCreateActions.getQuestionMetaData,
    getTeacherMe: manageUserActions.getTeacherMe,
};

export default connect(mapStateToProps, mapActionsToProps)(Finalize);
