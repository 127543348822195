import { UserManagementService } from '../../service/user_management/user_management.service';
import {userTypes} from '../../reducers/types';
import DataHandler from '../../handlers/DataHandler';
import { history } from '../../helper/history';
import {store} from "../../helper/redux_store";
import { UMAuthAgent } from "../../authentication/UMAuthAgent";
import { MicrosoftLoginAgent } from "../../authentication/MicrosoftLoginAgent";
import {authenticationConstants} from '../../helper/constants'
import { roleService } from '../../service/user_management/role.service';
import { alertActions } from './alertActions';

export const userActions = {
    logout,
    userlogout,
    staffLogout,
    getAllUsers,
    createUserMap,
    createUserRoleMap,
    getAllRoles
};

export function logout() {
    DataHandler.clearSession();
    UMAuthAgent.clearUser();
    MicrosoftLoginAgent.logout();
    history.push('/');
    window.location.reload();
}

function userlogout() {
    return dispatch => {
        try {
            dispatch(request());
            MicrosoftLoginAgent.logout();
            UserManagementService.logout();
            success()
            window.location.reload(true);
        } catch (e) {
            failure(e)
        }
    }

    function request() {
        return {type: authenticationConstants.LOGOUT_REQUEST}
    }

    function success() {
        return {type: authenticationConstants.LOGOUT_SUCCESS}
    }

    function failure(error) {
        return {type: authenticationConstants.LOGOUT_FAILURE, error}
    }
}

async function staffLogout() {
    return async dispatch => {
        try {
            dispatch(request());
            //MicrosoftLoginAgent.logout();
            await UserManagementService.staffLogout();
            success()
            window.location.reload(true);
        } catch (e) {
            failure(e)
        }
    }

    function request() {
        return {type: authenticationConstants.LOGOUT_REQUEST}
    }

    function success() {
        return {type: authenticationConstants.LOGOUT_SUCCESS}
    }

    function failure(error) {
        return {type: authenticationConstants.LOGOUT_FAILURE, error}
    }
}

function getAllUsers() {
    return async dispatch => {
        try {
            dispatch(get_users());
            dispatch(get_users_request());
            let users = await UserManagementService.getAllUsers();
            dispatch(get_users_success(users.data));
        } catch (error) {
            dispatch(get_users_failure(error));
        }
    };

    function get_users() {
        return {type: userTypes.GET_ALL_USERS}
    }

    function get_users_request() {
        return {type: userTypes.GET_ALL_USERS_REQUEST}
    }

    function get_users_success(data) {
        return {type: userTypes.GET_ALL_USERS_SUCCESS, users: data}
    }

    function get_users_failure(error) {
        return {type: userTypes.GET_ALL_USERS_FAILURE, error:error}
    }
}

function createUserMap() {
    return async dispatch => {
        try {
            dispatch(get_users_request());
            let data = await UserManagementService.getAllUsers();
            dispatch(get_users_success(data.data));
            await create_user_map(data.data);
        } catch(error) {
            dispatch(get_users_failure(error));
        }
    }

    async function create_user_map(users) {
        let map = {}
        let user;
        for (user of users) {
            map[user['user_id']] = user
        }

        let user_data = {map, users}
        store.dispatch({type: userTypes.CREATE_USER_MAP, user_data});
    }

    function get_users_request() {
        return {type: userTypes.GET_ALL_USERS_REQUEST}
    }

    function get_users_success(data) {
        return {type: userTypes.GET_ALL_USERS_SUCCESS, users: data}
    }

    function get_users_failure(error) {
        return {type: userTypes.GET_ALL_USERS_FAILURE, error}
    }
}

function createUserRoleMap() {
    return async dispatch => {

        try {
            dispatch(get_user_roles_request());
            let user_roles = await UserManagementService.getUserRoles();
            dispatch(get_user_roles_success(user_roles.data));
            dispatch(create_user_roles_map(user_roles.data));
        } catch (error) {
            dispatch(get_user_roles_failure(error));
        }
    }

    function get_user_roles_request() {
        return {type: userTypes.GET_USER_ROLES_REQUEST}
    }

    function get_user_roles_success(data) {
        return {type: userTypes.GET_USER_ROLES_SUCCESS, data}
    }

    function create_user_roles_map(user_roles) {
        let map = {}
        let user_role;
        for (user_role of user_roles) {
            map[user_role['user_role_id']] = user_role
        }
        return {type: userTypes.CREATE_USER_ROLES_MAP, map}
    }

    function get_user_roles_failure(error) {
        return {type: userTypes.GET_USER_ROLES_FAILURE, error}
    }
}

function getAllRoles() {
    return async dispatch => {
        dispatch(request());

        await roleService.getAll()
            .then(
                roles => dispatch(success(roles.data)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request() {
        return {type: userTypes.GET_ALL_ROLES_REQUEST}
    }

    function success(data) {
        return {type: userTypes.GET_ALL_ROLES_SUCCESS, data}
    }

    function failure(error) {
        return {type: userTypes.GET_ALL_ROLES_FAILURE, error}
    }
}