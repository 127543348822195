import React from "react";

export default function SelfGradeModalFooter({children}) {

    return (
        
        <div className="modal-footer">
            {children}
        </div>

    )
}