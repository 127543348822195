import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../../../components/admin/css/common.css";
import sweetAlert, {
  sweetStatusAlert,
  sweetConfirmAlert
} from "../../../components/common/SweetAlert.js";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import SimpleReactValidator from "simple-react-validator";
import { TitleService } from "../../../service/settings/manage/title.service.js";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import config from '../../../helper/config.js';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
      showModal: false,
      title_id: "",
      name: "",
      description: "",
      titles: [],
      isModified: false,
      showInsertModal: false,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      totalTitles: 10,
      isTableDataLoading: true,
      currentPage: 0,
      editVisibility: true,
      addVisibility: true,
      deleteVisibility: true,
      isLoading: false,
    };

    // Debounced version of handleSearch function
    this.debouncedHandleSearch = debounce(this.fetchTitlesData, 2000);
  }

  // Reset the form fields to initail values on update, insert, edit modal close and add modal close
  resetFormFields = () => {
    this.setState({
      name: "",
      description: "",
    });
  };

  componentDidMount() {
    this.fetchTitlesData();
  }

  // Creating validator instance without any custom rules
  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  //fetching available titles count for pagination using specific search_term
  fetchTitlesData = async () => {
    const { limit, offset, search_term } = this.state;
    await TitleService.getTitlesByPagination(limit, offset, search_term)
      .then((response) => {
        if (response.success) {
          this.setState({
            titles: response.data.titles,
            totalTitles: parseInt(response.data.count),
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Titles. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        sweetStatusAlert(
          "Failed!",
          "Error fetching Titles. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false }); // Set isTableDataLoading to false after data is fetched
      });
  };

  // Handle the data fetching as per the offset(page no) changes
  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchTitlesData();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.fetchTitlesData();
      }
    );
  };

  // handle the data fetching as per the data per page limit gets changess
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchTitlesData();
      }
    );
  };

  // handle search text as per the regex validation and search_term , and fetch data as per the debounce
  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  // Toggle out the state of add form modal
  toggleAddTitleModal = () => {
    this.setState((prevState) => ({
      showInsertModal: !prevState.showInsertModal,
    }));
  };

  // Toggle out the state of update form modal
  toggleUpdateTitleModal = (title_id, name, description) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      title_id: title_id,
      name: name,
      description: description,
    }));
  };

  // Toggle out the state of detail form modal
  toggleTitleDetailsModal = () => {
    this.setState((prevState) => ({
      showDetailModal: !prevState.showDetailModal,
    }));
  };

  // Sets the value of a specific state based on the value provided to the respective element based on its assigned name
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  // Pop up a sweet alert on edit or add modal and change its toggle state based on the input given to that sweet alert
  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.hideMessages();
        this.validator.purgeFields();
        this.resetFormFields();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.hideMessages();
      this.validator.purgeFields();
      this.resetFormFields();
      toggleFunction();
    }
  };

  // Make post request based on the fields validity and create the subjects based on the state
  handleSubmit = async (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });

      const { name, description } = this.state;
      const newTitle = { name: name, description: description };

      await TitleService.create(newTitle)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.success) {
            this.setState({ showInsertModal: false });
            sweetStatusAlert("Success!", response.message, "success");
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.fetchTitlesData();
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed !", "Unable to create new title", "error");
        });
    }
  };

  // Make put request based on the fields validity and updation of necessary states
  handleUpdateSubmit = async (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });

      const titleItem = {
        name: this.state.name,
        description: this.state.description,
      };

      const titleId = this.state.title_id;
      await TitleService.update(titleItem, titleId)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.success) {
            this.setState({ showModal: false });
            sweetStatusAlert(
              "Success!",
              "Title information updated successfully..!",
              "success"
            );
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.fetchTitlesData();
          } else {
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed !",
            "Unable to update the title information",
            "error"
          );
        });
    }
  };

  handleDeleteTitle = (id) => {
    sweetConfirmAlert().then(() => {
      this.setState({ isLoading: true });
      TitleService.deleteTitle(id)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.success) {
            sweetStatusAlert("Success!", response.message, "success");
            this.fetchTitlesData();
          } else {
            sweetStatusAlert(
              "Oops!",
              "This title is in use. You cannot delete it.",
              "info"
            );
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetStatusAlert("Oops!", "Error while deleting the title", "info");
        });
    });
  };

  //Rendering the necessary components based on the logic
  render() {
    const {
      limit,
      titles,
      totalTitles,
      isTableDataLoading,
      currentPage,
      editVisibility,
      addVisibility,
      deleteVisibility,
      isLoading,
    } = this.state;
    this.validator.purgeFields();
    return (
      <>
        <ContentWrapper>
          <div className="row justify-content-between align-items-center py-5 mb-3">
            <div className="col-md-6 col-6">
              <h3 className="text-nowrap">Manage Title</h3>
            </div>
            <div>
              {addVisibility && (
                <Button
                  className="insert-btn"
                  variant="primary"
                  onClick={() => this.toggleAddTitleModal(true)}
                >
                  Add Title
                </Button>
              )}
            </div>
          </div>
          <div>
            <Form.Group controlId="exampleForm.ControlInput1" className="mb-2">
              <Form.Control
                type="text"
                placeholder="Search"
                onChange={this.handleSearch}
                isInvalid={this.state.searchError}
                // style={{ width: "18%" }}
                className="col-md-3 col-12"
              />
              {this.state.searchError && (
                <Form.Control.Feedback type="invalid">
                  Invalid input
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div
                className="loading-container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "75vw",
                  height: "90vh",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : titles.length > 0 ? (
              <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="thead">No</TableCell>
                      <TableCell className="thead">Title Name</TableCell>
                      <TableCell className="thead">Title Description</TableCell>
                      {(editVisibility || deleteVisibility) && (
                        <TableCell className="thead">Actions</TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {titles.map((titleItem) => (
                      <TableRow key={titleItem.title_id}>
                        <TableCell className="tData">
                          {titleItem.title_id}
                        </TableCell>
                        <TableCell className="tData">
                          {titleItem.name}
                        </TableCell>
                        <TableCell className="tData">
                          {titleItem.description}
                        </TableCell>
                        {(editVisibility || deleteVisibility) && (
                          <TableCell>
                            <div className="btn-group">
                              {editVisibility && (
                                <Button
                                  onClick={() =>
                                    this.toggleUpdateTitleModal(
                                      titleItem.title_id,
                                      titleItem.name,
                                      titleItem.description
                                    )
                                  }
                                  className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                  title="Edit Details"
                                />
                              )}
                              {deleteVisibility && (
                                <Button
                                  onClick={() =>
                                    this.handleDeleteTitle(titleItem.title_id)
                                  }
                                  className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                                  title="Delete"
                                />
                              )}
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <MaterialCustomTablePagination
                  totalCount = {totalTitles}
                  currentPage = {currentPage}
                  limit = {limit}
                  handlePageChange = {this.handlePageChange}
                  handleRowsPerPageChange = {this.handleRowsPerPageChange}
                  handlePageOffsetChange = {this.handlePageOffsetChange}
                  offset = {this.state.offset}
                  rowsPerPageOptions={[5, 10, 20]}
                />
              </div>
            ) : (
              <div
                className="text-center mb-5 mt-10"
                style={{ height: "90vh" }}
              >
                <label>No Titles Available!</label>
              </div>
            )}
          </div>
        </ContentWrapper>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() =>
            this.handleSweetAlert(this.toggleAddTitleModal, "showInsertModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Title </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title Name"
                    name="name"
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "name",
                    this.state.name,
                    "required|alpha_space|max:30",
                    {
                      className: "text-danger",
                      messages: {
                        required: "The title name field is required.",
                        max: "The title name should not be greater than 30 characters.",
                        alpha_space:
                          "The title name must have valid characters.",
                      },
                    }
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    name="description"
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "description",
                    this.state.description,
                    "alpha_space|max:100",
                    {
                      className: "text-danger",
                      messages: {
                        max: "The title description should not be greater than 100 characters.",
                        alpha_space:
                          "The title description must have valid characters.",
                      },
                    }
                  )}
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              style={addBtnStyle}
              onClick={this.handleSubmit}
            >
              Save changes
            </Button>
            <Button
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={() =>
                this.handleSweetAlert(
                  this.toggleAddTitleModal,
                  "showInsertModal"
                )
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={() =>
            this.handleSweetAlert(this.toggleUpdateTitleModal, "showModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Title Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title Name *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Title Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "name",
                    this.state.name,
                    "required|alpha_space|max:30",
                    {
                      className: "text-danger",
                      messages: {
                        required: "The title name field is required.",
                        max: "The title name should not be greater than 30 characters.",
                        alpha_space:
                          "The title name must have valid characters.",
                      },
                    }
                  )}
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                  />
                  {this.validator.message(
                    "description",
                    this.state.description,
                    "alpha_space|max:100",
                    {
                      className: "text-danger",
                      messages: {
                        max: "The title description should not be greater than 100 characters.",
                        alpha_space:
                          "The title description must have valid characters.",
                      },
                    }
                  )}
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="modal-btn"
              variant="primary"
              style={addBtnStyle}
              onClick={this.handleUpdateSubmit}
            >
              Save changes
            </Button>
            <Button
              className="modal-btn"
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={() =>
                this.handleSweetAlert(this.toggleUpdateTitleModal, "showModal")
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showDetailModal}
          onHide={this.toggleTitleDetailsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Title Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Title Name</Form.Label>
                  <Form.Control type="text" placeholder="Title Name" />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="Discription" />
                </Form.Group>
              </Row>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Status</Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option>Select</option>
                    <option value="1">Active</option>
                    <option value="2">Deactive</option>
                  </Form.Select>
                </Form.Group>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="modal-btn"
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={this.toggleTitleDetailsModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
