import React, { useEffect } from 'react';

export default function FinalizeNavItemActions ({ children }) {
    return (
        <div className="pt-6 pl-5 pb-2 col-12">
            {children} 
        </div>
    )
}


