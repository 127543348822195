import React, { Component } from "react";
import Card from "../../../../components/common/Card.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardBody from "../../../../components/common/CardBody.js";
import CardTitle from "../../../../components/common/CardTitle.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../../components/common/MaterialCustomTablePagination.js";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "../../../../components/admin/css/common.css";
import { sweetStatusAlert } from "../../../../components/common/SweetAlert.js";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { TransactionService } from "../../../../service/manage/subscription/transaction.service.js";
import TimeZoneHandler from "../../../../handlers/TimeZoneHandler.js";
import debounce from "lodash.debounce";
import { CreditService } from "../../../../service/manage/subscription/credit.service.js";
import PageSpinner from "../../../../components/common/spinner/PageSpinner.js";

export default class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: false,
      isStudentListLoading: false,
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      transactions: "",
      showModal: false,
      selectedTransaction: null,
      transactionsPrivilege: null,
      currentPage: 0,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      totalTransactions: 10,
      search_term: "",
      isDropHidden: false,
      studentlist: [],
      selectedStudent: "",
      userType: "",
      userIDs: "",
    };
    this.debouncedHandleSearchAll = debounce(this.getAllTransactions, 1000);
    this.debouncedHandleSearchUser = debounce(
      this.getAllTransactionsByUser,
      1000
    );
  }

  componentDidMount() {
    const { breadcrumb2, nodeTitle, flg_list_active } = this.props.props;
    const userType = sessionStorage.getItem("userType");

    this.setState(
      {
        breadcrumb2: breadcrumb2,
        nodeTitle,
        flg_list_active,
        transactionsPrivilege: this.props.transactionsPrivilege,
        userType: userType,
      },
      () => {
        const { transactionsPrivilege } = this.state;
        if (transactionsPrivilege.isAdmin) {
          this.getAllTransactions();
        } else if (transactionsPrivilege.isStudent) {
          this.getAllTransactionsByUser();
        } else if (transactionsPrivilege.isParent) {
          if (flg_list_active === 1) {
            this.setState({ isDropHidden: true });
          }
          this.fetchallstudent();
        }
      }
    );
  }

  getAllTransactions() {
    const mid = 100;
    const { limit, offset, search_term } = this.state;
    this.setState({ isTableDataLoading: true });
    TransactionService.getAllTransactions(mid, limit, offset, search_term)
      .then((res) => {
        if (res.success) {
          this.setState({
            transactions: res.data.transactions,
            totalTransactions: parseInt(res.data.count),
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Transactions. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getAllTransactionsByUser() {
    const mid = 100;
    const { limit, offset, search_term } = this.state;
    const user_id = sessionStorage.getItem("manage_user_id");
    this.setState({ isTableDataLoading: true });
    TransactionService.getAllTransactionsByUser(
      mid,
      user_id,
      limit,
      offset,
      search_term
    )
      .then((res) => {
        if (res.success) {
          this.setState({
            transactions: res.data.transactions,
            totalTransactions: parseInt(res.data.count),
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Transactions. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getAllTrasactionByParent(user_id) {
    const mid = 100;
    const { limit, offset, search_term } = this.state;
    this.setState({ isTableDataLoading: true });
    TransactionService.getAllTransactionsByUser(
      mid,
      user_id,
      limit,
      offset,
      search_term
    )
      .then((res) => {
        if (res.success) {
          this.setState({
            transactions: res.data.transactions,
            totalTransactions: parseInt(res.data.count),
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Transactions. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  handleRowClick = (transaction) => {
    this.setState({
      showModal: true,
      selectedTransaction: transaction.transaction,
    });
  };

  handleModalClose = () => {
    this.setState({
      showModal: false,
      selectedTransaction: null,
    });
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        const { transactionsPrivilege } = this.state;
        if (transactionsPrivilege.isAdmin) {
          this.getAllTransactions();
        } else {
          this.getAllTransactionsByUser();
        }
      }
    );
  };
  fetchallstudent = () => {
    const { mid, lg_user_id, lg_user_table_id } = this.props.props;
    const { search_term } = this.state;
    let form_list_id = null;

    if (mid === 100) {
      form_list_id = 29;
    } else {
      form_list_id = this.props.props.form_list_id;
    }

    this.setState({ isStudentListLoading: true });
    CreditService.getAllStudentsForParent(
      mid,
      form_list_id,
      sessionStorage.getItem("parent_group_id"),
      sessionStorage.getItem("parent_group_user_table_id"),
      search_term,
      "parent"
    )
      .then((response) => {
        if (response.success) {
          const userIDs = response.data.students.map(
            (student) => student.user_id
          );
          this.setState({
            studentlist: response.data.students,
            userIDs: userIDs,
          });
          this.getAllTrasactionByParent(userIDs);
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching students. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching students. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isStudentListLoading: false });
      });
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        const { transactionsPrivilege } = this.state
        if (transactionsPrivilege.isAdmin) {
          this.getAllTransactions()
        }
        else {
          this.getAllTransactionsByUser()
        }
      }
    );
  };

  // handle the data fetching as per the data per page limit gets changes
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        const { transactionsPrivilege } = this.state;
        if (transactionsPrivilege.isAdmin) {
          this.getAllTransactions();
        } else {
          this.getAllTransactionsByUser();
        }
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();

    this.setState(
      {
        search_term,
        isTableDataLoading: true,
        offset: 0,
        currentPage: 0,
      },
      () => {
        const { transactionsPrivilege } = this.state;
        if (transactionsPrivilege.isAdmin) {
          this.debouncedHandleSearchAll();
        } else {
          this.debouncedHandleSearchUser();
        }
      }
    );
  };
  handleStudentSelect = (event) => {
    if (!event.target.value) {
      this.setState({ selectedStudent: "" });
      this.getAllTrasactionByParent(this.state.userIDs);
      return;
    }
    let student = this.state.studentlist.find(
      (student) => student.user_id === event.target.value
    );
    this.setState({ isTableDataLoading: true });
    const selectedStudent = student.user_id;
    if (selectedStudent) {
      this.setState({ selectedStudent }, () => {
        this.getAllTrasactionByParent(selectedStudent);
      });
    }
  };

  clearStudentFilter = () => {
    this.setState({ selectedStudent: "" });
    this.getAllTrasactionByParent(this.state.userIDs);
  };

  render() {
    const {
      isTableDataLoading,
      transactions,
      showModal,
      selectedTransaction,
      transactionsPrivilege,
      currentPage,
      limit,
      totalTransactions,
      selectedStudent,
      isDropHidden,
      studentlist,
      isStudentListLoading,
      userType,
    } = this.state;

    if (!transactionsPrivilege) {
      return (
        <div>
          <h4>Privilege not found for this section</h4>
        </div>
      );
    }
    return (
      <>
        <Card>
          {userType !== "student" && (
            <CardHeader>
              {userType === "admin" && (
                <div>
                  {/* <CardTitle cardLabel={history.location.state.form_display_title} /> */}
                  <CardTitle cardLabel={"Transactions"} />
                  {/* <h3 style={{ color: 'gray' }}>{(breadcrumb2 || nodeTitle) ? `(${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle})` : undefined}</h3> */}
                </div>
              )}
              {isDropHidden ? (
                <div className="col-md-12 d-flex justify-content-start align-items-center px-0">
                  <div className="dropdown mb-2 px-0 col-md-4">
                    <>
                      {userType === "parent" && !selectedStudent && (
                        <h3 className="thead">Please select the student</h3>
                      )}
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="exampleForm.ControlInput3"
                        className="px-0"
                      >
                        <Form.Select
                          aria-label="Select the student"
                          name="student_id"
                          value={selectedStudent}
                          onChange={this.handleStudentSelect}
                        >
                          <option
                            value=""
                            style={{ fontFamily: "Poppins, sans-serif" }}
                          >
                            All Students
                          </option>
                          {studentlist.map((student) => (
                            <option key={student.user_id} value={student.user_id}>
                              {student.data_concat}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </>
                  </div>
                  {selectedStudent && (
                    <Button
                      variant="secondary"
                      onClick={this.clearStudentFilter}
                      className="ml-5"
                    >
                      Clear
                    </Button>
                  )}
                </div>
              ) : null}

            </CardHeader>
          )}
          <CardBody>
            {transactions && (
              <div className="row">
                {transactionsPrivilege.isAdmin && (
                  <div className="col-md-6 d-flex align-items-end">
                    <Form.Group
                      controlId="exampleForm.ControlInput1"
                      className="col-md-8 mb-2 px-0"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Search"
                        onChange={this.handleSearch}
                        className="search-bar"
                      />
                    </Form.Group>
                  </div>
                )}
              </div>
            )}
            {isStudentListLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            {isTableDataLoading ? (
              <div className="loading-container">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div>
                {!isStudentListLoading && transactions.length === 0 ? (
                  <Form.Label
                    style={{ fontSize: "large", marginBottom: "0" }}
                    className="pt-5"
                  >
                    No Transactions Found!
                  </Form.Label>
                ) : (
                  transactions && (
                    <div style={{ overflow: "Auto" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className="thead"
                              style={{ textAlign: "center" }}
                            >
                              {transactionsPrivilege.isAdmin
                                ? "Transaction Id"
                                : "Confirmation No"}
                            </TableCell>
                            <TableCell
                              className="thead"
                              style={{ textAlign: "center" }}
                            >
                              Type
                            </TableCell>
                            <TableCell
                              className="thead"
                              style={{ textAlign: "center" }}
                            >
                              No Of Credits
                            </TableCell>
                            <TableCell
                              className="thead"
                              style={{ textAlign: "center" }}
                            >
                              Expiration Date
                            </TableCell>
                            <TableCell
                              className="thead"
                              style={{ textAlign: "center" }}
                            >
                              Amount
                            </TableCell>
                            {transactionsPrivilege.isAdmin && (
                              <TableCell
                                className="thead"
                                style={{ textAlign: "center" }}
                              >
                                Currency Type
                              </TableCell>
                            )}
                            <TableCell
                              className="thead"
                              style={{ textAlign: "center" }}
                            >
                              Paid By
                            </TableCell>
                            <TableCell
                              className="thead"
                              style={{ textAlign: "center" }}
                            >
                              Action
                            </TableCell>
                            {userType !== "student" && (
                              <TableCell
                                className="thead"
                                style={{ textAlign: "center" }}
                              >
                                Student No
                              </TableCell>
                            )}
                            {userType !== "student" && (
                              <TableCell
                                className="thead"
                                style={{ textAlign: "center" }}
                              >
                                Student Name
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {transactions.length > 0 &&
                            transactions.map((transaction) => (
                              <TableRow>
                                <TableCell
                                  className="tData"
                                  style={{ textAlign: "center" }}
                                >
                                  {transaction.transaction_id}
                                </TableCell>
                                <TableCell
                                  className="tData"
                                  style={{ textAlign: "center" }}
                                >
                                  {transaction.type}
                                </TableCell>
                                <TableCell
                                  className="tData"
                                  style={{ textAlign: "center" }}
                                >
                                  {transaction.credits}
                                </TableCell>
                                <TableCell
                                  className="tData"
                                  style={{ textAlign: "center" }}
                                >
                                  {TimeZoneHandler.convertTime(
                                    transaction.expiration_date
                                  )}
                                </TableCell>
                                <TableCell
                                  className="tData"
                                  style={{ textAlign: "center" }}
                                >
                                  {transaction.amount}
                                </TableCell>
                                {transactionsPrivilege.isAdmin && (
                                  <TableCell
                                    className="tData"
                                    style={{ textAlign: "center" }}
                                  >
                                    {transaction.currencyType}
                                  </TableCell>
                                )}
                                <TableCell
                                  className="tData"
                                  style={{ textAlign: "center" }}
                                >
                                  {transaction.paid_by}
                                </TableCell>
                                <TableCell
                                  className="tData"
                                  style={{ textAlign: "center" }}
                                >
                                  <Button
                                    className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-eye-icon"
                                    title="View Note"
                                    key={transaction.transaction_id}
                                    onClick={() => this.handleRowClick(transaction)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </TableCell>
                                {userType !== "student" && (
                                  <TableCell
                                    className="tData"
                                    style={{ textAlign: "center" }}
                                  >
                                    {transaction.user_code}
                                  </TableCell>
                                )}
                                {userType !== "student" && (
                                  <TableCell
                                    className="tData"
                                    style={{ textAlign: "center" }}
                                  >
                                    {transaction.student}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                      <MaterialCustomTablePagination
                        totalCount={totalTransactions}
                        currentPage={currentPage}
                        limit={limit}
                        handlePageChange={this.handlePageChange}
                        handleRowsPerPageChange={this.handleRowsPerPageChange}
                        handlePageOffsetChange={this.handlePageOffsetChange}
                        rowsPerPageOptions={[5, 10, 20]}
                        offset={this.state.offset}
                      />
                    </div>
                  )
                )}
              </div>
            )}
          </CardBody>
        </Card>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transaction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedTransaction && (
              <Form>
                {Object.entries(selectedTransaction).map(([key, value]) => (
                  <Row className="my-3" key={key}>
                    <Form.Label as={Col} md="3">
                      {key}
                    </Form.Label>
                    <Form.Label as={Col} md="9">
                      {key == "Exp. Date" || key == "Purchase Date"
                        ? TimeZoneHandler.convertTime(value)
                        : value}
                    </Form.Label>
                  </Row>
                ))}
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
