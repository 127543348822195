import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Calender from './CoverPageComponents/Calender';
import Button from '@mui/material/Button';
import './CoverPageComponents/CoverPage.css';
import CGCLogo from './CoverPageComponents/CGC logo.png';
import CoverImage from './CoverPageComponents/Cover image.png';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Menu_board from './CoverPageComponents/icons/menu-board.png';
import Menu_board_blue from './CoverPageComponents/icons/menu-board-blue.png';
import Hand from './CoverPageComponents/icons/hand.png';
import announcement from './CoverPageComponents/icons/announcement.png';
import graduate from './CoverPageComponents/icons/graduate.png';
import abe from './CoverPageComponents/abe.jpeg';
import CMA from './CoverPageComponents/CMA.jpeg';
import qualify from './CoverPageComponents/qualify.jpeg';
import lobal from './CoverPageComponents/lobalSchoalrs.png';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AnnouncementPopup from './AnnouncementPopup';
import ViewAll from './AllAnnouncements';
import { history } from "../../../helper/history"
import { routes } from '../../../helper/routes';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const handleRoleSelect = (role) => {
  sessionStorage.setItem("user_role_selection", role)
  history.push(routes.auth_response)
}

export default function BasicGrid() {
  return (
    <div>
      <Grid item xs={12}>
        <div className="headerBar"></div>
      </Grid>
      <div className="body">
        <Grid container>
          <Grid item xs={12} className="content01">
            <div className="alignItemsCenter">
              <Grid item xs={1}>
                <img src={CGCLogo} className="cgclogo" />
              </Grid>
              <Grid item xs={8}>
                <div className="row justifyContentCenter welcome">
                  <img src={Hand} className='hand'/><div className="text01">Welcome to Colombo Graduate Campus Learning management system!</div>
                </div>
              </Grid>
              <Grid item lg={3} md={3} sm={0} xs={0}>
                <div className="row alignItemsCenter justifyContentRight">
                  <div className="personIcon center"><PersonIcon className="PIcon" /></div>
                  <Button className="button1" variant="contained" onClick={() =>handleRoleSelect("student")}>
                    <p className='btntxt'>Student Login</p>
                    <ArrowForwardIcon />
                  </Button>
                </div>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <hr className="hr" />
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <img src={CoverImage} className="cover" />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="cursive text02 justifyContentCenter">Limitless Learning At Your Fingerprints</div>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="links alignItemsCenter">
                <img src={Menu_board} className="menu_board" />Important links
              </div>
            </Grid>
            <Grid container>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="alignItemsCenter link text03">
                  <img src={Menu_board_blue} className="menu_board" />
                  Harvard Referencing System
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="alignItemsCenter link text03">
                  <img src={Menu_board_blue} className="menu_board" />
                  Student Handbook
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="alignItemsCenter link text03">
                  <img src={Menu_board_blue} className="menu_board" />
                  Assignment Cover page
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="alignItemsCenter link text03">
                  <img src={Menu_board_blue} className="menu_board" />
                  Student Progression route
                </div>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <div className="alignItemsCenter link text03">
                  <img src={Menu_board_blue} className="menu_board" />
                  Scholarships Opportunities
                </div>
              </Grid>
            </Grid>
            <hr className="hr" />
            <div className='row edu center'>
              <img className="study" src={abe} />
              <img className="study" src={CMA} />
              <img className="study" src={qualify} />
              <img className="study" src={lobal} />
            </div>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <hr className="hr" />
            <Grid item xs={12}>
              <div className="sets"><Calender /></div>
            </Grid>
            <Grid item xs={12} className="sets ">
              <div className="justifyContentLeft column">
                <div className="text03 row alignItemsCenter">
                  <img src={announcement} />&thinsp;Latest announcements
                </div>
                <div><hr className="hr2 justifyContentLeft" /></div>
                <div><AnnouncementPopup/></div>
                <div className="text04 justifyContentRight"><ViewAll/></div>
              </div>
            </Grid>
            <Grid item xs={12} className="sets ">
              <div className="justifyContentLeft column">
                <div className="text03 row alignItemsCenter">
                  <img src={graduate} />&thinsp;Course categories
                </div>
                <div><hr className="hr2 justifyContentLeft" /></div>
                <div className="text03 courses row"><img src={graduate} />MBA</div>
                <div className="text03 courses row"><img src={graduate} />Accounting</div>
                <div className="text03 courses row"><img src={graduate} />Finance</div>
                <div className="text04 justifyContentRight"></div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
