import React from "react";

export default function PageSpinner({ id, text }) {
  return (
    <div className="spinner-wrap" id={id}>
      <div className="spinner-load">
        <div className="blockui">
          <span>{text}</span>
          <span className="spinner spinner-track spinner-primary"></span>
        </div>
      </div>
    </div>
  );
}
