import React from 'react';

export default function ContentWrapper({ children }) {
    return (
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

            {/* begin::Entry */}
            <div className="d-flex flex-column-fluid">
                {/* begin::Container */}
                <div className="container-fluid">
                    {children}
                </div>
                {/* end::Container */}
            </div>
            {/* end::Entry */}
        </div>
    )
}