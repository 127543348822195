import React from 'react';

export default function QuestionCardTitle({ cardLabel, cardBottomLable, children }) {
        // var currentDay = new Date();
        // var days = ["Sun,", "Mon,", "Tue,", "Wed,", "Thu,", "Fri,", "Sat,"];
        // var displayDay = days[currentDay.getDay()];

        // var currentDate = new Date();
        // var year = currentDate.getFullYear();
        // var date = currentDate.getDate();
        // var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // var month = months[currentDate.getMonth()];
        // if (date < 10) {
        //     date = "0" + date;
        // }

        // var displayDate = month + "  " + date + " , " + year;

        // var ampm = currentDate.getHours( ) >= 12 ? ' PM' : ' AM';
        // var displayClock = currentDate.getHours() + ":" + currentDate.getMinutes() + ":" +  currentDate.getSeconds() + ":" + ampm;
        
    return (
        <div className="card-title">
            <h3 className="card-label">{cardLabel}</h3>
            
            <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
            <span className="text-muted font-weight-bold mr-4 font-size-sm">{cardBottomLable}</span>

            {/* begin::Clock */}
            {/* <div id="info-box">
                <h3 className="day">{displayDay} {displayDate}<h3 id="clock"></h3></h3>
            </div> */}
            {/* end::Clock */}

            {children}
        </div>
    )
}
