import React from 'react';

export function Terms() {
  return (
    <div>
      These are the Terms and Conditions governing the use of this Service and
      the agreement that operates between You and the Company. These Terms and
      Conditions set out the rights and obligations of all users regarding the
      use of the Service.
      <br></br>
      <br></br>
      Your access to and use of the Service is also conditioned on Your
      acceptance of and compliance with the Privacy Policy of the Company. Our
      Privacy Policy describes Our policies and procedures on the collection,
      use and disclosure of Your personal information when You use the
      Application or the Website and tells You about Your privacy rights and how
      the law protects You. Please read Our Privacy Policy carefully before
      using Our Service.
      <br></br>
      <br></br>
      <ul>
        <li>
          You are responsible for safeguarding the password that You use to
          access the Service and for any activities or actions under Your
          password, whether Your password is with Our Service or a Third-Party
          Social Media Service.
        </li>
        <br></br>
        <li>
          You agree not to disclose Your password to any third party. You must
          notify Us immediately upon becoming aware of any breach of security or
          unauthorized use of Your account.
        </li>
        <br></br>
        <li>
          You represent and warrant that: (i) the Content is Yours (You own it)
          or You have the right to use it and grant Us the rights and license as
          provided in these Terms, and (ii) the posting of Your Content on or
          through the Service does not violate the privacy rights, publicity
          rights, copyrights, contract rights or any other rights of any person.
        </li>
        <br></br>
        <li>
          Although regular backups of Content are performed, the Company does
          not guarantee there will be no loss or corruption of data.
        </li>
        <br></br>
        <li>
          The Company will provide support and attempt to troubleshoot any known
          or discovered issues that may affect the backups of Content. But You
          acknowledge that the Company has no liability related to the integrity
          of Content or the failure to successfully restore Content to a usable
          state.
        </li>
        <br></br>
        <li>
          We may terminate or suspend Your Account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if You breach these Terms and Conditions
        </li>
      </ul>
      <br></br>
    </div>
  );
}
