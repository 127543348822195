import React from 'react';
import './Fraction.css';

const Fraction = ({ numerator, denominator }) => {
  return (
    <span className="fraction">
      <span className="numerator">{numerator}</span>
      <span className="denominator">{denominator}</span>
    </span>
  );
};

export default Fraction;