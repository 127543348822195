import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const TreeMainService = {
  getHierarchy,
  deleteHierarchyNode,
  insertHierarchyNode,
  updateNodeDetails,
  getRegistrationInfo,
  getAllCalendarContent,
  getNodeForm,
  getSiteMap,
  getHierarchyForms,
  getUserType,
  getNodeFormsInfo,
  getNodesInfo
};

async function getNodeForm(node_id, user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/getNodeForm/${node_id}/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getHierarchyForms(child_id, user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-forms/${child_id}/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function insertHierarchyNode(child) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(child),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getHierarchy(child_id, exclude_code, user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-list/${child_id}/${exclude_code}/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getNodeFormsInfo(child_id, exclude_code, user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-node-forms-info/${child_id}/${exclude_code}/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getNodesInfo(child_id, exclude_code, user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-nodes-info/${child_id}/${exclude_code}/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteHierarchyNode(child_id) {
  const requestOptions = {
    method: "DELETE",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/delete/${child_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


async function updateNodeDetails(child) {
  const requestOptions = {
    method: "PUT",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(child),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/update/${child.mid}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
async function getRegistrationInfo(user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-registration-information/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllCalendarContent(user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-calendar-content/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getSiteMap(mid, flg_hide, user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-site-map/${mid}/${flg_hide}/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getUserType(lg_user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/tree-main-hierarchy-nodes/get-user-type-information/${lg_user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

