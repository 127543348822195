import React, { Component } from "react";
import Card from "../../../../components/common/Card.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardBody from "../../../../components/common/CardBody.js";
import CardTitle from "../../../../components/common/CardTitle.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../../components/common/spinner/PageSpinner.js";
import "../../../../components/admin/css/common.css";
import sweetAlert, {
  sweetConfirmAlert,
  sweetStatusAlert,
  sweetAlertInfo,
} from "../../../../components/common/SweetAlert.js";
import SimpleReactValidator from "simple-react-validator";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import TimeZoneHandler from "../../../../handlers/TimeZoneHandler.js";
import config from "../../../../helper/config.js";
import { customerConstants } from "../../../../helper/constants.js";
import { CombankService } from "../../../../service/manage/subscription/combank_pg.service.js";
import { TransactionService } from "../../../../service/manage/subscription/transaction.service.js";
import { LevelPriceService } from "../../../../service/manage/subscription/level_price.service.js";
import { CreditService } from "../../../../service/manage/subscription/credit.service.js";
import { TypeService } from "../../../../service/manage/subscription/type.service.js";
import { StripeService } from "../../../../service/manage/subscription/stripe_pg.service.js";
import { StudentService } from "../../../../service/manage/registration/student.service.js";
import ReactSelect from "react-select";
import debounce from "lodash.debounce";
import { GradeService } from "../../../../service/settings/manage/grade.service.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class Credits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      showModal: false,
      isLoading: false,
      isTableDataLoading: false,
      isStudentListLoading: false,
      isModified: false,
      search_id: "",
      isStudentsLoading: false,
      isSubmitting: false,
      registration_id: "",
      editVisibility: true,
      addVisibility: true,
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      credits: "",
      types: "",
      type_id: "",
      type_name: "",
      type_credits: "",
      discount_rate: "",
      level_prices: "",
      level_price_id: "",
      level_price_name: "",
      level_name: "",
      level_definition: "",
      level_price_credits: "",
      level_price_value: "",
      level_price_currency_type: "",
      level_price_start_date: "",
      level_price_end_date: "",
      credit_id: "",
      name: "",
      definition: "",
      description: "",
      amount: "",
      userType: "",
      total_credits: "",
      isPayEnabled: false,
      showTransactionModal: false,
      selectedTransaction: null,
      creditsPrivilege: null,
      customer: "EdSider",
      sessionId: "",
      isDropHidden: false,
      studentlist: [],
      selectedStudent: "",
      payment_method: "",
      reference_id: "",
      selectedFile: null,
      studentSearchTerm: "",
      selectedOption: "",
      showStudentParentModal: false,
      grades: "",
      assessment_grade_id: "",
      level_type_loading: false,
      without_discount: "",
      discounted_amount: "",
      form_list_id: "",
      userIDs: "",
      gradeMapAvailable: false,
    };

    this.createValidationsInstance();
    this.debouncedHandleStudentSearch = debounce(this.fetchallstudent, 1000);
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  handleRowClick = (transaction) => {
    this.setState({
      showTransactionModal: true,
      selectedTransaction: transaction,
    });
  };

  handleModalClose = () => {
    this.setState({
      showTransactionModal: false,
      selectedTransaction: null,
    });
  };

  getAllAssessmentGrades() {
    GradeService.getGrades().then((res) => {
      if (res.data) {
        this.setState({ grades: res.data });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Level Grades. Please refresh the screen!",
          "error"
        );
      }
    });
  }

  componentDidMount() {
    const { breadcrumb2, nodeTitle, flg_list_active, form_list_id } =
      this.props.props;
    const userType = sessionStorage.getItem("userType");

    this.setState(
      {
        breadcrumb2: breadcrumb2,
        nodeTitle,
        flg_list_active,
        userType: userType,
        creditsPrivilege: this.props.creditsPrivilege,
        form_list_id: form_list_id,
        customer: getCustomerFeatureFlag(),
      },
      () => {
        const { creditsPrivilege } = this.state;

        if (creditsPrivilege.isAdmin) {
          this.fetchallstudent();
          this.getAllCredits();
          if (flg_list_active === 1) {
            this.setState({ isDropHidden: true });
          }
        } else if (creditsPrivilege.isStudent) {
          this.getAllCreditsByUser();
          this.getAllAssessmentGrades();
        } else if (creditsPrivilege.isParent) {
          if (flg_list_active === 1) {
            this.setState({ isDropHidden: true });
          }
          this.fetchallstudent();
          this.getAllAssessmentGrades();
        }
      }
    );
    if (sessionStorage.getItem("studentGrade")) {
      this.setState({
        level_type_loading: true,
        isPayEnabled: false,
        level_definition: "",
        level_price_currency_type: "",
        amount: "",
      });
      const mid = 100;
      this.getAllTypes();
      StudentService.getByEncryptedId(
        sessionStorage.getItem("manage_user_id")
      ).then((response) => {
        LevelPriceService.getAllActiveLevelPricesByGrade(
          mid,
          response.data.grade_id
        )
          .then((res) => {
            if (res.success && res.data.level_price) {
              this.setState(
                () => ({
                  level_prices: res.data.level_price,
                  level_price_credits: res.data.level_price.credits,
                  level_price_value: res.data.level_price.value,
                  level_price_currency_type: res.data.level_price.currency_type,
                  level_price_id: res.data.level_price.level_price_id,
                  level_price_name: res.data.level_price.name,
                  level_name: res.data.level_price.level,
                  level_definition: res.data.level_price.definition,
                  level_price_start_date: res.data.level_price.start_date,
                  level_price_end_date: res.data.level_price.end_date,
                  assessment_grade_id: sessionStorage.getItem("studentGrade"),
                  gradeMapAvailable: true,
                }),
                () => {
                  this.calculateAmount();
                }
              );
            } else {
              this.setState(
                {
                  types: [],
                  assessment_grade_id: "",
                  gradeMapAvailable: false,
                },
                () => {}
              );
            }
          })
          .finally(() => {
            this.setState({
              level_type_loading: false,
            });
          });
      });
    }
  }

  componentWillUnmount() {
    if (this.state.customer !== customerConstants.EXO_CUSTOMER2) {
      // Cleanup: Remove the script when the component is unmounted
      const script = document.querySelector("script[src*='checkout.min.js']");
      if (script) {
        document.head.removeChild(script);
      }
    }
  }

  manualPayment = async (payment_obj) => {
    sweetConfirmAlert(
      "Are you sure?",
      "Do you want to confirm the payment details?",
      "warning"
    ).then(() => {
      this.setState({ isLoading: true });
      TransactionService.manualPayment(JSON.stringify(payment_obj))
        .then((response) => {
          if (response.success) {
            sweetStatusAlert("Success!", response.message.success, "success");
            this.getAllCreditsByParent(this.state.selectedStudent);
          } else {
            sweetStatusAlert("Failed!", response.message.error, "error");
          }
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  handlePaymentPageClick = async (payment_obj) => {
    this.setState({ isLoading: true });

    await this.getSessionID(payment_obj);

    this.loadCheckoutScript();
  };

  getSessionID = async (payment_obj) => {
    try {
      const res = await CombankService.getSessionID(
        JSON.stringify(payment_obj)
      );
      sessionStorage.setItem("checkout_session_id", res.data.session.id);
      this.setState({
        sessionId: res.data.session.id,
      });
    } catch (error) {
      sweetStatusAlert(
        "Error!",
        "An error occurred while getting session ID.",
        "error"
      );
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  loadCheckoutScript = () => {
    const script = document.createElement("script");
    script.src =
      "https://cbcmpgs.gateway.mastercard.com/static/checkout/checkout.min.js";
    document.head.appendChild(script);

    script.onload = () => {
      window.Checkout.configure({
        session: {
          id: this.state.sessionId,
        },
      });

      // Set up callbacks after configuring
      window.Checkout.onComplete = this.successCallback;
      window.Checkout.onError = this.errorCallback;
      window.Checkout.onCancel = this.cancelCallback;

      // Ensure Checkout is configured before attempting to showPaymentPage
      if (window.Checkout) {
        window.Checkout.showPaymentPage();
      }
    };
  };

  fetchallstudent = () => {
    const { mid, lg_user_id, lg_user_table_id, form_list_id } =
      this.props.props;
    const { studentSearchTerm } = this.state;

    this.setState({ isStudentListLoading: true });

    let userType = sessionStorage.getItem("userType");

    let user_id;
    let user_table_id;

    if (userType === "parent") {
      user_id = sessionStorage.getItem("parent_group_id");
      user_table_id = sessionStorage.getItem("parent_group_user_table_id");
    } else {
      user_id = lg_user_id;
      user_table_id = lg_user_table_id;
    }
    CreditService.getAllStudentsForParent(
      mid,
      form_list_id,
      user_id,
      user_table_id,
      studentSearchTerm,
      userType
    )
      .then((response) => {
        if (response.success) {
          const userIDs = response.data.students.map(
            (student) => student.user_id
          );
          this.setState({
            studentlist: response.data.students,
            userIDs: userIDs,
            isLoading: false,
          });
          this.getAllCreditsByParent(userIDs);
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching students. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch(() => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching students. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isStudentListLoading: false });
      });
  };

  getAllCredits() {
    const mid = 100;
    this.setState({ isTableDataLoading: true });
    CreditService.getAllCredits(mid)
      .then((res) => {
        if (res.success) {
          this.setState({ credits: res.data.credits });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Credits. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
          isLoading: false,
        });
      });
  }

  getAllCreditsByUser() {
    const mid = 100;
    const user_id = sessionStorage.getItem("manage_user_id");
    this.setState({ isTableDataLoading: true });
    CreditService.getAllCreditsByUser(mid, user_id)
      .then((res) => {
        if (res.success) {
          this.setState({ credits: res.data.credits });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Credits. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getAllCreditsByParent(user_id) {
    const mid = 100;
    this.setState({ isTableDataLoading: true });
    CreditService.getAllCreditsByUser(mid, user_id)
      .then((res) => {
        if (res.success) {
          this.setState({ credits: res.data.credits });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Credits. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getAllActiveLevelPrices() {
    const mid = 100;
    LevelPriceService.getAllActiveLevelPrices(mid)
      .then((res) => {
        if (res.success) {
          this.setState({ level_prices: res.data.level_prices });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Member Levels. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  }

  getAllTypes() {
    this.setState({
      level_type_loading: true,
    });
    const mid = 100;
    TypeService.getAllActiveTypes(mid)
      .then((res) => {
        if (res.success) {
          this.setState({ types: res.data.types });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Member types. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
          level_type_loading: false,
        });
      });
  }

  submitCredit = async () => {
    if (this.validator.allValid()) {
      if (this.state.userType === "admin") {
        this.setState({ showInsertModal: false });
      } else {
        this.setState({ showStudentParentModal: false });
      }
      const mid = 100;
      const session_id = sessionStorage.getItem("manage_user_id");
      const table_log_id = sessionStorage.getItem("table_log_id");

      const {
        type_id,
        type_name,
        type_credits,
        level_price_id,
        level_price_credits,
        level_price_start_date,
        level_price_end_date,
        amount,
        level_price_currency_type,
        userType,
        selectedStudent,
        payment_method,
        reference_id,
        form_list_id,
      } = this.state;

      let student = this.state.studentlist.find(
        (student) => student.user_id === selectedStudent
      );

      // Parse the date strings
      const startDate = new Date(
        level_price_start_date.split("/").reverse().join("-")
      );
      const endDate = new Date(
        level_price_end_date.split("/").reverse().join("-")
      );

      // Calculate the difference in days
      const timeDifference = endDate.getTime() - startDate.getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      // Add the difference to the start date to get the expiration date
      const expirationDate = new Date(
        startDate.getTime() + daysDifference * (1000 * 3600 * 24)
      );

      let paid_by = "";

      if (userType === "parent") {
        paid_by = sessionStorage.getItem("username");
      } else if (userType === "student") {
        paid_by = sessionStorage.getItem("username");
      } else if (userType === "admin") {
        paid_by = "Admin";
      }

      const payment_obj = {
        email: sessionStorage.getItem("email"),
        unit_amount: amount * 100,
        amount: amount,
        credits: parseInt(type_credits) * parseInt(level_price_credits),
        currency_type: level_price_currency_type,
        type_name: type_name,
        node_mid: mid,
        type_id: parseInt(type_id),
        level_price_id: parseInt(level_price_id),
        expiration_date: expirationDate,
        total_credits: parseInt(type_credits) * parseInt(level_price_credits),
        available_credits:
          parseInt(type_credits) * parseInt(level_price_credits),
        used_credits: 0,
        user_id: userType === "student" ? session_id : selectedStudent,
        user_table_id:
          userType === "student" ? table_log_id : student.user_table_id,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
        paid_by: paid_by,
        payment_method: payment_method ? payment_method : "Online",
        reference_id: reference_id ? reference_id : "N/A",
        form_list_id: userType === "admin" ? form_list_id : 29,
      };

      if (this.state.customer === customerConstants.EXO_CUSTOMER2) {
        this.setState({ isLoading: true });
        StripeService.create_checkout_session(JSON.stringify(payment_obj))
          .then((res) => {
            if (res.success) {
              window.location.replace(res.data);
            } else {
              sweetStatusAlert("Failed!", res.message, "error");
            }
          })
          .finally(() => {
            // Hide loading indicator
            this.setState({ isLoading: false });
          });
      } else {
        if (userType === "admin") {
          await this.manualPayment(payment_obj);
        } else {
          this.setState({ isLoading: true });
          await this.handlePaymentPageClick(payment_obj);
        }
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      toggleFunction();
    }
  };

  calculateAmount() {
    const {
      type_credits,
      discount_rate,
      level_price_credits,
      level_price_value,
    } = this.state;

    if (
      type_credits &&
      discount_rate &&
      level_price_credits &&
      level_price_value
    ) {
      const amountToPay =
        parseFloat(type_credits) *
        parseFloat(level_price_value) *
        ((100 - parseFloat(discount_rate)) / 100);
      const roundedAmount = parseFloat(amountToPay.toFixed(2));
      const withoutDiscount = (
        parseFloat(type_credits) * parseFloat(level_price_value)
      ).toFixed(2);
      this.setState({
        amount: roundedAmount,
        total_credits: parseInt(type_credits) * parseInt(level_price_credits),
        isPayEnabled: true,
        without_discount: withoutDiscount,
        discounted_amount: withoutDiscount - roundedAmount,
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "type_id") {
      if (value) {
        const type = this.state.types.find(
          (item) => item.type_id === parseInt(value)
        );
        this.setState(
          () => ({
            type_name: type.name,
            type_credits: type.credits,
            discount_rate: type.discount_rate,
          }),
          () => {
            this.calculateAmount();
          }
        );
      } else {
        this.setState({
          type_name: "",
          type_credits: "",
          discount_rate: "",
          amount: "",
          total_credits: "",
          isPayEnabled: false,
        });
      }
    }
    if (name == "level_price_id") {
      if (value) {
        const level_price = this.state.level_prices.find(
          (item) => item.level_price_id === parseInt(value)
        );
        this.setState(
          () => ({
            level_price_name: level_price.name,
            level_price_credits: level_price.credits,
            level_name: level_price.level,
            level_definition: level_price.definition,
            level_price_value: level_price.value,
            level_price_currency_type: level_price.currency_type,
            level_price_start_date: level_price.start_date,
            level_price_end_date: level_price.end_date,
          }),
          () => {
            this.calculateAmount();
          }
        );
      } else {
        this.setState({
          level_price_name: "",
          level_price_credits: "",
          level_name: "",
          level_definition: "",
          level_price_value: "",
          level_price_currency_type: "",
          level_price_start_date: "",
          level_price_end_date: "",
          amount: "",
          total_credits: "",
          isPayEnabled: false,
        });
      }
    }
    this.setState(() => ({ [name]: value, isModified: true }));
    if (name == "assessment_grade_id") {
      if (value) {
        this.setState({
          level_type_loading: true,
          isPayEnabled: false,
          level_definition: "",
          level_price_currency_type: "",
          amount: "",
        });
        const mid = 100;
        this.getAllTypes();
        LevelPriceService.getAllActiveLevelPricesByGrade(mid, value)
          .then((res) => {
            if (res.success && res.data.level_price) {
              this.setState(
                () => ({
                  level_prices: res.data.level_price,
                  level_price_credits: res.data.level_price.credits,
                  level_price_value: res.data.level_price.value,
                  level_price_currency_type: res.data.level_price.currency_type,
                  level_price_id: res.data.level_price.level_price_id,
                  level_price_name: res.data.level_price.name,
                  level_name: res.data.level_price.level,
                  level_definition: res.data.level_price.definition,
                  level_price_start_date: res.data.level_price.start_date,
                  level_price_end_date: res.data.level_price.end_date,
                  assessment_grade_id: value,
                  gradeMapAvailable: true,
                }),
                () => {
                  this.calculateAmount();
                }
              );
            } else {
              this.setState(
                {
                  types: [],
                  assessment_grade_id: "",
                  gradeMapAvailable: false,
                },
                () => {
                  sweetAlertInfo(
                    "",
                    "Credit packages for the selected grade are coming soon."
                  );
                }
              );
            }
          })
          .finally(() => {
            this.setState({
              level_type_loading: false,
            });
          });
      } else {
        this.setState({
          level_price_id: "",
          level_price_name: "",
          level_price_credits: "",
          level_name: "",
          level_definition: "",
          level_price_value: "",
          level_price_currency_type: "",
          level_price_start_date: "",
          level_price_end_date: "",
          amount: "",
          total_credits: "",
          isPayEnabled: false,
          types: [],
        });
      }
    }
  };

  toggleAddCreditModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal:
          this.state.userType === "admin"
            ? !prevState.showInsertModal
            : prevState.showInsertModal,
        showStudentParentModal:
          this.state.userType !== "admin"
            ? !prevState.showStudentParentModal
            : prevState.showStudentParentModal,
        assessment_grade_id: "",
        level_price_id: "",
        level_price_name: "",
        level_price_credits: "",
        level_name: "",
        level_definition: "",
        level_price_value: "",
        level_price_currency_type: "",
        level_price_start_date: "",
        level_price_end_date: "",
        amount: "",
        total_credits: "",
        type_id: "",
        type_name: "",
        type_credits: "",
        discount_rate: "",
        isPayEnabled: false,
        payment_method: "",
        reference_id: "",
      }),
      () => {
        this.getAllActiveLevelPrices();
        this.getAllTypes();
      }
    );
  };

  handleStudentSelect(event, credits) {
    if (!event.target.value) {
      this.setState({
        selectedStudent: "",
        types: [],
        assessment_grade_id: "",
        type_name: "",
        type_credits: "",
        discount_rate: "",
        amount: "",
        total_credits: "",
        level_price_name: "",
        level_definition: "",
      });
      this.getAllCreditsByParent(this.state.userIDs);
      return;
    }
    let student = this.state.studentlist.find(
      (student) => student.user_id === event.target.value
    );
    this.setState({ isTableDataLoading: true });
    const selectedStudent = student.user_id;
    if (selectedStudent) {
      this.setState({ selectedStudent }, () => {
        this.getAllCreditsByParent(selectedStudent);
      });
    }
    if (selectedStudent) {
      this.setState({
        level_type_loading: true,
        isPayEnabled: false,
        level_definition: "",
        level_price_currency_type: "",
        amount: "",
        types: [],
      });
      const mid = 100;
      this.getAllTypes();
      let studentGrade;
      StudentService.getByEncryptedId(selectedStudent).then((response) => {
        studentGrade = response.data.grade;
        LevelPriceService.getAllActiveLevelPricesByGrade(
          mid,
          response.data.grade_id
        )
          .then((res) => {
            if (res.success && res.data.level_price) {
              this.setState(
                () => ({
                  level_prices: res.data.level_price,
                  level_price_credits: res.data.level_price.credits,
                  level_price_value: res.data.level_price.value,
                  level_price_currency_type: res.data.level_price.currency_type,
                  level_price_id: res.data.level_price.level_price_id,
                  level_price_name: res.data.level_price.name,
                  level_name: res.data.level_price.level,
                  level_definition: res.data.level_price.definition,
                  level_price_start_date: res.data.level_price.start_date,
                  level_price_end_date: res.data.level_price.end_date,
                  assessment_grade_id: response.data.grade_id,
                  gradeMapAvailable: true,
                }),
                () => {
                  this.calculateAmount();
                }
              );
            } else {
              this.setState(
                {
                  types: [],
                  assessment_grade_id: "",
                  gradeMapAvailable: false,
                },
                () => {}
              );
            }
          })
          .finally(() => {
            this.setState({
              level_type_loading: false,
            });
          });
      });
    }
  }

  onFileChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  handleStudentSearch = (event) => {
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
    if (event === "" || regex.test(event)) {
      let num = event;
      this.setState(
        {
          studentSearchTerm: num,
          searchError: false,
          selectedOption: "",
          studentlist: [],
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleStudentSearch();
        }
      );
    } else {
      this.setState({
        studentSearchTerm: event,
        searchError: true,
        selectedOption: "",
      });
    }
  };

  clearStudentFilter = () => {
    this.setState({
      selectedStudent: "",
      assessment_grade_id: "",
      level_definition: "",
    });
    this.getAllCreditsByParent(this.state.userIDs);
  };

  render() {
    const {
      isLoading,
      isTableDataLoading,
      credits,
      types,
      type_id,
      type_credits,
      discount_rate,
      level_prices,
      level_price_id,
      level_price_credits,
      level_name,
      level_definition,
      level_price_value,
      level_price_currency_type,
      amount,
      total_credits,
      isPayEnabled,
      showTransactionModal,
      selectedTransaction,
      creditsPrivilege,
      isDropHidden,
      studentlist,
      selectedStudent,
      isStudentListLoading,
      payment_method,
      reference_id,
      selectedOption,
      userType,
      grades,
      assessment_grade_id,
      level_type_loading,
      without_discount,
      discounted_amount,
      gradeMapAvailable,
    } = this.state;
    this.validator.purgeFields();

    if (!creditsPrivilege) {
      return (
        <div>
          <h4>Privilege not found for this section</h4>
        </div>
      );
    }
    return (
      <>
        {userType === "admin" ? (
          <Card>
            <CardHeader>
              <div>
                {/* <CardTitle cardLabel={history.location.state.form_display_title} /> */}
                <CardTitle cardLabel={"Credits"} />
                {/* <h3 style={{ color: 'gray' }}>{(breadcrumb2 || nodeTitle) ? `(${breadcrumb2 && breadcrumb2}${nodeTitle && nodeTitle})` : undefined}</h3> */}
              </div>
              {isDropHidden ? (
                <div className="col-md-12 d-flex justify-content-start align-items-center px-0">
                  <div className="dropdown mb-2 px-0 col-md-4">
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="exampleForm.ControlInput3"
                      className="px-0"
                    >
                      <ReactSelect
                        placeholder="Search by student number"
                        options={studentlist.map((student) => ({
                          value: student.user_id,
                          label: `${student.data_concat}`,
                        }))}
                        value={selectedOption}
                        isLoading={isStudentListLoading}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption?.value;

                          if (!selectedValue) {
                            this.setState({
                              selectedStudent: "",
                              selectedOption: "",
                              studentlist: [],
                            });
                            this.fetchallstudent();
                            this.getAllCredits();
                            return;
                          }
                          let student = this.state.studentlist.find(
                            (student) => student.user_id === selectedValue
                          );
                          this.setState({
                            isTableDataLoading: true,
                            selectedOption: selectedOption,
                          });
                          const selectedStudent = student.user_id;
                          if (selectedStudent) {
                            this.setState({ selectedStudent }, () => {
                              this.getAllCreditsByParent(selectedStudent);
                            });
                          }
                        }}
                        onInputChange={(inputValue) => {
                          if (inputValue) {
                            this.handleStudentSearch(inputValue);
                          }
                        }}
                        isClearable={true}
                      />
                    </Form.Group>
                  </div>

                  {selectedStudent && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {creditsPrivilege.addVisibility && (
                        <Button
                          className="insert-btn"
                          variant="primary"
                          onClick={this.toggleAddCreditModal}
                        >
                          Add Credits
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {creditsPrivilege.addVisibility && (
                    <Button
                      className="insert-btn"
                      variant="primary"
                      onClick={this.toggleAddCreditModal}
                    >
                      Add Credits
                    </Button>
                  )}
                </div>
              )}
            </CardHeader>
            <CardBody>
              {isLoading && (
                <PageSpinner id="show" text="Loading, Please wait!" />
              )}
              {isTableDataLoading ? (
                <div className="loading-container">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div>
                  {credits.length === 0 ? (
                    <Form.Label
                      style={{ fontSize: "large", marginBottom: "0" }}
                    >
                      No Credits Found!
                    </Form.Label>
                  ) : (
                    (selectedStudent || !isDropHidden || !isLoading) && (
                      <div style={{ overflow: "Auto" }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className="thead"
                                style={{ textAlign: "center" }}
                              >
                                Purchased Date
                              </TableCell>
                              <TableCell
                                className="thead"
                                style={{ textAlign: "center" }}
                              >
                                Total Credits
                              </TableCell>
                              <TableCell
                                className="thead"
                                style={{ textAlign: "center" }}
                              >
                                Used Credits
                              </TableCell>
                              <TableCell
                                className="thead"
                                style={{ textAlign: "center" }}
                              >
                                Available Credits
                              </TableCell>
                              <TableCell
                                className="thead"
                                style={{ textAlign: "center" }}
                              >
                                Expiration Date
                              </TableCell>
                              <TableCell
                                className="thead"
                                style={{ textAlign: "center" }}
                              >
                                {creditsPrivilege.isAdmin
                                  ? "Transaction Id"
                                  : "Confirmation No"}
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {credits.length > 0 &&
                              credits.map((credit) => (
                                <TableRow key={credit.credit_id}>
                                  <TableCell
                                    className="tData"
                                    style={{ textAlign: "center" }}
                                  >
                                    {TimeZoneHandler.convertTime(
                                      credit.created_at
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className="tData"
                                    style={{ textAlign: "center" }}
                                  >
                                    {credit.total_credits}
                                  </TableCell>
                                  <TableCell
                                    className="tData"
                                    style={{ textAlign: "center" }}
                                  >
                                    {credit.used_credits}
                                  </TableCell>
                                  <TableCell
                                    className="tData"
                                    style={{ textAlign: "center" }}
                                  >
                                    {credit.available_credits}
                                  </TableCell>
                                  <TableCell
                                    className="tData"
                                    style={{ textAlign: "center" }}
                                  >
                                    {TimeZoneHandler.convertTime(
                                      credit.expiration_date
                                    )}
                                  </TableCell>
                                  <TableCell
                                    className="tData"
                                    style={{
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.handleRowClick(credit.transaction)
                                    }
                                  >
                                    {credit.transaction_id}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </div>
                    )
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        ) : (
          <Card>
            {userType === "parent" && (
              <CardHeader>
                {isDropHidden && !isLoading && !isTableDataLoading && (
                  <div className="col-md-12 d-flex justify-content-start align-items-center px-0">
                    <div className="dropdown mb-2 px-0 col-md-4">
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="exampleForm.ControlInput3"
                        className="px-0"
                      >
                        {userType === "parent" && (
                          <>
                            {!selectedStudent && (
                              <h3 className="thead">
                                Please select the student
                              </h3>
                            )}
                            <Form.Select
                              aria-label="Select the student"
                              name="student_id"
                              value={selectedStudent}
                              onChange={(event) =>
                                this.handleStudentSelect(event, credits)
                              }
                            >
                              <option
                                value=""
                                style={{ fontFamily: "Poppins, sans-serif" }}
                              >
                                All Students
                              </option>
                              {studentlist.map((student) => (
                                <option
                                  key={student.user_id}
                                  value={student.user_id}
                                >
                                  {student.data_concat}
                                </option>
                              ))}
                            </Form.Select>
                          </>
                        )}
                      </Form.Group>
                    </div>
                    {selectedStudent && (
                      <Button
                        variant="secondary"
                        onClick={this.clearStudentFilter}
                        className="ml-5"
                      >
                        Clear
                      </Button>
                    )}
                  </div>
                )}
              </CardHeader>
            )}

            <CardBody>
              <Row>
                <Col>
                  {isLoading && (
                    <PageSpinner id="show" text="Loading, Please wait!" />
                  )}
                  {isTableDataLoading ? (
                    <div className="loading-container-subscription">
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <div>
                      {credits.length === 0 ? (
                        <Form.Label
                          style={{ fontSize: "large", marginBottom: "0" }}
                        >
                          No Credits Found!
                        </Form.Label>
                      ) : (
                        (selectedStudent || !isDropHidden || !isLoading) && (
                          <div style={{ overflow: "Auto" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">
                                    Grade Availability
                                  </TableCell>
                                  <TableCell className="thead">
                                    Available Credits
                                  </TableCell>
                                  <TableCell className="thead">
                                    Expiration Date
                                  </TableCell>
                                  {userType !== "student" && (
                                    <TableCell
                                      className="thead"
                                      style={{ textAlign: "center" }}
                                    >
                                      Student Name
                                    </TableCell>
                                  )}
                                  <TableCell className="thead">
                                    {creditsPrivilege.isAdmin
                                      ? "Transaction Id"
                                      : "Confirmation No"}
                                  </TableCell>
                                  <TableCell className="thead">
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {credits.length > 0 &&
                                  credits.map((credit) => (
                                    <TableRow key={credit.credit_id}>
                                      <TableCell className="tData">
                                        {credit.levelDefinition}
                                      </TableCell>
                                      <TableCell
                                        className="tData"
                                      >
                                        {credit.available_credits}
                                      </TableCell>
                                      <TableCell className="tData">
                                        {TimeZoneHandler.convertTime(
                                          credit.expiration_date
                                        )}
                                      </TableCell>
                                      {userType !== "student" && (
                                        <TableCell
                                          className="tData"
                                          style={{ textAlign: "center" }}
                                        >
                                          {credit.student}
                                        </TableCell>
                                      )}
                                      <TableCell
                                        className="tData"
                                      >
                                        {credit.transaction_id}
                                      </TableCell>
                                      <TableCell
                                        className="tData"
                                      >
                                        <Button
                                          className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-eye-icon"
                                          title="View Note"
                                          onClick={() =>
                                            this.handleRowClick(
                                              credit.transaction
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </Col>
                {/* <Col
                  md={5}
                  style={{
                    border: "1px solid #A4A6F2",
                    borderRadius: "10px",
                    padding: "20px",
                    backgroundColor: "#F9FBFF",
                  }}
                >
                  <h3>Buy Credits</h3>
                  <Form>
                    <Row>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Select the grade*</Form.Label>
                      </Form.Group>
                    </Row>
                    <Row className="mb-5" style={{ alignItems: "center" }}>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          name="assessment_grade_id"
                          onChange={this.handleChange}
                          value={
                            assessment_grade_id
                              ? assessment_grade_id
                              : userType === "parent" && selectedStudent
                              ? credits.length > 0
                                ? credits[0].grade
                                : ""
                              : userType === "student" && gradeMapAvailable
                              ? sessionStorage.getItem("studentGrade")
                              : ""
                          }
                          disabled={userType === "parent" && !selectedStudent}
                        >
                          {grades.length === 0 ? (
                            <option value="">Loading...</option>
                          ) : (
                            <>
                              <option value="">Select</option>
                              {grades.map((grade) => (
                                <option
                                  value={grade.grade_id}
                                  key={grade.grade_id}
                                >
                                  {grade.grade}
                                </option>
                              ))}
                            </>
                          )}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        {level_definition && (
                          <Form.Label className="mb-0">
                            By purchasing this credit package, you will gain
                            access to papers for {level_definition}
                          </Form.Label>
                        )}
                      </Form.Group>
                    </Row>
                    {assessment_grade_id && (
                      <div>
                        {level_type_loading ? (
                          <div className="loading-container-subscription">
                            <Spinner animation="border" role="status">
                              <span className="sr-only">Loading...</span>
                            </Spinner>
                          </div>
                        ) : (
                          <>
                            {types.length > 0 && (
                              <div>
                                <Row
                                  className=" pt-5"
                                  style={{ borderTop: "1px solid #EBEDF3" }}
                                >
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Package*</Form.Label>
                                  </Form.Group>
                                </Row>
                                <Row
                                  className="pb-5"
                                  style={{ alignItems: "center" }}
                                >
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Select
                                      aria-label="Default select example"
                                      name="type_id"
                                      onChange={this.handleChange}
                                      defaultValue={type_id}
                                    >
                                      <option value="">Select</option>
                                      {types.length > 0 &&
                                        types.map((type) => (
                                          <option
                                            value={type.type_id}
                                            key={type.type_id}
                                          >
                                            {type.name}
                                          </option>
                                        ))}
                                    </Form.Select>
                                  </Form.Group>

                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    {discount_rate && (
                                      <Form.Label style={{ fontWeight: "500" }}>
                                        Discount Rate: {discount_rate}%
                                      </Form.Label>
                                    )}
                                  </Form.Group>
                                </Row>

                                {total_credits && amount && (
                                  <Row>
                                    <Form.Group
                                      className="py-5"
                                      as={Col}
                                      md="12"
                                      controlId="exampleForm.ControlInput1"
                                      style={{ borderTop: "1px solid #EBEDF3" }}
                                    >
                                      {total_credits && (
                                        <Form.Label
                                          style={{ fontWeight: "500" }}
                                        >
                                          Total Credits: {total_credits}
                                        </Form.Label>
                                      )}
                                      <Form.Label style={{ fontWeight: "500" }}>
                                        Amount Due (Before Discount):{" "}
                                        {`${without_discount} ${level_price_currency_type}`}
                                      </Form.Label>
                                      <Form.Label style={{ fontWeight: "500" }}>
                                        Discount Applied:{" "}
                                        {`${discounted_amount} ${level_price_currency_type}`}
                                      </Form.Label>
                                      {amount && (
                                        <Form.Label
                                          style={{ fontWeight: "500" }}
                                        >
                                          Amount Due (After Discount):{" "}
                                          {`${amount} ${level_price_currency_type}`}
                                        </Form.Label>
                                      )}
                                    </Form.Group>
                                  </Row>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <Modal.Footer>
                      {amount ? (
                        <Button
                          disabled={!isPayEnabled}
                          className="modal-btn"
                          variant="primary"
                          style={addBtnStyle}
                          onClick={this.submitCredit}
                        >
                          Pay {`${amount} ${level_price_currency_type}`}
                        </Button>
                      ) : (
                        <Button
                          disabled={!isPayEnabled}
                          className="modal-btn"
                          variant="primary"
                          style={addBtnStyle}
                          onClick={this.submitCredit}
                        >
                          Pay
                        </Button>
                      )}
                    </Modal.Footer>
                  </Form>
                </Col> */}
              </Row>
            </CardBody>
          </Card>
        )}

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() => this.setState({ showInsertModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Credits</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Level Price*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="level_price_id"
                    onChange={this.handleChange}
                    defaultValue={level_price_id}
                  >
                    <option value="">Select</option>
                    {level_prices.length > 0 &&
                      level_prices.map((level_price) => (
                        <option
                          value={level_price.level_price_id}
                          key={level_price.level_price_id}
                        >
                          {level_price.name}
                        </option>
                      ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message(
                      "level price",
                      level_price_id,
                      "required"
                    )}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Level</Form.Label>
                  <Form.Control
                    type="text"
                    name="level_name"
                    value={level_name}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Available Grades</Form.Label>
                  <Form.Control
                    type="text"
                    name="level_definition"
                    value={level_definition}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Level Price Credits</Form.Label>
                  <Form.Control
                    type="text"
                    name="level_price_credits"
                    defaultValue={level_price_credits}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Value</Form.Label>
                  <Form.Control
                    type="text"
                    name="level_price_value"
                    value={`${level_price_value} ${level_price_currency_type}`}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Type*</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="type_id"
                    onChange={this.handleChange}
                    defaultValue={type_id}
                  >
                    <option value="">Select</option>
                    {types.length > 0 &&
                      types.map((type) => (
                        <option value={type.type_id} key={type.type_id}>
                          {type.name}
                        </option>
                      ))}
                  </Form.Select>
                  <div style={{ color: "red" }}>
                    {this.validator.message("type", type_id, "required")}
                  </div>
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Type Credits</Form.Label>
                  <Form.Control
                    type="text"
                    name="type_credits"
                    defaultValue={type_credits}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Discount Rate</Form.Label>
                  <Form.Control
                    type="text"
                    name="discount_rate"
                    defaultValue={discount_rate}
                    disabled
                  />
                </Form.Group>
              </Row>

              <Row className="my-5">
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Total Credits</Form.Label>
                  <Form.Control
                    type="text"
                    name="total_credits"
                    value={total_credits}
                    disabled
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="6"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Total Amount To Pay</Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    value={`${amount} ${level_price_currency_type}`}
                    disabled
                  />
                </Form.Group>
              </Row>

              {creditsPrivilege.isAdmin && (
                <div>
                  <Row className="pt-5">
                    <Modal.Title className="pl-4 mb-4">
                      Additional Details
                    </Modal.Title>
                  </Row>

                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Payment Method</Form.Label>
                      <Form.Control
                        type="text"
                        name="payment_method"
                        value={payment_method}
                        placeholder="Payment Method"
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Reference ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="reference_id"
                        value={reference_id}
                        placeholder="Reference ID"
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    {/* <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Documents</Form.Label>
                      <div>
                        <div className="card">
                          <div className="file-upload-section">
                            <label
                              htmlFor="fileInput"
                              className="custom-file-label"
                            >
                              {selectedFile && selectedFile.name
                                ? "Selected file: " + selectedFile &&
                                  selectedFile.name
                                : "Select a file: "}
                            </label>

                            <div className="file-input-container">
                              <input
                                type="file"
                                id="fileInput"
                                onChange={this.onFileChange}
                                className="custom-file-input"
                                style={{ width: "350px" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form.Group> */}
                  </Row>
                </div>
              )}

              <Modal.Footer>
                <Button
                  className="modal-btn"
                  id="modal-close-button"
                  style={closeBtnStyle}
                  onClick={() => this.setState({ showInsertModal: false })}
                >
                  Close
                </Button>
                {/* {editVisibility && ( */}
                <Button
                  disabled={!isPayEnabled}
                  className="modal-btn"
                  variant="primary"
                  style={addBtnStyle}
                  onClick={this.submitCredit}
                >
                  {userType === "admin" ? "Add Credits" : "Pay"}
                </Button>
                {/* )} */}
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showTransactionModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Transaction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedTransaction && (
              <Form>
                {Object.entries(selectedTransaction).map(([key, value]) => (
                  <Row className="my-3" key={key}>
                    <Form.Label as={Col} md="3">
                      {key}
                    </Form.Label>
                    <Form.Label as={Col} md="9">
                      {key == "Exp. Date" || key == "Purchase Date"
                        ? TimeZoneHandler.convertTime(value)
                        : value}
                    </Form.Label>
                  </Row>
                ))}
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
