import React, { Component } from "react";
import "./layouts.css";
import Template from "./Template";
import { history } from "../../../helper/history";

export default function withTemplate(WrappedComponent) {
    return class WithTemplate extends Component {
        constructor(props) {
            super(props);
            this.state = {
                user_name: sessionStorage.getItem("username")
            }
        }

        setUserName = (first_name,last_name) =>{
            
            sessionStorage.setItem("username", first_name + " " + last_name);
            this.setState({
                user_name:first_name + " " + last_name
            })
        }
        render() {
            return (
                <Template user_name={this.state.user_name}>
                    <WrappedComponent {...this.props} state = {history.location.state} setUserName = {this.setUserName} />
                </Template>
            );
        }
      };
}
