import React, { Component } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputAdornment from '@mui/material/InputAdornment';
import SimpleReactValidator from "simple-react-validator";
import { TermService } from '../../../service/user_management/terms_conditions.service.js';
import { Spinner } from 'react-bootstrap';
import { sweetErrorAlert, sweetStatusAlert, sweetSuccessAlert } from '../../../components/common/SweetAlert';
import ContentWrapper from '../../../components/common/ContentWrapper';
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

class ManageUserAcceptTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: false,
      isLoading: false,
      addAcceptTermsModal: false,
      updateAcceptTermsModal: false,
      term_id: "",
      content: "",
      language_id: 1,
      update_term_id: "",
      update_content: "",
      update_language_id: "",
      userAcceptTerms: [],
      allUserLanguages: [],
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getAllUserLanguages();
    this.getAllUserAcceptTerms();
  }

  getAllUserLanguages = async () => {
    this.setState({ isTableDataLoading: true });
    try {
      const response = await TermService.LanguageGetAll();
      this.setState({ allUserLanguages: response.data.languages});
    } catch (error) {
      this.setState({ isTableDataLoading: false });
    }
  };

  getUserLanguage(language_id) {
    if (language_id !== null) {
        const language = this.state.allUserLanguages?.find(
            (language) => language.language_id == language_id
        );
        return language?.language;
    }
    else{
        return "N/A";
    }
  }

  getAllUserAcceptTerms = async () => {
    this.setState({ isTableDataLoading: true });
    await TermService.TermsGetAll()
      .then((response) => {
        if (response.success) {
          this.setState({ userAcceptTerms: response.data.acceptTerm, isTableDataLoading: false });
        } else {
          this.setState({ isTableDataLoading: false });
        }
      })
      .catch((error) => {
        console.error("Error while fetching Privacy Policy & Terms and Conditions!", error);
        this.setState({ isTableDataLoading: false });
      });
  }

  toggleAddUserAcceptTermsModal = () => {
    const { addAcceptTermsModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessageFor("content");
    this.validator.hideMessageFor("language");
  
    if (addAcceptTermsModal) {
      this.setState({
        addAcceptTermsModal: !addAcceptTermsModal
      });
    } else {
      this.setState({
        addAcceptTermsModal: !addAcceptTermsModal,
      });
    }
  };
  
  toggleUpdateUserAcceptTermsModal = (acceptTerm) => {
    const { updateAcceptTermsModal} = this.state;
  
    if (!updateAcceptTermsModal) {
      this.setState({
        update_term_id: acceptTerm.term_id || "",
        update_content: acceptTerm.content || "",
        update_language_id: acceptTerm.language_id || "",
        updateAcceptTermsModal: true,
      });
    } else {
      this.setState({
        update_term_id: "",
        update_content: "",
        update_language_id: "",
        updateAcceptTermsModal: false,
      });
    }
  };

handleSubmit = () => {
  this.setState({ isLoading: true });

  const { content, language_id } = this.state; 

    const newUserAcceptTerms = {
      content: content,
      language_id: parseInt(language_id)
    };

    TermService.addTerms(newUserAcceptTerms)
      .then((response) => {
        if (response.success) {
          sweetSuccessAlert("Success", "User Privacy Policy & Terms and Conditions added successfully!", "success");
          this.setState({ term_id: "", content: "", language_id: "", isLoading: false });
          this.toggleAddUserAcceptTermsModal();
          this.getAllUserAcceptTerms();
        } else {
          this.setState({ term_id: "", content: "", language_id: "", isLoading: false });
          sweetStatusAlert("Failed to add user Privacy Policy & Terms and Conditions!", response.message.error, "error");
        }
      })
      .catch((error) => {
        this.setState({ term_id: "", content: "", language_id: "", isLoading: false });
        sweetErrorAlert("Error", "Error while adding user Privacy Policy & Terms and Conditions!", "error");
      });
};

handleEditSubmit = () => {
  const { update_term_id, update_content, update_language_id } = this.state;

  this.setState({ isLoading: true });

  const editedUserAcceptTerms = {
    term_id: update_term_id,
    content: update_content,
    language_id: update_language_id,
  };

  TermService.updateTerms(editedUserAcceptTerms)
  
    .then((response) => {
      if (response.success) {

        sweetSuccessAlert("Success", "User Privacy Policy & Terms and Conditions updated successfully!", "success");

        this.setState({
          update_term_id: "",
          update_content: "",
          update_language_id: "",
          isLoading: false,
        });

        this.toggleUpdateUserAcceptTermsModal();
        this.getAllUserAcceptTerms();
      } else {
        this.setState({
            update_term_id: "",
            update_content: "",
            update_language_id: "",
            isLoading: false,
        });
        sweetStatusAlert("Failed to update user Privacy Policy & Terms and Conditions!", response.message.error, "error");
      }
    })
    .catch((error) => {
        this.setState({
            update_term_id: "",
            update_content: "",
            update_language_id: "",
            isLoading: false,
        });
        sweetErrorAlert("Error", "Error while updating user Privacy Policy & Terms and Conditions!", "error");
    });
};

handleChangeContent = (event) => {
    event.preventDefault();
    this.setState({ content: event.target.value });
};

handleEditChangeContent = (event) => {
    event.preventDefault();
    this.setState({ update_content: event.target.value });
};

handleChangeLanguage = (event) => {
    event.preventDefault();
    this.setState({ language_id: event.target.value });
};
  
render() {
    const {
      addAcceptTermsModal,
      updateAcceptTermsModal,
      isTableDataLoading,
      isLoading,
      userAcceptTerms
    } = this.state;

    return (
      <ContentWrapper>
        <div className="row justify-content-between align-items-center py-5 mb-3">
          <div className="col-md-6 col-6">
            <h3>Manage User Privacy Policy & Terms and Conditions</h3>
          </div>
          {/* <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleAddUserAcceptTermsModal}
          >
            Add
          </Button> */}
        </div>
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "75vw",
              height: "90vh"
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead">Language</TableCell>
                  <TableCell className="thead">User Privacy Policy & Terms and Conditions Content</TableCell>
                  <TableCell className="thead">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {userAcceptTerms.length > 0 ? (
                  userAcceptTerms.map((userAcceptTerm) => (
                    <TableRow key={userAcceptTerm.term_id}>
                      <TableCell className="tData">{this.getUserLanguage(userAcceptTerm.language_id)}</TableCell>
                      <TableCell className="tData">
                        {userAcceptTerm.content}
                      </TableCell>
                      <TableCell className="tData" align="right">
                        <Button
                          className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                          title="Edit Details"
                          onClick={() => this.toggleUpdateUserAcceptTermsModal(userAcceptTerm)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <div style={{ height: "90vh" }}>No Privacy Policy & Terms and Conditions available!</div>
                )}
              </TableBody>
            </Table>
          </div>
        )}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={addAcceptTermsModal}
          onHide={this.toggleAddUserAcceptTermsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New User Privacy Policy & Terms and Conditions</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "100px"}}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Select Language</Form.Label>
                    <Form.Select
                          id="language"
                          value={this.state.language_id}
                          onChange={this.handleChangeLanguage}
                          className="form-control"
                        >
                          {this.state.allUserLanguages.map((option, index) => (
                            <option key={index} value={option.language_id}>
                              {option.language}
                            </option>
                          ))}
                    </Form.Select>
                    {this.validator.message(
                      "language",
                       this.state.language_id,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "90ch" }}
                      type="text"
                      name="content"
                      value={this.state.content}
                      onChange={this.handleChangeContent}
                      multiline
                      rows={25}
                    />
                    {this.validator.message(
                      "content",
                      this.state.content,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button variant="primary" style={ addBtnStyle } onClick={this.handleSubmit}>
                Save
              </Button>
              <Button
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleAddUserAcceptTermsModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateAcceptTermsModal}
          onHide={this.toggleUpdateUserAcceptTermsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update User Privacy Policy & Terms and Conditions For {this.getUserLanguage(this.state.update_language_id)}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.state.isLoading ? (
              <div className="loading-container" style={{ height: "100px"}}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "90ch" }}
                      type="text"
                      name="content"
                      value={this.state.update_content}
                      onChange={this.handleEditChangeContent}
                      multiline
                      rows={25}
                    />
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button
                className="modal-btn"
                variant="primary"
                style={ addBtnStyle }
                onClick={() => this.handleEditSubmit()}
              >
                Save
              </Button>
              <Button
                className="modal-btn"
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleUpdateUserAcceptTermsModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </ContentWrapper>
    )
  }
}

export default ManageUserAcceptTerms;
