import {assessmentTypes} from '../types';

const initialState = {
    candidates: '',
    candidateDetails:'',
    invalid: [],
    assessment: '',
    loading: false,
    error: '',
    success: '',
    text: '',
    candidateReports: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case assessmentTypes.ADD_CANDIDATES_ASSESSMENT_REFRESH:
            return {
                ...state,
                text: '',
                loading: false,
                error: '',
                success: '',
                assessment: action.assessment,
                candidates: '',
                invalid: []
            }
        case assessmentTypes.ADD_CANDIDATES_ASSESSMENT:
            return {
                ...state,
                text: 'Adding candidates!',
                invalid: [],
                assessment: action.assessment,
                loading: false
            }
        case assessmentTypes.ADD_CANDIDATES_ASSESSMENT_REQUEST:
            return {
                ...state,
                invalid: action.invalid_emails,
                loading: true
            }
        case assessmentTypes.ADD_CANDIDATES_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                text: 'Candidates are added Successfully!',
                success: action.message
            }
        case assessmentTypes.ADD_CANDIDATES_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                success: '',
                candidates: ''
            }
        case assessmentTypes.GET_CANDIDATES:
            return {
                ...state,
                assessment: action.id,
                text: 'Obtain candidates!',
                loading: true,
                candidates: ''
            }
        case assessmentTypes.GET_CANDIDATES_REQUEST:
            return {
                ...state,
                text: action.id,
                loading: true
            }
        case assessmentTypes.GET_CANDIDATES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Obtained Candidates Successfully!',
                candidates: action.candidates
            }
        case assessmentTypes.GET_CANDIDATES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_CANDIDATE:
            return {
                ...state,
                text: 'Obtain candidate!',
                loading: true,
                candidate: ''
            }
        case assessmentTypes.GET_CANDIDATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_CANDIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Obtained Candidate Successfully!',
                candidateDetails: action.candidate.assessmentCandidate
            }
        case assessmentTypes.GET_CANDIDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_CANDIDATE_REPORTS:
            return {
                ...state,
                assessment: action.assessment,
                text: 'Obtain candidate Reports!',
                error: '',
                success: '',
                loading: true
            }
        case assessmentTypes.GET_CANDIDATE_REPORTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_CANDIDATE_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Obtained Candidates Successfully!',
                candidateReports: action.candidateReports
            }
        case assessmentTypes.GET_CANDIDATE_REPORTS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.DELETE_CANDIDATE:
            return {
                ...state,
                error: '',
                success: ''
            }
        case assessmentTypes.DELETE_CANDIDATE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.DELETE_CANDIDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.message.success,
            }
        case assessmentTypes.DELETE_CANDIDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }

        default:
            return state
    }
}