import React, { useEffect } from "react"
import "../dashboard.css"
import config from "../../../../../helper/config.js"
import { customerConstants } from "../../../../../helper/constants.js"
import MoneyIcon from "@mui/icons-material/Money"
import { Dropdown } from "react-bootstrap";
import { useState } from "react"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}




const Receivables = ({suitableArray}) => {
  
  const [monthBasedReceivables, setMonthBasedReceivables] = useState(undefined);
  const [selectedReceivables, setSelectedReceivables] = useState(null);
  useEffect( () => {
    fetchSuitableReceivable()
  },[suitableArray])
  
  const fetchSuitableReceivable = () => {
    const suitableArray_ = suitableArray.find(item => item.chart_type === 'Receivables' && item.chart_id === 1000)
    const groupedData =suitableArray_?.array.reduce((acc, obj) => {
      const key = `${obj.flg_chart_legend_x_filter}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    if(groupedData){
      const filteredGroupedData = Object.values(groupedData)
      setMonthBasedReceivables(filteredGroupedData)
      setSelectedReceivables(filteredGroupedData[0])
    }
  }
  return (
    <div className="row d-flex">
      <div className="col-md-12 mb-3 pl-0 px-0 px-sm-0">
        <div className="dashboard__container">
          <div className="row d-flex justify-content-between align-items-center my-3">
            <h3 className="dashboard__container-title m-0">
              <MoneyIcon sx={{ fontSize: 22, color: "#5c5c5c" }} /> Receivables
            </h3>
            <div className="">
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    border: "1px solid #E4E6EF",
                    color: "var(--secondary-text-color)",
                    fontWeight: "400",
                    fontSize: "10px"
                  }}
                  
                >
                  {selectedReceivables&&selectedReceivables[0]?.flg_chart_legend_x_filter}
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {monthBasedReceivables && monthBasedReceivables.map(obj => (
                    <Dropdown.Item
                      onClick={() => {setSelectedReceivables(obj)}}
                    >
                      {obj[0].flg_chart_legend_x_filter}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {
            selectedReceivables?.map(obj => (
              <div className="row d-flex justify-content-between receivables-row">
                <h5 className="receivables-title m-0">{obj.chart_x_value}</h5>
                <p className="receivables-amount">{obj.chart_y_value}</p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Receivables
