import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import Spinner from "react-bootstrap/Spinner";
import debounce from "lodash.debounce";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import sweetAlert, {
  sweetStatusAlert,
} from "../../../components/common/SweetAlert";
import { ProvinceService } from "../../../service/settings/manage/province.service.js";
import { CountryService } from "../../../service/settings/manage/country.service.js";
import "../../../components/admin/css/common.css";
import SimpleReactValidator from "simple-react-validator";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import ContentWrapper from "../../../components/common/ContentWrapper";
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

export default class ManageProvince extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
      showModal: false,
      provinces: [],
      countries: [],
      name: "",
      description: "",
      province_id: "",
      country_id: "",
      countryName: "",
      isLoading: false,
      isModified: false,
      modalType: false,
      currentPage: 0,
      totalProvinces: 10,
      search_term: "",
      limit: 10,
      offset: 0,
      isTableDataLoading: true,
      editVisibility: true,
      addVisibility: true,
      deleteVisibility: false,
    };
    this.debouncedHandleSearch = debounce(this.fetchProvinceData, 2000);
  }

  resetFormFields = () => {
    this.setState({
      name: "",
      description: "",
      country_id: "",
    });
  };

  componentDidMount() {
    // const staff_id = sessionStorage.getItem("staff_id");
    // this.getVisibilityCheck(staff_id);
    this.fetchProvinceData();
    this.fetchCountryData();
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  //fetching provinces data with limit 10
  fetchProvinceData = () => {
    const { limit, offset, search_term } = this.state;
    ProvinceService.getAllProvincesLimit(limit, offset, search_term)
      .then((response) => {
        if (response.success) {
          this.setState({
            provinces: response.data.provinces,
            totalProvinces: parseInt(response.data.count),
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Provinces. Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({ isLoading: false, isTableDataLoading: false });
      });
  };

  fetchCountryData = () => {
    CountryService.getAll().then((response) => {
      this.setState({ countries: response.data });
    });
  };

  //table pagination handle
  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchProvinceData();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.fetchProvinceData();
      }
    );
  };

  //table rows handle
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage, 
        isTableDataLoading: true,
        currentPage: newPage, 
      },
      () => {
        this.fetchProvinceData();
      }
    );
  };

  //change add modal state
  toggleAddProvinceModal = (modalType) => {
    this.modalType = modalType;
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }));
  };

  //change update modal state
  toggleUpdateProvinceModal = (
    name,
    description,
    province_id,
    country_id,
    modalType
  ) => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      name: name,
      description: description,
      province_id: province_id,
      country_id: country_id,
      modalType: modalType,
    }));
  };

  //handle sweet alert
  handleSweetAlert = (modalType, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.hideMessages();
        this.validator.purgeFields();
        this.resetFormFields();
        this.modalType = false;
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.hideMessages();
      this.validator.purgeFields();
      this.resetFormFields();
      if (modalType) this.toggleAddProvinceModal();
      else this.toggleUpdateProvinceModal();
    }
  };

  //change variable values
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  //Handle Edit Submit
  handleEditSubmit = (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const provinceItem = {
        name: this.state.name,
        description: this.state.description,
        country_id: this.state.country_id,
      };
      const provinceId = this.state.province_id;

      ProvinceService.update(provinceItem, provinceId)
        .then((response) => {
          if (response.success) {
            this.setState({ showModal: false });
            sweetStatusAlert("Success!", response.message, "success");
            this.setState({ isLoading: false });
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.fetchProvinceData();
            this.fetchCountryData();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          sweetStatusAlert(
            "Failed!",
            "Unable to update the province/district",
            "error"
          );
        });
    }
  };

  //Handle add province
  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const { name, description, country_id } = this.state;
      const newProvince = {
        name: name,
        description: description,
        country_id: country_id,
      };

      ProvinceService.add(newProvince)
        .then((response) => {
          if (response.success) {
            sweetStatusAlert("Success!", response.message, "success");
            this.setState({ isLoading: false });
            this.validator.hideMessages();
            this.validator.purgeFields();
            this.resetFormFields();
            this.toggleAddProvinceModal(false);
            this.fetchProvinceData();
            this.fetchCountryData();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Failed!", response.message, "error");
          }
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", "Unable to create new province", "error");
        });
    }
  };

  //handle the search with pagination
  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  render() {
    const {
      currentPage,
      limit,
      totalProvinces,
      isTableDataLoading,
      countries,
      editVisibility,
      isLoading,
      addVisibility,
      deleteVisibility,
    } = this.state;

    return (
      <>
            <ContentWrapper>
            <div className="row justify-content-between align-items-center py-5 mb-3">
                <div className="col-md-6"><h3 className='text-nowrap'>Manage Province/District</h3></div>
                  {addVisibility && (
                    <Button
                      className="insert-btn"
                      variant="primary"
                      onClick={() => this.toggleAddProvinceModal(true)}
                    >
                      Add Province
                    </Button>
                  )}
                </div>
              <div>
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    // style={{ width: "18%" }}
                    className="col-md-3 col-12"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                {isLoading && (
                  <PageSpinner id="show" text="Loading, Please wait!" />
                )}
                {isTableDataLoading ? (
                  <div
                    className="loading-container"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "75vw",
                      height: "90vh"
                    }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : this.state.provinces.length > 0 ? (
                  <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" className="thead">
                            No
                          </TableCell>
                          <TableCell align="left" className="thead">
                            Province/District Name
                          </TableCell>
                          <TableCell align="left" className="thead">
                            Province/District Description
                          </TableCell>
                          {(editVisibility || deleteVisibility) && (
                            <TableCell className="thead d-flex justify-content-end">Actions</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.provinces.map((provincesDetails) => (
                          <TableRow
                            key={provincesDetails.province_id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left" className="tData">
                              {provincesDetails.province_id}
                            </TableCell>
                            <TableCell align="left" className="tData">
                              {provincesDetails.name}
                            </TableCell>
                            <TableCell align="left" className="tData">
                              {provincesDetails.description
                                ? provincesDetails.description
                                : "No description"}
                            </TableCell>
                            {(editVisibility || deleteVisibility) && (
                              <TableCell align="right">
                                <div className="btn-group">
                                  {editVisibility && (
                                    <Button
                                      onClick={() =>
                                        this.toggleUpdateProvinceModal(
                                          provincesDetails.name
                                            ? provincesDetails.name
                                            : "",
                                          provincesDetails.description
                                            ? provincesDetails.description
                                            : "",
                                          provincesDetails.province_id
                                            ? provincesDetails.province_id
                                            : "",
                                          provincesDetails.country_id
                                            ? provincesDetails.country_id
                                            : "",
                                          false
                                        )
                                      }
                                      className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                      title="Edit Details"
                                    />
                                  )}
                                  {deleteVisibility && (
                                    <Button
                                      className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                                      title="Delete"
                                    />
                                  )}
                                </div>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <MaterialCustomTablePagination
                      totalCount = {Number(totalProvinces)}
                      currentPage = {Number(currentPage)}
                      limit = {Number(limit)}
                      handlePageChange = {this.handlePageChange}
                      handleRowsPerPageChange = {this.handleRowsPerPageChange}
                      handlePageOffsetChange = {this.handlePageOffsetChange}
                      offset = {Number(this.state.offset)}
                      rowsPerPageOptions={[5, 10, 20]}
                    />
                  </div>
                ) : (
                  <div className="text-center mb-5 mt-10" style={{ height: "90vh" }}>
                    <label>No Provinces Available!</label>
                  </div>
                )}
              </div>
            </ContentWrapper>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.showModal}
            onHide={() => this.handleSweetAlert(this.modalType, "showModal")}
          >
            {this.modalType ? (
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>Create New Province/District </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Select Country *</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="country_id"
                          value={
                            this.state.country_id ? this.state.country_id : ""
                          }
                          onChange={this.handleChange}
                          required
                        >
                          <option value="">Select</option>
                          {countries.map((country) => (
                            <option
                              value={country.country_id}
                              key={country.country_id}
                            >
                              {country.name}
                            </option>
                          ))}
                        </Form.Select>
                        {this.validator.message(
                          "country_id",
                          this.state.country_id,
                          "required",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The country field is required.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Province/District Name *</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={this.state.name ? this.state.name : ""}
                          placeholder="Province/District Name"
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "name",
                          this.state.name,
                          "required|alpha_space|max:30",
                          {
                            className: "text-danger",
                            messages: {
                              max: "The province/district should not be greater than 30 characters.",
                              alpha_space:
                                "The province/district must have valid characters.",
                              required:
                                "The province/district field is required.",
                            },
                          }
                        )}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          name="description"
                          value={
                            this.state.description ? this.state.description : ""
                          }
                          placeholder="Description"
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "description",
                          this.state.description,
                          "alpha_space|max:100",
                          {
                            className: "text-danger",
                            messages: {
                              max: "The description should not be greater than 100 characters.",
                              alpha_space:
                                "The description must have valid characters.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" style={ addBtnStyle } onClick={this.handleSubmit}>
                    Save changes
                  </Button>
                  <Button
                    id="modal-close-button"
                    style={ closeBtnStyle }
                    onClick={() =>
                      this.handleSweetAlert(this.modalType, "showModal")
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </div>
            ) : (
              <div>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Update Province/District Information
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Select Country *</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="country_id"
                          onChange={this.handleChange}
                          required
                          value={
                            this.state.country_id ? this.state.country_id : ""
                          }
                        >
                          <option value="">Select</option>
                          {countries.map((country) => (
                            <option
                              value={country.country_id}
                              key={country.country_id}
                            >
                              {country.name}
                            </option>
                          ))}
                        </Form.Select>
                        {this.validator.message(
                          "country_id",
                          this.state.country_id,
                          "required",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The country field is required.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Province/District Name *</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Province/District Name"
                          value={this.state.name ? this.state.name : ""}
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "name",
                          this.state.name,
                          "required|alpha_space|max:30",
                          {
                            className: "text-danger",
                            messages: {
                              max: "The province/district should not be greater than 30 characters.",
                              alpha_space:
                                "The province/district must have valid characters.",
                              required:
                                "The province/district field is required.",
                            },
                          }
                        )}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          name="description"
                          placeholder="Description"
                          value={
                            this.state.description ? this.state.description : ""
                          }
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "description",
                          this.state.description,
                          "alpha_space|max:100",
                          {
                            className: "text-danger",
                            messages: {
                              max: "The description should not be greater than 100 characters.",
                              alpha_space:
                                "The description must have valid characters.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="modal-btn"
                    variant="primary"
                    style={ addBtnStyle }
                    onClick={this.handleEditSubmit}
                  >
                    Save changes
                  </Button>
                  <Button
                    className="modal-btn"
                    id="modal-close-button"
                    style={ closeBtnStyle }
                    onClick={() =>
                      this.handleSweetAlert(this.modalType, "showModal")
                    }
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </div>
            )}
          </Modal>
      </>
    );
  }
}
