import AdminTemplate from '../../../components/admin/layouts/Template';
import React from 'react';
import ContentWrapper from '../../../components/common/ContentWrapper';
import Card from '../../../components/common/Card';
import CardHeader from '../../../components/common/CardHeader';
import CardTitle from '../../../components/common/CardTitle';
import { history } from '../../../helper/history';
import CardBody from '../../../components/common/CardBody';
import { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { ManageDashboardService } from '../../../service/manage/dashboard/manage_dashboard.service';
import Button from "react-bootstrap/Button";
import {formFixture} from '../hierarchy/FormFixture'

const MyProfile = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const session_id = sessionStorage.getItem("manage_user_id");
        const table_log_id = sessionStorage.getItem("table_log_id")
        ManageDashboardService.getContent(session_id, table_log_id, "registration", "owner")
            .then((res) => {
                setData(res.data)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        if(data.length === 1){
            handleApplicationSelect(data[0])
        }
    }, [data])
    
    

    const cardData = (data) => {
        const formattedBreadcrumb = data?.breadcrumb ? data?.breadcrumb.replaceAll(">", " > ") : ""
        return (
            <div className='row d-flex align-items-center my-2'>
                <div className='col-md-9'>
                    <p className="col-md-6" style={{ color: "gray" }}>
                        {formattedBreadcrumb ? `${formattedBreadcrumb && formattedBreadcrumb}` : ""}
                    </p>
                </div>
                <div className='col-md-3 d-flex justify-content-start'>
                    <Button
                        className="insert-btn"
                        style={{ backgroundColor: "#5458af", border: 'none' }}
                        onClick={() => handleApplicationSelect(data)}
                    >
                        View Profile
                    </Button>
                </div>
            </div>
        )
    }

    const handleApplicationSelect = (formData) => {
        let state = {
            mid: formData.node_mid,
            lg_user_id: formData.lg_user_id,
            lg_user_table_id: formData.lg_user_table_id,
            flg_list_active: formData.flg_list_active,
            privilege_view: formData.privilege_view,
            privilege_edit: formData.privilege_edit,
            privilege_add: formData.privilege_add,
            privilege_delete: formData.privilege_delete,
            owner_restriction: formData.owner_restriction,
            user_list_restriction: formData.user_list_restriction,
            form_display_title: formData.form_display_title,
            form_display_order: formData.form_display_order,
            form_list_id: formData.form_list_id,
            breadcrumb: formData.breadcrumb,
            main_table_id: formData.main_table_id,
            breadcrumb2: formData.breadcrumb,
            node_mid_actual:formData.node_mid_actual,
            nodeTitle:formData.tree_main_title,
            path:'profile'
        }
        
        const componentDetail =  formFixture(state).find(item => formData.form_list_id === item.formListId)
        if(componentDetail && componentDetail.url){
        history.push({
            pathname: componentDetail.url,
            state: { ...state }
        });
        }
    }

    return (
        <AdminTemplate>
            <ContentWrapper>
                <Card>
                    <Row>
                        <Col md={12} className="mb-4">
                            {loading ? (
                                <div className="loading-container" style={{ height: "90vh" }}>
                                    <Spinner animation="border" role="status" >
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                </div>
                            ) : (
                                <div style={{ height: "90vh", overflow: "auto" }}>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle cardLabel="My Profile" />
                                        </CardHeader>
                                        <CardBody>
                                            {data.map((data) => (
                                                cardData(data)
                                            )
                                            )}
                                        </CardBody>
                                    </Card>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Card>
            </ContentWrapper>
        </AdminTemplate>
    )
}

export default MyProfile;