import {userConstants} from '../../helper/constants';

export function myProfileReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.GET_ME_REQUEST:
            return {
                loading: true
            };
        case userConstants.GET_ME_SUCCESS:
            return {
                me: action.me
            };
        case userConstants.GET_ME_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}