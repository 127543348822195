import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import {convertToRaw, EditorState,convertFromRaw } from "draft-js";
import { AboutUsService } from "../../../service/manage/about/about.us.service";
import { sweetStatusAlert, sweetSuccessAlert } from "../../../components/common/SweetAlert";
import {
    Row,
    Col,
    Spinner,
    Button,
    Form,
    Modal,
  } from "react-bootstrap";
import CustomEditor from "../enrollment/CustomEditor";
import {Editor} from "react-draft-wysiwyg";

class ManageAbout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: [],
      nodesData: [],
      hierarchyGridTopData: [],
      hierarchyGridBottomData: [],
      loadingNode: false,
      formModalLoading: true,
      showInsertModal: false,
      showUpdateModal: false,
      mcode: "",
      title: "",
      groupTitle: "",
      flg_node_hide: 0,
      pid: null,
      displayOrder: 1,
      data_id: null,
      insertType: "null",
      selectedAttributes: [], // Initialize selected attributes as an empty array
      previousAttributes: [], // Attributes that are assigned already for a specific node
      mid: "",
      user_id: null,
      user_table_id: null,
      showAboutUpdateModal:false,
      about:null,
      isAboutLoading:false,
      about_details:null,
      about_text:'',
      contentEditorState: EditorState.createEmpty(),
      showChatModal:false,//main chat modal
      chat:null,//which are passing through state of db function
      chat_details:null,//chat details of main db
      showGroupChatAdd:false,// chat create modal
      studentList:[],// student list of node (enrolled)
      chatName:'',
      createChatLoading:false,
      chat_server_details:null,
      chat_backDrop:false,
      checked:[],
      memberListLoading:false,
      showProgressModal:false,
      progress : null,
      progress_details:[],
      showProgressCreateModal:false,
      start_date:"",
      end_date:"",
      progress_loading:false,
      date:"",
      steps:[],
      percentage:null,
      comment:"",
      marks:[],
      dayArray:[],
      todayIndex:0,
      isCardSectionVisible: false,
      isMobile: window.innerWidth <= 820,
      titles:[],
      selectedMidsParents:null,//array
      selectedMid:null,//obj
      selectedMidsChilds:null,//array
      selectedMidsForms:null,//array
      selectedMidsSelectedForm:null,//obj
      selectedFormDetail:null,
      formLoading:false
    };

    this.onContentEditorChange = this.onContentEditorChange.bind(this)

    };

    componentDidMount(){
        if(this.state.showAboutUpdateModal){
            this.setState({
              showAboutUpdateModal: false,
              contentEditorState: null 
            })
          }else{
            this.setState({
              selectedFormDetail:null, 
            }, ()=>{
              this.setState({showAboutUpdateModal: true, }, () => {
                this.setState({ about: this.props?.state, isAboutLoading: true,})
                this.getAboutDetails(this.props?.state?.mid)
              })          
            })
          }
    }

    onContentEditorChange(editorState) {
        this.setState({
          contentEditorState: editorState,
        })
      }

    getAboutDetails = async (node_mid) => {
        this.setState({isAboutLoading: true })
        AboutUsService.getAboutInfo(node_mid)
          .then((response) => {
            this.setState({ about_details: response.data, isAboutLoading: false })
            const existingContent = JSON.parse(response.data.text)
            let editorStateWithExistingContent = EditorState.createWithContent(
              convertFromRaw(existingContent[0])
            )
            this.setState({ contentEditorState: editorStateWithExistingContent })
          })
          .catch((error) => {
            this.setState({ isAboutLoading: false })
          })
    };

    handleAboutUpdate = async () => {
        this.setState({ isAboutLoading: true })
        const { about_text, about } = this.state
        const updateAbout = {
          node_mid: about.mid,
          text: JSON.stringify([
            convertToRaw(this.state.contentEditorState.getCurrentContent()),
          ]),
        }
        AboutUsService.updateAboutInfo(updateAbout)
          .then((response) => {
            this.setState({ isAboutLoading: false })
            sweetSuccessAlert("Success", "About updated", "success")
          })
          .catch((error) => {
            this.setState({ isAboutLoading: false })
            sweetStatusAlert("Failed", "Failed to update about", "error")
          })
    };
    
    handleAboutAdd = async () => {
        this.setState({ isAboutLoading: true })
        const { about_text, about } = this.state
        const addAbout = {
          node_mid: about.mid,
          text: JSON.stringify([
            convertToRaw(this.state.contentEditorState.getCurrentContent()),
          ]),
          create_user_id: about.lg_user_id,
          create_user_table_id: about.lg_user_table_id,
        }
        AboutUsService.addAboutInfo(addAbout)
          .then((response) => {
            if (response.success) {
              this.setState({ isAboutLoading: false }, () => {
                sweetSuccessAlert("Success", "About added successfully", "success")
                this.getAboutDetails(this.props?.state?.mid)
              })
            }
          })
          .catch((error) => {
            this.setState({ isAboutLoading: false })
            sweetStatusAlert("Failed", "Failed to add about", "error")
          })
    };


    render() {

        const {
            showAboutUpdateModal,
            about,
            isAboutLoading,
            about_details,           
          } = this.state;
        const { path,breadcrumb2 } = this.props.state
        const formattedBreadcrumb = breadcrumb2? breadcrumb2.replaceAll(">", " > ") : ""
        return(
            <div style={{ height: "90vh", overflow: "auto" }}>
                <ContentWrapper disabled={path !== "site-map"}>
                <Card >
                    {isAboutLoading || ((this.props.state.path !== 'node' ) || about?.privilege_edit ) && <CardHeader>
                      {(this.props.state.path !== 'node' ) && <p style={{ fontSize: "10.5625px", color: "#00000099" }}>
                        {(formattedBreadcrumb) ? `${formattedBreadcrumb}`: ""}
                        </p>}
                    <div className="col-md-12 px-0 d-flex justify-content-end">
                        {about?.privilege_edit &&
                          (about_details?.id && (
                            <Button
                                variant="primary"
                                onClick={() => this.handleAboutUpdate()}
                            >
                              Update
                            </Button>
                          ))
                        }
                        {
                          (!about_details?.id && about?.privilege_add)  && (
                            <Button
                                variant="primary"
                                onClick={() => this.handleAboutAdd()}
                            >
                              Add
                            </Button>
                            )
                        }
                        </div>
                    </CardHeader>}

                    <CardBody>

                        {this.state.showAboutUpdateModal && 
                            (isAboutLoading ? (
                                <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "90vh",
                                    overflow: "auto"
                                }}
                                >
                                <Spinner />
                                </div>
                            ) : (
                                <>
                                {!!about?.privilege_edit ? (
                                                <Form>
                                                    <Row className="my-5">
                                                    <Form.Group
                                                        as={Col}
                                                        md="12"
                                                        controlId="exampleForm.ControlInput1"
                                                        style={{ height: "90vh" }}
                                                    >
                                                        {/* <Form.Label>About</Form.Label> */}
                                                        <div>
                                                        <CustomEditor
                                                            editorState={
                                                            this.state.contentEditorState
                                                                ? this.state.contentEditorState
                                                                : null
                                                            }
                                                            onEditorStateChange={this.onContentEditorChange}
                                                            customOptions={[
                                                            "inline",
                                                            "blockType",
                                                            "fontSize",
                                                            "fontFamily",
                                                            "list",
                                                            "textAlign",
                                                            "colorPicker",
                                                            "embedded",
                                                            "emoji",
                                                            "image",
                                                            "remove",
                                                            "history",
                                                            ]}
                                                        />
                                                        </div>
                                                    </Form.Group>
                                                    </Row>
                                                </Form>
                                                ) : (
                                                <div>
                                                    {!about_details?.id?(
                                                        <p>No content</p>
                                                    ):(
                                                    <div style={{ height: "70vh", overflow: "auto" }}>
                                                      <Editor
                                                        editorState={
                                                            this.state.contentEditorState
                                                            ? this.state.contentEditorState
                                                            : null
                                                        }
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        toolbarHidden={true}
                                                      />
                                                    </div>
                                                    )}
                                                </div>
                                                )}
                                </>
                            ))
                        }
                    </CardBody>
                </Card> 
              </ContentWrapper>
          </div>
        )
    }
}
export default ManageAbout;