import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const AssessmentService = {
  getAll,
  getExamsCount,
  getAllAssesmentsWithPaginationAndSorting,
  getAllLastAttemp,
  AddCandidate,
  getfilterdAllAssesmentsWithPaginationAndSorting
};

async function getAllAssesmentsWithPaginationAndSorting(
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return fetch(
    `${config.oat_api_url}/assessments/filter/getAll/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getAll() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return fetch(`${config.oat_api_url}/assessments/getAll/`, requestOptions).then(
    APIHandler.handleResponse
  );
}

async function getAllLastAttemp(user_id, asses_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return fetch(
    `${config.oat_api_url}/candidates/getBy/${user_id}/${asses_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getExamsCount(user_id, status) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return fetch(
    `${config.oat_api_url}/candidates/getExamsCount/${user_id}?status=${status}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function AddCandidate(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: data,
  };
  return await fetch(
    `${config.oat_api_url}/candidates/add/all`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getfilterdAllAssesmentsWithPaginationAndSorting(
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term,
  selectedCategories,
  selectedAuthors,
  selectedChapters,
  selectedGrades,
  selectedDifficulties,
  selectedLanguages,
  selectedTerms,
  selectedYears,
  selectedsubjects,
  selectedStatuses,
  teacher_id
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return fetch(
    `${config.oat_api_url}/assessments/filter/getAll/managed?limit=${limit}&offset=${offset}&sort_column=${sortColumn}&sort_direction=${sortDirection}&search_term=${search_term}&selectedCategories=${selectedCategories}&selectedAuthors=${selectedAuthors}&selectedChapters=${selectedChapters}&selectedGrades=${selectedGrades}&selectedDifficulties=${selectedDifficulties}&selectedLanguages=${selectedLanguages}&selectedTerms=${selectedTerms}&selectedYears=${selectedYears}&selectedsubjects=${selectedsubjects}&selectedStatuses=${selectedStatuses}&teacher_id=${teacher_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

