import React from "react";

export default function SearchFormGroup({ children, labelName, className,labelColStyle }) {
    return (
        <div className={className}>
                <div className="row">
                    <label className={labelColStyle}>{labelName}</label>
                    {children}
                </div>
        </div>
    )
}