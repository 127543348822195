import React from "react";
import './dashboard.css';
import { Row, Col, Spinner } from "react-bootstrap";
import ContentWrapper from "../../../../components/common/ContentWrapper.js";
import Card from "../../../../components/common/Card.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardTitle from "../../../../components/common/CardTitle.js";
import AssessmentCardTitle from "../../../../components/common/CardTitle.js";
import { history } from "../../../../helper/history.js";
import CardBody from "../../../../components/common/CardBody.js";
import DataHandler from "../../../../handlers/DataHandler.js";
import Option from "../../../../components/common/form/Option.js";
import AdminTemplate from "../../../../components/admin/layouts/Template.js";
import decorationLeft from "../../../../assets/images/studentdashboard-left.png";
import decorationRight from "../../../../assets/images/studentdashboard-right.png";
import { ManageDashboardService } from "../../../../service/manage/dashboard/manage_dashboard.service.js";
import { AssessmentService } from "../../../../service/candidate/assessmentService.js";
import { assessmentService } from "../../../../service/assessment/assessmentService.js";
import Button from "react-bootstrap/Button";
import { PaperService } from "../../../../service/manage/paper/paper_service.js";
import DoughnutChart from "../../../manage/charts/DoughnutChart.js";
import BarChart from "../../../manage/charts/BarChart.js";
import DrillChart from "../../../manage/charts/DrillChart.js";
import ProgressCircle from "../../../manage/charts/ProgresCircle.js";
import FigureChart from "../../../manage/charts/FigureChart.js";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import TaskIcon from "@mui/icons-material/Task";
import GradeIcon from "@mui/icons-material/Grade";
import QuizIcon from "@mui/icons-material/Quiz";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton
} from "@mui/material";
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import TeacherAnnouncementCard from "../TeacherAnnouncementCard.js";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ParentDashboardService } from "../../../../service/manage/dashboard/parent_dashboard.service.js";
import Chip from "@mui/material/Chip";
import Modal from "react-bootstrap/Modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { examService } from "../../../../service/candidate/examService.js";
import { AdminDashboardService } from "../../../../service/manage/dashboard/admin_dashboard.service.js";
import AddStudentModal from "../AddStudentModal.js";
import AddTeacherModal from "../AddTeacherModal.js";
import { AnnouncementService } from "../../../../service/manage/announcement/announcement.service.js";
import AdminAnnouncementCard from "../AdminAnnouncementCard.js";
import { formFixture } from '../../../manage/hierarchy/FormFixture.js';
import { assessmentActions } from "../../../../actions/assessment/assessmentActions.js";
import { connect } from "react-redux";
import { manageUserService } from "../../../../service/user_management/manage.user.service.js";
import { routes } from "../../../../helper/routes.js";
import config from '../../../../helper/config.js';
import { customerConstants } from "../../../../helper/constants.js";
import StudentsStatModel from "../StudentStatModel.js";
import QuickGuides from "../QuickGuides.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let isCMC, isEdSider, welcomeText, welcomeAssessmentText, welcomeParagraph, headingFontSize, iconColor, addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    welcomeText = "Welcome to Exam Online!"
    welcomeAssessmentText = "Welcome to Exam Online Assessment!"
    welcomeParagraph =
      "Our fun- filled learning platform. Get ready embark on an exciting adventure of knowledge!"
    iconColor = "var(--maincolor)"
    isCMC = false
    isEdSider = true
    break
  case customerConstants.EXO_CUSTOMER2:
    welcomeText = "Welcome to Chithambara Maths Challenge!"
    welcomeAssessmentText = "Welcome to CMC Past Papers!"
    welcomeParagraph = ""
    headingFontSize = { fontSize: "40px" }
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    }
    iconColor = "var(--maincolor-cmc)"
    isCMC = true
    isEdSider = false
    break
  default:
    welcomeText = "Welcome to EdFoci!"
    welcomeAssessmentText = "Welcome to EdFoci Assessment!"
    iconColor = "var(--maincolor)"
    isCMC = false
    isEdSider = false
    break
}

class ManageDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: [],
      registrationLoading: true,
      dashboardDataLoading: true,
      enrollmentLoading: true,
      studentEnrollmentLoading: true,
      attendanceLoading: true,
      paperLoading: true,
      childPaperLoading: true,
      registrationData: null,
      enrollmentData: null,
      childEnrollment: null,
      attendance: null,
      paperEnrollemnt: null,
      childPaperEnroll: null,
      totalEnrolledAssignedExams: "",
      totalEnrolledOnprogressExams: "",
      totalEnrolledCompletedExams: "",
      userId: "",
      userName: "",
      totalAllExams: "",
      dashboardData: [],
      totalEnrolledExams: "",
      search_term: "",
      dataLoading: false,
      limit: 10,
      offset: 0,
      shouldShowChildDashboard: false,
      shouldShowParentDashboard: false,
      shouldShowAdminDashboard: false,
      shouldShowTeacherDashboard: false,
      paperAnnonucements: [],
      studentListParent: [],
      announcementsLoading: false,
      paperAnnonucementsLoading: false,
      stuListLoading: false,
      studentDetailsModal: false,
      selectedStudent: null,
      gradeReportModal: false,
      selectedExam: null,
      gradeReportLoading: false,
      gradeReport: null,
      stuListCountAdmin: null,
      invitationDetailsModal: false,
      studentStatsModal: false,
      selectedInvitation: null,
      paperLoading: false,
      addedPapers: [],
      isAddStudentModalOpen: false,
      isAddTeacherModalOpen: false,
      isSearchPaperModalVisible: false,
      isBuyPaperModalVisible: false,
      announcements: [],
      currentIndex: 0,
      rowsToShow: 4,
      myAssessmentsLoading: true,
      loadingQuestions: true,
      loadingCategories: true,
      loadingTypes: true,
      loadingSubjects: true,
      userType: sessionStorage.getItem("userType"),
      featureFlag: getCustomerFeatureFlag()
    };
  }

  getCustomerFeatureFlag = () => {
    return config.customerConfigs.featureFlag;
  };

  async componentDidMount() {
    const user_id = sessionStorage.getItem("user_id");
    const user_name = sessionStorage.getItem("username");
    const userType = sessionStorage.getItem("userType");
    const lg_user_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");

    this.setState(() => {
      if (userType === "student") {
        return { shouldShowChildDashboard: true, shouldShowParentDashboard: false, shouldShowAdminDashboard: false, shouldShowTeacherDashboard: false }
      } else if (userType === "parent") {
        return { shouldShowChildDashboard: false, shouldShowParentDashboard: true, shouldShowAdminDashboard: false, shouldShowTeacherDashboard: false };

      } else if (userType === "admin") {
        return { shouldShowChildDashboard: false, shouldShowParentDashboard: false, shouldShowAdminDashboard: true, shouldShowTeacherDashboard: false }

      } else if (userType === "teacher") {
        return { shouldShowChildDashboard: false, shouldShowParentDashboard: false, shouldShowAdminDashboard: false, shouldShowTeacherDashboard: true }
      }
    });

    await this.getDashboardStats()

    if (userType === "student") {
      this.setState({ userId: user_id }, async () => {
        this.setState({ dataLoading: true });
        await this.fetchAllExamsCount();
        await this.fetchEnrolledAssignedExamsCount();
        await this.fetchEnrolledOnprogressExamsCount();
        await this.fetchEnrolledCompletedExamsCount();
        this.fetchTotalEnrolledExamsCount();
        this.setState({ dataLoading: false });
      });

      const queryString = new URLSearchParams(this.props.location.search);
      if (queryString.get('enrolled-paper-list') === 'true' && queryString.get('node-mid')) {
        if (this.state.paperEnrollemnt !== null) {
          const nodeMid = parseInt(queryString.get('node-mid'), 10);
          let formData = this.state.paperEnrollemnt.find(item => item.node_mid === nodeMid);
          if (formData) {
            formData.grade_report_candidate_id = queryString.get('candidate-id') ? parseInt(queryString.get('candidate-id'), 10) : null;
            this.handleApplicationSelect(formData);
          }
        }
      }
    }
    else if (userType === "parent") {
      this.fetchStudentDetailsForDashboard(lg_user_id)
      this.fetchAnnonucementsAdminDashboard(lg_user_id, table_log_id)
      this.fetchPaperForAnnonucements(lg_user_id)
    }
    else if (userType === "admin") {
      this.fetchStudentAndRegCountDashboard()
      this.fetchLatestAddedPapers()
      this.fetchAnnonucementsAdminDashboard(lg_user_id, table_log_id)
    }
    else if (userType === "teacher") {
      var teacher_user_id = DataHandler.getFromSession("user_id");

      await assessmentService.getAllAssessments().then((res) => {
        this.setState({
          managedAssessments: res.data.assessments,
        });
      });

      await this.props.getAssessmentSubject();

      const lg_user_id = DataHandler.getFromSession("manage_user_id");
      const table_log_id = DataHandler.getFromSession("table_log_id");

      this.fetchAnnonucementsAdminDashboard(lg_user_id, table_log_id);

      const totalAssessCountResponse =
        await assessmentService.getAllAssessmentsCount(teacher_user_id);

      if (!totalAssessCountResponse.error) {
        this.setState({
          totalCountMyAssessments:
            totalAssessCountResponse.data.total_assessments_count, // Adjust this based on the response structure
        });
      }
      this.setState({
        myAssessmentsLoading: false, // Set loading state to false
      });

      const totalPublicAssessmentsCountResponse =
        await assessmentService.getTotalPublicAssessmentsCount();

      if (!totalPublicAssessmentsCountResponse.error) {
        this.setState({
          totalCountPublicAssessments: totalPublicAssessmentsCountResponse.data.total_public_assessment_count,
        });
      }

      const totalSharedAssessmentsCountResponse =
        await assessmentService.getTotalSharedAssessmentsCount(teacher_user_id);

      if (!totalSharedAssessmentsCountResponse.error) {
        this.setState({
          totalCountSharedWithMe: totalSharedAssessmentsCountResponse.data.total_count,
        });
      }

      const totalCountResponse = await examService.getAllCount();

      if (!totalCountResponse.error) {
        this.setState({
          questionCount: totalCountResponse.data.get_total_question_count,
          loadingQuestions: false,
          categoryCount: totalCountResponse.data.total_categories_count,
          loadingCategories: false,
          typeCount: totalCountResponse.data.total_question_types_count,
          subjectCount: totalCountResponse.data.total_subjects_count,
        });
      }

      if (this.props.all.allSubjects.length > 0) {
        let allSubjects = [];
        allSubjects.push(
          <Option key={null} optionName="Nothing selected" value={null} />
        );
        this.props.all.allSubjects.map((el, i) => {
          allSubjects.push(
            <Option
              key={el.subject_id}
              optionName={el.name}
              value={el.subject_id}
            />
          );
        });

        this.setState({
          subjectOptions: allSubjects,
          selectedSubject: allSubjects[0].props.value,
        });

        if (allSubjects) window.$("#assessment-subject").selectpicker("refresh");
      }
    }
    this.setState({ userName: user_name });
  }

  handleApplicationSelect = (formData) => {
    let state = {
      pid: formData.pid,
      mid: formData.node_mid,
      lg_user_id: formData.lg_user_id,
      lg_user_table_id: formData.lg_user_table_id,
      flg_list_active: formData.flg_list_active,
      privilege_view: formData.privilege_view,
      privilege_edit: formData.privilege_edit,
      privilege_add: formData.privilege_add,
      privilege_delete: formData.privilege_delete,
      owner_restriction: formData.owner_restriction,
      user_list_restriction: formData.user_list_restriction,
      form_display_title: formData.form_display_title,
      form_display_order: formData.form_display_order,
      form_list_id: formData.form_list_id,
      breadcrumb2: formData.breadcrumb,
      main_table_id: formData.main_table_id,
      nodeTitle: formData.tree_main_title,
      path: 'dashboard',
      mcode: formData.mcode,
      grade_report_candidate_id: formData.grade_report_candidate_id || ''
    };
    const componentDetail = formFixture(state).find(item => formData.form_list_id === item.formListId)
    if (componentDetail && componentDetail.url) {
      history.push({
        pathname: componentDetail.url,
        state: { ...state }
      });
    }
  };

  async getDashboardStats() {
    this.setState({
      registrationLoading: true,
      enrollmentLoading: true,
      studentEnrollmentLoading: true,
      attendanceLoading: true,
    });
    const session_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");
    const userType = sessionStorage.getItem("userType");

    // DashboardService.getContent()
    if (userType === "admin") {
      await ManageDashboardService.getContent(
        session_id,
        table_log_id,
        "registration",
        "viewall"
      )
        .then((res) => {
          this.setState({ registrationData: res.data, registrationLoading: false });
        })
        .catch((error) => {
          this.setState({ registrationLoading: false });
        });
    } else {
      await ManageDashboardService.getContent(
        session_id,
        table_log_id,
        "registration",
        "creator"
      )
        .then((res) => {
          this.setState({ registrationData: res.data, registrationLoading: false });
        })
        .catch((error) => {
          this.setState({ registrationLoading: false });
        });
    }

    await ManageDashboardService.getStatistics(
      100,
      session_id,
      table_log_id,
      "viewall"
    )
      .then((res) => {
        this.setState({ dashboardData: res.data, dashboardDataLoading: false });
      })
      .catch((error) => {
        this.setState({ dashboardDataLoading: false });
      });

    await ManageDashboardService.getContent(
      session_id,
      table_log_id,
      "enrollment",
      "owner"
    )
      .then((res) => {
        this.setState({ enrollmentData: res.data, enrollmentLoading: false });
      })
      .catch((error) => {
        this.setState({ enrollmentLoading: false });
      });
    await ManageDashboardService.getContent(
      session_id,
      table_log_id,
      "enrollment",
      "creator"
    )
      .then((res) => {
        this.setState({
          childEnrollment: res.data,
          studentEnrollmentLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ studentEnrollmentLoading: false });
      });
    await ManageDashboardService.getContent(
      session_id,
      table_log_id,
      "attendance",
      "owner"
    )
      .then((res) => {
        this.setState({ attendance: res.data, attendanceLoading: false });
      })
      .catch((error) => {
        this.setState({ attendanceLoading: false });
      });

    await ManageDashboardService.getContent(
      session_id,
      table_log_id,
      "assessment-enrolled",
      "owner"
    )
      .then((res) => {
        this.setState({ paperEnrollemnt: res.data, paperLoading: false });
      })
      .catch((error) => {
        this.setState({ paperLoading: false });
      });
    await ManageDashboardService.getContent(
      session_id,
      table_log_id,
      "assessment-enrolled",
      "creator"
    )
      .then((res) => {
        this.setState({ childPaperEnroll: res.data, childPaperLoading: false });
      })
      .catch((error) => {
        this.setState({ childPaperLoading: false });
      });
  }

  async fetchPaperForAnnonucements(lg_user_id) {
    this.setState({ paperAnnonucementsLoading: true })
    await ParentDashboardService.getAllForAnnonucements(lg_user_id)
      .then((res) => {
        this.setState({ paperAnnonucements: res.data?.assessments, paperAnnonucementsLoading: false });
      })
      .catch((err) => {
        this.setState({ paperAnnonucementsLoading: false })
      })
  }

  async fetchAnnonucementsAdminDashboard(lg_user_id, lg_user_table_id) {
    this.setState({ announcementsLoading: true })
    await AnnouncementService.getAllAnnouncementsAdmin(lg_user_id, lg_user_table_id)
      .then((res) => {
        this.setState({ announcements: res.data.announcements, announcementsLoading: false });
      })
      .catch((err) => {
        this.setState({ announcementsLoading: false })
      })
  }

  async fetchStudentDetailsForDashboard(user_id) {
    this.setState({ stuListLoading: true })
    await ParentDashboardService.getAllStudentsForParent(user_id)
      .then((res) => {
        this.setState({ studentListParent: res.data, stuListLoading: false });
      })
      .catch((err) => {
        this.setState({ stuListLoading: false })
      })
  }

  async fetchAllExamsCount() {
    await PaperService.getAllCount().then((res) => {
      this.setState({ totalAllExams: res.data.count });
    });
  }

  async fetchEnrolledAssignedExamsCount() {
    const { userId } = this.state;
    await AssessmentService.getExamsCount(userId, "Assigned").then((res) => {
      this.setState({ totalEnrolledAssignedExams: parseInt(res.data.total_count) });
    });
  }

  async fetchEnrolledOnprogressExamsCount() {
    const { userId } = this.state;
    await AssessmentService.getExamsCount(userId, "Onprogress").then((res) => {
      this.setState({
        totalEnrolledOnprogressExams: parseInt(res.data.total_count),
      });
    });
  }

  async fetchEnrolledCompletedExamsCount() {
    const { userId } = this.state;
    await AssessmentService.getExamsCount(userId, "Finished").then((res) => {
      this.setState({ totalEnrolledCompletedExams: parseInt(res.data.total_count) });
    });
  }

  fetchTotalEnrolledExamsCount() {
    const {
      totalEnrolledAssignedExams,
      totalEnrolledOnprogressExams,
      totalEnrolledCompletedExams,
    } = this.state;
    let totalEnrolledExams =
      totalEnrolledAssignedExams +
      totalEnrolledOnprogressExams +
      totalEnrolledCompletedExams;
    this.setState({ totalEnrolledExams: totalEnrolledExams });
  }

  async fetchAnnonucementsAdminDashboard(lg_user_id, lg_user_table_id) {
    this.setState({ announcementsLoading: true });
    await manageUserService
      .getAllAnnouncementsAdmin(lg_user_id, lg_user_table_id)
      .then((res) => {
        this.setState({
          announcements: res.data.announcements,
          announcementsLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ announcementsLoading: false });
      });
  }

  handleViewMore = () => {
    // Update the state to display more rows
    this.setState((prevState) => ({
      rowsToShow: prevState.rowsToShow + 4, // Increase the number of rows by 4
    }));
  };

  handleViewLess = () => {
    // Update the state to display fewer rows
    this.setState((prevState) => ({
      rowsToShow: Math.max(prevState.rowsToShow - 4, 4), // Decrease the number of rows by 4, but not below 4
    }));
  };

  getColor(state) {
    switch (state) {
      case "Initial":
        return "label label-lg label-light-danger label-inline";
      case "Scheduled":
        return "label label-lg label-light-primary label-inline";
      case "Assigned":
        return "label label-lg label-light-warning label-inline";
      case "Ready":
        return "label label-lg label-light-success label-inline";
      case "Finished":
        return "label label-lg label-light-default label-inline";
      default:
        return "label label-lg label-light-info label-inline";
    }
  }

  displaySubject = (e) => {
    if (e !== null) {
      const subject = this.props.all.allSubjects.find(
        (subject) => subject.subject_id == e
      );
      return subject?.name;
    } else {
      return "N/A";
    }
  };

  renderCards() {
    const { cardData } = this.state;
    return cardData.map((card, index) => (
      <Col key={index} lg={4} md={6} sm={12} className="mb-3">
        <Card>
          <Card.Body>
            <p
              className="py-3 dashboard-card-topic"
              style={{ fontSize: "15px" }}
            >
              {card.ttl}
            </p>
            <span className="dashboard-card-count" style={{ fontSize: "24px" }}>
              {card.cnt}
            </span>
          </Card.Body>
        </Card>
      </Col>
    ));
  }

  renderDoghnutCharts() {
    const { dashboardData } = this.state;
    let doughnut = dashboardData?.filter((val) => {
      return val.chart_type == "donut"
    })
    let progressCircle = dashboardData?.filter((val) => {
      return val.chart_type == "progress_circle"
    })

    let bar = dashboardData?.filter((val) => {
      return val.chart_type == "bar"
    })

    let drillChart = dashboardData?.filter((val) => {
      return val.chart_type == "drill"
    })

    let figureData = dashboardData?.filter((val) => {
      return val.chart_type == "figure"
    })

    let doughnutValue = this.createArrayWithEqualIdGroups(doughnut)
    let barValue = this.createArrayWithEqualIdGroups(bar)

    return (
      <>
        {dashboardData !== null && (
          <div className="col-md-12">
            <div className="announcement-container">
              <div className="pt-4 pb-3 pl-3">
                <p className="parent-section-title">
                  {" "}
                  <InsertChartIcon sx={{ fontSize: 28, color: iconColor }} /> Statistics
                </p>
              </div>

              <CardBody key={doughnut.length}>
                {figureData.length > 0 && (
                  <FigureChart val={figureData} />
                )}
                {doughnut.length > 0 && (
                  <div
                    className={
                      doughnutValue.length > 1
                        ? "row d-flex justify-content-around"
                        : "col-md-12 d-flex justify-content-around"
                    }
                    style={{ marginBottom: "40px" }}
                  >
                    {doughnutValue.map((val, index) => (
                      <div className={
                        doughnutValue.length > 1
                          ? "row d-flex justify-content-around doughnutchart-card"
                          : "col-md-12 d-flex justify-content-around doughnutchart-card"
                      }>
                        <DoughnutChart val={val} />
                      </div>
                    ))}
                  </div>
                )}
                {progressCircle.length > 0 && (
                  <div
                    className={
                      progressCircle.length > 1
                        ? "row d-flex justify-content-around"
                        : "col-md-12 d-flex justify-content-around"
                    }
                    style={{ marginBottom: "40px" }}
                  >
                    {progressCircle.map((val, index) => (
                      <div
                        className={
                          progressCircle.length > 1
                            ? "col-md-6 col-12 progress-card"
                            : "col-md-12 col-12 progress-card"
                        }
                      >
                        <ProgressCircle val={val} />
                      </div>
                    ))}
                  </div>
                )}
                {bar.length > 1 && (
                  <div
                    className={
                      barValue.length > 1 ? "row d-flex" : "col-md-12 d-flex"
                    }
                    style={{ marginBottom: "40px" }}
                  >
                    {barValue.map((val, index) => (
                      <div
                        className={
                          barValue.length > 1 ? "col-md-6 px-0" : "col-md-12"
                        }
                      >
                        <BarChart val={val} />
                      </div>
                    ))}
                  </div>
                )}
                {drillChart.length > 1 && (
                  <div className="col-md-12" style={{ marginBottom: "40px" }}>
                    <div className="row d-flex justify-content-around">
                      <div className="col-md-12">
                        <DrillChart val={drillChart} />
                      </div>
                    </div>
                  </div>
                )}
              </CardBody>
            </div>
          </div>
        )}
      </>
    )
  }

  createArrayWithEqualIdGroups = (arr) => {
    const idGroupMap = {};

    // Group objects by id
    arr.forEach(obj => {
      const id = obj.chart_id;
      idGroupMap[id] = idGroupMap[id] || [];
      idGroupMap[id].push(obj);
    });

    const result = Object.values(idGroupMap).filter(group => group.length > 1);

    return result;
  };

  loaderSection = () => {
    return (
      <div className="loading-container">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }

  renderSections = () => {
    let cardTitle = "My Registrations"; // Default title

    const userType = sessionStorage.getItem("userType");
    if (userType === "admin") {
      cardTitle = "Registrations";
    } else if (userType === "parent") {
      cardTitle = "My Student Registrations";
    }

    return (
      <>
        {this.state.registrationLoading ? (
          this.loaderSection()
        ) : (
          <div className="mb-2">
            {this.state.registrationData !== null && (
              <Card>
                <CardHeader>
                  <CardTitle cardLabel={cardTitle} />
                </CardHeader>
                <CardBody>
                  {this.state.registrationData.map((data) => this.cardData(data))}
                </CardBody>
              </Card>
            )}
          </div >
        )
        }
        {
          this.state.enrollmentLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              {this.state.enrollmentData !== null && (
                <Card>
                  <CardHeader>
                    <CardTitle cardLabel="My Enrollments" />
                  </CardHeader>
                  <CardBody>
                    {this.state.enrollmentData.map((data) => this.cardData(data))}
                  </CardBody>
                </Card>
              )}
            </div>
          )
        }
        {
          this.state.studentEnrollmentLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              {this.state.childEnrollment !== null && (
                <Card>
                  <CardHeader>
                    <CardTitle cardLabel="Students’ Enrollments" />
                  </CardHeader>
                  <CardBody>
                    {this.state.childEnrollment.map((data) => this.cardData(data))}
                  </CardBody>
                </Card>
              )}
            </div>
          )
        }
        {
          this.state.attendanceLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              {this.state.attendance !== null && (
                <Card>
                  <CardHeader>
                    <CardTitle cardLabel="My Attendance" />
                  </CardHeader>
                  <CardBody>
                    {this.state.attendance.map((data) => this.cardData(data))}
                  </CardBody>
                </Card>
              )}
            </div>
          )
        }
        {
          this.state.paperLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              {this.state.paperEnrollemnt !== null && (
                <Card>
                  <CardHeader>
                    <CardTitle cardLabel="Your Paper Enrollments" />
                  </CardHeader>
                  <CardBody>
                    {this.state.paperEnrollemnt.map((data) => this.cardData(data))}
                  </CardBody>
                </Card>
              )}
            </div>
          )
        }
        {
          this.state.childPaperLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              {this.state.childPaperEnroll !== null && (
                <Card>
                  <CardHeader>
                    <CardTitle cardLabel="Papers enrolled for students" />
                  </CardHeader>
                  <CardBody>
                    {this.state.childPaperEnroll.map((data) => this.cardData(data))}
                  </CardBody>
                </Card>
              )}
            </div>
          )
        }
      </>
    );
  };

  toggleSearchPaperModal = () => {
    this.setState((prevState) => ({
      isSearchPaperModalVisible: !prevState.isSearchPaperModalVisible
    }))
  }

  toggleBuyPaperModal = () => {
    this.setState((prevState) => ({
      isBuyPaperModalVisible: !prevState.isBuyPaperModalVisible
    }))
  }

  //Quick guides for CMC and EdSider
  renderQuickGuide = () => {
    return (
      <QuickGuides />
    )
  }

  convertMinutesToTime(minutes) {
    if(minutes !== null){
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;

      return `${hours}h ${remainingMinutes}m`;
    }
    else{
      return 'N/A';
    }
  }

  toggleStudentDetailsViewModal = (student) => {

    const { studentDetailsModal } = this.state;

    this.setState((prevState) => ({
      selectedStudent: student,
      studentDetailsModal: !prevState.studentDetailsModal
    }), () => {
      if (!this.state.studentDetailsModal) {
        this.setState({ selectedStudent: null })
      }
    });
  }

  toggleInvitationDetailsViewModal = (student) => {

    const { invitationDetailsModal } = this.state;

    this.setState((prevState) => ({
      selectedInvitation: student,
      invitationDetailsModal: !prevState.invitationDetailsModal
    }), () => {
      if (!this.state.invitationDetailsModal) {
        this.setState({ selectedInvitation: null })
      }
    });
  }

  toggleStudentStatsModal = (student) => {
    this.setState((prevState) => {
      return ({
        selectedStudent: student,
        studentStatsModal: !prevState.studentStatsModal
      })
    })
  }

  toggleStudentGradeReportViewModal = (exam_paper) => {

    const { gradeReportModal } = this.state;

    this.setState((prevState) => ({
      gradeReportLoading: true,
      selectedExam: exam_paper,
      gradeReportModal: !prevState.gradeReportModal
    }), () => {
      if (this.state.gradeReportModal) {
        this.getGradeReportForExam(exam_paper)
      }
      else {
        this.setState({ gradeReportLoading: false, selectedExam: null, gradeReport: null })
      }
    });
  }

  getGradeReportForExam = (exam_paper) => {

    if (exam_paper.candidate_id && exam_paper.attempt_no) {

      examService.getReport(exam_paper.candidate_id, exam_paper.attempt_no)
        .then((response) => {
          this.setState({ gradeReport: response.data.assessment, gradeReportLoading: false })
        })
        .catch((error) => {
          this.setState({ gradeReportLoading: false })
        })

    }
  }

  async fetchStudentAndRegCountDashboard() {
    this.setState({ stuListLoading: true })
    await AdminDashboardService.getStudentAndRegCount()
      .then((res) => {
        if (res.success) {
          this.setState({ stuListCountAdmin: res.data, stuListLoading: false });
        }
        else {
          this.setState({ stuListLoading: false })
        }
      })
      .catch((err) => {
        this.setState({ stuListLoading: false })
      })
  }

  async fetchLatestAddedPapers() {
    this.setState({ paperLoading: true })
    await AdminDashboardService.getLatestAddedAssessment()
      .then((response) => {
        this.setState({ addedPapers: response.data.assessments, paperLoading: false });
      })
      .catch((err) => {
        this.setState({ paperLoading: false })
      })
  }

  toggleAddStudentModal = () => {
    this.setState((prevState) => ({
      isAddStudentModalOpen: !prevState.isAddStudentModalOpen,
    }), () => {
      if (!this.state.isAddStudentModalOpen) {
        this.fetchStudentAndRegCountDashboard()
      }
    });
  };

  toggleAddTeacherModal = () => {
    this.setState((prevState) => ({
      isAddTeacherModalOpen: !prevState.isAddTeacherModalOpen,
    }), () => {
      if (!this.state.isAddTeacherModalOpen) {
        this.fetchStudentAndRegCountDashboard()
      }
    });
  };

  renderAdminDashboard = () => {

    const { stuListCountAdmin, invitationDetailsModal, selectedInvitation, paperLoading, addedPapers, dashboardDataLoading, studentStatsModal } = this.state;

    return (
      <ContentWrapper>
        <Card>
          <CardHeader>
            <CardTitle cardLabel={welcomeText} />
          </CardHeader>
          <CardBody>
            <div className="row px-0 mb-4">
              <div className="col-md-12 mb-5">
                <div className="row">
                  {this.renderQuickGuide()}
                </div>
              </div>
              <div className="col-md-7">
                <div className="row">
                  {dashboardDataLoading ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      {this.loaderSection()}
                    </div>
                  ) : (
                    this.state.dashboardData.length > 0 && this.renderDoghnutCharts()
                  )}
                </div>
              </div>
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row users-container">
                      <div className="col-md-12">
                        <p className="parent-section-title">
                          {" "}
                          <GroupIcon sx={{ fontSize: 26, color: iconColor }} /> Users
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="row d-flex justify-content-between">
                          <div className={`user-cards ${isCMC ? 'col-md-6' : 'col-md-4'}`}>
                            <div className="row">
                              <div className="col-md-12">Students</div>
                              <div className="col-md-12 user-card-value">{stuListCountAdmin?.registration_count?.student_count}</div>
                            </div>
                          </div>
                          {!isCMC && (
                            <div className="col-md-4 user-cards">
                              <div className="row">
                                <div className="col-md-12">Parents</div>
                                <div className="col-md-12 user-card-value">{stuListCountAdmin?.registration_count?.parent_count}</div>
                              </div>
                            </div>
                          )}
                          <div className={`user-cards ${isCMC ? 'col-md-6' : 'col-md-4'}`}>
                            <div className="row">
                              <div className="col-md-12">Teachers</div>
                              <div className="col-md-12 user-card-value">{stuListCountAdmin?.registration_count?.teacher_count}</div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row mt-5 users-container">
                      <div className="col-md-12">
                        <p className="parent-section-title">
                          {" "}
                          <PersonAddIcon sx={{ fontSize: 26, color: iconColor }} /> User Registrations
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="row d-flex justify-content-between">
                          <div className="col-md-6 user-cards">
                            <div className="row d-flex align-items-center">
                              <div className="col-md-8">Students</div>
                              <div className="col-md-4 d-flex justify-content-end">
                                <IconButton onClick={() => this.toggleAddStudentModal()}>
                                  <AddCircleIcon sx={{ fontSize: 26, color: iconColor }} />
                                </IconButton>
                                <AddStudentModal isAddStudentModalOpen={this.state.isAddStudentModalOpen} toggleAddStudentModal={this.toggleAddStudentModal} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6 user-cards">
                            <div className="row d-flex align-items-center">
                              <div className="col-md-8">Teachers</div>
                              <div className="col-md-4 d-flex justify-content-end">
                                <IconButton onClick={() => this.toggleAddTeacherModal()}>
                                  <AddCircleIcon sx={{ fontSize: 26, color: iconColor }} />
                                </IconButton>
                                <AddTeacherModal isAddTeacherModalOpen={this.state.isAddTeacherModalOpen} toggleAddTeacherModal={this.toggleAddTeacherModal} fetchAllTeachers={null} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 pt-8">
                    <div className="row">
                      <div className="col-md-12 px-0">
                        <p className="parent-section-title">
                          {" "}
                          <CampaignRoundedIcon sx={{ fontSize: 28, color: iconColor }} />{" "}
                          Annonucements
                        </p>
                      </div>
                      <div className="col-md-12 announcement-container px-0">
                        {this.state.announcementsLoading ? (
                          <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i className="fas fa-spinner fa-spin"></i>
                          </div>
                        ) : (
                          (this.state.announcements?.length > 0 ? (
                            this.state.announcements.slice(-3).reverse().map((announcement, i) => (
                              <AdminAnnouncementCard key={i} announcements={announcement} />
                            ))
                          ) : (
                            <p className="py-4 px-3">No announcements</p>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={invitationDetailsModal}
          onHide={() => this.toggleInvitationDetailsViewModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Student details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Typography>
                  <strong>Student First Name:</strong>
                </Typography>
                <Typography>{selectedInvitation?.first_name ? selectedInvitation.first_name : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Student Last Name:</strong>
                </Typography>
                <Typography>{selectedInvitation?.last_name ? selectedInvitation.last_name : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Student Grade:</strong>
                </Typography>
                <Typography>{selectedInvitation?.grade ? selectedInvitation.grade : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Student Email:</strong>
                </Typography>
                <Typography>{selectedInvitation?.child_email ? selectedInvitation.child_email : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>School:</strong>
                </Typography>
                <Typography>{selectedInvitation?.school ? selectedInvitation.school : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Parent First Name:</strong>
                </Typography>
                <Typography>{selectedInvitation?.parent_fname ? selectedInvitation.parent_fname : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Parent Last Name:</strong>
                </Typography>
                <Typography>{selectedInvitation?.parent_lname ? selectedInvitation.parent_lname : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Parent Phone Number:</strong>
                </Typography>
                <Typography>{selectedInvitation?.phone_number ? selectedInvitation.phone_number : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Parent Email:</strong>
                </Typography>
                <Typography>{selectedInvitation?.parent_email ? selectedInvitation.parent_email : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Status</strong>
                </Typography>
                <Typography>
                  {selectedInvitation?.invitation_status === 0 ? (
                    <Chip label="Pending" color="primary" />
                  ) : selectedInvitation?.invitation_status === 1 ? (
                    <Chip label="Approved" color="success" />
                  ) : selectedInvitation?.invitation_status === 2 ? (
                    <Chip label="Rejected" color="error" variant="outlined" />
                  ) : (
                    <Chip
                      label="Registered"
                      color="success"
                      variant="outlined"
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={() =>
                this.toggleInvitationDetailsViewModal()
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </ContentWrapper>
    )
  }

  convertUTCtoIST(utcDateString) {
    // Parse the UTC date string
    const utcTime = new Date(utcDateString);

    // Convert to IST (Indian Standard Time, UTC+5:30)
    const istOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
    const istTime = new Date(utcTime.getTime() + istOffset);

    // Format the date for display
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'Asia/Kolkata'
    };

    const istTimeString = istTime.toLocaleString('en-US', options);

    return istTimeString;
  }

  rednderParentDashboard = () => {
    const { studentDetailsModal, selectedStudent, gradeReportModal } = this.state;

    return (
      <ContentWrapper>
        <Card>
          <CardHeader>
            <CardTitle cardLabel={welcomeText} />
          </CardHeader>
          <CardBody>
            <div className="row mb-4">
              <div className="col-md-12 mb-5">
                <div className="row">
                  {this.renderQuickGuide()}
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 px-0">
                    <p className="parent-section-title">
                      {" "}
                      <FolderSharedIcon sx={{ fontSize: 26, color: iconColor }} /> Children
                    </p>
                  </div>
                  <div className="col-md-12 announcement-container">
                    <div className="mb-5" style={{ overflowX: "auto" }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Student's First Name</TableCell>
                            <TableCell>Student's Last Name</TableCell>
                            <TableCell>School Name</TableCell>
                            <TableCell>Student Grade</TableCell>
                            <TableCell>Parent Contact Number</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.stuListLoading ? (
                            <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <i className="fas fa-spinner fa-spin"></i>
                            </div>
                          ) : (

                            (this.state.studentListParent.students?.length > 0 ? (
                              this.state.studentListParent.students?.map((student, i) => (
                                <TableRow>
                                  <TableCell>{student.first_name ? student.first_name : ""}</TableCell>
                                  <TableCell>{student.last_name ? student.last_name : ""}</TableCell>
                                  <TableCell>{student.school ? student.school : ""}</TableCell>
                                  <TableCell>{student.grade ? student.grade : ""}</TableCell>
                                  <TableCell>{this.state.studentListParent.parent.phone_number ? this.state.studentListParent.parent.phone_number : "--"}</TableCell>
                                  <TableCell>
                                    <button className="child-view-icon" onClick={() => this.toggleStudentDetailsViewModal(student)}>
                                      <VisibilityIcon
                                        sx={{ fontSize: 16, color: "#fff" }}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </button>
                                    <button className="child-view-icon" onClick={() => this.toggleStudentStatsModal(student)}>
                                      <InsertChartIcon
                                        sx={{ fontSize: 16, color: "#fff" }}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </button>
                                  </TableCell>
                                </TableRow>
                              ))
                            ) : (
                              <p className="py-4 px-3">No Children</p>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 px-0 pt-8">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-12 px-0">
                        <p className="parent-section-title">
                          {" "}
                          <ArticleRoundedIcon sx={{ fontSize: 26, color: iconColor }} /> Selected
                          Papers for Children
                        </p>
                      </div>
                      <div className="col-md-12 announcement-container">
                        <div className="mb-5" style={{ overflowX: "auto" }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Paper Added Date</TableCell>
                                <TableCell>Student First Name</TableCell>
                                <TableCell>Student Last Name</TableCell>
                                <TableCell>Paper Title</TableCell>
                                <TableCell>Paper Duration</TableCell>
                                <TableCell>Enrolled By</TableCell>
                                <TableCell>Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.paperAnnonucementsLoading ? (
                                <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <i className="fas fa-spinner fa-spin"></i>
                                </div>
                              ) : (
                                (this.state.paperAnnonucements.length > 0 ? (
                                  this.state.paperAnnonucements.slice(0, 5).map((paper, i) => (
                                    <TableRow>
                                      <TableCell>{this.convertUTCtoIST(paper.created_at)}</TableCell>
                                      <TableCell>{paper.first_name ? paper.first_name : ""}</TableCell>
                                      <TableCell>{paper.first_name ? paper.last_name : ""}</TableCell>
                                      <TableCell>{paper.title}</TableCell>
                                      <TableCell>{this.convertMinutesToTime(paper.exam_time_interval)}</TableCell>
                                      <TableCell>{paper.added_by}</TableCell>
                                      <TableCell>
                                        {paper.candidate_status === "Finished" ? (
                                          <Button
                                            className="btn btn-primary btn-sm font-weight-bold on-report-assement-btn"
                                            style={{ backgroundColor: iconColor }}
                                            onClick={() => this.toggleStudentGradeReportViewModal(paper)}
                                          >
                                            Grade Report
                                          </Button>

                                        ) : (
                                          <Chip label={paper.candidate_status ? paper.candidate_status : "Undefined"} color="primary" />
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <p className="py-4 px-3">No Papers</p>
                                ))
                              )}
                            </TableBody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="row">
                      <div className="col-md-12 px-0">
                        <p className="parent-section-title">
                          {" "}
                          <CampaignRoundedIcon sx={{ fontSize: 28, color: iconColor }} />{" "}
                          Annonucements
                        </p>
                      </div>
                      <div className="col-md-12 announcement-container px-0">
                        {this.state.announcementsLoading ? (
                          <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <i className="fas fa-spinner fa-spin"></i>
                          </div>
                        ) : (
                          (this.state.announcements.length > 0 ? (
                            this.state.announcements.slice(-3).reverse().map((announcement, i) => (
                              <AdminAnnouncementCard announcements={announcement} />
                            ))
                          ) : (
                            <p className="py-4 px-3">No announcements</p>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={studentDetailsModal}
          onHide={() => this.toggleStudentDetailsViewModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Student details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Typography>
                  <strong>Student First Name:</strong>
                </Typography>
                <Typography>{selectedStudent?.first_name ? selectedStudent.first_name : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Student Last Name:</strong>
                </Typography>
                <Typography>{selectedStudent?.last_name ? selectedStudent.last_name : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Student Grade:</strong>
                </Typography>
                <Typography>{selectedStudent?.grade ? selectedStudent.grade : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Student Email:</strong>
                </Typography>
                <Typography>{selectedStudent?.email ? selectedStudent.email : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>School:</strong>
                </Typography>
                <Typography>{selectedStudent?.school ? selectedStudent.school : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Parent First Name:</strong>
                </Typography>
                <Typography>{this.state.studentListParent.parent?.first_name ? this.state.studentListParent.parent.first_name : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Parent Last Name:</strong>
                </Typography>
                <Typography>{this.state.studentListParent.parent?.last_name ? this.state.studentListParent.parent.last_name : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Parent Phone Number:</strong>
                </Typography>
                <Typography>{this.state.studentListParent.parent?.phone_number ? this.state.studentListParent.parent?.phone_number : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Parent Email:</strong>
                </Typography>
                <Typography>{this.state.studentListParent.parent?.email ? this.state.studentListParent.parent?.email : ""}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <strong>Status</strong>
                </Typography>
                <Typography>
                  {selectedStudent?.invitation_status === 0 ? (
                    <Chip label="Pending" color="primary" />
                  ) : selectedStudent?.invitation_status === 1 ? (
                    <Chip label="Approved" color="success" />
                  ) : selectedStudent?.invitation_status === 2 ? (
                    <Chip label="Rejected" color="error" variant="outlined" />
                  ) : (
                    <Chip
                      label="Registered"
                      color="success"
                      variant="outlined"
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={() =>
                this.toggleStudentDetailsViewModal()
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={gradeReportModal}
          onHide={() => this.toggleStudentGradeReportViewModal()}
        >
          <Modal.Header closeButton>
            <Modal.Title>Student Grade Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.gradeReportLoading ? (
              <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            ) : (
              <div>

                <div className="row d-flex mb-4 align-items-center">
                  <div className="d-flex justify-content-center col-lg-12 col-md-4 col-sm-4">
                    <label className="access-grade-report">Access grade report here</label>
                  </div>
                  <div className="col-lg-6 col-md-8 col-sm-8 d-flex justify-content-end">
                    {/* <Breadcrumb>
                      <BreadcrumbItem
                        to="manage-hierarchy"
                        breadcrumbItemName="Go to Enrolled Paper List"
                      />
                      <BreadcrumbItem
                        to="grade-report"
                        breadcrumbItemName="Grade Report"
                      />
                    </Breadcrumb> */}
                  </div>
                </div>
                <div className="row exam-details">
                  <div className="col col-lg-12">
                    {/* <div className="row">
                                        <div className="col col-lg-12 mb-5">
                                            <span className="d-block text-muted font-size-sm">Candidate Name:</span>
                                            <h3 className="m-0">{this.props.report.candidate_id}</h3>
                                        </div>
                                    </div> */}
                    <div className="row">
                      <div className="col col-lg-12 col-md-4 col-12 mb-5 d-flex justify-content-center">
                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center">
                            <span className="text-muted font-size-sm">
                              Exam Title:
                            </span>
                          </div>
                          <div className="col-md-12 d-flex justify-content-center">
                            <h3 className="m-0">{this.state.selectedExam?.title}</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col col-lg-12 col-md-4 col-12 mb-5 d-flex justify-content-center">
                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center">
                            <span className="text-muted font-size-sm">
                              Attempted Date:
                            </span>
                          </div>
                          <div className="col-md-12 d-flex justify-content-center">
                            <h3 className="m-0">
                              {moment(
                                this.state.gradeReport?.attempt_start_time
                              ).format("MMMM Do YYYY")}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <div className="col col-lg-12 col-md-4 col-12 mb-5 d-flex justify-content-center">
                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center">
                            <span className="text-muted font-size-sm">
                              Final Grade:
                            </span>
                          </div>
                          <div className="col-md-12 d-flex justify-content-center">
                            <h3 className="m-0">
                              {this.state.gradeReport?.final_grade > 0 ? String(this.state.gradeReport.final_grade) : "Not Finalized Yet"}
                            </h3>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col col-lg-12 col-md-4 col-12 mb-5 d-flex justify-content-center">
                        <div className="row">
                          <div className="col-md-12 d-flex justify-content-center">
                            <span className="text-muted font-size-sm">
                              Assessor Remarks:
                            </span>
                          </div>
                          <div className="col-md-12 d-flex justify-content-center">
                            <h3 className="m-0">
                              {remarks_view || "Not Available"}
                            </h3>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col col-lg-12 col-md-12 col-12 mb-5">
                        <span className="d-block text-muted font-size-sm">Proctor Remarks:</span>
                        <h3 className="m-0">{this.props.report.proctor_remarks || "Not Available"}</h3>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center">
                  {/* <Button
                    variant="contained"
                    style={{ backgroundColor: '#3d9bff', boxShadow: 'none' }}
                    onClick={
                      () => {
                        this.props.history.push({
                          pathname: '/paper-review',
                        })
                      }
                    }
                    disabled={this.state.gradeReport?.final_grade !== null ? false : true}
                  >
                    VIEW ANSWER SHEET
                  </Button> */}
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="modal-close-button"
              style={closeBtnStyle}
              onClick={() =>
                this.toggleStudentGradeReportViewModal()
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </ContentWrapper>
    )
  }

  cardData = (data) => {
    let breadcrumb = data?.breadcrumb
    if (this.state.userType !== 'admin' && this.state.featureFlag === customerConstants.EXO_CUSTOMER2) {
      let parts = data?.breadcrumb.split('>');
      let changedBreadcrumb = parts ? parts.slice(2).join('>') : undefined
      breadcrumb = changedBreadcrumb
    }
    return (
      <div className="row d-flex align-items-center my-2">
        <div className="col-md-8">
          <b>
            {`${breadcrumb}${data?.tree_main_title}`}
          </b>
        </div>
        <div className="col-md-4 d-flex justify-content-start">
          <Button
            className="insert-btn"
            style={{ backgroundColor: iconColor, border: "none" }}
            onClick={() => this.handleApplicationSelect(data)}
          >
            {data?.form_display_title}
          </Button>
        </div>
      </div>
    );
  };

  renderTeacherDashboard() {

    const { totalCountPublicAssessments, totalCountMyAssessments, managedAssessments, myAssessmentsLoading } = this.state;

    return (
      <>
        <Card>
          <CardHeader>
            <AssessmentCardTitle cardLabel={welcomeAssessmentText} />
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-12 pb-12">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row dashboard-top-container mr-1">
                      <div className="col-md-12 d-flex">
                        <p className="px-0 py-2 d-flex text-nowrap section-title">
                          {" "}
                          <QuizIcon sx={{ fontSize: 24, color: iconColor }} />{" "}
                          <Link to={routes.questions_library}><p style={{ color: iconColor }}>Questions</p></Link>
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="row px-2">
                          <div className="col-md-3">
                            <div className="row py-2 dashboard-cards">
                              <div className="col-md-12">Questions</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  this.state.questionCount || 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">Categories</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingCategories ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  this.state.categoryCount || 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">Types</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  this.state.typeCount || 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">Subjects</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  this.state.subjectCount || 0
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="row dashboard-top-container">
                      <div className="col-md-12 d-flex">
                        <p className="px-0 py-2 d-flex text-nowrap section-title">
                          {" "}
                          <TaskIcon sx={{ fontSize: 24, color: iconColor }} />{" "}
                          <Link to={routes.view_assessment}><p style={{ color: iconColor }}>Assessments</p></Link>
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="row px-2">
                          <div className="col-md-5">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">My Assessments</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  totalCountMyAssessments || 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">Shared with me</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  (totalCountPublicAssessments - totalCountMyAssessments) || 0
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="pb-3 section-title">
                          {" "}
                          <GradeIcon sx={{ fontSize: 24, color: iconColor }} /> Latest Assessments
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div
                          className="dashboard-bottom-container"
                          style={{ overflowX: "auto" }}
                        >
                          {managedAssessments ? (
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Code</TableCell>
                                  <TableCell>Created On</TableCell>
                                  <TableCell>Title</TableCell>
                                  <TableCell>Subject</TableCell>
                                  <TableCell>State</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {myAssessmentsLoading ? (
                                  // Display centered loading spinner
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  managedAssessments
                                    .slice(0, this.state.rowsToShow)
                                    .map(
                                      (assessment, i) => (
                                        (
                                          <TableRow key={i}>
                                            {/* <TableCell className="tData"></TableCell> */}
                                            {/* <TableCell className="tData">{i + 1}</TableCell> */}
                                            <TableCell className="tData">
                                              <div className="center-vertical mg-m library-title">
                                                {"ASMNT" +
                                                  assessment.assessment_id}
                                              </div>
                                            </TableCell>
                                            <TableCell className="tData">
                                              <div className="center-vertical mg-m library-title">
                                                {new Date(
                                                  assessment.created_at
                                                ).toLocaleDateString()}
                                              </div>
                                            </TableCell>
                                            <TableCell className="tData">
                                              <Tooltip
                                                title={assessment.title}
                                                placement="bottom-start"
                                                arrow
                                              >
                                                <div className="center-vertical mg-m library-title">
                                                  {assessment.title.length > 20
                                                    ? `${assessment.title.substring(
                                                      0,
                                                      20
                                                    )}...`
                                                    : assessment.title}
                                                </div>
                                              </Tooltip>
                                            </TableCell>
                                            <TableCell className="tData">
                                              <div className="center-vertical mg-m library-title">
                                                {this.displaySubject(
                                                  assessment.subject_id
                                                )}
                                              </div>
                                            </TableCell>
                                            <TableCell className="tData">
                                              <span
                                                className={this.getColor(
                                                  assessment.assessment_state
                                                )}
                                              >
                                                {assessment.assessment_state}
                                              </span>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )
                                    )
                                )}
                              </TableBody>
                            </Table>
                          ) : (
                            <p></p>
                          )}
                          {managedAssessments &&
                            managedAssessments.length >
                            this.state.rowsToShow && (
                              <div className="py-5 pr-5 d-flex justify-content-end">
                                <p
                                  className="view-more"
                                  onClick={this.handleViewMore}
                                >
                                  View More /
                                </p>
                                <br></br>
                                <br></br>
                                <p
                                  className="view-more"
                                  onClick={this.handleViewLess}
                                >
                                  View Less
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="pb-3 section-title">
                          {" "}
                          <CampaignRoundedIcon sx={{ fontSize: 24 }} />{" "}
                          Announcements
                        </p>
                      </div>
                      <div className="col-md-12 announcement-container">
                        {this.state.announcementsLoading ? (
                          <div
                            className="stu-card-title pt-2 col-md-12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <i className="fas fa-spinner fa-spin"></i>
                          </div>
                        ) : Array.isArray(this.state.announcements) &&
                          this.state.announcements.length > 0 ? (
                          this.state.announcements
                            .slice(-3)
                            .reverse()
                            .map((announcement, i) => (
                              <TeacherAnnouncementCard
                                announcements={announcement}
                                key={i}
                              />
                            ))
                        ) : (
                          <p className="py-4 px-3">No announcements</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </>
    );
  }

  render() {
    const {
      totalAllExams,
      totalEnrolledCompletedExams,
      totalEnrolledExams,
      userName,
      dataLoading,
      shouldShowChildDashboard,
      dashboardDataLoading,
      shouldShowParentDashboard,
      shouldShowAdminDashboard,
      shouldShowTeacherDashboard,
      studentStatsModal
    } = this.state;
    return (

      <AdminTemplate>

        <ContentWrapper>

          <StudentsStatModel
            studentStatsModal={studentStatsModal}
            toggleStudentStatsModal={this.toggleStudentStatsModal}
            closeBtnStyle={closeBtnStyle}
            selectedStudent={this.state.selectedStudent}
            iconColor={iconColor}
            createArrayWithEqualIdGroups={this.createArrayWithEqualIdGroups}
          />
          <Card>
            {shouldShowChildDashboard && (
              <div className="row">
                <div className="col-md-12">
                  <Card id="welcome-card">
                    <CardBody
                      style={{
                        background:
                          "linear-gradient(118deg, rgba(54, 153, 255, 1), rgba(54, 153, 255, 0.7))",
                      }}
                    >
                      <img
                        className="congratulations-img-left"
                        src={decorationLeft}
                        alt="decor-left"
                      />
                      <img
                        className="congratulations-img-right"
                        src={decorationRight}
                        alt="decor-right"
                      />
                      <div className="text-center py-5">
                        <h1
                          className="pt-5 welcome-card-topic"
                          style={headingFontSize}
                        >
                          {welcomeText}
                        </h1>
                        <p className="py-5 welcome-card-para">
                          {welcomeParagraph}
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    {this.renderQuickGuide()}
                  </div>
                </div>
                {/* Name cards */}
                <div className="col-md-12">
                  <div className="row pt-5 pb-5">
                    <div className="stu-dashboard-cards col-md-3">
                      <div className="row">
                        <div className="col-md-12">Name</div>
                        <div className="stu-card-title pt-2 col-md-12">
                          {userName}
                        </div>
                      </div>
                    </div>
                    <div className="stu-dashboard-cards col-md-3">
                      <div className="row">
                        <div className="col-md-12">All Papers</div>
                        {dataLoading ? (
                          <div className="stu-card-title pt-2 col-md-12">
                            <i className="fas fa-spinner fa-spin"></i>
                          </div>
                        ) : (
                          <div className="stu-card-title pt-2 col-md-12">
                            {totalAllExams ? totalAllExams : "0"}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="stu-dashboard-cards col-md-3">
                      <div className="row">
                        <div className="col-md-12">Enrolled Papers</div>
                        {dataLoading ? (
                          <div className="stu-card-title pt-2 col-md-12">
                            <i className="fas fa-spinner fa-spin"></i>
                          </div>
                        ) : (
                          <div className="stu-card-title pt-2 col-md-12">
                            {totalEnrolledExams}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="stu-dashboard-cards col-md-3">
                      <div className="row">
                        <div className="col-md-12">Completed Papers</div>
                        {dataLoading ? (
                          <div className="stu-card-title pt-2 col-md-12">
                            <i className="fas fa-spinner fa-spin"></i>
                          </div>
                        ) : (
                          <div className="stu-card-title pt-2 col-md-12">
                            {totalEnrolledCompletedExams}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            )}
            <div>
              <Row>
                <Col md={12} className="mb-4">
                  <Card id="welcome-card"></Card>
                  {!!shouldShowTeacherDashboard &&
                    this.renderTeacherDashboard()}
                  {!shouldShowParentDashboard &&
                    !shouldShowAdminDashboard &&
                    this.renderSections()}
                  {!shouldShowParentDashboard && !shouldShowAdminDashboard ? (
                    dashboardDataLoading ? (
                      this.loaderSection()
                    ) : (
                      this.state.dashboardData.length > 0 &&
                      this.renderDoghnutCharts()
                    )
                  ) : (
                    <></>
                  )}
                  {!!shouldShowParentDashboard && this.rednderParentDashboard()}
                  {!!shouldShowAdminDashboard && this.renderAdminDashboard()}
                </Col>
              </Row>
            </div>
          </Card>
        </ContentWrapper>
      </AdminTemplate>
    )
  }
}

function mapStateToProps(state) {
  return {
    all: state.assessmentReducer
  };
}

const mapActionsToProps = {
  getAssessmentSubject: assessmentActions.getAssessmentSubject
};

export default connect(mapStateToProps, mapActionsToProps)(ManageDashboard);
