import React, { useEffect, useState } from "react"
import "../dashboard.css"
import config from "../../../../../helper/config.js"
import { customerConstants } from "../../../../../helper/constants.js"
import MoneyIcon from "@mui/icons-material/Money"
import { Dropdown } from "react-bootstrap"
import ReactSelect from "react-select"
import DoughnutChart from "../../../charts/DoughnutChart.js"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}

const TeacherPayments = ({dashboardDataLoading,loaderSection,renderDoghnutCharts,suitableArray}) => {
    
  const [monthBasedTeachPayments, setMonthBasedTeachPayments] = useState([]);
  const [selectedTeachPayments, setSelectedTeachPayments] = useState([]);
  const [monthBasedTeachPaymentsOnTeacher, setMonthBasedTeachPaymentsOnTeacher] = useState([]);
  const [selectedTeachPaymentsOnTeacher, setSelectedTeachPaymentsOnTeacher] = useState([]);
  const [dougnutData, setDougnutData] = useState([]);
  useEffect( () => {
    fetchSuitableTeachPayments()
  },[suitableArray])
  useEffect( () => {
    fetchSuitableTeachPaymentsOnTeacher()
  },[selectedTeachPayments])
  useEffect( () => {
    const filteredArray = selectedTeachPaymentsOnTeacher?.filter(element => element.chart_x_value ==='Current Outstanding' || element.chart_x_value ==='Old Outstanding')
    setDougnutData(filteredArray)
  },[selectedTeachPaymentsOnTeacher])

  const fetchSuitableTeachPayments = () => {
    const suitableArray_ = suitableArray.find(item => item.chart_type === 'TeacherPay-info1' && item.chart_id === 1500)
    const groupedData =suitableArray_?.array.reduce((acc, obj) => {
      const key = `${obj.flg_chart_legend_x_filter}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    if(groupedData){
      const filteredGroupedData = Object.values(groupedData)
      setMonthBasedTeachPayments(filteredGroupedData)
      setSelectedTeachPayments(filteredGroupedData[0])
    }
  }

  const fetchSuitableTeachPaymentsOnTeacher = () => {
    const groupedData =selectedTeachPayments?.reduce((acc, obj) => {
      const key = `${obj.chart_legend_x_title}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    if(groupedData){
      const filteredGroupedData = Object.values(groupedData)
      setMonthBasedTeachPaymentsOnTeacher(filteredGroupedData)
      setSelectedTeachPaymentsOnTeacher(filteredGroupedData[0])
    }
  }
  return (
    <div className="col-md-12 mb-3 pl-0 px-0 px-sm-0">
      <div className="dashboard__container row d-flex">
        <div className="col-lg-12 col-md-12 px-0 d-flex justify-content-between align-items-center my-3">
          <h3 className="dashboard__container-title m-0">
            <MoneyIcon sx={{ fontSize: 22, color: "#5c5c5c" }} /> Teacher Payments
          </h3>
          <div className="">
            <Dropdown>
              <Dropdown.Toggle
                variant="primary"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  border: "1px solid #E4E6EF",
                  color: "var(--secondary-text-color)",
                  fontWeight: "400",
                  fontSize: "10px",
                }}
              >
              {selectedTeachPayments&&selectedTeachPayments[0]?.flg_chart_legend_x_filter}
              </Dropdown.Toggle>
                <Dropdown.Menu className="w-100">
                  {monthBasedTeachPayments && monthBasedTeachPayments.map(obj => (
                    <Dropdown.Item
                      onClick={() => {setSelectedTeachPayments(obj)}}
                    >
                      {obj[0].flg_chart_legend_x_filter}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-12 px-0">
          <div className="py-5" style={{ width: "100%" }}>
            <ReactSelect
              isClearable={true}
              placeholder="Select Teacher"
              //   menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              options={monthBasedTeachPaymentsOnTeacher?.map(item => ({ value: item, label: item[0]?.chart_legend_x_title}))}
              onChange={(selectedOption) => {
                setSelectedTeachPaymentsOnTeacher(selectedOption?.value)
                console.log('selectedOption :',selectedOption) 
              }}
            />
          </div>
          <div className="row d-flex justify-content-between receivables-row">
            <h5 className="receivables-title m-0">Teacher Fee</h5>
            <p className="receivables-amount">{selectedTeachPaymentsOnTeacher?.find(item => item.chart_x_value === 'Teacher Fee')?.chart_y_value}</p>
          </div>
          <div className="row d-flex justify-content-between receivables-row">
            <h5 className="receivables-title m-0">Total Outstanding</h5>
            <p className="receivables-amount">{selectedTeachPaymentsOnTeacher?.find(item => item.chart_x_value === 'Total Outstanding')?.chart_y_value}</p>
          </div>
          <div className="pl-8 row d-flex justify-content-between receivables-row">
            <h5 className="receivables-title m-0">Current Outstanding</h5>
            <p className="receivables-amount">{selectedTeachPaymentsOnTeacher?.find(item => item.chart_x_value === 'Current Outstanding')?.chart_y_value}</p>
          </div>
          <div className="pl-8 row d-flex justify-content-between receivables-row">
            <h5 className="receivables-title m-0">Old Outstanding</h5>
            <p className="receivables-amount">{selectedTeachPaymentsOnTeacher?.find(item => item.chart_x_value === 'Old Outstanding')?.chart_y_value}</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-12 d-flex justify-content-center align-items-center">
          {(dashboardDataLoading) ? loaderSection() : (dougnutData && <DoughnutChart val={dougnutData}/>)}
        </div>
      </div>
    </div>
  )
}

export default TeacherPayments
