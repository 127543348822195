import { libraryTypes } from '../types';

const initialState = {
    adminAssessments: [],
    managedAssessments: [],
    publicAssessments: [],
    myAssessments: [],
    totalMyAssessmentsCount: 0,
    assessoredAssessments: [],
    sharedAssessments: [],
    totalSharedAssessmentsCount: 0,
    totalPublicAssessmentsCount:0,
    userOptions: [],
    assessment: '',
    viewAssessmentDetails: [],
    loading: false,
    error: '',
    success: ''
}

export function assessmentLibraryReducer(state = initialState, action) {
    switch (action.type) {
        case libraryTypes.LIST_MANAGED_ASSESSMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.LIST_MANAGED_ASSESSMENT:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.LIST_MANAGED_ASSESSMENT_SUCCESS:
            return {
                ...state,
                managedAssessments: action.data.assessments,
                loading: false
            }
        case libraryTypes.LIST_MANAGED_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case libraryTypes.GET_ALL_MY_ASSESSMENTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.GET_ALL_MY_ASSESSMENTS:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.GET_ALL_MY_ASSESSMENTS_SUCCESS:
            return {
                ...state,
                myAssessments: action.data.assessments,
                totalMyAssessmentsCount: action.data.total_count,
                loading: false
            }
        case libraryTypes.GET_ALL_MY_ASSESSMENTS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case libraryTypes.LIST_ADMIN_ASSESSMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.LIST_ADMIN_ASSESSMENT:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.LIST_ADMIN_ASSESSMENT_SUCCESS:
            return {
                ...state,
                adminAssessments: action.data.assessments,
                loading: false
            }
        case libraryTypes.LIST_ADMIN_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case libraryTypes.LIST_ASSESSED_ASSESSMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.LIST_ASSESSED_ASSESSMENT:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.LIST_ASSESSED_ASSESSMENT_SUCCESS:
            return {
                ...state,
                assessoredAssessments: action.data.assessments,
                loading: false
            }
        case libraryTypes.LIST_ASSESSED_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case libraryTypes.GET_ALL_SHARED_ASSESSMENTS:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.GET_ALL_SHARED_ASSESSMENTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.GET_ALL_SHARED_ASSESSMENTS_SUCCESS:
            return {
                ...state,
                sharedAssessments: action.data.assessments,
                totalSharedAssessmentsCount: action.data.total_count,
                loading: false
            }
        case libraryTypes.GET_ALL_SHARED_ASSESSMENTS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case libraryTypes.GET_ALL_PUBLIC_ASSESSMENTS:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.GET_ALL_PUBLIC_ASSESSMENTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.GET_ALL_PUBLIC_ASSESSMENTS_SUCCESS:
            return {
                ...state,
                publicAssessments: action.data.assessments,
                totalPublicAssessmentsCount: action.data.total_count,
                loading: false
            }
        case libraryTypes.GET_ALL_PUBLIC_ASSESSMENTS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case libraryTypes.VIEW_ASSESSMENT:
            return {
                ...state,
                assessment: action.assessment,
                loading: false
            }
        case libraryTypes.VIEW_ASSESSMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.VIEW_ASSESSMENT_SUCCESS:
            return {
                ...state,
                assessment: action.details,
                loading: false,
                success: action.data
            }
        case libraryTypes.VIEW_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case libraryTypes.VIEW_ASSESSMENT_DATA:
            return {
                ...state,
                loading: false
            }
        case libraryTypes.VIEW_ASSESSMENT_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.VIEW_ASSESSMENT_DATA_SUCCESS:
            return {
                ...state,
                viewAssessmentDetails: action.data.view_assessment_data,
                loading: false,
                success: action.data
            }
        case libraryTypes.VIEW_ASSESSMENT_DATA_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case libraryTypes.VIEW_ASSESSMENT_REFRESH:
            return {
                ...state,
                assessment: '',
                loading: true
            }
        case libraryTypes.GET_USERS:
            return {
                ...state,
                userOptions: [],
                loading: true,
                success: '',
                error: '',
                text: 'Getting all the users without candites'
            }
        case libraryTypes.GET_USERS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case libraryTypes.GGET_USERS_SUCCESS:
            return {
                ...state,
                success: 'Obtained',
                loading: false
            }
        case libraryTypes.GET_USERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}