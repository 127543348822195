import {userTypes} from '../types';

const initialState = {
    allUsers: [],
    userMap: {},
    userRoleMap: {},
    loading: false,
    error: '',
    assessors: [],
    text: ''
}

export function userReducer(state = initialState, action){
    switch (action.type){
        case userTypes.CREATE_USER_MAP:
            return {
                ...state,
                userMap: action.user_data.map,
                users: action.user_data.users,
                loading: true
            }
        case userTypes.GET_ALL_USERS:
            return {
                ...state,
                allUsers: [],
                loading: true,
                text: 'Obtaining all the users'
            }
        case userTypes.GET_ALL_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Obtaining all the users'
            }
        case userTypes.GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                allUsers: action.users,
                loading: false,
                success: 'Obtained users successfully'
            }
        case userTypes.GET_ALL_USERS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case userTypes.CREATE_USER_ROLES_MAP:
            return {
                ...state,
                userRoleMap: action.map,
                loading: true
            }
        case userTypes.GET_USER_ROLES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case userTypes.GET_USER_ROLES_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case userTypes.GET_USER_ROLES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}
