import React from "react";

export default function Select ({ children, selectId, name, multiple, dataActionsBox, dataSize, dataLiveSearch, onChange, className, selectWrap,  disabled, spinnerId }) {

    return (
        <div className={selectWrap}>
            <div className="spinner spinner-track spinner-primary spinner-right" id={spinnerId}></div>
            <select className={className} id={selectId} name={name} onChange={onChange} multiple={multiple} data-actions-box={dataActionsBox} data-size={dataSize} data-live-search={dataLiveSearch} disabled={disabled}>
                {children}
            </select>
        </div>
    )
}