import {quesSourceTypes} from '../../../reducers/settings/settingsTypes'
import {questionSourceService} from '../../../service/settings/question/sourceService'


export const quesSourceActions = {
    getAllQuestionSources,
    createSource,
    updateSource,
    deleteSource
}

function getAllQuestionSources(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let sources = await questionSourceService.getAllQuestionSources(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(sources.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: quesSourceTypes.GET_ALL_QUESTION_SOURCES}
    }

    function request() {
        return {type: quesSourceTypes.GET_ALL_QUESTION_SOURCES_REQUEST}
    }

    function success(sources) {
        return {type: quesSourceTypes.GET_ALL_QUESTION_SOURCES_SUCCESS, sources}
    }

    function failure(error) {
        return {type: quesSourceTypes.GET_ALL_QUESTION_SOURCES_FAILURE, error}
    }
}


function createSource(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await questionSourceService.createSource(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: quesSourceTypes.CREATE_SOURCE_REQUEST, data}
    }

    function success(sources) {
        return {type: quesSourceTypes.CREATE_SOURCE_SUCCESS, sources}
    }

    function failure(error) {
        return {type: quesSourceTypes.CREATE_SOURCE_FAILURE, error}
    }
}


function deleteSource(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await questionSourceService.deleteSource(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(source) {
        return {type: quesSourceTypes.DELETE_SOURCE_REQUEST, source}
    }

    function success(success) {
        return {type: quesSourceTypes.DELETE_SOURCE_SUCCESS, success}
    }

    function failure(error) {
        return {type: quesSourceTypes.DELETE_SOURCE_FAILURE, error}
    }
}

function updateSource(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await questionSourceService.updateSource(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: quesSourceTypes.UPDATE_SOURCE_REQUEST}
    }

    function success(source) {
        return {type: quesSourceTypes.UPDATE_SOURCE_SUCCESS, source}
    }

    function failure(error) {
        return {type: quesSourceTypes.UPDATE_SOURCE_FAILURE, error}
    }
}
