import React from "react"

export default function ContentWrapper({ children, disabled }) {
  if (disabled) {
    return children
  } else {
    return (
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content" style={{ minHeight: '100vh' }}
      >
        {/* begin::Entry */}
        <div className="d-flex flex-column-fluid">
          {/* begin::Container */}
          <div className="container-fluid">{children}</div>
          {/* end::Container */}
        </div>
        {/* end::Entry */}
      </div>
    )
  }
}
