import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const StudentAddressService = {
  create,
  update,
  getStudentAddressById
};

async function create(addressList) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: addressList,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/student-addresses/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(addressList) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: addressList,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/student-addresses/update`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getStudentAddressById(id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/student-addresses/student/${id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
