import React from 'react';
import ContentWrapper from '../../components/common/AssessmentContentWrapper.js';
import AdminTemplate from '../../components/admin/layouts/Template.js';
import Card from '../../components/common/Card.js';
import CardHeader from '../../components/common/CardHeader.js';
import CardBody from '../../components/common/CardBody.js';
import CardTitle from '../../components/common/AssessmentCardTitle.js';
import CardToolbar from '../../components/common/QuestionCardToolBar.js';
import Modal from '../../components/common/modal/SelfGradeModal.js';
import ModalBody from '../../components/common/modal/ModalBody.js';
import ModalHeader from '../../components/common/modal/ModalHeader.js';
import FormGroup from '../../components/common/form/FormGroup.js';
import Button from '../../components/common/form/Button.js';
import Option from '../../components/common/form/Option.js';
import ImportFileUpload from '../../components/common/form/ImportFileUpload.js';
import TableSearchButton from '../../components/common/table/TableSearchButton.js';
import Select from '../../components/common/form/AssessmentSelect.js';
import Input from '../../components/common/form/Input.js';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import RadioInline from '../../components/common/form/RadioInline.js';
import Radio from '../../components/common/form/Radio.js';
import { Editor } from 'react-draft-wysiwyg';
import Tooltip from '@mui/material/Tooltip';
import ViewEditor from "../../components/common/editor/ViewEditor";
import { questionActions } from '../../actions/assessment/questionActions.js';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../components/common/MaterialCustomTablePagination.js";
import QuestionAccordion from '../../components/common/form/QuestionAccordion.js';
import TabList from '../../components/common/tabs/TabList.js';
import NavItem from '../../components/common/tabs/NavItem.js';
import TabContent from '../../components/common/tabs/TabContent.js';
import TabPane from '../../components/common/tabs/TabPane.js';
import { Redirect } from 'react-router-dom';
import CreateButton from '../../components/common/CreateButton.js';
import { libraryActions } from "../../actions/question/libraryActions";
import { createActions } from "../../actions/question/createActions";
import { shareActions } from "../../actions/question/shareActions";
import { connect } from "react-redux";
import { questionTypeConstants, questionDifficultyConstants, exportImportFormatConstants, questionMCQAnswerTypeConstants } from "../../helper/constants";
import { convertFromRaw, EditorState } from "draft-js";
import CheckBox from '../../components/common/form/CheckBox.js';
import CheckBoxInline from '../../components/common/form/CheckBoxInline.js';
import Checkbox from '@mui/material/Checkbox';
import { AzureStorage } from "../../helper/azureStorage";
import DataHandler from "../../handlers/DataHandler";
import debounce from "lodash.debounce";
import { namespace } from "../../helper/namespace"
import { MCQExport, MCQImport, DescriptiveImport, DescExport } from "../../service/question/exportImportService";
import Spinner from "../../components/common/Spinner"
import { history } from '../../helper/history.js';
import { routes } from '../../helper/routes.js';
import './QuestionsLibrary.css';
import { QUESTION_FILTERS } from '../../helper/constants.js';
import { staffUserActions } from '../../actions/user_management/staffUserActions.js';
import { manageUserActions } from '../../actions/user_management/manageUserActions.js';
import Form from "react-bootstrap/Form";
import ReactSpinner from "react-bootstrap/Spinner";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from '../../helper/config.js';
import { customerConstants } from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
    return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
    case customerConstants.EXO_CUSTOMER1:
        closeBtnColor = "var(--maincolor)"
        addBtnColor = "var(--maincolor)"
        cancelClass = "btn-default"
        confirmClass = "btn-primary"
        break
    case customerConstants.EXO_CUSTOMER2:
        closeBtnColor = "var(--maincolor-cmc)"
        addBtnColor = "var(--maincolor-cmc)"
        break
    default:
        closeBtnColor = "var(--maincolor)"
        addBtnColor = "var(--maincolor)"
        cancelClass = "btn-default"
        confirmClass = "btn-primary"
        break
}

class QuestionsLibrary extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            questionCategoryMap: [],
            questionTypesMap: [],
            sharequestionData: [],
            selectedUserShare: '',
            selectedUserUnshare: '',
            shareUser: [],
            user: [],
            edit: false,
            editorQuestionState: EditorState.createEmpty(),
            editorAnswerStateDescriptive: EditorState.createEmpty(),
            editorAnswerStates: [],
            selectedChoices: [],
            attachment: '',
            exportImportLoading: false,
            exportImportFormat: null,
            export: false,
            import: false,
            importFile: null,
            selectedQuestionType: null,
            selectedQuestionCategories: [],
            subQuestionContent: [],
            subQuestionAnswers: [],
            questionsByIds: [],
            selectedQuestions: [],
            filters: [],
            selectedFilters: [],
            selectedGrades: null,
            selectedChapters: null,
            selectedsubjects: null,
            selectedAuthors: null,
            selectedYears: null,
            selectedCategories: null,
            activeTab: 1,
            selectedTerms: null,
            selectedSources: null,
            selectedTypes: null,
            selectedDifficulties: null,
            selectedLanguages: null,
            selectedTeachers: null,
            selectAllMCQ: false,
            categoryOptionsImport: [],
            categoryOptionsExport: [],
            categoryOptionsFilter: [],
            selectedCategory: [],
            selectedQuestionDifficulty: null,
            newCategory: null,
            deleteCategory: false,
            category: '',
            categoryId: '',
            editCategory: false,
            selectCategory: '',
            questionShared: false,
            myQuestions: [],
            publicQuestions: [],
            filterAdded: false,
            filterValue: 0,
            limit: 10,
            filterValues: [],
            offset: 0,
            category_offset: 0,
            search_term: "",
            category_search_term: "",
            currentPage: 0,
            currentCategoryPage: 0,
            isTableDataLoading: false,
            sort_column: "created_at",
            category_sort_column: "category_id",
            sort_direction: "DESC",
            category_sort_direction: "ASC",
            rowsPerPage: 10,
            categoryRowsPerPage: 5,
            category_id: "",
            navItemShare: "nav-link",
            navItemAll: "nav-link-active",
            count: 10,
            isDeleteLoading: false,
            states: '',
            isViewSubQuestionLoading: false
        }

        this.onViewQuestion = this.onViewQuestion.bind(this);
        this.onRemoveQuestion = this.onRemoveQuestion.bind(this);
        this.onChangeShare = this.onChangeShare.bind(this);
        this.onChangeUnshare = this.onChangeUnshare.bind(this);
        this.handleShareModal = this.handleShareModal.bind(this);
        this.onDownloadAttachment = this.onDownloadAttachment.bind(this);
        this.onChangeExportFormat = this.onChangeExportFormat.bind(this);
        this.onChangeImportQuestionType = this.onChangeImportQuestionType.bind(this);
        this.onChangeImportQuestionCategory = this.onChangeImportQuestionCategory.bind(this);
        this.getSubmissionContent = this.getSubmissionContent.bind(this);
        this.onImportQuestions = this.onImportQuestions.bind(this);
        this.onClickCloseImport = this.onClickCloseImport.bind(this);
        this.onChangeExportCategorySelection = this.onChangeExportCategorySelection.bind(this);
        this.onChangeExportQuestionType = this.onChangeExportQuestionType.bind(this);
        this.onChangeExportSelection = this.onChangeExportSelection.bind(this);
        this.onExportQuestion = this.onExportQuestion.bind(this);
        this.onClickCloseExport = this.onClickCloseExport.bind(this);
        this.onChangeImportQuestionDifficulty = this.onChangeImportQuestionDifficulty.bind(this);
        this.deletSubmissionContentImport = this.deletSubmissionContentImport.bind(this);
        this.shareQuestion = this.shareQuestion.bind(this);
        this.unShareQuestion = this.unShareQuestion.bind(this);
        this.onClickEdit = this.onClickEdit.bind(this);
        this.onDeleteQuestion = this.onDeleteQuestion.bind(this);
        this.debouncedHandleSearch = debounce(this.fetchAllMyQuestions, 2000);
        this.debouncedPublicSearch = debounce(this.fetchAllPublicQuestions, 2000);
    }

    async componentDidMount() {

        if (window.KTSelect2) window.KTSelect2.init();
        if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
        if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
        if (window.KTBootstrapSelect) window.KTBootstrapSelect.init();
        if (window.$) window.$('.selectpicker').selectpicker();

        await this.fetchAllMyQuestions();
        //await this.fetchAllSharedQuestions();
        await this.fetchAllPublicQuestions();

        this.setState({ limit: this.props.totalCount });
        this.setState({ count: this.props.totalCount });

        this.setState({ isTableDataLoading: true })
        await this.props.getQuestionMetaData()
        this.setState({ isTableDataLoading: false })

        let questionCatgories = this.props.allQuestionMetaData.find(item => 'categories' in item).categories;
        if (questionCatgories.length > 0) {
            let tempImportCategories = [];
            let tempExportCategories = [];
            let tempFilterCategories = [];
            let categoryOptionsImport = [];
            let categoryOptionsExport = [];
            let categoryOptionsFilter = [];
            categoryOptionsFilter.push(
                <Option key={0} optionName={"Nothing Selected"} value={0} selected="selected" />
            );
            questionCatgories.map((el) => {
                tempImportCategories[el.category_id] = el.name
                categoryOptionsImport.push(<Option key={el.category_id} optionName={el.name} value={el.category_id} />)
            });

            this.setState({
                myQuestions: this.props.myQuestions
            })

            questionCatgories.map((el) => {
                tempExportCategories[el.category_id] = el.name
                categoryOptionsExport.push(<Option key={el.category_id} optionName={el.name} value={el.category_id} />)
            });

            questionCatgories.map((el) => {
                tempFilterCategories[el.category_id] = el.name
                categoryOptionsFilter.push(<Option key={el.category_id} optionName={el.name} value={el.category_id} />)
            });

            this.setState({
                questionCategoryMap: tempImportCategories,
                categoryOptionsImport: categoryOptionsImport,
                categoryOptionsExport: categoryOptionsExport,
                categoryOptionsFilter: categoryOptionsFilter,
                selectedQuestionCategories: questionCatgories[0].category_id.toString()
            });

            if (categoryOptionsImport) {
                window.$('#question-import-select').selectpicker('refresh');
            }

            if (categoryOptionsFilter) {
                window.$('#question-filter-select').selectpicker('refresh');
            }
        }

        let questionTypes = this.props.allQuestionMetaData.find(item => 'types' in item).types;
        if (questionTypes.length > 0) {
            let tempQTypes = [];
            questionTypes.map((el) => {
                tempQTypes[el.type_id] = el.qtype
            });
            this.setState({
                questionTypesMap: tempQTypes
            })
        }

        this.setState({ sharequestionData: this.props.sharedQuestions })

        if (!this.props.allStaffUsers.length) {
            await this.props.getStaffUsers()
        }
        let self = this;
        window.$('#kt_select2_11').on('select2:select', function (e) {
            var data = e.params.data;
            self.onChangeExportCategorySelection(data.id)
        });

        window.$('#kt_select2_11').on('select2:unselect', function (e) {
            var data = e.params.data;
            self.onChangeExportCategorySelection(data.id)
        });

        this.onDatatableActionEvent()

    }

    componentDidUpdate() {
        if (this.props.sharedQuestions.length > 0 && !this.props.question) {
            this.props.getQuestionContent(this.props.sharedQuestions[0])
        }

    }

    onDatatableActionEvent() {
        window.$(document).on('click', '.view-question-btn', (e) => {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.onViewQuestion(this.props.myQuestions[idx])
            window.$('#view-details').modal('show')
        })

        window.$(document).on('click', '.edit-question-btn', (e) => {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.handleEditModel(this.props.myQuestions[idx])
            // window.$('#edit-details').modal('show')
        })

        window.$(document).on('click', '.public-edit-question-btn', (e) => {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.handleEditModel(this.props.publicQuestions[idx])
            // window.$('#edit-details').modal('show')
        })

        window.$(document).on('click', '.share-question-btn', (e) => {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.handleShareModal(this.props.myQuestions[idx])
            window.$('#manage-sharing').modal('show')
        })

        window.$(document).on('click', '.delete-question-btn', (e) => {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.onDeleteQuestion(this.props.myQuestions[idx])
        })

        window.$(document).on('click', '.share-view-question-btn', (e) => {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.onViewQuestion(this.state.sharequestionData[idx])
            window.$('#view-details').modal('show')
        })

        window.$(document).on('click', '.public-view-question-btn', (e) => {
            const _target = e.target
            const idx = _target.getAttribute('dataindex')
            this.onViewQuestion(this.state.publicQuestions[idx])
            window.$('#view-details').modal('show')
        })
    }

    fetchAllMyQuestions = async () => {
        const {
            limit,
            offset,
            sort_column,
            sort_direction,
            search_term,
            category_id,
            selectedCategories,
            selectedAuthors,
            selectedChapters,
            selectedGrades,
            selectedDifficulties,
            selectedLanguages,
            selectedTypes,
            selectedTerms,
            selectedYears,
            selectedsubjects,
            selectedSources,
        } = this.state;
        this.setState({ isTableDataLoading: true });
        await this.props.getAllMyQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectedCategories, selectedAuthors, selectedChapters,
            selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources);
        this.setState({ myQuestions: this.props.myQuestions });
        this.setState({ limit: this.state.rowsPerPage });
        this.setState({ isTableDataLoading: false });
    };

    fetchAllPublicQuestions = async () => {
        const {
            limit,
            offset,
            sort_column,
            sort_direction,
            search_term,
            category_id,
            selectedCategories,
            selectedAuthors,
            selectedChapters,
            selectedGrades,
            selectedDifficulties,
            selectedLanguages,
            selectedTypes,
            selectedTerms,
            selectedYears,
            selectedsubjects,
            selectedSources,
            selectedTeachers
        } = this.state;
        this.setState({ isTableDataLoading: true });
        await this.props.getAllPublicQuestions(limit, offset, sort_column, sort_direction, search_term, category_id, selectedCategories, selectedAuthors, selectedChapters,
            selectedGrades, selectedDifficulties, selectedLanguages, selectedTypes, selectedTerms, selectedYears, selectedsubjects, selectedSources, selectedTeachers);
        this.setState({ publicQuestions: this.props.publicQuestions });
        this.setState({ limit: this.state.rowsPerPage });
        this.setState({ isTableDataLoading: false });
    };

    // fetchAllSharedQuestions = async () => {
    //     const {
    //         limit,
    //         offset,
    //         sort_column,
    //         sort_direction,
    //         search_term,
    //         category_id,
    //         selectedCategories,
    //         selectedAuthors,
    //         selectedChapters,
    //         selectedGrades,
    //         selectedDifficulties,
    //         selectedLanguages,
    //         selectedTypes,
    //         selectedTerms,
    //         selectedYears,
    //         selectedsubjects,
    //         selectedSources,
    //     } = this.state;
    //     this.setState({ isTableDataLoading: true });
    //     await this.props.getAllSharedQuestions(
    //         limit,
    //         offset,
    //         sort_column,
    //         sort_direction,
    //         search_term,
    //         category_id,
    //         selectedCategories,
    //         selectedAuthors,
    //         selectedChapters,
    //         selectedGrades,
    //         selectedDifficulties,
    //         selectedLanguages,
    //         selectedTypes,
    //         selectedTerms,
    //         selectedYears,
    //         selectedsubjects,
    //         selectedSources,
    //     );
    //     this.setState({ limit: this.state.rowsPerPage });
    //     this.setState({ sharequestionData: this.props.sharedQuestions });
    //     this.setState({ isTableDataLoading: false });
    // };

    handlePageChange = (event, newPage) => {
        const { rowsPerPage } = this.state;
        const newOffset = newPage * rowsPerPage;
        this.setState(
            {
                limit: rowsPerPage,
                offset: newOffset,
                isTableDataLoading: true,
                currentPage: newPage,
            },
            async () => {
                await this.fetchAllMyQuestions();
                //await this.fetchAllSharedQuestions();
                await this.fetchAllPublicQuestions();
                this.setState({ count: this.props.totalCount });
            }
        );
    };

    //handle the data fetching as per the data per page limit gets changes
    handleRowsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        this.setState({ rowsPerPage: newLimit });
        const newPage = Math.floor(this.state.offset / newLimit);
        this.setState(
            {
                limit: newLimit,
                offset: newLimit * newPage,
                isTableDataLoading: true,
                currentPage: newPage,
            },
            async () => {
                await this.fetchAllMyQuestions();
                //await this.fetchAllSharedQuestions();
                await this.fetchAllPublicQuestions();
                this.setState({ count: this.props.totalCount });
            }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {rowsPerPage} = this.state;
        this.setState(
        {
            limit: rowsPerPage,
            offset: newOffset,
            currentPage: newPage
            },
            async () => {
                await this.fetchAllMyQuestions();
                //await this.fetchAllSharedQuestions();
                await this.fetchAllPublicQuestions();
                this.setState({ count: this.props.totalCount });
            }
        );
    };

    handleSearch = (event) => {
        const search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

        if (search_term !== "") {
            this.setState({ limit: this.props.totalCount });
        }

        if (search_term === "" || regex.test(search_term)) {
            this.setState(
                {
                    search_term,
                    isTableDataLoading: true,
                    offset: 0,
                    currentPage: 0,
                    searchError: false,
                    limit: 10,
                },
                async () => {
                    // Call the debounced version of fetchStudentData
                    this.debouncedHandleSearch();
                    this.debouncedPublicSearch();
                    this.setState({ count: this.props.totalCount });
                }
            );
        } else {
            this.setState({ search_term, searchError: true });
        }
    };

    handleCategorySearch = (event) => {
        const category_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

        if (category_search_term !== "") {
            this.setState({ limit: this.props.totalCount });
        }

        if (category_search_term === "" || regex.test(category_search_term)) {
            this.setState(
                {
                    category_search_term,
                    isTableDataLoading: true,
                    category_offset: 0,
                    currentCategoryPage: 0,
                    searchError: false,
                },
                () => {
                    // Call the debounced version of fetchStudentData
                    // this.debouncedHandleSearch();
                    this.fetchAllQuestionCategories();
                }
            );
        } else {
            this.setState({ category_search_term, searchError: true });
        }
    };

    handleSortChange = (sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { sort_column: currentSortColumn, sort_direction } = this.state;
        const newSortDirection =
            currentSortColumn === sort_column && sort_direction === "ASC"
                ? "DESC"
                : "ASC";

        this.setState(
            {
                sort_column,
                sort_direction: newSortDirection,
                offset: 0, // Reset offset when changing sorting
                currentPage: 0,
                isTableDataLoading: true,
            },
            async () => {
                await this.fetchAllMyQuestions();
                //await this.fetchAllSharedQuestions();
                await this.fetchAllPublicQuestions();
                this.setState({ count: this.props.totalCount });
            }
        );
    };

    handleSortCategoryChange = (category_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { category_sort_column: currentCategorySortColumn, category_sort_direction } = this.state;
        const newCategorySortDirection =
            currentCategorySortColumn === category_sort_column && category_sort_direction === "ASC"
                ? "DESC"
                : "ASC";

        this.setState(
            {
                category_sort_column,
                category_sort_direction: newCategorySortDirection,
                category_offset: 0,
                currentCategoryPage: 0,
                isTableDataLoading: true,
            },
            () => {
                this.fetchAllQuestionCategories();
            }
        );
    }

    onDeleteQuestion(element) {
        window.Swal.fire({
            title: 'Do you want to remove the question?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.setState({ isDeleteLoading: true });
                await this.props.getSetQuestion(element.question_id);
                if (element.share) {
                    window.Swal.fire({
                        title: namespace.FAILED,
                        text: namespace.CANT_REMOVE_SHARED_QUESTION,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    });
                    this.setState({ isDeleteLoading: false });
                } else if (this.props.assessmentSetQuestions.length > 0) {
                    window.Swal.fire({
                        title: namespace.FAILED,
                        text: namespace.CANT_REMOVE_SET_QUESTION,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    });
                    this.setState({ isDeleteLoading: false });
                }
                else {
                    const user_id = DataHandler.getFromSession("user_id")
                    const containerName = "".concat(user_id, "-a-", element.answer)

                    if (element.qtype === questionTypeConstants.SUBMISSION.id) {
                        await AzureStorage.removeBlobContent(containerName, element.answer)
                    }
                    await this.props.deleteQuestion(element);
                    this.setState({ isDeleteLoading: false });
                    window.removeEventListener("beforeunload", this.onUnload);
                    window.Swal.fire({
                        title: 'Success!',
                        text: namespace.QUESTION_DELETED_SUCCESSFULL,
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    }).then(function async() {
                        history.push(routes.questions_library)
                        // window.location.reload()
                    });
                    //    this.setState({ delete: true }); 
                }
            }
        })
    }

    async onViewQuestion(element, e) {
        this.setState({
            editorQuestionState: '',
            editorAnswerStates: '',
            editorAnswerStateDescriptive: '',
            questionsByIds: [],
            subQuestionAnswers: [],
            subQuestionContent: []
        })
        let tempAnswerStates = [];
        let tempAnswer = [];

        await this.props.getQuestionContent(element);

        if (this.props.questionContent) {
            let states = await JSON.parse(this.props?.questionContent);
            this.setState({ states: states });
            let tempQuestionState = EditorState.createWithContent(convertFromRaw(states[0]));
            this.setState({ editorQuestionState: tempQuestionState })
        }

        if (element?.qtype === questionTypeConstants.DESCRIPTIVE.id) {
            await this.props.getAnswerContent(element);
            let states = await JSON.parse(this.props?.answerContent);
            let Ans = EditorState.createWithContent(convertFromRaw(states[0]))
            this.setState({ editorAnswerStateDescriptive: Ans })

        } else if (element?.qtype === questionTypeConstants.SUBQUESTION.id && element?.is_subquestion === null) {
            this.setState({ isViewSubQuestionLoading: true });
            let questions = this.state.questionsByIds
            let sub = element.sub_questions?.split(",")
            for (let i = 0; i <= sub?.length - 1; i++) {
                await this.props.getQuestionById(parseInt(sub[i]))
                let id = [...this.props.questionById]
                questions.push(id[0])
            }
            let uniqueMap = new Map();
            questions.forEach(obj => uniqueMap.set(obj.question_id, obj));
            let uniqueArray = Array.from(uniqueMap.values());
            this.setState({
                questionsByIds: uniqueArray
            })
            let tempQuestionState = []
            let Ans = []
            for (let j = 0; j <= uniqueArray.length - 1; j++) {
                await this.props.getQuestionContent(uniqueArray[j]);
                await this.props.getAnswerContent(uniqueArray[j]);
                let states = await JSON.parse(this.props?.questionContent);
                tempQuestionState.push(EditorState.createWithContent(convertFromRaw(states[0])))
                let answers = await JSON.parse(this.props?.answerContent);
                Ans.push(EditorState.createWithContent(convertFromRaw(answers[0])))
            }
            this.setState({ subQuestionAnswers: Ans, subQuestionContent: tempQuestionState })
            this.setState({ isViewSubQuestionLoading: false });

        } else if (element?.qtype === questionTypeConstants.SUBQUESTION.id && element?.is_subquestion === "true") {
            this.setState({ isViewSubQuestionLoading: true });
            await this.props.getAnswerContent(element);
            let states = await JSON.parse(this.props?.answerContent);
            let Ans = EditorState.createWithContent(convertFromRaw(states[0]))
            this.setState({ editorAnswerStateDescriptive: Ans })
            this.setState({ isViewSubQuestionLoading: false });
        }
        else if (element?.qtype === questionTypeConstants.MCQ.id) {
            if(this.state.states){
                this.state.states.shift();
                this.state.states.forEach(state => {
                    tempAnswerStates.push(EditorState.createWithContent(convertFromRaw(state)))
                });
            }
            if(this.props.question.answer){
                this.props.question.answer.split(',').map((answer) => {
                    tempAnswer.push(parseInt(answer) > 0);
                });
                this.setState({
                    editorAnswerStates: tempAnswerStates,
                    selectedChoices: tempAnswer
                })
            }
        } else if (element?.qtype === questionTypeConstants.SUBMISSION.id) {
            await this.props.getAnswerContent(element);
            let attachmentName = this.props.answerContent?.replace(/^\[(.+)\]$/, "$1").replace(/"\"$/, "$1").split(',');
            this.setState({
                attachment: attachmentName
            });
        }
    }

    async onDownloadAttachment(e, attach) {
        e.preventDefault()
        let fileReader;
        async function handleFileRead() {
            const content = fileReader.result;

        }
        fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        let containerName = config.storageBlobConfigs.containerName;
        //let content = await AzureStorage.downloadBlob("".concat(this.props.question.created_by, "-sa-", this.props.question.answer), attach);
        let content = await AzureStorage.downloadBlob(containerName, attach);
        fileReader.readAsArrayBuffer(content);
    }

    onRemoveQuestion = (element) => {
        const question_id = element.question_id
        const user_id = DataHandler.getFromSession("user_id")
        this.props.unshareFromMe({ user_id, question_id })
        //this.props.getSharedQuestions()
        //this.fetchAllSharedQuestions();
    }

    getQuestionTypeName(qType, is_subquestion) {
        if (qType === questionTypeConstants.MCQ.id) {
            return questionTypeConstants.MCQ.name;
        } else if (qType === questionTypeConstants.DESCRIPTIVE.id) {
            return questionTypeConstants.DESCRIPTIVE.name
        } else if (qType === questionTypeConstants.SUBMISSION.id) {
            return questionTypeConstants.SUBMISSION.name;
        } else if (qType === questionTypeConstants.SUBQUESTION.id && is_subquestion === "true") {
            return questionTypeConstants.SUBQUESTION.name;
        } else if (qType === questionTypeConstants.SUBQUESTION.id && is_subquestion === null) {
            return questionTypeConstants.SUBQUESTION.name + " (Main-Question)";
        }
    }

    getQuestionDifficulty(difficulty_id) {
        if (difficulty_id !== null) {
            let questionDifficulties = this.props.allQuestionMetaData.find(item => 'difficulty_types' in item)?.difficulty_types;
            const difficulty = questionDifficulties?.find(
                (difficulty) => difficulty.difficulty_id == difficulty_id
            );
            return difficulty?.difficulty_type;
        }
    }

    getQuestionCategory(category_id) {
        if (category_id !== null) {
            let questionCategories = this.props.allQuestionMetaData.find(item => 'categories' in item)?.categories;
            const category = questionCategories?.find(
                (category) => category.category_id == category_id
            );
            return category?.name;
        }
    }

    getColor(type) {
        switch (type) {
            case questionDifficultyConstants.EASY.name:
                return 'label label-lg label-light-success label-inline';
            case questionDifficultyConstants.MEDIUM.name:
                return 'label label-lg label-light-warning label-inline';
            case questionDifficultyConstants.HARD.name:
                return 'label label-lg label-light-danger label-inline';
            default:
                return 'label label-lg label-light-default label-inline';
        }
    }

    getShare(share) {
        if (share > 0) {
            return <span className="label label-lg label-light-primary label-inline">Shared</span>
        } else {
            return ""
        }
    }

    getDate(date) {
        let createdTime = new Date(date).getTime();
        let currentTime = new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000;

        let difference = currentTime - createdTime;

        let daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24

        let hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60

        let minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60

        if (daysDifference == 0 && hoursDifference == 0 && minutesDifference == 0) {
            return 'Created Just now';
        }
        else {
            return 'Created ' + daysDifference + 'd ' + hoursDifference + 'h ' + minutesDifference + 'm ' + 'ago';
        }
    }

    async handleEditModel(editDetails) {
        await this.props.getQuestionContent(editDetails)
        await this.props.getQuestionGuidanceContent(editDetails)
        if (editDetails.qtype === questionTypeConstants.DESCRIPTIVE.id || editDetails.qtype === questionTypeConstants.SUBMISSION.id || (editDetails.qtype === questionTypeConstants.SUBQUESTION.id && editDetails.is_subquestion === "true")) {
            await this.props.getAnswerContent(editDetails)
        }
        this.setState({ edit: true })
    }

    async handleShareModal(element) {
        this.setState({ user: [], shareUser: [] })
        await this.props.loadSharePage(element)
        this.setState({ share: true })
        let user_id = element.created_by
        let question_id = element.question_id
        let share_id = element.share_id
        await this.props.getAlreadySharedUsers({ user_id, question_id })


        if (this.props.sharedUsers) {
            window.$('#show').selectpicker('refresh');
            window.$('#showshare').selectpicker('refresh');

            let temp = this.props.sharedUsers;
            let userDrpDwn = [];
            let sharedUserDrpDwn = [];
            let staffuser, sharedUser, shared;
            const myId = DataHandler.getFromSession("user_id");
            let assessor = []

            this.props.allStaffUsers.map(staffuser => {
                if (staffuser.role_code === "ASSR") {
                    assessor.push(staffuser)
                }
            })
            if (assessor.length) {
                for (staffuser of assessor) {
                    shared = false;
                    for (sharedUser of temp) {
                        if (staffuser.user_id === parseInt(sharedUser.shared_with)) {
                            await this.props.getTeacherDetails(staffuser.staff_id);
                            sharedUserDrpDwn.push(
                                <Option
                                    optionName={this.props.teacherDetails[0]?.first_name.concat(" ", this.props.teacherDetails[0]?.last_name)}
                                    value={sharedUser.share_id} />
                            );

                            shared = true;
                            break;
                        }

                    }
                    if (!shared) {
                        if (staffuser.user_id !== parseInt(myId)) {
                            await this.props.getTeacherDetails(staffuser.staff_id);
                            userDrpDwn.push(<Option
                                optionName={this.props.teacherDetails[0]?.first_name.concat(" ", this.props.teacherDetails[0]?.last_name)}
                                value={staffuser.user_id} />);
                        }

                    }

                }
                this.setState({
                    shareUser: sharedUserDrpDwn,
                    user: userDrpDwn
                })

                let newunsharevalue
                let newvalue
                if (this.state.shareUser.length > 0) {
                    newunsharevalue = this.props.sharedUsers[0].share_id
                    window.$('#showshare').selectpicker('refresh');
                }
                if (this.state.user.length > 0) {
                    newvalue = this.state.user[0].props.value
                    window.$('#show').selectpicker('refresh');
                }
                this.setState({ selectedUserUnshare: newunsharevalue, selectedUserShare: newvalue })


            }
        } else {
            window.$('#show').selectpicker('refresh');
            window.$('#showshare').selectpicker('refresh');
            let userDrpDwn = [];
            const myId = DataHandler.getFromSession("user_id");
            await Promise.all(
                await this.props.allStaffUsers.map(staffuser => {
                    if (staffuser.role_code === "ASSR") {
                        if (staffuser.user_id !== myId) {
                            this.props.getTeacherDetails(staffuser.staff_id);
                            userDrpDwn.push(<Option
                                optionName={this.props.teacherDetails[0].first_name.concat(" ", this.props.teacherDetails[0].last_name, " - ")}
                                value={staffuser.user_id} />);
                        }
                    }
                })
            );

            this.setState({
                user: userDrpDwn
            })

            let newvalue
            if (this.state.user.length > 0) {
                newvalue = this.state.user[0].props.value;
            }
            this.setState({ selectedUserShare: newvalue })
            window.$('#showshare').selectpicker('refresh');
            window.$('#show').selectpicker('refresh');
        }

    }


    async shareQuestion(e) {
        if (this.state.user) {
            const shared_by = DataHandler.getFromSession("user_id")

            const question_id = this.props.questionShare.question_id
            const shared_with = this.state.selectedUserShare.toString();
            window.Swal.showLoading()
            await this.props.shareQuestion({ question_id, shared_with, shared_by })
            if (!this.props.shareLoading) {
                if (this.props.shareError) {
                    window.Swal.fire({
                        title: 'Unable to share the Question!',
                        text: 'Failed!',
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    });
                } else {
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Question Shared Successfully',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    }).then((result) => {
                        if (result) {
                            history.push(routes.questions_library);
                            window.location.reload();
                        }
                    });
                }
            }
        } else {
            let errors = {};
            errors.selectedUserShare = "Can't be empty";
            this.setState({ errors })
        }
    }

    onChangeShare(e) {
        let newvalue;
        if (this.state.user.length === 1) {
            newvalue = this.props.sharedUsers[0].shared_with
        } else {
            newvalue = e.target.value
        }
        this.setState({ selectedUserShare: newvalue })
    }

    onHandleAccordionClick(value) {
        if (this.state.selectedQuestionType === value) {
            this.setState({
                selectedQuestionType: ''
            })
        } else {
            this.setState({
                selectedQuestionType: value
            })
        }
    }

    onSelectAllMCQToggle() {
        this.setState({
            selectAllMCQ: !this.state.selectAllMCQ,
        })
        if (!this.state.selectAllMCQ) {
            this.setState({
                selectedQuestions: this.props.myQuestions
            })
        } else {
            this.setState({
                selectedQuestions: []
            })
        }
    }

    onChangeExportQuestionType(e) {
        let qtype = e.target.value
        this.setState({
            selectedQuestionType: qtype
        })
    }

    onChangeExportCategorySelection(data) {
        let selectedCategory = this.state.selectedCategory
        let index = selectedCategory.indexOf(data);
        if (selectedCategory.includes(data)) {
            selectedCategory.splice(index, 1)
        } else {
            selectedCategory.push(data)
        }

        this.setState({ selectedCategory: selectedCategory })
        if (selectedCategory.length > 0) {
            let setqus = []
            this.props.myQuestions.map((el, i) => {
                if (selectedCategory.includes(el.category_id.toString())) {
                    setqus.push(el)
                }
            });
            this.props.sharedQuestions.map((el, i) => {
                if (selectedCategory.includes(el.category_id.toString())) {
                    setqus.push(el)
                }
            });
            this.setState({
                selectedQuestions: setqus
            })
        }

    }

    onChangeExportSelection(e, qus, i) {
        let question = [...this.state.selectedQuestions]
        let index = question.indexOf(qus);
        if (question.includes(qus)) {
            question.splice(index, 1)
        } else {
            question.push(qus)
        }
        this.setState({ selectedQuestions: question })
    }

    onChangeExportFormat(e) {
        let exportformat = e.target.value;
        this.setState({
            exportImportFormat: exportformat
        });
    }

    async onExportQuestion() {
        if (this.state.selectedQuestions.length !== 0 && this.state.exportImportFormat != null) {
            this.setState({
                exportImportLoading: true
            })
            let questionsContents = [];
            let answerContents = [];
            let qus = [];
            let descqus = []
            this.state.selectedQuestions.map(el => {
                if (el.qtype == questionTypeConstants.MCQ.id) {
                    qus.push(el);
                } else if (el.qtype === questionTypeConstants.DESCRIPTIVE.id) {
                    descqus.push(el)
                }
            })
            if (this.state.selectedQuestionType === questionTypeConstants.MCQ.name) {
                for (let selectedQuestion of qus) {
                    await this.props.clearQuestionContent();
                    await this.props.getQuestionContent(selectedQuestion);
                    await questionsContents.push(this.props.questionContent);
                }
                await MCQExport(questionsContents, qus, this.state.exportImportFormat);
            } else if (this.state.selectedQuestionType === questionTypeConstants.DESCRIPTIVE.name) {
                for (let selectedQuestion of descqus) {
                    await this.props.clearQuestionContent();
                    await this.props.getQuestionContent(selectedQuestion);
                    await this.props.getAnswerContent(selectedQuestion);
                    await questionsContents.push(this.props.questionContent);
                    await answerContents.push(this.props.answerContent)
                }
                await DescExport(questionsContents, answerContents, descqus, this.state.exportImportFormat);
                // window.Swal.fire({
                //         title: 'Question/s Exported Successfully!',
                //         text: 'Success!',
                //         icon: 'success',
                //         customClass: {
                //     confirmButton: `btn font-weight-bold ${confirmClass}`,
                //   },
                //   onOpen: (modalElement) => {
                //       if (customer === customerConstants.EXO_CUSTOMER2) {
                //           modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                //       }
                //   }
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         history.push(routes.questions_library);
                //         window.location.reload();
                //     }
                // })
            }
            this.setState({
                exportImportLoading: false,
                selectedQuestion: [],
                exportImportFormat: null,
                selectedQuestionType: null
            })
            window.$('#kt_select2_11').val(null).trigger('change');
            window.location.reload();
        }
    }

    onClickEdit(e) {
        e.preventDefault()
        history.push(routes.edit_question)
        window.scrollTo(0, 0);
    }

    onClickCloseExport(e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to discard and close?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Close it!'
        }).then((result) => {
            if (result.isConfirmed) {
                window.$('#export-questions').modal('hide');
                this.setState({
                    selectCategory: null,
                    selectedQuestion: [],
                    exportImportFormat: null,
                    selectedQuestionType: null
                })
                window.$('#kt_select2_11').val(null).trigger('change');
                window.location.reload();
            }
        })
    }

    onChangeImportQuestionCategory(e) {
        let type = e.target.value;
        this.setState({
            selectedQuestionCategories: type
        })
    }

    onChangeImportQuestionType(e) {
        let qtype = e.target.value
        this.setState({
            selectedQuestionType: qtype
        })
    }

    onChangeImportQuestionDifficulty(e) {
        let difficulty = e.target.value
        this.setState({
            selectedQuestionDifficulty: difficulty
        })
    }

    async getSubmissionContent(file) {
        this.setState({
            importFile: file
        });
    }

    async onImportQuestions(e) {
        e.preventDefault()
        if (this.state.selectedQuestionCategories.length > 0 && this.state.selectedQuestionType != null && this.state.exportImportFormat != null && this.state.importFile && this.state.selectedQuestionDifficulty !== null) {
            this.setState({
                exportImportLoading: true
            })
            if (this.state.selectedQuestionType === questionTypeConstants.MCQ.name) {
                await MCQImport(this.state.selectedQuestionCategories, questionTypeConstants.MCQ.id, this.state.importFile, this.state.exportImportFormat, this.state.selectedQuestionDifficulty);
                this.setState({
                    import: false,
                    exportImportLoading: false
                });
                // window.Swal.fire({
                //         title: 'MCQ Question/s Imported Successfully!',
                //         text: 'Success!',
                //         icon: 'success',
                //         customClass: {
                //     confirmButton: `btn font-weight-bold ${confirmClass}`,
                //   },
                //   onOpen: (modalElement) => {
                //       if (customer === customerConstants.EXO_CUSTOMER2) {
                //           modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                //       }
                //   }
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         history.push(routes.questions_library);
                //         window.location.reload();
                //     }
                // })
            } else if (this.state.selectedQuestionType === questionTypeConstants.DESCRIPTIVE.name) {
                await DescriptiveImport(this.state.selectedQuestionCategories, questionTypeConstants.DESCRIPTIVE.id, this.state.importFile, this.state.exportImportFormat, this.state.selectedQuestionDifficulty);
                // window.Swal.fire({
                //         title: 'MCQ Question/s Imported Successfully!',
                //         text: 'Success!',
                //         icon: 'success',
                //         customClass: {
                //     confirmButton: `btn font-weight-bold ${confirmClass}`,
                //   },
                //   onOpen: (modalElement) => {
                //       if (customer === customerConstants.EXO_CUSTOMER2) {
                //           modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                //       }
                //   }
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         history.push(routes.questions_library);
                //         window.location.reload();
                //     }
                // })
                this.setState({
                    import: false,
                    exportImportLoading: false
                });
            }
        }
    }

    onClickCloseImport(e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to discard and close?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Close it!'
        }).then((result) => {
            if (result.isConfirmed) {
                window.$('#import-questions').modal('hide');
                this.setState({
                    selectedQuestionDifficulty: null,
                    selectedQuestionType: null,
                    exportImportFormat: null
                })
                history.push(routes.questions_library);
                window.location.reload();
            }
        })
    }

    onChangeUnshare(e) {
        let newunsharevalue
        if (this.state.shareUser.length === 1) {
            newunsharevalue = this.props.sharedUsers[0].share_id
        } else {
            newunsharevalue = e.target.value
        }
        this.setState({ selectedUserUnshare: newunsharevalue })
    }

    async unShareQuestion() {
        if (this.state.shareUser) {
            const user_id = DataHandler.getFromSession("user_id")
            const question_id = this.props.questionShare.question_id
            const share_id = this.state.selectedUserUnshare
            window.Swal.showLoading()
            await this.props.unShareQuestion({ share_id, user_id, question_id })
            if (!this.props.shareLoading) {
                if (this.props.shareError) {
                    window.Swal.fire({
                        title: 'Unable to un-share the Question!',
                        text: 'Failed!',
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    });
                } else {
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Removed access Successfully',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    }).then((result) => {
                        if (result) {
                            history.push(routes.questions_library);
                            window.location.reload();
                        }
                    });
                }
            }
        } else {
            let errors = {};
            errors.selectedUserUnshare = "Can't be empty";
            this.setState({ errors })
        }
    }

    deletSubmissionContentImport(file) {
        this.setState({
            importFile: null
        })
    }

    onFilterInput = (v, r) => {
        let categoryOptions
        let languageOptions
        let subjectOptions
        let sourceOptions
        let authorOptions
        let gradeOptions
        let yearOptions
        let termOptions
        let chapterOptions
        let typeOptions
        let difficultyOptions
        let selectedFilters = []
        this.setState({
            filters: v,
            filterValues: []
        })

        if (r === "removeOption") {
            this.setState({
                selectedAuthors: null,
                selectedsubjects: null,
                selectedChapters: null,
                selectedTerms: null,
                selectedCategories: null,
                selectedYears: null,
                selectedLanguages: null,
                selectedDifficulties: null,
                selectedTypes: null,
                selectedSources: null,
                selectedGrades: null,

            }, () => {
                this.fetchAllMyQuestions()
                //this.fetchAllSharedQuestions()
                this.fetchAllPublicQuestions();
            })

        }
        if (v.includes("Category")) {
            let questionCategories = this.props.allQuestionMetaData.find(item => 'categories' in item).categories;
            categoryOptions = questionCategories?.map((val) => {
                return {
                    filter: "Category",
                    name: val.name,
                    category_id: val.category_id
                }
            })
            selectedFilters = [...selectedFilters, ...categoryOptions]
        } if (v.includes("Language")) {
            let questionLanguages = this.props.allQuestionMetaData.find(item => 'languages' in item).languages;
            languageOptions = questionLanguages?.map((val) => {
                return {
                    filter: "Language",
                    name: val.language,
                    language_id: val.language_id
                }
            })
            selectedFilters = [...selectedFilters, ...languageOptions]
        } if (v.includes("Source")) {
            let questionSources = this.props.allQuestionMetaData.find(item => 'sources' in item).sources;
            sourceOptions = questionSources?.map((val) => {
                return {
                    filter: "Source",
                    name: val.source,
                    source_id: val.source_id
                }
            })
            selectedFilters = [...selectedFilters, ...sourceOptions]
        } if (v.includes("Subject")) {
            let questionSubjects = this.props.allQuestionMetaData.find(item => 'subjects' in item).subjects;
            subjectOptions = questionSubjects?.map((val) => {
                return {
                    filter: "Subject",
                    name: val.subject,
                    subject_id: val.subject_id
                }
            })
            selectedFilters = [...selectedFilters, ...subjectOptions]
        } if (v.includes("Type")) {
            let questionTypes = this.props.allQuestionMetaData.find(item => 'types' in item).types;
            typeOptions = questionTypes?.map((val) => {
                return {
                    filter: "Type",
                    name: val.qtype,
                    type_id: val.type_id
                }
            })
            selectedFilters = [...selectedFilters, ...typeOptions]
        } if (v.includes("Author")) {
            let questionAuthors = this.props.allQuestionMetaData.find(item => 'authors' in item).authors;
            authorOptions = questionAuthors?.map((val) => {
                return {
                    filter: "Author",
                    name: val.author,
                    author_id: val.author_id
                }
            })
            selectedFilters = [...selectedFilters, ...authorOptions]
        } if (v.includes("Year")) {
            let questionYears = this.props.allQuestionMetaData.find(item => 'years' in item).years;
            yearOptions = questionYears?.map((val) => {
                return {
                    filter: "Year",
                    name: val.year,
                    year_id: val.year_id
                }
            })
            selectedFilters = [...selectedFilters, ...yearOptions]
        } if (v.includes("Dificulty")) {
            let questionDifficulties = this.props.allQuestionMetaData.find(item => 'difficulty_types' in item).difficulty_types;
            difficultyOptions = questionDifficulties?.map((val) => {
                return {
                    filter: "Dificulty",
                    name: val.difficulty_type,
                    difficulty_id: val.difficulty_id
                }
            })
            selectedFilters = [...selectedFilters, ...difficultyOptions]
        } if (v.includes("Grade")) {
            let questionGrades = this.props.allQuestionMetaData.find(item => 'grades' in item).grades;
            gradeOptions = questionGrades?.map((val) => {
                return {
                    filter: "Grade",
                    name: val.grade,
                    grade_id: val.grade_id
                }
            })
            selectedFilters = [...selectedFilters, ...gradeOptions]
        } if (v.includes("Term")) {
            let questionTerms = this.props.allQuestionMetaData.find(item => 'terms' in item).terms;
            termOptions = questionTerms?.map((val) => {
                return {
                    filter: "Term",
                    name: val.term,
                    term_id: val.term_id
                }
            })
            selectedFilters = [...selectedFilters, ...termOptions]
        } if (v.includes("Chapter")) {
            let questionChapters = this.props.allQuestionMetaData.find(item => 'chapters' in item).chapters;
            chapterOptions = questionChapters?.map((val) => {
                return {
                    filter: "Chapter",
                    name: val.chapter,
                    chapter_id: val.chapter_id
                }
            })
            selectedFilters = [...selectedFilters, ...chapterOptions]
        }
        this.setState({
            selectedFilters: selectedFilters
        })

    }

    onSelectFilter = (e) => {
        let selectedGrades = []
        let selectedChapters = []
        let selectedsubjects = []
        let selectedAuthors = []
        let selectedYears = []
        let selectedCategories = []
        let selectedTerms = []
        let selectedSources = []
        let selectedTypes = []
        let selectedDifficulties = []
        let selectedLanguages = []

        e.map((val) => {
            if (val.filter === "Category") {
                selectedCategories.push(val?.category_id)
            } if (val.filter === "Type") {
                selectedTypes.push(val?.type_id)
            } if (val.filter === "Dificulty") {
                selectedDifficulties.push(val?.difficulty_id)
            } if (val.filter === "Language") {
                selectedLanguages.push(val?.language_id)
            } if (val.filter === "Source") {
                selectedSources.push(val?.source_id)
            } if (val.filter === "Subject") {
                selectedsubjects.push(val?.subject_id)
            } if (val.filter === "Author") {
                selectedAuthors.push(val?.author_id)
            } if (val.filter === "Year") {
                selectedYears.push(val?.year_id)
            } if (val.filter === "Term") {
                selectedTerms.push(val?.term_id)
            } if (val.filter === "Chapter") {
                selectedChapters.push(val?.chapter_id)
            } if (val.filter === "Grade") {
                selectedGrades.push(val?.grade_id)
            }
        })

        this.setState({
            filterValues: e,
            selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
            selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
            selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
            selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
            selectedCategories: selectedCategories.length > 0 ? selectedCategories : null,
            selectedYears: selectedYears.length > 0 ? selectedYears : null,
            selectedLanguages: selectedLanguages.length > 0 ? selectedLanguages : null,
            selectedDifficulties: selectedDifficulties.length > 0 ? selectedDifficulties : null,
            selectedTypes: selectedTypes.length > 0 ? selectedTypes : null,
            selectedSources: selectedSources.length > 0 ? selectedSources : null,
            selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,

        }, () => {
            this.fetchAllMyQuestions()
            //this.fetchAllSharedQuestions()
            this.fetchAllPublicQuestions();
        })

    }

    syncTable() {
        return (
            <>
                <div className="questions-table" style={{ overflowX: "auto" }}>
                    {this.state.myQuestions.length > 0 ? (
                        <Table>
                            <>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="thead"></TableCell>
                                        {/* <TableCell className="thead">No</TableCell> */}
                                        <TableCell
                                            className="thead"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this.handleSortChange("question_id")}
                                        >
                                            Question Code{" "}
                                            {this.state.sort_column === "question_id" && (
                                                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className="thead"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this.handleSortChange("title")}
                                        >
                                            Question Title{" "}
                                            {this.state.sort_column === "title" && (
                                                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className="thead"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this.handleSortChange("category_id")}
                                        >
                                            Question Category{" "}
                                            {this.state.sort_column === "category_id" && (
                                                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className="thead"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this.handleSortChange("qtype")}
                                        >
                                            Question Type{" "}
                                            {this.state.sort_column === "qtype" && (
                                                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            className="thead"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this.handleSortChange("difficulty_id")}
                                        >
                                            Question Difficulty{" "}
                                            {this.state.sort_column === "difficulty_id" && (
                                                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                            )}
                                        </TableCell>
                                        <TableCell className="thead">Question Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.myQuestions.map((el, i) => (

                                        <TableRow key={i}>
                                            <TableCell className="tData"></TableCell>
                                            {/* <TableCell className="tData">{i + 1}</TableCell> */}
                                            <TableCell className="tData">
                                                <div className='center-vertical mg-m library-title'>
                                                    {"QUSTN" + el.question_id}
                                                </div>
                                            </TableCell>
                                            <TableCell className="tData">
                                                <Tooltip title={el.title} placement="bottom-start" arrow>
                                                    <div>
                                                        {el.title.length > 20 ? `${el.title.substring(0, 20)}...` : el.title}
                                                        {/* <span className="d-block text-muted pt-2 font-size-sm">{this.getDate(el.created_at)}</span> */}
                                                        <span className="d-block text-muted pt-2 font-size-sm">{this.getShare(el.share)}</span>
                                                    </div>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className="tData">{this.getQuestionCategory(el.category_id)}</TableCell>
                                            <TableCell className="tData">{this.getQuestionTypeName(el.qtype, el.is_subquestion)}</TableCell>
                                            <TableCell className="tData">{<span
                                                className={this.getColor(this.getQuestionDifficulty(el.difficulty_id))}>{this.getQuestionDifficulty(el.difficulty_id)}</span>}
                                            </TableCell>
                                            <TableCell className="tData">{<div>
                                                <Button
                                                    onClick={(e) => this.onViewQuestion(el, e)}
                                                    className="btn btn-icon action-btn btn-sm m-1 view-question-btn flaticon-eye-icon"
                                                    dataIndex={i}
                                                    dataToggle="modal"
                                                    dataTarget="#view-details"
                                                    dataBackDrop="static"
                                                    title="View Details"
                                                />
                                                <Button
                                                    onClick={this.onClickEdit}
                                                    className="btn btn-icon action-btn btn-sm m-1 edit-question-btn flaticon-edit-icon"
                                                    dataIndex={i}
                                                    title="Edit Details"
                                                />
                                                {el.privilege_id !== 1 && (
                                                    <Button
                                                        className="btn btn-icon action-btn btn-sm m-1 share-question-btn flaticon-share-icon"
                                                        dataIndex={i}
                                                        title="Share Details"
                                                        dataToggle="modal"
                                                        dataTarget="#manage-sharing"
                                                        dataBackDrop="static"
                                                    />
                                                )}
                                                <Button
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-question-btn flaticon-delete"
                                                    dataIndex={i}
                                                    title="Delete"
                                                    onClick={this.onDeleteAssessment}
                                                />
                                            </div>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </>
                        </Table>
                    ) : (
                        <div className="text-center mb-5 mt-10" style={{ height: "90vh" }}>
                            <label>No Questions Available!</label>
                        </div>
                    )}
                </div>
            </>
        )
    }


    syncShareTable() {

        return (
            <div style={{ overflowX: "auto" }}>
                {this.state.sharequestionData?.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="thead"></TableCell>
                                {/* <TableCell className="thead">No</TableCell> */}
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("question_id")}
                                >
                                    Question Code{" "}
                                    {this.state.sort_column === "question_id" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("title")}
                                >
                                    Question Title{" "}
                                    {this.state.sort_column === "title" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("category_id")}
                                >
                                    Question Category{" "}
                                    {this.state.sort_column === "category_id" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("qtype")}
                                >
                                    Question Type{" "}
                                    {this.state.sort_column === "qtype" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("difficulty_id")}
                                >
                                    Question Difficulty{" "}
                                    {this.state.sort_column === "difficulty_id" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Question Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.sharequestionData.map((el, i) => (

                                <TableRow key={i}>
                                    <TableCell className="tData"></TableCell>
                                    {/* <TableCell className="tData">{i + 1}</TableCell> */}
                                    <TableCell className="tData">
                                        <div className='center-vertical mg-m library-title'>
                                            {"QUSTN" + el.question_id}
                                        </div>
                                    </TableCell>
                                    <TableCell className="tData">
                                        <Tooltip title={el.title} placement="bottom-start" arrow>
                                            <div>
                                                {el.title.length > 20 ? `${el.title.substring(0, 20)}...` : el.title}
                                                {/* <span
                                                    // className="d-block text-muted pt-2 font-size-sm">Created {this.getDate(el.created_at)} ago.</span>
                                                    className="d-block text-muted pt-2 font-size-sm">{this.getDate(el.created_at)}</span> */}
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className="tData">{this.getQuestionCategory(el.category_id)}</TableCell>
                                    <TableCell className="tData">{this.getQuestionTypeName(el.qtype, el.is_subquestion)}</TableCell>
                                    <TableCell className="tData">{<span
                                        className={this.getColor(this.getQuestionDifficulty(el.difficulty_id))}>{this.getQuestionDifficulty(el.difficulty_id)}</span>}
                                    </TableCell>
                                    <TableCell className="tData">
                                        {
                                            <div>
                                                <Button
                                                    onClick={() => this.onViewQuestion(el)}
                                                    dataIndex={i}
                                                    className="btn btn-icon action-btn btn-sm m-1 share-view-question-btn flaticon-eye-icon"
                                                    title="View Details"
                                                />
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Questions Available!</label>
                    </div>
                )}
            </div>
        )
    }

    syncPublicTable() {
        return (
            <div style={{ overflowX: "auto" }}>
                {this.state.publicQuestions?.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="thead"></TableCell>
                                {/* <TableCell className="thead">No</TableCell> */}
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("question_id")}
                                >
                                    Question Code{" "}
                                    {this.state.sort_column === "question_id" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("title")}
                                >
                                    Question Title{" "}
                                    {this.state.sort_column === "title" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("category_id")}
                                >
                                    Question Category{" "}
                                    {this.state.sort_column === "category_id" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("qtype")}
                                >
                                    Question Type{" "}
                                    {this.state.sort_column === "qtype" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChange("difficulty_id")}
                                >
                                    Question Difficulty{" "}
                                    {this.state.sort_column === "difficulty_id" && (
                                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Question Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.publicQuestions.map((el, i) => (

                                <TableRow key={i}>
                                    <TableCell className="tData"></TableCell>
                                    {/* <TableCell className="tData">{i + 1}</TableCell> */}
                                    <TableCell className="tData">
                                        <div className='center-vertical mg-m library-title'>
                                            {"QUSTN" + el.question_id}
                                        </div>
                                    </TableCell>
                                    <TableCell className="tData">
                                        <Tooltip title={el.title} placement="bottom-start" arrow>
                                            <div>
                                                {el.title.length > 20 ? `${el.title.substring(0, 20)}...` : el.title}
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className="tData">{this.getQuestionCategory(el.category_id)}</TableCell>
                                    <TableCell className="tData">{this.getQuestionTypeName(el.qtype, el.is_subquestion)}</TableCell>
                                    <TableCell className="tData">{<span
                                        className={this.getColor(this.getQuestionDifficulty(el.difficulty_id))}>{this.getQuestionDifficulty(el.difficulty_id)}</span>}
                                    </TableCell>
                                    <TableCell className="tData">
                                        {
                                            <div>
                                                <Button
                                                    onClick={(e) => this.onViewQuestion(el, e)}
                                                    dataIndex={i}
                                                    className="btn btn-icon action-btn btn-sm m-1 public-view-question-btn flaticon-eye-icon"
                                                    title="View Details"
                                                />
                                                <Button
                                                    onClick={this.onClickEdit}
                                                    className="btn btn-icon action-btn btn-sm m-1 public-edit-question-btn flaticon-edit-icon"
                                                    dataIndex={i}
                                                    title="Edit Details"
                                                />
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Questions Available!</label>
                    </div>
                )}
            </div>
        )
    }

    render() {
        if (this.state.edit) {
            return <Redirect to='/edit-question' />;
        }
        if (this.props.mcq_success && this.props.textSuccess === "Getting all the appQuestions created by you!") {
            //window.location.reload()
            this.fetchAllMyQuestions();
            //this.fetchAllSharedQuestions();
            this.fetchAllPublicQuestions();
        }
        if (this.props.desc_success && this.props.textSuccess === "Getting all the appQuestions created by you!") {
            //window.location.reload()
            this.fetchAllMyQuestions();
            //this.fetchAllSharedQuestions();
            this.fetchAllPublicQuestions();
        }

        if (this.props.shareSuccess === 'Obtained shared users successfullly') {

            window.$('#showshare').selectpicker('refresh');
        }
        return (
            <AdminTemplate>
                <div style={{ height: "90vh", overflow: "auto" }}>
                    <ContentWrapper>
                        {this.props.qus_loading && this.state.exportImportLoading === true ?
                            <Spinner id="show" text="Generating content.." />

                            : <div></div>
                        }
                        {this.props.mcq_loading || this.props.desc_loading && this.state.exportImportLoading === false ?
                            <Spinner id="show" text="Please wait.." />

                            : <div></div>
                        }
                        {this.state.isDeleteLoading ?
                            <Spinner id="show" text="Please wait.." />

                            : <div></div>
                        }
                        {this.state.isTableDataLoading ?
                            <Spinner id="show" text="Please wait.." />

                            : <div></div>
                        }
                        {/* begin::Card */}
                        <Card>

                            <CardHeader>
                                <CardTitle cardLabel="Questions Library" />
                                <CardToolbar>
                                    <CreateButton createButtonTitle="Create Question" to="new-question" />
                                </CardToolbar>
                            </CardHeader>

                            <CardBody>
                                <div className="row justify-content-between align-items-center">
                                    <TableSearchButton />
                                    <TabList>
                                        <NavItem
                                            className="nav-link active"
                                            id="questions-library-tab-1"
                                            href="#questions-library-1"
                                            dataToggle="tab"
                                            iconName="flaticon2-chat-1"
                                            tabName="My Questions"
                                            onClick={() => { this.setState({ count: this.props.totalCount }) }}
                                        />
                                        {/* <NavItem
                                        className="nav-link"
                                        id="share-questions-tab-1"
                                        href="#share-questions-1"
                                        dataToggle="tab"
                                        iconName="flaticon-users-1"
                                        tabName="Shared Questions"
                                        onClick={() => { this.setState({ count: this.props.totalSharedCount }) }}
                                    /> */}
                                        <NavItem
                                            className="nav-link"
                                            id="public-questions-tab-1"
                                            href="#public-questions-1"
                                            dataToggle="tab"
                                            iconName="flaticon2-file"
                                            tabName="All Questions"
                                            onClick={() => { this.setState({ count: this.props.totalPublicCount }) }}
                                        />
                                    </TabList>
                                </div>
                                {/* begin::Search */}
                                <div className="row py-5 d-flex align-items-center">
                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <FormGroup labelName="Filter By">
                                                    <Autocomplete
                                                        multiple
                                                        id="checkboxes-filter"
                                                        options={QUESTION_FILTERS}
                                                        disableCloseOnSelect
                                                        getOptionLabel={(option) => option}
                                                        onChange={(e, v, r) => this.onFilterInput(v, r)}
                                                        renderOption={(props, option, { selected }) => (
                                                            <li {...props}>
                                                                <Checkbox
                                                                    style={{ marginRight: 8, color: "#5458AF" }}
                                                                    checked={selected}
                                                                />
                                                                {option}
                                                            </li>
                                                        )}
                                                        className="col-lg-8 col-md-8 p-0"
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Select Filter" />
                                                        )}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-md-6">
                                                {this.state.filters.length > 0 && (
                                                    <FormGroup labelName="">
                                                        <Autocomplete
                                                            multiple
                                                            id="grouped-demo"
                                                            className="col-lg-8 col-md-8 p-0"
                                                            options={this.state.selectedFilters}
                                                            groupBy={(option) => option.filter}
                                                            onChange={(e, v) => this.onSelectFilter(v)}
                                                            getOptionLabel={(option) => option.name}
                                                            sx={{ width: 300 }}
                                                            value={this.state.filterValues}
                                                            renderInput={(params) => <TextField {...params} label="Filters" />}
                                                        />
                                                    </FormGroup>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-3 mb-2">
                                        <Form.Group
                                            controlId="exampleForm.ControlInput1"
                                            className="mb-2"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="Search"
                                                onChange={this.handleSearch}
                                                className="col-md-12 col-12"
                                            />
                                        </Form.Group>
                                    </div>
                                </div>

                                <TabContent>
                                    <TabPane className="tab-pane fade show active" id="questions-library-1"
                                        ariaLabelledby="questions-library-tab-1">
                                        {(this.state.isTableDataLoading) ? (
                                            <div>
                                                <div className="loading-container mb-5 mt-10">
                                                    <ReactSpinner animation="border" role="status" style={{ display: 'block', margin: 'auto' }}>
                                                        <span className="sr-only">Loading...</span>
                                                    </ReactSpinner>
                                                </div>
                                            </div>
                                        ) : (
                                            this.syncTable()

                                        )
                                        }
                                        <div className="d-flex justify-content-end">
                                            <MaterialCustomTablePagination
                                                totalCount = {this.props.totalCount}
                                                currentPage = {this.state.currentPage}
                                                limit = {this.state.rowsPerPage}
                                                handlePageChange = {this.handlePageChange}
                                                handleRowsPerPageChange = {this.handleRowsPerPageChange}
                                                handlePageOffsetChange = {this.handlePageOffsetChange}
                                                offset = {this.state.offset}
                                                rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                                            />
                                        </div>
                                    </TabPane>

                                    <TabPane className="tab-pane fade" id="share-questions-1"
                                        ariaLabelledby="share-questions-tab-1">
                                        {/* begin: Datatable */}
                                        {this.state.isTableDataLoading ? (
                                            <div>
                                                <div className="loading-container mb-5 mt-10">
                                                    <ReactSpinner animation="border" role="status" style={{ display: 'block', margin: 'auto' }}>
                                                        <span className="sr-only">Loading...</span>
                                                    </ReactSpinner>
                                                </div>
                                            </div>
                                        ) : (
                                            this.syncShareTable()

                                        )
                                        }
                                        {/* end: Datatable */}
                                        <div className="d-flex justify-content-end">
                                            <MaterialCustomTablePagination
                                                totalCount = {this.props.totalSharedCount}
                                                currentPage = {this.state.currentPage}
                                                limit = {this.state.rowsPerPage}
                                                handlePageChange = {this.handlePageChange}
                                                handleRowsPerPageChange = {this.handleRowsPerPageChange}
                                                handlePageOffsetChange = {this.handlePageOffsetChange}
                                                offset = {this.state.offset}
                                                rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                                            />
                                        </div>
                                    </TabPane>
                                    <TabPane className="tab-pane fade" id="public-questions-1"
                                        ariaLabelledby="public-questions-tab-1">
                                        {/* begin: Datatable */}
                                        {this.state.isTableDataLoading ? (
                                            <div>
                                                <div className="loading-container mb-5 mt-10">
                                                    <ReactSpinner animation="border" role="status" style={{ display: 'block', margin: 'auto' }}>
                                                        <span className="sr-only">Loading...</span>
                                                    </ReactSpinner>
                                                </div>
                                            </div>
                                        ) : (
                                            this.syncPublicTable()

                                        )
                                        }
                                        {/* end: Datatable */}
                                        <div className="d-flex justify-content-end">
                                            <MaterialCustomTablePagination
                                                totalCount = {this.props.totalPublicCount}
                                                currentPage = {this.state.currentPage}
                                                limit = {this.state.rowsPerPage}
                                                handlePageChange = {this.handlePageChange}
                                                handleRowsPerPageChange = {this.handleRowsPerPageChange}
                                                handlePageOffsetChange = {this.handlePageOffsetChange}
                                                offset = {this.state.offset}
                                                rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                                            />
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                        {/* end::Card */}

                        {/* Import Questions Modal */}
                        <Modal modalId="import-questions" dataBackDrop="static" dataKeyboard="false">
                            <ModalHeader>
                                <h5 className="modal-title">Import Questions</h5>
                                <div class="text-right">
                                    <Button className="btn btn-primary font-weight-bold mr-2" dataDismiss="modal" btnName="Import" onClick={this.onImportQuestions} disabled={this.state.exportImportFormat == null || this.state.selectedQuestionDifficulty == null || this.state.importFile == null || this.state.selectedQuestionType == null ? true : false} />
                                    <Button className="btn btn-light-primary font-weight-bold" btnName="Close" onClick={this.onClickCloseImport} />
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup labelName="Select Category" className="col-lg-12">
                                    {this.state.categoryOptionsImport &&
                                        <Select
                                            selectId="question-import-select"
                                            id="question-import-select"
                                            selectWrap="col-lg-8"
                                            className="form-control selectpicker"
                                            dataLiveSearch="true"
                                            dataSize="5"
                                            defaultValue={this.state.categoryOptionsImport[0]}
                                            multiple={false}
                                            spinner={this.props.loading && this.state.categoryOptionsImport.length <= 0}
                                            disabled={this.props.loading && this.state.categoryOptionsImport.length <= 0}
                                            children={this.state.categoryOptionsImport}
                                            onChange={this.onChangeImportQuestionCategory}
                                        />
                                    }
                                </FormGroup>

                                <FormGroup labelName="Select Question Type" className="col-lg-12">
                                    <RadioInline className="col-lg-6 col-md-12">
                                        <Radio name="radio" radioBtnName={questionTypeConstants.MCQ.name} checked={this.state.selectedQuestionType === questionTypeConstants.MCQ.name} value={questionTypeConstants.MCQ.name} onChange={this.onChangeImportQuestionType} />
                                        <Radio name="radio" radioBtnName={questionTypeConstants.DESCRIPTIVE.name} checked={this.state.selectedQuestionType === questionTypeConstants.DESCRIPTIVE.name} value={questionTypeConstants.DESCRIPTIVE.name} onChange={this.onChangeImportQuestionType} />

                                    </RadioInline>
                                </FormGroup>

                                <FormGroup labelName="Select Difficulty Type" className="col-lg-12">
                                    <RadioInline className="col-lg-6 col-md-12">
                                        <Radio name="radio4" radioBtnName={questionDifficultyConstants.EASY.name} checked={this.state.selectedQuestionDifficulty === questionDifficultyConstants.EASY.id.toString()} value={questionDifficultyConstants.EASY.id} onChange={this.onChangeImportQuestionDifficulty} />
                                        <Radio name="radio4" radioBtnName={questionDifficultyConstants.MEDIUM.name} checked={this.state.selectedQuestionDifficulty === questionDifficultyConstants.MEDIUM.id.toString()} value={questionDifficultyConstants.MEDIUM.id} onChange={this.onChangeImportQuestionDifficulty} />
                                        <Radio name="radio4" radioBtnName={questionDifficultyConstants.HARD.name} checked={this.state.selectedQuestionDifficulty === questionDifficultyConstants.HARD.id.toString()} value={questionDifficultyConstants.HARD.id} onChange={this.onChangeImportQuestionDifficulty} />
                                    </RadioInline>
                                </FormGroup>

                                <FormGroup labelName="Select an import format" className="col-lg-12">
                                    <RadioInline className="col-lg-6 col-md-12">
                                        <Radio name="radio2" radioBtnName={exportImportFormatConstants.AIKEN} checked={this.state.exportImportFormat === exportImportFormatConstants.AIKEN} value={exportImportFormatConstants.AIKEN} onChange={this.onChangeExportFormat} />
                                        <Radio name="radio2" radioBtnName={exportImportFormatConstants.GIFT} checked={this.state.exportImportFormat === exportImportFormatConstants.GIFT} value={exportImportFormatConstants.GIFT} onChange={this.onChangeExportFormat} />
                                    </RadioInline>
                                </FormGroup>
                                <FormGroup className="col-lg-12">
                                    <ImportFileUpload className="col-lg-6 col-md-12" labelName="Import file" type="text" callBack={this.getSubmissionContent} deleteback={this.deletSubmissionContentImport} />
                                </FormGroup>
                            </ModalBody>
                        </Modal>

                        {/* Export Questions Modal */}
                        <Modal modalId="export-questions" dataBackDrop="static" dataKeyboard="false">
                            <ModalHeader>
                                <h5 className="modal-title">Export Questions</h5>
                                <div class="text-right">
                                    <Button className="btn btn-primary font-weight-bold mr-2" dataDismiss="modal" btnName="Export" onClick={this.onExportQuestion} disabled={this.state.exportImportFormat == null || this.state.selectedCategory.length == 0 || !this.state.selectedQuestions.length > 0 || this.state.selectedQuestionType == null ? true : false} />
                                    <Button className="btn btn-light-primary font-weight-bold" btnName="Close" onClick={this.onClickCloseExport} />
                                </div>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup labelName="Select Question Type" className="col-lg-12">
                                    <RadioInline className="col-md-6">
                                        <Radio name="radio3" radioBtnName={questionTypeConstants.MCQ.name} checked={this.state.selectedQuestionType === questionTypeConstants.MCQ.name} value={questionTypeConstants.MCQ.name} onChange={this.onChangeExportQuestionType} />
                                        <Radio name="radio3" radioBtnName={questionTypeConstants.DESCRIPTIVE.name} checked={this.state.selectedQuestionType === questionTypeConstants.DESCRIPTIVE.name} value={questionTypeConstants.DESCRIPTIVE.name} onChange={this.onChangeExportQuestionType} />

                                    </RadioInline>
                                </FormGroup>
                                <FormGroup labelName="Select Category" className="col-lg-12">
                                    {/* {this.state.selectedCategory} */}
                                    {this.state.categoryOptionsExport &&
                                        // <Select selectWrap="col-md-6" className="form-control select2" multiple="multiple" name="param" selectId="kt_select2_11">    
                                        <Select
                                            ref="mySelect"
                                            selectId="kt_select2_11"
                                            id="mySelect"
                                            name="param"
                                            selectWrap="col-lg-8"
                                            className="form-control select2"
                                            dataLiveSearch="true"
                                            dataActionsBox="true"
                                            dataSize="5"
                                            multiple="multiple"
                                            dataPlaceHolder="Add Categories"
                                            defaultValue={this.state.selectedCategory[0]}
                                            //   value={this.state.selectedCategory}
                                            spinner={this.props.loading && this.state.categoryOptionsExport.length <= 0}
                                            disabled={this.props.loading && this.state.categoryOptionsExport.length <= 0}
                                            children={this.state.categoryOptionsExport}
                                        />
                                    }

                                </FormGroup>
                                <FormGroup labelName=" Question" className="col-lg-12">
                                    {this.state.selectedQuestionType === questionTypeConstants.MCQ.name && this.state.selectedCategory.length > 0 &&
                                        <div className="RichEditor-root-export col-lg-8">

                                            {this.props.myQuestions.map((qus, i) => (
                                                <div>

                                                    {qus.qtype === questionTypeConstants.MCQ.id &&
                                                        <CheckBoxInline className="col-md-1">
                                                            <CheckBox
                                                                key={i}
                                                                onChange={e => this.onChangeExportSelection(e, qus, i)}
                                                                checked={this.state.selectedQuestions.includes(qus) ? true : false}
                                                                checkBoxName={qus.title}
                                                                style={{
                                                                    color: "#5458AF",
                                                                }}
                                                            />
                                                        </CheckBoxInline>
                                                    }
                                                </div>
                                            ))}
                                            {this.props.sharedQuestions.map((qus, i) => (
                                                <div>

                                                    {qus.qtype === questionTypeConstants.MCQ.id &&
                                                        <CheckBoxInline className="col-md-1">
                                                            <CheckBox
                                                                key={i}
                                                                onChange={e => this.onChangeExportSelection(e, qus, i)}
                                                                checked={this.state.selectedQuestions.includes(qus) ? true : false}
                                                                checkBoxName={qus.title}
                                                                style={{
                                                                    color: "#5458AF",
                                                                }}
                                                            />
                                                        </CheckBoxInline>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {this.state.selectedQuestionType === questionTypeConstants.DESCRIPTIVE.name && this.state.selectedCategory.length > 0 &&
                                        <div className="RichEditor-root-export col-lg-8">

                                            {this.props.myQuestions.map((qus, i) => (
                                                <div>

                                                    {qus.qtype === questionTypeConstants.DESCRIPTIVE.id &&
                                                        <CheckBoxInline className="col-md-1">
                                                            <CheckBox
                                                                key={i}
                                                                onChange={e => this.onChangeExportSelection(e, qus, i)}
                                                                checked={this.state.selectedQuestions.includes(qus) ? true : false}
                                                                checkBoxName={qus.title}
                                                                style={{
                                                                    color: "#5458AF",
                                                                }}
                                                            />
                                                        </CheckBoxInline>
                                                    }
                                                </div>
                                            ))}
                                            {this.props.sharedQuestions.map((qus, i) => (
                                                <div>

                                                    {qus.qtype === questionTypeConstants.DESCRIPTIVE.id &&
                                                        <CheckBoxInline className="col-md-1">
                                                            <CheckBox
                                                                key={i}
                                                                onChange={e => this.onChangeExportSelection(e, qus, i)}
                                                                checked={this.state.selectedQuestions.includes(qus) ? true : false}
                                                                checkBoxName={qus.title}
                                                                style={{
                                                                    color: "#5458AF",
                                                                }}
                                                            />
                                                        </CheckBoxInline>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </FormGroup>

                                <FormGroup labelName="Select an export format" className="col-lg-12">
                                    <RadioInline className="col-md-6">
                                        <Radio name="radio7" radioBtnName={exportImportFormatConstants.AIKEN} checked={this.state.exportImportFormat === exportImportFormatConstants.AIKEN} value={exportImportFormatConstants.AIKEN} onChange={this.onChangeExportFormat} />
                                        <Radio name="radio7" radioBtnName={exportImportFormatConstants.GIFT} checked={this.state.exportImportFormat === exportImportFormatConstants.GIFT} value={exportImportFormatConstants.GIFT} onChange={this.onChangeExportFormat} />
                                        <Radio name="radio7" radioBtnName={exportImportFormatConstants.PDF} checked={this.state.exportImportFormat === exportImportFormatConstants.PDF} value={exportImportFormatConstants.PDF} onChange={this.onChangeExportFormat} />
                                    </RadioInline>
                                </FormGroup>
                            </ModalBody>
                        </Modal>

                        {/* View Question Modal */}
                        <Modal modalId="view-details" dataBackDrop="static" dataKeyboard="false">
                            <ModalHeader>
                                <h4 className="modal-title text-muted">{this.props.question?.title}</h4>
                                <Button className="btn btn-light-primary font-weight-bold" dataDismiss="modal" btnName="Close" />
                            </ModalHeader>
                            {(this.props.qus_loading || this.state.isViewSubQuestionLoading) ? (
                                <div>
                                    <div className="loading-container mb-5 mt-10">
                                        <ReactSpinner
                                            animation="border"
                                            role="status"
                                            style={{ display: "block", margin: "auto" }}
                                        >
                                            <span className="sr-only">Loading...</span>
                                        </ReactSpinner>
                                    </div>
                                </div>
                            ) : (
                                <ModalBody>
                                    <div className="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                        {/* begin::Section */}
                                        <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-3">{this.props.question.qtype === questionTypeConstants.SUBQUESTION.id ? "Main Question :" : "Question :"}</h6>
                                            <div className="text-dark-50 line-height-lg col-md-9">
                                                <div>
                                                    <ViewEditor
                                                        editorState={this.state.editorQuestionState}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="separator separator-dashed my-5" />
                                        {/* end::Section */}

                                        {/* begin::Section */}
                                        {this.props.question.qtype !== questionTypeConstants.SUBQUESTION.id && (
                                            <div className="row">
                                            <h6 className="font-weight-bolder mb-3 col-lg-3 col-md-12">Model Answer :</h6>
                                                <div className="text-dark-50 line-height-lg col-lg-9 col-md-12">
                                                    <div>
                                                        {this.props.question.qtype === questionTypeConstants.DESCRIPTIVE.id && this.props.answerContent &&
                                                            <div>
                                                            <ViewEditor
                                                                    editorState={this.state.editorAnswerStateDescriptive}
                                                                />
                                                            </div>

                                                        }
                                                        {this.props.question.qtype === questionTypeConstants.MCQ.id && this.props.questionContent &&
                                                            <div>

                                                                {this.state.editorAnswerStates.length > 0 &&
                                                                    this.state.editorAnswerStates.map((editorState, i) => (
                                                                        <div className="view-questions-answer d-flex">


                                                                            {this.props.question?.answer_type_id === questionMCQAnswerTypeConstants.One.id ? 
                                                                                <RadioInline className="col-md-1 d-flex">
                                                                                    <Radio
                                                                                        checked={this.state.selectedChoices[i]}
                                                                                        style={{
                                                                                            color: "#5458AF",
                                                                                        }}
                                                                                    />
                                                                                </RadioInline>
                                                                                :
                                                                                <CheckBoxInline className="col-md-1 d-flex">
                                                                                    <CheckBox
                                                                                        checked={this.state.selectedChoices[i]}
                                                                                        style={{
                                                                                            color: "#5458AF",
                                                                                        }}
                                                                                    />
                                                                                </CheckBoxInline>
                                                                            }
                                                                            <div className="RichEditor-root">
                                                                            <ViewEditor
                                                                                    editorState={this.state.editorAnswerStates[i]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                }
                                                            </div>
                                                        }
                                                        {this.props.question.qtype === questionTypeConstants.SUBMISSION.id && this.state.attachment &&
                                                            <div className="RichEditor-root col-lg-8">
                                                                {this.state.attachment.map(attach => {
                                                                if (attach.replace(/"/g, '') !== "[]") {
                                                                        return <div>
                                                                            <Button className="btn btn-outline-primary primary-button mr-2" btnIconStyle="flaticon-interface-3" inlineStyle={{ innerWidth: '400px' }}
                                                                                btnName={attach.replace(/"/g, '')} onClick={(e) => {
                                                                                    this.onDownloadAttachment(e, attach.replace(/"/g, ''))
                                                                                }} />
                                                                            <br></br></div>
                                                                    }
                                                                    else {
                                                                    return <div></div>
                                                                    }
                                                                })
                                                                }
                                                            {this.state.attachment.length === 0 && <div>No answers uploaded!</div>}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {this.props.question.qtype === questionTypeConstants.SUBQUESTION.id && this.state.subQuestionAnswers.length === 0 &&
                                            <div className="row">
                                            <h6 className="font-weight-bolder mb-3 col-lg-3 col-md-12">Model Answer :</h6>
                                                <div className="text-dark-50 line-height-lg col-lg-9 col-md-12">
                                                    <div>
                                                    <ViewEditor
                                                        editorState={this.state.editorAnswerStateDescriptive}
                                                    />
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                        {this.props.question.qtype === questionTypeConstants.SUBQUESTION.id && this.state.subQuestionAnswers.length > 0 && (
                                            <>
                                                {this.state.subQuestionAnswers.length > 0 && this.state.subQuestionAnswers.map((val, i) => (

                                                    <div className="row border-bottom">
                                                        <div className="col-md-6 py-2">
                                                            <h6 className="font-weight-bolder mb-3">Sub Question</h6>
                                                            <div className="text-dark-50 line-height-lg px-0">
                                                                <div>
                                                                <ViewEditor
                                                                        editorState={this.state.subQuestionContent[i]}
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 py-2">
                                                            <h6 className="font-weight-bolder mb-3">Answer</h6>
                                                            <div className="text-dark-50 line-height-lg px-0">
                                                                <div>
                                                                <ViewEditor
                                                                        editorState={this.state.subQuestionAnswers[i]}
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    </div>

                                {this.props.question.category_id && (
                                    <>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Category :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'categories' in item).categories 
                                            .find(category => category.category_id === this.props.question.category_id)?.name
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.qtype && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Type :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'types' in item).types
                                            .find(type => type.type_id === this.props.question.qtype)?.qtype
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.language_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Medium/Language :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'languages' in item).languages
                                            .find(language => language.language_id === this.props.question.language_id)?.language
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.difficulty_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Difficulty :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'difficulty_types' in item).difficulty_types
                                            .find(difficulty => difficulty.difficulty_id === this.props.question.difficulty_id)?.difficulty_type
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.grade_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Grade :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'grades' in item).grades
                                            .find(grade => grade.grade_id === this.props.question.grade_id)?.grade
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.subject_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Subject :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'subjects' in item).subjects
                                            .find(subject => subject.subject_id === this.props.question.subject_id)?.subject
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.year_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Year :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'years' in item).years
                                            .find(year => year.year_id === this.props.question.year_id)?.year
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.term_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Term/Semester :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'terms' in item).terms
                                            .find(term => term.term_id === this.props.question.term_id)?.term
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.chapter_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Chapter/Module/Unit :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'chapters' in item).chapters
                                            .find(chapter => chapter.chapter_id === this.props.question.chapter_id)?.chapter
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.source_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Source :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'sources' in item).sources
                                            .find(source => source.source_id === this.props.question.source_id)?.source
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                {this.props.question.author_id && (
                                    <>
                                    <div className="separator separator-dashed my-5"></div>
                                    <div className="row">
                                        <h6 className="font-weight-bolder mb-3 col-md-4">
                                        Author :
                                        </h6>
                                        <div className="text-dark-50 line-height-lg col-md-5">
                                        <div className="inline-flex full-width align-center">
                                            {
                                            this.props.allQuestionMetaData.find(item => 'authors' in item).authors
                                            .find(author => author.author_id === this.props.question.author_id)?.author
                                            }
                                        </div>
                                        </div>
                                    </div>
                                    </>
                                )}

                                </ModalBody>
                            )}

                        </Modal>
                        {/* Questions Sharing Modal */}
                        <Modal modalId="manage-sharing" dataBackDrop="static" dataKeyboard="false">
                            <ModalHeader>
                                <h4 className="modal-title">Manage Question Sharing</h4>
                                <Button className="btn btn-light-primary font-weight-bold" dataDismiss="modal" btnName="Close" />
                            </ModalHeader>
                            <ModalBody>

                                <FormGroup labelName="Select user to share question">
                                    <Select
                                        selectWrap="col-lg-5 col-md-8 col-sm-7 col-7"
                                        selectId="show"
                                        id="show"
                                        className="form-control selectpicker"
                                        dataLiveSearch="true"
                                        dataSize="5"
                                        multiple={false}
                                        spinner={this.props.teacherDetailsLoading && this.state.user.length <= 0}
                                        disabled={this.props.teacherDetailsLoading && this.state.user.length <= 0}
                                        children={this.state.user}
                                        onChange={this.onChangeShare}
                                    >
                                    </Select>


                                    <div className="col-lg-3 col-md-4 col-sm-5 col-5">
                                        <Button className="btn btn-primary font-weight-bold" type="button" btnName="Share" disabled={this.state.selectedUserShare == '' || this.state.selectedUserShare == null}
                                            onClick={this.shareQuestion} />
                                    </div>
                                </FormGroup>

                                <FormGroup labelName="Select user to stop sharing question">
                                    <Select
                                        selectWrap="col-lg-5 col-md-8 col-sm-7 col-7"
                                        selectId="showshare"
                                        id="showshare"
                                        className="form-control selectpicker"
                                        dataLiveSearch="true"
                                        dataSize="5"
                                        multiple={false}
                                        spinner={this.props.teacherDetailsLoading && this.state.shareUser.length <= 0}
                                        disabled={this.props.teacherDetailsLoading && this.state.shareUser.length <= 0}
                                        children={this.state.shareUser}
                                        onChange={this.onChangeUnshare}
                                    >
                                    </Select>

                                    <div className="col-lg-3 col-md-4 col-sm-5 col-5">
                                        {/* <Button className="btn btn-primary font-weight-bold" btnName="Stop Sharing" disabled={this.state.selectedUserUnshare == '' || this.state.selectedUserUnshare == null}
                                        onClick={this.unShareQuestion} /> */}
                                        <Button className="btn btn-primary font-weight-bold" type="button" btnName="Stop Sharing" disabled={this.state.selectedUserUnshare == '' || this.state.selectedUserUnshare == null}
                                            onClick={this.unShareQuestion} />
                                    </div>
                                </FormGroup>

                            </ModalBody>

                        </Modal>
                    </ContentWrapper>
                </div>
            </AdminTemplate>
        )
    }
}

function mapStateToProps(state) {
    return {
        shareLoading: state.shareReducer.loading,
        shareSuccess: state.shareReducer.success,
        shareError: state.shareReducer.error,
        questionShare: state.shareReducer.question,
        sharedUsers: state.shareReducer.sharedUsers,

        textSuccess: state.questionsLibraryReducer.text,
        question: state.questionsLibraryReducer.question,
        qus_loading: state.questionsLibraryReducer.loading,
        myQuestions: state.questionsLibraryReducer.myQuestions,
        publicQuestions: state.questionsLibraryReducer.publicQuestions,

        totalCount: state.questionsLibraryReducer.totalCount,
        totalSharedCount: state.questionsLibraryReducer.totalSharedCount,
        totalPublicCount: state.questionsLibraryReducer.totalPublicCount,
        sharedQuestions: state.questionsLibraryReducer.sharedQuestions,
        assessmentSetQuestions: state.questionReducer.assessmentSetQuestions,
        questionContent: state.questionsLibraryReducer.questionContent,
        answerContent: state.questionsLibraryReducer.answerContent,

        allQuestionMetaData: state.questionsCreateReducer.allQuestionMetaData,
        loading: state.questionsCreateReducer.loading,
        questionById: state.questionsCreateReducer.question,
        error: state.questionsCreateReducer.error,
        success: state.questionsCreateReducer.success,

        allStaffUsers: state.staffUserReducer.allStaffUsers,
        teacherDetails: state.manageUserReducer.teacherDetails,
        teacherDetailsLoading: state.manageUserReducer.loading,

        mcq_loading: state.mcqReducer.loading,
        mcq_error: state.mcqReducer.error,
        mcq_success: state.mcqReducer.success,

        desc_loading: state.descriptiveReducer.loading,
        desc_error: state.descriptiveReducer.error,
        desc_success: state.descriptiveReducer.success
    };
}

const mapActionsToProps = {
    getAllMyQuestions: libraryActions.getAllMyQuestions,
    getAllPublicQuestions: libraryActions.getAllPublicQuestions,
    getSharedQuestions: libraryActions.getSharedQuestions,
    getAllSharedQuestions: libraryActions.getAllSharedQuestions,
    getQuestionContent: libraryActions.getQuestionContent,
    getQuestionGuidanceContent: libraryActions.getQuestionGuidanceContent,
    getAnswerContent: libraryActions.getAnswerContent,
   
    deleteQuestion: libraryActions.deleteQuestion,
    clearQuestionContent: libraryActions.clearQuestionContent,

    getQuestionById: createActions.getQuestionById,
    getQuestionMetaData: createActions.getQuestionMetaData,

    unShareFromMe: shareActions.unshareFromMe,
    loadSharePage: shareActions.loadSharePage,
    shareQuestion: shareActions.shareQuestion,
    getAlreadySharedUsers: shareActions.getAlreadySharedUsers,
    unShareQuestion: shareActions.stopSharing,

    getStaffUsers: staffUserActions.getAllStaffUsers,
    getTeacherDetails: manageUserActions.getTeacherDetails,
    getSetQuestion: questionActions.getSetQuestion
}

export default connect(mapStateToProps, mapActionsToProps)(QuestionsLibrary);
