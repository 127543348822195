import {assessGradeTypes} from '../settingsTypes';

const initialState = {
    allAssessmentGrades: [],
    loading: false,
    error: '',
    text: '',
    grade:'',
    totalGradesCount: 0,
    allGrades:[],
    errorgradedelete:false,
    loadinggradedelete:false,
    successgradeupdate: false,
    loadinggradeupdate: false,
    errorgradeupdate:false,
    successgradedelete:false,
    errorgradecreate: '',
    successgradecreate: false,
}

export function gradeReducer(state = initialState, action){
    switch (action.type){
        case assessGradeTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allAssessmentGrades: [],
                loading: false,
                error: '',
                text: '',
                grade:'',
                totalGradesCount:0,
                allGrades:[],
                errorgradedelete:false,
                loadinggradedelete:false,
                successgradeupdate: false,
                loadinggradeupdate: false,
                errorgradeupdate:false,
                successgradedelete:false,
                errorgradecreate: '',
                successgradecreate: false,
            }
        //Grade retrieve request sending
        case assessGradeTypes.GET_ALL_ASSESSMENT_GRADES:
            return {
                ...state,
                loading: true,
                text: 'Get all the assessment grades'
            }
        case assessGradeTypes.GET_ALL_ASSESSMENT_GRADES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessGradeTypes.GET_ALL_ASSESSMENT_GRADES_SUCCESS:
            return {
                ...state,
                loading: false,
                allAssessmentGrades: action.grades.grades,
                totalGradesCount: action.grades.total_count
            }
        case assessGradeTypes.GET_ALL_ASSESSMENT_GRADES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Grade creation request sending
        case assessGradeTypes.CREATE_GRADE_REQUEST:
            return {
                ...state,
                errorgradecreate: '',
                successgradecreate: false,
                loading: true
            }
        case assessGradeTypes.CREATE_GRADE_SUCCESS:
            var temp = state.allGrades;
            temp.push(action.grades)
            return {
                ...state,
                allGrades: temp,
                grade: action.grades.grade_id,
                successgradecreate: true,
                loading: false
            }
        case assessGradeTypes.CREATE_GRADE_FAILURE:
            return {
                ...state,
                errorgradecreate: action.error,
                loading: false
            }  
        //Grade deletion request sending   
        case assessGradeTypes.DELETE_GRADE_REQUEST:
            return {
            ...state,
            errorgradedelete: '',
            successgradedelete: false,
            loading: true,
            };
        case assessGradeTypes.DELETE_GRADE_SUCCESS:
            return {
            ...state,
            successgradedelete: true,
            loading: false,
            };
        case assessGradeTypes.DELETE_GRADE_FAILURE:
            return {
            ...state,
            errorgradedelete: action.error,
            loading: false,
            };
        //Grade updation request sending
        case assessGradeTypes.UPDATE_GRADE_REQUEST:
            return {
            ...state,
            errorgradeupdate:'',
            successgradeupdate: false,
            loading: true,
            };
        case assessGradeTypes.UPDATE_GRADE_SUCCESS:
            return {
            ...state,
            successgradeupdate: true,
            loading: false,
            };
        case assessGradeTypes.UPDATE_GRADE_FAILURE:
            return {
            ...state,
            errorgradeupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
