import React from 'react';
import Countdown from 'react-countdown';

const Completionlist = () => <span>Time exceeded! Submit your work within next 10 seconds!</span>;

const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
        return <Completionlist/>;
    } 
    else {
        let lessec
        let lessmin
        let lesshr
        let lessday
        let spancolor
        if(seconds<10){
          lessec = 0 
        }else{
            lessec = ''
        }
        if(days<10){
            lessday=0
        }else{
            lessday = ''
        }
        if(hours<10){
            lesshr=0
        }else{
            lesshr = ''
        }
        if(minutes < 10){
            lessmin = 0
        }else{
            lessmin = ''
        }
        if(hours<1 && minutes <10){
            spancolor = "label label-lg label-light-danger label-inline font-size-h1 time-count-text"
        }else{
            spancolor = "label label-lg label-light-success label-inline time-remaining time-count-text"
        }
       return <div className='row' style={{textAlign:"start", alignItems: 'center'}}>
        <span className="font-size-md position-center text-muted text-center bottom-text time-remaining-text">Time Left &emsp;</span>
            {days > 0 ? <span className={spancolor}>{lessday}{days}d : {lesshr}{hours}h : {lessmin}{minutes}m : {lessec}{seconds}s</span> :
            <span className={spancolor}>{lesshr}{hours}h : {lessmin}{minutes}m : {lessec}{seconds}s</span>}
            <br/>            
        </div>;
       
    }
};

export const examTimer = props => (
    <div className="time-remaining-wrap">
        <Countdown
            date={Date.now() + props.time_int}
            renderer={renderer}
        />
    </div>
);