import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const studentUserService = {
  getAllStudentUsers,
  getStudentUserRoles,
  searchStudentByEmail,
  statusChangeTerms
};

async function getAllStudentUsers() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/user/students/`, requestOptions).then(APIHandler.handleResponse);
}

async function getStudentUserRoles() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    const response = await fetch(`${config.oat_api_url}/user/student/roles/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function searchStudentByEmail(email) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(
      config.azure_ad_config.apis.OAT.name
    ),
  };

  const response = await fetch(
    `${config.oat_api_url}/user/students/email/${email}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function statusChangeTerms(id) {
  let requestOptions = {
    method: "PUT",
    headers: APIHandler.getHeader(
      config.azure_ad_config.apis.OAT.name
    ),
  };
  const response = await fetch(
    `${config.oat_api_url}/user/students/agree/${id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

