import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CountryService } from "../../../service/settings/manage/country.service.js";
import { ProvinceService } from "../../../service/settings/manage/province.service.js";
import { CityService } from "../../../service/settings/manage/city.service.js";
import { GenderService } from "../../../service/settings/manage/gender.service.js";
import { StatusTypesService } from "../../../service/settings/manage/status_type.service.js";
import { StatusService } from '../../../service/settings/manage/status.service.js';
import Spinner from "react-bootstrap/Spinner";
import "../../../components/admin/css/common.css";
import { history } from "../../../helper/history";
import debounce from "lodash.debounce";
import { UserManagementService } from "../../../service/user_management/user_management.service";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination as Pagination,
} from "@mui/material";
import sweetAlert, {
  sweetStatusAlert,
} from "../../../components/common/SweetAlert";
import { TeacherService } from "../../../service/manage/registration/teacher.service";
import SimpleReactValidator from "simple-react-validator";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import { roleService } from "../../../service/user_management/role.service";
import { StaffService } from "../../../service/user_management/staff.service";
import config from '../../../helper/config';
import { customerConstants } from "../../../helper/constants.js";
import DatePicker from "react-datepicker";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

export default class AddTeacherModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      teachers: [],
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      genders: [],
      titles: [],
      countries: [],
      provinces: [],
      cities: [],
      adm_statuses: [],
      statusTypes: [],
      AdmStatustype: "Availability",
      showInsertModal: false,
      isLoading: false,
      isTableDataLoading: true,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      totalTeachers: 10,
      currentPage: 0,
      isModified: false,
      isAddressesLoading: false,
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      sortColumn: "user_code", // Default sort column
      sortDirection: "desc",
      errorProvince: [],
      errorCity: [],
      addressValidity: [],
      hidden: false,
      node_mid: null,
      user_list_table_id: null,
      session_adm_id: null,
      nic: "",
      country_id: "",
      province_id: "",
      city_id: "",
      address_1: "",
      address_2: "",
      postalcode: "",
      user_id: "",
      is_lg_user: "",
      user_name: "",
      breadcrumb2: null,
      nodeTitle: null,
      main_table_id: null,
      user_code: "",
      showPrivModel: false,
      roles: [],
      role_code: null,
      assignedRoles: [],
      isPrivModalLoading: false,
      privedTeacher: null
    };

    this.createValidationsInstance();
    this.formInitialState = {
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      postalcode: "",
    };
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        address: {
          message: "The :attribute must be a valid address",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/:0-9,\s]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
        },
        dateBefore: {
          message: "The :attribute must be before current date",
          rule: (val, params, validator) => {
            const selectedDate = new Date(val)
            const currentDate = new Date()
            return selectedDate < currentDate;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        phone_number: {
          message: "The :attribute must be a valid phone number",
          rule: (val, params, validator) => {
            const isTenDigitsStartingWithZero =
              val.length === 10 && val.startsWith("0");
            const isNineDigits = val.length === 9 && !val.startsWith("0");
            return isTenDigitsStartingWithZero || isNineDigits;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
      },
    });
    this.updateValidator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.loadInitialData();
    this.genarateStudentIdPrefix()
  }

  genarateStudentIdPrefix() {

    let id_prefix;

    if (this.props.state && this.props.state.mcode) {
      id_prefix = this.props.state.mcode;
    } else {
      id_prefix = "ED";
    }

    const id_prefix_year = String(new Date().getFullYear() % 100)

    const user_base_prefix = id_prefix + id_prefix_year + "T"

    this.setState({ user_code: user_base_prefix })

  }


  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  loadInitialData() {

    this.setState(
      {
        user_list_table_id: sessionStorage.getItem("table_log_id"),
        session_adm_id: sessionStorage.getItem("manage_user_id"),
      },
      () => {
        this.getAllGenders();
        this.getAllStatusTypes();
        this.getAllContries();
        this.getAllRoles()
      }
    );
  }

  getAllRoles() {
    roleService.getAll().then(res => {
      this.setState({ roles: res.data });
    })
  }

  getAllContries() {
    CountryService.getAll().then((res) => {
      this.setState({ countries: res.data });
    });
  }

  getAllGenders() {
    GenderService.getAllGenders().then((res) => {
      this.setState({ genders: res.data });
    });
  }

  getAllStatusTypes() {
    StatusTypesService.getAll().then((res) => {
      this.setState({ statusTypes: res.data });
      this.getAllStatuses();
    });
  }

  getAllStatuses() {
    const adm_type_id = this.getRespectiveStatusTypeID(
      this.state.AdmStatustype
    );
    StatusService.getByTypeId(adm_type_id).then((res) => {
      this.setState({ adm_statuses: res.data });
    });
  }

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllTeachers();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllTeachers();
      }
    );
  };

  handleSortChange = (sortColumn) => {
    // If the same column is clicked, toggle the sort direction
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.fetchAllTeachers();
      }
    );
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(this.formInitialState);
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
        this.toggleAddTeacherModal()
        toggleFunction();
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      this.setState(this.formInitialState);
      this.toggleAddTeacherModal()
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
    if (name === "country_id") {
      const country_id = value;
      this.setState({
        cities: [],
        provinces: [],
        city_id: "",
        province_id: "",
      });
      this.getProvinceForCountry(country_id);
    }
    if (name === "province_id") {
      const province_id = value;
      this.setState({ cities: [], city_id: "" });
      this.getCitiesForProvince(province_id);
    }
  };
  
  
  handleDatePickerChange = (value) => {
    const dateObject = value ? new Date(value): undefined;
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = ("0" + dateObject.getDate()).slice(-2);

    const formattedDateString = `${year}-${month}-${day}`;
    this.setState(() => ({ dob: formattedDateString, isModified: true}));
  };

  getProvinceForCountry = (country_id) => {
    if (country_id) {
      ProvinceService.getProvinceForCountry(country_id).then((response) => {
        if (response.success) {
          this.setState({ provinces: response.data });
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      });
    }
  };

  getCitiesForProvince = (province_id) => {
    if (province_id) {
      CityService.getCitiesForProvince(province_id).then((response) => {
        if (response.success) {
          this.setState({ cities: response.data });
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      try {
        const {
          user_id,
          first_name,
          last_name,
          email,
          phone_number,
          dob,
          gender_id,
          created_on,
          created_by,
          status_id,
          node_mid,
          country_id,
          province_id,
          city_id,
          address_1,
          address_2,
          postalcode,
          main_table_id,
          user_code,
          nic
        } = this.state;

        const teacherItem = {
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone_number: parseInt(phone_number),
          dob: !!dob ? dob : null,
          gender_id: parseInt(gender_id),
          user_status_id: parseInt(status_id),
          country_id: parseInt(country_id),
          province_id: parseInt(province_id),
          city_id: parseInt(city_id),
          address_1: address_1,
          address_2: address_2,
          postalcode: parseInt(postalcode),
          create_user_table_id: sessionStorage.getItem("table_log_id"),
          create_user_id: sessionStorage.getItem("manage_user_id"),
          user_table_id:!!this.props.state && this.props.state.main_table_id
            ? this.props.state.main_table_id
            : 10,
          node_mid: !!this.props.state && !!this.props.state.mid
            ? this.props.state.mid
            : 100,
          user_code: user_code,
          nic: nic
        };
        const teacherResponse = await TeacherService.create(
          JSON.stringify(teacherItem)
        );
        if (teacherResponse.success) {

          if (this.props.fetchAllTeachers) {
            this.props.fetchAllTeachers()
          }

          this.validator.purgeFields();
          this.validator.hideMessages();
          this.setState(this.formInitialState)

          try {
            this.setState({ isLoading: false, showInsertModal: false });
            sweetStatusAlert(
              "Success!",
              "Teacher added successfully..!",
              "success"
            );
            this.props.toggleAddTeacherModal()
            this.validator.purgeFields();
            this.validator.hideMessages();
            this.setState(this.formInitialState);
          } catch (error) {
            this.setState({ isLoading: false });
            sweetStatusAlert("Failed!", "Failed to create new teacher", "error");
          }

        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", teacherResponse.message, "error");
        }
      } catch (e) {
        this.setState({ isLoading: false });
        sweetStatusAlert("Failed!", "Failed to create new teacher", "error");
      }
    }
  };

  getRespectiveStatusTypeID(name) {
    const type = this.state.statusTypes.find((item) => item.name === name);
    return type ? type.type_id : "";
  }


  getRespectiveRoleCode(role_code) {
    const roleData = this.state.roles.find((role) => role.role_code === role_code);
    return roleData
  }

  handleCreateRole = async () => {
    if (this.state.assignedRoles.length > 0) {
      const teacherUserRoleData = {
        user_id: this.state.assignedRoles[0].user_id,
        module: 'Manage ' + this.getRespectiveRoleCode(this.state.role_code).role_code,
        role_code: this.getRespectiveRoleCode(this.state.role_code).role_code
      };
      try {
        await UserManagementService.createStaffRole(
          JSON.stringify(teacherUserRoleData)
        );
        await StaffService.searchStaffByTableAndUserIds(this.state.privedTeacher.user_id, this.state.main_table_id).then((res) => {
          this.setState(() => ({
            assignedRoles: res.data
          }))
        })
      } catch (error) {
        sweetStatusAlert("Failed", error, "error");
      }
    } else {
      sweetStatusAlert("Failed", 'Cant able to Add Role', "error");
    }
  }


  handleDeleteRoleCode = async (teacherRole) => {
    try {
      await UserManagementService.deleteRoleCode(teacherRole.role_code, teacherRole.user_id)
      await StaffService.searchStaffByTableAndUserIds(this.state.privedTeacher.user_id, this.state.main_table_id).then((res) => {
        this.setState(() => ({
          assignedRoles: res.data
        }))
      })
    } catch (error) {

    }
  }

  getRespectiveRoleCode(role_code) {
    const roleData = this.state.roles.find((role) => role.role_code === role_code);
    return roleData
  }

  handleCreateRole = async () => {
    if (this.state.assignedRoles.length > 0) {
      const teacherUserRoleData = {
        user_id: this.state.assignedRoles[0].user_id,
        module: 'Manage ' + this.getRespectiveRoleCode(this.state.role_code).role_code,
        role_code: this.getRespectiveRoleCode(this.state.role_code).role_code
      };
      try {
        await UserManagementService.createStaffRole(
          JSON.stringify(teacherUserRoleData)
        );
        await StaffService.searchStaffByTableAndUserIds(this.state.privedTeacher.user_id, this.state.main_table_id).then((res) => {
          this.setState(() => ({
            assignedRoles: res.data,
          }))
        })
      } catch (error) {
        sweetStatusAlert("Failed", error, "error");
      }
    } else {
      sweetStatusAlert("Failed", 'Cant able to Add Role', "error");
    }
  }

  handleDeleteRoleCode = async (teacherRole) => {
    try {
      await UserManagementService.deleteRoleCode(teacherRole.role_code, teacherRole.user_id)
      await StaffService.searchStaffByTableAndUserIds(this.state.privedTeacher.user_id, this.state.main_table_id).then((res) => {
        this.setState(() => ({
          assignedRoles: res.data,
        }))
      })
    } catch (error) {

    }
  }

  toggleAddTeacherModal = () => {
    this.setState(prevState => ({
      showInsertModal: !prevState.showInsertModal,
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      address_1: "",
      address_2: "",
      city_id: "",
      province_id: "",
      postalcode: "",
      country_id: "",
    }), () => {
    });
  };

  render() {
    const {
      isLoading,
      countries,
      limit,
      teachers,
      totalTeachers,
      isTableDataLoading,
      currentPage,
      first_name,
      last_name,
      nic,
      email,
      phone_number,
      dob,
      gender_id,
      created_on,
      created_by,
      status_id,
      genders,
      adm_statuses,
      editVisibility,
      deleteVisibility,
      country_id,
      province_id,
      city_id,
      address_1,
      address_2,
      postalcode,
      provinces,
      cities,
      user_name,
      breadcrumb2,
      nodeTitle,
      addVisibility,
      user_code,
      roles,
      role_code,
      assignedRoles,
      isPrivModalLoading,
    } = this.state;
    const { isAddTeacherModalOpen, toggleAddTeacherModal } = this.props;
    this.validator.purgeFields();
    return (
      <>
        <>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={isAddTeacherModalOpen}
            onHide={() =>
              this.handleSweetAlert(
                toggleAddTeacherModal,
                "showInsertModal"
              )
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Create New Teacher</Modal.Title>
            </Modal.Header>
            <div>
              <Modal.Body>

                {isLoading ? (
                  <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <i className="fas fa-spinner fa-spin"></i>
                  </div>
                ) : (
                  <Form>

                    <Row>
                      <Modal.Title className="pl-4 mb-4">
                        Personal Details
                      </Modal.Title>
                    </Row>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="first_name"
                          value={first_name}
                          placeholder="First Name"
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "first_name",
                          first_name,
                          "required|alpha_space|min:2|max:15",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The teacher first name is required.",
                            },
                          }
                        )}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                          type="text"
                          name="last_name"
                          value={last_name}
                          placeholder="Last Name"
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "last_name",
                          last_name,
                          "required|alpha_space|min:2|max:15",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The teacher last name is required.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>NIC*</Form.Label>
                        <Form.Control
                          type="text"
                          name="nic"
                          placeholder="NIC"
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "nic",
                          nic,
                          "required|min:8",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The teacher nic is required.",
                            },
                          }
                        )}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>DOB*</Form.Label>
                        <div className="col-md-12 px-0">
                          <DatePicker
                            className="py-2 form-control w-100 col-md-12" // Add Bootstrap form control class
                            selected={this.state.dob ? new Date(this.state.dob):undefined}
                            name="dob"
                            onChange={this.handleDatePickerChange}
                            dateFormat="dd/MM/yyyy" // Set custom date format
                            placeholderText="Date of Birth"
                            wrapperClassName="react-datePicker"
                          />
                        </div>
                        {this.validator.message(
                          "dob",
                          dob,
                          "required|dateBefore",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The date of birth is required.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>Email*</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={email}
                          placeholder="example@gmail.com"
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "email",
                          email,
                          "required|email",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The email is required.",
                            },
                          }
                        )}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>Gender*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="gender_id"
                          value={gender_id}
                          onChange={this.handleChange}
                          defaultValue={""}
                        >
                          <option hidden value="">Select</option>
                          {genders.map((gender) => (
                            <option
                              key={gender.gender_id}
                              value={gender.gender_id}
                            >
                              {gender.name}
                            </option>
                          ))}
                        </Form.Select>
                        {this.validator.message(
                            "gender_id",
                            gender_id,
                            "required",
                            {
                            className: "text-danger",
                            messages: {
                                required: "The gender is required.",
                            },
                            }
                        )}
                      </Form.Group>
                    </Row>


                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>Phone Number*</Form.Label>
                        <Form.Control
                          type="text"
                          name="phone_number"
                          value={phone_number}
                          placeholder="Phone Number"
                          onChange={this.handleChange}
                        />
                        {this.validator.message(
                          "phone_number",
                          phone_number,
                          "required|numeric|between:9,10|phone_number",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The phone number is required.",
                            },
                          }
                        )}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Status*</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="status_id"
                          value={status_id}
                          onChange={this.handleChange}
                          defaultValue={""}
                        >
                          <option hidden value="">Select</option>
                          {adm_statuses.map((status) => (
                            <option
                              key={status.status_id}
                              value={status.status_id}
                            >
                              {status.name}
                            </option>
                          ))}
                        </Form.Select>
                        {this.validator.message(
                          "status_id",
                          status_id,
                          "required",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The status is required.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>

                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Teacher ID Prefix</Form.Label>
                        <Form.Control
                          type="text"
                          name="user_code"
                          value={user_code ? user_code : ""}
                          placeholder="Teacher ID"
                          disabled
                        />
                        {this.validator.message(
                          "user_code",
                          user_code,
                          "required",
                          {
                            className: "text-danger",
                            messages: {
                              required: "The teacher ID is required.",
                            },
                          }
                        )}
                      </Form.Group>
                    </Row>

                    <Row className="pt-5">
                      <Modal.Title className="pl-4 mb-4">
                        Residential Details
                      </Modal.Title>
                    </Row>

                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Address Line 1</Form.Label>
                        <Form.Control
                          type="text"
                          name="address_1"
                          placeholder="Address 1"
                          onChange={this.handleChange}
                          value={address_1}
                        />
                        {/* {this.validator.message(
                            "address_1",
                            address_1,
                            "address|required",
                            {
                            className: "text-danger",
                            messages: {
                                required: "The Address 1 is required.",
                                address: "The address 1 must be a valid address",
                            },
                            }
                        )} */}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Address Line 2</Form.Label>
                        <Form.Control
                          type="text"
                          name="address_2"
                          placeholder="Address 2"
                          onChange={this.handleChange}
                          value={address_2}
                        />
                        {/* {this.validator.message(
                            "address_2",
                            address_2,
                            "address|required",
                            {
                            className: "text-danger",
                            messages: {
                                required: "The Address 2 is required.",
                                address: "The address 2 must be a valid address",
                            },
                            }
                        )} */}
                      </Form.Group>

                    </Row>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Label>Country</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="country_id"
                          onChange={this.handleChange}
                          value={country_id}
                        >
                          <option value="">Select</option>
                          {countries.map((country) => (
                            <option
                              value={country.country_id}
                              key={country.country_id}
                            >
                              {country.name}
                            </option>
                          ))}
                        </Form.Select>
                        {/* {this.validator.message(
                            "country_id",
                            country_id,
                            "required",
                            {
                            className: "text-danger",
                            messages: {
                                required: "The Country is required.",
                            },
                            }
                        )} */}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>Province/District</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="province_id"
                          onChange={this.handleChange}
                          value={province_id}
                        >
                          <option value="">Select</option>
                          {provinces?.map((province) => (
                            <option
                              value={province.province_id}
                              key={province.province_id}
                            >
                              {province.name}
                            </option>
                          ))}
                        </Form.Select>
                        {/* {this.validator.message(
                            "province_id",
                            province_id,
                            "required",
                            {
                            className: "text-danger",
                            messages: {
                                required: "The Province is required.",
                            },
                            }
                        )} */}
                      </Form.Group>
                    </Row>
                    <Row className="my-5">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>City</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="city_id"
                          onChange={this.handleChange}
                          value={city_id}
                        >
                          <option value="">Select</option>
                          {cities?.map((city) => (
                            <option value={city.city_id} key={city.city_id}>
                              {city.name}
                            </option>
                          ))}
                        </Form.Select>
                        {/* {this.validator.message("city_id", city_id, "required", {
                            className: "text-danger",
                            messages: {
                            required: "The City is required.",
                            },
                        })} */}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          type="text"
                          name="postalcode"
                          placeholder="Postal Code"
                          onChange={this.handleChange}
                          value={postalcode}
                        />
                        {/* {this.validator.message(
                            "postalcode",
                            postalcode,
                            "numeric|required",
                            {
                            className: "text-danger",
                            messages: {
                                required: "The postal code is required.",
                                numeric: "The postal code must be a number",
                            },
                            }
                        )} */}
                      </Form.Group>
                    </Row>

                    <Modal.Footer>
                        <Button
                          className="mb-4"
                          variant="primary"
                          style={addBtnStyle}
                          onClick={this.handleSubmit}
                        >
                        Save changes
                        </Button>
                        <Button
                          className="mb-4"
                          id="modal-close-button"
                          style={closeBtnStyle}
                          onClick={() =>
                              this.handleSweetAlert(
                              toggleAddTeacherModal,
                              "showInsertModal"
                              )
                          }
                        >
                          Close
                        </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Modal.Body>
            </div>
          </Modal>
        </>
      </>
    );
  }
}