import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const EnrolledPaperService = {
  addEnrolledPaperToNode,
  enrollPaperToNode,
  getAllEnrolledPaperForNode,
  getEnrollForStudent,
  getAllEnrolledPapers,
  selfenrollPaperToNode
};

async function addEnrolledPaperToNode(enrolled_paper_node_data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrolled_paper_node_data,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/enrolled-papers/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function enrollPaperToNode(enrolled_paper_node_data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrolled_paper_node_data,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/enrolled-papers/enroll`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function selfenrollPaperToNode(enrolled_paper_node_data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: enrolled_paper_node_data,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/enrolled-papers/self-enroll`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllEnrolledPaperForNode(node_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/enrolled-papers/getAll/${node_id}`,
    requestOptions
  );

  return APIHandler.handleResponse(response);
}

async function getEnrollForStudent(node_id, enroll_user_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/enrolled-papers/getEnrollForStudent/${node_id}/${enroll_user_id}`,
    requestOptions
  );

  return APIHandler.handleResponse(response);
}

async function getAllEnrolledPapers(
  node_id,
  create_user_id,
  create_user_table_id,
  enroll_user_id,
  enroll_user_table_id,
  userType
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/enrolled-papers/getAllEnrolledPapers/${node_id}?cr_u_id=${create_user_id}&cr_u_t_id=${create_user_table_id}&en_u_id=${enroll_user_id}&en_u_t_id=${enroll_user_table_id}&type=${userType}`,
    requestOptions
  );

  return APIHandler.handleResponse(response);
}
