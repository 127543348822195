import React, { Component } from "react";
import { connect } from "react-redux";
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import CardBody from '../../../components/common/CardBody';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import TabList from '../../../components/common/tabs/TabList';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { quesDifficultyActions } from "../../../actions/settings/question/difficultyAction.js"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config.js";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class QuestionDifficulty extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newDifficulty:null,
        difficultyRowsPerPage:5,
        currentDifficultyPage: 0,
        difficultyData: [],
        difficulty_sort_column: "difficulty_id",
        difficulty_sort_direction: "ASC",
        selectDifficulty: '',
        editDifficulty: false,
        difficulty: '',
        limit: 10,
        difficulty_search_term: "",
        difficulty_offset: 0,
        searchError: false,
        difficulty_limit:5,
        difficultyId: '',
        difficultyOptionsImport: [],
        selectedDifficulty: []
      };
      
      this.onNewDifficultyInput = this.onNewDifficultyInput.bind(this);
      this.onAddNewDifficulty = this.onAddNewDifficulty.bind(this);
      this.generateDifficultyOptions = this.generateDifficultyOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllQuestionDifficulties();
    }
    
    onNewDifficultyInput(e) {
        this.setState({
            newDifficulty: e.target.value
        });
    }
    
    async onAddNewDifficulty(e) {
        if (this.state.newDifficulty && this.state.newDifficulty.trim()) {
            await this.props.createDifficulty({ 'difficulty_type': this.state.newDifficulty })
            if (this.props.successdifficultycreate) {
                this.setState(
                    {
                        difficulty_offset: 0,
                        currentDifficultyPage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllQuestionDifficulties();
                        this.generateDifficultyOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new difficulty!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-category').modal('hide')
                                window.$("#question-category").val('default');
                                window.$('#question-category').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errordifficultyupdate) {
                window.Swal.fire({
                    title: 'Failed to add a new difficulty!',
                    text: this.props.errordifficultyupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty difficulty!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newDifficulty: ''
        });
    }
    
    handleDifficultySearch = (event) => {
        const difficulty_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (difficulty_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (difficulty_search_term === "" || regex.test(difficulty_search_term)) {
            this.setState(
                {
                    difficulty_search_term,
                    difficulty_offset: 0,
                    currentDifficultyPage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllQuestionDifficulties();
                }
            );
        } else {
            this.setState({ difficulty_search_term, searchError: true });
        }
    };
    
    fetchAllQuestionDifficulties = async () => {
        const {
            difficulty_limit,
            difficulty_offset,
            difficulty_sort_column,
            difficulty_sort_direction,
            difficulty_search_term
        } = this.state;
        await this.props.getAllQuestionDifficulties(difficulty_limit, difficulty_offset, difficulty_sort_column, difficulty_sort_direction, difficulty_search_term);
        this.setState({ difficulty_limit: this.state.difficultyRowsPerPage });
        this.setState({ difficultyData: this.props.allQuestionDifficulties })
    };
    
    handleDifficultyPageChange = (event, newDifficultyPage) => {
        const {difficultyRowsPerPage} = this.state;
        const newDifficultyOffset = newDifficultyPage * difficultyRowsPerPage;
        this.setState(
        {
            difficulty_limit: difficultyRowsPerPage,
            difficulty_offset: newDifficultyOffset,
            currentDifficultyPage: newDifficultyPage
        },
        () => {
            this.fetchAllQuestionDifficulties();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {difficultyRowsPerPage} = this.state;
        this.setState(
        {
            difficulty_limit: difficultyRowsPerPage,
            difficulty_offset: newOffset,
            currentDifficultyPage: newPage
        },
        () => {
            this.fetchAllQuestionDifficulties();
        }
        );
    };

    //handle the data fetching as per the data per page limit gets changes
    handleDifficultyRowsPerPageChange = (event) => {
        const newDifficultyLimit = parseInt(event.target.value, 10);
        this.setState({difficultyRowsPerPage: newDifficultyLimit});
        const newDifficultyPage = Math.floor(this.state.difficulty_offset / newDifficultyLimit)
        this.setState(
        {
            difficulty_limit: newDifficultyLimit,
            difficulty_offset: newDifficultyLimit * newDifficultyPage,
            currentDifficultyPage: newDifficultyPage
        },
        () => {
            this.fetchAllQuestionDifficulties();
        }
        );
    };

    handleSortDifficultyChange = (difficulty_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { difficulty_sort_column: currentDifficultySortColumn, difficulty_sort_direction } = this.state;
        const newDifficultySortDirection =
        currentDifficultySortColumn === difficulty_sort_column && difficulty_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            difficulty_sort_column,
            difficulty_sort_direction: newDifficultySortDirection,
            difficulty_offset: 0,
            currentDifficultyPage: 0,
        },
        () => {
            this.fetchAllQuestionDifficulties();
        }
        );
    }

    getQuestionDifficulty(difficulty_id) {
        let difficultyname
        this.props.allQuestionDifficulties.map(difficulty => {
            if (difficulty.difficulty_id === difficulty_id) {
                return difficultyname = difficulty.difficulty_type
            }
        })
        return difficultyname
    }
    
    onEditDifficulty(e, el) {
        this.setState({
            difficultyId: el.difficulty_id,
            difficulty: e.target.value
        })
    }
    
    async onUpdateDifficulty(e) {
        e.preventDefault()
        var obj = {
            difficulty_id: this.state.difficultyId,
            difficulty_type: this.state.difficulty
        }
        this.setState({ selectDifficulty: '' })
        if (!this.state.difficulty.trim()) {
            window.Swal.fire({
                title: 'Failed to update difficulty!',
                text: 'Difficulty cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateDifficulty(obj);
            if (this.props.successdifficultyupdate) {
                // await this.props.getAllQuestionDifficulties(difficulty_limit, difficulty_offset, difficulty_sort_column, difficulty_sort_direction, difficulty_search_term);
                this.fetchAllQuestionDifficulties()
                this.generateDifficultyOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated difficulty!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });
            }
            if (this.props.errordifficultyupdate) {
                window.Swal.fire({
                    title: 'Failed to update difficulty!',
                    text: this.props.errordifficultyupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        }
    }

    generateDifficultyOptions() {
        let difficultyOptionsImport = []
        this.props.allQuestionDifficulties.map((el, i) => {
            difficultyOptionsImport.push(<Option key={el.difficulty_id} optionName={el.difficulty_type} value={el.difficulty_id} />)
        });

        this.setState({
            difficultyOptionsImport: difficultyOptionsImport,
            selectedDifficulty: difficultyOptionsImport[0].props.value
        });

        if (difficultyOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditDifficulty(el, i, e) {
        e.preventDefault()
        this.setState({
            editDifficulty: true,
            selectDifficulty: i,
            difficultyId: el.difficulty_id,
            difficulty: el.difficulty_type
        })
    }

    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the difficulty?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteDifficulty(el);
                if (this.props.successdifficultydelete) {
                    this.fetchAllQuestionDifficulties()
                    this.generateDifficultyOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted difficulty!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });
                }
                if (this.props.errordifficultydelete) {
                    window.Swal.fire({
                        title: 'Failed to delete difficulty!',
                        text: this.props.errordifficultydelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-difficulty').modal('hide')
                            window.$("#question-difficulty").val('default');
                            window.$('#question-difficulty').selectpicker('refresh');
                        });

                }
            }
        })
    }
    
    syncTableDifficulty() {
        return (
            <>
                {this.state.difficultyData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortDifficultyChange("difficulty_id")}
                                >
                                    No{" "}
                                    {this.state.difficulty_sort_column === "difficulty_id" && (
                                    <span>{this.state.difficulty_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortDifficultyChange("difficulty_type")}
                                >
                                    Difficulty Name{" "}
                                    {this.state.difficulty_sort_column === "difficulty_type" && (
                                    <span>{this.state.difficulty_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.difficultyData.map((el, i) => {
                                if (i === this.state.selectDifficulty) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editDifficulty ? 
                                                this.getQuestionDifficulty(el.difficulty_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.difficulty}
                                                    onChange={(e) => this.onEditDifficulty(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateDifficulty(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Difficulty" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getQuestionDifficulty(el.difficulty_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditDifficulty(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Difficulty" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete" 
                                                    title="Delete Difficulty" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Difficulties Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3>Manage Difficulties</h3>
              </div>

              <CardBody>
                <div className="row justify-content-end">
                  <div
                    className="table-wrapper-scroll-y my-custom-scrollbar"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <TabList>
                      <QuestionAccordion
                        labelName="Create New Difficulty"
                        onChange={this.onNewDifficultyInput}
                        onClick={this.onAddNewDifficulty}
                        val={this.state.newDifficulty}
                      />
                    </TabList>
                    <div className="row justify-content-end col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleDifficultySearch}
                          className="col-md-18 col-18"
                        />
                      </Form.Group>
                    </div>
                    <TabContent>
                      <TabPane
                        className="tab-pane fade show active"
                        id="manage-1"
                        ariaLabelledby="manage-tab-1"
                      >
                        {/* begin: Datatable */}
                        {this.props.cat_loading ? (
                          <div>
                            <Table sx={{ width: "75vw" }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Difficulty Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                            <div className="loading-container mb-5 mt-10">
                              <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </ReactSpinner>
                            </div>
                          </div>
                        ) : this.state.difficultyData.length > 0 ? (
                          this.syncTableDifficulty()
                        ) : (
                          <div style={{ width: "75vw", overflowX: "scroll" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Difficulty Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {/* end: Datatable */}
                            </Table>
                            <div className="text-center mb-5 mt-10">
                              <label>No Difficulties Available!</label>
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                            totalCount = {this.props.totalDifficultiesCount}
                            currentPage = {this.state.currentDifficultyPage}
                            limit = {this.state.difficultyRowsPerPage}
                            handlePageChange = {this.handleDifficultyPageChange}
                            handleRowsPerPageChange = {this.handleDifficultyRowsPerPageChange}
                            handlePageOffsetChange = {this.handlePageOffsetChange}
                            offset = {this.state.difficulty_offset}
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalDifficultiesCount: state.difficultyReducer.totalDifficultiesCount,//
        cat_loading:state.difficultyReducer.loading,
        error: state.difficultyReducer.error,//
        // totalCount: state.questionsLibraryReducer.totalCount,//
        
        errordifficultydelete: state.difficultyReducer.errordifficultydelete,
        successdifficultydelete: state.difficultyReducer.successdifficultydelete,

        errordifficultyupdate: state.difficultyReducer.errordifficultyupdate,
        successdifficultyupdate: state.difficultyReducer.successdifficultyupdate,//
        
        errordifficultycreate: state.difficultyReducer.errordifficultycreate,
        successdifficultycreate: state.difficultyReducer.successdifficultycreate,//

        allQuestionDifficulties: state.difficultyReducer.allQuestionDifficulties,//
        loading: state.difficultyReducer.loading,//
    }
}

    const mapActionsToProps = {
        createDifficulty: quesDifficultyActions.createDifficulty,
        getAllQuestionDifficulties: quesDifficultyActions.getAllQuestionDifficulties,
        updateDifficulty: quesDifficultyActions.updateDifficulty,
        deleteDifficulty: quesDifficultyActions.deleteDifficulty,
    }

    export default connect(mapStateToProps, mapActionsToProps)(QuestionDifficulty);

