import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const TypeService = {
    createType,
    getAllTypes,
    updateType,
    deleteType,
    getAllActiveTypes
};

async function createType(typeData) { 
    const requestOptions = {
        method: "POST",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: typeData,  
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/types/add`,  
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllTypes(node_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/types/getAllTypes/${node_id}`,  
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function updateType(typeData, type_id) {
    const requestOptions = {
        method: "PUT",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: typeData,
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/types/updateType/${type_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function deleteType(type_id, form_list_id) {
    const configOptions = {
        method: "DELETE",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/types/deleteType/${type_id}/${form_list_id}`,
        configOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllActiveTypes(node_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/types/getAllActiveTypes/${node_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}