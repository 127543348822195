import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputAdornment from "@mui/material/InputAdornment";
import SimpleReactValidator from "simple-react-validator";
import { LimitService } from "../../../service/settings/manage/limit.service.js";
import { reportTitleService } from "../../../service/manage/mark_sheet/marksheetreport.service.js";
import { Spinner } from "react-bootstrap";
import {
  sweetErrorAlert,
  sweetStatusAlert,
  sweetSuccessAlert,
} from "../../../components/common/SweetAlert";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

class ManageReportTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: false,
      isLoading: false,
      addTitleModal: false,
      updateTitleModal: false,
      title: null,
      updateTitle: null,
      selectedTitle: null,
      titles: [],
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getAllTitle();
  }

  getAllTitle = () => {
    this.setState({ isTableDataLoading: true });
    const { mid} =
      this.props.props;
    reportTitleService.getAllReportTitle(mid).then((response) => {
      if (response.success) {
        this.setState({
          titles: response.data.report_title,
          isTableDataLoading: false,
        });
      } else {
        this.setState({ isTableDataLoading: false });
      }
    });
  };

  toggleupdateTitleModal = (title) => {
    const { updateTitleModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessages();
    if (!updateTitleModal) {
      this.setState({
        updateTitle: title.title,
        selectedTitle: title.id,
        updateTitleModal: true,
      });
    } else {
      this.setState({
        updateTitle: null,
        selectedTitle: null,
        updateTitleModal: false,
      });
    }
  };

  handleEditSubmit = () => {
    const { updateTitle, selectedTitle } = this.state;
    const { mid } = this.props.props;

    if (!this.validator.fieldValid("updateTitle")) {
      this.validator.showMessageFor("updateTitle");
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      this.setState({ updateTitleModal: false });
      const newUpdateTitle = {
        title: updateTitle,
        node_id: mid
      };
      reportTitleService
        .updateReportTitle(newUpdateTitle, selectedTitle)
        .then((response) => {
          if (response.success) {
            sweetSuccessAlert(
              "Success",
              "title updated successfully",
              "success"
            );
            this.setState({ isLoading: false });
            this.setState({ updateTitleModal: false });
            this.getAllTitle();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Oops", response.message.error, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while updating title", "error");
        });
    }
  };

  handleDeleteSubmit = (selectedTitle) => {
    this.setState({ isLoading: true });
    reportTitleService
      .deleteReportTitle(selectedTitle)
      .then((response) => {
        if (response.success) {
          sweetSuccessAlert("Success", "Title deleted successfully", "success");
          this.setState({ isLoading: false });
          this.getAllTitle();
        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Oops", response.message.error, "info");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        sweetErrorAlert("Error", "Error while deleting title", "error");
      });
  };
  handleTitleChange = (e) => {
    const { name, value } = e.target;
    this.setState(() => ({ [name]: value }));
  };

  toggleaddTitleModal = () => {
    const { addTitleModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessageFor("title");
    this.setState({
      addTitleModal: !addTitleModal,
      title: "",
    });
  };

  handleSubmit = () => {
    const { title } = this.state;
    const { mid } = this.props.props;

    if (!this.validator.fieldValid("title")) {
      this.validator.showMessageFor("title");
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      this.setState({ addTitleModal: false });
      const newtitle = {
        title: title,
        node_id: mid,
      };
      reportTitleService
        .addReportTitle(newtitle)
        .then((response) => {
          if (response.success) {
            this.setState({ isLoading: false });
            this.toggleaddTitleModal();
            sweetSuccessAlert(
              "Success",
              "A new Report Title added successfully",
              "success"
            );
            this.setState({ addTitleModal: false });
            this.setState({ isLoading: false });
            this.getAllTitle();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Info", response.message.error, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while adding title", "error");
        });
    }
  };

  render() {
    const {
      addTitleModal,
      title,
      updateTitleModal,
      isTableDataLoading,
      isLoading,
      titles,
      updateTitle,
    } = this.state;

    return (
      <>
        <div className="row justify-content-between align-items-center py-5 mb-3">
          <div className="col-md-6 col-6">
            <h3 className="text-nowrap">Manage Report Title</h3>
          </div>

          <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleaddTitleModal}
          >
            Add New Title
          </Button>
        </div>
        {isLoading && (
          <PageSpinner id="show" text="Loading, Please wait!" />
        )}
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead">Report Title</TableCell>
                  <TableCell className="thead" align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {titles.length > 0 ? (
                  titles.map((title) => (
                    <TableRow key={title.id}>
                      <TableCell className="tData">{title.title}</TableCell>
                      <TableCell className="tData" align="right">
                        <div>
                          <Button
                            onClick={() => this.toggleupdateTitleModal(title)}
                            className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                            title="Edit Details"
                          />
                          <Button
                            className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                            title="Delete Details"
                            sx={{ color: "red" }}
                            onClick={() => this.handleDeleteSubmit(title.id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>No Report Titles found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateTitleModal}
          onHide={this.toggleupdateTitleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Title</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : ( */}
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                  <Form.Label>Title*</Form.Label>
                  <Form.Control
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      name="updateTitle"
                      value={updateTitle ? updateTitle : ""}
                      onChange={this.handleTitleChange}
                    />
                    {this.validator.message(
                      "updateTitle",
                      updateTitle,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            {/* )} */}
            <Modal.Footer>
              <Button
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
                sx={{ mr: "20px" }}
                onClick={this.toggleupdateTitleModal}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={() => this.handleEditSubmit()}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={addTitleModal}
          onHide={this.toggleaddTitleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Report Title</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Title*</Form.Label>
                  <Form.Control
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      name="title"
                      type="text"
                      defaultValue={title}
                      onChange={this.handleTitleChange}
                    />
                    {this.validator.message("title", title, "required", {
                      className: "text-danger",
                    })}
                  </Form.Group>
                </Row>
              </Form>
        
            <Modal.Footer>
              <Button
                sx={{ mr: "20px" }}
                onClick={this.toggleaddTitleModal}
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ManageReportTitle;
