import React, { forwardRef } from 'react';

export default function RadioInline ({ className, children }) {
    return (
        <div className={className}>
            <div className="radio-inline col-form-label">
                {children}
            </div>
        </div>
    )
}