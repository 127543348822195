import {quesDifficultyTypes} from '../settingsTypes';

const initialState = {
    allQuestionDifficulties: [],
    loading: false,
    error: '',
    text: '',
    difficulty:'',
    totalDifficultiesCount: 0,
    allDifficulties:[],
    errordifficultydelete:false,
    loadingdifficultydelete:false,
    successdifficultyupdate: false,
    loadingdifficultyupdate: false,
    errordifficultyupdate:false,
    successdifficultydelete:false,
    errordifficultycreate: '',
    successdifficultycreate: false,
}

export function difficultyReducer(state = initialState, action){
    switch (action.type){
        case quesDifficultyTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allQuestionDifficulties: [],
                loading: false,
                error: '',
                text: '',
                difficulty:'',
                totalDifficultiesCount:0,
                allDifficulties:[],
                errordifficultydelete:false,
                loadingdifficultydelete:false,
                successdifficultyupdate: false,
                loadingdifficultyupdate: false,
                errordifficultyupdate:false,
                successdifficultydelete:false,
                errordifficultycreate: '',
                successdifficultycreate: false,
            }
        //Difficulty retrieve request sending
        case quesDifficultyTypes.GET_ALL_QUESTION_DIFFICULTIES:
            return {
                ...state,
                loading: true,
                text: 'Get all the question difficulties'
            }
        case quesDifficultyTypes.GET_ALL_QUESTION_DIFFICULTIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case quesDifficultyTypes.GET_ALL_QUESTION_DIFFICULTIES_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionDifficulties: action.difficulties.difficulty_types,
                totalDifficultiesCount: action.difficulties.total_count
            }
        case quesDifficultyTypes.GET_ALL_QUESTION_DIFFICULTIES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Difficulty creation request sending
        case quesDifficultyTypes.CREATE_DIFFICULTY_REQUEST:
            return {
                ...state,
                errordifficultycreate: '',
                successdifficultycreate: false,
                loading: true
            }
        case quesDifficultyTypes.CREATE_DIFFICULTY_SUCCESS:
            var temp = state.allDifficulties;
            temp.push(action.difficulties)
            return {
                ...state,
                allDifficulties: temp,
                difficulty: action.difficulties.difficulty_id,
                successdifficultycreate: true,
                loading: false
            }
        case quesDifficultyTypes.CREATE_DIFFICULTY_FAILURE:
            return {
                ...state,
                errordifficultycreate: action.error,
                loading: false
            }  
        //Difficulty deletion request sending   
        case quesDifficultyTypes.DELETE_DIFFICULTY_REQUEST:
            return {
            ...state,
            errordifficultydelete: '',
            successdifficultydelete: false,
            loading: true,
            };
        case quesDifficultyTypes.DELETE_DIFFICULTY_SUCCESS:
            return {
            ...state,
            successdifficultydelete: true,
            loading: false,
            };
        case quesDifficultyTypes.DELETE_DIFFICULTY_FAILURE:
            return {
            ...state,
            errordifficultydelete: action.error,
            loading: false,
            };
        //Difficulty updation request sending
        case quesDifficultyTypes.UPDATE_DIFFICULTY_REQUEST:
            return {
            ...state,
            errordifficultyupdate:'',
            successdifficultyupdate: false,
            loading: true,
            };
        case quesDifficultyTypes.UPDATE_DIFFICULTY_SUCCESS:
            return {
            ...state,
            successdifficultyupdate: true,
            loading: false,
            };
        case quesDifficultyTypes.UPDATE_DIFFICULTY_FAILURE:
            return {
            ...state,
            errordifficultyupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
