import { assessmentTypes } from '../types';

const initialState = {
    assessments: [],
    assessment: '',
    allLanguages: [],
    allDifficulty: [],
    allCategories: [],
    allGrades: [],
    allSubjects: [],
    allAuthors: [],
    allYears: [],
    allTerms: [],
    allChapters: [],
    allAttempts: [],
    privilege: [],
    allAssessmentMetaData: [],
    loading: false,
    error: '',
    success: ''
}

export default function (state = initialState, action) {
    switch (action.type) {
        case assessmentTypes.DELETE_ASSESSMENT:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.DELETE_ASSESSMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.DELETE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.message
            }
        case assessmentTypes.DELETE_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.FINALIZE_ASSESSMENT:
            return {
                ...state,
                error: '',
                loading: true
            }
        case assessmentTypes.FINALIZE_ASSESSMENT_REQUEST:
            return {
                ...state,
                error: action.error,
                loading: true
            }
        case assessmentTypes.FINALIZE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: "",
                success:  action.assessment.message.message.success,
                assessment: action.assessment.data
            }
        case assessmentTypes.FINALIZE_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.FINALIZE_ASSESSMENT_REFRESH:
            return {
                ...state,
                error: '',
                success: '',
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_LANGUAGE:
            return {
                ...state,
                loading: true,
                text: 'Get all the languages'
            }
        case assessmentTypes.GET_ASSESSMENT_LANGUAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_LANGUAGE_SUCCESS:
            return {
                ...state,
                allLanguages: action.types.languages,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_LANGUAGE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_PRIVILEGE:
            return {
                ...state,
                loading: true,
                text: 'Get all the privileges'
            }
        case assessmentTypes.GET_ASSESSMENT_PRIVILEGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_PRIVILEGE_SUCCESS:
            return {
                ...state,
                privilege: action.types.privileges,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_PRIVILEGE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_ATTEMPT:
            return {
                ...state,
                loading: true,
                text: 'Get all the attempts'
            }
        case assessmentTypes.GET_ASSESSMENT_ATTEMPT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_ATTEMPT_SUCCESS:
            return {
                ...state,
                allAttempts: action.types.attempts,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_ATTEMPT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_GRADE:
            return {
                ...state,
                loading: true,
                text: 'Get all the grades'
            }
        case assessmentTypes.GET_ASSESSMENT_GRADE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_GRADE_SUCCESS:
            return {
                ...state,
                allGrades: action.types.grades,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_GRADE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_SUBJECT:
            return {
                ...state,
                loading: true,
                text: 'Get all the subjects'
            }
        case assessmentTypes.GET_ASSESSMENT_SUBJECT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_SUBJECT_SUCCESS:
            return {
                ...state,
                allSubjects: action.types.subjects,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_SUBJECT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_AUTHOR:
            return {
                ...state,
                loading: true,
                text: 'Get all the authors'
            }
        case assessmentTypes.GET_ASSESSMENT_AUTHOR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_AUTHOR_SUCCESS:
            return {
                ...state,
                allAuthors: action.types.authors,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_AUTHOR_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_YEAR:
            return {
                ...state,
                loading: true,
                text: 'Get all the years'
            }
        case assessmentTypes.GET_ASSESSMENT_YEAR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_YEAR_SUCCESS:
            return {
                ...state,
                allYears: action.types.years,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_YEAR_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_TERM:
            return {
                ...state,
                loading: true,
                text: 'Get all the terms'
            }
        case assessmentTypes.GET_ASSESSMENT_TERM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_TERM_SUCCESS:
            return {
                ...state,
                allTerms: action.types.terms,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_TERM_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_CHAPTER:
            return {
                ...state,
                loading: true,
                text: 'Get all the chapters'
            }
        case assessmentTypes.GET_ASSESSMENT_CHAPTER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_CHAPTER_SUCCESS:
            return {
                ...state,
                allChapters: action.types.chapters,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_CHAPTER_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_CATEGORY:
            return {
                ...state,
                loading: true,
                text: 'Get all the categories'
            }
        case assessmentTypes.GET_ASSESSMENT_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_CATEGORY_SUCCESS:
            return {
                ...state,
                allCategories: action.types.categories,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_CATEGORY_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_DIFFICULTY:
            return {
                ...state,
                loading: true,
                text: 'Get all the difficulty levels'
            }
        case assessmentTypes.GET_ASSESSMENT_DIFFICULTY_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_DIFFICULTY_SUCCESS:
            return {
                ...state,
                allDifficulty: action.types.difficulty_types,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_DIFFICULTY_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_META_DATA:
            return {
                ...state,
                loading: true,
                text: 'Get all the assessment meta data'
            }
        case assessmentTypes.GET_ASSESSMENT_META_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.GET_ASSESSMENT_META_DATA_SUCCESS:
            return {
                ...state,
                allAssessmentMetaData: action.data,
                loading: false
            }
        case assessmentTypes.GET_ASSESSMENT_META_DATA_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}
