import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const StaffService = {
  searchStaffByEmail,
  statusChangeTerms,
  searchStaffByTableAndUserIds,
  getAllStaffUsers,
  getStaffUserRoles
};

async function searchStaffByEmail(email) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(
      config.azure_ad_config.apis.OAT.name
    ),
  };
  const response = await fetch(
    `${config.oat_api_url}/user/staffs/email/${email}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function statusChangeTerms(id) {
  let requestOptions = {
    method: "PUT",
    headers: APIHandler.getHeader(
      config.azure_ad_config.apis.OAT.name
    ),
  };
  const response = await fetch(
    `${config.oat_api_url}/user/staffs/agree/${id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function searchStaffByTableAndUserIds(userId, table_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    }
  };
  const response = await fetch(
    `${config.oat_api_url}/user/staffs/${table_id}/${userId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllStaffUsers() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/user/staffs/`, requestOptions).then(APIHandler.handleResponse);
}

async function getStaffUserRoles() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    const response = await fetch(`${config.oat_api_url}/user/staff/roles/`, requestOptions);
    return APIHandler.handleResponse(response);
}

