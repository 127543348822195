import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import Card from "../../../components/common/Card.js";
import CardHeader from "../../../components/common/CardHeader.js";
import CardBody from "../../../components/common/CardBody.js";
import Grid from "@mui/material/Grid";
import { Tabs, Tab } from "@mui/material";
import CustomEditor from "../../../pages/manage/enrollment/CustomEditor.js";
import { TreeMainService } from "../../../service/manage/h_tree/tree_main.service.js";
import { history } from "../../../helper/history.js";
import { Row, Col, Spinner, Button, Form, Modal } from "react-bootstrap";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import {
  sweetSuccessAlert,
  sweetStatusAlert,
  sweetConfirmAlert,
} from "../../../components/common/SweetAlert.js";
import SingleChat from "../../../components/chat/SingleChat.js";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { ChatService } from "../../../service/manage/chat/chat.service.js";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ErrorIcon from "@mui/icons-material/Error";
import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { ProgressService } from "../../../service/manage/progress_catchup/progress.service.js";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import SimpleReactValidator from "simple-react-validator";
import Divider from "@mui/material/Divider";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ContactSupportRoundedIcon from "@mui/icons-material/ContactSupportRounded";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import config from "../../../helper/config.js";
import { customerConstants } from "../../../helper/constants.js";
import DatePicker from "react-datepicker";
import { red } from "@material-ui/core/colors";
import { Label } from "semantic-ui-react";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, sliderColor;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    sliderColor = "var(--maincolor)";
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    sliderColor = "var(--maincolor-cmc)";
    break;
  default:
    sliderColor = "var(--maincolor)";
    break;
}

export default class ManageProgress extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
      showProgressCreate: false,
      progress_backDrop: false,
      progress_loading: false,
      progress: this.props?.state,
      progress_details: [],
      start_date: "",
      end_date: "",
      updated_end_date: "",
      date: "",
      steps: [],
      percentage: null,
      comment: "",
      marks: [],
      dayArray: [],
      todayIndex: 0,
      showProgress: false,
      editEndDateModal: false,
      showEditDateModal:false,
      end_date_loading: false,
      node_mid_actual:null
    };
  }

  componentDidMount() {

    let node_mid = this.props.state.node_mid_actual !== null ? this.props.state.node_mid_actual : this.props.state.mid;

    this.setState(
      { progress: this.props?.state, progress_backDrop: true, node_mid_actual:node_mid },
      () => {
        const { progress } = this.state;
        this.getProgressDetails(this.state.node_mid_actual);
      }
    );
  }

  toggleeditEndDateModal = () => {
    const { editEndDateModal, progress_details } = this.state;
    this.setState({
      editEndDateModal: !editEndDateModal,
      updated_end_date:
        progress_details[progress_details.length - 1]?.end_date || "",
    });
  };

  toggleShowEditDate = () => {
    const { showEditDateModal } = this.state;
    this.setState({
      showEditDateModal: !showEditDateModal,
    });
  };

  formattedDateString = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString = `${day}-${month}-${year}`;
    return fromattedString;
  };
  formattedTimeString = (value) => {
    const time = new Date(value);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        dateAfter: {
          message: "The :attribute must greater than today and start date",
          rule: (val, params, validator) => {
            return (
              new Date(val) >= new Date() &&
              new Date(val) > new Date(this.state.start_date)
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        dateLastProgressAfter: {
          message: "The :attribute between last updated date and today",
          rule: (val, params, validator) => {
            const lastUpdatedDate = new Date(
              this.state.progress_details[
                this.state.progress_details?.length - 1
              ]?.date
            ).getTime();
            return (
              new Date(val).getTime() > lastUpdatedDate &&
              new Date(val).getTime() <= new Date().getTime()
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        percentageAfter: {
          message: "The :attribute between latest percentage and 100%",
          rule: (val, params, validator) => {
            return (
              val <= 100 &&
              val >
                this.state.progress_details[
                  this.state.progress_details?.length - 1
                ]?.percentage
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
      },
    });
  }

  formattedDateString = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString = `${day}-${month}-${year}`;
    return fromattedString;
  };

  getProgressDetails = async (node_mid) => {
    ProgressService.getProgressDetails(node_mid).then((response) => {
      if (response.success) {
        if (response.data.length > 0) {
          this.setState(
            {
              progress_details: response.data,
              progress_backDrop: false,
              showProgress: true,
            },
            () => {
              const { progress_details } = this.state;
              const endDateTimestamp = new Date(
                progress_details[progress_details.length - 1]?.end_date
              ).getTime();
              const startDateTimestamp = new Date(
                progress_details[progress_details.length - 1]?.start_date
              );
              startDateTimestamp.setHours(0, 0, 0, 0);
              const startDateTimestampTime = startDateTimestamp.getTime();
              const todayTimestamp = new Date().getTime();

              const dayDifference = Math.ceil(
                (endDateTimestamp - startDateTimestampTime) /
                  (1000 * 60 * 60 * 24)
              );

              this.setState({
                progress_details,
                updated_end_date:
                  progress_details[progress_details.length - 1]?.end_date || "",
              });
              this.setState(
                {
                  todayIndex: parseInt(
                    Math.ceil(
                      (todayTimestamp - startDateTimestampTime) /
                        (1000 * 60 * 60 * 24)
                    )
                  ),
                },
                () => {
                  const genarateDayArray = [];

                  for (let i = 0; i <= dayDifference; i++) {
                    const currentDateTimestamp =
                      startDateTimestampTime + i * 24 * 60 * 60 * 1000;
                    const currentDate = new Date(currentDateTimestamp);

                    genarateDayArray.push({
                      value: i + 1,
                      label: this.formattedDateString(currentDate),
                    });
                  }
                  this.setState({ dayArray: genarateDayArray }, () => {
                    if (this.state.todayIndex > genarateDayArray.length) {
                      this.setState({
                        todayIndex: genarateDayArray.length - 1,
                      });
                    }

                    let markslist = progress_details.map((item, index) => {
                      let markDate = new Date(item.date).getTime();
                      let startDate = new Date(item.start_date).getTime();
                      let dayMarkDifference = Math.ceil(
                        (markDate - startDate) / (1000 * 60 * 60 * 24)
                      );

                      return {
                        value: dayMarkDifference,
                        label: item.percentage + "%",
                      };
                    });
                    if (!this.state.dayArray[this.state.todayIndex]) {
                      markslist.push({
                        value: this.state.todayIndex - 1,
                        label: "Today",
                      });
                    }

                    this.setState({ marks: markslist });
                  });
                }
              );
            }
          );
        } else {
          if (!!this.state.progress.privilege_delete) {
            this.setState({
              steps: ["Start Date", "End Date"],
              progress_backDrop: false,
              showProgressCreate: true,
            });
          } else {
            this.setState({ progress_backDrop: false });
            sweetStatusAlert("Oops", "Progress not initialized yet!", "info");
          }
        }
      } else {
        this.setState({ progress_backDrop: false });
        sweetStatusAlert("Error", "Error getting progress details", "error");
      }
    });
  };

  handleChange = (event) => {
    let { name, value } = event.target;

    this.setState({ [name]: value });
  };

  handleDatePickerChange = (value, key) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);

    const hours = dateObject && dateObject.getHours(); // Get the hours (0-23)
    const minutes = dateObject && dateObject.getMinutes(); // Get the minutes (0-59)
    const seconds = dateObject && dateObject.getSeconds(); // Get the seconds (0-59)

    const formattedDateString =
      dateObject && `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    this.setState(() => ({ [key]: formattedDateString }));
  };

  handleAddProgress = () => {
    if (
      !this.validator.fieldValid("start_date") ||
      !this.validator.fieldValid("end_date")
    ) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      sweetConfirmAlert().then(() => {
        this.setState({ progress_loading: true });
        const { progress, start_date, end_date } = this.state;

        const newProgress = {
          node_mid: this.state.node_mid_actual,
          create_user_id: progress.lg_user_id,
          create_user_table_id: progress.lg_user_table_id,
          start_date: start_date,
          end_date: end_date,
          percentage: 0,
          date: start_date,
          comment: "Initialize progress",
        };

        ProgressService.add(newProgress)
          .then((response) => {
            this.setState({
              progress_loading: false,
              showProgressCreate: false,
            });
            sweetSuccessAlert("Success", response.message.success, "success");
            this.getProgressDetails(this.state.node_mid_actual);
            this.validator.purgeFields();
          })
          .catch((error) => {
            this.setState({ progress_loading: false });
            sweetStatusAlert("Failed", error.message, "error");
          });
      });
    }
  };

  handleProgressSubmit = () => {
    if (
      !this.validator.fieldValid("date") ||
      !this.validator.fieldValid("percentage") ||
      !this.validator.fieldValid("comment") ||
      !this.validator.check(this.state.percentage, ["percentageAfter"])
    ) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      sweetConfirmAlert().then(() => {
        this.setState({ progress_loading: true });
        const { progress, progress_details, percentage, comment, date } =
          this.state;

        const updateProgress = {
          node_mid: this.state.node_mid_actual,
          create_user_id: progress.lg_user_id,
          create_user_table_id: progress.lg_user_table_id,
          start_date: progress_details[progress_details.length - 1]?.start_date,
          end_date: progress_details[progress_details.length - 1]?.end_date,
          percentage: parseInt(percentage),
          date: date,
          comment: comment,
        };

        ProgressService.add(updateProgress)
          .then((response) => {
            this.setState({
              progress_loading: false,
              showProgressModal: false,
              date: "",
              percentage: null,
              comment: null,
            });
            sweetSuccessAlert("Success", response.message.success, "success");
            this.getProgressDetails(this.state.node_mid_actual);
            this.validator.hideMessages();
            this.validator.purgeFields();
          })
          .catch((error) => {
            this.setState({ progress_loading: false });
            sweetStatusAlert("Failed", error.message, "error");
          });
      });
    }
  };

  valueLabelFormat = (value) => {
    const matchedMark = this.state.dayArray?.find(
      (mark) => mark.value === value
    );

    const percentageMatchMark = this.state.marks?.find(
      (per) => per.value === value
    );

    if (matchedMark) {
      if (percentageMatchMark) {
        return matchedMark.label + " " + percentageMatchMark.label;
      }
      return matchedMark.label;
    }
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  deleteProgressItem = (progress) => {
    sweetConfirmAlert()
      .then(() => {
        this.setState({ progress_loading: true });
        ProgressService.deleteProgress(progress.id).then((response) => {
          if (response.success) {
            sweetSuccessAlert("Success", "Progress status deleted", "success");
            this.setState({ progress_loading: false, showProgress: false });
            this.getProgressDetails(this.state.node_mid_actual);
          } else {
            sweetSuccessAlert(
              "Oops",
              "Failed to delete Progress status",
              "error"
            );
            this.setState({ progress_loading: false });
          }
        });
      })
      .catch((error) => {
        this.setState({ isAboutLoading: false });
        sweetStatusAlert("Failed", "Failed to add about", "error");
      });
  };

  handleUpdate = () => {
    sweetConfirmAlert().then(() => {
      this.setState({ end_date_loading: true });
      const { updated_end_date, progress, progress_details } = this.state;

      const updateProgress = {
        end_date: updated_end_date,
        prev_end_date:progress_details[progress_details.length - 1]
        ?.end_date
      };

      const updateProgressJSON = JSON.stringify(updateProgress);

      ProgressService.updateEndDate(updateProgressJSON, this.state.node_mid_actual)
        .then((response) => {
          if (response.success) {
            this.setState({
              end_date_loading: false,
            });
            this.toggleeditEndDateModal();
            sweetSuccessAlert("Success", response.message.success, "success");
            this.getProgressDetails(this.state.node_mid_actual);
            // Close the modal after successful update
          } else {
            sweetStatusAlert("Failed", response.message, "error");
          }
        })
        .catch((error) => {
          this.setState({ end_date_loading: false });
          sweetStatusAlert("Failed", error.message, "error");
        });
    });
  };

  render() {
    const {
      showProgressCreate,
      loadingNode,
      hierarchyGridTopData,
      hierarchyGridBottomData,
      groupTitle,
      mcode,
      title,
      displayOrder,
      showAboutUpdateModal,
      about,
      isAboutLoading,
      about_details,
      showChatModal,
      chat,
      chat_details,
      createChatLoading,
      chat_server_details,
      chat_backDrop,
      checked,
      studentList,
      memberListLoading,
      showProgressCreateModal,
      progress,
      steps,
      start_date,
      end_date,
      progress_loading,
      progress_details,
      marks,
      dayArray,
      todayIndex,
      isCardSectionVisible,
      isMobile,
      selectedMidsForms,
      selectedMid,
      selectedFormDetail,
      user_id,
      user_table_id,
      titles,
      progress_backDrop,
      showProgress,
      editEndDateModal,
      showEditDateModal,
      end_date_loading,
    } = this.state;
    const { breadcrumb2, nodeTitle } = progress;
    const formattedBreadcrumb = breadcrumb2
      ? breadcrumb2.replaceAll(">", " > ")
      : "";
    const isValid = this.validator.check(this.state.percentage, [
      "percentageAfter",
    ]);
    return (
      <div style={{ height: "90vh", overflow: "auto" }}>
        {/* <ContentWrapper> */}
        <Card>
          <CardHeader>
            {this.props.state.path !== "node" && (
              <p style={{ fontSize: "10.5625px", color: "#00000099" }}>
                {formattedBreadcrumb
                  ? `${formattedBreadcrumb && formattedBreadcrumb}`
                  : ""}
              </p>
            )}
            <div className="container-fluid px-0 ">
              <div className="row d-flex justify-content-end ">
                <div className="col-auto">
                  {showProgressCreate && (
                    <Button variant="primary" onClick={this.handleAddProgress}>
                      Save changes
                    </Button>
                  )}
                </div>
                <div className="col-auto">
                  {showProgress && progress && !!progress.privilege_add && (
                    <Button
                      variant="primary"
                      onClick={() => this.handleProgressSubmit()}
                    >
                      Save changes
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </CardHeader>

          <CardBody>
            {progress_backDrop ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {showProgressCreate &&
                  (progress_loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <Form>
                      <Row className="my-5">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Add start date</Form.Label>
                          <div className="col-md-12 px-0">
                            <DatePicker
                              className="py-2 form-control w-100 col-md-12" // Add Bootstrap form control class
                              selected={
                                this.state.start_date
                                  ? new Date(this.state.start_date)
                                  : undefined
                              }
                              name="start_date"
                              onChange={(e) =>
                                this.handleDatePickerChange(e, "start_date")
                              }
                              dateFormat="dd-MM-yyyy h:mm aa" // Set custom date format
                              placeholderText="Start Date"
                              wrapperClassName="react-datePicker"
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                            />
                          </div>
                          {this.validator.message(
                            "start_date",
                            this.state.start_date,
                            "required",
                            {
                              className: "text-danger",
                              messages: {
                                required: "The start date field is required",
                              },
                            }
                          )}
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Add end date</Form.Label>
                          <div className="col-md-12 px-0">
                            <DatePicker
                              className="py-2 form-control w-100 col-md-12" // Add Bootstrap form control class
                              selected={
                                this.state.end_date
                                  ? new Date(this.state.end_date)
                                  : undefined
                              }
                              name="start_date"
                              onChange={(e) =>
                                this.handleDatePickerChange(e, "end_date")
                              }
                              dateFormat="dd-MM-yyyy h:mm aa" // Set custom date format
                              placeholderText="End Date"
                              wrapperClassName="react-datePicker"
                              disabled={!this.state.start_date}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              minDate={new Date()}
                            />
                          </div>

                          {this.validator.message(
                            "end_date",
                            this.state.end_date,
                            "required|dateAfter",
                            {
                              className: "text-danger",
                              messages: {
                                required: "The end date field is required",
                              },
                            }
                          )}
                        </Form.Group>
                      </Row>

                      <Row className="my-5">
                        <Box sx={{ width: "100%" }}>
                          <Stepper activeStep={2} alternativeLabel>
                            {steps.map((label, index) => {
                              const labelProps = {};
                              if (index === 0) {
                                labelProps.optional = (
                                  <Typography color="success">
                                    {start_date
                                      ? this.formattedDateString(start_date) +
                                        " " +
                                        this.formattedTimeString(start_date)
                                      : ""}
                                  </Typography>
                                );
                              } else if (index === steps.length - 1) {
                                labelProps.optional = (
                                  <Typography color="error">
                                    {end_date
                                      ? this.formattedDateString(end_date) +
                                        " " +
                                        this.formattedTimeString(end_date)
                                      : ""}
                                  </Typography>
                                );

                                labelProps.error = true;
                              }
                              return (
                                <Step key={label}>
                                  <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                              );
                            })}
                          </Stepper>
                        </Box>
                      </Row>
                    </Form>
                  ))}

                {showProgress &&
                  (progress_loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <Form >
                      <Row className="col-md-12 d-flex justify-content-end">
                        {showProgress &&
                          progress &&
                          !!progress.privilege_edit && (
                            <Button
                              onClick={this.toggleeditEndDateModal}
                              className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                              title="Edit End Date"
                            />
                        )}
                        {!!progress.privilege_edit && 
                            progress_details[progress_details.length - 1]?.end_date !== progress_details[progress_details.length - 1]?.prev_end_date &&
                            progress_details[progress_details.length - 1]?.prev_end_date !== null && (
                            <Button
                              onClick={this.toggleShowEditDate}
                              className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-info"
                              title="Edit End Date"
                            />
                        )}

                      </Row>
                      <Row className="mt-15 mb-10 px-2 d-flex justify-content-center">
                        <Box sx={{ width: "90%" }}>
                          {/* <Typography gutterBottom>Progress Bar</Typography> */}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mt: -2,
                            }}
                          >
                             <TinyText
                              sx={{
                                fontSize: "12px",
                                fontWeight: "600",
                                fontSize: "10px",
                              }}
                            >
                              Start Date : 
                              <span>
                                {this.formattedDateString(progress_details[progress_details.length - 1]?.start_date)}
                              </span>
                              <br />
                              Time : 
                              <span>
                              {this.formattedTimeString(progress_details[progress_details.length - 1]?.start_date)}
                              </span>
                                
                            </TinyText>
                            <TinyText
                              sx={{
                                fontSize: "12px",
                                fontWeight: "600",
                                fontSize: "10px",
                              }}
                            >
                              End Date : 
                              <span>
                                {this.formattedDateString(progress_details[progress_details.length - 1]?.end_date)}
                              </span>
                              <br />
                              Time : 
                              <span>
                              {this.formattedTimeString(progress_details[progress_details.length - 1]?.end_date)}
                              </span>

                              <br/>

                              {!!progress.privilege_edit && 
                            progress_details[progress_details.length - 1]?.end_date !== progress_details[progress_details.length - 1]?.prev_end_date &&
                            progress_details[progress_details.length - 1]?.prev_end_date !== null && (
                              <span style={{ color: "#808080", fontSize: "8px" }}>Edited</span>
                            )}
                            </TinyText>

                          </Box>
                          <IOSSlider
                            aria-label="ios slider"
                            value={todayIndex}
                            marks={marks}
                            min={dayArray[0]?.value}
                            max={dayArray[dayArray.length - 1]?.value}
                            step={1}
                            valueLabelDisplay="on"
                            getAriaValueText={this.valueLabelFormat}
                            valueLabelFormat={this.valueLabelFormat}
                            sx={{ color: sliderColor }}
                          />
                        </Box>
                      </Row>

                      <Row className="my-5">
                        <Col
                          md={!!progress && !!progress.privilege_add ? 6 : 12}
                          className="pb-5"
                        >
                          <div className="d-flex justify-content-start px-0">
                            <List
                              dense
                              sx={{
                                width: "100%",
                                // maxWidth: 360,
                                bgcolor: "background.paper",
                                position: "relative",
                                overflow: "auto",
                                maxHeight: 300,
                                "& ul": { padding: 0 },
                              }}
                            >
                              {progress_details?.map((progressItem, index) => {
                                return (
                                  <div key={index}>
                                    <ListItem
                                      alignItems="flex-start"
                                      key={index}
                                      className="row d-flex align-items-center px-0"
                                    >
                                      {/* <> */}
                                      <div className="col-md-10 col-10 d-flex px-0">
                                        <ListItemAvatar
                                          style={{ marginTop: "0px" }}
                                        >
                                          <Avatar sx={{ bgcolor: sliderColor }}>
                                            {progressItem?.percentage + "%"}
                                          </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                          primary={
                                            <div>
                                              <Typography variant="body1">
                                                {this.formattedDateString(
                                                  progressItem.date
                                                )}
                                              </Typography>
                                              <Typography variant="body2">
                                                {this.formattedTimeString(
                                                  progressItem.date
                                                )}
                                              </Typography>
                                            </div>
                                          }
                                          secondary={
                                            <React.Fragment>
                                              {progressItem.comment
                                                ? progressItem.comment
                                                : ""}
                                            </React.Fragment>
                                          }
                                          style={{ fontFamily: "Poppins" }}
                                        />
                                      </div>
                                      <div className="col-md-2 col-2 px-0 d-flex justify-content-end">
                                        {!!progress.privilege_delete && (
                                          <React.Fragment>
                                            <ListItemSecondaryAction>
                                              {/* <IconButton edge="end" aria-label="delete" onClick={() => this.deleteProgressItem(progressItem)}>
                                                <DeleteIcon color="error" />
                                                </IconButton> */}
                                              <Button
                                                className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                                title="Delete Details"
                                                onClick={() =>
                                                  this.deleteProgressItem(
                                                    progressItem
                                                  )
                                                }
                                              />
                                            </ListItemSecondaryAction>
                                          </React.Fragment>
                                        )}
                                      </div>
                                      {/* </> */}
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                  </div>
                                );
                              })}
                            </List>
                          </div>
                        </Col>
                        <Col md={6} className="pb-5">
                          <div className="d-flex justify-content-start">
                            {!!progress && !!progress.privilege_add && (
                              <Box
                                sx={{
                                  width: "100%",
                                  maxWidth: 360,
                                  marginLeft: "10px",
                                }}
                              >
                                <Form.Label>Add date</Form.Label>
                                <DatePicker
                                  className="py-2 form-control col-md-12" // Add Bootstrap form control class
                                  selected={
                                    this.state.date
                                      ? new Date(this.state.date)
                                      : undefined
                                  }
                                  name="date"
                                  onChange={(val) =>
                                    this.handleDatePickerChange(val, "date")
                                  }
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="dd-MM-yyyy h:mm aa"
                                  placeholderText="Date"
                                  wrapperClassName="react-datePicker"
                                  maxDate={
                                    new Date(
                                      progress_details[
                                        progress_details.length - 1
                                      ]?.end_date
                                    ) > new Date()
                                      ? new Date()
                                      : new Date(
                                          progress_details[
                                            progress_details.length - 1
                                          ]?.end_date
                                        )
                                  }
                                  minDate={
                                    new Date(
                                      progress_details[
                                        this.state.progress_details?.length - 1
                                      ]?.date
                                    )
                                  }
                                />
                                {this.validator.message(
                                  "date",
                                  this.state.date,
                                  "required|dateLastProgressAfter",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required: "The date field is required",
                                    },
                                  }
                                )}

                                <Form.Label className="mt-3">
                                  Percentage Covered
                                </Form.Label>

                                <Form.Control
                                  type="number"
                                  name="percentage"
                                  placeholder="Percentage"
                                  onChange={this.handleChange}
                                  className="mb-3"
                                  value={this.state.percentage}
                                  disabled={!this.state.date}
                                />
                                <p
                                  hidden={isValid}
                                  style={{
                                    color: this.state.percentage
                                      ? "red"
                                      : "inherit",
                                  }}
                                >
                                  The percentage between latest percentage and
                                  100%
                                </p>
                                {this.validator.message(
                                  "percentage",
                                  this.state.percentage,
                                  "required|numeric|between:1,100",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required:
                                        "The percentage field is required",
                                    },
                                  }
                                )}
                                {/* {this.validator.message(
                                  "percentage",
                                  this.state.percentage,
                                  "percentageAfter",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required:
                                        "The percentage field is required",
                                    },
                                  }
                                )} */}

                                <Form.Label className="mt-3">
                                  Comment
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name="comment"
                                  placeholder="Comment"
                                  onChange={this.handleChange}
                                  value={this.state.comment}
                                  disabled={!this.state.date}
                                />
                                {this.validator.message(
                                  "comment",
                                  this.state.comment,
                                  "required",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required: "The comment field is required",
                                    },
                                  }
                                )}
                              </Box>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  ))}
              </>
            )}
          </CardBody>
        </Card>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={editEndDateModal}
          onHide={this.toggleeditEndDateModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit End Date of the Proggress View</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {end_date_loading ? (
              <Row className="justify-content-center align-items-center">
                <Col xs={12} className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </Col>
              </Row>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>End Date</Form.Label>
                    {/* <div className="col-md-12 px-0"> */}
                    <DatePicker
                      className="py-2 form-control w-100 col-md-12"
                      name="updated_end_date"
                      selected={new Date(this.state.updated_end_date)}
                      onChange={(date) =>
                        this.handleDatePickerChange(date, "updated_end_date")
                      }
                      dateFormat="dd-MM-yyyy h:mm aa" // Set custom date format
                      placeholderText="End Date"
                      wrapperClassName="react-datePicker"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      // maxDate={(new Date(progress_details[progress_details.length - 1]?.end_date) > new Date()) ? new Date() :new Date(progress_details[progress_details.length - 1]?.end_date) }
                      minDate={
                        new Date(
                          progress_details[
                            progress_details.length - 1
                          ]?.end_date
                        )
                      }
                    />
                    {/* </div> */}
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button
                sx={{ mr: "20px" }}
                onClick={this.toggleeditEndDateModal}
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={this.handleUpdate}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showEditDateModal}
          onHide={this.toggleShowEditDate}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edited End Date Of Proggress View</Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label >Old End Date :</Form.Label>
          <span>
            {this.formattedDateString(progress_details[progress_details.length - 1]?.prev_end_date) + " " +
              this.formattedTimeString(progress_details[progress_details.length - 1]?.prev_end_date)}
          </span>
          <br/>
          <Form.Label >New End Date :</Form.Label>
          <span>
            {this.formattedDateString(progress_details[progress_details.length - 1]?.end_date) + " " +
              this.formattedTimeString(progress_details[progress_details.length - 1]?.end_date)}
          </span>
                   
                  </Form.Group>
                </Row>
              </Form>


            <Modal.Footer>
              <Button
                sx={{ mr: "20px" }}
                onClick={this.toggleShowEditDate}
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        {/* </ContentWrapper> */}

        {/* <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={progress_backDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop> */}
      </div>
    );
  }
}

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 10,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 60,
    width: 2,
    backgroundColor: "red",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel, & .MuiSlider-markLabel": {
    fontSize: 12,
    fontWeight: "normal",
    border: "1px solid #EDEEF7",
    backgroundColor: "#F6F8FA",
    padding: "2px 5px",
    borderRadius: "3px",
    marginTop: "-15px",
    color: theme.palette.text.primary,
    zIndex: 1,
    transition: "z-index 0.3s",
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
    fontFamily: "Poppins",
  },
  "& .MuiSlider-markLabel": {
    top: "52px",
  },
  "& .MuiSlider-markLabel:hover": {
    zIndex: 2,
    backgroundColor: "#5458AF",
    color: "#fff",
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 25,
    width: 5,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

const TinyText = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 500,
});
