import React from "react";

export default function ExamviewButton ({ type, btnName, className,onClick, dataDismiss,spanclassName ,pathD,pathFill,pathFilerule,pathTransform,rectFill,rectOpacity,rectTransform,rectX,rectY,rectWidth,rectHeight,rectRX, btnIconStyle, dataToggle, dataTarget, inlineStyle, disabled, id }) {

    return (
        <button type={type}
                className={className}
                data-toggle={dataToggle}
                data-target={dataTarget}
                onClick={onClick}>
                {btnName}
                    <span className={spanclassName}>
                        <svg xmlns="http://www.w3.org/2000/svg" 
                            width="24px" 
                            height="24px" 
                            viewBox="0 0 24 24"
                            version="1.1">
                            <g stroke="none" 
                                stroke-width="1" 
                                fill="none" 
                                fill-rule="evenodd">
                                <polygon points="0 0 24 0 24 24 0 24 "/>
                                <rect fill={rectFill}
                                    opacity={rectOpacity}
                                    transform={rectTransform}
                                    x={rectX} 
                                    y={rectY} 
                                    width={rectWidth} 
                                    height={rectHeight} 
                                    rx={rectRX}/>
                                    <path
                                        d={pathD}
                                        fill={pathFill}
                                        fill-rule={pathFilerule}
                                        transform={pathTransform}/>
                            </g>


                        </svg>
                    </span>
        </button>
    )
}