import React, { Component } from "react";
import {
  sweetSuccessAlert,
  sweetStatusAlert,
  registersuccess,
} from "../../../../components/common/SweetAlert";
import { TermService } from "../../../../service/user_management/terms_conditions.service";
import "./TermsAndConditions.css";
import { Button, CircularProgress } from "@material-ui/core";
import { RequestService } from "../../../../service/manage/registration/request_form.service";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import edfocilogo from "../../../../assets/images/logo.png"
import edsiderlogo from "../../../../assets/images/edsiderlogo.png"
import cmclogo from "../../../../assets/images/mid.png";
import { routes } from "../../../../helper/routes.js";
import config from '../../../../helper/config';
import {customerConstants} from "../../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, disabledBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    disabledBtnStyle = {
      color: "#fff",
      backgroundColor: "#7E8299",
      borderColor: "transparent",
      boxShadow: "none"
    }
    break
  default:
    break
}

const terms = [
  "",
  "I agree to the terms and conditions.",
  "මම නියමයන් සහ කොන්දේසි වලට එකඟ වෙමි.",
  "நான் விதிகள் மற்றும் நிபந்தனைகளை ஒப்புக்கொள்கிறேன்.",
];

class TermsAndConditions extends Component {
  state = {
    agreed: false,
    selectedLanguage: 1,
    isLoading: false,
    isLoadingDropdown: false,
    isLoadingContents: false,
    languages: [],
    contents: "",
    subLang: ["", "Submit", "ඉදිරිපත් කරන්න", "சமர்ப்பிக்க"],
  };

  async componentDidMount() {
    await this.loadDataDropdown();
    await this.getContentById(this.state.selectedLanguage);
  }

  loadDataDropdown = async () => {
    this.setState({ isLoadingDropdown: true });
    try {
      const response = await TermService.LanguageGetAll();
      this.setState({ languages: response.data.languages });
      const customer = getCustomerFeatureFlag();
      if(customer === customerConstants.EXO_CUSTOMER2){
        const firstLanguage = response.data[0];
        this.setState({ languages: [firstLanguage] });
      }
    } catch (error) {
      // Handle error
    } finally {
      this.setState({ isLoadingDropdown: false });
    }
  };

  getContentById = async (id) => {
    try {
      this.setState({ isLoadingContents: true });
      const response = await TermService.TermsById(id);
      this.setState({ contents: response.data.content });
    } catch (error) {
      // Handle error
    } finally {
      this.setState({ isLoadingContents: false });
    }
  };

  validateForm = (data) => {
    return Object.values(data).every((value) => value !== null && value !== "");
  };

  handleCheckboxChange = (event) => {
    const agreed = event.target.checked;
    this.setState({ agreed });
  };

  handleLanguageChange = async (event) => {
    const selectedLanguage = parseInt(event.target.value);
    this.setState({ selectedLanguage });
    await this.getContentById(selectedLanguage);
  };

  handleBack = async (event) => {
    event.preventDefault();
    const data = {
      ...this.props.location.state,
    };
    this.props.history.push({
      pathname: routes.registration_form_view,
      state: {
        data: data.data,
      },
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      ...this.props.location.state,
    };

    this.setState({ isLoading: true });

    try {
      const type = data.success ? data.success : null;
      const InvitationdData = data.data ? data.data : null;
    
      if (type && InvitationdData) {
        try {
          const reqResponse = await RequestService.submitRegistration(
            JSON.stringify(InvitationdData)
          );
          if (reqResponse.success) {
            sweetSuccessAlert(
              "Success",
              "Email verification sent successfully. Please check your mail inbox or spam/junk!",
              "success"
            );
            this.props.history.push({
              pathname: routes.base_url,
            });
          } else if (reqResponse.status === 400) {
            sweetStatusAlert(
              "Failed!",
              "Your Email already exists. Try with another Email",
              "error"
            );
            this.props.history.push({
              pathname: routes.registration_form_view,
            });
          } else {
            sweetStatusAlert("Failed!", reqResponse.message, "error");
            this.props.history.push({
              pathname: routes.registration_form_view,
            });
          }
        } catch (error) {
          // Handle error if needed
        } finally {
          this.setState({ isLoading: false });
        }
      } else {
        const reqResponse = await RequestService.getRegistration(
            InvitationdData.child_email
          );
        const verResponse = await RequestService.approveRegistration(
          reqResponse.invitation_id
        );
        if (verResponse.status === 200) {
          registersuccess(
            "Success!",
            "Thank you for your successful registration. Click ok to go to home page",
            "success",
            (result) => {
              if (result === true) {
                this.props.history.push({
                  pathname: routes.base_url,
                });
              }
            }
          );
        } else {
          sweetStatusAlert("Failed!", "Registration Failure", "error");
        }
      }
    } catch (error) {
      // Handle error
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      agreed,
      selectedLanguage,
      isLoading,
      isLoadingDropdown,
      isLoadingContents,
      languages,
      contents,
      subLang,
    } = this.state;

    const isDataLoading = isLoadingDropdown || isLoadingContents || isLoading;

    const currentYear = new Date().getFullYear();

    let logo, footerText, copyRightText;
    const customer = getCustomerFeatureFlag();
    switch (customer) {
      case customerConstants.EXO_CUSTOMER1:
        logo = edsiderlogo;
        copyRightText = "© 2024 EdSider";
        footerText = "All Right Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
        break;
      case customerConstants.EXO_CUSTOMER2:
        logo = cmclogo;
        copyRightText = "© 2024"
        footerText = "All Rights Reserved. Powered by EdSider (Pvt) Ltd. Supported by Infinity Innovators (Pvt) Ltd"
        break;
      default:
        logo = edfocilogo;
        copyRightText = "© 2024 EdFoci"
        footerText = "All Rights Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
        break;
    }

    return (
      <>
        <Navbar className="navigation-bg">
          <Navbar.Brand href="/">
            <img
              src={logo}
              alt="logo"
              style={{ height: "auto", width: "200px" }}
            />
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link onClick={this.handleBack} className="back-btn">BACK</Nav.Link>
          </Nav>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </Navbar>
        {/* <Button
          className="submit-button-back"
          variant="contained"
          color="default"
          onClick={this.handleBack}
        >
          Back
        </Button> */}
        <div className="container-terms">
          {/* <label className="language-label">
            Language: &nbsp;
            <select
              className="language-select"
              value={selectedLanguage}
              onChange={this.handleLanguageChange}
              disabled={isDataLoading}
            >
              {languages.map((language) => (
                <option key={language.language_id} value={language.language_id}>
                  {language.language}
                </option>
              ))}
            </select>
            {isLoadingDropdown && <CircularProgress />}
          </label> */}
          {!isDataLoading && (
            <>
              <div
                className="contents"
                dangerouslySetInnerHTML={{ __html: contents }}
              />
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-custom"
                  checked={agreed}
                  onChange={this.handleCheckboxChange}
                />
                <span className="checkbox-text">{terms[selectedLanguage]}</span>
              </label>

              <Button
                className="submit-button"
                variant="contained"
                color="primary"
                style={agreed || isLoading ? addBtnStyle : disabledBtnStyle}
                disabled={!agreed || isLoading}
                onClick={this.handleSubmit}
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  subLang[selectedLanguage]
                )}
              </Button>
            </>
          )}
          {isDataLoading && (
            <div style={{ height: "90vh" }}>
              <div className="spinner-container">
                <CircularProgress />
              </div>
            </div>
          )}
        </div>
        <div className="row d-flex justify-content-between footer-container">
          <div className="col-md-2 col-sm-3 copyright-items">
            {copyRightText}
          </div>
          <div className="col-md-6 col-sm-3 copyright-items d-flex justify-content-center">
            {footerText}
          </div>
          <div className="col-md-4 col-sm-3 d-flex justify-content-end policy-content">
            <span className="text-dark-75">
              <a
                href="/conditions"
                target="_blank"
                className="text-dark-75 text-hover-primary"
              >
                Privacy Policy and Terms & Conditions
              </a>
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default TermsAndConditions;
