import React from "react";
import { Children } from "react";

export default function TableFilter({children}) {
    return (
        <div className="card card-custom mb-10">
            <div className="card-body">
                <form className="form">
                    <div className="row course-search-wrap">
                        {children[0]}
                        {children[1]} 
                        {children[2]} 
                        {children[3]} 
                    </div>
                </form>
            </div>
        </div>
    )
}