import React, { Component } from "react"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import cmclogo from "../assets/images/mid.png"
import { routes } from "../../../helper/routes"

class Navigation extends Component {
  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role)
    this.props.history.push(routes.auth_response)
  }

  handleSignUp = () => {
    this.props.history.push(routes.registration_form_view)
  }

  render() {
    return (
      <>
        <div className="cmc-nav row px-10 py-5">
          <div className="col-md-6 col-6 pl-5 py-0">
            <img
              src={cmclogo}
              alt="logo"
              style={{ height: "auto", width: "300px" }}
              className="cmc-logo"
            />
          </div>
          <div className="col-md-6 col-6 pr-10 py-0 d-flex align-items-center justify-content-end register-btn-container">
            <div className="row d-flex align-items-center">
              <a
                className="pr-2 m-0 cmc-links"
                onClick={() => this.handleRoleSelect("student")}
              >
                Sign In
              </a>
              /
              <p onClick={this.handleSignUp} className="pl-2 cmc-links">
                {" "}
                Register
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Navigation)
