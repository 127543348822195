import React, { Component } from "react";
import './dashboard.css';
import { Row, Col, Spinner } from "react-bootstrap";
import ContentWrapper from "../../../../components/common/ContentWrapper.js";
import Card from "../../../../components/common/Card.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardTitle from "../../../../components/common/CardTitle.js";
import AssessmentCardTitle from "../../../../components/common/CardTitle.js";
import { history } from "../../../../helper/history.js";
import CardBody from "../../../../components/common/CardBody.js";
import AdminTemplate from "../../../../components/admin/layouts/Template.js";
import Option from "../../../../components/common/form/Option.js";
import { examService } from "../../../../service/candidate/examService.js";
import { ManageDashboardService } from "../../../../service/manage/dashboard/manage_dashboard.service.js";
import { AssessmentService } from "../../../../service/candidate/assessmentService.js";
import { assessmentService } from "../../../../service/assessment/assessmentService.js";
import DataHandler from "../../../../handlers/DataHandler.js";
import Button from "react-bootstrap/Button";
import { PaperService } from "../../../../service/manage/paper/paper_service.js";
import DoughnutChart from "../../charts/DoughnutChart.js";
import BarChart from "../../charts/BarChart.js";
import DrillChart from "../../charts/DrillChart.js";
import ProgressCircle from "../../charts/ProgresCircle.js";
import TaskIcon from "@mui/icons-material/Task";
import GradeIcon from "@mui/icons-material/Grade";
import QuizIcon from "@mui/icons-material/Quiz";
import { AdminDashboardService } from "../../../../service/manage/dashboard/admin_dashboard.service.js";
import { Dropdown } from "react-bootstrap";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton
} from "@mui/material";
import { routes } from "../../../../helper/routes.js";
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import TeacherAnnouncementCard from "../TeacherAnnouncementCard.js";
import { assessmentActions } from "../../../../actions/assessment/assessmentActions.js";
import { connect } from "react-redux";
import { AnnouncementService } from "../../../../service/manage/announcement/announcement.service.js";
import teacheradminguide from "./teacheradminguide.pdf"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import InsertChartIcon from '@mui/icons-material/InsertChart';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import DescriptionIcon from '@mui/icons-material/Description';
import AnnouncementCard from "./components/AnnouncementCard.js";
import DocumentCard from "./components/DocumentCard.js";
import QRgenerator from "../../attendance/QRgenerator.js";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClassIcon from '@mui/icons-material/Class';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddStudentModal from "../AddStudentModal.js";
import AddTeacherModal from "../AddTeacherModal.js";
import ManageMainCalendar from "../../calendar/ManageMainCalendar.js";
import Receivables from "./components/Receivables.js";
import Revenue from "./components/Revenue.js";
import TeacherPayments from "./components/TeacherPayments.js";
import Income from "./components/Income.js";
import TeacherExams from "./components/TeacherExams.js";
import StudentExams from "./components/StudentExams.js";
import SavingsRoundedIcon from "@mui/icons-material/SavingsRounded"
import PaymentOverdue from "./components/PaymentOverdue.js";
import CgcAddStudentModal from "./components/CgcAddStudentModal.js";
import GroupedBarChart from "../../charts/GroupedBarChart.js";
import FigureChart from "../../charts/FigureChart.js";
import TotalOutStanding from "./components/TotalOutStanding.js";

let welcomeAssessmentText = "Welcome to EdFoci Assessment!"
let iconColor = "var(--maincolor)"

class EdFociManageDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardData: [],
      registrationLoading: true,
      dashboardDataLoading: true,
      enrollmentLoading: true,
      studentEnrollmentLoading: true,
      attendanceLoading: true,
      paperLoading: false,
      childPaperLoading: false,
      registrationData: null,
      enrollmentData: null,
      childEnrollment: null,
      attendance: null,
      paperEnrollemnt: null,
      childPaperEnroll: null,
      totalEnrolledAssignedExams: "",
      totalEnrolledOnprogressExams: "",
      totalEnrolledCompletedExams: "",
      userId: "",
      userName: "",
      totalAllExams: "",
      dashboardData: '',
      totalEnrolledExams: "",
      search_term: "",
      dataLoading: false,
      limit: 10,
      offset: 0,
      shouldShowChildDashboard: false,
      shouldShowTeacherDashboard: false,
      shouldShowAdminDashboard: false,
      loadingQuestions: true,
      loadingCategories: true,
      loadingTypes: true,
      loadingSubjects: true,
      announcements: [],
      rowsToShow: 4,
      isAddStudentModalOpen: false,
      stuListLoading: false,
      stuListCountAdmin: null,
      isAddTeacherModalOpen: false,
      attendanceCreator: undefined,
      attendanceCreatorLoading: false,
      suitableArray:[],
      studentIntakes:undefined,
      selectedStudentIntake:undefined,
      statsArray:[]
    };
  }
  
  async componentDidMount() {
    this.getDashboardStats();
    const lg_user_id = DataHandler.getFromSession("manage_user_id");
    const table_log_id = DataHandler.getFromSession("table_log_id");
    this.fetchAnnonucementsAdminDashboard(lg_user_id, table_log_id);

    const user_id = sessionStorage.getItem("user_id");
    const user_name = sessionStorage.getItem("username");
    const userType = sessionStorage.getItem("userType");

    if (userType === "cgc_student") {
      this.setState({ shouldShowChildDashboard: true, shouldShowTeacherDashboard: false, shouldShowAdminDashboard:false});
    } 
    else if (userType === "teacher") {
      this.setState({shouldShowChildDashboard: false, shouldShowTeacherDashboard: true, shouldShowAdminDashboard:false})
      
      var teacher_user_id = DataHandler.getFromSession("user_id");

      await assessmentService.getAllAssessments().then((res) => {
        this.setState({
          managedAssessments: res.data.assessments,
        });
      });

      await this.props.getAssessmentSubject();

      const lg_user_id = DataHandler.getFromSession("manage_user_id");
      const table_log_id = DataHandler.getFromSession("table_log_id");

      this.fetchAnnonucementsAdminDashboard(lg_user_id, table_log_id);

      const totalAssessCountResponse =
        await assessmentService.getAllAssessmentsCount(teacher_user_id);

      if (!totalAssessCountResponse.error) {
        this.setState({
          totalCountMyAssessments:
            totalAssessCountResponse.data.total_assessments_count, // Adjust this based on the response structure
        });
      }
      this.setState({
        myAssessmentsLoading: false, // Set loading state to false
      });

      const totalPublicAssessmentsCountResponse =
        await assessmentService.getTotalPublicAssessmentsCount();

      if (!totalPublicAssessmentsCountResponse.error) {
        this.setState({
          totalCountPublicAssessments: totalPublicAssessmentsCountResponse.data.total_public_assessment_count,
        });
      }

      const totalSharedAssessmentsCountResponse =
        await assessmentService.getTotalSharedAssessmentsCount(teacher_user_id);

      if (!totalSharedAssessmentsCountResponse.error) {
        this.setState({
          totalCountSharedWithMe: totalSharedAssessmentsCountResponse.data.total_count,
        });
      }

      const totalCountResponse = await examService.getAllCount();

      if (!totalCountResponse.error) {
        this.setState({
          questionCount: totalCountResponse.data.get_total_question_count,
          loadingQuestions: false,
          categoryCount: totalCountResponse.data.total_categories_count,
          loadingCategories: false,
          typeCount: totalCountResponse.data.total_question_types_count,
          subjectCount: totalCountResponse.data.total_subjects_count,
        });
      }

      if (this.props.all.allSubjects.length > 0) {
        let allSubjects = [];
        allSubjects.push(
          <Option key={null} optionName="Nothing selected" value={null} />
        );
        this.props.all.allSubjects.map((el, i) => {
          allSubjects.push(
            <Option
              key={el.subject_id}
              optionName={el.name}
              value={el.subject_id}
            />
          );
        });

        this.setState({
          subjectOptions: allSubjects,
          selectedSubject: allSubjects[0].props.value,
        });

        if (allSubjects) window.$("#assessment-subject").selectpicker("refresh");
      }
    }
    else if (userType === "admin") {
      this.fetchStudentAndRegCountDashboard();
      this.setState({shouldShowChildDashboard: false, shouldShowTeacherDashboard: false, shouldShowAdminDashboard:true}, () => {
      })
    }

    this.setState({ userName: user_name });

  }

  handleApplicationSelect = (formData) => {
    let state = {
      pid: formData.pid,
      mid: formData.node_mid,
      lg_user_id: formData.lg_user_id,
      lg_user_table_id: formData.lg_user_table_id,
      flg_list_active: formData.flg_list_active,
      privilege_view: formData.privilege_view,
      privilege_edit: formData.privilege_edit,
      privilege_add: formData.privilege_add,
      privilege_delete: formData.privilege_delete,
      owner_restriction: formData.owner_restriction,
      user_list_restriction: formData.user_list_restriction,
      form_display_title: formData.form_display_title,
      form_display_order: formData.form_display_order,
      form_list_id: formData.form_list_id,
      breadcrumb2: formData.breadcrumb,
      main_table_id: formData.main_table_id,
      nodeTitle: formData.tree_main_title,
      node_mid_actual:formData.node_mid_actual,
      path: 'dashboard',
      mcode: formData.mcode
    };
    switch (formData.form_list_id) {
      case 3:
        history.push({
          pathname: "/manage-admin",
          state: { ...state },
        });
        break;
      case 4:
        history.push({
          pathname: "/manage-student",
          state: { ...state },
        });
        break;
      case 5:
        history.push({
          pathname: "/manage-parent",
          state: { ...state },
        });
        break;
      case 6:
        history.push({
          pathname: "/manage-enrollment",
          state: { ...state },
        });
        break;
      case 7:
        history.push({
          pathname: "/manage-attendance",
          state: { ...state },
        });
        break;
      case 8:
        history.push({
          pathname: "/paper-mapping",
          state: { ...state },
        });
        break;
      case 9:
        history.push({
          pathname: "/manage-search",
          state: { ...state },
        });
        break;
      case 13:
        history.push({
          pathname: "/manage-student",
          state: { ...state },
        });
        break;
      case 11:
        history.push({
          pathname: "/enrolled-paper-list",
          state: { ...state },
        });
        break;
      case 24:
        history.push({
          pathname: "/manage-teacher",
          state: { ...state },
        });
        break;
      default:
    }
  };

  async fetchStudentAndRegCountDashboard() {
    this.setState({ stuListLoading: true })
    await AdminDashboardService.getStudentAndRegCount()
      .then((res) => {
        if (res.success) {
          this.setState({ stuListCountAdmin: res.data, stuListLoading: false });
        }
        else {
          this.setState({ stuListLoading: false })
        }
      })
      .catch((err) => {
        this.setState({ stuListLoading: false })
      })
  }

  async fetchAnnonucementsAdminDashboard(lg_user_id, lg_user_table_id) {
    this.setState({ announcementsLoading: true })
    await AnnouncementService.getAllAnnouncementsAdmin(lg_user_id, lg_user_table_id)
      .then((res) => {
        this.setState({ announcements: res.data.announcements, announcementsLoading: false }, ()=>{});
      })
      .catch((err) => {
        this.setState({ announcementsLoading: false })
      })
  }

  async getDashboardStats() {
    this.setState({
      registrationLoading: true,
      enrollmentLoading: true,
      studentEnrollmentLoading: true,
      attendanceLoading: true,
    });
    const session_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");
    const userType = sessionStorage.getItem("userType");

    // ManageDashboardService.getContent()
    if (userType === "admin") {
      await ManageDashboardService.getContent(
        session_id,
        table_log_id,
        "registration",
        "viewall"
      )
        .then((res) => {
          this.setState({ registrationData: res.data, registrationLoading: false });
        })
        .catch((error) => {
          this.setState({ registrationLoading: false });
        });
    } else {
      await ManageDashboardService.getContent(
        session_id,
        table_log_id,
        "registration",
        "creator"
      )
        .then((res) => {
          this.setState({ registrationData: res.data, registrationLoading: false });
        })
        .catch((error) => {
          this.setState({ registrationLoading: false });
        });
    }

    await ManageDashboardService.getStatistics(
      100,
      session_id,
      table_log_id,
      "viewall"
    )
      .then((res) => {

        const subArray = this.buildStatChartArray(res.data)
        const statsArray =  subArray.filter(item => item.chart_type.includes('Statistics'))
        this.setState({ 
          dashboardData: res.data, 
          dashboardDataLoading: false,
          statsArray:statsArray, 
          suitableArray: this.buildStatChartArray(res.data) }, () =>{
            this.fetchSuitableStudentIntake()
          });
      })
      .catch((error) => {
        this.setState({ dashboardDataLoading: false });
      });

    await ManageDashboardService.getContent(
      session_id,
      table_log_id,
      "enrollment",
      "owner"
    )
      .then((res) => {
        this.setState({ enrollmentData: res.data, enrollmentLoading: false });
      })
      .catch((error) => {
        this.setState({ enrollmentLoading: false });
      });
    await ManageDashboardService.getContent(
      session_id,
      table_log_id,
      "enrollment",
      "creator"
    )
      .then((res) => {
        this.setState({
          childEnrollment: res.data,
          studentEnrollmentLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ studentEnrollmentLoading: false });
      });
    await ManageDashboardService.getContent(
      session_id,
      table_log_id,
      "attendance",
      "owner"
    )
      .then((res) => {
        this.setState({ attendance: res.data, attendanceLoading: false });
      })
      .catch((error) => {
        this.setState({ attendanceLoading: false });
      });
    
      await ManageDashboardService.getContent(
        session_id,
        table_log_id,
        "attendance",
        "creator"
      )
        .then((res) => {
          this.setState({ attendanceCreator: res.data, attendanceCreatorLoading: false });
        })
        .catch((error) => {
          this.setState({ attendanceCreatorLoading: false });
        });

    // await ManageDashboardService.getContent(
    //   session_id,
    //   table_log_id,
    //   "assessment-enrolled",
    //   "owner"
    // )
    //   .then((res) => {
    //     this.setState({ paperEnrollemnt: res.data, paperLoading: false });
    //   })
    //   .catch((error) => {
    //     this.setState({ paperLoading: false });
    //   });
    // await ManageDashboardService.getContent(
    //   session_id,
    //   table_log_id,
    //   "assessment-enrolled",
    //   "creator"
    // )
    //   .then((res) => {
    //     this.setState({ childPaperEnroll: res.data, childPaperLoading: false });
    //   })
    //   .catch((error) => {
    //     this.setState({ childPaperLoading: false });
    //   });
  }

  async fetchAllExamsCount() {
    await PaperService.getAllCount().then((res) => {
      this.setState({ totalAllExams: res.data.count });
    });
  }

  async fetchEnrolledAssignedExamsCount() {
    const { userId } = this.state;
    await AssessmentService.getExamsCount(userId, "Assigned").then((res) => {
      this.setState({ totalEnrolledAssignedExams: parseInt(res.data.total_count) });
    });
  }

  async fetchEnrolledOnprogressExamsCount() {
    const { userId } = this.state;
    await AssessmentService.getExamsCount(userId, "Onprogress").then((res) => {
      this.setState({
        totalEnrolledOnprogressExams: parseInt(res.data.total_count),
      });
    });
  }

  async fetchEnrolledCompletedExamsCount() {
    const { userId } = this.state;
    await AssessmentService.getExamsCount(userId, "Finished").then((res) => {
      this.setState({ totalEnrolledCompletedExams: parseInt(res.data.total_count) });
    });
  }

  fetchTotalEnrolledExamsCount() {
    const {
      totalEnrolledAssignedExams,
      totalEnrolledOnprogressExams,
      totalEnrolledCompletedExams,
    } = this.state;
    let totalEnrolledExams =
      totalEnrolledAssignedExams +
      totalEnrolledOnprogressExams +
      totalEnrolledCompletedExams;
    this.setState({ totalEnrolledExams: totalEnrolledExams });
  }

  handleViewMore = () => {
    // Update the state to display more rows
    this.setState((prevState) => ({
      rowsToShow: prevState.rowsToShow + 4, // Increase the number of rows by 4
    }));
  };

  handleViewLess = () => {
    // Update the state to display fewer rows
    this.setState((prevState) => ({
      rowsToShow: Math.max(prevState.rowsToShow - 4, 4), // Decrease the number of rows by 4, but not below 4
    }));
  };

  getColor(state) {
    switch (state) {
      case "Initial":
        return "label label-lg label-light-danger label-inline";
      case "Scheduled":
        return "label label-lg label-light-primary label-inline";
      case "Assigned":
        return "label label-lg label-light-warning label-inline";
      case "Ready":
        return "label label-lg label-light-success label-inline";
      case "Finished":
        return "label label-lg label-light-default label-inline";
      default:
        return "label label-lg label-light-info label-inline";
    }
  }

  displaySubject = (e) => {
    if (e !== null) {
      const subject = this.props.all.allSubjects.find(
        (subject) => subject.subject_id == e
      );
      return subject?.name;
    } else {
      return "N/A";
    }
  };

  renderCards() {
    const { cardData } = this.state;
    return cardData.map((card, index) => (
      <Col key={index} lg={4} md={6} sm={12} className="mb-3">
        <Card>
          <Card.Body>
            <p
              className="py-3 dashboard-card-topic"
              style={{ fontSize: "15px" }}
            >
              {card.ttl}
            </p>
            <span className="dashboard-card-count" style={{ fontSize: "24px" }}>
              {card.cnt}
            </span>
          </Card.Body>
        </Card>
      </Col>
    ));
  }

  buildStatChartArray = (dashboardData) => {
    const groupedData = dashboardData.reduce((acc, obj) => {
      const key = `${obj.chart_type}_${obj.chart_id}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    const arrays = Object.values(groupedData);
    const objectArray = arrays.map(arr => {
      const highestDisplayOrderObject = arr.reduce((maxObj, obj) => {
        if (!maxObj || obj.display_order > maxObj.display_order) {
          return obj;
        } else {
          return maxObj;
        }
      }, null);
      return {
        "array":arr,
        "chart_title":arr[0].chart_title,
        "chart_type":arr[0].chart_type,
        "chart_id":arr[0].chart_id,
        "display_order":highestDisplayOrderObject.display_order
      }
    })
    objectArray.sort((a, b) => a.display_order - b.display_order) 
    console.log('objectArray :', objectArray)
    return objectArray
  }

  fetchSuitableStudentIntake = () => {
    const suitableArray_ = this.state.suitableArray?.find(item => item.chart_type === 'Statistics-bar' && item.chart_id === 1200)
    const groupedData =suitableArray_?.array.reduce((acc, obj) => {
      const key = `${obj.flg_chart_legend_x_filter}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    if(groupedData){
      const filteredGroupedData = Object.values(groupedData)
      this.setState({
        studentIntakes : filteredGroupedData,
        selectedStudentIntake: filteredGroupedData[0]
      })
    }
  }

  renderDoghnutCharts(chart_id, flg_chart_legend_x_filter=undefined) {
    const renderOuterComponent  = (Component, val_) =>{
      return <div className="row d-flex justify-content-around chart-container w-100" style={{ marginBottom: '20px' }}>
          <div className="col-lg-12 col-md-12 d-flex justify-content-center pb-md-3 pb-sm-3">
            <Component val={val_} />
          </div>
        </div> 
    }
    const constructedArray = this.state.suitableArray.map((val,index) => {
      let applyValues = val.array
      if (val.chart_type == "progress_circle"){
        applyValues = applyValues[0]
      }
      if (flg_chart_legend_x_filter){
        applyValues = applyValues.filter(item => item.flg_chart_legend_x_filter === flg_chart_legend_x_filter)
      }
      if(chart_id === val.chart_id){
        if(val.chart_type == "Statistics-bar"){
          return renderOuterComponent(BarChart, applyValues)
        }
        if(val.chart_type == "progress_circle"){
          return renderOuterComponent(ProgressCircle, applyValues) 
        }
        if(val.chart_type == "donut"){
          return renderOuterComponent(DoughnutChart, applyValues)
        }
        if(val.chart_type == "TeacherPay-info2-donut"){
          return renderOuterComponent(DoughnutChart, applyValues)
        }
        if(val.chart_type == "Revenue-info2-donut"){
          return renderOuterComponent(DoughnutChart,applyValues)
        }
        if(val.chart_type == "Statistics-multibar"){
          return renderOuterComponent(GroupedBarChart,applyValues)
        }
      }
    })
    return constructedArray
  }

  renderStatsCharts() {
    const renderOuterComponent  = (Component, val_) =>{
      return <div className="row d-flex justify-content-around chart-container w-100" style={{ marginBottom: '20px' }}>
          <div className="col-lg-12 col-md-12 d-flex justify-content-center pb-md-3 pb-sm-3">
            <Component val={val_} />
          </div>
        </div> 
    }
    const constructedArray = this.state.statsArray.map((val,index) => {
      let applyValues = val.array
      if (val.chart_type == "Statistics-progress_circle"){
        applyValues = applyValues[0]
      }
      if(val.chart_type == "Statistics-figure"){
        return renderOuterComponent(FigureChart,applyValues)
      }
      if(val.chart_type == "Statistics-bar"){
        return renderOuterComponent(BarChart, applyValues)
      }
      if(val.chart_type == "Statistics-progress_circle"){
        return renderOuterComponent(ProgressCircle, applyValues) 
      }
      if(val.chart_type == "Statistics-donut"){
        return renderOuterComponent(DoughnutChart, applyValues)
      }
      if(val.chart_type == "Statistics-multibar"){
        return renderOuterComponent(GroupedBarChart,applyValues)
      }
    })
    return constructedArray
  }

  createArrayWithEqualIdGroups = (arr) => {
    const idGroupMap = {};

    // Group objects by id
    arr.forEach(obj => {
      const id = obj.chart_id;
      idGroupMap[id] = idGroupMap[id] || [];
      idGroupMap[id].push(obj);
    });

    const result = Object.values(idGroupMap).filter(group => group.length > 1);

    return result;
  };

  loaderSection = () => {
    return (
      <div className="loading-container">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )
  }

  renderTeacherDashboard() {

    const {
      totalCountPublicAssessments,
      totalCountMyAssessments,
      managedAssessments,
      myAssessmentsLoading,
      userName,
      dashboardDataLoading
    } = this.state;

    return (
      <>
        <div className="row m-3">
          <div className="col-md-12 mb-3 dashboard__welcome">
            <h2 className="dashboard__welcome-text">
              Hello {userName}! 👋
            </h2>
          </div>
          <div className="col-md-12 px-0">
            <div className="row">
              <div className="col-md-12 px-0">
                <div className="row">
                  <div className="col-lg-9 col-md-9 col-sm-12 px-0" style={{ minHeight: "800px", overflowY: "auto" }}>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 px-0">
                        <div className="row dashboard__container mb-3">
                          <h3 className="dashboard__container-title">
                            <PeopleAltIcon
                              sx={{ fontSize: 22, color: '#5c5c5c' }}
                            />{" "}
                            Exams & Courses
                          </h3>
                          <div className="col-md-12 d-flex px-0">
                            <div className="row w-100 d-flex justify-content-between">
                              <div className="col-lg-2 col-md-4 px-0 mb-sm-3 mb-md-3">
                                <div className="dashboard__count-cards-pink mr-1 mr-sm-0 mr-md-0">
                                  <h3 className="dashboard__user-text m-0">Initial</h3>
                                  <h1 className="dashboard__user-count m-0">5</h1>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 px-0">
                                <div className="dashboard__count-cards-blue ml-1 ml-sm-0 ml-md-0">
                                  <h3 className="dashboard__user-text m-0">Fianlized</h3>
                                  <h1 className="dashboard__user-count m-0">32</h1>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 px-0">
                                <div className="dashboard__count-cards-green ml-1 ml-sm-0 ml-md-0">
                                  <h3 className="dashboard__user-text m-0">Upcoming</h3>
                                  <h1 className="dashboard__user-count m-0">24</h1>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 px-0">
                                <div className="dashboard__count-cards-purple ml-1 ml-sm-0 ml-md-0">
                                  <h3 className="dashboard__user-text m-0">Inprogress</h3>
                                  <h1 className="dashboard__user-count m-0">11</h1>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 px-0">
                                <div className="dashboard__count-cards-orange ml-1 ml-sm-0 ml-md-0">
                                  <h3 className="dashboard__user-text m-0">Pending Review</h3>
                                  <h1 className="dashboard__user-count m-0">5</h1>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 px-0">
                                <div className="dashboard__count-cards-dodger ml-1 ml-sm-0 ml-md-0">
                                  <h3 className="dashboard__user-text m-0">Courses</h3>
                                  <h1 className="dashboard__user-count m-0">5</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 px-0">
                        <Income />
                      </div>
                      <div className="col-lg-8 col-md-12 pr-0 pl-sm-0" style={{ height: "630px", overflowY: "auto" }}>
                        <div className="row dashboard__container mb-sm-3 mb-md-3">
                          <h3 className="dashboard__container-title">
                            <InsertChartIcon
                              sx={{ fontSize: 22, color: '#5c5c5c' }}
                            />{" "}
                            Statistics
                          </h3>
                          <div className="col-md-12 px-0">
                            <div className="row">
                              (Course Coverage Progress Circles Here)
                            </div>
                            <div className="row">
                              (Paper Completion Dounut Charts Here)
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 px-0 mt-3">
                        <div className="row dashboard__container mb-sm-3 mb-md-3">
                          <h3 className="dashboard__container-title">
                            <QuizIcon
                              sx={{ fontSize: 22, color: '#5c5c5c' }}
                            />{" "}
                            My Exams
                          </h3>
                          <div className="col-md-12 px-0" style={{ overflow: "auto" }}>
                            <TeacherExams />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 pr-0 px-sm-0 px-md-0">
                    <div className="row">
                      <div className="col-md-12 mb-3 d-flex justify-content-between dashboard__container">
                        {this.renderQuickGuide()}
                      </div>
                      <div className="col-md-12 px-0 py-0 mb-3 dashboard__container">
                        <ManageMainCalendar fromDashboard={true}/>
                      </div>
                      { (!!this.state.announcements && !!this.state.announcements.length) && <div className="col-md-12 dashboard__container" style={{ height: "310px", overflow: "auto" }}>
                        <h3 className="dashboard__container-title mb-2">
                          <CampaignRoundedIcon
                            sx={{ fontSize: 22, color: '#5c5c5c' }}
                          />{" "}
                          Announcements
                        </h3>
                        {
                          this.state.announcements?.map(announcement =>{
                            return  <AnnouncementCard  announcement={announcement}/>
                          })
                        }
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Card>
          <CardHeader>
            <AssessmentCardTitle cardLabel={welcomeAssessmentText} />
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-12 pb-12">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row dashboard-top-container mr-1">
                      <div className="col-md-12 d-flex">
                        <p className="px-0 py-2 d-flex text-nowrap section-title">
                          {" "}
                          <QuizIcon sx={{ fontSize: 24, color: iconColor }} />{" "}
                          <Link to={routes.questions_library}><p style={{ color: iconColor }}>Questions</p></Link>
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="row px-2">
                          <div className="col-md-3">
                            <div className="row py-2 dashboard-cards">
                              <div className="col-md-12">Questions</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  this.state.questionCount || 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">Categories</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingCategories ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  this.state.categoryCount || 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">Types</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  this.state.typeCount || 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">Subjects</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  this.state.subjectCount || 0
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="row dashboard-top-container">
                      <div className="col-md-12 d-flex">
                        <p className="px-0 py-2 d-flex text-nowrap section-title">
                          {" "}
                          <TaskIcon sx={{ fontSize: 24, color: iconColor }} />{" "}
                          <Link to={routes.view_assessment}><p style={{ color: iconColor }}>Assessments</p></Link>
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="row px-2">
                          <div className="col-md-5">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">My Assessments</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  totalCountMyAssessments || 0
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="row py-3 px-2 dashboard-cards">
                              <div className="col-md-12">Shared with me</div>
                              <div className="col-md-12 card-value">
                                {this.state.loadingQuestions ? (
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  (totalCountPublicAssessments - totalCountMyAssessments) || 0
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="pb-3 section-title">
                          {" "}
                          <GradeIcon sx={{ fontSize: 24, color: iconColor }} /> Latest Assessments
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div
                          className="dashboard-bottom-container"
                          style={{ overflowX: "auto" }}
                        >
                          {managedAssessments ? (
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Code</TableCell>
                                  <TableCell>Created On</TableCell>
                                  <TableCell>Title</TableCell>
                                  <TableCell>Subject</TableCell>
                                  <TableCell>State</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {myAssessmentsLoading ? (
                                  // Display centered loading spinner
                                  <div
                                    className="stu-card-title pt-2 col-md-12"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </div>
                                ) : (
                                  managedAssessments
                                    .slice(0, this.state.rowsToShow)
                                    .map(
                                      (assessment, i) => (
                                        (
                                          <TableRow key={i}>
                                            <TableCell className="tData"></TableCell> //comment
                                            <TableCell className="tData">{i + 1}</TableCell> //comment
                                            <TableCell className="tData">
                                              <div className="center-vertical mg-m library-title">
                                                {"ASMNT" +
                                                  assessment.assessment_id}
                                              </div>
                                            </TableCell>
                                            <TableCell className="tData">
                                              <div className="center-vertical mg-m library-title">
                                                {new Date(
                                                  assessment.created_at
                                                ).toLocaleDateString()}
                                              </div>
                                            </TableCell>
                                            <TableCell className="tData">
                                              <Tooltip
                                                title={assessment.title}
                                                placement="bottom-start"
                                                arrow
                                              >
                                                <div className="center-vertical mg-m library-title">
                                                  {assessment.title.length > 20
                                                    ? `${assessment.title.substring(
                                                      0,
                                                      20
                                                    )}...`
                                                    : assessment.title}
                                                </div>
                                              </Tooltip>
                                            </TableCell>
                                            <TableCell className="tData">
                                              <div className="center-vertical mg-m library-title">
                                                {this.displaySubject(
                                                  assessment.subject_id
                                                )}
                                              </div>
                                            </TableCell>
                                            <TableCell className="tData">
                                              <span
                                                className={this.getColor(
                                                  assessment.assessment_state
                                                )}
                                              >
                                                {assessment.assessment_state}
                                              </span>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )
                                    )
                                )}
                              </TableBody>
                            </Table>
                          ) : (
                            <p></p>
                          )}
                          {managedAssessments &&
                            managedAssessments.length >
                            this.state.rowsToShow && (
                              <div className="py-5 pr-5 d-flex justify-content-end">
                                <p
                                  className="view-more"
                                  onClick={this.handleViewMore}
                                >
                                  View More /
                                </p>
                                <br></br>
                                <br></br>
                                <p
                                  className="view-more"
                                  onClick={this.handleViewLess}
                                >
                                  View Less
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="pb-3 section-title">
                          {" "}
                          <CampaignRoundedIcon sx={{ fontSize: 24 }} />{" "}
                          Announcements
                        </p>
                      </div>
                      <div className="col-md-12 announcement-container">
                        {this.state.announcementsLoading ? (
                          <div
                            className="stu-card-title pt-2 col-md-12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <i className="fas fa-spinner fa-spin"></i>
                          </div>
                        ) : Array.isArray(this.state.announcements) &&
                          this.state.announcements.length > 0 ? (
                          this.state.announcements
                            .slice(-3)
                            .reverse()
                            .map((announcement, i) => (
                              <TeacherAnnouncementCard
                                announcements={announcement}
                                key={i}
                              />
                            ))
                        ) : (
                          <p className="py-4 px-3">No announcements</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card> */}
      </>
    );
  }

  toggleAddStudentModal = () => {
    this.setState((prevState) => ({
      isAddStudentModalOpen: !prevState.isAddStudentModalOpen,
    }), () => {
      if (!this.state.isAddStudentModalOpen) {
        this.fetchStudentAndRegCountDashboard()
      }
    });
  };

  toggleAddTeacherModal = () => {
    this.setState((prevState) => ({
      isAddTeacherModalOpen: !prevState.isAddTeacherModalOpen,
    }), () => {
      if (!this.state.isAddTeacherModalOpen) {
        this.fetchStudentAndRegCountDashboard()
      }
    });
  };

  renderAdminDashboard() {
    const {
      userName,
      dashboardDataLoading,
      stuListCountAdmin
    } = this.state;

    return (
      <div className="row my-3" style={{ minHeight: "90vh" }}>
        <div className="col-md-12 mb-3 dashboard__welcome">
          <h2 className="dashboard__welcome-text">
            Hello {userName}! 👋
          </h2>
        </div>
        <div className="col-md-12 px-0">
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="row">
                <div className="col-lg-3 col-md-6 pl-0 px-sm-0">
                  <div className="row dashboard__container mb-3">
                    <h3 className="dashboard__container-title">
                      <PeopleAltIcon
                        sx={{ fontSize: 22, color: '#5c5c5c' }}
                      />{" "}
                      Users
                    </h3>
                    <div className="col-md-12 d-flex px-0">
                      <div className="row w-100">
                        <div className="col-lg-6 col-md-12 px-0 mb-sm-3 mb-md-3">
                          <div className="dashboard__count-cards-pink mr-1 mr-sm-0 mr-md-0">
                            <h3 className="dashboard__user-text m-0">Students</h3>
                            {this.state.stuListLoading ? (
                                <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <i className="fas fa-spinner fa-spin"></i>
                                </div>
                              ) : (
                            <h1 className="dashboard__user-count m-0">{stuListCountAdmin?.registration_count?.student_count}</h1>
                            )
                            }
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 px-0">
                          <div className="dashboard__count-cards-pink ml-1 ml-sm-0 ml-md-0">
                            <h3 className="dashboard__user-text m-0">Teachers</h3>
                            {this.state.stuListLoading ? (
                                <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <i className="fas fa-spinner fa-spin"></i>
                                </div>
                              ) : (
                            <h1 className="dashboard__user-count m-0">{stuListCountAdmin?.registration_count?.teacher_count}</h1>
                            )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 pl-0 px-sm-0">
                  <div className="row dashboard__container mb-3">
                    <h3 className="dashboard__container-title">
                      <PersonAddIcon
                        sx={{ fontSize: 24, color: '#5c5c5c' }}
                      />{" "}
                      User Registrations
                    </h3>
                    <div className="col-md-12 d-flex px-0">
                      <div className="row w-100">
                        <div className="col-lg-6 col-md-12 px-0 mb-sm-3 mb-md-3">
                          <div className="dashboard__count-cards-blue mr-1 mr-sm-0 mr-md-0 d-flex justify-content-between">
                            <h3 className="dashboard__user-text m-0 d-flex align-items-center">Students</h3>
                            <IconButton onClick={() => this.toggleAddStudentModal()}>
                              <AddCircleIcon sx={{ fontSize: 26, color: "#fff" }} />
                            </IconButton>
                            {this.state.isAddStudentModalOpen && <CgcAddStudentModal isAdmin={sessionStorage.getItem("userType") === "admin"} isStudent={sessionStorage.getItem("userType") === "cgc_student"} isAddStudentModalOpen={this.state.isAddStudentModalOpen} toggleAddStudentModal={this.toggleAddStudentModal} />}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 px-0">
                          <div className="dashboard__count-cards-blue ml-1 ml-sm-0 ml-md-0 d-flex justify-content-between">
                            <h3 className="dashboard__user-text m-0 d-flex align-items-center">Teachers</h3>
                            <IconButton onClick={() => this.toggleAddTeacherModal()}>
                              <AddCircleIcon sx={{ fontSize: 26, color: "#fff" }} />
                            </IconButton>
                            {this.state.isAddTeacherModalOpen && <AddTeacherModal isAddTeacherModalOpen={this.state.isAddTeacherModalOpen} toggleAddTeacherModal={this.toggleAddTeacherModal} fetchAllTeachers={null} />}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 pl-0 px-sm-0">
                  <div className="row dashboard__container mb-3">
                    <h3 className="dashboard__container-title">
                      <ClassIcon
                        sx={{ fontSize: 22, color: '#5c5c5c' }}
                      />{" "}
                      Courses & Enrollments
                    </h3>
                    <div className="col-md-12 d-flex px-0">
                      <div className="row w-100">
                        <div className="col-lg-6 col-md-12 px-0 mb-sm-3 mb-md-3">
                        
                          <div className="dashboard__count-cards-green mr-1 mr-sm-0 mr-md-0">
                            <h3 className="dashboard__user-text m-0">Enrolled Places</h3>
                            {this.state.stuListLoading ? (
                                <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <i className="fas fa-spinner fa-spin"></i>
                                </div>
                              ) : (
                                <h1 className="dashboard__user-count m-0">{stuListCountAdmin?.registration_count?.node_mid_count}</h1>
                              )
                            }
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 px-0">
                          <div className="dashboard__count-cards-green ml-1 ml-sm-0 ml-md-0">
                            <h3 className="dashboard__user-text m-0">Enrollments</h3>
                            {this.state.stuListLoading ? (
                                <div className="stu-card-title pt-2 col-md-12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  <i className="fas fa-spinner fa-spin"></i>
                                </div>
                              ) : (
                                <h1 className="dashboard__user-count m-0">{stuListCountAdmin?.registration_count?.enrollement_count}</h1>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 px-0 px-sm-0">
                  <div className="row dashboard__container mb-3">
                    <h3 className="dashboard__container-title">
                      <AssignmentIcon
                        sx={{ fontSize: 22, color: '#5c5c5c' }}
                      />{" "}
                      All Exams
                    </h3>
                    <div className="col-md-12 d-flex px-0">
                      <div className="row w-100">
                        <div className="col-lg-6 col-md-12 px-0 mb-sm-3 mb-md-3">
                          <div className="dashboard__count-cards-purple mr-1 mr-sm-0 mr-md-0">
                            <h3 className="dashboard__user-text m-0">Upcoming</h3>
                            <h1 className="dashboard__user-count m-0">24</h1>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 px-0 mb-sm-3 mb-md-3">
                          <div className="dashboard__count-cards-purple mr-1 mr-sm-0 mr-md-0">
                            <h3 className="dashboard__user-text m-0">Inprogress</h3>
                            <h1 className="dashboard__user-count m-0">24</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 px-0">
              <div className="row">
                <div className="col-lg-9 col-md-12 px-0">
                  <div className="row">
                    <div className="col-md-4 px-0">
                      <Receivables 
                        suitableArray = {this.state.suitableArray}
                        dashboardDataLoading={dashboardDataLoading}
                        loaderSection = {this.loaderSection}
                      />
                      <Revenue 
                          dashboardDataLoading={dashboardDataLoading}
                          loaderSection = {this.loaderSection}
                          renderDoghnutCharts = {(id, chart_flg) =>this.renderDoghnutCharts(id, chart_flg)}
                          suitableArray = {this.state.suitableArray}                          
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="row dashboard__container mb-3">
                        <h3 className="dashboard__container-title my-3">
                          <InsertChartIcon
                            sx={{ fontSize: 22, color: '#5c5c5c' }}
                          />{" "}
                          Statistics
                        </h3>
                        <div className="">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#fff",
                                border: "1px solid #E4E6EF",
                                color: "var(--secondary-text-color)",
                                fontWeight: "400",
                                fontSize: "10px"
                              }}
                              
                            >
                              {this.state.selectedStudentIntake&&this.state.selectedStudentIntake[0]?.flg_chart_legend_x_filter}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-100">
                              {this.state.studentIntakes && this.state.studentIntakes.map(obj => (
                                <Dropdown.Item
                                  onClick={() => {this.setState({selectedStudentIntake: obj})}}
                                >
                                  {obj[0].flg_chart_legend_x_filter}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>                       
                        <div className="col-md-12 px-0 d-flex justify-content-center py-3">
                          {dashboardDataLoading ? (this.loaderSection()) : (this.state.selectedStudentIntake && this.renderDoghnutCharts(1200,this.state.selectedStudentIntake[0]?.flg_chart_legend_x_filter))}
                        </div>
                        <div className="col-md-12 px-0 d-flex justify-content-center py-3">
                          {dashboardDataLoading ? (this.loaderSection()) : (this.renderDoghnutCharts(1300))}
                        </div>
                        <div className="col-md-12 px-0 d-flex justify-content-center py-3">
                        {dashboardDataLoading ? (this.loaderSection()) : (this.renderDoghnutCharts(1400))}
                        </div>
                      </div>
                      <div className="row mb-3">
                        <TeacherPayments 
                          dashboardDataLoading={dashboardDataLoading}
                          loaderSection = {this.loaderSection}
                          renderDoghnutCharts = {()=>this.renderDoghnutCharts(1500)}
                          suitableArray = {this.state.suitableArray}     
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 px-0">
                  <div className="row">
                    <div className="col-md-12 mb-3 d-flex justify-content-between dashboard__container">
                      {this.renderQuickGuide()}
                    </div>
                    <div className="col-md-12 px-0 py-0 mb-3 dashboard__container">
                      <ManageMainCalendar fromDashboard={true}/>
                    </div>
                    <div className="col-md-12 dashboard__container" style={{ height: "50vh", overflow: "auto" }}>
                      <h3 className="dashboard__container-title mb-2">
                        <CampaignRoundedIcon
                          sx={{ fontSize: 22, color: '#5c5c5c' }}
                        />{" "}
                        Announcements
                      </h3>
                        {
                          this.state.announcements?.map(announcement =>{
                            return  <AnnouncementCard  announcement={announcement}/>
                          })
                        }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-9 mb-sm-3 mb-md-3 pl-0 pl-sm-0">
              <div className="row dashboard__container">
                <h3 className="dashboard__container-title">
                  <InsertChartIcon
                    sx={{ fontSize: 22, color: '#5c5c5c' }}
                  />{" "}
                  Statistics
                </h3>
                  <div className="col-md-12 px-0">{dashboardDataLoading ? (this.loaderSection()) : (this.renderDoghnutCharts())}</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  }

  renderSections = () => {
    const lg_user_table_id = sessionStorage.getItem("table_log_id");
    const lg_user_id = sessionStorage.getItem("manage_user_id");
    let qrData = {
      lg_user_id: lg_user_id,
      lg_user_table_id: lg_user_table_id
    }
    let cardTitle = "My Registrations"; // Default title

    const userType = sessionStorage.getItem("userType");
    if (userType === "admin") {
      cardTitle = "Registrations";
    } else if (userType === "parent") {
      cardTitle = "My Student Registrations";
    }

    return (
      <>
        {this.state.registrationLoading ? (
          this.loaderSection()
        ) : (
          <div className="mb-2">
            {(!!this.state.registrationData && !!this.state.registrationData.length) && (
              <div className="col-md-9 px-0">
                <CardHeader>
                  <CardTitle cardLabel={cardTitle} />
                </CardHeader>
                <CardBody>
                  {this.state.registrationData?.map((data) => this.cardData(data))}
                </CardBody>
              </div>
            )}
          </div >
        )
        }
        {
          this.state.enrollmentLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              { (!!this.state.enrollmentData && !!this.state.enrollmentData.length)&& (
                <Card>
                  <CardHeader>
                    <CardTitle cardLabel="My Enrollments" />
                  </CardHeader>
                  <CardBody>
                    {this.state.enrollmentData.map((data) => this.cardData(data))}
                  </CardBody>
                </Card>
              )}
            </div>
          )
        }
        {
          this.state.studentEnrollmentLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              {(!!this.state.childEnrollment && !!this.state.childEnrollment.length) && (
                <div className="col-md-9 px-0">
                  <CardHeader>
                    <CardTitle cardLabel="Students’ Enrollments" />
                  </CardHeader>
                  <CardBody>
                    {this.state.childEnrollment.map((data) => this.cardData(data))}
                  </CardBody>
                </div>
              )}
            </div>
          )
        }
        {
          this.state.attendanceLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              {(!!this.state.attendance && !!this.state.attendance.length) && (
                <Card>
                  <CardHeader>
                    <CardTitle cardLabel="My Attendance" />
                  </CardHeader>
                  <CardBody>
                    {this.state.attendance.map((data) => this.cardData(data))}
                  </CardBody>
                </Card>
              )}
            </div>
          )
        }
        {
          this.state.attendanceCreatorLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-2">
              {(!!this.state.attendanceCreator && !!this.state.attendanceCreator?.length) && (
                <div className="col-md-9 px-0">
                  <CardHeader>
                    <CardTitle cardLabel="Attendance" />
                  </CardHeader>
                  <CardBody>
                    {this.state.attendanceCreator?.map((data) => this.cardData(data))}
                  </CardBody>
                </div>
              )}
            </div>
          )
        }
        {
          this.state.paperLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              {(!!this.state.paperEnrollemnt && !!this.state.paperEnrollemnt.length)  && (
                <Card>
                  <CardHeader>
                    <CardTitle cardLabel="Your Paper Enrollments" />
                  </CardHeader>
                  <CardBody>
                    {this.state.paperEnrollemnt.map((data) => this.cardData(data))}
                  </CardBody>
                </Card>
              )}
            </div>
          )
        }
        {
          this.state.childPaperLoading ? (
            this.loaderSection()
          ) : (
            <div className="mb-3">
              {(!!this.state.childPaperEnroll && !!this.state.childPaperEnroll.length)  && (
                <Card>
                  <CardHeader>
                    <CardTitle cardLabel="Papers enrolled for students" />
                  </CardHeader>
                  <CardBody>
                    {this.state.childPaperEnroll.map((data) => this.cardData(data))}
                  </CardBody>
                </Card>
              )}
            </div>
          )
        }
      </>
    );
  };

  cardData = (data) => {
    const formattedStr = data.breadcrumb.replaceAll(">", " > ")
    return (
      <div className="row d-flex align-items-center my-2">
        <div className="col-md-8">
          <p>
            {" "}
            {formattedStr}
            {`${data?.tree_main_title}`}
          </p>
        </div>
        <div className="col-md-4 d-flex justify-content-start">
          <Button
            className="insert-btn"
            style={{ backgroundColor: "#5458af", border: "none", width: "230px" }}
            onClick={() => this.handleApplicationSelect(data)}
          >
            {data?.form_display_title}
          </Button>
        </div>
      </div>
    );
  };

  renderQuickGuide = () => {
    return (
      <div>
        <h3 className="dashboard__container-title d-flex align-items-center my-2">
          <FontAwesomeIcon
            icon={faFilePdf}
            color='#5c5c5c'
            fontSize="18px"
            style={{ marginRight: "5px" }}
          /> {" "}
          Quick Guides
        </h3>
        <a href={teacheradminguide} without rel="noopener noreferrer" target="_blank" className="col-md-12 px-0">
          <button trailingIcon="picture_as_pdf" label="QuickGuides" className="quickguide-button">
            Click to view the quick guides
          </button>
        </a>
      </div>
    )
  }

  render() {
    const {
      totalAllExams,
      totalEnrolledCompletedExams,
      totalEnrolledExams,
      userName,
      dataLoading,
      shouldShowChildDashboard,
      shouldShowTeacherDashboard,
      shouldShowAdminDashboard,
      dashboardDataLoading,
    } = this.state;
    return (
      <AdminTemplate>
        <ContentWrapper>
          <div style={{ height: "90vh", overflowY: "auto" }}>
          <Card>
            {shouldShowChildDashboard && (
              <div className="row m-3">
                <div className="col-md-12 mb-3 dashboard__welcome">
                  <h2 className="dashboard__welcome-text">
                    Hello {userName}! 👋
                  </h2>
                </div>
                <div className="col-md-12 px-0">
                  <div className="row">
                    <div className="col-md-9 px-0 mb-sm-3 mb-md-3">
                      <div className="row">
                        <div className="col-md-12 dashboard__container mb-3">
                          <h3 className="dashboard__container-title mt-3">
                            <QuizIcon
                              sx={{ fontSize: 22, color: '#5c5c5c' }}
                            />{" "}
                            My Exams
                          </h3>
                          <StudentExams />
                        </div>
                        <div className="col-lg-4 col-md-5 px-0 mb-sm-3">
                          <div className="row">
                            <TotalOutStanding
                            suitableArray = {this.state.suitableArray}
                            dashboardDataLoading={dashboardDataLoading}
                            loaderSection = {this.loaderSection}
                            />
                            <div className="col-md-12 dashboard__container">
                              <h3 className="dashboard__container-title m-0 mt-3">
                                Payment Overdue
                              </h3>
                              <PaymentOverdue 
                              suitableArray = {this.state.suitableArray}
                              dashboardDataLoading={dashboardDataLoading}
                              loaderSection = {this.loaderSection}
                              />
                              
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-7 pr-lg-0 px-sm-0">
                          <div className="dashboard__container">
                            <h3 className="dashboard__container-title">
                              <InsertChartIcon
                                sx={{ fontSize: 22, color: '#5c5c5c' }}
                              />{" "}
                              Statistics
                            </h3>
                            {dashboardDataLoading ? (this.loaderSection()) : (this.renderStatsCharts())}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 pr-0 px-sm-0 px-md-0">
                      <div className="row">
                        <div className="col-md-12 mb-3 d-flex justify-content-between dashboard__container">
                          <div>
                            <h3 className="dashboard__container-title">
                              <QrCodeScannerIcon
                                sx={{ fontSize: 22, color: '#5c5c5c' }}
                              />{" "}
                              My QR Code
                            </h3>
                            <p className="dashboard__container-text">
                              Use the QR code to mark your course attendance
                            </p>
                          </div>
                          <QRgenerator />
                        </div>
                        <div className="col-md-12 px-0 py-0 mb-3 dashboard__container">
                          <ManageMainCalendar fromDashboard={true}/>
                        </div>
                        <div className="col-md-12 mb-3 dashboard__container">
                          <h3 className="dashboard__container-title mb-2">
                            <CampaignRoundedIcon
                              sx={{ fontSize: 22, color: '#5c5c5c' }}
                            />{" "}
                            Announcements
                          </h3>
                          {
                            this.state.announcements?.map(announcement =>{
                              return  <AnnouncementCard  announcement={announcement}/>
                            })
                          }
                        </div>
                        <div className="col-md-12 dashboard__container">
                          <h3 className="dashboard__container-title">
                            <DescriptionIcon
                              sx={{ fontSize: 22, color: '#5c5c5c' }}
                            />{" "}
                            My Documents
                          </h3>
                          <DocumentCard />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              // <div className="row">
              //   <div className="col-md-12">
              //     <Card id="welcome-card">
              //       <CardBody
              //         style={{
              //           background:
              //             "linear-gradient(118deg, rgba(54, 153, 255, 1), rgba(54, 153, 255, 0.7))",
              //         }}
              //       >
              //         <div className="text-center py-5">
              //           <h1 className="pt-5 welcome-card-topic">
              //             Welcome to EdFoci!
              //           </h1>
              //           <p className="py-5 welcome-card-para">
              //             Our fun-filled learning platform. Get ready embark on
              //             an exciting adventure of knowledge!
              //           </p>
              //         </div>
              //       </CardBody>
              //     </Card>
              //   </div>
              //   {/* Name cards */}
              //   <div className="col-md-12">
              //     <div className="row pt-5 pb-5">
              //       <div className="stu-dashboard-cards col-md-3">
              //         <div className="row">
              //           <div className="col-md-12">Name</div>
              //           <div className="stu-card-title pt-2 col-md-12">
              //             {userName}
              //           </div>
              //         </div>
              //       </div>
              //       <div className="stu-dashboard-cards col-md-3">
              //         <div className="row">
              //           <div className="col-md-12">All Papers</div>
              //           {dataLoading ? (
              //             <div className="stu-card-title pt-2 col-md-12">
              //               <i className="fas fa-spinner fa-spin"></i>
              //             </div>
              //           ) : (
              //             <div className="stu-card-title pt-2 col-md-12">
              //               {totalAllExams ? totalAllExams : "0"}
              //             </div>
              //           )}
              //         </div>
              //       </div>
              //       <div className="stu-dashboard-cards col-md-3">
              //         <div className="row">
              //           <div className="col-md-12">Enrolled Papers</div>
              //           {dataLoading ? (
              //             <div className="stu-card-title pt-2 col-md-12">
              //               <i className="fas fa-spinner fa-spin"></i>
              //             </div>
              //           ) : (
              //             <div className="stu-card-title pt-2 col-md-12">
              //               {totalEnrolledExams}
              //             </div>
              //           )}
              //         </div>
              //       </div>
              //       <div className="stu-dashboard-cards col-md-3">
              //         <div className="row">
              //           <div className="col-md-12">Completed Papers</div>
              //           {dataLoading ? (
              //             <div className="stu-card-title pt-2 col-md-12">
              //               <i className="fas fa-spinner fa-spin"></i>
              //             </div>
              //           ) : (
              //             <div className="stu-card-title pt-2 col-md-12">
              //               {totalEnrolledCompletedExams}
              //             </div>
              //           )}
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            )}
            <div>
              <Row>
                <Col md={12} className="mb-4">
                  <Card id="welcome-card"></Card>
                  {shouldShowTeacherDashboard && this.renderTeacherDashboard()}
                  {shouldShowAdminDashboard && this.renderAdminDashboard()}
                  {/* {this.renderSections()} */}
                </Col>
              </Row>
            </div>
          </Card>
          </div>
        </ContentWrapper>
      </AdminTemplate>
    )
  }
}

function mapStateToProps(state) {
  return {
    all: state.assessmentReducer
  };
}

const mapActionsToProps = {
  getAssessmentSubject: assessmentActions.getAssessmentSubject
};

export default connect(mapStateToProps, mapActionsToProps)(EdFociManageDashboard);