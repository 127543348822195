// src/AreaChart.js

import React from "react"
import { Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js"
import config from "../../../helper/config"
import { customerConstants } from "../../../helper/constants.js"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

let BGColor, hoverBGColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    BGColor = {
      backgroundColor: [
        "#238BCE",
        "#1A8FE3",
        "#D9DBF1",
        "#7E8299",
        "#ffc47c",
        "#61678c",
        "#8ca4f4",
        "#afbef4",
        "#c4c4bd",
        "#8cdcb4",
        "#6c85ec",
        "#dbdcd6",
        "#94a4f4",
        "#bcbcc4",
        "#fce45f",
      ],
    }
    hoverBGColor = {
      backgroundColor: [
        "#5458AF",
        "#1A8FE3",
        "#D9DBF1",
        "#7E8299",
        "#ffc47c",
        "#61678c",
        "#8ca4f4",
        "#afbef4",
        "#c4c4bd",
        "#8cdcb4",
        "#6c85ec",
        "#dbdcd6",
        "#94a4f4",
        "#bcbcc4",
        "#fce45f",
      ],
    }
    break
  case customerConstants.EXO_CUSTOMER2:
    BGColor = {
      backgroundColor: ["#216694", "#2980b9", "#5499c7", "#7fb3d5", "#a9cce3"],
    }
    hoverBGColor = {
      backgroundColor: ["#216694", "#2980b9", "#5499c7", "#7fb3d5", "#a9cce3"],
    }
    break
  default:
    BGColor = {
      backgroundColor: [
        "#238BCE",
        "#32B965",
        "#7558A8",
        "#F249A4",
        "#D53364",
        "#FFA31E",
        "#fce45f",
        "#00AEE0",
      ],
    }
    hoverBGColor = {
      backgroundColor: [
        "#238BCE",
        "#32B965",
        "#7558A8",
        "#F249A4",
        "#D53364",
        "#FFA31E",
        "#fce45f",
        "#00AEE0",
      ],
    }
    break
}

const getDatasets = () => {
  return [
    {
      label: "Course 1",
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderWidth: 0.5,
      backgroundColor: "rgba(213,51,100,0.6)",
      borderColor: "rgba(213,51,100)",
      tension: 0,
    },
    {
      label: "Course 2",
      data: [28, 48, 40, 19, 86, 27, 90],
      fill: false,
      borderWidth: 0.5,
      backgroundColor: "rgba(8,174,224,0.6)",
      borderColor: "rgba(8,174,224)",
      tension: 0,
    },
    {
      label: "Course 3",
      data: [12, 30, 50, 70, 20, 60, 80],
      fill: false,
      borderWidth: 0.5,
      backgroundColor: "rgba(255,168,0,0.6)",
      borderColor: "rgba(255,168,0)",
      tension: 0,
    },
  ]
}

const AreaChart = ({ height }) => {
  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: getDatasets(),
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Poppins",
            size: 10,
          },
        },
      },
      title: {
        display: true,
        // text: 'Area Chart Example',
      },
      tooltip: {
        bodyFont: {
          family: "Poppins",
        },
        titleFont: {
          family: "Poppins",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: "Poppins",
            size: 10,
          },
        },
        title: {
          display: true,
          text: 'Month',
          font: {
            family: "Poppins",
            size: 10,
          },
        }
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            family: "Poppins",
            size: 10,
          },
        },
        title: {
          display: true,
          text: 'Revenue',
          font: {
            family: "Poppins",
            size: 10,
          },
        }
      },
    },
    maintainAspectRatio: false,
    aspectRatio: 1
  }

  return (
    <div className="chart-container col-md-12 px-0" style={{ height: "300px" }}>
      <Line data={data} options={options} />
    </div>
  )
}

export default AreaChart
