import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const manageUserService = {
    getTeacherMe: getTeacherMe,
    getTeacherDetails: getTeacherDetails,
    getTeacherDetailsForStudent: getTeacherDetailsForStudent,
    getStudentDetails: getStudentDetails,
    getAllTeachers: getAllTeachers,
    getAllStudents: getAllStudents,
    getAllAnnouncementsAdmin: getAllAnnouncementsAdmin
};

async function getTeacherMe() {

    var teacher_id = sessionStorage.getItem("manage_user_id");
    var teacher_table_id = sessionStorage.getItem("table_log_id");

    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/registration/teachers/getTeacherForAssessment/${teacher_id}/${teacher_table_id}`, requestOptions).then(APIHandler.handleResponse);

}

async function getTeacherDetails(teacher_id) {

    var teacher_table_id = sessionStorage.getItem("table_log_id");

    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/registration/teachers/getTeacherDetailsForAssessment/${teacher_id}/${teacher_table_id}`, requestOptions).then(APIHandler.handleResponse);

}

async function getTeacherDetailsForStudent(teacher_id) {

    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/registration/teachers/getTeacherDetailsForStudent/${teacher_id}`, requestOptions).then(APIHandler.handleResponse);

}

async function getAllTeachers() {

    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/registration/teachers/getTeachersForAssessment`, requestOptions).then(APIHandler.handleResponse);

}

async function getAllStudents() {

    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/registration/students/getStudentsForAssessment`, requestOptions).then(APIHandler.handleResponse);

}

async function getStudentDetails(student_id) {

    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/registration/students/get/${student_id}`, requestOptions).then(APIHandler.handleResponse);

}

async function getAllAnnouncementsAdmin(lg_user_id, lg_user_table_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };
    return fetch(
        `${config.oat_api_url}/manage/announcements/get-announcement-admin-dashboard/${lg_user_id}/${lg_user_table_id}`,
        requestOptions
    ).then(APIHandler.handleResponse);

}
