import React, { Component } from "react";
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
  } from "@mui/material";
import { Spinner, Form } from "react-bootstrap";
import ContentWrapper from "../../../components/common/ContentWrapper";
import AdminTemplate from "../../../components/admin/layouts/Template";
import { TreeMainService } from "../../../service/manage/h_tree/tree_main.service";
import { history } from "../../../helper/history";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardTitle from "../../../components/common/CardTitle";
import {formFixture} from '../hierarchy/FormFixture'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

class Sitemap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbs: [],
      isTableLoading: true,
      search_term: "",
      sortColumn: "breadcrumb",
      sortDirection: "asc",
    };
  }

  async componentDidMount() {
    await this.loadSiteMap();
  }

  loadSiteMap = async () => {
    const { sortColumn, sortDirection, search_term } = this.state;
    const originalData = this.state.originalData || [];

    if (originalData.length === 0) {
      try {
        const siteMapData = await TreeMainService.getSiteMap(
          10,
          -1,
          sessionStorage.getItem("manage_user_id"),
          sessionStorage.getItem("table_log_id"),
          sortColumn,
          sortDirection,
          search_term
        );

        this.setState({
          breadcrumbs: siteMapData.data,
          originalData: siteMapData.data,
          isTableLoading: false,
        });
      } catch (error) {
        console.error("Error fetching site map data:", error);
        this.setState({ isTableLoading: false });
      }
    } else {
      const caseInsensitiveSearchTerm = search_term.toLowerCase();

      const filteredData = originalData.filter((breadcrumb) =>
        breadcrumb.breadcrumb.toLowerCase().includes(caseInsensitiveSearchTerm) ||
        breadcrumb.form_display_title.toLowerCase().includes(caseInsensitiveSearchTerm)
      );

      const sortedData = this.sortData(
        filteredData,
        sortColumn,
        sortDirection
      );

      this.setState({ breadcrumbs: sortedData, isTableLoading: false });
    }
  };

  sortData = (data, sortColumn, sortDirection) => {
    return data.sort((a, b) => {
      const aValue = a[sortColumn].toLowerCase();
      const bValue = b[sortColumn].toLowerCase();

      if (aValue < bValue) {
        return sortDirection === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return sortDirection === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    });
  };

  handleSortChange = (column) => {
    const { sortColumn, sortDirection } = this.state;
    const newSortDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";

    this.setState(
      {
        sortColumn: column,
        sortDirection: newSortDirection,
        isTableLoading: true,
      },
      () => {
        this.loadSiteMap();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    this.setState(
      {
        search_term,
        isTableLoading: true,
      },
      () => {
        this.loadSiteMap();
      }
    );
  };

  handleApplicationSelect = (formData) => {
    let state = {
      pid: formData.pid,
      mid: formData.node_mid,
      lg_user_id: formData.lg_user_id,
      lg_user_table_id: formData.lg_user_table_id,
      flg_list_active: formData.flg_list_active,
      privilege_view: formData.privilege_view,
      privilege_edit: formData.privilege_edit,
      privilege_add: formData.privilege_add,
      privilege_delete: formData.privilege_delete,
      owner_restriction: formData.owner_restriction,
      user_list_restriction: formData.user_list_restriction,
      form_display_title: formData.form_display_title,
      form_display_order: formData.form_display_order,
      form_list_id: formData.form_list_id,
      breadcrumb2: formData.breadcrumb,
      main_table_id: formData.main_table_id,
      nodeTitle: formData.tree_main_title,
      node_mid_actual:formData.node_mid_actual, // not from  the SQL function
      path: 'site-map', // not from  the SQL function
      mcode:formData.mcode // not from  the SQL function
    };
    const componentDetail =  formFixture(state).find(item => formData.form_list_id === item.formListId)
    if(componentDetail && componentDetail.url){
    history.push({
        pathname: componentDetail.url,
        state: { ...state }
    });
    }
  };

  renderBreadcrumbsTable() {
    const { breadcrumbs, isTableLoading } = this.state;

    return (
      <ContentWrapper>
        <div style={{ overflowX: "auto", height: "90vh" }}>
          <Table striped bordered hover responsive>
           
            <TableBody>
              {isTableLoading ? (
                <TableRow>
                  <TableCell colSpan="1">
                    <div
                      className="loading-container"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                breadcrumbs.map((breadcrumb, index) => {
                  const formattedBreadcrumb = breadcrumb?.breadcrumb.replaceAll(">", " › ")
                  return(
                  <TableRow key={index}>
                    <div className="d-flex justify-content-between align-items-center py-2" style={{ fontSize: "11px", borderBottom: "1px solid #E4E6EF" }}>
                      {formattedBreadcrumb} {" "}
                      <button
                        className="btn btn-link"
                        style={{ fontSize: "11px" }}
                        onClick={() =>
                          this.handleApplicationSelect(breadcrumb)
                        }
                      >
                        {breadcrumb.form_display_title}
                      </button>
                    </div>
                  </TableRow>
                  )
                })
              )}
            </TableBody>
          </Table>
        </div>
      </ContentWrapper>
    );
  }

  render(isTableLoading) {
    return (
      <AdminTemplate>
        <ContentWrapper>
          <Card>
            <CardHeader>
              <CardTitle cardLabel="Sitemap" />
            </CardHeader>
            <br></br>
            <Form.Group controlId="exampleForm.ControlInput1" className="mb-2"style={{ marginLeft: "auto", marginLeft: "20px" }}>
              <Form.Control
                type="text"
                placeholder="Search"
                onChange={this.handleSearch}
                className="col-md-3 col-12"
              />
                <TableHead>
              <TableRow>
                <TableCell
                  style={{ fontSize: "15px", fontWeight: "bold", cursor: "pointer" }} 
                  onClick={() => this.handleSortChange("breadcrumb")}
                  className="thead"
                >
                  Breadcrumb with Title{" "}
                  {this.state.sortColumn === "breadcrumb" && (
                    <span>
                      {this.state.sortDirection === "asc" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                    </span>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            </Form.Group>
            {this.renderBreadcrumbsTable()}
            
          </Card>
        </ContentWrapper>
      </AdminTemplate>
    );
  }
}

export default Sitemap;
