import React from "react";

export default function TableRow({ children }) {
    return (
        
        <tr>
            {children}
        </tr>
        

    )
}