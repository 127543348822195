import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const AnnouncementService = {
  getAllAnnouncements,
  deleteAnnouncementById,
  addAnnouncementTable,
  sendPushNotification,
  getAllAnnouncementsAdmin,
  getAllAnnouncementsByNode,
  getAllAnnouncementsCGC,
};

async function getAllAnnouncements(limit, offset, sort_column, sort_direction) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/announcements/getAll/${limit}/${offset}/${sort_column}/${sort_direction}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllAnnouncementsByNode(
  node_mid,
  limit,
  offset,
  search_term,
  sort_column,
  sort_direction
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
      "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/announcements/getAllByNode/${node_mid}/${limit}/${offset}/${sort_column}/${sort_direction}?search_term=${search_term}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteAnnouncementById(id) {
  const configOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/announcements/delete/${id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function addAnnouncementTable(newAnnoucement) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newAnnoucement),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/announcements/add/all`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function sendPushNotification(data) {
  const configOptions = {
    method: "POST",
    headers: {
      Authorization:
        "key = AAAAGWF98r8:APA91bHCHbG_SlglSTTQjGwzmxF7JZWRdYN1wS99Cj6Efcyms61vOobIvpuqioxROAVfaN5rK8vFCKpj55OMWQ0Owbk4HU_tafRtlshMIHYphniFyvrYnxWZYBuplU3cXyB29RkbTs1H",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  const response = await fetch(
    `https://fcm.googleapis.com/fcm/send`,
    configOptions
  );

  return response.status === 200;
}

async function getAllAnnouncementsAdmin(lg_user_id, lg_user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  return fetch(
    `${config.oat_api_url}/manage/announcements/get-announcement-admin-dashboard/${lg_user_id}/${lg_user_table_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}
async function getAllAnnouncementsCGC() {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${config.oat_api_url}/manage/announcements/get-announcement-cgc`,
    requestOptions
  ).then(APIHandler.handleResponse);
}
