import { AzureStorage } from "../../helper/azureStorage";
import DataHandler from "../../handlers/DataHandler";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";
import { questionTypeConstants } from "../../helper/constants";

let auto_assessment_id;

export const testService = {
  validateCandidate,
  loadQuestions: loadQuestions,
  loadAssessmentQuestions: loadAssessmentQuestions,
  getTestQuestionContent: getTestQuestionContent,
  submitAnswer: submitAnswer,
  getAllQuestionContents: getAllQuestionContents,
  finishTest: finishTest,
  submitAnswers: submitAnswers,
  startTimer: startTimer,
  getCandidateAnswers,
  getAllCandidateAnswerContents,
  getAllAnswerContents: getAllAnswerContents,
  getAutoShuffleFlag,
  getCandidatesAnswersForTeacherGrade
};

async function validateCandidate(id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  const response = await fetch(
    `${config.oat_api_url}/candidate/exams/validateCandidate/${id}`,
    requestOptions
  ).then(APIHandler.handleResponse);

  if(response.data.assessment){
    auto_assessment_id = response.data.assessment.assessment_id;
  }
  return response;
}

async function loadQuestions(assessment_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/exams/loadQuestions/${assessment_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function loadAssessmentQuestions(assessment_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/assessment/questions/getAllQuestions/${assessment_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function startTimer(obj) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      candidate_id: obj.candidate_id,
      assessment_id: obj.assessment_id,
      attempt_no: obj.attempt_no,
    }),
  };

  var data = await fetch(
    `${config.oat_api_url}/candidate/grade/reports/add/`,
    requestOptions
  ).then(APIHandler.handleResponse);
  var _time = new Date(data.data.attempt_start_time.concat("Z"));
  data.data.attempt_start_time = _time;
  return data;
}

async function getTestQuestionContent(key) {
  if(key.is_content_blob === "true"){
    const questionContainerName= config.storageBlobConfigs.containerName;
    const questionBlobName = "".concat(
      key.created_by,
      "-q-",
      key.content
    );
    return await AzureStorage.getBlobContent(
      questionContainerName,
      questionBlobName
    );
  }
  else{
    return key.content;
  }
}

async function getAllQuestionContents(obj) {
  let promises = obj["questions"].map(async function (question) {
    if(question.is_content_blob === "true"){
      const questionContainerName= config.storageBlobConfigs.containerName;
      const questionBlobName = "".concat(
        question.created_by,
        "-q-",
        question.content
      );
      return await AzureStorage.getBlobContent(
        questionContainerName,
        questionBlobName
      );
    }
    else{
      return question.content;
    }
  });

  const contents = await Promise.all(promises);
  const result = getContent(contents);

  return result;
}

// function to get autoshuffle flag
async function getAutoShuffleFlag() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/assessment/autoshuffle-mcqs/${auto_assessment_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function submitAnswer(obj) {
  try {
    // Convert the object to a JSON string
    const user_id = DataHandler.getFromSession("user_id");
    let candidateAnswerContent = JSON.stringify(obj.answer_content);
    let isAnswerContentBlob = "false";

    //Checking the size of the candidate answer content object
    const acsizeInBytes = new Blob([candidateAnswerContent]).size;
    const acsizeInGB = acsizeInBytes / (1024 * 1024 * 1024);

    //If the size of the candidate answer content exceeds 1 GB
    if(acsizeInGB > config.storageBlobConfigs.maxDbStorageSize){
      // Upload candidate answer content to Azure Blob Storage
      const candidateAnswerContainerName= config.storageBlobConfigs.containerName;
      const candidateAnswerBlobName = "".concat(user_id ,"-ca-", obj.answer_key);
      await AzureStorage.storeBlobContent(candidateAnswerContainerName, candidateAnswerBlobName, candidateAnswerContent);
      candidateAnswerContent = obj.answer_key;
      isAnswerContentBlob = "true";
    }

    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(
        config.azure_ad_config.apis.OAT.name
      ),
      body: JSON.stringify({
        candidate_id: obj.candidate_id,
        assessment_id: obj.assessment_id,
        question_id: obj.question_id,
        answer_content: candidateAnswerContent,
        answer_state: "Not_Graded",
      }),
    };

    return await fetch(
      `${config.oat_api_url}/candidate/answers/add/`,
      requestOptions
    ).then(APIHandler.handleResponse);
  } catch (err) {
    return { error: "Not submitted.Please try again!" };
  }
}

function finishTest(obj) {
  try {
    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(
        config.azure_ad_config.apis.OAT.name
      ),
      body: JSON.stringify({
        candidate_status: "Finished",
        is_all_mcq: obj.is_all_mcq
      }),
    };

    return fetch(
      `${config.oat_api_url}/candidate/exams/finishTest/${obj["candidate_id"]}`,
      requestOptions
    ).then(APIHandler.handleResponse);
  } catch (err) {
    return { error: "Some error occured. Please try again!" };
  }
}

async function submitAnswers(obj) {
  let answer_list = [];

  let answer = {
    candidate_id: obj.candidate_id,
    assessment_id: obj.assessment_id,
    question_id: "",
    answer_content: "",
    answer_state: "Not_Graded",
    attempt_no: obj.attempt_no,
    is_answer_content_blob: "false"
  };

  for (const [key, value] of Object.entries(obj.answers)) {

    //Checking the size of the candidate answer content object
    const acsizeInBytes = new Blob([answer["answer_content"]]).size;
    const acsizeInGB = acsizeInBytes / (1024 * 1024 * 1024);

    //If the size of the candidate answer content exceeds 1 GB
    if(acsizeInGB > config.storageBlobConfigs.maxDbStorageSize){
      answer["question_id"] = key;
      answer["answer_content"] = "answer" + obj.candidate_id + key;
      answer["is_answer_content_blob"] = "true";

      try {
        // Convert the object to a JSON string
        const user_id = DataHandler.getFromSession("user_id");
        const candidateAnswerContent = JSON.stringify(value);
        // Upload candidate answer content to Azure Blob Storage
        const candidateAnswerContainerName= config.storageBlobConfigs.containerName;
        const candidateAnswerBlobName = "".concat(
          user_id,
          "-ca-",
          answer["answer_content"]
        );
        await AzureStorage.storeBlobContent(
          candidateAnswerContainerName,
          candidateAnswerBlobName,
          candidateAnswerContent
        );
      } catch (err) {
        throw new Error(err);
      }
      answer_list.push(JSON.parse(JSON.stringify(answer)));
    }
    else{
      answer["question_id"] = key;
      answer["answer_content"] = JSON.stringify(value)
      answer_list.push(JSON.parse(JSON.stringify(answer)));
    }
  }
  try {
    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(
        config.azure_ad_config.apis.OAT.name
      ),
      body: JSON.stringify(answer_list),
    };

    return fetch(
      `${config.oat_api_url}/candidate/answers/addAll/`,
      requestOptions
    ).then(APIHandler.handleResponse);
  } catch (err) {
    throw new Error("Not submitted. Please try again!");
  }
}

async function getCandidateAnswers(candidate_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/answers/getAnswers/${candidate_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getCandidatesAnswersForTeacherGrade(graded_by,candidate_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };

  return await fetch(
    `${config.oat_api_url}/candidate/teacher-grade/get-answers/${graded_by}/${candidate_id}`,
    requestOptions
  ).then(APIHandler.handleResponse);
}

async function getAllCandidateAnswerContents(obj) {
  var promises = obj["answers"].map(async function (answer) {
    if(answer.is_content_blob === "true"){
      let userType = sessionStorage.getItem("userType");
      if (userType === "student") {
        const user_id = DataHandler.getFromSession("user_id");
        const candidateAnswerContainerName = config.storageBlobConfigs.containerName;
        const candidateAnswerBlobName = "".concat(
          user_id,
          "-ca-",
          answer.answer_content
        );
        return await AzureStorage.getBlobContent(
          candidateAnswerContainerName,
          candidateAnswerBlobName
        );
      }
      if (userType === "parent" || userType === "admin") {
        const user_id = DataHandler.getFromSession("selected_child_user_id");
        const candidateAnswerContainerName = config.storageBlobConfigs.containerName;
        const candidateAnswerBlobName = "".concat(
          user_id,
          "-ca-",
          answer.answer_content
        );
        return await AzureStorage.getBlobContent(
          candidateAnswerContainerName,
          candidateAnswerBlobName
        );
      }
      if (userType === "teacher") {
        const user_id = answer.user_id;
        const candidateAnswerContainerName = config.storageBlobConfigs.containerName;
        const candidateAnswerBlobName = "".concat(
          user_id,
          "-ca-",
          answer.answer_content
        );
        return await AzureStorage.getBlobContent(
          candidateAnswerContainerName,
          candidateAnswerBlobName
        );
      }
    }
    else{
      return answer.answer_content;
    }
  });

  return await Promise.all(promises).then(getContent);
}

async function getAllAnswerContents(obj) {
  var promises = obj["questions"].map(async function (question) {
    if (question.question_type === questionTypeConstants.MCQ.name) {
      return question.answer;
    } 
    else if (question.question_type === questionTypeConstants.DESCRIPTIVE.name) {
      if(question.is_answer_blob === "true"){
        const answerContainerName= config.storageBlobConfigs.containerName;
        const answerBlobName = "".concat(
          question.created_by,
          "-a-",
          question.answer
        );
        return await AzureStorage.getBlobContent(
          answerContainerName,
          answerBlobName
        );
      }
      else{
        return question.answer;
      }
    } 
    else if (question.question_type === questionTypeConstants.SUBQUESTION.name && question.is_subquestion === "true") {
      if(question.is_answer_blob === "true"){
        const answerContainerName= config.storageBlobConfigs.containerName;
        const answerBlobName = "".concat(
          question.created_by,
          "-a-",
          question.answer
        );
        return await AzureStorage.getBlobContent(
          answerContainerName,
          answerBlobName
        );
      }
      else{
        return question.answer;
      }
    }
    else if (question.question_type === questionTypeConstants.SUBMISSION.name) {
      if(question.is_answer_blob === "true"){
        const answerContainerName= config.storageBlobConfigs.containerName;
        const answerBlobName = "".concat(
          question.created_by,
          "-a-",
          question.answer
        );
        return await AzureStorage.getBlobContent(
          answerContainerName,
          answerBlobName
        );
      }
      else{
        return question.answer;
      }
    }
  });

  return await Promise.all(promises).then(getContent);
}

function getContent(snapshots) {
  try {
    var contents = [];
    snapshots.forEach(function (snapshot) {
      try {
        contents.push(snapshot.val());
      } catch (error) {
        contents.push(snapshot);
      }
    });

    return contents;
  } catch (error) {
    return Promise.reject(error);
  }
}

