import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const ParentService = {
  create,
  update,
  getAll,
  getParentbyEmail,
  checkParentStatus,
  getParentById,
  getParentGroupByParentId
};

async function create(registration) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: registration,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/parents/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(student, studentId) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: student,
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/registration/parents/update/${studentId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAll(
  parent_id,
  user_table_id,
  create_user_id,
  create_user_table_id,
  node_mid,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const login_user_id = sessionStorage.getItem("manage_user_id");
  const login_user_table_id = sessionStorage.getItem("table_log_id")
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/parents/getAll/${node_mid}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}&s_id=${parent_id}&u_t_id=${user_table_id}&cr_u_id=${create_user_id}&cr_u_t_id=${create_user_table_id}&lg_u_id=${login_user_id}&lg_u_t_id=${login_user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getParentbyEmail(email){
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/parents/getByEmail/${email}`,
    requestOptions
  );
  const data = await response.json()
  return data;
}

async function checkParentStatus(email){
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/parents/check-parent-status/${email}`,
    requestOptions
  );
  const data = await response.json()
  return data;
}

async function getParentById(id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/parents/get/${id}`,
    requestOptions
  );
  const data = await response.json()
  return data;
}

async function getParentGroupByParentId(parent_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/registration/parents/get-parent-group/${parent_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
