import { AzureStorage } from "../../helper/azureStorage";
import DataHandler from "../../handlers/DataHandler";

import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const mcqService = {
    saveQuestion,
};

/********************************************************************************* */

async function saveQuestion(obj) {
    try {
        // Convert the object to a JSON string
        const user_id = DataHandler.getFromSession("user_id");
        let questionContent = JSON.stringify(obj.questionContent);
        const guidanceContent = JSON.stringify(obj.guidence);
        let isContentBlob = "false";
        let isAnswerBlob = "false";

        //Checking the size of the question/answer content objects
        const qsizeInBytes = new Blob([questionContent]).size;
        const qsizeInGB = qsizeInBytes / (1024 * 1024 * 1024);

        //If the size of question content object exceeds 1 GB
        if(qsizeInGB > config.storageBlobConfigs.maxDbStorageSize){
            // Upload question content to Azure Blob Storage
            const questionContainerName= config.storageBlobConfigs.containerName;
            const questionBlobName = "".concat(user_id, "-q-", obj.questionKey);
            await AzureStorage.getSASToken();
            await AzureStorage.uploadBlob(questionContainerName, questionBlobName, questionContent);
            questionContent = obj.questionKey;
            isContentBlob = "true";
        }

        console.log(obj.answer_type_id)

        const requestOptions = {
            method: 'POST',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify({
                title: obj.title,
                content: questionContent, // Store the blob name as content
                qtype: obj.qType,
                answer_type_id: obj.answer_type_id,
                category_id: obj.categoryId,
                created_by: obj.createdBy,
                answer: obj.answer,
                difficulty_id: obj.difficulty_id,
                language_id: obj.language_id,
                chapter_id: obj.chapter_id,
                grade_id: obj.grade_id,
                guidence: guidanceContent,
                source_id: obj.source_id,
                privilege_id: obj.privilege_id,
                subject_id: obj.subject_id,
                author_id: obj.author_id,
                year_id: obj.year_id,
                term_id: obj.term_id,
                tags: obj.tags,
                is_content_blob: isContentBlob,
                is_answer_blob: isAnswerBlob
            })
        };

        return fetch(`${config.oat_api_url}/questions/add/`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        throw ({ 'error': error });
    }

}

/*********************************************************************** */
