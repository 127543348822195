import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../../components/common/Spinner.js"
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import AdminTemplate from '../../../components/admin/layouts/Template';
import Card from '../../../components/common/Card';
import CardHeader from '../../../components/common/CardHeader';
import CardBody from '../../../components/common/CardBody';
import CardTitle from '../../../components/common/AssessmentCardTitle';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import TabList from '../../../components/common/tabs/TabList';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { quesTypeActions } from "../../../actions/settings/question/typeAction.js"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config.js";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class QuestionType extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newType:null,
        typeRowsPerPage:5,
        currentTypePage: 0,
        typeData: [],
        type_sort_column: "type_id",
        type_sort_direction: "ASC",
        selectType: '',
        editType: false,
        qtype: '',
        limit: 10,
        type_search_term: "",
        type_offset: 0,
        searchError: false,
        type_limit:5,
        typeId: '',
        typeOptionsImport: [],
        selectedType: []
      };
      
      this.onNewTypeInput = this.onNewTypeInput.bind(this);
      this.onAddNewType = this.onAddNewType.bind(this);
      this.generateTypeOptions = this.generateTypeOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllQuestionTypes();
    }
    
    onNewTypeInput(e) {
        this.setState({
            newType: e.target.value
        });
    }
    
    async onAddNewType(e) {
        if (this.state.newType && this.state.newType.trim()) {
            await this.props.createType({ 'qtype': this.state.newType })
            if (this.props.successtypecreate) {
                this.setState(
                    {
                        type_offset: 0,
                        currentTypePage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllQuestionTypes();
                        this.generateTypeOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new type!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-category').modal('hide')
                                window.$("#question-category").val('default');
                                window.$('#question-category').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errortypeupdate) {
                window.Swal.fire({
                    title: 'Failed to add a new type!',
                    text: this.props.errortypeupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty type!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newType: ''
        });
    }

    handleTypeSearch = (event) => {
        const type_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (type_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (type_search_term === "" || regex.test(type_search_term)) {
            this.setState(
                {
                    type_search_term,
                    type_offset: 0,
                    currentTypePage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllQuestionTypes();
                }
            );
        } else {
            this.setState({ type_search_term, searchError: true });
        }
    };
    
    fetchAllQuestionTypes = async () => {
        const {
            type_limit,
            type_offset,
            type_sort_column,
            type_sort_direction,
            type_search_term
        } = this.state;
        await this.props.getAllQuestionTypes(type_limit, type_offset, type_sort_column, type_sort_direction, type_search_term);
        this.setState({ type_limit: this.state.typeRowsPerPage });
        this.setState({ typeData: this.props.allQuestionTypes })
    };
    
    handleTypePageChange = (event, newTypePage) => {
        const {typeRowsPerPage} = this.state;
        const newTypeOffset = newTypePage * typeRowsPerPage;
        this.setState(
        {
            type_limit: typeRowsPerPage,
            type_offset: newTypeOffset,
            currentTypePage: newTypePage
        },
        () => {
            this.fetchAllQuestionTypes();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {typeRowsPerPage} = this.state;
        this.setState(
        {
            type_limit: typeRowsPerPage,
            type_offset: newOffset,
            currentTypePage: newPage
        },
        () => {
            this.fetchAllQuestionTypes();
        }
        );
    };

    //handle the data fetching as per the data per page limit gets changes
    handleTypeRowsPerPageChange = (event) => {
        const newTypeLimit = parseInt(event.target.value, 10);
        this.setState({typeRowsPerPage: newTypeLimit});
        const newTypePage = Math.floor(this.state.type_offset / newTypeLimit)
        this.setState(
        {
            type_limit: newTypeLimit,
            type_offset: newTypeLimit * newTypePage,
            currentTypePage: newTypePage
        },
        () => {
            this.fetchAllQuestionTypes();
        }
        );
    };

    handleSortTypeChange = (type_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { type_sort_column: currentTypeSortColumn, type_sort_direction } = this.state;
        const newTypeSortDirection =
        currentTypeSortColumn === type_sort_column && type_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            type_sort_column,
            type_sort_direction: newTypeSortDirection,
            type_offset: 0,
            currentTypePage: 0,
        },
        () => {
            this.fetchAllQuestionTypes();
        }
        );
    }

    getQuestionType(type_id) {
        let typename
        this.props.allQuestionTypes.map(type => {
            if (type.type_id === type_id) {
                return typename = type.qtype
            }
        })
        return typename
    }
    
    onEditType(e, el) {
        this.setState({
            typeId: el.type_id,
            qtype: e.target.value
        })
    }
    
    async onUpdateType(e) {
        e.preventDefault()
        var obj = {
            type_id: this.state.typeId,
            qtype: this.state.qtype
        }
        this.setState({ selectType: '' })
        if (!this.state.qtype.trim()) {
            window.Swal.fire({
                title: 'Failed to update type!',
                text: 'Type cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateType(obj);
            if (this.props.successtypeupdate) {
                // await this.props.getAllQuestionTypes(type_limit, type_offset, type_sort_column, type_sort_direction, type_search_term);
                this.fetchAllQuestionTypes()
                this.generateTypeOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated type!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });
            }
            if (this.props.errortypeupdate) {
                window.Swal.fire({
                    title: 'Failed to update type!',
                    text: this.props.errortypeupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        }
    }

    generateTypeOptions() {
        let typeOptionsImport = []
        this.props.allQuestionTypes.map((el, i) => {
            typeOptionsImport.push(<Option key={el.type_id} optionName={el.qtype} value={el.type_id} />)
        });

        this.setState({
            typeOptionsImport: typeOptionsImport,
            selectedType: typeOptionsImport[0].props.value
        });

        if (typeOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditType(el, i, e) {
        e.preventDefault()
        this.setState({
            editType: true,
            selectType: i,
            typeId: el.type_id,
            qtype: el.qtype
        })
    }
    
    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the type?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteType(el);
                if (this.props.successtypedelete) {
                    // await this.props.getAllQuestionTypes(type_limit, type_offset, type_sort_column, type_sort_direction, type_search_term);
                    this.fetchAllQuestionTypes()
                    this.generateypeOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted type!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });
                }
                if (this.props.errortypedelete) {
                    window.Swal.fire({
                        title: 'Failed to delete type!',
                        text: this.props.errortypedelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });

                }
            }
        })
    }
    
    syncTableType() {
        return (
            <>
                {this.state.typeData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortTypeChange("type_id")}
                                >
                                    No{" "}
                                    {this.state.type_sort_column === "type_id" && (
                                    <span>{this.state.type_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortTypeChange("qtype")}
                                >
                                    Type Name{" "}
                                    {this.state.type_sort_column === "qtype" && (
                                    <span>{this.state.type_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.typeData.map((el, i) => {
                                if (i === this.state.selectType) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editType ? 
                                                this.getQuestionType(el.type_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.qtype}
                                                    onChange={(e) => this.onEditType(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateType(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Type" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getQuestionType(el.type_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                {/* <Button 
                                                    onClick={(e) => this.handleEditType(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Type" 
                                                /> */}
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                                                    title="Delete Type" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Types Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return(
            <ContentWrapper>
                <div className="pt-5">
                    <div className="col-md-12">
                        <h3 className='text-nowrap'>Manage Question Types</h3>
                    </div>

                <CardBody>
                    <div>
                        <div className="row">
                        {/* <div className="col-md-9">
                            <QuestionAccordion
                            labelName="Create New Type"
                            onChange={this.onNewTypeInput}
                            onClick={this.onAddNewType}
                            val={this.state.newType}
                            />
                        </div> */}
                        <div className="col-md-3">
                            <Form.Group
                            controlId="exampleForm.ControlInput1"
                            className="mb-2"
                            >
                            <Form.Control
                                type="text"
                                placeholder="Search"
                                onChange={this.handleTypeSearch}
                                className="col-md-12 col-12"
                            />
                            </Form.Group>
                        </div>
                        </div>

                        <TabContent>
                        <TabPane
                            className="tab-pane fade show active"
                            id="manage-1"
                            ariaLabelledby="manage-tab-1"
                        >
                            {/* begin: Datatable */}
                            {this.props.cat_loading ? (
                            <div style={{ width: "75vw", overflowX: "scroll" }}>
                                <Table>
                                <TableHead>
                                    <TableRow>
                                    <TableCell className="thead">No</TableCell>
                                    <TableCell className="thead">
                                        Type Name
                                    </TableCell>
                                    <TableCell className="thead">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                </Table>
                                <div className="loading-container mb-5 mt-10">
                                <ReactSpinner
                                    animation="border"
                                    role="status"
                                    style={{ display: "block", margin: "auto" }}
                                >
                                    <span className="sr-only">Loading...</span>
                                </ReactSpinner>
                                </div>
                            </div>
                            ) : this.state.typeData.length > 0 ? (
                            this.syncTableType()
                            ) : (
                            <div>
                                <Table sx={{ width: "75vw" }}>
                                <TableHead>
                                    <TableRow>
                                    <TableCell className="thead">No</TableCell>
                                    <TableCell className="thead">
                                        Type Name
                                    </TableCell>
                                    <TableCell className="thead">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                {/* end: Datatable */}
                                </Table>
                                <div className="text-center mb-5 mt-10">
                                <label>No Types Available!</label>
                                </div>
                            </div>
                            )}
                        </TabPane>
                        </TabContent>
                        <div className="d-flex justify-content-end">
                            <MaterialCustomTablePagination
                                totalCount = {this.props.totalTypesCount}
                                currentPage = {this.state.currentTypePage}
                                limit = {this.state.typeRowsPerPage}
                                handlePageChange = {this.handleTypePageChange}
                                handleRowsPerPageChange = {this.handleTypeRowsPerPageChange}
                                handlePageOffsetChange = {this.handlePageOffsetChange}
                                offset = {this.state.type_offset}
                                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                            /> 
                        </div>
                    </div>
                    </CardBody>
                </div>
            </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalTypesCount: state.typeReducer.totalTypesCount,//
        cat_loading:state.typeReducer.loading,
        error: state.typeReducer.error,//
        // totalCount: state.questionsLibraryReducer.totalCount,//
        
        errortypedelete: state.typeReducer.errortypedelete,
        successtypedelete: state.typeReducer.successtypedelete,

        errortypeupdate: state.typeReducer.errortypeupdate,
        successtypeupdate: state.typeReducer.successtypeupdate,//
        
        errortypecreate: state.typeReducer.errortypecreate,
        successtypecreate: state.typeReducer.successtypecreate,//

        allQuestionTypes: state.typeReducer.allQuestionTypes,//
        loading: state.typeReducer.loading,//
    }
}

    const mapActionsToProps = {
        createType: quesTypeActions.createType,
        getAllQuestionTypes: quesTypeActions.getAllQuestionTypes,
        updateType: quesTypeActions.updateType,
        deleteType: quesTypeActions.deleteType,
    }

    export default connect(mapStateToProps, mapActionsToProps)(QuestionType);

