import React, { useEffect } from "react"

const AssessmentTerms = ({
    terms_content
}) => {

    return (

        <>
            <div
                dangerouslySetInnerHTML={{ __html: terms_content }}
            />


        </>



    )
}

export default AssessmentTerms