import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const questionDifficultyService = {
    getAllQuestionDifficulties,
    createDifficulty,
    deleteDifficulty,
    updateDifficulty
};

async function getAllQuestionDifficulties(limit, offset, sort_column, sort_direction, search_term) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/manage/difficulties/getAllDifficultyTypes/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}`, requestOptions)
        .then(APIHandler.handleResponse);
}

async function createDifficulty(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/manage/difficulties/add/`, requestOptions).then(APIHandler.handleResponse);
}

async function deleteDifficulty(obj) {
    try {
        const requestOptions = {
            method: 'DELETE',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(obj)
        };
        return await fetch(`${config.oat_api_url}/manage/difficulties/delete/${obj.difficulty_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function updateDifficulty(obj) {
    try {
        let requestBody = {
            difficulty_type: obj.difficulty_type.toString()
        }
        const requestOptions = {
            method: 'PUT',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify(requestBody)
        };

        let data = await fetch(`${config.oat_api_url}/manage/difficulties/update/${obj.difficulty_id}`, requestOptions);

        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}
