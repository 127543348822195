import {quesYearTypes} from '../../../reducers/settings/settingsTypes'
import {questionYearService} from '../../../service/settings/question/yearService'


export const quesYearActions = {
    getAllQuestionYears,
    createYear,
    updateYear,
    deleteYear
}

function getAllQuestionYears(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let years = await questionYearService.getAllQuestionYears(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(years.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: quesYearTypes.GET_ALL_QUESTION_YEARS}
    }

    function request() {
        return {type: quesYearTypes.GET_ALL_QUESTION_YEARS_REQUEST}
    }

    function success(years) {
        return {type: quesYearTypes.GET_ALL_QUESTION_YEARS_SUCCESS, years}
    }

    function failure(error) {
        return {type: quesYearTypes.GET_ALL_QUESTION_YEARS_FAILURE, error}
    }
}


function createYear(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await questionYearService.createYear(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: quesYearTypes.CREATE_YEAR_REQUEST, data}
    }

    function success(years) {
        return {type: quesYearTypes.CREATE_YEAR_SUCCESS, years}
    }

    function failure(error) {
        return {type: quesYearTypes.CREATE_YEAR_FAILURE, error}
    }
}


function deleteYear(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await questionYearService.deleteYear(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(year) {
        return {type: quesYearTypes.DELETE_YEAR_REQUEST, year}
    }

    function success(success) {
        return {type: quesYearTypes.DELETE_YEAR_SUCCESS, success}
    }

    function failure(error) {
        return {type: quesYearTypes.DELETE_YEAR_FAILURE, error}
    }
}

function updateYear(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await questionYearService.updateYear(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: quesYearTypes.UPDATE_YEAR_REQUEST}
    }

    function success(year) {
        return {type: quesYearTypes.UPDATE_YEAR_SUCCESS, year}
    }

    function failure(error) {
        return {type: quesYearTypes.UPDATE_YEAR_FAILURE, error}
    }
}
