import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';

export default function AccordionUsage({requiredPapers}) {
  return (
    <div>      
      {requiredPapers?.chart_title && <Accordion>        
        <AccordionSummary
          expandIcon={!!requiredPapers?.array?.length && <ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <MenuIcon/>&emsp;{requiredPapers?.chart_title}
        </AccordionSummary>
        {!!requiredPapers?.array?.length && <AccordionDetails>
          {
            requiredPapers?.array?.map(paper => {
              return (
                <div className='row'>● {paper?.chart_x_value}</div>
              )
            })
          }
        </AccordionDetails>}
      </Accordion>}
    </div>
  );
}
