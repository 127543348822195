import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const reportTitleService = {
    getAllReportTitle,
    addReportTitle,
    updateReportTitle,
    deleteReportTitle
};

export const reportSubjectService = {
  getAllReportSubjects,
  addReportSubject,
  updateReportSubject,
  deleteReportSubject
}

export const teacherRemarkService = {
  getAllTeacherRemark,
  addTeacherRemark,
  updateTeacherRemark
}


async function getAllReportSubjects(node_id) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/mark-sheet/subjects/getAll/${node_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}
async function getAllReportTitle(node_id) {
    const configOptions = {
      method: "GET",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/report-title/getAll/${node_id}`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }

  async function addReportTitle(newTitle) {
    const configOptions = {
      method: "POST",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newTitle),
    };
  
    const response = await fetch(
      `${config.oat_api_url}/manage/report-title/add`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }

  async function updateReportTitle(updateTitle , id){

    const requestOptions = {
        method: "PUT",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(updateTitle),
      };
      const response = await fetch(
        `${config.oat_api_url}/manage/report-title/update/${id}`,
        requestOptions
      );
      return APIHandler.handleResponse(response);
}

async function deleteReportTitle(id){

    const requestOptions = {
        method: "DELETE",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      };
      const response = await fetch(
        `${config.oat_api_url}/manage/report-title/delete-report-title/${id}`,
        requestOptions
      );
      return APIHandler.handleResponse(response);
}

async function addReportSubject(newSubject) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newSubject),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/mark-sheet/subjects/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateReportSubject(updateSubject , id){

  const requestOptions = {
      method: "PUT",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(updateSubject),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/mark-sheet/subjects/update/${id}`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function deleteReportSubject(id){

  const requestOptions = {
      method: "DELETE",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/mark-sheet/subjects/delete/${id}`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllTeacherRemark(node_id,title_id) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/teacher-overall-comment/getById/${node_id}/${title_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function addTeacherRemark(newRemark) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newRemark),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/teacher-overall-comment/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateTeacherRemark(updateRemark , id){

  const requestOptions = {
      method: "PUT",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(updateRemark),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/teacher-overall-comment/update/${id}`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}