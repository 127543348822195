
import Modal from "react-bootstrap/Modal";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { ManageDashboardService } from "../../../service/manage/dashboard/manage_dashboard.service";
import Card from "../../../components/common/Card.js";
import ProgressBarChart from "../charts/ProgressBarChart.js";
import Enrollment_accordion from './edsider/Enrollment_accordion.js';
import ReactSpinner from "react-bootstrap/Spinner";
import './../dashboard/edsider/dashboard.css';

export default class StudentsStatModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            session_id: undefined,
            table_log_id: undefined,
            dashboardData: [],
            dashboardDataLoading: false,
            suitableArray: [],
            studentIntakes: undefined,
            selectedStudentIntake: undefined,
            statsArray: [],
            packageArray: [],
            subjectsAvailable: {},
            enrolledPapers: {},
            completedPapers: {},
        }
    }

    getChildDashboardStats = async (session_id, table_log_id) => {
        this.setState({ dashboardDataLoading: true });
        await ManageDashboardService.getStatistics(
            100,
            session_id,
            table_log_id,
            "viewall",
            "EdSider-student"
        )
            .then((res) => {
                const suitableArray = this.buildStatChartArray(res.data)
                const statsArray = suitableArray.filter(item => item.chart_type.includes('Statistics'))
                const packageArray = suitableArray.filter(item => item.chart_type.includes('edstu-subscription'))
                const subjectsAvailable = suitableArray.find(item => item.chart_type.includes('edstu-sub-avail'))
                const enrolledPapers = suitableArray.find(item => item.chart_type.includes('edstu-enr-paper'))
                const completedPapers = suitableArray.find(item => item.chart_type.includes('edstu-comp-paper'))
                this.setState({
                    dashboardData: res.data,
                    dashboardDataLoading: false,
                    suitableArray: suitableArray,
                    statsArray: statsArray,
                    packageArray: packageArray,
                    subjectsAvailable: subjectsAvailable,
                    enrolledPapers: enrolledPapers,
                    completedPapers: completedPapers,
                });
            })
            .catch((error) => {
                this.setState({ dashboardDataLoading: false });
            });
    };


    buildStatChartArray = (dashboardData) => {
        const groupedData = dashboardData.reduce((acc, obj) => {
            const key = `${obj.chart_type}_${obj.chart_id}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
        const arrays = Object.values(groupedData);
        const objectArray = arrays.map(arr => {
            const highestDisplayOrderObject = arr.reduce((maxObj, obj) => {
                if (!maxObj || obj.display_order > maxObj.display_order) {
                    return obj;
                } else {
                    return maxObj;
                }
            }, null);
            return {
                "array": arr,
                "chart_title": arr[0].chart_title,
                "chart_type": arr[0].chart_type,
                "chart_id": arr[0].chart_id,
                "display_order": highestDisplayOrderObject.display_order
            }
        })
        objectArray.sort((a, b) => a.display_order - b.display_order)
        return objectArray
    }



    componentDidUpdate(prevProps, prevState) {
        const userId = this.props.selectedStudent?.stu_id ? this.props.selectedStudent?.stu_id : this.props.selectedStudent?.user_id
        const prevUserId = prevProps.selectedStudent?.stu_id ? prevProps.selectedStudent?.stu_id : prevProps.selectedStudent?.user_id
        if ((
            this.props.studentStatsModal !== prevProps.studentStatsModal && this.props.studentStatsModal === true) &&
            (userId !== prevUserId || this.props.selectedStudent.user_table_id !== prevProps.selectedStudent?.user_table_id)
        ) {
            this.getChildDashboardStats(
                userId,
                this.props.selectedStudent.user_table_id
            )
        }
    }



    render() {
        const {
            dashboardData,
            dashboardDataLoading,
            packageArray,
            subjectsAvailable,
            enrolledPapers,
            completedPapers
        } = this.state;
        const first3PackageDetail = packageArray.slice(0, 3)
        return (
            <div>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.studentStatsModal}
                    onHide={() => this.props.toggleStudentStatsModal(null)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {" "}
                            <h3>
                                {this.props.selectedStudent?.first_name +
                                    " " +
                                    this.props.selectedStudent?.last_name}
                            </h3>
                            {" "}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {dashboardDataLoading ?
                            <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                            >
                                <span className="sr-only">Loading...</span>
                            </ReactSpinner>
                            : <div className="row">
                                <div className="col-md-6 colPadding stdpopupLeftside" >
                                    <Card>
                                        <div className="studentPopupCard">
                                            <div className="enrollTopics column" style={{ marginBottom: '20px', marginTop: '5px' }}>{subjectsAvailable?.chart_title}</div>
                                            {
                                                !!subjectsAvailable?.array?.length ?
                                                    subjectsAvailable?.array?.map(subject => {
                                                        return (
                                                            <div style={{ margin: '5px' }}>● {subject?.chart_x_value}</div>
                                                        )
                                                    }) :
                                                    <div>No Subjects Available</div>
                                            }
                                        </div>
                                        <div className="popupAccordions">
                                            <div className="popupAcc"><Enrollment_accordion requiredPapers={enrolledPapers} /></div>
                                            <div className="popupAcc"><Enrollment_accordion requiredPapers={completedPapers} /></div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="colPadding enrollTopics column" style={{ marginTop: '5px' }}>Subscription Packages</div>
                                    <div className="row">
                                        {
                                            first3PackageDetail?.map((packageDetail) => {
                                                return (
                                                    <div className="subscription_card silver" >
                                                        {packageDetail?.chart_title && <ProgressBarChart packageDetail={packageDetail} />}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            id="modal-close-button"
                            style={this.props.closeBtnStyle}
                            onClick={() =>
                                this.props.toggleStudentStatsModal(null)
                            }
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}



