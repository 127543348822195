import React, { useState } from 'react';
import './DragDrop.css';

function allowDrop(event) {
  event.preventDefault();
}

function drag(event) {
  event.dataTransfer.setData("text", event.target.id);
}

function drop(event) {
  event.preventDefault();
  var data = event.dataTransfer.getData("text");
  if (event.target.className === 'drop-box') {
    event.target.appendChild(document.getElementById(data));
  }
}

function DragDrop({ initialWords, initialSentence, isEditable }) {
  const [words, setWords] = useState(initialWords || ["quick", "brown", "fox", "lazy", "dog"]);
  const [sentenceStructure, setSentenceStructure] = useState(initialSentence || "The _____ _____ jumps over the _____ _____.");

  const handleWordsChange = (e) => {
    setWords(e.target.value.split(','));
  };

  const handleSentenceStructureChange = (e) => {
    setSentenceStructure(e.target.value);
  };

  const renderSentence = () => {
    const sentenceParts = sentenceStructure.split('_____');
    const dropBoxes = sentenceParts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < sentenceParts.length - 1 && <span className="drop-box" onDrop={drop} onDragOver={allowDrop}></span>}
      </React.Fragment>
    ));
    return <p>{dropBoxes}</p>;
  };

  return (
    <div className="DragDrop">

      <p><b>Drag the text and drop it into the empty boxes to complete the sentence.</b></p>

      <br/>

      {/* Form to customize words and sentence structure */}
      {isEditable && (
        <div className="customization-form">
          <label>
            Words (comma separated):
            <input type="text" value={words.join(',')} onChange={handleWordsChange} />
          </label>
          <label>
            Sentence Structure (use "_____" for drop areas):
            <input type="text" value={sentenceStructure} onChange={handleSentenceStructureChange} />
          </label>
        </div>
      )}

      {/* Draggable Text Elements */}
      <div id="dragContainer">
        {words.map((word, index) => (
          <span key={index} draggable="true" onDragStart={drag} id={`drag${index}`}>
            {word}
          </span>
        ))}
      </div>

      {/* Sentence with Drop Boxes */}
      {renderSentence()}
    </div>
  );
}

export default DragDrop;
