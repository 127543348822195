import {quesAuthorTypes} from '../settingsTypes';

const initialState = {
    allQuestionAuthors: [],
    loading: false,
    error: '',
    text: '',
    author:'',
    totalAuthorsCount: 0,
    allAuthors:[],
    errorauthordelete:false,
    loadingauthordelete:false,
    successauthorupdate: false,
    loadingauthorupdate: false,
    errorauthorupdate:false,
    successauthordelete:false,
    errorauthorcreate: '',
    successauthorcreate: false,
}

export function authorReducer(state = initialState, action){
    switch (action.type){
        case quesAuthorTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allQuestionAuthors: [],
                loading: false,
                error: '',
                text: '',
                author:'',
                totalAuthorsCount:0,
                allAuthors:[],
                errorauthordelete:false,
                loadingauthordelete:false,
                successauthorupdate: false,
                loadingauthorupdate: false,
                errorauthorupdate:false,
                successauthordelete:false,
                errorauthorcreate: '',
                successauthorcreate: false,
            }
        //Author retrieve request sending
        case quesAuthorTypes.GET_ALL_QUESTION_AUTHORS:
            return {
                ...state,
                loading: true,
                text: 'Get all the question authors'
            }
        case quesAuthorTypes.GET_ALL_QUESTION_AUTHORS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case quesAuthorTypes.GET_ALL_QUESTION_AUTHORS_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionAuthors: action.authors.authors,
                totalAuthorsCount: action.authors.total_count
            }
        case quesAuthorTypes.GET_ALL_QUESTION_AUTHORS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Author creation request sending
        case quesAuthorTypes.CREATE_AUTHOR_REQUEST:
            return {
                ...state,
                errorauthorcreate: '',
                successauthorcreate: false,
                loading: true
            }
        case quesAuthorTypes.CREATE_AUTHOR_SUCCESS:
            var temp = state.allAuthors;
            temp.push(action.authors)
            return {
                ...state,
                allAuthors: temp,
                author: action.authors.author_id,
                successauthorcreate: true,
                loading: false
            }
        case quesAuthorTypes.CREATE_AUTHOR_FAILURE:
            return {
                ...state,
                errorauthorcreate: action.error,
                loading: false
            }  
        //Author deletion request sending   
        case quesAuthorTypes.DELETE_AUTHOR_REQUEST:
            return {
            ...state,
            errorauthordelete: '',
            successauthordelete: false,
            loading: true,
            };
        case quesAuthorTypes.DELETE_AUTHOR_SUCCESS:
            return {
            ...state,
            successauthordelete: true,
            loading: false,
            };
        case quesAuthorTypes.DELETE_AUTHOR_FAILURE:
            return {
            ...state,
            errorauthordelete: action.error,
            loading: false,
            };
        //Author updation request sending
        case quesAuthorTypes.UPDATE_AUTHOR_REQUEST:
            return {
            ...state,
            errorauthorupdate:'',
            successauthorupdate: false,
            loading: true,
            };
        case quesAuthorTypes.UPDATE_AUTHOR_SUCCESS:
            return {
            ...state,
            successauthorupdate: true,
            loading: false,
            };
        case quesAuthorTypes.UPDATE_AUTHOR_FAILURE:
            return {
            ...state,
            errorauthorupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
