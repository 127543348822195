import React, { Component } from "react";
import { TermService } from "../../../../service/user_management/terms_conditions.service";
import "./TermsAndConditions.css";
import Spinner from "../../../../components/Spinner2";
import NavBar from "../../../../components/edsider/components/NavBar";
import config from '../../../../helper/config';
import {customerConstants} from "../../../../helper/constants.js";
import { routes } from "../../../../helper/routes.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};
class Conditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreed: false,
      selectedLanguage: 1,
      isLoading: false,
      isLoadingDropdown: false,
      isLoadingContents: false,
      languages: [],
      contents: "",
    };
  }

  componentDidMount() {
    this.loadDataDropdown();
    this.getContentById(this.state.selectedLanguage);
  }

  loadDataDropdown = async () => {
    this.setState({ isLoadingDropdown: true });
    try {
      const response = await TermService.LanguageGetAll();
      this.setState({ languages: response.data.languages, isLoadingDropdown: false });
      const customer = getCustomerFeatureFlag();
      if(customer === customerConstants.EXO_CUSTOMER2){
        const firstLanguage = response.data[0];
        this.setState({ languages: [firstLanguage] });
      }
    } catch (error) {
      this.setState({ isLoadingDropdown: false });
    }
  };

  getContentById = async (id) => {
    try {
      this.setState({ isLoadingContents: true });
      const response = await TermService.TermsById(id);
      this.setState({ contents: response.data.content });
    } catch (error) {
      // Handle error
    } finally {
      this.setState({ isLoadingContents: false });
    }
  };

  validateForm = (data) => {
    return Object.values(data).every((value) => value !== null && value !== "");
  };

  handleCheckboxChange = (event) => {
    const agreed = event.target.checked;
    this.setState({ agreed });
  };

  handleLanguageChange = (event) => {
    const selectedLanguage = parseInt(event.target.value);
    this.setState({ selectedLanguage });
    this.getContentById(selectedLanguage);
  };

  render() {
    const {
      agreed,
      selectedLanguage,
      isLoading,
      isLoadingDropdown,
      languages,
      contents,
    } = this.state;

    const customer = getCustomerFeatureFlag();
    let footerText, copyRightText;
    switch (customer) {
      case customerConstants.EXO_CUSTOMER1:
        copyRightText = "© 2024 EdSider";
        footerText = "All Right Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
        break;
      case customerConstants.EXO_CUSTOMER2:
        copyRightText = "© 2024"
        footerText = "All Rights Reserved. Powered by EdSider (Pvt) Ltd. Supported by Infinity Innovators (Pvt) Ltd"
        break;
      default:
        copyRightText = "© 2024 EdFoci"
        footerText = "All Rights Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
        break;
    }

    const currentYear = new Date().getFullYear();

    return (
      <>
        <NavBar className="navigation-bg" />
        <div className="container-terms">
        {/* <label className="language-label">
            Language: &nbsp;
            <select
              className="language-select"
              value={selectedLanguage}
              onChange={this.handleLanguageChange}
              disabled={isLoadingDropdown}
            >
              {isLoadingDropdown ? (
                <option>Loading...</option>
              ) : (
                languages.map((language) => (
                  <option
                    key={language.language_id}
                    value={language.language_id}
                  >
                    {language.language}
                  </option>
                ))
              )}
            </select>
            {isLoadingDropdown && <Spinner />}
          </label> */}
          <div
            className="contents"
            dangerouslySetInnerHTML={{ __html: contents }}
          />
          <label className="checkbox-label"></label>

        </div>
        <div className="row d-flex justify-content-between footer-container">
          <div className="col-md-2 col-sm-3 copyright-items">
            {copyRightText}
          </div>
          <div className="col-md-6 col-sm-3 copyright-items d-flex justify-content-center">
            {footerText}
          </div>
          <div className="col-md-4 col-sm-3 d-flex justify-content-end policy-content">
          <span className="text-dark-75">
                <a
                  href={routes.terms_and_conditions_view}
                  target="_blank"
                  className="text-dark-75 text-hover-primary"
                >
                  Privacy Policy and Terms & Conditions
                </a>
              </span>
          </div>
        </div>
      </>
    );
  }
}

export default Conditions;
