import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const QuizService = {
  getAll,
  addAll,
  getById,
  getAllCount,
  deleteAll,
  getAllEnrolledStudents,
};

async function getAllCount() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/quizzes/getAll/count`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAll(
  node_mid,
  limit,
  offset,
  sortColumn,
  sortDirection,
  search_term
) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/quizzes/getAll/${node_mid}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getById(node_mid) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/quizzes/getByNode/${node_mid}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function addAll(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
      "Authorization_UMI": APIHandler.getHeader(config.azure_ad_config.apis.OAT.name).Authorization,
    },
    body: data,
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/quizzes/add/all`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteAll(paperIds, nodeMid) {
  const payload = {
    paper_ids: paperIds,
    node_mid: nodeMid
  };

  const requestOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload), // Convert the payload to a JSON string
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/quizzes/delete/all`,
    requestOptions
  );

  return APIHandler.handleResponse(response);
}

async function getAllEnrolledStudents(
  node_mid,
  form_id,
  lg_user_id,
  lg_user_table_id,
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/quizzes/getAllQuizStudents/${node_mid}/${form_id}/${lg_user_id}/${lg_user_table_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

