import React, { Component } from "react";
import ContentWrapper from "../../../../components/common/ContentWrapper.js";
import CardHeader from "../../../../components/common/CardHeader.js";
import CardBody from "../../../../components/common/CardBody.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import MaterialCustomTablePagination from "../../../../components/common/MaterialCustomTablePagination.js";
import debounce from "lodash.debounce";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { TeacherEnrollmentService } from "../../../../service/manage/enrollment/enrollment.service.js";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../../components/common/spinner/PageSpinner.js";
import "../../../../components/admin/css/common.css";
import sweetAlert, {
  sweetErrorAlert,
  sweetStatusAlert,
  sweetSuccessAlert,
} from "../../../../components/common/SweetAlert.js";
import ReactSelect from "react-select";
import SimpleReactValidator from "simple-react-validator";
import { history } from "../../../../helper/history.js";
import config from "../../../../helper/config.js";
import { customerConstants } from "../../../../helper/constants.js";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DatePicker from "react-datepicker";
import { MarksheetService } from "../../../../service/manage/mark_sheet/marksheet.service.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

export default class ManageTeacherEnrollment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInsertModal: false,
      showModal: false,
      enrollmentId: "",
      typeName: "",
      typeDescription: "",
      enrollments: [],
      isLoading: false,
      isTableDataLoading: true,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      teacherSearchTerm: "",
      totalEnrollments: 10,
      currentPage: 0,
      isModified: false,
      teachers: [],
      selectedTeacherId: "",
      selectedTeacher: null,
      search_id: "",
      isStudentsLoading: false,
      enrollment_id: "",
      enroll_start_date: "",
      isSubmitting: false,
      isEditSubmitting: false,
      registration_id: "",
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      sortColumn: "user_code",
      sortDirection: "desc",
      breadcrumb2: null,
      nodeTitle: null,
      flg_list_active: null,
      enrollmentList: [],
    };

    this.debouncedHandleSearch = debounce(this.getAllEnrollments, 2000);
    this.debouncedHandleStudentSearch = debounce(this.fetchallteacher, 1000);

    this.createValidationsInstance();
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const {
      privilege_add,
      privilege_edit,
      privilege_delete,
      breadcrumb2,
      nodeTitle,
      flg_list_active,
    } = this.props.state;
    this.getVisibilityCheck(privilege_add, privilege_edit, privilege_delete);
    this.getAllEnrollments();
    this.setState({
      breadcrumb2: breadcrumb2,
      nodeTitle,
      flg_list_active,
    });
  }

  handleDatePickerChange = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject.getFullYear();
    const month = ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = ("0" + dateObject.getDate()).slice(-2);

    const formattedDateString = `${year}-${month}-${day}`;
    this.setState(() => ({
      enroll_start_date: formattedDateString,
      isModified: true,
    }));
  };

  getVisibilityCheck(privilege_add, privilege_edit, privilege_delete) {
    if (privilege_add == "1") {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_edit == "1") {
      this.setState({
        editVisibility: true,
      });
    }
    if (privilege_delete == "1") {
      this.setState({
        deleteVisibility: true,
      });
    }
  }

  fetchallteacher = () => {
    const { mid, form_list_id, lg_user_table_id, lg_user_id } =
      this.props.state;
    const { limit, offset, sortColumn, sortDirection, teacherSearchTerm } =
      this.state;

    this.setState({ isStudentsLoading: true });
    MarksheetService.getAllEnrolledStudents(
      mid,
      form_list_id,
      lg_user_id,
      lg_user_table_id,
      5000,
      0,
      sortColumn,
      sortDirection,
      teacherSearchTerm
    ).then((result) => {
      if (result.success) {
        const updatedStudentsArray = result.data.users.map((teacher) => ({
          ...teacher,
          status: false,
        }));
        this.setState({ teachers: updatedStudentsArray });
        this.setState({ isStudentsLoading: false });
      }
      this.setState({ isStudentsLoading: false });
    });
  };

  getAllEnrollments() {
    const {
      mid,
      lg_user_id,
      lg_user_table_id,
      user_list_table_id,
      owner_restriction,
      user_list_restriction,
      form_list_id,
    } = this.props.state;
    this.setState({ isTableDataLoading: true });
    const { limit, offset, sortColumn, sortDirection, search_term } =
      this.state;
    const session_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");
    let create_user_id = lg_user_id;
    let create_user_table_id = lg_user_table_id;
    let user_table_id =
      user_list_restriction === 1 && table_log_id ? table_log_id : "null";
    let reg_id =
      user_list_restriction === 1 && session_id ? session_id : "null";
      
      TeacherEnrollmentService.getTeacherSortedWithNodeCount(
      mid,
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      reg_id,
      user_table_id,
      create_user_id,
      owner_restriction,
      user_list_restriction,
      create_user_table_id,
      form_list_id
    )
      .then((res) => {
        if (res.success) {
          const teacherList = res.data["teacher_list"];
          const enrollmentList = res.data["teachers"];
          const allStudents = enrollmentList
            .map((item1) => {
              const matchingRecords = teacherList.find(
                (item2) => item2.user_id === item1.registration_id
              );
              if (matchingRecords) {
                return {
                  ...item1,
                  data_concat: matchingRecords.data_concat,
                  user_code: matchingRecords.user_code,
                };
              }
            })
            .filter((value) => value !== undefined);
          this.setState({
            enrollments: allStudents ? allStudents : enrollmentList,
            totalEnrollments: res.data.count,
            enrollmentList: teacherList,
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Enrollments Please refresh the screen!",
            "error"
          );
        }
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  }

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllEnrollments();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllEnrollments();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllEnrollments();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  handleStudentSearch = (event) => {
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
    if (event === "" || regex.test(event)) {
      let num = event;
      this.setState(
        {
            teacherSearchTerm: num,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleStudentSearch();
        }
      );
    } else {
      this.setState({ teacherSearchTerm: event, searchError: true });
    }
  };

  handleSortChange = (sortColumn) => {
    // If the same column is clicked, toggle the sort direction
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.getAllEnrollments();
      }
    );
  };

  toggleAddEnrollmentModal = () => {
    this.setState(
      (prevState) => ({
        showInsertModal: !prevState.showInsertModal,
        selectedTeacherId: "",
        selectedTeacher: null,
        enrollment_id: "",
        enroll_start_date: "",
        search_id: "",
        teacherSearchTerm: "",
      }),
      () => {
        if (this.state.showInsertModal) {
          this.fetchallteacher();
        }
      }
    );
  };

  toggleUpdateEnrollmentModal = (enrollment) => {
    this.setState(
      (prevState) => ({
        showModal: !prevState.showModal,
        search_id: "",
      }),
      () => {
        // State has changed, perform your logic here based on the state change
        if (this.state.showModal) {
          this.setState({
            enroll_start_date: enrollment.enroll_start_date,
            selectedTeacherId: enrollment.registration_id,
            enrollment_id: enrollment.enrollment_id,
            selectedTeacher: {
              value: enrollment.student_id,
              label: enrollment.first_name + " " + enrollment.last_name,
            },
            isEditSubmitting: false,
          });
        }
      }
    );
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
  };

  handleUpdate = async (event) => {
    const session_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");
    event.preventDefault();

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const { enrollment_id, selectedTeacherId, enroll_start_date } =
        this.state;
      const enrollment = {
        node_mid: this.props.state.mid,
        enroll_start_date: enroll_start_date,
        registration_id: selectedTeacherId,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
      };

      TeacherEnrollmentService.updateTeacherEnrollment(enrollment, enrollment_id)
        .then((res) => {
          if (res.success) {
            this.setState({ showModal: false });
            this.getAllEnrollments();
            sweetStatusAlert("Success!", res.message, "success");
            this.validator.purgeFields();
            this.validator.hideMessages();
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
          }
        })
        .catch((error) => {
          sweetStatusAlert("Failed !", "Unable to update Enrollment!", "error");
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const session_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true, showInsertModal: false });
      const { selectedTeacherId, enroll_start_date, enrollmentList } =
        this.state;

      let user = enrollmentList.find(
        (enrollment) => enrollment.user_id === parseInt(selectedTeacherId)
      );

      const enrollment = {
        enroll_start_date: enroll_start_date,
        create_user_id: session_id,
        create_user_table_id: table_log_id,
        node_mid: this.props.state.mid,
        registration_id: selectedTeacherId,
        user_table_id: user.user_table_id,
      };

      TeacherEnrollmentService.Teachersave(JSON.stringify(enrollment))
        .then((res) => {
          if (res.success) {
            this.getAllEnrollments();
            sweetStatusAlert("Success!", res.message.success, "success");
            this.validator.purgeFields();
            this.validator.hideMessages();
          } else {
            sweetStatusAlert("Failed!", res.message.error, "error");
          }
        })
        .catch((error) => {
          sweetStatusAlert(
            "Failed !",
            "Unable to create new Enrollment!",
            "error"
          );
        })
        .finally(() => {
          // Hide loading indicator
          this.setState({ isLoading: false });
        });
    }
  };

  formattedDateString = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString = `${day}-${month}-${year}`;
    return fromattedString;
  };

  handleDeleteSubmit = (enrollment_id) => {
    this.setState({ isLoading: true });
    TeacherEnrollmentService.removeTeacherEnrollment(enrollment_id)
      .then((response) => {
        if (response.success) {
          sweetSuccessAlert("Success", response.message.success, "success");
          this.setState({ isLoading: false });
          this.getAllEnrollments();
        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Oops", response.message.error, "info");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        sweetErrorAlert("Error", "Error while deleting Subject", "error");
      });
  };

  render() {
    const {
      enrollments,
      isLoading,
      isTableDataLoading,
      limit,
      currentPage,
      totalEnrollments,
      enroll_start_date,
      selectedTeacher,
      teachers,
      isStudentsLoading,
      isSubmitting,
      isEditSubmitting,
      addVisibility,
      editVisibility,
      deleteVisibility,
      selectedTeacherId,
      breadcrumb2,
      nodeTitle,
      flg_list_active,
      offset,
    } = this.state;
    const path = history.location.pathname;
    this.validator.purgeFields();
    const formattedBreadcrumb = breadcrumb2
      ? breadcrumb2.replaceAll(">", " > ")
      : "";

    return (
      <>
        <ContentWrapper disabled={path === "/manage-search"}>
          <div style={{ height: "90vh", overflow: "auto" }}>
            <CardHeader>
              {this.props.state.path !== "node" && (
                <p
                  className="pb-4"
                  style={{ fontSize: "10.5625px", color: "#00000099" }}
                >
                  {formattedBreadcrumb || nodeTitle
                    ? `${formattedBreadcrumb && formattedBreadcrumb}${
                        nodeTitle && nodeTitle
                      }`
                    : undefined}
                </p>
              )}

              <div className="row d-flex align-items-center">
                <div className="col-md-6 col-8 px-0">
                  {/* <CardTitle cardLabel={this.props.state.form_display_title} /> */}
                  <Form.Group
                    controlId="exampleForm.ControlInput1"
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Search"
                      onChange={this.handleSearch}
                      isInvalid={this.state.searchError}
                      style={{ width: "300px" }}
                      className="search-bar"
                    />
                    {this.state.searchError && (
                      <Form.Control.Feedback type="invalid">
                        Invalid input
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>

                <div className="col-md-6 col-4 px-0 d-flex justify-content-end">
                  {addVisibility && (
                    <Button
                      className="insert-btn"
                      variant="primary"
                      onClick={this.toggleAddEnrollmentModal}
                    >
                      Add Enrollment
                    </Button>
                  )}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {/* <Form.Group

                                    controlId="exampleForm.ControlInput1"
                                    className="mb-2"
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder="Search"
                                        onChange={this.handleSearch}
                                        isInvalid={this.state.searchError}
                                        // style={{ width: "18%" }}
                                        className="col-md-3 col-12"
                                    />
                                    {this.state.searchError && (
                                        <Form.Control.Feedback type="invalid">
                                            Invalid input
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group> */}
              {isLoading && (
                <PageSpinner id="show" text="Loading, Please wait!" />
              )}
              {isTableDataLoading ? (
                <div className="loading-container">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : !!enrollments.length ? (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("user_code")}
                        >
                          User Code{" "}
                          {this.state.sortColumn === "user_code" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("first_name")}
                        >
                          First Name{" "}
                          {this.state.sortColumn === "first_name" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleSortChange("last_name")}
                        >
                          Last Name{" "}
                          {this.state.sortColumn === "last_name" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          className="thead"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.handleSortChange("enroll_start_date")
                          }
                        >
                          Enroll Date{" "}
                          {this.state.sortColumn === "enroll_start_date" && (
                            <span>
                              {this.state.sortDirection === "asc" ? (
                                <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                              ) : (
                                <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                              )}
                            </span>
                          )}
                        </TableCell>
                        {editVisibility && (
                          <TableCell className="thead">Actions</TableCell>
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {enrollments.map((enrollment) => {
                        let splits = enrollment.data_concat
                          ? enrollment.data_concat.split(" ")
                          : [];
                        return (
                          <TableRow key={enrollment.enrollment_id}>
                            <TableCell className="tData">
                              {/* STU20201 */}
                              {enrollment.user_code}
                              {/* {enrollment.data_concat} */}
                            </TableCell>
                            <TableCell>{splits.length && splits[1]}</TableCell>
                            <TableCell>{splits.length && splits[2]}</TableCell>
                            <TableCell className="tData">
                              {this.formattedDateString(
                                enrollment.enroll_start_date
                              )}
                            </TableCell>
                            {editVisibility && (
                              <TableCell>
                                <Button
                                  onClick={() =>
                                    this.toggleUpdateEnrollmentModal(enrollment)
                                  }
                                  className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                  title="Edit Details"
                                />
                                {deleteVisibility && (
                                  <>
                                    <Button
                                      className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                      title="Delete Details"
                                      sx={{ color: "red" }}
                                      onClick={() =>
                                        this.handleDeleteSubmit(
                                          enrollment.enrollment_id
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>

                  <MaterialCustomTablePagination
                    totalCount={parseInt(totalEnrollments)}
                    currentPage={currentPage}
                    limit={limit}
                    handlePageChange={this.handlePageChange}
                    handleRowsPerPageChange={this.handleRowsPerPageChange}
                    handlePageOffsetChange={this.handlePageOffsetChange}
                    rowsPerPageOptions={[5, 10, 20]}
                    offset={offset}
                  />
                </div>
              ) : (
                <div className="text-center mb-5 mt-10">
                  <label>No User to Select </label>
                </div>
              )}
            </CardBody>
          </div>
        </ContentWrapper>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showInsertModal}
          onHide={() =>
            this.handleSweetAlert(
              this.toggleAddEnrollmentModal,
              "showInsertModal"
            )
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Enrollment</Modal.Title>
          </Modal.Header>
          {isSubmitting ? (
            <div className="loading-container">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : !!flg_list_active ? (
            <div>
              <Modal.Body>
                <Form>
                  <Row className="mb-5">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Search User By Id*</Form.Label>
                      <ReactSelect
                        options={teachers.map((teacher) => ({
                          value: teacher.user_id,
                          label: `${teacher.data_concat}`,
                        }))}
                        value={selectedTeacher}
                        isLoading={isStudentsLoading}
                        onChange={(selectedOption) => {
                          const selectedValue = selectedOption?.value;
                          this.setState({
                            selectedTeacherId: selectedValue,
                            selectedTeacher: selectedOption,
                            isModified: true,
                          });
                        }}
                        onInputChange={(inputValue) => {
                          if (inputValue) {
                            this.handleStudentSearch(inputValue);
                          }
                        }}
                        onMenuClose={() => {
                          if (!!this.state.teacherSearchTerm) {
                            this.setState(
                              {
                                teacherSearchTerm: "",
                              },
                              () => {
                                this.fetchallteacher();
                              }
                            );
                          }
                        }}
                      />
                      {this.validator.message(
                        "selectedTeacher",
                        selectedTeacherId && selectedTeacherId !== null,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The teacher is required.",
                          },
                        }
                      )}
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="startDate">
                      <Form.Label>Start Date*</Form.Label>
                      <DatePicker
                        className="py-2 form-control" // Add Bootstrap form control class
                        selected={
                          enroll_start_date
                            ? new Date(enroll_start_date)
                            : undefined
                        }
                        name="enroll_start_date"
                        onChange={this.handleDatePickerChange}
                        dateFormat="dd/MM/yyyy" // Set custom date format
                        placeholderText="Date Of Start"
                        wrapperClassName="react-datePicker"
                      />
                      {this.validator.message(
                        "enroll_start_date",
                        enroll_start_date,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The start date is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>

                  <Modal.Footer>
                    <Button
                      variant="primary"
                      style={addBtnStyle}
                      onClick={this.handleSubmit}
                    >
                      Save changes
                    </Button>
                    <Button
                      id="modal-close-button"
                      style={closeBtnStyle}
                      onClick={() =>
                        this.handleSweetAlert(
                          this.toggleAddEnrollmentModal,
                          "showInsertModal"
                        )
                      }
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </div>
          ) : (
            <div>No users to Add</div>
          )}
        </Modal>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showModal}
          onHide={() =>
            this.handleSweetAlert(this.toggleUpdateEnrollmentModal, "showModal")
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Enrollment Information</Modal.Title>
          </Modal.Header>

          {isEditSubmitting ? (
            <div className="loading-container">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div>
              <Modal.Body>
                <Form>
                  <Row className="mb-5">
                    <Form.Group
                      className="mb-5"
                      as={Col}
                      md="12"
                      controlId="startDate"
                    >
                      <Form.Label>Start Date*</Form.Label>
                      <DatePicker
                        className="py-2 form-control" // Add Bootstrap form control class
                        selected={
                          enroll_start_date
                            ? new Date(enroll_start_date)
                            : undefined
                        }
                        name="enroll_start_date"
                        onChange={this.handleDatePickerChange}
                        dateFormat="dd/MM/yyyy" // Set custom date format
                        placeholderText="Date Of Start"
                        wrapperClassName="react-datePicker"
                      />
                      {this.validator.message(
                        "enroll_start_date",
                        enroll_start_date,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The start date is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>

                  <Modal.Footer>
                    <Button
                      className="modal-btn"
                      variant="primary"
                      style={addBtnStyle}
                      onClick={this.handleUpdate}
                    >
                      Save changes
                    </Button>
                    <Button
                      className="modal-btn"
                      id="modal-close-button"
                      style={closeBtnStyle}
                      onClick={() =>
                        this.handleSweetAlert(
                          this.toggleUpdateEnrollmentModal,
                          "showModal"
                        )
                      }
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </div>
          )}
        </Modal>
      </>
    );
  }
}
