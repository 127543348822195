

export const quesChapterTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_CHAPTER_REQUEST: 'DELETE_CHAPTER_REQUEST',
    DELETE_CHAPTER_SUCCESS: 'DELETE_CHAPTER_SUCCESS',
    DELETE_CHAPTER_FAILURE: 'DELETE_CHAPTER_FAILURE',

    UPDATE_CHAPTER_REQUEST: 'UPDATE_CHAPTER_REQUEST',
    UPDATE_CHAPTER_SUCCESS: 'UPDATE_CHAPTER_SUCCESS',
    UPDATE_CHAPTER_FAILURE: 'UPDATE_CHAPTER_FAILURE',

    CREATE_CHAPTER_REQUEST: 'CREATE_CHAPTER_REQUEST',
    CREATE_CHAPTER_SUCCESS: 'CREATE_CHAPTER_SUCCESS',
    CREATE_CHAPTER_FAILURE: 'CREATE_CHAPTER_FAILURE',

    GET_ALL_QUESTION_CHAPTERS: 'GET_ALL_QUESTION_CHAPTERS',
    GET_ALL_QUESTION_CHAPTERS_REQUEST: 'GET_ALL_QUESTION_CHAPTERS_REQUEST',
    GET_ALL_QUESTION_CHAPTERS_SUCCESS: 'GET_ALL_QUESTION_CHAPTERS_SUCCESS',
    GET_ALL_QUESTION_CHAPTERS_FAILURE: 'GET_ALL_QUESTION_CHAPTERS_FAILURE',
};


export const quesGradeTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_GRADE_REQUEST: 'DELETE_GRADE_REQUEST',
    DELETE_GRADE_SUCCESS: 'DELETE_GRADE_SUCCESS',
    DELETE_GRADE_FAILURE: 'DELETE_GRADE_FAILURE',

    UPDATE_GRADE_REQUEST: 'UPDATE_GRADE_REQUEST',
    UPDATE_GRADE_SUCCESS: 'UPDATE_GRADE_SUCCESS',
    UPDATE_GRADE_FAILURE: 'UPDATE_GRADE_FAILURE',

    CREATE_GRADE_REQUEST: 'CREATE_GRADE_REQUEST',
    CREATE_GRADE_SUCCESS: 'CREATE_GRADE_SUCCESS',
    CREATE_GRADE_FAILURE: 'CREATE_GRADE_FAILURE',

    GET_ALL_QUESTION_GRADES: 'GET_ALL_QUESTION_GRADES',
    GET_ALL_QUESTION_GRADES_REQUEST: 'GET_ALL_QUESTION_GRADES_REQUEST',
    GET_ALL_QUESTION_GRADES_SUCCESS: 'GET_ALL_QUESTION_GRADES_SUCCESS',
    GET_ALL_QUESTION_GRADES_FAILURE: 'GET_ALL_QUESTION_GRADES_FAILURE',
};


export const quesCatTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    CREATE_CATEGORY_REQUEST: 'CREATE_QUESS_CATEGORY_REQUEST',
    CREATE_CATEGORY_SUCCESS: 'CREATE_QUESS_CATEGORY_SUCCESS',
    CREATE_CATEGORY_FAILURE: 'CREATE_QUESS_CATEGORY_FAILURE',

    GET_ALL_QUESTION_CATEGORIES: 'GET_ALL_QUESTION_CATEGORIES',
    GET_ALL_QUESTION_CATEGORIES_REQUEST: 'GET_ALL_QUESTION_CATEGORIES_REQUEST',
    GET_ALL_QUESTION_CATEGORIES_SUCCESS: 'GET_ALL_QUESTION_CATEGORIES_SUCCESS',
    GET_ALL_QUESTION_CATEGORIES_FAILURE: 'GET_ALL_QUESTION_CATEGORIES_FAILURE',

};


export const quesTypeTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_TYPE_REQUEST: 'DELETE_TYPE_REQUEST',
    DELETE_TYPE_SUCCESS: 'DELETE_TYPE_SUCCESS',
    DELETE_TYPE_FAILURE: 'DELETE_TYPE_FAILURE',

    UPDATE_TYPE_REQUEST: 'UPDATE_TYPE_REQUEST',
    UPDATE_TYPE_SUCCESS: 'UPDATE_TYPE_SUCCESS',
    UPDATE_TYPE_FAILURE: 'UPDATE_TYPE_FAILURE',

    CREATE_TYPE_REQUEST: 'CREATE_TYPE_REQUEST',
    CREATE_TYPE_SUCCESS: 'CREATE_TYPE_SUCCESS',
    CREATE_TYPE_FAILURE: 'CREATE_TYPE_FAILURE',

    GET_ALL_QUESTION_TYPES: 'GET_ALL_QUESTION_TYPES',
    GET_ALL_QUESTION_TYPES_REQUEST: 'GET_ALL_QUESTION_TYPES_REQUEST',
    GET_ALL_QUESTION_TYPES_SUCCESS: 'GET_ALL_QUESTION_TYPES_SUCCESS',
    GET_ALL_QUESTION_TYPES_FAILURE: 'GET_ALL_QUESTION_TYPES_FAILURE',

};


export const quesDifficultyTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_DIFFICULTY_REQUEST: 'DELETE_DIFFICULTY_REQUEST',
    DELETE_DIFFICULTY_SUCCESS: 'DELETE_DIFFICULTY_SUCCESS',
    DELETE_DIFFICULTY_FAILURE: 'DELETE_DIFFICULTY_FAILURE',

    UPDATE_DIFFICULTY_REQUEST: 'UPDATE_DIFFICULTY_REQUEST',
    UPDATE_DIFFICULTY_SUCCESS: 'UPDATE_DIFFICULTY_SUCCESS',
    UPDATE_DIFFICULTY_FAILURE: 'UPDATE_DIFFICULTY_FAILURE',

    CREATE_DIFFICULTY_REQUEST: 'CREATE_DIFFICULTY_REQUEST',
    CREATE_DIFFICULTY_SUCCESS: 'CREATE_DIFFICULTY_SUCCESS',
    CREATE_DIFFICULTY_FAILURE: 'CREATE_DIFFICULTY_FAILURE',

    GET_ALL_QUESTION_DIFFICULTIES: 'GET_ALL_QUESTION_DIFFICULTIES',
    GET_ALL_QUESTION_DIFFICULTIES_REQUEST: 'GET_ALL_QUESTION_DIFFICULTIES_REQUEST',
    GET_ALL_QUESTION_DIFFICULTIES_SUCCESS: 'GET_ALL_QUESTION_DIFFICULTIES_SUCCESS',
    GET_ALL_QUESTION_DIFFICULTIES_FAILURE: 'GET_ALL_QUESTION_DIFFICULTIES_FAILURE',

};



export const quesSubjectTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_SUBJECT_REQUEST: 'DELETE_SUBJECT_REQUEST',
    DELETE_SUBJECT_SUCCESS: 'DELETE_SUBJECT_SUCCESS',
    DELETE_SUBJECT_FAILURE: 'DELETE_SUBJECT_FAILURE',

    UPDATE_SUBJECT_REQUEST: 'UPDATE_SUBJECT_REQUEST',
    UPDATE_SUBJECT_SUCCESS: 'UPDATE_SUBJECT_SUCCESS',
    UPDATE_SUBJECT_FAILURE: 'UPDATE_SUBJECT_FAILURE',

    CREATE_SUBJECT_REQUEST: 'CREATE_SUBJECT_REQUEST',
    CREATE_SUBJECT_SUCCESS: 'CREATE_SUBJECT_SUCCESS',
    CREATE_SUBJECT_FAILURE: 'CREATE_SUBJECT_FAILURE',

    GET_ALL_QUESTION_SUBJECTS: 'GET_ALL_QUESTION_SUBJECTS',
    GET_ALL_QUESTION_SUBJECTS_REQUEST: 'GET_ALL_QUESTION_SUBJECTS_REQUEST',
    GET_ALL_QUESTION_SUBJECTS_SUCCESS: 'GET_ALL_QUESTION_SUBJECTS_SUCCESS',
    GET_ALL_QUESTION_SUBJECTS_FAILURE: 'GET_ALL_QUESTION_SUBJECTS_FAILURE',

};


export const quesLanguageTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_LANGUAGE_REQUEST: 'DELETE_LANGUAGE_REQUEST',
    DELETE_LANGUAGE_SUCCESS: 'DELETE_LANGUAGE_SUCCESS',
    DELETE_LANGUAGE_FAILURE: 'DELETE_LANGUAGE_FAILURE',

    UPDATE_LANGUAGE_REQUEST: 'UPDATE_LANGUAGE_REQUEST',
    UPDATE_LANGUAGE_SUCCESS: 'UPDATE_LANGUAGE_SUCCESS',
    UPDATE_LANGUAGE_FAILURE: 'UPDATE_LANGUAGE_FAILURE',

    CREATE_LANGUAGE_REQUEST: 'CREATE_LANGUAGE_REQUEST',
    CREATE_LANGUAGE_SUCCESS: 'CREATE_LANGUAGE_SUCCESS',
    CREATE_LANGUAGE_FAILURE: 'CREATE_LANGUAGE_FAILURE',

    GET_ALL_QUESTION_LANGUAGES: 'GET_ALL_QUESTION_LANGUAGES',
    GET_ALL_QUESTION_LANGUAGES_REQUEST: 'GET_ALL_QUESTION_LANGUAGES_REQUEST',
    GET_ALL_QUESTION_LANGUAGES_SUCCESS: 'GET_ALL_QUESTION_LANGUAGES_SUCCESS',
    GET_ALL_QUESTION_LANGUAGES_FAILURE: 'GET_ALL_QUESTION_LANGUAGES_FAILURE',

};


export const quesTermTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_TERM_REQUEST: 'DELETE_TERM_REQUEST',
    DELETE_TERM_SUCCESS: 'DELETE_TERM_SUCCESS',
    DELETE_TERM_FAILURE: 'DELETE_TERM_FAILURE',

    UPDATE_TERM_REQUEST: 'UPDATE_TERM_REQUEST',
    UPDATE_TERM_SUCCESS: 'UPDATE_TERM_SUCCESS',
    UPDATE_TERM_FAILURE: 'UPDATE_TERM_FAILURE',

    CREATE_TERM_REQUEST: 'CREATE_TERM_REQUEST',
    CREATE_TERM_SUCCESS: 'CREATE_TERM_SUCCESS',
    CREATE_TERM_FAILURE: 'CREATE_TERM_FAILURE',

    GET_ALL_QUESTION_TERMS: 'GET_ALL_QUESTION_TERMS',
    GET_ALL_QUESTION_TERMS_REQUEST: 'GET_ALL_QUESTION_TERMS_REQUEST',
    GET_ALL_QUESTION_TERMS_SUCCESS: 'GET_ALL_QUESTION_TERMS_SUCCESS',
    GET_ALL_QUESTION_TERMS_FAILURE: 'GET_ALL_QUESTION_TERMS_FAILURE',

};


export const quesYearTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_YEAR_REQUEST: 'DELETE_YEAR_REQUEST',
    DELETE_YEAR_SUCCESS: 'DELETE_YEAR_SUCCESS',
    DELETE_YEAR_FAILURE: 'DELETE_YEAR_FAILURE',

    UPDATE_YEAR_REQUEST: 'UPDATE_YEAR_REQUEST',
    UPDATE_YEAR_SUCCESS: 'UPDATE_YEAR_SUCCESS',
    UPDATE_YEAR_FAILURE: 'UPDATE_YEAR_FAILURE',

    CREATE_YEAR_REQUEST: 'CREATE_YEAR_REQUEST',
    CREATE_YEAR_SUCCESS: 'CREATE_YEAR_SUCCESS',
    CREATE_YEAR_FAILURE: 'CREATE_YEAR_FAILURE',

    GET_ALL_QUESTION_YEARS: 'GET_ALL_QUESTION_YEARS',
    GET_ALL_QUESTION_YEARS_REQUEST: 'GET_ALL_QUESTION_YEARS_REQUEST',
    GET_ALL_QUESTION_YEARS_SUCCESS: 'GET_ALL_QUESTION_YEARS_SUCCESS',
    GET_ALL_QUESTION_YEARS_FAILURE: 'GET_ALL_QUESTION_YEARS_FAILURE',

};



export const quesAuthorTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_AUTHOR_REQUEST: 'DELETE_AUTHOR_REQUEST',
    DELETE_AUTHOR_SUCCESS: 'DELETE_AUTHOR_SUCCESS',
    DELETE_AUTHOR_FAILURE: 'DELETE_AUTHOR_FAILURE',

    UPDATE_AUTHOR_REQUEST: 'UPDATE_AUTHOR_REQUEST',
    UPDATE_AUTHOR_SUCCESS: 'UPDATE_AUTHOR_SUCCESS',
    UPDATE_AUTHOR_FAILURE: 'UPDATE_AUTHOR_FAILURE',

    CREATE_AUTHOR_REQUEST: 'CREATE_AUTHOR_REQUEST',
    CREATE_AUTHOR_SUCCESS: 'CREATE_AUTHOR_SUCCESS',
    CREATE_AUTHOR_FAILURE: 'CREATE_AUTHOR_FAILURE',

    GET_ALL_QUESTION_AUTHORS: 'GET_ALL_QUESTION_AUTHORS',
    GET_ALL_QUESTION_AUTHORS_REQUEST: 'GET_ALL_QUESTION_AUTHORS_REQUEST',
    GET_ALL_QUESTION_AUTHORS_SUCCESS: 'GET_ALL_QUESTION_AUTHORS_SUCCESS',
    GET_ALL_QUESTION_AUTHORS_FAILURE: 'GET_ALL_QUESTION_AUTHORS_FAILURE',

};



export const quesSourceTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_SOURCE_REQUEST: 'DELETE_SOURCE_REQUEST',
    DELETE_SOURCE_SUCCESS: 'DELETE_SOURCE_SUCCESS',
    DELETE_SOURCE_FAILURE: 'DELETE_SOURCE_FAILURE',

    UPDATE_SOURCE_REQUEST: 'UPDATE_SOURCE_REQUEST',
    UPDATE_SOURCE_SUCCESS: 'UPDATE_SOURCE_SUCCESS',
    UPDATE_SOURCE_FAILURE: 'UPDATE_SOURCE_FAILURE',

    CREATE_SOURCE_REQUEST: 'CREATE_SOURCE_REQUEST',
    CREATE_SOURCE_SUCCESS: 'CREATE_SOURCE_SUCCESS',
    CREATE_SOURCE_FAILURE: 'CREATE_SOURCE_FAILURE',

    GET_ALL_QUESTION_SOURCES: 'GET_ALL_QUESTION_SOURCES',
    GET_ALL_QUESTION_SOURCES_REQUEST: 'GET_ALL_QUESTION_SOURCES_REQUEST',
    GET_ALL_QUESTION_SOURCES_SUCCESS: 'GET_ALL_QUESTION_SOURCES_SUCCESS',
    GET_ALL_QUESTION_SOURCES_FAILURE: 'GET_ALL_QUESTION_SOURCES_FAILURE',

};

export const assessAttemptTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_ATTEMPT_REQUEST: 'DELETE_ATTEMPT_REQUEST',
    DELETE_ATTEMPT_SUCCESS: 'DELETE_ATTEMPT_SUCCESS',
    DELETE_ATTEMPT_FAILURE: 'DELETE_ATTEMPT_FAILURE',

    UPDATE_ATTEMPT_REQUEST: 'UPDATE_ATTEMPT_REQUEST',
    UPDATE_ATTEMPT_SUCCESS: 'UPDATE_ATTEMPT_SUCCESS',
    UPDATE_ATTEMPT_FAILURE: 'UPDATE_ATTEMPT_FAILURE',

    CREATE_ATTEMPT_REQUEST: 'CREATE_ATTEMPT_REQUEST',
    CREATE_ATTEMPT_SUCCESS: 'CREATE_ATTEMPT_SUCCESS',
    CREATE_ATTEMPT_FAILURE: 'CREATE_ATTEMPT_FAILURE',

    GET_ALL_ASSESSMENT_ATTEMPTS: 'GET_ALL_ASSESSMENT_ATTEMPTS',
    GET_ALL_ASSESSMENT_ATTEMPTS_REQUEST: 'GET_ALL_ASSESSMENT_ATTEMPTS_REQUEST',
    GET_ALL_ASSESSMENT_ATTEMPTS_SUCCESS: 'GET_ALL_ASSESSMENT_ATTEMPTS_SUCCESS',
    GET_ALL_ASSESSMENT_ATTEMPTS_FAILURE: 'GET_ALL_ASSESSMENT_ATTEMPTS_FAILURE',
};



export const assessChapterTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_CHAPTER_REQUEST: 'DELETE_CHAPTER_REQUEST',
    DELETE_CHAPTER_SUCCESS: 'DELETE_CHAPTER_SUCCESS',
    DELETE_CHAPTER_FAILURE: 'DELETE_CHAPTER_FAILURE',

    UPDATE_CHAPTER_REQUEST: 'UPDATE_CHAPTER_REQUEST',
    UPDATE_CHAPTER_SUCCESS: 'UPDATE_CHAPTER_SUCCESS',
    UPDATE_CHAPTER_FAILURE: 'UPDATE_CHAPTER_FAILURE',

    CREATE_CHAPTER_REQUEST: 'CREATE_CHAPTER_REQUEST',
    CREATE_CHAPTER_SUCCESS: 'CREATE_CHAPTER_SUCCESS',
    CREATE_CHAPTER_FAILURE: 'CREATE_CHAPTER_FAILURE',

    GET_ALL_ASSESSMENT_CHAPTERS: 'GET_ALL_ASSESSMENT_CHAPTERS',
    GET_ALL_ASSESSMENT_CHAPTERS_REQUEST: 'GET_ALL_ASSESSMENT_CHAPTERS_REQUEST',
    GET_ALL_ASSESSMENT_CHAPTERS_SUCCESS: 'GET_ALL_ASSESSMENT_CHAPTERS_SUCCESS',
    GET_ALL_ASSESSMENT_CHAPTERS_FAILURE: 'GET_ALL_ASSESSMENT_CHAPTERS_FAILURE',
};


export const assessGradeTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_GRADE_REQUEST: 'DELETE_GRADE_REQUEST',
    DELETE_GRADE_SUCCESS: 'DELETE_GRADE_SUCCESS',
    DELETE_GRADE_FAILURE: 'DELETE_GRADE_FAILURE',

    UPDATE_GRADE_REQUEST: 'UPDATE_GRADE_REQUEST',
    UPDATE_GRADE_SUCCESS: 'UPDATE_GRADE_SUCCESS',
    UPDATE_GRADE_FAILURE: 'UPDATE_GRADE_FAILURE',

    CREATE_GRADE_REQUEST: 'CREATE_GRADE_REQUEST',
    CREATE_GRADE_SUCCESS: 'CREATE_GRADE_SUCCESS',
    CREATE_GRADE_FAILURE: 'CREATE_GRADE_FAILURE',

    GET_ALL_ASSESSMENT_GRADES: 'GET_ALL_ASSESSMENT_GRADES',
    GET_ALL_ASSESSMENT_GRADES_REQUEST: 'GET_ALL_ASSESSMENT_GRADES_REQUEST',
    GET_ALL_ASSESSMENT_GRADES_SUCCESS: 'GET_ALL_ASSESSMENT_GRADES_SUCCESS',
    GET_ALL_ASSESSMENT_GRADES_FAILURE: 'GET_ALL_ASSESSMENT_GRADES_FAILURE',
};









export const assessCatTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_FAILURE: 'DELETE_CATEGORY_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    CREATE_CATEGORY_REQUEST: 'CREATE_ASSESS_CATEGORY_REQUEST',
    CREATE_CATEGORY_SUCCESS: 'CREATE_ASSESS_CATEGORY_SUCCESS',
    CREATE_CATEGORY_FAILURE: 'CREATE_ASSESS_CATEGORY_FAILURE',

    GET_ALL_ASSESSMENT_CATEGORIES: 'GET_ALL_ASSESSMENT_CATEGORIES',
    GET_ALL_ASSESSMENT_CATEGORIES_REQUEST: 'GET_ALL_ASSESSMENT_CATEGORIES_REQUEST',
    GET_ALL_ASSESSMENT_CATEGORIES_SUCCESS: 'GET_ALL_ASSESSMENT_CATEGORIES_SUCCESS',
    GET_ALL_ASSESSMENT_CATEGORIES_FAILURE: 'GET_ALL_ASSESSMENT_CATEGORIES_FAILURE',

};


export const assessDifficultyTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_DIFFICULTY_REQUEST: 'DELETE_DIFFICULTY_REQUEST',
    DELETE_DIFFICULTY_SUCCESS: 'DELETE_DIFFICULTY_SUCCESS',
    DELETE_DIFFICULTY_FAILURE: 'DELETE_DIFFICULTY_FAILURE',

    UPDATE_DIFFICULTY_REQUEST: 'UPDATE_DIFFICULTY_REQUEST',
    UPDATE_DIFFICULTY_SUCCESS: 'UPDATE_DIFFICULTY_SUCCESS',
    UPDATE_DIFFICULTY_FAILURE: 'UPDATE_DIFFICULTY_FAILURE',

    CREATE_DIFFICULTY_REQUEST: 'CREATE_DIFFICULTY_REQUEST',
    CREATE_DIFFICULTY_SUCCESS: 'CREATE_DIFFICULTY_SUCCESS',
    CREATE_DIFFICULTY_FAILURE: 'CREATE_DIFFICULTY_FAILURE',

    GET_ALL_ASSESSMENT_DIFFICULTIES: 'GET_ALL_ASSESSMENT_DIFFICULTIES',
    GET_ALL_ASSESSMENT_DIFFICULTIES_REQUEST: 'GET_ALL_ASSESSMENT_DIFFICULTIES_REQUEST',
    GET_ALL_ASSESSMENT_DIFFICULTIES_SUCCESS: 'GET_ALL_ASSESSMENT_DIFFICULTIES_SUCCESS',
    GET_ALL_ASSESSMENT_DIFFICULTIES_FAILURE: 'GET_ALL_ASSESSMENT_DIFFICULTIES_FAILURE',

};



export const assessSubjectTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_SUBJECT_REQUEST: 'DELETE_SUBJECT_REQUEST',
    DELETE_SUBJECT_SUCCESS: 'DELETE_SUBJECT_SUCCESS',
    DELETE_SUBJECT_FAILURE: 'DELETE_SUBJECT_FAILURE',

    UPDATE_SUBJECT_REQUEST: 'UPDATE_SUBJECT_REQUEST',
    UPDATE_SUBJECT_SUCCESS: 'UPDATE_SUBJECT_SUCCESS',
    UPDATE_SUBJECT_FAILURE: 'UPDATE_SUBJECT_FAILURE',

    CREATE_SUBJECT_REQUEST: 'CREATE_SUBJECT_REQUEST',
    CREATE_SUBJECT_SUCCESS: 'CREATE_SUBJECT_SUCCESS',
    CREATE_SUBJECT_FAILURE: 'CREATE_SUBJECT_FAILURE',

    GET_ALL_ASSESSMENT_SUBJECTS: 'GET_ALL_ASSESSMENT_SUBJECTS',
    GET_ALL_ASSESSMENT_SUBJECTS_REQUEST: 'GET_ALL_ASSESSMENT_SUBJECTS_REQUEST',
    GET_ALL_ASSESSMENT_SUBJECTS_SUCCESS: 'GET_ALL_ASSESSMENT_SUBJECTS_SUCCESS',
    GET_ALL_ASSESSMENT_SUBJECTS_FAILURE: 'GET_ALL_ASSESSMENT_SUBJECTS_FAILURE',

};


export const assessLanguageTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_LANGUAGE_REQUEST: 'DELETE_LANGUAGE_REQUEST',
    DELETE_LANGUAGE_SUCCESS: 'DELETE_LANGUAGE_SUCCESS',
    DELETE_LANGUAGE_FAILURE: 'DELETE_LANGUAGE_FAILURE',

    UPDATE_LANGUAGE_REQUEST: 'UPDATE_LANGUAGE_REQUEST',
    UPDATE_LANGUAGE_SUCCESS: 'UPDATE_LANGUAGE_SUCCESS',
    UPDATE_LANGUAGE_FAILURE: 'UPDATE_LANGUAGE_FAILURE',

    CREATE_LANGUAGE_REQUEST: 'CREATE_LANGUAGE_REQUEST',
    CREATE_LANGUAGE_SUCCESS: 'CREATE_LANGUAGE_SUCCESS',
    CREATE_LANGUAGE_FAILURE: 'CREATE_LANGUAGE_FAILURE',

    GET_ALL_ASSESSMENT_LANGUAGES: 'GET_ALL_ASSESSMENT_LANGUAGES',
    GET_ALL_ASSESSMENT_LANGUAGES_REQUEST: 'GET_ALL_ASSESSMENT_LANGUAGES_REQUEST',
    GET_ALL_ASSESSMENT_LANGUAGES_SUCCESS: 'GET_ALL_ASSESSMENT_LANGUAGES_SUCCESS',
    GET_ALL_ASSESSMENT_LANGUAGES_FAILURE: 'GET_ALL_ASSESSMENT_LANGUAGES_FAILURE',

};


export const assessTermTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_TERM_REQUEST: 'DELETE_TERM_REQUEST',
    DELETE_TERM_SUCCESS: 'DELETE_TERM_SUCCESS',
    DELETE_TERM_FAILURE: 'DELETE_TERM_FAILURE',

    UPDATE_TERM_REQUEST: 'UPDATE_TERM_REQUEST',
    UPDATE_TERM_SUCCESS: 'UPDATE_TERM_SUCCESS',
    UPDATE_TERM_FAILURE: 'UPDATE_TERM_FAILURE',

    CREATE_TERM_REQUEST: 'CREATE_TERM_REQUEST',
    CREATE_TERM_SUCCESS: 'CREATE_TERM_SUCCESS',
    CREATE_TERM_FAILURE: 'CREATE_TERM_FAILURE',

    GET_ALL_ASSESSMENT_TERMS: 'GET_ALL_ASSESSMENT_TERMS',
    GET_ALL_ASSESSMENT_TERMS_REQUEST: 'GET_ALL_ASSESSMENT_TERMS_REQUEST',
    GET_ALL_ASSESSMENT_TERMS_SUCCESS: 'GET_ALL_ASSESSMENT_TERMS_SUCCESS',
    GET_ALL_ASSESSMENT_TERMS_FAILURE: 'GET_ALL_ASSESSMENT_TERMS_FAILURE',

};


export const assessYearTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_YEAR_REQUEST: 'DELETE_YEAR_REQUEST',
    DELETE_YEAR_SUCCESS: 'DELETE_YEAR_SUCCESS',
    DELETE_YEAR_FAILURE: 'DELETE_YEAR_FAILURE',

    UPDATE_YEAR_REQUEST: 'UPDATE_YEAR_REQUEST',
    UPDATE_YEAR_SUCCESS: 'UPDATE_YEAR_SUCCESS',
    UPDATE_YEAR_FAILURE: 'UPDATE_YEAR_FAILURE',

    CREATE_YEAR_REQUEST: 'CREATE_YEAR_REQUEST',
    CREATE_YEAR_SUCCESS: 'CREATE_YEAR_SUCCESS',
    CREATE_YEAR_FAILURE: 'CREATE_YEAR_FAILURE',

    GET_ALL_ASSESSMENT_YEARS: 'GET_ALL_ASSESSMENT_YEARS',
    GET_ALL_ASSESSMENT_YEARS_REQUEST: 'GET_ALL_ASSESSMENT_YEARS_REQUEST',
    GET_ALL_ASSESSMENT_YEARS_SUCCESS: 'GET_ALL_ASSESSMENT_YEARS_SUCCESS',
    GET_ALL_ASSESSMENT_YEARS_FAILURE: 'GET_ALL_ASSESSMENT_YEARS_FAILURE',

};



export const assessAuthorTypes = {
    LIBRARY_PAGE_REFRESH: 'LIBRARY_PAGE_REFRESH',

    DELETE_AUTHOR_REQUEST: 'DELETE_AUTHOR_REQUEST',
    DELETE_AUTHOR_SUCCESS: 'DELETE_AUTHOR_SUCCESS',
    DELETE_AUTHOR_FAILURE: 'DELETE_AUTHOR_FAILURE',

    UPDATE_AUTHOR_REQUEST: 'UPDATE_AUTHOR_REQUEST',
    UPDATE_AUTHOR_SUCCESS: 'UPDATE_AUTHOR_SUCCESS',
    UPDATE_AUTHOR_FAILURE: 'UPDATE_AUTHOR_FAILURE',

    CREATE_AUTHOR_REQUEST: 'CREATE_AUTHOR_REQUEST',
    CREATE_AUTHOR_SUCCESS: 'CREATE_AUTHOR_SUCCESS',
    CREATE_AUTHOR_FAILURE: 'CREATE_AUTHOR_FAILURE',

    GET_ALL_ASSESSMENT_AUTHORS: 'GET_ALL_ASSESSMENT_AUTHORS',
    GET_ALL_ASSESSMENT_AUTHORS_REQUEST: 'GET_ALL_ASSESSMENT_AUTHORS_REQUEST',
    GET_ALL_ASSESSMENT_AUTHORS_SUCCESS: 'GET_ALL_ASSESSMENT_AUTHORS_SUCCESS',
    GET_ALL_ASSESSMENT_AUTHORS_FAILURE: 'GET_ALL_ASSESSMENT_AUTHORS_FAILURE',

};

