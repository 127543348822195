import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import Card from "../../../components/common/Card.js";
import CardHeader from "../../../components/common/CardHeader.js";
import CardBody from "../../../components/common/CardBody.js";
import CardTitle from "../../../components/common/CardTitle.js";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { CountryService } from "../../../service/settings/manage/country.service.js";
import { ProvinceService } from "../../../service/settings/manage/province.service.js";
import { CityService } from "../../../service/settings/manage/city.service.js";
import { GenderService } from "../../../service/settings/manage/gender.service.js";
import { StatusTypesService } from "../../../service/settings/manage/status_type.service.js";
import { StatusService } from "../../../service/settings/manage/status.service.js";
import Spinner from "react-bootstrap/Spinner";
import "../../../components/admin/css/common.css";
import { history } from "../../../helper/history.js";
import CheckBox from "../../../components/common/form/CheckBox.js";
import CheckBoxInline from "../../../components/common/form/CheckBoxInline.js";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination as Pagination,
} from "@mui/material";
import debounce from "lodash.debounce";
import sweetAlert, {
  sweetStatusAlert,
} from "../../../components/common/SweetAlert.js";
import { ParentService } from "../../../service/manage/registration/parent.service.js";
import { UserManagementService } from "../../../service/user_management/user_management.service.js";
import SimpleReactValidator from "simple-react-validator";
import PageSpinner from "../../../components/common/spinner/PageSpinner.js";
import config from '../../../helper/config.js';
import {customerConstants} from "../../../helper/constants.js";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

export default class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      students: [],
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      genders: [],
      titles: [],
      countries: [],
      provinces: [],
      cities: [],
      stu_statuses: [],
      statusTypes: [],
      StuStatustype: "Student",
      showInsertModal: false,
      isLoading: false,
      isTableDataLoading: true,
      limit: 10, // Set the desired limit
      offset: 0, // Set the desired offset
      search_term: "",
      totalStudents: 10,
      currentPage: 0,
      isModified: false,
      isAddressesLoading: false,
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      sortColumn: "user_id", // Default sort column
      sortDirection: "desc",
      errorProvince: [],
      errorCity: [],
      addressValidity: [],
      hidden: false,
      node_mid: null,
      user_list_table_id: null,
      session_stu_id: null,
      stu_table_log_id: 3,
      country_id: "",
      province_id: "",
      city_id: "",
      address_1: "",
      address_2: "",
      postalcode: "",
      user_id: "",
    };

    this.debouncedHandleSearch = debounce(this.fetchAllStudents, 2000);

    this.createValidationsInstance();
    this.formInitialState = {
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      phone_number: "",
      gender_id: "",
      status_id: "",
      created_on: "",
      created_by: "",
      postalcode: "",
    };
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        address: {
          message: "The :attribute must be a valid address",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z-/:0-9,\s]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
        },
        dateBefore: {
          message: "The :attribute must be before current date",
          rule: (val, params, validator) => {
            const currentDate = new Date().toISOString().split("T");
            return val < currentDate[0];
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
        phone_number: {
          message: "The :attribute must be a valid phone number",
          rule: (val, params, validator) => {
            const isTenDigitsStartingWithZero =
              val.length === 10 && val.startsWith("0");
            const isNineDigits = val.length === 9 && !val.startsWith("0");
            return isTenDigitsStartingWithZero || isNineDigits;
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true,
        },
      },
    });
    this.updateValidator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.loadInitialData();
  }

  loadInitialData() {
    this.setState(
      {
        user_list_table_id: sessionStorage.getItem("table_log_id"),
        session_stu_id: sessionStorage.getItem("manage_user_id"),
      },
      () => {
      //   this.fetchAllStudents();
      //   this.getAllGenders();
      //   this.getAllStatusTypes();
      //   this.getAllContries();
      // 
    }
    );
  }

  getAllContries() {
    CountryService.getAll().then((res) => {
      this.setState({ countries: res.data });
    });
  }

  getAllGenders() {
    GenderService.getAllGenders().then((res) => {
      this.setState({ genders: res.data });
    });
  }

  getAllStatusTypes() {
    StatusTypesService.getAll().then((res) => {
      this.setState({ statusTypes: res.data });
      this.getAllStatuses();
    });
  }

  getAllStatuses() {
    const stu_type_id = this.getRespectiveStatusTypeID(
      this.state.StuStatustype
    );
    StatusService.getByTypeId(stu_type_id).then((res) => {
      this.setState({ stu_statuses: res.data });
    });
  }

  fetchAllStudents = () => {
    const {
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term,
      user_list_table_id,
      session_stu_id,
    } = this.state;
    let mid = 200;
    let create_user_id = null;
    let create_user_table_id = null;
    let user_table_id = null;
    let user_id = null;

    ParentService.getAll(
      user_id,
      user_table_id,
      create_user_id,
      create_user_table_id,
      mid,
      limit,
      offset,
      sortColumn,
      sortDirection,
      search_term
    )
      .then((response) => {
        if (response.success) {
          this.setState({
            students: response.data.parents,
            totalStudents: response.data.total_count, // Update totalStudents with the correct count
          });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching students Please refresh the screen!",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        sweetStatusAlert(
          "Failed!",
          "Error fetching students Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  handleSortChange = (sortColumn) => {
    // If the same column is clicked, toggle the sort direction
    const { sortColumn: currentSortColumn, sortDirection } = this.state;
    const newSortDirection =
      currentSortColumn === sortColumn && sortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        sortColumn,
        sortDirection: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.fetchAllStudents();
      }
    );
  };

  handleSweetAlert = (toggleFunction, modalVisibility) => {
    if (this.state.isModified) {
      sweetAlert().then((response) => {
        this.validator.purgeFields();
        this.validator.hideMessages();
        this.setState(this.formInitialState);
        this.setState(() => ({
          [modalVisibility]: response,
          isModified: false,
        }));
      });
    } else {
      this.validator.purgeFields();
      this.validator.hideMessages();
      this.setState(this.formInitialState);
      toggleFunction();
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState(() => ({ [name]: value, isModified: true }));
    if (name === "country_id") {
      const country_id = value;
      this.setState({
        cities: [],
        provinces: [],
        city_id: "",
        province_id: "",
      });
      this.getProvinceForCountry(country_id);
    }
    if (name === "province_id") {
      const province_id = value;
      this.setState({ cities: [], city_id: "" });
      this.getCitiesForProvince(province_id);
    }
  };

  getProvinceForCountry = (country_id) => {
    if (country_id) {
      ProvinceService.getProvinceForCountry(country_id).then((response) => {
        if (response.success) {
          this.setState({ provinces: response.data });
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      });
    }
  };

  getCitiesForProvince = (province_id) => {
    if (province_id) {
      CityService.getCitiesForProvince(province_id).then((response) => {
        if (response.success) {
          this.setState({ cities: response.data });
        } else {
          sweetStatusAlert("Failed!", response.message, "error");
        }
      });
    }
  };

  toggleUpdateStudentModal = (student) => {
    this.setState(
      (prevState) => ({
        showModal: !prevState.showModal,
        first_name: "",
        last_name: "",
        dob: "",
        email: "",
        phone_number: "",
        gender_id: "",
        status_id: "",
        created_on: "",
        created_by: "",
        address_1: "",
        address_2: "",
        city_id: "",
        province_id: "",
        postalcode: "",
        country_id: "",
      }),
      async () => {
        // State has changed, perform your logic here based on the state change
        if (this.state.showModal) {
          this.setState({
            first_name: student.first_name,
            last_name: student.last_name,
            email: student.email,
            phone_number: student.phone_number
              ? student.phone_number.toString()
              : student.phone_number,
            dob: student.dob ? student.dob : "",
            gender_id: student.gender_id ? student.gender_id : "",
            created_on: student.created_on ? student.created_on : "",
            created_by: student.created_by ? student.created_by : "",
            status_id: student.user_status_id ? student.user_status_id : "",
            user_id: student.user_id,
            node_mid: student.node_mid,
            address_1: student.address_1,
            address_2: student.address_2,
            city_id: student.city_id,
            province_id: student.province_id,
            postalcode: student.postalcode,
            country_id: student.country_id,
          });
          this.getProvinceForCountry(student.country_id);
          this.getCitiesForProvince(student.province_id);
        }
      }
    );
  };

  //Handle Edit Submit
  handleEditSubmit = async (event) => {
    event.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      try {
        this.setState({ isLoading: true });
        const {
          user_id,
          first_name,
          last_name,
          email,
          phone_number,
          dob,
          gender_id,
          created_on,
          created_by,
          status_id,
          node_mid,
          country_id,
          province_id,
          city_id,
          address_1,
          address_2,
          postalcode,
        } = this.state;

        const studentItem = {
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone_number: parseInt(phone_number),
          dob: dob,
          gender_id: parseInt(gender_id),
          created_on: created_on === "" ? null : created_on,
          created_by: created_by,
          user_status_id: parseInt(status_id),
          node_mid: node_mid,
          country_id: country_id,
          province_id: province_id,
          city_id: city_id,
          address_1: address_1,
          address_2: address_2,
          postalcode: postalcode,
        };

        const studentId = user_id;
        const studentResponse = await ParentService.update(
          JSON.stringify(studentItem),
          studentId
        );

        if (studentResponse.success) {
          this.fetchAllStudents();
          let usertype = sessionStorage.getItem("userType");
          if ("admin" !== usertype && "student" !== usertype) {
            sessionStorage.setItem("username", first_name + " " + last_name);
          }
          this.setState({ isLoading: false, showModal: false });
          sweetStatusAlert(
            "Success!",
            "Parent information updated successfully..!",
            "success"
          );

          this.validator.purgeFields();
          this.validator.hideMessages();
          this.setState(this.formInitialState);
        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Failed!", studentResponse.message, "error");
        }
      } catch (e) {
        this.setState({ isLoading: false });
        sweetStatusAlert("Failed!", e, "error");
      }
    }
  };

  getRespectiveStatusTypeID(name) {
    const type = this.state.statusTypes.find((item) => item.name === name);
    return type ? type.type_id : "";
  }

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  render() {
    const {
      isLoading,
      countries,
      limit,
      students,
      totalStudents,
      isTableDataLoading,
      currentPage,
      first_name,
      last_name,
      email,
      phone_number,
      dob,
      gender_id,
      created_on,
      created_by,
      status_id,
      genders,
      stu_statuses,
      editVisibility,
      addVisibility,
      deleteVisibility,
      country_id,
      province_id,
      city_id,
      address_1,
      address_2,
      postalcode,
      provinces,
      cities,
    } = this.state;
    this.validator.purgeFields();
    return (
      <>
          <ContentWrapper>
            <Card>
              <CardHeader>
                <CardTitle cardLabel={"USER-GROUP: User List (FORM Manage)​"} />
              </CardHeader>
              <CardBody>
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="mb-2"
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    // style={{ width: "18%" }}
                    className="col-md-3 col-12"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                {isLoading && (
                  <PageSpinner id="show" text="Loading, Please wait!" />
                )}
                {isTableDataLoading ? (
                  <div className="loading-container">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <div style={{ overflowX: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("stu_id")}
                          >
                            
                            User Code​{" "}
                            {this.state.sortColumn === "stu_id" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </TableCell>
                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("first_name")}
                          >
                            First Name{" "}
                            {this.state.sortColumn === "first_name" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </TableCell>
                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleSortChange("last_name")}
                          >
                            Last Name{" "}
                            {this.state.sortColumn === "last_name" && (
                              <span>
                                {this.state.sortDirection === "asc"
                                  ? <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </TableCell>
                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                          >
                            Privilege Role
                          </TableCell>
                          <TableCell
                            className="thead"
                            style={{ cursor: "pointer" }}
                          >
                            Add / Remove
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {students.map((studentItem) => (
                          <TableRow key={studentItem.user_id}>
                            <TableCell className="tData">
                              {studentItem.user_id}
                            </TableCell>
                            <TableCell className="tData">
                              {studentItem.first_name}
                            </TableCell>
                            <TableCell className="tData">
                              {studentItem.last_name}
                            </TableCell>
                            <TableCell className="tData">
                              <Form.Select
                                aria-label="Default select example"
                                name="gender_id"
                                value={gender_id}
                                onChange={this.handleChange}
                              >
                                <option value="">Select</option>
                                {genders.map((gender) => (
                                  <option
                                    key={gender.gender_id}
                                    value={gender.gender_id}
                                  >
                                    {gender.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </TableCell>
                            <TableCell className="tData">
                              <Row>
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="statusId"
                                >
                                  <CheckBoxInline>
                                    <CheckBox
                                      key={"selectedForm"}
                                      // onClick={(e) => this.makeRevise(e, i)}
                                      checked="true"
                                      checkBoxName="Add"
                                      style ={{
                                        color: "#5458AF",
                                      }}
                                    />
                                  </CheckBoxInline>
                                </Form.Group>
                                <Form.Group
                                  as={Col}
                                  md="6"
                                  controlId="statusId"
                                >
                                  <CheckBoxInline>
                                    <CheckBox
                                      key={"selectedForm"}
                                      // onClick={(e) => this.makeRevise(e, i)}
                                      checked="true"
                                      checkBoxName="Remove​"
                                      style ={{
                                        color: "#5458AF",
                                      }}
                                    />
                                  </CheckBoxInline>
                                </Form.Group>
                              </Row>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Pagination
                      component="div"
                      count={parseInt(totalStudents)}
                      page={currentPage}
                      rowsPerPage={limit}
                      rowsPerPageOptions={[5, 10, 20]}
                      onPageChange={this.handlePageChange}
                      onRowsPerPageChange={this.handleRowsPerPageChange}
                    />
                  </div>
                )}
              </CardBody>
            </Card>
          </ContentWrapper>

          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.showModal}
            onHide={() =>
              this.handleSweetAlert(this.toggleUpdateStudentModal, "showModal")
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Parent Information</Modal.Title>
            </Modal.Header>
            <div>
              <Modal.Body>
                <Form>
                  <Row>
                    <Modal.Title className="pl-4 mb-4">
                      Personal Details
                    </Modal.Title>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={first_name}
                        placeholder="First Name"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "first_name",
                        first_name,
                        "required|alpha_space|min:2|max:15",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The student first name is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        value={last_name}
                        placeholder="Last Name"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "last_name",
                        last_name,
                        "required|alpha_space|min:2|max:15",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The student last name is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Email*</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={email}
                        placeholder="example@gmail.com"
                        onChange={this.handleChange}
                        disabled
                      />
                      {this.validator.message(
                        "email",
                        email,
                        "required|email",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The email is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Phone Number*</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_number"
                        value={phone_number}
                        placeholder="Phone Number"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "phone_number",
                        phone_number,
                        "required|numeric|between:9,10|phone_number",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The phone number is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>DOB*</Form.Label>
                      <Form.Control
                        className="py-2"
                        type="date"
                        name="dob"
                        value={dob}
                        placeholder="Date of Birth"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "dob",
                        dob,
                        "required|dateBefore",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The date of birth is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Gender*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="gender_id"
                        value={gender_id}
                        onChange={this.handleChange}
                      >
                        <option value="">Select</option>
                        {genders.map((gender) => (
                          <option
                            key={gender.gender_id}
                            value={gender.gender_id}
                          >
                            {gender.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "gender_id",
                        gender_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The gender is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Created On</Form.Label>
                      <Form.Control
                        className="py-2"
                        type="date"
                        name="created_on"
                        value={created_on}
                        placeholder="Date of creation"
                        onChange={this.handleChange}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Created By*</Form.Label>
                      <Form.Control
                        type="text"
                        name="created_by"
                        value={created_by}
                        placeholder="Created By"
                        onChange={this.handleChange}
                      />
                      {this.validator.message(
                        "created_by",
                        created_by,
                        "required|alpha_space",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The created by is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Status*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="status_id"
                        value={status_id}
                        onChange={this.handleChange}
                      >
                        <option value="">Select</option>
                        {stu_statuses.map((status) => (
                          <option
                            key={status.status_id}
                            value={status.status_id}
                          >
                            {status.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "status_id",
                        status_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The status is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="pt-5">
                    <Modal.Title className="pl-4 mb-4">
                      Residential Details
                    </Modal.Title>
                  </Row>

                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Country*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="country_id"
                        onChange={this.handleChange}
                        value={country_id}
                      >
                        <option value="">Select</option>
                        {countries.map((country) => (
                          <option
                            value={country.country_id}
                            key={country.country_id}
                          >
                            {country.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "country_id",
                        country_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Country is required.",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Province/District*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="province_id"
                        onChange={this.handleChange}
                        value={province_id}
                      >
                        <option value="">Select</option>
                        {provinces?.map((province) => (
                          <option
                            value={province.province_id}
                            key={province.province_id}
                          >
                            {province.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message(
                        "province_id",
                        province_id,
                        "required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Province is required.",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>City*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="city_id"
                        onChange={this.handleChange}
                        value={city_id}
                      >
                        <option value="">Select</option>
                        {cities?.map((city) => (
                          <option value={city.city_id} key={city.city_id}>
                            {city.name}
                          </option>
                        ))}
                      </Form.Select>
                      {this.validator.message("city_id", city_id, "required", {
                        className: "text-danger",
                        messages: {
                          required: "The City is required.",
                        },
                      })}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Address 1*</Form.Label>
                      <Form.Control
                        type="text"
                        name="address_1"
                        placeholder="Address 1"
                        onChange={this.handleChange}
                        value={address_1}
                      />
                      {this.validator.message(
                        "address_1",
                        address_1,
                        "address|required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Address 1 is required.",
                            address: "The address 1 must be a valid address",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="my-5">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label>Address 2*</Form.Label>
                      <Form.Control
                        type="text"
                        name="address_2"
                        placeholder="Address 2"
                        onChange={this.handleChange}
                        value={address_2}
                      />
                      {this.validator.message(
                        "address_2",
                        address_2,
                        "address|required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The Address 2 is required.",
                            address: "The address 2 must be a valid address",
                          },
                        }
                      )}
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Postal Code*</Form.Label>
                      <Form.Control
                        type="text"
                        name="postalcode"
                        placeholder="Postal Code"
                        onChange={this.handleChange}
                        value={postalcode}
                      />
                      {this.validator.message(
                        "postalcode",
                        postalcode,
                        "numeric|required",
                        {
                          className: "text-danger",
                          messages: {
                            required: "The postal code is required.",
                            numeric: "The postal code must be a number",
                          },
                        }
                      )}
                    </Form.Group>
                  </Row>

                  <Modal.Footer>
                    <Button
                      className="modal-btn"
                      variant="primary"
                      style={ addBtnStyle }
                      onClick={this.handleEditSubmit}
                    >
                      Save changes
                    </Button>
                    <Button
                      className="modal-btn"
                      id="modal-close-button"
                      style={ closeBtnStyle }
                      onClick={() =>
                        this.handleSweetAlert(
                          this.toggleUpdateStudentModal,
                          "showModal"
                        )
                      }
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal.Body>
            </div>
          </Modal>
      </>
    );
  }
}
