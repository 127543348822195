import React from "react";

export default function SelfGradeModal({ children, onSubmit, modalId, dataBackDrop, dataKeyboard}) {

    return (
        
        <div className="modal fade" id={modalId} tabIndex="-1" role="dialog" data-backdrop={dataBackDrop} data-keyboard={dataKeyboard} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ margin: 0 }}>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    
                    <form className="form fv-plugins-bootstrap fv-plugins-framework" onSubmit={onSubmit}>
                        {children}
                    </form>

                </div>
            </div>
        </div>

    )
}
