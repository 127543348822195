import React, { Component } from "react";
import { connect } from "react-redux";
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import CardBody from '../../../components/common/CardBody';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import TabList from '../../../components/common/tabs/TabList';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { quesChapterActions } from "../../../actions/settings/question/chapterAction.js"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config.js";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class QuestionChapter extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newChapter:null,
        chapterRowsPerPage:5,
        currentChapterPage: 0,
        chapterData: [],
        chapter_sort_column: "chapter_id",
        chapter_sort_direction: "ASC",
        selectChapter: '',
        editChapter: false,
        chapter: '',
        limit: 10,
        chapter_search_term: "",
        chapter_offset: 0,
        searchError: false,
        chapter_limit:5,
        chapterId: '',
        chapterOptionsImport: [],
        selectedChapter: []
      };
      
      this.onNewChapterInput = this.onNewChapterInput.bind(this);
      this.onAddNewChapter = this.onAddNewChapter.bind(this);
      this.generateChapterOptions = this.generateChapterOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllQuestionChapters();
    }
    
    onNewChapterInput(e) {
        this.setState({
            newChapter: e.target.value
        });
    }
    
    async onAddNewChapter(e) {
        if (this.state.newChapter && this.state.newChapter.trim()) {
            await this.props.createChapter({ 'chapter': this.state.newChapter })
            if (this.props.successchaptercreate) {
                this.setState(
                    {
                        chapter_offset: 0,
                        currentChapterPage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllQuestionChapters();
                        this.generateChapterOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new chapter/module/unit!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-chapter').modal('hide')
                                window.$("#question-chapter").val('default');
                                window.$('#question-chapter').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errorchapterupdate) {
                window.Swal.fire({
                    title: 'Failed to add a new chapter/module/unit!',
                    text: this.props.errorchapterupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-chapter').modal('hide')
                        window.$("#question-chapter").val('default');
                        window.$('#question-chapter').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty chapter/module/unit!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newChapter: ''
        });
    }

    handleChapterSearch = (event) => {
        const chapter_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (chapter_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (chapter_search_term === "" || regex.test(chapter_search_term)) {
            this.setState(
                {
                    chapter_search_term,
                    chapter_offset: 0,
                    currentChapterPage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllQuestionChapters();
                }
            );
        } else {
            this.setState({ chapter_search_term, searchError: true });
        }
    };
    
    fetchAllQuestionChapters = async () => {
        const {
            chapter_limit,
            chapter_offset,
            chapter_sort_column,
            chapter_sort_direction,
            chapter_search_term
        } = this.state;
        await this.props.getAllQuestionChapters(chapter_limit, chapter_offset, chapter_sort_column, chapter_sort_direction, chapter_search_term);
        this.setState({ chapter_limit: this.state.chapterRowsPerPage });
        this.setState({ chapterData: this.props.allQuestionChapters })
    };

    handleChapterPageChange = (event, newChapterPage) => {
        const {chapterRowsPerPage} = this.state;
        const newChapterOffset = newChapterPage * chapterRowsPerPage;
        this.setState(
        {
            chapter_limit: chapterRowsPerPage,
            chapter_offset: newChapterOffset,
            currentChapterPage: newChapterPage
        },
        () => {
            this.fetchAllQuestionChapters();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {chapterRowsPerPage} = this.state;
        this.setState(
        {
            chapter_limit: chapterRowsPerPage,
            chapter_offset: newOffset,
            currentChapterPage: newPage
        },
        () => {
            this.fetchAllQuestionChapters();
        }
        );
    };
    
    //handle the data fetching as per the data per page limit gets changes
    handleChapterRowsPerPageChange = (event) => {
        const newChapterLimit = parseInt(event.target.value, 10);
        this.setState({chapterRowsPerPage: newChapterLimit});
        const newChapterPage = Math.floor(this.state.chapter_offset / newChapterLimit)
        this.setState(
        {
            chapter_limit: newChapterLimit,
            chapter_offset: newChapterLimit * newChapterPage,
            currentChapterPage: newChapterPage
        },
        () => {
            this.fetchAllQuestionChapters();
        }
        );
    };

    handleSortChapterChange = (chapter_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { chapter_sort_column: currentChapterSortColumn, chapter_sort_direction } = this.state;
        const newChapterSortDirection =
        currentChapterSortColumn === chapter_sort_column && chapter_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            chapter_sort_column,
            chapter_sort_direction: newChapterSortDirection,
            chapter_offset: 0,
            currentChapterPage: 0,
        },
        () => {
            this.fetchAllQuestionChapters();
        }
        );
    }

    getQuestionChapter(chapter_id) {
        let chaptername
        this.props.allQuestionChapters.map(chapter => {
            if (chapter.chapter_id === chapter_id) {
                return chaptername = chapter.chapter
            }
        })
        return chaptername
    }
    
    onEditChapter(e, el) {
        this.setState({
            chapterId: el.chapter_id,
            chapter: e.target.value
        })
    }
    
    async onUpdateChapter(e) {
        e.preventDefault()
        var obj = {
            chapter_id: this.state.chapterId,
            chapter: this.state.chapter
        }
        this.setState({ selectChapter: '' })
        if (!this.state.chapter.trim()) {
            window.Swal.fire({
                title: 'Failed to update chapter/module/unit!',
                text: 'Chapter/Module/Unit cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateChapter(obj);
            if (this.props.successchapterupdate) {
                // await this.props.getAllQuestionChapters(chapter_limit, chapter_offset, chapter_sort_column, chapter_sort_direction, chapter_search_term);
                this.fetchAllQuestionChapters()
                this.generateChapterOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated chapter/module/unit!',
                    icon: 'success',
                    customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
                })
                    .then(function () {
                        window.$('#manage-chapter').modal('hide')
                        window.$("#question-chapter").val('default');
                        window.$('#question-chapter').selectpicker('refresh');
                    });
            } 
            if(this.props.errorchapterupdate){
                window.Swal.fire({
                    title: 'Failed to update chapter/module/unit!',
                    text: this.props.errorchapterupdate,
                    icon: 'error',
                    customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
                })
                    .then(function () {
                        window.$('#manage-chapter').modal('hide')
                        window.$("#question-chapter").val('default');
                        window.$('#question-chapter').selectpicker('refresh');
                    });

            }
        }
    }

    generateChapterOptions() {
        let chapterOptionsImport = []
        this.props.allQuestionChapters.map((el, i) => {
            chapterOptionsImport.push(<Option key={el.chapter_id} optionName={el.chapter} value={el.chapter_id} />)
        });

        this.setState({
            chapterOptionsImport: chapterOptionsImport,
            selectedChapter: chapterOptionsImport[0].props.value
        });

        if (chapterOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditChapter(el, i, e) {
        e.preventDefault()
        this.setState({
            editChapter: true,
            selectChapter: i,
            chapterId: el.chapter_id,
            chapter: el.chapter
        })
    }

    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the chapter/moduel/unit?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteChapter(el);
                if (this.props.successchapterdelete) {
                    // await this.props.getAllQuestionChapters(chapter_limit, chapter_offset, chapter_sort_column, chapter_sort_direction, chapter_search_term);
                    this.fetchAllQuestionChapters()
                    this.generateChapterOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted chapter/module/unit',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-chapter').modal('hide')
                            window.$("#question-chapter").val('default');
                            window.$('#question-chapter').selectpicker('refresh');
                        });
                }
                if (this.props.errorchapterdelete) {
                    window.Swal.fire({
                        title: 'Failed to delete chapter/module/unit!',
                        text: this.props.errorchapterdelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-chapter').modal('hide')
                            window.$("#question-chapter").val('default');
                            window.$('#question-chapter').selectpicker('refresh');
                        });

                }
            }
        })
    }
    
    syncTableChapter() {
        return (
            <>
                {this.state.chapterData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChapterChange("chapter_id")}
                                >
                                    No{" "}
                                    {this.state.chapter_sort_column === "chapter_id" && (
                                    <span>{this.state.chapter_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortChapterChange("chapter")}
                                >
                                    Chapter/Module/Unit Name{" "}
                                    {this.state.chapter_sort_column === "chapter" && (
                                    <span>{this.state.chapter_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.chapterData.map((el, i) => {
                                if (i === this.state.selectChapter) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editChapter ? 
                                                this.getQuestionChapter(el.chapter_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.chapter}
                                                    onChange={(e) => this.onEditChapter(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateChapter(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Chapter" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getQuestionChapter(el.chapter_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditChapter(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Chapter" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete" 
                                                    title="Delete Chapter" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Chapters Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3>Manage Chapters/Modules/Units</h3>
              </div>

              <CardBody>
                <div className="row justify-content-end">
                  <div
                    className="table-wrapper-scroll-y my-custom-scrollbar"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <TabList>
                      <QuestionAccordion
                        labelName="Create New Chapter/Module/Unit"
                        onChange={this.onNewChapterInput}
                        onClick={this.onAddNewChapter}
                        val={this.state.newChapter}
                      />
                    </TabList>
                    <div className="row justify-content-end col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleChapterSearch}
                          className="col-md-18 col-18"
                        />
                      </Form.Group>
                    </div>
                    <TabContent>
                      <TabPane
                        className="tab-pane fade show active"
                        id="manage-1"
                        ariaLabelledby="manage-tab-1"
                      >
                        {/* begin: Datatable */}
                        {this.props.chapter_loading ? (
                          <div style={{ width: "75vw", overflowX: "scroll" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Chapter/Module/Unit Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                            <div className="loading-container mb-5 mt-10">
                              <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </ReactSpinner>
                            </div>
                          </div>
                        ) : this.state.chapterData.length > 0 ? (
                          this.syncTableChapter()
                        ) : (
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Chapter/Module/Unit Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {/* end: Datatable */}
                            </Table>
                            <div className="text-center mb-5 mt-10">
                              <label>No Chapters Available!</label>
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                            totalCount = {this.props.totalChaptersCount}
                            currentPage = {this.state.currentChapterPage}
                            limit = {this.state.chapterRowsPerPage}
                            handlePageChange = {this.handleChapterPageChange}
                            handleRowsPerPageChange = {this.handleChapterRowsPerPageChange}
                            handlePageOffsetChange = {this.handlePageOffsetChange}
                            offset = {this.state.chapter_offset}
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalChaptersCount: state.quesChapterReducer.totalChaptersCount,//
        chapter_loading:state.quesChapterReducer.loading,
        error: state.quesChapterReducer.error,//
        // totalCount: state.questionsLibraryReducer.totalCount,//
        
        errorchapterdelete: state.quesChapterReducer.errorchapterdelete,
        successchapterdelete: state.quesChapterReducer.successchapterdelete,

        errorchapterupdate: state.quesChapterReducer.errorchapterupdate,
        successchapterupdate: state.quesChapterReducer.successchapterupdate,//
        
        errorchaptercreate: state.quesChapterReducer.errorchaptercreate,
        successchaptercreate: state.quesChapterReducer.successchaptercreate,//

        allQuestionChapters: state.quesChapterReducer.allQuestionChapters,//
        loading: state.quesChapterReducer.loading,//
    }
}

    const mapActionsToProps = {
        createChapter: quesChapterActions.createChapter,
        getAllQuestionChapters: quesChapterActions.getAllQuestionChapters,
        updateChapter: quesChapterActions.updateChapter,
        deleteChapter: quesChapterActions.deleteChapter,
    }

    export default connect(mapStateToProps, mapActionsToProps)(QuestionChapter);

