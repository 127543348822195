import React from 'react';
import { Link } from "react-router-dom";

export default function CreateButton({ createButtonTitle, to, id, dataToggle, dataTarget }) {
    return (

        
        <Link to={to} className="btn w-auto btn-clean d-inline-flex align-items-center create-btn" id={id} data-toggle={dataToggle} data-target={dataTarget}>
            <div className="symbol symbol-30 symbol-circle symbol-primary">
                <span className="symbol-label font-size-h6 text-center">
                    <i className="flaticon2-plus text-white pr-0"></i>
                </span>
            </div>
            <span className="btn btn-link-primary font-weight-bolder font-size-base ml-4 font-size-h6">{createButtonTitle}</span>
        </Link>

    )
}