import {TermsAgreementConstants} from '../../helper/constants';

export function userAgreementReducer(state = {status:'notYetReview'}, action) {
    switch (action.type) {
        case TermsAgreementConstants.AGREEMENT_FAILURE:
            return {
                status: 'unaccepted',
            };
        case TermsAgreementConstants.AGREEMENT_SUCCESS:
            return {
                status: 'accepted',
            };
        case TermsAgreementConstants.AGREEMENT_REQUEST:
            return {
                status: 'underReview'
            };
        default:
            return state
    }
    
}