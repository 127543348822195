import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close'; // Import Close Icon
import IconButton from '@mui/material/IconButton'; // For clickable icon
import { StripeService } from "../../../../service/manage/subscription/stripe_pg.service.js";
import { customerConstants } from "../../../../helper/constants.js";
import { sweetStatusAlert } from "../../../../components/common/SweetAlert.js";
import { CombankService } from "../../../../service/manage/subscription/combank_pg.service.js";
import { TransactionService } from "../../../../service/manage/subscription/transaction.service.js";
import './PackageCard.css';
import SimpleReactValidator from 'simple-react-validator';
import { PromoService } from '../../../../service/settings/manage/promo_code.service.js';
import DiscountIcon from '@mui/icons-material/Discount';
import { Backdrop, Chip, CircularProgress, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import animationData from './asserts/celebration.json';

class BasicCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPromoInput: false, // State to manage input field visibility
      showPromoButton: true, // State to manage promo button visibility
      promo_code: '',
      is_promo_applied: false,
      updated_amount_with_promo_code: null,
      applied_promo_code_details: null,
      is_submitting_promo_code: false,
      promo_response: null,
      showAnimation: false,
      is_submit_credit: false,
      claimedFreeCredits: false,
    };
    this.createValidationsInstance();
  }

  componentDidMount() {

  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        greaterThanZero: {
          message: 'The :attribute must be greater than 0.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^\d*\.?\d+$/) && parseFloat(val) > 0;
          },
          required: true,
        },
      },
    });
    this.updateValidator = new SimpleReactValidator();
  }

  componentWillUnmount() {
    // Cleanup: Remove the script when the component is unmounted
    if (this.props.customer !== customerConstants.EXO_CUSTOMER2) {
      const script = document.querySelector("script[src*='checkout.min.js']");
      if (script) {
        document.head.removeChild(script);
      }
    }
  }

  handlePromoClick = () => {
    this.validator.hideMessages();
    this.validator.purgeFields();
    this.setState({ promo_code: '', showPromoInput: true, showPromoButton: false, is_promo_applied: false, updated_amount_with_promo_code: null, is_submitting_promo_code: false });
  };

  handleCloseClick = () => {
    this.setState({ promo_code: '', showPromoInput: false, showPromoButton: true, is_promo_applied: false, updated_amount_with_promo_code: null, is_submitting_promo_code: false });
    this.validator.hideMessages();
    this.validator.purgeFields();
  };

  getSessionID = async (payment_obj) => {
    try {
      const res = await CombankService.getSessionID(JSON.stringify(payment_obj));
      sessionStorage.setItem("checkout_session_id", res.data.session.id);
      this.setState({ is_submit_credit: false }, () => {
        this.loadCheckoutScript(res.data.session.id);
      });
    } catch (error) {
      sweetStatusAlert("Error!", "An error occurred while getting session ID.", "error");
      this.setState({ is_submit_credit: false })
    }
  };

  errorCallback = (error) => {
    this.setState({ is_submit_credit: false });
    if (this.props.customer !== customerConstants.EXO_CUSTOMER2) {
      const script = document.querySelector("script[src*='checkout.min.js']");
      if (script) {
        document.head.removeChild(script);
      }
    }
    sweetStatusAlert("Failed!", JSON.stringify(error), "error");
  };

  cancelCallback = () => {
      this.setState({ is_submit_credit: false });
      if (this.props.customer !== customerConstants.EXO_CUSTOMER2) {
        const script = document.querySelector("script[src*='checkout.min.js']");
        if (script) {
          document.head.removeChild(script);
        }
      }
      sweetStatusAlert("Failed!", "Payment cancelled!", "info");
    };

  successCallback = () => {
      this.setState({ is_submit_credit: false });
      if (this.props.customer !== customerConstants.EXO_CUSTOMER2) {
        const script = document.querySelector("script[src*='checkout.min.js']");
        if (script) {
          document.head.removeChild(script);
        }
      }
      sweetStatusAlert("Success!", "Payment successful!", "success");
    };

  loadCheckoutScript = (sessionID) => {
    const script = document.createElement("script");
    script.src = "https://cbcmpgs.gateway.mastercard.com/static/checkout/checkout.min.js";
    document.head.appendChild(script);
    script.onload = () => {
      window.Checkout.configure({
        session: {
          id: sessionID,
        },
      });

      window.Checkout.onComplete = this.successCallback;
      window.Checkout.onError = this.errorCallback;
      window.Checkout.onCancel = this.cancelCallback;

      if (window.Checkout) {
        if (this.props.customer !== customerConstants.EXO_CUSTOMER2) {
          const script = document.querySelector("script[src*='checkout.min.js']");
          if (script) {
            document.head.removeChild(script);
          }
        }
        window.Checkout.showPaymentPage();
      }
    };
  };

  handlePaymentPageClick = async (payment_obj) => {
    await this.getSessionID(payment_obj);
  };

  manualPayment = async (payment_obj) => {
    TransactionService.manualPayment(JSON.stringify(payment_obj))
      .then((response) => {
        if (response.success) {
          this.setState({ claimedFreeCredits: true })
          sweetStatusAlert("Success!", "Free credits added successfully", "success");
          this.setState({ is_submit_credit: false })
        } else {
          this.setState({ claimedFreeCredits: false })
          sweetStatusAlert("Failed!", "Failed to add free credits", "error");
          this.setState({ is_submit_credit: false })
        }
      })
  };

  calculateRemainingTime = (level_end_date) => {
    const currentDate = new Date();
    const endDate = new Date(level_end_date);
    const timeDiff = endDate - currentDate;

    if (timeDiff <= 0) {
      return "Expired";
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    return `${years} years, ${months % 12} months, and ${days % 30} days`;
  };

  submitCredit = async () => {
    this.setState({ is_submit_credit: true })
    const { pkg, selectedStudent, studentlist, customer } = this.props;
    const mid = 100;
    const session_id = sessionStorage.getItem("manage_user_id");
    const table_log_id = sessionStorage.getItem("table_log_id");

    let student = studentlist.find((student) => student.user_id === selectedStudent);

    const startDate = new Date(pkg.level_start_date.split("/").reverse().join("-"));
    const endDate = new Date(pkg.level_end_date.split("/").reverse().join("-"));
    const timeDifference = endDate.getTime() - startDate.getTime();
    const expirationDate = new Date(startDate.getTime() + timeDifference);

    let paid_by = sessionStorage.getItem("username");
    let userType = sessionStorage.getItem("userType");

    const { is_promo_applied, updated_amount_with_promo_code, applied_promo_code_details } = this.state;

    const payment_obj = {
      email: sessionStorage.getItem("email"),
      unit_amount: pkg.total_credit_calculated_withDiscount * 100,
      amount: is_promo_applied ? updated_amount_with_promo_code : pkg.total_credit_calculated_withDiscount,
      credits: parseInt(pkg.type_credits),
      currency_type: pkg.level_currency_type,
      type_name: pkg.type_name,
      node_mid: mid,
      type_id: parseInt(pkg.type_id),
      level_price_id: parseInt(pkg.level_price_id),
      expiration_date: expirationDate,
      total_credits: parseInt(pkg.type_credits),
      available_credits: parseInt(pkg.type_credits),
      used_credits: 0,
      user_id: userType === "student" ? session_id : selectedStudent,
      user_table_id: userType === "student" ? table_log_id : student.user_table_id,
      create_user_id: session_id,
      create_user_table_id: table_log_id,
      paid_by: paid_by,
      payment_method: pkg.isClaim ? "Free Credits" : "Online",
      reference_id: "N/A",
      form_list_id: 29,
      is_promo_applied: is_promo_applied,
      usage_promo_id: is_promo_applied ? applied_promo_code_details?.id : null,
      promo_code: is_promo_applied ? applied_promo_code_details?.promo_code : null,


    };

    if (customer === customerConstants.EXO_CUSTOMER2) {
      if (pkg.isClaim && !pkg.isDisable) {
        const paymentForManual = { ...payment_obj };
        delete paymentForManual.is_promo_applied;
        delete paymentForManual.usage_promo_id;
        delete paymentForManual.promo_code;
        await this.manualPayment(paymentForManual);
      } else {
        StripeService.create_checkout_session(JSON.stringify(payment_obj)).then((res) => {
          if (res.success) {
            window.location.replace(res.data);
            this.setState({ is_submit_credit: false })
          } else {
            sweetStatusAlert("Failed!", res.message, "error");
            this.setState({ is_submit_credit: false })
          }
        });
      }
    } else {
      if (pkg.isClaim && !pkg.isDisable) {
        const paymentForManual = { ...payment_obj };
        delete paymentForManual.is_promo_applied;
        delete paymentForManual.usage_promo_id;
        delete paymentForManual.promo_code;
        await this.manualPayment(paymentForManual);
      } else {
        await this.handlePaymentPageClick(payment_obj);
      }
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'promo_code') {
      this.setState(() => ({ [name]: value.toUpperCase(), isModified: true }));
    }
    else {
      this.setState(() => ({ [name]: value, isModified: true }));
    }
  };

  validate_promo_code = async () => {

    const { promo_code } = this.state
    const { pkg, selectedStudent, studentlist, customer } = this.props;

    let userType = sessionStorage.getItem("userType");

    let student;

    if (selectedStudent) {
      student = studentlist?.find((student) => student.user_id === selectedStudent);
    }


    const user_id = userType === "student" ? sessionStorage.getItem("manage_user_id") : selectedStudent;
    const user_table_id = userType === "student" ? sessionStorage.getItem("table_log_id") : student?.user_table_id;

    const current_amount = {
      current_amount: pkg?.total_credit_calculated_withDiscount
    }

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    }

    else {

      this.setState({ is_submitting_promo_code: true })

      try {

        await PromoService.validatePromoCode(promo_code, user_id, user_table_id, current_amount)
          .then((response) => {

            if (response?.data.code === 1 && response?.data?.is_promo_code_valid) {
              
              if(response?.data.discount_amount >= current_amount?.current_amount){
                sweetStatusAlert(
                  "Cannot Apply Promo Code",
                  `Discount value is greater than package price`,
                  "info"
                )
                this.setState({ applied_promo_code_details: null, showPromoInput: false, is_promo_applied: false, updated_amount_with_promo_code: null, showPromoButton: true, is_submitting_promo_code: false });
              
              }
              else{
                this.promoAddedTheme()
                this.setState({
                  applied_promo_code_details: response.data.promo_code_details,
                  showPromoInput: false,
                  showPromoButton: false,
                  is_promo_applied: true,
                  updated_amount_with_promo_code: response.data.total_credit_calculated_with_discount_and_promo,
                  is_submitting_promo_code: false,
                  promo_response: response.data
                })
                this.validator.hideMessages();
                this.validator.purgeFields();
              }
              
            }
            else {
              sweetStatusAlert(
                "Oops...",
                `${typeof response?.data?.message === "string" ? response.data.message : 'Failed to apply promo code'}`,
                "info"
              )
              this.setState({ applied_promo_code_details: null, showPromoInput: false, is_promo_applied: false, updated_amount_with_promo_code: null, showPromoButton: true, is_submitting_promo_code: false });
            }

          })

      } catch (error) {
        sweetStatusAlert("Error", 'Failed to apply promo code', 'error')
        this.setState({ applied_promo_code_details: null, showPromoInput: false, is_promo_applied: false, updated_amount_with_promo_code: null, showPromoButton: true, is_submitting_promo_code: false });
        this.validator.hideMessages();
        this.validator.purgeFields();
      }
    }

  }

  remove_promo_code = async () => {

    this.setState({ is_promo_applied: false, showPromoButton: true }
      , () => {
        this.setState({
          applied_promo_code_details: null,
          showPromoInput: false,
          updated_amount_with_promo_code: null,
          promo_response: null
        })
      }
    )

  }

  promoAddedTheme = () => {

    this.setState({ showAnimation: true })

    setTimeout(() => {
      this.setState({ showAnimation: false })
    }, 2000);
  };

  render() {
    const { pkg, customer } = this.props;
    const {
      promo_response,
      showPromoInput,
      showPromoButton,
      is_promo_applied,
      promo_code,
      is_submitting_promo_code,
      applied_promo_code_details,
      updated_amount_with_promo_code,
      showAnimation,
      claimedFreeCredits,
      is_submit_credit
    } = this.state;

    return (
      <div className={(pkg.isClaim && pkg.isDisable) || claimedFreeCredits ? "disabledPackageCard" : "packageCard"}>
        {showAnimation && (
          <div className="background-animation">
            <Lottie animationData={animationData} loop={true} /> {/* Play Lottie animation */}
          </div>
        )}
        {is_submit_credit && (
           <Backdrop
           sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
           open={is_submit_credit}
          >
           <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <CardContent>
          <div className={(pkg.isClaim && pkg.isDisable) || claimedFreeCredits ? "disabledPackTopic" : "packTopic"}>
            <div className="packName">
              {pkg.level_definition} {pkg.type_name} package
            </div>
            {parseInt(pkg.type_discount_rate) > 0 ? (
              <div className="packDis ">Package Discount {parseInt(pkg.type_discount_rate)}%</div>
            ) : (
              <div className="packDis ">&emsp;</div>
            )
            }
          </div>
          <hr className="packHr1" />
          <div className="packCredit">
            {pkg.type_credits} {pkg.type_credits > 1 ? 'Credits' : 'Credit'}
          </div>

          {/* Promo Code Button */}
          {!pkg.isClaim && (
            <>
              {showPromoButton && (
                <div
                  variant="contained"
                  className="packPromo"
                  onClick={this.handlePromoClick}
                  style={{ cursor: "pointer" }}
                >
                  Add Promo Code
                </div>
              )}

              {/* Input field and close button */}
              {showPromoInput && (
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "2px", marginTop: "10px" }}>
                  <IconButton
                    disabled={is_submitting_promo_code}
                    onClick={this.handleCloseClick}
                    sx={{ padding: "0", alignSelf: "flex-end" }}
                    className="closeIcon"
                  >
                    <CloseIcon sx={{ color: "black", fontSize: "20px" }} />
                  </IconButton>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <TextField
                      name='promo_code'
                      variant="outlined"
                      size="small"
                      placeholder="Enter promo code"
                      className="promoInput"
                      onChange={this.handleChange}
                      value={promo_code}
                    />
                    {is_submitting_promo_code ? (
                      <CircularProgress size={24} /> // Show loader when the row is loading
                    ) : (
                      <Button
                        disabled={is_submitting_promo_code}
                        variant="contained"
                        className="applyBtn"
                        onClick={this.validate_promo_code}
                      >
                        Apply
                      </Button>
                    )}
                  </Box>
                </Box>
              )}
              {this.validator.message(
                "promo_code",
                this.state.promo_code,
                "required",
                {
                  className: "text-danger",
                  messages: {
                    required: "The promo code required",
                  },
                }
              )}

              {is_promo_applied && !showPromoInput && !showPromoButton &&
                <Box sx={{ display: "flex", alignItems: "center", gap: "8px", marginTop: "10px" }}>
                  <Chip
                    color="secondary"
                    icon={<DiscountIcon />}
                    label={
                      <Box component="span" sx={{ fontSize: '0.875rem' }}>
                        <Typography component="span" sx={{ fontSize: '0.875rem' }}>CODE : </Typography>
                        <Typography component="span" fontWeight="bold" sx={{ fontSize: '0.875rem' }}>
                          {applied_promo_code_details?.promo_code}
                        </Typography>
                        <Typography component="span" sx={{ fontSize: '0.875rem' }}> | DISCOUNT : </Typography>
                        <Typography component="span" fontWeight="bold" sx={{ fontSize: '0.875rem' }}>
                          {applied_promo_code_details?.promo_value}
                        </Typography>
                        <Typography component="span" sx={{ fontSize: '0.875rem' }}>
                          {applied_promo_code_details?.promo_type === 'percentage' ? '%' : ' LKR'}
                        </Typography>
                      </Box>
                    }
                    onDelete={this.remove_promo_code} />
                </Box>
              }

              <hr className="packHr2" />
              <div className="packPrice">
                {`Credit fee : ${pkg.credit_amount_calculated} ${pkg.level_currency_type}`}
              </div>
            </>
          )}
          {parseInt(pkg.type_discount_rate) > 0 ?
            <>
              {is_promo_applied && updated_amount_with_promo_code ?
                <>
                  <div className="packPrice">
                    {`Package discount    : ${pkg.savings_calculated} ${pkg.level_currency_type}`}
                  </div>
                  <div className="packPrice">
                    {`Promo code discount : ${promo_response?.discount_amount} ${pkg.level_currency_type}`}
                  </div>
                  <div className="packPrice">
                    {`Your savings : ${pkg?.savings_calculated + promo_response?.discount_amount} ${pkg.level_currency_type}`}
                  </div>
                </>
                :
                <>
                <div className="packPrice">
                  {`Package discount    : ${pkg.savings_calculated} ${pkg.level_currency_type}`}
                  &nbsp;
                </div>
                <div className="packPrice">
                  &nbsp;
                </div>
                <div className="packPrice">
                  {`Your savings : ${pkg.savings_calculated} ${pkg.level_currency_type} `}
                </div>
                </>
              }

              <div className="packPrice">
                {is_promo_applied && updated_amount_with_promo_code ?

                  `Total : ${updated_amount_with_promo_code} ${pkg.level_currency_type}`
                  :
                  `Total : ${pkg?.total_credit_calculated_withDiscount} ${pkg.level_currency_type}`
                }

              </div>
            </>
            :
            (is_promo_applied && updated_amount_with_promo_code ?
              <>
                <div className="packPrice">
                    &nbsp;
                </div>
                <div className="packPrice">
                  Promo code discount : {promo_response?.discount_amount} {pkg.level_currency_type}
                </div>
                <div className="packPrice">
                  Your savings : {promo_response?.discount_amount} {pkg.level_currency_type}
                </div>
                <div className="packPrice">
                  Total : {promo_response?.discount_amount} {pkg.level_currency_type}
                </div>
              </>
              :
              <>
              <div className="packPrice">
                    &nbsp;
                </div>
                <div className="packPrice">
                    &nbsp;
                </div>
                <div className="packPrice">
                    &nbsp;
                </div>
                <div className="packPrice">
                  {pkg.isClaim?
                    'As a special offer, you can claim a free credit package to use in your     future transactions. Add it to your account today and enjoy the benefits!'
                    :
                    `Total : ${pkg?.total_credit_calculated_withDiscount} ${pkg.level_currency_type} `
                  }
                </div>
              </>
            )
          }

          {/* <hr className="packHr2" /> */}
          {/* {is_submit_credit ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '16px' }}>
              <CircularProgress size={24} />
            </div>
          ) : ( */}
            <Button
              variant="contained"
              onClick={this.submitCredit}
              disabled={(is_promo_applied && updated_amount_with_promo_code < 1) || (!pkg.isClaim && pkg.total_credit_calculated_withDiscount < 1 )|| pkg.isDisable || showPromoInput}
              className={(pkg.isClaim && pkg.isDisable) || claimedFreeCredits ? "packbtnDisabled" : "packbtn"}
              fullWidth
            >
              {pkg.isClaim && !pkg.isDisable
                ? "Claim"
                : (pkg.isClaim && pkg.isDisable || claimedFreeCredits)
                  ? "Already claimed"
                  : `Proceed to Pay - ${is_promo_applied ? updated_amount_with_promo_code : pkg.total_credit_calculated_withDiscount} ${pkg.level_currency_type}`}
            </Button>
          {/* )} */}

          <div className="packDetails2">
            <div>Valid for: {this.calculateRemainingTime(pkg.level_end_date)}</div>
          </div>
        </CardContent>
      </div>
    );
  }
}

export default BasicCard;
