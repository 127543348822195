import {assessCatTypes} from '../../../reducers/settings/settingsTypes'
import {assessCategoryService} from '../../../service/settings/assessment/categoryServices'


export const assessCatActions = {
    getAllAssessmentCategories,
    createCategory,
    updateCategory,
    deleteCategory
}

function getAllAssessmentCategories(limit, offset, sort_column, sort_direction, search_term)  {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());

            let categories = await assessCategoryService.getAllAssessmentCategories(limit, offset, sort_column, sort_direction, search_term) ;
            dispatch(success(categories.data))
        }catch (error) {
            dispatch(failure(error.toString()))
        }
    };
    function clearExisting() {
        return {type: assessCatTypes.GET_ALL_ASSESSMENT_CATEGORIES}
    }

    function request() {
        return {type: assessCatTypes.GET_ALL_ASSESSMENT_CATEGORIES_REQUEST}
    }

    function success(categories) {
        return {type: assessCatTypes.GET_ALL_ASSESSMENT_CATEGORIES_SUCCESS, categories}
    }

    function failure(error) {
        return {type: assessCatTypes.GET_ALL_ASSESSMENT_CATEGORIES_FAILURE, error}
    }
}


function createCategory(obj) {
    return async dispatch => {
        try {
            dispatch(request(obj));
            let res = await assessCategoryService.createCategory(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function request(data) {
        return {type: assessCatTypes.CREATE_CATEGORY_REQUEST, data}
    }

    function success(categories) {
        return {type: assessCatTypes.CREATE_CATEGORY_SUCCESS, categories}
    }

    function failure(error) {
        return {type: assessCatTypes.CREATE_CATEGORY_FAILURE, error}
    }
}


function deleteCategory(obj) {

    return async dispatch => {
        dispatch(request(obj));
        try {
            let res = await assessCategoryService.deleteCategory(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request(category) {
        return {type: assessCatTypes.DELETE_CATEGORY_REQUEST, category}
    }

    function success(success) {
        return {type: assessCatTypes.DELETE_CATEGORY_SUCCESS, success}
    }

    function failure(error) {
        return {type: assessCatTypes.DELETE_CATEGORY_FAILURE, error}
    }
}

function updateCategory(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let res = await assessCategoryService.updateCategory(obj);
            if (res.success) {
                dispatch(success(res.data));
            } else {
                dispatch(failure(res.message));  
            }
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return {type: assessCatTypes.UPDATE_CATEGORY_REQUEST}
    }

    function success(category) {
        return {type: assessCatTypes.UPDATE_CATEGORY_SUCCESS, category}
    }

    function failure(error) {
        return {type: assessCatTypes.UPDATE_CATEGORY_FAILURE, error}
    }
}
