import {mcqTypes} from '../../reducers/types';
import {mcqService} from '../../service/question/mcqService';

export const mcqActions = {
    refreshPage,
    createQuestion
};

function createQuestion(obj) {
    
    return async dispatch => {

        dispatch(clearExisting());
        dispatch(request());

        try {
            let data = await mcqService.saveQuestion(obj);
            dispatch(success(data.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return {type: mcqTypes.CREATE_MCQ_QUESTION}
    }

    function request() {
        return {type: mcqTypes.CREATE_MCQ_QUESTION_REQUEST}
    }

    function success(question) {
        return {type: mcqTypes.CREATE_MCQ_QUESTION_SUCCESS, question}
    }

    function failure(error) {
        return {type: mcqTypes.CREATE_MCQ_QUESTION_FAILURE, error}
    }
}

function refreshPage() {
    return dispatch => {
        dispatch(clearExisting());
    };

    function clearExisting() {
        return {type: mcqTypes.MCQ_PAGE_REFRESH}
    }
}