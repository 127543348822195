const labels = {

    //Home
    home: "Home",

    //Dashboard
    dashboard: "Dashboard",

    //Site Administration
    site_administration: "Site Administration",

    //Log out
    log_out: "Log out",

    // Pending payments
    pending_payments: "Pending Payments",

    // Pending registrations
    pending_registrations:"Pending Registrations",

    //User
    user: "User",
    users: "Users",
    user_search: "Search Users",
    user_view: "View User",
    user_add: "Add User",
    user_edit: "Edit User",
    user_delete: "Delete User",

    //Role
    role: "Role",
    roles: "Roles",
    role_code: "Role Code",
    role_name: "Role Name",
    role_desc: "Role Desc",
    role_search: "Search Role",
    role_view: "View Role",
    role_add: "Add Role",
    role_edit: "Edit Role",
    role_delete: "Delete Role",

    //Privilege
    privilege: 'Privileges',
    privilege_search: 'Search Privileges',
    privilege_view: 'View Privilege',
    privilege_code: 'Privilege Code',
    privilege_name: 'Privilege Name',
    privilege_desc: 'Privilege Description',

    //User Roles
    email: "Email",
    module: "Module",
    status: "Status",
    user_roles: "User Roles",
    user_roles_search: "Search User Roles",
    user_roles_view: "View User Roles",
    user_roles_add: "Add User Roles",
    user_roles_edit: "Edit User Roles",
    user_roles_delete: "Delete User Roles",

    //Role Privileges
    role_privileges: "Role Privileges",
    role_privileges_id: "Role Privilege Id",
    role_privileges_search: "Search Role Privileges",
    role_privileges_view: "View Role Privileges",
    role_privileges_add: "Add Role Privileges",
    role_privileges_edit: "Edit Role Privileges",
    role_privileges_delete: "Delete Role Privileges",

    //System Settings
    system_settings: 'System Settings',
    settings_timezone: 'Timezone Settings',
}

const messages = {
    //Messages
    invalid_credentials: "User email/password is incorrect",
    user_exists_with_email: "User already exists with email",
    bad_request: "One or more of the required fields are empty",
    unauthorized_action: "Unauthorized action",
    unauthorized_application: "Unauthorized application",
    undefined_error: "Some error occurred. Please contact System Administrator.",
    register_successful: "Register successful",
    create_successful: "Create successful",
    invalid_email: "Invalid email",
    invalid_input: "Invalid input",
    user_id_required :'User is Required',
    module_required  : 'Module is Required',
    role_is_Required  : 'Role is Required',
    user_create_successful: "User added successfully",
    role_create_successful: "Role added successfully",
    user_role_create_successful: " User Role added successfully",
    role_privilege_create_successful: "Role Privilege added successfully",
    update_successful: "Update successful",
    user_update_successful: "User updated successfully",
    role_update_successful: "Role updated successfully",
    user_role_update_successful: "User Role updated successfully",
    role_privilege_update_successful: "Role Privilege updated successfully",
    time_zone_update_successful: "Timezone updated Successfully",
    delete_successful: "Delete successful",
    user_delete_successful: "User deleted successfully",
    role_delete_successful: "Role deleted successfully",
    user_role_delete_successful: "User Role deleted successfully",
    role_privilege_delete_successful: "Role Privilege deleted successfully",
    user_not_found: "User not found",
    role_exists_with_name: "Role already exists",
    role_not_found: 'Role not found',
    role_privilege_exists: 'Role privilege exists',
    role_privilege_not_found: 'Role privilege not found',
    user_role_exists: "User Role already exists",
    user_role_not_found: 'User Role not found',
    login_failed: 'Login failed',
    logout_failed: 'Logout failed',
}

module.exports = {labels, messages}
