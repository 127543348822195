import React, { Component } from "react"
import getstarted from "../assets/images/getstarted.svg"
import phone from "../assets/images/phone.png"
import email from "../assets/images/email.png"

class Hero extends Component {
  render() {
    return (
      <>
        <div id="getstarted" className="container">
          <div className="pt-5 pb-5">
            <h1 className="edsider-section-title">Get Started</h1>
          </div>
          <div className="row">
            <div className="col-md-6 hero-container">
              {/* <h1 className="get-started-title">Grade 5 Scholarship</h1> */}
              <p className="paragraph pb-5">
                Fun, Simple, and Easy educational materials you can do in the
                comfort of your home
              </p>
              <p className="paragraph">
                For any inquiries or support, please reach out to us:
              </p>
              <div className="row mb-3">
                <div className="row d-flex align-items-center contact-icons">
                  <img src={email} alt="email-icon" />
                  <p className="contact-text">info@edsider.com</p>
                </div>
              </div>
              {/* <div className="row">
                <div className="row d-flex align-items-center contact-icons">
                  <img src={phone} alt="phone-icon" />
                  <p className="contact-text">+94 XX XXX XXXX</p>
                </div>
              </div> */}
            </div>

            <div className="col-md-6 hero-container">
              <div className="image-container">
                <img src={getstarted} alt="Image" className="centered-image" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Hero
