import { manageUserService } from '../../service/user_management/manage.user.service';
import {userTypes} from '../../reducers/types';


export const manageUserActions = {
    getTeacherMe,
    getTeacherDetails,
    getTeacherDetailsForStudent,
    getStudentDetails,
    getAllTeachers,
    getAllStudents
};

function getTeacherMe() {
    return async dispatch => {
        try {
            dispatch(get_teacher_me());
            dispatch(get_teacher_me_request());
            let teacher = await manageUserService.getTeacherMe();
            dispatch(get_teacher_me_success(teacher.data));
        } catch (error) {
            dispatch(get_teacher_me_failure(error));
        }
    };

    function get_teacher_me() {
        return {type: userTypes.GET_TEACHER_ME}
    }

    function get_teacher_me_request() {
        return {type: userTypes.GET_TEACHER_ME_REQUEST}
    }

    function get_teacher_me_success(data) {
        return {type: userTypes.GET_TEACHER_ME_SUCCESS, teacher: data}
    }

    function get_teacher_me_failure(error) {
        return {type: userTypes.GET_TEACHER_ME_FAILURE, error:error}
    }
}

function getTeacherDetails(teacher_id) {
    return async dispatch => {
        try {
            dispatch(get_teacher_details());
            dispatch(get_teacher_details_request());
            let teacherDetails = await manageUserService.getTeacherDetails(teacher_id);
            dispatch(get_teacher_details_success(teacherDetails.data));
        } catch (error) {
            dispatch(get_teacher_details_failure(error));
        }
    };

    function get_teacher_details() {
        return {type: userTypes.GET_TEACHER_DETAILS}
    }

    function get_teacher_details_request() {
        return {type: userTypes.GET_TEACHER_DETAILS_REQUEST}
    }

    function get_teacher_details_success(data) {
        return {type: userTypes.GET_TEACHER_DETAILS_SUCCESS, teacherDetails: data}
    }

    function get_teacher_details_failure(error) {
        return {type: userTypes.GET_TEACHER_DETAILS_FAILURE, error:error}
    }
}

function getTeacherDetailsForStudent(teacher_id) {
    return async dispatch => {
        try {
            dispatch(get_teacher_details_for_student());
            dispatch(get_teacher_details_for_student_request());
            let teacherDetailsForStudent = await manageUserService.getTeacherDetailsForStudent(teacher_id);
            dispatch(get_teacher_details_for_student_success(teacherDetailsForStudent.data));
        } catch (error) {
            dispatch(get_teacher_details_for_student_failure(error));
        }
    };

    function get_teacher_details_for_student() {
        return {type: userTypes.GET_TEACHER_DETAILS_FOR_STUDENT}
    }

    function get_teacher_details_for_student_request() {
        return {type: userTypes.GET_TEACHER_DETAILS_FOR_STUDENT_REQUEST}
    }

    function get_teacher_details_for_student_success(data) {
        return {type: userTypes.GET_TEACHER_DETAILS_FOR_STUDENT_SUCCESS, teacherDetailsForStudent: data}
    }

    function get_teacher_details_for_student_failure(error) {
        return {type: userTypes.GET_TEACHER_DETAILS_FOR_STUDENT_FAILURE, error:error}
    }
}

function getAllTeachers() {
    return async dispatch => {
        try {
            dispatch(get_all_teachers());
            dispatch(get_all_teachers_request());
            let allTeachers = await manageUserService.getAllTeachers();
            dispatch(get_all_teachers_success(allTeachers.data));
        } catch (error) {
            dispatch(get_all_teachers_failure(error));
        }
    };

    function get_all_teachers() {
        return {type: userTypes.GET_ALL_TEACHERS}
    }

    function get_all_teachers_request() {
        return {type: userTypes.GET_ALL_TEACHERS_REQUEST}
    }

    function get_all_teachers_success(data) {
        return {type: userTypes.GET_ALL_TEACHERS_SUCCESS, allTeachers: data}
    }

    function get_all_teachers_failure(error) {
        return {type: userTypes.GET_ALL_TEACHERS_FAILURE, error:error}
    }
}

function getAllStudents() {
    return async dispatch => {
        try {
            dispatch(get_all_students());
            dispatch(get_all_students_request());
            let allStudents = await manageUserService.getAllStudents();
            dispatch(get_all_students_success(allStudents.data));
        } catch (error) {
            dispatch(get_all_students_failure(error));
        }
    };

    function get_all_students() {
        return {type: userTypes.GET_ALL_STUDENTS}
    }

    function get_all_students_request() {
        return {type: userTypes.GET_ALL_STUDENTS_REQUEST}
    }

    function get_all_students_success(data) {
        return {type: userTypes.GET_ALL_STUDENTS_SUCCESS, allStudents: data}
    }

    function get_all_students_failure(error) {
        return {type: userTypes.GET_ALL_STUDENTS_FAILURE, error:error}
    }
}

function getStudentDetails(student_id) {
    return async dispatch => {
        try {
            dispatch(get_student_details());
            dispatch(get_student_details_request());
            let studentDetails = await manageUserService.getStudentDetails(student_id);
            dispatch(get_student_details_success(studentDetails.data));
        } catch (error) {
            dispatch(get_student_details_failure(error));
        }
    };

    function get_student_details() {
        return {type: userTypes.GET_STUDENT_DETAILS}
    }

    function get_student_details_request() {
        return {type: userTypes.GET_STUDENT_DETAILS_REQUEST}
    }

    function get_student_details_success(data) {
        return {type: userTypes.GET_STUDENT_DETAILS_SUCCESS, studentDetails: data}
    }

    function get_student_details_failure(error) {
        return {type: userTypes.GET_STUDENT_DETAILS_FAILURE, error:error}
    }
}
