import { assessmentTypes } from '../types';

const initialState = {
    text: '',
    loading: false,
    error: '',
    success: '',
    assessment: '',
    assessors: '',
    schedule: '',
    candidates: ''
}

export function editDetailsReducer(state = initialState, action) {
    switch (action.type) {

        case assessmentTypes.EDIT_DETAILS:
            return {
                ...state,
                text: 'Edit Details!',
                loading: true
            }
        case assessmentTypes.EDIT_DETAILS_REQUEST:
            return {
                ...state,
                text: action.content,
                loading: true
            }
        case assessmentTypes.EDIT_DETAILS_SUCCESS:
            return {
                ...state,
                text: action.assessment,
                loading: false,
                success: 'Assessment Edited Successfully!',
                assessment: action.assessment
            }
        case assessmentTypes.EDIT_DETAILS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}

export function editScheduleReducer(state = initialState, action) {

    switch (action.type) {

        case assessmentTypes.EDIT_SCHEDULE:
            return {
                ...state,
                assessment: action.id,
                text: 'Edit schedule!',
                loading: true
            }
        case assessmentTypes.EDIT_SCHEDULE_REQUEST:
            return {
                ...state,
                text: action.id,
                loading: true
            }
        case assessmentTypes.EDIT_SCHEDULE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Schedule is edited Successfully!',
                schedule: action.schedule
            }
        case assessmentTypes.EDIT_SCHEDULE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}

export function editAssessorsReducer(state = initialState, action) {

    switch (action.type) {

        case assessmentTypes.EDIT_ASSESSORS_ASSESSMENT:
            return {
                ...state,
                text: 'Editing Assessors for Assessment!',
                invalid: [],
                assessment: action.assessment,
                loading: false
            }
        case assessmentTypes.EDIT_ASSESSORS_ASSESSMENT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessmentTypes.EDIT_ASSESSORS_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                text: 'Assessors edited successfully!',
                success: action.message
            }
        case assessmentTypes.EDIT_ASSESSORS_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                success: '',
                assessors: ''
            }
        default:
            return state
    }
}

export function editCandidatesReducer(state = initialState, action) {
    switch (action.type) {

        case assessmentTypes.EDIT_CANDIDATES_ASSESSMENT:
            return {
                ...state,
                text: 'Adding candidates!',
                invalid: [],
                assessment: action.assessment,
                loading: false
            }
        case assessmentTypes.EDIT_CANDIDATES_ASSESSMENT_REQUEST:
            return {
                ...state,
                invalid: action.invalid_emails,
                loading: true
            }
        case assessmentTypes.EDIT_CANDIDATES_ASSESSMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                text: 'Candidates are added Successfully!',
                success: action.message
            }
        case assessmentTypes.EDIT_CANDIDATES_ASSESSMENT_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                success: '',
                candidates: ''
            }
        default:
            return state
    }
}
