import React from "react";

export default function AttachmentButton ({ dataIndex, type, btnName, className, dataDismiss, onClick, btnIconStyle, dataToggle, dataTarget, inlineStyle, disabled, id ,title}) {

    return (
        <button 
            dataIndex={dataIndex}
            type={type} 
            className={className} 
            data-dismiss={dataDismiss} 
            onClick={onClick} 
            data-toggle={dataToggle} 
            data-target={dataTarget} 
            style={inlineStyle} 
            disabled={disabled} 
            id={id} 
            title={title}>
            <i className={btnIconStyle}></i>
            {btnName}
        </button>
    )
}
