import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";
import { UserManagementService } from "../../user_management/user_management.service";

export const NodeFormMapService = {
  insertFormAttachment,
  deleteFormAttachment,
  updateFormAttachment,
  
  getFormAttachmentList,
  getDropdownFormList,
  getFormDisplayTitle,
  getMembersListOfForm,

  attachDropdownForm,
  updateDropdownForm,
  removeDropdownForm,

  getPrivPerNodeForm,
  getPrivPerNode,
  getDisplayPrios,
  getFormPrivRole,
  getNodePrivRole,
  getRecRestricts,
  getPrivStatus,

  addUpdatePrivPerNodeForm,
  deletePrivPerNodeForm,
  addUpdatePrivPerNode,
  deletePrivPerNode,
  getNodeMidsWithDisplayTitles,

  getFormLinkAttachmentList,
  addLinkFormAttachment
};

async function getNodeMidsWithDisplayTitles(form_list_id, user_access_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/get-node-mids-with-display-title/${form_list_id}/${user_access_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getFormDisplayTitle(node_mid, form_list_id, user_access_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/get-form-display-title/${node_mid}/${form_list_id}/${user_access_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getFormAttachmentList(node_id, user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/get-form-attachment-lists/${node_id}/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
async function getFormLinkAttachmentList(node_id, user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/get-link-form-attachment-lists/${node_id}/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function addLinkFormAttachment(form) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(form),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/link-node`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}
async function getDropdownFormList(node_id, user_id, user_table_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/get-dropdown-form-lists/${node_id}/${user_id}/${user_table_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getMembersListOfForm(node_mid, form_list_id, lg_user_id, lg_user_table_id,limit,
  offset,
  sortColumn,
  sortDirection,
  studentSearchTerm) {

  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/members-of-form/${node_mid}/${form_list_id}/${lg_user_id}/${lg_user_table_id}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${studentSearchTerm}`,
    requestOptions
  );
  return APIHandler.handleResponse(response)

}

async function insertFormAttachment(user_id, user_table_id, mid, form_list_id, note) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'user_id': user_id,
      'user_table_id': user_table_id,
      'mid': mid,
      'form_list_id': form_list_id,
      'note': note,
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/insert-form-attachment`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateFormAttachment(user_id, user_table_id, mid, form_list_id, note) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'user_id': user_id,
      'user_table_id': user_table_id,
      'mid': mid,
      'form_list_id': form_list_id,
      'note': note,
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/update-form-attachment`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteFormAttachment(user_id, user_table_id, mid, form_list_id) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'user_id': user_id,
      'user_table_id': user_table_id,
      'mid': mid,
      'form_list_id': form_list_id,
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/delete-form-attachment`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function attachDropdownForm(
  mid,
  user_id,
  user_table_id,
  form_list_id,
  dropdownform_node_id,
  dropdownform_form_list_id
) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'mid': mid,
      'user_id': user_id,
      'user_table_id': user_table_id,
      'form_list_id': form_list_id,
      'dropdown_form_mid': dropdownform_node_id,
      'dropdown_form_id': dropdownform_form_list_id
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/attach-dropdown-on-form`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateDropdownForm(
  mid,
  user_id,
  user_table_id,
  form_list_id,
  dropdownform_node_id,
  dropdownform_form_list_id
) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'mid': mid,
      'user_id': user_id,
      'user_table_id': user_table_id,
      'form_list_id': form_list_id,
      'dropdown_form_mid': dropdownform_node_id,
      'dropdown_form_id': dropdownform_form_list_id
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/update-dropdown-on-form`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function removeDropdownForm(
  mid,
  user_id,
  user_table_id,
  form_list_id
) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'mid': mid,
      'user_id': user_id,
      'user_table_id': user_table_id,
      'form_list_id': form_list_id
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/remove-dropdown-on-form`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


async function getPrivPerNodeForm(mid, form_list_id) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'mid': mid,
      'form_list_id': form_list_id
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/node-form-privileges`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


async function getPrivPerNode(mid) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'mid': mid
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/node-privileges`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getFormPrivRole() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/form-role-privileges`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getNodePrivRole() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/node-role-privileges`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getDisplayPrios() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/display-priorities`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getPrivStatus() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/privilege-statuses`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getRecRestricts() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/record-restrictions`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function addUpdatePrivPerNodeForm(
  user_id,
  user_table_id,
  node_mid,
  form_list_id,
  user_access_node_mid,
  user_access_form_list_id,
  user_access_table_id,
  user_access_user_id,
  flg_add_remove,
  role_group_id,
  flg_owner_restriction_onrecord,
  flg_owner_restriction_onlist,
  form_display_order,
  flg_form_display_priority,
  form_display_title,
  status
) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'user_id': user_id,
      'user_table_id': user_table_id,
      'node_mid': node_mid,
      'form_list_id': form_list_id,
      'user_access_node_mid': user_access_node_mid,
      'user_access_form_list_id': user_access_form_list_id,
      'user_access_table_id': user_access_table_id,
      'user_access_user_id': user_access_user_id,
      'flg_add_remove': flg_add_remove,
      'role_group_id': role_group_id,
      'flg_owner_restriction_onrecord': flg_owner_restriction_onrecord,
      'flg_owner_restriction_onlist': flg_owner_restriction_onlist,
      'form_display_order': form_display_order,
      'flg_form_display_priority': flg_form_display_priority,
      'form_display_title': form_display_title,
      'status': status
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/add-update-node-form-privilege`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deletePrivPerNodeForm(
  user_id,
  user_table_id,
  node_mid,
  form_list_id,
  user_access_node_mid,
  user_access_form_list_id,
  user_access_table_id,
  user_access_user_id,
  flg_add_remove,
  role_group_id,
  flg_form_display_priority,
  form_display_title,
  status
) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'user_id': user_id,
      'user_table_id': user_table_id,
      'node_mid': node_mid,
      'form_list_id': form_list_id,
      'user_access_node_mid': user_access_node_mid,
      'user_access_form_list_id': user_access_form_list_id,
      'user_access_table_id': user_access_table_id,
      'user_access_user_id': user_access_user_id,
      'flg_add_remove': flg_add_remove,
      'role_group_id': role_group_id,
      'flg_form_display_priority': flg_form_display_priority,
      'form_display_title': form_display_title,
      'status': status
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/delete-node-form-privilege`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function addUpdatePrivPerNode(
  user_id,
  user_table_id,
  node_mid,
  form_list_id,
  user_access_node_mid,
  user_access_form_list_id,
  user_access_table_id,
  user_access_user_id,
  flg_add_remove,
  role_group_id,
  flg_owner_restriction_onrecord,
  flg_owner_restriction_onlist,
  form_display_order,
  flg_form_display_priority,
  form_display_title,
  status
) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'user_id': user_id,
      'user_table_id': user_table_id,
      'node_mid': node_mid,
      'form_list_id': form_list_id,
      'user_access_node_mid': user_access_node_mid,
      'user_access_form_list_id': user_access_form_list_id,
      'user_access_table_id': user_access_table_id,
      'user_access_user_id': user_access_user_id,
      'flg_add_remove': flg_add_remove,
      'role_group_id': role_group_id,
      'flg_owner_restriction_onrecord': flg_owner_restriction_onrecord,
      'flg_owner_restriction_onlist': flg_owner_restriction_onlist,
      'form_display_order': form_display_order,
      'flg_form_display_priority': flg_form_display_priority,
      'form_display_title': form_display_title,
      'status': status
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/add-update-node-privilege`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deletePrivPerNode(
  user_id,
  user_table_id,
  node_mid,
  form_list_id,
  user_access_node_mid,
  user_access_form_list_id,
  user_access_table_id,
  user_access_user_id,
  flg_add_remove,
  role_group_id,
  flg_form_display_priority,
  form_display_title,
  status
) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify({
      'user_id': user_id,
      'user_table_id': user_table_id,
      'node_mid': node_mid,
      'form_list_id': form_list_id,
      'user_access_node_mid': user_access_node_mid,
      'user_access_form_list_id': user_access_form_list_id,
      'user_access_table_id': user_access_table_id,
      'user_access_user_id': user_access_user_id,
      'flg_add_remove': flg_add_remove,
      'role_group_id': role_group_id,
      'flg_form_display_priority': flg_form_display_priority,
      'form_display_title': form_display_title,
      'status': status
    }),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/node-form-maps/delete-node-privilege`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
