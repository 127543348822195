import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const FormListService = {
  getAll,
  getAllList
};

async function getAll() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/form-lists/getAll`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllList(node_id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/form-lists/getAll/${node_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}