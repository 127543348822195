import React from "react";
import Card from "../../../components/common/Card";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import "./hirearchy.css";
import CircularProgress from "@mui/material/CircularProgress";
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}

const AddCard = (props) => {
  return (
    <div className="hirearchy-card">
      <Card>
        <div className="add-card d-flex justify-content-center align-items-center">
          <IconButton aria-label="add" onClick={()=>props.toggleModal(false)}>
            {!!props.loading ? (
              <CircularProgress />
            ) : (
              <AddIcon fontSize="large" style={{ color: iconColor }} />
            )}
          </IconButton>
        </div>
      </Card>
    </div>
  );
};

export default AddCard;
