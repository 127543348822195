import React, { useEffect } from 'react';

export default function TabList({children}) {

    return (
                    
        <ul className="nav nav-pills available-assessments-nav" id="myTab1" role="tablist">
            {children}
        </ul>
        
    )
}


