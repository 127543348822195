import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function CustomSpinner({ text }) {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <Spinner animation="border" role="status" variant="primary">
        <span className="sr-only">{text}</span>
      </Spinner>
      <h4 className="ml-2" style={{ color: 'grey' }}>
        {text}
      </h4>
    </div>
  );
}
