import React, { Component } from "react"
import Nav from "react-bootstrap/Nav"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import { routes } from "../../../helper/routes";

class Scholarships extends Component {

  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role);
    this.props.history.push(routes.auth_response)
  }

  handleSignUp = () => {
    this.props.history.push(routes.registration_form_view)
  }

  render() {
    return (
      <>
        <div id="scholarship" className="container pt-3 pb-3">
          <div className="pt-5 pb-5 d-flex justify-content-center">
            <h1 className="edsider-section-title white-section-title">
              {/* Try EdSider Now */}
            </h1>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-md-3 col-6">
              <div className="schol-card-container">
                <div className="about-card">
                  <div className="schol-card-body">
                    <h1 className="d-flex justify-content-center align-items-center schol-card-title">
                      <Nav.Link
                        onClick={() => this.handleRoleSelect("student")}
                      >
                        Sign In
                      </Nav.Link>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6 pb-5">
              <div className="schol-card-container">
                <h1 className="d-flex justify-content-center align-items-center schol-card-title">
                  <Nav.Link onClick={this.handleSignUp}>Sign Up</Nav.Link>
                </h1>
              </div>
            </div>
            {/* <div className="col-md-4">
              <div className="schol-card-container">
                <div className="about-card">
                  <div className="schol-card-body">
                    <h1 className="d-flex justify-content-center align-items-center schol-card-title">
                      <Nav.Link onClick={this.handleSignUp}>English</Nav.Link>
                    </h1>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Scholarships)
