import React, { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import { Bar } from "react-chartjs-2"
import config from "../../../helper/config"
import { customerConstants } from "../../../helper/constants.js"
import { size } from "lodash"
import { Dropdown } from "react-bootstrap";


const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let BGColor, hoverBGColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    BGColor = "#7679bf"
    hoverBGColor = "#323569"
    break
  case customerConstants.EXO_CUSTOMER2:
    BGColor = "#5499c7"
    hoverBGColor = "#194d6f"
    break
  default:
    BGColor = "#15ACDE"
    hoverBGColor = "#218ACB"
    break
}

const BarChart = ({ val }) => {
  const [monthBasedData, setMonthBasedData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  useEffect( () => {
    fetchSuitableReceivable()
  },[val])

  const fetchSuitableReceivable = () => {
    const groupedData = val.reduce((acc, obj) => {
      const key = `${obj.flg_chart_legend_x_filter}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
    if(groupedData){
      const filteredGroupedData = Object.values(groupedData)
      setMonthBasedData(filteredGroupedData)
      setSelectedData(filteredGroupedData[0])
    }
  }
  const x_value = selectedData.map((val) => {
    return val.chart_x_value
  })
  const y_value = selectedData.map((val) => {
    return val.chart_y_value
  })
  const data = {
    labels: x_value,
    datasets: [
      {
        backgroundColor: BGColor,
        borderColor: BGColor,
        borderWidth: 1,
        hoverBackgroundColor: hoverBGColor,
        hoverBorderColor: hoverBGColor,
        data: y_value,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
        labels: {
          font: {
            family: 'Poppins',
          },
        },
      },
      tooltip: {
        bodyFont: {
          family: "Poppins",
        },
        titleFont: {
          family: "Poppins",
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text:
          selectedData[0]?.chart_legend_x_title !== null &&
          selectedData[0]?.chart_legend_x_title !== ""
              ? selectedData[0]?.chart_legend_x_title
              : "",
          font: {
            family: "Poppins",
            size: 10,
          },
        },
        ticks: {
          font: {
            family: "Poppins",
            size: 10,
          },
        },
      },
      y: {
        stacked: false,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text:
          selectedData[0]?.chart_legend_y_title !== null &&
          selectedData[0]?.chart_legend_y_title !== ""
              ? selectedData[0]?.chart_legend_y_title
              : "",
          font: {
            family: "Poppins",
            size: 10,
          },
        },
        ticks: {
          stepSize: 1,
          font: {
            family: "Poppins",
            size: 10,
          },
        },
      },
    },
    maintainAspectRatio: false,
    aspectRatio: 1
  }

  return (
    <div className="w-100">
      <h4 className="text-center mb-4">{selectedData[0]?.chart_title}</h4>
      <h4
        className="text-center"
        style={{ fontSize: "10px", fontWeight: "400" }}
      >
        {selectedData[0]?.breadcrumb}
      </h4>
    {
      (monthBasedData.length > 1 )&& <div className="">
    <Dropdown>
      <Dropdown.Toggle
        variant="primary"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#fff",
          border: "1px solid #E4E6EF",
          color: "var(--secondary-text-color)",
          fontWeight: "400",
          fontSize: "10px"
        }}
        
      >
        {selectedData&&selectedData[0]?.flg_chart_legend_x_filter}
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100">
        {monthBasedData && monthBasedData.map(obj => (
          <Dropdown.Item
            onClick={() => {setSelectedData(obj)}}
          >
            {obj[0].flg_chart_legend_x_filter}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
      </div>
    }
      <div style={{ height: "300px" }}><Bar data={data} options={options} /></div>
    </div>
  )
}

export default BarChart
