import { AzureStorage } from "../../helper/azureStorage";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const examService = {
    listAssessments: listAssessments,
    listChildAssessments: listChildAssessments,
    getAssessment: getAssessment,
    updateCandidateStatus:updateCandidateStatus,
    getReport: getReport,
    getRemarks: getRemarks,
    getAllCount: getAllCount
};

async function getAllCount() {
    try {
        const requestOptions = {
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            method: 'GET'
        };
        return await fetch(`${config.oat_api_url}/questions/getAllCount/`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}

async function listAssessments (id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/candidate/exams/getExams/${id}`, requestOptions).then(APIHandler.handleResponse);
}

async function listChildAssessments (parent_id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/candidate/exams/getChildExams/${parent_id}`, requestOptions).then(APIHandler.handleResponse);
}

async function getAssessment (id) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/assessments/get/${id}`, requestOptions).then(APIHandler.handleResponse);
}

async function getReport (id, attempt_no) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    return await fetch(`${config.oat_api_url}/candidate/grade/reports/get/${id}/${attempt_no}`, requestOptions).then(APIHandler.handleResponse);
}

async function updateCandidateStatus (obj) {
    let requestBody = {
        candidate_id: obj.candidate_id,
        assessment_id: obj.assessment_id,
      //  userID: obj.userID,
        candidate_status: obj.candidate_status,
      //  added_by: obj.added_by
    }
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(requestBody)
    };

    return await fetch(`${config.oat_api_url}/candidates/update/${obj.assessment_id}`, requestOptions).then(APIHandler.handleResponse);
}

async function getRemarks (key) {
  //Get assessor remarks content from DB
  return key;
}
  

