import React from 'react';

export default function WizardForm ({ children, id }) {

    return (

        <form className="px-10 col-md-12 pt-3" id={id}>
            {children}
        </form>
                         
    )
}


