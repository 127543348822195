import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography, CssBaseline, Modal, Box, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import { CalendarEventTypeService } from '../../../../service/settings/manage/calendar_events.service';
import { CalendarService } from '../../../../service/manage/calendar/calendar.service';
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomToolBar from '../../../../pages/manage/calendar/CustomToolBar';
import CalToolBar from './CalToolbar';
import './CoverPage.css';

const localizer = momentLocalizer(moment);

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
      `,
    },
  },
});

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const DateCalendarValue = () => {
  const [events, setEvents] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month());
  const [showEventPopup, setShowEventPopup] = useState(false);
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [slotSelectedDate, setSlotSelectedDate] = useState(null);

  useEffect(() => {
    loadEventsForSelectedMonth();
  }, [selectedYear, selectedMonth]);

  const loadEventsForSelectedMonth = async () => {
    try {
      const response = await CalendarService.getEventsForCGC(selectedYear, selectedMonth + 1);
      if (response.success) {
        const calendarEvents = response.data.map((event) => ({
          title: event.event_title,
          start: moment(event.event_start_date).toDate(),
          end: moment(event.event_start_date).toDate(),
          entry_id: event.entry_id,
          calendar_event_type: event.calendar_event_type,
          cal_event_type_id: event.cal_event_type_id,
        }));
        setEvents(calendarEvents);
      }
    } catch (error) {
      console.error('Error loading events:', error);
    }
  };

  const handleMonthChange = (date) => {
    setSelectedMonth(moment(date).month());
    setSelectedYear(moment(date).year());
  };

  const handleDateClick = (slotInfo) => {
    const selectedDate = moment(slotInfo.start);
    const selectedEvents = events.filter((event) => {
      return moment(event.start).isSame(selectedDate, "day");
    });
    setSelectedDateEvents(selectedEvents);
    setSlotSelectedDate(selectedDate);
    setShowEventPopup(true);
  };

  const closeEventPopup = () => {
    setShowEventPopup(false);
    setSelectedDateEvents([]);
    setSlotSelectedDate(null);
  };

  const formattedDateString = (date) => {
    return dayjs(date).format('MMMM D, YYYY');
  };

  const selectedDate = moment().year(selectedYear).month(selectedMonth);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BigCalendar
        localizer={localizer}
        events={events}
        views={["month"]}
        defaultView={"month"}
        style={{ height: 300 }}
        date={selectedDate.toDate()}
        onNavigate={handleMonthChange}
        selectable={true}
        onSelectSlot={handleDateClick}
        onSelectEvent={handleDateClick}
        components={{
          toolbar: CalToolBar,
        }}
      />
      {showEventPopup && (
        <Modal open={showEventPopup} onClose={closeEventPopup}>
          <Box sx={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
            width: 400, bgcolor: 'background.paper', border: '2px solid #000',
            boxShadow: 24, p: 4
          }}>
            <Typography variant="h6" component="h2">
              Events Of the Day {`: ${formattedDateString(slotSelectedDate)}`}
            </Typography>
            {selectedDateEvents.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Event Title</TableCell>
                    <TableCell>Event Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedDateEvents.map((event) => (
                    <TableRow key={event.entry_id}>
                      <TableCell>{event.title}</TableCell>
                      <TableCell>{event.calendar_event_type}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography>No events to show on this Date</Typography>
            )}
            <Button onClick={closeEventPopup}>Close</Button>
          </Box>
        </Modal>
      )}
    </ThemeProvider>
  );
};

export default DateCalendarValue;
