import React from "react";
import { MicrosoftLoginAgent } from "../../authentication/MicrosoftLoginAgent";
import { UMAuthAgent } from "../../authentication/UMAuthAgent";
import { Redirect } from "react-router";
import Spinner from "../Spinner";
import { connect } from "react-redux";
import { blue } from "@material-ui/core/colors";
import { routes } from "../../helper/routes";

class AuthResp extends React.Component {
  // Add a flag to track component mounting/unmounting
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      isFlowCancelled: false,
      termsAndConditions: "underReview",
      loading: true,
      umAuthAgentObj: new UMAuthAgent(),
    };
  }

  styles = {
    color: blue,
  };

  async componentDidMount() {
    this._isMounted = true; // Set the flag to true when the component is mounted

    // Check if user was redirected after login
    let token = await MicrosoftLoginAgent.GetTokenFromRedirect();
    if (token) return this.postLogin(token);
    return this.login();
  }

  componentWillUnmount() {
    this._isMounted = false; // Set the flag to false when the component is unmounted
  }

  async login() {
    try {
      await MicrosoftLoginAgent.login();
      if (this._isMounted) {
        // Check if the component is still mounted before updating the state
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      if (this._isMounted) {
        // Check if the component is still mounted before updating the state
        this.setState({
          isFlowCancelled: true,
          loading: false,
        });
      }
    }
  }

  async postLogin(token) {
    try {
      await MicrosoftLoginAgent.GetDetailsFromToken(token);
      const UMAuthAgentObj = new UMAuthAgent();
      await UMAuthAgentObj.getUMUser();
      if (this._isMounted) {
        // Check if the component is still mounted before updating the state
        this.setState({
          umAuthAgentObj: UMAuthAgentObj,
          loading: false,
        });
      }
    } catch (error) {
      if (this._isMounted) {
        // Check if the component is still mounted before updating the state
        this.setState({
          isFlowCancelled: true,
          loading: false,
        });
      }
    }
  }

  render() {
    if (!this.state.loading) {
      if (
        this.props.agreementStatus === "accepted"
        // && this.state.umAuthAgentObj.userType === "admin"
      ) {
        const fromURL = sessionStorage.getItem("fromURL");
        if (fromURL === routes.enrolled_paper_list) {
          const nodeMid = sessionStorage.getItem("entrolledPaper_nodeMid");
          const candidateId = sessionStorage.getItem("entrolledPaper_candidateId");
          sessionStorage.removeItem('fromURL');
          sessionStorage.removeItem('entrolledPaper_nodeMid');
          sessionStorage.removeItem('entrolledPaper_candidateId');
          return <Redirect to={`/manage-dashboard?enrolled-paper-list=true&node-mid=${nodeMid}&candidate-id=${candidateId}`} />;
        } else {
          return <Redirect to="/manage-dashboard" />;
        }
      }

      if (this.props.agreementStatus === "notYetReview") {
        return (
          <Redirect
            to={{
              pathname: routes.accept_terms,
              state: { userType: this.state.umAuthAgentObj.userType },
            }}
          />
        );
      }
      if (
        this.state.isFlowCancelled ||
        this.props.agreementStatus === "unaccepted"
      ) {
        return <Redirect to="/" />;
      }
      return <Spinner active={true} text="Logging In..." />;
    } else {
      return <Spinner active={true} text="Logging In..." />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    agreementStatus: state.userAgreementReducer.status,
  };
};

const actionCreators = {};

const connectedAuthRespPage = connect(
  mapStateToProps,
  actionCreators
)(AuthResp);

export { connectedAuthRespPage as AuthResp };
