import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const GenderService = {
  getAll,
  getAllGenders,
  add,
  update,
  deleteGender,
};

async function getAll(limit, offset, search_term) {
  const configOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/genders/getAll/${limit}/${offset}?search_term=${search_term}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllGenders() {
  const configOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/genders/getAllGenders`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function add(newGender) {
  const configOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(newGender),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/genders/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(genderItem, genderId) {
  const configOptions = {
    method: "PUT",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(genderItem),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/genders/update/${genderId}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function deleteGender(id) {
  const configOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/genders/delete/${id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}
