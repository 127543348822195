import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const PaymentMethodService = {
  create,
  update,
  getPaymentMethodByPagination,
  getAllPaymentMethods,
  deletePaymentMethod
};

async function create(calendarEvent) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(calendarEvent),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/payment-methods/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(calendarEvent, calendarEventId) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(calendarEvent),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/payment-methods/update/${calendarEventId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllPaymentMethods() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/payment-methods/getAllPaymentMethods`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}


async function getPaymentMethodByPagination(limit, offset, search_term) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/payment-methods/getAll/${limit}/${offset}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function deletePaymentMethod(id) {
  const configOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/payment-methods/delete/${id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}