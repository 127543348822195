import { AzureStorage } from "../../helper/azureStorage";
import DataHandler from "../../handlers/DataHandler";
import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";
import { questionTypeConstants } from "../../helper/constants";

export const descriptiveService = {
    saveAnswer,
    saveQuestion,
    saveSubQuestion,
    saveMainQuestion,
};

async function saveQuestion(obj) {
    try {

        // Convert the object to a JSON string
        const user_id = DataHandler.getFromSession("user_id");
        let questionContent = JSON.stringify(obj.questionContent);
        let answerContent = JSON.stringify(obj.answerContent);
        let isContentBlob = "false";
        let isAnswerBlob = "false";
        const guidanceContent = JSON.stringify(obj.guidence);

        //Checking the size of the question/answer content objects
        const qsizeInBytes = new Blob([questionContent]).size;
        const qsizeInGB = qsizeInBytes / (1024 * 1024 * 1024);

        const asizeInBytes = new Blob([answerContent]).size;
        const asizeInGB = asizeInBytes / (1024 * 1024 * 1024);

        //If the size of question content object exceeds 1 GB
        if(qsizeInGB > config.storageBlobConfigs.maxDbStorageSize){
            // Upload question content to Azure Blob Storage
            const questionContainerName= config.storageBlobConfigs.containerName;
            const questionBlobName = "".concat(user_id, "-q-", obj.questionKey);
            await AzureStorage.storeBlobContent(questionContainerName, questionBlobName, questionContent);
            questionContent = obj.questionKey;
            isContentBlob = "true";
        }

        //If the size of answer content object exceeds 1 GB
        if(asizeInGB > config.storageBlobConfigs.maxDbStorageSize){
            // Upload answer content to Azure Blob Storage
            const answerContainerName =  config.storageBlobConfigs.containerName;
            const answerBlobName = "".concat(user_id, "-a-", obj.answerKey);
            await AzureStorage.storeBlobContent(answerContainerName, answerBlobName, answerContent);
            answerContent = obj.answerKey;
            isAnswerBlob = "true";
        }

        const requestOptions = {
            method: 'POST',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify({
                title: obj.title,
                content: questionContent, 
                qtype: obj.qType,
                answer_type_id: obj.answer_type_id,
                category_id: obj.categoryId,
                created_by: obj.createdBy,
                answer: answerContent, 
                difficulty_id: obj.difficulty_id,
                language_id: obj.language_id,
                source_id: obj.source_id,
                subject_id: obj.subject_id,
                chapter_id: obj.chapter_id,
                grade_id: obj.grade_id,
                guidence: guidanceContent,
                author_id: obj.author_id,
                year_id: obj.year_id,
                term_id: obj.term_id,
                privilege_id: obj.privilege_id,
                tags: obj.tags,
                is_content_blob: isContentBlob,
                is_answer_blob: isAnswerBlob
            })
        };

        let data = await fetch(`${config.oat_api_url}/questions/add/`, requestOptions);
        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}

async function saveSubQuestion(obj) {
    try {
        // Convert the object to a JSON string
        const user_id = DataHandler.getFromSession("user_id");
        let questionContent = JSON.stringify(obj.questionContent);
        let answerContent = JSON.stringify(obj.answerContent);
        let isContentBlob = "false";
        let isAnswerBlob = "false";
        const guidanceContent = JSON.stringify(obj.guidence);

        //Checking the size of the question/answer content objects
        const qsizeInBytes = new Blob([questionContent]).size;
        const qsizeInGB = qsizeInBytes / (1024 * 1024 * 1024);

        const asizeInBytes = new Blob([answerContent]).size;
        const asizeInGB = asizeInBytes / (1024 * 1024 * 1024);

        //If the size of question content object exceeds 1 GB
        if(qsizeInGB > config.storageBlobConfigs.maxDbStorageSize){
            // Upload question content to Azure Blob Storage
            const questionContainerName= config.storageBlobConfigs.containerName;
            const questionBlobName = "".concat(user_id, "-q-", obj.questionKey);
            await AzureStorage.storeBlobContent(questionContainerName, questionBlobName, questionContent);
            questionContent = obj.questionKey;
            isContentBlob = "true";
        }

        //If the size of answer content object exceeds 1 GB
        if(asizeInGB > config.storageBlobConfigs.maxDbStorageSize){
            // Upload answer content to Azure Blob Storage
            const answerContainerName= config.storageBlobConfigs.containerName;
            const answerBlobName = "".concat(user_id, "-a-", obj.answerKey);
            await AzureStorage.storeBlobContent(answerContainerName, answerBlobName, answerContent);
            answerContent = obj.answerKey;
            isAnswerBlob = "true";
        }

        const requestOptions = {
            method: 'POST',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify({
                title: obj.title,
                content: questionContent, 
                qtype: obj.qType,
                answer_type_id: obj.answer_type_id,
                category_id: obj.categoryId,
                created_by: obj.createdBy,
                answer: answerContent, 
                difficulty_id: obj.difficulty_id,
                language_id: obj.language_id,
                source_id: obj.source_id,
                subject_id: obj.subject_id,
                chapter_id: obj.chapter_id,
                grade_id: obj.grade_id,
                sub_questions: null,
                is_subquestion: "true",
                guidence: guidanceContent,
                author_id: obj.author_id,
                year_id: obj.year_id,
                term_id: obj.term_id,
                privilege_id: obj.privilege_id,
                tags: obj.tags,
                is_content_blob: isContentBlob,
                is_answer_blob: isAnswerBlob
            })
        };

        let data = await fetch(`${config.oat_api_url}/questions/add/`, requestOptions);
        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}

async function saveMainQuestion(obj) {
    try {
        // Convert the object to a JSON string
        const user_id = DataHandler.getFromSession("user_id");
        let questionContent = JSON.stringify(obj.questionContent);
        let isContentBlob = "false";
        let isAnswerBlob = "false";
        const guidanceContent = JSON.stringify(obj.guidence);

        //Checking the size of the question content object
        const qsizeInBytes = new Blob([questionContent]).size;
        const qsizeInGB = qsizeInBytes / (1024 * 1024 * 1024);

        //If the size of question content object exceeds 1 GB
        if(qsizeInGB > config.storageBlobConfigs.maxDbStorageSize){
            // Upload question content to Azure Blob Storage
            const questionContainerName= config.storageBlobConfigs.containerName;
            const questionBlobName = "".concat(user_id, "-q-", obj.questionKey);
            await AzureStorage.storeBlobContent(questionContainerName, questionBlobName, questionContent);
            questionContent = obj.questionKey;
            isContentBlob = "true";
        }

        const requestOptions = {
            method: 'POST',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify({
                title: obj.title,
                content: questionContent, // Store the blob name as content
                qtype: obj.qType,
                answer_type_id: obj.answer_type_id,
                category_id: obj.categoryId,
                created_by: obj.createdBy,
                answer: obj.answerContent, // Store the blob name as answer
                difficulty_id: obj.difficulty_id,
                language_id: obj.language_id,
                source_id: obj.source_id,
                subject_id: obj.subject_id,
                chapter_id: obj.chapter_id,
                grade_id: obj.grade_id,
                sub_questions: obj.sub_questions,
                guidence: guidanceContent,
                author_id: obj.author_id,
                year_id: obj.year_id,
                term_id: obj.term_id,
                privilege_id: obj.privilege_id,
                tags: obj.tags,
                is_content_blob: isContentBlob,
                is_answer_blob: isAnswerBlob
            })
        };

        let data = await fetch(`${config.oat_api_url}/questions/add/`, requestOptions);
        return APIHandler.handleResponse(data);
    } catch (error) {
        return { error: error };
    }
}

async function saveAnswer(obj) {
    try {
        const user_id = DataHandler.getFromSession("user_id");
        let answerContent = JSON.stringify(obj.answerContent);

        //Checking the size of the answer content object
        const asizeInBytes = new Blob([answerContent]).size;
        const asizeInGB = asizeInBytes / (1024 * 1024 * 1024);

        //If the size of answer content object exceeds 1 GB
        if(asizeInGB > config.storageBlobConfigs.maxDbStorageSize){
            // Upload answer content to Azure Blob Storage
            const answerContainerName= config.storageBlobConfigs.containerName;
            const answerBlobName = "".concat(user_id, "-a-", obj.answerKey)
            await AzureStorage.storeBlobContent(answerContainerName, answerBlobName, answerContent);
            answerContent = obj.answerKey;
        }

        const requestOptions = {
            method: 'POST',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            body: JSON.stringify({
                answer: answerContent // Store the blob name as the answer
            })
        };

        return fetch(`${config.oat_api_url}/questions/update/${obj.question_id}`, requestOptions).then(APIHandler.handleResponse);
    } catch (error) {
        return { error: error };
    }
}
