import {quesSubjectTypes} from '../settingsTypes';

const initialState = {
    allQuestionSubjects: [],
    loading: false,
    error: '',
    text: '',
    subject:'',
    totalSubjectsCount: 0,
    allSubjects:[],
    errorsubjectdelete:false,
    loadingsubjectdelete:false,
    successsubjectupdate: false,
    loadingsubjectupdate: false,
    errorsubjectupdate:false,
    successsubjectdelete:false,
    errorsubjectcreate: '',
    successsubjectcreate: false,
}

export function subjectReducer(state = initialState, action){
    switch (action.type){
        case quesSubjectTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allQuestionSubjects: [],
                loading: false,
                error: '',
                text: '',
                subject:'',
                totalSubjectsCount:0,
                allSubjects:[],
                errorsubjectdelete:false,
                loadingsubjectdelete:false,
                successsubjectupdate: false,
                loadingsubjectupdate: false,
                errorsubjectupdate:false,
                successsubjectdelete:false,
                errorsubjectcreate: '',
                successsubjectcreate: false,
            }
        //Subject retrieve request sending
        case quesSubjectTypes.GET_ALL_QUESTION_SUBJECTS:
            return {
                ...state,
                loading: true,
                text: 'Get all the question subjects'
            }
        case quesSubjectTypes.GET_ALL_QUESTION_SUBJECTS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case quesSubjectTypes.GET_ALL_QUESTION_SUBJECTS_SUCCESS:
            return {
                ...state,
                loading: false,
                allQuestionSubjects: action.subjects.subjects,
                totalSubjectsCount: action.subjects.total_count
            }
        case quesSubjectTypes.GET_ALL_QUESTION_SUBJECTS_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Subject creation request sending
        case quesSubjectTypes.CREATE_SUBJECT_REQUEST:
            return {
                ...state,
                errorsubjectcreate: '',
                successsubjectcreate: false,
                loading: true
            }
        case quesSubjectTypes.CREATE_SUBJECT_SUCCESS:
            var temp = state.allSubjects;
            temp.push(action.subjects)
            return {
                ...state,
                allSubjects: temp,
                subject: action.subjects.subject_id,
                successsubjectcreate: true,
                loading: false
            }
        case quesSubjectTypes.CREATE_SUBJECT_FAILURE:
            return {
                ...state,
                errorsubjectcreate: action.error,
                loading: false
            }  
        //Subject deletion request sending   
        case quesSubjectTypes.DELETE_SUBJECT_REQUEST:
            return {
            ...state,
            errorsubjectdelete: '',
            successsubjectdelete: false,
            loading: true,
            };
        case quesSubjectTypes.DELETE_SUBJECT_SUCCESS:
            return {
            ...state,
            successsubjectdelete: true,
            loading: false,
            };
        case quesSubjectTypes.DELETE_SUBJECT_FAILURE:
            return {
            ...state,
            errorsubjectdelete: action.error,
            loading: false,
            };
        //Subject updation request sending
        case quesSubjectTypes.UPDATE_SUBJECT_REQUEST:
            return {
            ...state,
            errorsubjectupdate:'',
            successsubjectupdate: false,
            loading: true,
            };
        case quesSubjectTypes.UPDATE_SUBJECT_SUCCESS:
            return {
            ...state,
            successsubjectupdate: true,
            loading: false,
            };
        case quesSubjectTypes.UPDATE_SUBJECT_FAILURE:
            return {
            ...state,
            errorsubjectupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
