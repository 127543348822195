import {assessCatTypes} from '../settingsTypes';

const initialState = {
    allAssessmentCategories: [],
    loading: false,
    error: '',
    text: '',
    category:'',
    totalCategoriesCount: 0,
    allCategories:[],
    errorcategorydelete:false,
    loadingcategorydelete:false,
    successcategoryupdate: false,
    loadingcategoryupdate: false,
    errorcategoryupdate:false,
    successcategorydelete:false,
    errorcategorycreate: '',
    successcategorycreate: false,
}

export function categoryReducer(state = initialState, action){
    switch (action.type){
        case assessCatTypes.CREATE_PAGE_REFRESH:
            return {
                ...state,
                allAssessmentCategories: [],
                loading: false,
                error: '',
                text: '',
                category:'',
                totalCategoriesCount:0,
                allCategories:[],
                errorcategorydelete:false,
                loadingcategorydelete:false,
                successcategoryupdate: false,
                loadingcategoryupdate: false,
                errorcategoryupdate:false,
                successcategorydelete:false,
                errorcategorycreate: '',
                successcategorycreate: false,
            }
        //Category retrieve request sending
        case assessCatTypes.GET_ALL_ASSESSMENT_CATEGORIES:
            return {
                ...state,
                loading: true,
                text: 'Get all the assessment categories'
            }
        case assessCatTypes.GET_ALL_ASSESSMENT_CATEGORIES_REQUEST:
            return {
                ...state,
                loading: true
            }
        case assessCatTypes.GET_ALL_ASSESSMENT_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                allAssessmentCategories: action.categories.categories,
                totalCategoriesCount: action.categories.total_count
            }
        case assessCatTypes.GET_ALL_ASSESSMENT_CATEGORIES_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        //Category creation request sending
        case assessCatTypes.CREATE_CATEGORY_REQUEST:
            return {
                ...state,
                errorcategorycreate: '',
                successcategorycreate: false,
                loading: true
            }
        case assessCatTypes.CREATE_CATEGORY_SUCCESS:
            var temp = state.allCategories;
            temp.push(action.categories)
            return {
                ...state,
                allCategories: temp,
                category: action.categories.category_id,
                successcategorycreate: true,
                loading: false
            }
        case assessCatTypes.CREATE_CATEGORY_FAILURE:
            return {
                ...state,
                errorcategorycreate: action.error,
                loading: false
            }  
        //Category deletion request sending   
        case assessCatTypes.DELETE_CATEGORY_REQUEST:
            return {
            ...state,
            errorcategorydelete: '',
            successcategorydelete: false,
            loading: true,
            };
        case assessCatTypes.DELETE_CATEGORY_SUCCESS:
            return {
            ...state,
            successcategorydelete: true,
            loading: false,
            };
        case assessCatTypes.DELETE_CATEGORY_FAILURE:
            return {
            ...state,
            errorcategorydelete: action.error,
            loading: false,
            };
        //Category updation request sending
        case assessCatTypes.UPDATE_CATEGORY_REQUEST:
            return {
            ...state,
            errorcategoryupdate:'',
            successcategoryupdate: false,
            loading: true,
            };
        case assessCatTypes.UPDATE_CATEGORY_SUCCESS:
            return {
            ...state,
            successcategoryupdate: true,
            loading: false,
            };
        case assessCatTypes.UPDATE_CATEGORY_FAILURE:
            return {
            ...state,
            errorcategoryupdate: action.error,
            loading: false,
            };     
        default:
            return state
    }
}
