import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import edfocilogo from "../../../assets/images/logo.png"
import edsiderlogo from "../../../assets/images/edsiderlogo.png"
import cmclogo from "../../../assets/images/mid.png";
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";
import React, { Component } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { routes } from "../../../helper/routes.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};
class NavBar extends Component {
  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role);
    this.props.history.push(routes.auth_response);
  };

  handleSignUp = () => {
    this.props.history.push(routes.registration_form_view);
  };

  render() {
    const customer = getCustomerFeatureFlag();
    let logo;
    switch (customer) {
      case customerConstants.EXO_CUSTOMER1:
        logo = edsiderlogo;
        break;
      case customerConstants.EXO_CUSTOMER2:
        logo = cmclogo;
        break;
      default:
        logo = edfocilogo;
        break;
    }
    return (
      <>
        <Navbar
          // fixed="top"
          //   collapseOnSelect
          //   expand="lg"
          className="navigation-bg"
        >
          <Navbar.Brand href="/">
            <img
              src={logo}
              alt="logo"
              style={{ height: "auto", width: "200px" }}
            />
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/" className="back-btn">Back</Nav.Link>
          </Nav>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </Navbar>
      </>
    );
  }
}

export default withRouter(NavBar);
