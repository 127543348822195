import React, { Component } from "react";
import { Form, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
} from "@mui/material";
import ReactSelect from "react-select";
import { CreditService } from "../../../service/manage/subscription/credit.service";
import {
  sweetErrorAlert,
  sweetStatusAlert,
  sweetSuccessAlert,
} from "../../../components/common/SweetAlert";
import { MarksheetService } from "../../../service/manage/mark_sheet/marksheet.service";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import debounce from "lodash.debounce";
import {
  reportTitleService,
  teacherRemarkService,
} from "../../../service/manage/mark_sheet/marksheetreport.service";

export default class ReportCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      studentlist: [],
      selectedOption: null,
      isStudentListLoading: false,
      selectedStudent: "",
      table_id:"",
      reportTitle: [],
      selectedTitle: "",
      isTitleListLoading: false,
      titleSelected: false,
      reportCardPrivilege: null,
      flg_list_active: null,
      isDropHidden: false,
      isModalOpen: false,
      modalDataLoading: false,
      limit: 5000,
      offset: 0,
      sortColumn: "user_code",
      sortDirection: "desc",
      studentSearchTerm: "",
      userType: "",
      tableData: [],
      isTableDataLoading: false,
      teacherRemark: [],
      RemarkStatus: "",
    };
    this.debouncedHandleStudentSearch = debounce(this.fetchallstudent, 1000);
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  componentDidMount() {
    const { flg_list_active } = this.props.props;
    const userType = sessionStorage.getItem("userType");

    this.setState(
      {
        userType: userType,
        reportCardPrivilege: this.props.reportCardPrivilege,
        flg_list_active,
      },
      () => {
        const { reportCardPrivilege } = this.state;
        if (reportCardPrivilege.isAdmin || reportCardPrivilege.isTeacher) {
          this.fetchallstudent();
          if (flg_list_active === 1) {
            this.setState({ isDropHidden: true });
          }
        }

        this.fetchallTitles();
      }
    );
  }

  fetchallstudent = () => {
    const { mid, lg_user_id, lg_user_table_id, form_list_id } =
      this.props.props;
    const { studentSearchTerm, limit, offset, sortColumn, sortDirection } =
      this.state;

    this.setState({ isStudentListLoading: true });

    MarksheetService.getAllEnrolledStudents(
      mid,
      form_list_id,
      lg_user_id,
      lg_user_table_id,
      limit,
      offset,
      sortColumn,
      sortDirection,
      studentSearchTerm
    )
      .then((response) => {
        if (response.success) {
          this.setState({ studentlist: response.data.users });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching students. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch(() => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching students. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isStudentListLoading: false });
      });
  };

  fetchallTitles = () => {
    const { mid } = this.props.props;
    this.setState({ isTitleListLoading: true });
    reportTitleService
      .getAllReportTitle(mid)
      .then((response) => {
        if (response.success) {
          this.setState({ reportTitle: response.data.report_title });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Titles. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch(() => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Titles. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.setState({ isTitleListLoading: false });
      });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  handleTitleChange = (event) => {
    const selectedTitle = event.target.value;
    this.setState({ selectedTitle, titleSelected: true });

    const { reportCardPrivilege, selectedStudent,table_id } = this.state;
    if (reportCardPrivilege.isStudent) {
      this.fetchReportForStudent(selectedTitle);
    } else if (selectedStudent && reportCardPrivilege.isAdmin) {
      this.fetchReportForParentAndAdmin(selectedTitle, selectedStudent,table_id);
    } else if (selectedStudent && reportCardPrivilege.isTeacher) {
      this.fetchReportForParentAndAdmin(selectedTitle, selectedStudent,table_id);
    }
  };

  fetchReportForStudent = (selectedTitle) => {
    const { mid, lg_user_id, lg_user_table_id } = this.props.props;

    this.setState({ isTableDataLoading: true });
    MarksheetService.getReportForStudent(
      mid,
      lg_user_id,
      lg_user_table_id,
      selectedTitle
    )
      .then((response) => {
        if (response.success) {
          this.setState({ tableData: response.data.reports });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Report. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch(() => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Report. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.fetchTeacherRemark(selectedTitle);
      });
  };

  fetchReportForParentAndAdmin = (selectedTitle, lg_user_id,lg_user_table_id) => {
    const { mid } = this.props.props;

    this.setState({ isTableDataLoading: true });
    MarksheetService.getReportForAdminAndTeachers(
      mid,
      lg_user_id,
      lg_user_table_id,
      selectedTitle
    )
      .then((response) => {
        if (response.success) {
          this.setState({ tableData: response.data.reports });
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error fetching Report. Please refresh the screen!",
            "error"
          );
        }
      })
      .catch(() => {
        sweetStatusAlert(
          "Failed!",
          "Error fetching Report. Please refresh the screen!",
          "error"
        );
      })
      .finally(() => {
        this.fetchTeacherRemark(selectedTitle);
      });
  };

  handleStudentSearch = (event) => {
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;
    if (event === "" || regex.test(event)) {
      let num = event;
      this.setState(
        {
          studentSearchTerm: num,
          searchError: false,
          selectedOption: "",
          studentlist: [],
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleStudentSearch();
        }
      );
    } else {
      this.setState({
        studentSearchTerm: event,
        searchError: true,
        selectedOption: "",
      });
    }
  };

  fetchTeacherRemark = (selectedTitle) => {
    const { mid } = this.props.props;

    teacherRemarkService
      .getAllTeacherRemark(mid, selectedTitle)
      .then((response) => {
        if (response.success) {
          this.setState({ teacherRemark: response.data?.overallComment });
          this.setState({ RemarkStatus: response.data.status_code });
        } else {
          console.error("Error fetching Report. Please refresh the screen!");
        }
      })
      .catch(() => {
        console.error("Error fetching Report. Please refresh the screen!");
      })
      .finally(() => {
        this.setState({ isTableDataLoading: false });
      });
  };

  handleOverallRemark = (id, e) => {
    const { teacherRemark } = this.state;

    const updatedTeacherRemark = teacherRemark.map((teacher) => {
      if (teacher.id === id) {
        return { ...teacher, comment: e.target.value };
      }
      return teacher;
    });

    this.setState({ teacherRemark: updatedTeacherRemark });
  };

  handleChange = (e) => {
    const { teacherRemark } = this.state;

    const newTeacherRemark = [{ comment: e.target.value }];

    this.setState({ teacherRemark: newTeacherRemark });
  };

  handleUpdate = () => {
    const { teacherRemark, RemarkStatus, selectedTitle, selectedStudent,table_id } =
      this.state;

    const { mid } = this.props.props;

    if (RemarkStatus === 404) {
      this.setState({ isLoading: true });

      const addRemark = {
        comment: teacherRemark[0]?.comment,
        node_id: mid,
        title_id: this.state.selectedTitle,
      };

      teacherRemarkService
        .addTeacherRemark(addRemark)
        .then((response) => {
          if (response.success) {
            this.toggleModal();
            this.setState({ isLoading: false });
            sweetSuccessAlert(
              "Success",
              "A new Overall Remark Saved Successfuly.",
              "success"
            );

            this.fetchReportForParentAndAdmin(selectedTitle, selectedStudent,table_id);
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Info", response.message, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while adding Remark", "error");
        });
    } else if (RemarkStatus === 200) {
      this.setState({ isLoading: true });
      this.setState({ isModalOpen: false });
      const updateRemark = {
        comment: teacherRemark[0]?.comment,
        node_id: mid,
        title_id: this.state.selectedTitle,
      };
      teacherRemarkService
        .updateTeacherRemark(updateRemark, teacherRemark[0]?.id)
        .then((response) => {
          if (response.success) {
            this.setState({ isModalOpen: false });
            this.setState({ isLoading: false });
            sweetSuccessAlert(
              "Success",
              "A new Overall Remark Saved Successfuly.",
              "success"
            );

            this.fetchReportForParentAndAdmin(selectedTitle, selectedStudent,table_id);
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Info", response.message, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while adding Remark", "error");
        });
    }
  };

  render() {
    const {
      studentlist,
      selectedOption,
      isStudentListLoading,
      reportTitle,
      titleSelected,
      selectedTitle,
      isDropHidden,
      isModalOpen,
      modalDataLoading,
      Spinner,
      reportCardPrivilege,
      isTableDataLoading,
      isTitleListLoading,
      tableData,
      userType,
      teacherRemark,
      isLoading,
    } = this.state;

    return (
      <>
        <div className="row py-4">
          <div className="col-md-12 d-flex justify-content-end py-3">
            {userType === "teacher" || userType === "admin" ? (
              selectedTitle &&
              this.state.selectedStudent && (
                <Button
                  className="insert-btn"
                  variant="primary"
                  onClick={this.toggleModal}
                >
                  View Overall
                </Button>
              )
            ) : (
              <Button
                className="insert-btn"
                variant="primary"
                onClick={this.toggleModal}
              >
                View Overall
              </Button>
            )}
          </div>
          <div className="col-md-6 mb-md-2 mb-sm-2">
            <InputLabel htmlFor="markTypeDropdown">Select the Title</InputLabel>
            <Select
              id="markTypeDropdown"
              value={selectedTitle}
              onChange={this.handleTitleChange}
              style={{
                // width: "100%",
                fontFamily: "Poppins",
              }}
              size="small"
              className="col-md-12"
            >
              <MenuItem value="" disabled style={{ fontFamily: "Poppins" }}>
                Select
              </MenuItem>
              {reportTitle.map((reportTitl) => (
                <MenuItem
                  key={reportTitl.id}
                  value={reportTitl.id}
                  style={{ fontFamily: "Poppins" }}
                >
                  {reportTitl.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="col-md-6 col-12 d-flex align-items-end">
            {titleSelected && isDropHidden && (
              <div style={{ width: "100%" }}>
                <Form.Group
                  as={Col}
                  controlId="exampleForm.ControlInput3"
                  size="small"
                  className="col-md-12 col-12 px-0"
                >
                  <ReactSelect
                    placeholder="Search by student number"
                    options={studentlist.map((student) => ({
                      value: student.user_id,
                      label: `${student.data_concat}`,
                    }))}
                    value={selectedOption}
                    isLoading={isStudentListLoading}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption?.value;

                      if (!selectedValue) {
                        this.setState({
                          selectedStudent: "",
                          selectedOption: null,
                          studentlist: [],
                        });
                        this.handleStudentSearch("");
                        return;
                      }

                      let student = this.state.studentlist.find(
                        (student) => student.user_id === selectedValue
                      );
                      this.setState({
                        selectedOption,
                      });

                      const selectedStudent = student.user_id;
                      const table_id = student.user_table_id
                      const { selectedTitle } = this.state;
                      if (selectedStudent) {
                        this.setState({ selectedStudent:selectedStudent,table_id:table_id });
                        if (
                          reportCardPrivilege.isAdmin ||
                          reportCardPrivilege.isTeacher
                        ) {
                          this.fetchReportForParentAndAdmin(
                            selectedTitle,
                            selectedStudent,
                            table_id
                          );
                        }
                      }
                    }}
                    onInputChange={(inputValue) => {
                      if (inputValue) {
                        this.handleStudentSearch(inputValue);
                      }
                    }}
                    isClearable={true}
                  />
                </Form.Group>
              </div>
            )}
          </div>
        </div>
        {isTitleListLoading && (
          <PageSpinner id="show" text="Loading, Please wait!" />
        )}
        {isLoading && <PageSpinner id="show" text="Loading, Please wait!" />}
        {
          isTableDataLoading ? (
            <PageSpinner id="show" text="Loading, Please wait!" />
          ) : this.state.selectedStudent || (!isDropHidden && titleSelected) ? (
            <div style={{ overflow: "auto" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="thead"
                      style={{ textAlign: "center" }}
                    >
                      Module Code
                    </TableCell>
                    <TableCell
                      className="thead"
                      style={{ textAlign: "center" }}
                    >
                      Module Name
                    </TableCell>
                    <TableCell
                      className="thead"
                      style={{ textAlign: "center" }}
                    >
                      Marks%
                    </TableCell>
                    <TableCell
                      className="thead"
                      style={{ textAlign: "center" }}
                    >
                      Module Rank
                    </TableCell>
                    <TableCell
                      className="thead"
                      style={{ textAlign: "center" }}
                    >
                      Module Average marks
                    </TableCell>
                    <TableCell
                      className="thead"
                      style={{ textAlign: "center" }}
                    >
                      Teachers Remark
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData.map((rowData, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className="tData"
                        style={{ textAlign: "center" }}
                      >
                        {rowData.subject_code}
                      </TableCell>
                      <TableCell
                        className="tData"
                        style={{ textAlign: "center" }}
                      >
                        {rowData.subject_name}
                      </TableCell>
                      <TableCell
                        className="tData"
                        style={{ textAlign: "center" }}
                      >
                        {rowData.marks} %
                      </TableCell>
                      <TableCell
                        className="tData"
                        style={{ textAlign: "center" }}
                      >
                        {rowData.rank_within_group}
                      </TableCell>
                      <TableCell
                        className="tData"
                        style={{ textAlign: "center" }}
                      >
                        {rowData.sub_avg_marks}
                      </TableCell>
                      <TableCell
                        className="tData"
                        style={{ textAlign: "center" }}
                      >
                        {rowData.comment}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : null /* Render nothing if no student is selected */
        }

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={isModalOpen}
          onHide={this.toggleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Student Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalDataLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <Form>
                {tableData.length > 0 && (
                  <div key={tableData[0].id} className="mb-3">
                    <Form.Label>Overall Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={teacherRemark && teacherRemark[0]?.comment}
                      onChange={(e) => {
                        if (
                          teacherRemark &&
                          teacherRemark[0]?.comment !== null
                        ) {
                          this.handleOverallRemark(teacherRemark[0].id, e);
                        } else {
                          this.handleChange(e);
                        }
                      }}
                      disabled={
                        !reportCardPrivilege?.editVisibility &&
                        !reportCardPrivilege?.addVisibility
                      }
                    />

                    <Form.Label>Students Average</Form.Label>
                    <Form.Control
                      type="text"
                      value={tableData[0] && tableData[0].sub_avg_marks}
                      disabled={true}
                    />

                    <Form.Label>Students Rank</Form.Label>
                    <Form.Control
                      type="text"
                      value={tableData[0].student_rank}
                      disabled={true}
                    />

                    <Form.Label>Total no of students in the group</Form.Label>
                    <Form.Control
                      type="text"
                      value={tableData[0].stu_count}
                      disabled={true}
                    />
                    <br />
                    {(reportCardPrivilege?.editVisibility ||
                      reportCardPrivilege?.addVisibility) && (
                      <Button variant="primary" onClick={this.handleUpdate}>
                        Update
                      </Button>
                    )}
                  </div>
                )}
              </Form>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
