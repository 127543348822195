import {testConstants} from '../../helper/constants';

const initialState = {
    loading: false,
    finish_loading: false,
    error:'',
    finish_error: '',
    success: '',
    finish_success: '',
    text: '',
}

export default function(state = initialState, action){
    switch (action.type){
        case testConstants.SUBMIT_ANSWER:
            return {
                ...state,
                loading: true
            }
        case testConstants.SUBMIT_ANSWER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case testConstants.SUBMIT_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                error:"",
                success: 'Answer submitted successfully!'
            }
        case testConstants.SUBMIT_ANSWER_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                success:""
            }
        case testConstants.SUBMIT_ANSWERS_FOR_PAGE:
            return {
                ...state,
                loading: true
            }
        case testConstants.SUBMIT_ANSWERS_FOR_PAGE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case testConstants.SUBMIT_ANSWERS_FOR_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                error:"",
                success: 'Answer submitted successfully!'
            }
        case testConstants.SUBMIT_ANSWERS_FOR_PAGE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                success:""
            }
        case testConstants.FINISH_TEST:
            return {
                ...state,
                text: 'Finishing Test',
                finish_loading: true
            }
        case testConstants.FINISH_TEST_REQUEST:
            return {
                ...state,
                finish_loading: true
            }
        case testConstants.FINISH_TEST_SUCCESS:
            return {
                ...state,
                finish_loading: false,
                finish_success: 'Test submitted Successfully!'
            }
        case testConstants.FINISH_TEST_FAILURE:
            return {
                ...state,
                finish_error: action.error,
                finish_loading: false
            }
        default:
            return state
    }
}