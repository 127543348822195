import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const LevelService = {
    createLevel,
    getAllLevels,
    updateLevel,
    deleteLevel
};

async function createLevel(level) {
    const requestOptions = {
        method: "POST",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: level,
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/levels/add`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getAllLevels(node_id) {
    const requestOptions = {
        method: "GET",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/levels/getAllLevels/${node_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function updateLevel(level, level_id) {
    const requestOptions = {
        method: "PUT",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
        body: level,
    };
    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/levels/update/${level_id}`,
        requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function deleteLevel(level_id, form_list_id) {
    const configOptions = {
        method: "DELETE",
        headers: {
            ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
            "Content-Type": "application/json",
        },
    };

    const response = await fetch(
        `${config.oat_api_url}/manage/subscription/levels/delete/${level_id}/${form_list_id}`,
        configOptions
    );
    return APIHandler.handleResponse(response);

}