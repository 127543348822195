import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const AboutUsService = {
  getAboutInfo,
  updateAboutInfo,
  addAboutInfo
};

async function getAboutInfo(node_mid) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/about-us-details/getByNode/${node_mid}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateAboutInfo(about) {
  const node_mid = about.node_mid;
  const requestOptions = {
    method: "PUT",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(about),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/about-us-details/update/${node_mid}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function addAboutInfo(about) {
  const requestOptions = {
    method: "POST",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    body: JSON.stringify(about),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/about-us-details/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
