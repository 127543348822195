import React, { Component } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputAdornment from '@mui/material/InputAdornment';
import SimpleReactValidator from "simple-react-validator";
import { EmailTemplateService } from '../../../service/settings/manage/email.service.js';
import { Spinner } from 'react-bootstrap';
import { sweetConfirmAlert, sweetErrorAlert, sweetStatusAlert, sweetSuccessAlert } from '../../../components/common/SweetAlert';
import ContentWrapper from '../../../components/common/ContentWrapper';
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

class ManageEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: false,
      isLoading: false,
      addEmailModal: false,
      updateEmailModal: false,
      // email: {
      //   template_name: "",
      //   subject: "",
      //   html_content: "",
      // },
      updateEmail: {
        template_name: "",
        subject: "",
        html_content: "",
      },
      selectedEmail: null,
      emails: []
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getAllEmailTemplates();
  }

  getAllEmailTemplates = () => {
    this.setState({ isTableDataLoading: true });
    EmailTemplateService.getAllTemplates()
      .then((response) => {
        if (response.success) {
          this.setState({ emails: response.data, isTableDataLoading: false });
        } else {
          this.setState({ isTableDataLoading: false });
        }
      })
      .catch((error) => {
        console.error("Error while fetching email templates:", error);
        this.setState({ isTableDataLoading: false });
      });
  }

  toggleAddEmailModal = () => {
    const { addEmailModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessageFor("template_name");
    this.validator.hideMessageFor("subject");
    this.validator.hideMessageFor("html_content");
  
    if (addEmailModal) {
      this.setState({
        addEmailModal: !addEmailModal,
        updateEmail: {
          template_name: "",
          subject: "",
          html_content: "",
        },
      });
    } else {
      this.setState({
        addEmailModal: !addEmailModal,
      });
    }
  };
  

  toggleUpdateEmailModal = (template) => {
    const { updateEmailModal } = this.state;
    // this.validator.purgeFields();
    // this.validator.hideMessages();
  
    if (!updateEmailModal) {
      this.setState({
        updateEmail: {
          template_name: template.template_name || "",
          subject: template.subject || "",
          html_content: template.html_content || "",
        },
        selectedEmail: template.template_name, // Set the selected email
        updateEmailModal: true,
      });
    } else {
      this.setState({
        updateEmail: {
          template_name: "",
          subject: "",
          html_content: "",
        },
        selectedEmail: null, // Clear the selected email
        updateEmailModal: false,
      });
    }
  };
  



handleSubmit = () => {
  this.setState({ isLoading: true });

  const { updateEmail } = this.state; 

  // if (!this.validator.fieldValid("template_name") || !this.validator.fieldValid("subject") || !this.validator.fieldValid("html_content")) {
  //   this.validator.showMessages();
  //   this.forceUpdate();
  //   this.setState({ isLoading: false }); 
  // } else {
    const newEmail = {
      template_name: updateEmail.template_name,
      subject: updateEmail.subject,
      html_content: updateEmail.html_content,
    };

    EmailTemplateService.addTemplate(newEmail)
      .then((response) => {
        if (response.success) {
          sweetSuccessAlert("Success", "Email template added successfully", "success");
          this.setState({ isLoading: false });
          this.toggleAddEmailModal();
          this.getAllEmailTemplates();
        } else {
          this.setState({ isLoading: false });
          sweetStatusAlert("Info", response.message, "info");
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        sweetErrorAlert("Error", "Error while adding email template", "error");
      });
  // }
};

// Updated handleEditSubmit for email
handleEditSubmit = () => {
  const { updateEmail, selectedEmail } = this.state;

  this.setState({ isLoading: true });

  const editedEmail = {
    template_name: updateEmail.template_name,
    subject: updateEmail.subject,
    html_content: updateEmail.html_content,
  };

  EmailTemplateService.updateTemplate(editedEmail, selectedEmail)
  
    .then((response) => {
      if (response.success) {

        sweetSuccessAlert("Success", "Email template updated successfully", "success");

        // Update the state with the new data
        this.setState((prevState) => ({
          emails: prevState.emails.map((template) =>
          
            template.template_name === selectedEmail ? { ...template, ...editedEmail } : template
            
          ),
        
          isLoading: false,
        }));

        this.toggleUpdateEmailModal();
        this.getAllEmailTemplates();
      } else {
        this.setState({ isLoading: false });
        sweetStatusAlert("Oops", response.message, "info");
      }
    })
    .catch((error) => {
      this.setState({ isLoading: false });
      sweetErrorAlert("Error", "Error while updating email template", "error");
    });
};

  handleDeleteEmail = (templateName) => {
    sweetConfirmAlert()
      .then(() => {
        this.setState({ isTableDataLoading: true });
        EmailTemplateService.deleteTemplate(templateName)
          .then((response) => {
            sweetSuccessAlert("Success", "Email template deleted successfully", "success");
            this.getAllEmailTemplates();
          })
          .catch((error) => {
            sweetStatusAlert("Oops", "Error while deleting email template", "info");
            this.setState({ isTableDataLoading: false });
          });
      });
  }

  handleEmailChange = (event) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
      updateEmail: {
        ...prevState.updateEmail,
        [name]: value,
      },
      isModified: true,
    }));
  };
  


  render() {
    const {
      addEmailModal,
      updateEmailModal,
      isTableDataLoading,
      isLoading,
      emails,
      updateEmail
    } = this.state;

    return (
      <ContentWrapper>
        <div className="row justify-content-between align-items-center py-5 mb-3">
          <div className="col-md-6 col-6">
            <h3>Manage Email</h3>
          </div>
          <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleAddEmailModal}
          >
            Add Email Template
          </Button>
        </div>
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "75vw",
              height: "90vh"
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead">Template Name</TableCell>
                  <TableCell className="thead">Subject</TableCell>
                  <TableCell className="thead">HTML content</TableCell>
                  <TableCell className="thead">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {emails.length > 0 ? (
                  emails.map((template) => (
                    <TableRow key={template.template_name}>
                      <TableCell className="tData">
                        {template.template_name}
                      </TableCell>
                      <TableCell className="tData">{template.subject}</TableCell>
                      <TableCell className="tData">
                        {template.html_content}
                      </TableCell>
                      <TableCell className="tData" align="right">
                        <Button
                          className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                          title="Edit Details"
                          onClick={() => this.toggleUpdateEmailModal(template)}
                        />
                        {/* <IconButton aria-label="delete" color="error" onClick={() => this.handleDeleteEmailTemplate(template.template_name)}>
                            <DeleteIcon />
                        </IconButton> */}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <div style={{ height: "90vh" }}>No emailTemplates available</div>
                )}
              </TableBody>
            </Table>
          </div>
        )}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={addEmailModal}
          onHide={this.toggleAddEmailModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Email</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "50ch" }}
                      type="text"
                      name="template_name"
                      defaultValue={updateEmail.template_name}
                      onChange={this.handleEmailChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Template Name
                          </InputAdornment>
                        ),
                      }}
                    />

                    {this.validator.message(
                      "template_name",
                      updateEmail.template_name,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "50ch" }}
                      type="text"
                      name="subject"
                      defaultValue={updateEmail.subject}
                      onChange={this.handleEmailChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Subject
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.validator.message(
                      "subject",
                      updateEmail.subject,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "90ch" }}
                      type="text"
                      name="html_content"
                      defaultValue={updateEmail.html_content}
                      onChange={this.handleEmailChange}
                      multiline
                      rows={25}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       Html Template
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                    {this.validator.message(
                      "html_content",
                      updateEmail.html_content,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button variant="primary" style={ addBtnStyle } onClick={this.handleSubmit}>
                Save
              </Button>
              <Button
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleAddEmailModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateEmailModal}
          onHide={this.toggleUpdateEmailModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Email</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.state.isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "50ch" }}
                      type="text"
                      name="template_name"
                      defaultValue={updateEmail.template_name}
                      onChange={this.handleEmailChange}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            Template Name
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* {this.validator.message(
                      "template_name",
                      updateEmail.template_name,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )} */}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput2"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "50ch" }}
                      type="text"
                      name="subject"
                      // value={this.state.updateEmail.subject}
                      defaultValue={updateEmail.subject}
                      onChange={this.handleEmailChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Subject
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* {this.validator.message(
                      "subject",
                     updateEmail.subject,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )} */}
                  </Form.Group>
                </Row>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput3"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "90ch" }}
                      type="text"
                      name="html_content"
                      // value={this.state.updateEmail.html_content}
                      defaultValue={updateEmail.html_content}
                      onChange={this.handleEmailChange}
                      multiline
                      rows={25}
                      // InputProps={{
                      //   startAdornment: (
                      //     <InputAdornment position="start">
                      //       Html Template
                      //     </InputAdornment>
                      //   ),
                      // }}
                    />
                    {/* {this.validator.message(
                      "html_content",
                      updateEmail.html_content,
                      "required",
                      {
                        className: "text-danger",
                      }
                    )} */}
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button
                className="modal-btn"
                variant="primary"
                style={ addBtnStyle }
                onClick={() => this.handleEditSubmit()}
              >
                Save
              </Button>
              <Button
                className="modal-btn"
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleUpdateEmailModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </ContentWrapper>
    )
  }
}

export default ManageEmail;
