import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const StatusService = {
  create,
  update,
  getByTypeId,
  getAllStatusType,
  getStatusByPagination
};

async function create(status) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(status),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/statuses/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function update(status, status_id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(status),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/statuses/update/${status_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getByTypeId(typeId) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/statuses/type/${typeId}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllStatusType() {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/status-types/getAllStatusType`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getStatusByPagination(limit, offset, search_term) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/statuses/getAll/${limit}/${offset}?search_term=${search_term}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}
