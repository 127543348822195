import React, { useState } from "react";
import "../../../../pages/manage/dashboard/edfoci/dashboard.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TimeZoneHandler from "../../../../handlers/TimeZoneHandler.js";
import config from "../../../../helper/config.js";
import { customerConstants } from "../../../../helper/constants.js";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import "./CoverPage.css";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let iconColor;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)";
    break;
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)";
    break;
  default:
    iconColor = "var(--maincolor)";
    break;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AnnouncementCard = (props) => {
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  const handleOpen = (announcement) => {
    setSelectedAnnouncement(announcement);
  };

  const handleClose = () => {
    setSelectedAnnouncement(null);
  };

  const formattedDateString = (value) => {
    const dateObject = value ? new Date(value) : undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject && ("0" + (dateObject.getMonth() + 1)).slice(-2);
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const formattedString = `${day}-${month}-${year}`;
    return formattedString;
  };

  const formattedTimeString = (value) => {
    const time = new Date(value);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  const truncateText = (text, length) => {
    if (text.length <= length) return text;
    return text.substring(0, length) + "...";
  };

  const announcementBody = truncateText(
    props.announcement.announcement_body,
    40
  );

  return (
    <>
      <div
        className="row announcement__card"
        onClick={() => handleOpen(props.announcement)}
        style={{ cursor: "pointer" }}
      >
        <div className="pl-5">
          <h6 className="announcementTopic">
            {props.announcement.announcement_title}
          </h6>
          <span className="ann_body">
            <p>{announcementBody}</p>
          </span>
          <div className="ann_date">
            <div>
              {formattedDateString(
                TimeZoneHandler.convertTime(props.announcement?.created_at)
              )}{" "}
              &nbsp; &nbsp;{" "}
              {formattedTimeString(
                TimeZoneHandler.convertTime(props.announcement?.created_at)
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={!!selectedAnnouncement}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-container">
            <h6 className="announcementTopic">
              {selectedAnnouncement?.announcement_title}
            </h6>
            <span className="ann_body">
              {selectedAnnouncement?.announcement_body}
            </span>
            <div className="ann_date">
              {formattedDateString(
                TimeZoneHandler.convertTime(selectedAnnouncement?.created_at)
              )}{" "}
              &nbsp; &nbsp;{" "}
              {formattedTimeString(
                TimeZoneHandler.convertTime(selectedAnnouncement?.created_at)
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AnnouncementCard;
