import React, { Component } from 'react';
import {
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell
  } from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import SimpleReactValidator from "simple-react-validator";
import { Spinner } from 'react-bootstrap';
import { sweetConfirmAlert, sweetErrorAlert, sweetStatusAlert, sweetSuccessAlert } from '../../../components/common/SweetAlert';
import { candidateService } from '../../../service/settings/candidate/candidateService.js';
import ContentWrapper from '../../../components/common/ContentWrapper';
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

class ManageGradeScale extends Component {
  constructor(props) {
    super(props);
    this.createValidationsInstance();
    this.state = {
        isTableDataLoading: false,
        isLoading: false,
        addGradeScaleModal: false,
        updateGradeScaleModal:false,
        min_marks: "",
        max_marks: "",
        grade: "",
        remarks: "",
        scale_id: "",
        updateMinMarks: "",
        updateMaxMarks: "",
        updateGrade: "",
        updateRemarks: "",
        candidateGradeScales: [],
        limit: 10, 
        offset: 0, 
        search_term: "",
        sort_column: "scale_id",
        sort_direction: "ASC",
        totalCount: 10,
        currentPage: 0
    };
  }

  async componentDidMount(){
    await this.getAllCandidateGradeScales();
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator({
      validators: {
        customMin: {
            message: "The :attribute value must be greater than or equal to :min.",
            rule: (val, params) => {
            return parseInt(val, 10) >= params[0];
            },
            messageReplace: (message, params) => message.replace(':min', params[0]),
            required: true
        },
        customMax: {
            message: "The :attribute value must be less than or equal to :max.",
            rule: (val, params) => {
                return parseInt(val, 10) <= params[0];
            },
            messageReplace: (message, params) => message.replace(':max', params[0]),
            required: true
        }
      }
    });
  }

  getAllCandidateGradeScales = async() => {
    const { 
        limit, 
        offset, 
        sort_column, 
        sort_direction, 
        search_term
    } = this.state;
    this.setState({ isTableDataLoading: true });
    await candidateService.getAllCandidateGradeScales(limit, offset, sort_column, sort_direction, search_term)
        .then((response)=>{
            if(response.success){
                this.setState({ 
                    candidateGradeScales: response.data.candidate_grade_scales,
                    totalCount: parseInt(response.data.total_count), 
                    isTableDataLoading: false 
                });
            }
            else{
                this.setState({ isTableDataLoading: false });
            }
            
        })
        .catch(()=>{
          this.setState({ isTableDataLoading: false });
        })
  }

  handleSortChange = (sort_column) => {
    // If the same column is clicked, toggle the sort direction
    const { sort_column: currentSortColumn, sort_direction } = this.state;
    const newSortDirection =
      currentSortColumn === sort_column && sort_direction === "ASC"
        ? "DESC"
        : "ASC";

    this.setState(
      {
        sort_column,
        sort_direction: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
       this.getAllCandidateGradeScales();
      }
    );
  };

   // Handle the data fetching as per the offset(page no) changes
   handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllCandidateGradeScales();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.getAllCandidateGradeScales();
      }
    );
  };

  // handle the data fetching as per the data per page limit gets changess
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.getAllCandidateGradeScales();
      }
    );
  };

  // handle search text as per the regex validation and search_term , and fetch data as per the debounce
  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  toggleAddCandidateGradeScaleModal = () => {
    const { addGradeScaleModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessages();
    this.setState({
        addGradeScaleModal: !addGradeScaleModal,
        min_marks: "",
        max_marks: "",
        grade: "",
        remarks: ""
    });
  };

  toggleUpdateCandidateGradeScaleModal = (scale_id, min_marks, max_marks, grade, remarks) => {
    const { updateGradeScaleModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessages();
    if(!updateGradeScaleModal){
        this.setState({
            scale_id: scale_id,
            updateMinMarks: min_marks,
            updateMaxMarks: max_marks,
            updateGrade: grade,
            updateRemarks: remarks,
            updateGradeScaleModal: true
        });
    }
    else{
        this.setState({
            scale_id: null,
            updateMinMarks: null,
            updateMaxMarks: null,
            updateGrade: null,
            updateRemarks: null,
            updateGradeScaleModal: false
        });
    }
  };

  handleSubmit = async(event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    } else {
      const { min_marks, max_marks, grade, remarks } = this.state;
      this.setState({ isLoading:true });
      const newGradeScale = {
        min_marks: parseInt(min_marks, 10),
        max_marks: parseInt(max_marks, 10),
        grade: grade,
        remarks: remarks
      }
      await candidateService.createCandidateGradeScale(newGradeScale)
        .then((response)=>{
            if(response.success){
                sweetSuccessAlert("Success", "Student grade scale added successfully!", "success");
                this.setState({ isLoading: false, candidateGradeScales:[...this.state.candidateGradeScales, response.data] });
                this.toggleAddCandidateGradeScaleModal();
            }
            else{
                this.setState({ isLoading: false });
                sweetStatusAlert("Cannot add grade scale!", response.message, "error");
            }
        })
        .catch((error)=>{
            this.setState({ isLoading: false });
            sweetErrorAlert("Error", "Error while adding student grade scale!", "error");
        })
    }
  };

  handleEditSubmit = async(event) =>{
    event.preventDefault();
    if(this.validator.allValid()){
        this.validator.showMessages();
        this.forceUpdate();
    }
    else{
        const {scale_id, updateMinMarks, updateMaxMarks, updateGrade, updateRemarks} = this.state;
        this.setState({ isLoading: true });
        const newUpdateGradeScale = {
            scale_id: parseInt(scale_id, 10),
            min_marks: parseInt(updateMinMarks, 10),
            max_marks: parseInt(updateMaxMarks, 10),
            grade: updateGrade,
            remarks: updateRemarks
        }
        await candidateService.updateCandidateGradeScale(newUpdateGradeScale)
            .then((response)=>{
                if(response.success){
                    sweetSuccessAlert("Success", "Student grade scale updated successfully!", "success");
                    this.setState({ isLoading: false });
                    this.toggleUpdateCandidateGradeScaleModal();
                    this.getAllCandidateGradeScales();
                }
                else{
                    this.setState({ isLoading: false });
                    sweetStatusAlert("Cannot update grade scale!", response.message, "error");
                }
                
            })
            .catch((error)=>{
                this.setState({ isLoading: false });
                sweetErrorAlert("Error", "Error while updating candidate grade scale!", "error");
            })
    }
  }

  handleDeleteGradeScale = (scale)=>{
    sweetConfirmAlert()
        .then(async()=>{
            this.setState({ isTableDataLoading: true });
            await candidateService.deleteCandidateGradeScale(scale)
                .then((response)=>{
                  if(response.success){
                    sweetSuccessAlert("Success", "Student grade scale deleted successfully!", "success");
                    this.getAllCandidateGradeScales();
                  }
                  else{
                    sweetStatusAlert("Cannot delete grade scale!", response.message, "error");
                    this.setState({ isTableDataLoading: false });
                  }
                    
                })
                .catch((error)=>{
                    sweetStatusAlert("Error", "Error while deleting node title group", "error");
                    this.setState({ isTableDataLoading: false });
                })
        })
  };

  handleChangeMinMarks = (event) => {
    event.preventDefault();
    this.setState({ min_marks: event.target.value });
  };

  handleChangeMaxMarks = (event) => {
    event.preventDefault();
    this.setState({ max_marks: event.target.value });
  };

  handleChangeGrade = (event) => {
    event.preventDefault();
    this.setState({ grade: event.target.value });
  };

  handleChangeRemarks = (event) => {
    event.preventDefault();
    this.setState({ remarks: event.target.value });
  };

  handleEditChangeMinMarks = (event) => {
    event.preventDefault();
    this.setState({ updateMinMarks: event.target.value });
  };

  handleEditChangeMaxMarks = (event) => {
    event.preventDefault();
    this.setState({ updateMaxMarks: event.target.value });
  };

  handleEditChangeGrade = (event) => {
    event.preventDefault();
    this.setState({ updateGrade: event.target.value });
  };

  handleEditChangeRemarks = (event) => {
    event.preventDefault();
    this.setState({ updateRemarks: event.target.value });
  };

  render() {
    const { 
        addGradeScaleModal, 
        min_marks,
        max_marks,
        grade,
        remarks,
        updateGradeScaleModal,
        isTableDataLoading,
        isLoading,
        candidateGradeScales,
        updateMinMarks,
        updateMaxMarks,
        updateGrade,
        updateRemarks,
        limit,
        totalCount,
        currentPage
    } = this.state;

    this.validator.purgeFields();

    return (
      <ContentWrapper>
        <div className="row justify-content-between align-items-center py-5 mb-3">
          <div className='col-md-6 col-6'>
            <h3 className='text-nowrap'>Manage Marks Grade Scale</h3>
          </div>
          <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleAddCandidateGradeScaleModal}
          >
            Add Grade Scale
          </Button>
        </div>
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "75vw",
              height: "90vh"
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                    <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("min_marks")}
                    >
                        Minimum Marks{" "}
                        {this.state.sort_column === "min_marks" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                    </TableCell>
                    <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("max_marks")}
                    >
                        Maximum Marks{" "}
                        {this.state.sort_column === "max_marks" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                    </TableCell>
                    <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("grade")}
                    >
                        Grade{" "}
                        {this.state.sort_column === "grade" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                    </TableCell>
                    <TableCell
                        className="thead"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.handleSortChange("remarks")}
                    >
                        Grading Marks{" "}
                        {this.state.sort_column === "remarks" && (
                        <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                        )}
                    </TableCell>
                    {/* <TableCell className="thead">Minimum Marks</TableCell>
                    <TableCell className="thead">Maximum Marks</TableCell>
                    <TableCell className="thead">Grade</TableCell>
                    <TableCell className="thead">Grading Remarks</TableCell> */}
                    <TableCell className="thead d-flex justify-content-end">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {candidateGradeScales.length > 0 ? (
                  candidateGradeScales.map((scale) => (
                    <TableRow key={scale.scale_id}>
                      <TableCell className="tData">
                        {scale.min_marks}
                      </TableCell>
                      <TableCell className="tData">
                        {scale.max_marks}
                      </TableCell>
                      <TableCell className="tData">
                        {scale.grade}
                      </TableCell>
                      <TableCell className="tData">
                        {scale.remarks}
                      </TableCell>
                      <TableCell className="tData" align="right">
                        <Button
                          className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                          title="Edit Details"
                          color="primary"
                          onClick={() => this.toggleUpdateCandidateGradeScaleModal(scale.scale_id, scale.min_marks, scale.max_marks, scale.grade, scale.remarks)}
                        />
                        <Button
                          className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                          title="Delete"
                          onClick={() => this.handleDeleteGradeScale(scale)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <div style={{ height: "90vh" }}>No grade scales available!</div>
                )}
              </TableBody>
            </Table>
            <MaterialCustomTablePagination
                totalCount = {totalCount}
                currentPage = {currentPage}
                limit = {limit}
                handlePageChange = {this.handlePageChange}
                handleRowsPerPageChange = {this.handleRowsPerPageChange}
                handlePageOffsetChange = {this.handlePageOffsetChange}
                offset = {this.state.offset}
                rowsPerPageOptions={[5, 10, 20]}
            />
          </div>
        )}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={addGradeScaleModal}
          onHide={this.toggleAddCandidateGradeScaleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Grade Scale</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Minimum Marks</Form.Label>
                    <Form.Control
                      type="number"
                      name="min_marks"
                      value={this.state.min_marks}
                      placeholder="Enter minimum marks"
                      onChange={this.handleChangeMinMarks}
                    />
                    {this.validator.message(
                        "min_marks",
                        this.state.min_marks,
                        "required|integer|customMin:0|customMax:100",
                        {
                            className: "text-danger",
                            messages: {
                                required: "The minimum marks field is required.",
                                integer: "The minimum marks must be a valid integer.",
                            },
                        }
                    )}
                    <Form.Label>Maximum Marks</Form.Label>
                    <Form.Control
                      type="number"
                      name="max_marks"
                      value={this.state.max_marks}
                      placeholder="Enter maximum marks"
                      onChange={this.handleChangeMaxMarks}
                    />
                    {this.validator.message(
                        "max_marks",
                        this.state.max_marks,
                        "required|integer|customMin:0|customMax:100",
                        {
                            className: "text-danger",
                            messages: {
                                required: "The maximum marks field is required.",
                                integer: "The maximum marks must be a valid integer.",
                            },
                        }
                    )}
                    <Form.Label>Grade</Form.Label>
                    <Form.Control
                      type="text"
                      name="grade"
                      value={this.state.grade}
                      placeholder="Enter grade"
                      onChange={this.handleChangeGrade}
                    />
                    {this.validator.message(
                        "grade",
                        this.state.grade,
                        "required",
                        {
                            className: "text-danger",
                            messages: {
                                required: "The grade field is required."
                            },
                        }
                    )}
                    <Form.Label>Grading Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      name="remarks"
                      value={this.state.remarks}
                      placeholder="Enter grading remarks"
                      onChange={this.handleChangeRemarks}
                    />
                    {this.validator.message(
                        "remarks",
                        this.state.remarks,
                        "required",
                        {
                            className: "text-danger",
                            messages: {
                                required: "The remarks is required."
                            },
                        }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}
            <Modal.Footer>
              <Button variant="primary" style={ addBtnStyle } onClick={this.handleSubmit}>
                Save
              </Button>
              <Button
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleAddCandidateGradeScaleModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateGradeScaleModal}
          onHide={this.toggleUpdateCandidateGradeScaleModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Grade Scale</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Minimum Marks</Form.Label>
                    <Form.Control
                      type="number"
                      name="updateMinMarks"
                      value={updateMinMarks}
                      placeholder="Enter Minimum Marks"
                      onChange={this.handleEditChangeMinMarks}
                    />
                    {this.validator.message(
                        "updateMinMarks",
                        this.state.updateMinMarks,
                        "required|integer|customMin:0|customMax:100",
                        {
                            className: "text-danger",
                            messages: {
                                required: "The minimum marks field is required.",
                                integer: "The minimum marks must be a valid integer.",
                            },
                        }
                    )}
                    <Form.Label>Maximum Marks</Form.Label>
                    <Form.Control
                      type="number"
                      name="updateMaxMarks"
                      value={updateMaxMarks}
                      placeholder="Enter Maximum Marks"
                      onChange={this.handleEditChangeMaxMarks}
                    />
                    {this.validator.message(
                        "updateMaxMarks",
                        this.state.updateMaxMarks,
                        "required|integer|customMin:0|customMax:100",
                        {
                            className: "text-danger",
                            messages: {
                                required: "The maximum marks field is required.",
                                integer: "The maximum marks must be a valid integer.",
                            },
                        }
                    )}
                    <Form.Label>Grade</Form.Label>
                    <Form.Control
                      type="text"
                      name="Grade"
                      value={updateGrade}
                      placeholder="Enter Grade"
                      onChange={this.handleEditChangeGrade}
                    />
                    {this.validator.message(
                      "updateGrade",
                      updateGrade,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                            required: "The grade field is required."
                        },
                      }
                    )}
                    <Form.Label>Grading Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      name="updateRemarks"
                      value={updateRemarks}
                      placeholder="Enter Remarks"
                      onChange={this.handleEditChangeRemarks}
                    />
                    {this.validator.message(
                      "updateRemarks",
                      updateMinMarks,
                      "required",
                      {
                        className: "text-danger",
                        messages: {
                            required: "The remarks field is required."
                        },
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}
            <Modal.Footer>
              <Button variant="primary" style={ addBtnStyle } onClick={this.handleEditSubmit}>
                Save
              </Button>
              <Button
                id="modal-close-button"
                style={ closeBtnStyle }
                sx={{ mr: "20px" }}
                onClick={this.toggleUpdateCandidateGradeScaleModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </ContentWrapper>
    )
  }
}

export default ManageGradeScale;
