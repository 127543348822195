import React, { Component } from "react";
import {
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import Spinner from "../../../components/Spinner2.js";
import { Modal, Button, Form, Table } from "react-bootstrap";
import CardHeader from "../../../components/common/CardHeader.js";
import CardBody from "../../../components/common/CardBody.js";
import { FeedbackService } from "../../../service/manage/feedback/feedback.service.js";
import "react-big-calendar/lib/css/react-big-calendar.css";
import config from '../../../helper/config.js';
import {customerConstants} from "../../../helper/constants.js";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Tooltip from '@mui/material/Tooltip';
import {
  sweetConfirmAlert,
  sweetSuccessAlert,
  sweetStatusAlert
} from "../../../components/common/SweetAlert.js";
import TimeZoneHandler from "../../../handlers/TimeZoneHandler.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

class FeedbackForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedbackText: "",
      rating: null,
      feedbackList: [],
      showModal: false,
      showDetailsModal: false,
      details: [],
      showAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "",
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      deleteConfirmation: null,
      feedbackSortColumn: "created_on",
      feedbackSortDirection: "desc",
      isLoading: false,
      limit: 10,
      offset: 0,
      search_term: "",
      totalFeedbacks: 10,
      currentPage: 0,
      breadcrumb2: null,
      nodeTitle: null,
    };
  }

  componentDidMount() {
    this.loadFeedback();
    const { privilege_add, privilege_delete } = this.props.state;
    this.getVisibilityCheck(privilege_add, privilege_delete);
    this.setState({
      breadcrumb2: this.props.state.breadcrumb2,
      nodeTitle: this.props.state.nodeTitle,
    });
  }

  getVisibilityCheck(privilege_add, privilege_delete) {
    if (privilege_add == "1") {
      this.setState({
        addVisibility: true,
      });
    }
    if (privilege_delete == "1") {
      this.setState({
        deleteVisibility: true,
      });
    }
  }

  loadFeedback = async () => {
    try {
      this.setState({ isLoading: true });

      const {
        feedbackSortColumn,
        feedbackSortDirection,
        limit,
        offset,
        search_term,
      } = this.state;

      const session_stu_id = sessionStorage.getItem("manage_user_id");

      const {mid, owner_restriction, lg_user_id, lg_user_table_id,  user_list_table_id, user_list_restriction} = this.props.state

      let create_user_id = (!!owner_restriction && lg_user_id) ? lg_user_id :null
      let create_user_table_id = (!!owner_restriction && lg_user_table_id) ? lg_user_table_id : null
      let user_table_id = (!!user_list_restriction && lg_user_table_id) ? lg_user_table_id : null
      let stu_id = (!!user_list_restriction&& session_stu_id) ? session_stu_id : null

      const response =
        await FeedbackService.getAllFeedbackWithPaginationAndSorting( 
          mid,
          limit,
          offset,
          feedbackSortColumn,
          feedbackSortDirection,
          search_term,
          create_user_id,
          create_user_table_id,
          user_table_id,
          stu_id
        );

      if (response.success) {
        const { feedbacks, count } = response.data;

        this.setState({
          feedbackList: feedbacks,
          totalFeedbacks: count,
        });
      } else {
        console.error("Failed to load feedback:", response.message);
      }
    } catch (error) {
      console.error("Error loading feedback:", error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleFeedbackSortChange = (sortColumn) => {
    const {
      feedbackSortColumn: currentFeedbackSortColumn,
      feedbackSortDirection,
    } = this.state;
    const newFeedbackSortDirection =
      currentFeedbackSortColumn === sortColumn &&
      feedbackSortDirection === "asc"
        ? "desc"
        : "asc";

    this.setState(
      {
        feedbackSortColumn: sortColumn,
        feedbackSortDirection: newFeedbackSortDirection,
        isLoading: true,
        offset: 0,
      },
      () => {
        this.loadFeedback();
      }
    );
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleRatingChange = (newRating) => {
    this.setState({ rating: newRating });
  };

  
  formattedDateString = (value) =>{
    const dateObject = value ? new Date(value): undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject &&  ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString =  `${day}-${month}-${year}`
    return fromattedString;
  }
  formattedTimeString = (value) =>{
    const time = new Date(value);    
    const formattedTime = time.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    return formattedTime
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      showModal: false,
    });

    const { feedbackText, rating } = this.state;

    if (!feedbackText) {
      sweetStatusAlert("Error", "Your Feedback field is required", "error")
      return;
    }

    const maxFeedbackLength = 1024;
    if (feedbackText.length > maxFeedbackLength) {
      sweetStatusAlert("Error", "Your feedback text should not exceed 1024 characters. Please check it.", "error")
      return;
    }

    if (rating === null) {
      sweetStatusAlert("Error", "Rating field is required", "error")
      return;
    }

    try {
      const response = await FeedbackService.addFeedback({
        node_mid: !!this.props.state.mid ? this.props.state.mid : null,
        feedback_text: feedbackText,
        rating: parseInt(rating),
        create_user_id: !!this.props.state.lg_user_id
          ? this.props.state.lg_user_id
          : null,
        create_user_table_id: !!this.props.state.lg_user_table_id
          ? this.props.state.lg_user_table_id
          : null,
      });

      if (response.success) {
        sweetSuccessAlert(
          "Success",
          "Feedback submitted successfully!",
          "success"
        )
        // Reload feedback after submission
        this.loadFeedback();
        // Reset the form after submission if needed
      } else {
        sweetStatusAlert(
          "Error",
          `Failed to submit feedback: ${response.message}`,
          "error"
        );
      }
      this.setState({
        feedbackText: "",
        rating: null
      });
    } catch (error) {
      this.setState({
        feedbackText: "",
        rating: null
      });
      sweetStatusAlert(
        "Error",
        `Error submitting feedback: ${error.message}`,
        "error"
      );
    }
  };

  handlePageChange = (event, newPage) => {
    const { limit } = this.state;
    const newOffset = newPage * limit;
    this.setState(
      {
        offset: newOffset,
        currentPage: newPage,
        isLoading: true,
      },
      () => {
        this.loadFeedback();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        offset: newOffset,
        isLoading: true,
        currentPage: newPage
      },
      () => {
        this.loadFeedback();
      }
    );
  };

  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        currentPage: newPage,
        isLoading: true,
      },
      () => {
        this.loadFeedback();
      }
    );
  };
  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    this.setState(
      {
        search_term,
        isLoading: false,
        offset: 0,
        currentPage: 0,
      },
      () => {
        this.loadFeedback();
      }
    );
  };

  handleDelete = (feedbackId) => {
    // Set delete confirmation state
    sweetConfirmAlert().then((response) => {
      this.setState({
        deleteConfirmation: {
          feedbackId: feedbackId,
          // show: true,
        },
      }, () => {
        this.confirmDeleteFeedback()
      });      
    }); 
  };

  confirmDeleteFeedback = async () => {
    const { deleteConfirmation } = this.state;
    if (deleteConfirmation && deleteConfirmation.feedbackId) {
      try {
        const response = await FeedbackService.deleteFeedback(
          deleteConfirmation.feedbackId
        );

        if (response.success) {
          // Reload feedback after deletion
          this.loadFeedback();
          sweetSuccessAlert(
            "Success",
            "Feedback deleted successfully!",
            "success"
          );
          this.setState({
            deleteConfirmation: null,
          });
        } else {
          sweetStatusAlert(
            "Error",
            `Failed to delete feedback: ${response.message}`,
            "error"
          );
        }
      } catch (error) {
        sweetStatusAlert(
          "Error",
          `Error deleting feedback: ${error.message}`,
          "error"
        );
      }
    }
  };

  showAlert = (title, message, type) => {
    this.setState({
      showAlert: true,
      alertTitle: title,
      alertMessage: message,
      alertType: type,
    });
  };

  hideAlert = () => {
    this.setState({
      showAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "",
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      feedbackText: "",
      rating: null,
    });
  };

  truncateString = (str, maxLength)  =>{
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
    } else {
        return str;
    }
  }
  starCounter = ( count ) => {
    // Array to store stars
    const stars = [];
    // Generate stars based on count
    for (let i = 0; i < count; i++) {
        // Add stars to the array
        stars.push(<span
          key={i}
          size="large"
          style={{
            color: "gold",
            alignItems: "center",
            fontSize: "20px"
          }}
        >
          &#9733;
        </span>);
    }
    // Return the array of stars
    return <div>{stars}</div>;
}



  render() {
    const {
      feedbackText,
      rating,
      feedbackList,
      showModal,
      showDetailsModal,
      showAlert,
      alertTitle,
      alertMessage,
      alertType,
      addVisibility,
      deleteConfirmation,
      deleteVisibility,
      isLoading,
      currentPage,
      totalFeedbacks,
      limit,
      breadcrumb2,
      nodeTitle,
      offset
    } = this.state;
    const formattedBreadcrumb = breadcrumb2? breadcrumb2.replaceAll(">", " > ") : ""

    return (
      <>
        <div style={{ overflow: "auto", height: "90vh" }}>
        <>
            <CardHeader>
              {(this.props.state.path !== 'node' ) &&
              <p className="py-4 pl-4" style={{ fontSize: "10.5625px", color: "#00000099" }}>
              {(formattedBreadcrumb) ? `${formattedBreadcrumb &&formattedBreadcrumb}`: ""}
              </p>}
              <div className="col-md-12 px-0 d-flex justify-content-between">

                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  className="mb-2 col-md-4"
                  //style={{ marginleft: '280px'}}
                >
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={this.handleSearch}
                    isInvalid={this.state.searchError}
                    // style={{ width: "300px" }}
                    className="search-bar"
                  />
                  {this.state.searchError && (
                    <Form.Control.Feedback type="invalid">
                      Invalid input
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <div className="col-md-4 col-5 px-0 d-flex justify-content-end">
                  {addVisibility && (
                    <Button
                      style={{ height: "38px" }}
                      className="insert-btn"
                      variant="primary"
                      onClick={() => this.setState({ showModal: true })}
                    >
                      Add Feedback
                    </Button>
                  )}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div>
                {/* {addVisibility && (
                    <Button
                      variant="primary"
                      onClick={() => this.setState({ showModal: true })}
                    >
                      Add Feedback
                    </Button>
                  )} */}

                {/* Feedback Modal */}
                {/* <Modal
                    show={showModal}
                    onHide={this.handleCloseModal}
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Add Feedback</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group controlId="formFeedbackText">
                          <Form.Label>Your Feedback</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter feedback text"
                            name="feedbackText"
                            value={feedbackText}
                            onChange={this.handleInputChange}
                          />
                        </Form.Group>
                        <br></br>
                        <Form.Group controlId="formRating">
                          <Form.Label>Rating</Form.Label>
                          <div style={{ fontSize: "20px" }}>
                            {[1, 2, 3, 4, 5].map((star) => (
                              <span
                                key={star}
                                onClick={() => this.handleRatingChange(star)}
                                style={{
                                  cursor: "pointer",
                                  color: star <= rating ? "gold" : "gray",
                                }}
                              >
                                &#9733;
                              </span>
                            ))}
                          </div>
                          <Stack spacing={1}>
    <Rating name="size-large" defaultValue={2} size="large" style={{ fontSize: '4em' , alignItems: 'center'}} />
  </Stack>
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          this.handleCloseModal();
                          this.setState({ showModal: false });
                        }}
                      >
                        Close
                      </Button>
                      <Button variant="primary" onClick={this.handleSubmit}>
                        Add Feedback
                      </Button>
                    </Modal.Footer>
                  </Modal> */}

                {/* <hr />
                  <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleSearch}
                          className="col-md-3 col-12"
                        />
                      </Form.Group> */}
                {/* <h4>Feedback List</h4> */}
                {isLoading ? (
                  <Spinner />
                ) : (
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {!this.props.state?.owner_restriction && 
                          <>
                            <TableCell className="thead">User Code</TableCell>
                            <TableCell className="thead">Name</TableCell>
                          </>
                          }
                          <TableCell
                            style={{ cursor: "pointer" }}
                            className="thead"
                            onClick={() =>
                              this.handleFeedbackSortChange("created_on")
                            }
                          >
                            Date & Time
                            {this.state.feedbackSortColumn === "created_on" && (
                              <span>
                                {this.state.feedbackSortDirection === "asc"
                                  ? <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </TableCell>
                          <TableCell className="thead">Feedback</TableCell>
                          <TableCell className="thead"  style={{ widows: "50%" }}>Rating</TableCell>
                          {/* <TableCell
              className="thead"
              style={{ cursor: "pointer" }}
          >
              Student Details{" "}
          </TableCell> */}
                          {!!this.props.state.privilege_delete && <TableCell className="thead">Actions</TableCell>}

                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {feedbackList.map((feedback) => (
                          <TableRow key={feedback.feedback_id}>
                            {!this.props.state?.owner_restriction && <TableCell className="tData">{feedback.user_code}</TableCell>}
                           {!this.props.state?.owner_restriction &&  <TableCell>{feedback.first_name + ' ' + feedback.last_name}</TableCell>}
                            <TableCell className="tData text-nowrap">
                              <div>{this.formattedDateString(TimeZoneHandler.convertTime(feedback.created_on))}</div>
                              <div>{this.formattedTimeString(TimeZoneHandler.convertTime(feedback.created_on))}</div>
                              {/* {enrollment.data_concat} */}
                            </TableCell>
                            { 
                              feedback.feedback_text === this.truncateString(feedback.feedback_text, 50) ? 
                              <TableCell style={{ width: "40%" }}>{feedback.feedback_text}</TableCell>:
                              <TableCell style={{ width: "40%" }}>
                                <Tooltip title={feedback.feedback_text}>
                                  <span> {this.truncateString(feedback.feedback_text, 50) }</span>
                                </Tooltip>
                              </TableCell>
                            }
                            <TableCell>
                              {feedback.rating && this.starCounter(feedback.rating)}
                            </TableCell>
                            {!!this.props.state?.privilege_delete && <TableCell>
                              <Button
                                className="btn btn-icon btn-danger btn-sm m-1 flaticon-delete"
                                title="Delete Feedback"
                                onClick={() =>
                                  this.handleDelete(feedback.feedback_id)
                                }
                              />
                            </TableCell>}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {/* <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th
                            style={{ cursor: "pointer" }}
                            className="thead"
                            onClick={() =>
                              this.handleFeedbackSortChange("created_on")
                            }
                          >
                            Created On
                            {this.state.feedbackSortColumn ===
                              "created_on" && (
                              <span>
                                {this.state.feedbackSortDirection === "asc"
                                  ? <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            className="thead"
                            onClick={() =>
                              this.handleFeedbackSortChange("feedback_text")
                            }
                          >
                            Feedback Text
                            {this.state.feedbackSortColumn ===
                              "feedback_text" && (
                              <span>
                                {this.state.feedbackSortDirection === "asc"
                                  ? <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </th>
                          <th
                            style={{ cursor: "pointer" }}
                            className="thead"
                            onClick={() =>
                              this.handleFeedbackSortChange("rating")
                            }
                          >
                            Rating
                            {this.state.feedbackSortColumn === "rating" && (
                              <span>
                                {this.state.feedbackSortDirection === "asc"
                                  ? <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                                  : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                              </span>
                            )}
                          </th>
                          {addVisibility && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {feedbackList.map((feedback) => (
                          <tr key={feedback.feedback_id}>
                            <td>{feedback.feedback_id}</td>
                            <td>
                              {moment
                                .utc(feedback.created_on)
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td>{feedback.feedback_text}</td>
                            <td>{feedback.rating} out of 5 stars</td>
                            <td>
                              {addVisibility && (
                                <Button
                                  variant="danger"
                                  onClick={() =>
                                    this.handleDelete(feedback.feedback_id)
                                  }
                                >
                                  Delete
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table> */}
                    <MaterialCustomTablePagination
                      totalCount = {parseInt(totalFeedbacks)}
                      currentPage = {currentPage}
                      limit = {limit}
                      handlePageChange = {this.handlePageChange}
                      handleRowsPerPageChange = {this.handleRowsPerPageChange}
                      handlePageOffsetChange = {this.handlePageOffsetChange}
                      rowsPerPageOptions={[5, 10, 20]}
                      offset = {offset}
                    />
                  </div>
                )}                
              </div>
            </CardBody>
          </>
        </div>
        {/* Feedback Modal */}
        <Modal show={showModal} onHide={this.handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFeedbackText">
                <Form.Label>Your Feedback</Form.Label>                
                <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"            
                    placeholder="Enter Feedback"
                    rows="3"
                    name="feedbackText"
                    value={feedbackText}
                    onChange={this.handleInputChange}
                  ></textarea>
              </Form.Group>
              <br></br>
              <Form.Group controlId="formRating">
                <Form.Label>Rating</Form.Label>
                {/* <Stack spacing={1}>
        <Rating name="size-large" defaultValue={2} size="large" style={{ fontSize: '2.5em' , alignItems: 'center'}} />
             </Stack> */}

                <div style={{ fontSize: "25px" }}>
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      onClick={() => this.handleRatingChange(star)}
                      size="large"
                      style={{
                        cursor: "pointer",
                        color: star <= rating ? "gold" : "gray",
                        // fontSize: "2.5em",
                        alignItems: "center",
                      }}
                    >
                      &#9733;
                    </span>
                  ))}
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="insert-btn"
              variant="primary"
              style={ addBtnStyle }
              onClick={this.handleSubmit}
            >
              Add Feedback
            </Button>
            <Button
              id="modal-close-button"
              style={ closeBtnStyle }
              onClick={() => {
                this.handleCloseModal();
                this.setState({ showModal: false });
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default FeedbackForm;
