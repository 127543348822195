import {submissionTypes} from '../types';

const initialState = {
    question_id: '',
    loading: false,
    success: '',
    error: '',
    text: '',
    media: null
}

export function submissionQuestionReducer(state = initialState, action){
    switch (action.type){
        case submissionTypes.SUBMISSION_PAGE_REFRESH:
            return {
                ...state,
                question_id: '',
                loading: false,
                success: '',
                error: '',
                text: '',
                media: null
            }
        case submissionTypes.CREATE_SUBMISSION_QUESTION:
            return {
                ...state,
                loading: true,
                text: 'Creating Question'
            }
        case submissionTypes.CREATE_SUBMISSION_QUESTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case submissionTypes.CREATE_SUBMISSION_QUESTION_SUCCESS:
            return {
                ...state,
                question_id: action.question.question_id,
                loading: false,
                success: true
            }
        case submissionTypes.CREATE_SUBMISSION_QUESTION_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case submissionTypes.ADD_ANSWER:
            return {
                ...state,
                loading: false,
                text: 'Add answer',
                error: '',
                success: ''

            }
        case submissionTypes.ADD_ANSWER_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Adding Answer'
            }
        case submissionTypes.ADD_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Answer added successfully'
            }
        case submissionTypes.ADD_ANSWER_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case submissionTypes.GET_MEDIA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case submissionTypes.GET_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                media: action.media,
                success: true
            }
        case submissionTypes.GET_MEDIA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        default:
            return state
    }
}
