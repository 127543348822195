import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const createService = {
    createCategory: createCategory,
    getQuestionTypes: getQuestionTypes,
    getQuestionCategories: getQuestionCategories,
    getAllQuestionCategories: getAllQuestionCategories,
    getQuestionDifficulty: getQuestionDifficulty,
    getQuestionLanguage: getQuestionLanguage,
    getQuestionSource: getQuestionSource,
    getQuestionSubject: getQuestionSubject,
    getQuestionAuthor: getQuestionAuthor,
    getQuestionYear: getQuestionYear,
    getQuestionTerm: getQuestionTerm,
    getQuestionGrade: getQuestionGrade,
    getQuestionChapter: getQuestionChapter,
    getQuestionById:getQuestionById,
    getAllQuestionMetaData:getAllQuestionMetaData
};


async function getQuestionTypes() {

    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/question/types/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionDifficulty(){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/difficulties/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionById(id){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/questions/getById/${id}`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionGrade(){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/grades/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionChapter(){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/chapters/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionLanguage(){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/languages/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionSource(){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/question/sources/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionSubject(){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/subjects/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionAuthor(){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/authors/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionYear(){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/years/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionTerm(){
    
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/manage/terms/getAll/`, requestOptions);
    return APIHandler.handleResponse(response);
}

async function getQuestionCategories() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/question/categories/getAll/`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllQuestionCategories(limit, offset, sort_column, sort_direction, search_term) {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
 
    return await fetch(`${config.oat_api_url}/question/categories/getAllCategories/?limit=${limit}&offset=${offset}&sort_column=${sort_column}&sort_direction=${sort_direction}&search_term=${search_term}`, requestOptions).then(APIHandler.handleResponse);
}

async function createCategory(obj) {
    const requestOptions = {
        method: 'POST',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(obj)
    };

    return await fetch(`${config.oat_api_url}/question/categories/add/`, requestOptions).then(APIHandler.handleResponse);
}

async function getAllQuestionMetaData() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    const response = await fetch(`${config.oat_api_url}/questions/get-all-meta-data/`, requestOptions);
    return APIHandler.handleResponse(response);
}