import { BlobServiceClient } from '@azure/storage-blob'
import config from "./config";
import appUrls from "./appUrls";

import {store} from "./redux_store";
import { testConstants } from './constants';
import APIHandler from "../handlers/APIHandler";
import DataHandler from "../handlers/DataHandler";

let SAS_TOKEN = ''
let SAS_URL = ''

class AzureStorage {

    async getBlobServiceClient() {
        let blobServiceClient;
        SAS_TOKEN = DataHandler.getFromSession('SAS_TOKEN');
        SAS_URL = appUrls.STORAGE_ACCOUNT_URI +'?' + SAS_TOKEN
        if (SAS_TOKEN) {
            try {
                blobServiceClient = await new BlobServiceClient(SAS_URL);
            } catch (e) {
                SAS_TOKEN = await this.getSASToken();
                blobServiceClient = await new BlobServiceClient(SAS_URL);
            }
        } else {
            SAS_TOKEN = await this.getSASToken();

            SAS_URL = appUrls.STORAGE_ACCOUNT_URI + '?' + SAS_TOKEN
            blobServiceClient = await new BlobServiceClient(SAS_URL);
        }

        return blobServiceClient;
    }

    async getSASToken() {
        await store.dispatch({ type: testConstants.GET_SAS_TOKEN_REQUEST })
        let requestOptions = {
            method: 'GET',
            headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        }
        SAS_TOKEN = await fetch(`${config.oat_api_url}/questions/azure-tokens/get-question-sas-token`, requestOptions).then(APIHandler.handleResponse);
        await store.dispatch({ type: testConstants.GET_SAS_TOKEN_SUCCESS, token: SAS_TOKEN.data })
        DataHandler.setToSession('SAS_TOKEN', SAS_TOKEN.data)
        return SAS_TOKEN;
    }

    async getContainer(containerName) {
        let blobServiceClient = await this.getBlobServiceClient();
        try {
            let containerClient = await blobServiceClient.getContainerClient(containerName);
            let isExists = await containerClient.exists();
            if (isExists) {
                return containerClient;
            } else {
                await this.createContainer(containerName);
                return await blobServiceClient.getContainerClient(containerName);
            }
        } catch (e) {
            await this.createContainer(containerName);
            return blobServiceClient.getContainerClient(containerName);
        }
    }

    async createContainer(containerName) {
        try {
            let blobServiceClient = await this.getBlobServiceClient();
            let containerClient = await blobServiceClient.getContainerClient(containerName);
            let containerCreateResponse = await containerClient.createIfNotExists();
        } catch (e) {
            throw 'Azure Blob Container Create Failed';
        }
    }

    // delete container immediately on blobServiceClient
    async deleteContainerImmediately(containerName) {
        let blobServiceClient = await this.getBlobServiceClient();
        const response = await blobServiceClient.deleteContainer(containerName);
        if (!response.errorCode) {
        }
    }

    async uploadBlob(containerName, blobName, blobData) {
        try {
            let containerClient = await this.getContainer(containerName);
            let blockBlobClient = await containerClient.getBlockBlobClient(blobName);
            let uploadBlobResponse = await blockBlobClient.uploadData(blobData);
            return uploadBlobResponse.requestId
        } catch (e) {
            throw 'Azure Blob Upload Failed';
        }
    }

    async storeBlobContent(containerName, blobName, blobData) {
        try {
            let containerClient = await this.getContainer(containerName);
            let blockBlobClient = await containerClient.getBlockBlobClient(blobName);
            let storeBlobResponse = await blockBlobClient.uploadData(blobData);
            return storeBlobResponse.requestId
        } catch (e) {
            throw 'Azure Blob Content Store Failed';
        }
    }

    async updateBlobContent(containerName, blobName, newBlobData, newBlobDataLength) {
        try {
            let containerClient = await this.getContainer(containerName);
            let blockBlobClient = await containerClient.getBlockBlobClient(blobName);
            let updateBlobResponse = await blockBlobClient.upload(newBlobData, newBlobDataLength);
            return updateBlobResponse.requestId
        } catch (e) {
            throw 'Azure Blob Update Failed';
        }
    }

    async downloadBlob(containerName, blobName) {
        try {
            let containerClient = await this.getContainer(containerName);
            let blockBlobClient = await containerClient.getBlockBlobClient(blobName.replace(/['"]+/g, ''));
            let downloadBlobResponse = await blockBlobClient.download(0);
            return await downloadBlobResponse.blobBody;
        } catch (e) {
            throw 'Azure Blob Download Failed';
        }
    }

    async listBlobs(container, candidate) {
        try {
            store.dispatch({ type: testConstants.GET_MEDIA_REQUEST })
            let containerClient = await this.getContainer(container);
            let iter = containerClient.listBlobsByHierarchy('*', { prefix: 'video/' + candidate });
            let entity = await iter.next();
            let blobList = []
            while (!entity.done) {
                let item = entity.value;
                if (item.kind === "prefix") {
                } else {
                    blobList.push({ name: item.name })
                }
                entity = await iter.next();
            }
            store.dispatch({ type: testConstants.GET_MEDIA_SUCCESS, content: blobList })
            return blobList;
        } catch (e) {
            store.dispatch({ type: testConstants.GET_MEDIA_FAILURE, error: e.toString() })
        }
    }

    async downloadBlob(containerName, blobName) {
        let blobServiceClient = await this.getBlobServiceClient();
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);
        let downloadBlockBlobResponse = await blobClient.download();
        window.open(downloadBlockBlobResponse._response.request.url)
        return await downloadBlockBlobResponse.blobBody;
    }

    async getBlobContent(containerName, blobName) {
        let blobServiceClient = await this.getBlobServiceClient();
        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobClient = containerClient.getBlobClient(blobName);
        let downloadBlockBlobResponse = await blobClient.download();
        const downloadedBlobContent = await this.blobToString(await downloadBlockBlobResponse.blobBody);
        return await downloadedBlobContent;
    }

    async downloadBlobs(containerName, blobsList) {
        let blobServiceClient = await this.getBlobServiceClient();
        const containerClient = blobServiceClient.getContainerClient(containerName);

        let blobs = []

        for (let blob of blobsList) {
            let blobClient = await containerClient.getBlobClient(blob.name);
            let response = await blobClient.download();
            let blobBody = await response.blobBody;
            blobs.push(blobBody);
        }

        let recordedBlob = new Blob(blobs, { type: "application/octet-stream" });
        return recordedBlob;
    }

    async deleteblob(containerName, blobName) {
        let blobServiceClient = await this.getBlobServiceClient();
        const containerClient = blobServiceClient.getContainerClient(containerName);
        await containerClient.deleteBlob(blobName);
    }

    async removeBlobContent(containerName, blobName) {
        let blobServiceClient = await this.getBlobServiceClient();
        const containerClient = blobServiceClient.getContainerClient(containerName);
        await containerClient.deleteBlob(blobName);
        //await blobServiceClient.deleteContainer(containerName);
    }

    async streamToString(readableStream) {
        return new Promise((resolve, reject) => {
            const chunks = [];
            readableStream.on("data", (data) => {
                chunks.push(data.toString());
            });
            readableStream.on("end", () => {
                resolve(chunks.join(""));
            });
            readableStream.on("error", reject);
        });
    }

    async blobToString(blob) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                if (fileReader.result) {
                    resolve(fileReader.result);
                } else {
                    reject(new Error("Failed to read the blob as a string."));
                }
            };

            fileReader.onerror = () => {
                reject(new Error("Error reading the blob as a string."));
            };

            fileReader.readAsText(blob);
        });
   }
}

const AzureBlobStorage = new AzureStorage();
export { AzureBlobStorage as AzureStorage }