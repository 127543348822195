import * as React from 'react'
import ScrollableFeed from 'react-scrollable-feed'
import Avatar from '@mui/material/Avatar';
import animationData from "../animations/typing.json";
import Lottie from "react-lottie";
import Zoom from '@mui/material/Zoom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { isSameSender,getBackground,senderMargins } from '../../../../helper/ChatLogics';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ScrollableChat = ({ messages, chat, istyping, typingUser }) => {
//   const { user } = ChatState();

  const messageContainerStyle = {
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word', 
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  function linkify(text) {
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlRegex, function(url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });
  }

  return (
    <div className="chat-bg" style={{ height: "380px" }}>
      <ScrollableFeed className="px-2">
        {messages &&
          messages.map((m, i) => (
            <div key={m._id} className="d-flex align-items-end py-3">
              {!isSameSender(m, chat) && (
                <BootstrapTooltip
                  title={m.sender.name}
                  placement="top"
                  arrow
                  TransitionComponent={Zoom}
                >
                  <Avatar
                    mt="25px"
                    borderRadius="50%"
                    mr="5px"
                    size="sm"
                    cursor="pointer"
                    name={m.sender.name}
                    src={m.sender.name}
                    alt={m.sender.name}
                    sx={{ maxWidth: "25px", maxHeight: "25px", backgroundColor: "#7779CF" }}
                  />
                </BootstrapTooltip>
              )}
              <span
                style={{
                  backgroundColor: `${
                    getBackground(m, chat) ? "#DCFFF1" : "#E2F0FD"
                  }`,
                  marginLeft: senderMargins(m, chat) ? "auto" : 0,
                  // marginTop: senderMargins(m,chat) ? 3 : 10,
                  borderRadius: `${
                    getBackground(m, chat)
                      ? "8px 8px 0px 8px"
                      : "8px 8px 8px 0px"
                  }`,
                  padding: "5px 15px",
                  maxWidth: "75%",
                  // ...messageContainerStyle,
                }}
              >
                <p
                  style={{
                    textAlign: "start",
                    color: "#3f3f3f"
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: linkify(m.content) }} />
                </p>
              </span>
            </div>
          ))}
        <div style={{ height: "30px", ml: "10px" }}>
          {istyping ? (
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <Lottie
                options={defaultOptions}
                height={20}
                width={70}
                style={{ marginBottom: 5, marginLeft: 20, marginTop: 5 }}
              />
              <p>{typingUser ? typingUser : ""}</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </ScrollableFeed>
    </div>
  )
};

export default ScrollableChat;
