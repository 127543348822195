import React, { Component } from "react";
import moment from "moment";
import {
  Card,
  Row,
  Modal,
  Button,
  Form,
  Dropdown,
} from "react-bootstrap";
import CardHeader from "../../../components/common/CardHeader.js";
import CardBody from "../../../components/common/CardBody.js";
import ContentWrapper from "../../../components/common/ContentWrapper.js";
import AdminTemplate from "../../../components/admin/layouts/Template.js";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { CalendarService } from "../../../service/manage/calendar/calendar.service.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { history } from "../../../helper/history.js";
import { TreeMainService } from "../../../service/manage/h_tree/tree_main.service.js";
import Tooltip from '@mui/material/Tooltip';
import config from '../../../helper/config.js';
import { customerConstants } from "../../../helper/constants.js";
import CustomToolBar from './CustomToolBar.js'
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CalendarEventTypeService } from "../../../service/settings/manage/calendar_events.service.js";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead
} from "@mui/material";

const localizer = momentLocalizer(moment);
const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle, dropdownStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    dropdownStyle = {
      width: "170px",
      display: "flex",
      justifyContent: "space=between",
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

export default class AllEventsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: moment(),
      events: [],
      eventText: "",
      selectedMonth: moment().month(),
      selectedYear: moment().year(),
      showEventsPopup: false,
      selectedEvents: [],
      showSuccessAlert: false,
      showUpdateSuccessAlert: false,
      editingEvent: null,
      editedEventText: "",
      node_mid: null,
      selectedDateEvents: [],
      firstDayOfMonth: moment().startOf("month"),
      deleteConfirmation: null,
      selectedEvent: null,
      showAllEvents: false,
      editVisibility: false,
      addVisibility: false,
      deleteVisibility: false,
      selectedEventCategoryDropdown: "All Events",
      selectedEventCategoryRadio: "All Events",
      nodeMids: [],
      selectedNodeMid: "All Nodes",
      formDisplayTitle: "",
      selectedEventCategories: [
        "Holiday",
        "Assignments",
        "Lab sessions",
        "Special Events",
        "Lecture Notice",
      ],
      eventDescription: "",
      showModal: false,
      showPersonalCalendar: false,
      calEventTypes:[],
      lg_user_id: undefined,
      lg_user_table_id: undefined,
      selectedSearchEventTypes:[],
      slotSelectedDate:undefined
    };
  }

  componentDidMount() {
    this.loadNodeMids();
    this.fetchAllEventTypes()
  }

  fetchAllEventTypes = () => {
    CalendarEventTypeService.getAllCalEventTypes().then(res => {
      this.setState({
        calEventTypes: res.data
      })
    })
  }
  
  
  formattedDateString = (value) =>{
    const dateObject = value ? new Date(value): undefined;
    const year = dateObject && dateObject.getFullYear();
    const month = dateObject &&  ("0" + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = dateObject && ("0" + dateObject.getDate()).slice(-2);
    const fromattedString =  `${day}-${month}-${year}`
    return fromattedString;
  }
  

  async loadNodeMids() {
    try {
      // Assuming formListId and userAccessTableId are available in your component
      const lg_user_table_id = sessionStorage.getItem("table_log_id");
      const lg_user_id = sessionStorage.getItem("manage_user_id");
      const response = await TreeMainService.getAllCalendarContent(
        lg_user_id,
        lg_user_table_id
      );

      if (response) {
        this.setState({ nodeMids: response.data.map((item, index) => ({ ...item, 'id': index })) }, () => {
          this.loadEventsForSelectedNodeMid(undefined);
        });

      }
    } catch (error) {
      console.error("Error loading form display titles:", error);
    }
  }
  onFilterInput = (e,v,r) =>{
    this.setState({
      // On autofill we get a the stringified value.
      selectedSearchEventTypes:v
    }, () => {
      this.loadEventsForSelectedNodeMid(this.state.selectedNodeMid)
    })
  }

  // Handle changes for the checkboxes
  handleCheckboxPersonalChange = (event) => {
    const {checked} = event.target
    this.setState({
      lg_user_id: checked ? sessionStorage.getItem("manage_user_id") : undefined,
      lg_user_table_id: checked ? sessionStorage.getItem("table_log_id"): undefined
    },()=>{
      this.loadEventsForSelectedNodeMid(this.state.selectedNodeMid)
    })
  };
  CustomToolbar = ({ label }) => (
    <div className="rbc-toolbar">
      <span className="rbc-toolbar-label">abcd</span>
    </div>
  );

  async loadEventsForSelectedNodeMid(nodeMid) {
    try {
      const lg_user_id  = this.state.lg_user_id ? this.state.lg_user_id : '';
      const lg_user_table_id  = this.state.lg_user_table_id ? this.state.lg_user_table_id : '';
      const actualNodeMid = nodeMid || "All Nodes";

      let response;
      const nodeArray = this.state.nodeMids && this.state.nodeMids.map(obj => obj.mid)
      let selectedEventCategories = this.state.selectedSearchEventTypes.length ? this.state.selectedSearchEventTypes.map(event => event.cal_event_type_id) : []

      if (actualNodeMid === "All Nodes") {
        // Fetch all events for the specified month without applying node_mid filter
        response = await CalendarService.getEventsForNode(
          this.state.selectedYear,
          this.state.selectedMonth + 1,
          selectedEventCategories,
          lg_user_id,
          lg_user_table_id,
          "All Nodes", // Pass null or any appropriate value for node_mid parameter,
          nodeArray
        );
      } else {
        // Fetch events based on the selected node_mid
        response = await CalendarService.getEventsForNode(
          this.state.selectedYear,
          this.state.selectedMonth + 1,
          selectedEventCategories,
          lg_user_id,
          lg_user_table_id,
          actualNodeMid
        );
      }

      if (response.success) {
        
        const calendarEvents = response.data.map((event) => {
          const nodeItBelongs = this.state.nodeMids?.find(node => node.mid === event.node_mid)
          return {
            title: event.event_title,
            start: moment(event.event_start_date).toDate(),
            end: moment(event.event_start_date).toDate(),
            entry_id: event.entry_id,
            calendar_event_type: event.calendar_event_type,
            cal_event_type_id: event.cal_event_type_id,
            breadcrumb: nodeItBelongs?.breadcrumb
          }
        });

        this.setState({ events: calendarEvents });
      }
    } catch (error) {
      console.error("Error loading events for node mid:", error);
    }
  }

  handleNodeMidChange(nodeMid) {
    // Update the selected node mid state
    this.setState({ selectedNodeMid: nodeMid ? nodeMid : "All Nodes" });


    // Add logic to load events for the selected node mid
    if (nodeMid === "All Nodes") {
      // When "All Nodes" is selected, load all events
      this.setState({ formDisplayTitle: null });
      this.loadEventsForSelectedNodeMid();
    } else {

      this.setState({ formDisplayTitle: nodeMid.form_display_title });
      // Load events for the selected node_mid
      this.loadEventsForSelectedNodeMid(nodeMid.node_mid);
    }
  }


  handleAllEventsCheckboxChange = (checked) => {
    if (checked) {
      this.handleCheckboxChange("All Events");
    } else {
      this.setState({ selectedEventCategories: [] }, () => {
        this.loadEventsForSelectedMonth();
      });
    }
  };

  loadEventsForSelectedDate = async (selectedDate) => {
    try {
      const response = await CalendarService.getEventsByStartDate(
        selectedDate.format("YYYY-MM-DD")
      );

      if (response.success) {
        const retrievedEvents = response.data;
        this.setState({ events: retrievedEvents });
      }
    } catch (error) {
      console.error("Error loading events:", error);
    }
  };

  handleDropdownChange = (category) => {
    this.setState({ selectedEventCategoryDropdown: category }, () => {
      // this.loadEventsForSelectedMonth();
    });

    this.setState({ selectedEventCategoryRadio: category });
  };

  handleRadioChange = (category) => {
    this.setState(
      (prevState) => {
        const updatedCategories = [...prevState.selectedEventCategories];

        if (updatedCategories.includes(category)) {
          updatedCategories.splice(updatedCategories.indexOf(category), 1);
        } else {
          updatedCategories.push(category);
        }

        return { selectedEventCategories: updatedCategories };
      },
      () => {
        this.loadEventsForSelectedMonth();
      }
    );
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    this.updateDropdownText(date);
  };

  handleDeleteEvent = (event) => {
    this.setState({
      deleteConfirmation: {
        event,
        show: true,
      },
    });
  };

  // Add this method to confirm the deletion of an event
  confirmDeleteEvent = async () => {
    const { deleteConfirmation } = this.state;
    if (deleteConfirmation && deleteConfirmation.event) {
      try {
        const response = await CalendarService.deleteEvent(
          deleteConfirmation.event.entry_id
        );

        if (response.success) {
          const updatedEvents = this.state.events.filter(
            (event) => event.entry_id !== deleteConfirmation.event.entry_id
          );

          this.setState({
            events: updatedEvents,
            deleteConfirmation: null,
            showEventPopup: false,
          });
        }
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };

  updateDropdownText = (date) => {
    const formattedDate = date.format("MMMM DD, YYYY") + " ▼";
    const dropdownButton = document.getElementById("selectDateButton");

    if (dropdownButton) {
      dropdownButton.innerHTML = formattedDate;
      dropdownButton.title = date.format("YYYY-MM-DD");
    }
  };

  handleInputChange = (e) => {
    this.setState({ eventText: e.target.value });
  };

  handleDescriptionChange = (e) => {
    this.setState({ eventDescription: e.target.value });
  };

  handleSaveEvent = async () => {
    const newEvent = {
      event_title: this.state.eventText,
      event_description: `${this.state.eventDescription}${this.state.selectedEventCategoryDropdown}`,
      event_start_date: this.state.selectedDate.format("YYYY-MM-DD"),
      event_end_date: this.state.selectedDate.format("YYYY-MM-DD"),
      node_mid: !!history.location.state.mid
        ? history.location.state.mid
        : null,
      event_start_time: "08:00:00",
      event_end_time: "09:00:00",
      create_user_id: !!history.location.state.lg_user_id
        ? history.location.state.lg_user_id
        : null,
      create_user_table_id: !!history.location.state.lg_user_table_id
        ? history.location.state.lg_user_table_id
        : null,
    };

    try {
      const response = await CalendarService.addEvent(newEvent);

      if (response.success) {
        const savedEvent = response.data;
        this.setState(
          (prevState) => ({
            events: [...prevState.events, savedEvent],
            eventText: "",
            eventDescription: "",
            showSuccessAlert: true,
          }),
          () => {
            this.loadEventsForSelectedMonth();
          }
        );
      }
    } catch (error) {
      console.error("Error saving event:", error);
    }
  };

  handleEditEvent = (event) => {
    this.setState({
      selectedEvent: event,
    });
  };

  // Handle changes to the edited event title
  handleEditingEventInputChange = (e) => {
    this.setState({ editingEventText: e.target.value });
  };

  handleSaveEditedEvent = async () => {
    const { selectedEvent, editingEventText } = this.state;

    if (!selectedEvent) {
      return;
    }

    const updatedEvent = {
      entry_id: selectedEvent.entry_id,
      event_title: editingEventText,
      event_start_date: selectedEvent.start.toISOString(),
    };

    try {
      const response = await CalendarService.updateEvent(
        selectedEvent.entry_id,
        updatedEvent
      );

      if (response.success) {
        // Update the event in the calendar
        const updatedEvents = this.state.events.map((event) =>
          event.entry_id === selectedEvent.entry_id
            ? { ...event, title: editingEventText }
            : event
        );

        this.setState(
          {
            events: updatedEvents,
            editingEvent: null,
            editingEventText: "",
            showUpdateSuccessAlert: true,
            showEventPopup: false,
          },
          () => {
            setTimeout(() => {
              this.setState({ showUpdateSuccessAlert: false });
              // After closing the popup, load events for the selected month
              this.loadEventsForSelectedMonth();
            }, 3000);
          }
        );
      }
    } catch (error) {
      console.error("Error saving edited event:", error);
    }
  };

  handleMonthChange = (month) => {
    this.setState({ selectedMonth: month }, () => {
      // Update the firstDayOfMonth to the selected month and year
      const newFirstDayOfMonth = this.state.firstDayOfMonth
        .clone()
        .month(month);
      this.setState({ firstDayOfMonth: newFirstDayOfMonth }, () => {
        this.loadEventsForSelectedMonth();
      });
    });
  };

  handleYearChange = (year) => {
    this.setState({ selectedYear: year }, () => {
      // Update the firstDayOfMonth to the selected year
      const newFirstDayOfMonth = this.state.firstDayOfMonth.clone().year(year);
      this.setState({ firstDayOfMonth: newFirstDayOfMonth }, () => {
        this.loadEventsForSelectedMonth();
      });
    });
  };

  loadEventsForPersonalCalendar = async () => {
    const { selectedMonth, selectedYear } = this.state;
    const { lg_user_id, lg_user_table_id, owner_restriction } =
      history.location.state;

    // Check if owner_restriction is true, then only load personal calendar events
    if (owner_restriction) {
      try {
        const response = await CalendarService.getPersonalCalendarEvents(
          selectedYear,
          selectedMonth + 1,
          lg_user_id,
          lg_user_table_id
        );

        if (response.success) {
          const personalCalendarEvents = response.data.map((event) => ({
            title: event.event_title,
            start: moment(event.event_start_date).toDate(),
            end: moment(event.event_start_date).toDate(),
            entry_id: event.entry_id,
          }));

          this.setState({ personalCalendarEvents });
        }
      } catch (error) {
        console.error(
          "Error loading personal calendar events for the month:",
          error
        );
      }
    }
  };

  loadEventsForSelectedMonth = async (nodeMid) => {
    const { selectedMonth, selectedYear, selectedEventCategories, showModal, selectedNodeMid } =
      this.state;
    const nodeArray = this.state.nodeMids && this.state.nodeMids.map(obj => obj.mid)

    const firstDayOfMonth = this.state.firstDayOfMonth;
    const lastDayOfMonth = firstDayOfMonth.clone().endOf("month");

    const lg_user_id = sessionStorage.getItem("manage_user_id");
    const lg_user_table_id = sessionStorage.getItem("table_log_id");
    // const node_mid = sessionStorage.getItem("node_mid");
    const mid = selectedNodeMid === "All Nodes" ? selectedNodeMid : selectedNodeMid.node_mid//nodeMid? nodeMid.node_mid :"All Nodes";

    // if (!lg_user_id || !lg_user_table_id) {
    //   // Handle the case where lg_user_id or lg_user_table_id is not present
    //   console.error("lg_user_id and/or lg_user_table_id is not available.");
    //   return;
    // }

    try {
      let response;

      if (selectedEventCategories.length === 0) {
        // If no categories are selected, fetch all events for the selected month
        response = await CalendarService.getEventTitlesForMonth(
          selectedYear,
          selectedMonth + 1,
          "All Events",
          lg_user_id,
          lg_user_table_id,
          mid,
          nodeArray
        );
      } else {
        // Fetch events based on the selected categories
        response = await CalendarService.getEventTitlesForMonth(
          selectedYear,
          selectedMonth + 1,
          selectedEventCategories,
          lg_user_id,
          lg_user_table_id,
          mid,
          nodeArray
        );
      }

      if (response.success) {
        const calendarEvents = response.data.map((event) => ({
          title: event.event_title,
          start: moment(event.event_start_date).toDate(),
          end: moment(event.event_start_date).toDate(),
          entry_id: event.entry_id,
        }));

        this.setState({ events: calendarEvents });
      }
    } catch (error) {
      console.error("Error loading events for the month:", error);
    }
  };

  handleAddEventClick = () => {
    this.setState({
      showModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedEventCategoryDropdown: "All Events",
    });
  };

  loadEventsForSelectedDate = async (date) => {
    try {
      const response = await CalendarService.getEventsByDate(date);

      if (response.success) {
        const retrievedEvents = response.data;
        this.setState({ events: retrievedEvents });
      }
    } catch (error) {
      console.error("Error loading events for the selected date:", error);
    }
  };

  handleDateSelect = async (selectedDate) => {
    this.setState({ selectedDate }, async () => {
      this.loadEventsForSelectedMonth();
    });
  };

  handleUpdateEvents = async () => {
    const { selectedDate } = this.state;

    try {
      const response = await CalendarService.getEventsByDate(
        selectedDate.format("YYYY-MM-DD")
      );

      if (response.success) {
        const retrievedEvents = response.data;
        this.setState({ events: retrievedEvents });
      }
    } catch (error) {
      console.error("Error updating events:", error);
    }
  };

  handleDateClick = (slotInfo) => {
    if(!this.props.fromDashboard){
      const selectedDate = moment(slotInfo.start);
      const selectedEvents = this.state.events.filter((event) => {
        return moment(event.start).isSame(selectedDate, "day");
      });
      this.setState({
        showEventPopup: true,
        slotSelectedDate: selectedDate,
        selectedDateEvents: selectedEvents,
      });
    }    
  };

  closeEventPopup = () => {
    this.setState({
      showEventPopup: false,
      selectedDateEvents: [],
      selectedEvent: null,
      slotSelectedDate: undefined,
    });
  };

  returnContentWrapper = () => {
    const {
      events,
      showSuccessAlert,
      showUpdateSuccessAlert,
      selectedDate,
      selectedMonth,
      selectedYear,
      // showAllEvents,
      deleteConfirmation,
      editingEvent,
      editedEventText,
      firstDayOfMonth,
      showEventsPopup,
      selectedEvents,
      editVisibility,
      addVisibility,
      deleteVisibility,
      selectedEventCategoryDropdown,
      selectedEventCategoryRadio,
      showModal,
      eventText,
      formDisplayTitle,
      nodeMids,
      selectedNodeMid,
    } = this.state;
    return <div>
    <ContentWrapper disabled={this.props.fromDashboard}>
      <Row>
        <div className="col-md-12 px-0">
          <Card>
           { !this.props.fromDashboard && <CardHeader>
              <div className="row">
                <div className="col-md-6 d-flex align-items-center px-0">
                  <h4 className="card-title">
                    Main Calendar
                    {formDisplayTitle &&
                      ` (${formDisplayTitle})`}
                  </h4>
                </div>

                {/* Center the node_mid dropdown */}
                <div className="col-md-6 d-flex justify-content-end px-0">
                  <Form.Group controlId="nodeMidDropdown">
                    <Dropdown>

                      <Tooltip title={selectedNodeMid.breadcrumb ? `${selectedNodeMid.breadcrumb}>${selectedNodeMid.tree_main_title}` : undefined} arrow>
                        <Dropdown.Toggle variant="primary">
                          {selectedNodeMid.form_display_title
                            ? `${selectedNodeMid.form_display_title}`
                            : "All Nodes"}
                        </Dropdown.Toggle>

                      </Tooltip>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            this.handleNodeMidChange("All Nodes")
                          }
                        ><div className="col-10">
                            All Nodes
                          </div>
                          <div className="col-2">
                          </div>
                        </Dropdown.Item>
                        {nodeMids?.map((selectedNodeMid) => (
                          <Tooltip title={selectedNodeMid.breadcrumb ? `${selectedNodeMid.breadcrumb}${selectedNodeMid.tree_main_title}` : undefined} arrow>
                            <Dropdown.Item
                              key={selectedNodeMid.id}
                              onClick={() =>
                                this.handleNodeMidChange(selectedNodeMid)
                              }
                            >
                              <div>
                                {selectedNodeMid.form_display_title}
                              </div>
                            </Dropdown.Item>

                          </Tooltip>

                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </div>
              </div>
            </CardHeader>}
            <CardBody>
              <div className="row pb-4">
                <div className="col-md-4">
                  {/* <Button
                    variant="primary"
                    onClick={this.handleAddEventClick}
                  >
                    Add Event
                  </Button> */}
                </div>
                <div className="col-md-2">
                  {addVisibility && (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="selectMonthYearButton"
                      >
                        {firstDayOfMonth.format("MMMM YYYY")} ▼
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        style={{ maxHeight: "200px", overflowY: "auto" }}
                      >
                        {moment.months().map((month, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => this.handleMonthChange(index)}
                          >
                            {month}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                {!this.props.fromDashboard && <div className="col-md-12 d-flex justify-content-between px-0 pb-5">
                  <div className="col-md-12 px-0">
                    <div
                    class="d-flex justify-content-start px-0 mt-5 pb-5 col-6 "
                    style={{ fontWeight: "bold", fontSize: "1.1rem" }}
                  >
                    <Autocomplete
                      multiple
                      size="small"
                      id="checkboxes-filter"
                      options={this.state.calEventTypes}
                      disableCloseOnSelect
                      getOptionLabel={(option) => (
                        <li style={{ fontFamily: 'Poppins, sans-serif', listStyle: 'none' }}>{option.name}</li>
                      )}
                      onChange={(e, v, r) => this.onFilterInput(e, v, r)}
                      sx={{
                        width: 300,
                        fontFamily: "Poppins, sans-serif"
                      }}
                      renderOption={(props, option, { selected }) => {
                        return (
                        <li {...props} style={{ fontFamily: 'Poppins' }}>
                          <Checkbox
                            style={{ marginRight: 8, color: "#5458AF" }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}}
                      className="col-lg-6 col-md-6 col-12 px-1 py-2"
                      renderInput={(params) => (
                        <TextField {...params} label="Select Filter" className="col-md-12" />
                      )}
                    />
                    </div>
                      
                <Form.Group className="d-flex col-md-12 justify-content-between px-0">
                <div className="calendar-checkbox">
                    <Form.Check
                      type="checkbox"
                      label="My Events"
                      checked={!!this.state.lg_user_id && !!this.state.lg_user_table_id}
                      onChange={
                        this.handleCheckboxPersonalChange
                      }
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </div>
                    </Form.Group>
                  </div>
                </div>}
              </div>

              <BigCalendar
                localizer={localizer}
                events={events}
                views={["month"]}
                defaultView={"month"}
                style={{ height: this.props.fromDashboard ? 300 : 500 }}
                date={selectedDate.toDate()}
                onNavigate={(newDate) =>
                  this.handleDateChange(moment(newDate))
                }
                selectable={true}
                onSelectSlot={this.handleDateClick}
                onSelectEvent = {this.handleDateClick}
                components={{ 
                  toolbar: CustomToolBar,
                }}
              />

              <br />
              <br />
              {this.state.showEventPopup && (
                <Modal
                  show={this.state.showEventPopup}
                  onHide={this.closeEventPopup}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Events Of the Day {`: ${this.formattedDateString(this.state.slotSelectedDate)}`}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {this.state.selectedDateEvents?.length ? <Table>
                    <TableHead>
                          <TableRow>
                              <TableCell className="thead">Event title</TableCell>
                              <TableCell className="thead">Event type</TableCell>
                              <TableCell className="thead">Event bind to</TableCell>
                            </TableRow>
                          </TableHead>
                        <TableBody>
                          {this.state.selectedDateEvents.map((event, index) => {
                            return (
                            <TableRow key={event.entry_id}>
                                <TableCell className="tData">
                                {event.title}
                                </TableCell>
                                <TableCell className="tData">
                                  {event.calendar_event_type}
                                </TableCell>
                                <TableCell className="tData">
                                  {event.breadcrumb}
                                </TableCell>
                              </TableRow>
                            )})
                            }
                          </TableBody>
                        </Table> : <div>No events to show on this Date</div>}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={this.closeEventPopup}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}
              {this.state.selectedEvent && (
                <Modal
                  show={true}
                  onHide={() => this.handleEditEvent(null)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit Event</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Control
                      type="text"
                      placeholder="Edit event title"
                      value={
                        this.state.editingEventText ||
                        this.state.selectedEvent.title
                      }
                      onChange={(e) =>
                        this.handleEditingEventInputChange(e)
                      }
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="primary"
                      onClick={() => {
                        this.handleSaveEditedEvent();
                        this.handleEditEvent(null);
                      }}
                    >
                      Save
                    </Button>
                  </Modal.Footer>
                </Modal>
              )}

              {deleteConfirmation && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Delete"
                  confirmBtnBsStyle="danger"
                  title="Confirm Delete"
                  onConfirm={() => this.confirmDeleteEvent()}
                  onCancel={() =>
                    this.setState({ deleteConfirmation: null })
                  }
                >
                  Are you sure you want to delete this event?
                </SweetAlert>
              )}

              {showUpdateSuccessAlert && (
                <SweetAlert
                  success
                  title="Success!"
                  onConfirm={() =>
                    this.setState({ showUpdateSuccessAlert: false })
                  }
                >
                  Event updated successfully.
                </SweetAlert>
              )}
            </CardBody>
          </Card>
        </div>
      </Row>
    </ContentWrapper>
    {showModal && (
      <Modal show={showModal} onHide={this.handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="selectDateButton">
                  {selectedDate.format("MMMM DD, YYYY")} ▼
                </Dropdown.Toggle>
                <Dropdown.Menu
                  style={{ maxHeight: "250px", overflowY: "auto" }}
                >
                  {Array.from(
                    { length: firstDayOfMonth.daysInMonth() },
                    (_, i) => i + 1
                  ).map((day) => (
                    <Dropdown.Item
                      key={day}
                      onClick={() =>
                        this.handleDateChange(
                          firstDayOfMonth.clone().date(day)
                        )
                      }
                    >
                      {day}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <div className="col-md-4">
                  <div className="row"> */}
            <div className="col-md-6">
              {/* {addVisibility && ( */}
              <Dropdown>
                <Dropdown.Toggle
                  variant="primary"
                  id="eventCategoryDropdown"
                >
                  {selectedEventCategoryDropdown} ▼
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* {addVisibility && ( */}
                  <>
                    <Dropdown.Item
                      onClick={() => this.handleDropdownChange("Holiday")}
                      style={{ color: "green" }}
                    >
                      Holiday
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        this.handleDropdownChange("Assignments")
                      }
                      style={{ color: "black" }}
                    >
                      Assignments
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.handleDropdownChange("Lab sessions")}
                      style={{ color: "red" }}
                    >
                      Lab sessions
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        this.handleDropdownChange("Special Events")
                      }
                      style={{ color: "purple" }}
                    >
                      Special Events
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() =>
                        this.handleDropdownChange("Lecture Notice")
                      }
                      style={{ color: "brown" }}
                    >
                      Lecture Notice
                    </Dropdown.Item>
                  </>
                  {/* )} */}
                  <Dropdown.Item
                    onClick={() =>
                      this.handleDropdownChange("Personal Calendar")
                    }
                    style={{ color: "orange" }}
                  >
                    Personal Calendar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* )} */}
            </div>
          </div>
          <div className="my-3"></div>
          <div className="row">
            <div className="col-md-12">
              <Form.Control
                type="text"
                placeholder="Enter event"
                value={this.state.eventText}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={this.handleSaveEvent}
              disabled={!eventText}
            >
              Save Event
            </Button>
            <span className="ml-2"></span>
            {showSuccessAlert && (
              <SweetAlert
                success
                title="Success!"
                onConfirm={() => {
                  this.setState({ showSuccessAlert: false });
                  this.handleCloseModal();
                }}
              >
                Event added successfully.
              </SweetAlert>
            )}
            <Button variant="secondary" onClick={this.handleCloseModal}>
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )}
    </div>
  }

  // Add your event handling methods

  render() {
    if (this.props.fromDashboard){
      return this.returnContentWrapper()
    }

    return (
      <AdminTemplate>
        {this.returnContentWrapper()}
      </AdminTemplate>
    );
  }
}
