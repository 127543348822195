
import { alertConstants, authenticationConstants, federationConstants, userConstants } from "../helper/constants";
import {store} from "../helper/redux_store";

/* On Initialization */
export function onInitializationFailure(error){
    store.dispatch({
        type: authenticationConstants.LOGIN_FAILURE,
        error: error
    });
    return store.dispatch({
        type: alertConstants.ERROR,
        message: error
    });
}


/* On Login */
export function onLoginRequest() {
    store.dispatch({
        type: authenticationConstants.LOGIN_REQUEST,
        method: federationConstants.MICROSOFT
    });
}

export function onLoginSuccess(response, userFederation) {
    store.dispatch({
        type: authenticationConstants.LOGIN_SUCCESS,
        method: userFederation,
        token: response.idToken
    });
}

export function onLoginFailure(error) {
    store.dispatch({
        type: authenticationConstants.LOGIN_FAILURE,
        error: error
    });
    return error;
}

/* on AccessToken */
export function onAccessTokenRequest() {
    store.dispatch({
        type: authenticationConstants.TOKEN_REQUEST
    });
}

export function onAccessTokenSuccess(accessToken) {
    store.dispatch({
        type: authenticationConstants.TOKEN_SUCCESS,
        token: accessToken
    });
}

export function onAccessTokenFailure(error) {
    store.dispatch({
        type: authenticationConstants.TOKEN_FAILURE,
        error: error
    });
    store.dispatch({
        type: authenticationConstants.LOGOUT
    });
    return error;
}

/* on GetUser */
export function onGetUserFailure(error){
    store.dispatch({
        type: userConstants.GET_ME_FAILURE,
        error: error
    }) 
    return error
}

export function onGetUserSuccess(userDetails){
    store.dispatch({
        type: userConstants.GET_ME_SUCCESS,
        me: userDetails
    })
}