import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputAdornment from "@mui/material/InputAdornment";
import SimpleReactValidator from "simple-react-validator";
import { ExpirationService } from "../../../service/settings/manage/expiration.service.js";
import { Spinner } from "react-bootstrap";
import {
  sweetErrorAlert,
  sweetStatusAlert,
  sweetSuccessAlert
} from "../../../components/common/SweetAlert";
import ContentWrapper from "../../../components/common/ContentWrapper";
import config from "../../../helper/config";
import { customerConstants } from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle;
const customer = getCustomerFeatureFlag();
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break;
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent",
    };
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent",
    };
    break;
  default:
    break;
}

class ManageExpiration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTableDataLoading: false,
      isLoading: false,
      addExpirationModal: false,
      updateExpirationModal: false,
      expiration: null,
      updateExpiration: null,
      selectedExpiration: null,
      expirations: [],
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getAllExpirations();
  }

  getAllExpirations = () => {
    this.setState({ isTableDataLoading: true });
    ExpirationService.getExpirations().then((response) => {
      if (response.success) {
        this.setState({
          expirations: response.data,
          isTableDataLoading: false,
        });
      } else {
        this.setState({ isTableDataLoading: false });
      }
    });
  };

  toggleUpdateExpirationModal = (expiration) => {
    const { updateExpirationModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessages();
    if (!updateExpirationModal) {
      this.setState({
        updateExpiration: expiration.expiration_hours,
        selectedExpiration: expiration.expiration_id,
        updateExpirationModal: true,
      });
    } else {
      this.setState({
        updateExpiration: null,
        selectedExpiration: null,
        updateExpirationModal: false,
      });
    }
  };

  handleEditSubmit = () => {
    const { updateExpiration, selectedExpiration } = this.state;
    if (!this.validator.fieldValid("updateExpiration")) {
      this.validator.showMessageFor("updateExpiration");
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const newUpdateExpiration = {
        expiration_hours: updateExpiration,
      };
      ExpirationService.updateExpiration(
        newUpdateExpiration,
        selectedExpiration
      )
        .then((response) => {
          if (response.success) {
            sweetSuccessAlert(
              "Success",
              "Expiration updated successfully",
              "success"
            );
            this.setState({ isLoading: false });
            this.setState({ updateExpirationModal: false });
            this.getAllExpirations();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Oops", response.message, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while updating Expiration", "error");
        });
    }
  };

  handleExpirationChange = (e) => {
    const { name, value } = e.target;
    let newValue = parseInt(value, 10);
    if (isNaN(newValue) || newValue < 0) {
      newValue = 0;
    }
    this.setState(() => ({ [name]: newValue }));
  };
  
  

  toggleAddExpirationModal = () => {
    const { addExpirationModal } = this.state;
    this.validator.purgeFields();
    this.validator.hideMessageFor("expiration");
    this.setState({
      addExpirationModal: !addExpirationModal,
      grade: "",
    });
  };

  handleSubmit = () => {
    const { expiration } = this.state;
    if (!this.validator.fieldValid("expiration")) {
      this.validator.showMessageFor("expiration");
      this.forceUpdate();
    } else {
      this.setState({ isLoading: true });
      const newExpiration = {
        expiration_hours: expiration,
      };
      ExpirationService.addExpiration(newExpiration)
        .then((response) => {
          if (response.success) {
            this.setState({ isLoading: false });
            this.toggleAddExpirationModal();
            sweetSuccessAlert(
              "Success",
              "A new Request Expiration added successfully",
              "success"
            );
            this.getAllExpirations();
          } else {
            this.setState({ isLoading: false });
            sweetStatusAlert("Info", response.message, "info");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          sweetErrorAlert("Error", "Error while adding Expirations", "error");
        });
    }
  };

  render() {
    const {
      addExpirationModal,
      expiration,
      updateExpirationModal,
      isTableDataLoading,
      isLoading,
      expirations,
      updateExpiration,
    } = this.state;

    return (
      <ContentWrapper>
        <div className="row justify-content-between align-items-center py-5 mb-3">
          <div className="col-md-6 col-6">
            <h3 className="text-nowrap">
              Manage Registration Request Expiration
            </h3>
          </div>
          <Button
            className="insert-btn"
            variant="primary"
            onClick={this.toggleAddExpirationModal}
            style={{ display: expirations.length < 1 ? "block" : "none" }}
          >
            Add New Expiration
          </Button>
        </div>
        {isTableDataLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "75vw",
              height: "90vh",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div style={{ width: "80vw", height: "90vh", overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead">
                    Set a Request Expiration In Hours
                  </TableCell>
                  <TableCell className="thead" align="right">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {expirations.length > 0 ? (
                  expirations.map((expiration) => (
                    <TableRow key={expiration.expiration_id}>
                      <TableCell className="tData">
                        {expiration.expiration_hours}
                      </TableCell>
                      <TableCell className="tData" align="right">
                        <Button
                          onClick={() =>
                            this.toggleUpdateExpirationModal(expiration)
                          }
                          className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                          title="Edit Details"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2}>No Expirations found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        )}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateExpirationModal}
          onHide={this.toggleUpdateExpirationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Expiration Hours</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      type="number"
                      name="updateExpiration"
                      value={updateExpiration ? updateExpiration : ""}
                      onChange={this.handleExpirationChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Expiration Hours
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.validator.message(
                      "updateExpiration",
                      updateExpiration,
                      "required|min:1,num",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}
            <Modal.Footer>
              <Button
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
                sx={{ mr: "20px" }}
                onClick={this.toggleUpdateExpirationModal}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={() => this.handleEditSubmit()}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={addExpirationModal}
          onHide={this.toggleAddExpirationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Expiration Hours</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {isLoading ? (
              <div className="loading-container" style={{ height: "50px" }}>
                <Spinner animation="border" role="status">
                  <span className="sr-only">Please wait...</span>
                </Spinner>
              </div>
            ) : (
              <Form>
                <Row className="my-5">
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId="exampleForm.ControlInput1"
                  >
                    <TextField
                      id="outlined-start-adornment"
                      sx={{ width: "25ch" }}
                      type="number"
                      name="expiration"
                      value={expiration ? expiration : ""}
                      onChange={this.handleExpirationChange}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Expiration Hours
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.validator.message(
                      "expiration",
                      expiration,
                      "required|min:1,num",
                      {
                        className: "text-danger",
                      }
                    )}
                  </Form.Group>
                </Row>
              </Form>
            )}

            <Modal.Footer>
              <Button
                sx={{ mr: "20px" }}
                onClick={this.toggleAddExpirationModal}
                className="modal-btn"
                id="modal-close-button"
                style={closeBtnStyle}
              >
                Close
              </Button>
              <Button
                className="modal-btn"
                variant="primary"
                style={addBtnStyle}
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
      </ContentWrapper>
    );
  }
}

export default ManageExpiration;
