import React from "react";

export default function SearchInput({ label, children,onChange,value }) {
    return (
        <div className="input-icon input-icon-right">
            <input type="text" className="form-control" placeholder={label} onChange={onChange} value={value}/>
            <span>
                <i className="flaticon2-search-1 icon-md"></i>
            </span>
        </div>
    )
}