import React, { Component } from "react";
import { connect } from "react-redux";
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import CardBody from '../../../components/common/CardBody';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import TabList from '../../../components/common/tabs/TabList';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { assessAttemptActions } from "../../../actions/settings/assessment/attemptAction"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class AssessmentAttempt extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newAttempt:null,
        attemptRowsPerPage:5,
        currentAttemptPage: 0,
        attemptData: [],
        attempt_sort_column: "attempt_no_id",
        attempt_sort_direction: "ASC",
        selectAttempt: '',
        editAttempt: false,
        attempt: '',
        limit: 10,
        attempt_search_term: "",
        attempt_offset: 0,
        searchError: false,
        attempt_limit:5,
        attemptId: '',
        attemptOptionsImport: [],
        selectedAttempt: []
      };
      
      this.onNewAttemptInput = this.onNewAttemptInput.bind(this);
      this.onAddNewAttempt = this.onAddNewAttempt.bind(this);
      this.generateAttemptOptions = this.generateAttemptOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllAssessmentAttempts();
    }
    
    onNewAttemptInput(e) {
        this.setState({
            newAttempt: e.target.value
        });
    }
    
    async onAddNewAttempt(e) {
        if (this.state.newAttempt && this.state.newAttempt.trim()) {
            await this.props.createAttempt({ 'no_of_attempts': parseInt(this.state.newAttempt) })
            if (this.props.successattemptcreate) {
                this.setState(
                    {
                        attempt_offset: 0,
                        currentAttemptPage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllAssessmentAttempts();
                        this.generateAttemptOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new attempt!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-attempt').modal('hide')
                                window.$("#question-attempt").val('default');
                                window.$('#question-attempt').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errorattemptupdate) {
                window.Swal.fire({
                    title: 'Failed to add a new attempt!',
                    text: this.props.errorattemptupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-attempt').modal('hide')
                        window.$("#question-attempt").val('default');
                        window.$('#question-attempt').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty attempt!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newAttempt: ''
        });
    }

    handleAttemptSearch = (event) => {
        const attempt_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (attempt_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (attempt_search_term === "" || regex.test(attempt_search_term)) {
            this.setState(
                {
                    attempt_search_term,
                    attempt_offset: 0,
                    currentAttemptPage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllAssessmentAttempts();
                }
            );
        } else {
            this.setState({ attempt_search_term, searchError: true });
        }
    };
    
    fetchAllAssessmentAttempts = async () => {
        const {
            attempt_limit,
            attempt_offset,
            attempt_sort_column,
            attempt_sort_direction,
            attempt_search_term
        } = this.state;
        await this.props.getAllAssessmentAttempts(attempt_limit, attempt_offset, attempt_sort_column, attempt_sort_direction, attempt_search_term);
        this.setState({ attempt_limit: this.state.attemptRowsPerPage });
        this.setState({ attemptData: this.props.allAssessmentAttempts })
    };

    handleAttemptPageChange = (event, newAttemptPage) => {
        const {attemptRowsPerPage} = this.state;
        const newAttemptOffset = newAttemptPage * attemptRowsPerPage;
        this.setState(
        {
            attempt_limit: attemptRowsPerPage,
            attempt_offset: newAttemptOffset,
            currentAttemptPage: newAttemptPage
        },
        () => {
            this.fetchAllAssessmentAttempts();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {attemptRowsPerPage} = this.state;
        this.setState(
        {
            attempt_limit: attemptRowsPerPage,
            attempt_offset: newOffset,
            currentAttemptPage: newPage
        },
        () => {
            this.fetchAllAssessmentAttempts();
        }
        );
    };

    //handle the data fetching as per the data per page limit gets changes
    handleAttemptRowsPerPageChange = (event) => {
        const newAttemptLimit = parseInt(event.target.value, 10);
        this.setState({attemptRowsPerPage: newAttemptLimit});
        const newAttemptPage = Math.floor(this.state.attempt_offset / newAttemptLimit)
        this.setState(
        {
            attempt_limit: newAttemptLimit,
            attempt_offset: newAttemptLimit * newAttemptPage,
            currentAttemptPage: newAttemptPage
        },
        () => {
            this.fetchAllAssessmentAttempts();
        }
        );
    };

    handleSortAttemptChange = (attempt_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { attempt_sort_column: currentAttemptSortColumn, attempt_sort_direction } = this.state;
        const newAttemptSortDirection =
        currentAttemptSortColumn === attempt_sort_column && attempt_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            attempt_sort_column,
            attempt_sort_direction: newAttemptSortDirection,
            attempt_offset: 0,
            currentAttemptPage: 0,
        },
        () => {
            this.fetchAllAssessmentAttempts();
        }
        );
    }

    getAssessmentAttempt(attempt_no_id) {
        let attemptname
        this.props.allAssessmentAttempts.map(attempt => {
            if (attempt.attempt_no_id === attempt_no_id) {
                return attemptname = attempt.no_of_attempts
            }
        })
        return attemptname
    }

    onEditAttempt(e, el) {
        this.setState({
            attemptId: el.attempt_no_id,
            attempt: e.target.value
        })
    }
    
    async onUpdateAttempt(e) {
        e.preventDefault()
        var obj = {
            attempt_no_id: this.state.attemptId,
            no_of_attempts: this.state.attempt
        }
        this.setState({ selectAttempt: '' })
        if (!this.state.attempt.trim()) {
            window.Swal.fire({
                title: 'Failed to update attempt!',
                text: 'Attempt cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateAttempt(obj);
            if (this.props.successattemptupdate) {
                // await this.props.getAllAssessmentAttempts(attempt_limit, attempt_offset, attempt_sort_column, attempt_sort_direction, attempt_search_term);
                this.fetchAllAssessmentAttempts()
                this.generateAttemptOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated attempt!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-attempt').modal('hide')
                        window.$("#question-attempt").val('default');
                        window.$('#question-attempt').selectpicker('refresh');
                    });
            }
            if (this.props.errorattemptupdate) {
                window.Swal.fire({
                    title: 'Failed to update attempt!',
                    text: this.props.errorattemptupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-attempt').modal('hide')
                        window.$("#question-attempt").val('default');
                        window.$('#question-attempt').selectpicker('refresh');
                    });

            }
        }
    }

    generateAttemptOptions() {
        let attemptOptionsImport = []
        this.props.allAssessmentAttempts.map((el, i) => {
            attemptOptionsImport.push(<Option key={el.attempt_no_id} optionName={el.no_of_attempts} value={el.attempt_no_id} />)
        });

        this.setState({
            attemptOptionsImport: attemptOptionsImport,
            selectedAttempt: attemptOptionsImport[0].props.value
        });

        if (attemptOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditAttempt(el, i, e) {
        e.preventDefault()
        this.setState({
            editAttempt: true,
            selectAttempt: i,
            attemptId: el.attempt_no_id,
            attempt: el.no_of_attempts
        })
    }

    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the attempt?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteAttempt(el);
                if (this.props.successattemptdelete) {
                    // await this.props.getAllAssessmentAttempts(attempt_limit, attempt_offset, attempt_sort_column, attempt_sort_direction, attempt_search_term);
                    this.fetchAllAssessmentAttempts()
                    this.generateAttemptOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted attempt!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-attempt').modal('hide')
                            window.$("#question-attempt").val('default');
                            window.$('#question-attempt').selectpicker('refresh');
                        });
                }
                if (this.props.errorattemptdelete) {
                    window.Swal.fire({
                        title: 'Failed to delete attempt!',
                        text: this.props.errorattemptdelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-attempt').modal('hide')
                            window.$("#question-attempt").val('default');
                            window.$('#question-attempt').selectpicker('refresh');
                        });

                }
            }
        })
    }  
    
    syncTableAttempt() {
        return (
            <>
                {this.state.attemptData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortAttemptChange("attempt_no_id")}
                                >
                                    No{" "}
                                    {this.state.attempt_sort_column === "attempt_no_id" && (
                                    <span>{this.state.attempt_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortAttemptChange("no_of_attempts")}
                                >
                                    Attempt Name{" "}
                                    {this.state.attempt_sort_column === "no_of_attempts" && (
                                    <span>{this.state.attempt_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.attemptData.map((el, i) => {
                                if (i === this.state.selectAttempt) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editAttempt ? 
                                                this.getAssessmentAttempt(el.attempt_no_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.attempt}
                                                    onChange={(e) => this.onEditAttempt(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateAttempt(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Attempt" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getAssessmentAttempt(el.attempt_no_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditAttempt(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Attempt" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete" 
                                                    title="Delete Attempt" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Attempts Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3>Manage Assessment Attempts</h3>
              </div>

              <CardBody>
                <div className="row justify-content-end">
                  <div
                    className="table-wrapper-scroll-y my-custom-scrollbar"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <TabList>
                      <QuestionAccordion
                        labelName={"Create New Attempt"}
                        onChange={this.onNewAttemptInput}
                        onClick={this.onAddNewAttempt}
                        val={this.state.newAttempt}
                      />
                    </TabList>
                    <div className="row justify-content-end col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleAttemptSearch}
                          className="col-md-18 col-18"
                        />
                      </Form.Group>
                    </div>
                    <TabContent>
                      <TabPane
                        className="tab-pane fade show active"
                        id="manage-1"
                        ariaLabelledby="manage-tab-1"
                      >
                        {/* begin: Datatable */}
                        {this.props.attempt_loading ? (
                          <div style={{ width: "75vw", overflowX: "scroll" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Attempt Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                            <div className="loading-container mb-5 mt-10">
                              <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </ReactSpinner>
                            </div>
                          </div>
                        ) : !!this.state.attemptData &&
                          this.state.attemptData.length > 0 ? (
                          this.syncTableAttempt()
                        ) : (
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Attempt Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {/* end: Datatable */}
                            </Table>
                            <div className="text-center mb-5 mt-10">
                              <label>No Attempts Available!</label>
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                            totalCount = {this.props.totalAttemptsCount}
                            currentPage = {this.state.currentAttemptPage}
                            limit = {this.state.attemptRowsPerPage}
                            handlePageChange = {this.handleAttemptPageChange}
                            handleRowsPerPageChange = {this.handleAttemptRowsPerPageChange}
                            handlePageOffsetChange = {this.handlePageOffsetChange}
                            offset = {this.state.attempt_offset}
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalAttemptsCount: state.assessAttemptReducer.totalAttemptsCount,//
        attempt_loading:state.assessAttemptReducer.loading,
        error: state.assessAttemptReducer.error,//
        // totalCount: state.assessmentsLibraryReducer.totalCount,//
        
        errorattemptdelete: state.assessAttemptReducer.errorattemptdelete,
        successattemptdelete: state.assessAttemptReducer.successattemptdelete,

        errorattemptupdate: state.assessAttemptReducer.errorattemptupdate,
        successattemptupdate: state.assessAttemptReducer.successattemptupdate,//
        
        errorattemptcreate: state.assessAttemptReducer.errorattemptcreate,
        successattemptcreate: state.assessAttemptReducer.successattemptcreate,//

        //assessmentAttempts: state.assessmentsCreateReducer.allAttempts,//
        allAssessmentAttempts: state.assessAttemptReducer.allAssessmentAttempts,//
        loading: state.assessAttemptReducer.loading,//
    }
}

    const mapActionsToProps = {
        createAttempt: assessAttemptActions.createAttempt,
        getAllAssessmentAttempts: assessAttemptActions.getAllAssessmentAttempts,
        updateAttempt: assessAttemptActions.updateAttempt,
        deleteAttempt: assessAttemptActions.deleteAttempt,
    }

    export default connect(mapStateToProps, mapActionsToProps)(AssessmentAttempt);

