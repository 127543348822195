import React from "react"
import "../dashboard.css"
import config from "../../../../../helper/config.js"
import { customerConstants } from "../../../../../helper/constants.js"
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
} from "@mui/material"

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag
}

let iconColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    iconColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    iconColor = "var(--maincolor-cmc)"
    break
  default:
    iconColor = "var(--maincolor)"
    break
}

const TeacherExams = (props) => {
  return (
    <div className="row" style={{ overflow: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="tHead">Code</TableCell>
            <TableCell className="tHead">Created On</TableCell>
            <TableCell className="tHead">Assessment Title</TableCell>
            <TableCell className="tHead">Paper Duration</TableCell>
            <TableCell className="tHead">Assessment Subject</TableCell>
            <TableCell className="tHead">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="tData">CODE1</TableCell>
            <TableCell className="tData">2023-05-04</TableCell>
            <TableCell className="tData">Test MCQ</TableCell>
            <TableCell className="tData">30 mins</TableCell>
            <TableCell className="tData">English</TableCell>
            <TableCell className="tData">
              <span className="stu-variant-class label label-lg label-danger label-inline">
                Initial
              </span>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tData">CODE2</TableCell>
            <TableCell className="tData">2024-05-12</TableCell>
            <TableCell className="tData">Maths Assessment</TableCell>
            <TableCell className="tData">60 mins</TableCell>
            <TableCell className="tData">Mathematics</TableCell>
            <TableCell className="tData">
              <span className="stu-variant-class label label-lg label-light-success label-inline">
                Ready
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

export default TeacherExams
