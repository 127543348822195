// import React, { useEffect } from 'react';

// import Button from '../form/Button';

// export default function Marks ({ value}) {
//     return (
//         <div className="row mb-2">
//             <div className="mr-2 col-md-4">Marks:</div>
//             <input type="text" className="form-control col-md-2 form-control-sm mr-2 text-center" value={value} />
//             <div className="">
//                 <Button className="btn btn-warning btn-sm" btnName="Set Marks" disabled />
//             </div>
//         </div>
//     )
// }

import React from "react"
import Button from "../form/Button"
import './Marks.css';

export default function Marks({
  value,
  label,
  onChangeMarks,
  onSetMarks,
  readOnly,
  question,
  mark_button_visible,
  editable,
  viewAllocated,
  viewAllMarks,
}) {
  window.$("#marks-setup-assessment").keypress(function (e) {
    if (e.which == 45 || e.which == 189) {
      e.preventDefault()
    }
  })

  return (
    <div
      className={
        viewAllMarks
          ? "row mb-2 d-flex justify-content-end mr-1"
          : "row mb-2 align-items-center justify-content-start"
      }
    >
      {!viewAllocated && (
        <>
          <div className={viewAllMarks ? "col-md-2 col-4" : ""}>
            {viewAllMarks ? "Set All Marks :" : ""}
          </div>
          <input
            type="number"
            step="any"
            onPaste={(e) => {
              e.preventDefault()
              return false
            }}
            onDrag={(e) => {
              e.preventDefault()
              return false
            }}
            onDrop={(e) => {
              e.preventDefault()
              return false
            }}
            min="0"
            id="marks-setup-assessment"
            className="form-control col-md-3 col-4 form-control-sm mr-2 text-center"
            value={value}
            onKeyPress={blockInvalidChar}
            onKeyDown={blockInvalidChar}
            onChange={onChangeMarks}
            readOnly={readOnly}
            viewAllMarks={viewAllMarks}
          />
        </>
      )}
      {viewAllocated && (
        <>
          <input
            type="number"
            step="any"
            onPaste={(e) => {
              e.preventDefault()
              return false
            }}
            onDrag={(e) => {
              e.preventDefault()
              return false
            }}
            onDrop={(e) => {
              e.preventDefault()
              return false
            }}
            className="form-control col-md-4 col-4 form-control-sm mr-2 text-center px-0"
            value={value}
            onKeyPress={blockInvalidChar}
            onKeyDown={blockInvalidChar}
            onChange={onChangeMarks}
            readOnly={readOnly}
          />
          <div className="col-md-6 mark-title-color">
            {"/ ".concat(String(label))}
          </div>
        </>
      )}

      {!mark_button_visible && (
        <div className="">
          <Button
            className="btn btn-warning btn-sm"
            btnName="Set Marks"
            onClick={onSetMarks}
            disabled={readOnly}
            value={question}
            viewAllMarks={viewAllMarks}
          />
        </div>
      )}
    </div>
  )
}

export const blockInvalidChar = (e) => {
  ;["e", "E", "+", "-"].includes(e.key) && e.preventDefault()

  var allowedChars = "0123456789."
  function contains(stringValue, charValue) {
    return stringValue.indexOf(charValue) > -1
  }
  var invalidKey =
    (e.key.length === 1 && !contains(allowedChars, e.key)) ||
    (e.key === "." && contains(e.target.value, "."))
  invalidKey && e.preventDefault()
}
