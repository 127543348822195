import React, { Component } from "react";
import { Spinner, Row, Col, Button, Dropdown } from "react-bootstrap";
import BootCard from "react-bootstrap/Card";
import ContentWrapper from "../../../components/common/ContentWrapper";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardBody from "../../../components/common/CardBody";
import CardTitle from "../../../components/common/CardTitle";
import {
  sweetConfirmAlert,
  sweetStatusAlert
} from "../../../components/common/SweetAlert";
import { NodeFormMapService } from "../../../service/manage/h_tree/node_form_map.service";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import SimpleReactValidator from "simple-react-validator";
import { GridColumn } from "semantic-ui-react";
import CheckBox from "../../../components/common/form/CheckBox";
import CheckBoxInline from "../../../components/common/form/CheckBoxInline";
import { history } from "../../../helper/history";
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";
import Tooltip from '@mui/material/Tooltip';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import debounce from "lodash.debounce";


const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnStyle, closeBtnStyle
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnStyle = {
      color: "var(--maincolor-cmc)",
      backgroundColor: "#E5F5FF",
      borderColor: "transparent"
    }
    addBtnStyle = {
      color: "#fff",
      backgroundColor: "var(--maincolor-cmc)",
      borderColor: "transparent"
    }
    break
  default:
    break
}

class ManageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formPrivilegesList: [],
      dropDownForms:[],
      loading: true,
      form_list_id: "",
      form_code: "",
      form_type: "",
      form_title: "",
      note: "",
      formList: [],
      selectedDropDownForm: null,
      showViewUserList:false,
      node_id: '',
      lg_user_id: '',
      lg_user_table_id:'',
      formUserList: [],
      parentFormDetail:null,
      privGroupList:[],      
      privStatusList: [],
      displayPriorityList:[],
      recRestrictionsList:[],
      privRoleList:[],
      showPrivSetup:false,
      selectedPrivGroup:null,      
      selectedPrivUsers:[],
      showAddPrivGroup:false,

      //Add Priv states
      selectedPrivRole:null,
      selectedRecRest:null,
      selectedUserRecRest:null,
      selectedStatus:null,
      displayTitle:null,
      displayOrder:null,
      displayPriority:null,
      selectedUser:null, // only for update visibility   
      selectFlgAddRemove:'1',
      parentDropDownForm:null,
      nodeTitle:null,
      breadcrumb2:null,
      limit: 5000,
      offset: 0,
      sortColumn: "user_code",
      sortDirection: "desc",
      studentSearchTerm: "",
      memberListLoading: false,
      searchError:''
    };
    this.debouncedHandleSearch = debounce(this.searchMembersListOfForm, 1000);
    this.createValidationsInstance();
    this.formInitialState = {
      form_list_id: "",
      note: "",
    };
  }

  createValidationsInstance() {
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    const node_id = this.props.mid;
    const user_id = sessionStorage.getItem("manage_user_id");
    const user_table_id = sessionStorage.getItem("table_log_id");

    const {
      breadcrumb2,
      nodeTitle
    } = this.props.nodeState;
    this.setState({
      node_id: node_id,
      lg_user_id: user_id,
      lg_user_table_id: user_table_id,
      selectedDropDownForm:null,
      parentFormDetail: !!this.props.formDetail?this.props.formDetail:null,
      breadcrumb2,
      nodeTitle
    });
    
    this.getDropdownFormList(node_id, user_id, user_table_id).then(() => {
      const formDetail = (this.props.formDetail && this.props.formDetail.dropdown_form_id && this.props.formDetail.dropdown_form_mid) ? this.getSpecificFormDropDown(this.props.formDetail.dropdown_form_id, this.props.formDetail.dropdown_form_mid) : undefined
      this.setState({
        parentDropDownForm:formDetail
      })
    })
    
  }

  getPrivPerNodeForm = async(node_id, form_list_id) => {
    this.setState({
      loading: true
    });
    await NodeFormMapService.getPrivPerNodeForm(node_id, form_list_id).then(
      (data) => {
        this.setState({
          privGroupList: data.data,
        })        
      }
    )
    this.setState({
      loading: false
    });
  }

  timeConvert = (note) => {
    let no_te =  "user(insert):1/1(2023-12-06 07:09:39.522128+00)"

  }

  getDropdownFormList = async(node_id, user_id, user_table_id) => {
    this.setState({
      loading: true
    });
    return await NodeFormMapService.getDropdownFormList(node_id, user_id, user_table_id).then(
      (data) => {
        this.setState({
          dropDownForms: data.data.map((item,index) => ({...item,'id':index}))
        })

        this.getPrivPerNodeForm(node_id, this.props.formDetail.form_list_id)        
        this.getNecessaryLists()

      }
    )
  }
  getNecessaryLists = async() => {
    this.setState({
      loading: true
    });
    await NodeFormMapService.getPrivStatus().then(
      (data) => {
        this.setState({
          privStatusList: data.data
        })
      }
    )
    await NodeFormMapService.getDisplayPrios().then(
      (data) => {
        this.setState({
          displayPriorityList: data.data
        })
      }
    )
    await NodeFormMapService.getRecRestricts().then(
      (data) => {
        this.setState({
          recRestrictionsList: data.data
        })
      }
    )
    await NodeFormMapService.getFormPrivRole().then(
      (data) => {
        this.setState({
          privRoleList: data.data
        })
      }
    )
    this.setState({
      loading: false
    });
  }
  
  handleChange = (event) => {
    let { name, value } = event.target;
    if ((value<0) && name === "displayOrder") {
      value = 0;
    }
    this.setState({ [name]: value });
  };


  handleDropDownFormSelect = (e) => {
    const {node_id,lg_user_id,lg_user_table_id, studentSearchTerm, limit, offset, sortColumn, sortDirection} = this.state  
  
    const selectedDropDownForm = this.state.dropDownForms.find(
      (form) => form.id === parseInt(e.target.value)
    );
    this.setState({
      selectedDropDownForm: selectedDropDownForm,
      selectFlgAddRemove:"1",
      studentSearchTerm:""
    }, () => {
      !!selectedDropDownForm? this.getMembersListOfForm(selectedDropDownForm.node_mid, selectedDropDownForm.form_list_id, lg_user_id,lg_user_table_id):this.setState({formUserList:[]})
    });
    
  };

  togglePrivlegeSetup = (group) => {
    const {selectedPrivGroup,    limit,
      offset,
      sortColumn,
      sortDirection,
      studentSearchTerm} = this.state
    this.setState((prev) => ({
      showPrivSetup: !prev.showPrivSetup,
    }));
    if(!!group){
      this.setState((prev) => ({
        selectedRecRest:group.flg_owner_restriction_onrecord,
        selectedUserRecRest: !!prev.parentDropDownForm ? group.flg_owner_restriction_onlist: null,
        selectedStatus:group.status,
        displayTitle:group.form_display_title,
        displayOrder:group.form_display_order,
        displayPriority:group.flg_form_display_priority,
        selectedPrivGroup: group,
      }));
      const form = this.getSpecificFormDropDown(group.user_access_form_list_id, group.user_access_node_mid)
      if(!!form &&group.user_access_user_id &&group.user_access_table_id){
        NodeFormMapService.getMembersListOfForm(   
          form.node_mid, 
          form.form_list_id,
          this.state.lg_user_id, 
          this.state.lg_user_table_id,
          limit,
          offset,
          sortColumn,
          sortDirection,
          studentSearchTerm
        ).then(
          (data) => {          
            const user = data.data.members.find(user => parseInt(user.user_id) === group.user_access_user_id && parseInt(user.user_table_id) === group.user_access_table_id)
            this.setState({
              selectedUser:user
            })
          }
        );
      }else{
        this.setState({
          selectedUser:'None'
        })
      }
      
    }else{
      this.setState({
        selectedPrivGroup: null,
        formUserList:[],
        selectedPrivUsers:[],  
        selectedRecRest:null,
        selectedUserRecRest:null,
        selectedStatus:null,
        displayTitle:null,
        displayOrder:null,
        displayPriority:null
      });
    }    
    this.validator.purgeFields();
    this.validator.hideMessages();
  }

  toggleAddPrivGroup = () => {
    if(this.state.showAddPrivGroup){
      this.setState(() => ({
        showAddPrivGroup: false,
        selectedPrivRole:null,
        selectedRecRest:null,
        selectedUserRecRest: null,
        selectedStatus:null,
        displayTitle:null,
        displayOrder:null,
        displayPriority:null,
        selectedDropDownForm: null,
        selectedPrivUsers:[],
        formUserList: [],
        selectedPrivUsers:[],
        selectFlgAddRemove:'1',
        limit: 5000,
        offset: 0,
        sortColumn: "user_code",
        sortDirection: "desc",
        studentSearchTerm: "",
      }));
    }else{
      this.setState((prev) => ({
        showAddPrivGroup: true,
        // selectedUserRecRest:!!prev.parentDropDownForm ? 0:null
      }));
    }
    this.validator.purgeFields();
    this.validator.hideMessages();
  }

  onPrivCheckBoxChange = (e,index) => {
    const selectedPrivUsers = this.state.selectedPrivUsers
    const existingIndex = selectedPrivUsers.findIndex(priv => priv === true)
    selectedPrivUsers[existingIndex] = false
    if(index !== existingIndex){
      selectedPrivUsers[index] = true
    }
    this.setState({
      selectedPrivUsers: selectedPrivUsers
    }, () => {
      if(existingIndex === -1){
        this.setState({
          selectFlgAddRemove:'1'
        })
      }
    })
  }

  getMembersListOfForm = async(node_mid, form_list_id, user_id, table_id) => {
    const { studentSearchTerm, limit, offset, sortColumn, sortDirection } =
      this.state;
      this.setState({
        memberListLoading: true,
      });
    return NodeFormMapService.getMembersListOfForm(   
      node_mid,
      form_list_id,
      user_id,
      table_id,
      limit,
      offset,
      sortColumn,
      sortDirection,
      studentSearchTerm
    ).then(
      (data) => {
        const selectedUser = []
        data.data.members.map(member => {
          selectedUser.push(false)
        })
        this.setState({
          formUserList: data.data.members,
          loading: false,
          selectedPrivUsers:selectedUser,
          memberListLoading: false,
        });
      }
    );
  }

  getSpecificFormDropDown = (form_list_id, node_mid) => {
    const form = this.state.dropDownForms.find(form =>( form.form_list_id === form_list_id && form.node_mid === node_mid))
    return form
  }

  
  isUserSelected = () => {
    const selectedPrivUsers = this.state.selectedPrivUsers
    const existingIndex = selectedPrivUsers.findIndex(priv => priv === true)
    if(existingIndex === -1){
      return false
    }else{
      return true
    }
  }

  deletePrivGoup(){
    const {
      selectedPrivGroup,
      parentFormDetail,
      node_id, 
      lg_user_id, 
      lg_user_table_id,
      formUserList,
      selectedPrivUsers,
      selectedStatus
    } = this.state
    
    let userId = 0
    const selectedUserIndex = selectedPrivUsers.findIndex(priv => priv === true)
    if(selectedUserIndex !== -1){
      userId = parseInt(formUserList[selectedUserIndex].user_id)
    }
    
    sweetConfirmAlert().then(() => {
      this.setState({
        loading: true,
      });
      NodeFormMapService.deletePrivPerNodeForm(
        lg_user_id, 
        lg_user_table_id, 
        selectedPrivGroup.node_mid, 
        selectedPrivGroup.form_list_id,
        selectedPrivGroup.user_access_node_mid,
        selectedPrivGroup.user_access_form_list_id,
        selectedPrivGroup.user_access_table_id,
        selectedPrivGroup.user_access_user_id,
        selectedPrivGroup.flg_add_remove,
        selectedPrivGroup.role_group_id,
        selectedPrivGroup.flg_form_display_priority,
        selectedPrivGroup.form_display_title,
        selectedPrivGroup.status
      ).then(
        (data) => {
          this.props.nodeProps.selectNode()
          sweetStatusAlert("Success!", "Privilege Group deleted successfully", "success");
        }
      );   
      this.togglePrivlegeSetup()
    });
  }  

  updatePrivGoup(){
    const {
      selectedPrivGroup,
      parentFormDetail,
      node_id, 
      lg_user_id, 
      lg_user_table_id,
      formUserList,
      selectedRecRest,
      selectedPrivUsers,
      selectedUserRecRest,
      displayOrder,
      displayPriority,
      displayTitle,
      selectedStatus,
      selectFlgAddRemove
    } = this.state
    if (!this.validator.fieldValid('selectedRecRest') || !this.validator.fieldValid('displayOrder') || !this.validator.fieldValid('displayTitle')) {
      this.validator.showMessages();      
      this.forceUpdate();
    }else{
      let userId = 0
      const selectedUserIndex = selectedPrivUsers.findIndex(priv => priv === true)
      if(selectedUserIndex !== -1){
        userId = parseInt(formUserList[selectedUserIndex].user_id)
      }

      sweetConfirmAlert().then(() => {
        this.setState({
          loading: true,
        });
        NodeFormMapService.addUpdatePrivPerNodeForm(
          lg_user_id, 
          lg_user_table_id, 
          selectedPrivGroup.node_mid, 
          selectedPrivGroup.form_list_id,
          selectedPrivGroup.user_access_node_mid,
          selectedPrivGroup.user_access_form_list_id,
          selectedPrivGroup.user_access_table_id,
          selectedPrivGroup.user_access_user_id,
          selectedPrivGroup.flg_add_remove,
          selectedPrivGroup.role_group_id,
          parseInt(selectedRecRest),
          parseInt(selectedUserRecRest),
          !!displayOrder ? displayOrder : 0,
          parseInt(displayPriority),
          displayTitle,
          parseInt(selectedStatus)
        ).then(
          (data) => {
            this.props.nodeProps.selectNode()
            sweetStatusAlert("Success!", "Privilege Group updated successfully", "success");
          }
        );
        this.togglePrivlegeSetup()
      });
    }
  }

  convertToSriLankaTime = (utcTimestamp) => {
    let dotSplit = utcTimestamp.split('.')
    let splitDouble = utcTimestamp.split(':')
    let splitEqual = utcTimestamp.split('=')
    let checkLog = utcTimestamp.includes('log') 
    if(dotSplit.length === 2 && splitDouble.length === 4 ){
        let brackSplit = utcTimestamp.split('(')
        const utcDate = new Date(dotSplit[0].slice(-19));
        const sriLankaDate = new Date(utcDate.getTime() + (5 * 60 + 30) * 60000)//.toISOString()//.slice(0, 19).replace("T", " ");
        const formattedTime = sriLankaDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
        const sriLankaTimeString =
        ('0' + sriLankaDate.getDate()).slice(-2) + '-' +
        ('0' + (sriLankaDate.getMonth() + 1)).slice(-2) + '-' +
        sriLankaDate.getFullYear() + '   ' + formattedTime
        const neededString = (brackSplit[0]+'('+brackSplit[1]+'('+sriLankaTimeString+')')
        return sriLankaTimeString
    }
    if(splitEqual.length === 2 && checkLog){
      return splitEqual[1].slice(0,10)
    }

    return undefined;
  }
  
  addPriveGroup() {
    const {
      parentFormDetail,
      node_id, 
      lg_user_id, 
      lg_user_table_id,
      selectedDropDownForm,
      formUserList,
      selectedPrivRole,
      selectedRecRest,
      selectedPrivUsers,
      selectedUserRecRest,
      displayOrder,
      displayPriority,
      displayTitle,
      selectedStatus,
      selectFlgAddRemove
    } = this.state
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
    }else{
      const flg_add_remove = !!selectFlgAddRemove ? 1 : -1
    
      let userId = null
      const selectedUserIndex = selectedPrivUsers.findIndex(priv => priv === true)
      if(selectedUserIndex !== -1){
        userId = parseInt(formUserList[selectedUserIndex].user_id)
      }
      
      sweetConfirmAlert().then(() => {
        let selectRecDrop = (null === selectedUserRecRest) ? null : parseInt(selectedUserRecRest)
        this.setState({
          loading: true,
        });
        NodeFormMapService.addUpdatePrivPerNodeForm(
          lg_user_id, 
          lg_user_table_id, 
          node_id, 
          parentFormDetail.form_list_id,
          selectedDropDownForm.node_mid,
          selectedDropDownForm.form_list_id,
          selectedDropDownForm.form_list_table_id,
          userId,
          flg_add_remove,
          parseInt(selectedPrivRole),
          parseInt(selectedRecRest),
          selectRecDrop,
          !!displayOrder ? displayOrder : 0,
          parseInt(displayPriority),
          displayTitle,
          parseInt(selectedStatus)
        ).then(
          (data) => {
            this.props.nodeProps.selectNode()
            sweetStatusAlert("Success!", "Privilege Group added successfully", "success");
          }
        );
        this.toggleAddPrivGroup()
      });
    }
    
  }

  
  handlePrivRole = (value) => {
    this.setState({ selectedPrivRole: value });
  }

  handleSearch = (event) => {
    const studentSearchTerm = event.target.value.trim();
    const regex = /^[a-zA-Z0-9\-@\[\]\s.&]*$/;

    if (studentSearchTerm === "" || regex.test(studentSearchTerm)) {
      this.setState(
        {
          studentSearchTerm,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        () => {
          // Call the debounced version of fetchStudentData
          this.debouncedHandleSearch();
        }
      );
    } else {
      this.setState({ studentSearchTerm, searchError: true });
    }
  };

  
  searchMembersListOfForm = () => {
    const { lg_user_id, lg_user_table_id, selectedDropDownForm } = this.state;

    if (selectedDropDownForm) {
      this.getMembersListOfForm(
        selectedDropDownForm.node_mid,
        selectedDropDownForm.form_list_id,
        lg_user_id,
        lg_user_table_id
      );
    }
  };

  findItem = (id, list) => {
    return list.find(
      (item) => item.id === id
    );
  }

  render() {
    const {
      parentFormDetail,
      formPrivilegesList,
      dropDownForms,
      loading,
      form_list_id,
      form_code,
      form_type,
      form_title,
      note,
      selectedForm,
      selectedDropDownForm,
      formUserList,
      privStatusList,
      displayPriorityList,
      recRestrictionsList,
      privRoleList,
      privGroupList,
      showPrivSetup,
      selectedPrivGroup,
      selectedPrivUsers,
      selectedRecRest,
      selectedStatus,
      selectedUserRecRest,
      selectedUser,
      displayTitle,
      displayOrder,
      displayPriority,
      selectFlgAddRemove,
      parentDropDownForm,
      breadcrumb2 , 
      nodeTitle,
      selectedPrivRole
    } = this.state;
    const path = !!history.location.pathname ? history.location.pathname:undefined

    this.validator.purgeFields();
    let selectUserLiteral = 'Selected User'
    if(selectedPrivGroup && selectedPrivGroup.flg_add_remove === -1){
      selectUserLiteral = 'Removed User'
    }
    const privGroupLiteral = (group) => {
      let privSetup = 'Group user based' 
      if(!!group.user_access_user_id && group.flg_add_remove === 1){
        privSetup = 'Single user based - Selected'
      }else if(!!group.user_access_user_id && group.flg_add_remove === -1){
        privSetup = 'Single user based - Removed'
      }
      return privSetup
    }
    const selectedPrivRoleObj = (privRoleList.length && selectedPrivRole) ? privRoleList.find(row => row.id === parseInt(selectedPrivRole)) : undefined
    const formattedBreadcrumb = breadcrumb2? breadcrumb2.replaceAll(">", " > ") : ""
    const formName = this.props.formDetail? `Privilege Groups of ${this.props.formDetail?.form_title} (${this.props.formDetail?.form_code})` :'Privilege Groups'
    const formNameAddPriv = this.props.formDetail? `Privilege Details - ${this.props.formDetail?.form_title} (${this.props.formDetail?.form_code})` :'Privilege Details'
    const dropdownFormattedBreadcrumb = parentDropDownForm ? breadcrumb2.replaceAll(">", " > ") : ""
    const dropdowmFormTitle = `${parentDropDownForm?.form_display_title} (${dropdownFormattedBreadcrumb})`
    const dropdownFormName = dropdownFormattedBreadcrumb ? dropdowmFormTitle : "There is no dropdown on this Form"
    

    return (
      <>
        <ContentWrapper disabled={path === "/manage-search"}>
          <Row style={{ backgroundColor: "#fff", height: "90vh", overflow: "auto" }}>
            <Col md={12} className="px-0">
              <Card>
                <CardHeader>
                {(this.props.nodeState.path !== 'node' ) && <p style={{ color: 'gray' }}>
                  {(formattedBreadcrumb) ? `${formattedBreadcrumb &&formattedBreadcrumb}`: ""}
                  </p>}
                  <div>
                    <CardTitle cardLabel={formName}  />
                    <div className="pt-2">
                      {!!parentDropDownForm && (<span style={{ fontWeight: "bold" }}>
                        Selected Dropdown is from:
                        &nbsp;&nbsp;
                      </span>)}
                      {dropdownFormName}
                    </div>

                  </div>
                  <div className="text-center">
                    <Button
                      className="insert-btn"
                      variant="primary"
                      onClick={this.toggleAddPrivGroup}
                    >
                      Add new
                    </Button>
                    <Button
                      className="insert-btn"
                      variant="primary"
                      onClick={() =>this.props.manageFormSetup()}
                    >
                      Back
                    </Button>
                  </div>
                </CardHeader>
                {this.state.loading ? 
                (<div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px"
                  }}
                >
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: "var(--maincolor)" }}
                >
                <span className="sr-only">Loading...</span>
                </Spinner>
                </div>) : (
                <CardBody>
                  <div>
                  {privGroupList.length > 0 ?(
                    <div>
                      {privGroupList.map((group) => {
                        const specificPrivFormFromDropDown  = this.getSpecificFormDropDown(group.user_access_form_list_id, group.user_access_node_mid)
                                                
                        return (
                          <BootCard
                            key={`${group.node_mid}${group.form_list_id}`}
                            className="mb-4 p-0"
                            style={{ 
                              border: "1px solid #C8C9F9"
                            }}
                          >
                            <BootCard.Header className="p-0 pb-2">
                              <div
                                className="row d-flex justify-content-between"
                                style={{
                                  background: "#F9F9FF",
                                  borderBottom: "1px solid #C8C9F9",
                                  color: "var(--maincolor)",
                                  padding: "2px 10px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  borderTopRightRadius: "0.42rem",
                                  borderTopLeftRadius: "0.42rem",
                                }}
                              >
                                <p className="d-flex align-items-center">
                                  {!!dropDownForms.length
                                    ? `User Group: ${
                                        group.user_access_form_list_id !==
                                          null &&
                                        group.user_access_node_mid !==
                                          null &&
                                        specificPrivFormFromDropDown &&
                                        specificPrivFormFromDropDown.form_display_title
                                      }`
                                    : ""}
                                </p>
                                <Button
                                  onClick={() => this.togglePrivlegeSetup(group)}
                                  className="btn btn-icon btn-primary btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                                  title="Update Detail"
                                />
                              </div>

                              <div className="row d-flex py-5">
                                <div className="col-md-6">
                                  <p>
                                    <span
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {`${group.form_display_title} : `}
                                      &nbsp;&nbsp;
                                    </span>
                                    {privGroupLiteral(group)}
                                  </p>
                                </div>
                                <div className="col-md-6 mb-0">
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {!!privRoleList.length
                                      ? "Privilege Given: "
                                      : " "}
                                    &nbsp;&nbsp;
                                  </span>
                                  {!!privRoleList.length
                                    ? `${
                                        this.findItem(
                                          group.role_group_id,
                                          privRoleList
                                        ).text_str_ex
                                      }`
                                    : ""}
                                </div>
                              </div>
                            </BootCard.Header>
                            <BootCard.Body
                              className="p-0"
                              style={{ 
                                borderBottomRightRadius: "0.42rem",
                                borderBottomLeftRadius: "0.42rem",
                              }}
                            >
                              <div className="row py-5">
                                <div className="col-6">
                                  <div className="row">
                                    <span style={{ fontWeight: "bold" }}>
                                      {" "}
                                      {!!recRestrictionsList.length
                                        ? "Record Restriction: "
                                        : " "}
                                      &nbsp;&nbsp;
                                    </span>
                                    {!!recRestrictionsList.length
                                      ? ` ${
                                          group.flg_owner_restriction_onrecord !==
                                            null ?
                                          this.findItem(
                                            group.flg_owner_restriction_onrecord,
                                            recRestrictionsList
                                          ).text_str :
                                          'Not given'
                                        }`
                                      : ""}
                                  </div>
                                  <div className="row mt-3">
                                    <span style={{ fontWeight: "bold" }}>
                                      {!!recRestrictionsList.length
                                        ? "Dropdown Restriction: "
                                        : " "}
                                      &nbsp;&nbsp;
                                    </span>
                                    {!!recRestrictionsList.length
                                      ? ` ${
                                          group.flg_owner_restriction_onlist !==
                                            null ?
                                          this.findItem(
                                            group.flg_owner_restriction_onlist,
                                            recRestrictionsList
                                          ).text_str :
                                          'Not given'
                                        }`
                                      : ""}
                                  </div>
                                  <div className="row mt-3">
                                    <span style={{ fontWeight: "bold" }}>
                                      Display Order: &nbsp;&nbsp;
                                    </span>{" "}
                                    {group.form_display_order}
                                  </div>
                                </div>

                                <div className="col-6">
                                  {/* <div className="row">
                              <span style={{ fontWeight: 'bold'}}>{!!privStatusList.length ? "Privilege Status:" :" "}&nbsp;&nbsp;</span>{!!privStatusList.length ? ` ${group.status && this.findItem(group.status, privStatusList).text_str}`: ''}
                              </div> */}
                                  {/* <div className="row mt-3">
                              <span style={{ fontWeight: 'bold'}}>{!!displayPriorityList.length ? "Display Priority:" :" "}&nbsp;&nbsp;</span>{!!displayPriorityList.length ? ` ${group.flg_form_display_priority!== null && this.findItem(group.flg_form_display_priority, displayPriorityList).text_str}`: ''}
                              </div> */}
                                  {/* <div className="row">
                              {this.convertToSriLankaTime(group.note)?this.convertToSriLankaTime(group.note):group.note}
                              </div> */}
                                  <div className="row ">
                                    <span style={{ fontWeight: "bold" }}>
                                      Last update: &nbsp;&nbsp;
                                    </span>
                                    {this.convertToSriLankaTime(group.note)
                                      ? this.convertToSriLankaTime(group.note)
                                      : group.note}
                                  </div>
                                  <div className="row mt-3">
                                    <span style={{ fontWeight: "bold" }}>
                                      Display Title: &nbsp;&nbsp;
                                    </span>{" "}
                                    {group.form_display_title}
                                  </div>
                                </div>
                              </div>
                            </BootCard.Body>
                          </BootCard>
                        )})}      
                    </div>
                  ) :  (
                    <p style={{ textAlign: "center", marginTop: "20px" }}>
                      No privilege groups are available!
                    </p>
                  )}                  
                  </div>
                </CardBody>
              )}
              </Card>
            </Col>
          </Row>
        </ContentWrapper>

        
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showPrivSetup}
          onHide={() =>
            this.togglePrivlegeSetup()
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Privilege Setup</Modal.Title>
          </Modal.Header>
          <div>
            <Modal.Body>
            <Card>
                <CardHeader>
                  <CardTitle cardLabel={`Manage Form Privilege - (${selectedPrivGroup && privGroupLiteral(selectedPrivGroup)})`}/>
                </CardHeader>
                <CardBody>
                <GridColumn className="col-md-12 px-0">
                        <div>

                          <div style={{ border: "1px" }}>

                          {/* <Row className="mt-0">
                          <GridColumn className="col-md-12 pl-0 pr-0">
                                <Form.Group
                                    as={Col}
                                    controlId="statusId"
                                    className="mb-4"
                                  ><div className="row space-between !important ">
                                    <Form.Label>Selected Priv Group :</Form.Label>
                                    <span>
                                    <Form.Control
                                      type="text"
                                      name="selectedUser"
                                      defaultValue={!!selectedPrivGroup ? !!dropDownForms.length&& this.getSpecificFormDropDown(selectedPrivGroup.user_access_form_list_id, selectedPrivGroup.user_access_node_mid) && this.getSpecificFormDropDown(selectedPrivGroup.user_access_form_list_id, selectedPrivGroup.user_access_node_mid).form_display_title: ""}
                                      disabled
                                    /></span></div>
                                  </Form.Group>  
                                  </GridColumn>
                          </Row> */}

                          <Row>
                              <Form.Group
                                  as={Col}
                                  controlId="statusId"
                                  className="mb-4"
                                   >
                                  <div className="d-flex">
                                  <Form.Label><span style={{ fontWeight: 'bold' }}>Selected Privilege Group :&nbsp;&nbsp;</span></Form.Label>
                                  <span>
                                    <div className="form-control" style={{ appearance: 'none',border: 'none', padding: '0' }}>
                                  {!!selectedPrivGroup ? !!dropDownForms.length&& this.getSpecificFormDropDown(selectedPrivGroup.user_access_form_list_id, selectedPrivGroup.user_access_node_mid) && this.getSpecificFormDropDown(selectedPrivGroup.user_access_form_list_id, selectedPrivGroup.user_access_node_mid).form_display_title: ""}
                               </div>
                               </span>
                               </div>
                                </Form.Group>      
                          </Row>

                          {/* <Row className="mt-0">
                          <GridColumn className="col-md-12 pl-0 pr-0">
                              <Form.Group
                                  as={Col}
                                  controlId="statusId"
                                  className="mb-4"
                                ><div className="row space-between !important ">
                                  <Form.Label><span style={{ fontWeight: 'bold',marginTop: '5px'}}>{selectUserLiteral} :&nbsp;&nbsp;</span></Form.Label>
                                  <span>
                                  <Form.Control
                                    type="text"
                                    name="selectedUser"
                                    defaultValue={!!selectedUser ? selectedUser.data_concat:""}
                                    disable
                                     /></span></div>
                                </Form.Group>   
                                </GridColumn>   
                          </Row> */}
                         
                     {selectedPrivGroup && selectedPrivGroup.user_access_user_id && <Row>
                              <Form.Group
                                  as={Col}
                                  controlId="statusId"
                                  className=""
                                  >
                                  <div className="d-flex">
                                  <Form.Label><span style={{ fontWeight: 'bold' }}>{selectUserLiteral}:&nbsp;&nbsp;</span></Form.Label>
                                  <span>
                                    <div className="form-control" style={{appearance: 'none',border: 'none', padding: '0'}}>
                                  {!!selectedUser ? selectedUser.data_concat:""}
                                  </div>
                                  </span>
                                  </div>
                                </Form.Group>      
                          </Row>}
                          

                          <Row className="px-0">

                              <GridColumn className="col-md-6 px-0">                          
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="statusId"
                                  className="mb-4"
                                >
                                  <div className="d-flex">
                                  <Form.Label className="d-flex align-items-center m-0"><span style={{ fontWeight: 'bold' }}>Privilege Role :&nbsp;&nbsp;</span></Form.Label>
                                  <span>
                                  <Form.Select
                                    aria-label="Default select example"
                                    value={!!selectedPrivGroup ? selectedPrivGroup.role_group_id: ""}
                                    disabled
                                    style={{appearance: 'none',border: 'none', marginTop: '0', padding: '0'}}
                                  >
                                    <option value="">Select</option>
                                    {privRoleList.map((row) => (
                                      <option
                                        key={row.id}
                                        value={row.id}
                                      >
                                        {row.text_str_ex}
                                      </option>
                                    ))}
                                  </Form.Select></span></div>
                                </Form.Group>
                              </GridColumn>

                              
                              
                          </Row>


                          <Row className="mt-3 px-0">  

                          <GridColumn className="col-md-6 px-0">
                              <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="statusId"
                                  className="mb-4"
                                >
                                  <Form.Label>
                                    Record restriction​​ *
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    value={selectedRecRest}
                                    onChange={this.handleChange}
                                    name="selectedRecRest"
                                  >
                                    <option value="">Select</option>
                                    {recRestrictionsList.map((row) => (
                                      <option
                                        key={row.id}
                                        value={row.id}
                                      >
                                        {row.text_str}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {this.validator.message(
                                  "selectedRecRest",
                                  selectedRecRest,
                                  "required",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required: "The secord restriction is required.",
                                    },
                                  }
                                )}
                                </Form.Group>                       
                              </GridColumn>
                            {parentDropDownForm &&    
                            <GridColumn className="col-md-6 px-0">                    
                            <Form.Group
                                as={Col}
                                md="12"
                                controlId="statusId"
                                className="mb-4"
                              >
                                <Form.Label>
                                Dropdown Restriction​​
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={this.handleChange}
                                  name="selectedUserRecRest"
                                  value={selectedUserRecRest}
                                  disabled={!parentDropDownForm}
                                >
                              <option value={null}>Select</option>
                              {recRestrictionsList.map((row) => (
                                <option
                                  key={row.id}
                                  value={row.id}
                                >
                                  {row.text_str}
                                </option>
                              ))}
                                </Form.Select>
                              </Form.Group>
                              </GridColumn>
                            }
                            </Row>



                          <Row className="mt-5 px-0">
                              <GridColumn className="col-md-6 px-0">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="displayTitleId"
                                  className="mb-4"
                                >
                                  <Form.Label>Display Title​ *​</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="displayTitle"
                                    defaultValue={displayTitle}
                                    placeholder="Display Title​​"
                                    onChange={this.handleChange}
                                  />
                                  {this.validator.message(
                                  "displayTitle",
                                  displayTitle,
                                  "required",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required: "The display title is required.",
                                    },
                                  }
                                )}
                                </Form.Group>
                              </GridColumn >
                              <GridColumn className="col-md-6 px-0">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="displayOrdId"
                                  className="mb-4"
                                >
                                  <Form.Label>Display Order​​ *​</Form.Label>
                                  <Form.Control
                                    type="number"
                                    name="displayOrder"
                                    step={1}
                                    min={0}
                                    max={100}
                                    value={displayOrder}
                                    placeholder="Display Order"
                                    onChange={this.handleChange}
                                  />
                                  {this.validator.message(
                                  "displayOrder",
                                  displayOrder,
                                  "required",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required: "The display order is required.",
                                    },
                                  }
                                )}
                                </Form.Group>
                              </GridColumn>
                                
                            </Row>

                            

                          <Row className="mt-3 px-0">
                          {/* <GridColumn className="col-md-6 pl-0 pr-0">
                                  <Form.Group 
                                  as={Col}
                                  md="12"
                                  controlId="displayPriIdId"
                                  className="mb-4"
                                  >
                                    <Form.Label>Display Priority​​</Form.Label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      value={displayPriority}
                                      onChange={this.handleChange}
                                      name="displayPriority"
                                    >
                                    <option value="">Select</option>
                                    {displayPriorityList.map((row) => (
                                      <option
                                        key={row.id}
                                        value={row.id}
                                      >
                                        {row.text_str}
                                      </option>
                                    ))}
                                    </Form.Select>
                                  </Form.Group>
                                </GridColumn> */}
                              {/* <GridColumn className="col-md-12 pl-0 pr-0">
                                <Form.Group
                                  as={Col}
                                  md="12"
                                  controlId="statusId"
                                  className="mb-4 pl-0"
                                >
                                  <Form.Label>Status</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    value={selectedStatus}
                                    onChange={this.handleChange}
                                    name="selectedStatus"
                                  >
                                    <option value="">Select</option>
                                    {privStatusList.map((row) => (
                                      <option
                                        key={row.id}
                                        value={row.id}
                                      >
                                        {row.text_str}
                                      </option>
                                    ))}
                                  </Form.Select>
                                  {this.validator.message(
                                  "selectedStatus",
                                  selectedStatus,
                                  "required",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required: "The selected status is required.",
                                    },
                                  }
                                )} 
                                </Form.Group>
                              </GridColumn> */}
                          </Row>

                              
                          
                            
                            
                            {/* <GridColumn
                                  className="col-md-12"
                                  style={{ alignSelf: "end" }}
                                >
                                  <Row style={{ justifyContent: "right" }}>
                                    <SaveIcon
                                      sx={{ fontSize: 40, color: blue[500] }}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.updatePrivGoup()}
                                    />
                                    <DeleteForeverIcon
                                      sx={{ fontSize: 40, color: orange[500] }}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.deletePrivGoup()}
                                    />
                                  </Row>
                                </GridColumn> */}
                                <Modal.Footer>
                                            
                                            <Button variant="primary" style={ addBtnStyle } onClick={() => this.updatePrivGoup()}>
                                                Save changes
                                            </Button>
                                            <Button variant="danger" onClick={() => this.deletePrivGoup()}>
                                               Delete
                                            </Button>
                                            <Button
                                                id="modal-close-button"
                                                style={ closeBtnStyle }
                                                onClick={this.togglePrivlegeSetup}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                          </div>
                          
                        </div>
                      </GridColumn>
                </CardBody>
              </Card>
            </Modal.Body>
          </div>
        </Modal>
        <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show={this.state.showAddPrivGroup}
              onHide={() =>
                this.toggleAddPrivGroup()
              }
            >
              {/* <Modal.Header closeButton>
                <Modal.Title>Add New Privilege Group</Modal.Title>
              </Modal.Header> */}
              <div>
                <Modal.Body>
                {/* <Card> */}
                    <CardHeader>
                    <CardTitle cardLabel={formNameAddPriv} />                      
                      
                    </CardHeader>
                    <CardBody>
                      <Row className="mt-3">
                        <GridColumn className="col-md-6">
                        <Form.Group
                            as={Col}
                            md="12"
                            controlId="statusId"
                            className="mb-4 px-0"
                        >
                            <Form.Label>Privilege Role​ *</Form.Label>
                            <Dropdown onSelect={this.handlePrivRole}>              
                              <Tooltip title={selectedPrivRoleObj ? selectedPrivRoleObj.text_str_ex : 'Non Selected'} arrow>
                                <Dropdown.Toggle
                                  variant="primary"
                                  disabled={!privRoleList && !privRoleList.length}
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#fff",
                                    border: "1px solid #E4E6EF",
                                    color: "var(--secondary-text-color)",
                                    fontWeight: "400"
                                  }}
                                >
                                  {selectedPrivRoleObj ? selectedPrivRoleObj.text_str : 'Non Selected'}
                                </Dropdown.Toggle>
                              </Tooltip>                                       
                              <Dropdown.Menu className="w-100">
                                {privRoleList.map((row) => (
                                  <Tooltip title={row.text_str_ex} arrow>
                                    <Dropdown.Item
                                    key={row.id}
                                    eventKey={row.id}                                           
                                  >
                                  <div>
                                  {row.text_str}
                                  </div>
                                  </Dropdown.Item>
                                  </Tooltip>                                          
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            {this.validator.message(
                            "selectedPrivRole",
                            selectedPrivRole,
                            "required",
                            {
                              className: "text-danger",
                              messages: {
                                required: "The privilege role is required.",
                              },
                            }
                          )}
                        </Form.Group>
                        </GridColumn>
                                              
                        
                      </Row>  

                      <Row className="mt-3">
                        <GridColumn className="col-md-6">
                        <Form.Group
                            as={Col}
                            md="12"
                            controlId="statusId"
                            className="mb-4 px-0"
                        >
                            <Form.Label>
                            Record restriction​​ *
                            </Form.Label>
                            <Form.Select
                            aria-label="Default select example"
                            onChange={this.handleChange}
                            name="selectedRecRest"
                            >
                            <option value="">Select</option>
                            {recRestrictionsList.map((row) => (
                                <option
                                key={row.id}
                                value={row.id}
                                >
                                {row.text_str}
                                </option>
                            ))}
                            </Form.Select>                        
                          {this.validator.message(
                            "selectedRecRest",
                            selectedRecRest,
                            "required",
                            {
                              className: "text-danger",
                              messages: {
                                required: "The record restriction is required.",
                              },
                            }
                          )}
                        </Form.Group>
                        </GridColumn>
                        {parentDropDownForm && 
                       <GridColumn className="col-md-6">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="statusId"
                          className="mb-4 px-0"
                        >
                          <Form.Label>
                          Dropdown Restriction​​
                          </Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={this.handleChange}
                            name="selectedUserRecRest"
                            disabled={!parentDropDownForm}
                          >
                        <option value={null}>Select</option>
                        {recRestrictionsList.map((row) => (
                          <option
                            key={row.id}
                            value={row.id}
                          >
                            {row.text_str}
                          </option>
                        ))}
                          </Form.Select>
                        </Form.Group>
                        </GridColumn>} 
                        {/* <GridColumn className="col-md-6">
                          <Form.Group 
                            controlId="statusId" 
                            className="mb-4" 
                            as={Col}
                            md="12">
                            <Form.Label>Display Priority​​</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={this.handleChange}
                              name="displayPriority"
                            >
                            <option value="">Select</option>
                            {displayPriorityList.map((row) => (
                              <option
                                key={row.id}
                                value={row.id}
                              >
                                {row.text_str}
                              </option>
                            ))}
                            </Form.Select>                       
                          {this.validator.message(
                            "displayPriority",
                            displayPriority,
                            "required",
                            {
                              className: "text-danger",
                              messages: {
                                required: "The display priority is required.",
                              },
                            }
                          )}
                          </Form.Group>  
                        </GridColumn> */}
                        
                      </Row>


                      <Row className=" mt-3">
                      <GridColumn className="col-md-6">
                          <Form.Group
                            as={Col}
                            controlId="statusId"
                            className="mb-4 px-0"
                          >
                            <Form.Label>Display Order​​ *​</Form.Label>
                            <Form.Control
                              type="number"
                              name="displayOrder"
                              step={1}
                              min={0}
                              max={100}
                              value={displayOrder}
                              defaultValue={0}
                              placeholder="Display Order"
                              onChange={this.handleChange}
                            />                        
                            {this.validator.message(
                              "displayOrder",
                              displayOrder,
                              "required",
                              {
                                className: "text-danger",
                                messages: {
                                  required: "The display order is required.",
                                },
                              }
                            )}
                          </Form.Group> 
                        </GridColumn>
                        <GridColumn className="col-md-6">
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="statusId"
                            className="mb-4 px-0"
                          >
                            <Form.Label>Display Title​​ *</Form.Label>
                            <Form.Control
                              type="text"
                              name="displayTitle"
                              onChange={this.handleChange}
                            />                        
                            {this.validator.message(
                              "displayTitle",
                              displayTitle,
                              "required",
                              {
                                className: "text-danger",
                                messages: {
                                  required: "The display title is required.",
                                },
                              }
                            )}
                          </Form.Group>  
                        </GridColumn>
                        
                      
                        
                        {/*
                        <GridColumn className="col-md-6">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="statusId"
                          className="mb-4"
                        >
                          <Form.Label>Status</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            onChange={this.handleChange}                       
                            name="selectedStatus"
                          >
                            <option value="">Select</option>
                            {privStatusList.map((row) => (
                              <option
                                key={row.id}
                                value={row.id}
                              >
                                {row.text_str}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                        </GridColumn> */}
                      </Row>  

                      <Row className="col-12 pr-0  mt-3">
                      <GridColumn className="col-md-12 px-0 mb-4">
                      <Form.Group
                            as={Col}
                            md="12"
                            controlId="statusId"
                            className="pl-0"
                          >
                            <div className="row justify-content-between">
                              <Form.Label>User Groups ​*</Form.Label>
                            </div>
                            <div className="row justify-content-between px-0">
                              <div class="col-12 px-0">
                              <Form.Select
                                  aria-label="Default select example"
                                  value={!!selectedDropDownForm ? selectedDropDownForm.id : ""}
                                  onChange={this.handleDropDownFormSelect}
                                  className="mb-2"
                                  name="user_group"
                                >
                                  <option value="">Select</option>
                                  {dropDownForms.map((form) => {
                                      const cleanTitle = (title) =>
                                      title.replace(/\[.*?\]\s*/, "");
                                    return (
                                      <option key={form.id} value={form.id}>
                                        {(!!form.breadcrumb
                                          ? `${form.breadcrumb}  `
                                          : "") + cleanTitle(form.form_display_title)}
                                      </option>
                                    );
                                  })}
                                </Form.Select>                              
                                {this.validator.message(
                                  "user_group",
                                  !!selectedDropDownForm?selectedDropDownForm.id:undefined,
                                  "required",
                                  {
                                    className: "text-danger",
                                    messages: {
                                      required: "The user group is required.",
                                    },
                                  }
                                )}
                              </div> 
                            </div>
                            {/* <Form.Label>
                              Please select the FORM the list of users
                              need to be taken from (i.e. USER-GROUP*)​​
                            </Form.Label> */}
                          </Form.Group>      
                      </GridColumn>
                      {/* <Row className="mt-0">
                          <div className="row justify-content-between">
                              <Form.Label><span style={{ fontWeight: 'bold', marginLeft: '12px' }}>Location :</span> &nbsp;&nbsp;{(!!selectedDropDownForm && selectedDropDownForm.breadcrumb)&& `(${selectedDropDownForm.breadcrumb})`}​</Form.Label>
                            </div>
                            </Row>  */}

                            {!!this.isUserSelected() && <GridColumn className="col-md-12 px-0 mb-4">
                                <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="statusId"
                                    className="mb-4 pl-0"
                                >
                                    <Form.Label>
                                    User Selection​​
                                    </Form.Label>
                                    <Form.Select
                                    aria-label="Default select example"
                                    onChange={this.handleChange}
                                    name="selectFlgAddRemove"
                                    value={selectFlgAddRemove}
                                    >
                                    <option
                                    key={0}
                                    value=""
                                    >
                                    Remove
                                    </option>
                                        <option
                                        key={1}
                                        value='1'
                                        >
                                        Choose
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                                </GridColumn>}
                          
                        </Row>
                        {/* {selectedDropDownForm && <h4>{selectedDropDownForm.form_display_title}</h4>} */}
                        {!!selectedDropDownForm && <div className="col-md-12 col-12">
                          {/* <CardTitle cardLabel={this.props.state.form_display_title} /> */}
                          <Form.Group
                            controlId="exampleForm.ControlInput1"
                            className="mb-2"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Search User"
                              onChange={this.handleSearch}
                              isInvalid={this.state.searchError}
                              // style={{ width: "300px" }}
                              className="search-bar"
                              value={this.state.studentSearchTerm}
                            />
                            {this.state.searchError && (
                              <Form.Control.Feedback type="invalid">
                                Invalid input
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </div>}
                      {this.state.memberListLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                          }}
                        >
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "var(--maincolor)" }}
                          >
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </div>
                      ) : formUserList.length > 0 ? (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell className="thead">User Code</TableCell>
                              <TableCell className="thead">User Detail</TableCell>
                              <TableCell className="thead">Select</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {formUserList.map((user, index) => {
                              let splits = user.data_concat
                                ? user.data_concat.split(" ")
                                : [];

                              return (
                                <TableRow
                                  key={`${user.user_id}${user.user_table_id}`}
                                >
                                  <TableCell className="tData">
                                    {user.user_code}
                                  </TableCell>
                                  <TableCell className="tData">
                                    {user.first_name}{" "}
                                    {user.last_name}
                                  </TableCell>
                                <TableCell>
                                  <Form.Group
                                    as={Col}
                                    md="12"
                                    controlId="statusId"
                                  >
                                    <CheckBoxInline>
                                      <CheckBox
                                        onChange={(e) => {
                                          this.onPrivCheckBoxChange(e,index)
                                        }}
                                        checked={selectedPrivUsers[index]}
                                        style ={{
                                          color: "#5458AF",
                                        }}
                                      />
                                    </CheckBoxInline>
                                  </Form.Group>
                                </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      ) : (
                        <p style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "20px" }}>
                          No data available.
                        </p>
                      )}
                                
                    </CardBody>
                    <Modal.Footer>
                    <Button
                        className="insert-btn"
                        variant="primary"
                        style={ addBtnStyle }
                        onClick={()=>this.addPriveGroup()}
                      >
                        Add
                      </Button>
                    <Button
                        id="modal-close-button"
                        style={ closeBtnStyle }
                        onClick={this.toggleAddPrivGroup}>
                        Close
                    </Button>
                </Modal.Footer>
                  {/* </Card> */}
                </Modal.Body>
              </div>
        </Modal>
      </>
    );
  }
}

export default ManageForm;
