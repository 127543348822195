import {descriptiveTypes} from '../types';

const initialState = {
    question_id: '',
    loading: false,
    success: '',
    error: '',
    text: ''
}

export function descriptiveReducer(state = initialState, action){
    switch (action.type){
        case descriptiveTypes.DESCRIPTIVE_PAGE_REFRESH:
            return {
                ...state,
                question_id: '',
                loading: false,
                success: '',
                error: '',
                text: ''
            }
        case descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION:
            return {
                ...state,
                loading: true,
                text: 'Creating Question'
            }
        case descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_SUCCESS:
            return {
                ...state,
                question_id: action.question.question_id,
                loading: false,
                success: 'Question created successfully'
            }
        case descriptiveTypes.CREATE_DESCRIPTIVE_QUESTION_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        case descriptiveTypes.ADD_ANSWER:
            return {
                ...state,
                loading: false,
                text: 'Add answer',
                error: '',
                success: ''

            }
        case descriptiveTypes.ADD_ANSWER_REQUEST:
            return {
                ...state,
                loading: true,
                text: 'Adding Answer'
            }
        case descriptiveTypes.ADD_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'Answer added successfully'
            }
        case descriptiveTypes.ADD_ANSWER_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            }
        default:
            return state
    }
}
