import React from 'react';

export default function StepContent ({ children, dataWizardState, wizardTitle }) {

    return (
        
        <div className="pb-5" data-wizard-type="step-content" data-wizard-state={dataWizardState}>

            {/* begin::Title */}
            <div className="pt-lg-0 pt-5 pb-15 text-center text-md-left">
                <h3 className="font-weight-bolder text-dark font-size-h2">{wizardTitle}</h3>
            </div>
            {/* begin::Title */}

            {children}

        </div>

    )
}


