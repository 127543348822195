import React, { Component } from "react"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import underline from "../assets/images/underline.svg"
import book from "../assets/images/book.svg"
import child from "../assets/images/child.svg"
import arrowone from "../assets/images/arrowone.svg"
import arrowtwo from "../assets/images/arrowtwo.svg"
import imagegroup from "../assets/images/imagegroup.svg"
import { routes } from "../../../helper/routes"

class Hero extends Component {
  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role)
    this.props.history.push(routes.auth_response)
  }

  handleSignUp = () => {
    this.props.history.push(routes.registration_form_view)
  }

  render() {
    const currentYear = new Date().getFullYear()

    return (
      <>
        <div className="row px-10 py-5">
          <div className="col-md-12">
            <div className="row cmc-hero-container">
              <div className="col-lg-2 col-md-2 d-flex justify-content-end book-container">
                <img
                  src={book}
                  style={{ width: "180px" }}
                  alt="book"
                  className="book-img"
                />
              </div>
              <div className="col-lg-8 col-md-8 col-12 d-flex justify-content-center">
                <div className="row" style={{ zIndex: 1 }}>
                  <div className="col-md-12">
                    <h1 className="text-center cmc-heading">
                      Practice Past Papers
                    </h1>
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12">
                        <h1 className="text-center cmc-heading title-gradient">
                          Online
                        </h1>
                      </div>
                      <div className="col-md-12 d-flex justify-content-center">
                        <img
                          src={underline}
                          style={{ width: "220px" }}
                          alt="underline"
                          className="title-underline"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 pt-5 d-flex justify-content-center">
                    <p className="cmc-para text-center">
                      “Stand out as a beacon of personalized learning, through
                      our site that delivers knowledge tailored to individual
                      needs and aspirations”
                    </p>
                  </div>
                  <div className="col-md-12 pt-8 d-flex justify-content-center">
                    <button
                      onClick={() => this.handleRoleSelect("student")}
                      className="cmc-signin-btn mr-1"
                    >
                      Sign In
                    </button>
                    <button
                      onClick={this.handleSignUp}
                      className="cmc-register-btn ml-1"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-12">
                <img
                  src={arrowone}
                  style={{ width: "210px" }}
                  alt="child"
                  className="arrow-one"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-around child-img-container">
            <img
              src={arrowtwo}
              style={{ width: "150px" }}
              alt="child"
              className="arrow-two"
            />
            <img
              src={child}
              style={{ width: "320px" }}
              alt="child"
              className="child-img"
            />
          </div>
          <div className="col-md-12 light-green-container">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <img
                  src={imagegroup}
                  style={{ width: "500px" }}
                  alt="child"
                  className="group-img"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 d-flex justify-content-center pt-8 pb-2 pl-1 text-center">
            &#169; {currentYear} All Rights Reserved. Powered by EdSider (Pvt)
            Ltd. Supported by Infinity Innovators (Pvt) Ltd
          </div>
        </div>
      </>
    )
  }
}
export default withRouter(Hero)
