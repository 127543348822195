import React from "react";

export default function ModalHeader({children}) {

    return (
        
        <div className="modal-header">
            {children}
        </div>

    )
}