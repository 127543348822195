import React from 'react';
import config from '../../helper/config';
import { customerConstants } from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
    return config.customerConfigs.featureFlag;
};

export default function LoginColum({ children }) {
    const customer = getCustomerFeatureFlag();
    let footerText;
    switch (customer) {
        case customerConstants.EXO_CUSTOMER1:
            footerText = "© 2024 Edsider. All Right Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
            break;
        case customerConstants.EXO_CUSTOMER2:
            footerText = "© 2024 All Rights Reserved. Powered by EdSider Supported by Infinity Innovators (Pvt) Ltd"
            break;
        default:
            footerText = "© 2024 All Rights Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
            break;
    }
    return (
        <>
            <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
                {/* begin::Login */}
                <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">

                    {children}

                    {/* begin::Aside */}
                    <div className="login-aside order-1 order-lg-2 bgi-no-repeat bgi-position-x-right">
                        <div className="login-conteiner bgi-no-repeat bgi-position-x-right bgi-position-y-bottom" style={{ backgroundImage: 'url(../assets/media/bg/login-bg.jpg)' }}></div>
                    </div>
                    {/* end::Aside */}

                </div>
                {/* end::Login */}
            </div>
            <div className="footer bg-white py-4 " id="footer">
                <div className="container-fluid">
                    <span className="text-dark-75 d-flex justify-content-center">
                        {footerText}
                    </span>
                </div>
            </div>
        </>

    )
}