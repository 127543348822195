import React, { useEffect, useRef } from 'react';
import config from '../../../helper/config';
import { customerConstants } from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    // cancelClass = "cmc-btn-default"
    // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

export default function ImportFileUpload ({className, url, fileName, callBack,defaultFile,deleteback, enable}) {
    const refDropZone = useRef();
    useEffect(() => {
        init()
    }, [])
    function init(){
        const $ = window.$;
        if($){
            $(refDropZone.current).dropzone({
                url: url ? url : '/', // Set the url for your upload script location
                paramName: fileName, // The name that will be used to transfer the file
                maxFiles: config.fileUploadConfigs.maxFileCount,
                maxFilesize: (config.fileUploadConfigs.maxFileSize / 1024), // MB
                addRemoveLinks: true,
                acceptedFiles: ".txt",
                
                removedfile: function(file){  
                        deleteback(file)      
                        var _ref;
                        this.element.style.cursor = "allowed";
                        this.hiddenFileInput.style.cursor = "allowed";
                        this.hiddenFileInput.disabled = false;
                        this.setupEventListeners();
                        return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
                },
                
                init:function(){
                    var thisDropzone = this;
                    if(defaultFile){
                        defaultFile.map((file,i) => {
                            if (file !='"[]"' && file !='[]'){
                            var mockFile = { name: file, size: 12345};
                            thisDropzone.emit("addedfile", mockFile);
                            thisDropzone.emit("complete", mockFile);
                            thisDropzone.emit("success", mockFile);
                        }
                    }) 
                    }

                    this.on('error', function(file) {
                        window.Swal.fire({
                            title: 'Failed!',
                            text: "File cannot be uploaded. Check the size and/or file type",
                            icon: 'error',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        });
                        this.removeFile(file)
                    });
                    
                },
                accept: function(file, done) {

                if (this.files.length > 1) {
                    this.removeAllFiles()
                    this.element.style.cursor = "allowed";
                    this.hiddenFileInput.style.cursor = "allowed";
                    this.hiddenFileInput.disabled = false;
                    this.setupEventListeners();
                    window.Swal.fire({
                        title: 'Failed!',
                        text: "Multiple files cannot be uploaded. Check the file count",
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    });
                }
                    else{
                    if (file.name === "") {
                        done("Empty file");
                    } else {
                        callBack(file)
                        this.emit("complete", file);
                        this.emit("success", file); 
                        this.element.style.cursor = "not-allowed";
                        this.hiddenFileInput.style.cursor = "not-allowed";
                        this.hiddenFileInput.disabled = true;
                        this.removeEventListeners();       
                    }
                }
                }
            });
        }
    }

    return (
        <div className={className}>
            <div className="dropzone dropzone-default dropzone-primary" ref={refDropZone} id="kt_dropzone_2">           
                <div className="dropzone-msg dz-message needsclick">
                    <h3 className="dropzone-msg-title">Drop files here or click to upload.</h3>
                </div>
                <span className="dropzone-msg-desc">{"Upload " + config.fileUploadConfigs.maxFileCount + " file at a time. Maximum File Size is " +  (config.fileUploadConfigs.maxFileSize / (1024*1024)) + " GB."}</span>
                <div>&nbsp;</div>
            </div>
        </div>
    )
}