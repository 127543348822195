import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const CountryService = {
  getAll,
  add,
  update,
  getAllCountriesLimit
};

//getting all countries
async function getAll() {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/countries/getAll`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//getting countries with limit
async function getAllCountriesLimit(limit, offset, search_term) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/countries/getAllCountriesLimit/${limit}/${offset}?search_term=${search_term}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//add a new country
async function add(newCountry) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newCountry),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/countries/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//update existing county
async function update(updateCountry, country_id) {
  const configOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateCountry),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/countries/update/${country_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}
