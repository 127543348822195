import React, { Component } from "react";
import ContentWrapper from "../../../components/common/ContentWrapper";
import Card from "../../../components/common/Card";
import CardHeader from "../../../components/common/CardHeader";
import CardTitle from "../../../components/common/CardTitle";
import CardBody from "../../../components/common/CardBody";
import { Button } from "react-bootstrap";
import { history } from "../../../helper/history";
import { PaymentService } from "../../../service/manage/payments/payment.service";
import PageSpinner from "../../../components/common/spinner/PageSpinner";
import sweetAlert, { sweetStatusAlert } from "../../../components/common/SweetAlert";

class SamplePaymentForm extends Component {
  state = {
    nodeTitle: null,
    breadcrumb2: null,
    amount: "",
    sessionId: "",
    isLoading: false,
  };

  componentDidMount() {
    // Add any initial setup if needed
  }

  componentWillUnmount() {
    // Cleanup: Remove the script when the component is unmounted
    const script = document.querySelector("script[src*='checkout.min.js']");
    if (script) {
      document.head.removeChild(script);
    }
  }

  errorCallback = (error) => {
    sweetStatusAlert("Failed!", JSON.stringify(error), "error");
  };

  cancelCallback = () => {
    sweetStatusAlert("Failed!", "Payment cancelled!", "error");
  };

  successCallback = () => {
    sweetStatusAlert("Success!", "Payment successful!", "success");
  };

  handlePaymentPageClick = async () => {
    this.setState({
      isLoading: true,
    });

    await this.getSessionID();

    this.loadCheckoutScript();
  };

  getSessionID = async () => {
    try {
      const res = await PaymentService.getSessionID();
      const responseData = await res.json();
      this.setState({
        sessionId: responseData.session.id,
      });
    } catch (error) {
      console.error("Error getting session ID:", error);
      sweetStatusAlert("Error!", "An error occurred while getting session ID.", "error");
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  loadCheckoutScript = () => {
    const script = document.createElement("script");
    script.src =
      "https://cbcmpgs.gateway.mastercard.com/static/checkout/checkout.min.js";
    document.head.appendChild(script);

    script.onload = () => {
      window.Checkout.configure({
        session: {
          id: this.state.sessionId,
        },
      });

      // Set up callbacks after configuring
      window.Checkout.onComplete = this.successCallback;
      window.Checkout.onError = this.errorCallback;
      window.Checkout.onCancel = this.cancelCallback;

      // Ensure Checkout is configured before attempting to showPaymentPage
      if (window.Checkout) {
        window.Checkout.showPaymentPage();
      } else {
        console.error("Checkout is not configured.");
      }
    };
  };

  getVisibilityCheck = (privilege_view, privilege_add, privilege_delete, privilege_edit) => {
    const visibilityState = {
      addVisibility: privilege_add === 1,
      editVisibility: privilege_edit === 1,
      viewVisibility: privilege_view === 1,
      deleteVisibility: privilege_delete === 1,
    };

    this.setState(visibilityState);
  };

  render() {
    const { amount, breadcrumb2, nodeTitle, isLoading } = this.state;
    const formattedBreadcrumb = breadcrumb2? breadcrumb2.replaceAll(">", " > ") : ""

    return (
      <>
        <ContentWrapper>
          <Card>
            <CardHeader>
          {(this.props.state.path !== 'node' ) && 
            <p className="col-md-6" style={{ fontSize: "10.5625px", color: "#00000099" }}>
            {(formattedBreadcrumb) ? `${formattedBreadcrumb}`: ""}
            </p>}
              <div>
                <CardTitle cardLabel="Sample Payment Form" />
              </div>
            </CardHeader>
            {isLoading && (
              <PageSpinner id="show" text="Loading, Please wait!" />
            )}
            <CardBody>
              <Button variant="primary" onClick={this.handlePaymentPageClick}>
                Pay with Payment Page
              </Button>
            </CardBody>
          </Card>
        </ContentWrapper>
      </>
    );
  }
}

export default SamplePaymentForm;
