import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const AttendanceService = {
  add,
  getAllAttendanceLimit,
  getAllEnrolledStudents
};

async function getAllEnrolledStudents(node_mid, form_id, lg_user_id, lg_user_table_id) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/attendances/getAllEnrolledStudents/${node_mid}/${form_id}/${lg_user_id}/${lg_user_table_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllAttendanceLimit(node_mid, limit, offset, search_term, create_user_id, create_user_table_id, user_table_id, stu_id, sortColumn, sortDirection) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/attendances/getAll/${node_mid}/${limit}/${offset}/${sortColumn}/${sortDirection}?search_term=${search_term}&create_user_id=${create_user_id}&create_user_table_id=${create_user_table_id}&user_table_id=${user_table_id}&stu_id=${stu_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

//add a new city
async function add(newAttendance) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newAttendance),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/attendances/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}



