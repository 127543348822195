import * as React from 'react'
import ScrollableFeed from 'react-scrollable-feed'
import {
  isSameSender,
  getBackground,
  senderMargins
} from "../../helper/ChatLogics";
import Avatar from '@mui/material/Avatar';
import animationData from "../animations/typing.json";
import Lottie from "react-lottie";
import Zoom from '@mui/material/Zoom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ScrollableChat = ({ messages, chat, istyping, typingUser }) => {
//   const { user } = ChatState();

  const messageContainerStyle = {
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word', 
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={{height:"380px"}}>
    <ScrollableFeed>
      {messages &&
        messages.map((m, i) => (
          <div style={{ display: "flex" }} key={m._id}>
            {!isSameSender(m,chat) && (
                <BootstrapTooltip title={m.sender.name} placement="top" arrow TransitionComponent={Zoom}>
                <Avatar
                  mt='25px'
                  borderRadius="50%"
                  mr={1}
                  size="sm"
                  cursor="pointer"
                  name={m.sender.name}
                  src={m.sender.name}
                  alt={m.sender.name}
                  sx={{maxWidth:'25px',maxHeight:'25px'}}
                />
              </BootstrapTooltip>
            )}
            <span 
              style={{
                backgroundColor: `${
                  getBackground(m,chat) ? "#BEE3F8" : "#B9F5D0"
                }`,
                marginLeft: senderMargins(m,chat)?'auto':0,
                marginTop: senderMargins(m,chat) ? 3 : 10,
                borderRadius: "20px",
                padding: "5px 15px",
                maxWidth: "75%",
                ...messageContainerStyle,
              }}
            >
              <p>{m.content}</p>
            </span>
          </div>
        ))}
        <div style={{ height: '30px', ml:'10px' }}>
            {istyping ?                      
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Lottie
                    options={defaultOptions}
                    height={20}
                    width={70}
                    style={{ marginBottom: 5, marginLeft: 20,marginTop:5 }}
                />
                <p >{typingUser?typingUser:''}</p>
            </div> 
            : <div>
                
            </div>
        }
    </div>
    </ScrollableFeed>
    </div>
  );
};

export default ScrollableChat;
