import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const CalendarService = {
  addEvent,
  getEvent,
  updateEvent,
  getEventTitlesForMonth,
  deleteEvent,
  getEventsByStartDate,
  getPersonalCalendarEvents,
  getAllEventsFromDifferentNodeIds,
  getNodeMids,
  getAvailableNodeMids,
  getEventsForNodeMid ,
  getEventsForNode,
  getEventsForCGC, 
};

// Add a new calendar event
async function addEvent(newEvent) {
  const configOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newEvent),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/calendar-events/add`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getEventTitlesForMonth(
  year,
  month,
  selectedCategories,
  lg_user_id,
  lg_user_table_id,
  node_mid,
  nodeArray
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  // Convert the array of selected categories to a comma-separated string
  const categoryQueryString = selectedCategories.join(",");

  const nodeArrayString = nodeArray && nodeArray.join(",");
  // Include lg_user_id and lg_user_table_id in the URL
  const url1 = `${config.oat_api_url}/manage/calendar-events/get-event-titles-for-month/${year}/${month}?categories=${categoryQueryString}&lg_user_id=${lg_user_id}&lg_user_table_id=${lg_user_table_id}&node_mid=${node_mid}&node_array=${nodeArrayString}`;
  const url2 = `${config.oat_api_url}/manage/calendar-events/get-event-titles-for-month/${year}/${month}?categories=${categoryQueryString}&lg_user_id=${lg_user_id}&lg_user_table_id=${lg_user_table_id}&node_mid=${node_mid}`;

  const response = nodeArrayString ? await fetch(url1, configOptions) : await fetch(url2, configOptions);
  return APIHandler.handleResponse(response);
}

// Get a calendar event by ID
async function getEvent(entryId) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/calendar-events/get/${entryId}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

// Get calendar events by start date
async function getEventsByStartDate(startDate) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/calendar-events/get-by-start-date/${startDate}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

// Update an existing calendar event
async function updateEvent(entryId, updatedEvent) {
  const configOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updatedEvent),
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/calendar-events/update/${entryId}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

// Delete a calendar event by entry_id
async function deleteEvent(entryId) {
  const configOptions = {
    method: "DELETE",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/calendar-events/delete/${entryId}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

// Get personal calendar events for a specific user
async function getPersonalCalendarEvents(lg_user_id) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/calendar-events/get-personal-calendar-events/${lg_user_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getAllEventsFromDifferentNodeIds(
  year,
  month,
  selectedCategories,
  lg_user_id,
  lg_user_table_id
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const categoryQueryString = selectedCategories.join(",");

  const response = await fetch(
    `${config.oat_api_url}/manage/calendar-events/get-all-events-from-different-node-ids/${year}/${month}?categories=${categoryQueryString}&lg_user_id=${lg_user_id}&lg_user_table_id=${lg_user_table_id}`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}
async function getNodeMids(
  year,
  month,
  selectedCategories,
  lg_user_id,
  lg_user_table_id
) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };
  const categoryQueryString = selectedCategories.join(",");

  const response = await fetch(
    `${config.oat_api_url}/manage/calendar-events/get-node-mids/${year}/${month}?categories=${categoryQueryString}&lg_user_id=${lg_user_id}&lg_user_table_id=${lg_user_table_id}`,
    configOptions
  );

  return APIHandler.handleResponse(response);
}

async function getAvailableNodeMids() {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `${config.oat_api_url}/manage/calendar-events/get-available-node-mids`,
    configOptions
  );
  return APIHandler.handleResponse(response);
}

async function getEventsForNodeMid(nodeMid) {
  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const url = nodeMid
    ? `${config.oat_api_url}/manage/calendar-events/get-events-for-node-mid?node_mid=${nodeMid}`
    : `${config.oat_api_url}/manage/calendar-events/get-events-for-node-mid`;

  const response = await fetch(url, configOptions);
  return APIHandler.handleResponse(response);
}

async function getEventsForNode(year, month, selectedCategories, lg_user_id, lg_user_table_id, selectedNodeMid, nodeArray) {
  const categoryQueryString = selectedCategories.join(",");
  const url = new URL(`${config.oat_api_url}/manage/calendar-events/get-events`);
  url.searchParams.append('year', year);
  url.searchParams.append('month', month);
  url.searchParams.append('categories', categoryQueryString);
  url.searchParams.append('lg_user_id', lg_user_id);
  url.searchParams.append('lg_user_table_id', lg_user_table_id);
  url.searchParams.append('node_mid', selectedNodeMid);
  const nodeArrayString = nodeArray && nodeArray.join(",");
  nodeArrayString && url.searchParams.append('node_array', nodeArrayString);

  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(url.toString(), configOptions);
  return APIHandler.handleResponse(response);
}

async function getEventsForCGC(year, month) {
  const url = new URL(`${config.oat_api_url}/manage/calendar-events/get-events-cgc`);
  url.searchParams.append('year', year);
  url.searchParams.append('month', month);
  

  const configOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(url.toString(), configOptions);
  return APIHandler.handleResponse(response);
}


