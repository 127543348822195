import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { history } from "../../helper/history";
import moment from "moment";
import ContentWrapper from "../../components/common/AssessmentContentWrapper";
import AdminTemplate from "../../components/admin/layouts/Template";
import Card from "../../components/common/Card";
import CardHeader from "../../components/common/CardHeader";
import CardBody from "../../components/common/CardBody";
import CardTitle from "../../components/common/AssessmentCardTitle";
import CardToolbar from "../../components/common/SelfGradeCardToolBar";
import Spinner from "../../components/common/Spinner";
import TabList from "../../components/common/tabs/TabList";
import NavItem from "../../components/common/tabs/NavItem";
import TabContent from "../../components/common/tabs/TabContent";
import TabPane from "../../components/common/tabs/TabPane";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../components/common/MaterialCustomTablePagination.js"
import Modal from "../../components/common/modal/SelfGradeModal";
import ModalBody from "../../components/common/modal/ModalBody";
import ModalHeader from "../../components/common/modal/ModalHeader";
import FormGroup from "../../components/common/form/FormGroup";
import Button from "../../components/common/form/Button";
import CreateButton from "../../components/common/CreateButton";
import Input from "../../components/common/form/Input";
import Select from "../../components/common/form/AssessmentSelect";
import Option from "../../components/common/form/Option";
import SwitchCheck from "../../components/common/form/SwitchCheck";
import Form from "../../components/common/form/Form";
import Wizard from "../../components/common/wizard/Wizard";
import WizardSteps from "../../components/common/wizard/WizardSteps";
import WizardForm from "../../components/common/wizard/WizardForm";
import StepContent from "../../components/common/wizard/StepContent";
import WizardActions from "../../components/common/wizard/WizardActions";
import WizardStepsItems from "../../components/common/wizard/WizardStepsItems";
import { libraryActions } from "../../actions/assessment/libraryActions";
import { candidateActions } from "../../actions/candidate/candidateActions.js";
import { questionActions } from "../../actions/assessment/questionActions";
import { assessmentActions } from "../../actions/assessment/assessmentActions";
import { staffUserActions } from "../../actions/user_management/staffUserActions.js";
import { manageUserActions } from "../../actions/user_management/manageUserActions.js";
import { messageTypes, userRoles } from "../../reducers/types";
import DataHandler from "../../handlers/DataHandler";
import "../question/QuestionsLibrary.css";
import { namespace } from "../../helper/namespace";
import { ASSESSMENT_LIBRARY_FILTERS } from "../../helper/constants";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { routes } from "../../helper/routes";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import ReactForm from "react-bootstrap/Form";
import ReactSpinner from "react-bootstrap/Spinner";
import config from '../../helper/config.js';
import { customerConstants } from "../../helper/constants.js";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { quesGradeService } from "../../service/candidate/gradeService.js";
import SetupAssessment from "./SetupAssessment.js";
import { unitConstants } from "../../helper/constants";
import CheckBoxInline from "../../components/common/form/CheckBoxInline.js";
import CheckBox from "../../components/common/form/CheckBox.js";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { termsConditionsService } from "../../service/assessment/termsConditionsService.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    addBtnColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
  // confirmClass = "cmc-btn-default"
  // cancelClass = "cmc-btn-primary"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    addBtnColor = "var(--maincolor)"
    break
}

class AssessmentLibrary extends React.Component {
  constructor(props) {
    super(props);

    this.ref_tagify = React.createRef();
    this.edit_ref_tagify = React.createRef();
    this.state = {
      tags: "",
      viewAssessmentDetails: "",
      editDetails: "",
      setupAssessment: false,
      grade: false,

      currentIndex: 0,
      maxCount: 3,
      errors: {},
      init_tagify: false,

      assessment_id: "",
      title: "",
      assessment_admin: "",
      proctor_requirement: false,
      assessors: [],
      chief_assessor: "",
      chief_assessor_id: "",
      selectedAssessors: [],

      start_time: "",
      test_duration: null,
      access_buffer: null,
      lockEdit: false,

      selectedLanguage: "",
      languageOptions: [],
      selectedCategory: "",
      categoryOptions: [],
      selectedDifficulty: "",
      selectedAttempt: "",
      selectedStatus: "",
      selectedStatusName: "",
      selectedGrade: "",
      selectedSubject: "",
      selectedAuthor: "",
      selectedYear: "",
      selectedTerm: "",
      selectedChapter: "",
      difficultyOptions: [],
      attemptOptions: [],
      statusOptions: [],
      assessmentStatuses: [],
      editStatusOptions: [],
      timeUnitOptions: [],
      bufferTimeUnitOptions: [],
      gradeOptions: [],
      subjectOptions: [],
      privilegeOptions: [],
      selectedPrivilege: "",
      authorOptions: [],
      yearOptions: [],
      termOptions: [],
      chapterOptions: [],
      paperTerms: [],

      editTags: "",
      editorState: EditorState.createEmpty(),
      editorEditState: EditorState.createEmpty(),
      termsAndConditions: null,
      editTermsAndConditions: null,
      edit_init_tagify: true,
      editAssessmentId: "",
      editTitle: "",
      editAssessmentAdminId: "",
      editProctorRequirement: false,
      editStartTime: "",
      editTestDuration: "",
      editAccessBuffer: "",
      editChiefAssessorId: "",
      editSelectedAssessors: [],
      assessorNames: [],
      chiefAssessorName: "",
      candidatesToDelete: [],
      assessorsToDelete: [],
      chiefAssessorToDelete: "",
      candidates: [],
      selectedCandidates: "",
      editCandidateEmails: [],
      dateInputMin: "",
      dateManualInputDisabled: false,
      loadingModal: false,
      loadingEditModal: false,
      deletion: false,
      selectedUser: "",
      tempUsers: [],
      staffUserOptions: [],
      staffUserAssessorOptions: [],
      staffUserChiefAssessorOptions: [],

      editLanguage: null,
      editCategory: null,
      editDifficulty: null,
      editAttempts: null,
      editStatus: null,
      editStatusName: null,
      editSubject: null,
      editTerm: null,
      editYear: null,
      editGrade: null,
      editAuthor: null,
      editChapter: null,
      editPrivilege: null,

      candidate_emails: [],
      candidate_names: [],
      manage_assessment_id: "",
      manage_assessment_state: "",
      filterValues: [],
      filters: [],
      selectedFilters: [],
      selectedGrades: null,
      selectedChapters: null,
      selectedsubjects: null,
      selectedStatuses: null,
      selectedAuthors: null,
      selectedYears: null,
      selectedCategories: null,
      selectedTerms: null,
      selectedDifficulties: null,
      selectedLanguages: null,

      limit: 10,
      offset: 0,
      search_term: "",
      currentPage: 0,
      sort_column: "created_at",
      sort_direction: "DESC",
      rowsPerPage: 10,
      btnDisabled: false,

      isTableDataLoading: false,
      myAssessments: [],
      sharedAssessments: [],
      publicAssessments: [],
      count: 10,
      editAssessmentState: "",
      candidateReportLoading: false,
      assessment:"",
      teacher_grade_reports:[],

      testDurationValue: '',
      bufferDurationValue: '',
      timeUnit: unitConstants.MINUTES.name,
      bufferTimeUnit: unitConstants.MINUTES.name,
      editTestDurationValue: '',
      editBufferDurationValue: '',
      editTimeUnit: '',
      editBufferTimeUnit: '',
      setTimeLimit: false,
      editSetTimeLimit: false,
    };

    this.initTagify = this.initTagify.bind(this);
    this.funcAddTag = this.funcAddTag.bind(this);
    this.editInitTagify = this.editInitTagify.bind(this);
    this.editFuncAddTag = this.editFuncAddTag.bind(this);
    this.validate = this.validate.bind(this);
    this.validateEditAssessment = this.validateEditAssessment.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.onChangeAssessmentLanguage =
      this.onChangeAssessmentLanguage.bind(this);
    this.onChangeAssessmentCategory =
      this.onChangeAssessmentCategory.bind(this);
    this.onChangeAssessmentDifficulty =
      this.onChangeAssessmentDifficulty.bind(this);
    this.onClickPrev = this.onClickPrev.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onDeleteAssessment = this.onDeleteAssessment.bind(this);
    this.onUpdateDetails = this.onUpdateDetails.bind(this);
    this.onClear = this.onClear.bind(this);
    this.clearWizard = this.clearWizard.bind(this);
    this.onClickDiscard = this.onClickDiscard.bind(this);
    this.handleChangeAssessors = this.handleChangeAssessors.bind(this);
    this.handleChangeEditSelectedAssessors =
      this.handleChangeEditSelectedAssessors.bind(this);
  }

  async componentDidMount() {
    if (window.KTSelect2) window.KTSelect2.init();
    if (window.KTBootstrapDatepicker) window.KTBootstrapDatepicker.init();
    if (window.KTIONRangeSlider) window.KTIONRangeSlider.init();
    if (window.KTWizard6) window.KTWizard6.init();
    if (window.KTBootstrapDatetimepicker)
      window.KTBootstrapDatetimepicker.init();
    if (window.$) window.$(".selectpicker").selectpicker();

    await this.fetchAllMyAssessments();
    // await this.fetchAllSharedAssessments();
    await this.fetchAllPublicAssessments();

    await this.fetchallTeacherGradeReports();

    this.setState({ limit: this.props.totalMyAssessmentsCount });
    this.setState({ count: this.props.totalMyAssessmentsCount });
    this.setState({ myAssessments: this.props.myAssessments });
    // this.setState({ sharedAssessments: this.props.sharedAssessments });
    this.setState({ publicAssessments: this.props.publicAssessments });

    var user_id = DataHandler.getFromSession("user_id");
    // await this.props.listManagedAssessments({ user_id });
    // await this.props.listAdministratedAssessments({ user_id });
    // await this.props.listAssessedAssessments({ user_id });
    await this.props.clearAssessmentView();
    
    this.setState({isTableDataLoading: true})
    await this.props.getAssessmentMetaData()
    await this.getAllPaperTerms()

    //await this.props.getStaffUsers();
    await this.props.refreshPage();
    await this.props.createStaffUserMap();
    this.setState({isTableDataLoading: false})

    if (this.props.allStaffUsers.length > 0) {
      var staffUserOptions = [];
      var staffuser;
      var allTeachers;
      this.setState({isTableDataLoading: true})
      await this.props.getAllTeachers();
      this.setState({isTableDataLoading: false})
      for (staffuser of this.props.allStaffUsers) {
        for (allTeachers of this.props.allTeachers)
          if (
            staffuser.staff_id === allTeachers.user_id &&
            staffuser.role_code === "ASSR"
          ) {
            staffUserOptions.push(
              <Option
                key={staffuser.user_id}
                optionName={allTeachers.first_name.concat(
                  " ",
                  allTeachers.last_name
                )}
                value={staffuser.user_id}
              />
            );
          }
      }
      const updatedStaffUserOptions = staffUserOptions.filter(
        (option) => option.props.value !== parseInt(user_id)
      );
      this.setState({ staffUserOptions: staffUserOptions });
      this.setState({ staffUserChiefAssessorOptions: staffUserOptions });
      this.setState({ staffUserAssessorOptions: updatedStaffUserOptions });
    }
    
    let assessmentLanguages = this.props.allAssessmentMetaData.find(item => 'languages' in item).languages;
    if (assessmentLanguages.length > 0) {
      let languageOptions = [];
      languageOptions.push(
        <Option key={null} optionName="Nothing selected" value={null} />
      );
      assessmentLanguages.map((el, i) => {
        languageOptions.push(
          <Option
            key={el.language_id}
            optionName={el.language}
            value={el.language_id}
          />
        );
      });

      this.setState({
        languageOptions: languageOptions,
        selectedLanguage: languageOptions[0].props.value,
      });

      if (languageOptions)
        window.$("#assessment-language").selectpicker("refresh");
    }

    let assessmentPrivileges = this.props.allAssessmentMetaData.find(item => 'privileges' in item).privileges;
    if (assessmentPrivileges.length > 0) {
      let privilegeOptions = [];
      assessmentPrivileges.map((el, i) => {
        privilegeOptions.push(
          <Option
            key={el.privilege_id}
            optionName={el.privilege}
            value={el.privilege_id}
          />
        );
      });

      this.setState({
        privilegeOptions: privilegeOptions,
        selectedPrivilege: privilegeOptions[0].props.value,
        editPrivilege: privilegeOptions[0].props.value,
      });

      if (privilegeOptions) window.$("#assessment-privilege").selectpicker("refresh");
    }

    let assessmentCategories = this.props.allAssessmentMetaData.find(item => 'categories' in item).categories;
    if (assessmentCategories.length > 0) {
      let categoryOptions = [];
      categoryOptions.push(
        <Option key={null} optionName="Nothing selected" value={null} />
      );
      assessmentCategories.map((el, i) => {
        categoryOptions.push(
          <Option
            key={el.category_id}
            optionName={el.name}
            value={el.category_id}
          />
        );
      });

      this.setState({
        categoryOptions: categoryOptions,
        selectedCategory: categoryOptions[0].props.value,
      });

      if (categoryOptions)
        window.$("#assessment-category").selectpicker("refresh");
    }

    let assessmentDifficultyTypes = this.props.allAssessmentMetaData.find(item => 'difficulty_types' in item).difficulty_types;
    if (assessmentDifficultyTypes.length > 0) {
      let difficultyOptions = [];
      difficultyOptions.push(
        <Option key={null} optionName="Nothing selected" value={null} />
      );
      assessmentDifficultyTypes.map((el, i) => {
        difficultyOptions.push(
          <Option
            key={el.difficulty_id}
            optionName={el.difficulty_type}
            value={el.difficulty_id}
          />
        );
      });

      this.setState({
        difficultyOptions: difficultyOptions,
        selectedDifficulty: difficultyOptions[0].props.value,
      });

      if (difficultyOptions)
        window.$("#assessment-difficulty").selectpicker("refresh");
    }

    let assessmentAttempts = this.props.allAssessmentMetaData.find(item => 'attempts' in item).attempts;
    if (assessmentAttempts.length > 0) {
      let allAttempts = [];
      assessmentAttempts.map((el, i) => {
        allAttempts.push(
          <Option
            key={el.attempt_no_id}
            optionName={el.no_of_attempts}
            value={el.attempt_no_id}
          />
        );
      });

      this.setState({
        attemptOptions: allAttempts,
        selectedAttempt: allAttempts[0].props.value,
      });

      if (allAttempts) window.$("#assessmentAttempts").selectpicker("refresh");
    }

    let assessmentStatuses = this.props.allAssessmentMetaData.find(item => 'statuses' in item).statuses;
    if (assessmentStatuses.length > 0) {
      let allStatuses = [];
      assessmentStatuses.map((el, i) => {
        allStatuses.push(
          <Option
            key={el.status_id}
            optionName={el.status}
            value={el.status_id}
          />
        );
      });

      this.setState({
        statusOptions: allStatuses,
        assessmentStatuses: assessmentStatuses,
        selectedStatus: allStatuses[0].props.value,
        selectedStatusName: allStatuses[0].props.optionName
      });

      if (allStatuses) window.$("#assessmentStatuses").selectpicker("refresh");
    }

    let assessmentScheduleUnits = this.props.allAssessmentMetaData.find(item => 'units' in item).units;
    if (assessmentScheduleUnits.length > 0) {
      let allUnits = [];
      assessmentScheduleUnits.map((el, i) => {
        allUnits.push(
          <Option
            key={el.unit_id}
            optionName={el.unit}
            value={el.unit_id}
          />
        );
      });

      this.setState({
        timeUnitOptions: allUnits,
        bufferTimeUnitOptions: allUnits,
        timeUnit: allUnits[0].props.value,
        bufferTimeUnit: allUnits[0].props.value,
      });

      if (allUnits) window.$("#assessment-schedule-time-units").selectpicker("refresh");
      if (allUnits) window.$("#assessment-schedule-buffer-time-units").selectpicker("refresh");
    }

    let assessmentGrades = this.props.allAssessmentMetaData.find(item => 'grades' in item).grades;
    if (assessmentGrades.length > 0) {
      let allGrades = [];
      allGrades.push(
        <Option key={null} optionName="Nothing selected" value={null} />
      );
      assessmentGrades.map((el, i) => {
        allGrades.push(
          <Option key={el.grade_id} optionName={el.grade} value={el.grade_id} />
        );
      });

      this.setState({
        gradeOptions: allGrades,
        selectedGrade: allGrades[0].props.value,
      });

      if (allGrades) window.$("#assessment-grades").selectpicker("refresh");
    }

    let assessmentSubjects = this.props.allAssessmentMetaData.find(item => 'subjects' in item).subjects;
    if (assessmentSubjects.length > 0) {
      let allSubjects = [];
      allSubjects.push(
        <Option key={null} optionName="Nothing selected" value={null} />
      );
      assessmentSubjects.map((el, i) => {
        allSubjects.push(
          <Option
            key={el.subject_id}
            optionName={el.subject}
            value={el.subject_id}
          />
        );
      });

      this.setState({
        subjectOptions: allSubjects,
        selectedSubject: allSubjects[0].props.value,
      });

      if (allSubjects) window.$("#assessment-subject").selectpicker("refresh");
    }

    let assessmentAuthors = this.props.allAssessmentMetaData.find(item => 'authors' in item).authors;
    if (assessmentAuthors.length > 0) {
      let allAuthors = [];
      allAuthors.push(
        <Option key={null} optionName="Nothing selected" value={null} />
      );
      assessmentAuthors.map((el, i) => {
        allAuthors.push(
          <Option
            key={el.author_id}
            optionName={el.author}
            value={el.author_id}
          />
        );
      });

      this.setState({
        authorOptions: allAuthors,
        selectedAuthor: allAuthors[0].props.value,
      });

      if (allAuthors) window.$("#assessment-author").selectpicker("refresh");
    }

    let assessmentTerms = this.props.allAssessmentMetaData.find(item => 'terms' in item).terms;
    if (assessmentTerms.length > 0) {
      let allTerms = [];
      allTerms.push(
        <Option key={null} optionName="Nothing selected" value={null} />
      );
      assessmentTerms.map((el, i) => {
        allTerms.push(
          <Option key={el.term_id} optionName={el.term} value={el.term_id} />
        );
      });

      this.setState({
        termOptions: allTerms,
        selectedTerm: allTerms[0].props.value,
      });

      if (allTerms) window.$("#assessment-term").selectpicker("refresh");
    }

    let assessmentYears = this.props.allAssessmentMetaData.find(item => 'years' in item).years;
    if (assessmentYears.length > 0) {
      let allYears = [];
      allYears.push(
        <Option key={null} optionName="Nothing selected" value={null} />
      );
      assessmentYears.map((el, i) => {
        allYears.push(
          <Option key={el.year_id} optionName={el.year} value={el.year_id} />
        );
      });

      this.setState({
        yearOptions: allYears,
        selectedYear: allYears[0].props.value,
      });

      if (allYears) window.$("#assessment-year").selectpicker("refresh");
    }

    let assessmentChapters = this.props.allAssessmentMetaData.find(item => 'chapters' in item).chapters;
    if (assessmentChapters.length > 0) {
      let allChapters = [];
      allChapters.push(
        <Option key={null} optionName="Nothing selected" value={null} />
      );
      assessmentChapters.map((el, i) => {
        allChapters.push(
          <Option
            key={el.chapter_id}
            optionName={el.chapter}
            value={el.chapter_id}
          />
        );
      });

      this.setState({
        chapterOptions: allChapters,
        selectedChapter: allChapters[0].props.value,
      });

      if (allChapters) window.$("#assessment-chapter").selectpicker("refresh");
    }

    var self = this;
    window.$("#kt_select2_12").on("select2:select", function (e) {
      var data = e.params.data;
      self.handleChangeEditSelectedAssessors(data.id);
    });

    window.$("#kt_select2_12").on("select2:unselect", function (e) {
      var data = e.params.data;
      self.handleChangeEditSelectedAssessors(data.id);
    });
    this.onDatatableActionEvent();

    const currentTimeZone = moment.tz.guess();
    const currentOffset = moment().tz(currentTimeZone).format("Z");

    DataHandler.setToSession(
      DataHandler.SESSION_DATA.TimeZone,
      currentTimeZone
    );

    DataHandler.setToSession(
      DataHandler.SESSION_DATA.TimeZoneOffset,
      currentOffset
    );

    var dateobj = new Date().toLocaleString("sv-SE", {
      hour12: false,
      timeZone: DataHandler.getFromSession("TimeZone"),
    });
    let dateInputMin = dateobj.split(" ")[0].concat("T00:00:00"); // Only date is considered
    this.setState({ dateInputMin });
  }

  getAllPaperTerms = async () => {
    this.setState({ isTableDataLoading: true });
    await termsConditionsService.getAllAssessmentTermsConditions()
      .then((response) => {
        if (response.success) {
          this.setState({ paperTerms: response.data.assessmentTermsConditions, isTableDataLoading: false });
        } else {
          this.setState({ isTableDataLoading: false });
        }
      })
      .catch((error) => {
        console.error("Error while fetching paper terms and conditions!", error);
        this.setState({ isTableDataLoading: false });
      });
  }

  getAssessmentTermsContent(language_id) {
    if (language_id !== null) {
        const paperTerm = this.state.paperTerms?.find(
            (language) => language.language_id == parseInt(language_id)
        );
        return paperTerm?.content;
    }
    else{
        return null;
    }
  }

  onUnload = (e) => {
    // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = "";
  };

  componentDidUpdate() {
    window.$(".selectPicker").selectpicker("refresh");
    if (this.state.init_tagify) {
      if (window.$) window.$(".selectpicker").selectpicker();
      this.initTagify();
    }

    if (this.state.edit_init_tagify) {
      if (window.$) window.$(".selectpicker").selectpicker();
      this.editInitTagify();
    }

    if (
      !this.state.assessment_admin &&
      this.props.allStaffUsers &&
      this.props.allStaffUsers.length
    ) {
      this.setState({
        assessment_admin: DataHandler.getFromSession("user_id"),
      });
    }

    if (
      !this.state.chief_assessor &&
      this.props.allStaffUsers &&
      this.props.allStaffUsers.length
    ) {
      this.setState({ chief_assessor: DataHandler.getFromSession("user_id") });
    }

    if (
      !this.state.dateManualInputDisabled &&
      document.getElementById("kt_datetimepicker_5")
    ) {
      let input_div = document.getElementById("kt_datetimepicker_5");
      input_div.setAttribute("onkeyDown", "return false");
      this.setState({ dateManualInputDisabled: true });
    }

    window.$("#assessment_admin").selectpicker("refresh");
    window.$("#chief_assessor").selectpicker("refresh");
    window.$("#assessors").selectpicker("refresh");
    window.$("#candidates").selectpicker("refresh");

    window.$("#edit_assessment_admin").selectpicker("refresh");
    window.$("#edit_chief_assessor").selectpicker("refresh");
    window.$("#edit_assessors").selectpicker("refresh");
    window.$("#edit_candidates").selectpicker("refresh");
    window.$("#assessment-difficulty-edit").selectpicker("refresh");
    window.$("#assessment-category-edit").selectpicker("refresh");
    window.$("#assessment-privilege-edit").selectpicker("refresh");
    window.$("#assessment-language-edit").selectpicker("refresh");
    window.$("#edit-assessment-chapter").selectpicker("refresh");
    window.$("#edit-assessment-grades").selectpicker("refresh");
    window.$("#edit-assessment-term").selectpicker("refresh");
    window.$("#edit-assessment-subject").selectpicker("refresh");
    window.$("#edit-assessment-author").selectpicker("refresh");
    window.$("#edit-assessment-year").selectpicker("refresh");
    window.$("#edit-assessment-attempts").selectpicker("refresh");
    window.$("#edit-assessment-statuses").selectpicker("refresh");
    window.$("#edit-assessment-schedule-time-units").selectpicker("refresh");
    window.$("#edit-assessment-schedule-buffer-time-units").selectpicker("refresh");

    // if (this.state.loadingModal && !this.props.assessmentLoading &&  !this.props.candidateLoading &&  !this.props.scheduleLoading &&  !this.props.assessorLoading) {
    //     this.setState({loadingModal: false})
    // }
  }

  // this function bind jquery event listener to view-detail btn on datatable
  // datatable plugin is a jquery plgin, so it is duplicting action element and whatever event we have
  // bind via react component will be ignored.
  // to fix this bug, we are finding the array index on click event and retrive data. thereafter passing to relevent function
  // triggering the modal to be shown.
  onDatatableActionEvent() {
    window.$(document).on("click", ".view-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.handleChangeModalView(this.props.myAssessments[idx]);
      window.$("#view-details").modal("show");
    });

    window.$(document).on("click", ".view-public-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.handleChangeModalView(this.props.publicAssessments[idx]);
      window.$("#view-details").modal("show");
    });

    window.$(document).on("click", ".viewAdmin-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.handleChangeModalView(this.props.adminAssessments[idx]);
      window.$("#view-details").modal("show");
    });

    window.$(document).on("click", ".viewAssess-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.handleChangeModalView(this.props.sharedAssessments[idx]);
      window.$("#view-details").modal("show");
    });

    window.$(document).on("click", ".edit-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.setState({ editAssessmentState: this.props.myAssessments[idx].assessment_state })
      this.handleChangeModalEdit(
        this.props.myAssessments[idx],
        this.props.myAssessments[idx].assessment_id,
        this.props.myAssessments[idx].title,
        this.props.myAssessments[idx].assessment_admin,
        this.props.myAssessments[idx].proctor_requirement
      );
      window.$("#edit-details").modal("show");
    });

    window.$(document).on("click", ".edit-public-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.setState({ editAssessmentState: this.props.myAssessments[idx]?.assessment_state })
      this.handleChangeModalEdit(
        this.props.publicAssessments[idx],
        this.props.publicAssessments[idx].assessment_id,
        this.props.publicAssessments[idx].title,
        this.props.publicAssessments[idx].assessment_admin,
        this.props.publicAssessments[idx].proctor_requirement
      );
      window.$("#edit-details").modal("show");
    });

    window.$(document).on("click", ".delete-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.handleDelete(this.props.myAssessments[idx]);
    });

    window.$(document).on("click", ".delete-public-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.handleDelete(this.props.publicAssessments[idx]);
    });

    window.$(document).on("click", ".setup-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.setupAssessment(this.props.sharedAssessments[idx]);
    });

    window.$(document).on("click", ".setup-public-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.setupAssessment(this.props.publicAssessments[idx]);
    });

    window.$(document).on("click", ".grade-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.gradeAssessment(this.props.sharedAssessments[idx]);
    });

    window.$(document).on("click", ".grade-public-assement-btn", (e) => {
      const _target = e.target;
      const idx = _target.getAttribute("dataindex");
      this.gradeAssessment(this.props.publicAssessments[idx]);
    });
  }
  
  getUnit(unit_id) {
    let unit  = this.props.allAssessmentMetaData.find(item => 'units' in item).units 
    .find(unit => unit.unit_id === parseInt(unit_id, 10))?.unit
    return unit;
  }
  
  fetchAllMyAssessments = async () => {
    const {
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      selectedStatuses
    } = this.state;
    var user_id = DataHandler.getFromSession("user_id");
    this.setState({ isTableDataLoading: true });
    await this.props.getAllMyAssessments(
      { user_id },
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedGrades,
      selectedDifficulties,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
      selectedStatuses
    );
    this.setState({ myAssessments: this.props.myAssessments });
    this.setState({ limit: this.state.rowsPerPage });
    this.setState({ isTableDataLoading: false });
  };

  fetchAllPublicAssessments = async () => {
    const {
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedGrades,
      selectedChapters,
      selectedsubjects,
      selectedAuthors,
      selectedYears,
      selectedCategories,
      selectedTerms,
      selectedDifficulties,
      selectedLanguages,
      selectedStatuses
    } = this.state;
    this.setState({ isTableDataLoading: true });
    await this.props.getAllPublicAssessments(
      limit,
      offset,
      sort_column,
      sort_direction,
      search_term,
      selectedCategories,
      selectedAuthors,
      selectedChapters,
      selectedGrades,
      selectedDifficulties,
      selectedLanguages,
      selectedTerms,
      selectedYears,
      selectedsubjects,
      selectedStatuses
    );
    this.setState({ publicAssessments: this.props.publicAssessments });
    this.setState({ limit: this.state.rowsPerPage });
    this.setState({ isTableDataLoading: false });
  };

  fetchallTeacherGradeReports = async () => {
    const graded_by = sessionStorage.getItem("manage_user_id");
    this.setState({
      isTableDataLoading: true,
    });
    await quesGradeService.getAllTeacherGrades(graded_by)
      .then((res) => {
        if (res.success) {
          this.setState({
            teacher_grade_reports: res.data.grade_reports,
            isTableDataLoading: false,
          });
        } else {
          this.setState({
            teacher_grade_reports: [],
          });
        }
      })
      .finally(() => {
        this.setState({
          isTableDataLoading: false,
        });
      });
  };

  // fetchAllSharedAssessments = async () => {
  //   const {
  //     limit,
  //     offset,
  //     sort_column,
  //     sort_direction,
  //     search_term,
  //     selectedGrades,
  //     selectedChapters,
  //     selectedsubjects,
  //     selectedAuthors,
  //     selectedYears,
  //     selectedCategories,
  //     selectedTerms,
  //     selectedDifficulties,
  //     selectedLanguages,
  //     selectedStatuses
  //   } = this.state;
  //   var user_id = DataHandler.getFromSession("user_id");
  //   this.setState({ isTableDataLoading: true });
  //   await this.props.getAllSharedAssessments(
  //     { user_id },
  //     limit,
  //     offset,
  //     sort_column,
  //     sort_direction,
  //     search_term,
  //     selectedCategories,
  //     selectedAuthors,
  //     selectedChapters,
  //     selectedGrades,
  //     selectedDifficulties,
  //     selectedLanguages,
  //     selectedTerms,
  //     selectedYears,
  //     selectedsubjects,
  //     selectedStatuses
  //   );
  //   this.setState({ sharedAssessments: this.props.sharedAssessments });
  //   this.setState({ limit: this.state.rowsPerPage });
  //   this.setState({ isTableDataLoading: false });
  // };

  funcAddTag(e) {
    this.setState({ tags: e });
  }

  editFuncAddTag(e) {
    this.setState({ editTags: e });
    var emails = [],
      email;
    var existingEmails = [];
    existingEmails = this.state.candidatesToDelete;
    var newEmail = "";
    for (email in this.state.editTags.detail.tagify.value) {
      newEmail = this.state.editTags.detail.tagify.value[email].value;

      if (!existingEmails.includes(newEmail)) {
        emails.push(this.state.editTags.detail.tagify.value[email].value);
      }
    }

    this.setState({ editCandidateEmails: emails });
  }

  initTagify() {
    const input = this.ref_tagify.current;
    if (input) {
      this.ref_tagify.current.tagify = new window.Tagify(input, {
        addTagOnBlur: false,
      });
      this.ref_tagify.current.tagify.DOM.input.classList.add("form-control");
      this.ref_tagify.current.tagify.DOM.input.setAttribute(
        "placeholder",
        "enter tag..."
      );
      this.ref_tagify.current.tagify.DOM.scope.parentNode.insertBefore(
        this.ref_tagify.current.tagify.DOM.input,
        this.ref_tagify.current.tagify.DOM.scope
      );
      this.ref_tagify.current.tagify.on("add", (e) => {
        this.funcAddTag(e);
      });
      this.ref_tagify.current.tagify.on("remove", (e) => {
        this.funcAddTag(e);
      });
    }
    this.setState({ init_tagify: false });
  }

  onClickNext() {
    const _validate = this.validate();
    const userID = DataHandler.getFromSession("user_id");
    const assessment_id = this.props.createdAssessment?.assessment_id;
    const assessment_data = {};

    if (this.state.currentIndex < this.state.maxCount) {
      if (_validate) {
        _validate.then((e) => {
          if (this.state.currentIndex < this.state.maxCount && e === "Valid") {
            const selectedGrade = this.state.selectedGrade;
            if (
              selectedGrade === null ||
              selectedGrade === "Nothing selected"
            ) {
              // Grade validation failed, show SweetAlert
              window.Swal.fire({
                title: "Validation Error",
                text: "Grade is required",
                icon: "error",
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              });
            } else {
              const indexVal = this.state.currentIndex + 1;
              this.setState({ currentIndex: indexVal });
              this.setState({ init_tagify: true });
            }
          }
        });
      } else {
        const indexVal = this.state.currentIndex + 1;
        this.setState({ currentIndex: indexVal });
        this.setState({ init_tagify: true });
      }
    }
  }

  onClickPrev() {
    if (this.state.currentIndex > 0) {
      var indexVal = this.state.currentIndex - 1;
      this.setState({ currentIndex: indexVal });
      this.setState({ init_tagify: true });
    }
    if (document.querySelector('[data-wizard-type="action-next1"]')) {
      document.querySelector(
        '[data-wizard-type="action-next1"]'
      ).disabled = false;
    }
  }

  clearWizard() {
    window.$("#exampleModal").modal("hide");
    this.setState({
      currentIndex: 0,
      candidate_emails: [],
      title: "",
      assessment_admin: "",
      proctor_requirement: false,
      assessors: [],
      btnDisabled: false,
      chief_assessor: "",
      chief_assessor_id: "",
      selectedAssessors: [],
      start_time: "",
      test_duration: "",
      access_buffer: "",
      timeUnit: "",
      bufferTimeUnit: "",
      setTimeLimit: false,
      editSetTimeLimit: false,
      editStatus: "",
      editStatusName: "",
      dateManualInputDisabled: false,
    });
  }

  onChangeAssessmentLanguage(e) {
    this.setState({
      selectedLanguage:
        e?.target?.value === "Nothing selected" ? null : e?.target?.value,
    });
    if(e?.target?.value !== "Nothing selected"){
      var terms_content = this.getAssessmentTermsContent(e.target.value);
      this.setState({ 
        editorState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(terms_content))) 
      }, () => {
        this.setState({ termsAndConditions: terms_content});
      });
    }
    else{
      this.setState({ 
        editorState: EditorState.createEmpty()
      });
    }
  }

  onChangeAssessmentCategory(e) {
    this.setState({
      selectedCategory:
        e?.target?.value === "Nothing selected" ? null : e?.target?.value,
    });
  }

  onChangeAssessmentDifficulty(e) {
    this.setState({
      selectedDifficulty:
        e?.target?.value === "Nothing selected" ? null : e?.target?.value,
    });
  }

  onChangeAssessmentPrivilege = (e) => {
    this.setState({
      selectedPrivilege: e?.target?.value,
    });
  };

  onChangeAttempt = (e) => {
    this.setState({
      selectedAttempt: e?.target?.value,
    });
  };

  onChangeStatus = (e) => {
    this.setState({
      selectedStatus: e?.target?.value,
      selectedStatusName: this.displayStatus(parseInt(e?.target?.value, 10)),
    });
  };

  onChangeGrade = (e) => {
    this.setState({
      selectedGrade:
        e?.target?.value === "Nothing selected" ? null : e?.target?.value,
    });
  };

  onChangeSubject = (e) => {
    this.setState({
      selectedSubject:
        e?.target?.value === "Nothing selected" ? null : e?.target?.value,
    });
  };

  onChangeAuthor = (e) => {
    this.setState({
      selectedAuthor:
        e?.target?.value === "Nothing selected" ? null : e?.target?.value,
    });
  };

  onChangeYear = (e) => {
    this.setState({
      selectedYear:
        e?.target?.value === "Nothing selected" ? null : e?.target?.value,
    });
  };

  onChangeTerm = (e) => {
    this.setState({
      selectedTerm:
        e?.target?.value === "Nothing selected" ? null : e?.target?.value,
    });
  };

  onChangeChapter = (e) => {
    this.setState({
      selectedChapter:
        e?.target?.value === "Nothing selected" ? null : e?.target?.value,
    });
  };

  onClickDiscard() {
    window.Swal.fire({
      title: "Do you want to discard and close?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: addBtnColor,
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Discard and Close it!",
    }).then((result) => {
      if (result.isConfirmed) {
        window.$("#exampleModal").modal("hide");
        this.setState({
          currentIndex: 0,
          candidate_emails: [],
          title: "",
          assessment_admin: "",
          proctor_requirement: false,
          assessors: [],
          chief_assessor: "",
          chief_assessor_id: "",
          selectedAssessors: [],
          start_time: "",
          test_duration: "",
          access_buffer: "",
          timeUnit: "",
          bufferTimeUnit: "",
          setTimeLimit: false,
          editSetTimeLimit: false,
          dateManualInputDisabled: false,
        });
        window.location.reload();
        history.push(routes.view_assessment)
      }
    });
  }

  async onClickSubmit(e) {
    e.preventDefault();
    const _validate = this.validateScheduleAssessment();
    if (_validate) {
      _validate.then(async (e) => {
        if (e == "Valid") {
          var userID = DataHandler.getFromSession("user_id");
          var assessment_data = {};
          const {
            title,
            assessment_admin,
            proctor_requirement,
            selectedLanguage,
            selectedCategory,
            selectedDifficulty,
            termsAndConditions,
            selectedAttempt,
            selectedStatus,
            selectedStatusName,
            selectedAuthor,
            selectedTerm,
            selectedYear,
            selectedChapter,
            selectedSubject,
            selectedGrade,
            selectedPrivilege,
          } = this.state;
          assessment_data["assessment"] = {
            title,
            assessment_admin,
            proctor_requirement,
            userID,
            language_id: selectedLanguage,
            selectedCategory,
            selectedDifficulty,
            termsAndConditions,
            selectedAttempt,
            selectedStatus,
            selectedStatusName,
            selectedAuthor,
            selectedTerm,
            selectedYear,
            selectedChapter,
            selectedSubject,
            selectedGrade,
            selectedPrivilege,
          };
          const { chief_assessor, assessors } = this.state;
          assessment_data["assessor_data"] = {
            assessment_id,
            chief_assessor,
            assessors,
            userID,
          };
          let start_time = moment.utc(
            this.state.start_time.concat(
              DataHandler.getFromSession(
                DataHandler.SESSION_DATA.TimeZoneOffset
              )
            )
          );
          start_time = moment.utc(start_time).format("YYYY-MM-DDTHH:mm:ss");
          const { test_duration, access_buffer, timeUnit, bufferTimeUnit, setTimeLimit } = this.state;
          assessment_data["schedule_data"] = {
            start_time,
            test_duration,
            access_buffer,
            timeUnit,
            bufferTimeUnit,
            setTimeLimit,
            userID,
          };
          this.setState({ btnDisabled: true })
          window.Swal.showLoading();
          await this.props.createAssessment(assessment_data);
          var assessment_id = this.props.createdAssessment.assessment_id;
          if (
            this.state.currentIndex == 3 &&
            this.state.tags.detail &&
            this.state.tags.detail.tagify.value &&
            this.state.tags.detail.tagify.value.length &&
            this.props.createSuccess
          ) {
            var emails = [],
              email;
            for (email in this.state.tags.detail.tagify.value) {
              if (this.state.tags.detail.tagify.value[email].__isValid) {
                emails.push(this.state.tags.detail.tagify.value[email].value);
              }
            }
            this.setState({ candidate_emails: emails });
            const { candidate_emails } = this.state;
            await this.props.addCandidates({
              assessment_id,
              candidate_emails,
              userID,
            });
            if (
              this.props.createSuccess &&
              this.props.candidateSuccess &&
              this.props.invalidEmails.length
            ) {
              window.Swal.fire({
                title: messageTypes.WARNING,
                text: messageTypes.ASSESSMENT_CREATE_SUCCESS +
                  "<br/>" +
                  this.props.candidateSuccess,
                icon: messageTypes.WARN,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                window.location.reload();
                history.push(routes.view_assessment)
              });
              this.clearWizard();
            } else if (
              this.props.createSuccess &&
              this.props.candidateSuccess
            ) {
              window.Swal.fire({
                title: messageTypes.SUCCESS_WARNING,
                text: messageTypes.ASSESSMENT_CREATE_SUCCESS +
                  "<br/>" +
                  this.props.candidateSuccess,
                icon: messageTypes.SUCCESS,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                window.location.reload();
                history.push(routes.view_assessment)
              });
              this.clearWizard();
            } else if (this.props.createSuccess && this.props.candidateError) {
              window.Swal.fire({
                title: messageTypes.WARNING,
                text: messageTypes.ASSESSMENT_CREATE_SUCCESS +
                  "<br/>" +
                  this.props.candidateError,
                icon: messageTypes.WARN,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                window.location.reload();
                history.push(routes.view_assessment)
              });
              this.clearWizard();
            } else {
              window.Swal.fire({
                title: messageTypes.FAILED_WARNING,
                text: messageTypes.CREATE_FAIL,
                icon: messageTypes.ERROR,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              });
              this.clearWizard();
            }
          } else {
            if (this.props.createSuccess) {
              window.Swal.fire({
                title: messageTypes.SUCCESS_WARNING,
                text: messageTypes.ASSESSMENT_CREATE_SUCCESS,
                icon: messageTypes.SUCCESS,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                window.location.reload();
                history.push(routes.view_assessment)
              });
              this.clearWizard();
            } else {
              window.Swal.fire({
                title: messageTypes.FAILED_WARNING,
                text: messageTypes.CREATE_FAIL,
                icon: messageTypes.ERROR,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              });
              this.clearWizard();
            }
          }
        }
      });
    }
  }

  validate() {
    const FORM = document.getElementById("kt_form");
    const FORM_VALIDATION = window.FormValidation;
    const nextButton = FORM.querySelector('[data-wizard-type="action-next1"]');
    const validations = [
      {
        fields: {
          title: {
            validators: {
              notEmpty: {
                message: "Title is required",
              },
            },
          },
          assessmentAttempts: {
            validators: {
              notEmpty: {
                message: "attempt is required",
              },
            },
          },
          assessment_admin: {
            validators: {
              notEmpty: {
                message: "Assessment admin is required",
              },
            },
          },
        },
      },
      {
        fields: {
          chief_assessor: {
            validators: {
              notEmpty: {
                message: "Chief assessor is required",
              },
            },
          },
        },
      },
    ];
    if (validations[this.state.currentIndex]) {
      let form = FORM_VALIDATION.formValidation(FORM, {
        fields: validations[this.state.currentIndex].fields,
        plugins: {
          trigger: new FORM_VALIDATION.plugins.Trigger(),
          bootstrap: new FORM_VALIDATION.plugins.Bootstrap({
            eleValidClass: "",
          }),
          fieldStatus: new FORM_VALIDATION.plugins.FieldStatus({
            onStatusChanged: function (areFieldsValid) {
              areFieldsValid
                ? nextButton.removeAttribute("disabled")
                : nextButton.setAttribute("disabled", "disabled");
            },
          }),
        },
      });
      return form.validate();
    }
  }

  validateScheduleAssessment() {
    const FORM = document.getElementById("kt_form");
    const FORM_VALIDATION = window.FormValidation;
    const finishSetupButton = FORM.querySelector(
      '[data-wizard-type="action-submit1"]'
    );
    let form = FORM_VALIDATION.formValidation(FORM, {
      fields: {
        start_time: {
          validators: {
            notEmpty: {
              message: "Start time is required",
            },
          },
        },
        test_duration: {
          validators: {
            notEmpty: {
              message: "Time duration is required",
            },
            regexp: {
              regexp: /^[1-9]+[0-9]*$/,
              message: "Time duration should be a positive integer",
            },
          },
        },
        access_buffer: {
          validators: {
            notEmpty: {
              message: "Access buffer duration is required",
            },
            regexp: {
              regexp: /^[1-9]+[0-9]*$/,
              message: "Access buffer duration should be a positive integer",
            },
          },
        },
      },
      plugins: {
        trigger: new FORM_VALIDATION.plugins.Trigger(),
        bootstrap: new FORM_VALIDATION.plugins.Bootstrap({
          eleValidClass: "",
        }),
        fieldStatus: new FORM_VALIDATION.plugins.FieldStatus({
          onStatusChanged: function (areFieldsValid) {
            areFieldsValid
              ? finishSetupButton.removeAttribute("disabled")
              : finishSetupButton.setAttribute("disabled", "disabled");
          },
        }),
      },
    });
    return form.validate();
  }

  validateEditAssessment() {
    const FORM = document.getElementById("edit-details");
    const FORM_VALIDATION = window.FormValidation;
    const submitButton = document.getElementById("edit_assessment_submit_btn");
    let form = FORM_VALIDATION.formValidation(FORM, {
      fields: {
        edit_title: {
          validators: {
            notEmpty: {
              message: "Title is required",
            },
          },
        },
        edit_assessment_admin: {
          validators: {
            notEmpty: {
              message: "Assessment admin is required",
            },
          },
        },
        edit_chief_assessor: {
          validators: {
            notEmpty: {
              message: "Chief assessor is required",
            },
          },
        },
        edit_start_time: {
          validators: {
            notEmpty: {
              message: "Start time is required",
            },
          },
        },
        edit_test_duration: {
          validators: {
            notEmpty: {
              message: "Test duration is required",
            },
            regexp: {
              regexp: /^[1-9]+[0-9]*$/,
              message: "Test duration should be a positive integer",
            },
          },
        },
        edit_access_buffer: {
          validators: {
            notEmpty: {
              message: "Access buffer time is required",
            },
            regexp: {
              regexp: /^[1-9]+[0-9]*$/,
              message: "Access buffer duration should be a positive integer",
            },
            // lessThan:{
            // inclusive: true,
            // max: 15,
            // message: "Access buffer duration should be maximum 15 minutes",
            // }
          },
        },
      },
      plugins: {
        trigger: new FORM_VALIDATION.plugins.Trigger(),
        bootstrap: new FORM_VALIDATION.plugins.Bootstrap({
          eleValidClass: "",
        }),
        fieldStatus: new FORM_VALIDATION.plugins.FieldStatus({
          onStatusChanged: function (areFieldsValid) {
            areFieldsValid
              ? submitButton.removeAttribute("disabled")
              : submitButton.setAttribute("disabled", "disabled");
          },
        }),
      },
    });
    return form.validate();
  }

  handleClose() {
    window.location.reload();
    history.push(routes.view_assessment)
  }

  editInitTagify() {
    const input = this.edit_ref_tagify.current;
    if (input) {
      this.edit_ref_tagify.current.tagify = new window.Tagify(input, {
        addTagOnBlur: false,
      });
      this.edit_ref_tagify.current.tagify.DOM.input.classList.add(
        "form-control"
      );
      this.edit_ref_tagify.current.tagify.DOM.input.setAttribute(
        "placeholder",
        "enter emails..."
      );
      this.edit_ref_tagify.current.tagify.DOM.scope.parentNode.insertBefore(
        this.edit_ref_tagify.current.tagify.DOM.input,
        this.edit_ref_tagify.current.tagify.DOM.scope
      );

      this.edit_ref_tagify.current.tagify.on("add", (e) => {
        this.editFuncAddTag(e);
      });
      this.edit_ref_tagify.current.tagify.on("remove", (e) => {
        this.editFuncAddTag(e);
      });
    }
    this.setState({ edit_init_tagify: false });
  }

  async onUpdateDetails(e) {
    e.preventDefault();
    const _validate = this.validateEditAssessment();
    if (_validate) {
      _validate.then(async (e) => {
        if (e == "Valid") {
          if (
            this.state.editGrade === null ||
            this.state.editGrade === "Nothing selected"
          ) {
            // Grade validation failed, show SweetAlert
            window.Swal.fire({
              title: "Validation Error",
              text: "Grade is required",
              icon: "error",
              customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
              }
            });
            return;
          }
          window.$("#edit-details").modal("hide");
          window.Swal.showLoading();
          let userID = sessionStorage.getItem("user_id");
          let editAssessmentId = this.state.editAssessmentId;
          let editTitle = this.state.editTitle;
          let editAssessmentAdminId = this.state.editAssessmentAdminId;
          let editProctorRequirement = this.state.editProctorRequirement;
          let editChiefAssessorId = this.state.editChiefAssessorId;
          let editSelectedAssessors = this.state.editSelectedAssessors;
          let editTestDuration = this.state.editTestDuration;
          let editAccessBuffer = this.state.editAccessBuffer;
          let editTimeUnit = this.state.editTimeUnit;
          let editBufferTimeUnit = this.state.editBufferTimeUnit;
          let editSetTimeLimit = this.state.editSetTimeLimit;
          let chiefAssessorToDelete = this.state.chiefAssessorToDelete;
          let language_id = this.state.editLanguage;
          let category_id = this.state.editCategory;
          let attempt_no_id = this.state.editAttempts;
          let status_id = this.state.editStatus;
          let assessment_state = this.state.editStatusName;
          let grade_id = this.state.editGrade;
          let author_id = this.state.editAuthor;
          let chapter_id = this.state.editChapter;
          let subject_id = this.state.editSubject;
          let term_id = this.state.editTerm;
          let year_id = this.state.editYear;
          let terms_content = this.state.editTermsAndConditions;
          let difficulty_id = this.state.editDifficulty;
          let assessorsToDelete = this.state.assessorsToDelete;
          let editCandidateEmails = this.state.editCandidateEmails;
          let editPrivilege = this.state.editPrivilege;
          let editStartTime = moment.utc(
            this.state.editStartTime.concat(
              DataHandler.getFromSession(
                DataHandler.SESSION_DATA.TimeZoneOffset
              )
            )
          );
          editStartTime = moment
            .utc(editStartTime)
            .format("YYYY-MM-DDTHH:mm:ss");

          await this.props.editAssessors({
            userID,
            editAssessmentId,
            editChiefAssessorId,
            editSelectedAssessors,
            chiefAssessorToDelete,
            assessorsToDelete,
          });
          await this.props.editDetails({
            userID,
            editAssessmentId,
            editTitle,
            editAssessmentAdminId,
            editProctorRequirement,
            language_id,
            category_id,
            difficulty_id,
            attempt_no_id,
            grade_id,
            author_id,
            chapter_id,
            subject_id,
            term_id,
            year_id,
            status_id,
            assessment_state,
            terms_content,
            editPrivilege,
          });
          await this.props.editScheduleAssessment({
            userID,
            editAssessmentId,
            editStartTime,
            editTestDuration,
            editAccessBuffer,
            editTimeUnit,
            editBufferTimeUnit,
            editSetTimeLimit
          });

          if (editCandidateEmails.length != 0) {
            await this.props.editCandidates({
              userID,
              editAssessmentId,
              editCandidateEmails,
            });
          }

          if (editCandidateEmails.length != 0) {
            if (
              this.props.editScheduleSuccess &&
              this.props.editDetailsSuccess &&
              this.props.editAssessorsSuccess &&
              this.props.editCandidatesSuccess &&
              this.props.editCandidatesInvalidEmails.length
            ) {
              await window.Swal.fire({
                title: messageTypes.ASSESSMENT_EDIT_SUCCESS,
                text: this.props.editCandidatesSuccess,
                icon: messageTypes.WARN,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                  history.push(routes.view_assessment);
                  window.location.reload();
                });
            } else if (
              this.props.editScheduleSuccess &&
              this.props.editDetailsSuccess &&
              this.props.editAssessorsSuccess &&
              this.props.editCandidatesSuccess
            ) {
              await window.Swal.fire({
                title: messageTypes.ASSESSMENT_EDIT_SUCCESS,
                text: this.props.editCandidatesSuccess,
                icon: messageTypes.SUCCESS,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                  history.push(routes.view_assessment);
                  window.location.reload();
                });
            } else if (
              this.props.editScheduleSuccess &&
              this.props.editDetailsSuccess &&
              this.props.editAssessorsSuccess &&
              this.props.editCandidateError
            ) {
              await window.Swal.fire({
                title: messageTypes.ASSESSMENT_EDIT_SUCCESS,
                text: this.props.editCandidateError,
                icon: messageTypes.WARN,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                  history.push(routes.view_assessment);
                  window.location.reload();
                });;
            } else {
              await window.Swal.fire({
                title: messageTypes.FAILED_WARNING,
                text: messageTypes.FAILED_MESSAGE,
                icon: messageTypes.WARN,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                  history.push(routes.view_assessment);
                  window.location.reload();
                });;
            }
          } else {
            if (
              this.props.editScheduleSuccess &&
              this.props.editDetailsSuccess &&
              this.props.editAssessorsSuccess
            ) {
              await window.Swal.fire({
                title: messageTypes.SUCCESS_WARNING,
                text: messageTypes.ASSESSMENT_EDIT_SUCCESS,
                icon: messageTypes.SUCCESS,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                  history.push(routes.view_assessment);
                  window.location.reload();
                });;
            } else {
              await window.Swal.fire({
                title: messageTypes.FAILED_WARNING,
                text: messageTypes.FAILED_MESSAGE,
                icon: messageTypes.ERROR,
                customClass: {
                  confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
                }
              }).then(function () {
                  history.push(routes.view_assessment);
                  window.location.reload();
                });;
            }
          }
          this.handleClose();
        }
      });
    }
  }

  onClear() {
    window.$("#kt_select2_12").val(null).trigger("change");
    this.handleClose();
  }

  handleChange = (e) => {
    if (!!this.state.errors[e.target.name]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors[e.target.name];

      this.setState({
        [e.target.name]: e.target.value,
        errors,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleChangeTestDuration = (e) => {
    e.preventDefault();
    const testDurationValue = parseInt(e.target.value, 10) || 0;
    if (!!this.state.errors["test_duration"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["test_duration"];

      this.setState({ errors });
    } else {
      this.calculateDurationInMinutes(testDurationValue, this.state.timeUnit);
    }
  };
  
  handleChangeTimeUnit = (e) => {
    e.preventDefault();
    const timeUnit = e.target.value;
    this.setState({ timeUnit }, () => {
      this.calculateDurationInMinutes(this.state.testDurationValue, timeUnit);
    });
  };
  
  calculateDurationInMinutes = (value, unit) => {
    let testDurationInMinutes;
    let timeUnit = this.getUnit(unit);

    switch (timeUnit) {
      case unitConstants.MONTHS.name:
        testDurationInMinutes = value * 30 * 24 * 60;
        break;
      case unitConstants.HOURS.name:
        testDurationInMinutes = value * 60;
        break;
      case unitConstants.DAYS.name:
        testDurationInMinutes = value * 24 * 60;
        break;
      case unitConstants.MINUTES.name:
      default:
        testDurationInMinutes = value;
    }
  
    this.setState({
      test_duration: testDurationInMinutes,
      testDurationValue: value,
    });
  
    if (!!this.state.errors["test_duration"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["test_duration"];
      this.setState({ errors });
    }
  };

  handleChangeBufferTime = (e) => {
    e.preventDefault();
    const bufferDurationValue = parseInt(e.target.value, 10) || 0;
    if (!!this.state.errors["access_buffer"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["access_buffer"];

      this.setState({ errors });
    } else {
      this.calculateBufferDurationInMinutes(bufferDurationValue, this.state.bufferTimeUnit);
    }
  };
  
  handleChangeBufferTimeUnit = (e) => {
    e.preventDefault();
    const bufferTimeUnit = e.target.value;
    this.setState({ bufferTimeUnit }, () => {
      this.calculateBufferDurationInMinutes(this.state.bufferDurationValue, bufferTimeUnit);
    });
  };
  
  calculateBufferDurationInMinutes = (value, unit) => {
    let bufferDurationInMinutes;
    let timeUnit = this.getUnit(unit);
    switch (timeUnit) {
      case unitConstants.HOURS.name:
        bufferDurationInMinutes = value * 60;
        break;
      case unitConstants.DAYS.name:
        bufferDurationInMinutes = value * 24 * 60;
        break;
      case unitConstants.MONTHS.name:
        bufferDurationInMinutes = value * 30 * 24 * 60;
        break;
      case unitConstants.MINUTES.name:
      default:
        bufferDurationInMinutes = value;
    }
  
    this.setState({
      access_buffer: bufferDurationInMinutes,
      bufferDurationValue: value,
    });
  
    if (!!this.state.errors["access_buffer"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["access_buffer"];
      this.setState({ errors });
    }
  };

  handleChangeAdmin = (e) => {
    if (!!this.state.errors["assessment_admin"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["assessment_admin"];

      this.setState({
        assessment_admin: e.target.value,
        errors,
      });
    } else {
      this.setState({ assessment_admin: e.target.value });
    }
  };

  handleToggle = (e) => {
    let PR = !this.state.proctor_requirement;
    this.setState({ proctor_requirement: PR });
  };

  handleCheckTimeLimit = () => {
    this.setState(
    (prevState) => ({
        setTimeLimit: !prevState.setTimeLimit,
    }), () => {
      if(this.state.setTimeLimit === false){
        this.setState({ test_duration: null, access_buffer: null, timeUnit: 1, bufferTimeUnit: 1});
      }
    });
  }

  handleCheckEditTimeLimit = () => {
    this.setState(
    (prevState) => ({
        editSetTimeLimit: !prevState.editSetTimeLimit,
    }), () => {
      if(this.state.editSetTimeLimit === false){
        this.setState({ editTestDuration: null, editAccessBuffer: null, editTimeUnit: 1, editBufferTimeUnit: 1});
      }
    });
  }

  handleChangeChiefAssessor = (e) => {
    var selectedId = e.target.value;
    const staffUserAssessorOptions =
      this.state.staffUserChiefAssessorOptions.filter(
        (option) => option.props.value !== parseInt(selectedId)
      );
    this.setState({ staffUserAssessorOptions: staffUserAssessorOptions });
    if (!!this.state.errors["chief_assessor"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["chief_assessor"];

      this.setState({
        chief_assessor: e.target.value,
        errors,
      });
    } else {
      this.setState({ chief_assessor: e.target.value });
    }
  };

  onFilterInput = (v, r) => {
    let categoryOptions;
    let languageOptions;
    let subjectOptions;
    let authorOptions;
    let gradeOptions;
    let yearOptions;
    let difficultyOptions;
    let termOptions;
    let chapterOptions;
    let statusOptions;
    let selectedFilters = [];
    this.setState({
      filters: v,
      filterValues: [],
    });

    if (r === "removeOption") {
      this.setState(
        {
          selectedAuthors: null,
          selectedsubjects: null,
          selectedChapters: null,
          selectedTerms: null,
          selectedCategories: null,
          selectedYears: null,
          selectedLanguages: null,
          selectedDifficulties: null,
          selectedGrades: null,
          selectedStatuses: null
        },
        () => {
          this.fetchAllMyAssessments();
          //this.fetchAllSharedAssessments();
          this.fetchAllPublicAssessments();
        }
      );
    }
    if (v.includes("Category")) {
      let assessmentCategories = this.props.allAssessmentMetaData.find(item => 'categories' in item).categories;
      categoryOptions = assessmentCategories?.map((val) => {
        return {
          filter: "Category",
          name: val.name,
          category_id: val.category_id,
        };
      });
      selectedFilters = [...selectedFilters, ...categoryOptions];
    }
    if (v.includes("Language")) {
      let assessmentLanguages = this.props.allAssessmentMetaData.find(item => 'languages' in item).languages;
      languageOptions = assessmentLanguages?.map((val) => {
        return {
          filter: "Language",
          name: val.language,
          language_id: val.language_id,
        };
      });
      selectedFilters = [...selectedFilters, ...languageOptions];
    }
    if (v.includes("Subject")) {
      let assessmentSubjects = this.props.allAssessmentMetaData.find(item => 'subjects' in item).subjects;
      subjectOptions = assessmentSubjects?.map((val) => {
        return {
          filter: "Subject",
          name: val.subject,
          subject_id: val.subject_id,
        };
      });
      selectedFilters = [...selectedFilters, ...subjectOptions];
    }
    if (v.includes("Author")) {
      let assessmentAuthors = this.props.allAssessmentMetaData.find(item => 'authors' in item).authors;
      authorOptions = assessmentAuthors?.map((val) => {
        return {
          filter: "Author",
          name: val.author,
          author_id: val.author_id,
        };
      });
      selectedFilters = [...selectedFilters, ...authorOptions];
    }
    if (v.includes("Year")) {
      let assessmentYears = this.props.allAssessmentMetaData.find(item => 'years' in item).years
      yearOptions = assessmentYears?.map((val) => {
        return {
          filter: "Year",
          name: val.year,
          year_id: val.year_id,
        };
      });
      selectedFilters = [...selectedFilters, ...yearOptions];
    }
    if (v.includes("Difficulty")) {
      let assessmentDifficultyTypes = this.props.allAssessmentMetaData.find(item => 'difficulty_types' in item).difficulty_types;
      difficultyOptions = assessmentDifficultyTypes?.map((val) => {
          return {
              filter: "Difficulty",
              name: val.difficulty_type,
              difficulty_id: val.difficulty_id
          }
      })
      selectedFilters = [...selectedFilters, ...difficultyOptions]
    } 
    if (v.includes("Grade")) {
       let assessmentGrades = this.props.allAssessmentMetaData.find(item => 'grades' in item).grades;
      gradeOptions = assessmentGrades?.map((val) => {
        return {
          filter: "Grade",
          name: val.grade,
          grade_id: val.grade_id,
        };
      });
      selectedFilters = [...selectedFilters, ...gradeOptions];
    }
    if (v.includes("Term")) {
      let assessmentTerms = this.props.allAssessmentMetaData.find(item => 'terms' in item).terms;
      termOptions = assessmentTerms?.map((val) => {
        return {
          filter: "Term",
          name: val.term,
          term_id: val.term_id,
        };
      });
      selectedFilters = [...selectedFilters, ...termOptions];
    }
    if (v.includes("Chapter")) {
      let assessmentChapters = this.props.allAssessmentMetaData.find(item => 'chapters' in item).chapters;
      chapterOptions = assessmentChapters?.map((val) => {
        return {
          filter: "Chapter",
          name: val.chapter,
          chapter_id: val.chapter_id,
        };
      });
      selectedFilters = [...selectedFilters, ...chapterOptions];
    }
    if (v.includes("Status")) {
      let assessmentStatuses = this.props.allAssessmentMetaData.find(item => 'statuses' in item).statuses;
      statusOptions = assessmentStatuses?.map((val) => {
        return {
          filter: "Status",
          name: val.status,
          status_id: val.status_id,
        };
      });
      selectedFilters = [...selectedFilters, ...statusOptions];
    }
    this.setState({
      selectedFilters: selectedFilters,
    });
  };

  displaySubject = (e) => {
    if (e !== null) {
      let assessmentSubjects = this.props.allAssessmentMetaData.find(item => 'subjects' in item)?.subjects;
      const subject = assessmentSubjects?.find(
        (subject) => subject.subject_id == e
      );
      return subject?.subject;
    } else {
      return "N/A";
    }
  };

  displayStatus = (e) => {
    if (e !== null) {
      let assessmentStatuses = this.props.allAssessmentMetaData.find(item => 'statuses' in item)?.statuses;
      const status = assessmentStatuses?.find(
        (status) => status.status_id == e
      );
      return status?.status;
    } else {
      return "N/A";
    }
  };

  displayEditStatusOptions = (assessmentStatuses, assessmentStatus, statusId) =>{
    let editStatuses;
    switch (assessmentStatus) {
      case "Initial":
        editStatuses = assessmentStatuses.filter(status => status.status_id >= parseInt(statusId) && status.status_id < 3);
        break;
      case "Ready":
        editStatuses = assessmentStatuses.filter(status => status.status_id >= parseInt(statusId) && status.status_id < 4);
        break;
      case "Review":
        editStatuses = assessmentStatuses.filter(status => status.status_id >= parseInt(statusId) && status.status_id != 5);
        break;
      case "Published":
        editStatuses = assessmentStatuses.filter(status => status.status_id >= parseInt(statusId));
        break;
      case "Retired":
        editStatuses = assessmentStatuses.filter(status => status.status_id >= parseInt(statusId));
        break;
      default:
        editStatuses = assessmentStatuses;
    }
    let allEditStatuses = [];
    editStatuses.map((el, i) => {
      allEditStatuses.push(
        <Option
          key={el.status_id}
          optionName={el.status}
          value={el.status_id}
        />
      );
    });
    let editStatusOptions = allEditStatuses;
    this.setState({
      editStatusOptions: editStatusOptions
    });
  }

  onSelectFilter = (e) => {
    let selectedGrades = [];
    let selectedChapters = [];
    let selectedsubjects = [];
    let selectedAuthors = [];
    let selectedYears = [];
    let selectedDifficulties = [];
    let selectedCategories = [];
    let selectedTerms = [];
    let selectedLanguages = [];
    let selectedStatuses = [];

    e.map((val) => {
      if (val.filter === "Category") {
        selectedCategories.push(val?.category_id);
      }
      if (val.filter === "Difficulty") {
        selectedDifficulties.push(val?.difficulty_id);
      }
      if (val.filter === "Language") {
        selectedLanguages.push(val?.language_id);
      }
      if (val.filter === "Subject") {
        selectedsubjects.push(val?.subject_id);
      }
      if (val.filter === "Author") {
        selectedAuthors.push(val?.author_id);
      }
      if (val.filter === "Year") {
        selectedYears.push(val?.year_id);
      }
      if (val.filter === "Term") {
        selectedTerms.push(val?.term_id);
      }
      if (val.filter === "Chapter") {
        selectedChapters.push(val?.chapter_id);
      }
      if (val.filter === "Grade") {
        selectedGrades.push(val?.grade_id);
      }
      if (val.filter === "Status") {
        selectedStatuses.push(val?.status_id);
      }
    });

    this.setState(
      {
        filterValues: e,
        selectedAuthors: selectedAuthors.length > 0 ? selectedAuthors : null,
        selectedsubjects: selectedsubjects.length > 0 ? selectedsubjects : null,
        selectedChapters: selectedChapters.length > 0 ? selectedChapters : null,
        selectedTerms: selectedTerms.length > 0 ? selectedTerms : null,
        selectedCategories: selectedCategories.length > 0 ? selectedCategories : null,
        selectedYears: selectedYears.length > 0 ? selectedYears : null,
        selectedLanguages: selectedLanguages.length > 0 ? selectedLanguages : null,
        selectedDifficulties: selectedDifficulties.length > 0 ? selectedDifficulties : null,
        selectedGrades: selectedGrades.length > 0 ? selectedGrades : null,
        selectedStatuses: selectedStatuses.length > 0 ? selectedStatuses : null,
      },
      () => {
        this.fetchAllMyAssessments();
        //this.fetchAllSharedAssessments();
        this.fetchAllPublicAssessments();
      }
    );
  };

  handleChangeAssessors() {
    let assessorList = [];
    let selectedAssessors = window.$("#assessors").find(":selected");
    for (let i = 0; i < selectedAssessors.length; i++) {
      assessorList.push(selectedAssessors[i].attributes.value.value);
    }

    if (!!this.state.errors["assessors"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["assessors"];

      this.setState({
        assessors: assessorList,
        errors,
      });
    } else {
      this.setState({ assessors: assessorList });
    }
  }

  handleChangeTime = (e) => {
    if (!!this.state.errors["start_time"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["start_time"];

      this.setState({
        start_time: e.target.value,
        errors,
      });
    } else {
      this.setState({ start_time: e.target.value });
    }
  };

  handleChangeCandidateEmails = (e) => {
    var candidateEmailslist = [];
    candidateEmailslist.push(e.target.value);
    this.state.candidate_emails.push(e.target.value);
    if (!this.state.errors["candidate_emails"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["candidate_emails"];
      this.setState({
        candidate_emails: candidateEmailslist,
        errors,
      });
    } else {
      this.setState({ candidate_emails: candidateEmailslist });
    }
  };

  handleChangeModalView = async (viewAssessmentDetails) => {
    this.setState({ loadingModal: true });
    // await this.props.onViewAssessment(viewAssessmentDetails.assessment_id);
    await this.props.viewAssessmentData(viewAssessmentDetails?.assessment_id);
    this.setState({viewAssessmentDetails:  this.props.viewAssessmentDetails.find(item => 'assessment' in item)?.assessment});

    let assessmentAssessors = this.props.viewAssessmentDetails.find(item => 'assessors' in item)?.assessors;
    await Promise.all(
      assessmentAssessors.map(async (el) => {
        let assessorNames = this.state.assessorNames;
        if (el.role_code == userRoles.CASS) {
          let chiefAssessorName = el.first_name.concat(" ", el.last_name);
          this.setState({ chiefAssessorName: chiefAssessorName });
        } else {
          let assessorName = el.first_name.concat(" ", el.last_name);
          assessorNames.push(assessorName);
          this.setState({ assessorNames: assessorNames });
        }
      })
    );
    
    // await this.props.getCandidates(viewAssessmentDetails.assessment_id);
    // await this.props.getCandidateReports(viewAssessmentDetails);
    this.setState({ loadingModal: false });
    // await this.props.createStaffUserMap();
  };

  handleDelete = (deleteDetails) => {
    this.setState({ manage_assessment_id: deleteDetails.assessment_id });
    this.setState({ manage_assessment_state: deleteDetails.assessment_state });
  };


  editInitTagify() {
    const input = this.edit_ref_tagify.current;
    if (input) {
      this.edit_ref_tagify.current.tagify = new window.Tagify(input, {
        addTagOnBlur: false,
      });
      this.edit_ref_tagify.current.tagify.DOM.input.classList.add(
        "form-control"
      );
      this.edit_ref_tagify.current.tagify.DOM.input.setAttribute(
        "placeholder",
        "enter tag..."
      );
      this.edit_ref_tagify.current.tagify.DOM.scope.parentNode.insertBefore(
        this.edit_ref_tagify.current.tagify.DOM.input,
        this.edit_ref_tagify.current.tagify.DOM.scope
      );
      this.edit_ref_tagify.current.tagify.on("add", (e) => {
        this.editFuncAddTag(e);
      });
      this.edit_ref_tagify.current.tagify.on("remove", (e) => {
        this.editFuncAddTag(e);
      });
    }
    this.setState({ edit_init_tagify: false });
  }

  handleChangeEditTitle = (e) => {
    if (!!this.state.errors["edit_title"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit_title"];

      this.setState({
        editTitle: e.target.value,
        errors,
      });
    } else {
      this.setState({ editTitle: e.target.value });
    }
  };

  handleChangeEditBufferTime = (e) => {
    e.preventDefault();
    const editBufferDurationValue = parseInt(e.target.value, 10) || 0;
    if (!!this.state.errors["edit_access_buffer"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit_access_buffer"];
      this.setState({ errors });
    } else {
      this.calculateEditBufferDurationInMinutes(editBufferDurationValue, this.state.editBufferTimeUnit);
    }
  };
  
  handleChangeEditBufferTimeUnit = (e) => {
    e.preventDefault();
    const editBufferTimeUnit = e.target.value;
    this.setState({ editBufferTimeUnit }, () => {
      this.calculateEditBufferDurationInMinutes(this.state.editBufferDurationValue, editBufferTimeUnit);
    });
  };
  
  calculateEditBufferDurationInMinutes = (value, unit) => {
    let editBufferDurationInMinutes;
    let bufferUnit = this.getUnit(unit);
    switch (bufferUnit) {
      case unitConstants.HOURS.name:
        editBufferDurationInMinutes = value * 60;
        break;
      case unitConstants.DAYS.name:
        editBufferDurationInMinutes = value * 24 * 60;
        break;
      case unitConstants.MONTHS.name:
        editBufferDurationInMinutes = value * 30 * 24 * 60;
        break;
      case unitConstants.MINUTES.name:
      default:
        editBufferDurationInMinutes = value;
    }
  
    this.setState({
      editAccessBuffer: editBufferDurationInMinutes,
      editBufferDurationValue: value,
    });
  
    if (!!this.state.errors["edit_access_buffer"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit_access_buffer"];
      this.setState({ errors });
    }
  };

  convertBufferDurationInSelectedUnit = (value, unit) => {
    let editBufferDurationInSelectedUnit;
    let bufferUnit = this.getUnit(unit);

    switch (bufferUnit) {
      case unitConstants.MONTHS.name:
        editBufferDurationInSelectedUnit = value / 43200;
        break;
      case unitConstants.HOURS.name:
        editBufferDurationInSelectedUnit = value / 60;
        break;
      case unitConstants.DAYS.name:
        editBufferDurationInSelectedUnit = value / 1440;
        break;
      case unitConstants.MINUTES.name:
      default:
        editBufferDurationInSelectedUnit = value;
    }
    
    return editBufferDurationInSelectedUnit;
  }

  handleChangeEditTestDuration = (e) => {
    e.preventDefault();
    const editTestDurationValue = parseInt(e.target.value, 10) || 0;
    if (!!this.state.errors["edit_test_duration"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit_test_duration"];
      this.setState({ errors });
    } else {
      this.calculateEditDurationInMinutes(editTestDurationValue, this.state.editTimeUnit);
    }
  };
  
  handleChangeEditTimeUnit = (e) => {
    e.preventDefault();
    const editTimeUnit = e.target.value;
    this.setState({ editTimeUnit }, () => {
      this.calculateEditDurationInMinutes(this.state.editTestDurationValue, editTimeUnit);;
    });
  };
  
  calculateEditDurationInMinutes = (value, unit) => {
    let editTestDurationInMinutes;
    let timeUnit = this.getUnit(unit);
  
    switch (timeUnit) {
      case unitConstants.MONTHS.name:
        editTestDurationInMinutes = value * 30 * 24 * 60;
        break;
      case unitConstants.HOURS.name:
        editTestDurationInMinutes = value * 60;
        break;
      case unitConstants.DAYS.name:
        editTestDurationInMinutes = value * 24 * 60;
        break;
      case unitConstants.MINUTES.name:
      default:
        editTestDurationInMinutes = value;
    }
  
    this.setState({
      editTestDuration: editTestDurationInMinutes,
      editTestDurationValue: value
    });
  
    if (!!this.state.errors["edit_test_duration"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit_test_duration"];
      this.setState({ errors });
    }
  };

  convertTestDurationInSelectedUnit = (value, unit) => {
    let editTestDurationInSelectedUnit;
    let timeUnit = this.getUnit(unit);

    switch (timeUnit) {
      case unitConstants.MONTHS.name:
        editTestDurationInSelectedUnit = value / 43200;
        break;
      case unitConstants.HOURS.name:
        editTestDurationInSelectedUnit = value / 60;
        break;
      case unitConstants.DAYS.name:
        editTestDurationInSelectedUnit = value / 1440;
        break;
      case unitConstants.MINUTES.name:
      default:
        editTestDurationInSelectedUnit = value;
    }

    return editTestDurationInSelectedUnit;
  }

  handleChangeEditAdmin = (e) => {
    if (!!this.state.errors["edit_assessment_admin"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit_assessment_admin"];

      this.setState({
        editAssessmentAdminId: e.target.value,
        errors,
      });
    } else {
      this.setState({ editAssessmentAdminId: e.target.value });
    }
  };

  handleChangeEditChiefAssessor = (e) => {
    if (!!this.state.errors["edit_chief_assessor"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit_chief_assessor"];

      this.setState({
        editChiefAssessorId: e.target.value,
        errors,
      });
    } else {
      this.setState({ editChiefAssessorId: e.target.value });
    }
  };

  handleEditLanguage = (e) => {
    if (!!this.state.errors["assessment-language-edit"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["assessment-language-edit"];

      this.setState({
        editLanguage:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
        errors,
      });
    } else {
      this.setState({
        editLanguage:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
      });
    }
    if(e?.target?.value !== "Nothing selected"){
      var edit_terms_content = this.getAssessmentTermsContent(e.target.value);
      this.setState({ 
        editorEditState: EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(edit_terms_content))) 
      }, () => {
        this.setState({ editTermsAndConditions: edit_terms_content });
      });
    }
    else{
      this.setState({ 
        editorEditState: EditorState.createEmpty()
      });
    }
  };

  handleEditCategory = (e) => {
    if (!!this.state.errors["assessment-category-edit"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["assessment-category-edit"];

      this.setState({
        editCategory:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
        errors,
      });
    } else {
      this.setState({
        editCategory:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
      });
    }
  };

  handleEditPrivilege = (e) => {
    if (!!this.state.errors["assessment-privilege-edit"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["assessment-privilege-edit"];

      this.setState({
        editPrivilege: e?.target?.value,
        errors,
      });
    } else {
      this.setState({ editPrivilege: e?.target?.value });
    }
  };

  handleEditAttempts = (e) => {
    if (!!this.state.errors["edit-assessment-attempts"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit-assessment-attempts"];

      this.setState({
        editAttempts: e?.target?.value,
        errors,
      });
    } else {
      this.setState({ editAttempts: e?.target?.value });
    }
  };

  handleEditStatus = (e) => {
    if (!!this.state.errors["edit-assessment-statuses"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit-assessment-statuses"];

      this.setState({
        editStatus: e?.target?.value,
        editStatusName: this.displayStatus(parseInt(e?.target?.value, 10)),
        errors,
      });
    } else {
      this.setState({ editStatus: e?.target?.value, editStatusName: this.displayStatus(parseInt(e?.target?.value, 10)) });
    }
  };

  handleEditDifficulty = (e) => {
    if (!!this.state.errors["assessment-difficulty-edit"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["assessment-difficulty-edit"];

      this.setState({
        editDifficulty:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
        errors,
      });
    } else {
      this.setState({
        editDifficulty:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
      });
    }
  };

  handleEditSubject = (e) => {
    if (!!this.state.errors["edit-assessment-subject"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit-assessment-subject"];

      this.setState({
        editSubject:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
        errors,
      });
    } else {
      this.setState({
        editSubject:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
      });
    }
  };

  handleEditAuthor = (e) => {
    if (!!this.state.errors["edit-assessment-author"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit-assessment-author"];

      this.setState({
        editAuthor:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
        errors,
      });
    } else {
      this.setState({
        editAuthor:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
      });
    }
  };

  handleEditGrade = (e) => {
    if (!!this.state.errors["edit-assessment-grades"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit-assessment-grades"];

      this.setState({
        editGrade:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
        errors,
      });
    } else {
      this.setState({
        editGrade:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
      });
    }
  };

  handleEditYear = (e) => {
    if (!!this.state.errors["edit-assessment-year"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit-assessment-year"];

      this.setState({
        editYear:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
        errors,
      });
    } else {
      this.setState({
        editYear:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
      });
    }
  };

  handleEditTerm = (e) => {
    if (!!this.state.errors["edit-assessment-term"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit-assessment-term"];

      this.setState({
        editTerm:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
        errors,
      });
    } else {
      this.setState({
        editTerm:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
      });
    }
  };

  handleEditChapter = (e) => {
    if (!!this.state.errors["edit-assessment-chapter"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit-assessment-chapter"];

      this.setState({
        editChapter:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
        errors,
      });
    } else {
      this.setState({
        editChapter:
          e?.target?.value === "Nothing selected" ? null : e?.target?.value,
      });
    }
  };

  handleChangeEditAssessors(data) {
    let editSelectedAssessors = this.state.editSelectedAssessors;
    let index = editSelectedAssessors.indexOf(data);
    if (editSelectedAssessors.includes(data)) {
      editSelectedAssessors.splice(index, 1);
    } else {
      editSelectedAssessors.push(data);
    }
    this.setState({ editSelectedAssessors: editSelectedAssessors });
  }

  handleChangeEditSelectedAssessors() {
    let editSelectedAssessors = [];
    let selectedAssessors = window.$("#edit_assessors").find(":selected");
    for (let i = 0; i < selectedAssessors.length; i++) {
      editSelectedAssessors.push(selectedAssessors[i].attributes.value.value);
    }
    this.setState({ editSelectedAssessors: editSelectedAssessors });
  }

  handleChangeEditTime = (e) => {
    let editStartTime = moment.utc(
      e.target.value.concat(
        DataHandler.getFromSession(DataHandler.SESSION_DATA.TimeZoneOffset)
      )
    );
    editStartTime = moment.utc(editStartTime).format();
    if (!!this.state.errors["edit_start_time"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit_start_time"];

      this.setState({
        editStartTime: e.target.value,
        errors,
      });
    } else {
      this.setState({ editStartTime: e.target.value });
    }
  };

  handleChangeEditCandidates = (e) => {
    var editCandidateslist = [];
    editCandidateslist.push(e.target.value);
    if (!!this.state.errors["edit_candidates"]) {
      let errors = Object.assign({}, this.state.errors);
      delete errors["edit_candidates"];

      this.setState({
        editSelectedCandidates: editCandidateslist,
        errors,
      });
    } else {
      this.setState({ editSelectedCandidates: editCandidateslist });
    }
  };
  handleEditToggle = (e) => {
    let PR = !this.state.editProctorRequirement;
    this.setState({ editProctorRequirement: PR });
  };

  handleChangeModalEdit = async (
    editDetails,
    editAssessmentId,
    editTitle,
    editAssessmentAdminId,
    editProctorRequirement
  ) => {
    //this.onClear();
    this.setState({ loadingEditModal: true });
    //await this.props.onViewAssessment(editDetails.assessment_id);
    await this.props.viewAssessmentData(editDetails?.assessment_id);
    // await this.props.getCandidates(editDetails.assessment_id);
    await this.findAssessors(this.props.viewAssessmentDetails.find(item => 'assessors' in item)?.assessors);

    // this.getSelectedCandidates(this.props.candidates);

    this.displayEditStatusOptions(this.state.assessmentStatuses, editDetails.assessment_state, editDetails.status_id);

    this.setState({ editDetails: this.props.viewAssessmentDetails.find(item => 'assessment' in item)?.assessment });
    this.setState({ editTitle });
    this.setState({ editAssessmentId });
    this.setState({ editAssessmentAdminId });
    this.setState({ editProctorRequirement });
    this.setState({ editLanguage: editDetails.language_id });
    this.setState({ editTermsAndConditions: editDetails.terms_content });
    this.setState({ editDifficulty: editDetails.difficulty_id });
    this.setState({ editAttempts: editDetails.attempt_no_id });
    this.setState({ editStatus: editDetails.status_id });
    this.setState({ editStatusName: editDetails.assessment_state });
    this.setState({ editYear: editDetails.year_id });
    this.setState({ editTerm: editDetails.term_id });
    this.setState({ editAuthor: editDetails.author_id });
    this.setState({ editSubject: editDetails.subject_id });
    this.setState({ editGrade: editDetails.grade_id });
    this.setState({ editChapter: editDetails.chapter_id });
    this.setState({ editCategory: editDetails.category_id });
    this.setState({ editPrivilege: editDetails.privilege_id });
    if (editDetails.terms_content !== null) {
      this.setState({
        editorEditState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(editDetails.terms_content)
          )
        ),
      });
    }

    let schedule = this.props.viewAssessmentDetails.find(item => 'schedule' in item)?.schedule;
    var editStartTime = schedule[0]?.local;
    var editTestDuration = schedule[0]?.examTimeInterval;
    var editTimeUnit = schedule[0]?.timeUnit;
    var editAccessBuffer = schedule[0]?.bufferTime;
    var editBufferTimeUnit = schedule[0]?.bufferTimeUnit;
    var editSetTimeLimit = schedule[0]?.setTimeLimit;
    this.setState({ editStartTime: editStartTime });
    this.setState({ editTestDuration: editTestDuration });
    this.setState({ editAccessBuffer: editAccessBuffer });
    this.setState({ editTimeUnit: editTimeUnit });
    this.setState({ editBufferTimeUnit: editBufferTimeUnit });
    this.setState({ editSetTimeLimit: editSetTimeLimit});
    this.setState({ editTestDurationValue: this.convertTestDurationInSelectedUnit(editTestDuration, editTimeUnit) });
    this.setState({ editBufferDurationValue: this.convertBufferDurationInSelectedUnit(editAccessBuffer, editBufferTimeUnit) });
    this.setState({ loadingEditModal: false });
  };

  onDetails = (id) => {
    this.props.createStaffUserMap();
    this.props.viewAssessmentData(id);
    history.push("/view");
  };

  onView = (i) => {
    var assessment = this.props.assessments[i];
    this.props.viewAddQuestions(assessment);
    history.push("/viewAssessmentQuestions");
  };

  onEdit = (id) => {
    this.props.createStaffUserMap();
    this.props.viewAssessmentData(id);
    history.push("/edit");
  };

  //Function to delete a created assessment that is not published or finished
  async onDeleteAssessment() {
    await window.Swal.fire({
      text: "Do you want to delete the assessment?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: !1,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: `btn font-weight-bold ${confirmClass}`,
        cancelButton: `btn font-weight-bold ${cancelClass}`,
      },
      onOpen: (modalElement) => {
        if (customer === customerConstants.EXO_CUSTOMER2) {
          modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          modalElement.querySelector('.swal2-cancel').setAttribute('id', 'cancelButtonId');
        }
      }
    }).then(async (action) => {
      if (action.isConfirmed) {
        //Getting the assessment creator user_id
        var userID = DataHandler.getFromSession("user_id");
        //Getting the deleted assessment assessment_id
        var deleteAssessmentId = this.state.manage_assessment_id;
        //Getting the deleted assessment assessment_state
        var deleteAssessmentState = this.state.manage_assessment_state;

        //Can not delete a published assessment
        if (deleteAssessmentState == "Ready") {
          window.Swal.fire({
            title: namespace.FAILED,
            text: namespace.CANT_DELETE_READY_ASSESSMENT,
            icon: "error",
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
            }
          });
        }
        //Can not delete a finished assessment
        else if (deleteAssessmentState == "Finished") {
          window.Swal.fire({
            title: namespace.FAILED,
            text: namespace.CANT_DELETE_FINISHED_ASSESSMENT,
            icon: "error",
            customClass: {
              confirmButton: `btn font-weight-bold ${confirmClass}`,
            },
            onOpen: (modalElement) => {
              if (customer === customerConstants.EXO_CUSTOMER2) {
                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
              }
            }
          });
        }

        // else if (deleteAssessmentState == "Published") {
        //   window.Swal.fire({
        //     title: namespace.FAILED,
        //     text: namespace.CANT_DELETE_PUBLISHED_ASSESSMENT,
        //     icon: "error",
        //     customClass: {
        //       confirmButton: `btn font-weight-bold ${confirmClass}`,
        //     },
        //     onOpen: (modalElement) => {
        //       if (customer === customerConstants.EXO_CUSTOMER2) {
        //         modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
        //       }
        //     }
        //   });
        // }

        // else if (deleteAssessmentState == "Review") {
        //   window.Swal.fire({
        //     title: namespace.FAILED,
        //     text: namespace.CANT_DELETE_REVIEW_ASSESSMENT,
        //     icon: "error",
        //     customClass: {
        //       confirmButton: `btn font-weight-bold ${confirmClass}`,
        //     },
        //     onOpen: (modalElement) => {
        //       if (customer === customerConstants.EXO_CUSTOMER2) {
        //         modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
        //       }
        //     }
        //   });
        // }

        // else if (deleteAssessmentState == "Retired") {
        //   window.Swal.fire({
        //     title: namespace.FAILED,
        //     text: namespace.CANT_DELETE_RETIRED_ASSESSMENT,
        //     icon: "error",
        //     customClass: {
        //       confirmButton: `btn font-weight-bold ${confirmClass}`,
        //     },
        //     onOpen: (modalElement) => {
        //       if (customer === customerConstants.EXO_CUSTOMER2) {
        //         modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
        //       }
        //     }
        //   });
        // }

        //Can delete an initial state assessment
        else {
          this.setState({isTableDataLoading: true})
          await this.props.deleteAssessment({ userID, deleteAssessmentId });
          this.setState({isTableDataLoading: false})
          if (this.props.deleteSuccess) {
            window.Swal.fire({
              title: "Success!",
              text: namespace.ASSESSMENT_DELETED_SUCCESSFUL,
              icon: "success",
              customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
              }
            }).then(function () {
              history.push(routes.view_assessment);
              window.location.reload();
            });
          } else if (this.props.deleteError) {
            await window.Swal.fire({
              title: messageTypes.FAILED_WARNING,
              text: messageTypes.DELETE_FAIL,
              icon: messageTypes.ERROR,
              customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                if (customer === customerConstants.EXO_CUSTOMER2) {
                  modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                }
              }
            });
          }
          this.setState({ deletion: true });
        }
      }
    });
  }

  async setupAssessment(assessment) {
    this.setState({assessment: assessment});
    sessionStorage.setItem("setup_assessment", JSON.stringify(assessment));
    await this.props.getAssessmentQuestions(assessment.assessment_id);
    this.setState({setupAssessment: true});
    this.props.viewAddQuestions(assessment);
    this.props.getAllQuestions();
    this.props.refreshforAddingQuestions();
  }

  async gradeAssessment(assessment) {


    if (this.props.candidateError == "") {
       this.setState({
        assessment:assessment,
      });
      this.setState({
        grade: true,
      });
    } else {
      window.Swal.fire({
        title: messageTypes.FAILED_MESSAGE,
        text: messageTypes.FAILED_MESSAGE,
        icon: messageTypes.ERROR,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
  }

  async viewCandidateReport() {
    this.setState({candidateReportLoading: true})
    await this.props.getCandidateReports(this.props.assessment);
    this.setState({candidateReportLoading: false})
    if (this.props.candidateError == "") {
      this.setState({
        grade: true,
      });
    } else {
      window.Swal.fire({
        title: messageTypes.FAILED_MESSAGE,
        text: messageTypes.FAILED_MESSAGE,
        icon: messageTypes.ERROR,
        customClass: {
          confirmButton: `btn font-weight-bold ${confirmClass}`,
        },
        onOpen: (modalElement) => {
          if (customer === customerConstants.EXO_CUSTOMER2) {
            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
          }
        }
      });
    }
  }

  getColor(state) {
    switch (state) {
      case "Initial":
        return "label label-lg label-light-primary label-inline";
      case "Ready":
        return "label label-lg label-light-warning label-inline";
      case "Review":
        return "label label-lg label-light-default label-inline";
      case "Published":
        return "label label-lg label-success label-inline";
      case "Retired":
        return "label label-lg label-light-danger label-inline";
      default:
        return "label label-lg label-light-info label-inline";
    }
  }

  getDate(date) {
    //date = new Date(moment.utc(date).format());
    //date.toLocaleString('en-US', { timeZone: DataHandler.getFromSession(DataHandler.SESSION_DATA.TimeZone) });

    let createdTime = new Date(date).getTime();
    let currentTime =
      new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000;

    let difference = currentTime - createdTime;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24;

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60;

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60;

    //var secondsDifference = Math.floor(difference / 1000);

    if (daysDifference == 0 && hoursDifference == 0 && minutesDifference == 0) {
      return "Created Just now";
    } else {
      return (
        "Created " +
        daysDifference +
        "d " +
        hoursDifference +
        "h " +
        minutesDifference +
        "m " +
        "ago"
      );
    }
  }

  async findAssessors(el) {
    let assessors = el;
    let editSelectedAssessors = [];
    let assessorsToDelete = [];
    let editChiefAssessorId;
    let chiefAssessorToDelete;

    if (assessors && assessors.length) {
      for (const key in assessors) {
        if (assessors[key].role_code === userRoles.CASS) {
          editChiefAssessorId = assessors[key].id;
          chiefAssessorToDelete = assessors[key].id;
        } else {
          editSelectedAssessors.push(assessors[key].id);
          assessorsToDelete.push(assessors[key].id);
        }
      }
    }
    this.setState({
      editChiefAssessorId,
      editSelectedAssessors,
      assessorsToDelete,
      chiefAssessorToDelete,
    });
  }

  returnLanguage = (id) => {
    switch (id) {
      case 1:
        return "English";
      case 2:
        return "Tamil";
      case 3:
        return "Sinhala";
    }
  };

  onTermsStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    let contentState = convertToRaw(this.state.editorState.getCurrentContent());
    if (contentState?.blocks[0].text.trim().length !== 0) {
      let content = convertToHTML(this.state.editorState.getCurrentContent());
      this.setState({
        termsAndConditions: content,
      });
    }
  };

  onTermsEditStateChange = (editorEditState) => {
    this.setState(
      {
        editorEditState,
      },
      () => {
        let contentState = convertToRaw(
          this.state.editorEditState.getCurrentContent()
        );
        if (contentState?.blocks[0].text.trim().length !== 0) {
          let content = convertToHTML(
            this.state.editorEditState.getCurrentContent()
          );
          this.setState({
            editTermsAndConditions: content,
          });
        } else {
          this.setState({
            editTermsAndConditions: null,
          });
        }
      }
    );
  };

  getSelectedCandidates(el) {
    let candidates = el;
    let candidatesToDelete = [];
    if (candidates && candidates.length) {
      for (const key in candidates) {
        candidatesToDelete.push(candidates[key].candidate_email);
      }
    }
    this.setState({ candidatesToDelete });
  }

  handleSortChange = (sort_column) => {
    // If the same column is clicked, toggle the sort direction
    const { sort_column: currentSortColumn, sort_direction } = this.state;
    const newSortDirection =
      currentSortColumn === sort_column && sort_direction === "ASC"
        ? "DESC"
        : "ASC";

    this.setState(
      {
        sort_column,
        sort_direction: newSortDirection,
        offset: 0, // Reset offset when changing sorting
        currentPage: 0,
        isTableDataLoading: true,
      },
      () => {
        this.fetchAllMyAssessments();
        //this.fetchAllSharedAssessments();
        this.fetchAllPublicAssessments();
      }
    );
  };

  handlePageChange = (event, newPage) => {
    const { rowsPerPage } = this.state;
    const newOffset = newPage * rowsPerPage;
    this.setState(
      {
        limit: rowsPerPage,
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllMyAssessments();
        //this.fetchAllSharedAssessments();
        this.fetchAllPublicAssessments();
      }
    );
  };

  handlePageOffsetChange = (newOffset, newPage) => {
    this.setState(
      {
        limit: this.state.rowsPerPage,
        offset: newOffset,
        isTableDataLoading: true,
        currentPage: newPage
      },
      () => {
        this.fetchAllMyAssessments();
        //this.fetchAllSharedAssessments();
        this.fetchAllPublicAssessments();
      }
    );
  };

  //handle the data fetching as per the data per page limit gets changes
  handleRowsPerPageChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: newLimit });
    const newPage = Math.floor(this.state.offset / newLimit);
    this.setState(
      {
        limit: newLimit,
        offset: newLimit * newPage,
        isTableDataLoading: true,
        currentPage: newPage,
      },
      () => {
        this.fetchAllMyAssessments();
        //this.fetchAllSharedAssessments();
        this.fetchAllPublicAssessments();
      }
    );
  };

  handleSearch = (event) => {
    const search_term = event.target.value.trim();
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;

    if (search_term !== "") {
      this.setState({ limit: this.props.totalMyAssessmentsCount });
    }

    if (search_term === "" || regex.test(search_term)) {
      this.setState(
        {
          search_term,
          isTableDataLoading: true,
          offset: 0,
          currentPage: 0,
          searchError: false,
        },
        async () => {
          // Call the debounced version of fetchStudentData
          // this.debouncedHandleSearch();
          await this.fetchAllMyAssessments();
          //await this.fetchAllSharedAssessments();
          await this.fetchAllPublicAssessments();
        }
      );
    } else {
      this.setState({ search_term, searchError: true });
    }
  };

  syncTableManage() {
    return (
      <>
        {this.state.myAssessments.length > 0 ? (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="thead"></TableCell>
                  {/* <TableCell className="thead">No</TableCell> */}
                  <TableCell
                    className="thead"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleSortChange("assessment_id")}
                  >
                    Assessment Code{" "}
                    {this.state.sort_column === "assessment_id" && (
                      <span>
                        {this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    className="thead"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleSortChange("title")}
                  >
                    Assessment Title{" "}
                    {this.state.sort_column === "title" && (
                      <span>
                        {this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    className="thead"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleSortChange("subject_id")}
                  >

                    Assessment Subject{" "}
                    {this.state.sort_column === "subject_id" && (
                      <span>
                        {this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )}
                  </TableCell>
                  <TableCell
                    className="thead"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.handleSortChange("assessment_state")}
                  >

                    Assessment Status{" "}
                    {this.state.sort_column === "assessment_state" && (
                      <span>
                        {this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className="thead">Assessment Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {this.state.myAssessments.map((el, i) => (
                  <TableRow key={i}>
                    <TableCell className="tData"></TableCell>
                    {/* <TableCell className="tData">{i + 1}</TableCell> */}
                    <TableCell className="tData">
                      <div className="center-vertical mg-m library-title">
                        {"ASMNT" + el.assessment_id}
                      </div>
                    </TableCell>
                    <TableCell className="tData">
                      <Tooltip title={el.title} placement="bottom-start" arrow>
                        <div className="center-vertical mg-m library-title">
                          {el.title.length > 20
                            ? `${el.title.substring(0, 20)}...`
                            : el.title}
                          {/* <span className="d-block text-muted pt-2 font-size-sm">
                                        {this.getDate(el.created_at)}
                                    </span> */}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell className="tData">
                      <div className="center-vertical mg-m library-title">
                        {this.displaySubject(el.subject_id)}
                      </div>
                    </TableCell>
                    <TableCell className="tData">
                      {
                        <span className={this.getColor(el.assessment_state)}>
                          {el.assessment_state}
                        </span>
                      }
                    </TableCell>
                    <TableCell className="tData">
                      {
                        <div>
                          <Button
                            className="btn btn-icon action-btn  btn-sm m-1 view-assement-btn flaticon-eye-icon"
                            dataIndex={i}
                            title="View Details"
                          />

                          {el.assessment_state !== "Published" && (
                            <Button
                              className="btn btn-icon action-btn btn-sm m-1 edit-assement-btn flaticon-edit-icon"
                              dataIndex={i}
                              title="Edit Details"
                            />
                          )}
                          {el.assessment_state === "Initial" && ( 
                            <Button
                              className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                              dataIndex={i}
                              title="Delete"
                              onClick={this.onDeleteAssessment}
                            />
                          )}
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className="text-center mb-5 my-10" style={{ height: "90vh" }}>
            <label>No Assessments Available!</label>
          </div>
        )}
      </>
    );
  }

  syncTableAdmin() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="thead">#</TableCell>
            {/* <TableCell className="thead">No</TableCell> */}
            <TableCell
              className="thead"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSortChange("title")}
            >
              Assessment Title{" "}
              {this.state.sort_column === "title" && (
                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
              )}
            </TableCell>
            <TableCell
              className="thead"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSortChange("assessment_state")}
            >
              Assessment Status{" "}
              {this.state.sort_column === "assessment_state" && (
                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
              )}
            </TableCell>
            <TableCell className="thead">Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {this.props.adminAssessments.map((el, i) => (
            <TableRow key={i}>
              <TableCell className="tData"></TableCell>
              {/* <TableCell className="tData">{i + 1}</TableCell> */}
              <TableCell className="tData">
                <Tooltip title={el.title} placement="bottom-start" arrow>
                  <div className="center-vertical mg-m library-title">
                    {el.title.length > 20
                      ? `${el.title.substring(0, 20)}...`
                      : el.title}
                    {/* <span
                                    className="d-block text-muted pt-2 font-size-sm"> {this.getDate(el.created_at)} </span> */}
                  </div>
                </Tooltip>
              </TableCell>

              <TableCell className="tData">
                {
                  <span className={this.getColor(el.assessment_state)}>
                    {el.assessment_state}
                  </span>
                }
              </TableCell>
              <TableCell className="tData">
                {
                  <div>
                    <Button
                      className="btn btn-icon btn-primary btn-sm m-1 viewAdmin-assement-btn flaticon-eye-icon"
                      dataIndex={i}
                      title="View Details"
                    />
                  </div>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  syncTableAssess() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="thead"></TableCell>
            {/* <TableCell className="thead">No</TableCell> */}
            <TableCell
              className="thead"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSortChange("title")}
            >
              Assessment Title{" "}
              {this.state.sort_column === "title" && (
                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
              )}
            </TableCell>
            <TableCell
              className="thead"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSortChange("assessment_state")}
            >
              Assessment Status{" "}
              {this.state.sort_column === "assessment_state" && (
                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
              )}
            </TableCell>
            <TableCell className="thead">Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {this.state.sharedAssessments.map((el, i) => (
            <TableRow key={i}>
              <TableCell className="tData"></TableCell>
              {/* <TableCell className="tData">{i + 1}</TableCell> */}
              <TableCell className="tData">
                <Tooltip title={el.title} placement="bottom-start" arrow>
                  <div className="center-vertical mg-m library-title">
                    {el.title.length > 20
                      ? `${el.title.substring(0, 20)}...`
                      : el.title}
                    {/* <span
                                    className="d-block text-muted pt-2 font-size-sm"> {this.getDate(el.created_at)} </span> */}
                  </div>
                </Tooltip>
              </TableCell>

              <TableCell className="tData">
                {
                  <span className={this.getColor(el.assessment_state)}>
                    {el.assessment_state}
                  </span>
                }
              </TableCell>
              <TableCell className="tData">
                {
                  <div>
                    <Button
                      className="btn btn-icon action-btn btn-sm m-1 viewAssess-assement-btn flaticon-eye-icon"
                      dataIndex={i}
                      title="View Details"
                    />
                    <Button
                      className="btn btn-icon action-btn btn-sm m-1 setup-assement-btn flaticon2-checking-icon"
                      dataIndex={i}
                      title="Setup Assessment"
                      to="setup-assessment"
                    />
                    {/* <Button
                        className="btn btn-icon action-btn btn-sm m-1 grade-assement-btn flaticon2-correct-icon"
                        dataIndex={i}
                        title="Grade Assessment"
                        to="grade_report"
                    /> */}
                  </div>
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  syncTablePublic() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="thead"></TableCell>
            {/* <TableCell className="thead">No</TableCell> */}
            <TableCell
              className="thead"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSortChange("assessment_id")}
            >
              Assessment Code{" "}
              {this.state.sort_column === "assessment_id" && (
                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
              )}
            </TableCell>
            <TableCell
              className="thead"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSortChange("title")}
            >
              Assessment Title{" "}
              {this.state.sort_column === "title" && (
                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
              )}
            </TableCell>
            <TableCell
              className="thead"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSortChange("subject_id")}
            >
              Assessment Subject{" "}
              {this.state.sort_column === "subject_id" && (
                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
              )}
            </TableCell>
            <TableCell
              className="thead"
              style={{ cursor: "pointer" }}
              onClick={() => this.handleSortChange("assessment_state")}
            >
              Assessment Status{" "}
              {this.state.sort_column === "assessment_state" && (
                <span>{this.state.sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
              )}
            </TableCell>
            <TableCell className="thead">Assessment Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
        {this.state.publicAssessments.map((el, i) => {
            
            const t1 = this.state.teacher_grade_reports.length > 0 
            ? this.state.teacher_grade_reports.find(t1 => t1.assessment_id === el.assessment_id) 
            : undefined;

            return (
              <TableRow key={i}>
                <TableCell className="tData"></TableCell>
                {/* <TableCell className="tData">{i + 1}</TableCell> */}
                <TableCell className="tData">
                  <div className="center-vertical mg-m library-title">
                    {"ASMNT" + el.assessment_id}
                  </div>
                </TableCell>
                <TableCell className="tData">
                  <Tooltip title={el.title} placement="bottom-start" arrow>
                    <div className="center-vertical mg-m library-title">
                      {el.title.length > 20
                        ? `${el.title.substring(0, 20)}...`
                        : el.title}
                      {/* <span
                          className="d-block text-muted pt-2 font-size-sm"> {this.getDate(el.created_at)} </span> */}
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell className="tData">
                  <div className="center-vertical mg-m library-title">
                    {this.displaySubject(el.subject_id)}
                  </div>
                </TableCell>
                <TableCell className="tData">
                  <span className={this.getColor(el.assessment_state)}>
                    {el.assessment_state}
                  </span>
                </TableCell>
                <TableCell className="tData">
                  <div>
                    <Button
                      className="btn btn-icon action-btn btn-sm m-1 view-public-assement-btn flaticon-eye-icon"
                      dataIndex={i}
                      title="View Details"
                    />

                    {el.assessment_state !== "Published" && (
                      <Button
                        className="btn btn-icon action-btn btn-sm m-1 edit-public-assement-btn flaticon-edit-icon"
                        dataIndex={i}
                        title="Edit Details"
                      />
                    )}

                    <Button
                      className="btn btn-icon action-btn btn-sm m-1 setup-public-assement-btn flaticon2-checking-icon"
                      dataIndex={i}
                      title="Setup Assessment"
                      to="setup-assessment"
                    />

                    {/* <Button
                      className="btn btn-icon btn-danger btn-sm m-1 delete-public-assement-btn flaticon-delete"
                      dataIndex={i}
                      title="Delete"
                      onClick={this.onDeleteAssessment}
                    /> */}

                    {t1 && (
                      <Button
                        className="btn btn-icon action-btn btn-sm m-1 grade-public-assement-btn flaticon2-correct-icon"
                        dataIndex={i}
                        title="Grade Assessment"
                        to="grade_report"
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}

        </TableBody>
      </Table>
    );
  }
  render() {
    let candidates = this.props.candidates;
    if (candidates && candidates.length && this.state.lockEdit == false) {
      for (const key in candidates) {
        if (
          candidates[key].candidate_status == "Finished" ||
          candidates[key].candidate_status == "Onprogress"
        ) {
          this.setState({
            lockEdit: true,
          });
        }
      }
    }
    let schedule = this.props.viewAssessmentDetails.find(item => 'schedule' in item)?.schedule;
    let candidateView;
    let scheduleView;

    if (this.props.candidateReports.length > 0) {
      candidateView = (
        <div>
          <Button
            className="btn btn-primary font-weight-bold mt-5"
            dataDismiss="modal"
            btnName="View Candidates"
            onClick={(e) => this.viewCandidateReport()}
          />
        </div>
      );
    } else {
      candidateView = <div> Candidates are not added yet!</div>;
    }
   
    if (schedule && schedule.length) {
      scheduleView = (
        <div className="row">
          <h6 className="font-weight-bolder mb-3 col-md-4">Schedule :</h6>
          <div className="text-dark-50 line-height-lg col-md-5">
            <div className="inline-flex full-width align-center">
              <div>Date : {schedule[0].startDate} </div>
            </div>

            <div className="inline-flex full-width align-center">
              <div>Start Time : {schedule[0].startTime} </div>
            </div>

            {schedule[0].examTimeInterval !== null ?
              <div className="inline-flex full-width align-center">
                <div>Time Duration : {this.convertTestDurationInSelectedUnit(schedule[0].examTimeInterval, schedule[0].timeUnit) + ' ' + this.getUnit(schedule[0].timeUnit)}</div>
              </div>
              : (
                <div className="inline-flex full-width align-center">
                  <div>Time Duration : Unlimited</div>
                </div>
              )
            }

            {schedule[0].bufferTime !== null ?
              <div className="inline-flex full-width align-center">
                <div>Access Buffer Duration : {this.convertBufferDurationInSelectedUnit(schedule[0].bufferTime , schedule[0].bufferTimeUnit) + ' ' + this.getUnit(schedule[0].bufferTimeUnit)} </div>
              </div>
              : (
                <div className="inline-flex full-width align-center">
                  <div>Access Buffer Duration : Unlimited</div>
                </div>
              )
            }
          </div>
        </div>
      );
    } else {
      scheduleView = (
        <div className="row">
          <h6 className="font-weight-bolder mb-3 col-md-4">Schedule :</h6>
          <div className="text-dark-50 line-height-lg col-md-5">
            <div className="inline-flex full-width align-center">
              <div> Schedule is not addedd yet!</div>
            </div>
          </div>
        </div>
      );
    }

    let candidateEditView;
    let scheduleEdit;

    if (schedule && schedule.length) {
      scheduleEdit = (
        <div>
          <FormGroup
            labelName={"Date and Time (Timezone: ".concat(
              DataHandler.getFromSession("TimeZone"),
              ")"
            )}
            className="col-lg-12"
          >
            <div className="col-lg-8">
              <Input
                min={this.state.dateInputMin}
                type="datetime-local"
                className="form-control form-control-solid datetimepicker-input"
                inputWrap="col-lg-14"
                id="kt_datetimepicker_5"
                dataToggle="datetimepicker"
                dataTarget="#kt_datetimepicker_5"
                value={this.state.editStartTime}
                onChange={this.handleChangeEditTime}
                name="edit_start_time"
                disabled={this.state.lockEdit}
              />
              {!this.state.lockEdit && (
                <span className="d-block text-muted pt-2 font-size-sm">
                  Click calendar icon to edit assessment start time
                </span>
              )}
              {this.state.lockEdit && (
                <span className="d-block text-muted pt-2 font-size-sm">
                  Candidate/s attempted, cannot edit assessment start time
                </span>
              )}
            </div>
          </FormGroup>

          <Row className="my-5">
            <ReactForm.Group as={Col} md="6" controlId="editSetTimeLimit">
              <div style={{ display: "flex", alignItems: "center" }}>
                <ReactForm.Label style={{ marginLeft: "12px", marginRight: "20px", whiteSpace: "nowrap" }}>
                  Do you want to set a time limit?
                </ReactForm.Label>
                <CheckBoxInline style={{ alignItems: "center" }}>
                  <ReactForm.Label
                    className="mb-0 mr-1"
                    style={{ fontWeight: "500" }}
                  >
                    Yes
                  </ReactForm.Label>
                  <CheckBox
                    onChange={this.handleCheckEditTimeLimit}
                    checked={this.state.editSetTimeLimit}
                  />
                  <ReactForm.Label
                    className="mb-0 mr-1"
                    style={{ fontWeight: "500" }}
                  >
                    No
                  </ReactForm.Label>
                  <CheckBox
                    onChange={this.handleCheckEditTimeLimit}
                    checked={!this.state.editSetTimeLimit}
                  />
                </CheckBoxInline>
              </div>
            </ReactForm.Group>
          </Row>

          {this.state.editSetTimeLimit && 
            <>
              <div className="separator separator-dashed my-5"></div>
              <FormGroup
                labelName="Time Duration"
                className="col-lg-12"
              >
                <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '310px', marginRight: '0px' }}>
                    <Input
                      type="number"
                      inputWrap="col-lg-12"
                      className="form-control"
                      value={this.state.editTestDurationValue || ''}
                      onChange={this.handleChangeEditTestDuration}
                      placeholder="Enter the time duration in selected unit"
                      name="edit_test_duration"
                      id="edit_test_duration"
                      disabled={this.state.lockEdit}
                      style={{width: '100%', height: '40px', padding: '8px 2px', fontSize: '14px'}}
                      maxLength={7}
                    />
                  </div>
                  <div style={{ flexShrink: 0, width: '165px' }}>
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-schedule-time-units"
                      id="edit-assessment-schedule-time-units"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editTimeUnit}
                      multiple={false}
                      children={this.state.timeUnitOptions}
                      onChange={this.handleChangeEditTimeUnit}
                    ></Select>
                  </div>
                </div>
                {this.state.lockEdit && (
                  <span className="d-block text-muted pt-2 font-size-sm">
                    Candidate/s attempted, cannot edit assessment duration
                  </span>
                )}
              </FormGroup>
            </>
          }

          {this.state.editSetTimeLimit && 
            <>
              <div className="separator separator-dashed my-5"></div>
              <FormGroup
                labelName="Access Buffer Duration"
                className="col-lg-12"
              >
                <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '310px', marginRight: '0px' }}>
                    <Input
                      type="number"
                      inputWrap="col-lg-12"
                      className="form-control"
                      value={this.state.editBufferDurationValue || ''}
                      onChange={this.handleChangeEditBufferTime}
                      placeholder="Enter the buffer time in selected unit"
                      name="edit_access_buffer"
                      id="edit_access_buffer"
                      style={{width: '100%', height: '40px', padding: '8px 2px', fontSize: '14px'}}
                      maxLength={7}
                    />
                  </div>
                  <div style={{ flexShrink: 0, width: '165px' }}>
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-schedule-buffer-time-units"
                      id="edit-assessment-schedule-buffer-time-units"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editBufferTimeUnit}
                      multiple={false}
                      children={this.state.bufferTimeUnitOptions}
                      onChange={this.handleChangeEditBufferTimeUnit}
                    ></Select>
                  </div>
                </div>
              </FormGroup>
            </>
          }
        </div>
      );
    }

    if (candidates && candidates.length) {
      candidateEditView = (
        <div>
          <Button
            className="btn btn-primary font-weight-bold mt-5"
            dataDismiss="modal"
            btnName="Delete Candidates"
            onClick={(e) => this.viewCandidateReport()}
          />
          <span className="label label-lg label-light-primary label-inline font-weight-bold mt-5">
            You can delete the candidates through grade report..
          </span>
        </div>
      );
    } else {
      candidateEditView = (
        <span className="label label-lg label-light-warning label-inline font-weight-bold mt-5">
          Candidates are not added yet!
        </span>
      );
    }

    if (this.state.setupAssessment) {
      return (
        <>
          <Redirect to={routes.setup_assessment} />
          <SetupAssessment assessment={this.state.assessment} />
        </>
      );
    }

    if (this.state.grade) {
      // return <Redirect to="/teacher_grade_report" />;
      return  <Redirect
      to={{
        pathname: routes.teacher_grade_report,
        state: { assessment: this.state.assessment }
      }}
    />
    }
    

    return (
      <AdminTemplate>
        <div style={{ height: "90vh", overflow: "auto" }}>
          <ContentWrapper>
            {/* begin::Card */}
            { 
              (this.props.loadingQuestions ||
              this.props.candidateLoading ||
              this.props.deleteLoading) && (
                <Spinner id="show" text="Please wait.." />
            )}
            {this.state.candidateReportLoading && (
              <Spinner id="show" text="Please wait.." />
            )}
            {this.state.isTableDataLoading && (
              <Spinner id="show" text="Please wait.." />
            )}
            <Card>
              <CardHeader>
                <CardTitle cardLabel="Assessments Library" />
                <CardToolbar>
                  <CreateButton
                    createButtonTitle="Create Assessment"
                    dataToggle="modal"
                    dataTarget="#exampleModal"
                  />
                </CardToolbar>
              </CardHeader>
              <CardBody>
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <TabList>
                      <NavItem
                        className="nav-link active"
                        id="manage-tab-1"
                        href="#manage-1"
                        dataToggle="tab"
                        iconName="flaticon2-chat-1"
                        tabName="My Assessments"
                        onClick={() => {
                          this.setState({
                            count: this.props.totalMyAssessmentsCount,
                          });
                        }}
                      />
                      {/* 
                                      <NavItem 
                                          className="nav-link" 
                                          id="administrate-tab-1" 
                                          href="#administrate-1"
                                          dataToggle="tab" 
                                          iconName="flaticon-users-1" 
                                          tabName="Administrate"
                                      /> 
                                      */}
                      {/* <NavItem
                                          className="nav-link"
                                          id="assess-tab-1"
                                          href="#assess-1"
                                          dataToggle="tab"
                                          iconName="flaticon2-rocket-1"
                                          tabName="Shared Assessments"
                                          onClick={() => { this.setState({ count: this.props.totalSharedAssessmentsCount }) }}
                                      /> */}
                      <NavItem
                        className="nav-link"
                        id="public-tab-1"
                        href="#public-1"
                        dataToggle="tab"
                        iconName="flaticon2-layers"
                        tabName="All Assessments"
                        onClick={() => {
                          this.setState({
                            count: this.props.totalPublicAssessmentsCount,
                          });
                        }}
                      />
                    </TabList>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup labelName="Filter By">
                          <Autocomplete
                            multiple
                            id="checkboxes-filter"
                            options={ASSESSMENT_LIBRARY_FILTERS}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            onChange={(e, v, r) => this.onFilterInput(v, r)}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  style={{ marginRight: 8, color: "#5458AF" }}
                                  checked={selected}
                                />
                                {option}
                              </li>
                            )}
                            className="col-lg-8 col-md-8 p-0"
                            renderInput={(params) => (
                              <TextField {...params} label="Select Filter" />
                            )}
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        {this.state.filters.length > 0 && (
                          <FormGroup labelName="">
                            <Autocomplete
                              multiple
                              id="grouped-demo"
                              className="col-lg-8 col-md-8 p-0"
                              options={this.state.selectedFilters}
                              groupBy={(option) => option.filter}
                              onChange={(e, v) => this.onSelectFilter(v)}
                              getOptionLabel={(option) => option.name}
                              sx={{ width: 300 }}
                              value={this.state.filterValues}
                              renderInput={(params) => (
                                <TextField {...params} label="Filters" />
                              )}
                            />
                          </FormGroup>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 py-3">
                  <ReactForm.Group
                    controlId="exampleForm.ControlInput1"
                    className="mb-2"
                  >
                    <ReactForm.Control
                      type="text"
                      placeholder="Search"
                      onChange={this.handleSearch}
                      className="col-md-12 col-12"
                    />
                  </ReactForm.Group>
                </div>
                <div className="my-table">
                  <TabContent>
                    <TabPane
                      className="tab-pane fade show active"
                      id="manage-1"
                      ariaLabelledby="manage-tab-1"
                    >
                      {this.state.isTableDataLoading ? (
                        <div>
                          <div className="loading-container mb-5 mt-10">
                            <ReactSpinner
                              animation="border"
                              role="status"
                              style={{ display: "block", margin: "auto" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </ReactSpinner>
                          </div>
                        </div>
                      ) : (
                        this.syncTableManage()
                      )}
                      <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                          totalCount = {this.props.totalMyAssessmentsCount}
                          currentPage = {this.state.currentPage}
                          limit = {this.state.rowsPerPage}
                          handlePageChange = {this.handlePageChange}
                          handleRowsPerPageChange = {this.handleRowsPerPageChange}
                          handlePageOffsetChange = {this.handlePageOffsetChange}
                          offset = {this.state.offset}
                          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                        />
                      </div>
                    </TabPane>
                    <TabPane
                      className="tab-pane fade"
                      id="administrate-1"
                      ariaLabelledby="administrate-tab-1"
                    >
                      {this.props.adminAssessments.length > 0 ? (
                        this.syncTableAdmin()
                      ) : (
                        <div>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="thead">#</TableCell>
                                <TableCell className="thead">No</TableCell>
                                <TableCell className="thead">
                                  Assessment Title
                                </TableCell>
                                <TableCell className="thead">
                                  Assessment Status
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                          <div className="text-center mb-5 my-10">
                            <label>No Assessments Available!</label>
                          </div>
                        </div>
                      )}
                    </TabPane>

                    <TabPane
                      className="tab-pane fade"
                      id="assess-1"
                      ariaLabelledby="assess-tab-1"
                    >
                      {this.state.isTableDataLoading ? (
                        <div>
                          <div className="loading-container mb-5 mt-10">
                            <ReactSpinner
                              animation="border"
                              role="status"
                              style={{ display: "block", margin: "auto" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </ReactSpinner>
                          </div>
                        </div>
                      ) : this.state.sharedAssessments.length > 0 ? (
                        this.syncTableAssess()
                      ) : (
                        <div>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="thead">#</TableCell>
                                <TableCell className="thead">No</TableCell>
                                <TableCell className="thead">
                                  Assessment Title
                                </TableCell>
                                <TableCell className="thead">
                                  Assessment Status
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                          <div className="text-center mb-5 my-10">
                            <label>No Assessments Available!</label>
                          </div>
                        </div>
                      )}
                      <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                          totalCount = {this.props.totalSharedAssessmentsCount}
                          currentPage = {this.state.currentPage}
                          limit = {this.state.rowsPerPage}
                          handlePageChange = {this.handlePageChange}
                          handleRowsPerPageChange = {this.handleRowsPerPageChange}
                          handlePageOffsetChange = {this.handlePageOffsetChange}
                          offset = {this.state.offset}
                          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                        />
                      </div>
                    </TabPane>
                    <TabPane
                      className="tab-pane fade"
                      id="public-1"
                      ariaLabelledby="public-tab-1"
                    >
                      {this.state.isTableDataLoading ? (
                        <div>
                          <div className="loading-container mb-5 mt-10">
                            <ReactSpinner
                              animation="border"
                              role="status"
                              style={{ display: "block", margin: "auto" }}
                            >
                              <span className="sr-only">Loading...</span>
                            </ReactSpinner>
                          </div>
                        </div>
                      ) : this.state.publicAssessments.length > 0 ? (
                        this.syncTablePublic()
                      ) : (
                        <div style={{ overflowX: "auto" }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className="thead">#</TableCell>
                                <TableCell className="thead">No</TableCell>
                                <TableCell className="thead">
                                  Assessment Title
                                </TableCell>
                                <TableCell className="thead">
                                  Assessment Status
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                          <div className="text-center mb-5 my-10">
                            <label>No Assessments Available!</label>
                          </div>
                        </div>
                      )}
                      <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                          totalCount = {this.props.totalPublicAssessmentsCount}
                          currentPage = {this.state.currentPage}
                          limit = {this.state.rowsPerPage}
                          handlePageChange = {this.handlePageChange}
                          handleRowsPerPageChange = {this.handleRowsPerPageChange}
                          handlePageOffsetChange = {this.handlePageOffsetChange}
                          offset = {this.state.offset}
                          rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100]}
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </CardBody>
            </Card>
            {/* end::Card */}

            {/* Modal */}
            <Modal
              modalId="exampleModal"
              dataBackDrop="static"
              dataKeyboard="false"
            >
              <ModalHeader>
                <h5 className="modal-title">New Assessment</h5>
              </ModalHeader>
              <ModalBody>
                <Wizard>
                  <WizardSteps>
                    <WizardStepsItems
                      wizardTitle="Create Assessment"
                      className="svg-icon"
                      wizardNumber="1"
                      dataWizardState={
                        this.state.currentIndex > -1 ? "current" : ""
                      }
                    />
                    <WizardStepsItems
                      wizardTitle="Role Assignment"
                      className="svg-icon"
                      wizardNumber="2"
                      dataWizardState={
                        this.state.currentIndex > 0 ? "current" : ""
                      }
                    />
                    <WizardStepsItems
                      wizardTitle="Schedule Assessment"
                      className="svg-icon d-none"
                      wizardNumber="3"
                      dataWizardState={
                        this.state.currentIndex > 1 ? "current" : ""
                      }
                    />
                    {/* <WizardStepsItems wizardTitle="Add Candidates" className="svg-icon d-none" wizardNumber="4"
                                      dataWizardState={this.state.currentIndex > 2 ? "current" : ''} /> */}
                  </WizardSteps>

                  <WizardForm id="kt_form">
                    {/* begin: Wizard Step 1 */}
                    {this.state.currentIndex == 0 && (
                      <StepContent
                        wizardTitle="Create Assessment"
                        dataWizardState="current"
                      >
                        <FormGroup labelName="Title*" className="col-lg-12">
                          <Input
                            type="text"
                            inputWrap="col-lg-8"
                            className="form-control"
                            name="title"
                            value={this.state.title}
                            onChange={this.handleChange}
                            id="title"
                          />
                        </FormGroup>

                        <FormGroup labelName="Medium/Language" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-language"
                            id="assessment-language"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.languageOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.languageOptions.length <= 0
                            }
                            disabled={
                              this.props.loading &&
                              this.state.languageOptions.length <= 0
                            }
                            children={this.state.languageOptions}
                            onChange={this.onChangeAssessmentLanguage}
                          ></Select>
                        </FormGroup>
                        <FormGroup labelName="Category" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-category"
                            id="assessment-category"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.categoryOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.categoryOptions.length <= 0
                            }
                            disabled={
                              this.props.loading &&
                              this.state.categoryOptions.length <= 0
                            }
                            children={this.state.categoryOptions}
                            onChange={this.onChangeAssessmentCategory}
                          ></Select>
                        </FormGroup>
                        <FormGroup labelName="Difficulty" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-difficulty"
                            id="assessment-difficulty"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.difficultyOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.difficultyOptions.length <= 0
                            }
                            disabled={
                              this.props.loading &&
                              this.state.difficultyOptions.length <= 0
                            }
                            children={this.state.difficultyOptions}
                            onChange={this.onChangeAssessmentDifficulty}
                          ></Select>
                        </FormGroup>
                        <FormGroup labelName="Privilege" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-privilege"
                            id="assessment-privilege"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.privilegeOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.privilegeOptions.length <= 0
                            }
                            disabled={!DataHandler.getFromSession("isSYST")}
                            children={this.state.privilegeOptions}
                            onChange={this.onChangeAssessmentPrivilege}
                          ></Select>
                        </FormGroup>
                        {customer !== customerConstants.EXO_CUSTOMER2 && (
                          <FormGroup labelName="Attempts" className="col-lg-12">
                            <Select
                              selectWrap="col-lg-8"
                              selectId="assessmentAttempts"
                              id="assessmentAttempts"
                              className="form-control selectpicker"
                              dataLiveSearch="true"
                              dataSize="5"
                              defaultValue={this.state.attemptOptions[0]}
                              multiple={false}
                              spinner={
                                this.props.loading &&
                                this.state.attemptOptions.length <= 0
                              }
                              disabled={
                                this.props.loading &&
                                this.state.attemptOptions.length <= 0
                              }
                              children={this.state.attemptOptions}
                              onChange={this.onChangeAttempt}
                            ></Select>
                          </FormGroup>
                        )}
                        <FormGroup labelName="Grade*" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-grades"
                            id="assessment-grades"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.gradeOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.gradeOptions.length <= 0
                            }
                            disabled={
                              this.props.loading &&
                              this.state.gradeOptions.length <= 0
                            }
                            children={this.state.gradeOptions}
                            onChange={this.onChangeGrade}
                          ></Select>
                        </FormGroup>
                        <FormGroup labelName="Subject" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-subject"
                            id="assessment-subject"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.subjectOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.subjectOptions.length <= 0
                            }
                            disabled={
                              this.props.loading &&
                              this.state.subjectOptions.length <= 0
                            }
                            children={this.state.subjectOptions}
                            onChange={this.onChangeSubject}
                          ></Select>
                        </FormGroup>
                        <FormGroup labelName="Author" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-author"
                            id="assessment-author"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.authorOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.authorOptions.length <= 0
                            }
                            disabled={
                              this.props.loading &&
                              this.state.authorOptions.length <= 0
                            }
                            children={this.state.authorOptions}
                            onChange={this.onChangeAuthor}
                          ></Select>
                        </FormGroup>
                        <FormGroup labelName="Year" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-year"
                            id="assessment-year"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.yearOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.yearOptions.length <= 0
                            }
                            disabled={
                              this.props.loading &&
                              this.state.yearOptions.length <= 0
                            }
                            children={this.state.yearOptions}
                            onChange={this.onChangeYear}
                          ></Select>
                        </FormGroup>
                        <FormGroup labelName="Term/Semester" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-term"
                            id="assessment-term"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.termOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.termOptions.length <= 0
                            }
                            disabled={
                              this.props.loading &&
                              this.state.termOptions.length <= 0
                            }
                            children={this.state.termOptions}
                            onChange={this.onChangeTerm}
                          ></Select>
                        </FormGroup>
                        <FormGroup labelName="Chapter/Module/Unit" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessment-chapter"
                            id="assessment-chapter"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.chapterOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.chapterOptions.length <= 0
                            }
                            disabled={
                              this.props.loading &&
                              this.state.chapterOptions.length <= 0
                            }
                            children={this.state.chapterOptions}
                            onChange={this.onChangeChapter}
                          ></Select>
                        </FormGroup>
                        <FormGroup
                          labelName="Terms & Conditions"
                          className="col-lg-12"
                        >
                          <div className="col-lg-8">
                            <Editor
                              toolbar={{
                                options: [
                                  "inline",
                                  "blockType",
                                  "fontSize",
                                  "list",
                                  "textAlign",
                                ],
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                              }}
                              editorClassName="editor-class"
                              editorState={this.state.editorState}
                              onEditorStateChange={this.onTermsStateChange}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup labelName="Status" className="col-lg-12">
                          <Select
                            selectWrap="col-lg-8"
                            selectId="assessmentStatuses"
                            id="assessmentStatuses"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            defaultValue={this.state.statusOptions[0]}
                            multiple={false}
                            spinner={
                              this.props.loading &&
                              this.state.statusOptions.length <= 0
                            }
                            disabled={true}
                            children={this.state.statusOptions}
                            onChange={this.onChangeStatus}
                          ></Select>
                        </FormGroup>
                        {/* <FormGroup labelName="Assessment Admin" className="col-lg-12">
                          {this.state.staffUserOptions.length &&
                              <Select
                                  isLoading={!this.state.staffUserOptions.length}
                                  selectWrap="col-lg-8"
                                  className="form-control selectpicker"
                                  dataLiveSearch="true"
                                  dataSize="5"
                                  onChange={this.handleChangeAdmin}
                                  selectId="assessment_admin"
                                  name="assessment_admin"
                                  defaultValue={this.state.staffUserOptions[0]}
                                  value={this.state.assessment_admin}
                                  spinner={this.props.loading && this.state.staffUserOptions.length <= 0}
                                  disabled={this.props.loading && this.state.staffUserOptions.length <= 0}
                                  children={this.state.staffUserOptions}
                              >
                              </Select>
                          }
                      </FormGroup> */}
                      {/* <FormGroup labelName="Proctor Requirement" className="col-lg-12">
                          <SwitchCheck
                              className="col-lg-8"
                              name="proctor_requirement"
                              onChange={this.handleToggle}
                              checked={this.state.proctor_requirement}
                              id="proctor_requirement"
                              type="checkbox"
                      />
                      </FormGroup> */}
                      </StepContent>
                    )}
                    {/* end: Wizard Step 1 */}

                    {/* begin: Wizard Step 2 */}
                    {this.state.currentIndex == 1 && (
                      <StepContent
                        wizardTitle="Role Assignment"
                        dataWizardState="current"
                      >
                        <FormGroup
                          labelName="Chief Assessor"
                          className="col-lg-12"
                        >
                          <Select
                            selectWrap="col-lg-8"
                            className="form-control selectpicker"
                            dataLiveSearch="true"
                            dataSize="5"
                            selectId="chief_assessor"
                            name="chief_assessor"
                            onChange={this.handleChangeChiefAssessor}
                            defaultValue={this.state.staffUserOptions[0]}
                            value={this.state.chief_assessor}
                            spinner={
                              this.props.teacherDetailsLoading &&
                              this.state.staffUserOptions.length <= 0
                            }
                            disabled={
                              this.props.teacherDetailsLoading &&
                              this.state.staffUserOptions.length <= 0
                            }
                            children={this.state.staffUserOptions}
                          ></Select>
                        </FormGroup>

                        {DataHandler.getFromSession("isSYST") && (
                          <FormGroup labelName="Assessor" className="col-lg-12">
                            <Select
                              selectId="assessors"
                              selectWrap="col-lg-8"
                              name="assessors"
                              className="form-control select2"
                              dataLiveSearch="true"
                              dataSize="5"
                              value={this.state.assessors}
                              multiple="multiple"
                              dataActionsBox="true"
                              onChange={this.handleChangeAssessors}
                              defaultValue={this.state.staffUserAssessorOptions}
                              spinner={
                                this.props.teacherDetailsLoading &&
                                this.state.staffUserAssessorOptions.length <= 0
                              }
                              disabled={
                                this.props.teacherDetailsLoading &&
                                this.state.staffUserAssessorOptions.length <= 0
                              }
                              children={this.state.staffUserAssessorOptions}
                            ></Select>
                          </FormGroup>
                        )}
                      </StepContent>
                    )}
                    {/* end: Wizard Step 2 */}

                    {/* begin: Wizard Step 3 */}
                    {this.state.currentIndex == 2 && (
                      <StepContent
                        wizardTitle="Schedule Assessment"
                        dataWizardState="current"
                      >
                        <FormGroup
                          labelName={"Assessment Date and Time (Timezone: ".concat(
                            DataHandler.getFromSession("TimeZone"),
                            ")"
                          )}
                          className="col-lg-20"
                        >
                          <div className="col-lg-8">
                            <Input
                              min={this.state.dateInputMin}
                              type="datetime-local"
                              className="form-control form-control-solid datetimepicker-input"
                              inputWrap="col-lg-14"
                              id="kt_datetimepicker_5"
                              placeholder="Select date &amp; time"
                              dataToggle="datetimepicker"
                              dataTarget="#kt_datetimepicker_5"
                              value={this.state.start_time}
                              onChange={this.handleChangeTime}
                              name="start_time"
                            />
                            <span className="d-block text-muted pt-2 font-size-sm">
                              Click calendar icon to schedule assessment
                            </span>
                          </div>
                        </FormGroup>

                        <Row className="my-5">
                          <ReactForm.Group as={Col} md="6" controlId="setTimeLimit">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <ReactForm.Label style={{ marginLeft: "0px", marginRight: "20px", whiteSpace: "nowrap" }}>
                                Do you want to set a time limit?
                              </ReactForm.Label>
                              <CheckBoxInline style={{ alignItems: "center" }}>
                                <ReactForm.Label
                                  className="mb-0 mr-1"
                                  style={{ fontWeight: "500" }}
                                >
                                  Yes
                                </ReactForm.Label>
                                <CheckBox
                                  onChange={this.handleCheckTimeLimit}
                                  checked={this.state.setTimeLimit}
                                />
                                <ReactForm.Label
                                  className="mb-0 mr-1"
                                  style={{ fontWeight: "500" }}
                                >
                                  No
                                </ReactForm.Label>
                                <CheckBox
                                  onChange={this.handleCheckTimeLimit}
                                  checked={!this.state.setTimeLimit}
                                />
                              </CheckBoxInline>
                            </div>
                          </ReactForm.Group>
                        </Row>

                        {this.state.setTimeLimit && 
                          <>
                            <FormGroup
                              labelName="Time Duration"
                              className="col-lg-20"
                            >
                              <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '310px', marginRight: '0px' }}>
                                  <Input
                                    type="number"
                                    inputWrap="col-lg-12"
                                    className="form-control"
                                    value={this.state.testDurationValue || ''}
                                    onChange={this.handleChangeTestDuration}
                                    placeholder="Enter the time duration in selected unit"
                                    name="test_duration"
                                    id="test_duration"
                                    style={{width: '100%', height: '40px', padding: '8px 2px', fontSize: '14px'}}
                                    maxLength={7}
                                  />
                                </div>
                                <div style={{ flexShrink: 0, width: '195px' }}>
                                  <Select
                                    selectWrap="col-lg-8"
                                    selectId="assessment-schedule-time-units"
                                    id="assessment-schedule-time-units"
                                    className="form-control"
                                    dataLiveSearch="true"
                                    dataSize="5"
                                    defaultValue={this.state.timeUnitOptions[0]}
                                    multiple={false}
                                    spinner={
                                      this.props.loading &&
                                      this.state.timeUnitOptions.length <= 0
                                    }
                                    disabled={
                                      this.props.loading &&
                                      this.state.timeUnitOptions.length <= 0
                                    }
                                    children={this.state.timeUnitOptions}
                                    onChange={this.handleChangeTimeUnit}
                                    style={{ width: '100%', height: '40px', padding: '8px 12px',fontSize: '14px'}}
                                  ></Select>
                                </div>
                              </div>
                            </FormGroup>
                          </>
                        }

                        {this.state.setTimeLimit && 
                          <>
                            <FormGroup
                              labelName="Access Buffer Duration"
                              className="col-lg-20"
                            >
                              <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ width: '310px', marginRight: '0px' }}>
                                  <Input
                                    type="number"
                                    inputWrap="col-lg-12"
                                    className="form-control"
                                    value={this.state.bufferDurationValue || ''}
                                    onChange={this.handleChangeBufferTime}
                                    placeholder="Enter the buffer time in selected unit"
                                    name="access_buffer"
                                    id="access_buffer"
                                    style={{width: '100%', height: '40px', padding: '8px 2px', fontSize: '14px'}}
                                    maxLength={7}
                                  />
                                </div>
                                <div style={{ flexShrink: 0, width: '195px' }}>
                                    <Select
                                      selectWrap="col-lg-8"
                                      selectId="assessment-schedule-buffer-time-units"
                                      id="assessment-schedule-buffer-time-units"
                                      className="form-control"
                                      dataLiveSearch="true"
                                      dataSize="5"
                                      defaultValue={this.state.bufferTimeUnitOptions[0]}
                                      multiple={false}
                                      spinner={
                                        this.props.loading &&
                                        this.state.bufferTimeUnitOptions.length <= 0
                                      }
                                      disabled={
                                        this.props.loading &&
                                        this.state.bufferTimeUnitOptions.length <= 0
                                      }
                                      children={this.state.bufferTimeUnitOptions}
                                      onChange={this.handleChangeBufferTimeUnit}
                                      style={{ width: '100%', height: '40px', padding: '8px 12px',fontSize: '14px'}}
                                  ></Select>
                                </div>
                              </div>
                            </FormGroup>
                          </>
                        }
                      </StepContent>
                    )}
                    {/* end: Wizard Step 3 */}

                    {/* begin: Wizard Step 4 */}
                    {
                      // this.state.currentIndex == 3 &&
                      // <StepContent wizardTitle="Add Candidates" dataWizardState="current">
                      //     {/* <FormGroup labelName="Candidates" className="col-lg-12">
                      //         <Textarea className="col-lg-8" rows="3" />
                      //     </FormGroup> */}
                      //     <div className="form-group">
                      //         <div className="col-lg-12">
                      //             <div className="row">
                      //                 <label className="col-lg-4 col-form-label">Candidates</label>
                      //                 <div className="col-lg-8">
                      //                     <div className="dropdown bootstrap-select form-control">
                      //                         <input id="kt_tagify_3" ref={this.ref_tagify}
                      //                             name='candidates'
                      //                             value= {this.state.candidate_emails}
                      //                             onChange= {this.handleChangeCandidateEmails}
                      //                             class='form-control tagify tagify--outside'
                      //                             placeholder='Add candidate emails here...' />
                      //                     <span className="d-block text-muted pt-2 font-size-sm">
                      //                         Press enter key to tag the typed or pasted email
                      //                     </span>
                      //                     </div>
                      //                 </div>
                      //             </div>
                      //         </div>
                      //     </div>
                      // </StepContent>
                    }
                    {/* end: Wizard Step 4 */}

                    <WizardActions
                      onClickPrev={this.onClickPrev}
                      onClickNext={this.onClickNext}
                      onClickSubmit={this.onClickSubmit}
                      disabled={this.state.btnDisabled}
                      onDiscard={this.onClickDiscard}
                      maxCount={this.state.maxCount}
                      currentIndex={this.state.currentIndex}
                    />
                  </WizardForm>
                </Wizard>
              </ModalBody>
            </Modal>
            {/* Modal */}
            {/* View Details Modal */}

            <Modal
              modalId="view-details"
              dataBackDrop="static"
              dataKeyboard="false"
            >
              <ModalHeader>
                <h5 className="modal-title">{this.state.viewAssessmentDetails.title}</h5>
                <Button
                  className="btn btn-light-primary font-weight-bold"
                  dataDismiss="modal"
                  btnName="Close"
                  onClick={(e) => {
                    this.setState({ viewAssessmentDetails: "" });
                  }}
                />
              </ModalHeader>
              {(this.state.loadingModal || this.props.candidateLoading) ? (
              <div>
                <div className="loading-container mb-5 mt-10">
                  <ReactSpinner
                    animation="border"
                    role="status"
                    style={{ display: "block", margin: "auto" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </ReactSpinner>
                </div>
              </div>
              ) : (
              <ModalBody>
                <Form className="form" id="kt_form">
                  {/* begin::Section */}
                  {/* <div className="row">
                                  <h6 className="font-weight-bolder mb-3 col-md-4">Assessment Admin :</h6>
                                  <div className="text-dark-50 line-height-lg col-md-5">
                                      <div>{this.props.assessment.assessment_admin_name}</div>
                                  </div>
                              </div> */}

                  {/* <div className="separator separator-dashed my-5"></div> */}
                  {/* end::Section */}

                  {/* begin::Section */}
                  {/* <div className="row">
                                  <h6 className="font-weight-bolder mb-3 col-md-4">Proctor Requirement :</h6>
                                  <div className="text-dark-50 line-height-lg col-md-5">
                                      {this.state.viewAssessmentDetails.proctor_requirement && <div>Proctoring Enabled</div>}
                                      {!this.state.viewAssessmentDetails.proctor_requirement && <div>Proctoring Disabled</div>}

                                  </div>
                              </div>

                              <div className="separator separator-dashed my-5"></div> */}
                  {/* end::Section */}

                  {/* begin::Section */}
                  <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Assessors :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      {this.state.assessorNames?.map((name, index) => (
                        <div key={index}>{name}</div>
                      ))}
                      {this.state.chiefAssessorName}
                      <br />
                      <span className="label label-lg label-light-warning label-inline font-weight-bold ml-2">
                        Chief Assessor
                      </span>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-5"></div>
                  {scheduleView}
                  {/* <div className="row">
                    <h6 className="font-weight-bolder mb-3 col-md-4">
                      Candidates :
                    </h6>
                    <div className="text-dark-50 line-height-lg col-md-5">
                      {candidateView}
                    </div>
                  </div> */}
                  {this.state.viewAssessmentDetails.category_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Category :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                              this.props.allAssessmentMetaData.find(item => 'categories' in item).categories 
                              .find(category => category.category_id === this.state.viewAssessmentDetails.category_id)?.name
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.viewAssessmentDetails.language_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Medium/Language :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                              this.props.allAssessmentMetaData.find(item => 'languages' in item).languages
                              .find(lang => lang.language_id === this.state.viewAssessmentDetails.language_id)?.language
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.viewAssessmentDetails.difficulty_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Difficulty :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                              this.props.allAssessmentMetaData.find(item => 'difficulty_types' in item).difficulty_types
                              .find(difficulty => difficulty.difficulty_id === this.state.viewAssessmentDetails.difficulty_id)?.difficulty_type
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.viewAssessmentDetails.attempt_no_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Attempts :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                              this.props.allAssessmentMetaData.find(item => 'attempts' in item).attempts
                              .find(attempt => attempt.attempt_no_id === this.state.viewAssessmentDetails.attempt_no_id)?.no_of_attempts
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.viewAssessmentDetails.grade_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Grade :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                              this.props.allAssessmentMetaData.find(item => 'grades' in item).grades 
                              .find(grade => grade.grade_id === this.state.viewAssessmentDetails.grade_id)?.grade
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.viewAssessmentDetails.subject_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Subject :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                              this.props.allAssessmentMetaData.find(item => 'subjects' in item).subjects 
                              .find(subject => subject.subject_id === this.state.viewAssessmentDetails.subject_id)?.subject
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.viewAssessmentDetails.year_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Year :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                              this.props.allAssessmentMetaData.find(item => 'years' in item).years 
                              .find(year => year.year_id === this.state.viewAssessmentDetails.year_id)?.year
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.viewAssessmentDetails.term_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Term/Semester :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                              this.props.allAssessmentMetaData.find(item => 'terms' in item).terms 
                              .find(term => term.term_id === this.state.viewAssessmentDetails.term_id)?.term
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.viewAssessmentDetails.chapter_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Chapter/Module/Unit :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                             this.props.allAssessmentMetaData.find(item => 'chapters' in item).chapters 
                             .find(chapter => chapter.chapter_id === this.state.viewAssessmentDetails.chapter_id)?.chapter
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.viewAssessmentDetails.author_id && (
                    <>
                      <div className="separator separator-dashed my-5"></div>
                      <div className="row">
                        <h6 className="font-weight-bolder mb-3 col-md-4">
                          Author :
                        </h6>
                        <div className="text-dark-50 line-height-lg col-md-5">
                          <div className="inline-flex full-width align-center">
                            {
                              this.props.allAssessmentMetaData.find(item => 'authors' in item).authors
                              .find(author => author.author_id === this.state.viewAssessmentDetails.author_id)?.author
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Form>
              </ModalBody>
            )}
            </Modal>

            {/* Edit Details Modal */}
            <Modal
              modalId="edit-details"
              dataBackDrop="static"
              dataKeyboard="false"
            >
              <ModalHeader>
                <h5 className="modal-title">{this.state.editDetails?.title}</h5>
                <div class="text-right">
                  <Button
                    className="btn btn-primary font-weight-bold mr-2"
                    id="edit_assessment_submit_btn"
                    onClick={this.onUpdateDetails}
                    btnName="Save changes"
                    disabled={this.state.editTitle == "" ? true : false}
                  />
                  <Button
                    className="btn btn-light-primary font-weight-bold"
                    dataDismiss="modal"
                    onClick={this.onClear}
                    btnName="Close"
                  />
                </div>
              </ModalHeader>
              {(this.state.loadingEditModal || this.props.candidateLoading) ? (
              <div>
                <div className="loading-container mb-5 mt-10">
                  <ReactSpinner
                    animation="border"
                    role="status"
                    style={{ display: "block", margin: "auto" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </ReactSpinner>
                </div>
              </div>
              ) : (
              <ModalBody>
                <Form className="form" id="kt_form">
                  {/* <div className='table-wrapper-scroll-y my-custom-scrollbar'> */}
                  <FormGroup labelName="Title*" className="col-lg-12">
                    <div className="col-lg-8">
                      <input
                        type="text"
                        name="edit_title"
                        className="form-control"
                        value={this.state.editTitle}
                        onChange={this.handleChangeEditTitle}
                        // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                        id="edit_title"
                      />
                    </div>
                  </FormGroup>
                  <div className="separator separator-dashed my-5"></div>
                  <FormGroup labelName="Medium/Language" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="assessment-language-edit"
                      id="assessment-language-edit"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editLanguage}
                      multiple={false}
                      children={this.state.languageOptions}
                      //   disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      onChange={this.handleEditLanguage}
                    ></Select>
                  </FormGroup>
                  <div className="separator separator-dashed my-5"></div>
                  <FormGroup labelName="Category" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="assessment-category-edit"
                      id="assessment-category-edit"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editCategory}
                      multiple={false}
                      children={this.state.categoryOptions}
                      // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      onChange={this.handleEditCategory}
                    ></Select>
                  </FormGroup>
                  <FormGroup labelName="Privilege" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="assessment-privilege-edit"
                      id="assessment-privilege-edit"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editPrivilege}
                      multiple={false}
                      children={this.state.privilegeOptions}
                      onChange={this.handleEditPrivilege}
                      disabled={!DataHandler.getFromSession("isSYST")}
                    ></Select>
                  </FormGroup>
                  <div className="separator separator-dashed my-5"></div>
                  <FormGroup labelName="Difficulty" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="assessment-difficulty-edit"
                      id="assessment-difficulty-edit"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editDifficulty}
                      multiple={false}
                      children={this.state.difficultyOptions}
                      // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      onChange={this.handleEditDifficulty}
                    ></Select>
                  </FormGroup>
                  <FormGroup labelName="Attempts" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-attempts"
                      id="edit-assessment-attempts"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editAttempts}
                      multiple={false}
                      // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      children={this.state.attemptOptions}
                      onChange={this.handleEditAttempts}
                    ></Select>
                  </FormGroup>
                  <FormGroup labelName="Grade*" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-grades"
                      id="edit-assessment-grades"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editGrade}
                      multiple={false}
                      children={this.state.gradeOptions}
                      // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      onChange={this.handleEditGrade}
                    ></Select>
                  </FormGroup>
                  <FormGroup labelName="Subject" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-subject"
                      id="edit-assessment-subject"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editSubject}
                      multiple={false}
                      children={this.state.subjectOptions}
                      // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      onChange={this.handleEditSubject}
                    ></Select>
                  </FormGroup>
                  <FormGroup labelName="Author" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-author"
                      id="edit-assessment-author"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editAuthor}
                      multiple={false}
                      children={this.state.authorOptions}
                      // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      onChange={this.handleEditAuthor}
                    ></Select>
                  </FormGroup>
                  <FormGroup labelName="Year" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-year"
                      id="edit-assessment-year"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editYear}
                      multiple={false}
                      children={this.state.yearOptions}
                      // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      onChange={this.handleEditYear}
                    ></Select>
                  </FormGroup>
                  <FormGroup labelName="Term/Semester" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-term"
                      id="edit-assessment-term"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editTerm}
                      multiple={false}
                      children={this.state.termOptions}
                      // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      onChange={this.handleEditTerm}
                    ></Select>
                  </FormGroup>
                  <FormGroup labelName="Chapter/Module/Unit" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-chapter"
                      id="edit-assessment-chapter"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editChapter}
                      multiple={false}
                      children={this.state.chapterOptions}
                      // disabled={this.state.editAssessmentState === "Ready" ? true : false}
                      onChange={this.handleEditChapter}
                    ></Select>
                  </FormGroup>
                  <div className="separator separator-dashed my-5"></div>
                  <FormGroup labelName="Terms & Conditions" className="col-lg-12">
                    <div className="col-lg-8">
                      <Editor
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "list",
                            "textAlign",
                          ],
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                        }}
                        editorClassName="editor-class"
                        editorState={this.state.editorEditState}
                        onEditorStateChange={this.onTermsEditStateChange}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup labelName="Status" className="col-lg-12">
                    <Select
                      selectWrap="col-lg-8"
                      selectId="edit-assessment-statuses"
                      id="edit-assessment-statuses"
                      className="form-control selectpicker"
                      dataLiveSearch="true"
                      dataSize="5"
                      value={this.state.editStatus}
                      disabled={true}
                      multiple={false}
                      children={this.state.editStatusOptions}
                      onChange={this.handleEditStatus}
                    ></Select>
                  </FormGroup>
                  
                  {/* <FormGroup labelName="Assessment Admin" className="col-lg-12">
                                  {this.state.staffUserOptions.length &&
                                      <Select
                                          selectWrap="col-lg-8"
                                          selectId="edit_assessment_admin"
                                          id="edit_assessment_admin"
                                          name="edit_assessment_admin"
                                          className="form-control selectpicker"
                                          dataLiveSearch="true"
                                          dataSize="5"
                                          defaultValue={this.state.staffUserOptions[0]}
                                          value={this.state.editAssessmentAdminId}
                                          multiple={false}
                                          spinner={this.props.loading && this.state.staffUserOptions.length <= 0}
                                          disabled={this.props.loading && this.state.staffUserOptions.length <= 0}
                                          children={this.state.staffUserOptions}
                                          onChange={this.handleChangeEditAdmin}>
                                      </Select>}
                              </FormGroup> */}
                  <div className="separator separator-dashed my-5"></div>
                  <FormGroup labelName="Chief Assessor" className="col-lg-12">
                    {this.state.staffUserOptions.length && (
                      <Select
                        selectWrap="col-lg-8"
                        selectId="edit_chief_assessor"
                        name="edit_chief_assessor"
                        id="edit_chief_assessor"
                        className="form-control selectpicker"
                        dataLiveSearch="true"
                        dataSize="5"
                        defaultValue={this.state.staffUserOptions[0]}
                        value={this.state.editChiefAssessorId}
                        multiple={false}
                        spinner={
                          this.props.loading &&
                          this.state.staffUserOptions.length <= 0
                        }
                        disabled={
                          this.props.loading &&
                          this.state.staffUserOptions.length <= 0
                        }
                        children={this.state.staffUserOptions}
                        onChange={this.handleChangeEditChiefAssessor}
                      ></Select>
                    )}
                  </FormGroup>
                  <div className="separator separator-dashed my-5"></div>
                  {/* <FormGroup labelName="Assessors" className="col-lg-12">
                                  {this.state.staffUserAssessorOptions &&
                                      <Select
                                          ref="mySelect"
                                          selectId="edit_assessors"
                                          selectWrap="col-lg-8"
                                          name="edit_assessors"
                                          id="mySelect"
                                          className="form-control select2"
                                          dataLiveSearch="true"
                                          dataSize="5"
                                          value={this.state.editSelectedAssessors}
                                          multiple="multiple"
                                          dataActionsBox="true"
                                          spinner={this.props.loading && this.state.staffUserAssessorOptions.length <= 0}
                                          disabled={this.props.loading && this.state.staffUserAssessorOptions.length <= 0}
                                          children={this.state.staffUserAssessorOptions}
                                          onChange={this.handleChangeEditSelectedAssessors}>
                                      </Select>
                                  }
                              </FormGroup>
                              <div className="separator separator-dashed my-5"></div> */}
                  {scheduleEdit}
                  {/* </div> */}
                  {/* <div className="separator separator-dashed my-5"></div> */}
                  {/* <FormGroup labelName="Candidates" className="col-lg-12">
                                  <div className="col-lg-8">
                                      <div className="dropdown bootstrap-select form-control">
                                          <input id="kt_tagify_3" ref={this.edit_ref_tagify}
                                              name='edit_candidates'
                                              class='form-control tagify tagify--outside'
                                              placeholder='Add new candidate emails here..'
                                          />
                                          <span className="d-block text-muted pt-2 font-size-sm">
                                                              Press enter key to tag the typed or pasted email
                                          </span>
                                      </div>
                                      {candidateEditView}
                                      
                                  </div>
                              </FormGroup> */}

                  {/* <div className="separator separator-dashed my-5"></div> */}
                  {/* <FormGroup labelName="Proctor Requirement" className="col-lg-12">
                                  <SwitchCheck
                                      className="col-lg-8"
                                      name="edit_proctor_requirement"
                                      checked={this.state.editProctorRequirement}
                                      onChange={this.handleEditToggle}
                                      id="edit_proctor_requirement"
                                      type="checkbox"
                                  />
                              </FormGroup> */}
                </Form>
              </ModalBody>
            )}
            </Modal>
          </ContentWrapper>
        </div>
      </AdminTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    allStaffUsers: state.staffUserReducer.allStaffUsers,
    teacherDetails: state.manageUserReducer.teacherDetails,
    allTeachers: state.manageUserReducer.allTeachers,
    teacherDetailsLoading: state.manageUserReducer.loading,
    staffUserMap: state.staffUserReducer.staffUserMap,

    managedAssessments: state.assessmentLibraryReducer.managedAssessments,
    myAssessments: state.assessmentLibraryReducer.myAssessments,
    publicAssessments: state.assessmentLibraryReducer.publicAssessments,
    
    totalMyAssessmentsCount:
      state.assessmentLibraryReducer.totalMyAssessmentsCount,
    totalPublicAssessmentsCount:
      state.assessmentLibraryReducer.totalPublicAssessmentsCount,
    totalSharedAssessmentsCount:
      state.assessmentLibraryReducer.totalSharedAssessmentsCount,
    
    adminAssessments: state.assessmentLibraryReducer.adminAssessments,
    assessoredAssessments: state.assessmentLibraryReducer.assessoredAssessments,
    sharedAssessments: state.assessmentLibraryReducer.sharedAssessments,
    
    assessment: state.assessmentLibraryReducer.assessment,
    viewAssessmentDetails: state.assessmentLibraryReducer.viewAssessmentDetails,
    assessmentLoading: state.assessmentLibraryReducer.loading,
    error: state.assessmentLibraryReducer.error,
    success: state.assessmentLibraryReducer.success,
    allAssessmentMetaData: state.assessmentReducer.allAssessmentMetaData,

    createLoading: state.assessmentCreateReducer.loading,
    deleteLoading: state.assessmentReducer.loading,
    createError: state.assessmentCreateReducer.error,
    createSuccess: state.assessmentCreateReducer.success,
    deleteSuccess: state.assessmentReducer.success,
    deleteError: state.assessmentReducer.error,
    createdAssessment: state.assessmentCreateReducer.assessment,
    loading: state.assessmentCreateReducer.loading,

    candidateError: state.candidateReducer.error,
    candidateSuccess: state.candidateReducer.success,
    candidates: state.candidateReducer.candidates,
    candidateLoading: state.candidateReducer.loading,
    invalidEmails: state.candidateReducer.invalid,
    candidateReports: state.candidateReducer.candidateReports,

    scheduleError: state.assessmentScheduleReducer.error,
    schedule: state.assessmentScheduleReducer.schedule,
    scheduleLoading: state.assessmentScheduleReducer.loading,

    loadingUserOptions: state.assessmentLibraryReducer.loading,
    assessorError: state.assessorReducer.error,
    assessorLoading: state.assessorReducer.loading,
    assessors: state.assessorReducer.assessors,

    editScheduleSuccess: state.editScheduleReducer.success,
    editDetailsSuccess: state.editDetailsReducer.success,
    editAssessorsSuccess: state.editAssessorsReducer.success,
    editCandidatesSuccess: state.editCandidatesReducer.success,
    editCandidatesInvalidEmails: state.editCandidatesReducer.invalid,
    editCandidateError: state.editCandidatesReducer.error,
    loadingQuestions: state.questionReducer.loading
  };
}

const mapActionsToProps = {
  listManagedAssessments: libraryActions.listManagedAssessments,
  listAdministratedAssessments: libraryActions.listAdministratedAssessments,
  listAssessedAssessments: libraryActions.listAssessedAssessments,
  
  getAllMyAssessments: libraryActions.getAllMyAssessments,
  getAllSharedAssessments: libraryActions.getAllSharedAssessments,
  getAllPublicAssessments: libraryActions.getAllPublicAssessments,
  
  onViewAssessment: libraryActions.viewAssessment,
  viewAssessmentData: libraryActions.viewAssessmentData,
  getCandidates: libraryActions.getCandidates,
  getSchedule: libraryActions.getSchedule,
  refreshPage: assessmentActions.refreshPage,
  
  viewAddQuestions: questionActions.viewAddQuestions,
  getAssessors: libraryActions.getAssessors,
  createAssessment: assessmentActions.createAssessment,
  deleteAssessment: assessmentActions.deleteAssessment,
  
  getAssessmentMetaData: assessmentActions.getAssessmentMetaData,
  refreshForSchedule: assessmentActions.refreshForSchedule,
  assignRoles: assessmentActions.assignRoles,
  
  scheduleAssessment: assessmentActions.scheduleAssessment,
  createStaffUserMap: staffUserActions.createStaffUserMap,
  clearAssessmentView: libraryActions.clearAssessmentView,
  
  editScheduleAssessment: assessmentActions.editScheduleAssessment,
  editDetails: assessmentActions.editDetails,
  editAssessors: assessmentActions.editAssessors,
  
  getStaffUsers: staffUserActions.getAllStaffUsers,
  getTeacherDetails: manageUserActions.getTeacherDetails,
  getAllTeachers: manageUserActions.getAllTeachers,
  getAllQuestions: questionActions.getAllQuestions,
  
  getQuestionContent: questionActions.getQuestionContent, 
  refreshforAddingQuestions: questionActions.refreshforAddingQuestions,
  getAssessmentQuestions: questionActions.getAssessmentQuestions,
  
  editCandidates: candidateActions.editCandidates,
  getCandidateReports: candidateActions.getCandidateReports,
  addCandidates: candidateActions.addCandidates,
};

export default connect(mapStateToProps, mapActionsToProps)(AssessmentLibrary);
