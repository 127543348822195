import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const StaffsRoleService = {
  searchStaffRoleByUserId
};

async function searchStaffRoleByUserId(id) {
  const requestOptions = {
    method: "GET",
    headers: APIHandler.getHeader(
      config.azure_ad_config.apis.OAT.name
    ),
  };
  const response = await fetch(
    `${config.oat_api_url}/user/staff/roles/${id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

