import config from "../../helper/config";
import APIHandler from "../../handlers/APIHandler";

export const staffUserService = {
    getAllStaffUsers: getAllStaffUsers,
    getStaffUserRoles: getStaffUserRoles
};

async function getAllStaffUsers() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };

    return await fetch(`${config.oat_api_url}/user/staffs/`, requestOptions).then(APIHandler.handleResponse);
}

async function getStaffUserRoles() {
    const requestOptions = {
        method: 'GET',
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name)
    };

    const response = await fetch(`${config.oat_api_url}/user/staff/roles/`, requestOptions);
    return APIHandler.handleResponse(response);
}