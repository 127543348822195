import React from "react";

export default function TableBody({ children }) {
    return (
            
        <tbody>

            {children}

        </tbody>

    )
}