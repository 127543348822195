import React, { useState } from 'react';
import './drillchart.css';

const DrillChart = ({ val }) => {
    const [level, setLevel] = useState(1);
    const [values, setValues] = useState([val[0]])

    const handlePlusClick = () => {
        setLevel(level + 1);
        let append = [...values]
        append.push(val[0 + level])
        setValues(append)
    };

    const handleMinusClick = () => {
        if (level > 1) {
            setLevel(level - 1);
            let append = [...values]
            append.pop()
            setValues(append)
        }
    };

    const renderContent = () => {

        return (
            <>
                {values.map((l, index) => (

                    <div className="content-drillchart" id={index}>
                        <div className={`level-${level}`}>
                            <div
                                className="row"
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <div
                                    className="left-column"
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <p>{l.breadcrumb}</p>
                                </div>
                                <div
                                    className="right-column"
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <p>{l.chart_y_value}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                ))}
            </>
        )
    };

    return (
        <div>
            <h3>{val[0]?.chart_title}</h3>
            <div className="container-drillchart">
                <div className="top-right-buttons">
                    {level !== val.length && <button onClick={handlePlusClick}>+</button>}
                    {level > 1 && <button onClick={handleMinusClick}>-</button>}
                </div>
                {renderContent()}
            </div>
        </div>
    );
};

export default DrillChart;

