import React, { Component } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import sweetAlert, {
  sweetConfirmAlert,
  sweetStatusAlert,
} from "../../../components/common/SweetAlert.js";
import { NodeFormMapService } from "../../../service/manage/h_tree/node_form_map.service.js";
import CheckBoxInline from "../../../components/common/form/CheckBoxInline.js";
import CheckBox from "../../../components/common/form/CheckBox.js";

class ManageLinkForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFormLinkModal: this.props.showModal || false,
      isLoading: false,
      formData: [],
      selectedForms: {},
      noDataMessage: "",
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleLinkButtonClick = this.handleLinkButtonClick.bind(this);
  }

  componentDidMount() {
    this.fetchAllLinkForms();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({ showFormLinkModal: this.props.showModal });
    }
  }

  fetchAllLinkForms = async () => {
    const { mid, lg_user_id, lg_user_table_id } = this.props.nodeState;

    this.setState({ isLoading: true });

    try {
      const result = await NodeFormMapService.getFormLinkAttachmentList(
        mid,
        lg_user_id,
        lg_user_table_id
      );

      if (result.success) {
        this.setState({ formData: result.data });
      } else if (result.status === 404) {
        this.setState({ noDataMessage: "No Form Links Available" });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching form links. Please refresh the screen!",
          "error"
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        this.setState({ noDataMessage: "No Form Links Available" });
      } else {
        sweetStatusAlert(
          "Failed!",
          "Error fetching form links. Please refresh the screen!",
          "error"
        );
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleShow() {
    this.setState({ showFormLinkModal: true });
  }

  handleClose() {
    this.props.hideModal();
  }

  handleCheckboxChange(index) {
    this.setState((prevState) => {
      const updatedSelectedForms = { ...prevState.selectedForms };
      updatedSelectedForms[index] = !updatedSelectedForms[index];
      return { selectedForms: updatedSelectedForms };
    });
  }

  handleLinkButtonClick = async (form, index) => {
    const { mid, lg_user_id, lg_user_table_id, form_list_id } =
      this.props.nodeState;

    sweetConfirmAlert().then(async () => {
      this.setState({ isLoading: true });

      const newLinkForm = {
        node_mid: mid,
        anchor_node_mid: form.node_mid_actual,
        form_list_id: form.form_list_id,
        user_id: lg_user_id,
        user_table_id: lg_user_table_id,
      };

      try {
        const result = await NodeFormMapService.addLinkFormAttachment(
          newLinkForm
        );

        if (result.success) {
          sweetStatusAlert("Success!", result.message.success, "success");
          this.fetchAllLinkForms();
          this.props.nodeProps.selectNode();
          this.handleClose();
        } else {
          sweetStatusAlert(
            "Failed!",
            "Error linking form. Please try again.",
            "error"
          );
        }
      } catch (error) {
        sweetStatusAlert(
          "Failed!",
          "Error linking form. Please try again.",
          "error"
        );
      } finally {
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const {
      showFormLinkModal,
      isLoading,
      formData,
      selectedForms,
      noDataMessage,
    } = this.state;

    return (
      <div>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showFormLinkModal}
          onHide={this.handleClose}
          animation={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Form Link</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: "#007bff" }}
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                {noDataMessage ? (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    {noDataMessage}
                  </div>
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="thead">Form Breadcrumb</TableCell>
                        <TableCell className="thead">Form Template</TableCell>
                        <TableCell className="thead">Select</TableCell>
                        <TableCell className="thead">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formData.map((form, index) => (
                        <TableRow key={index}>
                          <TableCell className="tData">
                            {form.breadcrumb}
                          </TableCell>
                          <TableCell className="tData">
                            {form.form_template_title}
                          </TableCell>
                          <TableCell className="tData">
                            <CheckBoxInline>
                              <CheckBox
                                checked={
                                  selectedForms[index] ||
                                  form.flg_linked === "Y"
                                }
                                onChange={() =>
                                  this.handleCheckboxChange(index)
                                }
                              />
                            </CheckBoxInline>
                          </TableCell>
                          <TableCell className="tData">
                            {form.flg_linked === "Y" ? (
                              <span className="stu-variant-class label label-lg label-success label-inline">
                                Linked
                              </span>
                            ) : (
                              <Button
                                variant="link"
                                onClick={() =>
                                  this.handleLinkButtonClick(form, index)
                                }
                                disabled={!selectedForms[index]}
                              >
                                Link
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="mb-4"
              id="modal-close-button"
              onClick={this.handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ManageLinkForm;
