import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Navigation from "../../edsider/components/Navigation";
import Hero from "../../edsider/components/Hero";
import Support from "../../edsider/components/Support";
import GetStarted from "../../edsider/components/GetStarted";
import About from "../../edsider/components/About";
import ChooseEdsider from "../../edsider/components/ChooseEdsider";
import Tabs from "../../edsider/components/Tabs";
import Scholarships from "../../edsider/components/Scholarships";
import Footer from "../../edsider/components/Footer";
import "../../edsider/assets/css/edsider-landing.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { routes } from "../../../helper/routes";

class StudentParentLandingPage extends Component {
  componentDidMount() {
    AOS.init({ duration: 1000 });
  }

  handleRoleSelect = (role) => {
    sessionStorage.setItem("user_role_selection", role);
    this.props.history.push(routes.auth_response);
  };

  render() {
    return (
      <>
        <div className="bg-landing-page">
          <Navigation />
          <Hero />
          <div className="bg-cyan-section">
            <Support />
          </div>
          <div className="bg-scholarship-section">
            <ChooseEdsider />
          </div>
          <div className="bg-cyan-section">
            <About />
          </div>
          <div className="">
            <Tabs />
          </div>
          {/* <div className="light-blue-container">
            <Testimonials />
          </div> */}
          <div className="bg-cyan-section">
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(StudentParentLandingPage);
