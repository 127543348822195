import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./CoverPageComponents/CoverPage.css";
import { AnnouncementService } from "../../../service/manage/announcement/announcement.service";
import { CircularProgress } from "@mui/material";
import AnnouncementCard from "./CoverPageComponents/AnnoucementCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal() {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  useEffect(() => {
    loadAnnouncementData();
  }, []);

  const loadAnnouncementData = async () => {
    try {
      const response = await AnnouncementService.getAllAnnouncementsCGC();
      if (response.success) {
        setAnnouncements(response.data.announcements);
      }
    } catch (error) {
      console.error("Error loading announcements:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="spinner-container">
          <CircularProgress />
        </div>
      ) : (
        announcements
          .slice(0, 2)
          .map((announcement) => (
            <div className="announcement ann1 row">
              <AnnouncementCard
                key={announcement.id}
                announcement={announcement}
              />
            </div>
          ))
      )}
    </div>
  );
}
