import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "./CoverPageComponents/CoverPage.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AnnouncementService } from "../../../service/manage/announcement/announcement.service";
import AnnouncementCard from "./CoverPageComponents/AnnoucementCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 300, // Set a fixed height for the modal
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // Make the content scrollable
};

export default function BasicModal() {
  const [open, setOpen] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    loadAnnouncementData();
  }, []);

  const loadAnnouncementData = async () => {
    try {
      const response = await AnnouncementService.getAllAnnouncementsCGC();
      if (response.success) {
        setAnnouncements(response.data.announcements);
      }
    } catch (error) {
      console.error("Error loading announcements:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="text04 justifyContentRight" onClick={handleOpen}>
        View all
        <KeyboardArrowRightIcon />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Announcements
          </Typography>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            announcements.map((announcement) => (
              <AnnouncementCard
                key={announcement.id}
                announcement={announcement}
              />
            ))
          )}
        </Box>
      </Modal>
    </div>
  );
}
