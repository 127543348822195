import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const ExpirationService = {
    addExpiration,
    getExpirations,
    updateExpiration
  };

async function addExpiration(expiration) {
    const requestOptions = {
      method: "POST",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      body: JSON.stringify(expiration),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/request-expirations/add`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function getExpirations() {
    const requestOptions = {
      method: "GET",
      headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/request-expirations/getAllExpirations`,
      requestOptions
    );
    return APIHandler.handleResponse(response);
}

async function updateExpiration(updateExpiration , id){
    const requestOptions = {
        method: "PUT",
        headers: APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        body: JSON.stringify(updateExpiration),
      };
      const response = await fetch(
        `${config.oat_api_url}/manage/request-expirations/update/${id}`,
        requestOptions
      );
      return APIHandler.handleResponse(response);
}

