import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../../components/common/Spinner.js"
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import AdminTemplate from '../../../components/admin/layouts/Template';
import Card from '../../../components/common/Card';
import CardHeader from '../../../components/common/CardHeader';
import CardBody from '../../../components/common/CardBody';
import CardTitle from '../../../components/common/AssessmentCardTitle';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import TabList from '../../../components/common/tabs/TabList';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { quesSourceActions } from "../../../actions/settings/question/sourceAction.js"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config.js";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class QuestionSource extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newSource:null,
        sourceRowsPerPage:5,
        currentSourcePage: 0,
        sourceData: [],
        source_sort_column: "source_id",
        source_sort_direction: "ASC",
        selectSource: '',
        editSource: false,
        source: '',
        limit: 10,
        source_search_term: "",
        source_offset: 0,
        searchError: false,
        source_limit:5,
        sourceId: '',
        sourceOptionsImport: [],
        selectedSource: []
      };
      
      this.onNewSourceInput = this.onNewSourceInput.bind(this);
      this.onAddNewSource = this.onAddNewSource.bind(this);
      this.generateSourceOptions = this.generateSourceOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllQuestionSources();
    }
    
    onNewSourceInput(e) {
        this.setState({
            newSource: e.target.value
        });
    }
    
    async onAddNewSource(e) {
        if (this.state.newSource && this.state.newSource.trim()) {
            await this.props.createSource({ 'source': this.state.newSource })
            if (this.props.successsourcecreate) {
                this.setState(
                    {
                        source_offset: 0,
                        currentSourcePage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllQuestionSources();
                        this.generateSourceOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new source!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-category').modal('hide')
                                window.$("#question-category").val('default');
                                window.$('#question-category').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errorsourceupdate) {
                window.Swal.fire({
                    title: 'Failed to add a new source!',
                    text: this.props.errorsourceupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty source!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newSource: ''
        });
    }
    
    handleSourceSearch = (event) => {
        const source_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (source_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (source_search_term === "" || regex.test(source_search_term)) {
            this.setState(
                {
                    source_search_term,
                    source_offset: 0,
                    currentSourcePage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllQuestionSources();
                }
            );
        } else {
            this.setState({ source_search_term, searchError: true });
        }
    };
    
    fetchAllQuestionSources = async () => {
        const {
            source_limit,
            source_offset,
            source_sort_column,
            source_sort_direction,
            source_search_term
        } = this.state;
        await this.props.getAllQuestionSources(source_limit, source_offset, source_sort_column, source_sort_direction, source_search_term);
        this.setState({ source_limit: this.state.sourceRowsPerPage });
        this.setState({ sourceData: this.props.allQuestionSources })
    };
    
    handleSourcePageChange = (event, newSourcePage) => {
        const {sourceRowsPerPage} = this.state;
        const newSourceOffset = newSourcePage * sourceRowsPerPage;
        this.setState(
        {
            source_limit: sourceRowsPerPage,
            source_offset: newSourceOffset,
            currentSourcePage: newSourcePage
        },
        () => {
            this.fetchAllQuestionSources();
        }
        );
    };
     
    handlePageOffsetChange = (newOffset, newPage) => {
        const {sourceRowsPerPage} = this.state;
        this.setState(
        {
            source_limit: sourceRowsPerPage,
            source_offset: newOffset,
            currentSourcePage: newPage
        },
        () => {
            this.fetchAllQuestionSources();
        }
        );
    };
    
    //handle the data fetching as per the data per page limit gets changes
    handleSourceRowsPerPageChange = (event) => {
        const newSourceLimit = parseInt(event.target.value, 10);
        this.setState({sourceRowsPerPage: newSourceLimit});
        const newSourcePage = Math.floor(this.state.source_offset / newSourceLimit)
        this.setState(
        {
            source_limit: newSourceLimit,
            source_offset: newSourceLimit * newSourcePage,
            currentSourcePage: newSourcePage
        },
        () => {
            this.fetchAllQuestionSources();
        }
        );
    };

    handleSortSourceChange = (source_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { source_sort_column: currentSourceSortColumn, source_sort_direction } = this.state;
        const newSourceSortDirection =
        currentSourceSortColumn === source_sort_column && source_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            source_sort_column,
            source_sort_direction: newSourceSortDirection,
            source_offset: 0,
            currentSourcePage: 0,
        },
        () => {
            this.fetchAllQuestionSources();
        }
        );
    }

    getQuestionSource(source_id) {
        let sourcename
        this.props.allQuestionSources.map(source => {
            if (source.source_id === source_id) {
                return sourcename = source.source
            }
        })
        return sourcename
    }
    
    onEditSource(e, el) {
        this.setState({
            sourceId: el.source_id,
            source: e.target.value
        })
    }
    
    async onUpdateSource(e) {
        e.preventDefault()
        var obj = {
            source_id: this.state.sourceId,
            source: this.state.source
        }
        this.setState({ selectSource: '' })
        if (!this.state.source.trim()) {
            window.Swal.fire({
                title: 'Failed to update source!',
                text: 'Source cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateSource(obj);
            if (this.props.successsourceupdate) {
                // await this.props.getAllQuestionSources(source_limit, source_offset, source_sort_column, source_sort_direction, source_search_term);
                this.fetchAllQuestionSources()
                this.generateSourceOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated source!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });
            }
            if (this.props.errorsourceupdate) {
                window.Swal.fire({
                    title: 'Failed to update source!',
                    text: this.props.errorsourceupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        }
    }

    generateSourceOptions() {
        let sourceOptionsImport = []
        this.props.allQuestionSources.map((el, i) => {
            sourceOptionsImport.push(<Option key={el.source_id} optionName={el.source} value={el.source_id} />)
        });

        this.setState({
            sourceOptionsImport: sourceOptionsImport,
            selectedSource: sourceOptionsImport[0].props.value
        });

        if (sourceOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditSource(el, i, e) {
        e.preventDefault()
        this.setState({
            editSource: true,
            selectSource: i,
            sourceId: el.source_id,
            source: el.source
        })
    }

    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the source?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteSource(el);
                if (this.props.successsourcedelete) {
                    // await this.props.getAllQuestionSources(source_limit, source_offset, source_sort_column, source_sort_direction, source_search_term);
                    this.fetchAllQuestionSources()
                    this.generateSourceOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted source!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });
                }
                if (this.props.errorsourcedelete) {
                    window.Swal.fire({
                        title: 'Failed to delete source!',
                        text: this.props.errorsourcedelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-source').modal('hide')
                            window.$("#question-source").val('default');
                            window.$('#question-source').selectpicker('refresh');
                        });

                }
            }
        })
    }
    
    syncTableSource() {
        return (
            <>
                {this.state.sourceData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortSourceChange("source_id")}
                                >
                                    No{" "}
                                    {this.state.source_sort_column === "source_id" && (
                                    <span>{this.state.source_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortSourceChange("source")}
                                >
                                    {this.state.source_sort_column === "source" && (
                                    <span>{this.state.source_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                    {" "}Source Name
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.sourceData.map((el, i) => {
                                if (i === this.state.selectSource) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editSource ? 
                                                this.getQuestionSource(el.source_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.source}
                                                    onChange={(e) => this.onEditSource(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateSource(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Source" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getQuestionSource(el.source_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditSource(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Source" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete" 
                                                    title="Delete Source" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Sources Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3>Manage Question Sources</h3>
              </div>

              <CardBody>
                <div className="row justify-content-end">
                  <div
                    className="table-wrapper-scroll-y my-custom-scrollbar"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <TabList>
                      <QuestionAccordion
                        labelName="Create New Source"
                        onChange={this.onNewSourceInput}
                        onClick={this.onAddNewSource}
                        val={this.state.newSource}
                      />
                    </TabList>
                    <div className="row justify-content-end col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleSourceSearch}
                          className="col-md-18 col-18"
                        />
                      </Form.Group>
                    </div>
                    <TabContent>
                      <TabPane
                        className="tab-pane fade show active"
                        id="manage-1"
                        ariaLabelledby="manage-tab-1"
                      >
                        {/* begin: Datatable */}
                        {this.props.cat_loading ? (
                          <div style={{ width: "75vw", overflowX: "scroll" }}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Source Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                            <div className="loading-container mb-5 mt-10">
                              <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </ReactSpinner>
                            </div>
                          </div>
                        ) : this.state.sourceData.length > 0 ? (
                          this.syncTableSource()
                        ) : (
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Source Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {/* end: Datatable */}
                            </Table>
                            <div className="text-center mb-5 mt-10">
                              <label>No Sources Available!</label>
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                            totalCount = {this.props.totalSourcesCount}
                            currentPage = {this.state.currentSourcePage}
                            limit = {this.state.sourceRowsPerPage}
                            handlePageChange = {this.handleSourcePageChange}
                            handleRowsPerPageChange = {this.handleSourceRowsPerPageChange}
                            handlePageOffsetChange = {this.handlePageOffsetChange}
                            offset = {this.state.source_offset}
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalSourcesCount: state.sourceReducer.totalSourcesCount,//
        cat_loading:state.sourceReducer.loading,
        error: state.sourceReducer.error,//
        // totalCount: state.questionsLibraryReducer.totalCount,//
        
        errorsourcedelete: state.sourceReducer.errorsourcedelete,
        successsourcedelete: state.sourceReducer.successsourcedelete,

        errorsourceupdate: state.sourceReducer.errorsourceupdate,
        successsourceupdate: state.sourceReducer.successsourceupdate,//
        
        errorsourcecreate: state.sourceReducer.errorsourcecreate,
        successsourcecreate: state.sourceReducer.successsourcecreate,//

        allQuestionSources: state.sourceReducer.allQuestionSources,//
        loading: state.sourceReducer.loading,//
    }
}

    const mapActionsToProps = {
        createSource: quesSourceActions.createSource,
        getAllQuestionSources: quesSourceActions.getAllQuestionSources,
        updateSource: quesSourceActions.updateSource,
        deleteSource: quesSourceActions.deleteSource,
    }

    export default connect(mapStateToProps, mapActionsToProps)(QuestionSource);

