import React from "react";
import { Redirect } from "react-router";
import { UMAuthAgent } from "../../authentication/UMAuthAgent";
import { connect } from "react-redux";
import { Terms as TermsAndConditionsText } from "../../helper/terms";
import { TermsAgreementConstants } from "../../helper/constants";
import { Button } from "@material-ui/core";
import Spinner from "../Spinner";
import { store } from "../../helper/redux_store";
import "./TermsStyles.css";
import { UserManagementService } from "../../service/user_management/user_management.service";

class AcceptTerms extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      termsAndConditions: "underReview",
      loading: false,
    };
  }

  async closeTerms(e) {
    e.preventDefault();
    this.setState({
      termsAndConditions: "unaccepted",
    });
    store.dispatch({
      type: TermsAgreementConstants.AGREEMENT_FAILURE,
    });
  }

  async AcceptTerms(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    try {
      const response = await UMAuthAgent.AgreeToTerms(
        this.props.location.state.userType
      );
      if (response) {
        store.dispatch({
          type: TermsAgreementConstants.AGREEMENT_SUCCESS,
        });
        this.setState({
          termsAndConditions: "accepted",
        });
      }
    } catch (e) {
      //Send back to homepage
      this.setState({
        termsAndConditions: "unaccepted",
        loading: false,
      });
      store.dispatch({
        type: TermsAgreementConstants.AGREEMENT_FAILURE,
      });
    }
  }

  render() {
    if (
      this.props.agreementStatus === "accepted" &&
      this.props.location.state.userType === "student"
    ) {
      return <Redirect to="/manage-dashboard" />;
    }
    if (
      this.props.agreementStatus === "accepted" &&
      this.props.location.state.userType === "admin"
    ) {
      return <Redirect to="/manage-dashboard" />;
    }
    if (
      this.props.agreementStatus === "accepted" &&
      this.props.location.state.userType === "teacher"
    ) {
      return <Redirect to="/manage-dashboard" />;
    }
    if (this.props.agreementStatus === "unaccepted") {
      return UserManagementService.logout();      
    }

    return (
      <>
        <div>
          {this.state.loading && (
            <Spinner active={this.state.loading} text="Logging In..." />
          )}
          {!this.state.loading && (
            <div className="container-terms">
              <h2>Terms & Conditions</h2>
              <hr />
              &nbsp; &nbsp;
              <TermsAndConditionsText />
              <div className="buttons">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={(e) => this.AcceptTerms(e)}
                >
                  {" "}
                  Accept{" "}
                </Button>
                &nbsp; &nbsp;
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={(e) => this.closeTerms(e)}
                >
                  {" "}
                  Close{" "}
                </Button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    agreementStatus: state.userAgreementReducer.status,
  };
};

const actionCreators = {};

const connectedAcceptTerms = connect(
  mapStateToProps,
  actionCreators
)(AcceptTerms);
export { connectedAcceptTerms as AcceptTerms };
