import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const DeadlineService = {
  addDeadline,
  updateDeadline,
  getDeadlineByContainerId,
};

async function addDeadline(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/deadline/add`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function updateDeadline(data, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/deadline/update/${id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

async function getDeadlineByContainerId(container_id) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
      "Time-Zone":Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  };
  const response = await fetch(
    `${config.oat_api_url}/manage/deadline/get-by-container-id/${container_id}`,
    requestOptions
  );
  return APIHandler.handleResponse(response);
}

