import { assessmentTypes, userTypes, userRoles } from '../../reducers/types';
import { assessmentService } from "../../service/assessment/assessmentService";
import { staffUserService } from '../../service/user_management/staff.user.service';
import _ from 'underscore';
import {store} from '../../helper/redux_store';

export const assessmentActions = {
    createAssessment,
    finalizeAssessment,
    scheduleAssessment,
    editScheduleAssessment,
    editAssessors,
    deleteAssessment,
    editDetails,
    refreshPage,
    refreshFinalizePage,
    refreshForSchedule,
    refreshForAssigning,
    getAssessmentLanguage,
    getAssessmentDifficulty,
    getAssessmentCategory,
    getAssessmentAttempt,
    getAssessmentAuthor,
    getAssessmentChapter,
    getAssessmentGrade,
    getAssessmentYear,
    getAssessmentTerm,
    getAssessmentSubject,
    getAssessmentPrivilege,
    getAssessmentMetaData
};

function finalizeAssessment(assessment) {
    return async dispatch => {
        dispatch(finalize_assessment());

        var data = new Object;
        Object.assign(data, assessment);

        let content = {
            'assessment_id': data.assessment_id,
            'user_role_id': data.user_role_id,
            'assessment_state': '',
            'status_id': ''
        }

        if (data.assessment_state == 'Ready') {
            content['assessment_state'] = 'Initial'
            data['assessment_state'] = 'Initial'
            content['status_id'] = 1
        } else {
            content['assessment_state'] = 'Ready'
            data['assessment_state'] = 'Ready'
            content['status_id'] = 2
        }

        dispatch(request_finalize());

        try {
            var message = await assessmentService.finalizeAssessment(content)
            if(message.success){
                dispatch(success_finalize({ data, message}))
            }
            else{
                dispatch(failure_finalize(message.message.error))
            }
        } catch (error) {
            dispatch(failure_finalize(error))
        }
    }

    function finalize_assessment() {
        return { type: assessmentTypes.FINALIZE_ASSESSMENT }
    }

    function request_finalize() {
        return { type: assessmentTypes.FINALIZE_ASSESSMENT_REQUEST }
    }

    function success_finalize(assessment) {
        return { type: assessmentTypes.FINALIZE_ASSESSMENT_SUCCESS, assessment }
    }

    function failure_finalize(error) {
        return { type: assessmentTypes.FINALIZE_ASSESSMENT_FAILURE, error }
    }
}

function getAssessmentLanguage() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let language = await assessmentService.getAssessmentLanguage();
            dispatch(success(language.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_LANGUAGE }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_LANGUAGE_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_LANGUAGE_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_LANGUAGE_FAILURE, error }
    }
}

function getAssessmentPrivilege() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let privilege = await assessmentService.getAssessmentPrivilege();
            dispatch(success(privilege.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_PRIVILEGE }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_PRIVILEGE_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_PRIVILEGE_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_PRIVILEGE_FAILURE, error }
    }
}

function getAssessmentAttempt() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let attempt = await assessmentService.getAssessmentAttempt();
            dispatch(success(attempt.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_ATTEMPT }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_ATTEMPT_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_ATTEMPT_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_ATTEMPT_FAILURE, error }
    }
}

function getAssessmentGrade() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let grade = await assessmentService.getAssessmentGrade();
            dispatch(success(grade.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_GRADE }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_GRADE_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_GRADE_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_GRADE_FAILURE, error }
    }
}

function getAssessmentAuthor() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let author = await assessmentService.getAssessmentAuthor();
            dispatch(success(author.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_AUTHOR }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_AUTHOR_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_AUTHOR_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_AUTHOR_FAILURE, error }
    }
}

function getAssessmentYear() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let year = await assessmentService.getAssessmentYear();
            dispatch(success(year.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_YEAR }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_YEAR_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_YEAR_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_YEAR_FAILURE, error }
    }
}

function getAssessmentTerm() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let term = await assessmentService.getAssessmentTerm();
            dispatch(success(term.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_TERM }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_TERM_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_TERM_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_TERM_FAILURE, error }
    }
}

function getAssessmentChapter() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let chapter = await assessmentService.getAssessmentChapter();
            dispatch(success(chapter.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_CHAPTER }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_CHAPTER_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_CHAPTER_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_CHAPTER_FAILURE, error }
    }
}

function getAssessmentSubject() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let subject = await assessmentService.getAssessmentSubject();
            dispatch(success(subject.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_SUBJECT }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_SUBJECT_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_SUBJECT_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_SUBJECT_FAILURE, error }
    }
}

function getAssessmentDifficulty() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let difficulty = await assessmentService.getAssessmentDifficulty();
            dispatch(success(difficulty.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_DIFFICULTY }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_DIFFICULTY_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_DIFFICULTY_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_DIFFICULTY_FAILURE, error }
    }
}

function getAssessmentCategory() {
    return async dispatch => {
        try {
            dispatch(clearExisting());
            dispatch(request());
            let category = await assessmentService.getAssessmentCategories();
            dispatch(success(category.data));
        } catch (error) {
            dispatch(failure(error));
        }
    };

    function clearExisting() {
        return { type: assessmentTypes.GET_ASSESSMENT_CATEGORY }
    }

    function request() {
        return { type: assessmentTypes.GET_ASSESSMENT_CATEGORY_REQUEST }
    }

    function success(types) {
        return { type: assessmentTypes.GET_ASSESSMENT_CATEGORY_SUCCESS, types }
    }

    function failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_ASSESSORS_FAILURE, error }
    }
}

function scheduleAssessment(data) {
    return dispatch => {
        dispatch(start_schedule());

        let content = {
            'assessment_id': data.assessment_id,
            'start_time': data.start_time,
            'exam_time_interval': data.test_duration,
            'buffer_time': data.access_buffer,
            'scheduled_by': data.userID,
            'exam_time_interval_unit_id': data.timeUnit,
            'buffer_time_unit_id': data.bufferTimeUnit,
            'is_set_time_limit': data.setTimeLimit
        }

        dispatch(request_schedule(content));
        assessmentService.scheduleAssessment(content).then(
            assessment => {
                dispatch(success_schedule(assessment.data))
            },
            error => {
                dispatch(failure_schedule(error))
            }
        )

    }

    function start_schedule() {
        return { type: assessmentTypes.SCHEDULE_ASSESSMENT }
    }

    function request_schedule(content) {
        return { type: assessmentTypes.SCHEDULE_ASSESSMENT_REQUEST, content }
    }

    function success_schedule(assessment) {
        return { type: assessmentTypes.SCHEDULE_ASSESSMENT_SUCCESS, assessment }
    }

    function failure_schedule(error) {
        return { type: assessmentTypes.SCHEDULE_ASSESSMENT_FAILURE, error }
    }
}

function editScheduleAssessment(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let data = await assessmentService.editScheduleAssessment(obj);
            dispatch(success(data.data));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return { type: assessmentTypes.EDIT_SCHEDULE_REQUEST }

    }

    function success(message) {
        return { type: assessmentTypes.EDIT_SCHEDULE_SUCCESS, message }

    }

    function failure(error) {
        return { type: assessmentTypes.EDIT_SCHEDULE_FAILURE, error }
    }
}

function createAssessment(data) {
    return async dispatch => {
        try {
            let staffusers = await staffUserService.getAllStaffUsers();
            dispatch(get_staffusers_success(staffusers));

            dispatch(start());

            var arr = [], arr_roles = []
            var staff_user_roles = store.getState()['staffUserReducer']['staffUserRoleMap'];

            let content = {
                'title': data.assessment.title,
                'assessment_admin': data.assessment.assessment_admin,
                'proctor_requirement': data.assessment.proctor_requirement,
                'created_by': data.assessment.userID,
                'language_id': data.assessment.language_id,
                'category_id': data.assessment.selectedCategory,
                'terms_content': data.assessment.termsAndConditions,
                'difficulty_id': data.assessment.selectedDifficulty,
                "attempt_no_id": data.assessment.selectedAttempt,
                "grade_id": data.assessment.selectedGrade,
                "author_id": data.assessment.selectedAuthor,
                "chapter_id": data.assessment.selectedChapter,
                "subject_id": data.assessment.selectedSubject,
                "term_id": data.assessment.selectedTerm,
                "year_id": data.assessment.selectedYear,
                "privilege_id": data.assessment.selectedPrivilege,
                "status_id": parseInt(data.assessment.selectedStatus),
                'assessment_state': data.assessment.selectedStatusName
            }

            let schedule = {
                'start_time': data.schedule_data.start_time,
                'exam_time_interval': data.schedule_data.test_duration,
                'buffer_time': data.schedule_data.access_buffer,
                'scheduled_by': data.schedule_data.userID,
                'exam_time_interval_unit_id': data.schedule_data.timeUnit,
                'buffer_time_unit_id': data.schedule_data.bufferTimeUnit,
                'is_set_time_limit': data.schedule_data.setTimeLimit
            }
            var assessment_data = {
                'assessment': content,
                'user_role': '',
                'assessors': '',
                'user_roles': '',
                'schedule': schedule
            }

            if (!staff_user_roles['ASAD' + data.assessment.assessment_admin]) {
                assessment_data['user_role'] = { 'user_id': data.assessment.assessment_admin, 'role_code': 'ASAD', 'assigned_by': data.assessment.userID }
            }

            let cass = {
                'user_id': data.assessor_data.chief_assessor,
                'chief_assessor': true,
                'assigned_by': data.assessor_data.userID
            }
            arr.push(cass)
            if (!staff_user_roles['CASS' + data.assessor_data.chief_assessor]) {
                arr_roles.push({ 'user_id': data.assessor_data.chief_assessor, 'role_code': 'CASS', 'assigned_by': data.assessor_data.userID })
            }

            var assessor;

            for (assessor of data.assessor_data.assessors) {
                if (assessor != data.assessor_data.chief_assessor) {
                    content = {
                        'user_id': assessor,
                        'chief_assessor': false,
                        'assigned_by': data.assessor_data.userID
                    }

                    arr.push(content)
                    if (!staff_user_roles['ASSR' + assessor]) {
                        arr_roles.push({ 'user_id': assessor, 'role_code': 'ASSR', 'assigned_by': data.assessor_data.userID })
                    }
                }

            }

            assessment_data['assessors'] = arr;
            assessment_data['user_roles'] = arr_roles;

            dispatch(request(data));

            var assessment = await assessmentService.createAssessment(assessment_data);
            dispatch(success(assessment.data));
        } catch (error) {
            dispatch(failure(error));
        }


    };

    function get_staffusers_success(data) {
        return { type: userTypes.GET_ALL_STAFFUSERS_SUCCESS, staffusers: data }
    }
    function start() {
        return { type: assessmentTypes.CREATE_ASSESSMENT }
    }

    function request(content) {
        return { type: assessmentTypes.CREATE_ASSESSMENT_REQUEST, content }
    }

    function success(assessment) {
        return { type: assessmentTypes.CREATE_ASSESSMENT_SUCCESS, assessment }
    }

    function failure(error) {
        return { type: assessmentTypes.CREATE_ASSESSMENT_FAILURE, error }
    }

}

function editAssessors(data) {

    return dispatch => {
        dispatch(start_edit_assign_assessors());
        let arr = [], arrRoles = [], arrDelete = [], arrRolesDelete = []
        let staffUserRoles = store.getState()['staffUserReducer']['staffUserRoleMap'];

        let content = {
            'assessment_id': data.editAssessmentId,
            'user_id': data.editChiefAssessorId,
            'chief_assessor': true,
            'assigned_by': data.userID
        }
        arr.push(content)
        if (!staffUserRoles[userRoles.CASS + data.editAssessmentId]) {
            arrRoles.push({
                'user_id': data.editChiefAssessorId,
                'assessment_id': data.editAssessmentId,
                'role_code': userRoles.CASS,
                'assigned_by': data.userID
            })
        }
        let assessor;

        for (assessor of data.editSelectedAssessors) {

            if (assessor != data.editChiefAssessorId) {
                content = {
                    'assessment_id': data.editAssessmentId,
                    'user_id': assessor,
                    'chief_assessor': false,
                    'assigned_by': data.userID
                }

                arr.push(content)
                if (!staffUserRoles[userRoles.ASSR + assessor]) {
                    arrRoles.push({
                        'user_id': assessor,
                        'assessment_id': data.editAssessmentIdd,
                        'role_code': userRoles.ASSR,
                        'assigned_by': data.userID
                    })
                }
            }
        }

        let contentToDelete = {
            'assessment_id': data.editAssessmentId,
            'user_id': data.chiefAssessorToDelete,
            'chief_assessor': true,
            'assigned_by': data.userID
        }
        arrDelete.push(contentToDelete)
        if (!staffUserRoles[userRoles.CASS + data.chiefAssessorToDelete]) {
            arrRolesDelete.push({
                'user_id': data.chiefAssessorToDelete,
                'assessment_id': data.editAssessmentId,
                'role_code': userRoles.CASS,
                'assigned_by': data.userID
            })
        }
        let assessorToDelete;

        for (assessorToDelete of data.assessorsToDelete) {

            contentToDelete = {
                'assessment_id': data.editAssessmentId,
                'user_id': assessorToDelete,
                'chief_assessor': false,
                'assigned_by': data.userID
            }

            arrDelete.push(contentToDelete)
            if (!staffUserRoles[userRoles.ASSR + assessorToDelete]) {
                arrRolesDelete.push({
                    'user_id': assessorToDelete,
                    'assessment_id': data.editAssessmentId,
                    'role_code': userRoles.ASSR,
                    'assigned_by': data.userID
                })
            }
        }


        let addData = {
            'assessors': arr,
            'user_roles': arrRoles
        }
        let deleteData = {
            'assessors': arrDelete,
            'user_roles': arrRolesDelete
        }

        let assessor_data = {
            'addData': addData,
            'deleteData': deleteData
        }

        dispatch(request_edit_assign_assessors(data));

        assessmentService.editAssessors(assessor_data).then(
            success => {
                dispatch(success_edit_assign_assessors(success.data))
            },
            error => {
                dispatch(failure_edit_assign_assessors(error))
            }
        )
    }

    function start_edit_assign_assessors() {
        return { type: assessmentTypes.EDIT_ASSESSORS_ASSESSMENT }
    }

    function request_edit_assign_assessors(content) {
        return { type: assessmentTypes.EDIT_ASSESSORS_ASSESSMENT_REQUEST, content }
    }

    function success_edit_assign_assessors(message) {
        return { type: assessmentTypes.EDIT_ASSESSORS_ASSESSMENT_SUCCESS, message }
    }

    function failure_edit_assign_assessors(error) {
        return { type: assessmentTypes.EDIT_ASSESSORS_ASSESSMENT_FAILURE, error }
    }
}

function deleteAssessment(obj) {

    return async dispatch => {
        try {
            dispatch(delete_assessment());
            dispatch(delete_assessment_request());
            var message = await assessmentService.deleteAssessment(obj);
            dispatch(delete_assessment_success(message.data))
        } catch (error) {
            dispatch(delete_assessment_failure(error))
        }
    }

    function delete_assessment() {
        return { type: assessmentTypes.DELETE_ASSESSMENT }
    }

    function delete_assessment_request(obj) {
        return { type: assessmentTypes.DELETE_ASSESSMENT_REQUEST, obj }
    }

    function delete_assessment_success(message) {
        return { type: assessmentTypes.DELETE_ASSESSMENT_SUCCESS, message }
    }

    function delete_assessment_failure(error) {
        return { type: assessmentTypes.DELETE_ASSESSMENT_FAILURE, error }
    }
}

function editDetails(obj) {
    return async dispatch => {

        dispatch(request());

        try {
            let data = await assessmentService.editDetails(obj);
            dispatch(success(data.data));
        } catch (error) {
            dispatch(failure(error.toString()));
        }
    };

    function request() {
        return { type: assessmentTypes.EDIT_DETAILS_REQUEST }
    }

    function success(message) {
        return { type: assessmentTypes.EDIT_DETAILS_SUCCESS, message }
    }

    function failure(error) {
        return { type: assessmentTypes.EDIT_DETAILS_FAILURE, error }
    }
}

function refreshPage() {
    return dispatch => {
        dispatch(refreshPage());
    }

    function refreshPage() {
        return { type: assessmentTypes.CREATE_ASSESSMENT_REFRESH }
    }
}

function refreshFinalizePage() {
    return dispatch => {
        dispatch(refreshForFinalizing());
    }

    function refreshForFinalizing() {
        return { type: assessmentTypes.FINALIZE_ASSESSMENT_REFRESH }
    }
}

function refreshForSchedule(assessment) {
    return dispatch => {
        dispatch(refreshForSchedule(assessment));
    }

    function refreshForSchedule(assessment) {
        return { type: assessmentTypes.SCHEDULE_ASSESSMENT_REFRESH, assessment }
    }
}

function refreshForAssigning(assessment) {
    return dispatch => {
        dispatch(refreshForAssigning(assessment));
    }

    function refreshForAssigning(assessment) {
        return { type: assessmentTypes.ASSIGN_ASSESSORS_ASSESSMENT_REFRESH, assessment }
    }
}

function getAssessmentMetaData() {
    return async dispatch => {
        try {
            dispatch(get_assessment_meta_data());
            dispatch(get_assessment_meta_data_request());
            var metaData = await assessmentService.getAllAssessmentMetaData();
            dispatch(get_assessment_meta_data_success(metaData.data))
        } catch (error) {
            dispatch(get_assessment_meta_data_failure(error))
        }
    }

    function get_assessment_meta_data() {
        return { type: assessmentTypes.GET_ASSESSMENT_META_DATA }
    }

    function get_assessment_meta_data_request() {
        return { type: assessmentTypes.GET_ASSESSMENT_META_DATA_REQUEST}
    }

    function get_assessment_meta_data_success(data) {
        return { type: assessmentTypes.GET_ASSESSMENT_META_DATA_SUCCESS, data }
    }

    function get_assessment_meta_data_failure(error) {
        return { type: assessmentTypes.GET_ASSESSMENT_META_DATA_FAILURE, error }
    }
}