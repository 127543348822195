import React, { useState, useRef } from "react";
import QRCode from "qrcode"
import { Button } from "@mui/material";
import { toPng } from 'html-to-image';
import { useLocation } from "react-router-dom"

const QRgenerator = ({ data }) => {
  const [qrDataURL, setQRDataURL] = useState(null);
  const elementRef = useRef(null);
  const location = useLocation();
  const isManageDashboard = location.pathname === '/manage-dashboard'
  const qrData = `stu_id:${data?.stu_id},table_id:${data?.user_table_id},user_code:${data?.user_code},name:${data?.first_name + " " + data?.last_name}`;
  QRCode.toDataURL(qrData).then(setQRDataURL)

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${data?.first_name}-QR.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        return err
      });
  };

  return (
    <>
      {qrDataURL !== null && (
        <>
          <div className="d-flex justify-content-center row" style={{ backgroundColor: "#fff", padding: isManageDashboard ? "0px" : "10px" }} ref={elementRef}>
            <img
              src={qrDataURL}
              style={{
                width: isManageDashboard ? "100px" : "auto",
                height: isManageDashboard ? "100px" : "auto",
              }}
            />
            {!isManageDashboard && (
              <p className="stu-variant-class label label-lg label-light-primary label-inline col-md-12">
                {" "}
                Name: {data?.first_name + " " + data?.last_name}
              </p>
            )}
          </div>
          {!isManageDashboard && (
            <div className="col-md-12 d-flex justify-content-center">
              <Button
                onClick={htmlToImageConvert}
                color="primary"
                variant="contained"
                style={{
                  color: "#fff",
                  backgroundColor: "var(--maincolor)",
                  borderColor: "transparent",
                  textTransform: "unset",
                  fontFamily: "Poppins, sans-serif"
                }}
              >
                Download
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default QRgenerator;
