import React from "react";
import "./layouts.css";
import config from '../../../helper/config';
import { customerConstants } from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

export default function Footer(props) {
  const customer = getCustomerFeatureFlag();
  let footerText;
  let footerText2;
  switch (customer) {
    case customerConstants.EXO_CUSTOMER1:
      footerText = "© 2024 Edsider. All Right Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
      footerText2 = "Privacy Policy and Terms & Conditions"
      break;
    case customerConstants.EXO_CUSTOMER2:
      footerText = "© 2024 All Rights Reserved. Powered by EdSider Supported by Infinity Innovators (Pvt) Ltd"
      break;
    default:
      footerText = "© 2024 All Rights Reserved. Designed and Developed by Infinity Innovators (Pvt) Ltd"
      break;
  }
  return (
    <div className="footer bg-white py-4 " id="footer">

      <div className="container-fluid" style={{ display: "flex", justifyContent: 'space-between' }}>
        <span className="text-dark-75">
          {footerText}
        </span>
        <span className="text-dark-75">
          <a
            href="/conditions"
            target="_blank"
            className="text-dark-75 text-hover-primary"
          >
            {footerText2}
          </a>
        </span>
      </div>
    </div>
  );
}
