import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../../components/common/Spinner.js"
import ContentWrapper from '../../../components/common/AssessmentContentWrapper';
import AdminTemplate from '../../../components/admin/layouts/Template';
import Card from '../../../components/common/Card';
import CardHeader from '../../../components/common/CardHeader';
import CardBody from '../../../components/common/CardBody';
import CardTitle from '../../../components/common/AssessmentCardTitle';
import Input from '../../../components/common/form/Input';
import Button from '../../../components/common/form/Button';
import QuestionAccordion from '../../../components/common/form/QuestionAccordion';
import TabList from '../../../components/common/tabs/TabList';
import Form from "react-bootstrap/Form";
import TabContent from '../../../components/common/tabs/TabContent';
import TabPane from '../../../components/common/tabs/TabPane';
import ReactSpinner from "react-bootstrap/Spinner";
import Option from '../../../components/common/form/Option';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import MaterialCustomTablePagination from "../../../components/common/MaterialCustomTablePagination.js";
import { quesLanguageActions } from "../../../actions/settings/question/languageAction.js"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import config from "../../../helper/config.js";
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let addBtnColor, closeBtnColor, cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    closeBtnColor = "var(--maincolor-cmc)"
    addBtnColor = "var(--maincolor-cmc)"
    break
  default:
    closeBtnColor = "var(--maincolor)"
    addBtnColor = "var(--maincolor)"
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

class QuestionLanguage extends Component {
    constructor(props) {
      super(props);
      this.state = {
        newLanguage:null,
        languageRowsPerPage:5,
        currentLanguagePage: 0,
        languageData: [],
        language_sort_column: "language_id",
        language_sort_direction: "ASC",
        selectLanguage: '',
        editLanguage: false,
        language: '',
        limit: 10,
        language_search_term: "",
        language_offset: 0,
        searchError: false,
        language_limit:5,
        languageId: '',
        languageOptionsImport: [],
        selectedLanguage: []
      };
      
      this.onNewLanguageInput = this.onNewLanguageInput.bind(this);
      this.onAddNewLanguage = this.onAddNewLanguage.bind(this);
      this.generateLanguageOptions = this.generateLanguageOptions.bind(this);
    }

    componentDidMount(){        
        this.fetchAllQuestionLanguages();
    }
    
    onNewLanguageInput(e) {
        this.setState({
            newLanguage: e.target.value
        });
    }
    
    async onAddNewLanguage(e) {
        if (this.state.newLanguage && this.state.newLanguage.trim()) {
            await this.props.createLanguage({ 'language': this.state.newLanguage })
            if (this.props.successlanguagecreate) {
                this.setState(
                    {
                        language_offset: 0,
                        currentLanguagePage: 0,
                        searchError: false,
                    },
                    () => {
                        this.fetchAllQuestionLanguages();
                        this.generateLanguageOptions();
                        window.Swal.fire({
                            title: 'Success!',
                            text: 'Added new medium/language!',
                            icon: 'success',
                            customClass: {
                                confirmButton: `btn font-weight-bold ${confirmClass}`,
                            },
                            onOpen: (modalElement) => {
                                if (customer === customerConstants.EXO_CUSTOMER2) {
                                    modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                                }
                            }
                        })
                            .then(function () {
                                window.$('#manage-category').modal('hide')
                                window.$("#question-category").val('default');
                                window.$('#question-category').selectpicker('refresh');
                            });
                    }
                );
            }
            if (this.props.errorlanguageupdate) {
                window.Swal.fire({
                    title: 'Failed to add a new medium/language!',
                    text: this.props.errorlanguageupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        } else {
            window.Swal.fire({
                title: 'Warning!',
                text: 'Empty medium/language!',
                icon: 'warning',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        this.setState({
            newLanguage: ''
        });
    }

    handleLanguageSearch = (event) => {
        const language_search_term = event.target.value.trim();
        const regex = /^[a-zA-Z0-9][a-zA-Z0-9@\s.&]*$/;
        // if (language_search_term !== "")
        // {
        //     this.setState({limit: this.props.totalCount});
        // }
        if (language_search_term === "" || regex.test(language_search_term)) {
            this.setState(
                {
                    language_search_term,
                    language_offset: 0,
                    currentLanguagePage: 0,
                    searchError: false,
                },
                () => {
                // Call the debounced version of fetchStudentData
                // this.debouncedHandleSearch();
                    this.fetchAllQuestionLanguages();
                }
            );
        } else {
            this.setState({ language_search_term, searchError: true });
        }
    };
    
    fetchAllQuestionLanguages = async () => {
        const {
            language_limit,
            language_offset,
            language_sort_column,
            language_sort_direction,
            language_search_term
        } = this.state;
        await this.props.getAllQuestionLanguages(language_limit, language_offset, language_sort_column, language_sort_direction, language_search_term);
        this.setState({ language_limit: this.state.languageRowsPerPage });
        this.setState({ languageData: this.props.allQuestionLanguages })
    };
    
    handleLanguagePageChange = (event, newLanguagePage) => {
        const {languageRowsPerPage} = this.state;
        const newLanguageOffset = newLanguagePage * languageRowsPerPage;
        this.setState(
        {
            language_limit: languageRowsPerPage,
            language_offset: newLanguageOffset,
            currentLanguagePage: newLanguagePage
        },
        () => {
            this.fetchAllQuestionLanguages();
        }
        );
    };

    handlePageOffsetChange = (newOffset, newPage) => {
        const {languageRowsPerPage} = this.state;
        this.setState(
        {
            language_limit: languageRowsPerPage,
            language_offset: newOffset,
            currentLanguagePage: newPage
        },
        () => {
            this.fetchAllQuestionLanguages();
        }
        );
    };
    
    //handle the data fetching as per the data per page limit gets changes
    handleLanguageRowsPerPageChange = (event) => {
        const newLanguageLimit = parseInt(event.target.value, 10);
        this.setState({languageRowsPerPage: newLanguageLimit});
        const newLanguagePage = Math.floor(this.state.language_offset / newLanguageLimit)
        this.setState(
        {
            language_limit: newLanguageLimit,
            language_offset: newLanguageLimit * newLanguagePage,
            currentLanguagePage: newLanguagePage
        },
        () => {
            this.fetchAllQuestionLanguages();
        }
        );
    };

    handleSortLanguageChange = (language_sort_column) => {
        // If the same column is clicked, toggle the sort direction
        const { language_sort_column: currentLanguageSortColumn, language_sort_direction } = this.state;
        const newLanguageSortDirection =
        currentLanguageSortColumn === language_sort_column && language_sort_direction === "ASC"
            ? "DESC"
            : "ASC";

        this.setState(
        {
            language_sort_column,
            language_sort_direction: newLanguageSortDirection,
            language_offset: 0,
            currentLanguagePage: 0,
        },
        () => {
            this.fetchAllQuestionLanguages();
        }
        );
    }

    getQuestionLanguage(language_id) {
        let languagename
        this.props.allQuestionLanguages.map(language => {
            if (language.language_id === language_id) {
                return languagename = language.language
            }
        })
        return languagename
    }
    
    onEditLanguage(e, el) {
        this.setState({
            languageId: el.language_id,
            language: e.target.value
        })
    }
    
    async onUpdateLanguage(e) {
        e.preventDefault()
        var obj = {
            language_id: this.state.languageId,
            language: this.state.language
        }
        this.setState({ selectLanguage: '' })
        if (!this.state.language.trim()) {
            window.Swal.fire({
                title: 'Failed to update medium/language!',
                text: 'Medium/Language cannot be empty!',
                icon: 'error',
                customClass: {
                    confirmButton: `btn font-weight-bold ${confirmClass}`,
                },
                onOpen: (modalElement) => {
                    if (customer === customerConstants.EXO_CUSTOMER2) {
                        modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                    }
                }
            });
        }
        else {
            await this.props.updateLanguage(obj);
            if (this.props.successlanguageupdate) {
                // await this.props.getAllQuestionLanguages(language_limit, language_offset, language_sort_column, language_sort_direction, language_search_term);
                this.fetchAllQuestionLanguages()
                this.generateLanguageOptions();
                window.Swal.fire({
                    title: 'Success!',
                    text: 'Updated medium/language!',
                    icon: 'success',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });
            }
            if (this.props.errorlanguageupdate) {
                window.Swal.fire({
                    title: 'Failed not update medium/language!',
                    text: this.props.errorlanguageupdate,
                    icon: 'error',
                    customClass: {
                        confirmButton: `btn font-weight-bold ${confirmClass}`,
                    },
                    onOpen: (modalElement) => {
                        if (customer === customerConstants.EXO_CUSTOMER2) {
                            modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                        }
                    }
                })
                    .then(function () {
                        window.$('#manage-category').modal('hide')
                        window.$("#question-category").val('default');
                        window.$('#question-category').selectpicker('refresh');
                    });

            }
        }
    }

    generateLanguageOptions() {
        let languageOptionsImport = []
        this.props.allQuestionLanguages.map((el, i) => {
            languageOptionsImport.push(<Option key={el.language_id} optionName={el.language} value={el.language_id} />)
        });

        this.setState({
            languageOptionsImport: languageOptionsImport,
            selectedLanguage: languageOptionsImport[0].props.value
        });

        if (languageOptionsImport)
            window.$('#question-import-select').selectpicker('refresh');
    }
    
    async handleEditLanguage(el, i, e) {
        e.preventDefault()
        this.setState({
            editLanguage: true,
            selectLanguage: i,
            languageId: el.language_id,
            language: el.language
        })
    }

    async handleDeleteModal(el, e) {
        e.preventDefault()
        window.Swal.fire({
            title: 'Do you want to delete the medium/language?',
            showCancelButton: true,
            confirmButtonColor: addBtnColor,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirm'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await this.props.deleteLanguage(el);
                if (this.props.successlanguagedelete) {
                    // await this.props.getAllQuestionLanguages(language_limit, language_offset, language_sort_column, language_sort_direction, language_search_term);
                    this.fetchAllQuestionLanguages()
                    this.generateLanguageOptions();
                    window.Swal.fire({
                        title: 'Success!',
                        text: 'Deleted medium/language!',
                        icon: 'success',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-category').modal('hide')
                            window.$("#question-category").val('default');
                            window.$('#question-category').selectpicker('refresh');
                        });
                }
                if (this.props.errorlanguagedelete) {
                    window.Swal.fire({
                        title: 'Failed to delete medium/language!',
                        text: this.props.errorlanguagedelete,
                        icon: 'error',
                        customClass: {
                            confirmButton: `btn font-weight-bold ${confirmClass}`,
                        },
                        onOpen: (modalElement) => {
                            if (customer === customerConstants.EXO_CUSTOMER2) {
                                modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                            }
                        }
                    })
                        .then(function () {
                            window.$('#manage-language').modal('hide')
                            window.$("#question-language").val('default');
                            window.$('#question-language').selectpicker('refresh');
                        });

                }
            }
        })
    }
    
    syncTableLanguage() {
        return (
            <>
                {this.state.languageData.length > 0 ? (
                    <Table sx={{width:"75vw"}}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortLanguageChange("language_id")}
                                >
                                    No{" "}
                                    {this.state.language_sort_column === "language_id" && (
                                    <span>{this.state.language_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell
                                    className="thead"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.handleSortLanguageChange("language")}
                                >
                                    Medium/Language Name{" "}
                                    {this.state.language_sort_column === "language" && (
                                    <span>{this.state.language_sort_direction === "ASC" ? <ArrowDownwardIcon sx={{ fontSize: 15 }} /> : <ArrowUpwardIcon sx={{ fontSize: 15 }} />}</span>
                                    )}
                                </TableCell>
                                <TableCell className="thead">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.languageData.map((el, i) => {
                                if (i === this.state.selectLanguage) {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className="tData">{i+1}</TableCell>
                                            <TableCell className="tData">
                                                {!this.state.editLanguage ? 
                                                this.getQuestionLanguage(el.language_id) : 
                                                <Input type="text" inputWrap="col-lg-8" className="form-control" id="id-title"
                                                    name="title"
                                                    value={this.state.language}
                                                    onChange={(e) => this.onEditLanguage(e, el)} 
                                                />
                                                }
                                            </TableCell>
                                            <TableCell className="tData">{
                                                <div>
                                                    <Button 
                                                        onClick={(e) => this.onUpdateLanguage(e)} 
                                                        className="btn btn-icon btn-success btn-sm m-1" 
                                                        btnIconStyle="flaticon2-correct" 
                                                        title="Save Language" 
                                                    />
                                                </div>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                } 
                                else 
                                {
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="tData">{i+1}</TableCell>
                                        <TableCell className="tData">{this.getQuestionLanguage(el.language_id)}</TableCell>
                                        <TableCell className="tData">{
                                            <div>
                                                <Button 
                                                    onClick={(e) => this.handleEditLanguage(el, i, e)} 
                                                    className="btn btn-icon action-btn btn-sm m-1" 
                                                    btnIconStyle="flaticon-edit" 
                                                    title="Edit Language" 
                                                />
                                                <Button 
                                                    onClick={(e) => this.handleDeleteModal(el, e)} 
                                                    className="btn btn-icon btn-danger btn-sm m-1 delete-assement-btn flaticon-delete"
                                                    title="Delete Language" 
                                                />
                                            </div>}
                                        </TableCell>
                                    </TableRow>
                                )
                                }
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <div className="text-center mb-5 mt-10">
                        <label>No Languages Available!</label>
                    </div>
                    )}
            </>
        )
    }

    render(){
        return (
          <ContentWrapper>
            <div className="pt-5">
              <div className="col-md-12">
                <h3>Manage Mediums/Languages</h3>
              </div>

              <CardBody>
                <div className="row justify-content-end">
                  <div
                    className="table-wrapper-scroll-y my-custom-scrollbar"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <TabList>
                      <QuestionAccordion
                        labelName="Create New Medium/Language"
                        onChange={this.onNewLanguageInput}
                        onClick={this.onAddNewLanguage}
                        val={this.state.newLanguage}
                      />
                    </TabList>
                    <div className="row justify-content-end col-md-12">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Search"
                          onChange={this.handleLanguageSearch}
                          className="col-md-18 col-18"
                        />
                      </Form.Group>
                    </div>
                    <TabContent>
                      <TabPane
                        className="tab-pane fade show active"
                        id="manage-1"
                        ariaLabelledby="manage-tab-1"
                      >
                        {/* begin: Datatable */}
                        {this.props.cat_loading ? (
                          <div style={{ width: "75vw", overflowX: "scroll" }}>
                            <Table sx={{ width: "75vw" }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Medium/Language Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                            <div className="loading-container mb-5 mt-10">
                              <ReactSpinner
                                animation="border"
                                role="status"
                                style={{ display: "block", margin: "auto" }}
                              >
                                <span className="sr-only">Loading...</span>
                              </ReactSpinner>
                            </div>
                          </div>
                        ) : this.state.languageData.length > 0 ? (
                          this.syncTableLanguage()
                        ) : (
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell className="thead">No</TableCell>
                                  <TableCell className="thead">
                                    Medium/Language Name
                                  </TableCell>
                                  <TableCell className="thead">
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {/* end: Datatable */}
                            </Table>
                            <div className="text-center mb-5 mt-10">
                              <label>No Languages Available!</label>
                            </div>
                          </div>
                        )}
                      </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end">
                        <MaterialCustomTablePagination
                            totalCount = {this.props.totalLanguagesCount}
                            currentPage = {this.state.currentLanguagePage}
                            limit = {this.state.languageRowsPerPage}
                            handlePageChange = {this.handleLanguagePageChange}
                            handleRowsPerPageChange = {this.handleLanguageRowsPerPageChange}
                            handlePageOffsetChange = {this.handlePageOffsetChange}
                            offset = {this.state.language_offset}
                            rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        />
                    </div>
                  </div>
                </div>
              </CardBody>
            </div>
          </ContentWrapper>
        )
    }
}

  function mapStateToProps(state) {
    return {
        totalLanguagesCount: state.languageReducer.totalLanguagesCount,//
        cat_loading:state.languageReducer.loading,
        error: state.languageReducer.error,//
        // totalCount: state.questionsLibraryReducer.totalCount,//
        
        errorlanguagedelete: state.languageReducer.errorlanguagedelete,
        successlanguagedelete: state.languageReducer.successlanguagedelete,

        errorlanguageupdate: state.languageReducer.errorlanguageupdate,
        successlanguageupdate: state.languageReducer.successlanguageupdate,//
        
        errorlanguagecreate: state.languageReducer.errorlanguagecreate,
        successlanguagecreate: state.languageReducer.successlanguagecreate,//

        allQuestionLanguages: state.languageReducer.allQuestionLanguages,//
        loading: state.languageReducer.loading,//
    }
}

    const mapActionsToProps = {
        createLanguage: quesLanguageActions.createLanguage,
        getAllQuestionLanguages: quesLanguageActions.getAllQuestionLanguages,
        updateLanguage: quesLanguageActions.updateLanguage,
        deleteLanguage: quesLanguageActions.deleteLanguage,
    }

    export default connect(mapStateToProps, mapActionsToProps)(QuestionLanguage);

