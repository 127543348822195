import React from 'react';

export default function Card({ children }) {
    return (

        <div className="card-body sticky-card-body" style={{ position: "sticky", top: "0px" }}>

            {children}

        </div>

    )
}