import React from 'react';
import { Link } from 'react-router-dom';
import {history} from "../../helper/history"

export default function BreadcrumbItem({ to, breadcrumbItemName, active }) {
    return (
        <li className={active ? "breadcrumb-item active text-muted" : "breadcrumb-item text-muted"}>
            <Link  to={to} className="text-muted" onClick={() => {
                history.push(to);
                window.location.reload();
            }}>{breadcrumbItemName}
            </Link>
        </li>
    )
}