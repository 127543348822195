import React, { Component } from "react";
import check from "../assets/images/check.svg";
import students from "../assets/images/students.png";
import teacher from "../assets/images/teacher.png";
import parents from "../assets/images/parents.png";
import classroom from '../assets/images/classroom.png';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

class Support extends Component {

  componentDidMount() {
    // Intersection Observer setup
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
          observer.unobserve(entry.target);  // Stop observing once animated
        }
      });
    }, { threshold: 0.1 });

    // Target elements for animation
    const elements = document.querySelectorAll('.support-container');
    elements.forEach((el, index) => {
      el.style.transitionDelay = `${index * 0.3}s`;
      observer.observe(el);
    });
  }

  render() {
    return (
      <>
        <div id="support" className="container section-container support">
          <div className="pb-5 px-5">
            <h1 className="edsider-section-title supTitle">What we have</h1>
          </div>
          <div className="row">
            <div className="image-stack">
              <div className="image-stack__item image-stack__item--bottom">
                <img src={classroom} className="falling-image1" alt="student" />
              </div>
              <div className="image-stack__item image-stack__item--top">
                <div className="row d-flex justify-content-between supportAll">
                  <div className="col-md-12 support-container d-flex align-items-center support-check">
                    <div className="row support-card">
                      <div className="row">
                        <div className="col-md-12 support-row-title pb-3">Comprehensive Coverage</div>
                        <div className="col-md-12 support-row-content">
                          <CheckCircleIcon className="checkIcon" />Covers Grade 6 to 11 syllabuses<br />
                          <CheckCircleIcon className="checkIcon" />Includes Subject, Chapter, and Term Model papers<br />
                          <CheckCircleIcon className="checkIcon" />Available in English, Sinhala, and Tamil mediums<br />
                          <CheckCircleIcon className="checkIcon" />Covers subjects like Science, Mathematics, and English
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 support-container d-flex align-items-center support-check">
                    <div className="row support-card">
                      <div className="row">
                        <div className="col-md-12 support-row-title pb-3">Variety of Question Types</div>
                        <div className="col-md-12 support-row-content">
                          <CheckCircleIcon className="checkIcon" />Multiple Choice Questions (MCQs)<br />
                          <CheckCircleIcon className="checkIcon" />Descriptive questions<br />
                          <CheckCircleIcon className="checkIcon" />Comprehensive questions
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 support-container d-flex align-items-center support-check">
                    <div className="row support-card">
                      <div className="row">
                        <div className="col-md-12 support-row-title pb-3">Extensive Collection of Papers</div>
                        <div className="col-md-12 support-row-content">
                          <CheckCircleIcon className="checkIcon" />Over 8000 questions with varying difficulty levels and categories under each language<br />
                          <CheckCircleIcon className="checkIcon" />Contributions from experienced teachers in government schools<br />
                          <CheckCircleIcon className="checkIcon" />Ongoing addition of new papers to the system<br />
                          <CheckCircleIcon className="checkIcon" />Flexible purchasing options and attractive discounts
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Support;
