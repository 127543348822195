import React,{useState} from "react";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, AtomicBlockUtils, convertToRaw, Modifier} from "draft-js";
import { namespace } from "../../helper/namespace";
import config from '../../helper/config';
import { customerConstants } from "../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let cancelClass, confirmClass
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
  case customerConstants.EXO_CUSTOMER2:
    // cancelClass = "cmc-btn-default"
    // confirmClass = "cmc-btn-primary"
  default:
    cancelClass = "btn-default"
    confirmClass = "btn-primary"
    break
}

export default function ViewEditor ({ editorState, value, onEditorStateChange}) {
  
    //Creating a custom audio component
    const CustomAudioOption = ({ addAudio }) => {
      return (
        <div
          onClick={addAudio}
          style={{ cursor: "pointer", marginRight: "10px" }}
          className="rdw-option-wrapper"
        >
          <i className="fa fa-microphone" />
        </div>
      )
    }

    // Custom function to get audio file
    const getAudioFile = () => {
      return new Promise((resolve, reject) => {
        // Create an input element
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'audio/*';
        input.onchange = (event) => {
          const file = event.target.files[0];
          if (file) {
            resolve(file);
          } else {
            reject(new Error('No audio file selected'));
          }
        };
        // Trigger a click on the input element
        input.click();
      });
    };

    // Handle add audio
    const addAudio = async() => {
      try {
        // Your logic to upload and get base64Data for the audio file
        const audioFile = await getAudioFile();
        const base64Data = await convertFileToBase64(audioFile);

        // Create an entity with audio data
        const contentStateWithEntity = editorState.getCurrentContent().createEntity(
          'AUDIO',
          'IMMUTABLE',
          { src: base64Data }
        );

        // Get the last created entity key
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Insert the audio block into the editor
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
          editorState,
          entityKey,
          ' '
        );

        // Update the editor state
        onEditorStateChange(newEditorState);
      } catch (error) {
        console.error('Error adding audio:', error);
      }
    };

    // Custom component for adding video
    const CustomVideoOption = ({ addVideo }) => {
      return (
        <div onClick={addVideo} style={{ cursor: 'pointer', marginRight: '10px' }}>
          <i className="fa fa-video" />
        </div>
      );
    };

    // Custom function to get audio file
    const getVideoFile = () => {
      return new Promise((resolve, reject) => {
        // Create an input element
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'video/*';
        input.onchange = (event) => {
          const file = event.target.files[0];
          if (file) {
            resolve(file);
          } else {
            reject(new Error('No video file selected'));
          }
        };
        // Trigger a click on the input element
        input.click();
      });
    };

    // Handle add audio
    const addVideo = async() => {
      try {
        // Your logic to upload and get base64Data for the audio file
        const videoFile = await getVideoFile();
        const base64Data = await convertFileToBase64(videoFile);

        // Create an entity with audio data
        const contentStateWithEntity = editorState.getCurrentContent().createEntity(
          'VIDEO',
          'IMMUTABLE',
          { src: base64Data }
        );

        // Get the last created entity key
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Insert the audio block into the editor
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(
          editorState,
          entityKey,
          ' '
        );

        // Update the editor state
        onEditorStateChange(newEditorState);
      } catch (error) {
        console.error('Error adding video:', error);
      }
    };

    const [isMathModalOpen, setMathModalOpen] = useState(false);

    const handleToggleMath = () => {
      setMathModalOpen(!isMathModalOpen);
    };

    const MathSelectModal = ({ isOpen, onClose, onSelectMath }) => {
      const mathExpressions = [
        'x^2', 'y^2', 'a^2', 'b^2', 'x²', 'y²', 
        'a²', 'b²', '∫', '∑', '√', 'α', 'β', 'γ',
        'π', 'θ', 'μ', '∞', '≠', '≈', '≥', '≤',
        '∂', '∆', '∇', '∏', '√2', '∛', '∜', '∝',
        '∞', '∟', '∠', '∡', '∢', '∣', '∤', '∥',
        '∦', '∧', '∨', '∩', '∪', '∫', '∬', '∭',
        '∮', '∯', '∰', '∱', '∲', '∳', '∴', '∵',
        'y³', 'x³', 'a³', 'b³', '+', '-'
      ];

      const handleSelectMath = (expression) => {
        onSelectMath(expression);
        onClose();
      };

      // Define the number of columns
      const columns = 15;

      // Calculate the number of rows
      const rows = Math.ceil(mathExpressions.length / columns);

      return (
        <div style={{ display: isOpen ? 'block' : 'none' }}>
          {/* <p>Select a mathematical expression:</p> */}
          <table
            style={{
              borderCollapse: 'collapse',
              width: '100%',
              margin: '10px 0',
            }}
          >
            <tbody>
              {Array.from({ length: rows }).map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {Array.from({ length: columns }).map((_, colIndex) => {
                    const index = rowIndex * columns + colIndex;
                    const expression = mathExpressions[index];
                    return (
                      <td
                        key={expression}
                        onClick={() => handleSelectMath(expression)}
                        style={{
                          border: '1px solid #ccc',
                          padding: '8px',
                          cursor: 'pointer',
                        }}
                      >
                        {expression}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={onClose}>Cancel</button>
        </div>
      );
    };

    const CustomMathOption = ({ onToggleMath }) => {
      return (
        <div
          onClick={onToggleMath}
          style={{ cursor: "pointer" }}
          className="rdw-option-wrapper"
        >
          <i className="fa fa-superscript" />
        </div>
      )
    }

    const handleSelectMath = (mathExpression) => {
      const contentState = editorState.getCurrentContent();
      const selection = editorState.getSelection();

      // Get the current block and its text
      const currentBlock = contentState.getBlockForKey(selection.getStartKey());
      const currentText = currentBlock.getText();

      // Get the start and end offsets of the selection
      const startOffset = selection.getStartOffset();
      const endOffset = selection.getEndOffset();

      // // Combine the existing text with the selected expression
      const newText = mathExpression + currentText.substring(endOffset);

      // Replace the current content with the new text
      const newContentState = Modifier.replaceText(
        contentState,
        selection.merge({
          anchorOffset: startOffset,
          focusOffset: startOffset + mathExpression.length,
        }),
        newText
      );

      // Update the editor state
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'replace-text'
      );

      onEditorStateChange(newEditorState);
    };

  
    //Function to read and convert the uploaded file into Base64 data
    function convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    }

    // Handling file upload functionality
    const handleFileUpload = (file) => {
      return new Promise(async (resolve, reject) => {
        try{
          const fileSizeLimit = 10 * 1024 * 1024; // 10MB limit (adjust the value as needed)
          if (file.size > fileSizeLimit) {
            reject(new Error('File size exceeds the limit.'));
            window.Swal.fire({
              title: namespace.FAILED,
              text: namespace.FILE_SIZE_IS_EXCEEDED,
              icon: 'error',
              customClass: {
                confirmButton: `btn font-weight-bold ${confirmClass}`,
              },
              onOpen: (modalElement) => {
                  if (customer === customerConstants.EXO_CUSTOMER2) {
                      modalElement.querySelector('.swal2-confirm').setAttribute('id', 'confirmButtonId');
                  }
              }
            });
            return;
          }
          const base64Data = await convertFileToBase64(file);
          resolve({ data: { link: base64Data } });   
        }
        catch(error) {
          reject(error);
        }
      });         
    }

    //Creating a custom table component
    const CustomTableOption = ({ addTable }) => {
      return (
        <div
          onClick={addTable}
          style={{
            cursor: "pointer",
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
          className="rdw-option-wrapper"
        >
          <i className="fa fa-table" />
        </div>
      )
    }

    //Handle add table
    const addTable = () => {;
        const numRows = prompt("Enter the number of rows:");
        const numCols = prompt("Enter the number of columns:");

        const tableData = [];
        for (let i = 0; i < numRows; i++) {
          const row = [];
          for (let j = 0; j < numCols; j++) {
            row.push("");
          }
          tableData.push(row);
        }

        const contentStateWithEntity = editorState.getCurrentContent().createEntity('TABLE', 'IMMUTABLE', {
          tableData,
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');
        onEditorStateChange(newEditorState);
        const entityMap = {
                [3]: {
                  type: 'table',
                  mutability: 'IMMUTABLE',
                  tableData
                }
              };
        const updatedContentState = newEditorState.getCurrentContent();
        const rawContentState = convertToRaw(updatedContentState);
        rawContentState.entityMap = entityMap;
    };

    
    //Creating the custom eraser component
    const eraser = () => {
      return (
        <div
          onClick={(e) => {
            e.preventDefault()
            onEditorStateChange(EditorState.createEmpty())
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
          className="rdw-option-wrapper"
        >
          <i className="fa fa-eraser" />
        </div>
      )
    }
  
    //Creating custom block component to render image
    const ImageBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src, width, height } = entity.getData();
      return <img src={src} alt="Image" width={width} height={height} />;
    }
    
    //Creating custom block component to render audio
    const AudioBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src } = entity.getData();
      return <audio src={src} controls />;
    };

    //Creating custom block component to render video
    const VideoBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { src } = entity.getData();
      return <video src={src} controls />;
    };

    const MathBlock = (props) => {
      const { block, contentState } = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { expression } = entity.getData();
      return (
        <span>
          {expression}
        </span>
      );
    };

    // Rendering table block
    function TableBlock(props) {
      const { block, contentState} = props;
      const entity = contentState.getEntity(block.getEntityAt(0));
      const { tableData } = entity.getData();
      const entityKey = block.getEntityAt(0);

      const handleEditCell = (e, rowIndex, cellIndex) => {
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex][cellIndex] = e.target.value;
        const newContentState = contentState.mergeEntityData(entityKey, { tableData: updatedTableData });
        onEditorStateChange(EditorState.push(editorState, newContentState));
      };

      return (
        <table>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cellData, cellIndex) => (
                  <td 
                  key={cellIndex} 
                  style={{ border: "1px solid black", padding: "8px" }}
                  >
                  <input
                  type="text"
                  value={cellData}
                  onChange={(e) => handleEditCell(e, rowIndex, cellIndex)}
                  style={{ outline: "none", border: "none" }}
                  />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      );
    }

    //Function to render the respective blocks
    const BlockRenderer = (block) => {
    if (block.getType() === "atomic") {
      const contentState = editorState.getCurrentContent();
      const entity = contentState.getEntity(block.getEntityAt(0));
      const entityType = entity.getType();

      if (entityType === "AUDIO") {
        const { src } = entity.getData();
        return {
          component: AudioBlock,
          editable: false,
          props: {
            block,
            contentState,
            src
          },
        };
      }

      if (entityType === "VIDEO") {
        const { src } = entity.getData();
        return {
          component: VideoBlock,
          editable: false,
          props: {
            block,
            contentState,
            src
          },
        };
      }

      if (entityType === "IMAGE") {
        const { src, width, height } = entity.getData();
        return {
          component: ImageBlock,
          editable: false,
          props: {
            src,
            width,
            height
          },
        };
      }

      if (entityType === "TABLE") {
        const { tableData } = entity.getData();
        return {
          component: TableBlock,
          editable: false,
          props: {
            block,
            contentState,
            tableData
          },
        };
      }

      if (entity.getType() === 'MATH') {
        return {
          component: MathBlock,
          editable: false,
        };
      }
    }
    return null;
  }

  return (
      <div>
        <Editor
            editorState= {editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            value={{key:value , state: editorState}}
            onEditorStateChange={onEditorStateChange}
            blockRendererFn={BlockRenderer}
            toolbarHidden={true}
            readOnly={true}
            toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  uploadCallback: handleFileUpload,
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  //alt: { present: true, mandatory: true },
                  defaultSize: {
                      height: '',
                      width: '',
                    },
                },
                fontFamily: {
                  options: [
                    "Arial",
                    "Georgia",
                    "Impact",
                    "Tahoma",
                    "Times New Roman",
                    "Verdana",
                    "Kushpoo",
                    "Baamini",
                    "FMAbhaya"
                  ],
                },
                remove: {component:eraser},
               }}
               toolbarCustomButtons={[
                //<CustomTableOption key="table" addTable={addTable} />,
                <CustomAudioOption key="audio" addAudio={addAudio} />,
                //<CustomVideoOption key="video" addVideo={addVideo} />,
                <CustomMathOption key="math" onToggleMath={handleToggleMath} />
               ]}
        />
        <MathSelectModal
          isOpen={isMathModalOpen}
          onClose={() => setMathModalOpen(false)}
          onSelectMath={handleSelectMath}
        />
        </div>
    )
}
