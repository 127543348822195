import React, { useEffect } from "react";

export default function FinalizeTabPane({
  id,
  role,
  ariaLabelledby,
  className,
  question,
  answer,
}) {
  return (
    <div
      className={className}
      id={id}
      role={role}
      aria-labelledby={ariaLabelledby}
    >
      <h3 className="card-label mb-10 mt-5">
        {question}
      </h3>
      <p>{answer}</p>
    </div>
  );
}
