import React, {useEffect} from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { formFixture } from "./FormFixture"
import "./hirearchy.css"
import config from '../../../helper/config';
import {customerConstants} from "../../../helper/constants.js";

const getCustomerFeatureFlag = () => {
  return config.customerConfigs.featureFlag;
};

let tabTextColor
const customer = getCustomerFeatureFlag()
switch (customer) {
  case customerConstants.EXO_CUSTOMER1:
    tabTextColor = "var(--maincolor)"
    break
  case customerConstants.EXO_CUSTOMER2:
    tabTextColor = "var(--maincolor-cmc)"
    break
  default:
    tabTextColor = "var(--maincolor)"
    break
}

const TabComponent = ({ formArray, pid, title, setFormDetail, selectedFormDetail }) => {

  useEffect(()=>{
    const selectedFormIndex = formArray.findIndex(form => form.form_list_id === selectedFormDetail.form_list_id)    
    setValue(selectedFormIndex)    
  }, [selectedFormDetail]) 

  const [value, setValue] = React.useState(0);

  const findIcon = (id) => {
    return formFixture(undefined).find((item) => id === item.formListId)
      }

  return (
    <Tabs
      variant="scrollable"
      scrollButtons
      value={value}
      TabIndicatorProps={{
        style: {
          backgroundColor: tabTextColor,
        },
      }}
    >
      {formArray?.map((form, index) => {
        const isSelected = index === value
        return (
          <div className="px-1 py-2">
            {findIcon(form.form_list_id) && findIcon(form.form_list_id).icon}
            <Tab
              key={index}
              label={form.form_display_title}
              value={index}
              onClick={() => {
                setFormDetail(form)
                setValue(index)
              }}
              style={{
                whiteSpace: "nowrap",
                // paddingLeft: "4px",
                // paddingRight: "3px",
                textTransform: "unset",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "500",
                letterSpacing: "-0.4px",
                color: "#7E8299",
                opacity: 1,
              }}
              className={isSelected ? "selected-tab-color" : ""}
            />
          </div>
        )
      })}
    </Tabs>
  )
}

export default TabComponent
