import React from "react";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

const cardBottomRowStyle = {
};

export default function Card({ cardLabel, cardBottomLable, children }) {
  return (
    <div className="card-title" style={cardStyle}>
      <h3 id="card-sub-topic" className="card-label">
        {cardLabel}
      </h3>
      {children}
      <div className="card-bottom-row" style={cardBottomRowStyle}>
        <p style={{ fontSize: 'small' }}>
          <u>{cardBottomLable}</u>
        </p>
      </div>
    </div>
  );
}
