import config from "../../../helper/config";
import APIHandler from "../../../handlers/APIHandler";

export const NotificationService = {
  getAllNotificationsByAnnoucementId,
  addNewNotification
};
  
  async function getAllNotificationsByAnnoucementId(annoucement_id) {
    const configOptions = {
      method: "GET",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `${config.oat_api_url}/manage/notifications/get-by-announcement-id/${annoucement_id}`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }


  async function addNewNotification(newNotification) {
    const configOptions = {
      method: "POST",
      headers: {
        ...APIHandler.getHeader(config.azure_ad_config.apis.OAT.name),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newNotification),
    };
  
    const response = await fetch(
      `${config.oat_api_url}/manage/notifications/add`,
      configOptions
    );
    return APIHandler.handleResponse(response);
  }