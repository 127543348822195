import { testConstants } from "../../helper/constants";
import { testService } from "../../service/candidate/testService";
import { store } from "../../helper/redux_store";

export function viewTest(candidate_id) {

    return dispatch => {
        dispatch(view_test());
        dispatch(view_test_request(candidate_id));

        testService.viewTest(candidate_id).then(
            data => {
                dispatch(view_test_success(data.data))
            },
            error => {
                dispatch(view_test_failure(error))
            }
        )
    }

    function view_test() {
        return { type: testConstants.VIEW_TEST }
    }

    function view_test_request(candidate_id) {
        return { type: testConstants.VIEW_TEST_REQUEST, candidate_id }
    }

    function view_test_success(data) {
        return { type: testConstants.VIEW_TEST_SUCCESS, data }
    }

    function view_test_failure(error) {
        return { type: testConstants.VIEW_TEST_FAILURE, error }
    }
}

export function loadQuestions(assessment_id) {

    return async dispatch => {
        dispatch(load_questions());
        dispatch(load_questions_request(assessment_id));

        await testService.loadQuestions(assessment_id).then(
            data => {
                dispatch(load_questions_success(data.data))
            },
            error => {
                dispatch(load_questions_failure(error))
            }
        )
    }

    function load_questions() {
        return { type: testConstants.LOAD_EXAM_QUESTIONS }
    }

    function load_questions_request(candidate_id) {
        return { type: testConstants.LOAD_EXAM_QUESTIONS_REQUEST, candidate_id }
    }

    function load_questions_success(data) {
        return { type: testConstants.LOAD_EXAM_QUESTIONS_SUCCESS, data }
    }

    function load_questions_failure(error) {
        return { type: testConstants.LOAD_EXAM_QUESTIONS_FAILURE, error }
    }
}

export function loadAssessmentQuestions(assessment_id) {

    return async dispatch => {
        dispatch(load_assessment_questions());
        dispatch(load_assessment_questions_request(assessment_id));

        await testService.loadAssessmentQuestions(assessment_id).then(
            assessmentQuestions => {
                dispatch(load_assessment_questions_success(assessmentQuestions.data))
            },
            error => {
                dispatch(load_assessment_questions_failure(error))
            }
        )
    }

    function load_assessment_questions() {
        return { type: testConstants.LOAD_ASSESSMENT_QUESTIONS }
    }

    function load_assessment_questions_request(candidate_id) {
        return { type: testConstants.LOAD_ASSESSMENT_QUESTIONS_REQUEST, candidate_id }
    }

    function load_assessment_questions_success(assessmentQuestions) {
        return { type: testConstants.LOAD_ASSESSMENT_QUESTIONS_SUCCESS, assessmentQuestions }
    }

    function load_assessment_questions_failure(error) {
        return { type: testConstants.LOAD_ASSESSMENT_QUESTIONS_FAILURE, error }
    }
}

export function startTimer(obj) {
    return async dispatch => {
        dispatch(start_timer());
        dispatch(start_timer_request(obj));

        await testService.startTimer(obj).then(
            data => {
                dispatch(start_timer_success(data.data))
            },
            error => {
                dispatch(start_timer_failure(error))
            }
        )
    }

    function start_timer() {
        return { type: testConstants.START_EXAM }
    }

    function start_timer_request(obj) {
        return { type: testConstants.START_EXAM_REQUEST, obj }
    }

    function start_timer_success(data) {
        return { type: testConstants.START_EXAM_SUCCESS, data }
    }

    function start_timer_failure(error) {
        return { type: testConstants.START_EXAM_FAILURE, error }
    }
}

export function validateCandidate(candidate_id) {

    return async dispatch => {
        dispatch(validate_candidate());
        dispatch(validate_candidate_request(candidate_id));

        await testService.validateCandidate(candidate_id)
        .then((res) => {
            if (res.success) {
                dispatch(validate_candidate_success(res.data));
            } else {
                dispatch(validate_candidate_failure(res.message));  
            }
        })
        .catch((error) => {
            dispatch(validate_candidate_failure(error));
        });
    }

    function validate_candidate() {
        return { type: testConstants.VALIDATE_CANDIDATE }
    }

    function validate_candidate_request(candidate_id) {
        return { type: testConstants.VALIDATE_CANDIDATE_REQUEST, candidate_id }
    }

    function validate_candidate_success(data) {
        return { type: testConstants.VALIDATE_CANDIDATE_SUCCESS, data }
    }

    function validate_candidate_failure(error) {
        return { type: testConstants.VALIDATE_CANDIDATE_FAILURE, error }
    }
}

export function getTestQuestionContent(question) {
    return async dispatch => {
        dispatch(get_test_question_content_request(question));

        await testService.getTestQuestionContent(question)
            .then(
                content => {
                    dispatch(get_test_question_content_success(content.data))
                },
                error => {
                    dispatch(get_test_question_content_failure(error))
                }
            )
    }

    function get_test_question_content_request(question) {
        return { type: testConstants.GET_TEST_QUESTION_CONTENT_REQUEST, question }
    }

    function get_test_question_content_success(data) {
        return { type: testConstants.GET_TEST_QUESTION_CONTENT_SUCCESS, data }
    }

    function get_test_question_content_failure(error) {
        return { type: testConstants.GET_TEST_QUESTION_CONTENT_FAILURE, error }
    }
}

export function getAllQuestionContents(obj) {
    return async dispatch => {
        dispatch(get_page_question_contents());
        dispatch(get_page_question_contents_request());

        await testService.getAllQuestionContents(obj)
            .then(
                content => {
                    dispatch(get_page_question_contents_success(content))
                },
                error => {
                    dispatch(get_page_question_contents_failure(error))
                }
            )
    }

    function get_page_question_contents() {
        return { type: testConstants.GET_PAGE_QUESTION_CONTENTS }
    }

    function get_page_question_contents_request() {
        return { type: testConstants.GET_PAGE_QUESTION_CONTENTS_REQUEST }
    }

    function get_page_question_contents_success(data) {
        return { type: testConstants.GET_PAGE_QUESTION_CONTENTS_SUCCESS, data }
    }

    function get_page_question_contents_failure(error) {
        return { type: testConstants.GET_PAGE_QUESTION_CONTENTS_FAILURE, error }
    }
}

export function submitAnswer(details) {
    return async dispatch => {
        dispatch(submit_answer());
        dispatch(submit_answer_request());

        await testService.submitAnswer(details)
            .then(
                data => {
                    dispatch(submit_answer_success(data.data))
                },
                error => {
                    dispatch(submit_answer_failure(error))
                }
            )
    }

    function submit_answer() {
        return { type: testConstants.SUBMIT_ANSWER }
    }

    function submit_answer_request() {
        return { type: testConstants.SUBMIT_ANSWER_REQUEST }
    }

    function submit_answer_success(data) {
        return { type: testConstants.SUBMIT_ANSWER_SUCCESS, data }
    }

    function submit_answer_failure(error) {
        return { type: testConstants.SUBMIT_ANSWER_FAILURE, error }
    }
}

export function submitAnswers(obj) {

    return async dispatch => {
        dispatch(submit_answers_for_page());
        dispatch(submit_answers_for_page_request());

        try {
            let data = await testService.submitAnswers(obj);
            dispatch(submit_answers_for_page_success(data.data));
        } catch (error) {
            dispatch(submit_answers_for_page_failure(error));
        }
    }

    function submit_answers_for_page() {
        return { type: testConstants.SUBMIT_ANSWERS_FOR_PAGE }
    }

    function submit_answers_for_page_request() {
        return { type: testConstants.SUBMIT_ANSWERS_FOR_PAGE_REQUEST }
    }

    function submit_answers_for_page_success(data) {
        return { type: testConstants.SUBMIT_ANSWERS_FOR_PAGE_SUCCESS, data }
    }

    function submit_answers_for_page_failure(error) {
        return { type: testConstants.SUBMIT_ANSWERS_FOR_PAGE_FAILURE, error }
    }
}

export function finishTest(obj) {
    return dispatch => {
        dispatch(finish_test());
        dispatch(finish_test_request());

        return testService.finishTest(obj)
            .then(
                data => {
                    dispatch(finish_test_success(data.data))
                },
                error => {
                    dispatch(finish_test_failure(error))
                }
            )
    }

    function finish_test() {
        return { type: testConstants.FINISH_TEST }
    }

    function finish_test_request() {
        return { type: testConstants.FINISH_TEST_REQUEST }
    }

    function finish_test_success(data) {
        return { type: testConstants.FINISH_TEST_SUCCESS, data }
    }

    function finish_test_failure(error) {
        return { type: testConstants.FINISH_TEST_FAILURE, error }
    }
}


export function refreshAssessmentStartPage() {
    return dispatch => {
        dispatch(refreshAssessmentStartPage());
    }

    function refreshAssessmentStartPage(assessment) {
        return { type: testConstants.VALIDATE_CANDIDATE_REFRESH, assessment }
    }
}

export function refreshForExamView() {
    return dispatch => {
        dispatch(loadQuestionsRefresh());
    }

    function loadQuestionsRefresh() {
        return { type: testConstants.LOAD_EXAM_QUESTIONS_REFRESH }
    }
}

export function refreshForAnswerView() {
    return dispatch => {
        dispatch(answerTestRefresh());
    }

    function answerTestRefresh() {
        return { type: testConstants.GET_TEST_QUESTION_CONTENT }
    }
}

export function getCandidateAnswers(candidate) {

    return async dispatch => {
        try {
            dispatch(get_candidate_answers(candidate));
            dispatch(get_candidate_answers_request());
            var answers = await testService.getCandidateAnswers(candidate.candidate_id)
            dispatch(get_candidate_answers_success(answers.data))
        } catch (error) {
            dispatch(get_candidate_answers_failure(error))
        }
    }

    function get_candidate_answers(candidate) {
        return { type: testConstants.GET_CANDIDATE_ANSWERS, candidate }
    }

    function get_candidate_answers_request() {
        return { type: testConstants.GET_CANDIDATE_ANSWERS_REQUEST }
    }

    function get_candidate_answers_success(content) {
        return { type: testConstants.GET_CANDIDATE_ANSWERS_SUCCESS, content }
    }

    function get_candidate_answers_failure(error) {
        return { type: testConstants.GET_CANDIDATE_ANSWERS_FAILURE, error }
    }

}

export function clearCandidateAnswers() {
    return { type: testConstants.CLEAR_CANDIDATE_ANSWERS }
}

export function getAllAnswerContents(obj) {
    return async dispatch => {
        dispatch(get_all_answer_contents());
        dispatch(get_all_answer_contents_request());

        await testService.getAllAnswerContents(obj)
            .then(
                content => {
                    dispatch(get_all_answer_contents_success(content))
                },
                error => {
                    dispatch(get_all_answer_contents_failure(error))
                }
            )
    }

    function get_all_answer_contents() {
        return { type: testConstants.GET_TEST_QUESTION_ANSWER_CONTENTS }
    }

    function get_all_answer_contents_request() {
        return { type: testConstants.GET_TEST_QUESTION_ANSWER_CONTENTS_REQUEST }
    }

    function get_all_answer_contents_success(answers) {
        return { type: testConstants.GET_TEST_QUESTION_ANSWER_CONTENTS_SUCCESS, answers }
    }

    function get_all_answer_contents_failure(error) {
        return { type: testConstants.GET_TEST_QUESTION_ANSWER_CONTENTS_FAILURE, error }
    }
}

// action for get autoshuffle
export function getAutoShuffleFlag() {

    return async dispatch => {
        try {
            dispatch(get_auto_shuffle_request());
            var auto_shuffle = await testService.getAutoShuffleFlag()
            dispatch(get_auto_shuffle_success(auto_shuffle.data))
        } catch (error) {
            dispatch(get_auto_shuffle_failure(error))
        }
    }


    function get_auto_shuffle_request() {
        return { type: testConstants.GET_AUTO_SHUFFLE_REQUEST }
    }

    function get_auto_shuffle_success(content) {
        return { type: testConstants.GET_AUTO_SHUFFLE_SUCCESS, content }
    }

    function get_auto_shuffle_failure(error) {
        return { type: testConstants.GET_AUTO_SHUFFLE_FAILURE, error }
    }

}


export function getAllCandidateAnswerContents(obj) {
    return async dispatch => {
        dispatch(get_all_candidate_answer_contents());
        dispatch(get_all_candidate_answer_contents_request());

        try {
            let answer = await testService.getAllCandidateAnswerContents(obj);
            // var candidate_answers = store.getState()['testReducer']['candidateAnswers']; 
            var candidate_answers = obj['answers'];
            dispatch(get_all_candidate_answer_contents_success({ answer, candidate_answers }));
        } catch (error) {
            dispatch(get_all_candidate_answer_contents_failure(error.toString()));
        }

    }

    function get_all_candidate_answer_contents() {
        return { type: testConstants.GET_ALL_CANDIDATE_ANSWER_CONTENTS }
    }

    function get_all_candidate_answer_contents_request() {
        return { type: testConstants.GET_ALL_CANDIDATE_ANSWER_CONTENTS_REQUEST }
    }

    function get_all_candidate_answer_contents_success(answers) {
        return { type: testConstants.GET_ALL_CANDIDATE_ANSWER_CONTENTS_SUCCESS, answers }
    }

    function get_all_candidate_answer_contents_failure(error) {
        return { type: testConstants.GET_ALL_CANDIDATE_ANSWER_CONTENTS_FAILURE, error }
    }
}

export function onSetMarks(obj) {
    return async (dispatch) => {
        try {
            var candidate_answers =
                store.getState()["testReducer"]["candidateAnswerMap"];
            candidate_answers[obj.id]["mark_obtained"] = obj.marks;
            candidate_answers[obj.id]["graded_by"] = obj.assessor_id;
            dispatch(set_marks(candidate_answers));
        } catch (error) {
            // dispatch(get_all_candidate_answer_contents_failure(error.toString()));
        }
    };

    function set_marks(answers) {
        return { type: testConstants.SET_MARKS, answers };
    }
}

export function onSelfSetMarks(obj) {
    return async (dispatch) => {
        try {
            var studentId =sessionStorage.getItem("user_id");
            
            var candidate_answers =
                store.getState()["testReducer"]["candidateAnswerMap"];
            candidate_answers[obj.id]["mark_obtained"] = obj.marks;
            candidate_answers[obj.id]["graded_by"] = studentId;
            dispatch(set_self_marks(candidate_answers));
        } catch (error) {
            // dispatch(get_all_candidate_answer_contents_failure(error.toString()));
        }
    };

    function set_self_marks(answers) {
        return { type: testConstants.SET_SELF_MARKS, answers };
    }
}
