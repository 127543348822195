import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, List, ListItem, ListItemText, Skeleton, Tooltip} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import GroupIcon from '@mui/icons-material/Group';

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Chat List
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

export default function ChatListView(props) {

    const {setSelectedChat,chats,selected_chat,chatLastMessages} = props

    const [selected, setSelected] = React.useState([]);

    const handleToggle = (value) => () => {
      if(selected[0]==null){
          setSelected([value]);
      }else{
        setSelected([...selected,value]);
      }
    };

  return (
    <div style={{ height: "465px", overflowY: "auto" }}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer>
            <Table aria-labelledby="tableTitle" size={"medium"}>
              {/* <Checkbox
                                color="primary"
                              /> */}

              <TableBody>
                {chats ? (
                  <>
                    {chats.map((chat, i) => (
                      // <TableRow
                      //   hover
                      //   // role="checkbox"
                      //   selected={selected_chat?._id===chat._id}
                      //   tabIndex={-1}
                      //   onClick={()=>setSelectedChat(chat,i)}
                      //   sx={{marginTop:'10px',cursor:'pointer'}}
                      // >

                      // <TableCell padding="checkbox" key={chat._id}>
                      //   <Checkbox
                      //     color="primary"
                      //   //   onClick={handleToggle(chat)}
                      //     // checked={selected.indexOf(chat) !== -1}
                      //     inputProps={{ 'aria-labelledby': chat }}
                      //   />
                      // </TableCell>
                      // <TableCell padding="checkbox"onClick={()=>setSelectedChat(chat,i)}>
                      //   <Avatar
                      //   src={chat.chatName?chat.chatName:""} alt={chat.chatName?chat.chatName:""}>
                      //     {/* <img alt={chat.chatName?chat.chatName:""} style={{width:'50px',height:'50px'}}/> */}
                      //   </Avatar>
                      // </TableCell>

                      // <TableCell
                      //   component="th"
                      //   scope="row"
                      //   padding="10px"
                      //   onClick={()=>setSelectedChat(chat,i)}
                      // >
                      //   {chat.chatName?chat.chatName:""}
                      // </TableCell>

                      // <TableCell align="left"> <b>{chatLastMessages[i] && (

                      //                   <b>{chatLastMessages[i].length > 50
                      //                     ? chatLastMessages[i].substring(0, 51) + "..."
                      //                     : chatLastMessages[i]}</b>
                      //               )}</b></TableCell>

                      // <TableCell align="right">
                      //               <IconButton aria-label="delete">
                      //                   <DeleteIcon />
                      //                 </IconButton>

                      //   </TableCell>
                      // </TableRow>

                      <ListItem
                        selected={selected_chat?._id === chat._id}
                        tabIndex={-1}
                        onClick={() => setSelectedChat(chat, i)}
                        sx={{ 
                          borderBottom: "1px solid #ececec",
                          cursor: "pointer"
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            onClick={() => setSelectedChat(chat, i)}
                            sx={{ backgroundColor: "#7779CF" }}
                          >
                            <GroupIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          onClick={() => setSelectedChat(chat, i)}
                          primary={chat.chatName ? chat.chatName : ""}
                          secondary={
                            chatLastMessages[i] &&
                            (chatLastMessages[i].length > 40
                              ? chatLastMessages[i].substring(0, 41) + "..."
                              : chatLastMessages[i])
                          }
                          style={{ fontFamily: "Poppins" }}
                        />
                      </ListItem>
                    ))}
                  </>
                ) : (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton animation="wave" />
                    <Skeleton variant="text" />
                    <Skeleton animation="wave" />
                    <Skeleton variant="text" />
                    <Skeleton animation="wave" />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  )
}
