import React from "react";

export default function QuestionButton({ type, btnName, className, dataDismiss, onClick, onClickSubmit, callBackPage, btnIconStyle, dataToggle, dataTarget, inlineStyle, disabled, id }) {

    return (
        <button type={type}
            className={className}
            data-dismiss={dataDismiss}
            onClick={onClick}
            onClickSubmit={onClickSubmit}
            callBackPage={callBackPage}
            data-toggle={dataToggle}
            data-target={dataTarget}
            style={inlineStyle}
            disabled={disabled}
            id={id}>
            <i className={btnIconStyle}></i>
            {btnName}
        </button>
    )
}